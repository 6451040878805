// @ts-strict-ignore
import angular from 'angular';
import { FluxService } from '@/core/flux.service';

export const flux = new FluxService();

// Adds a new .store() method to the angular.module
const originalAngularModule = angular.module;
angular.module = function () {
  const moduleInstance = originalAngularModule.apply(angular, arguments);
  moduleInstance.store = function (storeName, storeDefinition) {
    flux.addPendingStore(storeName);
    this.factory(storeName, function ($injector) {
      const storeConfig = $injector.invoke(storeDefinition);
      return flux.createStoreFromService(storeConfig, storeName);
    });
    return this;
  };

  return moduleInstance;
};

// Inject angular into flux
angular
  .module('Sq.Flux', [])
  .constant('flux', flux)
  .run(($injector, $rootScope) => {
    flux.setRootScope($rootScope);
    flux.setDispatchCallback((handler, payload, option) => {
      try {
        $injector.get('sqStateSynchronizer').push(option);
      } catch (e) {
        // sqStateSynchronizer is not always available in the integration tests
      }
    });

    if (!angular.mock && process.env.NODE_ENV !== 'test') {
      // This must happen after setImmutableDefaults
      $injector.invoke((flux.pendingStores as any).concat(angular.noop));
    }

    if (angular.mock) {
      flux.useEvalAsync = false;
      flux.reset();
    }
  });
