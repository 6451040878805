// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { WorksheetViewSelector } from '@/hybrid/trend/WorksheetViewSelector.molecule';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Popover } from 'react-bootstrap';
import { ToolbarButton } from '@/hybrid/core/ToolbarButton.molecule';
import { MIN_SAMPLES_FOR_BOOST, ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { IconSelect } from '@/hybrid/core/IconSelect.molecule';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { ToolbarPopoverButton } from '@/hybrid/core/ToolbarPopoverButton.molecule';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ScatterPlotColorModal } from '@/hybrid/scatterPlot/ScatterPlotColorModal.organism';
import { ScatterPlotConditionButton } from '@/hybrid/scatterPlot/ScatterPlotConditionButton.molecule';
import { ScatterPlotMarkerSizePopoverButton } from '@/hybrid/scatterPlot/ScatterPlotMarkerSizePopoverButton.molecule';
import { ScatterPlotFxLineModal } from '@/hybrid/scatterPlot/ScatterPlotFxLineModal.molecule';
import { SMALL_SIZE_SCREEN_LIMIT } from '@/main/app.constants';
import { useResizeWatcher } from '@/hybrid/core/hooks/useResizeWatcher.hook';
import { sqSignalsApi } from '@/sdk';
import { ScatterPlotSignalDropdown } from '@/hybrid/scatterPlot/ScatterPlotSignalDropdown.molecule';
import { SCATTER_PLOT_VIEWS } from '@/scatterPlot/scatterPlot.constants';
import { doTrack } from '@/track/track.service';
import { isViewOnlyWorkbookMode as isViewOnlyWorkbookModeSqUtilities } from '@/hybrid/utilities/utilities';
import { sqScatterPlotStore, sqTrendCapsuleSetStore, sqTrendSeriesStore } from '@/core/core.stores';

const binOptions = [...Array(20).keys()].map((key) => ({
  text: `${(key + 1) * 5}`,
  value: (key + 1) * 5,
}));

export const scatterPlotToolbarBindings = bindingsDefinition({
  fxLines: prop<any[]>(),
  colorRanges: prop<any[]>(),
  signals: prop<any[]>(),
  sqScatterPlotActions: injected<ScatterPlotActions>(),
});

export const ScatterPlotToolbar: SeeqComponent<typeof scatterPlotToolbarBindings> = ({
  fxLines,
  colorRanges,
  signals,
}) => {
  const { sqScatterPlotActions } = useInjectedBindings(scatterPlotToolbarBindings);

  const { t } = useTranslation();
  const isViewOnlyWorkbookMode = isViewOnlyWorkbookModeSqUtilities();

  const [showFxLineModal, setShowFxLineModal] = useState(false);
  const [isSmallToolbar, setIsSmallButton] = useState(false);
  const toolbarElement = useRef(null);

  const { items } = useFlux(sqTrendCapsuleSetStore);
  const {
    xSignal,
    ySignals,
    colorSignalId,
    colorConditionIds,
    connect,
    showTooltips,
    isRegionSelected,
    isViewRegionSet,
    plotMode,
    plotView,
    numXBins, // Density plot-specific fields
    numYBins, // Density plot-specific fields
  } = useFlux(sqScatterPlotStore);

  const isScatterPlotView = plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT;
  const isDensityPlotView = plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT;

  useResizeWatcher({
    elementRef: toolbarElement,
    callback: (data) => setIsSmallButton(data.newWidth < SMALL_SIZE_SCREEN_LIMIT),
    callOnLoad: true,
  });

  useEffect(() => autoSelectXySignals(), [xSignal, ySignals, signals]);

  const colorSignal = sqTrendSeriesStore.findItem(colorSignalId);
  const colorSignalName = colorSignal?.name;

  /**
   * As a convenience, this loads the first two available time series for the scatter plot
   * so the user doesn't have to do it manually
   */
  const autoSelectXySignals = () => {
    const xId = xSignal?.id;
    const yId = ySignals[0]?.id;
    const yIds = _.map(ySignals, 'id');
    const availableSignals = _.chain(signals)
      .reject(['id', xId])
      .reject((signal) => _.includes(yIds, signal.id))
      .value();

    if ((!xId || !yId) && availableSignals.length) {
      sqScatterPlotActions.setSignals({
        xSignal: !xId && availableSignals.length ? availableSignals.shift() : xSignal,
        ySignals: !yId && availableSignals.length ? [availableSignals.shift()] : ySignals,
      });
    }
  };

  const switchToDensityPlot = () => {
    sqScatterPlotActions.switchToDensityPlot();
    doTrack('XY Plot', 'Switch to Density Plot');
  };

  const flipXAndY = () => {
    sqScatterPlotActions.flipXAndY();
    doTrack('Scatterplot', 'Signals swapped');
  };

  /**
   * Opens the modal for managing lines onto the chart.
   */
  const openLinesModal = () => setShowFxLineModal(true);

  /**
   * Closes the modal for managing lines onto the chart.
   */
  const closeLinesModal = () => setShowFxLineModal(false);

  /**
   * Opens the modal for managing colors onto the chart.
   */
  const openColorsModal = () => sqScatterPlotActions.setShowColorModal(true);

  const setConnect = () => {
    sqScatterPlotActions.setConnect(!connect);
    doTrack('Scatterplot', 'Connect');
  };

  const hasManyPoints = _.chain(sqScatterPlotStore.scatterData)
    .map((data) => data.length)
    .sum()
    .thru((sum) => sum > MIN_SAMPLES_FOR_BOOST)
    .value();

  const toggleShowTooltips = () => sqScatterPlotActions.setShowTooltips(!showTooltips);

  const zoomToSelectedRegion = () => {
    sqScatterPlotActions.zoomToSelectedRegion();
    doTrack('Scatterplot', 'Zoom');
  };

  const expandViewRegion = () => {
    sqScatterPlotActions.expandViewRegion();
    doTrack('Scatterplot', 'Expand');
  };

  /**
   * Gets the ids of signals that are not compatible with the ability to plot a function of x line on the scatter plot
   * chart. The only signals that are valid are described in #isValidFxSignal().
   *
   * Resolves with array of signal ids that are not valid for use with scatter plot line functionality.
   */
  const getFxSignalIdsToExclude = (fxLines): Promise<string[]> => {
    const xId = xSignal?.id;
    const yIds = _.map(ySignals, (ySignal) => ySignal.id);
    return _.chain(signals)
      .reject(['id', xId])
      .reject((signal) => _.includes(yIds, signal.id)) // No need to fetch X and Y since they are always excluded
      .reject((signal) => _.chain(fxLines).map('id').includes(signal.id).value())
      .filter('calculationType') // Only calculated signals are useful
      .map(({ id }) => sqSignalsApi.getSignal({ id }))
      .thru((promises) => Promise.all(promises))
      .value()
      .then((fetchedSignals) =>
        _.chain(fetchedSignals)
          .map('data')
          .filter((signal) => sqScatterPlotStore.isValidFxSignal(signal.parameters, signal.formula))
          .map('id')
          .thru((validIds) => _.chain(signals).map('id').difference(validIds).value())
          .value(),
      );
  };

  return (
    <div
      className={classnames('toolbar flexWrap flexColumnContainer flexAlignCenter scatterPlotToolbar', {
        isSmallToolbar,
      })}
      data-testid="scatterPlotToolbar"
      ref={(ref) => {
        toolbarElement.current = ref;
      }}>
      <WorksheetViewSelector />
      {/* Series selectors */}
      <ButtonGroup className="flexColumnContainer pl6 flexCenter">
        <ToolbarButton
          active={isScatterPlotView}
          onClick={sqScatterPlotActions.switchToScatterPlot}
          testId="scatterPlotToolbarButton"
          isSmall={isSmallToolbar}
          label="SCATTER.SCATTER"
          icon="fc-scatterplot"
          tooltipText="DENSITY_PLOT.SCATTER_PLOT"
          tooltipPlacement="top"
        />
        <ToolbarButton
          active={isDensityPlotView}
          onClick={switchToDensityPlot}
          testId="densityPlotToolBarButton"
          isSmall={isSmallToolbar}
          label="DENSITY_PLOT.DENSITY"
          icon="fc-clock-density"
          tooltipText="SCATTER.DENSITY_PLOT"
          tooltipPlacement="top"
        />
      </ButtonGroup>
      <ButtonGroup>
        <div className="flexColumnContainer flexCenter flexWrap">
          <div className="flexColumnContainer">
            {!isSmallToolbar && (
              <div className="flexRowContainer">
                <div className="mb1 mr3">{t('SCATTER.Y_AXIS_SERIES')}</div>
                <div className="mb1 mr3">{t('SCATTER.X_AXIS_SERIES')}</div>
              </div>
            )}
            <ToolbarPopoverButton
              tooltipText="SCATTER.SIGNAL_DROPDOWN"
              tooltipPlacement="top"
              testId="selectedSignals"
              icon="fc-series"
              buttonClasses="forceZeroPadding"
              label={
                <div className="flexColumnContainer mr7">
                  <div className="flexRowContainer btn-group">
                    <div data-testid="selectedYSignal">
                      {ySignals.length > 1 && t('SCATTER.MULTIPLE_SIGNALS')}
                      {ySignals.length === 1 && sqTrendSeriesStore.findItem(ySignals[0].id)?.name}
                      {ySignals.length === 0 && t('SCATTER.NA')}
                    </div>
                    <hr className="mt1 mb1" />
                    <div data-testid="selectedXSignal">
                      {sqTrendSeriesStore.findItem(xSignal?.id)?.name ?? t('SCATTER.NA')}
                    </div>
                  </div>
                  <div className="flexRowContainer flexCenter">
                    <Icon icon="fa-caret-down" type="text" />
                  </div>
                </div>
              }>
              <Popover id="scatterplot-signal-popover" data-testid="scatterPlotSignalPopover">
                <Popover.Content>
                  <ScatterPlotSignalDropdown
                    ySignals={ySignals}
                    xSignal={xSignal}
                    setSignals={sqScatterPlotActions.setSignals}
                    isScatterPlotView={sqScatterPlotStore.plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT}
                  />
                </Popover.Content>
              </Popover>
            </ToolbarPopoverButton>
          </div>
          {/* Flip XY Button */}
          <ToolbarButton
            id="scatterPlotFlipXY"
            active={isScatterPlotView}
            disabled={!xSignal || !ySignals[0] || ySignals.length > 1}
            onClick={flipXAndY}
            testId="scatterPlotFlipXY"
            isSmall={true}
            label=""
            icon="fa-exchange fa-rotate-270"
            tooltipText="SCATTER.FLIP_XY"
            tooltipPlacement="top"
          />

          {/* Num Bins Selectors */}
          {isDensityPlotView && (
            <div className="flexRowContainer mr3 flexAlignEnd">
              <div className="flexColumnContainer flexCenter flexWrap">
                {!isSmallToolbar && <div className="mb1 mr3 ml5">{t('DENSITY_PLOT.Y_BINS')}</div>}
                <IconSelect
                  extraClassNames="mb1 min-width-20"
                  onChange={(option) => sqScatterPlotActions.setNumYBins(_.toNumber(option.value))}
                  selectOptions={binOptions}
                  value={numYBins}
                  testId="numYBins"
                />
              </div>
              <div className="flexColumnContainer flexCenter flexWrap">
                {!isSmallToolbar && <div className="mt1 mr3 ml5">{t('DENSITY_PLOT.X_BINS')}</div>}
                <IconSelect
                  extraClassNames="mb1 min-width-20"
                  onChange={(option) => sqScatterPlotActions.setNumXBins(_.toNumber(option.value))}
                  selectOptions={binOptions}
                  value={numXBins}
                  testId="numXBins"
                />
              </div>
            </div>
          )}
          {/* Add function of x line */}
          {!isViewOnlyWorkbookMode && isScatterPlotView && (
            <div className="flexCenter pl6">
              <ToolbarButton
                active={!!fxLines.length}
                onClick={openLinesModal}
                testId="scatterPlotLinesButton"
                isSmall={isSmallToolbar}
                label="SCATTER.FX_LINE"
                icon="fc-scatterplot-line"
                tooltipText="SCATTER.FX_LINE_TOOLTIP"
                tooltipPlacement="top"
              />
            </div>
          )}
        </div>
      </ButtonGroup>
      {isScatterPlotView && (
        <ButtonGroup
          className={classnames('flexColumnContainer flexCenter', {
            pt5: isSmallToolbar,
            pb5: isSmallToolbar,
          })}>
          {/* Plot Modes */}
          <ToolbarPopoverButton
            icon="fa-filter"
            label="SCATTER.FILTER"
            tooltipText="SCATTER.FILTER_TOOLTIP"
            tooltipPlacement="top"
            testId="scatterPlotFilterButton"
            isSmall={isSmallToolbar}>
            <Popover id="scatterplot-filter-popover" data-testid="scatterplotFilterPopover">
              <Popover.Content>
                <HoverTooltip text={t('SCATTER.DISPLAY_RANGE_TOOLTIP')} placement="right">
                  <div
                    id="scatterPlotGridButton1"
                    className="cursorPointer toolbarPlotOptions"
                    onClick={() => sqScatterPlotActions.setPlotMode('DISPLAY_RANGE')}>
                    <Icon icon={`fa-fw ${plotMode === 'DISPLAY_RANGE' ? 'fa-check-circle' : 'fa-circle-thin'}`} />
                    <small className="max-width-70">{t('SCATTER.DISPLAY_RANGE')}</small>
                  </div>
                </HoverTooltip>
                <HoverTooltip text={t('SCATTER.DISPLAY_CONDITIONS_TOOLTIP')} placement="right">
                  <div
                    id="scatterPlotSamplesButton1"
                    className={classnames('cursorPointer toolbarPlotOptions', {
                      active: plotMode === 'CAPSULES',
                      disabled: !items.length,
                    })}
                    onClick={() => items.length && sqScatterPlotActions.setPlotMode('CAPSULES')}>
                    <Icon
                      icon={`fa-fw ${plotMode === 'CAPSULES' ? 'fa-check-circle' : 'fa-circle-thin'}`}
                      type={!items.length ? 'gray' : 'theme'}
                    />
                    <small className="max-width-70">{t('SCATTER.DISPLAY_CONDITIONS')}</small>
                  </div>
                </HoverTooltip>
              </Popover.Content>
            </Popover>
          </ToolbarPopoverButton>
          <ToolbarButton
            active={colorConditionIds.length || colorSignalName || colorRanges.length}
            onClick={openColorsModal}
            testId="scatterPlotColorsButton"
            isSmall={isSmallToolbar}
            label="SCATTER.COLOR_CRITERIA"
            icon="fa-tint"
            tooltipText="SCATTER.COLOR_CRITERIA_TOOLTIP"
            tooltipPlacement="top"
          />
        </ButtonGroup>
      )}
      <ButtonGroup
        className={classnames('flexColumnContainer flexCenter', {
          pt5: isSmallToolbar,
          pb5: isSmallToolbar,
        })}>
        {/* Connect Samples */}
        {isScatterPlotView && (
          <ToolbarButton
            disabled={hasManyPoints}
            active={connect}
            onClick={setConnect}
            testId="scatterPlotConnectButton"
            isSmall={isSmallToolbar}
            label="SCATTER.CONNECT"
            icon="fc-connect"
            tooltipText={hasManyPoints ? 'SCATTER.CONNECT_TOOLTIP_DISABLED' : 'SCATTER.CONNECT_TOOLTIP'}
            tooltipPlacement="top"
          />
        )}
        <ToolbarButton
          active={showTooltips}
          onClick={toggleShowTooltips}
          testId="scatterPlotShowTooltipsButton"
          isSmall={isSmallToolbar}
          label="SCATTER.SHOW_TOOLTIPS"
          icon="fa-tag"
          tooltipText="SCATTER.SHOW_TOOLTIPS_TOOLTIP"
          tooltipPlacement="top"
        />
      </ButtonGroup>
      {/* Zoom to Selection */}
      <ButtonGroup
        className={classnames('flexColumnContainer flexCenter', {
          pt5: isSmallToolbar,
          pb5: isSmallToolbar,
        })}>
        <ToolbarButton
          disabled={!isRegionSelected()}
          onClick={zoomToSelectedRegion}
          testId="scatterPlotZoomButton"
          isSmall={isSmallToolbar}
          label="TOOLBAR.ZOOM"
          icon="fa-search-plus"
          tooltipText={isRegionSelected ? 'TOOLBAR.ZOOM_ENABLED' : 'TOOLBAR.ZOOM_DISABLED'}
          tooltipPlacement="top"
        />
        <ToolbarButton
          disabled={!isViewRegionSet()}
          onClick={expandViewRegion}
          testId="scatterPlotExpandButton"
          isSmall={isSmallToolbar}
          label="TOOLBAR.RESET"
          icon="fa-arrows-alt"
          tooltipText="TOOLBAR.EXPAND_TOOLTIP"
          tooltipPlacement="top"
        />
      </ButtonGroup>

      {isScatterPlotView && (
        <ButtonGroup
          className={classnames('flexColumnContainer flexCenter', {
            pt5: isSmallToolbar,
            pb5: isSmallToolbar,
          })}>
          <ScatterPlotConditionButton isSmallButton={isSmallToolbar} />
          <ScatterPlotMarkerSizePopoverButton isSmallButton={isSmallToolbar} />
        </ButtonGroup>
      )}

      {showFxLineModal && (
        <ScatterPlotFxLineModal closeModal={closeLinesModal} signalIdsToExclude={getFxSignalIdsToExclude} />
      )}

      <ScatterPlotColorModal />
    </div>
  );
};
