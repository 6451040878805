import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { FORM_ERROR, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import {
  setAutoupdateTimeRange,
  setDuration,
  setExportTimeZone,
  setGridOption,
  setGridOrigin,
  setGridOriginEnabled,
  setGridSize,
  setTimeRangeEnd,
  setTimeRangeStart,
} from '@/hybrid/tools/exportOData/exportODataPanel.actions';
import { GRID_OPTION } from '@/hybrid/tools/exportOData/exportODataPanel.store';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import React, { useState } from 'react';
import _ from 'lodash';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { DurationEntry } from '@/hybrid/core/DurationEntry.atom';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { APP_STATE } from '@/main/app.constants';
import { Feature } from '@/licenseManagement/licenseManagement.store';
import { toOData } from '@/hybrid/utilities/export.utilities';

import { handleModalOpenErrors, randomUUID } from '@/hybrid/utilities/utilities';
import {
  sqExportODataPanelStore,
  sqLicenseManagementStore,
  sqTrendStore,
  sqWorkbookStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { ExportODataModal } from '@/hybrid/tools/exportOData/ExportODataModal.organism';

const exportODataBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
  sqInvestigateActions: injected<InvestigateActions>(),
});

const toolName = TREND_TOOLS.EXPORT_ODATA;

export const ExportOData = () => {
  const { $state, sqInvestigateActions } = useInjectedBindings(exportODataBindings);

  const {
    name,
    autoupdateTimeRange,
    timeRange,
    gridOption,
    gridSize,
    gridOriginEnabled,
    exportSignals,
    exportConditions,
    gridOrigin,
    exportTimeZone,
  } = useFlux(sqExportODataPanelStore);
  const timezone = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone);
  // force the component to re-render whenever view changes
  useFluxPath(sqTrendStore, () => sqTrendStore.view);

  const [showModal, setShowModal] = useState(false);
  const [oDataPath, setODataPath] = useState('');
  const [oDataCapsulePath, setODataCapsulePath] = useState('');

  const isCapsuleTime = sqTrendStore.isTrendViewCapsuleTime();
  const isChainView = sqTrendStore.isTrendViewChainView();
  const autoupdateDisabled = !sqLicenseManagementStore.hasValidFeature(Feature.ODATA_Realtime_Export);

  const { t } = useTranslation();

  /**
   * Generate a Workbench URL to current Worksheet, with view-only access
   *
   * @returns {String} Returns a absolute URL
   */
  const makeWorksheetURL = (): string => {
    return $state.href(
      APP_STATE.VIEW_WORKSHEET,
      {
        workbookId: $state.params.workbookId,
        worksheetId: $state.params.worksheetId,
      },
      {
        absolute: true,
      },
    );
  };

  const execute = () => {
    const items = exportSignals.concat(exportConditions);
    const exportName = `${name}-Export-${sqWorkbookStore.getWorksheetName($state.params.worksheetId)}_${randomUUID()}`;
    const gridSizeEnabled = gridOption === GRID_OPTION.CUSTOM;
    const gridOriginString =
      gridSizeEnabled && gridOriginEnabled && !isCapsuleTime
        ? // Format the origin so it's output as a localized ISO-style time string
          gridOrigin.format('YYYY-MM-DD[T]HH:mm:ss')
        : null;

    return (
      toOData(
        exportName,
        items,
        timeRange,
        autoupdateTimeRange,
        true,
        isCapsuleTime,
        isChainView,
        false,
        gridSizeEnabled && gridSize.value + gridSize.units,
        gridOriginString,
        exportTimeZone.name,
        makeWorksheetURL(),
      )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - We probably shouldn't have used {} as the returned type
        .then(({ data: { href: generalExportLink } }) =>
          toOData(
            `${exportName}Capsule`,
            items,
            timeRange,
            autoupdateTimeRange,
            true,
            isCapsuleTime,
            isChainView,
            true,
            gridSizeEnabled && gridSize.value + gridSize.units,
            gridOriginString,
            exportTimeZone.name,
            makeWorksheetURL(),
          ).then(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - We probably shouldn't have used {} as the returned type
            ({ data: { href: odataCapsulePath } }) => {
              setODataCapsulePath(odataCapsulePath);
              setODataPath(generalExportLink);
              setShowModal(true);
            },
          ),
        )
        .catch(handleModalOpenErrors)
    );
  };

  const formDataSetup: FormElement[] = [
    {
      component: 'DisplayOnlyFormElementWrapper',
      name: 'title',
      children: (
        <h4 className="card-title flexColumnContainer pt10 pl15">
          <Icon testId="titleIcon" icon="fa-database" extraClassNames="pr10" large={true} />
          <span>{t('EXPORT_ODATA.HEADER')}</span>
        </h4>
      ),
    },
    {
      component: 'FormControlFormComponent',
      testId: 'exportName',
      name: 'name',
      value: name,
      onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.EXPORT_ODATA, name),
      size: 'lg',
      label: 'EXPORT_ODATA.EXPORT_NAME',
      displayNumber: true,
      wrapperClassNames: 'flexFill',
      required: true,
    },
    {
      component: 'ButtonGroupFormComponent',
      name: 'timeRangeOptions',
      label: 'EXPORT_ODATA.TIME_RANGE_OPTIONS',
      tooltip: 'EXPORT_ODATA.TIME_RANGE_OPTIONS_TOOLTIP',
      onChange: (value) => setAutoupdateTimeRange(value === 'autoupdate'),
      value: autoupdateTimeRange ? 'autoupdate' : 'fixed',
      buttonItems: [
        {
          label: 'EXPORT_ODATA.TIME_RANGE_FIXED',
          id: 'fixedTimeRange',
          value: 'fixed',
        },
        {
          label: 'EXPORT_ODATA.TIME_RANGE_AUTOUPDATE',
          id: 'autoupdateTimeRange',
          value: 'autoupdate',
          disabled: autoupdateDisabled,
          tooltip: autoupdateDisabled ? 'LICENSE.FEATURE_DISABLED' : '',
        },
      ],
      displayNumber: true,
    },
    {
      component: 'FormGroup',
      name: 'timeRangeFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'DateTimeRangeFormComponent',
          name: 'timeRange',
          label: 'EXPORT_ODATA.TIME_RANGE',
          value: _.omit(timeRange, 'duration'),
          timezone,
          hideTimezone: true,
          onStartTimeChange: setTimeRangeStart,
          onEndTimeChange: setTimeRangeEnd,
          disabled: autoupdateTimeRange,
          extraClassNames: 'flexJustifyCenter',
        },
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'durationEntry',
          children: (
            <div className="flexColumnContainer flexJustifyCenter">
              [
              <DurationEntry
                startDate={timeRange.start}
                endDate={timeRange.end}
                updateDuration={setDuration}
                timezone={exportTimeZone}
                updateDates={_.noop}
                textExtraClassNames="sq-text-primary"
                extraClassNames="mb10"
                autoCorrect={false}
              />
              ]
            </div>
          ),
        },
        {
          component: 'ErrorMessageFormComponent',
          name: 'durationError',
          failForm: true,
          includeIf: timeRange.end?.isSameOrBefore?.(timeRange.start),
          type: FORM_ERROR,
          value: `${t('PARSE_DURATION_ERROR')} 0`,
          extraClassNames: 'text-center',
        },
        {
          component: 'TimeZoneSelectorFormComponent',
          name: 'exportTimeZone',
          value: exportTimeZone,
          onChange: setExportTimeZone,
          extraClassNames: 'max-width-230 marginAuto',
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'gridOptionsFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'RadioButtonGroupFormComponent',
          name: 'gridOptions',
          id: 'gridOptions',
          value: gridOption,
          verticalLayout: true,
          label: 'EXPORT_ODATA.GRID_SIZE',
          onChange: _.noop,
          options: [
            {
              id: 'gridOptionAutomatic',
              label: 'EXPORT_ODATA.AUTO',
              checked: gridOption === GRID_OPTION.AUTOMATIC,
              onToggle: () => setGridOption(GRID_OPTION.AUTOMATIC),
            },
            {
              id: 'gridOptionCustom',
              label: 'EXPORT_ODATA.CUSTOM',
              checked: gridOption === GRID_OPTION.CUSTOM,
              onToggle: () => setGridOption(GRID_OPTION.CUSTOM),
            },
          ],
        },
        {
          component: 'FormGroup',
          name: 'setGridSizeFormGroup',
          testId: 'setGridSizeFormGroup',
          includeIf: gridOption === GRID_OPTION.CUSTOM,
          extraClassNames: 'ml20',
          components: [
            {
              component: 'ValueWithUnitsFormComponent',
              name: 'setGridSize',
              testId: 'setGridSize',
              value: gridSize,
              min: 0,
              minIsExclusive: true,
              onChange: setGridSize,
              required: true,
              customErrorText: 'EXPORT_EXCEL.GRID_SIZE_MINIMUM',
            },
            {
              component: 'CheckboxFormComponent',
              name: 'gridOriginEnabled',
              testId: 'gridOriginEnabled',
              id: 'gridOriginEnabled',
              value: gridOriginEnabled,
              checkboxLabel: 'EXPORT_EXCEL.CUSTOM_ORIGIN',
              tooltip: 'EXPORT_EXCEL.CUSTOM_ORIGIN_TOOLTIP',
              onChange: () => setGridOriginEnabled(!gridOriginEnabled),
              extraClassNames: 'mt10',
            },
            {
              component: 'DateTimeEntryFormComponent',
              name: 'gridOrigin',
              timezone,
              value: gridOrigin,
              hideTimezone: true,
              onChange: setGridOrigin,
              extraClassNames: 'ml20',
            },
          ],
        },
      ],
    },
    {
      component: 'ItemSelectFormComponent',
      name: 'exportSignals',
      testId: 'exportSignals',
      displayNumber: true,
      label: 'EXPORT_ODATA.SIGNALS',
      itemTypes: [ITEM_TYPES.SERIES],
      isMultipleSelect: true,
      value: _.map(exportSignals, 'id'),
      required: _.isEmpty(exportConditions),
      onChange: (item) => sqInvestigateActions.setParameterItem(TREND_TOOLS.EXPORT_ODATA, 'exportSignals', item),
      onRemove: (item) => sqInvestigateActions.unsetParameterItem(TREND_TOOLS.EXPORT_ODATA, 'exportSignals', item),
    },
    {
      component: 'ItemSelectFormComponent',
      name: 'exportConditions',
      testId: 'exportConditions',
      displayNumber: true,
      label: 'EXPORT_ODATA.CONDITIONS',
      itemTypes: [ITEM_TYPES.CAPSULE_SET],
      isMultipleSelect: true,
      value: _.map(exportConditions, 'id'),
      required: _.isEmpty(exportSignals),
      onChange: (item) => sqInvestigateActions.setParameterItem(TREND_TOOLS.EXPORT_ODATA, 'exportConditions', item),
      onRemove: (item) => sqInvestigateActions.unsetParameterItem(TREND_TOOLS.EXPORT_ODATA, 'exportConditions', item),
    },
  ];

  return (
    <>
      <ToolPanelFormBuilder
        formDefinition={formDataSetup}
        submitFn={execute}
        closeFn={sqInvestigateActions.close}
        toolId={toolName}
        submitBtnId="exportODataButton"
      />
      {showModal && oDataCapsulePath && oDataPath && (
        <ExportODataModal
          onClose={() => setShowModal(false)}
          oDataPath={oDataPath}
          oDataCapsulePath={oDataCapsulePath}
        />
      )}
    </>
  );
};

export const sqExportOData = angularComponent(exportODataBindings, ExportOData);
