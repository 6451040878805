import _ from 'lodash';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import { IS_PROTRACTOR } from '@/main/app.constants';
import { useTranslation } from 'react-i18next';

export const DEFAULT_TOOL_TIP_DELAY = 500;

export interface HoverTooltipProps {
  placement?: Placement;
  delay?: number;
  text: string;
  formattedText?: JSX.Element;
  translateParams?: object;
  skipMemo?: boolean;
  children?: JSX.Element;
}

/**
 * Generic tooltip that appears after hover delay
 */
const HoverTooltipUnwrapped: React.FunctionComponent<HoverTooltipProps> = (props) => {
  const { text = '', delay = DEFAULT_TOOL_TIP_DELAY, placement, children, formattedText, translateParams } = props;
  const { t } = useTranslation();

  if (_.isEmpty(text)) {
    return <>{children}</>;
  }

  const tooltip = <Tooltip id={text}>{formattedText ? formattedText : t(text, translateParams ?? {})}</Tooltip>;
  return (
    <OverlayTrigger placement={placement} delay={IS_PROTRACTOR ? 0 : { show: delay, hide: 0 }} overlay={tooltip}>
      {IS_PROTRACTOR ? <span data-testid="hover-tooltip">{props.children as any}</span> : (props.children as any)}
    </OverlayTrigger>
  );
};

export const HoverTooltip = React.memo(
  HoverTooltipUnwrapped,
  (prev, next) =>
    !(
      !_.isEqual(prev.translateParams, next.translateParams) ||
      !_.isEqual(prev.formattedText, next.formattedText) ||
      !_.isEqual(prev.children, next.children) ||
      prev.placement !== next.placement ||
      prev.delay !== next.delay ||
      prev.text !== next.text ||
      prev.skipMemo
    ),
);
