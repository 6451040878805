// @ts-strict-ignore
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import DataSummarySlider from '@/hybrid/trend/DataSummarySlider.molecule';
import { SummaryTypeEnum } from '@/sdk/model/ContentInputV1';
import { IconSelect, ReactSelectOption } from '@/hybrid/core/IconSelect.molecule';
import {
  KEEP_CURRENT_SUMMARY,
  ReportContentSummaries,
  ReportContentSummary as SummaryType,
} from '@/reportEditor/report.constants';
import { SummarySliderValues, SummaryTypeSettings } from '@/trendData/trendData.constants';

interface ReportContentSummaryProps {
  summary: SummaryType;
  summaries: ReportContentSummaries | any;
  setSummary: (summaryType: SummaryType | undefined) => void;
}

export const ReportContentSummary: React.FunctionComponent<ReportContentSummaryProps> = (props) => {
  const { summary, summaries, setSummary } = props;
  const { t } = useTranslation();

  const calculateStartingSliderValue = (): number => {
    if (!summary || (summary.key as any) === KEEP_CURRENT_SUMMARY.key) {
      return 0;
    }
    if (summary.key === SummaryTypeEnum.AUTO) {
      return _.toNumber(summary.value);
    }
    const key = _.findKey(SummarySliderValues[summary.key]?.value, summary.value as any);
    const sliderValue = key !== undefined ? _.toNumber(key) : -1;
    return sliderValue !== -1 ? sliderValue : SummaryTypeSettings[summary.key]?.max / 2 ?? 0;
  };

  const inheritOption: ReactSelectOption = {
    text: 'REPORT.CONFIG.INHERIT_FROM_WORKSHEET',
    value: undefined,
  };

  const summaryOptions = [inheritOption].concat(
    _.map(
      summaries,
      (summary): ReactSelectOption => ({
        text: t(summary.name),
        value: summary.key,
      }),
    ),
  );

  const [sliderValue, setSliderValue] = useState(calculateStartingSliderValue);
  const [selectedSummaryOption, setSelectedSummaryOption] = useState<ReactSelectOption>(
    summary ? _.find(summaryOptions, { value: summary.key }) : _.first(summaryOptions),
  );

  const updateSlider = (level) => {
    const value = summary.key === SummaryTypeEnum.AUTO ? level : SummarySliderValues[summary.key].value[level];
    setSummary({ ...summary, value });
    setSliderValue(level);
  };

  const updateSpecificValue = (update) => {
    setSummary({
      ...summary,
      value: { value: update.value, units: update.units },
    });
  };

  const currentSliderSettings = summary ? SummaryTypeSettings[summary.key] : undefined;
  const slider = summary && _.chain(summaries).filter({ variable: true }).some({ key: summary.key }).value() && (
    <DataSummarySlider
      label=""
      min={currentSliderSettings.min}
      max={currentSliderSettings.max}
      step={currentSliderSettings.step}
      value={sliderValue}
      onChange={updateSlider}
      updateValueWithUnits={updateSpecificValue}
      showCopyButton={false}
      testId="report-summary-slider"
      summary={{
        value: sliderValue,
        type: summary.key,
        discreteUnits: summary.value,
        isSlider: true,
      }}
    />
  );

  const handleSetSummaryOption = (userChoice: ReactSelectOption) => {
    const summaryMatch: SummaryType = _.find<SummaryType>(summaries, { key: userChoice.value }) || _.first(summaries);
    setSummary(summaryMatch);
    if (summaryMatch) {
      setSliderValue(
        SummaryTypeSettings[
          _.toString(summaryMatch.key) !== KEEP_CURRENT_SUMMARY.key ? summaryMatch.key : SummaryTypeEnum.NONE
        ].min,
      );
    }
    setSelectedSummaryOption(userChoice);
  };

  return (
    <div className="p10">
      <label htmlFor="summarySelect">{t('REPORT.CONFIG.SUMMARY')}</label>
      <IconSelect
        id="summarySelect"
        testId="summarySelect"
        insideModal={true}
        selectOptions={summaryOptions}
        value={selectedSummaryOption}
        onChange={handleSetSummaryOption}
      />
      {slider}
    </div>
  );
};
