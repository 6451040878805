import React from 'react';
import classNames from 'classnames';

interface ShowFormulaHelpInterface {
  showColumnNameField?: boolean;
  onClick: () => void;
}

/** Displays "Show Help" text and toggles the store setting to reflect user's choice **/
export const ShowFormulaHelp: React.FunctionComponent<ShowFormulaHelpInterface> = ({
  showColumnNameField = false,
  onClick,
}) => {
  return (
    <div className={classNames('flexColumnContainer', showColumnNameField ? 'mb15' : '')}>
      <span className="text-underline ml15 cursorPointer sq-text-primary nowrap" onClick={onClick}>
        Show Help
      </span>
    </div>
  );
};
