// @ts-strict-ignore
import _ from 'lodash';
import {
  PROFILE_SEARCH_NORMALIZE_AMPLITUDE,
  PROFILE_SEARCH_NORMALIZE_LOCATION,
  PROFILE_SEARCH_SIMILARITY,
} from '@/trendData/trendData.constants';
import { BaseToolStore, ParameterDefinitions } from '@/hybrid/toolSelection/baseTool.store';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { BASE_TOOL_COMMON_PROPS } from '@/hybrid/toolSelection/baseTool.constants';
import { InitializeMode } from '@/core/flux.service';

export class ProfileSearchStore extends BaseToolStore {
  static readonly storeName = 'sqProfileSearchStore';
  type = TREND_TOOLS.PROFILE_SEARCH;
  parameterDefinitions: ParameterDefinitions = {
    inputSignal: { predicate: ['name', 'a'] },
  };

  initialize(initializeMode: InitializeMode) {
    const saveState = this.state && initializeMode !== 'FORCE';
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, {
        ...this.parameterDefinitions,
        similarity: PROFILE_SEARCH_SIMILARITY,
        normalizeAmplitude: PROFILE_SEARCH_NORMALIZE_AMPLITUDE,
        normalizeLocation: PROFILE_SEARCH_NORMALIZE_LOCATION,
        selectedRegion: saveState ? this.state.get('selectedRegion') : {},
      }),
    );
  }

  /**
   * Exports the state that is not ephemeral (such as progress indicators).
   * @returns {Object} The dehydrated state.
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Re-creates the profile search results.
   *
   * @param {Object} dehydratedState Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  get inputSignal() {
    return this.state.get('inputSignal');
  }

  get selectedRegion() {
    return this.state.get('selectedRegion');
  }

  get similarity() {
    return this.state.get('similarity');
  }

  get normalizeAmplitude() {
    return this.state.get('normalizeAmplitude');
  }

  get normalizeLocation() {
    return this.state.get('normalizeLocation');
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    /**
     * Sets the similarity for the profile search.
     * @param {Object} payload - Object containing state information
     * @param {String} payload.similarity - the similarity for the profile search.
     */
    PROFILE_SEARCH_SET_SIMILARITY: (payload) => {
      this.state.set('similarity', payload.similarity);
    },
    /**
     * Ignore amplitude of candidates in the profile search.
     * @param {Object} payload - Object containing state information
     * @param {Boolean} payload.normalizeAmplitude - normalize amplitude for profile search.
     */
    PROFILE_SEARCH_SET_NORMALIZE_AMPLITUDE: (payload) => {
      this.state.set('normalizeAmplitude', payload.normalizeAmplitude);
    },
    /**
     * Ignore location of candidates in the profile search.
     * @param {Object} payload - Object containing state information
     * @param {Boolean} payload.normalizeLocation - normalize location for profile search.
     */
    PROFILE_SEARCH_SET_NORMALIZE_LOCATION: (payload) => {
      this.state.set('normalizeLocation', payload.normalizeLocation);
    },
    /**
     * Sets the selected region
     * @param {Object} payload - Object containing state information
     * @param {Number} payload.min - Lower x-value of selected region.
     * @param {Number} payload.max - Upper x-value of selected region.
     */
    TREND_SET_SELECTED_REGION: (payload) => {
      this.state.set('selectedRegion', payload);
    },
  };
}
