// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Icon } from '../core/Icon.atom';
import { ReportConfigMeta } from '@/hybrid/reportConfig/components/meta/ReportConfigMeta.molecule';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ReportConfigDates } from '@/hybrid/reportConfig/components/dateRanges/ReportConfigDates.molecule';
import { ReportConfigBackups } from '@/hybrid/reportConfig/components/backups/ReportConfigBackups.molecule';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';

import { CkMigrationModal } from '@/hybrid/annotation/CKMigrationModal.molecule';
import { useTranslation } from 'react-i18next';
import { ReportConfigAssets } from '@/hybrid/reportConfig/components/assetSelection/ReportConfigAssets.molecule';
import { ReportActions } from '@/reportEditor/report.actions';
import { Alert } from 'react-bootstrap';
import _ from 'lodash';
import { sqReportStore, sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import utilities from '@/hybrid/core/utilities';
import { FormulaService } from '@/services/formula.service';
import { sandboxUrlParams } from '@/hybrid/reportConfig/components/sandboxUrlParams.util';
import { infoToast } from '@/hybrid/utilities/toast.utilities';

const reportConfigBindings = bindingsDefinition({
  viewOnlyMode: prop.optional<boolean>(),
  isCkAutoUpgradeEnabled: prop<boolean>(),
  sqReportEditor: injected<ReportEditorService>(),
  sqReportActions: injected<ReportActions>(),
  sqFormula: injected<FormulaService>(),
});

export const ReportConfig: SeeqComponent<typeof reportConfigBindings> = ({
  viewOnlyMode = false,
  isCkAutoUpgradeEnabled,
}) => {
  const { sqReportEditor, sqReportActions, sqFormula } = useInjectedBindings(reportConfigBindings);

  const { t } = useTranslation();
  const originalData = useRef({ assetSelections: [], dateRanges: [] });

  /**
   * this useEffect checks if there's any url params that should activate the sandbox mode
   * ReportConfig → switched to sandbox mode if any parms are there (dates and/or assets)
   * ReportConfigAssets → saves initial values and sends to each assetSelection any override value
   * ReportConfigAssetSelection → applies the override value if sandbox is enabled
   * ReportConfigDates → saves initial values and applies overrides if sandbox is enabled
   */
  useEffect(() => {
    // store original data in order to be able to match the url params
    originalData.current = {
      assetSelections: sqReportStore.assetSelectionsNotArchived,
      dateRanges: sqReportStore.dateRangesNotArchived,
    };
    const sandboxUrlParamsInstance = sandboxUrlParams(originalData.current, {
      utilities,
      sqReportActions,
      sqFormula,
    });
    if (
      viewOnlyMode &&
      (_.isArray(params.dateRangeStart) || _.isArray(params.dateRangeEnd) || _.isArray(params.assetSelection))
    ) {
      sqReportActions.doActionElseActivateSandbox(
        _.noop,
        _.noop,
        _.noop,
        sandboxUrlParamsInstance.applyUrlParamsToData(params),
      )();
    }
  }, []);

  const [showMigrateModal, setShowMigrateModal] = useState(false);

  // force the props to be updated so we can get the new name
  useFluxPath(sqWorkbookStore, () => sqWorkbookStore.worksheets);
  useFluxPath(sqReportStore, () => sqReportStore.contentErrors);
  // we're only displaying some elements IF ckEditor is enabled, but we need to know when this changes state
  useFluxPath(sqReportStore, () => sqReportStore.isCkEnabled);

  const { sandboxOriginalCreatorName, contentErrors } = sqReportStore;
  const params = sqWorkbenchStore.stateParams;
  const worksheetName: string = sqWorkbookStore.getWorksheetName(params.worksheetId);
  const isCKEditor = sqReportEditor.isCkEditor();
  const toCk = !isCKEditor;
  const canUpgrade = sqReportEditor.canUpgradeToCk() && !isCkAutoUpgradeEnabled;
  const canRevertToFroala = useFluxPath(sqReportStore, () => sqReportStore.canRevertToFroala);

  useEffect(() => {
    if (canUpgrade && toCk) {
      infoToast({
        messageKey: 'REPORT.EDITOR.MIGRATION.TO_CK.NOTICE',
        buttonLabelKey: 'REPORT.EDITOR.MIGRATION.TO_CK.BUTTON',
        buttonAction: () => setShowMigrateModal(true),
        buttonIcon: null,
      });
    }
  }, []);

  const viewOnlyModeConfig = (
    <div className="card-body">
      {!!contentErrors.length && (
        <Alert data-testid="specPermissionError" className="sq-alert-danger mb15">
          <p>
            {t('REPORT.CONFIG.CONTENT_FAILED_TO_LOAD_1', {
              number: contentErrors.length,
              name: sandboxOriginalCreatorName,
            })}
          </p>
          <p>
            {t('REPORT.CONFIG.CONTENT_FAILED_TO_LOAD_2')}
            <a href="#" onClick={sqReportActions.exitSandboxMode}>
              {t('REPORT.CONFIG.CONTENT_FAILED_TO_LOAD_3')}
            </a>
            {t('REPORT.CONFIG.CONTENT_FAILED_TO_LOAD_4')}
          </p>
        </Alert>
      )}
      {isCKEditor && <ReportConfigAssets viewOnlyMode={viewOnlyMode} />}
      <ReportConfigDates />
    </div>
  );

  const editModeConfig = (
    <div className="card-body">
      <h4 className="flexColumnContainer">
        <Icon large={true} icon="fc-journal" extraClassNames="pr10" />
        <span title={worksheetName} className="flexFill truncatedLabel">
          {worksheetName}
        </span>
      </h4>
      {canUpgrade && toCk && (
        <TextButton
          extraClassNames="width-150 flexSelfCenter sq-btn-organizer-migration"
          label={t('REPORT.EDITOR.MIGRATION.TO_CK.BUTTON')}
          size="sm"
          variant="outline"
          onClick={() => setShowMigrateModal(true)}
        />
      )}
      <div className="flexRowContainer">
        <ReportConfigMeta isCKEditor={isCKEditor} />
        {isCKEditor && <ReportConfigAssets viewOnlyMode={viewOnlyMode} />}
        <ReportConfigDates />
        <ReportConfigBackups />
        {!toCk && canRevertToFroala && (
          <a className="mt10" href="#" onClick={() => setShowMigrateModal(true)}>
            {t('REPORT.EDITOR.MIGRATION.TO_FROALA.BUTTON')}
          </a>
        )}
      </div>
      {showMigrateModal && (
        <CkMigrationModal
          annotationId={sqReportStore.id}
          onClose={() => setShowMigrateModal(false)}
          ckDisabled={!canUpgrade}
          toCk={toCk}
        />
      )}
    </div>
  );

  return (
    <div data-testid="reportConfig" className="reportConfig flexRowContainer card card-primary">
      {viewOnlyMode && viewOnlyModeConfig}
      {!viewOnlyMode && editModeConfig}
    </div>
  );
};

/**
 * Export it for usage in AngularJS
 */
export const sqReportConfig = angularComponent(reportConfigBindings, ReportConfig);
