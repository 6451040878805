// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import {
  branchWorksheet as branchWorksheetAction,
  restoreWorksheet as restoreWorksheetAction,
  archiveWorksheet as archiveWorksheetAction,
  renameWorksheet,
} from '@/workbook/workbook.actions';
import { Icon } from '@/hybrid/core/Icon.atom';

import { WorksheetThumbnail } from '@/hybrid/worksheets/WorksheetThumbnail.atom';
import { WorksheetWatchers } from '@/hybrid/worksheets/WorksheetWatchers.atom';
import { Dropdown } from 'react-bootstrap';
import { EditableText } from '@/hybrid/core/EditableText.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { ButtonWithPopover } from '@/hybrid/core/ButtonWithPopover.molecule';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';
import { sqWorkbookStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';

const worksheetPaneTileBindings = bindingsDefinition({
  worksheet: prop<{ worksheetId: string; updatedAt: string; name: string }>(),
  workbookId: prop<string>(),
  isActive: prop<boolean>(),
  index: prop<number>(),
  gotoWorksheet: prop<(worksheetId: string, viewOnly?: boolean) => any>(),
  $state: injected<ng.ui.IStateService>(),
});

export const WorksheetPaneTile: SeeqComponent<typeof worksheetPaneTileBindings> = (props) => {
  const { worksheet, isActive, workbookId, gotoWorksheet, index } = props;

  const { $state } = useInjectedBindings(worksheetPaneTileBindings);

  const { t } = useTranslation();
  const [forceEdit, setForceEdit] = useState(false);
  const isReportBinder = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.isReportBinder);
  const worksheetId = worksheet.worksheetId;

  const branchWorksheet = (worksheetId) => {
    doTrack(isReportBinder ? 'Topic' : 'Analysis', 'Worksheet copied');

    const options = isReportBinder ? { prefix: t('ITEM_TYPES.DOCUMENT') } : {};
    return branchWorksheetAction(workbookId, worksheetId, options, $state);
  };

  const archiveWorksheet = (worksheetId) => {
    const worksheetName = sqWorkbookStore.getWorksheetName(worksheetId);
    const worksheetIndex = sqWorkbookStore.getWorksheetIndex(worksheetId);

    const action = () => {
      return restoreWorksheetAction(workbookId, worksheetId, worksheetIndex, $state)
        .then(() => gotoWorksheet(worksheetId))
        .catch((error) => {
          errorToast({ httpResponseOrError: error });
        });
    };

    doTrack(isReportBinder ? 'Topic' : 'Analysis', 'Worksheet deleted');
    return archiveWorksheetAction(workbookId, worksheetId, $state)
      .then(() =>
        successToast({
          messageKey: 'WORKSHEET_DELETE_CONFIRMATION',
          messageParams: { WORKSHEET_NAME: worksheetName },
          toastUUID: `WORKSHEET_DELETE_${workbookId}_${worksheetId}`,
          buttonId: 'restoreWorksheetBtn',
          buttonLabelKey: 'RESTORE',
          buttonAction: action,
        }),
      )
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  };

  const worksheetMenuOptions = [
    {
      icon: 'fa-i-cursor',
      action: setForceEdit,
      label: 'RENAME',
    },
    {
      icon: 'fa-copy',
      action: branchWorksheet,
      label: 'DUPLICATE',
    },
    {
      divider: true,
    },
    {
      icon: 'fa-remove',
      action: archiveWorksheet,
      label: 'TRASH.TRASH',
    },
  ];

  const renderDropdownOption = (option, worksheetId) => {
    if (option.divider) {
      return <Dropdown.Divider key={`divider_${worksheetId}`} />;
    }

    const actionParameter = option.label === 'RENAME' ? true : worksheetId;

    return (
      <Dropdown.Item
        onClick={() => option.action(actionParameter)}
        className="sq-force-text-gray"
        key={`${option.icon}_${worksheetId}`}>
        <Icon icon={option.icon} extraClassNames="fa-fw" type="inherit" />
        <span className="forceFont ml5">{t(option.label)}</span>
      </Dropdown.Item>
    );
  };

  return (
    <div id={worksheetId} className="worksheetContainer" data-testid="worksheetContainer">
      {isActive && <div className="activeWorksheetIndicator" data-testid="activeIndicator" />}
      <div className="worksheetSlide" id={`thumb_${index}`}>
        <div
          onClick={() => gotoWorksheet(worksheetId, false)}
          data-testid={`${worksheetId}_thumbnailContainer`}
          className="cursorPointer">
          <WorksheetThumbnail
            workbookId={workbookId}
            worksheetId={worksheetId}
            isActive={isActive}
            updatedAt={worksheet.updatedAt}
          />
        </div>
        <div className="worksheetWell flexColumnContainer flexSpaceBetween">
          <EditableText
            id={`worksheetLabel_${index}`}
            testId={`worksheetLabel_${index}`}
            value={worksheet.name}
            textClasses="flexFill breakWord"
            inputClasses="flexFill mb7"
            forceEdit={forceEdit}
            onUpdate={(newName) => {
              setForceEdit(false);
              return renameWorksheet(worksheetId, newName);
            }}
          />
          <WorksheetWatchers classes="cursorPointer text-opaque-onhover" worksheetId={worksheetId} compactMode={true} />
          <ButtonWithPopover
            label={
              <div className="text-opaque-onhover specWorksheetDropdownTrigger">
                <Icon icon="fa-chevron-down" type="white" extraClassNames="pt3 pl1 pr1" testId="worksheetOptionsMenu" />
              </div>
            }
            popoverConfig={{
              id: `worksheetOptionsMenu_${index}`,
              placement: 'bottom-end',
              wrapperClassNames: 'min-width-0',
            }}>
            {_.map(worksheetMenuOptions, (option) => renderDropdownOption(option, worksheetId))}
          </ButtonWithPopover>
        </div>
      </div>
    </div>
  );
};
