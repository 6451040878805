// @ts-strict-ignore
import mixpanel from 'mixpanel-browser';
import { SEEQ_VERSION } from '@/services/buildConstants.service';
import { TrackerService } from '@/track/tracker.service';
import _ from 'lodash';

interface MixpanelTrackingProps {
  companyName: string;
  serverEmail: string;
  userEmail: string;
  userName: string;
  contractNumber: string;
  language: string;
  darkMode: boolean;
}

export default class MixpanelTrackingService extends TrackerService {
  private static serviceInstance?: MixpanelTrackingService;
  dataToIncludeWithEveryEvent = {
    companyName: '',
    serverEmail: '',
    userName: '',
    userEmail: '',
    contractNumber: '',
    seeqVersion: '',
    language: '',
    darkMode: false,
  };

  constructor({
    companyName,
    serverEmail,
    userName,
    userEmail,
    contractNumber,
    language,
    darkMode,
  }: MixpanelTrackingProps) {
    super();
    if (MixpanelTrackingService.serviceInstance) {
      return MixpanelTrackingService.serviceInstance;
    }
    mixpanel.init('40ec369f3886f9d8b7bfee0b048d2494', {
      api_host: 'https://telemetry.seeq.com/mixpanel',
    });

    this.dataToIncludeWithEveryEvent = {
      serverEmail,
      userName,
      userEmail,
      contractNumber,
      companyName,
      seeqVersion: SEEQ_VERSION,
      language,
      darkMode,
    };

    mixpanel.identify(userEmail);
    mixpanel.people.set(this.dataToIncludeWithEveryEvent);
    MixpanelTrackingService.serviceInstance = this;
  }

  trackEvent(category, action, information, uniqueEventId, language: string) {
    const trackInfo = { action, uniqueEventId, language, darkMode: false };
    let parsedInformation = information;
    try {
      parsedInformation = JSON.parse(information);
    } catch (e) {} // ignore, strings are perfectly valid;
    if (_.isObject(parsedInformation)) {
      _.assign(trackInfo, parsedInformation);
    } else {
      _.assign(trackInfo, { information });
    }

    mixpanel.track(category, trackInfo);
  }

  static reset() {
    delete MixpanelTrackingService.serviceInstance;
  }
}
