// @ts-strict-ignore
import React, { useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { logError } from '@/hybrid/utilities/logger';
import { sqHomeScreenStore } from '@/core/core.stores';
import { HelpDisplay } from '@/hybrid/homescreen/homescreen.constants';
import { customSidebar, officeHoursEnabled } from '@/services/systemConfiguration.utilities';
import { Announcements } from '@/getFeedback/Announcements.atom';

const homeScreenRightPanelBindings = bindingsDefinition({
  sqHomeScreenActions: injected<HomeScreenActions>(),
});

export const HomeScreenRightPanel: SeeqComponent<typeof homeScreenRightPanelBindings> = () => {
  const { sqHomeScreenActions } = useInjectedBindings(homeScreenRightPanelBindings);

  const { t } = useTranslation();

  const trainingTarget = useRef(null);
  const showTrainingPopover = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.helpDisplay === 'POPOVER');

  const trainingOverlay = (
    <Overlay placement="left" show={true} target={trainingTarget.current} transition={false}>
      <Popover id="trainingPopover">
        <Popover.Content className="flexRowContainer flexAlignCenter pb20">
          <div className="picker-close" onClick={() => sqHomeScreenActions.setHelpDisplay(HelpDisplay.NONE)}>
            <Icon icon="fa-close" extraClassNames="cursorPointer" />
          </div>
          <div className="fs15 m20">{t('HOME_SCREEN.RESUME_TRAINING')}</div>
          <TextButton
            label="HOME_SCREEN.GOT_IT"
            testId="homeScreenGotIt"
            variant="theme"
            onClick={() => sqHomeScreenActions.setHelpDisplay(HelpDisplay.NONE)}
          />
        </Popover.Content>
      </Popover>
    </Overlay>
  );

  return (
    <div>
      {showTrainingPopover && trainingOverlay}
      <div className="homeScreenNotification flexRowContainer">
        <h3>{t('HOME_SCREEN.HELPFUL_LINKS')}</h3>
        <div className="mb5 flexFill">
          <a
            href="https://telemetry.seeq.com/training"
            target="_blank"
            className="flexColumnContainer p5 link-no-underline homeScreenHelpfulLink">
            <Icon icon="fc-certificate" extraClassNames="mr10 fa-fw" />
            {t('HOME_SCREEN.ONLINE_CERTIFICATION')}
          </a>
        </div>
        <div className="mb5 flexFill">
          <a
            href="https://telemetry.seeq.com/support-link/wiki/spaces/KB/overview"
            target="_blank"
            className="flexColumnContainer p5 link-no-underline homeScreenHelpfulLink">
            <Icon icon="fc-knowledge-base" extraClassNames="mr10 fa-fw" />
            {t('KNOWLEDGE_BASE')}
          </a>
        </div>
        <div className="mb5 flexFill">
          <a
            href="https://telemetry.seeq.com/community"
            target="_blank"
            className="flexColumnContainer p5 link-no-underline homeScreenHelpfulLink">
            <Icon icon="fc-user-community" extraClassNames="mr10 fa-fw" />
            {t('HOME_SCREEN.USER_COMMUNITY')}
          </a>
        </div>
        <div
          className={classNames('mb5 flexFill', {
            noCoverInBlackout: showTrainingPopover,
          })}
          ref={trainingTarget}>
          <a
            href="https://telemetry.seeq.com/interactive-training"
            target="_blank"
            className="flexColumnContainer p5 link-no-underline homeScreenHelpfulLink">
            <Icon icon="fc-training" extraClassNames="mr10 fa-fw" />
            {t('HOME_SCREEN.INTERACTIVE_TRAINING')}
          </a>
        </div>
        {officeHoursEnabled() && (
          <div className="mb5 flexFill">
            <a
              href="https://telemetry.seeq.com/office-hours"
              target="_blank"
              className="flexColumnContainer p5 link-no-underline homeScreenHelpfulLink">
              <Icon icon="fc-support" extraClassNames="mr10 fa-fw" />
              {t('HOME_SCREEN.OFFICE_HOURS')}
            </a>
          </div>
        )}
      </div>
      <Announcements />
      {customSidebar() && (
        <div className="homeScreenNotification mt10 flexRowContainer overflowYScroll" data-testid="customSidebar">
          {cheapMarkdown(customSidebar())}
        </div>
      )}
    </div>
  );
};

function cheapMarkdown(s) {
  try {
    return _.chain(s)
      .split('\n')
      .map((s) => {
        let m = /^(#+)(.*)/.exec(s);
        if (m) {
          const [, level, text] = m;
          return React.createElement(`h${level.length + 2}`, {}, text);
        }

        m = /- \[(.*)\]\((http[^)]+)\)/.exec(s);
        if (m) {
          const [, text, href] = m;
          return (
            <div>
              <a href={href} target="_blank">
                {text}
              </a>
            </div>
          );
        }

        m = /- (http[^)]+)/.exec(s);
        if (m) {
          const href = m[1];
          return (
            <div>
              <a href={href} target="_blank">
                {href}
              </a>
            </div>
          );
        }
        return undefined;
      })
      .map((element, index) => (
        <div key={index} className="mb5">
          {element}
        </div>
      ))
      .value();
  } catch (ex) {
    logError(ex);
  }
}
