// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { QUARTZ_CRON_PENDING_INPUT } from '@/reportEditor/report.constants';
import { sqReportStore } from '@/core/core.stores';
import { quartzCronExpressionHelper } from '@/hybrid/utilities/froalaReportContent.utilities';

interface DateRangeIconProps {
  dateRange: any;
  includeTooltip?: boolean;
  tooltipDelay?: number;
  extraClassNames?: string;
}

export const DateRangeIcon: React.FunctionComponent<DateRangeIconProps> = (props) => {
  const { dateRange, includeTooltip = false, tooltipDelay = 500, extraClassNames } = props;
  const { t } = useTranslation();

  const reportSchedule = useFluxPath(sqReportStore, () => sqReportStore.reportSchedule);
  const hasReportSchedule = useFluxPath(sqReportStore, () => sqReportStore.hasReportSchedule);
  const hasDateSchedule =
    !!dateRange?.auto?.cronSchedule && !_.isEqual(dateRange?.auto?.cronSchedule, [QUARTZ_CRON_PENDING_INPUT]);
  const cronHelper = quartzCronExpressionHelper();

  if (!dateRange?.auto?.enabled) return null;

  const effectiveSchedule = hasReportSchedule ? reportSchedule?.cronSchedule : dateRange?.auto?.cronSchedule;
  const rate = cronHelper.cronScheduleToRate(effectiveSchedule);
  const hasRate = !!rate?.value;

  const isLive =
    (hasReportSchedule && !reportSchedule.background) || (!hasReportSchedule && !dateRange?.auto?.background);
  const isScheduled =
    (hasReportSchedule && reportSchedule.background) || (!hasReportSchedule && dateRange?.auto?.background);
  const isError = !hasReportSchedule && !hasDateSchedule && !sqReportStore.shouldShowConfigureAutoUpdateModal;

  let icon;
  let classes;
  let testId;

  if (isError) {
    icon = 'fa-calendar-times-o';
    classes = 'verticalAlignBottom error-text';
    testId = 'autoEnabledIconError';
  } else if (isLive) {
    icon = 'fa fc-refresh-on';
    classes = 'verticalAlignMiddle';
    testId = 'autoEnabledIconLive';
  } else {
    icon = 'fa-calendar-check-o';
    classes = 'verticalAlignBottom';
    testId = 'autoEnabledIconScheduled';
  }

  let tooltip;
  if (!includeTooltip) {
    tooltip = '';
  } else if (!hasReportSchedule && !hasDateSchedule) {
    tooltip = 'REPORT.CONFIG.AUTO_UPDATE_NOT_CONFIGURED';
  } else if (isScheduled) {
    tooltip = 'REPORT.CONFIG.AUTO_UPDATE_CONFIGURED';
  } else if (isLive && hasRate) {
    tooltip = t('REPORT.CONFIG.LIVE_RATE', { rate: rate.value + rate.units });
  } else if (isLive && !hasRate) {
    tooltip = t('REPORT.CONFIG.SCHEDULE', { schedule: effectiveSchedule });
  }

  return (
    <span>
      <Icon
        type="inherit"
        icon={icon}
        tooltip={tooltip}
        tooltipDelay={tooltipDelay}
        extraClassNames={classNames(classes, extraClassNames)}
        testId={testId}
      />
    </span>
  );
};
