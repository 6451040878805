// @ts-strict-ignore
import React, { useState } from 'react';
import { ConfigureAutoUpdateModal } from '@/hybrid/reportConfig/components/dateRanges/autoUpdateModal/ConfigureAutoUpdateModal.organism';
import { useTranslation } from 'react-i18next';
import { NextScheduledUpdate } from '@/hybrid/reportConfig/components/dateRanges/autoUpdateModal/components/NextScheduledUpdate.molecule';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';
import { sqReportStore } from '@/core/core.stores';

interface AutoUpdateSectionProps {
  timezone: string;
  viewOnly?: boolean;
}

export const AutoUpdateSection: React.FunctionComponent<AutoUpdateSectionProps> = (props) => {
  const { timezone, viewOnly = false } = props;

  const [showAutoUpdateModal, setShowAutoUpdateModal] = useState(false);

  const { t } = useTranslation();

  const hasReportSchedule = useFluxPath(sqReportStore, () => sqReportStore.hasReportSchedule);
  const hasMultipleSchedules = useFluxPath(sqReportStore, () => sqReportStore.hasMultipleSchedules);
  const isScheduleEnabled = useFluxPath(sqReportStore, () => sqReportStore.isScheduleEnabled);

  const viewOnlyAutoUpdateSection = (
    <div className="flexRowContainer pt10 pb10 pl3">
      <NextScheduledUpdate timezone={timezone} onClick={_.noop} viewOnly={viewOnly} />
    </div>
  );

  const editModeAutoUpdateSection = (
    <div className="flexRowContainer pt10 pb10 pl3">
      {showAutoUpdateModal && (
        <ConfigureAutoUpdateModal
          schedule={sqReportStore.reportSchedule}
          onClose={() => setShowAutoUpdateModal(false)}
        />
      )}

      {!(sqReportStore.hasAutoDateRanges && hasReportSchedule && isScheduleEnabled) && (
        <div className="flexColumnContainer mt5 mr5">
          <label htmlFor="autoUpdateSection" className="flexFill">
            {t('REPORT.MODAL.AUTO_UPDATE.HEADER')}
          </label>
          <a
            href="#"
            data-testid="openConfigureAutoUpdateModalButton"
            className="flexColumnContainer link-no-focus link-no-underline"
            onClick={() => setShowAutoUpdateModal(true)}>
            <Icon
              icon={classNames('fa-edit', !hasReportSchedule ? 'error-text' : '')}
              extraClassNames="fa-fw mr3 mt2"
              testId={`editAutoConfig${!hasReportSchedule ? 'Error' : ''}`}
            />
          </a>
        </div>
      )}
      {!isScheduleEnabled && <div className="mr5">{t('REPORT.CONFIG.AUTO_UPDATE_SCHEDULE_DISABLED')}</div>}
      {!hasReportSchedule && <div className="mr5">{t('REPORT.CONFIG.AUTO_UPDATE_SCHEDULE_NOT_CONFIGURED')}</div>}
      {sqReportStore.hasAutoDateRanges && hasReportSchedule && isScheduleEnabled && (
        <div>
          <NextScheduledUpdate timezone={timezone} onClick={() => setShowAutoUpdateModal(true)} />
        </div>
      )}
      {hasMultipleSchedules && <div className="mr5">{t('REPORT.CONFIG.AUTO_UPDATE_SCHEDULE_MULTIPLE_SCHEDULES')}</div>}
    </div>
  );

  return viewOnly ? viewOnlyAutoUpdateSection : editModeAutoUpdateSection;
};
