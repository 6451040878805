// @ts-strict-ignore
import React from 'react';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { getAllItems } from '@/hybrid/trend/trendDataHelper.utilities';
import _ from 'lodash';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { TrendActions } from '@/trendData/trend.actions';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { Placement } from 'react-bootstrap/Overlay';
import { IPromise } from 'angular';
import classNames from 'classnames';
import {
  sqTrendScalarStore,
  sqTrendMetricStore,
  sqTrendSeriesStore,
  sqTrendTableStore,
  sqTrendCapsuleSetStore,
} from '@/core/core.stores';

const addToDisplayPaneBindings = bindingsDefinition({
  itemId: prop<string>(),
  tooltip: prop.optional<string>(),
  extraClassNames: prop.optional<string>(),
  tooltipPlacement: prop.optional<Placement>(),
  afterAdd: prop.optional<(itemId: string) => Promise<void> | IPromise<void> | void>(),
  sqTrendActions: injected<TrendActions>(),
});

export const AddToDisplayPane: SeeqComponent<typeof addToDisplayPaneBindings> = (props) => {
  const { itemId, tooltip, extraClassNames, tooltipPlacement, afterAdd } = props;
  const { sqTrendActions } = useInjectedBindings(addToDisplayPaneBindings);

  // getAllItems uses these stores to source items, so we must respond to changes in those stores
  useFlux(sqTrendSeriesStore);
  useFlux(sqTrendScalarStore);
  useFlux(sqTrendCapsuleSetStore);
  useFlux(sqTrendTableStore);
  useFlux(sqTrendMetricStore);

  const trendItems = getAllItems({});

  if (_.some(trendItems, { id: itemId })) {
    return null;
  }

  const addToDisplay = (event) => {
    sqItemsApi
      .getItemAndAllProperties({ id: itemId })
      .then(({ data: item }) => sqTrendActions.addItem(item))
      .then(() => (afterAdd ? afterAdd(itemId) : undefined));
  };

  return (
    <HoverTooltip placement={tooltipPlacement || 'right'} text={tooltip || 'ADD_TO_DISPLAY'}>
      <a
        data-testid="addToDisplay"
        className={classNames(
          'displayIcon a fa fa-area-chart link-no-underline animated animate-if-opacity pt2 pl5 force-link-color',
          extraClassNames,
        )}
        onClick={addToDisplay}
      />
    </HoverTooltip>
  );
};

export const sqAddToDisplayPane = angularComponent(addToDisplayPaneBindings, AddToDisplayPane);
