// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { AssetSelectionLabel } from './AssetSelectionLabel.molecule';
import { Form, FormControl, Overlay, Popover } from 'react-bootstrap';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';

import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormulaService } from '@/services/formula.service';
import { calculateOptimalTooltipPosition } from '@/hybrid/core/calculateOptimalTooltipPosition';
import { Placement } from 'react-bootstrap/esm/Overlay';
import { ReportActions } from '@/reportEditor/report.actions';
import { base64guid } from '@/hybrid/utilities/utilities';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { sqReportStore } from '@/core/core.stores';

const editAssetSelectionLabelBindings = bindingsDefinition({
  assetSelectionId: prop<string>(),
  updateId: prop.optional<(id: string) => void>(),
  updateDepthLevel: prop.optional<(id: number) => void>(),
  viewMode: prop.optional<boolean>(),
  sqReportActions: injected<ReportActions>(),
  sqFormula: injected<FormulaService>(),
  sqReportContent: injected<ReportContentService>(),
  pathLevel: prop.optional<number>(),
});

/**
 *
 * @param assetSelectionId - the asset selection id
 * @param pathLevel - asset selection depth path level
 * @param updateId - function that updates html element id attribute
 * @param updateDepthLevel - function that updates html element depth level attribute
 */
export const EditAssetSelectionLabel: SeeqComponent<typeof editAssetSelectionLabelBindings> = ({
  viewMode,
  assetSelectionId,
  updateId,
  updateDepthLevel,
  pathLevel,
}) => {
  const { sqFormula, sqReportActions, sqReportContent } = useInjectedBindings(editAssetSelectionLabelBindings);
  const { t } = useTranslation();

  const [showMenu, setShowMenu] = useState(false);
  const [selectedDepth, setSelectedDepth] = useState(pathLevel || 1);
  const [assetPath, setAssetPath] = useState(null);
  const [placement, setPlacement] = useState<Placement>('right');
  const popoverRef = useRef(null);
  const assetSelectionsNotArchived = useFluxPath(sqReportStore, () => sqReportStore.assetSelectionsNotArchived);
  const [ownId, setOwnId] = useState('');
  const target = useRef(null);
  const assetSelection = _.find(assetSelectionsNotArchived, ['id', assetSelectionId]);

  useEffect(() => {
    setOwnId(base64guid());
    if (!assetSelectionId && assetSelectionsNotArchived.length) {
      updateId(assetSelectionsNotArchived[0].id);
    } else if (!assetSelection && assetSelectionId) {
      sqReportActions.fetchAssetSelection(assetSelectionId, false).then(({ assetSelection }) => {
        if (viewMode) {
          updateId(sqReportStore.findSimilarAssetSelection(assetSelection)?.id ?? assetSelectionId);
        } else {
          return sqReportContent.copyAssetSelectionForPendingContent(assetSelection, Promise).then(updateId);
        }
      });
    }
  }, []);

  useEffect(() => {
    findAndSetAssetPath(pathLevel);
  }, [assetSelection]);

  useEffect(() => {
    if (popoverRef.current) {
      setPlacement(calculateOptimalTooltipPosition(target.current, popoverRef.current));
    }
  }, [popoverRef?.current]);

  const onChangePathDepth = (event) => {
    const pathLevel = event.target.value === '' ? undefined : _.toNumber(event.target.value);
    setSelectedDepth(pathLevel);
    findAndSetAssetPath(pathLevel);
    updateDepthLevel(pathLevel);
  };

  const findAndSetAssetPath = (pathLevel) =>
    setAssetPath(
      assetSelection
        ? sqFormula.getAssetPathFromAncestors(
            assetSelection.asset.ancestors.concat({
              name: assetSelection.asset.name,
            }),
            pathLevel || selectedDepth,
          )
        : '',
    );

  return (
    <>
      {!viewMode && (
        <Overlay
          transition={false}
          placement={placement}
          target={target.current}
          rootClose={true}
          show={showMenu}
          onHide={() => setShowMenu(false)}>
          <Popover id={`assetSelection-${ownId}`} className="min-width-200">
            <div ref={popoverRef}>
              <Popover.Title>{t('REPORT.DATE_RANGE_LABEL.CONFIGURATION')}</Popover.Title>
              <Popover.Content className="ml10 mr10">
                <Form.Label>{t('REPORT.DATE_RANGE_LABEL.SELECT_ASSET_SELECTION')}</Form.Label>
                <Select
                  value={
                    assetSelection
                      ? {
                          value: assetSelection?.id,
                          label: assetSelection.name,
                        }
                      : null
                  }
                  options={_.map(assetSelectionsNotArchived, (item) => ({
                    value: item?.id,
                    label: item.name,
                  }))}
                  onChange={(selected) => updateId(selected.value)}
                  classNamePrefix="react-select"
                />

                <div className="forceFlex flexAlignCenter pl2 mt10">
                  <label className="nowrap" htmlFor="assetSelectionPathLevelInput">
                    {t('REPORT.ASSET_SELECTION_PATH_LEVEL')}
                  </label>
                  <FormControl
                    className="max-width-70 ml25"
                    id="assetSelectionPathLevelInput"
                    name="value"
                    type="number"
                    value={selectedDepth}
                    onChange={onChangePathDepth}
                    min={1}
                  />
                </div>
              </Popover.Content>
            </div>
          </Popover>
        </Overlay>
      )}
      <AssetSelectionLabel onClick={() => setShowMenu(true)} targetRef={target} assetName={assetPath} />
    </>
  );
};
