// @ts-strict-ignore
import _ from 'lodash';
import { determineIdealUnits, getDurationTimeUnit } from '@/hybrid/datetime/dateTime.utilities';
import { LOW_PASS_FILTER_CUTOFF } from '@/hybrid/toolSelection/investigate.constants';
import { DURATION_TIME_UNITS } from '@/main/app.constants';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { BaseToolStore } from '@/hybrid/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/hybrid/toolSelection/baseTool.constants';

export class FftStore extends BaseToolStore {
  type = TREND_TOOLS.FFT_TABLE;
  static readonly storeName = 'sqFftStore';
  parameterDefinitions = {
    signalToAggregate: { predicate: ['name', 'signalToAggregate'] },
  };

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, this.parameterDefinitions, {
        rateType: true,
        rate: LOW_PASS_FILTER_CUTOFF,
        outputUnits: getDurationTimeUnit('min', DURATION_TIME_UNITS).unit[0],
        highPass: LOW_PASS_FILTER_CUTOFF,
        lowPass: LOW_PASS_FILTER_CUTOFF,
        useHighPass: false,
        useLowPass: false,
      }),
    );
  }

  get signalToAggregate() {
    return this.state.get('signalToAggregate');
  }

  get rateType() {
    return this.state.get('rateType');
  }

  get rate() {
    return this.state.get('rate');
  }

  get outputUnits() {
    return this.state.get('outputUnits');
  }

  get highPass() {
    return this.state.get('highPass');
  }

  get lowPass() {
    return this.state.get('lowPass');
  }

  get useHighPass() {
    return this.state.get('useHighPass');
  }

  get useLowPass() {
    return this.state.get('useLowPass');
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Sets the fft panel state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    /**
     * Sets the rate type flag
     *
     * @param {Object} payload - Object container
     * @param {Bolean} payload.rateType - True if period rate type, false if frequency
     */
    FFT_SET_RATE_TYPE: (payload) => {
      this.state.set('rateType', payload.rateType);
    },

    /**
     * Sets the sampling rate
     *
     * @param {Object} payload - Object container
     * @param {String} payload.rate - the rate to sample at
     */
    FFT_SET_RATE: (payload) => {
      let rate = payload.rate;

      // If the rate is undefined, populate the display rate.  This usually occurs when the user selects a series,
      // as the payload units are generally not human friendly

      if (_.isUndefined(this.state.get('rate').value)) {
        rate = determineIdealUnits(payload.rate);
      }

      this.state.set('rate', rate);
    },

    /**
     * Sets the output units of the fft
     *
     * @param {Object} payload - Object container
     * @param {String} payload.outputUnits - The units to format the bucket labels with
     */
    FFT_SET_OUTPUT_UNITS: (payload) => {
      this.state.set('outputUnits', payload.outputUnits);
    },

    /**
     * Sets the high pass filter
     *
     * @param {Object} payload - Object container
     * @param {String} payload.highPass - the high pass filter
     */
    FFT_SET_FILTER_HIGH: (payload) => {
      this.state.set('highPass', payload.highPass);
    },

    /**
     * Sets the low pass filter
     *
     * @param {Object} payload - Object container
     * @param {String} payload.lowPass - the low pass filter
     */
    FFT_SET_FILTER_LOW: (payload) => {
      this.state.set('lowPass', payload.lowPass);
    },

    /**
     * Sets the use of the high pass filter
     * @param {Object} payload - Object container
     * @param {Bolean} payload.useHighPass - the flag for use of high pass filter
     */
    FFT_SET_USE_FILTER_HIGH: (payload) => {
      this.state.set('useHighPass', payload.useHighPass);
    },

    /**
     * Sets the use of the low pass filter
     *
     * @param {Object} payload - Object container
     * @param {Bolean} payload.useLowPass - the flag for use of low pass filter
     */
    FFT_SET_USE_FILTER_LOW: (payload) => {
      this.state.set('useLowPass', payload.useLowPass);
    },
  };
}
