// @ts-strict-ignore
import { HOME_SCREEN_TABS } from '@/main/app.constants';

export enum HelpDisplay {
  NONE = 'NONE',
  EXPANDED = 'EXPANDED',
  COLLAPSED = 'COLLAPSED',
  POPOVER = 'POPOVER',
}

export const HOME_SCREEN_SORT = {
  UPDATED_AT: 'updatedAt',
  OPENED_AT: 'openedAt',
  CREATED_AT: 'createdAt',
  NAME: 'name',
  OWNER: 'owner',
  SHARED_AT: 'sharedAt',
};

export const HOME_SCREEN_TABLE_TYPE = {
  TAB: 'table_on_tab',
  PINNED: 'pinned_table',
  RECENT: 'recent_table',
  SEARCH: 'search_results_table',
};

export const HOME_SCREEN_CANCELLATION_GROUP = 'homeScreenRequest';

export const ITEM_TYPES = {
  ANALYSIS: 'Analysis',
  ANY: 'Any',
  FOLDER: 'Folder',
  PROJECT: 'Project',
  TOPIC: 'Topic',
};

export const DEFAULT_TYPE = {
  text: 'HOME_SCREEN.TYPES.ANY',
  value: ITEM_TYPES.ANY,
};

export const TYPE_OPTIONS = [
  {
    text: 'HOME_SCREEN.TYPES.ANY',
    value: ITEM_TYPES.ANY,
    icon: undefined,
  },
  {
    text: 'HOME_SCREEN.TYPES.ANALYSIS',
    value: ITEM_TYPES.ANALYSIS,
    icon: 'fc fc-analysis workbenchDisplay',
  },
  {
    text: 'HOME_SCREEN.TYPES.TOPIC',
    value: ITEM_TYPES.TOPIC,
    icon: 'fc fc-report workbenchDisplay',
  },
  {
    text: 'HOME_SCREEN.TYPES.PROJECT',
    value: ITEM_TYPES.PROJECT,
    icon: 'fc fc-seeq-datalab workbenchDisplay',
  },
  {
    text: 'HOME_SCREEN.TYPES.FOLDER',
    value: ITEM_TYPES.FOLDER,
    icon: 'fc fc-folder sq-text-primary workbenchDisplay',
  },
];

export const MIN_HOME_SCREEN_ITEM_COUNT = 5;

export const tabFolders = (isAdmin, includeCorporate) => {
  const tabs = [HOME_SCREEN_TABS.MY_FOLDER];
  if (includeCorporate) {
    tabs.push(HOME_SCREEN_TABS.CORPORATE);
  }
  if (isAdmin) {
    tabs.push(HOME_SCREEN_TABS.USERS);
  }
  return tabs;
};

export enum DISPLAY_LOCATION {
  CURRENT_PAGE,
  NEW_TAB,
}
