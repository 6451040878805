// @ts-strict-ignore
import { sqWorkbenchStore } from '@/core/core.stores';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ColorPicker } from '@/hybrid/workbooks/ColorPicker.organism';
import { TrendTableActions } from '@/trendData/trendTable.actions';
import classNames from 'classnames';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  getChartConfig,
  processChanges,
  restoreDefaultColors,
  toggleSeries,
  updateColorDelay,
  updateLabels,
} from '@/hybrid/trend/tableVisualization/tableVisualization.utilities';
import useResizableHighchart from '@/hybrid/core/hooks/useResizableHighchart.hook';
import { useDidUpdate } from 'rooks';

const tableVisualizationBindings = bindingsDefinition({
  sqTrendTableActions: injected<TrendTableActions>(),
  table: prop<any>(),
  showLaneLabels: prop<boolean>(),
  labelDisplayConfiguration: prop<any>(),
});

export const TableVisualization: SeeqComponent<typeof tableVisualizationBindings> = ({
  table,
  showLaneLabels,
  labelDisplayConfiguration,
}) => {
  const { sqTrendTableActions } = useInjectedBindings(tableVisualizationBindings);

  const userLanguage = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userLanguage);

  const [chart, setChart] = useState<Highcharts.Chart | null>(null);

  const isProcessingChanges = useRef(false);
  const lastTable = useRef(table);
  const chartElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!chart) {
      return;
    }

    updateLabels(chart, table);
  }, [showLaneLabels, labelDisplayConfiguration]);

  useEffect(() => {
    // if there's already an update queued, skip this one
    if (isProcessingChanges.current) {
      return;
    }

    isProcessingChanges.current = true;
    processChanges({
      chart,
      oldTable: lastTable.current,
      newTable: table,

      sqTrendTableActions,
    });
    lastTable.current = table;
    isProcessingChanges.current = false;
  }, [table]);

  // reload table if language changes
  useDidUpdate(() => {
    if (userLanguage) {
      sqTrendTableActions.fetchTableData(table.id);
    }
  }, [userLanguage]);

  const onDestroyChart = () => {
    chartElementRef.current = null;
  };

  useResizableHighchart({
    chart,
    chartElementRef,
    setChart,
    onDestroyChart,
  });

  return (
    <div className="flexColumnContainer chart table-container" data-testid="tableVisualization">
      <div className="flexFill mr10" ref={chartElementRef}>
        <HighchartsReact
          highcharts={Highcharts}
          options={getChartConfig(table)}
          allowChartUpdate={false}
          callback={setChart}
          containerProps={{ style: { height: '100%' } }}
        />
      </div>
      {chart?.series.length > 1 && (
        <div className="pr5 mr5 mb15 mt10 table-legend" data-testid="table-legend">
          {_.map(chart.series, (series) => (
            <div className="flexColumnContainer" key={series.index}>
              {!series.visible && <div className="colorPickerSwatch inactive mt2" />}
              {series.visible && (
                <HoverTooltip text="AGGREGATIONS.CHANGE_COLOR">
                  <ColorPicker
                    itemId={series.name}
                    placement="left"
                    color={series['color']}
                    notifyOnSelect={(seriesName: string, color) =>
                      updateColorDelay({
                        table,
                        sqTrendTableActions,
                        seriesName,
                        color,
                      })
                    }
                    linkFunction={() => restoreDefaultColors({ table, sqTrendTableActions })}
                    linkText="AGGREGATIONS.RESTORE"
                  />
                </HoverTooltip>
              )}
              <HoverTooltip text="AGGREGATIONS.TOGGLE_VISIBILITY">
                <span
                  className={classNames('pl5 cursorPointer', series.visible ? 'text-color-default' : 'sq-darkish-gray')}
                  onClick={() =>
                    toggleSeries({
                      chart,
                      table,
                      seriesName: series.name,
                      sqTrendTableActions,
                    })
                  }>
                  {series.name}
                </span>
              </HoverTooltip>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
