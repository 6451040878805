// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { findChildrenIn } from '@/hybrid/trend/trendDataHelper.utilities';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { TrendActions } from '@/trendData/trend.actions';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { SortIcon } from '@/hybrid/core/SortIcon.atom';
import { ItemPropertiesSelectorButton } from '@/hybrid/utilities/ItemPropertiesSelectorButton.molecule';
import { SelectAllIcon } from '@/hybrid/trend/panels/SelectAllIcon.atom';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { DetailsPanelColumn } from '@/hybrid/trend/panels/detailsPanel/DetailsPanelColumn.molecule';
import { SelectedRegionRow } from '@/hybrid/trend/panels/detailsPanel/SelectedRegionRow.atom';
import { DataStatusIcon } from '@/hybrid/trend/panels/detailsPanel/DataStatusIcon.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useAllTrendStoreItems } from '@/hybrid/core/hooks/useAllTrendStoreItems.hook';
import { onEventPreventPropagation } from '@/hybrid/core/onEnterKeypress.util';
import { cloneDeepOmit } from '@/hybrid/utilities/utilities';
import {
  AXIS_ALIGN,
  CUSTOMIZATIONS_COLUMNS,
  EXTRA_COLUMNS,
  EXTRA_CUSTOMIZATION_COLUMNS,
  ITEM_TYPES,
  REQUIRED_COLUMNS,
  STAT_COLUMNS,
  TREND_PANELS,
  TREND_STORES,
  XY_AXIS_ALIGN,
} from '@/trendData/trendData.constants';
import {
  formatItemsForGroupingModeDisplay,
  formatPointValue,
  getEnabledColumns,
  isCustomizationColumnEnabled,
  removeItem,
  setInvestigateItem,
} from '@/hybrid/utilities/detailsPanel.utilities';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { doTrack } from '@/track/track.service';
import { sqScatterPlotStore, sqTrendStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import { sortAndDecorateItems } from '@/hybrid/trend/trendViewer/itemDecorator.utilities';
import { canWriteItem } from '@/services/authorization.service';

const detailsPanelBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqTrendActions: injected<TrendActions>(),
  sqWorksheetActions: injected<WorksheetActions>(),
});

export const DetailsPanel: SeeqComponent<typeof detailsPanelBindings> = () => {
  const { sqInvestigateActions, sqTrendActions, sqWorksheetActions } = useInjectedBindings(detailsPanelBindings);
  const { t } = useTranslation();

  const { lanes, alignments, customizationMode, conditionLanes } = useFlux(sqTrendStore);
  const { ySignals, xSignal } = useFlux(sqScatterPlotStore);
  const enabledColumnsFromStore = useFluxPath(sqTrendStore, () => sqTrendStore.enabledColumns(TREND_PANELS.SERIES));
  const sort = useFluxPath(sqTrendStore, () => sqTrendStore.getPanelSort(TREND_PANELS.SERIES));
  const { view, capsuleGroupMode } = useFlux(sqWorksheetStore);
  const isEditMode = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.EDIT);

  const [allItems, setAllItems] = useState([]);
  const [enabledColumns, setEnabledColumns] = useState(REQUIRED_COLUMNS);

  const isGrouping = capsuleGroupMode && (sqTrendStore.isTrendViewCapsuleTime() || sqTrendStore.isTrendViewChainView());
  const simplifiedView = _.includes([WORKSHEET_VIEW.SCATTER_PLOT, WORKSHEET_VIEW.TREEMAP], view.key);

  const displayItems = useAllTrendStoreItems(
    {
      transformer: (items) =>
        _.chain(items)
          .thru((trendItems) =>
            sortAndDecorateItems({
              sortParam: sort,
              items: trendItems,
              sqTrendStore,
            }),
          )
          .thru((trendItems) => cloneDeepOmit(trendItems, ['data', 'samples']))
          .tap((trendItems) => setAllItems(trendItems)) // need all items (not just those displayed) for grouping
          .thru((trendItems) => formatItemsForGroupingModeDisplay(trendItems, sort, isGrouping))
          .map((item) => ({
            ...item,
            itemAndMetricChildren:
              view.key === WORKSHEET_VIEW.TREND && item.itemType === ITEM_TYPES.METRIC
                ? _.concat([item], findChildrenIn(TREND_STORES, item.id))
                : [item],
          }))
          .value(),
    },
    [sort, isGrouping, ySignals, xSignal, view.key],
  );
  const anySelected = _.some(displayItems, 'selected');

  _.find(CUSTOMIZATIONS_COLUMNS, ['key', 'lane']).options = lanes;
  _.find(CUSTOMIZATIONS_COLUMNS, ['key', 'conditionLane']).options = conditionLanes;
  _.find(AXIS_ALIGN, ['key', 'axisAlign']).options =
    view.key === WORKSHEET_VIEW.SCATTER_PLOT ? XY_AXIS_ALIGN : alignments;

  const allowStats = view.key === WORKSHEET_VIEW.TREND;
  const statColumns = !simplifiedView && allowStats ? STAT_COLUMNS : [];
  const allExtraColumns = _.concat(
    EXTRA_COLUMNS,
    _.filter(EXTRA_CUSTOMIZATION_COLUMNS, (column) => isCustomizationColumnEnabled(column.key, displayItems)),
  );

  const isColumnEnabled = (column) => sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, column.key);
  const toggleSort = (sortBy) => sqTrendActions.togglePanelSort(TREND_PANELS.SERIES, sortBy);
  const addPropertiesColumn = (property) => sqTrendActions.addPropertiesColumn(TREND_PANELS.SERIES, property);
  const toggleColumn = (column) =>
    sqTrendActions.isPropertyColumn(column)
      ? sqTrendActions.removePropertiesColumn(TREND_PANELS.SERIES, column)
      : sqTrendActions.toggleColumn(TREND_PANELS.SERIES, column.key);

  const propertyColumns = _.map(sqTrendStore.propertyColumns(TREND_PANELS.SERIES), (column) => ({
    ...column,
    style: 'string', // indicates that no formatting is needed
    title: column.propertyName,
    shortTitle: column.propertyName,
  }));

  useEffect(() => {
    const maybeNewColumns = getEnabledColumns({
      simplifiedView,
      isGrouping,
      allowStats,
      propertyColumns,
      items: displayItems,
    });
    if (!_.isEqual(maybeNewColumns, enabledColumns)) {
      setEnabledColumns(maybeNewColumns);
    }
  }, [simplifiedView, capsuleGroupMode, enabledColumnsFromStore, displayItems, customizationMode, view.key]);

  const selectRow = (event, item) => {
    event.stopPropagation();
    if ((isGrouping && item.itemType === ITEM_TYPES.CAPSULE_SET) || !isGrouping) {
      sqTrendActions.selectItems(item, displayItems, event);
    }
  };

  const updateAllSelections = (selected: boolean) => {
    _.forEach(displayItems, (item) => sqTrendActions.setItemSelected(item, selected));
  };

  return (
    <div className="flexFill flexRowContainer msOverflowStyleAuto height-maximum overflowAuto">
      <div className="tableWrapper height-maximum overflowAuto">
        <table
          className={classNames('table table-condensed fixedHeaderTable mb0', {
            'table-striped': !isGrouping,
          })}>
          <thead>
            <tr className="panelTableHeader">
              <th className="width-minimum" />
              <th className="width-30 text-center">
                <DataStatusIcon
                  header={true}
                  items={_.chain(displayItems).map('itemAndMetricChildren').flatten().value()}
                />
              </th>
              <th className="width-minimum" />
              <th className="width-minimum text-center">
                {isEditMode && (
                  <Icon
                    id="removeSelected"
                    icon="fa-close"
                    testId="removeSelectedIcon"
                    extraClassNames={classNames('removeButton', {
                      cursorPointer: anySelected,
                    })}
                    type={anySelected ? 'theme' : 'dark-gray'}
                    onClick={() => {
                      doTrack('Worksheets', 'selected Signals removed');
                      sqTrendActions.removeSelectedItems();
                    }}
                    tooltip="TOOLBAR.REMOVE_SELECTED"
                    tooltipPlacement="right"
                  />
                )}
              </th>
              <th className="width-minimum">
                <SelectAllIcon
                  items={displayItems}
                  selectAll={() => updateAllSelections(true)}
                  unselectAll={() => updateAllSelections(false)}
                />
              </th>
              <th className="width-minimum cursorPointer" />
              {_.map(enabledColumns, (column: any) => (
                <th
                  key={column.key}
                  className={classNames('cursorPointer text-nowrap', {
                    'width-minimum': column.style !== 'string' || column.key === 'valueUnitOfMeasure',
                  })}>
                  <span onClick={() => !!column.hideSort || toggleSort(column.key)}>
                    <HoverTooltip text={column.tooltip}>
                      <>
                        <span
                          className={classNames({
                            'sq-text-info': sort.sortBy === column.key,
                          })}
                          data-testid={`${column.key}-header`}>
                          {t(column.key === 'valueUnitOfMeasure' ? '' : column.shortTitle)}
                        </span>
                        {!column.hideSort && (
                          <SortIcon sortProperty={column.key} sortBy={sort.sortBy} sortAsc={sort.sortAsc} />
                        )}
                      </>
                    </HoverTooltip>
                  </span>
                </th>
              ))}
              <th className="cursorPointer text-right width-80">
                <div className="btn btn-stat btn-xs columnsBtn">
                  <ItemPropertiesSelectorButton
                    itemIds={_.map(allItems, 'id')}
                    statColumns={statColumns}
                    extraColumns={allExtraColumns}
                    propertyColumns={propertyColumns}
                    showPropertyColumns={!simplifiedView}
                    isColumnEnabled={isColumnEnabled}
                    toggleColumn={toggleColumn}
                    addPropertyColumn={addPropertiesColumn}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(displayItems, (item: any, index: number) => (
              <tr
                data-testid="detailsPanelRow"
                key={item.trackId || item.id}
                onClick={(e) => selectRow(e, item)}
                className={classNames('specDetailsPanelRow', item.rowClasses)}>
                <td className="icon">
                  {isEditMode && item.calculationType && canWriteItem(item) && (
                    <Icon
                      testId="editIcon"
                      icon="fa-pencil"
                      extraClassNames="btn-transparent editButton"
                      onClick={
                        item.calculationType &&
                        onEventPreventPropagation(() => sqInvestigateActions.loadToolForEdit(item.id))
                      }
                      tooltip="EDIT"
                      tooltipPlacement="top"
                    />
                  )}
                </td>
                <td className="icon width-30 text-center forceVerticalAlignMiddle">
                  <DataStatusIcon items={item.itemAndMetricChildren} header={false} />
                </td>
                <td className="icon">
                  <Icon
                    icon="fa-info-circle"
                    testId="itemPropertiesIcon"
                    type={item.isArchived ? 'danger' : 'theme'}
                    tooltip={item.isArchived ? 'TRASH.ITEM_TRASHED_CLICK_TO_SEE_DETAILS' : 'PROPERTIES.HEADER'}
                    tooltipPlacement="top"
                    extraClassNames="btn-transparent"
                    onClick={onEventPreventPropagation(() =>
                      setInvestigateItem(sqInvestigateActions, item.id, TREND_TOOLS.PROPERTIES),
                    )}
                  />
                </td>
                <td className="icon text-center">
                  {!item.trackId && (
                    <Icon
                      icon="fa-close"
                      testId="removeItemIcon"
                      extraClassNames="btn-transparent removeButton"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeItem(sqTrendActions, sqWorksheetActions, item);
                      }}
                      tooltip="REMOVE"
                      tooltipPlacement="top"
                    />
                  )}
                </td>
                <td onClick={onEventPreventPropagation(() => !item.trackId && sqTrendActions.toggleItemSelected(item))}>
                  {!item.trackId && (
                    <Icon
                      testId="rowCheckbox"
                      icon={item.selectIcon}
                      tooltip={item.selectTooltip}
                      type="text"
                      tooltipPlacement="right"
                      extraClassNames="specRowCheckbox fa-fw"
                    />
                  )}
                </td>
                <td>
                  <Icon
                    testId="itemTypeIcon"
                    icon={item.iconClass}
                    type="color"
                    color={item.color}
                    extraClassNames="fa-fw pr3 itemMainIcon"
                    tooltip={item.translateKey}
                    tooltipPlacement="right"
                  />
                </td>

                {_.map(enabledColumns, (column) => (
                  <DetailsPanelColumn
                    rowIndex={index}
                    key={column.key}
                    item={item}
                    displayItems={displayItems}
                    allItems={allItems} // used for grouping
                    column={column}
                    capsuleGroupingMode={isGrouping}
                    viewKey={view.key}
                  />
                ))}

                <td>
                  <div className="verticalAlignMiddle text-nowrap text-right width-80 overflowHidden">
                    <span
                      data-testid="pointValue"
                      className={classNames(
                        'pointValue',
                        { 'text-bolder': item.pointSelected },
                        { 'sq-fairly-dark-gray': item.pointSelected === false },
                      )}
                      style={item.pointSelected === false ? {} : { color: item.color }}>
                      {formatPointValue(item)}
                    </span>
                  </div>
                </td>
              </tr>
            ))}

            {sqTrendStore.isRegionSelected() && <SelectedRegionRow numberOfEnabledColumns={enabledColumns.length} />}
          </tbody>
        </table>
      </div>
    </div>
  );
};
