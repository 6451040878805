// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { DropdownButton } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import classNames from 'classnames';
import { ToolbarDropdownTitle } from '@/hybrid/core/ToolbarDropdownTitle.molecule';

interface ToolbarDropdownButtonProps {
  /** icon class to be used with the dropdown (i.e. 'fc-zoom') */
  icon: string;
  /** label for the toolbar item (shown only when toolbar is wide enough) */
  label?: string;
  /** items for the dropdown (typically a _.map on <Dropdown.Item/>) */
  dropdownItems: any;
  /** true to render an icon-only dropdown button */
  isSmall?: boolean;
  id?: string;
  /** extra class names to be placed on the DropdownButton component */
  extraClassNames?: string;
  /** the text to be displayed on the icon's tooltip */
  tooltipText?: string;
  tooltipPlacement?: Placement;
  testId?: string;
  disabled?: boolean;
  /** function called when the popover is clicked on, i.e. for tracking (does not open the popover) */
  onClick?: () => void;
  /** true to give the button the "pressed" look */
  active?: boolean;
  /** props to manually handle the toggling of the dropdown */
  show?: boolean;
  setShow?: (value: boolean) => void;
}

/**
 * Toolbar button with dropdown list of items, e.g. trend capsule color
 */
export const ToolbarDropdownButton: React.FunctionComponent<ToolbarDropdownButtonProps> = ({
  isSmall = false,
  icon,
  label,
  tooltipText,
  tooltipPlacement = 'top',
  id,
  dropdownItems,
  extraClassNames,
  testId,
  disabled = false,
  onClick = _.noop,
  active = false,
}) => {
  const [show, setShow] = useState(false);

  return (
    <DropdownButton
      disabled={disabled}
      show={show}
      onToggle={setShow}
      title={
        <ToolbarDropdownTitle
          icon={icon}
          label={label}
          tooltipPlacement={tooltipPlacement}
          tooltipText={tooltipText}
          isSmall={isSmall}
          testId={testId}
          onClick={onClick}
        />
      }
      className={classNames(
        extraClassNames,
        'sq-btn-toolbar-dropdown',
        { active },
        isSmall ? 'sq-btn-toolbar-dropdown-sm' : 'btn-lg-text',
      )}
      variant={null}
      bsPrefix="sq-btn"
      size="sm"
      id={id}>
      {show && dropdownItems}
    </DropdownButton>
  );
};
