// @ts-strict-ignore
import _ from 'lodash';
import { SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { sqFoldersApi } from '@/sdk';
import { flux } from '@/core/flux.module';
import { sqExploreWorkbookModalStore } from '@/core/core.stores';

export function sqExploreWorkbookModalActions() {
  const service = {
    setWorkbookId,
    setSort,
    clear,
    setTableFilter,
    setPageSizeForTable,
    setPageNumberAndGo,
    setSearchParams,
    loadItems,
    setFolder,
    getRootAndUpdateFilter,
    setShowResults,
    setTableRoot,
    setInitialFolderId,
  };

  return service;

  function setInitialFolderId(initialFolderId: string) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_INITIAL_FOLDER_ID', {
      initialFolderId,
    });
  }

  /**
   * Sets the workbook ID
   *
   * @param {String} workbookId - a workbook ID
   */
  function setWorkbookId(workbookId) {
    flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_WORKBOOK_ID', { workbookId });
  }

  /**
   * Sets the sort field that controls the sorting of the workbooks and toggles the direction if the field
   * was already set to the specified one.
   *
   * @param {String} sortProperty - One of WORKBOOKS_SORT_FIELDS
   * @param {boolean} sortAscending - True for asc, false for desc
   * @param {boolean} forceSortDirection=false - True if the given sort direction must not be flipped when sorting
   *   on the currently sorted property (normally used to toggle sort direction)
   */
  function setSort(sortProperty, ascending: boolean, forceSortDirection = false) {
    const sortAscending =
      !forceSortDirection && sortProperty === sqExploreWorkbookModalStore.sortProperty ? !ascending : ascending;
    flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_SORT', {
      sortProperty,
      sortAscending,
    });
  }

  /**
   * Clears the data for the currently loaded seeq content image.
   */
  function clear() {
    flux.dispatch('EXPLORE_WORKBOOK_MODAL_CLEAR');
  }

  function setTableFilter(tableFilter) {
    flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_TABLE_FILTER', { tableFilter });
  }

  function setSearchParams(field, value) {
    flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_SEARCH_PARAMS', { field, value });
  }

  function setPageSizeForTable({ size }) {
    flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_PAGE_SIZE', { pageSize: size });
  }

  function setPageNumberAndGo(page: number, loadTable: Function) {
    flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_PAGE_NUMBER', {
      pageNumber: page,
    });
    loadTable();
  }

  function setFolder(folder) {
    flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_FOLDER', { folder });
  }

  function setShowResults(showResults) {
    flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_SHOW_RESULTS', { showResults });
  }

  function setTableRoot(root) {
    flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_TABLE_ROOT', { root });
  }

  function loadItems() {
    const limit = sqExploreWorkbookModalStore.getPageSizeByTable();
    const currentPageNumber = sqExploreWorkbookModalStore.getPageNumberForTable();
    const offset = (currentPageNumber - 1) * limit;
    const sortDirection = sqExploreWorkbookModalStore.sortAscending ? 'asc' : 'desc';
    const sortOrder = `${sqExploreWorkbookModalStore.sortProperty} ${sortDirection}`;
    const root = sqExploreWorkbookModalStore.tableRoot;
    const params = _.assign({}, sqExploreWorkbookModalStore.searchParams, {
      limit,
      offset,
      sortOrder,
      root,
    });

    flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_LOADING', { loading: true });
    return sqFoldersApi
      .getFolders(params)
      .then((response) => {
        flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_ITEMS', {
          items: response.data.content,
        });
        flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_ITEM_TOTAL_FOR_TABLE', {
          itemTotalForTable: response.data.totalResults,
        });
      })
      .finally(() => flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_LOADING', { loading: false }));
  }

  function getRootAndUpdateFilter(folderId) {
    if (folderId) {
      return sqFoldersApi.getAncestors({ folderId }).then((folderTree) => {
        const itemFolderRoot = folderTree.data.id;
        if (itemFolderRoot) {
          if (itemFolderRoot === SEARCH_ITEM_LOCATIONS.MY_FOLDER) {
            service.setTableFilter(SEARCH_ITEM_LOCATIONS.MY_FOLDER);
            service.setSearchParams('filter', SEARCH_ITEM_LOCATIONS.MY_FOLDER);
          } else if (itemFolderRoot === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC) {
            service.setTableFilter(SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC);
            service.setSearchParams('filter', SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC);
          } else if (itemFolderRoot === SEARCH_ITEM_LOCATIONS.CORPORATE) {
            service.setTableFilter(SEARCH_ITEM_LOCATIONS.CORPORATE);
            service.setSearchParams('filter', SEARCH_ITEM_LOCATIONS.CORPORATE);
          }
        }
      });
    } else {
      return Promise.resolve({});
    }
  }
}
