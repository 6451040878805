import React, { useEffect } from 'react';
import _ from 'lodash';
import { HOME_SCREEN_TABS } from '@/main/app.constants';
import { HomeScreenNavigation } from '@/hybrid/homescreen/HomeScreenNavigation.molecule';
import { HomeScreenNewButton } from '@/hybrid/homescreen/HomeScreenNewButton.organism';
import { HomeScreenRightPanel } from '@/hybrid/homescreen/HomeScreenRightPanel.organism';
import { HomeScreenBreadCrumbs } from '@/hybrid/homescreen/HomeScreenBreadcrumbs.atom';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { HomeScreenTable } from '@/hybrid/homescreen/HomeScreenTable.organism';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { HomeScreenSearch } from '@/hybrid/homescreen/HomeScreenSearch.organism';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { ErrorBoundaryWithLogging } from '@/hybrid/core/ErrorBoundary.atom';
import { ErrorFallback } from '@/hybrid/core/ErrorFallback.organism';
import { HomeScreenWelcome } from '@/hybrid/homescreen/HomeScreenWelcome.organism';
import { HOME_SCREEN_TABLE_TYPE } from '@/hybrid/homescreen/homescreen.constants';
import { sqHomeScreenStore, sqPluginStore } from '@/core/core.stores';
import { HomeScreenAddOns } from '@/hybrid/homescreen/HomeScreenAddOns.organism';

const homeScreenBindings = bindingsDefinition({
  sqHomeScreenActions: injected<HomeScreenActions>(),
});

export const HomeScreen: SeeqComponent<typeof homeScreenBindings> = () => {
  const { sqHomeScreenActions } = useInjectedBindings(homeScreenBindings);
  const { t } = useTranslation();

  useFlux(sqHomeScreenStore);
  const homeScreenItems = sqHomeScreenStore.getItemsForTable(HOME_SCREEN_TABLE_TYPE.TAB);
  const pinnedItems = sqHomeScreenStore.getItemsForTable(HOME_SCREEN_TABLE_TYPE.PINNED);
  const recentlyUsedItems = sqHomeScreenStore.getItemsForTable(HOME_SCREEN_TABLE_TYPE.RECENT);
  const searchResultsItems = sqHomeScreenStore.getItemsForTable(HOME_SCREEN_TABLE_TYPE.SEARCH);
  const currentTab = sqHomeScreenStore.currentTab;
  const showHelp = _.includes(['COLLAPSED', 'EXPANDED'], sqHomeScreenStore.helpDisplay);
  const showTrainingPopover = sqHomeScreenStore.helpDisplay === 'POPOVER';
  // loading indicators should only show while tabs are loading, not if tabs happen to have no content.
  // before the request for content of a tab is made the contents is set to null, if a tab has no content then the
  // content will be set to an empty array
  const loadingPinnedTable = sqHomeScreenStore.isTableLoading(HOME_SCREEN_TABLE_TYPE.PINNED);
  const loadingPinned = loadingPinnedTable && pinnedItems === null;
  const loadingTabTable = sqHomeScreenStore.isTableLoading(HOME_SCREEN_TABLE_TYPE.TAB);
  const loadingTab = loadingTabTable && homeScreenItems === null;
  const loadingLruTable = sqHomeScreenStore.isTableLoading(HOME_SCREEN_TABLE_TYPE.RECENT);
  const loadingLru = loadingLruTable && recentlyUsedItems === null;
  const loadingSearchTable = sqHomeScreenStore.isTableLoading(HOME_SCREEN_TABLE_TYPE.SEARCH);
  const loadingSearch = loadingSearchTable && searchResultsItems === null;

  const noPinnedItems = !loadingPinned && _.isEmpty(pinnedItems);
  const noRecentItems = !loadingLru && _.isEmpty(recentlyUsedItems);
  const noContentAvailable = noPinnedItems && noRecentItems;

  useFlux(sqPluginStore);
  useEffect(() => {
    // Handle case where add-ons tab was displayed and user subsequently loses permission to any home screen add-ons
    if (currentTab === HOME_SCREEN_TABS.ADDONS && sqPluginStore.homeScreenPlugins().length === 0) {
      sqHomeScreenActions.setCurrentTab(HOME_SCREEN_TABS.HOME);
    }
  }, [currentTab]);

  const renderTableOnly = (
    <div className="height-maximum width-maximum pl10 pr10 flexRowContainer pb10 homeScreenMainPanel overflowYAuto overflowXHidden">
      <HomeScreenTable
        loadTable={sqHomeScreenActions.loadTabTable}
        items={homeScreenItems}
        isLoading={loadingTab}
        showBreadcrumbs={false}
        tableType={HOME_SCREEN_TABLE_TYPE.TAB}
      />
    </div>
  );

  const renderPinned = (
    <div data-testid="pinnedTable">
      {!noPinnedItems && <h4>{t('HOME_SCREEN.PINNED')}</h4>}
      <HomeScreenTable
        loadTable={sqHomeScreenActions.loadPinnedTable}
        items={pinnedItems}
        isLoading={loadingPinned}
        showBreadcrumbs={true}
        tableType={HOME_SCREEN_TABLE_TYPE.PINNED}
      />
    </div>
  );

  const renderSearchResults = (
    <div className="height-maximum width-maximum pl10 pr10 flexRowContainer overflowAuto pb100 homeScreenMainPanel">
      <HomeScreenTable
        loadTable={() =>
          sqHomeScreenActions.loadSearchTable({
            searchParams: sqHomeScreenStore.searchParams,
          })
        }
        items={searchResultsItems}
        showBreadcrumbs={true}
        isLoading={loadingSearch}
        tableType={HOME_SCREEN_TABLE_TYPE.SEARCH}
      />
    </div>
  );

  const renderRecent = (
    <div className="mb50" data-testid="recentlyOpenedTable">
      {!noRecentItems && <h4>{t('HOME_SCREEN.RECENTLY_OPENED')}</h4>}
      <HomeScreenTable
        loadTable={sqHomeScreenActions.loadRecentTable}
        items={recentlyUsedItems}
        isLoading={loadingLru}
        showBreadcrumbs={true}
        tableType={HOME_SCREEN_TABLE_TYPE.RECENT}
      />
    </div>
  );

  const contentComingSoon = (
    <div className="pl10 pr10 flexFill flexRowContainer flexAlignCenter">
      <div className="flexFill flexRowContainer flexCenter pb50">
        <img src="/img/noContentYet.png" className="noContentImgWrapper" />
        <div className="homeScreenNoContent">{t('HOME_SCREEN.NO_CONTENT')}</div>
      </div>
    </div>
  );

  const renderHomeTables = (
    <div className="height-maximum width-maximum pl10 pr10 overflowAuto">
      {renderRecent}
      {renderPinned}
    </div>
  );

  const renderHome = noContentAvailable && !showHelp ? contentComingSoon : renderHomeTables;

  const renderTables = () => {
    switch (currentTab) {
      case HOME_SCREEN_TABS.HOME:
        return renderHome;
      case HOME_SCREEN_TABS.SEARCH:
        return renderSearchResults;
      default:
        return renderTableOnly;
    }
  };

  return (
    <ErrorBoundaryWithLogging fallback={(error, reset) => <ErrorFallback error={error} retry={reset} />}>
      <div className="flexColumnContainer flexFill height-maximum width-maximum m-0 overflowHidden">
        {showTrainingPopover && <div className="width-maximum height-maximum positionAbsolute blackout" />}
        <div className="flexRowContainer homeScreenLeftPanel">
          <HomeScreenNewButton />
          <HomeScreenNavigation />
        </div>
        <div className="flexRowContainer height-maximum width-maximum">
          {currentTab === HOME_SCREEN_TABS.ADDONS ? (
            <HomeScreenAddOns />
          ) : (
            <>
              <div className="m20">
                <HomeScreenSearch isLoading={loadingSearchTable} />
              </div>
              {showHelp && currentTab !== HOME_SCREEN_TABS.SEARCH && <HomeScreenWelcome />}
              {currentTab !== HOME_SCREEN_TABS.HOME && currentTab !== HOME_SCREEN_TABS.SEARCH && (
                <div className="ml20 mb10">
                  <HomeScreenBreadCrumbs />
                </div>
              )}
              <ErrorBoundaryWithLogging fallback={(error, reset) => <ErrorFallback error={error} retry={reset} />}>
                {renderTables()}
              </ErrorBoundaryWithLogging>
            </>
          )}
        </div>
        <div className="flexRowContainer homeScreenRightPanel">
          <HomeScreenRightPanel />
        </div>
      </div>
    </ErrorBoundaryWithLogging>
  );
};

export const sqHomeScreen = angularComponent(homeScreenBindings, HomeScreen);
