import React from 'react';
import {
  adminContactEmail,
  isAnnouncementsEnabled,
  isTelemetryAnonymized,
} from '@/services/systemConfiguration.utilities';
import { isProtractor } from '@/hybrid/core/utilities';
import { Icon } from '@/hybrid/core/Icon.atom';
import { sqLicenseManagementStore, sqWorkbenchStore } from '@/core/core.stores';
import { isAdmin } from '@/services/authorization.service';
import { SEEQ_VERSION } from '@/services/buildConstants.service';
import _ from 'lodash';
import { getUserTrackingData } from '@/hybrid/utilities/utilities';

export const BEAMER_CONFIG_ID = 'RAOEMNgq50783';

/**
 * This component pulls in announcements that are created on getBeamer.com. For questions regarding contents for these
 * announcements talk to Shamus
 */
export const Announcements: React.FunctionComponent<{}> = () => {
  const show = isAnnouncementsEnabled() && !isProtractor();

  React.useEffect(() => {
    if (show) {
      addBeamerForm();
    }
  }, []);

  return show ? (
    <div className="beamerTrigger flexColumnContainer flexCenter flexAlignCenter" data-testid="announcementsIcon">
      <div>
        <Icon type="white" icon="fc-announcements" extraClassNames="fa-lg" />
      </div>
    </div>
  ) : null;
};

// This piece of code is copy & paste from getbeamer.com - it adds a script tag that loads the announcement form.
export function addBeamerForm() {
  const telemetryAnonymized = isTelemetryAnonymized();
  const contractId = _.isEmpty(sqLicenseManagementStore.license.contractNumber)
    ? 'DEV'
    : sqLicenseManagementStore.license.contractNumber;
  const majorVersion = SEEQ_VERSION.substring(1, SEEQ_VERSION.indexOf('.'));
  const minorVersion = SEEQ_VERSION.substring(SEEQ_VERSION.indexOf('.') + 1);
  const { userEmail } = getUserTrackingData({
    anonymizeTelemetry: isTelemetryAnonymized(),
    adminEmail: adminContactEmail(),
    username: sqWorkbenchStore.currentUser.username,
    userEmail: sqWorkbenchStore.currentUser.email,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.beamer_config = {
    product_id: BEAMER_CONFIG_ID,
    contractId,
    user_id: telemetryAnonymized ? '' : sqWorkbenchStore.currentUser.id,
    user_firstname: telemetryAnonymized ? '' : sqWorkbenchStore.currentUser.firstName,
    user_lastname: telemetryAnonymized ? '' : sqWorkbenchStore.currentUser.lastName,
    user_email: userEmail,
    isAdmin: isAdmin(),
    majorVersion,
    minorVersion,
  };
  const js = document.createElement('script');
  js.setAttribute('type', 'text/javascript');
  js.setAttribute('src', '//app.getbeamer.com/js/beamer-embed.js');
  document.getElementsByTagName('body')[0].appendChild(js);
}
