// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { ExplorerModalBody } from '@/hybrid/explorer/ExplorerModalBody.molecule';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { ItemPreviewWithAssetsV1, sqContentApi, sqWorkbooksApi } from '@/sdk';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { addAssetsProperty } from '@/hybrid/utilities/httpHelpers.utilities';
import { SelectWorksheet } from '@/hybrid/worksheets/SelectWorksheet.molecule';
import { WizardModal } from '@/hybrid/core/WizardModal.molecule';
import { SelectWorksheetHeader } from '@/hybrid/header/SelectWorksheetHeader.atom';
import { useTranslation } from 'react-i18next';
import { errorToast, infoToast, successToast } from '@/hybrid/utilities/toast.utilities';
import { ExploreWorkbookModalActions } from '@/hybrid/explorer/explorer.constants';
import { sqExploreWorkbookModalStore } from '@/core/core.stores';

const itemsFromWorksheetModalBindings = bindingsDefinition({
  setItems: prop<(items: ItemPreviewWithAssetsV1[]) => void>(),
  setModalIsOpen: prop<(showModal: boolean) => void>(),
  sqExploreWorkbookModalActions: injected<ExploreWorkbookModalActions>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
});

const auditedItemTypes = [
  SeeqNames.Types.Asset,
  'ConfigurationOption',
  SeeqNames.Types.CalculatedCondition,
  SeeqNames.Types.StoredCondition,
  SeeqNames.Types.Condition,
  SeeqNames.Types.Agent,
  SeeqNames.Types.Connector,
  SeeqNames.Types.Chart,
  SeeqNames.Types.AggregatingFormulaFunction,
  SeeqNames.Types.ThresholdMetric,
  SeeqNames.Types.CalculatedScalar,
  SeeqNames.Types.CalculatedSignal,
  SeeqNames.Types.StoredSignal,
  SeeqNames.Types.Signal,
  SeeqNames.Types.User,
  SeeqNames.Types.FormulaDoc,
  SeeqNames.Types.FormulaPackageDoc,
  SeeqNames.Types.FormulaFunction,
  SeeqNames.Types.UserDefinedFormulaFunction,
  SeeqNames.Types.FormulaPackage,
  SeeqNames.Types.FormulaPackageDoc,
  SeeqNames.Types.UserGroup,
];

export const ItemsFromWorksheetModal: SeeqComponent<typeof itemsFromWorksheetModalBindings> = (props) => {
  const { sqExploreWorkbookModalActions } = useInjectedBindings(itemsFromWorksheetModalBindings);

  const { setItems, setModalIsOpen } = props;

  const { t } = useTranslation();

  const [allowedTypes] = useState([SeeqNames.Types.Folder, SeeqNames.Types.Analysis, SeeqNames.Types.Topic]);

  const { workbookId } = useFlux(sqExploreWorkbookModalStore);
  const [worksheetIds, setWorksheetIds] = useState([]);
  const [workstepIds, setWorkstepIds] = useState([]);
  const [totalWorksheets, setTotalWorksheets] = useState(-1);
  const [stepChanged, setStepChanged] = useState(false);
  const noWorksheetsSelected = _.isEmpty(worksheetIds);

  const fetchWorksheetItems = (worksheetIds, workstepIds) => {
    Promise.all(
      _.map(worksheetIds, (worksheetId, index) =>
        sqWorkbooksApi.getReferencedItems({
          worksheetId,
          workstepId: workstepIds[index],
        }),
      ),
    )
      .then((results: any) => {
        const itemsToAdd = _.chain(results)
          .map('data')
          .flatMap((data) => addAssetsProperty(data).items)
          .reject((item) => !_.includes(auditedItemTypes, item.type))
          .value();
        setItems(itemsToAdd);
        return itemsToAdd;
      })
      .then((results) => {
        if (_.isEmpty(results)) {
          infoToast({
            messageKey: 'ITEMS_FROM_WORKSHEET.NOTIFICATION_NO_ITEMS_FOUND',
          });
        } else {
          successToast({
            messageKey: 'ITEMS_FROM_WORKSHEET.NOTIFICATION_SUCCESS',
          });
        }
      })
      .catch(() => {
        setItems([]);
        errorToast({ messageKey: 'ITEMS_FROM_WORKSHEET.NOTIFICATION_FAILED' });
      });
  };

  const fetchTopicItems = () => {
    Promise.all(_.map(worksheetIds, (worksheetId) => sqWorkbooksApi.getWorksheet({ workbookId, worksheetId }))).then(
      (results) =>
        Promise.all(
          _.map(results, (result) =>
            sqContentApi.getContentsWithAllMetadata({
              reportId: result.data.report.id,
            }),
          ),
        )
          .then((results: any) => {
            const contentItems = _.chain(results)
              .map('data')
              .flatMap('contentItems')
              .reject((result) => result.archived === true)
              .value();
            const sourceWorksheetIds = _.chain(contentItems).map('sourceWorksheet').uniq().value();
            const sourceWorkstepIds = _.chain(contentItems).map('sourceWorkstep').uniq().value();
            return { sourceWorksheetIds, sourceWorkstepIds };
          })
          .then(({ sourceWorksheetIds, sourceWorkstepIds }) =>
            fetchWorksheetItems(sourceWorksheetIds, sourceWorkstepIds),
          ),
    );
  };

  const fetchItemsForTopicsOrAnalysis = () => {
    const worksheetId = _.first(worksheetIds);
    sqWorkbooksApi
      .getWorksheet({ workbookId, worksheetId })
      .then((result) =>
        _.isNil(result.data.report?.id) ? fetchWorksheetItems(worksheetIds, workstepIds) : fetchTopicItems(),
      );
  };

  const reset = () => {
    sqExploreWorkbookModalActions.clear();
    setModalIsOpen(false);
  };

  const setContent = () => {
    fetchItemsForTopicsOrAnalysis();
    reset();
  };

  const stepDefinitions = [
    {
      title: 'ITEMS_FROM_WORKSHEET.SELECT_WORKBOOK',
      component: (
        <ExplorerModalBody
          show={true}
          next={_.noop}
          store={sqExploreWorkbookModalStore}
          actions={sqExploreWorkbookModalActions}
          allowedTypes={allowedTypes}
        />
      ),
      shouldGoToNext: { canGoToNext: !!workbookId, showActionButton: false },
      modalClassName: 'items-from-worksheet-modal',
      actionButtonLabel: 'SELECT',
    },
    {
      customHeader: (
        <SelectWorksheetHeader
          title="ITEMS_FROM_WORKSHEET.SELECT_WORKSHEETS"
          subtitle="ITEMS_FROM_WORKSHEET.INFO"
          workbookId={workbookId}
          worksheetIds={worksheetIds}
          setWorksheetIds={setWorksheetIds}
          workstepIds={workstepIds}
          setWorkstepIds={setWorkstepIds}
          totalWorksheets={totalWorksheets}
          setTotalWorksheets={setTotalWorksheets}
          isAllSelectedOnStartup={true}
        />
      ),
      title: 'ITEMS_FROM_WORKSHEET.SELECT_WORKSHEETS',
      component: (
        <SelectWorksheet
          workstepIds={workstepIds}
          worksheetIds={worksheetIds}
          workbookId={workbookId}
          setWorksheetIds={setWorksheetIds}
          setWorkstepIds={setWorkstepIds}
          setTotalWorksheets={setTotalWorksheets}
        />
      ),
      actionButtonLabel: 'INSERT',
      actionFn: setContent,
      backButtonFn: () => {
        sqExploreWorkbookModalActions.setWorkbookId(undefined);
        setStepChanged(!stepChanged);
      },
      valid: !noWorksheetsSelected,
      modalClassName: 'items-from-worksheet-modal',
    },
  ];

  return <WizardModal onClose={reset} testId="wizardGetItemsFromWorksheets" stepDefinitions={stepDefinitions} />;
};
