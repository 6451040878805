// @ts-strict-ignore
import _ from 'lodash';
import { PLUGIN_CATEGORY } from '@/hybrid/plugin/pluginHost.constants';
import { PluginOutputV1 } from '@/sdk';
import { PersistenceLevel, Store } from '@/core/flux.service';

export class PluginStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqPluginStore';

  initialize() {
    this.state = this.immutable({
      plugins: this.state?.get?.('plugins') || [], // preserve any existing plugins state
      pluginState: {},
      displayPaneRenderComplete: true,
      queryParam: undefined,
    });
  }

  get plugins() {
    return this.state.get('plugins');
  }

  get pluginState() {
    return this.state.get('pluginState');
  }

  get displayPaneRenderComplete() {
    return this.state.get('displayPaneRenderComplete');
  }

  get queryParam() {
    return this.state.get('queryParam');
  }

  displayPanePlugins() {
    return _.filter(this.state.get('plugins'), {
      category: PLUGIN_CATEGORY.DISPLAY_PANE,
    });
  }

  homeScreenPlugins(): PluginOutputV1[] {
    return _.filter(this.state.get('plugins'), {
      category: PLUGIN_CATEGORY.HOME_SCREEN,
    }) as PluginOutputV1[];
  }

  /**
   * Gets a plugin by its pluginIdentifier from the store
   *
   * @param {string} identifier - the plugin identifier
   */
  getPlugin(identifier): PluginOutputV1 {
    return _.chain(this.state.get('plugins')).filter({ identifier }).first().value() as PluginOutputV1;
  }

  dehydrate() {
    return _.omit(this.state.serialize(), ['plugins', 'displayPaneRenderComplete', 'queryParam']);
  }

  rehydrate(dehydratedState) {
    this.state.deepMerge(dehydratedState);
  }

  protected readonly handlers = {
    /**
     * Sets the list of plugins into the store
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.plugins - the new list of plugins to be set into the store
     */
    PLUGINS_SET: (payload) => this.state.set('plugins', payload.plugins),
    /**
     * Sets the plugin state for a given plugin into the store
     *
     * @param {Object} payload - Object container for arguments
     * @param {string} payload.pluginIdentifier - the identifier of the plugin
     * @param {Object} payload.pluginState - the new state
     */
    PLUGINS_SET_STATE: (payload) => this.state.set(['pluginState', payload.pluginIdentifier], payload.pluginState),
    /**
     * Sets the display pane render complete into the store
     *
     * @param {Object} payload - Object container for arguments
     * @param {boolean} payload.complete - if the display pane render is complete
     */
    PLUGINS_SET_DISPLAY_PANE_RENDER_COMPLETE: (payload) =>
      this.state.set('displayPaneRenderComplete', payload.complete),

    /**
     * Sets the HomeScreen add-on query parameter into the store, so it can be passed to the plugin when it loads
     *
     * @param {Object} payload - Object container for arguments
     * @param {string} payload.queryParam - the query parameter
     */
    PLUGINS_SET_QUERY_PARAM: (payload) => this.state.set('queryParam', payload.queryParam),
  };
}
