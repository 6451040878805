// @ts-strict-ignore
import _ from 'lodash';
import { TableBuilderSimpleTable } from '@/hybrid/tableBuilder/TableBuilderSimpleTable.organism';
import { TableBuilderConditionTable } from '@/hybrid/tableBuilder/TableBuilderConditionTable.organism';
import { Accordion, Alert } from 'react-bootstrap';
import { Icon } from '@/hybrid/core/Icon.atom';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  provideVisualizationData,
  serializeDisplayRange,
} from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';

import { TableBuilderHeaders, TableBuilderSort } from '@/hybrid/tableBuilder/tableBuilder.store';
import { TableColumnFilter } from '@/hybrid/core/tableUtilities/tables';
import { RangeExport } from '@/trendData/duration.store';
import { TableTextFormatterIF } from '@/hybrid/tableBuilder/TableBuilder.molecule';
import { TableViewer } from '@/hybrid/tableBuilder/tableViewer/TableViewer.molecule';
import { ChartSettingsInterface } from './tableViewer/ChartSettings.molecule';
import { NewLineToBr } from '@/hybrid/core/NewLineToBr.atom';
import { headlessRenderMode } from '@/hybrid/utilities/utilities';
import { Visualization } from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities.constants';
import { TableBuilderMode } from '@/hybrid/tableBuilder/tableBuilder.constants';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { CustomToggle } from '@/hybrid/core/CustomToggle.atom';

const tableBuilderPropsOnlyBindings = bindingsDefinition({
  canEdit: prop<boolean>(),
  isSimpleMode: prop<boolean>(),
  startMode: prop<boolean>(),
  isLoading: prop<boolean>(),
  hasOnlyStringSeries: prop<boolean>(),
  hasNumericAndStringSeries: prop<boolean>(),
  hasOnlyStringMetrics: prop<boolean>(),
  hasNumericAndStringMetrics: prop<boolean>(),
  setCellText: prop<(key: string, value: string, id?: string) => void>(),
  setHeaderText: prop<(columnKey: string, text: string) => void>(),
  setColumnFilter: prop<(key: string, filter: TableColumnFilter) => void>(),
  simpleTableData: prop<any>(),
  conditionTableData: prop<any>(),
  headers: prop<TableBuilderHeaders>(),
  simpleColumns: prop<any>(),
  conditionColumns: prop<any>(),
  isTransposed: prop<boolean>(),
  isTableStriped: prop<boolean>(),
  isPresentationMode: prop<boolean>(),
  isViewOnlyMode: prop<boolean>(),
  displayRange: prop<RangeExport>(),
  timezone: prop<{ name: string }>(),
  displayMetricOnTrend:
    prop<(metricId: string, formulaItemId: string, start: number, end: number, event: any) => void>(),
  columnToThresholdsForCondition: prop<Object>(),
  columnToThresholdsForSimple: prop<Object>(),
  distinctStringValueMap: prop<{
    [mode: string]: { [columnKey: string]: string[] };
  }>(),
  textFormatter: prop<TableTextFormatterIF>(),
  moveColumn: prop<(key: string, newKey: string) => void>(),
  canSort: prop<boolean>(),
  maxSortLevel: prop<number>(),
  sortByColumn: prop<(key: string, direction: string) => void>(),
  removeColumn: prop<(key: string) => void>(),
  itemFilters: prop<{ [id: string]: { filter: TableColumnFilter } }>(),
  itemSorts: prop<{ [id: string]: TableBuilderSort }>(),
  setActiveTool: prop<(toolName: string) => void>(),
  fetchFailedMessage: prop<{ message: string; apiMessage: string } | undefined>(),
  setActiveRefElement: prop<any>(),
  showChartView: prop<boolean>(),
  chartViewSettings: prop<ChartSettingsInterface>(),
  fetchStringColumnValues: prop<(columnKey: string, isStringColumn: boolean, cancellationGroup: string) => void>(),
  setChartViewSettings: prop<(property: any) => void>(),
  onContentLoad: prop.optional<() => void>(),
});
export type TableBuilderPropsOnlyBindings = typeof tableBuilderPropsOnlyBindings;
export let TableBuilderPropsOnly: SeeqComponent<typeof tableBuilderPropsOnlyBindings> = (props) => {
  const {
    isSimpleMode,
    startMode,
    canEdit,
    setCellText,
    setColumnFilter,
    setHeaderText,
    isLoading,
    hasOnlyStringMetrics,
    hasNumericAndStringMetrics,
    hasNumericAndStringSeries,
    hasOnlyStringSeries,
    simpleTableData,
    conditionTableData,
    headers,
    canSort,
    displayMetricOnTrend,
    displayRange,
    isPresentationMode,
    isTransposed,
    maxSortLevel,
    textFormatter,
    itemFilters,
    isTableStriped,
    columnToThresholdsForCondition,
    columnToThresholdsForSimple,
    distinctStringValueMap,
    moveColumn,
    sortByColumn,
    removeColumn,
    isViewOnlyMode,
    itemSorts,
    conditionColumns,
    simpleColumns,
    timezone,
    fetchFailedMessage,
    setActiveRefElement,
    setActiveTool,
    showChartView,
    chartViewSettings,
    onContentLoad,
    fetchStringColumnValues,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    onContentLoad?.();
  }, []);

  const hasFetchError = !_.isEmpty(fetchFailedMessage?.message);

  if (headlessRenderMode() && !startMode) {
    provideVisualizationData({ ...props, visualization: Visualization.TABLE }, { displayRange: serializeDisplayRange });
  }

  // Ensures alert is not centered in headless mode, because otherwise renderer only captures empty space
  const alertWrapper = (alert) => (
    <div className={headlessRenderMode() ? '' : 'flexRowContainer flexCenter flexFill'}>{alert}</div>
  );

  return (
    <>
      <div className={`flexRowContainer flexFill${isPresentationMode ? '' : ' p20'}`}>
        {isSimpleMode && !startMode && !hasFetchError && (
          <div className={showChartView ? 'flexFill flexColumnContainer' : 'overflowYAuto'} ref={setActiveRefElement}>
            {!showChartView && (
              <TableBuilderSimpleTable
                simpleTableData={simpleTableData}
                isLoading={isLoading}
                columns={simpleColumns}
                headers={headers}
                isTransposed={isTransposed}
                isStriped={isTableStriped}
                isPresentationMode={isPresentationMode}
                isViewOnlyMode={isViewOnlyMode}
                displayRange={displayRange}
                timezone={timezone}
                displayMetricOnTrend={displayMetricOnTrend}
                setCellText={setCellText}
                setHeaderText={setHeaderText}
                setColumnFilter={setColumnFilter}
                columnToThresholds={columnToThresholdsForSimple}
                hasOnlyStringSeries={hasOnlyStringSeries}
                hasNumericAndStringSeries={hasNumericAndStringSeries}
                hasOnlyStringMetrics={hasOnlyStringMetrics}
                hasNumericAndStringMetrics={hasNumericAndStringMetrics}
                distinctStringValueMap={distinctStringValueMap?.[TableBuilderMode.Simple]}
                textFormatter={textFormatter}
                canSort={canSort}
                maxSortLevel={maxSortLevel}
                moveColumn={moveColumn}
                removeColumn={removeColumn}
                sortByColumn={sortByColumn}
              />
            )}
            {showChartView && (
              <TableViewer
                testId="tableChartViewer"
                storeSettings={chartViewSettings}
                data={simpleTableData}
                columns={simpleColumns}
                isTransposed={isTransposed}
              />
            )}
          </div>
        )}
        {!isSimpleMode && !startMode && !hasFetchError && (
          <div className="overflowYAuto" ref={setActiveRefElement}>
            <TableBuilderConditionTable
              tableData={conditionTableData}
              isLoading={isLoading}
              headers={headers}
              columns={conditionColumns}
              isTransposed={isTransposed}
              isStriped={isTableStriped}
              isPresentationMode={isPresentationMode}
              canEdit={canEdit}
              timezone={timezone}
              setCellText={setCellText}
              setHeaderText={setHeaderText}
              setColumnFilter={setColumnFilter}
              itemFilters={itemFilters}
              fetchStringColumnValues={fetchStringColumnValues}
              columnToThresholds={columnToThresholdsForCondition}
              distinctStringValueMap={distinctStringValueMap?.[TableBuilderMode.Condition]}
              textFormatter={textFormatter}
              canSort={canSort}
              itemSorts={itemSorts}
              maxSortLevel={maxSortLevel}
              moveColumn={moveColumn}
              removeColumn={removeColumn}
              displayMetricOnTrend={displayMetricOnTrend}
              sortByColumn={sortByColumn}
            />
          </div>
        )}
        {hasFetchError &&
          !startMode &&
          alertWrapper(
            <Alert variant="info" className="max-width-400 screenshotSizeToContent fs fs16" transition={false}>
              <NewLineToBr lineToBreak={fetchFailedMessage?.message} />

              {fetchFailedMessage.apiMessage && (
                <Accordion className="pt10 font-size-smaller">
                  <CustomToggle
                    eventKey="0"
                    Component={({ onClick, label, isCardOpen }) => (
                      <div
                        onClick={onClick}
                        data-testid="detailsToggle"
                        className="sq-text-primary cursorPointer flexColumnContainer flexAlignCenter">
                        <span className="pr5">{t(label)}</span>
                        <Icon icon={isCardOpen ? 'fa-chevron-down' : 'fa-chevron-right'} />
                      </div>
                    )}
                    label="TABLE_BUILDER.VIEW_DETAILS"
                  />
                  <Accordion.Collapse eventKey="0" data-testid="apiMessage">
                    <span>{fetchFailedMessage.apiMessage}</span>
                  </Accordion.Collapse>
                </Accordion>
              )}
            </Alert>,
          )}
        {startMode &&
          alertWrapper(
            <Alert variant="info" className="width-400 fs16 p25 screenshotSizeToContent" transition={false}>
              {!canEdit && <p>{t('TABLE_BUILDER.START_MODE_NO_CONTENT')}</p>}
              {canEdit && ( // TODO CRAB-27446: <p> cannot appear as a descendant of <p>
                <p>
                  <p>{t('TABLE_BUILDER.START_MODE')}</p>
                  <a className="cursorPointer" onClick={() => setActiveTool(TREND_TOOLS.THRESHOLD_METRIC)}>
                    <Icon icon="fa-plus-circle" extraClassNames="p5" type="theme" testId="addFirstMetricButton" />
                    <span>{t('TABLE_BUILDER.ADD_METRIC')}</span>
                  </a>
                </p>
              )}
            </Alert>,
          )}
      </div>
    </>
  );
};
