// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { FormElement, FORM_ERROR } from '@/hybrid/formbuilder/formBuilder.module';
import { useAuth } from '@/hybrid/main/auth/hooks/useAuth.hook';
import { MAX_NAME_LENGTH, PASSWORD_MIN_LENGTH } from '@/main/app.constants';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';

export type RegisterFormProps = {
  seeqDomain: any;
  domain: any;
  updateDomain: (domain) => void;
  toggleDisplay: () => void;
};

export const RegisterForm: React.FunctionComponent<RegisterFormProps> = ({
  seeqDomain,
  domain,
  updateDomain,
  toggleDisplay,
}) => {
  const {
    updateFormData,
    formData: { firstName, lastName, username, password, passwordCheck },
    serverMessage,
    register,
  } = useAuth({ seeqDomain, domain, updateDomain });

  const [newUsernameFocused, setNewUsernameFocused] = useState(false);

  const formDefinition: FormElement[] = [
    {
      component: 'FormControlFormComponent',
      name: 'firstName',
      testId: 'firstName',
      label: 'USER.FIRST_NAME',
      placeholder: 'USER.FIRST_NAME_PROMPT',
      value: firstName,
      onChange: (firstName: string) => updateFormData({ firstName }),
      size: 'lg',
      autoComplete: 'given-name',
      maxLength: MAX_NAME_LENGTH.NAME,
      required: true,
      customErrorText: firstName.length > MAX_NAME_LENGTH.NAME ? 'NAME_LENGTH_ERROR' : '',
      customErrorParams: { count: MAX_NAME_LENGTH.NAME },
    },
    {
      component: 'FormControlFormComponent',
      name: 'lastName',
      testId: 'lastName',
      label: 'USER.LAST_NAME',
      placeholder: 'USER.LAST_NAME_PROMPT',
      value: lastName,
      onChange: (lastName: string) => updateFormData({ lastName }),
      size: 'lg',
      autoComplete: 'family-name',
      maxLength: MAX_NAME_LENGTH.NAME,
      required: true,
      customErrorText: lastName.length > MAX_NAME_LENGTH.NAME ? 'NAME_LENGTH_ERROR' : '',
      customErrorParams: { count: MAX_NAME_LENGTH.NAME },
    },
    {
      component: 'FormControlFormComponent',
      name: 'newUsername',
      testId: 'newUsername',
      label: 'USER.EMAIL',
      placeholder: 'USER.EMAIL_PROMPT',
      type: 'email',
      value: username,
      onChange: (username: string) => updateFormData({ username }),
      size: 'lg',
      autoComplete: 'username',
      onFocus: () => setNewUsernameFocused(true),
      onBlur: () => setNewUsernameFocused(false),
      required: true,
      customErrorText: newUsernameFocused ? 'USER.EMAIL_INVALID' : '',
    },
    {
      component: 'FormControlFormComponent',
      name: 'password',
      testId: 'password',
      label: 'USER.PASSWORD',
      placeholder: 'USER.PASSWORD_PROMPT',
      type: 'password',
      value: password,
      onChange: (password: string) => updateFormData({ password }),
      size: 'lg',
      autoComplete: 'new-password',
      minLength: PASSWORD_MIN_LENGTH,
      required: true,
      customErrorText: password.length < PASSWORD_MIN_LENGTH ? 'USER.PASSWORD_MIN_LENGTH' : '',
      customErrorParams: { min: PASSWORD_MIN_LENGTH },
    },
    {
      component: 'FormControlFormComponent',
      name: 'passwordCheck',
      testId: 'passwordCheck',
      label: 'USER.PASSWORD_CHECK',
      placeholder: 'USER.PASSWORD_CHECK_PROMPT',
      type: 'password',
      value: passwordCheck,
      onChange: (passwordCheck: string) => updateFormData({ passwordCheck }),
      size: 'lg',
      autoComplete: 'new-password',
      required: true,
      validation: (passwordCheck: string) => passwordCheck !== password,
      extendValidation: true,
      customErrorText: passwordCheck !== password ? 'USER.PASSWORD_MISMATCH' : '',
    },
    {
      component: 'ErrorMessageFormComponent',
      name: 'serverErrorMessage',
      testId: 'specServerErrorMessage',
      type: FORM_ERROR,
      value: serverMessage,
      failForm: false,
      includeIf: !!serverMessage,
    },
  ];

  return (
    <SimpleSaveFormBuilder
      testId="register"
      formDefinition={formDefinition}
      submitFn={register}
      closeFn={toggleDisplay}
      buttonExtraClassNames="mt25"
      submitBtnLabel="LOGIN_PANEL.REGISTER"
    />
  );
};
