// @ts-strict-ignore
import { addCsrfHeader, removeCsrfToken } from '@/hybrid/utilities/auth.utilities';
import HttpCodes from 'http-status-codes';
import _ from 'lodash';
import { APP_STATE } from '@/main/app.constants';

/**
 * An HTTP interceptor to handle the authentication of HTTP requests. For outgoing requests it will set the necessary
 * information to ensure the requests are authenticated if the user is logged in. Since the primary authentication
 * token is set as a httpOnly cookie by the backend, to ensure it is protected from XSS attacks, only the Cross Site
 * Forgery token needs to be set. See
 * https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html for an
 * explanation of why this is needed. This interceptor also takes care of sending the user to the login page if a
 * response code indicates they are no longer logged in.
 */
export function authenticationRequestInterceptor(config) {
  addCsrfHeader(config?.headers);
  return config;
}

export function authenticationResponseInterceptor(error) {
  const isOnLoginPage = window.location.pathname === `/${APP_STATE.LOGIN}`;
  const errorStatus = _.get(error, 'response.status');
  const errorMessage = _.get(error, 'error.response.data.statusMessage', '');
  if (
    !isOnLoginPage &&
    (errorStatus === HttpCodes.UNAUTHORIZED ||
      (errorStatus === HttpCodes.FORBIDDEN && errorMessage.match(/CSRF.*invalid/)))
  ) {
    // Force the cancellation of all pending requests
    window.stop();
    removeCsrfToken();
    window.location.href = `/${APP_STATE.LOGIN}?returnTo=${encodeURIComponent(window.location.href)}`;
    return Promise.reject(error);
  }
  // If in the login state and the server does not respond, then route to the load error state
  if (isOnLoginPage && HttpCodes.GATEWAY_TIMEOUT === errorStatus) {
    window.location.href = `/${APP_STATE.LOAD_ERROR}?returnState=${APP_STATE.LOGIN}`;
  }
  return Promise.reject(error);
}
