import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { baseEditorBindings } from '@/hybrid/annotation/BaseEditor.organism';
import { CKEditor } from '@/hybrid/annotation/CKEditor.organism';
import { FroalaEditor } from '@/hybrid/annotation/FroalaEditor.organism';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';

const editorBindings = bindingsDefinition({
  ...baseEditorBindings,
  sqReportEditor: injected<ReportEditorService>(),
});

export const Editor: SeeqComponent<typeof editorBindings> = (props) => {
  const { sqReportEditor } = useInjectedBindings(editorBindings);
  if (sqReportEditor.isCkEditor()) {
    return <CKEditor {...props} />;
  } else {
    return <FroalaEditor {...props} />;
  }
};

export const sqEditor = angularComponent(editorBindings, Editor);
