// @ts-strict-ignore
import _ from 'lodash';
import { sqSystemApi } from '@/sdk/api/SystemApi';
import { sqUserGroupsApi } from '@/sdk/api/UserGroupsApi';
import { convertDuration } from '@/hybrid/datetime/dateTime.utilities';
import { PriorityV1 } from '@/sdk/model/PriorityV1';
import { Timezone } from '@/datetime/timezone.service';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { setSystemMessage, setSystemWarnings } from '@/hybrid/systemWarning/systemWarning.actions';
import { sqTimezones } from '@/hybrid/utilities/datetime.constants';
import { FrontendDuration } from '@/services/systemConfiguration.constants';
import { ConfigurationOptionOutputSimpleV1 } from '@/sdk';
import { AUTO_FORMAT } from '@/hybrid/utilities/stringHelper.utilities';

let configurationData;
let supportedUnits;
let everyoneGroupEnabled = true;

export function fetchConfiguration() {
  return Promise.resolve(sqSystemApi.getServerStatus({ includeInternal: false }))
    .then(({ data }) => {
      const updatedConfigurationOptions = _.transform(
        data.configurationOptions,
        (memo, option) => {
          memo[option.path] = option.value;
        },
        {},
      );
      const offset = data.timeZone?.split(' ')?.[1];

      configurationData = {
        ...data,
        configurationOptions: updatedConfigurationOptions as unknown as ConfigurationOptionOutputSimpleV1[],
        timeZone: sqTimezones.findMostCommonTimeZone(offset),
        defaultNumberFormat: updatedConfigurationOptions['Features/NumberFormat'] as string,
      };
      setSystemWarnings(data.serverSpecs);
      setSystemMessage(systemMessage());
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

export function isConfigurationLoaded() {
  return configurationData !== undefined;
}

// Because we need the user groups API, this has to be done after login
export function fetchEveryoneDisabled() {
  return sqUserGroupsApi.getUserGroups({ nameSearch: 'Everyone' }).then(({ data }) => {
    const everyoneGroup = _.find(data.items, { name: 'Everyone' });
    everyoneGroupEnabled = !_.isUndefined(everyoneGroup) && everyoneGroup.isEnabled;
  });
}

export function assetGroupEditorEnabled() {
  return getConfigurationOption('Features/AssetGroupEditor/Enabled');
}

export function seeqDirectoryEnabled() {
  return getConfigurationOption('Authentication/SeeqDirectory/Enabled');
}

// Memoized since it is not expected to change, but only needed in some tools
export function getSupportedUnits(): Promise<{ [key: string]: string[] }> {
  if (_.isUndefined(supportedUnits)) {
    supportedUnits = sqSystemApi.getSupportedUnits().then(({ data: { units } }) => units);
  }

  return supportedUnits;
}

function serverConfiguration() {
  if (_.isUndefined(configurationData)) {
    throw new Error('Configuration must be fetched before being accessed');
  }

  return configurationData;
}

export function defaultMaxInterpolation(): FrontendDuration {
  return convertDuration(serverConfiguration().defaultMaxInterpolation);
}

export function priorityColors(): PriorityV1[] {
  return serverConfiguration().priorities;
}

export function serverTimezone(): Timezone {
  return configurationData?.timeZone;
}

export function maxScatterPlotSamples() {
  return getConfigurationOption('Features/ScatterPlot/MaxPoints');
}

export function serverSpecs() {
  return serverConfiguration().serverSpecs;
}

export function restrictLogs() {
  return getConfigurationOption('Features/RestrictLogs/Enabled');
}

export function registrationEnabled() {
  return getConfigurationOption('Features/UserRegistration/Enabled');
}

export function authDefaultProviderId() {
  return getConfigurationOption('Authentication/DefaultProviderId');
}

export function authAutoLogin() {
  return getConfigurationOption('Authentication/AutoLogin');
}

export function isAuthOnContentImageEnabled() {
  return getConfigurationOption('Authentication/ContentImage/Enabled');
}

export function isTelemetryEnabled() {
  return getConfigurationOption('Features/Telemetry/Enabled');
}

export function isTelemetryAnonymized() {
  return getConfigurationOption('Features/Telemetry/Anonymized');
}

export function adminContactName(): string {
  return getConfigurationOption('Installation/AdminContact/Name');
}

export function adminContactEmail(): string {
  return getConfigurationOption('Installation/AdminContact/Email');
}

export function defaultNumberFormat() {
  return configurationData?.defaultNumberFormat ?? AUTO_FORMAT;
}

export function defaultStringFormat() {
  return getConfigurationOption('Features/StringFormat');
}

export function addOnToolsEnabled() {
  return getConfigurationOption('Features/AddOnTools/Enabled');
}

export function systemMessage() {
  return getConfigurationOption('Features/Messages/SystemMessage');
}

export function auditingEnabled() {
  return getConfigurationOption('Features/Auditing/Enabled');
}

export function dataLabHostname() {
  return getConfigurationOption('Network/DataLab/Hostname');
}

export function auditingAllUsersCanRead() {
  return getConfigurationOption('Features/Auditing/AllUsersCanRead');
}

export function dayFirstDefault() {
  return getConfigurationOption('Features/Datafiles/DayFirstDefault') as boolean;
}

export function datasourceManagementEnabled() {
  return getConfigurationOption('Features/DatasourceManagement/Enabled');
}

export function displaysEnabled() {
  return getConfigurationOption('Features/Displays/Enabled');
}

export function delimiterDefault() {
  return getConfigurationOption('Features/Datafiles/DelimiterDefault').toLowerCase();
}

export function officeHoursEnabled() {
  return getConfigurationOption('Features/HomeScreen/OfficeHours/Enabled');
}

export function customSidebar() {
  return getConfigurationOption('Features/HomeScreen/CustomSidebar');
}

export function pluginsEnabled() {
  return getConfigurationOption('Features/Plugins/Enabled');
}

export function hardwareWarningsEnabled() {
  return getConfigurationOption('Features/HardwareWarnings/Enabled');
}

export function isEveryoneGroupEnabled() {
  return everyoneGroupEnabled;
}

export function isCkEditorEnabled() {
  return getConfigurationOption('Features/CKEditor/Enabled');
}

export function isCkAutoUpgradeEnabled() {
  return getConfigurationOption('Features/CKEditor/AutoUpgrade');
}

export function isAdvancedDateRangeSwapEnabled() {
  return getConfigurationOption('Features/AdvancedDateRangeSwap/Enabled');
}

export function atUserLimit() {
  return configurationData?.atUserLimit || false;
}

export function isSeeqPreviewEnabled() {
  return getConfigurationOption('Features/SeeqPreview/Enabled');
}

export function isMixpanelEnabled() {
  return getConfigurationOption('Features/Mixpanel/Enabled');
}

export function getFeedbackEnabled() {
  return getConfigurationOption('Features/Feedback/Enabled');
}

export function isAnnouncementsEnabled() {
  return getConfigurationOption('Features/Announcements/Enabled');
}

function getConfigurationOption(path: string): any {
  if (!_.has(serverConfiguration().configurationOptions, path)) {
    throw new TypeError(`${path} is not a valid configuration option`);
  }

  return serverConfiguration().configurationOptions[path];
}

export function getDefaultMaxInterpolation(): FrontendDuration {
  return convertDuration(serverConfiguration().defaultMaxInterpolation);
}

export function getDefaultMaxCapsuleDuration() {
  return convertDuration(serverConfiguration().defaultMaxCapsuleDuration);
}

export function getLoginMessage(): string {
  return getConfigurationOption('Features/Login/InfoMessage');
}

// only used for testing
export function resetConfigurationData() {
  configurationData = undefined;
}
