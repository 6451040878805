import React, { useState } from 'react';
import { Icon } from '@/hybrid/core/Icon.atom';
import {
  dependencyRemoved,
  findAtLeastOneChild,
  findChild,
  getCellIcon,
  getCellIconType,
} from '@/hybrid/assetGroupEditor/assetGroup.utilities';
import { doTrack } from '@/track/track.service';
import _ from 'lodash';
import { AssetGroupAsset, AssetGroupChild } from '@/hybrid/assetGroupEditor/assetGroup.types';
import { sqAssetGroupStore } from '@/core/core.stores';
import { AssetGroupCellDebug } from '@/hybrid/assetGroupEditor/AssetGroupCellDebug.atom';
import { AssetFinderModal } from '@/hybrid/assetGroupEditor/AssetFinderModal';
import { CalculationDetailModal } from '@/hybrid/assetGroupEditor/CalculationDetailModal';
import { AssetDetailModal } from '@/hybrid/assetGroupEditor/AssetDetailModal';

interface AssetGroupCellProps {
  asset: AssetGroupAsset;
  columnName: string;
  debug?: boolean;
}

export const AssetGroupCell: React.FunctionComponent<AssetGroupCellProps> = ({ asset, columnName, debug = false }) => {
  type ModalType = 'details' | 'finder' | 'none';
  const [modalConfig, setModalConfig] = useState({
    modalShown: 'none',
    returnToOnClose: 'none',
  });
  const [currentItem, setCurrentItem] = useState<AssetGroupCellProps>({
    asset: { name: '', id: '', ancestors: [] },
    columnName: '',
  });

  const child = findChild(asset, columnName);
  let columnChild = null;

  // if we have an empty child findAtLeast one child so we can determine if the row is a calculation or a item
  // assignment so we can show the correct modal
  if (!child) {
    columnChild = findAtLeastOneChild(sqAssetGroupStore.assets, columnName);
  }
  if (!child && !columnChild) {
    return null;
  }
  const childType = child ? child.columnType : columnChild?.columnType;

  const icon = getCellIcon(child);
  const type = getCellIconType(child);
  const color = type === 'color' ? '#30ad8e' : undefined;

  const openItemFinder = (asset: AssetGroupAsset, columnName: string) => {
    setCurrentItem({ asset, columnName });
    setModalConfig({ modalShown: 'finder', returnToOnClose: 'none' });
  };

  const openCalculationDetails = (asset: AssetGroupAsset, columnName: string) => {
    doTrack('Asset Group Editor', 'Calculation Details Modal', 'Opened');
    setCurrentItem({ asset, columnName });
    setModalConfig({
      modalShown: 'calculationDetails',
      returnToOnClose: 'none',
    });
  };

  const openDetails = (asset: AssetGroupAsset, columnName: string) => {
    doTrack('Asset Group Editor', 'Details Modal', 'Opened');
    setCurrentItem({ asset, columnName });
    setModalConfig({ modalShown: 'details', returnToOnClose: 'none' });
  };

  const closeModal = () => {
    const properModal = modalConfig.returnToOnClose !== 'none' ? modalConfig.returnToOnClose : 'none';
    setModalConfig({ modalShown: properModal, returnToOnClose: 'none' });
  };

  // avoid returning to details panel if item is assigned
  const closeAfterAssignment = () => {
    setModalConfig({ modalShown: 'none', returnToOnClose: 'none' });
  };

  const closeCurrentModalAndOpenAnother = (modalToOpen: ModalType, returnToOnClose: ModalType) => {
    setModalConfig({ modalShown: modalToOpen, returnToOnClose });
  };

  const getCellIconClickHandler = (asset: AssetGroupAsset, columnName: string, child: AssetGroupChild | undefined) => {
    if (childType === 'Calculation') {
      openCalculationDetails(asset, columnName);
    } else if (!child || _.isEmpty(child.parameters)) {
      openItemFinder(asset, columnName);
    } else {
      openDetails(asset, columnName);
    }
  };

  const dependencyError = child && dependencyRemoved(asset, child);

  return (
    <>
      {dependencyError ? (
        <Icon
          testId="assetGroupCell_warning"
          icon="fa-exclamation-triangle"
          type="danger"
          large={false}
          tooltip="ASSET_GROUP_EDITOR.REMAP_ITEMS"
          onClick={() => getCellIconClickHandler(asset, columnName, child)}
        />
      ) : (
        <Icon
          testId={`assetGroupCell_${columnName}`}
          icon={icon}
          type={type}
          color={color}
          extraClassNames="cursorPointer"
          large={true}
          onClick={() => getCellIconClickHandler(asset, columnName, child)}
        />
      )}

      {child && debug && <AssetGroupCellDebug formula={child.formula || ''} parameters={child.parameters || []} />}
      {child && modalConfig.modalShown === 'details' && (
        <AssetDetailModal
          testId="assetDetailsModal"
          item={child}
          asset={currentItem.asset}
          swapItem={() => closeCurrentModalAndOpenAnother('finder', 'details')}
          onClose={closeModal}
        />
      )}
      {modalConfig.modalShown === 'finder' && (
        <AssetFinderModal
          testId="assetFinderModal"
          asset={currentItem.asset}
          onCloseAfterAssignment={closeAfterAssignment}
          columnName={currentItem.columnName}
          onClose={closeModal}
        />
      )}
      {modalConfig.modalShown === 'calculationDetails' && (
        <CalculationDetailModal
          testId="calculationDetailModal"
          item={!child ? { name: columnName, columnType: 'Calculation', parameters: [], formula: '' } : child}
          asset={currentItem.asset}
          columnName={currentItem.columnName}
          onClose={closeModal}
        />
      )}
    </>
  );
};
