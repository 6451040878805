// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import _ from 'lodash';
import classNames from 'classnames';
import { Icon, IconType } from '@/hybrid/core/Icon.atom';

/**
 * Help button with dropdown for custom content
 */
const helpButtonBindings = bindingsDefinition({
  customChildren: prop.optional<any>(),
  knowledgeBaseUrl: prop.optional<string>(),
  knowledgeBaseLinkText: prop.optional<string>(),
  imgPath: prop.optional<string>(),
  textBeforeImage: prop.optional<string>(),
  customKnowledgeBaseLink: prop.optional<any>(),
  iconClassNames: prop.optional<string>(),
  iconType: prop.optional<IconType>(),
  placement: prop.optional<Placement>(),
  id: prop.optional<string>(),
});

export const HelpButton: SeeqComponent<typeof helpButtonBindings> = ({
  customChildren,
  imgPath,
  textBeforeImage,
  knowledgeBaseLinkText,
  knowledgeBaseUrl,
  customKnowledgeBaseLink,
  iconType = 'theme',
  iconClassNames = '',
  placement = 'bottom',
  id = undefined,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flexColumnContainer flexAlignCenter mr5">
      <OverlayTrigger
        trigger="click"
        rootClose={true}
        placement={placement}
        overlay={
          <Popover id={id ? id : 'itemsIdPopover'} className="max-width-800">
            <Popover.Content>
              <div className="flexRowContainer flexAlignCenter">
                {!_.isNil(customChildren) && customChildren}
                {_.isNil(customChildren) && (
                  <div className="max-width-800">
                    <span>{t(textBeforeImage)}</span>
                    <img alt="img" data-testid="helpImage" className="width-780" src={imgPath} />
                  </div>
                )}
                {!_.isNil(customKnowledgeBaseLink) && customKnowledgeBaseLink}
                {_.isNil(customKnowledgeBaseLink) && (
                  <a target="_blank" href={knowledgeBaseUrl}>
                    {t(knowledgeBaseLinkText)}
                  </a>
                )}
              </div>
            </Popover.Content>
          </Popover>
        }
        transition={false}>
        <div data-testid="questionIcon">
          <Icon
            icon="fa-question-circle"
            type={iconType}
            extraClassNames={classNames('cursorPointer', iconClassNames)}
          />
        </div>
      </OverlayTrigger>
    </div>
  );
};
