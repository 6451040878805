// @ts-strict-ignore
import _ from 'lodash';
import { BasicCursor } from '@/hybrid/utilities/cursor.constants';
import { PersistenceLevel, Store } from '@/core/flux.service';
import { sqTrendStore } from '@/core/core.stores';

export class CursorStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqCursorStore';

  /**
   * Initializes the store by setting default values to the stored state
   */
  initialize() {
    this.state = this.immutable({
      calendarCursors: [],
      capsuleCursors: [],
      showValues: true,
    });
  }

  get calendarCursors(): BasicCursor[] {
    return this.state.get('calendarCursors');
  }

  get capsuleCursors(): BasicCursor[] {
    return this.state.get('capsuleCursors');
  }

  get showValues(): boolean {
    return this.state.get('showValues');
  }

  hasCursorsInSelectedRegion(capsuleTime) {
    const cursors = this.state.get(this.getCursorState(capsuleTime));
    const region = sqTrendStore.selectedRegion;

    return _.filter(cursors, (cursor) => cursor.xValue >= region.min && cursor.xValue <= region.max).length > 0;
  }

  /**
   * Dehydrates the item by retrieving the current set parameters in view
   *
   * @returns {Object} An object with the state properties as JSON
   */
  dehydrate() {
    return {
      calendarCursors: _.map(this.state.get('calendarCursors'), (item) => _.omit(item, ['points'])),
      capsuleCursors: _.map(this.state.get('capsuleCursors'), (item) => _.omit(item, ['points'])),
      showValues: this.state.get('showValues'),
    };
  }

  /**
   * Rehydrates item from dehydrated state
   *
   * @param {Object} dehydratedState - State object that should be restored
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  /**
   * Gets the string for the appropriate cursor array based on the specified flag.
   *
   * @param {Boolean} capsuleTime - True for capsule-time cursors; false for calendar-time cursors
   * @return {string} - string to use in state.select accessor
   */
  getCursorState(capsuleTime: boolean) {
    return capsuleTime ? 'capsuleCursors' : 'calendarCursors';
  }

  /**
   * Determines the next available numerical 'name' for a cursor. The name is generated by finding the largest name
   * in the specified set of cursors and adding 1.
   *
   * @param {Object[]} cursors - Array of cursors
   * @param {Number} cursors[].name - Name of existing cursor
   * @return {String} Next available name for new cursor
   */
  findNextCursorName(cursors: { name: string }[]): string {
    return (_.chain(cursors).map('name') as any)
      .max()
      .thru(function (value) {
        return _.isFinite(value) ? value + 1 : 1;
      })
      .value();
  }

  protected readonly handlers = {
    /**
     * Adds a new cursor to the store.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.xValue - x-value for the cursor to add
     * @param {Boolean} payload.capsuleTime - True to add as capsule-time cursor, false for calendar-time cursor
     * @param {Object} payload.points - Points for this cursor
     * @param {string} payload.points[key] - ID of the series for the points
     * @param {Number} payload.points[key][].yValue - y-value of the series with the specified ID for this cursor
     * @param {String} payload.points[key][].unitOfMeasure - units of measure for this cursor point
     */
    TREND_CURSOR_ADD: (payload: { xValue: number; capsuleTime: boolean; points: {} }) => {
      const cursors = this.getCursorState(payload.capsuleTime);
      const index = _.findIndex(this.state.get(cursors), ['xValue', payload.xValue]);

      if (index === -1) {
        this.state.select(cursors).push({
          xValue: payload.xValue,
          points: payload.points,
          name: this.findNextCursorName(this.state.get(cursors)),
          selected: false,
        });
      } else {
        this.state.set([cursors, index, 'points'], payload.points);
      }
    },

    /**
     * Deletes a cursor with the specified parameters.
     *
     * @param {Object} payload - Object container for arguments
     * @param {Number} payload.xValue - x-value for the cursor to delete
     * @param {Boolean} payload.capsuleTime - True if this is a capsule-time cursor, false for calendar-time
     */
    TREND_CURSOR_DELETE: (payload: { xValue: number; capsuleTime: boolean }) => {
      const cursors = this.getCursorState(payload.capsuleTime);
      const index = _.findIndex(this.state.get(cursors), ['xValue', payload.xValue]);
      if (index > -1) {
        this.state.unset([cursors, index]);
      }
    },

    TREND_CURSOR_DELETE_ALL: (payload: { capsuleTime: boolean }) => {
      this.state.set(this.getCursorState(payload.capsuleTime), []);
    },

    TREND_CURSOR_DELETE_SELECTED: (payload: { capsuleTime: boolean }) => {
      const cursors = this.getCursorState(payload.capsuleTime);
      const region = sqTrendStore.selectedRegion;

      const unselectedCursors = _.filter(
        this.state.get(cursors),
        (cursor) => cursor.xValue < region.min || cursor.xValue > region.max,
      );
      this.state.set(cursors, unselectedCursors);
    },

    /**
     * Sets the display of cursor y-values on/off.
     *
     * @param {Boolean} show - Whether or not to show the cursor values
     */
    TREND_CURSOR_SET_VALUE_FLAGS: (show: boolean) => {
      this.state.set('showValues', show);
    },

    /**
     * Sets the selected property of a cursor.
     *
     * @param {object} payload - Object container for arguments
     * @param {number} payload.selected - the value for the selected property
     * @param {number} payload.multiSelect - whether or not to multi-select
     * @param {boolean} payload.capsuleTime - True if this is a capsule-time cursor, false for calendar-time
     */
    TREND_CURSOR_TOGGLE_SELECTION: (payload: {
      selected: number;
      multiSelect: number;
      capsuleTime: boolean;
      xValue: number;
    }) => {
      const cursors = this.getCursorState(payload.capsuleTime);
      if (payload.selected && !payload.multiSelect) {
        // unselect previously selected unless we're multi-selecting
        const previouslySelected = _.filter(this.state.get(cursors), ['selected', true]);
        _.forEach(previouslySelected, (previous) => {
          const previouslySelectedIndex = _.findIndex(this.state.get(cursors), previous);
          this.state.set([cursors, previouslySelectedIndex, 'selected'], false);
        });
      }

      const index = _.findIndex(this.state.get(cursors), ['xValue', payload.xValue]);
      if (index > -1) {
        this.state.set([cursors, index, 'selected'], payload.selected);
      }
    },
  };
}
