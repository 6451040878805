import React from 'react';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { logInfo } from '@/hybrid/utilities/logger';

interface LoadingFallbackProps {
  fallbackLocation: string;
}

/**
 * Full page loading spinner to use as the fallback with React.Suspense
 */
export const LoadingFallback: React.FunctionComponent<LoadingFallbackProps> = ({ fallbackLocation }) => {
  React.useEffect(() => {
    logInfo(`Loading fallback encountered: ${fallbackLocation}`);
  }, []);

  return (
    <div data-testid="loadingFallback" className="flexColumnContainer flexCenter fullViewport">
      <div className="container ml0 mr0">
        <div className="row flexJustifyCenter">
          <IconWithSpinner spinning={true} extraClassNames="fa-5x" />
        </div>
      </div>
    </div>
  );
};
