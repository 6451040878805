// @ts-strict-ignore
import _ from 'lodash';
import {
  API_TYPES_TO_ITEM_TYPES,
  ITEM_CHILDREN_TYPES,
  ITEM_DATA_STATUS,
  ITEM_TYPES,
  PREVIEW_ID,
  TREND_STORES,
} from '@/trendData/trendData.constants';
import { sqTrendCapsuleStore, sqTrendSeriesStore, sqWorkbookStore } from '@/core/core.stores';
import { formatCapsuleLabel } from '@/hybrid/utilities/chartHelper.utilities';

/**
 * Gets all of the items that can be aligned to a lane and axis on the trend. If sqTrendStore.hideUnselectedItems is
 * true, this function will filter to only selected items if that list is not empty.
 *
 * @return {any[]} list of items
 */
export function getAlignableItems({
  workingSelection = false,
  conditionsOnly = false,
}: {
  /** if true only selected items (or all if none are selected) will be returned */
  workingSelection?: boolean;
  conditionsOnly?: boolean;
}): any[] {
  return getAllItems({
    includeSignalPreview: true,
    workingSelection,
    itemTypes: conditionsOnly ? [ITEM_TYPES.CAPSULE_SET] : [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR, ITEM_TYPES.METRIC],
    // The children types follow their parent to their lane and axis
    itemChildrenTypes: [],
  });
}

/**
 * Query the TREND_STORES for a list of items. By default this will return only the items in the details pane.
 *
 * @param {boolean} [includeSignalPreview] - include the preview signal (if available).
 * @param {boolean} [excludeEditingCondition] - include the condition being edited (if there is one).
 * @param {boolean} [workingSelection] - if true only selected items (or all if none are selected) will be returned
 * @param {string[]} [excludeDataStatus] - items without these dataStatus will be returned (list of ITEM_DATA_STATUS)
 * @param {string[]} [itemTypes] - only items with these types will be returned (list of ITEM_TYPES)
 * @param {string[]} [itemChildrenTypes] - child types to include (list of ITEM_CHILDREN_TYPES)
 * @return {Object[]} list of items
 */
export function getAllItems({
  includeSignalPreview = false,
  excludeEditingCondition = false,
  workingSelection = false,
  excludeDataStatus = [],
  itemTypes = [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET, ITEM_TYPES.SCALAR, ITEM_TYPES.TABLE, ITEM_TYPES.METRIC],
  itemChildrenTypes = [],
}: {
  includeSignalPreview?: boolean;
  excludeEditingCondition?: boolean;
  workingSelection?: boolean;
  excludeDataStatus?: ITEM_DATA_STATUS[];
  itemTypes?: ITEM_TYPES[];
  itemChildrenTypes?: ITEM_CHILDREN_TYPES[];
}) {
  // Note that this will never include new preview conditions because preview conditions don't exist.
  // The preview capsules reside in sqTrendCapsuleStore instead of sqTrendCapsuleSetStore.
  return _.chain(TREND_STORES)
    .flatMap((store) =>
      store === sqTrendSeriesStore && includeSignalPreview ? (store as any).itemsAndPreview : store.items,
    )
    .filter((item) => _.includes(itemTypes, item.itemType))
    .reject((item) => _.includes(excludeDataStatus, item.dataStatus))
    .filter((item) => !item.isChildOf || _.includes(itemChildrenTypes, item.childType))
    .reject((item) => excludeEditingCondition && item.id === sqTrendCapsuleStore.editingId)
    .thru((items) =>
      workingSelection && _.some(items, 'selected')
        ? _.filter(items, (item) => item.selected || item.id === PREVIEW_ID)
        : items,
    )
    .value();
}

/**
 * Query the TREND_STORES for a list of items including pinned and recently used items
 *
 * @param {boolean} [detailsPaneItems] - whether to include items from the details pane
 * @param {boolean} [pinnedItems] - whether to include items from the workbooks pinned array
 * @param {boolean} [recentlyAccessedItems] - whether to include items from the workbooks recently accessed array
 * @param {string[]} [itemTypes] - only items with these types will be returned (list of ITEM_TYPES)
 */
export function getAllExtendedItems({
  detailsPaneItems = true,
  pinnedItems = true,
  recentlyAccessedItems = true,
  itemTypes = [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET, ITEM_TYPES.SCALAR, ITEM_TYPES.TABLE, ITEM_TYPES.METRIC],
}: {
  detailsPaneItems?: boolean;
  pinnedItems?: boolean;
  recentlyAccessedItems?: boolean;
  itemTypes?: ITEM_TYPES[];
}) {
  return _.chain(!detailsPaneItems ? [] : getAllItems({ itemTypes }))
    .concat(!pinnedItems ? [] : sqWorkbookStore.pinned)
    .concat(!recentlyAccessedItems ? [] : sqWorkbookStore.recentlyAccessed)
    .uniqBy((item) => item.id)
    .filter((item) => _.includes(itemTypes, item.itemType ?? API_TYPES_TO_ITEM_TYPES[item.type]))
    .value();
}

/**
 * Query the TREND_STORES for a list of items. By default this will return most of the children items -
 * CAPSULE items are not included because they are strongly associated with conditions (i.e., when you want
 * capsules you usually only want the capsules within a specific condition).
 *
 * @param [itemTypes] - only items with these types will be returned (list of ITEM_TYPES)
 * @param [itemChildrenTypes] - child types to include (list of ITEM_CHILDREN_TYPES)
 * @return {Object[]} list of items
 */
export function getAllChildItems({
  itemTypes = [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET, ITEM_TYPES.SCALAR, ITEM_TYPES.TABLE, ITEM_TYPES.METRIC],
  itemChildrenTypes = [
    ITEM_CHILDREN_TYPES.ANCILLARY,
    ITEM_CHILDREN_TYPES.METRIC_DISPLAY,
    ITEM_CHILDREN_TYPES.METRIC_THRESHOLD,
    ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE,
  ],
}: {
  itemTypes?: ITEM_TYPES[];
  itemChildrenTypes?: ITEM_CHILDREN_TYPES[];
}) {
  return _.filter(
    getAllItems({
      itemTypes,
      itemChildrenTypes,
    }),
    'isChildOf',
  );
}

/**
 * Calls findItems on all the stores specified and returns the first item
 *
 * @param {Object[]} stores - one or more stores to look in for the item
 * @param {string} id - guid to search stores for
 * @return {Object} - item from one of the stores or undefined if it wasn't found
 */
export function findItemIn(stores, id) {
  return _.chain(stores).invokeMap('findItem', id).compact().first().value();
}

/**
 * Calls findChildren on all the stores specified and returns the combined children
 *
 * @param  {Object[]} stores - one or more stores to look in for the items
 * @param  {String} id - parent guid to search stores with
 * @return {Object[]} - item from one of the stores or undefined if it wasn't found
 */
export function findChildrenIn(stores, id) {
  return _.chain(stores).invokeMap('findChildren', id).flatten().compact().value();
}

/**
 * Determines the scopedTo property of a trend item
 *
 * @param id - the trend item ID
 * @returns {string} the scopedTo property of a trend item or, if it's a new item, then the current
 * workbook ID
 */
export function getTrendItemScopedTo(id, $state) {
  const item = findItemIn(TREND_STORES, id);
  // Only fall back to the workbookId for the scope if we have a new item (without an id)
  return item?.id ? item.scopedTo : $state.params.workbookId;
}

export function getSeriesIdsByCalculationType(calculationType, sqTrendSeriesStore) {
  return _.chain(sqTrendSeriesStore.nonCapsuleSeries)
    .filter((item) => item.calculationType === calculationType)
    .map('id')
    .value();
}

export function getDefaultCapsuleDataLabelSettings() {
  return {
    enabled: false,
    inside: true,
    crop: false, // This and overflow ensure labels work near the edge of the screen
    overflow: 'allow',
    allowOverlap: true, // Ensures Highcharts does not filter out labels it thinks would overlap
    className: 'highcharts-capsule-label',
    padding: 1,
    labelNames: [], // Used to add labels to lane
    formatter: formatCapsuleLabel,
  };
}
