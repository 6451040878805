let injector: ng.auto.IInjectorService;

export function getInjector(): ng.auto.IInjectorService {
  if (!injector) {
    const err = new Error();
    console.log(err.stack);
  }
  return injector;
}

export function setInjector($injector: ng.auto.IInjectorService) {
  injector = $injector;
}
