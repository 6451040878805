// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import _ from 'lodash';
import { IconSelect, ReactSelectOption } from '@/hybrid/core/IconSelect.molecule';
import { AssetSelection } from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';

const reportContentAssetBindings = bindingsDefinition({
  selection: prop<AssetSelection>(),
  assetSelections: prop<AssetSelection[]>(),
  setAssetSelection: prop<(any) => void>(),
});

export const ReportContentAssetSelection: SeeqComponent<typeof reportContentAssetBindings> = ({
  assetSelections,
  selection,
  setAssetSelection,
}) => {
  const { t } = useTranslation();

  const inheritOption: ReactSelectOption = {
    text: 'REPORT.CONFIG.INHERIT_FROM_WORKSHEET',
    value: 'inherit',
  };

  const assetSelectionOptions = [inheritOption].concat(
    _.chain(assetSelections)
      .sortBy(['name', 'id'])
      .compact()
      .map(
        (assetSelection): ReactSelectOption => ({
          text: assetSelection.name,
          value: assetSelection.id,
        }),
      )
      .value(),
  );

  const [selectedOption, setSelectedOption] = useState<ReactSelectOption>(
    selection ? _.find(assetSelectionOptions, { value: selection.id }) : _.first(assetSelectionOptions),
  );

  function setAssetSelectionAndTrack(assetSelection: AssetSelection | undefined) {
    doTrack('Topic', 'Update Content', 'asset selection changed');
    setAssetSelection(assetSelection);
  }

  const handleSetSelectedAsset = (option: ReactSelectOption) => {
    if (_.size(assetSelections)) {
      const assetSelectionMatch = _.find(assetSelections, { id: option.value });
      setAssetSelectionAndTrack(assetSelectionMatch);
    } else {
      setAssetSelectionAndTrack(undefined);
    }
    setSelectedOption(option);
  };

  return (
    <div className="flexRowContainer p10">
      <label htmlFor="assetSelectionSelect">{t('REPORT.CONFIG.ASSET_SELECTION')}</label>
      <IconSelect
        id="assetSelectionSelect"
        testId="assetSelectionSelect"
        insideModal={true}
        selectOptions={assetSelectionOptions}
        value={selectedOption}
        onChange={handleSetSelectedAsset}
      />
    </div>
  );
};
