// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { CELL_TYPES } from '@/hybrid/core/Table.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import {
  cancelRequests as cancelRequestsSqAdministrationAction,
  refreshRequests as refreshRequestsSqAdministrationAction,
} from '@/administration/administration.actions';
import { RequestsTabUtilities } from '@/hybrid/administration/requestsTab.utilities';
import { IconCell, ProgressCell } from '@/hybrid/homescreen/CellRender.atom';
import { RequestDetailsModal } from '@/hybrid/administration/RequestDetailsModal.molecule';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { AdminTableWrapper } from '@/hybrid/core/AdminTableWrapper.molecule';
import { sqAdministrationStore } from '@/core/core.stores';

const requestsTabBindings = bindingsDefinition({
  sqRequestsTabUtilities: injected<RequestsTabUtilities>(),
});

export const RequestsTab: SeeqComponent<typeof requestsTabBindings> = () => {
  const { sqRequestsTabUtilities } = useInjectedBindings(requestsTabBindings);

  const { t } = useTranslation();

  const [requests, setRequests] = useState([]);
  const [aggregatedRequestDetails, setAggregatedRequestDetails] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemForDetailsModal, setItemForDetailsModal] = useState(undefined);

  const tooManyRequests = useFluxPath(sqAdministrationStore, () => sqAdministrationStore.tooManyRequests);

  useEffect(() => {
    refreshRequests();
  }, []);

  const columns = [
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, minWidth: 40, maxWidth: 40 },
    },
    {
      accessor: 'userFullName',
      searchProperty: 'userFullName',
      header: 'ADMIN.REQUEST.USER_NAME',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: 100 },
    },
    {
      accessor: 'userEmail',
      searchProperty: 'userEmail',
      header: 'ADMIN.REQUEST.USER_EMAIL',
      sortable: true,
      filterable: true,
    },
    {
      accessor: 'methodAndUri',
      searchProperty: 'methodAndUri',
      header: 'ADMIN.REQUEST.METHOD_AND_URI',
      sortable: true,
      filterable: true,
      cellStyle: { width: 200, minWidth: 200 },
    },
    {
      accessor: 'duration',
      searchProperty: 'duration',
      header: 'ADMIN.REQUEST.DURATION',
      sortable: true,
      cellStyle: { width: 100, minWidth: 100, maxWidth: 100 },
    },
    {
      accessor: 'status',
      searchProperty: 'status',
      header: 'ADMIN.REQUEST.STATUS',
      sortable: true,
      filterable: true,
    },
    {
      accessor: 'parallelizationCount',
      searchProperty: 'parallelizationCount',
      header: 'ADMIN.REQUEST.PARALLELIZATION_COUNT',
      sortable: true,
      cellStyle: { width: 80, minWidth: 80, maxWidth: 80 },
    },
    {
      accessor: 'percentProgress',
      searchProperty: 'percentProgress',
      header: 'ADMIN.REQUEST.PROGRESS',
      sortable: true,
      cellRenderFunction: (item, accessor) => <ProgressCell percentage={item[accessor]} />,
      cellStyle: { width: 80, minWidth: 80, maxWidth: 80 },
    },
    {
      accessor: 'info',
      sortable: false,
      filterAble: false,
      cellStyle: { width: 40, minWidth: 40, maxWidth: 40 },
      cellRenderFunction: (item) => (
        <IconCell icon="fa-info-circle" onClick={() => setItemForDetailsModal(item)} type="theme" large={true} />
      ),
    },
  ];

  const refreshRequests = () =>
    refreshRequestsSqAdministrationAction()
      .then((data) => {
        setRequests(sqRequestsTabUtilities.formatRequests(data.requests));
        setAggregatedRequestDetails(sqRequestsTabUtilities.getAggregatedRequestDetails(data.requests));
      })
      .finally(() => setSelectedItems([]));

  const cancelRequests = () => {
    const requestHrefs: string[] = _.map(selectedItems, (request) => _.last(request.href.split('/')));

    return cancelRequestsSqAdministrationAction(requestHrefs)
      .then(refreshRequests)
      .finally(() => setSelectedItems([]));
  };

  return (
    <div className="height-maximum flexFill flexRowContainer">
      {itemForDetailsModal && (
        <RequestDetailsModal request={itemForDetailsModal} onClose={() => setItemForDetailsModal(undefined)} />
      )}

      <div className="flexColumnContainer flexSpaceBetween flexAlignCenter mb5">
        <HoverTooltip text="ADMIN.REQUEST.CANCEL_SELECTED">
          <ButtonWithManagedSpinner
            buttonProps={{
              id: 'cancelRequests',
              disabled: _.isEmpty(selectedItems),
            }}
            spinnerIconProps={{ type: 'text' }}
            label="ADMIN.REQUEST.CANCEL_SELECTED"
            action={cancelRequests}
          />
        </HoverTooltip>

        {tooManyRequests && <span>{t('ADMIN.REQUEST.TOO_MANY_REQUESTS_WARNING')}</span>}

        <ButtonWithManagedSpinner
          buttonProps={{ id: 'refreshRequests' }}
          spinnerIconProps={{ type: 'text' }}
          label="ADMIN.REQUEST.REFRESH_LIST"
          action={refreshRequests}
          icon="fa-repeat"
        />
      </div>

      <div className="overflowAuto width-maximum flexFillOverflow mb10">
        <AdminTableWrapper
          testId="configurationAdministrationTable"
          defaultSort={{ property: 'duration', asc: false }}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          items={requests}
          columns={columns}
          noItemsComponent={<span>{t('ADMIN.REQUEST.NONE_PENDING')}</span>}
        />
      </div>

      {aggregatedRequestDetails.length > 0 && (
        <div>
          {t('ADMIN.REQUEST.TOP_DATASOURCES')}
          {_.map(aggregatedRequestDetails, (countsAndKeys) => (
            <div key={countsAndKeys.toString()}>
              {countsAndKeys[0]}: {countsAndKeys[1]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
