// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Ace } from '@/hybrid/accessControl/itemAclModal.utilities';
import { PERMISSIONS } from '@/main/app.constants';

interface AceCheckboxProps {
  ace: Ace;
  setPermissions: (permission: string, ace: Ace) => void;
  name: string;
  isAclFromDatasource: boolean;
}

export type CheckboxPlacement = 'left' | 'right';

export const AceCheckbox: React.FunctionComponent<AceCheckboxProps> = ({
  ace,
  setPermissions,
  name,
  isAclFromDatasource,
}) => {
  const hasLocalPermission: boolean = ace.permissions && ace.permissions[name];
  const hasInheritedPermission: boolean = ace.inheritedPermissions && ace.inheritedPermissions[name];
  const isOwner: boolean = ace.isOwner;
  const inherited: boolean = isOwner && hasInheritedPermission;
  const placementForPermission: CheckboxPlacement = name === PERMISSIONS.READ ? 'right' : 'left';

  const togglePermissions = () => !isAclFromDatasource && setPermissions(name, ace);

  /**
   * Returns a translation key that represents the appropriate tooltip text.
   * Local permissions always take precedence.
   *
   * @return the translation key or undefined if no tooltip should show
   */
  const getTooltip: () => string = () => {
    if (hasLocalPermission) {
    } else if (isAclFromDatasource) {
      return 'ACCESS_CONTROL.FROM_DATASOURCE';
    } else if (inherited) {
      return 'ACCESS_CONTROL.INHERITED';
    } else if (isOwner) {
      return 'ACCESS_CONTROL.OWNER_ACCESS';
    }
  };

  /**
   * Returns the a string of css classes that displays the correct checkbox and cursor.
   * Local permissions show as black, inherited permission show as grayed out. Permissions that come from the
   * datasource are shown as grayed out with a "forbidden" tooltip.
   *
   * @return CSS classes that display the appropriate checkbox and cursor
   */
  const getCheckboxStyle: () => string = () => {
    const defaultCursor = 'cursorPointer';
    if (hasLocalPermission || hasInheritedPermission || isOwner) {
      const checked = 'fa fa-check-square';
      if (isAclFromDatasource) {
        return `${checked} disabledLook cursorNotAllowed`;
      }
      // local permissions take precedence
      if (hasLocalPermission) {
        return `${checked} ${defaultCursor}`;
      }
      return `${checked} disabledLook ${defaultCursor}`;
    } else {
      const unchecked = 'fa fa-square-o';
      if (isAclFromDatasource) {
        return `${unchecked} disabledLook cursorNotAllowed`;
      }
      return `${unchecked} ${defaultCursor}`;
    }
  };

  const keyUp = (event: any) => {
    if (event.key === '32') {
      togglePermissions();
    }
  };

  return (
    <div onClick={togglePermissions}>
      <HoverTooltip delay={1000} text={getTooltip()} placement={placementForPermission}>
        <span
          id={`ace-checkbox-${name}`}
          data-testid="ace-checkbox"
          className={classNames(getCheckboxStyle())}
          onKeyUp={keyUp}
        />
      </HoverTooltip>
    </div>
  );
};
