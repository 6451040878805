// @ts-strict-ignore
import _ from 'lodash';
import { STRING_UOM } from '@/main/app.constants';
import { formatNumber } from '@/hybrid/utilities/numberHelper.utilities';
import i18next from 'i18next';
import { ENUM_REGEX, TREND_SIGNAL_STATS } from '@/trendData/trendData.constants';
import { sqTrendSeriesStore } from '@/core/core.stores';
import { logError } from '@/hybrid/utilities/logger';

/**
 * Get the value and UOM of a column for an item
 *
 * @param {Object} column - One of the columns from COLUMNS
 * @param {Object} item - Item for which to get a value
 * @returns {String} Formatted value
 */
export function getColumnValueAndUOM(column, item) {
  const isStartTime = column.key === 'startTime';
  const isEndTime = column.key === 'endTime';
  const isUncertain = item?.isUncertain;

  if (isStartTime && _.isNil(item.startTime)) {
    return i18next.t('CAPSULES_PANEL.STARTS_OFF_SCREEN');
  }

  if (isEndTime && _.isNil(item.endTime)) {
    return i18next.t('CAPSULES_PANEL.ENDS_OFF_SCREEN');
  }

  const formatOptions = item.formatOptions ?? column.series?.formatOptions ?? {};

  /**
   * Gets a custom property from an item using said propertys column key.
   *
   * The custom property can be stored in a flat or nested structure within the item
   *
   * @param item - The item object containing data displayed in the column
   * @param columnKey - key to match to object property, column.key or column.uomKey
   *
   * @return The property of `item` that the key matched to
   */
  const getUsingKey = (item: Record<string, any>, columnKey: string): string | number => {
    const property = _.get(item, columnKey);
    if (!_.isNil(property)) {
      return property;
    }

    const [parentPropName, ...rest] = _.split(columnKey, '.');

    return _.get(item, [parentPropName].concat(_.startsWith(parentPropName, 'properties') ? rest.join('.') : rest));
  };

  const rawValue = _.get(item, column.accessor) ?? getUsingKey(item, column.key);
  const value =
    column.style === 'string' || isProperty(column) || (column.isStringCompatible && _.isString(rawValue))
      ? parseEnum(rawValue) || rawValue
      : formatNumber(
          getUsingKey(item, column.key),
          {
            ...formatOptions,
            format: column.format || formatOptions.format,
            isPercent: column.style === 'percent',
          },
          logError,
        );
  const uom = getUsingKey(item, column.uomKey);

  return `${_.isNil(value) ? '' : value}${_.isNil(uom) || uom === STRING_UOM ? '' : uom}`;
}

/**
 * Determines if the column is a statistic (e.g. Max of a series)
 *
 * @param {Object} column - One of the columns from COLUMNS
 * @return {boolean} True if it is a custom statistic column
 */
export function isStatistic(column) {
  return _.includes(column.key, 'statistics');
}

/**
 * Determines if the column is a custom property on the item (e.g. Batch ID)
 *
 * @param {Object} column - One of the columns from COLUMNS
 * @return {boolean} True if it is a custom statistic column
 */
export function isProperty(column) {
  return _.includes(column.key, 'properties');
}

export function isValueColumn(column) {
  return (
    column.style !== 'icon' &&
    column.style !== 'select' &&
    column.style !== 'input' &&
    column.style !== 'iconSelect' &&
    column.style !== 'assets' &&
    column.style !== 'datasourceName' &&
    column.key !== 'valueUnitOfMeasure'
  );
}

/**
 * Gets the title for a column from the capsules pane
 *
 * @param {Object} column - One of the columns
 * @return {string} The title of the column
 */
export function getCapsuleColumnTitle(column) {
  return (
    i18next.t(column.title) ||
    column.propertyName ||
    `${sqTrendSeriesStore.findItem(column.referenceSeries)?.name} ${i18next.t(
      _.find(TREND_SIGNAL_STATS, ['key', column.statisticKey])?.title,
    )}`
  );
}

export function parseEnum(value) {
  const match = value?.toString().match(ENUM_REGEX);
  return match ? match[2] : undefined;
}
