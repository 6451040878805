// @ts-strict-ignore
import React from 'react';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { Icon, IconType } from '@/hybrid/core/Icon.atom';
import { canUseMemo } from '@/hybrid/formbuilder/formbuilder.utilities';

export interface IconIF extends ValidatingFormComponent<string> {
  component: 'IconFormComponent';
  onIconClick?: (event?) => any;
  type?: IconType;
  color?: string;
  id?: string;
  large?: boolean;
  iconClasses?: string;
}

const IconFormComponentUnwrapped: React.FunctionComponent<IconIF> = (props) => {
  const { value, tooltip, onIconClick, testId, extraClassNames, type, color, id, large, iconClasses } = props;

  return (
    <span className={extraClassNames}>
      <Icon
        type={type}
        color={color}
        id={id}
        tooltip={tooltip}
        large={large}
        icon={value}
        onClick={onIconClick}
        testId={testId}
        extraClassNames={iconClasses}
      />
    </span>
  );
};

export const IconFormComponent = React.memo(IconFormComponentUnwrapped, canUseMemo);
