// @ts-strict-ignore
import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { ToolbarButton } from '@/hybrid/core/ToolbarButton.molecule';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import _ from 'lodash';
import { SCATTER_CONDITION_TOOL_ID } from '@/hybrid/toolSelection/investigate.constants';
import { INVESTIGATE_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { doTrack } from '@/track/track.service';
import { sqScatterPlotStore } from '@/core/core.stores';

const scatterPlotConditionButtonBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqScatterPlotActions: injected<ScatterPlotActions>(),
  isSmallButton: prop.optional<boolean>(),
});

export const ScatterPlotConditionButton: SeeqComponent<typeof scatterPlotConditionButtonBindings> = (props) => {
  const { sqInvestigateActions } = useInjectedBindings(scatterPlotConditionButtonBindings);

  const { isSmallButton } = props;

  const isRegionSelected = useFluxPath(sqScatterPlotStore, () => sqScatterPlotStore.isRegionSelected());

  const openToolPanelAndTrack = () => {
    doTrack('Scatterplot', 'Open Condition Tool');
    sqInvestigateActions.setActiveTool(_.find(INVESTIGATE_TOOLS, { id: SCATTER_CONDITION_TOOL_ID }).id);
  };

  return (
    <ToolbarButton
      onClick={openToolPanelAndTrack}
      disabled={!isRegionSelected}
      active={null}
      label="TOOLBAR.SCATTER_CONDITION"
      icon="fc-scatterplot-select"
      testId="scatterPlotConditionButton"
      tooltipText={
        isRegionSelected ? 'TOOLBAR.SCATTER_CONDITION_TOOLTIP' : 'TOOLBAR.SCATTER_CONDITION_TOOLTIP_DISABLED'
      }
      tooltipPlacement="top"
      isSmall={isSmallButton}
    />
  );
};
