import React from 'react';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { getFormFieldProps, getValidationFunction } from '@/hybrid/formbuilder/formbuilder.utilities';
import { Field, useForm } from 'react-final-form';
import { FormError } from '@/hybrid/core/FormError.atom';
import { FormControl, InputGroup } from 'react-bootstrap';
import { SearchWidget } from '@/hybrid/search/SearchWidget.organism';
import { useShowError } from '@/hybrid/formbuilder/hooks/useShowError';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { SearchPanes } from '@/search/search.constants';

export interface SearchWidgetIF extends ValidatingFormComponent<string> {
  component: 'SearchWidgetFormComponent';
  customErrorText?: string;
  onCloseClick: () => void;
  pane: SearchPanes;
  searchTypes: string[];
  allowAssetReplacement?: boolean;
  searchResultsClassNames?: string;
  onSearchItemClick: (item: any) => void;
}

export const SearchWidgetFormComponent: React.FunctionComponent<SearchWidgetIF> = (props) => {
  const {
    name,
    testId = 'searchWidget',
    validation,
    extendValidation,
    customErrorText,
    onCloseClick,
    onSearchItemClick,
    searchTypes,
    pane,
    allowAssetReplacement = false,
    value,
    searchResultsClassNames,
  } = props;

  const formState = useForm().getState();
  const { showError, setShowError } = useShowError(false);

  const defaultValidation = (value: string) => !value;

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <div className="flexRowContainer pb15" data-testid={testId}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const { value, showError, onChange } = getFormFieldProps(formState, input, meta, props);
          setShowError(showError);

          return value ? (
            <InputGroup>
              <FormControl
                data-testid="parameterIdentifier"
                autoComplete="off"
                type="text"
                name="commonName"
                className="width-maximum"
                value={value}
                disabled={true}
              />
              <InputGroup.Append data-testid="remove-parameter-button">
                <TextButton
                  label=""
                  icon="fa-close"
                  iconStyle="theme"
                  testId={`${testId}Close`}
                  onClick={() => {
                    onCloseClick();
                    onChange('');
                  }}
                />
              </InputGroup.Append>
            </InputGroup>
          ) : (
            <SearchWidget
              pane={pane}
              searchTypes={searchTypes}
              allowAssetReplacement={allowAssetReplacement}
              onItemClick={(item) => {
                onSearchItemClick(item);
                onChange(item.name);
              }}
              searchResultsClassNames={searchResultsClassNames}
            />
          );
        }}
      </Field>
      {showError && !value && customErrorText && (
        <FormError errorText={customErrorText} dataTestId={`${testId}Error`} extraClassNames="pt5" />
      )}
    </div>
  );
};
