export const getLabelFromItemType = (isSignalSelected: boolean, translationKey: string): string => {
  if (isSignalSelected) {
    return `IMPORTS.${translationKey}.SIGNAL`;
  } else {
    return `IMPORTS.${translationKey}.CONDITION`;
  }
};

export const getTooltipFromItemType = (isSignalSelected: boolean, translationKey: string): string => {
  if (isSignalSelected) {
    return `IMPORTS.TOOLTIPS.${translationKey}.SIGNAL`;
  } else {
    return `IMPORTS.TOOLTIPS.${translationKey}.CONDITION`;
  }
};
