import React from 'react';
import _ from 'lodash';
import { KEY_CODES } from '@/main/app.constants';
import { InvestigateToolType } from '@/hybrid/toolSelection/investigate.constants';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';

type BreadcrumbsProps = {
  breadcrumbs: InvestigateToolType[];
  onBreadcrumbClick: (breadcrumb: InvestigateToolType) => void;
};

/**
 * A chain of links to the different sections of the tool-selection-view
 *
 * Ex: Overview >> Identify >> Value Search
 */
export const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({ breadcrumbs, onBreadcrumbClick }) => {
  return (
    <ul
      className="unstyled-list searchBreadcrumbs flexColumnContainer flexWrap flexNoGrowNoShrink flexBaseline"
      data-testid="breadcrumbs">
      {_.map(breadcrumbs, (breadcrumb, index) => {
        const isLastBreadcrumb = +index === breadcrumbs.length - 1;
        const id = `${breadcrumb.id}Breadcrumb`;

        return (
          <li
            key={breadcrumb.id}
            id={id}
            data-testid={id}
            className="ml2 flexColumnContainer flexNoGrowNoShrink flexBaseline"
            tabIndex={isLastBreadcrumb ? undefined : 0}
            onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && !isLastBreadcrumb && onBreadcrumbClick(breadcrumb)}
            onClick={() => !isLastBreadcrumb && onBreadcrumbClick(breadcrumb)}>
            {breadcrumb.id === TREND_TOOLS.OVERVIEW && <Icon icon="fa-home" large={true} />}
            <span className="ml2">{breadcrumb.name}</span>
          </li>
        );
      })}
    </ul>
  );
};
