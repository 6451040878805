// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { ReportActions } from '@/reportEditor/report.actions';

import { Modal } from 'react-bootstrap';
import { FormulaService } from '@/services/formula.service';
import { ReportDateModalConfigureCapsuleSelection } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/components/ReportDateModalConfigureCapsuleSelection.molecule';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { ReportDateModalAutoUpdate } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/components/ReportDateModalAutoUpdate.molecule';
import { DisplayRangeSelector } from '@/hybrid/trend/DisplayRangeSelector.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { sharedReportDateModalBindings } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/ReportDateModal.molecule';
import { useEffectOnceWhen } from 'rooks';
import { useTranslation } from 'react-i18next';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { DateRangeSwapInfo } from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';
import { sqReportStore, sqWorksheetStore } from '@/core/core.stores';

const dateRangeChooseCapsuleModalBindings = bindingsDefinition({
  ...sharedReportDateModalBindings,
  sqReportActions: injected<ReportActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqFormula: injected<FormulaService>(),
});

export const DateRangeChooseCapsuleModal: SeeqComponent<typeof dateRangeChooseCapsuleModalBindings> = ({
  onCloseModal,
  setNewDateRange,
  newDateRange,
  dateRangeRate,
  displayRange,
  hasMultipleSchedules,
  computedCapsuleDisplay,
  setSelectedCondition,
  isSelectingRelative,
  selectedCondition,
  clearCapsuleDisplay,
  computedCapsuleProperties,
  capsuleIsLoading,
  capsuleCountData,
  updateDateRangeFromCapsule,
  isMaximumDurationRequiredForItem,
  updateSelectedCondition,
  isConditionSelected,
  onSave,
  canSave,
}) => {
  const { sqReportActions, sqFormula } = useInjectedBindings(dateRangeChooseCapsuleModalBindings);

  const { t } = useTranslation();
  const currentSwap: DateRangeSwapInfo = useFluxPath(
    sqReportStore,
    () => sqReportStore.activeDateRangeSwapInfo.currentSwap,
  );
  const [targetAssetShortName, setTargetAssetShortName] = useState('');
  const { timezone } = useFlux(sqWorksheetStore);

  useEffectOnceWhen(() => {
    sqFormula.getAssetPath(currentSwap.assetSelection.asset, 1).then((path) => setTargetAssetShortName(path));
  }, !!currentSwap);

  useEffect(() => {
    if (!!currentSwap && currentSwap.dateRange.condition.id !== selectedCondition?.id) {
      updateSelectedCondition(currentSwap.swapItem);
    }
  }, [newDateRange.condition.id]);

  useEffectOnceWhen(() => {
    if (currentSwap.dateRange.condition.columns) {
      // reset this field so the date range cannot be saved until a capsule is selected
      setNewDateRange({ condition: { columns: null } });
    }
  }, !!currentSwap && currentSwap.dateRange.condition.id === newDateRange?.condition?.id);

  const capsuleTypeTrackingString = isSelectingRelative ? 'Relative' : 'Specific';
  const onCancel = () => {
    if (currentSwap) {
      doTrack('DateRange Asset Swap', 'Choose Capsule', `Canceled - ${capsuleTypeTrackingString}`);
    }
    sqReportActions.setActiveDateRangeSwapInfo(undefined, []);
    clearCapsuleDisplay();
    onCloseModal();
  };

  const saveAndTrack = () => {
    if (currentSwap) {
      doTrack('DateRange Asset Swap', 'Choose Capsule', `Saved - ${capsuleTypeTrackingString}`);
    }
    onSave();
  };

  const dateLabelWithIcon = (
    <label>
      <Icon icon="fa-calendar" extraClassNames="mr3" />
      {newDateRange.name}
    </label>
  );
  const targetAssetLabelWithIcon = (
    <label className="mr3">
      <Icon icon="fa-cube" />
      {targetAssetShortName}
    </label>
  );
  const currentAssetLabelWithIcon = (
    <label className="mr3 ml3">
      <Icon icon="fa-cube" />
      {currentSwap?.swapAsset.name}
    </label>
  );

  const helpTextElement = (
    <div className="alert alert-info">
      {t('REPORT.MODAL.DATE_RANGE.CAPSULE_SELECT.CURRENTLY_USING_CONDITION')}
      <ul>
        <li className="mt2">
          <HoverTooltip
            text="REPORT.MODAL.DATE_RANGE.CAPSULE_SELECT.SWAP_ASSETS_TOOLTIP"
            translateParams={{
              conditionName: selectedCondition?.name,
              targetAssetName: targetAssetShortName,
              currentAssetName: currentSwap?.swapAsset.name,
            }}
            placement="right">
            <span>
              {currentAssetLabelWithIcon}
              <Icon icon="fa-long-arrow-right" extraClassNames="mr3 ml3" />
              {targetAssetLabelWithIcon}
            </span>
          </HoverTooltip>
        </li>
        <li>{t('REPORT.MODAL.DATE_RANGE.CAPSULE_SELECT.SELECT_CAPSULE_HELP')}</li>
        <li>
          {t('REPORT.MODAL.DATE_RANGE.CAPSULE_SELECT.CANCEL_HELP', {
            currentAssetName: targetAssetShortName,
          })}
        </li>
      </ul>
    </div>
  );

  const conditionMetadata = [
    {
      label: t('REPORT.MODAL.DATE_RANGE.METADATA.TARGET_ASSET'),
      data: currentSwap?.assetSelection.asset.name,
      order: 2,
    },
    {
      label: t('REPORT.MODAL.DATE_RANGE.METADATA.CURRENT_ASSET'),
      data: currentSwap?.swapAsset.formattedName,
      order: 1,
    },
    {
      label: t('REPORT.MODAL.DATE_RANGE.METADATA.ASSET_SELECTION'),
      data: currentSwap?.assetSelection.name,
      order: 3,
    },
  ];

  return (
    <Modal show={true} onHide={onCancel} animation={false} className="reportDateModal form-group" scrollable={true}>
      <Modal.Header closeButton={true} onHide={onCancel}>
        <Modal.Title>
          {dateLabelWithIcon}
          {t('REPORT.MODAL.DATE_RANGE.CAPSULE_SELECT.TITLE')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb10">
          {newDateRange?.auto?.enabled ? (
            <ReportDateModalAutoUpdate
              setNewDateRange={setNewDateRange}
              hasMultipleSchedules={hasMultipleSchedules}
              dateRangeRate={dateRangeRate}
              newDateRange={newDateRange}
              displayRange={displayRange}
            />
          ) : (
            <DisplayRangeSelector
              displayRange={displayRange}
              timezone={timezone}
              trackAction="Date Range Tool"
              trackCategory="Topic"
              formMode={true}
              investigateRangeCopyDisabled={true}
              investigateRangeCopyNonExistent={true}
              autoUpdateDisabled={true}
              halfRangeButtonDisabled={true}
              timeZoneEditingEnabled={false}
              durationEditingEnabled={true}
              rangeEditingEnabled={true}
              inputExtraClassNames="min-width-20 p0"
            />
          )}
        </div>
        {!!currentSwap && helpTextElement}
        <ReportDateModalConfigureCapsuleSelection
          selectedCondition={selectedCondition}
          isConditionSelected={isConditionSelected}
          setNewDateRange={setNewDateRange}
          isSelectingRelative={isSelectingRelative}
          capsuleCountData={capsuleCountData}
          newDateRange={newDateRange}
          capsuleIsLoading={capsuleIsLoading}
          computedCapsuleDisplay={computedCapsuleDisplay}
          computedCapsuleProperties={computedCapsuleProperties}
          updateDateRangeFromCapsule={updateDateRangeFromCapsule}
          isMaximumDurationRequiredForItem={isMaximumDurationRequiredForItem}
          setSelectedCondition={setSelectedCondition}
          conditionMetadata={currentSwap ? conditionMetadata : []}
        />
      </Modal.Body>
      <Modal.Footer>
        <div>
          <TextButton
            tooltip="REPORT.MODAL.DATE_RANGE.CAPSULE_SELECT.CANCEL_BUTTON_TOOLTIP"
            onClick={onCancel}
            label="REPORT.CANCEL"
            extraClassNames="mr5"
            testId="assetDateSwapCancel"
          />
          <TextButton
            testId="saveSwapCapsule"
            onClick={canSave() && saveAndTrack}
            disabled={!canSave()}
            label="SAVE"
            variant="theme"
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
