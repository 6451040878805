// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { WizardModal } from '@/hybrid/core/WizardModal.molecule';
import { addCalculationColumn } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import {
  AssetGroupCalculationCreationOption,
  SelectExistingOrNew,
} from '@/hybrid/assetGroupEditor/calculations/SelectExistingOrNew.atom';
import { SearchForExistingConditionOrCalculatedSignal } from '@/hybrid/assetGroupEditor/calculations/SearchForExistingConditionOrCalculatedSignal.atom';
import { FormulaFromScratch } from '@/hybrid/assetGroupEditor/calculations/FormulaFromScratch.atom';
import { FormulaEditorParam } from '@/hybrid/formula/FormulaParametersTable.molecule';
import { validateAssetGroupFormula } from '@/hybrid/formula/formula.utilities';
import { sqFormulasApi } from '@/sdk';
import { FormulaErrorInterface } from '@/hybrid/formula/FormulaEditor.molecule';
import { SEARCH_TYPES_ASSET_GROUP } from '@/hybrid/assetGroupEditor/AssetFinderModal';

import { successToast } from '@/hybrid/utilities/toast.utilities';
import { sqAssetGroupStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';
import { clear } from '@/search/search.actions';
import { isValidColumnName } from '@/hybrid/assetGroupEditor/assetGroup.utilities';

interface AssetColumnCalculationModalProps {
  onClose: () => void;
}

export const AssetColumnCalculationModal: React.FunctionComponent<AssetColumnCalculationModalProps> = ({ onClose }) => {
  const [currentSelection, setCurrentSelection] = useState<AssetGroupCalculationCreationOption>('fromExisting');
  const [selectedItem, setSelectedItem] = useState(null);
  const [formula, setFormula] = useState('');
  const [parameters, setParameters] = useState<FormulaEditorParam[]>([]);
  const [formulaErrors, setFormulaErrors] = useState<FormulaErrorInterface[]>([]);
  const [calculationColumnName, setCalculationColumnName] = useState('');

  const doValidateFormula = () => {
    return validateAssetGroupFormula(sqAssetGroupStore.assets, formula, parameters)
      .then(() => {
        setFormulaErrors([]);
        return true;
      })
      .catch((formulaErrors) => {
        setFormulaErrors(formulaErrors);
        return false;
      });
  };

  const saveCalculation = () =>
    doValidateFormula().then((valid) => {
      if (valid) {
        addCalculationColumn({ formula, parameters, calculationColumnName });
        onClose();
        successToast({
          messageKey: 'ASSET_GROUP_EDITOR.ADD_CALCULATION_SUCCESS',
        });
      }
    });

  const setAndTrackCurrentSelection = (selection) => {
    setCurrentSelection(selection);
    doTrack('Asset Group Editor', 'Column type selection', selection);
  };

  const typeSelectionDef = {
    title: 'ASSET_GROUP_EDITOR.ADD_CALCULATION',
    component: <SelectExistingOrNew onSelection={setAndTrackCurrentSelection} />,
    valid: true,
  };

  const resetSearchModal = () => clear('modal', SEARCH_TYPES_ASSET_GROUP);

  const formulaAndNameValid =
    _.isEmpty(formulaErrors) && isValidColumnName(sqAssetGroupStore.assets, calculationColumnName);

  const existingCalculationSteps = [
    typeSelectionDef,
    {
      title: 'ASSET_GROUP_EDITOR.SEARCH_CONDITION_OR_CALC_ITEM',
      component: <SearchForExistingConditionOrCalculatedSignal setSelectedItem={setSelectedItem} />,
      valid: selectedItem !== null,
      backButtonFn: () => {
        doTrack('Asset Group Editor', 'Back Button', 'Calculation type selection');
        return resetSearchModal();
      },
      actionFn: () => {
        return sqFormulasApi
          .getItem({ id: selectedItem.id })
          .then(({ data }) => {
            const { formula, parameters: fnParams } = data;
            const formulaParams = _.map(fnParams, ({ name, item }) => ({
              name,
              identifier: name,
              item: { name: item.name, id: item.id, type: item.type },
            }));
            setSelectedItem(data);
            setFormula(formula);
            setParameters(formulaParams);
            doTrack('Asset Group Editor', 'Add existing Item to Asset Group');
          })
          .finally(resetSearchModal);
      },
    },
    {
      title: 'ASSET_GROUP_EDITOR.MAP_EXISTING',
      component: (
        <FormulaFromScratch
          fromExisting={true}
          item={selectedItem}
          existingParameters={parameters}
          validateFormula={doValidateFormula}
          formulaErrors={formulaErrors}
          setFormulaErrorsFn={setFormulaErrors}
          exposeFormulaToParent={setFormula}
          exposeParametersToParent={setParameters}
          exposeCalculationColumnNameToParent={setCalculationColumnName}
          showColumnNameField={true}
        />
      ),
      actionButtonLabel: 'ASSET_GROUP_EDITOR.ADD_CALCULATION',
      valid: formulaAndNameValid,
      actionFn: () => {
        doTrack('Asset Group Editor', 'Complete adding existing Calculation to Asset Group');
        return saveCalculation();
      },
      modalClassName: 'min-width-900',
    },
  ];

  const fromScratchSteps = [
    typeSelectionDef,
    {
      title: 'ASSET_GROUP_EDITOR.FORMULA',
      component: (
        <FormulaFromScratch
          validateFormula={doValidateFormula}
          formulaErrors={formulaErrors}
          exposeFormulaToParent={setFormula}
          setFormulaErrorsFn={setFormulaErrors}
          exposeParametersToParent={setParameters}
          exposeCalculationColumnNameToParent={setCalculationColumnName}
          showColumnNameField={true}
        />
      ),
      backButtonFn: () => {
        doTrack('Asset Group Editor', 'Back Button', 'Calculation type selection');
        setCurrentSelection('fromExisting');
      },
      actionButtonLabel: 'ASSET_GROUP_EDITOR.ADD_CALCULATION',
      valid: formulaAndNameValid,
      actionFn: () => {
        doTrack('Asset Group Editor', 'Complete adding Calculation from Scratch to Asset Group');
        return saveCalculation();
      },
      modalClassName: 'min-width-900',
    },
  ];

  const [steps, setSteps] = useState(existingCalculationSteps);

  useEffect(() => {
    setSteps(currentSelection === 'fromExisting' ? existingCalculationSteps : fromScratchSteps);
  }, [currentSelection, selectedItem, formula, parameters, formulaErrors, calculationColumnName]);

  return <WizardModal testId="assetGroupCalculations" onClose={onClose} stepDefinitions={steps} />;
};
