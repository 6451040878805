import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { Editor } from '@/hybrid/annotation/Editor.organism';
import { ReportTemplateOutputV1, sqReportTemplatesApi } from '@/sdk';
import { setWorkbookDisplayMode } from '@/workbook/workbook.actions';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import { sqWorkbenchStore } from '@/core/core.stores';
import { notifyCapture, notifyLoading } from '@/hybrid/utilities/screenshot.utilities';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';

const reportTemplateThumbnailBindings = bindingsDefinition({
  sqReportContent: injected<ReportContentService>(),
  $injector: injected<ng.auto.IInjectorService>(),
});

export const ReportTemplateThumbnail: SeeqComponent<typeof reportTemplateThumbnailBindings> = () => {
  const { sqReportContent, $injector } = useInjectedBindings(reportTemplateThumbnailBindings);

  const [reportTemplate, setReportTemplate] = useState<ReportTemplateOutputV1 | null>(null);
  const documentChangedNoop = useRef(_.noop);

  useEffect(() => {
    notifyLoading();
    setWorkbookDisplayMode(WORKBOOK_DISPLAY.PRESENT);
    sqReportTemplatesApi
      .getReportTemplate({ id: sqWorkbenchStore.stateParams.templateId })
      .then(({ data }) => setReportTemplate(data));
  }, []);

  return reportTemplate ? (
    <Editor
      id={reportTemplate.id}
      document={reportTemplate.htmlTemplate}
      isCkEditor={true}
      isJournal={false}
      canEdit={false}
      afterOnInit={() => notifyCapture({ sqReportContent, $$testability: $injector.get('$$testability') })}
      beforeOnInit={_.noop}
      onDestroy={_.noop}
      documentChanged={documentChangedNoop}
    />
  ) : null;
};

export const sqReportTemplateThumbnail = angularComponent(reportTemplateThumbnailBindings, ReportTemplateThumbnail);
