// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { Table, TableColumn } from '@/hybrid/core/Table.atom';
import _ from 'lodash';
import { setSearchParams } from '@/administration/administration.actions';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { sqAdministrationStore } from '@/core/core.stores';

interface AdminTableWrapperProps {
  items: any[];
  testId: string;
  columns: TableColumn[];
  selectedItems: any[];
  setSelectedItems: ([]: any) => void;
  defaultSort: { property: string; asc: boolean };
  stickyHeader?: boolean;
  noItemsComponent?: JSX.Element;
}

export const AdminTableWrapper: React.FunctionComponent<AdminTableWrapperProps> = ({
  items,
  selectedItems,
  setSelectedItems,
  columns,
  defaultSort,
  testId,
  stickyHeader = true,
  noItemsComponent = undefined,
}) => {
  const searchParams = useFluxPath(sqAdministrationStore, () => sqAdministrationStore.searchParams);
  const [sortedAndFilteredItems, setSortedAndFilteredItems] = useState([]);
  const [sortAsc, setSortAsc] = useState(defaultSort.asc);
  const [sortProperty, setSortProperty] = useState(defaultSort.property);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    setSortedAndFilteredItems(sortAndFilterItems(items));
  }, [items, searchParams, sortProperty, sortAsc]);

  useEffect(() => {
    setAllSelected(sortedAndFilteredItems.length === selectedItems.length && selectedItems.length !== 0);
  }, [selectedItems, sortedAndFilteredItems]);

  const sortCallback = (field, oldSortOrder) => {
    setSortProperty(field);
    setSortAsc(!oldSortOrder);
  };

  const filterCallback = (option, field) => {
    setSearchParams({ field, value: option.value });
    setSelectedItems([]);
  };

  const updateSelection = (item) => {
    const itemIndex = _.findIndex(selectedItems, (selectedItem) => selectedItem.id === item.id);
    if (itemIndex > -1) {
      _.pullAt(selectedItems, itemIndex);
      setSelectedItems([...selectedItems]);
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const sortAndFilterItems = (items) => {
    const filteredItems = _.filter(items, (item) =>
      _.every(searchParams, (searchValue, columnName) => {
        if (_.isNil(searchValue) || searchValue === '') {
          return true;
        } else if (_.isString(item[columnName])) {
          return item[columnName].toLowerCase().includes((searchValue as string).toLowerCase());
        } else {
          return item[columnName] === searchValue;
        }
      }),
    );

    return _.orderBy(
      filteredItems,
      [
        (item) => {
          if (_.isNumber(item[sortProperty])) {
            return item[sortProperty];
          } else {
            return _.toLower(item[sortProperty]);
          }
        },
      ],
      [sortAsc ? 'asc' : 'desc'],
    );
  };

  const onSelectAll = () => {
    allSelected ? setSelectedItems([]) : setSelectedItems(_.clone(sortedAndFilteredItems));
    setAllSelected(!allSelected);
  };

  return (
    <>
      <Table
        testId={testId}
        tableClass={stickyHeader ? 'fixedHeaderTable' : ''}
        sortProperty={sortProperty}
        sortAscending={sortAsc}
        sortTableCallback={sortCallback}
        filterTableCallback={filterCallback}
        onRowSelectCallback={updateSelection}
        selectedIds={_.map(selectedItems, 'id')}
        columns={columns}
        selectAll={allSelected}
        items={sortedAndFilteredItems}
        selectAllCallback={onSelectAll}
        searchParams={searchParams}
      />

      {sortedAndFilteredItems.length === 0 && !_.isUndefined(noItemsComponent) && (
        <div className="flexAlignCenter flexRowContainer">{noItemsComponent}</div>
      )}
    </>
  );
};
