// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useTranslation } from 'react-i18next';
import { CELL_TYPES, TableColumn } from '@/hybrid/core/Table.atom';
import {
  enableUsers as enableUsersSqAdministrationAction,
  loadTable,
  resetTable,
  setFilter,
  setPageNumberAndGo,
  setPageSizeForTable,
  setSort,
} from '@/administration/administration.actions';
import { ServerSideFilteringAndPaginatingTable } from '@/hybrid/core/ServerSideFilteringAndPaginatingTable.molecule';
import { fetchLicense } from '@/licenseManagement/licenseManagement.actions';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { AddUserModal } from '@/hybrid/administration/AddUserModal.molecule';
import { EditUserModal } from '@/hybrid/administration/EditUserModal.molecule';
import { RemoveUserModal } from '@/hybrid/administration/RemoveUserModal.molecule';
import { TableLoadingIndicator } from '@/hybrid/core/TableLoadingIndicator.molecule';
import { infoToast } from '@/hybrid/utilities/toast.utilities';
import { sqAdministrationStore, sqLicenseManagementStore } from '@/core/core.stores';
import { LEGACY_GUEST_USERNAME } from '@/administration/administration.constants';
import { seeqDirectoryEnabled } from '@/services/systemConfiguration.utilities';

export const UserTable: React.FunctionComponent<{}> = () => {
  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState([]);

  useFlux(sqAdministrationStore);
  const users = sqAdministrationStore.users;
  useFlux(sqLicenseManagementStore);
  const canAddUsers = sqLicenseManagementStore.canAddUsers();

  const tableLoading = sqAdministrationStore.userTableLoading;

  useEffect(() => {
    loadTable();
  }, []);

  type ModalType = 'addUser' | 'editUser' | 'removeUser' | 'none';
  const [modalShown, setModalShown] = useState<ModalType>('none');
  const [editUser, setEditUser] = useState();

  const closeModal = () => {
    if (modalShown === 'removeUser') {
      setSelectedIds([]);
    }
    setModalShown('none');
  };

  const openUserEditModal = (user) => {
    setModalShown('editUser');
    setEditUser(user);
  };

  const renderEditCell = (item) => (
    <div className="cursorPointer" onClick={() => openUserEditModal(item)}>
      <Icon icon="fa-pencil" testId={`editUser_${item.id}`} />
      <span className="sq-text-primary pl5">{t('EDIT')}</span>
    </div>
  );

  const columns: TableColumn[] = [
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40 },
    },
    {
      accessor: 'email',
      searchProperty: 'emailSearch',
      header: 'ADMIN.USER.EMAIL',
      cellStyle: { wordWrap: 'break-word' },
    },
    {
      accessor: 'firstName',
      searchProperty: 'firstNameSearch',
      header: 'ADMIN.USER.FIRST_NAME',
      cellStyle: { minWidth: 75, wordWrap: 'break-word' },
    },
    {
      accessor: 'lastName',
      searchProperty: 'lastNameSearch',
      header: 'ADMIN.USER.LAST_NAME',
      cellStyle: { minWidth: 75, wordWrap: 'break-word' },
    },
    {
      accessor: 'username',
      searchProperty: 'usernameSearch',
      header: 'ADMIN.USER.USERNAME',
      cellStyle: { minWidth: 120, wordWrap: 'break-word' },
    },
    {
      accessor: 'datasourceName',
      searchProperty: 'datasourceNameSearch',
      header: 'ADMIN.USER.DIRECTORY',
      cellStyle: { minWidth: 75, wordWrap: 'break-word' },
    },
    {
      accessor: 'isAdmin',
      sortable: false,
      searchProperty: 'isAdmin',
      header: 'ADMIN.USER.ADMINISTRATOR',
      cellStyle: { width: 85, maxWidth: 95 },
      cellType: CELL_TYPES.CHECKMARK,
    },
    {
      accessor: 'isEnabled',
      sortable: false,
      searchProperty: 'isEnabled',
      header: 'ADMIN.USER.STATUS',
      cellStyle: { width: 100, maxWidth: 105 },
      cellType: CELL_TYPES.ENABLED_DISABLED,
    },
    {
      accessor: 'isActive',
      sortable: false,
      searchProperty: 'isLoggedIn',
      header: 'ADMIN.USER.LOGGED_IN',
      cellStyle: { width: 85, maxWidth: 95 },
      cellType: CELL_TYPES.CHECKMARK,
    },
    {
      accessor: 'id',
      cellStyle: { width: 90, maxWidth: 90 },
      cellRenderFunction: renderEditCell,
    },
  ];

  const manageSelectedIds = (item) => {
    const itemIndex = _.indexOf(selectedIds, item.id);
    if (itemIndex > -1) {
      setSelectedIds([..._.pull(selectedIds, item.id)]);
    } else {
      setSelectedIds([...selectedIds, item.id]);
    }
  };

  const isLegacyGuestUser = (user) => user.username === LEGACY_GUEST_USERNAME;

  const enableUsers = (enable) => {
    const [legacyUsers, ids] = _.partition<any>(
      selectedIds,
      (userId) => enable && isLegacyGuestUser(_.find(users, { id: userId })),
    );
    if (_.some(legacyUsers)) {
      infoToast({ messageKey: 'ADMIN.USER.LEGACY_GUEST_ENABLE_NOT_ALLOWED' });
      if (!_.some(users)) {
        return;
      }
    }

    enableUsersSqAdministrationAction({ enabled: enable, ids })
      .then(() => fetchLicense())
      .then(() => loadTable())
      .finally(() => setSelectedIds([]));
  };

  const filterUsers = (option, field) => {
    setFilter(field, option.value);
    loadTable();
  };

  const sortUsers = (field, asc) => {
    setSort(field, asc);
    loadTable();
  };

  const refreshUsers = () => {
    setSelectedIds([]);
    resetTable();
    return loadTable().then(() => fetchLicense());
  };

  const nothingSelected = _.isEmpty(selectedIds);

  return (
    <div className="height-maximum">
      <div className="flexColumnContainer mb5 flexSpaceBetween flexAlignCenter">
        <div>
          <TextButton
            id="enableSelected"
            extraClassNames="mr5"
            onClick={() => enableUsers(true)}
            disabled={!canAddUsers || nothingSelected}
            label={canAddUsers ? 'ADMIN.USER.ENABLE_SELECTED' : 'ADMIN.USER.ADD_USER_OVER_LIMIT'}
          />

          <TextButton
            id="disableSelected"
            extraClassNames="mr5"
            onClick={() => enableUsers(false)}
            disabled={nothingSelected}
            label="ADMIN.USER.DISABLE_SELECTED"
          />

          <TextButton
            id="removeSelected"
            onClick={() => setModalShown('removeUser')}
            disabled={nothingSelected}
            label="ADMIN.USER.REMOVE_SELECTED"
          />
        </div>
        <div className="flexColumnContainer">
          <ButtonWithManagedSpinner
            buttonProps={{ id: 'refreshUsers', extraClassNames: 'mr5' }}
            action={refreshUsers}
            icon="fa-repeat"
            label="ADMIN.USER.REFRESH_LIST"
            spinnerIconProps={{ large: true, type: 'text' }}
          />

          {seeqDirectoryEnabled() && (
            <TextButton
              id="addUser"
              variant="theme"
              onClick={() => setModalShown('addUser')}
              disabled={!canAddUsers}
              label={canAddUsers ? 'ADMIN.USER.ADD_USER' : 'ADMIN.USER.ADD_USER_OVER_LIMIT'}
              iconStyle="white"
              icon="fa-plus fa-lg"
            />
          )}
        </div>
      </div>

      <div className="height-maximum overflowAuto pb100 width-maximum flexRowContainer flexSpaceBetween">
        <ServerSideFilteringAndPaginatingTable
          testId="userAdministrationTable"
          tableClass="fixedHeaderTable"
          rowSelectionCallback={manageSelectedIds}
          filterCallback={filterUsers}
          selectAllCallback={setSelectedIds}
          selectedIds={selectedIds}
          sortCallback={sortUsers}
          columns={columns}
          items={users}
          store={sqAdministrationStore}
          actions={{ setPageSizeForTable, setPageNumberAndGo }}
          loadTable={loadTable}
        />
        {tableLoading && <TableLoadingIndicator />}
      </div>

      {modalShown === 'addUser' && <AddUserModal onClose={closeModal} />}

      {modalShown === 'editUser' && <EditUserModal user={editUser} onClose={closeModal} />}

      {modalShown === 'removeUser' && <RemoveUserModal selectedIds={selectedIds} onClose={closeModal} />}
    </div>
  );
};
