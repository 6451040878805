// @ts-strict-ignore
import angular from 'angular';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { flux } from '@/core/flux.module';
import { sqPredictionPanelStore } from '@/core/core.stores';

angular.module('Sq.Investigate').service('sqPredictionPanelActions', sqPredictionPanelActions);
export type PredictionPanelActions = ReturnType<typeof sqPredictionPanelActions>;

function sqPredictionPanelActions(sqToolRunner: ToolRunnerService) {
  const service = {
    generate,
    setOption,
    setPolynomialValue,
    setMustGoThroughZero,
    setLambda,
    setPrincipalComponentsToKeep,
    setTrainingWindow,
    setModelExpanded,
    setRegressionMethod,
    setVariableSelection,
    setVariableSelectionEnabled,
  };

  return service;

  /**
   * Generate a signal using prediction
   */
  function generate(name, formula, parameters, color) {
    return sqToolRunner.panelExecuteSignal(
      name,
      formula,
      parameters,
      sqPredictionPanelStore.configParams,
      sqPredictionPanelStore.id,
      color,
    );
  }

  /**
   * Set the prediction option that is used in the form.
   *
   * @param {String} option - The prediction option; one of PREDICTION.SCALE
   */
  function setOption(option) {
    flux.dispatch('PREDICTION_SET_OPTION', { option });
  }

  /**
   * Set the polynomial value that is used in the form.
   *
   * @param {Number} polynomialValue - The polynomial value
   */
  function setPolynomialValue(polynomialValue) {
    flux.dispatch('PREDICTION_SET_POLYNOMIAL_VALUE', { polynomialValue });
  }

  /**
   * Set the 'must go through zero' value that is used in the form.
   *
   * @param {Boolean} mustGoThroughZero - the 'must go through zero' options open value
   */
  function setMustGoThroughZero(mustGoThroughZero) {
    flux.dispatch('PREDICTION_SET_MUST_GO_THROUGH_ZERO', { mustGoThroughZero });
  }

  /**
   * Set the lambda value that is used in the form.
   *
   * @param {Number} lambda - the lambda options open value
   */
  function setLambda(lambda) {
    flux.dispatch('PREDICTION_SET_LAMBDA', { lambda });
  }

  /**
   * Set the 'principal components to keep' value that is used in the form.
   *
   * @param {Number} principalComponentsToKeep - the 'principal components to keep' options open value
   */
  function setPrincipalComponentsToKeep(principalComponentsToKeep) {
    flux.dispatch('PREDICTION_SET_PRINCIPAL_COMPONENTS_TO_KEEP', {
      principalComponentsToKeep,
    });
  }

  /**
   * Set the training window start value that is used in the form.
   *
   * @param {Number} window.startTime - the start of the reference table training window
   * @param {Number} window.endTime - the end of the reference table training window
   */
  function setTrainingWindow(window) {
    flux.dispatch('PREDICTION_SET_TRAINING_WINDOW', { window });
  }

  /**
   * Set the model expanded value that is used in the form.
   *
   * @param {Boolean} modelExpanded - the model expanded value
   */
  function setModelExpanded(modelExpanded) {
    flux.dispatch('PREDICTION_SET_MODEL_EXPANDED', { modelExpanded });
  }

  /**
   * Set the regression method that is used in the form.
   *
   * @param {String} regressionMethod - The regression method to use (one of REGRESSION_METHODS).
   */
  function setRegressionMethod(regressionMethod) {
    flux.dispatch('PREDICTION_SET_REGRESSION_METHOD', { regressionMethod });
  }

  /**
   * Set the variable selection that is used in the form.
   *
   * @param {Number} variableSelection - The variable selection to use [0, 1].
   */
  function setVariableSelection(variableSelection) {
    flux.dispatch('PREDICTION_SET_VARIABLE_SELECTION', { variableSelection });
  }

  /**
   * Set whether variable selection is enabled or not.
   *
   * @param {Boolean} variableSelectionEnabled - True if variable selection is enabled, false otherwise.
   */
  function setVariableSelectionEnabled(variableSelectionEnabled) {
    flux.dispatch('PREDICTION_SET_VARIABLE_SELECTION_ENABLED', {
      variableSelectionEnabled,
    });
  }
}
