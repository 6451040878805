import _ from 'lodash';

export const handleAnchorClick = (func: () => void) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.preventDefault();
  func();
};

export const getScopeMessage = (availableOutsideAnalysis: boolean, scopedTo: string, workbookId: string) => {
  if (availableOutsideAnalysis) {
    return 'SCOPE.GLOBAL';
  }

  if (_.toLower(scopedTo) === _.toLower(workbookId)) {
    return 'SCOPE.LOCAL';
  }

  return 'SCOPE.OTHER';
};
