// @ts-strict-ignore
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { CancelAndSave } from '@/hybrid/core/CancelAndSave.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';

export const ConfigurationPreviewModal = ({ configurationOptions, onClose }) => {
  const { t } = useTranslation();

  const restartRequiredOptions = configurationOptions?.filter((option) => option.registration === 'Restart');

  return (
    <Modal show={true} onHide={() => onClose()} animation={false} data-testid="configurationPreviewModal">
      <Modal.Header closeButton={true}>
        <div className="flexFill">
          <Icon icon="fc-edit" /> &nbsp;
          <span className="modal-title">{t('ADMIN.CONFIGURATION.PREVIEW_TITLE')}</span>
        </div>
      </Modal.Header>

      <Modal.Body>
        <table className="table table-striped table-condensed">
          <thead>
            <tr>
              <th>{t('ADMIN.CONFIGURATION.NAME')}</th>
              <th>{t('ADMIN.CONFIGURATION.DEFAULT_VALUE')}</th>
              <th>{t('ADMIN.CONFIGURATION.OVERRIDE_VALUE')}</th>
              <th>{t('ADMIN.CONFIGURATION.NOTE')}</th>
            </tr>
          </thead>
          <tbody>
            {_.map(configurationOptions, (option) => (
              <tr key={option.path}>
                <td>{option.path}</td>
                <td
                  className={classNames('aggressiveWordBreak', {
                    'text-bolder': option.changedFields.defaultValue,
                  })}>
                  {!_.isNil(option.defaultValue) && String(option.defaultValue)}
                </td>
                <td
                  className={classNames('aggressiveWordBreak', {
                    'text-bolder': option.changedFields.value,
                  })}>
                  {!_.isNil(option.value) && String(option.value)}
                </td>
                <td
                  className={classNames('text-pre-wrap', {
                    'text-bolder': option.changedFields.note,
                  })}>
                  {option.note}
                </td>
              </tr>
            ))}
          </tbody>
          {(!configurationOptions || configurationOptions.length === 0) && (
            <tfoot>
              <tr>
                <td colSpan={4} className="text-center text-italic">
                  {t('ADMIN.CONFIGURATION.NONE_FOUND')}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
        {restartRequiredOptions.length > 0 && (
          <div className="text-italic">
            <span>{t('ADMIN.CONFIGURATION.RESTART_REQUIRED')}</span>
            {_.map(restartRequiredOptions, (option, index) => (
              <div key={option.path} className="simple-word-break">
                {option.path}
                {+index !== restartRequiredOptions.length - 1 && <span>,</span>}
              </div>
            ))}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <CancelAndSave
          submitFn={() => Promise.resolve(onClose(true))}
          cancelFn={onClose}
          btnDisabled={!configurationOptions.length}
          values={[]}
        />
      </Modal.Footer>
    </Modal>
  );
};
