// @ts-strict-ignore
import moment from 'moment-timezone';
import _ from 'lodash';
import { BEGINNING_OF_TIME, Capsule, END_OF_TIME } from '@/hybrid/utilities/datetime.constants';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { FormulaService } from '@/services/formula.service';
import { logWarn } from '@/hybrid/utilities/logger';
import { formatMessage } from '@/hybrid/utilities/logger.utilities';
import { base64guid } from '@/hybrid/utilities/utilities';
import {
  CAPSULE_SOURCE_ID_PROPERTY_NAME,
  CAPSULE_UNIQUE_PROPERTY_NAME,
} from '@/hybrid/tools/manualCondition/manualCondition.constants';

/**
 * Compute a condition formula containing the provided capsules.
 *
 * @param {Object[]} capsules - array of capsules
 * @returns {String} the string for the formula
 */
export function conditionFormula(capsules: Capsule[]) {
  if (!_.isArray(capsules) || capsules.length === 0) {
    throw new Error('A condition built using a `condition` formula requires at least one capsule');
  }

  const args = _.map(capsules, capsuleFormula);

  // Adding 1 second (presumably) fixes some rounding problems
  const maxDuration = _.max(_.map(capsules, (capsule) => capsule.endTime - capsule.startTime)) + 1000;

  return `condition(${maxDuration}ms,\n  ${_.join(args, ',\n  ')})`;
}

/**
 * Computes the query interval for each capsule. Although a capsule has start and end time, the query interval
 * might be reduced or extended based on other parameters.
 * The calculated interval will be extended by 1ms on each end to avoid interpolated fake samples exactly on
 * capsule start and end time
 * @param seriesId - The series id
 * @param capsules - The set of capsules for which we calculate the query interval
 * @param offset - The offset to be applied an all capsules. The user may select a portion of the chart and zoom in.
 * @param maxCapsuleDuration - The duration of the longest capsule.
 * @param displayRangeEndTime - The display range end time. It is used for capsules that do not have the end time set.
 * off-screen
 * @param queryDataOutsideCapsules - Specifies if data outside capsule should be retrieved
 * @returns an array of capsules with the start and end time adjusted for the chart display options
 */
export function findCapsulesQueryInterval(
  seriesId: string,
  capsules: Capsule[],
  offset: { lower: number; upper: number },
  maxCapsuleDuration: number,
  displayRangeEndTime: number,
  queryDataOutsideCapsules: boolean,
): {
  interestId: string;
  capsuleId: string;
  startTime: number;
  endTime: number;
}[] {
  // overwrite capsule duration to maxCapsuleDuration if we have to show the data outside of capsules
  const forceLongestCapsuleSeriesDuration = queryDataOutsideCapsules;

  return (
    _.chain(capsules)
      .map((capsule) => {
        let startTime = capsule.startTime + offset.lower;
        // use the end time of the display range if the capsule end time is not set
        let capsuleEndTime = capsule.endTime ?? displayRangeEndTime;
        if (forceLongestCapsuleSeriesDuration) {
          capsuleEndTime = capsule.startTime + maxCapsuleDuration;
        }
        // offset.upper is a negative value relative to the right part of the display range
        const zoomEndTime = capsule.startTime + maxCapsuleDuration + offset.upper;
        let endTime = Math.min(capsuleEndTime, zoomEndTime);

        // Extend the interval by 1ms on each end
        startTime--;
        endTime++;
        return {
          interestId: seriesId,
          capsuleId: capsule.id,
          startTime,
          endTime,
        };
      })
      // some capsules might not have any data in the zoomed interval
      .reject(({ startTime, endTime }) => startTime > endTime)
      .value()
  );
}

/**
 * Compute a capsule formula for the provided capsule.
 *
 * @param {Object} capsule - the capsule
 * @returns {String} the string for the formula
 */
export function capsuleFormula(capsule: Capsule) {
  // Add the property for the id if needed
  const properties = _.isNil(capsule.id)
    ? capsule.properties
    : _.uniqBy(
        [
          {
            name: CAPSULE_SOURCE_ID_PROPERTY_NAME,
            value: capsule.id,
            unitOfMeasure: 'string',
          },
          ...capsule.properties,
        ],
        'name',
      );

  const propertiesDefinition = _.map(properties, ({ name, value, unitOfMeasure }) => {
    let valueStr: string;
    if ((_.isNumber(value) && _.isNil(unitOfMeasure)) || _.isBoolean(value)) {
      valueStr = value.toString();
    } else if (_.isNumber(value) && _.isString(unitOfMeasure)) {
      valueStr = `${value} ${unitOfMeasure}`;
    } else if (_.isString(value)) {
      valueStr = `'${value}'`;
    } else {
      throw new Error(
        formatMessage`Unhandled property '${{
          name,
          value,
          unitOfMeasure,
        }}'`,
      );
    }

    return `setProperty('${name}', ${valueStr})`;
  });

  return _.join([`capsule(${capsule.startTime}ms, ${capsule.endTime}ms)`].concat(propertiesDefinition), '\n    .');
}

/**
 * Takes a `condition` formula and runs it so that the backend can tell us what capsules are inside.
 *
 * @param {String} formula - a `condition` formula
 * @param {FormulaService} sqFormula
 * @returns {Promise} resolves with a list of capsules
 */
export function requestCapsulesFromFormula(formula: string, sqFormula: FormulaService) {
  return sqFormula
    .computeCapsules({
      range: { start: BEGINNING_OF_TIME, end: END_OF_TIME },
      formula,
      parameters: {},
      usePost: true, // Formulas can become large enough to exceed node's max http header size
    })
    .then((results) => results.capsules)
    .then((capsules) =>
      _.map(capsules as any[], (c) => {
        const property = _.find(c.properties as any[], ['name', CAPSULE_SOURCE_ID_PROPERTY_NAME]);
        const capsule = {
          startTime: c.start / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
          endTime: c.end / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
          properties: property ? _.without(c.properties, property) : c.properties,
        };

        return !property
          ? capsule
          : ({
              ...capsule,
              id: property.value,
            } as Capsule);
      }),
    );
}

/**
 * The backend does not allow a condition to contain identical capsules; a capsule is identical if it has the same
 * start, end, and properties. If it is desirable for a condition to contain identical capsules, this function can
 * be used to ensure that each capsule is unique. If necessary, it will introduce the `UNIQUE_PROPERTY_NAME` property
 * to the capsule with a random guid.
 *
 * @param {Object[]} capsules - the capsule that should be made unique
 * @returns {Object[]} the capsules with an added `UNIQUE_PROPERTY_NAME` property if necessary.
 */
export function makeCapsulesUnique(capsules: Capsule[]): Capsule[] {
  if (capsules.length < 2) {
    // Need at least 2 capsules to have a collision
    return capsules;
  }
  return _.chain(capsules)
    .sortBy(['startTime', 'endTime'])
    .map((capsule, index, collection) => {
      const adjacent = collection[capsule === _.head(collection) ? index + 1 : index - 1];
      const isSimilar =
        _.isNil(capsule.id) &&
        _.isNil(adjacent.id) && // Assume if they have ids they will be unique
        Math.round(capsule.startTime) === Math.round(adjacent.startTime) &&
        Math.round(capsule.endTime) === Math.round(adjacent.endTime);
      return !isSimilar
        ? capsule
        : {
            ...capsule,
            properties: _.uniqBy(
              [
                {
                  name: CAPSULE_UNIQUE_PROPERTY_NAME,
                  value: base64guid(),
                  unitOfMeasure: 'string',
                },
                ...(capsule.properties || []),
              ],
              'name',
            ),
          };
    })
    .value();
}

/**
 * We filter out invalid capsules before making a condition formula because if the backend fails to run the
 * formula then we will be unable to get the capsules back out of the formula since we rely on the backend to
 * parse and return results for us. Invalid capsules are logged, but otherwise will be removed silently
 *
 * @param {Object[]} capsules - the capsule that should checked for validity
 * @returns {Object[]} the valid capsules.
 */
export function removeInvalidCapsules(capsules: Capsule[]) {
  const [valid, invalid] = _.partition(capsules, isValidCapsule);

  _.forEach(invalid, (invalidCapsule) => {
    logWarn(`Removing an invalid capsule, ${capsuleFormula(invalidCapsule)}`);
  });

  return valid;
}

/**
 * Check if a capsule is valid. An invalid capsule is one that the backend will reject. Currently, this will
 * happen if the capsule's end is before the start.
 *
 * @param {Object} capsule - the capsule to check
 * @returns {Boolean} true if the capsule is valid
 */
export function isValidCapsule(capsule: Capsule): boolean {
  return capsule.startTime && capsule.endTime && moment(capsule.startTime).isBefore(moment(capsule.endTime));
}
