// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { WorksheetThumbnail } from '@/hybrid/worksheets/WorksheetThumbnail.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { formatDateWorkbook } from '@/hybrid/datetime/dateTime.utilities';
import { WorkbenchActions } from '@/workbench/workbench.actions';

import { getWorksheets } from '@/hybrid/worksheets/worksheets.utilities';
import { APP_STATE } from '@/main/app.constants';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ButtonWithPopover } from '@/hybrid/core/ButtonWithPopover.molecule';
import { truncate } from '@/hybrid/utilities/utilities';
import { cancelAll } from '@/hybrid/requests/pendingRequests.utilities';
import { canModifyWorkbook } from '@/services/authorization.service';

const worksheetDropdownBindings = bindingsDefinition({
  sqWorkbenchActions: injected<WorkbenchActions>(),
  workbook: prop<any>(),
  setIsOpen: prop<(isOpen: boolean) => void>(),
  $state: injected<ng.ui.IStateService>(),
});

export const WorksheetDropdown: SeeqComponent<typeof worksheetDropdownBindings> = (props) => {
  const { workbook, setIsOpen } = props;
  const { sqWorkbenchActions, $state } = useInjectedBindings(worksheetDropdownBindings);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [worksheets, setWorksheets] = useState([]);

  const handleIconClick = (isOpen) => {
    setIsOpen(isOpen);
    if (isOpen) loadWorksheets();
  };

  const loadWorksheets = () => {
    setIsLoading(true);
    getWorksheets(workbook.workbookId)
      .then((worksheets) => {
        const formattedWorksheets = _.map(worksheets, (worksheet: any) =>
          _.assign({}, worksheet, {
            updatedAt: formatDateWorkbook(worksheet.updatedAt),
          }),
        );
        setWorksheets(formattedWorksheets);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const gotoWorksheet = (worksheetId, e) => {
    setIsOpen(false);
    cancelAll();
    sqWorkbenchActions.setOpeningAndLoadingItemId(null);
    $state.goNewTab(
      canModifyWorkbook(workbook) ? APP_STATE.WORKSHEET : APP_STATE.VIEW_WORKSHEET,
      {
        workbookId: workbook.workbookId,
        worksheetId,
        currentFolderId: $state.params.currentFolderId,
      },
      e,
    );
  };

  const dropdownId = `itemInfo-${workbook.workbookId}`;

  return (
    <div className="mr10 pt10">
      <ButtonWithPopover
        label={
          <div className="sq-icon-hover">
            <Icon
              icon="fa-info-circle"
              extraClassNames="fa-xlg fa-fw folderAction"
              testId="worksheetDropdownButton"
              tooltipDelay={500}
              tooltip="HOME_SCREEN.WORKSHEET_POPOVER.TOOLTIP"
              id={dropdownId}
            />
          </div>
        }
        popoverConfig={{
          id: `${dropdownId}Menu`,
          placement: 'bottom-end',
          wrapperClassNames: 'min-width-500',
          contentClassNames: 'min-width-500',
        }}
        onToggle={handleIconClick}
        extraClassNames="width-fit-content">
        <div
          className="forceFont p10 max-height-250 overflowYAuto overflowXHidden min-width-500 width-500"
          data-testid="worksheetDropdown">
          <div className="text-bolder sq-text-primary pb5 aggressiveWordBreak">
            {truncate(_.get(workbook, 'name'), 80, 35)}
          </div>
          <div className="mb10">
            <div className="simple-word-break">
              {_.get(workbook, 'description') ? (
                workbook.description
              ) : (
                <span className="text-italic">{t('WORKBOOK_NO_DESCRIPTION')}</span>
              )}
            </div>
          </div>
          {isLoading && (
            <div className="text-center mt10">
              <div>{t('HOME_SCREEN.WORKSHEET_POPOVER.LOADING')}</div>
              <IconWithSpinner spinning={true} />
            </div>
          )}
          {!isLoading && (
            <div className="flexColumnContainer flexWrap flexJustifyCenter flexAlignStart flexGrowShrink">
              {_.map(worksheets, (worksheet: any) => (
                <div
                  id={worksheet.worksheetId}
                  key={worksheet.worksheetId}
                  data-testid="worksheetInfo"
                  className="flexNoGrowNoShrink worksheetContainer homeScreenWorksheetContainer col-sm-4 cursorPointer text-center mb10 p10 min-width-130 max-width-130 mr10"
                  onClick={(e) => gotoWorksheet(worksheet.worksheetId, e)}>
                  <WorksheetThumbnail
                    workbookId={workbook.workbookId}
                    worksheetId={worksheet.worksheetId}
                    isActive={false}
                    updatedAt={worksheet.updatedAt}
                  />
                  <div className="mt5">
                    <HoverTooltip placement="top" text={worksheet.name}>
                      <div className="text-bolder sq-text-info">{truncate(worksheet.name, 16, 6)}</div>
                    </HoverTooltip>
                    <div className="sq-text-info font-size-smaller">{worksheet.updatedAt}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </ButtonWithPopover>
    </div>
  );
};
