import React from 'react';
import { sqHomeScreenStore, sqPluginStore } from '@/core/core.stores';
import { generatePluginApi } from '@/hybrid/plugin/generatedPluginApi';
import { pluginWorkerApi } from '@/webWorkers/plugin.worker-api';
import { PluginHost } from '@/hybrid/plugin/PluginHost.atom';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { getInjector } from '@/hybrid/utilities/conversion.utilities';
import { angularComponent } from '@/hybrid/core/react2angular.util';

export const HomeScreenAddOnHost: React.FunctionComponent<{}> = () => {
  useFlux(sqHomeScreenStore);
  useFlux(sqPluginStore);

  const plugin = sqPluginStore.getPlugin(sqHomeScreenStore.displayedAddOnIdentifier);
  const createApi = (port: MessagePort) =>
    generatePluginApi(plugin?.identifier || '', pluginWorkerApi(), getInjector(), port);

  return (
    <div className="flexFill flexRowContainer height-maximum width-maximum" data-testid="homeScreenAddOnHost">
      <PluginHost entryPoint={plugin?.entryPoint || ''} createApi={createApi} />
    </div>
  );
};

export const sqHomeScreenAddOnHost = angularComponent({}, HomeScreenAddOnHost);
