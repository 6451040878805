// @ts-strict-ignore
import _ from 'lodash';
import angular from 'angular';
import { TrendActions } from '@/trendData/trend.actions';
import { setWorkbench } from '@/hybrid/workbench/workbench.utilities';
import { setWorkBook } from '@/hybrid/workbooks/workbook.utilities';
import { DEBOUNCE } from '@/core/core.constants';
import { APP_STATE, SEARCH_TYPES } from '@/main/app.constants';
import { logError, logWarn } from '@/hybrid/utilities/logger';
import {
  debounceAsync,
  headlessRenderMode,
  isPresentationWorkbookMode,
  isViewOnlyWorkbookMode,
} from '@/hybrid/utilities/utilities';
import { infoToast } from '@/hybrid/utilities/toast.utilities';
import { flux } from '@/core/flux.module';
import {
  InitializeMode,
  PersistenceLevel,
  PUSH_IGNORE,
  PUSH_WORKBENCH,
  PUSH_WORKBOOK,
  PUSH_WORKSTEP_IMMEDIATE,
  Store,
} from '@/core/flux.service';
import { generate } from '@/hybrid/utilities/screenshot.utilities';
import { sqWorkbookStore, sqWorkstepsStore } from '@/core/core.stores';
import { isRehydrating, setIsRehydrating } from '@/services/stateSynchronizer.utilities';
import { pushWorkstepAction } from '@/worksteps/worksteps.actions';
import { initializeSearchActions } from '@/search/search.actions';
import { resetRedactionService } from '@/hybrid/utilities/redaction.utilities';

const dependencies = ['Sq.Worksteps', 'Sq.Workbench', 'Sq.TrendData', 'Sq.Search'];

angular.module('Sq.Core.StateSynchronizer', dependencies).service('sqStateSynchronizer', sqStateSynchronizer);

/** Options interface to be passed to sqStateSynchronizer.rehydrate() */
export interface RehydrateOptions {
  /**
   * Specifies on what level (workbench, workbook, worksheet, or none) stores should be rehydrated
   */
  persistenceLevel?: PersistenceLevel;

  /**
   * Specifies whether to initialize all stores (FORCE) or just those that have been modified (SOFT)
   */
  initializeMode?: InitializeMode;

  /**
   * Indicates whether the rehydrated state should be pushed to a new workstep
   */
  pushWorkstep?: boolean;

  /**
   * Optional predicate that return true for stores that should be rehydrated. If not given, all stores of the
   * specified initialize mode / persistence level will be rehydrated
   */
  storeFilter?: (storeInstance: Store, storeName: string) => boolean;

  /**
   * ID of the workbook from which this state originates If persistenceLevel is WORKBOOK or WORKSHEET this value is
   * required and setLoadingWorksheet() must be called first.
   */
  workbookId?: string;

  /**
   * ID of the worksheet from which this state originates If persistenceLevel is WORKBOOK or WORKSHEET this value is
   * required and setLoadingWorksheet() must be called first.
   */
  worksheetId?: string;

  /**
   * Optional hook to change the state before making requests.
   * For example changing the display range requires refreshing most data, so if the display range has been changed
   * via a url parameter it should be changed after the synchronous data is present in the store but before all the
   * data is requested from the backend.
   */
  beforeFetch?: () => ng.IPromise<any>;

  /**
   * Optional hook specifying how to fetch data after rehydrating. By default, all trend items will be fetched and the
   * main search pane will be initialized.
   */
  fetchData?: () => ng.IPromise<any>;
}

export type StateSynchronizerService = ReturnType<typeof sqStateSynchronizer>;

function sqStateSynchronizer($state: ng.ui.IStateService, $window: ng.IWindowService, sqTrendActions: TrendActions) {
  let currentPush;
  let deferredPush;
  let currentWorkbenchState = {};
  let currentWorkbookState = {};
  let currentWorksheetState = {};
  let loadingWorksheet;
  const debouncedWorksheetPush = _.debounce(pushWorksheetState, DEBOUNCE.WORKSTEP);
  const debouncedLoadWorkstep = debounceAsync(rehydrateWorkstep);

  const service = {
    push,
    rehydrate,
    initialize,
    onWorkstep,
    fetchRehydrateData, // Exposed for testing
    setLoadingWorksheet: (workbookId, worksheetId) => {
      if (loadingWorksheet) {
        logWarn('Attempted to setLoadingWorksheet, but a worksheet is already loading');
        return;
      }

      loadingWorksheet = { workbookId, worksheetId };
    },
    unsetLoadingWorksheet: () => {
      loadingWorksheet = undefined;
    },
    isLoadingWorksheet: () => {
      return !!loadingWorksheet;
    },
  };

  return service;

  /**
   * Invokes the correct push method based on the specified pushMode.
   *
   * @param {String} [pushMode] - One of the PUSH constants. If not specified it defaults to debounced worksheet push
   * @param {Object} [options] - Additional push options
   * @param {String} [options.workbookId=$state.params.workbookId] - The workbook id to push
   * @param {String} [options.worksheetId=$state.params.worksheetId] - The worksheet id to push
   * @returns {Promise} if push immediate then resolves when the push is complete otherwise resolves immediately
   */
  function push(pushMode?, options?) {
    if (pushMode === PUSH_IGNORE) {
      return Promise.resolve();
    }

    options = _.defaults(options || {}, {
      workbookId: $state.params.workbookId,
      worksheetId: $state.params.worksheetId,
    });

    return Promise.resolve().then(() => {
      if (pushMode === PUSH_WORKBENCH) return saveWorkbenchState();

      if (isRehydrating()) return;
      if (headlessRenderMode()) return;
      // No workbook means there won't be a push, and checking here prevents console errors for the calls below
      if (!options.workbookId) return;
      if (isViewOnlyWorkbookMode()) return;
      if (isPresentationWorkbookMode()) return;

      if (options.workbookId && sqWorkbookStore.workbookId === options.workbookId && pushMode === PUSH_WORKBOOK) {
        return saveWorkbookState(options.workbookId); // Only save workbook state if we're in the same workbook
      }

      if (options.workbookId && options.worksheetId) {
        if (service.isLoadingWorksheet() && pushMode !== PUSH_WORKSTEP_IMMEDIATE) {
          // If we are loading a worksheet, we force any worksheet push to be immediately pushed to the worksheet
          // that is in the process of loading to avoid the potential for leaking workstep information between
          // worksheets.
          pushMode = PUSH_WORKSTEP_IMMEDIATE;
          options.workbookId = loadingWorksheet.workbookId;
          options.worksheetId = loadingWorksheet.worksheetId;
        }

        if (pushMode === PUSH_WORKSTEP_IMMEDIATE) {
          if (_.isFunction(debouncedWorksheetPush.cancel)) {
            debouncedWorksheetPush.cancel();
          }
          return pushWorksheetState(options.workbookId, options.worksheetId);
        } else {
          if (!service.isLoadingWorksheet()) {
            debouncedWorksheetPush(options.workbookId, options.worksheetId);
          }
        }
      }
    });
  }

  /**
   * Persists workbench state
   */
  function saveWorkbenchState() {
    let newWorkbenchState;
    const newState = flux.dispatcher.dehydrate();

    newWorkbenchState = filterStoresWithPersistenceLevel(newState, 'WORKBENCH');
    if (_.isEqual(JSON.stringify(currentWorkbenchState), JSON.stringify(newWorkbenchState))) {
      return Promise.resolve();
    }
    currentWorkbenchState = newWorkbenchState;
    return setWorkbench(newWorkbenchState);
  }

  /**
   * Persists workbook state
   */
  function saveWorkbookState(id) {
    let newWorkbookState;
    const newState = flux.dispatcher.dehydrate();

    newWorkbookState = filterStoresWithPersistenceLevel(newState, 'WORKBOOK');
    if (!_.isEqual(JSON.stringify(currentWorkbookState), JSON.stringify(newWorkbookState))) {
      currentWorkbookState = newWorkbookState;
      setWorkBook(id, newWorkbookState);
    }
  }

  /**
   * Persists the current worksheet state by pushing as a workstep
   *
   * @param {String} workbookId - The workbook id to push.
   * @param {String} worksheetId - The worksheet id to push.
   * @return {Promise|undefined} - Promise if it pushes, undefined if not
   */
  function pushWorksheetState(workbookId, worksheetId) {
    if (isRehydrating()) return;
    if (currentPush) {
      deferredPush = () => {
        if (workbookId === $state.params.workbookId && worksheetId === $state.params.worksheetId) {
          return service.push(PUSH_WORKSTEP_IMMEDIATE, {
            workbookId,
            worksheetId,
          });
        } else {
          logError('Not performing enqueued push because $state has changed. This should not happen!');
        }
      };
      return currentPush;
    }

    const newState = flux.dispatcher.dehydrate();
    const newWorksheetState = filterStoresWithPersistenceLevel(newState, 'WORKSHEET');

    if (!_.isEqual(JSON.stringify(currentWorksheetState), JSON.stringify(newWorksheetState))) {
      currentPush = Promise.resolve();

      // If we have a next workstep, that means we're currently on a previous workstep, so we need to push
      // the state of that workstep before pushing the new state. This provides a nice transition when going
      // backwards through the workstep history. (e.g. If the history is 1 2 3 and users goes back to 2 and
      // then new step 4 is added, the history will now be 1 2 3 2 4)
      if (sqWorkstepsStore.next) {
        currentPush = currentPush.then(() =>
          pushWorkstepAction(workbookId, worksheetId, currentWorksheetState)
            // Even if it fails the current workstep should be pushed
            .catch(_.noop),
        );
      }

      // Ensure the current workstep is pushed after the previous one (if there is a previous workstep)
      currentPush = currentPush
        .then(() => pushWorkstepAction(workbookId, worksheetId, newWorksheetState))
        .then((response: any) => {
          currentWorksheetState = newWorksheetState;
          generate(workbookId, worksheetId);
        })
        // Do not want to fail if the push fails for some reason (usually cancellation)
        .catch(_.noop)
        .finally(() => {
          currentPush = undefined;
          if (deferredPush) {
            const response = deferredPush();
            deferredPush = undefined;
            return response;
          }
        });
    }

    return currentPush;
  }

  function filterStoresWithPersistenceLevel(newState, persistenceLevel) {
    // Get an array containing all the store names that apply at the requested persistence level
    const storeNames = _.chain(flux.dispatcher.storeInstances)
      .pickBy(function (store, storeName) {
        if (!store.persistenceLevel) {
          throw new Error(`${storeName} has no PersistenceLevel`);
        }

        return store.persistenceLevel === persistenceLevel;
      })
      .keys()
      .value();

    // Return an object containing only the applicable stores for the requested persistence level
    return {
      stores: _.pickBy(newState.stores, function (value, key) {
        return _.includes(storeNames, key);
      }),
    };
  }

  /**
   * Handle workstep messages received over websocket for the current worksheet which is what enables fast-follow
   * (where updates from another user are reflected for the current user). Several guards are in place to ensure that
   * worksteps never get applied to the wrong worksheet or at the wrong time:
   * - The worksheet is presentation-mode in Analysis, so fast follow does not apply.
   * - The current worksheet is in the process of loading, which indicates the user is transitioning somewhere else
   * - The current worksheet does not match the workstep's worksheet, which could indicate a workstep channel was
   * not properly closed or a race condition (CRAB-18940).
   *
   * @param {Object} data an object describing a workstep
   * @param {Object} data.workstepData a JSON string to containing workstep details
   */
  function onWorkstep(data) {
    const workstepData = _.attempt(JSON.parse, data.workstepData);
    if (
      (!sqWorkbookStore.isReportBinder && isPresentationWorkbookMode()) ||
      service.isLoadingWorksheet() ||
      data.workbookId !== $state.params.workbookId ||
      data.worksheetId !== $state.params.worksheetId
    ) {
      return;
    }

    // Handle the view-only case.
    if (isViewOnlyWorkbookMode()) {
      onViewOnlyWorkstep();
    } else {
      debouncedLoadWorkstep(data.workbookId, data.worksheetId, workstepData);
    }
  }

  /**
   * Handle a workstep received in view only mode.
   */
  function onViewOnlyWorkstep() {
    function reloadPageWithShortURL() {
      // $state.reload() almost works but changes the shorter url into a longer url
      $state.go($state.current, $state.params, {
        location: false,
        reload: true,
        notify: false,
      });
    }

    infoToast({
      messageKey: 'RELOAD_MESSAGE',
      buttonLabelKey: 'RELOAD',
      buttonAction: reloadPageWithShortURL,
    });
  }

  /**
   * Rehydrates workstep data associated with a particular worksheet
   *
   * @param workbookId {String} the id of the workbook
   * @param worksheetId {String} the id of the worksheet
   * @param workstepData {Object} the workstep data do rehydrate
   */
  function rehydrateWorkstep(workbookId, worksheetId, workstepData) {
    service.setLoadingWorksheet(workbookId, worksheetId);
    return service
      .rehydrate(workstepData.state, {
        persistenceLevel: 'WORKSHEET',
        initializeMode: 'SOFT',
        workbookId,
        worksheetId,
      })
      .finally(() => {
        service.unsetLoadingWorksheet();
      });
  }

  /**
   * Initializes all stores and rehydrates their previous state if present.
   *
   * Stores can specify an array of other stores which must first rehydrate by adding the `rehydrateWaitFor`
   * property. Note that rehydrateWaitFor can not be used to wait for a store that is not part of its
   * persistenceLevel. Also note that while it supports a chain of dependencies (storeA -> storeB -> storeC), there is
   * no circular dependency checking, but you'll figure that out soon enough if you create one :)
   *
   * @param {Object} [dehydratedState] - An object with a `stores` property. Usually the result of the
   *   `dispatcher.dehydrate` method.
   * @param {RehydrateOptions} [options] - Additional options for rehydrate
   * @returns {Promise} A promise that is resolved when all the rehydrate stores finish rehydrating.
   */
  function rehydrate(dehydratedState?, options?: RehydrateOptions): Promise<any> {
    options = _.defaults(options, {
      persistenceLevel: 'WORKSHEET',
      initializeMode: 'FORCE',
      pushWorkstep: false,
      beforeFetch: _.noop,
      fetchData: service.fetchRehydrateData,
    });

    if (options.pushWorkstep && options.persistenceLevel !== 'WORKSHEET') {
      return Promise.reject('persistence level must be WORKSHEET in order to push workstep after rehydrating');
    }

    const areParametersGuarded = _.includes(['WORKBOOK', 'WORKSHEET'], options.persistenceLevel);

    if (areParametersGuarded && !(options.workbookId && options.worksheetId)) {
      return Promise.reject('workbookId and worksheetId are required when rehydrating workbooks or worksheets');
    }

    const isLoadingCorrectState =
      loadingWorksheet &&
      options.workbookId === loadingWorksheet.workbookId &&
      options.worksheetId === loadingWorksheet.worksheetId;
    // If state is mismatched or it is still rehydrating, likely because of race conditions that occur when
    // transitioning between worksheets while another rehydrate is still going, then it is not safe to proceed because
    // the worksheet data will be overwritten. Since the existing promise can't be interrupted the safest thing is
    // to reload the page with the specified worksheet. There is similar logic in app.module.
    if (areParametersGuarded && (!isLoadingCorrectState || isRehydrating())) {
      $window.location.href = $state.href(APP_STATE.WORKSHEET, _.pick(options, ['workbookId', 'worksheetId']));
      const message = !isLoadingCorrectState
        ? 'workbookId and worksheetId do not match those from setLoadingWorksheet()'
        : 'rehydrate already in process';
      logWarn(`Preventing rehydrate and reloading worksheet because ${message}`);
      return Promise.resolve();
    }

    try {
      rehydrateSynchronous(dehydratedState, options);
    } catch (e) {
      return Promise.reject(e);
    }

    if (options.persistenceLevel !== 'WORKSHEET') {
      return Promise.resolve();
    }

    // While this dynamic data is coming in we don't want extra worksteps created which is why isRehydrating is not
    // set to false until after it finishes.
    setIsRehydrating(true);
    return Promise.resolve()
      .then(options.beforeFetch)
      .then(options.fetchData)
      .then(() => {
        setIsRehydrating(false);

        if (options.pushWorkstep) {
          return service.push(PUSH_WORKSTEP_IMMEDIATE, _.pick(options, ['workbookId', 'worksheetId']));
        }
      })
      .catch(() => {
        setIsRehydrating(false);
        if (options.pushWorkstep) {
          return service.rehydrate(
            currentWorksheetState,
            _.chain(options)
              .omit(['pushWorkstep', 'beforeFetch', 'storeFilter'])
              .merge({ initializeMode: 'SOFT' })
              .value(),
          );
        }
      });
  }

  /**
   * Internal method for the rehydrate method
   *
   * @see rehydrate
   */
  function rehydrateSynchronous(dehydratedState, options: RehydrateOptions) {
    const rehydrateCalled = {};

    // Reset redaction monitor before we start rehydration so we can recognise if any items on the worksheet failed to
    // load during rehydration because of insufficient permissions.
    if (options.persistenceLevel === 'WORKSHEET') {
      resetRedactionService();
    }

    const storeInstances = getStoresToRehydrate(dehydratedState, options);

    _.chain(storeInstances).values().filter('initialize').invokeMap('initialize', options.initializeMode).value();

    const setState = (state) => {
      if (options.pushWorkstep) {
        return;
      }
      if (options.persistenceLevel === 'WORKBENCH') {
        currentWorkbenchState = state;
      } else if (options.persistenceLevel === 'WORKBOOK') {
        currentWorkbookState = state;
      } else if (options.persistenceLevel === 'WORKSHEET') {
        currentWorksheetState = state;
      }
    };

    // If there is no dehydratedState then it is the special case where internal state is being reset
    if (_.isUndefined(dehydratedState)) {
      setState(undefined);
    } else {
      _.forEach(storeInstances, function callRehydrate(store: any, name: string) {
        if (rehydrateCalled[name]) {
          return;
        }

        if (store.rehydrateWaitFor) {
          _.forEach(store.rehydrateWaitFor, function (dependencyName) {
            if (storeInstances[dependencyName]) {
              callRehydrate(storeInstances[dependencyName], dependencyName);
            }
          });
        }

        if (store.rehydrate && dehydratedState.stores && dehydratedState.stores[name]) {
          store.rehydrate(dehydratedState.stores[name]);
        }

        rehydrateCalled[name] = true;
      });

      setState(filterStoresWithPersistenceLevel(flux.dispatcher.dehydrate(), options.persistenceLevel));
    }
  }

  /**
   * Gets stores that need to be rehydrated given a dehydratedState. Stores that have differing state from the
   * dehydrated state are included in the output while stores that have matching state are not included. If
   * options.initializeMode is FORCE, then all stores of the appropriate persistence level are included.
   *
   * @param {Object} [dehydratedState] the dehydrated state used to determine what stores to rehydrate
   * @param {String} [options.persistenceLevel='WORKSHEET'] - one of PersistenceLevel
   * @param {Boolean} [options.initializeMode='FORCE'] - one of InitializeMode
   * @param {Function} [options.storeFilter] - if given, only returns stores for which storeFilter returns true
   * @return {Array} An array of the stores that need to be rehydrated
   */
  function getStoresToRehydrate(dehydratedState, options: RehydrateOptions) {
    let currentState;
    let storeInstances;
    const changedStores = {};

    const isStoreAllowedForRehydrate = _.defaultTo(options.storeFilter, _.constant(true));

    // Filter by persistence level and storeFilter
    storeInstances = _.pickBy(
      flux.dispatcher.storeInstances,
      (instance, storeName) =>
        instance.persistenceLevel === options.persistenceLevel && isStoreAllowedForRehydrate(instance, storeName),
    );

    /*
     * If we have dehydrated state, then filter so we only rehydrate those stores that have actually changed. However,
     * if we are forcing initialization then we want all the stores to be reinitialized
     */
    if (dehydratedState && options.initializeMode !== 'FORCE') {
      // Determine which stores have changed
      currentState = flux.dispatcher.dehydrate();
      _.forEach(storeInstances, function (store, key) {
        changedStores[key] =
          dehydratedState.stores &&
          dehydratedState.stores[key] &&
          JSON.stringify(dehydratedState.stores[key]) !== JSON.stringify(currentState.stores[key]);
      });

      // Filter so only changed stores and stores that depend on changed stores are rehydrated
      storeInstances = _.pickBy(
        storeInstances,
        _.rearg(function hasStoreChanged(storeName) {
          return changedStores[storeName] || _.some(storeInstances[storeName].rehydrateWaitFor, hasStoreChanged);
        }, 1),
      );
    }

    return storeInstances;
  }

  /**
   * Initialize all the states at the provided persistenceLevel using the initializeMode.
   *
   * @param {PersistenceLevel} persistenceLevel - the group of stores to initialize
   */
  function initialize(persistenceLevel: PersistenceLevel) {
    return rehydrateSynchronous(undefined, {
      persistenceLevel,
      initializeMode: 'FORCE',
    });
  }

  /**
   * Fetch all items for the details pane and the search pane
   *
   * @return {Promise} resolves when all of the items have been fetched.
   */
  function fetchRehydrateData(): Promise<[any[], any]> {
    return Promise.all([
      sqTrendActions.fetchAllItems(),
      initializeSearchActions('main', SEARCH_TYPES, false, undefined),
    ]);
  }
}
