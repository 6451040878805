// @ts-strict-ignore
import _ from 'lodash';
import { SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { sqFoldersApi } from '@/sdk';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { flux } from '@/core/flux.module';
import { equalsIgnoreCase } from '@/hybrid/utilities/utilities';
import { tabFolders } from '@/hybrid/homescreen/homescreen.constants';
import { sqExploreFolderModalStore, sqHomeScreenStore } from '@/core/core.stores';
import { isEveryoneGroupEnabled } from '@/services/systemConfiguration.utilities';
import { isAdmin } from '@/services/authorization.service';

export type ExploreFolderModalActions = ReturnType<typeof sqExploreFolderModalActions>;

export function sqExploreFolderModalActions(sqHomeScreenUtilities: HomeScreenUtilitiesService) {
  const service = {
    setInitialFolderId,
    setWorkbookId,
    setSort,
    clear,
    setTableFilter,
    setPageSizeForTable,
    setPageNumberAndGo,
    setSearchParams,
    loadItems,
    setFolder,
    getRootAndUpdateFilter,
    setShowResults,
    setTableRoot,
    getInitialFolder,
  };

  return service;

  /**
   * Sets the ID of the folder where the user started
   *
   * @param {String} initialFolderId - ID of a folder
   */
  function setInitialFolderId(initialFolderId) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_INITIAL_FOLDER_ID', {
      initialFolderId,
    });
  }

  /**
   * Sets the workbook ID
   *
   * @param {String} workbookId - a workbook ID
   */
  function setWorkbookId(workbookId) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_WORKBOOK_ID', { workbookId });
  }

  /**
   * Sets the sort field that controls the sorting of the workbooks and toggles the direction if the field
   * was already set to the specified one.
   *
   * @param {String} sortProperty - One of WORKBOOKS_SORT_FIELDS
   * @param {boolean} sortAscending - True for asc, false for desc
   * @param {boolean} forceSortDirection=false - True if the given sort direction must not be flipped when sorting
   *   on the currently sorted property (normally used to toggle sort direction)
   */
  function setSort(sortProperty, ascending: boolean, forceSortDirection = false) {
    const sortAscending =
      !forceSortDirection && sortProperty === sqExploreFolderModalStore.sortProperty ? !ascending : ascending;
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_SORT', {
      sortProperty,
      sortAscending,
    });
  }

  /**
   * Clears the data for the currently loaded seeq content image.
   */
  function clear() {
    flux.dispatch('EXPLORE_FOLDER_MODAL_CLEAR');
  }

  function setTableFilter(tableFilter) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_TABLE_FILTER', { tableFilter });
  }

  function setSearchParams(field, value) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_SEARCH_PARAMS', { field, value });
  }

  function setPageSizeForTable({ size }) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_PAGE_SIZE', { pageSize: size });
  }

  function setPageNumberAndGo(page: number, loadTable: Function) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_PAGE_NUMBER', { pageNumber: page });
    loadTable();
  }

  function setFolder(folder) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_FOLDER', { folder });
  }

  function setShowResults(showResults) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_SHOW_RESULTS', { showResults });
  }

  function setTableRoot(root) {
    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_TABLE_ROOT', { root });
  }

  function loadItems() {
    if (_.isUndefined(sqExploreFolderModalStore.initialFolderId)) {
      return;
    }

    const limit = sqExploreFolderModalStore.getPageSizeByTable();
    const currentPageNumber = sqExploreFolderModalStore.getPageNumberForTable();
    const offset = (currentPageNumber - 1) * limit;
    const sortDirection = sqExploreFolderModalStore.sortAscending ? 'asc' : 'desc';
    const sortOrder = `${sqExploreFolderModalStore.sortProperty} ${sortDirection}`;
    const root = sqExploreFolderModalStore.tableRoot;
    const params = _.assign({}, sqExploreFolderModalStore.searchParams, {
      limit,
      offset,
      sortOrder,
      root,
      filter:
        sqExploreFolderModalStore.initialFolderId === null && !sqExploreFolderModalStore.searchParams.textSearch
          ? SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC
          : sqExploreFolderModalStore.searchParams.filter,
    });

    flux.dispatch('EXPLORE_FOLDER_MODAL_SET_LOADING', { loading: true });
    return sqFoldersApi
      .getFolders(params)
      .then((response) => {
        flux.dispatch('EXPLORE_FOLDER_MODAL_SET_ITEMS', {
          items: response.data?.content,
        });
        flux.dispatch('EXPLORE_FOLDER_MODAL_SET_ITEM_TOTAL_FOR_TABLE', {
          itemTotalForTable: response.data?.totalResults,
        });
      })
      .finally(() => flux.dispatch('EXPLORE_FOLDER_MODAL_SET_LOADING', { loading: false }));
  }

  function getRootAndUpdateFilter(folderId) {
    if (folderId) {
      return sqFoldersApi.getAncestors({ folderId }).then((folderTree) => {
        const itemFolderRoot = folderTree.data.id;
        if (
          _.includes(
            [SEARCH_ITEM_LOCATIONS.CORPORATE, SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC, SEARCH_ITEM_LOCATIONS.MY_FOLDER],
            itemFolderRoot,
          )
        ) {
          service.setTableFilter(itemFolderRoot);
          service.setSearchParams('filter', itemFolderRoot);
        }
      });
    } else {
      return Promise.resolve({});
    }
  }

  /**
   * @param {Boolean} forceCorporate - checks if the function is being called within the corporate drive message,
   * which will default the folder to being the corporate drive
   */
  function getInitialFolder({
    currentTab,
    item,
    forceCorporate = false,
    insideAdvancedSearch = false,
    isCurrentFolderNested = false,
    breadcrumbsPresent = false,
    breadcrumbClicked = false,
    locationChangedInAdvanced = false,
    currentFolderId = '',
    locationValue = '',
  }) {
    let tabFolderPromise;
    let tableFilter = currentTab;
    const tabFolder = _.includes(tabFolders(isAdmin(), isEveryoneGroupEnabled()), currentTab) ? currentTab : undefined;

    sqHomeScreenUtilities.getTabFolder(tabFolder).then((folder) => {
      let updatedFolder = _.cloneDeep(folder);
      if (
        (item !== undefined && !forceCorporate && insideAdvancedSearch) ||
        (item && !equalsIgnoreCase(item.parentFolderId, updatedFolder?.id) && !forceCorporate)
      ) {
        tabFolderPromise = sqHomeScreenUtilities.getTabFolderName(item.ancestors[0]?.id);
        tabFolderPromise.then((tabName) => {
          tableFilter = item.ancestors[0]?.id ? tabName : tableFilter;
          updatedFolder = insideAdvancedSearch
            ? item
            : _.assign({}, _.last(item.ancestors), {
                ancestors: _.slice(item.ancestors, 0, item.ancestors.length - 1),
              });
        });
      } else {
        tabFolderPromise = Promise.resolve();
      }

      // If the user is inside a nested folder and they are opening the specific folder modal within the home
      // screen advanced search, then the initial folder needs to be set to the folder of the current folder id.
      // When a user changes the location of the search or clicks on a breadcrumb, the modal should no longer show
      // the contents of the current folder id, so this condition will be skipped over.
      if (
        isCurrentFolderNested &&
        insideAdvancedSearch &&
        breadcrumbsPresent &&
        !breadcrumbClicked &&
        !locationChangedInAdvanced &&
        currentFolderId &&
        !item
      ) {
        tabFolderPromise.then(() => {
          sqHomeScreenUtilities
            .formatNestedFolderWithAncestors(sqHomeScreenStore.currentFolderId, locationValue, false)
            .then((response) => {
              service.setFolder(response);
              service.setTableFilter(SEARCH_ITEM_LOCATIONS[tableFilter]);
              service.setSearchParams('folderId', response?.id);
              service.setInitialFolderId(response?.id);
            });
        });
      } else {
        const advancedSearchId = item ? item?.id : updatedFolder?.id;
        const folderId = item && !forceCorporate && !insideAdvancedSearch ? item?.parentFolderId : advancedSearchId;
        tabFolderPromise.then(() => {
          service.setFolder(updatedFolder);
          service.setTableFilter(SEARCH_ITEM_LOCATIONS[tableFilter]);
          service.setSearchParams('folderId', folderId);
          service.setInitialFolderId(folderId);
        });
      }
    });
  }
}
