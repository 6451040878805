// @ts-strict-ignore
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { DatasourceStatusV1, sqDatasourcesApi, sqItemsApi, sqRequestsApi } from '@/sdk';
import _ from 'lodash';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';

/**
 * Return the latest of several ISO-8601 date strings, which may or may not be undefined
 *
 * @param isoDates - The list of iso date string
 * @returns The latest of the date strings
 */
function latestIsoDateTime(...isoDates: string[]): string {
  let latest;
  _.forEach(isoDates, (isoDate) => {
    if (latest === undefined || (isoDate && isoDate > latest)) {
      latest = isoDate;
    }
  });

  return latest;
}

/**
 * Convert a number of nanoseconds to a number of seconds, with a "reasonable" number of digits
 *
 * @param numNanos - The duration in nanoseconds
 * @returns The number of seconds of the duration with 'reasonable' number of digits
 */
function roundToReasonableDurationInSeconds(numNanos: number): string {
  const numSeconds = numNanos / 1000000000;
  if (numSeconds <= 0) {
    return String(numSeconds);
  }

  // when < 1 second: format like 0.123 (millisecond precision)
  // when >= 1 second: format like 12.3 (tenth of second precision)
  return numSeconds.toFixed(numSeconds < 1 ? 3 : 1);
}

export async function loadDatasources() {
  const externalDatasourceType = (status: DatasourceStatusV1) =>
    status.storedInSeeq ? 'ADMIN.DATASOURCE.LOCAL_DATASOURCE' : 'ADMIN.DATASOURCE.EXTERNAL_DATASOURCE';

  const { data } = await sqDatasourcesApi.getStatus({ offset: 0, limit: 1000 });

  return _.map(data.datasourceStatuses, (dsStatus) => {
    // Adjust the fields present in each datasource status so they match the expectation of the table
    const stats = dsStatus.statistics;

    return {
      id: dsStatus.datasourceGuid,
      datasourceGuid: dsStatus.datasourceGuid,
      datasourceName: dsStatus.datasourceName,
      seeqInternal: dsStatus.seeqInternal,
      datasourceType: dsStatus.seeqInternal ? 'ADMIN.DATASOURCE.SYSTEM_DATASOURCE' : externalDatasourceType(dsStatus),
      datasourceClass: dsStatus.datasourceClass,
      datasourceId: dsStatus.datasourceId,
      enabled: dsStatus.enabled,
      lastQueriedDatetime: latestIsoDateTime(
        stats.mostRecentSuccess,
        stats.mostRecentFailure,
        stats.mostRecentTimeout,
        stats.mostRecentCancellation,
      ),
      medianQueuedSeconds: roundToReasonableDurationInSeconds(stats.medianQueueNanos),
      medianDatasourceSeconds: roundToReasonableDurationInSeconds(stats.medianDatasourceNanos),
      medianTotalSeconds: roundToReasonableDurationInSeconds(stats.medianTotalNanos),
      numSuccesses: stats.numSuccesses,
      numFailures: stats.numFailures,
      numTimeouts: stats.numTimeouts,
      numCancellations: stats.numCancellations,
      numTotal: stats.numSuccesses + stats.numFailures + stats.numTimeouts + stats.numCancellations,
      totalNumDatums: stats.totalNumDatums,
    };
  });
}

/**
 * Sets the isEnabled property for a datasource
 *
 * @param datasource - the datasource object
 * @param value - the value to set the isEnabled property to
 * @returns A promise that resolves when the isEnabled property has been set
 */
export async function setDatasourceEnabled(datasource: any, value: boolean) {
  try {
    await sqItemsApi.setProperty(
      { value },
      {
        id: datasource.datasourceGuid,
        propertyName: SeeqNames.Properties.Enabled,
      },
    );
    const newStatus = value ? 'ADMIN.DATASOURCE.ENABLED_SUCCESS' : 'ADMIN.DATASOURCE.DISABLED_SUCCESS';
    const messageParams = {
      datasourceName: datasource.datasourceName,
      datasourceId: datasource.datasourceId,
    };
    successToast({ messageKey: newStatus, messageParams });
  } catch (error) {
    errorToast({ httpResponseOrError: error, displayForbidden: true });
  }
}

/**
 * Cancels all requests to the selected datasource
 */
export async function cancelDatasource({ datasourceClass, datasourceId }) {
  try {
    await sqRequestsApi.cancelRequests({ datasourceClass, datasourceId });
    successToast({
      messageKey: 'ADMIN.DATASOURCE.CANCELED_REQUEST_SUCCESS',
    });
  } catch (error) {
    errorToast({ httpResponseOrError: error, displayForbidden: true });
  }
}
