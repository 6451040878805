import React from 'react';
import ReactDOM from 'react-dom';

type PortalProps = {
  children: React.ReactNode;
};

/**
 * This component injects "children" as a direct descendant of <body> (on the same level as the root div)
 */
export const Portal: React.FunctionComponent<PortalProps> = ({ children }) =>
  ReactDOM.createPortal(children, document.body);
