// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';
import { formatTime as formatDateTime } from '@/hybrid/datetime/dateTime.utilities';
import { CAPSULE_GROUP_DATE_FORMAT } from '@/hybrid/utilities/datetime.constants';
import { useTranslation } from 'react-i18next';
import { Timezone } from '@/datetime/timezone.service';

interface ScatterPlotLegendColorConditionItemsProps {
  colorConditions: any[];
  colorRanges: any[];
  timezone: Timezone;
}

export const ScatterPlotLegendColorConditionItems: React.FunctionComponent<
  ScatterPlotLegendColorConditionItemsProps
> = ({ colorRanges, colorConditions, timezone }) => {
  const { t } = useTranslation();

  const formatTime = (time) => formatDateTime(time, timezone, CAPSULE_GROUP_DATE_FORMAT);

  return (
    !!(colorConditions.length || colorRanges.length) && (
      <span>
        <strong className="mr10">{t('SCATTER.LEGEND_COLOR')}:</strong>
        {_.chain(colorConditions)
          .uniqBy('id')
          .map((condition) => (
            <span
              className="mr10 text-nowrap specColorConditionLegend"
              key={condition.id}
              data-testid="color-condition-span">
              <Icon icon="fa-circle" type="color" color={condition.color} extraClassNames="pr5" />
              {condition.name}
            </span>
          ))
          .value()}
        {_.chain(colorRanges)
          .uniqBy('id')
          .map((colorRange) => (
            <span className="mr10 text-nowrap specColorRangeLegend" key={colorRange.id} data-testid="color-range-span">
              <Icon icon="fa-circle" type="color" color={colorRange.color} extraClassNames="pr5" />
              {formatTime(colorRange.start)} - {formatTime(colorRange.end)}
            </span>
          ))
          .value()}
      </span>
    )
  );
};
