// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { CELL_TYPES } from '@/hybrid/core/Table.atom';
import { refreshJobs as refreshJobsSqAdministrationAction, stopJobs } from '@/administration/administration.actions';
import { IconCell } from '@/hybrid/homescreen/CellRender.atom';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { AdminTableWrapper } from '@/hybrid/core/AdminTableWrapper.molecule';
import { JobDetailsModal } from '@/hybrid/administration/JobDetailsModal.molecule';
import { APP_STATE } from '@/main/app.constants';
import { decorateJob } from '@/hybrid/utilities/jobAdmin.utilities';

const jobsTabBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
});

export const JobsTab: SeeqComponent<typeof jobsTabBindings> = () => {
  const { $state } = useInjectedBindings(jobsTabBindings);

  const { t } = useTranslation();

  const [jobs, setJobs] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [jobForDetailsModal, setJobForDetailsModal] = useState(undefined);

  useEffect(() => {
    refreshJobs();
  }, []);

  const sourceRenderFunction = (job) => {
    if (job.isReportJobGroup || job.isScreenshotJobGroup) {
      const params = { workbookId: job.topicId, worksheetId: job.documentId };
      return (
        <a href={$state.href(APP_STATE.WORKSHEET, params)} target="_blank">
          {job.sourceName}
        </a>
      );
    } else if (job.isNotebookJobGroup) {
      return (
        <a href={job.scheduledNotebookUrl} target="_blank">
          {job.sourceName}
        </a>
      );
    } else {
      return <span>{job.sourceName}</span>;
    }
  };

  const columns = [
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, minWidth: 40, maxWidth: 40 },
    },
    {
      accessor: 'id',
      searchProperty: 'id',
      header: 'ADMIN.JOB.ID',
      sortable: true,
      filterable: true,
    },
    {
      accessor: 'group',
      searchProperty: 'group',
      header: 'ADMIN.JOB.GROUP',
      sortable: true,
      filterable: true,
    },
    {
      accessor: 'sourceName',
      searchProperty: 'sourceName',
      header: 'ADMIN.JOB.SOURCE',
      sortable: true,
      filterable: true,
      cellRenderFunction: sourceRenderFunction,
    },
    {
      accessor: 'duration',
      searchProperty: 'duration',
      header: 'ADMIN.JOB.RUN_TIME',
      sortable: true,
    },
    {
      accessor: 'previousRunTime',
      searchProperty: 'previousRunTime',
      header: 'ADMIN.JOB.PREVIOUS_RUN_TIME',
      sortable: true,
      filterable: false,
      cellType: CELL_TYPES.DATE_TIME,
    },
    {
      accessor: 'nextRunTime',
      searchProperty: 'nextRunTime',
      header: 'ADMIN.JOB.NEXT_RUN_TIME',
      sortable: true,
      filterable: false,
      cellType: CELL_TYPES.DATE_TIME,
    },
    {
      accessor: 'info',
      sortable: false,
      filterAble: false,
      cellStyle: { width: 40, minWidth: 40, maxWidth: 40 },
      cellRenderFunction: (job) => (
        <IconCell
          icon="fa-info-circle"
          onClick={() => setJobForDetailsModal(job)}
          type="theme"
          testId="jobDetailsIcon"
          large={true}
        />
      ),
    },
  ];

  const refreshJobs = () =>
    refreshJobsSqAdministrationAction()
      .then(({ data }) => {
        const formattedJobs = _.forEach(data.jobs, (job) => decorateJob(job));
        setJobs(formattedJobs);
      })
      .finally(() => setSelectedItems([]));

  const cancelJobs = () =>
    stopJobs(_.map(selectedItems, (item) => ({ id: item.id, group: item.group })))
      .then(refreshJobs)
      .finally(() => setSelectedItems([]));

  return (
    <div className="height-maximum">
      {jobForDetailsModal && (
        <JobDetailsModal job={jobForDetailsModal} onClose={() => setJobForDetailsModal(undefined)} />
      )}

      <div className="flexColumnContainer flexSpaceBetween flexAlignCenter mb5">
        <ButtonWithManagedSpinner
          buttonProps={{
            id: 'stopSelectedJob',
            disabled: _.isEmpty(selectedItems),
          }}
          spinnerIconProps={{ type: 'text', large: true }}
          label="ADMIN.JOB.STOP_SELECTED"
          action={cancelJobs}
        />

        <ButtonWithManagedSpinner
          buttonProps={{ id: 'refreshJobs' }}
          spinnerIconProps={{ type: 'text', large: true }}
          label="ADMIN.JOB.REFRESH_LIST"
          action={refreshJobs}
          icon="fa-repeat"
        />
      </div>

      <div className="overflowAuto width-maximum height-maximum pb70">
        <AdminTableWrapper
          testId="jobTable"
          defaultSort={{ property: 'group', asc: true }}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          items={jobs}
          columns={columns}
          noItemsComponent={<span>{t('ADMIN.JOB.NONE')}</span>}
        />
      </div>
    </div>
  );
};
