// @ts-strict-ignore
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';

import { ReportActions } from '@/reportEditor/report.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import React, { useEffect, useState } from 'react';
import { renameWorksheet, setWorkbookName } from '@/workbook/workbook.actions';
import { sqItemsApi } from '@/sdk';
import { APP_STATE } from '@/main/app.constants';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { successToast } from '@/hybrid/utilities/toast.utilities';
import { TEMP_TOPIC_NAME } from '@/reportEditor/report.constants';
import { sqReportStore, sqWorkbookStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';
import { HomeScreenUtilitiesService } from '../homescreen/homeScreen.utilities.service';

const sandboxModeBannerBindings = bindingsDefinition({
  sqReportActions: injected<ReportActions>(),
  $state: injected<ng.ui.IStateService>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
});

export const SandboxModeBanner: SeeqComponent<typeof sandboxModeBannerBindings> = () => {
  const { sqHomeScreenUtilities, sqReportActions, $state } = useInjectedBindings(sandboxModeBannerBindings);

  const { t } = useTranslation();
  const sandboxMode = useFluxPath(sqReportStore, () => sqReportStore.sandboxMode);

  const [originalWorksheetName, setOriginalWorksheetName] = useState(null);
  const [saveTooltip, setSaveTooltip] = useState(null);
  useEffect(() => {
    const getSaveTooltip = () => {
      return t('SANDBOX_MODE.SAVE_COPY_TOOLTIP', {
        workbook: buildNewWorkbookName(),
      });
    };

    const loadOldWorksheetName = () => {
      return sqItemsApi
        .getProperty({
          id: sandboxMode.originalWorksheetId,
          propertyName: 'Name',
        })
        .then((results) => setOriginalWorksheetName(results.data.value));
    };

    if (sandboxMode.enabled) {
      loadOldWorksheetName().then(() => setSaveTooltip(getSaveTooltip()));
    }
  });

  const buildNewWorkbookName = () => {
    const oldWorkbookName = sqWorkbookStore.name;
    return t('SANDBOX_MODE.NEW_WORKBOOK_NAME', { workbook: oldWorkbookName });
  };

  const buildNewWorksheetName = () => {
    return t('SANDBOX_MODE.NEW_WORKSHEET_NAME', {
      worksheet: originalWorksheetName,
    });
  };

  const onClickSave = () => {
    const newWorkbookName = buildNewWorkbookName();
    sqItemsApi
      .setProperty(
        { value: false },
        {
          id: sandboxMode.sandboxedWorkbookId,
          propertyName: SeeqNames.Properties.Archived,
        },
      )
      .then(() =>
        setWorkbookName(sandboxMode.sandboxedWorkbookId, newWorkbookName, TEMP_TOPIC_NAME, sqHomeScreenUtilities),
      )
      .then(() =>
        $state
          .go(APP_STATE.WORKSHEET, {
            workbookId: sandboxMode.sandboxedWorkbookId,
            worksheetId: sandboxMode.sandboxedWorksheetId,
          })
          .then(() => renameWorksheet(sandboxMode.sandboxedWorksheetId, buildNewWorksheetName()))
          .then(() => {
            successToast({
              messageKey: 'SANDBOX_MODE.SUCCESS_MESSAGE',
              messageParams: { newWorkbook: newWorkbookName },
            });
          }),
      );
    doTrack('Sandbox Mode', 'Save a Copy');
  };

  return (
    <>
      {sandboxMode.enabled && (
        <div className="flexColumnContainer flexCenter">
          <span className="p3">
            <Icon
              icon="fc-eraser"
              tooltip="SANDBOX_MODE.REVERT_TOOLTIP"
              onClick={sqReportActions.exitSandboxMode}
              type="theme-light"
              large={true}
              testId="sandboxExit"
            />
          </span>
          <span className="p3">
            <Icon
              icon="fa-copy"
              tooltip={saveTooltip}
              tooltipPlacement="bottom"
              onClick={onClickSave}
              type="theme-light"
              large={true}
              testId="sandboxSave"
            />
          </span>
        </div>
      )}
    </>
  );
};
