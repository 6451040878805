// @ts-strict-ignore
import { TableColumn } from '@/hybrid/core/Table.atom';
import { formatNumber, isNumeric } from '@/hybrid/utilities/numberHelper.utilities';
import _ from 'lodash';

type ModelTableItemData = {
  columns: TableColumn[];
  items: { id: string; [key: string]: any }[];
};

export type ModelTableData = {
  legend: ModelTableItemData;
  coefficients: ModelTableItemData;
  stats: ModelTableItemData;
};

/**
 * Helper function that returns CSV formatted coefficient data as the clipboard text.
 */
export const getCoefficientClipboardText = (model, translate) => {
  if (model.coefficients.length === 0) {
    return '';
  }

  // Add coefficients
  const { name, coefficient, standardError, pValue } = translate;
  let csvTable = `${[name, coefficient, standardError, pValue].join('\t')}\n`;
  _.forEach(model.coefficients, ({ name, coefficient, error, pValue }) => {
    csvTable += `${[name, coefficient, error, pValue].join('\t')}\n`;
  });

  // Add legend
  csvTable += `\n${translate.legend}\n`;
  _.forEach(model.legend, (entry: any) => {
    csvTable += `${entry.variable} = ${entry.name}\n`;
  });

  return csvTable;
};

/**
 * Helper function that returns CSV formatted statistics data as the clipboard text.
 */
export const getStatisticsClipboardText = (model, translate) => {
  if (model.stats.length === 0) {
    return '';
  }

  const { name, value } = translate;
  let statsCsvTable = `${[name, value].join('\t')}\n`;
  _.forEach(model.stats, ({ name, value }) => {
    statsCsvTable += `${[name, value].join('\t')}\n`;
  });

  return statsCsvTable;
};

const getColumnProps = ({ accessor, header }: Pick<TableColumn, 'accessor' | 'header'>): TableColumn => ({
  accessor,
  header,
  sortable: true,
  cellStyle: {
    maxWidth: '70px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const getModelTableData = (model): ModelTableData => ({
  legend: {
    columns: [
      getColumnProps({ accessor: 'variable', header: 'PREDICTION.VARIABLE' }),
      getColumnProps({ accessor: 'name', header: 'PREDICTION.NAME' }),
    ],
    items: _.map(model.legend, (entry, index) => ({
      ...entry,
      id: index,
    })),
  },
  coefficients: {
    columns: [
      getColumnProps({ accessor: 'name', header: 'PREDICTION.NAME' }),
      getColumnProps({ accessor: 'coefficient', header: 'PREDICTION.COEFFICIENT' }),
      getColumnProps({ accessor: 'error', header: 'PREDICTION.STANDARD_ERROR' }),
      getColumnProps({ accessor: 'pValue', header: 'PREDICTION.PVALUE' }),
    ],
    items: _.map(model.coefficients, (entry, index) => ({
      ...entry,
      id: index,
      coefficient: formatNumber(entry.coefficient),
      error: formatNumber(entry.error),
      pValue: formatNumber(entry.pValue),
    })),
  },
  stats: {
    columns: [
      getColumnProps({ accessor: 'name', header: 'PREDICTION.NAME' }),
      getColumnProps({ accessor: 'value', header: 'PREDICTION.VALUE' }),
    ],
    items: _.map(model.stats, (entry, index) => ({
      ...entry,
      id: index,
      value: isNumeric(entry.value) ? formatNumber(entry.value) : entry.value,
    })),
  },
});

export const openTooltipLink = () =>
  window.open('https://telemetry.seeq.com/support-link/wiki/spaces/KB/pages/143163422/Prediction');

export const generateDefaultSortValues = (defaultValue: any) => ({
  legend: defaultValue,
  coefficients: defaultValue,
  stats: defaultValue,
});
