// @ts-strict-ignore
import { flux } from '@/core/flux.module';
import { DisplayOutputV1, sqDisplaysApi, sqDisplayTemplatesApi, sqTreesApi } from '@/sdk';
import { getCurrentWorkstepId } from '@/hybrid/worksteps/worksteps.utilities';

export function setAsset(asset) {
  flux.dispatch('DISPLAY_TOOL_ASSET', { asset });
}

export function setIsScaled(isScaled) {
  flux.dispatch('DISPLAY_TOOL_IS_SCALED', { isScaled });
}

/**
 * Execute action for the display tool. Returns a promise containing the created display.
 *
 * @param workbookId - Current workbook
 * @param worksheetId - Current worksheet
 * @param name - Name of display to be created
 * @param asset - Asset where display should be inserted
 * @param isScaled - Whether the display should be scaled with the scaleTreeItem() endpoint
 */
export function executeDisplay(
  workbookId: string,
  worksheetId: string,
  name: string,
  asset: any,
  isScaled: boolean,
): Promise<DisplayOutputV1> {
  const displayPromise = getCurrentWorkstepId(workbookId, worksheetId)
    .then((workstepId) =>
      sqDisplayTemplatesApi.createDisplayTemplate({
        name,
        sourceWorkstepId: workstepId,
        scopedTo: workbookId,
      }),
    )
    .then(({ data: displayTemplate }) =>
      sqDisplaysApi.createDisplay({
        templateId: displayTemplate.id,
      }),
    );
  const moveNodePromise = displayPromise.then(
    ({ data: display }) => sqTreesApi.moveNodesToParent({ items: [display.id] }, { parentId: asset.id }) as any,
  );

  if (isScaled) {
    const scalePromise = Promise.all([displayPromise, moveNodePromise]).then(([{ data: display }]) =>
      sqTreesApi.scaleAcrossTree({ id: display.id }),
    );
    return Promise.all([displayPromise, scalePromise])
      .then(([{ data: display }]) => sqDisplaysApi.getDisplay({ id: display.id }))
      .then(({ data: display }) => display);
  } else {
    return Promise.all([displayPromise, moveNodePromise]).then(([{ data: display }]) => display);
  }
}
