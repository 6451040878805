import angular from 'angular';
import { TREND_STORES, TrendStoreHelper } from './trendData.constants';
import { sqTrendActions } from '@/trendData/trend.actions';
import {
  sqTrendCapsuleSetStore,
  sqTrendCapsuleStore,
  sqTrendMetricStore,
  sqTrendScalarStore,
  sqTrendSeriesStore,
  sqTrendTableStore,
} from '@/core/core.stores';

const dependencies = [
  'Sq.Core',
  'Sq.Investigate',
  'Sq.AppConstants',
  'Sq.Annotation',
  'Sq.ScatterPlot',
  'Sq.Search',
  'Sq.Services.ToolRunner',
  'Sq.Services.Formula',
  'Sq.Workbook',
  'Sq.TableBuilder',
  'Sq.FormulaTool',
];

angular
  .module('Sq.TrendData', dependencies)
  // Currently set to a very high number so as not to limit data the user receives.
  // However, it is used by system tests to speed things up.
  .constant('MAX_SERIES_PIXELS', 5000)
  .service('sqTrendActions', sqTrendActions)
  // Stores that contain items that can be shown in the details pane
  .factory(
    'TREND_STORES',
    () =>
      [
        sqTrendSeriesStore,
        sqTrendScalarStore,
        sqTrendCapsuleStore,
        sqTrendCapsuleSetStore,
        sqTrendTableStore,
        sqTrendMetricStore,
      ] as typeof TREND_STORES,
  )
  .run(TrendStoreHelper);
