// @ts-strict-ignore
import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { bindingsDefinition, prop } from '../core/bindings.util';
import { angularComponent } from '../core/react2angular.util';
import { JournalMetadata } from '../annotation/JournalMetadata.atom';
import { formatTime as formatDateTime, parseISODate } from '@/hybrid/datetime/dateTime.utilities';
import { useTranslation } from 'react-i18next';
import { CancelAndSave } from '@/hybrid/core/CancelAndSave.molecule';
import { EllipsisDropdownMenu } from '@/hybrid/core/EllipsisDropdownMenu.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { doTrack } from '@/track/track.service';
import { sqWorksheetStore } from '@/core/core.stores';
import { hasCurrentUserId, isAdmin } from '@/services/authorization.service';

export interface JournalCommentData {
  createdBy: {
    id: string;
    isArchived: Boolean;
    isRedacted: Boolean;
    name: string;
    type: string;
  };
  createdAt: string;
  name: string;
  id: string;
}

const journalCommentBindings = bindingsDefinition({
  comment: prop<JournalCommentData>(),
  isPresentationMode: prop<boolean>(),
  updateComment: prop<(id: string, name: string) => void>(),
  deleteComment: prop<(id: string) => void>(),
  canManage: prop<(commentData: object) => boolean>(),
});

export const JournalComment: SeeqComponent<typeof journalCommentBindings> = (props) => {
  const { comment, isPresentationMode, updateComment, deleteComment, canManage } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [mutableName, setMutableName] = useState('');
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const { t } = useTranslation();

  const isCreator = (comment) => hasCurrentUserId(comment, 'createdBy.id');
  const formatTime = (date: number | moment.Moment): string => formatDateTime(date, sqWorksheetStore.timezone);
  const canEdit = (comment: object): boolean => isAdmin() || isCreator(comment);

  const handleSaveComment = (id: string, name: string) => {
    setIsEditing(false);
    doTrack('Topic', 'Comments', 'save');
    updateComment(id, name);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    doTrack('Topic', 'Comments', 'cancel');
  };

  const menuClass = canManage(comment) ? 'sq-text-primary' : 'sq-fairly-dark-gray-always';

  const manageMenuItems = [
    {
      classes: classNames({ cursorNotAllowed: !canEdit(comment) }),
      onClick: () => {
        if (canEdit(comment)) {
          setMutableName(comment.name);
          setIsEditing(true);
          setIsConfirmingDelete(false);
          doTrack('Topic', 'Comments', 'edit');
        }
      },
      icon: <Icon testId="specCommentEditMenuBtn" icon="fa-fw fa-pencil" type="inherit" />,
      label: 'EDIT',
      testid: 'commentEdit',
    },
    {
      classes: classNames({ cursorNotAllowed: !canEdit(comment) }),
      onClick: () => {
        if (canEdit(comment)) {
          setIsConfirmingDelete(true);
          setMutableName('');
          setIsEditing(false);
        }
      },
      icon: <Icon testId="specCommentDeleteBtn" icon="fa-fw fa-remove" type="inherit" />,
      label: 'DELETE',
      testid: 'commentDelete',
    },
  ];

  return (
    <div className="flexRowContainer card annotationCard card-default mb10 pl5 pr5" data-testid="specJournalComment">
      <div className="card-body pt6 pb0">
        <div className="flexColumnContainer flexSpaceBetween flexAlignCenter">
          <JournalMetadata name={comment.createdBy.name} date={formatTime(parseISODate(comment.createdAt))} />
          {!isPresentationMode && (
            <div className="mt2">
              <EllipsisDropdownMenu itemsList={manageMenuItems} iconExtraClass={menuClass} />
            </div>
          )}
        </div>
      </div>
      {!isEditing && <div className="card-body transitionNone breakLongWords pt0">{comment.name}</div>}
      {isConfirmingDelete && !isPresentationMode && (
        <div className="text-center pb10">
          <hr className="mt10 mb10" />
          <TextButton
            id="specConfirmDeleteBtn"
            testId="specConfirmDeleteBtn"
            variant="danger"
            label="DELETE"
            onClick={() => {
              setMutableName('');
              deleteComment(comment.id);
            }}
          />
          <span
            data-testid="specCancelDeleteBtn"
            className="btn btn-sm btn-link"
            onClick={(event) => {
              setIsConfirmingDelete(false);
              setMutableName('');
              event.stopPropagation();
              event.preventDefault();
            }}>
            {t('CANCEL')}
          </span>
        </div>
      )}
      {isEditing && !isPresentationMode && (
        <div className="commentNameEdit">
          <Form.Group data-testid="specCommentEditor" controlId="specCommentEditor" className="mb0 card-body">
            <Form.Control
              as="textarea"
              rows={3}
              className="form-control textAreaResizeNone"
              value={mutableName}
              onChange={(event) => setMutableName(event.currentTarget.value)}
            />
          </Form.Group>
          <div className="text-right">
            <div className="pt5 pr0 pb5 text-right">
              <CancelAndSave
                submitFn={() => Promise.resolve().then(() => handleSaveComment(comment.id, mutableName))}
                cancelFn={handleCancelEdit}
                values={[]}
                btnDisabled={!mutableName.length}
                cancelClassNames="btn-xs mr5 min-width-50 sq-btn-xs"
                submitClassNames="btn-xs min-width-50 sq-btn-xs"
                cancelBtnTestId="specCancelCommentBtn"
                submitBtnTestId="specSaveCommentBtn"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const sqJournalComment = angularComponent(journalCommentBindings, JournalComment);
