// @ts-strict-ignore
import { ITEM_CUSTOMIZATIONS, ITEM_TYPES } from '@/trendData/trendData.constants';
import { isStringSeries } from '@/hybrid/utilities/utilities';
import _ from 'lodash';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { sqWorksheetStore } from '@/core/core.stores';

/**
 * This function was created here to ease function mocking
 *
 * Determine if a customization is enabled for an item. A control is enabled if it could be possible for a certain
 * type of item to be used
 */
export function customizationEnabled(item, property: string) {
  const specialExclusions = [];
  if (item.itemType === ITEM_TYPES.SERIES && (item.isStringSeries || item.isCustomizationHidden)) {
    specialExclusions.push('axisAutoScale', 'yAxisMin', 'yAxisMax', 'yAxisType');
  }

  if (item.calculationType === TREND_TOOLS.FFT_TABLE) {
    specialExclusions.push('stack');
  }

  if (sqWorksheetStore.view.key === WORKSHEET_VIEW.SCATTER_PLOT) {
    specialExclusions.push('lane');
    if (isStringSeries(item) || item.itemType === ITEM_TYPES.METRIC) {
      specialExclusions.push('axisAlign');
    }
  }

  return !_.includes(specialExclusions, property) && _.includes(ITEM_CUSTOMIZATIONS[item.itemType], property);
}
