// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ExploreFolderModalActions } from '@/hybrid/explorer/exploreFolderModal.actions';
import { ExplorerModalBody } from '@/hybrid/explorer/ExplorerModalBody.molecule';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { CancelAndSave } from '@/hybrid/core/CancelAndSave.molecule';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqExploreFolderModalStore, sqHomeScreenStore } from '@/core/core.stores';

/** The modal that is rendered in the home screen advanced search if a tab has subfolders (My Folder, Shared, Users, Corporate) **/

const advancedSearchSubFolderModalBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  advancedSearchSelectedFolder: prop<{ id: string }>(),
  setAdvancedSearchSelectedFolder: prop<(object) => void>(),
  currentTabName: prop<string>(),
  isCurrentFolderNested: prop<boolean>(),
  breadcrumbsPresent: prop<boolean>(),
  breadcrumbClicked: prop<boolean>(),
  itemLocationFilter: prop<{ text: string; value: string; label: string }>(),
  locationChangedInAdvanced: prop<boolean>(),
  initialFolderId: prop<string>(),
  myFolderContent: prop<string>(),
  corporateFolderId: prop<string>(),
  sqExploreFolderModalActions: injected<ExploreFolderModalActions>(),
});

export const AdvancedSearchSubFolderModal: SeeqComponent<typeof advancedSearchSubFolderModalBindings> = ({
  onClose,
  currentTabName,
  advancedSearchSelectedFolder,
  setAdvancedSearchSelectedFolder,
  isCurrentFolderNested,
  breadcrumbsPresent,
  breadcrumbClicked,
  locationChangedInAdvanced,
  itemLocationFilter,
  initialFolderId,
  myFolderContent,
  corporateFolderId,
}) => {
  const { sqExploreFolderModalActions } = useInjectedBindings(advancedSearchSubFolderModalBindings);

  const { t } = useTranslation();

  const [folderUponInitialRender, setFolderUponInitialRender] = useState(undefined);

  const folder = useFluxPath(sqExploreFolderModalStore, () => sqExploreFolderModalStore.folder);
  const disableSelect =
    initialFolderId === folder?.id ||
    (!initialFolderId &&
      !folder?.id &&
      (folderUponInitialRender?.id === null || folderUponInitialRender?.ancestors?.length === 0)) ||
    (folderUponInitialRender?.id === folder?.id && itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC);

  useEffect(() => {
    sqExploreFolderModalActions.setInitialFolderId(undefined);
    sqExploreFolderModalActions.getInitialFolder({
      currentTab: currentTabName,
      item: advancedSearchSelectedFolder,
      forceCorporate: false,
      insideAdvancedSearch: true,
      isCurrentFolderNested,
      breadcrumbsPresent,
      breadcrumbClicked,
      locationChangedInAdvanced,
      currentFolderId: sqHomeScreenStore.currentFolderId,
      locationValue: itemLocationFilter.value,
    });
    setFolderUponInitialRender(advancedSearchSelectedFolder);
  }, []);

  const selectSubFolder = () => {
    setAdvancedSearchSelectedFolder(sqExploreFolderModalStore.folder);
    onClose();
  };

  const renderButtons = (
    <div className="flexColumnContainer mr10 mb10 flexCenter">
      <CancelAndSave
        submitFn={() => Promise.resolve().then(selectSubFolder)}
        submitBtnLabel="HOME_SCREEN.SELECT"
        cancelFn={onClose}
        values={[]}
        btnDisabled={disableSelect}
        cancelClassNames="mr20"
        submitClassNames={classNames('btn', {
          'disabledLook disabledBehavior': disableSelect,
        })}
        cancelBtnTestId="cancelSelectFolderBtn"
        submitBtnTestId="selectFolderBtn"
      />
    </div>
  );

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="advancedSubFolderModal">
      <Modal.Header closeButton={true}>
        <h4 className="modal-title flexColumnContainer flexAlignCenter">
          <span data-testid="advancedSubFolderTitle">
            <span>{t(`HOME_SCREEN.LOCATION.${currentTabName}`)}</span>
          </span>
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className=" max-height-475 overflowAuto">
          <ExplorerModalBody
            show={true}
            store={sqExploreFolderModalStore}
            actions={sqExploreFolderModalActions}
            allowedTypes={[SeeqNames.Types.Folder]}
            insideAdvancedSearch={true}
            initialFolderId={initialFolderId}
            itemLocationFilter={itemLocationFilter}
            breadcrumbsPresent={breadcrumbsPresent}
            advancedSearchSelectedFolder={advancedSearchSelectedFolder}
            myFolderContent={myFolderContent}
            corporateFolderId={corporateFolderId}
          />
        </div>
        {(initialFolderId ||
          (itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC && !initialFolderId)) &&
          renderButtons}
      </Modal.Body>
    </Modal>
  );
};
