export const AGGREGATION_MODES = {
  Y_VALUE: 'by_y_value',
  TIME: 'by_time',
  CONDITION: 'by_condition',
};

export const Y_VALUE_BIN_MODES = { SIZE: 'size', NUMBER: 'number' };

export const TIME_BUCKETS = [
  {
    key: 'Hour',
    display: 'BY_HOUR',
    funct: 'hours()',
  },
  {
    key: 'Day Of Week',
    display: 'BY_DAY_OF_WEEK',
    funct: 'days()',
  },
  {
    key: 'Day Of Month',
    display: 'BY_DAY_OF_MONTH',
    funct: 'days()',
  },
  {
    key: 'Day Of Year',
    display: 'BY_DAY_OF_YEAR',
    funct: 'days()',
  },
  {
    key: 'Week',
    display: 'BY_WEEK',
    funct: 'weeks(Day.Monday)',
  },
  {
    key: 'Month',
    display: 'BY_MONTH',
    funct: 'months()',
  },
  {
    key: 'Quarter',
    display: 'BY_QUARTER',
    funct: 'quarters()',
  },
  {
    key: 'Year',
    display: 'BY_YEAR',
    funct: 'years()',
  },
];

export const BIN_ERRORS = {
  MIN_EXCEEDS_MAX: 'MIN_EXCEEDS_MAX',
  INT_REQUIRED: 'INT_REQUIRED',
  MIN_NOT_A_NUMBER: 'MIN_NOT_A_NUMBER',
  MAX_NOT_A_NUMBER: 'MAX_NOT_A_NUMBER',
  INVALID_BIN_SIZE: 'INVALID_BIN_SIZE',
};

export const CAPSULE_MODES = [
  {
    key: 'intersect',
    formula: 'CapsuleBoundary.Intersect',
    display: 'AGGREGATIONS.CAPSULE_MODE.INTERSECT',
    image: 'capsuleModeIntersect.png',
    boundedOnly: false,
  },
  {
    key: 'enclosedBy',
    formula: 'CapsuleBoundary.EnclosedBy',
    display: 'AGGREGATIONS.CAPSULE_MODE.ENCLOSED_BY',
    image: 'capsuleModeEnclosedBy.png',
    boundedOnly: false,
  },
  {
    key: 'overlap',
    formula: 'CapsuleBoundary.Overlap',
    display: 'AGGREGATIONS.CAPSULE_MODE.OVERLAP',
    image: 'capsuleModeOverlap.png',
    boundedOnly: true,
  },
  {
    key: 'start',
    formula: 'CapsuleBoundary.StartsIn',
    display: 'AGGREGATIONS.CAPSULE_MODE.STARTS_IN',
    image: 'capsuleModeStart.png',
    boundedOnly: true,
  },
  {
    key: 'end',
    formula: 'CapsuleBoundary.EndsIn',
    display: 'AGGREGATIONS.CAPSULE_MODE.ENDS_IN',
    image: 'capsuleModeEnd.png',
    boundedOnly: true,
  },
];
