// @ts-strict-ignore
import _ from 'lodash';
import React from 'react';
import moment from 'moment-timezone';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { ValueWithUnits } from '@/hybrid/trend/ValueWithUnits.atom';
import { DURATION_TIME_UNITS_ALL } from '@/main/app.constants';
import { getValueAndUnits } from '@/hybrid/datetime/dateTime.utilities';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { DurationActions } from '@/trendData/duration.actions';
import { updateDisplayRange } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/ReportDateModal.utilities';

const reportDateModalAutoUpdateBindings = bindingsDefinition({
  sqDurationActions: injected<DurationActions>(),
  hasMultipleSchedules: prop<boolean>(),
  dateRangeRate: prop<any>(),
  newDateRange: prop<any>(),
  displayRange: prop<any>(),
  setNewDateRange: prop<(partialDateRange) => void>(),
});

export const ReportDateModalAutoUpdate: SeeqComponent<typeof reportDateModalAutoUpdateBindings> = ({
  hasMultipleSchedules,
  dateRangeRate,
  newDateRange,
  displayRange,
  setNewDateRange,
}) => {
  const { sqDurationActions } = useInjectedBindings(reportDateModalAutoUpdateBindings);
  const { t } = useTranslation();
  const defaultValueAndUnit = getValueAndUnits(moment.duration(displayRange.end.diff(displayRange.start)));

  const updateAutoUpdateDuration = ({ propName, ...duration }) => {
    const unit = _.find(DURATION_TIME_UNITS_ALL, ({ unit }) => _.includes(unit, duration.units));
    if (duration.value && unit) {
      setNewDateRange(
        updateDisplayRange(
          {
            ...newDateRange,
            auto: {
              ...newDateRange.auto,
              duration: moment.duration(duration.value, unit.momentUnit).as('ms'),
            },
          },
          sqDurationActions,
        ),
      );
    }
  };

  const onValueChange = ({ value }) => {
    setNewDateRange({
      auto: {
        cronSchedule: value,
      },
    });
  };

  return (
    <div data-testid="specReportDateModalAutoUpdate">
      <div className="flexRowContainer">
        <div className="mb10">{t('REPORT.MODAL.DATE_RANGE.AUTO_UPDATE_CONFIGURED')}</div>

        {/**<!-- Duration --> */}
        <div className="flexColumnContainer flexAlignStart">
          <label htmlFor="autoUpdateDateRangeDuration">{t('DURATION')}</label>
          <div className="small text-italic sq-darkish-gray ml5">{t('REPORT.CONFIG.DURATION_DESCRIPTION')}</div>
        </div>

        <div className="flexRowContainer flexAlignStart">
          <ValueWithUnits
            propName="autoUpdateDateRangeDuration"
            onChange={updateAutoUpdateDuration}
            insideModal={true}
            min={0}
            availableUnits={DURATION_TIME_UNITS_ALL}
            required={true}
            defaultValue={defaultValueAndUnit}
          />
        </div>

        {/**<!-- Rate --> */}
        {hasMultipleSchedules && (
          <div className="flexRowContainer flexAlignStart">
            <div className="flexColumnContainer flexAlignStart">
              <label className="mb0">{t('RATE')}</label>
              <div className="small text-italic sq-darkish-gray ml5">{t('REPORT.CONFIG.RATE_DESCRIPTION')}</div>
            </div>
            {dateRangeRate ? (
              <ValueWithUnits
                extraClassNames="width-130 input-number-no-spin flexNoGrowNoShrink width-150 mb10"
                availableUnits={DURATION_TIME_UNITS_ALL}
                disabled={true}
                insideModal={true}
                propName="specRateValueWithUnits"
                min={0}
                defaultValue={newDateRange.auto.cronSchedule}
                onChange={onValueChange}
              />
            ) : (
              <div className="flexAlignStart" data-testid="specRateInput">
                <Form.Group>
                  <Form.Control
                    as="input"
                    disabled={true}
                    className="form-control height-32 p5"
                    defaultValue={newDateRange?.auto?.cronSchedule}
                  />
                </Form.Group>
              </div>
            )}
            <p>{t('REPORT.CONFIG.SCHEDULE_MULTIPLE_DEPRECATED')}</p>
          </div>
        )}
      </div>
    </div>
  );
};
