// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';

interface ScatterPlotLegendFxLinesProps {
  fxLines: any[];
}

export const ScatterPlotLegendFxLinesItems: React.FunctionComponent<ScatterPlotLegendFxLinesProps> = (props) => {
  const { fxLines } = props;

  return (
    !!fxLines.length && (
      <>
        <strong className="mr10 specFxLineLegend">f(x):</strong>
        {_.chain(fxLines)
          .uniqBy('id')
          .map((fxLine) => (
            <span className="mr10 text-nowrap" key={fxLine.id}>
              <Icon icon="fc-series" type="color" color={fxLine.color} extraClassNames="pr5 fa fc" />
              <span className="specFxLineLegend">{fxLine.name}</span>
              {fxLine?.metadata?.rSquared && (
                <span className="pl5">
                  (r<sup>2</sup> = {fxLine.metadata.rSquared.toFixed(3)})
                </span>
              )}
            </span>
          ))
          .value()}
      </>
    )
  );
};
