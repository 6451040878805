import { isTelemetryEnabled } from '@/services/systemConfiguration.utilities';
import { TrackInformation } from '@/track/track.types';

export abstract class TrackerService {
  protected constructor() {}

  abstract trackEvent(
    category: string,
    action: string,
    information: TrackInformation,
    uniqueEventId: number,
    language?: string,
  ): void;

  shouldTrack() {
    return isTelemetryEnabled();
  }
}
