import React from 'react';
import classNames from 'classnames';
import { Icon, IconProps } from '@/hybrid/core/Icon.atom';
import { HoverTooltip, HoverTooltipProps } from '@/hybrid/core/HoverTooltip.atom';

/**
 * A component to encapsulate the necessary logic for a pair of icons - one that shows by default and one that shows
 * only on hover.
 */
export interface HoverIconPairProps {
  iconProps: IconProps;
  hoverIconProps: IconProps;
  tooltipProps: HoverTooltipProps;
}

export const HoverIconPair: React.FunctionComponent<HoverIconPairProps> = ({
  iconProps,
  hoverIconProps,
  tooltipProps,
}) => (
  <HoverTooltip {...tooltipProps}>
    <span>
      <Icon {...iconProps} extraClassNames={classNames(iconProps.extraClassNames, 'fa fa-hover-hidden')} />
      <Icon
        {...hoverIconProps}
        extraClassNames={classNames(hoverIconProps.extraClassNames, 'fa fa-hover-show', 'btn-transparent')}
      />
    </span>
  </HoverTooltip>
);
