import { useContext, useEffect, useRef } from 'react';
import { useForceUpdate } from '@/hybrid/core/hooks/useForceUpdate.hook';
import { FluxContext } from '@/hybrid/core/flux.context';
import _ from 'lodash';

function getState<T>(store: T, paths: (keyof T)[]) {
  return _.map(paths, (path) => [path, _.get(store, path)]);
}

/**
 * Similar to useFluxPath. Takes in multiple paths
 */
export function useFluxPaths<T>(store: T, paths: (keyof T)[]): T {
  const state = useRef(getState(store, paths));
  const flux = useContext(FluxContext);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    flux.listenTo(
      store,
      undefined,
      () => {
        // Object.is is the method react uses to bail out of state updates for useState and useReducer
        // https://reactjs.org/docs/hooks-reference.html#bailing-out-of-a-state-update
        for (let i = 0; i < state.current.length; i++) {
          const [path, value] = state.current[i];
          if (!Object.is(value, _.get(store, path))) {
            forceUpdate();
            state.current = getState(store, paths);

            return;
          }
        }
      },
      false,
    );
  }, [flux, store]);

  return store;
}
