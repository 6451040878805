// @ts-strict-ignore
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { ToggleSwitch } from '@/hybrid/core/ToggleSwitch.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AgentStatusOutputV1, ConnectionStatusOutputV1 } from '@/sdk';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { formatTime as formatDateTime, parseISODate } from '@/hybrid/datetime/dateTime.utilities';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ConnectionStatus, DatasourcesService } from '@/hybrid/administration/datasources/datasources.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { setActiveTabIndex, setSearchParams } from '@/administration/administration.actions';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { AGENTS_TAB_INDEX } from '@/administration/administration.constants';
import { sqWorksheetStore } from '@/core/core.stores';

const connectionCardBindings = bindingsDefinition({
  connection: prop<ConnectionStatusOutputV1>(),
  onConnectionManage: prop<(connection: ConnectionStatusOutputV1) => Promise<any>>(),
  agents: prop<AgentStatusOutputV1[]>(),
  sqDatasourcesService: injected<DatasourcesService>(),
});

export const ConnectionCard: SeeqComponent<typeof connectionCardBindings> = (props) => {
  const { connection, onConnectionManage, agents } = props;

  const { sqDatasourcesService } = useInjectedBindings(connectionCardBindings);

  const { t } = useTranslation();
  const { timezone } = useFlux(sqWorksheetStore);

  const connectionStatusIconMap = new Map([
    [ConnectionStatus.Unknown, 'fa-exclamation-triangle sq-status-error width-17'],
    [ConnectionStatus.Disconnected, 'fa-exclamation-triangle sq-status-error width-17'],
    [ConnectionStatus.Connecting, 'fa-circle-o-notch fa-spin sq-status-progress width-17'],
    [ConnectionStatus.Connected, 'fa-check-circle sq-status-good width-17'],
    [ConnectionStatus.Indexing, 'fa-refresh fa-spin sq-status-progress width-17'],
    [ConnectionStatus.Disabled, 'fa-minus-circle disabledLook width-17'],
  ]);

  const formatTime = (input: string): string => {
    const timeFormat = 'LL LTS'; // Example: June 1, 2020 8:00:00 AM
    return input != null
      ? formatDateTime(parseISODate(input), { name: timezone.name }, timeFormat)
      : t('ADMIN.DATASOURCES.NO_LAST_SUCCESSFUL_DATE');
  };

  const viewLogs = (connection: ConnectionStatusOutputV1) => {
    const logUrl = sqDatasourcesService.computeLogUrl(connection.agentName, agents, connection);
    window.open(logUrl, '_blank');
    return Promise.resolve();
  };

  const viewAgent = (agentName) => {
    setActiveTabIndex(AGENTS_TAB_INDEX);
    setSearchParams({ field: 'name', value: agentName });
    return Promise.resolve();
  };

  return (
    <Card key={connection.connectionId} className="mt10 datasourceConnectionCard">
      <Card.Header className="datasourceConnectionCard">
        <div className="flexColumnContainer">
          <div className="pt2 mr5">
            <Icon
              testId={`conStatusIcon_${connection.connectionId}`}
              icon={connectionStatusIconMap.get(sqDatasourcesService.getConnectionStatus(connection))}
              large={true}
            />
          </div>

          <div className="flexFill flexRowContainer">
            <div className="flexColumnContainer">
              <div className="font-size-larger font-weight-lighter">
                {t('ADMIN.DATASOURCES.CONNECTION_SERVED_BY')}
                <ButtonWithManagedSpinner
                  action={() => viewAgent(connection.agentName)}
                  buttonProps={{
                    extraClassNames: 'p0 mb5 ml5 link-underline-hover fs16',
                    type: 'link',
                    variant: 'no-border',
                    testId: `viewAgents_${connection.connectionId}`,
                  }}
                  label={connection.agentName}
                />
              </div>
              <div className="flexFill" />
              <HoverTooltip
                text={
                  connection.datasourceManaged
                    ? t('ADMIN.DATASOURCES.CONNECTION_ENABLE_DATASOURCE_MANAGED_TOOLTIP', {
                        agentName: connection.agentName,
                      })
                    : ''
                }>
                <div>
                  {/* Above div is added to be able to show the tooltip when button is disabled */}
                  <ToggleSwitch
                    testId={`connEnable_${connection.connectionId}`}
                    isOn={connection.status !== SeeqNames.Connectors.Connections.Status.Disabled}
                    onChange={() =>
                      !connection.datasourceManaged && sqDatasourcesService.toggleConnectionEnable(connection)
                    }
                    label="ADMIN.DATASOURCES.ENABLED"
                    disabled={connection.datasourceManaged}
                  />
                </div>
              </HoverTooltip>
            </div>
            <div className="flexFill flexColumnContainer">
              <div className="flexFill flexRowContainer">
                {connection.status === SeeqNames.Connectors.Connections.Status.Disconnected && (
                  <>
                    <div className="flewRowContainer">
                      <div className="flexColumnContainer truncatedLabel min-width-0">
                        <div className="truncatedLabel" title={connection.connectionMessage}>
                          {t('ADMIN.DATASOURCES.DISCONNECT_ERROR')} {connection.connectionMessage}
                        </div>
                      </div>
                      <div>
                        {t('ADMIN.DATASOURCES.LAST_SUCCESSFUL_CONNECTION', {
                          date: formatTime(connection.lastSuccessfulConnectedAt),
                        })}
                      </div>
                    </div>
                  </>
                )}
                {connection.status === SeeqNames.Connectors.Connections.Status.Disabled && (
                  <>
                    <div>
                      {t('ADMIN.DATASOURCES.DISABLED_BY_ON', {
                        disabledBy: connection.disabledBy,
                        disabledAt: formatTime(connection.disabledAt),
                      })}
                    </div>
                  </>
                )}
              </div>

              <ButtonWithManagedSpinner
                action={() => viewLogs(connection)}
                buttonProps={{
                  extraClassNames: 'min-width-100 p0 link-no-underline',
                  type: 'link',
                  variant: 'no-border',
                  testId: `viewLogs_${connection.connectionId}`,
                }}
                icon="fa-file-text-o fa-fw mt3"
                label="ADMIN.DATASOURCES.VIEW_LOGS"
              />
              <HoverTooltip
                text={
                  connection.datasourceManaged
                    ? t('ADMIN.DATASOURCES.CONNECTION_MANAGE_DATASOURCE_MANAGED_TOOLTIP', {
                        agentName: connection.agentName,
                      })
                    : ''
                }>
                <div>
                  {/* Above div is added to be able to show the tooltip when button is disabled */}
                  <ButtonWithManagedSpinner
                    buttonProps={{
                      extraClassNames: 'min-width-70 p0 link-no-underline',
                      type: 'link',
                      variant: 'no-border',
                      testId: `connManage_${connection.connectionId}`,
                      disabled: connection.datasourceManaged,
                    }}
                    action={() => onConnectionManage(connection)}
                    icon="fa-cog fa-fw"
                    label="ADMIN.DATASOURCES.MANAGE"
                  />
                </div>
              </HoverTooltip>
            </div>
          </div>
        </div>
      </Card.Header>
    </Card>
  );
};
