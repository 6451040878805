// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { TrendActions } from '@/trendData/trend.actions';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ItemPropertiesSelector } from '@/hybrid/utilities/ItemPropertiesSelector.molecule';
import { SuggestedPropertiesMode } from '@/hybrid/utilities/CustomPropertySelector.atom';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { getCapsuleColumnTitle } from '@/hybrid/utilities/columnHelper.utilities';
import {
  CAPSULE_PANEL_LOOKUP_COLUMNS,
  CAPSULE_PANEL_TREND_COLUMNS,
  TREND_PANELS,
} from '@/trendData/trendData.constants';
import { doTrack } from '@/track/track.service';
import { sqTrendCapsuleSetStore, sqTrendStore } from '@/core/core.stores';

const trendLabelsPopoverBindings = bindingsDefinition({
  sqTrendActions: injected<TrendActions>(),
});

/**
 * A dropdown menu for selecting capsule properties for labeling chart capsules
 */
export const TrendCapsulePropertiesDropdown: SeeqComponent<typeof trendLabelsPopoverBindings> = () => {
  const { sqTrendActions } = useInjectedBindings(trendLabelsPopoverBindings);

  // respond to trendstore updates
  useFlux(sqTrendStore);
  const { items } = useFlux(sqTrendCapsuleSetStore);
  const propertyColumns = sqTrendStore.propertyColumns(TREND_PANELS.CHART_CAPSULES);

  const customColumns = _.map(sqTrendStore.customColumns(TREND_PANELS.CAPSULES), (property) => ({
    ...property,
    propertyName: getCapsuleColumnTitle(property),
  }));

  const toggleCapsuleColumnAndTrack = (column) => {
    doTrack('Trend', 'Toggle Capsule Label');
    const wasColumnEnabled = sqTrendStore.isColumnEnabled(TREND_PANELS.CHART_CAPSULES, column.key);
    sqTrendActions.toggleColumn(TREND_PANELS.CHART_CAPSULES, column.key);
    if (wasColumnEnabled) {
      sqTrendActions.removePropertiesColumn(TREND_PANELS.CHART_CAPSULES, column);
    }
  };

  const isCapsuleColumnEnabled = (column) => sqTrendStore.isColumnEnabled(TREND_PANELS.CHART_CAPSULES, column.key);
  const addPropertiesColumn = (column) =>
    sqTrendActions.addPropertiesColumn(
      TREND_PANELS.CHART_CAPSULES,
      _.find(customColumns, { propertyName: column.propertyName }) || column,
    );

  return (
    <>
      <ItemPropertiesSelector
        extraSelectorProperties={customColumns}
        propertyColumns={_.map(propertyColumns, (column: any) => ({
          ...column,
          title: column.propertyName,
          shortTitle: column.propertyName,
        }))
          .concat(CAPSULE_PANEL_TREND_COLUMNS)
          .concat(_.find(CAPSULE_PANEL_LOOKUP_COLUMNS, { key: 'asset' }))}
        itemIds={_.map(items, 'id')}
        suggestedPropertiesMode={SuggestedPropertiesMode.Capsules}
        isColumnEnabled={isCapsuleColumnEnabled}
        toggleColumn={toggleCapsuleColumnAndTrack}
        addPropertyColumn={addPropertiesColumn}
        maxHeightPropertyColumns={130}
      />
    </>
  );
};
