// @ts-strict-ignore
/* istanbul ignore file */
import _ from 'lodash';
import Plugin from 'ckeditor5/packages/ckeditor5-core/src/plugin';
import {
  BasePluginDependencies,
  ContentCallbacks,
  CustomPlugin,
} from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { PluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/plugins/PluginDependencies';
import ButtonView from 'ckeditor5/packages/ckeditor5-ui/src/button/buttonview';
import Command from 'ckeditor5/packages/ckeditor5-core/src/command';
import { Content } from '@/hybrid/annotation/ckEditorPlugins/plugins/content/Content';
import icon from '@/hybrid/annotation/ckEditorPlugins/ckIcons/ckeditor5-seeq-border.svg';
import { CONTENT_MODEL_ATTRIBUTES } from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import i18next from 'i18next';

const BORDER_COMMAND = 'toggleSeeqContentBorder';

/**
 * This adds a button which when pressed toggles a border around the selected content. If multiple pieces of content
 * are selected, it toggles all selected pieces of content to the value opposite the first selected piece.
 */
export class ContentBorder extends Plugin {
  static pluginName = 'ContentBorder';
  static setup = {
    name: ContentBorder.pluginName,
    plugin: ContentBorder,
    toolbar: ContentBorder.pluginName,
  };

  static get requires() {
    return [Content];
  }

  init() {
    const editor = this.editor;

    editor.commands.add(BORDER_COMMAND, new (ContentBorderCommand as any)(this.editor));

    this.extendSchema();
    this.defineToolbarButton();
  }

  extendSchema() {
    const editor = this.editor;
    editor.model.schema.extend('content', {
      allowAttributes: CONTENT_MODEL_ATTRIBUTES.BORDER,
    });
  }

  defineToolbarButton() {
    const editor = this.editor;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

    editor.ui.componentFactory.add(ContentBorder.pluginName, (locale) => {
      const command = editor.commands.get(BORDER_COMMAND);
      const buttonView = new ButtonView(locale);

      buttonView.set({
        label: i18next.t('REPORT.EDITOR.BORDERS'),
        icon,
        tooltipPosition: 'sw',
        tooltip: true,
      });

      buttonView.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled');

      this.listenTo(buttonView, 'execute', () => editor.execute(BORDER_COMMAND));

      return buttonView;
    });
  }
}

const getContentInSelection = (model) =>
  _.chain(Array.from(model.document.selection.getRanges()))
    .flatMap((range: any) => Array.from(range.getItems()))
    .filter((item: any) => item.is('element', 'content'))
    .value();

class ContentBorderCommand extends Command {
  execute() {
    const editor = this.editor;
    const model = editor.model;
    const contentCallbacks: ContentCallbacks = editor.config.get(CustomPlugin.Content).contentCallbacks;
    let valueToSetTo;

    model.change((writer) => {
      // Selections have ranges which actually point to the real content in the selection
      _.forEach(getContentInSelection(model), (content) => {
        // Selections use different models from the actual models.
        const contentModel = contentCallbacks.getCurrentModel(
          content.getAttribute(CONTENT_MODEL_ATTRIBUTES.DATA_SEEQ_CONTENT),
        );
        if (_.isUndefined(valueToSetTo)) {
          valueToSetTo = !contentModel.getAttribute(CONTENT_MODEL_ATTRIBUTES.BORDER);
        }
        writer.setAttribute(CONTENT_MODEL_ATTRIBUTES.BORDER, valueToSetTo, contentModel);
      });
    });
  }

  refresh() {
    this.isEnabled = !_.isEmpty(getContentInSelection(this.editor.model));
  }
}
