// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup, Overlay, Popover } from 'react-bootstrap';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { Placement } from 'react-bootstrap/Overlay';
import { ColorPicker } from '@/hybrid/workbooks/ColorPicker.organism';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';

const TextFormatterPopoverBindings = bindingsDefinition({
  backgroundColor: prop<string>(),
  textAlign: prop<string>(),
  textColor: prop<string>(),
  textStyle: prop<string[]>(),
  columnKey: prop<string>(),
  colors: prop.optional<string[]>(),
  onClose: prop.optional<() => void>(),
  setTextAlign: prop.optional<(columnKey: string, alignment: string) => void>(),
  setTextBackground: prop.optional<(columnKey: string | number, color: string) => void>(),
  setTextColor: prop.optional<(columnKey: string | number, color: string) => void>(),
  setTextStyle: prop.optional<(columnKey: string, weight: string[]) => void>(),
  placement: prop<Placement>(),
  show: prop.optional<boolean>(),
  target: prop<HTMLElement>(),
  title: prop<string>(),
});

/**
 * Popover with selections for formatting text, e.g. bold/italic, colors, alignment
 */
export const TextFormatterPopover: SeeqComponent<typeof TextFormatterPopoverBindings> = (props) => {
  const {
    backgroundColor,
    textAlign,
    textColor,
    textStyle,
    colors,
    columnKey,
    setTextAlign = () => {},
    setTextBackground = () => {},
    setTextColor = () => {},
    setTextStyle = () => {},
    onClose = () => {},
    placement,
    show = false,
    target,
    title,
  } = props;

  const { t } = useTranslation();

  const [showPopover, setShowPopover] = useState(show);

  useEffect(() => {
    if (show) {
      openPopover();
    }
  }, [show]);

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.getAttribute('data-itemid') === columnKey) {
        return;
      }
      closePopover();
    };

    // add when mounted
    document.querySelector('#mainView').addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.querySelector('#mainView')?.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const openPopover = () => {
    if (showPopover) {
      closePopover();
    }
  };

  const closePopover = () => {
    setShowPopover(false);
    onClose();
  };

  return (
    <Overlay target={target} show={show} placement={placement} transition={false}>
      <Popover id="textFormatterPopover" data-testid="textFormatterPopover">
        <div className="popover-header">{t(title)}</div>
        <div className="popover-body p10">
          <div className="flexColumnContainer flexSpaceBetween">
            <div className="flexColumnContainer mb10">
              <span className="mr10">{t('TABLE_BUILDER.BACKGROUND_COLOR')}</span>
              <div>
                <ColorPicker
                  color={backgroundColor}
                  colors={colors}
                  itemId={columnKey}
                  notifyOnSelect={(columnKey, color) => setTextBackground(columnKey, color)}
                  placement="bottom"
                  dataTestId="backgroundColorPicker"
                />
              </div>
            </div>
            <div className="flexColumnContainer mb10">
              <span className="mr10">{t('TABLE_BUILDER.TEXT_COLOR')}</span>
              <div>
                <ColorPicker
                  color={textColor}
                  colors={colors}
                  itemId={columnKey}
                  notifyOnSelect={(columnKey, color) => setTextColor(columnKey, color)}
                  placement="bottom"
                  dataTestId="textColorPicker"
                />
              </div>
            </div>
          </div>
          <div className="flexColumnContainer mb10">
            <ToggleButtonGroup
              defaultValue={textStyle}
              type="checkbox"
              data-testid="textStyleGroup"
              onChange={(value) => setTextStyle(columnKey, value)}>
              <ToggleButton variant="light" value="bold">
                <Icon icon="fa fa-bold" type="text" />
              </ToggleButton>
              <ToggleButton variant="light" value="italic">
                <Icon icon="fa fa-italic" type="text" />
              </ToggleButton>
              <ToggleButton variant="light" value="underline">
                <Icon icon="fa fa-underline" type="text" />
              </ToggleButton>
              <ToggleButton variant="light" value="line-through">
                <Icon icon="fa fa-strikethrough" type="text" />
              </ToggleButton>
              <ToggleButton variant="light" value="overline">
                <Icon icon="fa fa-minus" type="text" />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="flexColumnContainer">
            <ToggleButtonGroup
              defaultValue={textAlign}
              name="text alignment"
              type="radio"
              data-testid="textAlignGroup"
              onChange={(value) => setTextAlign(columnKey, value)}>
              <ToggleButton variant="light" value="left">
                <Icon icon="fa fa-align-left" type="text" />
              </ToggleButton>
              <ToggleButton variant="light" value="center">
                <Icon icon="fa fa-align-center" type="text" />
              </ToggleButton>
              <ToggleButton variant="light" value="right">
                <Icon icon="fa fa-align-right" type="text" />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </Popover>
    </Overlay>
  );
};
