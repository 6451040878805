// @ts-strict-ignore
/**
 * A simple collection of functions to display toast notifications.  There are short messages that are momentarily
 * displayed in the top right corner of our application in response to a user action or API error/warning.  The user can
 * just wait for the toast to vanish (after a few seconds) or dismiss it using its close button.
 *
 * The simplest form of toast notification displays a text message that tells the user what has happened, with
 * background color that indicates the level of importance/severity - success (green), warning (yellow), error (red),
 * info (blue).
 *
 * The more sophisticated notifications include an action button to allow the user take a secondary action
 * (typically a sort of Undo or Restore operation).
 */

import React from 'react';
import i18next from 'i18next';

import type { IconType } from '@/hybrid/core/Icon.atom';
import { Bounce, cssTransition, toast, ToastOptions, TypeOptions } from 'react-toastify';
import { ButtonVariant, TextButton } from '@/hybrid/core/TextButton.atom';
import { logError, logInfo, logWarn } from '@/hybrid/utilities/logger';
import { formatError, formatValue } from '@/hybrid/utilities/logger.utilities';
import { headlessRenderMode } from '@/hybrid/utilities/utilities';
import { get, isError } from 'lodash';
import { IS_PROTRACTOR } from '@/main/app.constants';
import { extractError } from '@/hybrid/requests/axios.utilities';
import HttpCodes from 'http-status-codes';
import { doTrack } from '@/track/track.service';

const systemTestTransition = cssTransition({
  enter: 'Toastify--animate Toastify_systemTest',
  exit: 'Toastify--animate Toastify_systemTest',
});
/***
 * Type definitions
 */

type BaseToast = {
  toastUUID?: string;
  doNotTrack?: boolean;
};

export type TextToastProps = BaseToast & {
  messageKey: string;
  messageParams?: object;
};

export type ApiToastProps = BaseToast & {
  httpResponseOrError: unknown;
  displayForbidden?: true;
};

export type ButtonToastProps = (TextToastProps | ApiToastProps) & {
  buttonId?: string;
  buttonLabelKey: string;
  buttonLabelParams?: object;
  buttonAction: () => void;
  buttonIcon?: string | null;
  buttonIconStyle?: IconType;
  buttonVariant?: ButtonVariant;
};

export type ToastProps = TextToastProps | ApiToastProps | ButtonToastProps;

/**
 * Internals
 */

const suppressDisplay = headlessRenderMode(); // CRAB-27446: we're no longer handling route-based suppression

function isTextToast(props: ToastProps): props is TextToastProps {
  return 'messageKey' in props;
}

function isButtonToast(props: ToastProps): props is ButtonToastProps {
  return 'buttonLabelKey' in props;
}

function renderKind(
  method: TypeOptions,
  { toastUUID }: ToastProps,
): { kind: 'suppress' | 'new' | 'update'; toastId: string } {
  if (suppressDisplay) return { kind: 'suppress', toastId: '' };
  if (toastUUID && toast.isActive(toastUUID)) return { kind: 'update', toastId: toastUUID };
  return { kind: 'new', toastId: toastUUID ?? `${method}|${Date.now()}` };
}

function apiLog(method: TypeOptions, props: ApiToastProps): string {
  const errorOrResponse = extractError(props.httpResponseOrError);
  const text = isError(errorOrResponse) ? formatError(errorOrResponse) : formatValue(errorOrResponse);
  logAndTrack(text, method, props);
  return text;
}

function autoClose(method: TypeOptions, options?: ToastOptions): number | false {
  const autoCloseInterval1 = method === 'error' ? AUTO_CLOSE_INTERVAL_LONG : AUTO_CLOSE_INTERVAL_DEFAULT;
  return options?.autoClose === undefined ? autoCloseInterval1 : options.autoClose;
}

function getToastOptions(
  toastId: string,
  method: TypeOptions,
  autoClose: number | false,
  options?: ToastOptions,
): ToastOptions {
  return {
    draggable: false,
    closeOnClick: false,
    closeButton: true,
    ...options,
    toastId,
    autoClose,
    transition: IS_PROTRACTOR ? systemTestTransition : Bounce,
    hideProgressBar: false,
  };
}

function renderSimpleToast(
  text: string,
  method: TypeOptions,
  props: ToastProps,
  options?: ToastOptions,
): string | number | null {
  const { kind, toastId } = renderKind(method, props);

  if (kind === 'new') {
    return toast[method](
      <div data-testid="toastText">{text}</div>,
      getToastOptions(toastId, method, autoClose(method, options), options),
    );
  }

  if (kind === 'update') {
    toast.update(toastId, {
      render: <div data-testid="toastText">{text}</div>,
    });
    return toastId;
  }

  return null;
}

function apiToast(method: TypeOptions, props: ApiToastProps, options?: ToastOptions): string | number | null {
  const text = apiLog(method, props);
  return renderSimpleToast(text, method, props, options);
}

function textLog(method: TypeOptions, props: TextToastProps): string {
  // NOTE: we force English translation for log text!
  const text = i18next.t(props.messageKey, {
    ...props.messageParams,
    lng: 'en',
  });
  logAndTrack(text, method, props);
  return i18next.t(props.messageKey, props.messageParams);
}

function textToast(method: TypeOptions, props: TextToastProps, options?: ToastOptions): string | number | null {
  const text = textLog(method, props);
  return renderSimpleToast(text, method, props, options);
}

function buttonToast(method: TypeOptions, props: ButtonToastProps, options?: ToastOptions): string | number | null {
  const text = isTextToast(props) ? textLog(method, props) : apiLog(method, props);
  const { kind, toastId } = renderKind(method, props);
  const buttonId = props.buttonId ?? `toastActionButton_${toastId}`;
  const autoCloseInterval =
    options?.autoClose === false ? options.autoClose : Math.max(options?.autoClose, AUTO_CLOSE_INTERVAL_LONG);
  const autoClose = options?.autoClose === undefined ? AUTO_CLOSE_INTERVAL_LONG : autoCloseInterval;

  return kind === 'suppress'
    ? null
    : toast[method](
        <div className="button-toast-wrapper flexRowContainer flexAlignCenter">
          <div className="mb10" data-testid="toastText">
            {text}
          </div>
          <TextButton
            onClick={() => {
              toast.dismiss(props.toastUUID);
              props.buttonAction();
            }}
            label={props.buttonLabelKey ?? 'UNDO'}
            labelTranslationParameters={props.buttonLabelParams}
            size="sm"
            testId="toastActionButton"
            id={buttonId}
            icon={props.buttonIcon === undefined ? 'fa-undo' : props.buttonIcon}
            iconStyle={props.buttonIconStyle ?? 'white'}
            variant={props.buttonVariant ?? 'theme'}
          />
        </div>,
        getToastOptions(toastId, method, autoClose, options),
      );
}

function buildToast(method: TypeOptions, props: ToastProps, options?: ToastOptions): string | number | null {
  if (isButtonToast(props)) return buttonToast(method, props, options);
  if (isTextToast(props)) return textToast(method, props, options);
  return apiToast(method, props, options);
}

function log(method: TypeOptions, message: string) {
  switch (method) {
    case 'success':
    case 'info':
      logInfo(message);
      break;
    case 'warning':
      logWarn(message);
      break;
    case 'error':
      logError(message);
      break;
  }
}

function logAndTrack(text: string, method: TypeOptions, props: ToastProps) {
  log(method, text);
  if (!props.doNotTrack) {
    doTrack('Warning', `${method}${suppressDisplay ? '(suppressed)' : ''}`, text);
  }
}

/**
 * Exported functions and constants
 */

/**
 * Renders an informational toast
 * @param {ToastProps} props
 * @param {ToastOptions} options
 * @returns {string | number | null} the toast id
 */
export function infoToast(props: ToastProps, options?: ToastOptions): string | number | null {
  return buildToast('info', props, options);
}

/**
 * Renders a success toast
 * @param {ToastProps} props
 * @param {ToastOptions} options
 * @returns {string | number | null} the toast id
 */
export function successToast(props: ToastProps, options?: ToastOptions): string | number | null {
  return buildToast('success', props, options);
}

/**
 * Renders a warning toast
 * @param {ToastProps} props
 * @param {ToastOptions} options
 * @returns {string | number | null} the toast id
 */
export function warnToast(props: ToastProps, options?: ToastOptions): string | number | null {
  return buildToast('warning', props, options);
}

/**
 * Renders an error toast except for status code SERVICE_UNAVAILABLE,
 * which the backend sends when a request is cancelled.
 * @param {ToastProps} props
 * @param {ToastOptions} options
 * @returns {string | number | null} the toast id
 */
export function errorToast(props: ToastProps, options?: ToastOptions): string | number | null {
  if (get(props, 'httpResponseOrError.response.status') === HttpCodes.SERVICE_UNAVAILABLE) {
    return;
  }

  return buildToast('error', props, options);
}

export const AUTO_CLOSE_INTERVAL_DEFAULT = 4_000;
export const AUTO_CLOSE_INTERVAL_LONG = 10_000;
