// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectIdentity from '@/hybrid/core/SelectIdentity.molecule';

/** The component that gets rendered in home screen advanced search that allows the user to filter their search by
 *  owner or creator **/

interface FilterOwnerCreatorAdvancedSearchProps {
  ownerCreator: any[];
  setOwnerCreator: (object) => void;
  label: string;
  labelId: string;
}

export const FilterOwnerCreatorAdvancedSearch: React.FunctionComponent<FilterOwnerCreatorAdvancedSearchProps> = ({
  ownerCreator,
  setOwnerCreator,
  label,
  labelId,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-50 flexRowContainer col-sm-6 mt10 multiSelectTwoLine">
      <div className="mb5"> {t(label)} </div>
      <SelectIdentity
        idForLabel={labelId}
        setIdentity={setOwnerCreator}
        allowGroups={false}
        identity={ownerCreator}
        includeAllProperties={true}
        startEditable={true}
        multiple={true}
        prefillIdentity={true}
        placeholder="HOME_SCREEN.ADVANCED_SEARCH.OWNED_CREATED_BY_PLACEHOLDER"
        clearIdentityWhenEmpty={true}
      />
      {ownerCreator?.length > 0 && (
        <a href="#" data-testid="searchOwnedCreatedBy" className="textAlignRight" onClick={() => setOwnerCreator([])}>
          {t('HOME_SCREEN.ADVANCED_SEARCH.CLEAR_ALL')}
        </a>
      )}
    </div>
  );
};
