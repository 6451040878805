import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { FORM_ERROR, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import React from 'react';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import _ from 'lodash';
import { APP_STATE, MIME_TYPES } from '@/main/app.constants';
import FileSaver from 'file-saver';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from 'react-i18next';
import { HelpIcon, Icon } from '@/hybrid/core/Icon.atom';
import { GRID_OPTION } from '@/hybrid/tools/exportOData/exportODataPanel.store';
import { DurationEntry } from '@/hybrid/core/DurationEntry.atom';
import {
  setDuration,
  setExportData,
  setExportStatistics,
  setExportTimeZone,
  setGridOption,
  setGridOrigin,
  setGridOriginEnabled,
  setGridSize,
  setTimeRangeEnd,
  setTimeRangeStart,
} from '@/hybrid/tools/exportExcel/exportExcelPanel.actions';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { toExcel } from '@/hybrid/utilities/export.utilities';
import { cancelGroup } from '@/hybrid/requests/pendingRequests.utilities';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import {
  sqTrendStore,
  sqWorkbookStore,
  sqWorksheetStore,
  sqWorkstepsStore,
  sqExportExcelPanelStore,
} from '@/core/core.stores';
import { doTrack } from '@/track/track.service';

const exportExcelBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
  sqInvestigateActions: injected<InvestigateActions>(),
});

export const ExportExcel: SeeqComponent<typeof exportExcelBindings> = () => {
  const { sqInvestigateActions, $state } = useInjectedBindings(exportExcelBindings);

  const {
    name,
    exportStatistics,
    exportData,
    timeRange,
    gridOption,
    gridSize,
    gridOriginEnabled,
    exportSignals,
    exportConditions,
    gridOrigin,
    exportTimeZone,
  } = useFlux(sqExportExcelPanelStore);

  const timezone = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone);
  // force the component to re-render whenever view changes
  useFluxPath(sqTrendStore, () => sqTrendStore.view);

  const isCapsuleTime = sqTrendStore.isTrendViewCapsuleTime();
  const isChainView = sqTrendStore.isTrendViewChainView();

  const { t } = useTranslation();

  /**
   * Generate a Workbench URL to current Worksheet, with view-only access
   *
   * @returns {String} Returns an absolute URL
   */
  const makeWorksheetURL = () => {
    const { workbookId, worksheetId } = $state.params;

    return $state.href(
      APP_STATE.VIEW_WORKSHEET,
      {
        workbookId,
        worksheetId,
      },
      {
        absolute: true,
      },
    );
  };

  /**
   * Begins the export process
   */
  const execute = () => {
    const cancellationGroup = 'excelExport';
    const gridSizeEnabled = gridOption === GRID_OPTION.CUSTOM;
    const originalTimestampsEnabled = gridOption === GRID_OPTION.ORIGINAL;
    const gridOriginString =
      gridSizeEnabled && gridOriginEnabled && !isCapsuleTime
        ? // Format the origin so it's output as a localized ISO-style time string
          gridOrigin.format('YYYY-MM-DD[T]HH:mm:ss')
        : null;

    cancelGroup(cancellationGroup);

    const exportName = `Export-${name}-${sqWorkbookStore.getWorksheetName($state.params.worksheetId)}_${
      sqWorkstepsStore.current.id
    }`;

    doTrack('Export', 'Excel', 'start');

    const items = _.chain(exportSignals).concat(exportConditions).compact().value();

    return toExcel(
      exportName,
      items,
      timeRange,
      exportStatistics,
      exportData,
      gridSizeEnabled && gridSize.value + gridSize.units,
      gridOriginString,
      isCapsuleTime,
      isChainView,
      cancellationGroup,
      originalTimestampsEnabled,
      exportTimeZone.name,
      makeWorksheetURL(),
    )
      .then((response) => new Blob([response.data], { type: MIME_TYPES.EXCEL }))
      .then((blob) => {
        FileSaver.saveAs(blob, 'SeeqExport.xlsx');
        sqInvestigateActions.close();
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  };

  /**
   * Determines if any options are selected
   * @returns {Boolean} True if options are selected, false otherwise
   */
  const anyOptionsSelected = () => exportStatistics || exportData;

  const formDataSetup: FormElement[] = [
    {
      component: 'DisplayOnlyFormElementWrapper',
      name: 'title',
      children: (
        <div className="flexColumnContainer flexAlignCenter pt10 pl15">
          <Icon icon="fc-excel" large={true} />
          <h4 className="modal-title ml10">{t('EXPORT_EXCEL.TITLE')}</h4>
        </div>
      ),
    },
    {
      component: 'FormControlFormComponent',
      testId: 'exportName',
      name: 'name',
      value: name,
      onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.EXPORT_EXCEL, name),
      size: 'lg',
      label: 'EXPORT_EXCEL.EXPORT_NAME',
      displayNumber: true,
      wrapperClassNames: 'flexFill',
      required: true,
    },
    {
      component: 'FormGroup',
      name: 'exportOptions',
      displayNumber: true,
      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'exportOptionsLabelContainer',
          children: (
            <div className="pb5 flexColumnContainer">
              <span data-testid="exportOptionsLabel">{t('EXPORT_EXCEL.EXPORT_OPTIONS')}</span>
              <HelpIcon
                tooltip="EXPORT_EXCEL.EXPORT_OPTIONS_TOOLTIP"
                extraClassNames="pl5"
                testId="exportOptionsTooltip"
              />
            </div>
          ),
        },
        {
          component: 'CheckboxFormComponent',
          name: 'statistics',
          testId: 'statistics',
          id: 'stats',
          value: exportStatistics,
          onChange: () => setExportStatistics(!exportStatistics),
          checkboxLabel: 'EXPORT_EXCEL.SUMMARY',
          validation: () => !anyOptionsSelected(),
        },
        {
          component: 'CheckboxFormComponent',
          name: 'dataEnabled',
          testId: 'dataEnabled',
          id: 'data',
          value: exportData,
          onChange: () => setExportData(!exportData),
          checkboxLabel: 'EXPORT_EXCEL.DATA',
          validation: () => !anyOptionsSelected(),
        },
        {
          component: 'ErrorMessageFormComponent',
          name: 'noOptionSelectedError',
          failForm: true,
          includeIf: !anyOptionsSelected(),
          value: 'EXPORT_EXCEL.NO_OPTION_SELECTED_ERROR',
          type: FORM_ERROR,
          extraClassNames: 'ml15',
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'timeRangeFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'DateTimeRangeFormComponent',
          name: 'timeRange',
          label: 'EXPORT_EXCEL.TIME_RANGE',
          value: _.omit(timeRange, 'duration'),
          timezone,
          hideTimezone: true,
          onStartTimeChange: setTimeRangeStart,
          onEndTimeChange: setTimeRangeEnd,
          extraClassNames: 'flexJustifyCenter',
        },
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'durationEntry',
          children: (
            <div className="flexColumnContainer flexJustifyCenter">
              [
              <DurationEntry
                startDate={timeRange.start}
                endDate={timeRange.end}
                updateDuration={setDuration}
                timezone={exportTimeZone}
                updateDates={_.noop}
                textExtraClassNames="sq-text-primary"
                extraClassNames="mb10"
                autoCorrect={false}
              />
              ]
            </div>
          ),
        },
        {
          component: 'ErrorMessageFormComponent',
          name: 'durationError',
          failForm: true,
          includeIf: timeRange.end?.isSameOrBefore?.(timeRange.start),
          type: FORM_ERROR,
          value: `${t('PARSE_DURATION_ERROR')} 0`,
          extraClassNames: 'text-center',
        },
        {
          component: 'TimeZoneSelectorFormComponent',
          name: 'exportTimeZone',
          value: exportTimeZone,
          onChange: setExportTimeZone,
          extraClassNames: 'max-width-230 marginAuto',
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'gridOptionsFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'gridOptionsLabelContainer',
          children: (
            <div className="pb5">
              <span data-testid="gridOptionsLabel">{t('EXPORT_EXCEL.GRID_SIZE')}</span>
            </div>
          ),
        },
        {
          component: 'CheckboxFormComponent',
          name: 'autoOption',
          id: 'autoOption',
          checkboxLabel: 'EXPORT_EXCEL.AUTO',
          value: gridOption === GRID_OPTION.AUTOMATIC,
          type: 'radio',
          onChange: () => setGridOption(GRID_OPTION.AUTOMATIC),
        },
        {
          component: 'CheckboxFormComponent',
          name: 'customOption',
          id: 'customOption',
          checkboxLabel: 'EXPORT_EXCEL.CUSTOM',
          value: gridOption === GRID_OPTION.CUSTOM,
          type: 'radio',
          onChange: () => setGridOption(GRID_OPTION.CUSTOM),
        },
        {
          component: 'FormGroup',
          name: 'setGridSizeFormGroup',
          testId: 'setGridSizeFormGroup',
          includeIf: gridOption === GRID_OPTION.CUSTOM,
          extraClassNames: 'ml20',
          components: [
            {
              component: 'ValueWithUnitsFormComponent',
              name: 'setGridSize',
              testId: 'setGridSize',
              value: gridSize,
              min: 0,
              minIsExclusive: true,
              onChange: setGridSize,
              required: true,
              customErrorText: 'EXPORT_EXCEL.GRID_SIZE_MINIMUM',
            },
            {
              component: 'CheckboxFormComponent',
              name: 'gridOriginEnabled',
              testId: 'gridOriginEnabled',
              id: 'gridOriginEnabled',
              value: gridOriginEnabled,
              checkboxLabel: 'EXPORT_EXCEL.CUSTOM_ORIGIN',
              tooltip: 'EXPORT_EXCEL.CUSTOM_ORIGIN_TOOLTIP',
              onChange: () => setGridOriginEnabled(!gridOriginEnabled),
              extraClassNames: 'mt10',
            },
            {
              component: 'DateTimeEntryFormComponent',
              name: 'gridOrigin',
              timezone,
              value: gridOrigin,
              hideTimezone: true,
              onChange: setGridOrigin,
              extraClassNames: 'ml20',
            },
          ],
        },
        {
          component: 'CheckboxFormComponent',
          name: 'originalOption',
          id: 'originalOption',
          checkboxLabel: 'EXPORT_EXCEL.ORIGINAL',
          value: gridOption === GRID_OPTION.ORIGINAL,
          type: 'radio',
          onChange: () => setGridOption(GRID_OPTION.ORIGINAL),
        },
      ],
    },
    {
      component: 'ItemSelectFormComponent',
      name: 'exportSignals',
      testId: 'exportSignals',
      displayNumber: true,
      label: 'EXPORT_EXCEL.SIGNALS',
      itemTypes: [ITEM_TYPES.SERIES],
      isMultipleSelect: true,
      value: _.map(exportSignals, 'id'),
      required: _.isEmpty(exportConditions),
      onChange: (item) => sqInvestigateActions.setParameterItem(TREND_TOOLS.EXPORT_EXCEL, 'exportSignals', item),
      onRemove: (item) => sqInvestigateActions.unsetParameterItem(TREND_TOOLS.EXPORT_EXCEL, 'exportSignals', item),
      addAllEnabled: true,
      removeAllEnabled: true,
    },
    {
      component: 'ItemSelectFormComponent',
      name: 'exportConditions',
      testId: 'exportConditions',
      displayNumber: true,
      label: 'EXPORT_EXCEL.CONDITIONS',
      itemTypes: [ITEM_TYPES.CAPSULE_SET],
      isMultipleSelect: true,
      value: _.map(exportConditions, 'id'),
      required: _.isEmpty(exportSignals),
      onChange: (item) => sqInvestigateActions.setParameterItem(TREND_TOOLS.EXPORT_EXCEL, 'exportConditions', item),
      onRemove: (item) => sqInvestigateActions.unsetParameterItem(TREND_TOOLS.EXPORT_EXCEL, 'exportConditions', item),
      addAllEnabled: true,
      removeAllEnabled: true,
    },
  ];

  return (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={execute}
      closeFn={sqInvestigateActions.close}
      toolId={TREND_TOOLS.EXPORT_EXCEL}
      submitBtnId="exportExcelButton"
      submitBtnLabel="EXPORT"
    />
  );
};

export const sqExportExcel = angularComponent(exportExcelBindings, ExportExcel);
