import React, { useState } from 'react';
import { Accordion, Modal, useAccordionToggle } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CopyableInputField } from '@/hybrid/core/CopyableInputField.molecule';
import { Toggler } from '@/hybrid/core/CustomToggle.atom';
import { throwError } from '@/hybrid/core/utilities';
import { getWorkbenchAddress } from '@/hybrid/utilities/utilities';

interface ExportODataModalProps {
  onClose: () => void;
  oDataPath: string;
  oDataCapsulePath: string;
}

const ExportInfoToggle: (props: {
  eventKey: string;
  activeEventKey: string | undefined;
  label: React.ReactElement;
  setActiveKey: (key: string | undefined) => void;
}) => React.ReactElement = ({ eventKey, activeEventKey, label, setActiveKey }) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    setActiveKey(activeEventKey === eventKey ? undefined : eventKey),
  );
  return (
    <Toggler isCardOpen={eventKey === activeEventKey} onClick={decoratedOnClick} label={label} showDivider={false} />
  );
};

export const ExportODataModal: React.FunctionComponent<ExportODataModalProps> = ({
  onClose,
  oDataCapsulePath,
  oDataPath,
}) => {
  const oDataEndpoint = getWorkbenchAddress() + oDataPath;
  const oDataCapsuleEndpoint = getWorkbenchAddress() + oDataCapsulePath;
  const serviceUrl = oDataEndpoint.match(/.*\.svc\//)?.[0] ?? throwError('No OData service URL found');
  const exportName = oDataPath.match(/[^\/]+$/)?.[0] ?? throwError('Invalid Export Name');

  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);

  const getLabel = (labelText: string) => <span className="force-link-look sq-text-info">{t(labelText)}</span>;

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="exportODataModal">
      <Modal.Header closeButton={true}>
        <h4>{t('EXPORT_ODATA_MODAL.TITLE')}</h4>
      </Modal.Header>
      <Modal.Body>
        <h4>{t('EXPORT_ODATA_MODAL.ODATA_HEADINGS.SERVICE')}</h4>
        <div className="flexJustifyEnd pt3 mr10 width-100percent">
          <CopyableInputField
            value={serviceUrl}
            readOnly={true}
            buttonTooltip="COPY_URL.TO_CLIPBOARD"
            notifyMessage="COPY_URL.SUCCESS"
            fieldTooltip=""
            testId="serviceUrl"
          />
        </div>

        <h4>{t('EXPORT_ODATA_MODAL.ODATA_HEADINGS.EXPORT_NAME')}</h4>
        <div className="flexJustifyEnd pt3 mr10 width-100percent">
          <CopyableInputField
            value={exportName}
            readOnly={true}
            buttonTooltip="COPY_NAME.TO_CLIPBOARD"
            notifyMessage="COPY_NAME.SUCCESS"
            fieldTooltip=""
            testId="exportName"
          />
        </div>

        <h4>{t('EXPORT_ODATA_MODAL.ODATA_HEADINGS.ENDPOINT_SAMPLE')}</h4>
        <div className="flexJustifyEnd pt3 mr10 width-100percent">
          <CopyableInputField
            value={oDataEndpoint}
            readOnly={true}
            buttonTooltip="COPY_URL.TO_CLIPBOARD"
            notifyMessage="COPY_URL.SUCCESS"
            fieldTooltip=""
            testId="oDataEndpoint"
          />
        </div>

        <h4>{t('EXPORT_ODATA_MODAL.ODATA_HEADINGS.ENDPOINT_CAPSULE')}</h4>
        <div className="flexJustifyEnd pt3 mr10 width-100percent">
          <CopyableInputField
            value={oDataCapsuleEndpoint}
            readOnly={true}
            buttonTooltip="COPY_URL.TO_CLIPBOARD"
            notifyMessage="COPY_URL.SUCCESS"
            fieldTooltip=""
            testId="oDataCapsuleEndpoint"
          />
        </div>
        <h4>{t('EXPORT_ODATA_MODAL.INSTRUCTIONS')}</h4>
        <Accordion>
          <ExportInfoToggle
            setActiveKey={setActiveKey}
            activeEventKey={activeKey}
            eventKey="0"
            label={getLabel('EXPORT_ODATA_MODAL.EXCEL.TITLE')}
          />
          <Accordion.Collapse eventKey="0" data-testid="excelToggle">
            <ol>
              <h5>{t('EXPORT_ODATA_MODAL.EXCEL.ME_2016')}</h5>
              <ol>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.OPEN_NEW_IN_EXCEL')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.SELECT_DATA_TAB')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.FIND_SECTION')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.SELECT_ODATA_FEED')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.PROVIDE_ENDPOINT')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.LOG_IN')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.SUCCESS')}</li>
              </ol>
              <h5>{t('EXPORT_ODATA_MODAL.EXCEL.ME_2010_2013')}</h5>
              <ol>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.DOWNLOAD_PLUGIN')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.OPEN_NEW_IN_EXCEL')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.SELECT_POWER_QUERY')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.FIND_SECTION')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.SELECT_ODATA_FEED')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.PROVIDE_ENDPOINT')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.LOG_IN')}</li>
                <li>{t('EXPORT_ODATA_MODAL.EXCEL.SUCCESS')}</li>
              </ol>
            </ol>
          </Accordion.Collapse>
          <ExportInfoToggle
            setActiveKey={setActiveKey}
            activeEventKey={activeKey}
            eventKey="1"
            label={getLabel('EXPORT_ODATA_MODAL.TABLEAU.TITLE')}
          />
          <Accordion.Collapse eventKey="1" data-testid="tableauToggle">
            <ol>
              <li>{t('EXPORT_ODATA_MODAL.TABLEAU.OPEN_TABLEAU')}</li>
              <li>{t('EXPORT_ODATA_MODAL.TABLEAU.CLICK_ODATA')}</li>
              <li>{t('EXPORT_ODATA_MODAL.TABLEAU.USE_ENDPOINT_SHOWN')}</li>
              <li>{t('EXPORT_ODATA_MODAL.TABLEAU.USE_DEFAULT_SETTINGS')}</li>
              <li>{t('EXPORT_ODATA_MODAL.TABLEAU.SUCCESS')}</li>
            </ol>
          </Accordion.Collapse>
          <ExportInfoToggle
            setActiveKey={setActiveKey}
            activeEventKey={activeKey}
            eventKey="2"
            label={getLabel('EXPORT_ODATA_MODAL.SPOTFIRE.TITLE')}
          />
          <Accordion.Collapse eventKey="2" data-testid="spotfireToggle">
            <ol>
              <li>{t('EXPORT_ODATA_MODAL.SPOTFIRE.OPEN_SPOTFIRE')}</li>
              <li>{t('EXPORT_ODATA_MODAL.SPOTFIRE.ADD_DATA_CONNECTION')}</li>
              <li>{t('EXPORT_ODATA_MODAL.SPOTFIRE.SPECIFY_ODATA_SERVICE_URL')}</li>
              <li>{t('EXPORT_ODATA_MODAL.SPOTFIRE.SELECT_TABLE')}</li>
              <li>{t('EXPORT_ODATA_MODAL.SPOTFIRE.CLICK_ADD')}</li>
              <li>{t('EXPORT_ODATA_MODAL.SPOTFIRE.SUCCESS')}</li>
            </ol>
          </Accordion.Collapse>
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};
