// @ts-strict-ignore
import { HTTP_MAX_BODY_SIZE_BYTES } from '@/main/app.constants';
import { browserIsFirefox } from '@/utilities/browserId';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import moment from 'moment-timezone';
import log4javascript from 'log4javascript';
import _ from 'lodash';
import { isHttpConfig, isHttpResponse, isWebsocketStatusMessage } from '@/hybrid/utilities/http.utilities';
import { getCsrfToken } from '@/hybrid/utilities/auth.utilities';
import { headlessRenderMode } from '@/hybrid/utilities/utilities';

export type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'fatal';

export const LOG_ROOT_NAME = 'sqLogger';

// Callbacks that receive a log message and scrub sensitive information from it
const SENSITIVE_INFO_SCRUBBERS = [
  (text: string) => text.replace(getCsrfToken(), '****'),
  // Extra precaution to remove from JSON a csrf token that is not the same as what is returned by #getCsrfToken()
  (text: string) => text.replace(new RegExp(`("${SeeqNames.API.Headers.Csrf}"\s*:\s*")(.*?)"`, 'g'), '$1****"'),
];

/**
 * Helper that removes sensitive information from a string.
 *
 * @param value - The value to scrub
 * @return The scrubbed string
 */
function scrubSensitiveData(value: string): string {
  return _.reduce(SENSITIVE_INFO_SCRUBBERS, (memo, scrubber) => scrubber(memo), value);
}

/**
 * Logs a message to the browser console at the specified level.
 *
 * @param message - Message to log. Note that a message longer than HTTP_MAX_BODY_SIZE_BYTES will be
 *   truncated when POSTed via HTTP.
 * @param [category] - A category that will allow log messages to be grouped (e.g. chart)
 */
export function logToConsole(level: LogLevel, message: string | Error, category?: string): [string, string] {
  const formattedMessage = formatValue(message);
  const timestamp = moment().format('HH:mm:ss.SSS');
  const loggerName = _.isUndefined(category) ? LOG_ROOT_NAME : `${LOG_ROOT_NAME}.${category}`;

  console[level === 'fatal' ? 'error' : level](`${timestamp} [${loggerName}] ${formattedMessage}`);

  return [formattedMessage, loggerName];
}

/**
 * Logs a message to the browser console and sends it to the server (via AjaxAppender) at the specified level.
 *
 * @param message - Message to log. Note that a message longer than HTTP_MAX_BODY_SIZE_BYTES will be
 *   truncated when POSTed via HTTP.
 * @param [category] - A category that will allow log messages to be grouped (e.g. chart)
 */
export function log(level: LogLevel, message: string | Error, category?: string) {
  const [msg, loggerName] = logToConsole(level, message, category);

  if (!headlessRenderMode()) {
    const logger = log4javascript.getLogger(loggerName);
    const ellipses = msg.length > HTTP_MAX_BODY_SIZE_BYTES ? ' ...[truncated]' : '';
    logger[level](msg.slice(0, HTTP_MAX_BODY_SIZE_BYTES) + ellipses);
  }
}

/**
 * Formats the given Error in a format suitable for logging
 *
 * @param error - the Error to format as a string
 * @param includeStack - include the stack trace or not to produce the formatted message
 */
export function formatError(error: Error, includeStack = false): string {
  if (!includeStack) {
    return error.message;
  }
  return formatValue(error);
}

/**
 * Formats the value in a format suitable for logging with the most information
 *
 * @param value - the value to format as a string
 */
export function formatValue(value: any): string {
  try {
    if (_.isString(value)) {
      return scrubSensitiveData(value);
    } else if (_.isNil(value)) {
      return String(value); // Get a nice string like "null" or "undefined"
    } else if (_.isError(value)) {
      if (browserIsFirefox) {
        // Firefox only includes the stack in Error#stack which loses the error message; also indent the stack
        return [value.toString(), ...(value.stack?.split('\n') ?? [])].join('\n  ');
      } else {
        // Use the non-standard 'stack' property to for a more complete message or fallback to a normal toString
        return value.stack ?? String(value);
      }
    } else if (_.isArray(value) || _.isPlainObject(value)) {
      // Special case objects that look like they are http responses
      if (isHttpResponse(value)) {
        const path = _.split(value.config.url ?? '', '?', 1)[0];
        const response = formatMessage`${value.config.method} ${path} ${value.status} ${value.statusText}`;
        if (!_.isEmpty(_.get(value, 'data.statusMessage'))) {
          // Appserver includes a statusMessage in error responses
          return formatMessage`${value.data.statusMessage} (${response})`;
        } else if (!_.isNil(value.data) && value.status >= 300) {
          // Include the body of the request for errors since we assume the contents will be small
          return formatMessage`${value.data} (${response})`;
        } else {
          return response;
        }
      }

      // Special case object that look like they are http config objects
      if (isHttpConfig(value)) {
        const path = _.split(value.url, '?', 1)[0];
        const requestId = value.headers[SeeqNames.API.Headers.RequestId];
        if (requestId) {
          return formatMessage`${value.method} ${path} (${requestId})`;
        } else {
          return formatMessage`${value.method} ${path}`;
        }
      }

      // Special case websocket status messages
      if (isWebsocketStatusMessage(value)) {
        return formatMessage`${value.statusMessage}`;
      }

      // Use JSON.stringify to represent the object or array; convert things other than an array or object to strings
      return JSON.stringify(value, (replacerKey, replacerValue) => {
        if (!_.isArray(replacerValue) && !_.isPlainObject(replacerValue)) {
          return formatValue(replacerValue);
        } else {
          return replacerValue;
        }
      });
    } else if (_.isFunction(value)) {
      return `[Function] ${value.name || '(anonymous)'}`;
    } else if (_.isObject(value)) {
      const type = value.constructor.name || 'anonymous';
      return `[${type}] ${value.toString()}`;
    } else {
      return scrubSensitiveData(_.toString(value));
    }
  } catch (ex) {
    return `[${String(ex)} when formatting]`;
  }
}

/**
 * Tagged template literal, formats inputs suitable for logging with the most information
 *
 * @example format `Error with ${object}: ${ex}`
 * @param strings - array built of the string components of the template (ex: ['Error with ', ': ', ''])
 * @param values - array built of the value components of the template (ex: [object, ex])
 */
export function formatMessage(strings: TemplateStringsArray, ...values: unknown[]) {
  const result: string[] = [];
  for (let x = 0; x < strings.length; x++) {
    if (x !== 0) {
      result.push(formatValue(values[x - 1]));
    }

    result.push(strings[x]);
  }

  return _.join(result, '');
}
