import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import _ from 'lodash';
import { CustomLabelInput } from '@/hybrid/trend/toolbar/CustomLabelInput.molecule';
import { TrendActions } from '@/trendData/trend.actions';
import { useTranslation } from 'react-i18next';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { SignalLabelSelection } from '@/hybrid/trend/toolbar/SignalLabelSelection.molecule';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { TrendCapsulePropertiesDropdown } from '@/hybrid/trend/TrendCapsulePropertiesDropdown.molecule';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { deleteAll, deleteSelected, setCursorValueFlags } from '@/hybrid/trend/toolbar/cursor.actions';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { LABEL_LOCATIONS, LABEL_PROPERTIES, TREND_VIEWS } from '@/trendData/trendData.constants';
import { doTrack } from '@/track/track.service';
import { sqCursorStore, sqTrendStore } from '@/core/core.stores';

const labelsPopoverBindings = bindingsDefinition({
  sqTrendActions: injected<TrendActions>(),
});

export const LabelsPopover: SeeqComponent<typeof labelsPopoverBindings> = () => {
  const { sqTrendActions } = useInjectedBindings(labelsPopoverBindings);
  const { t } = useTranslation();

  const { labelDisplayConfiguration, showCapsuleLaneLabels, isRegionSelected, view } = useFlux(sqTrendStore);

  const isCapsuleTime = view === TREND_VIEWS.CAPSULE;

  const showCursorValues = useFluxPath(sqCursorStore, () => sqCursorStore.showValues);
  const hasCursorsInSelection = useFluxPath(sqCursorStore, () =>
    sqCursorStore.hasCursorsInSelectedRegion(isCapsuleTime),
  );

  const enableRemoveSelectedCursorsButton = hasCursorsInSelection && isRegionSelected();

  const displayCustomLabels =
    labelDisplayConfiguration.custom !== LABEL_LOCATIONS.OFF
      ? _.chain(
          labelDisplayConfiguration.custom === LABEL_LOCATIONS.AXIS
            ? sqTrendStore.uniqueAlignments
            : sqTrendStore.uniqueLanes,
        )
          .map((target) => {
            const label = {
              location: labelDisplayConfiguration.custom,
              target,
            } as any;

            label.text = _.get(_.find(labelDisplayConfiguration.customLabels, label), 'text', '');

            return label;
          })
          .value()
      : [];

  return (
    <div className="flexColumnContainer m10" data-testid="labelsPopover">
      <div className="flexRowContainer flexGrowShrink flexSpaceBetween">
        {/* SIGNAL LABEL OPTIONS */}
        <div className="optionHeader min-width-300">{t('TOOLBAR.SIGNAL_LABELS')}</div>

        <SignalLabelSelection
          labelTitle="TOOLBAR.CHART_SIGNAL_NAME_LABEL"
          labelValue={labelDisplayConfiguration.name}
          trackLabel="Name"
          property={LABEL_PROPERTIES.NAME}
        />

        <SignalLabelSelection
          labelTitle="TOOLBAR.CHART_SIGNAL_ASSET_LABEL"
          labelValue={labelDisplayConfiguration.asset}
          trackLabel="Asset"
          property={LABEL_PROPERTIES.ASSET}
        />

        <div className="flexColumnContainer flexSpaceBetween flexBaseline pl20">
          <div>{t('TOOLBAR.CHART_SIGNAL_ASSET_PATH_LEVELS_LABEL')}</div>
          <div className="btn-group flexColumnContainer flexSpaceBetween ml10">
            <input
              type="number"
              min="1"
              className="form-control input-sm width-50"
              data-testid="assetPathLevelInput"
              disabled={labelDisplayConfiguration.asset === LABEL_LOCATIONS.OFF}
              value={labelDisplayConfiguration.assetPathLevels}
              onChange={(e) =>
                sqTrendActions.setLabelDisplayConfiguration(LABEL_PROPERTIES.ASSET_PATH_LEVELS, e.target.value)
              }
            />
          </div>
        </div>

        <SignalLabelSelection
          labelTitle="TOOLBAR.CHART_SIGNAL_LINE_LABEL"
          labelValue={labelDisplayConfiguration.line}
          trackLabel="Line"
          property={LABEL_PROPERTIES.LINE}
        />

        <SignalLabelSelection
          labelTitle="TOOLBAR.CHART_SIGNAL_UOM_LABEL"
          labelValue={labelDisplayConfiguration.unitOfMeasure}
          trackLabel="Unit of Measure"
          property={LABEL_PROPERTIES.UOM}
        />

        <SignalLabelSelection
          labelTitle="TOOLBAR.CHART_SIGNAL_CUSTOM_LABEL"
          labelValue={labelDisplayConfiguration.custom}
          trackLabel="Custom"
          property={LABEL_PROPERTIES.CUSTOM}
        />

        {/* CONDITION LABEL OPTIONS */}
        <div className="optionHeader min-width-300">{t('TOOLBAR.CAPSULE_LABELS')}</div>

        <div className="flexColumnContainer flexSpaceBetween flexBaseline pl20">
          <div>{t('TOOLBAR.CHART_SIGNAL_NAME_LABEL')}</div>
          <div className="btn-group flexColumnContainer flexSpaceBetween ml10">
            {_.map(
              [
                {
                  id: 'conditionLabelOff',
                  value: false,
                  label: 'TOOLBAR.CHART_SIGNAL_LABELS_OFF',
                },
                {
                  id: 'conditionLabelOn',
                  value: true,
                  label: 'TOOLBAR.CHART_SIGNAL_LABELS_LANE',
                },
              ],
              (option) => (
                <Checkbox
                  key={`${option.id}_checkbox`}
                  classes="cursorPointer m5"
                  value={option.value}
                  type="radio"
                  id={option.id}
                  label={option.label}
                  isChecked={showCapsuleLaneLabels === option.value}
                  onChange={() => {
                    sqTrendActions.setCapsuleLaneLabels(option.value);
                    doTrack('Trend', 'Conditions Label - Name', option.value ? 'true' : 'false');
                  }}
                />
              ),
            )}
          </div>
        </div>

        <div className="flexColumnContainer flexSpaceBetween flexBaseline pl20 pb5">
          <div>{`${t('CAPSULES')}:`}</div>
          <TrendCapsulePropertiesDropdown />
        </div>

        {/* CURSOR LABEL OPTIONS */}
        <div className="optionHeader min-width-300">{t('TOOLBAR.CURSORS')}</div>
        <div className="instructions ml5">{t('TOOLBAR.CURSORS_INSTRUCTIONS')}</div>

        <div className="flexColumnContainer flexSpaceBetween flexBaseline pl20">
          <div>{`${t('TOOLBAR.VALUES')}:`}</div>
          <div className="btn-group flexColumnContainer flexSpaceBetween ml10">
            {_.map(
              [
                { id: 'showCursorValues', value: true, label: 'TOOLBAR.SHOW' },
                { id: 'hideCursorValues', value: false, label: 'TOOLBAR.HIDE' },
              ],
              (option) => (
                <Checkbox
                  key={`${option.id}_checkbox`}
                  classes="cursorPointer m5"
                  value={option.value}
                  type="radio"
                  id={option.id}
                  label={option.label}
                  isChecked={showCursorValues === option.value}
                  onChange={() => {
                    setCursorValueFlags(option.value);
                    doTrack('Trend', 'Cursors', option.value ? 'show' : 'hide');
                  }}
                />
              ),
            )}
          </div>
        </div>

        <div className="text-center flexRowContainer">
          <HoverTooltip
            placement="top"
            text={enableRemoveSelectedCursorsButton ? '' : 'TOOLBAR.REMOVE_SELECTED_CURSORS_DISABLED'}>
            <span className="disabled-btn-wrapper width-100percent">
              <TextButton
                id="removeSelectedCursorsBtn"
                extraClassNames="mb10 width-100percent"
                disabled={!enableRemoveSelectedCursorsButton}
                icon="fa-fw fa-remove"
                label="TOOLBAR.REMOVE_SELECTED_CURSORS"
                onClick={() => {
                  deleteSelected(isCapsuleTime);
                  doTrack('Trend', 'Remove selected Cursors');
                }}
              />
            </span>
          </HoverTooltip>

          <TextButton
            id="removeAllCursorsBtn"
            extraClassNames="mb10 width-100percent"
            icon="fa-fw fa-remove"
            label="TOOLBAR.REMOVE_ALL_CURSORS"
            onClick={() => {
              deleteAll(isCapsuleTime);
              doTrack('Trend', 'Remove all Cursors');
            }}
          />
        </div>
      </div>

      {/*  CUSTOM LABELS PANEL */}
      {labelDisplayConfiguration.custom !== LABEL_LOCATIONS.OFF && (
        <div className="customLabels flexFill" data-testid="customLabelsArea">
          <div className="flexColumnContainer flexNoGrowNoShrink flexFill">
            <div className="lightGreyBorderLeft ml10 mr10 flexFill" />
            <div className="flexRowContainer flexFill max-height-600">
              <div className="optionHeader min-width-300 mb10">{t('TOOLBAR.CUSTOM_LABELS')}</div>

              <div className="flexRowContainer flexFillOverflow pr5 overflowYAuto msOverflowStyleAuto mb10">
                {_.map(displayCustomLabels, (label) => (
                  <CustomLabelInput key={`${label.target}_${label.text}`} label={label} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
