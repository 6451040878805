// @ts-strict-ignore
import _ from 'lodash';
import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { TableBuilderToolbar } from '@/hybrid/tableBuilder/TableBuilderToolbar.organism';
import { ScorecardStore } from '@/investigate/scorecard/scorecard.store';
import { useTranslation } from 'react-i18next';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { TableBuilderActions } from '@/hybrid/tableBuilder/tableBuilder.actions';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { TableBuilderOldMetricTable } from '@/hybrid/tableBuilder/TableBuilderOldMetricTable.organism';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { TableBuilderPropsOnly } from '@/hybrid/tableBuilder/TableBuilderPropsOnly.organism';
import {
  headlessRenderMode,
  isPresentationWorkbookMode,
  isStringSeries,
  isViewOnlyWorkbookMode,
} from '@/hybrid/utilities/utilities';
import { ITEM_DATA_STATUS, ITEM_TYPES } from '@/trendData/trendData.constants';
import { copyToClipboard, hasNumericAndStringItems } from '@/hybrid/utilities/tableBuilderHelper.utilities';
import { FormulaService } from '@/services/formula.service';
import { sqDurationStore, sqWorksheetStore, sqTableBuilderStore } from '@/core/core.stores';

const tableBuilderBindings = bindingsDefinition({
  sqTableBuilderActions: injected<TableBuilderActions>(),
  sqScorecardStore: injected<ScorecardStore>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqFormula: injected<FormulaService>(),
});

export interface TableTextFormatterIF {
  setColumnBackground: (key: string | number, color: string) => void;
  setColumnTextAlign: (key: string, alignment: string) => void;
  setColumnTextColor: (key: string | number, color: string) => void;
  setColumnTextStyle: (key: string, styles: string[]) => void;
  setHeaderBackground: (key: string | number, color: string) => void;
  setHeaderTextAlign: (key: string, alignment: string) => void;
  setHeaderTextColor: (key: string | number, color: string) => void;
  setHeaderTextStyle: (key: string, styles: string[]) => void;
  setStyleToAllHeaders: (key: string) => void;
  setStyleToAllColumns: (key: string) => void;
  setStyleToAllHeadersAndColumns: (key: string) => void;
  copyStyle: (key: string) => void;
  pasteStyleOnHeader: (key: string) => void;
  pasteStyleOnColumn: (key: string) => void;
  pasteStyleOnHeaderAndColumn: (key: string) => void;
}

export interface TableSortIF {
  canSort?: boolean;
  canSortDisabledTooltip?: string;
  sortDirection?: string;
  sortLevel?: number;
  maxSortLevel?: number;
  sortByColumn?: (key: string, direction: string) => void;
}

export const TableBuilder: SeeqComponent<typeof tableBuilderBindings> = () => {
  const { sqTableBuilderActions, sqScorecardStore, sqInvestigateActions, sqFormula } =
    useInjectedBindings(tableBuilderBindings);

  const {
    columns,
    conditionTableColumns,
    headers,
    conditionTableData,
    simpleTableData,
    isTransposed,
    isMigrating,
    isTableStriped,
    fetchFailedMessage,
    itemFilters,
    itemSorts,
    chartViewSettings,
    distinctStringValueMap,
  } = useFlux(sqTableBuilderStore);

  const { displayRange } = useFlux(sqDurationStore);
  let tableBuilderRef = null;

  const { t } = useTranslation();

  const scorecardStore = useFlux(sqScorecardStore);
  const oldMetrics = scorecardStore.metrics;

  const isSimpleMode = useFluxPath(sqTableBuilderStore, () => sqTableBuilderStore.isSimpleMode());
  const showTableView = useFluxPath(sqTableBuilderStore, () => sqTableBuilderStore.showChartView);
  const isPresentationMode: boolean = isPresentationWorkbookMode();
  const isViewOnlyMode: boolean = isViewOnlyWorkbookMode();
  const canEdit = !isPresentationMode && !isViewOnlyMode;
  const { timezone } = useFlux(sqWorksheetStore);

  const isMigrationRequired = !_.isEmpty(oldMetrics);
  const allItems = sqTableBuilderStore.getTableItems();
  const startMode: boolean = allItems.length === 0;
  const isLoading: boolean = _.some(allItems, {
    dataStatus: ITEM_DATA_STATUS.LOADING,
  });

  const tableItems = sqTableBuilderStore.getTableItems();
  const canSort = sqTableBuilderStore.isUserSortAllowed() && !isPresentationMode;

  const hasOnlyStringSeries = _.chain(tableItems)
    .filter((item) => item.itemType === ITEM_TYPES.SERIES)
    .every((signal) => isStringSeries(signal))
    .value();
  const hasNumericAndStringSeries = hasNumericAndStringItems(tableItems, ITEM_TYPES.SERIES);
  const hasOnlyStringMetrics = _.chain(tableItems)
    .filter((item) => item.itemType === ITEM_TYPES.METRIC)
    .every((signal) => isStringSeries(signal))
    .value();
  const hasNumericAndStringMetrics = hasNumericAndStringItems(tableItems, ITEM_TYPES.METRIC);

  const fetchStringColumnValues = (columnKey, isStringColumn, DATA_CANCELLATION_GROUP) => {
    if (isStringColumn) {
      sqTableBuilderStore
        .getConditionTableStringColumnFetchParams(columnKey, sqFormula)
        .then(({ fetchParams, columnKeyAndName }) =>
          sqFormula.fetchTableDistinctStringValues(
            fetchParams,
            columnKeyAndName,
            'TABLE_BUILDER_SET_CONDITION_DISTINCT_STRING_VALUES',
            DATA_CANCELLATION_GROUP,
          ),
        );
    }
  };
  const setActiveRefElement = (el) => {
    tableBuilderRef = el;
  };
  const isTableEmpty =
    (!isSimpleMode && !conditionTableData.capsules.length) || (isSimpleMode && !simpleTableData.length);

  const textFormatter: TableTextFormatterIF = {
    setColumnBackground: sqTableBuilderActions.setColumnBackground,
    setColumnTextAlign: sqTableBuilderActions.setColumnTextAlign,
    setColumnTextColor: sqTableBuilderActions.setColumnTextColor,
    setColumnTextStyle: sqTableBuilderActions.setColumnTextStyle,
    setHeaderBackground: sqTableBuilderActions.setHeaderBackground,
    setHeaderTextAlign: sqTableBuilderActions.setHeaderTextAlign,
    setHeaderTextColor: sqTableBuilderActions.setHeaderTextColor,
    setHeaderTextStyle: sqTableBuilderActions.setHeaderTextStyle,
    setStyleToAllHeaders: sqTableBuilderActions.setStyleToAllHeaders,
    setStyleToAllColumns: sqTableBuilderActions.setStyleToAllColumns,
    setStyleToAllHeadersAndColumns: sqTableBuilderActions.setStyleToAllHeadersAndColumns,
    copyStyle: sqTableBuilderActions.copyStyle,
    pasteStyleOnHeader: sqTableBuilderActions.pasteStyleOnHeader,
    pasteStyleOnColumn: sqTableBuilderActions.pasteStyleOnColumn,
    pasteStyleOnHeaderAndColumn: sqTableBuilderActions.pasteStyleOnHeaderAndColumn,
  };

  return (
    <>
      <TableBuilderToolbar
        copyToClipboard={() => copyToClipboard(tableBuilderRef)}
        isTableEmpty={isTableEmpty}
        setChartViewSettings={sqTableBuilderActions.setChartViewSettings}
      />
      {isMigrationRequired && (
        <>
          {canEdit && (
            <div className="flexRowContainer flexCenter">
              <div className="alert alert-info fs16 p25 mt20 max-width-500 text-center">
                <p>{t('TABLE_BUILDER.MIGRATE')}</p>
                <TextButton
                  testId="migrateNowButton"
                  extraClassNames="mt10"
                  label="TABLE_BUILDER.MIGRATE_NOW"
                  disabled={isMigrating}
                  variant="theme"
                  onClick={sqTableBuilderActions.migrate}
                />
              </div>
            </div>
          )}
          <div className="overflowYAuto" ref={setActiveRefElement}>
            <TableBuilderOldMetricTable oldMetrics={oldMetrics} />
          </div>
        </>
      )}
      {!isMigrationRequired && (
        <TableBuilderPropsOnly
          conditionTableData={conditionTableData}
          isLoading={isLoading}
          headers={headers}
          conditionColumns={conditionTableColumns}
          isTransposed={isTransposed}
          isTableStriped={isTableStriped}
          isPresentationMode={isPresentationMode}
          canEdit={canEdit}
          timezone={timezone}
          setCellText={sqTableBuilderActions.setCellText}
          setHeaderText={sqTableBuilderActions.setHeaderText}
          setColumnFilter={sqTableBuilderActions.setColumnFilter}
          itemFilters={itemFilters}
          distinctStringValueMap={distinctStringValueMap}
          columnToThresholdsForCondition={sqTableBuilderStore.getColumnToThresholdsForCondition()}
          textFormatter={textFormatter}
          canSort={canSort}
          itemSorts={itemSorts}
          maxSortLevel={sqTableBuilderStore.maxSortLevel}
          moveColumn={sqTableBuilderActions.moveColumn}
          removeColumn={sqTableBuilderActions.removeColumn}
          displayMetricOnTrend={sqTableBuilderActions.displayMetricOnTrend}
          sortByColumn={sqTableBuilderActions.sortByColumn}
          simpleTableData={simpleTableData}
          simpleColumns={columns}
          isViewOnlyMode={isViewOnlyMode}
          displayRange={displayRange}
          columnToThresholdsForSimple={sqTableBuilderStore.getColumnToThresholdsForSimple()}
          hasOnlyStringSeries={hasOnlyStringSeries}
          hasNumericAndStringSeries={hasNumericAndStringSeries}
          hasOnlyStringMetrics={hasOnlyStringMetrics}
          hasNumericAndStringMetrics={hasNumericAndStringMetrics}
          startMode={startMode}
          fetchFailedMessage={fetchFailedMessage}
          fetchStringColumnValues={fetchStringColumnValues}
          setActiveRefElement={setActiveRefElement}
          setActiveTool={sqInvestigateActions.setActiveTool}
          isSimpleMode={isSimpleMode}
          showChartView={showTableView}
          chartViewSettings={{
            ...chartViewSettings,
            showSettings: !headlessRenderMode() && chartViewSettings.showSettings,
            showHeader: !headlessRenderMode(),
          }}
          setChartViewSettings={sqTableBuilderActions.setChartViewSettings}
        />
      )}
    </>
  );
};
