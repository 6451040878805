// @ts-strict-ignore
import { BROWSER_LANG } from '@/core/coreConfiguration.module';
import { trackDateParse } from '@/track/track.service';
import _ from 'lodash';
import moment from 'moment-timezone';

/**
 * Track a date parsing attempt.
 *
 * @param {String} field - Identifying string for the input field
 * @param {Moment} originalDate - The previous Moment that was being replaced.
 * @param {Moment} originalPairDate - The previous Moment for the paired field, or undefined if there is
 *   no pair
 * @param {String} enteredString - The string that was entered and parsed.
 * @param {Moment} result - Moment object that was parsed from the string, or undefined if parsing was
 *   not successful.
 * @param {Moment} pairResult - Moment object that was parsed from the string for the paired field, or
 *   undefined if the result did not include a value for the pair
 * @param {String} parser - Name of the parsing function that was successful, or undefined if parsing was not
 *   successful.
 * @param {Object} timezone - Timezone used for parsing.
 */
export function dateParse(field, originalDate, originalPairDate, enteredString, result, pairResult, parser, timezone) {
  const success = !_.isUndefined(result) && moment.isMoment(result) && result.isValid();
  trackDateParse({
    field,
    originalDate,
    originalPairDate,
    enteredString,
    newDate: success ? result : undefined,
    newPairDate: success ? pairResult : undefined,
    success,
    parser: success ? parser : undefined,
    tz: timezone.name,
    lang: BROWSER_LANG,
    locale: moment.locale(),
  });
}
