// @ts-strict-ignore
import React from 'react';
import { Placement } from 'react-bootstrap/Overlay';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

interface ToolbarDropdownTitleProps {
  /** icon class to be used with the dropdown (i.e. 'fc-zoom') */
  icon: string;
  /** label for the toolbar item (shown only when toolbar is wide enough, pass in the translation key) */
  label: string;
  /** text to be displayed on the icon's tooltip (pass in the translation key) */
  tooltipText?: string;
  tooltipPlacement?: Placement;
  /** true to render an icon-only dropdown button */
  isSmall?: boolean;
  testId?: string;
  onClick?: () => void;
}

/**
 * Toolbar dropdown title, for use by ToolbarDropdownButton
 */
export const ToolbarDropdownTitle: React.FunctionComponent<ToolbarDropdownTitleProps> = ({
  icon,
  label,
  tooltipText,
  tooltipPlacement = 'top',
  isSmall = false,
  testId,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <HoverTooltip placement={tooltipPlacement} text={tooltipText}>
      <span className="flexRowContainer flexAlignCenter" data-testid={testId} onClick={onClick}>
        <span className="nowrap">
          <Icon icon={icon} type="text" />
          <Icon icon="fa-caret-down" extraClassNames="pl3 small" type="text" />
        </span>
        {!isSmall && <small>{t(label)}</small>}
      </span>
    </HoverTooltip>
  );
};
