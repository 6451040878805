// @ts-strict-ignore
import { FixedSizeList } from 'react-window';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { AddToDisplayPane } from '@/hybrid/workbooks/AddToDisplayPane.molecule';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { sqItemsApi } from '@/sdk';

import { TrendActions } from '@/trendData/trend.actions';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { sqInvestigateStore } from '@/core/core.stores';
import { isViewOnlyWorkbookMode } from '@/hybrid/utilities/utilities';

const derivedDataTreeBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqTrendActions: injected<TrendActions>(),
});

const Row = ({ index, style, data }) => {
  const { derivedDataTree, openItemProperties, renderItemName } = data;
  const item = derivedDataTree[index];
  return (
    <div className="flexColumnContainer" data-testid="specDerivedDataRow" style={style} key={item.trackId}>
      {_.map(item.spacers, (node) => (
        <div
          key={node.id}
          className={classNames('spacer flexNoGrowNoShrink', {
            notLast: !node.isLast,
          })}
        />
      ))}
      {item.depth > 0 && !item.isLast && <div className="flexNoGrowNoShrink notLastChild" />}
      <div
        className={classNames('flexNoGrowNoShrink', {
          isChild: item.depth > 0,
          isLast: item.isLast,
        })}
      />
      <Icon
        icon={item.iconClass}
        extraClassNames="flexNoGrowNoShrink itemIcon ml5"
        type="color"
        color={item.color || 'gray'}
      />
      <AddToDisplayPane
        itemId={item.id}
        tooltipPlacement="right"
        afterAdd={openItemProperties}
        extraClassNames="flexNoGrowNoShrink"
      />
      {item.isArchived && (
        <Icon
          id="specInfoTrashIcon"
          icon="fa-info-circle"
          large={true}
          type="danger"
          extraClassNames="flexNoGrowNoShrink pt3 pl4"
          tooltip="TRASH.ITEM_TRASHED_CLICK_TO_SEE_DETAILS"
          tooltipPlacement="top"
          onClick={() => openItemProperties(item.id)}
        />
      )}
      {renderItemName(item.name, item.id)}
    </div>
  );
};

export const DerivedDataTree: SeeqComponent<typeof derivedDataTreeBindings> = () => {
  const { sqInvestigateActions, sqTrendActions } = useInjectedBindings(derivedDataTreeBindings);

  const derivedDataTree = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.derivedDataTree);

  const isLoading = _.isNil(derivedDataTree);

  /**
   * Add the specified item to the trend and open it on the Item Properties pane
   *
   * @param {String} itemId - ID of the item to open
   */
  const openItemProperties = (itemId: string) =>
    sqItemsApi
      .getItemAndAllProperties({ id: itemId })
      .then(({ data: item }) => sqTrendActions.addItem(item))
      .then(() => {
        sqInvestigateActions.setActiveTool(TREND_TOOLS.PROPERTIES);
        sqInvestigateActions.setItem(itemId);
      });

  const renderItemName = (itemName: string, itemId: string) => {
    if (isViewOnlyWorkbookMode()) {
      return <span className="derivedDataItem flexNoGrowNoShrink pl4 pr5">{itemName}</span>;
    }

    return (
      <a
        className="a derivedDataItem flexNoGrowNoShrink pl4 pr5 force-link-look"
        onClick={() => sqInvestigateActions.loadToolForEdit(itemId)}>
        <HoverTooltip text="DERIVED_DATA.EDIT_TOOLTIP" delay={1000} placement="top">
          <span>{itemName}</span>
        </HoverTooltip>
      </a>
    );
  };

  const rowHeight = 18.56;

  return (
    <div className="specDerivedDataTree width-maximum">
      {derivedDataTree?.length > 0 && (
        <div className="derivedDataTreeDisplay width-maximum" data-testid="items">
          <FixedSizeList
            height={_.min([240, derivedDataTree.length * rowHeight])}
            itemCount={derivedDataTree?.length || 0}
            itemSize={rowHeight}
            itemData={{
              derivedDataTree,
              renderItemName,
              openItemProperties,
            }}
            width="100%">
            {Row}
          </FixedSizeList>
        </div>
      )}
      {isLoading && <IconWithSpinner testId="loading" spinning={true} extraClassNames="sq-text-primary ml5" />}
    </div>
  );
};

export const sqDerivedDataTree = angularComponent(derivedDataTreeBindings, DerivedDataTree);
