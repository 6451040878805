// @ts-strict-ignore
import SelectItem from '@/hybrid/core/SelectItem.organism';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface ItemSelectProps {
  item: any;
  isUserCreatedType: boolean;
  setItem: (item) => void;
  setArchived: (archived: boolean) => void;
}

export const ItemSelect: React.FunctionComponent<ItemSelectProps> = ({
  item,
  isUserCreatedType,
  setItem,
  setArchived,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flexColumnContainer flexCenter mb10" data-testid="selectItem">
      <label className="pr5 text-nowrap">{t('PROPERTIES.SELECT_ITEM')}</label>
      <div className="flexFill">
        <SelectItem selectedItemId={item.id} disableAutoSelect={true} excludeWorkbookItems={true} onSelect={setItem} />
        {item.isArchived && isUserCreatedType && (
          <a
            className="link-no-underline cursorPointer"
            id="specSelectedItemTrash"
            data-testid="specSelectedItemTrash"
            onClick={(e) => {
              e.preventDefault();
              setArchived(false);
            }}>
            <span className="sq-text-danger">{t('TRASH.ITEM_TRASHED_RESTORABLE')}</span>
          </a>
        )}

        {item.isArchived && !isUserCreatedType && <span className="sq-text-danger">{t('TRASH.ITEM_TRASHED')}</span>}
      </div>
    </div>
  );
};
