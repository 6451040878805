import React from 'react';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';

interface DensityPlotLegendProps {}

/**
 * Used in the DensityPlot component to render the legend for coloring by time.
 */
export const DensityPlotLegend: React.FunctionComponent<DensityPlotLegendProps> = () => {
  const { t } = useTranslation();

  const legendText = t('DENSITY_PLOT.LEGEND_TIME');
  const legendTitle = t('DENSITY_PLOT.LEGEND_TITLE');

  return (
    <div className="densityPlotLegend mb10 ml25 mr25 sqGrayBox pl10 pr10 pt0 pb0">
      <strong className="mr10">{legendTitle}:</strong>
      <span>{legendText}</span>
    </div>
  );
};
