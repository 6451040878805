import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ToolbarButton } from '@/hybrid/core/ToolbarButton.molecule';
import { useAllTrendStoreItems } from '@/hybrid/core/hooks/useAllTrendStoreItems.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { TREND_VIEWS } from '@/trendData/trendData.constants';
import { sqTrendCapsuleStore, sqTrendStore, sqWorkbookStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';
import { canWriteItem } from '@/services/authorization.service';
import { isItemRedacted } from '@/hybrid/utilities/redaction.utilities';

const annotateButtonBindings = bindingsDefinition({
  isSmall: prop<boolean>(),
  sqAnnotationActions: injected<AnnotationActions>(),
});

export const AnnotateButton: SeeqComponent<typeof annotateButtonBindings> = ({ isSmall }) => {
  const { sqAnnotationActions } = useInjectedBindings(annotateButtonBindings);

  const items = useAllTrendStoreItems();
  const capsuleStoreItems = useFluxPath(sqTrendCapsuleStore, () => sqTrendCapsuleStore.items);
  const isCapsuleTime = useFluxPath(sqTrendStore, () => sqTrendStore.view === TREND_VIEWS.CAPSULE);
  const isRegionSelected = useFluxPath(sqTrendStore, sqTrendStore.isRegionSelected);

  const isAnnotateEnabled = () => {
    const selectedItems = _.filter(items, 'selected');
    const isRegionSelectedAndAllowed = isRegionSelected && canWriteItem(sqWorkbookStore);
    return (
      (_.some(selectedItems) || _.some(capsuleStoreItems, 'selected') || isRegionSelectedAndAllowed) &&
      !isCapsuleTime &&
      !_.some(selectedItems, (item) => isItemRedacted(item))
    );
  };

  const getAnnotateButtonTooltip = () => {
    if (isAnnotateEnabled()) {
      return canWriteItem(sqWorkbookStore) ? 'TOOLBAR.ANNOTATE_ENABLED' : 'TOOLBAR.ANNOTATE_ENABLED_READ_ONLY';
    } else if (isCapsuleTime) {
      return 'TOOLBAR.ANNOTATE_DISABLED_CAPSULE';
    } else {
      return canWriteItem(sqWorkbookStore) ? 'TOOLBAR.ANNOTATE_DISABLED' : 'TOOLBAR.ANNOTATE_DISABLED_READ_ONLY';
    }
  };

  return (
    <ToolbarButton
      icon={classNames('fc-annotate', { 'fa-lg': !isSmall })}
      label="TOOLBAR.ANNOTATE"
      isSmall={isSmall}
      disabled={!isAnnotateEnabled()}
      id="annotateBtn"
      testId="annotateBtn"
      tooltipText={getAnnotateButtonTooltip()}
      tooltipPlacement="top"
      onClick={() => {
        if (isAnnotateEnabled()) {
          sqAnnotationActions.newAnnotation();
          doTrack('Trend', 'Annotate');
        }
      }}
    />
  );
};
