// @ts-strict-ignore
import React from 'react';
import { Modal } from 'react-bootstrap';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { StateSynchronizerService } from '@/services/stateSynchronizer.service';
import { APP_STATE, HOME_SCREEN_TABS } from '@/main/app.constants';
import { generateTabHash } from '@/hybrid/utilities/utilities';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';
import _ from 'lodash';
import { TextButton } from '../core/TextButton.atom';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { canWriteItem } from '@/services/authorization.service';

const workbookAccessWarningModalBindings = bindingsDefinition({
  workbook: prop<{ name: string; workbookId: string }>(),
  isReportBinder: prop<boolean>(),
  onClose: prop<() => void>(),
  $state: injected<ng.ui.IStateService>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqStateSynchronizer: injected<StateSynchronizerService>(),
});

export const WorkbookAccessWarningModal: SeeqComponent<typeof workbookAccessWarningModalBindings> = ({
  workbook,
  isReportBinder,
  onClose,
}) => {
  const { $state, sqHomeScreenActions, sqStateSynchronizer } = useInjectedBindings(workbookAccessWarningModalBindings);

  const { t } = useTranslation();

  const canRestoreWorkbook = canWriteItem(workbook);

  /**
   * Restores a workbook and optionally redirects the user to the workbook explorer
   */
  const restore = () => {
    onClose();

    return sqHomeScreenActions
      .restoreWorkbook(workbook)
      .then(() => {
        return $state
          .go(APP_STATE.WORKBOOKS, { t: generateTabHash(HOME_SCREEN_TABS.MY_FOLDER) }, { reload: true })
          .then(() => {
            requestAnimationFrame(() =>
              successToast({
                messageKey: 'TRASH.ITEM_RESTORED_NOTIFICATION',
                messageParams: { ITEM_NAME: workbook.name },
              }),
            );
          });
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  };

  /**
   * Duplicates a workbook and redirects the user to the workbook explorer
   */
  const copy = () => {
    onClose();
    return sqHomeScreenActions
      .addWorkbook({
        name: `${workbook.name} - ${t('COPY')}`,
        addNewWorksheet: false,
        branchFrom: workbook.workbookId,
        isReportBinder,
        folderId: $state.params.currentFolderId,
      })
      .then((item) => {
        const worksheetId = _.get(item, 'worksheets[0].worksheetId');
        // set loading worksheet so that differences between View only and Edit worksheet states are ignored
        sqStateSynchronizer.setLoadingWorksheet(item.id, worksheetId);
        $state.go(APP_STATE.WORKSHEET, {
          workbookId: item.id,
          worksheetId,
          currentFolderId: '',
        });
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      animation={false}
      data-testid="workbookAccessWarningModal"
      contentClassName="width-390 marginAuto">
      <Modal.Header closeButton={true}>
        <h4>{t('WORKBOOK_ACCESS_WARNING_MODAL.TITLE')}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center form-group">
          {t('TRASH.ITEM_TRASHED')}
          {t(
            canRestoreWorkbook
              ? 'WORKBOOK_ACCESS_WARNING_MODAL.YES_RESTORE_PERMISSION'
              : 'WORKBOOK_ACCESS_WARNING_MODAL.NO_RESTORE_PERMISSION',
          )}
          <div>
            {canRestoreWorkbook ? (
              <TextButton extraClassNames="mt12" variant="theme" label="RESTORE" onClick={restore} />
            ) : (
              <TextButton
                extraClassNames="mt12"
                variant="theme"
                label="WORKBOOK_ACCESS_WARNING_MODAL.DUPLICATE"
                onClick={copy}
              />
            )}

            <TextButton extraClassNames="mt12 ml5" label="WORKBOOK_ACCESS_WARNING_MODAL.VIEW" onClick={onClose} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const sqWorkbookAccessWarningModal = angularComponent(
  workbookAccessWarningModalBindings,
  WorkbookAccessWarningModal,
);
