import React from 'react';
import classNames from 'classnames';
import { Icon } from '@/hybrid/core/Icon.atom';

interface JournalReplyIconProps {
  small?: boolean;
  count: number;
}

export const JournalReplyIcon: React.FunctionComponent<JournalReplyIconProps> = (props) => {
  const { count, small } = props;
  return (
    <div
      data-testid="specJournalReplyIcon"
      className={classNames('flexColumnContainer flexCenter', {
        'sq-text-primary': !!count,
        'sq-fairly-dark-gray': !count,
      })}>
      <span className={classNames('specCommentCount', { xsmall: !!small })}>{count}</span>
      <Icon testId="specCommentIcon" icon={classNames('ml3 fc fc-comment', { xsmall: !!small })} />
    </div>
  );
};
