// @ts-strict-ignore
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import tinycolor from 'tinycolor2';
import { DragObjectWithType, useDrop } from 'react-dnd';
import { MINIMAP_HANDLE_LOWER, MinimapHandleLane } from '@/hybrid/scatterPlot/minimap/MinimapHandleLane.molecule';
import { ScatterPlotMinimapChart } from '@/hybrid/scatterPlot/minimap/MinimapChart.molecule';
import { useDebounce } from '@/hybrid/core/hooks/useDebounce.hook';
import { DEBOUNCE } from '@/core/core.constants';
import { useStateWithRef } from '@/hybrid/core/hooks/useStateWithRef.hook';
import useResizableHighchart from '@/hybrid/core/hooks/useResizableHighchart.hook';
import { ScatterPlotMinimapProps } from '@/hybrid/scatterPlot/minimap/Minimap.molecule';
import { useForceUpdate } from '@/hybrid/core/hooks/useForceUpdate.hook';
import { MINIMAP_OPACITY, SCATTER_PLOT_COLORS } from '@/scatterPlot/scatterPlot.constants';

export const MINIMAP_HANDLE_DRAG_SOURCE_TYPE = 'scatterPlotMinimapHandle';

/**
 * Displays the minimap, including the chart and the draggable handles.
 * Contains data/functions common to the chart and the handles.
 * Is the DropTarget for dragging the handles.
 */
export const MinimapInner: React.FunctionComponent<ScatterPlotMinimapProps> = ({
  displayRange,
  xValue,
  plotSeries,
  regions,
  selectorLow,
  selectorHigh,
  setSelectors,
  useSelectors,
  backgroundColor,
  isContent,
}) => {
  const chartElementRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [lowerMiddleDragX, setLowerMiddleDragX] = useStateWithRef(undefined);
  const [middleUpperDragX, setMiddleUpperDragX] = useStateWithRef(undefined);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    setLowerMiddleDragX(undefined);
    setMiddleUpperDragX(undefined);
    updateBoxes();
  }, [selectorLow, selectorHigh]);

  useEffect(() => {
    updateBoxes();
  }, [regions, useSelectors, chart]);

  useResizableHighchart({
    chart,
    chartElementRef,
    setChart,
    reflowChartCallback: forceUpdate,
    reflowWait: DEBOUNCE.SHORT,
  });

  /**
   * Calculate the current x-pixel locations for the different regions of the minimap.
   *
   * @returns {{lowerSlider: *, upperSlider: *, rangeStart: *, rangeEnd: *}} Object containing x-pixel locations
   */
  const getXLocations = useCallback(() => {
    const rangeStartX = displayRange.start;
    const rangeEndX = displayRange.end;
    const sliderWidth = Math.max(0, rangeEndX - rangeStartX);

    return {
      lowerSlider: _.isFinite(lowerMiddleDragX.current)
        ? lowerMiddleDragX.current
        : rangeStartX + sliderWidth * selectorLow,
      upperSlider: _.isFinite(middleUpperDragX.current)
        ? middleUpperDragX.current
        : rangeStartX + sliderWidth * selectorHigh,
      rangeStart: rangeStartX,
      rangeEnd: rangeEndX,
    };
  }, [displayRange.start, displayRange.end, selectorLow, selectorHigh, lowerMiddleDragX.current, middleUpperDragX]);

  /**
   * Updates the minimap boxes that encompass time-ranges.
   */
  const updateBoxes = useCallback(() => {
    if (_.isEmpty(chart)) {
      return;
    }
    const colorRegions = regions || [];
    let plotBands = [];

    const xLocations = getXLocations();
    const crossedSliders = xLocations.upperSlider <= xLocations.lowerSlider;

    if (useSelectors && _.isFinite(selectorLow) && _.isFinite(selectorHigh)) {
      if (xLocations.lowerSlider > xLocations.rangeStart) {
        // Use the correct boundaries when the middle region has disappeared
        const to = !crossedSliders ? xLocations.lowerSlider : xLocations.upperSlider;
        plotBands.push({
          from: xLocations.rangeStart,
          to,
          color: tinycolor(SCATTER_PLOT_COLORS.LOW).setAlpha(MINIMAP_OPACITY).toString(),
          id: 'lowBand',
        });
      }
      if (!crossedSliders) {
        plotBands.push({
          from: xLocations.lowerSlider,
          to: xLocations.upperSlider,
          color: tinycolor(SCATTER_PLOT_COLORS.MID).setAlpha(MINIMAP_OPACITY).toString(),
          id: 'midBand',
        });
      }
      if (xLocations.rangeEnd > xLocations.upperSlider) {
        // Use the correct boundaries when the middle region has disappeared
        const from = !crossedSliders ? xLocations.upperSlider : xLocations.lowerSlider;
        plotBands.push({
          from,
          to: xLocations.rangeEnd,
          color: tinycolor(SCATTER_PLOT_COLORS.HIGH).setAlpha(MINIMAP_OPACITY).toString(),
          id: 'highBand',
        });
      }
    }

    plotBands = plotBands.concat(
      _.chain(colorRegions)
        .sortBy('opacity')
        .reverse()
        .map((region) => ({
          from: region.start,
          to: region.end,
          color: tinycolor(region.color).setAlpha(MINIMAP_OPACITY).toString(),
          id: region.id,
        }))
        .value(),
    );

    while (chart.xAxis[0].plotLinesAndBands.length > 0) {
      chart.xAxis[0].plotLinesAndBands[0].destroy();
    }
    _.forEach(plotBands, (plotBand) => chart.xAxis[0].addPlotBand(plotBand));
  }, [chart, getXLocations, useSelectors, selectorLow, selectorHigh, regions]);

  /**
   * On dragging of the lower-middle region slider.
   */
  const dragMoveLowerMiddle = useCallback(
    (x) => {
      if (_.isEmpty(chart)) {
        return;
      }
      let xOnAxis = chart.xAxis[0].toValue(x);
      const xLocations = getXLocations();
      xOnAxis = _.clamp(xOnAxis, xLocations.rangeStart, xLocations.rangeEnd);
      setLowerMiddleDragX(xOnAxis);
      if (xLocations.upperSlider < xOnAxis) {
        setMiddleUpperDragX(xOnAxis);
      }
      updateBoxes();
      debouncedDragEnd();
    },
    [chart, getXLocations, updateBoxes],
  );

  /**
   * On dragging of the middle-upper region slider.
   */
  const dragMoveMiddleUpper = useCallback(
    (x) => {
      if (_.isEmpty(chart)) {
        return;
      }
      let xOnAxis = chart.xAxis[0].toValue(x);
      const xLocations = getXLocations();
      xOnAxis = _.clamp(xOnAxis, xLocations.rangeStart, xLocations.rangeEnd);
      setMiddleUpperDragX(xOnAxis);
      if (xLocations.lowerSlider > xOnAxis) {
        setLowerMiddleDragX(xOnAxis);
      }
      updateBoxes();
      debouncedDragEnd();
    },
    [chart, getXLocations, updateBoxes],
  );

  /**
   * On stop dragging of one of the sliders.
   */
  const dragEnd = useCallback(() => {
    const rangeStartX = displayRange.start;
    const rangeEndX = displayRange.end;
    const widthX = rangeEndX - rangeStartX;
    let newLow, newHigh;

    // calculate breakpoints as percentage of the whole range
    newLow = _.isFinite(lowerMiddleDragX.current) ? (lowerMiddleDragX.current - rangeStartX) / widthX : selectorLow;
    newHigh = _.isFinite(middleUpperDragX.current) ? (middleUpperDragX.current - rangeStartX) / widthX : selectorHigh;
    setSelectors(newLow, newHigh);
  }, [displayRange.start, displayRange.end, lowerMiddleDragX, middleUpperDragX, selectorLow, selectorHigh]);

  const debouncedDragEnd = useDebounce(() => dragEnd(), DEBOUNCE.LONG);

  const [, drop] = useDrop(
    {
      accept: MINIMAP_HANDLE_DRAG_SOURCE_TYPE,
      drop: (item: DragObjectWithType & { id: string; left: number }, monitor) => {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        if (item.id === MINIMAP_HANDLE_LOWER) {
          dragMoveLowerMiddle(left);
        }
        dragEnd();
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - this is a hook that accepts a second argument, but ts doesn't think it does
    [dragMoveLowerMiddle, dragMoveMiddleUpper, dragEnd],
  );

  return (
    <div className="flexFillOverflow flexRowContainer flexColumnContainer pl25 pr15 mb5" ref={drop}>
      {chartElementRef.current && !isContent && (
        <MinimapHandleLane
          chart={chart}
          getXLocations={getXLocations}
          dragMoveLowerMiddle={dragMoveLowerMiddle}
          dragMoveMiddleUpper={dragMoveMiddleUpper}
          useSelectors={useSelectors}
        />
      )}
      <ScatterPlotMinimapChart
        chart={chart}
        setChart={setChart}
        chartElementRef={chartElementRef}
        trendStart={displayRange.start}
        trendEnd={displayRange.end}
        xValue={xValue}
        plotSeries={plotSeries}
        selectorLow={selectorLow}
        selectorHigh={selectorHigh}
        updateBoxes={updateBoxes}
        backgroundColor={backgroundColor}
      />
    </div>
  );
};
