// @ts-strict-ignore
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { APPSERVER_API_CONTENT_TYPE } from '@/main/app.constants';
import {
  cancellationRequestInterceptor,
  cancellationResponseErrorInterceptor,
  cancellationResponseInterceptor,
} from '@/hybrid/requests/interceptors/requestCancellation.interceptor';
import {
  asyncHttpRequestInterceptor,
  asyncHttpResponseInterceptor,
  asyncResponseErrorInterceptor,
} from '@/hybrid/requests/interceptors/async.interceptor';
import { postInterceptor } from '@/hybrid/requests/interceptors/post.interceptor';
import {
  authenticationRequestInterceptor,
  authenticationResponseInterceptor,
} from '@/hybrid/requests/interceptors/authentication.interceptor';
import { requestOriginInterceptor } from '@/hybrid/requests/interceptors/requestOrigin.interceptor';
import { sessionIdInterceptor } from '@/hybrid/requests/interceptors/sessionId.interceptor';
import { handleInterceptError } from '@/hybrid/requests/interceptors/error.interceptor';
import { versionInterceptor } from '@/hybrid/requests/interceptors/version.interceptor';
import { conflictRetryInterceptor } from '@/hybrid/requests/interceptors/retry.interceptor';
import HttpCodes from 'http-status-codes';
import { addRequest, removeRequest } from './axios.actions';
import { sqAxiosStore } from '@/core/core.stores';

const axiosInstance = axios.create({});
axiosInstance.defaults.headers.common['Accept'] = APPSERVER_API_CONTENT_TYPE;
axiosInstance.interceptors.request.use(postInterceptor, handleInterceptError);
axiosInstance.interceptors.request.use(authenticationRequestInterceptor, handleInterceptError);
axiosInstance.interceptors.request.use(requestOriginInterceptor, handleInterceptError);
axiosInstance.interceptors.request.use(sessionIdInterceptor, handleInterceptError);
axiosInstance.interceptors.request.use(asyncHttpRequestInterceptor, handleInterceptError);
axiosInstance.interceptors.request.use(cancellationRequestInterceptor, handleInterceptError);
axiosInstance.interceptors.request.use(incrementPendingRequests, decrementPendingRequestsError);

// response interceptor first callback is if status is <=200, second callback to handle errors
axiosInstance.interceptors.response.use((response) => response, authenticationResponseInterceptor);
axiosInstance.interceptors.response.use(versionInterceptor, handleInterceptError);
axiosInstance.interceptors.response.use((response) => response, conflictRetryInterceptor);
axiosInstance.interceptors.response.use((response) => response, handleInterceptError);
axiosInstance.interceptors.response.use(asyncHttpResponseInterceptor, asyncResponseErrorInterceptor);
axiosInstance.interceptors.response.use(cancellationResponseInterceptor, cancellationResponseErrorInterceptor);
axiosInstance.interceptors.response.use(decrementPendingRequests, decrementPendingRequestsError);

/**
 * Exposes an instance of our properly configured axios-instance to the SDK
 */
export function getAxiosInstance() {
  return axiosInstance;
}

function decrementPendingRequests(response) {
  removeRequest();
  return Promise.resolve(response);
}

function incrementPendingRequests(config) {
  addRequest();
  return config;
}

function decrementPendingRequestsError(error) {
  removeRequest();
  return Promise.reject(error);
}

export function getPendingRequestCount() {
  return sqAxiosStore.pendingRequests;
}

/**
 * In order for Notifications to properly display we need to ensure we return a proper Error.
 * This functions extracts a proper Error from the axios error that is returned from http Requests that are
 * unsuccessful.
 */
export function extractError(errorOrResponse) {
  if (errorOrResponse?.response?.status >= HttpCodes.BAD_REQUEST) {
    return new Error(errorOrResponse.response.data?.statusMessage || errorOrResponse.response.data);
  }
  if (errorOrResponse.request && !errorOrResponse.data) {
    return new Error(errorOrResponse.request);
  }
  if (errorOrResponse.config && !errorOrResponse.data) {
    return new Error(errorOrResponse.config);
  }
  return errorOrResponse?.data?.statusMessage || errorOrResponse;
}

/**
 * Extracts the "returnTo" portion from the link for proper redirection - this function is likely short lived and
 * will replaced once we have React based routing
 */
export function getReturnToLink() {
  const urlParams = new URLSearchParams(window.location.search);
  const returnTo = urlParams.get('returnTo');
  if (returnTo) {
    return returnTo;
  }
  return undefined;
}

export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError)?.isAxiosError;
}

export interface SeeqAxiosRequestConfig extends AxiosRequestConfig {
  cancellationGroup?: string;
  cancelOnServer?: boolean;
  useManualAsync?: boolean;
  ignoreLoadingBar?: boolean;
}

export interface SeeqAxiosResponse extends AxiosResponse {
  config: SeeqAxiosRequestConfig;
}
