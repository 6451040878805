// @ts-strict-ignore
import { prepareDragResize } from '@/hybrid/utilities/utilities';
import { THROTTLE } from '@/core/core.constants';
import _ from 'lodash';

/**
 * Computes the height of a child element as a percentage of the container height. Rounds to the nearest tenth.
 *
 * @param {Number} childHeight - Height of child in pixels
 * @param {Number} containerHeight - Height of child's container in pixels
 */
export const getPercentHeight = (childHeight: number, containerHeight: number) =>
  !childHeight || !containerHeight ? 0 : Math.round((childHeight / containerHeight) * 100 * 10) / 10;

/**
 * Calculate the minimum height in a percentage that the view can be set to where just the headers of the panels
 * are visible to create the appearance that the panels are pinned to the bottom.
 *
 * @param {Number} parentHeight - Height of parent in pixels
 *
 * @return {Number} - minimum percentage of the parentHeight
 */
export const minPanelHeight = (parentHeight: number) => {
  const DRAG_BAR_PADDING = 2;
  // Assuming that the series panel is always shown we can use it to measure the header
  const dragBarHeight =
    document.querySelector<HTMLDivElement>('[data-testid="detailsPanel"] .header')?.offsetHeight ||
    0 + DRAG_BAR_PADDING;
  const dragBarHeightPercent = getPercentHeight(dragBarHeight, parentHeight);

  return dragBarHeightPercent;
};

export const PANEL_HEADER_OFFSET = 69;
export const getMenuTop = (additionalOffset: number) => {
  // Constrain offset so regardless of additional offset, it is never above the panel title bar
  const offset = Math.max(PANEL_HEADER_OFFSET, PANEL_HEADER_OFFSET + additionalOffset);

  return `${document.querySelector('.bottomPanel').getBoundingClientRect().top + offset}px`;
};

/**
 * Helper function that keeps dropdown menus usable by making them scroll instead of displaying off screen.
 */
export const adjustDropdownMenus = () => {
  let capsuleSignalMenus, tableScrollOffset: number;
  const TABLE_HEADER_OFFSET = 25;
  const bottomPanelHeight = document.querySelector('.bottomPanel').getBoundingClientRect().height;

  // Adjust the capsule table signal dropdown menus
  capsuleSignalMenus = document.querySelector('.bottomPanel .tableWrapper .scrollable-menu');

  if (capsuleSignalMenus?.length) {
    tableScrollOffset = _.get(document.querySelectorAll('#capsulesPanel .tableWrapper'), '0.scrollTop', 0);
    capsuleSignalMenus.css('top', getMenuTop(TABLE_HEADER_OFFSET - tableScrollOffset));
    capsuleSignalMenus.css('max-height', bottomPanelHeight + tableScrollOffset - TABLE_HEADER_OFFSET);
  }
};

type ResizePanelStartArgs = {
  e: React.MouseEvent<HTMLDivElement, MouseEvent>;
  parentHeight: number;
  updatePanelProps: (newPercent: number) => void;
  bottomPanelsElm: HTMLDivElement;
};

/**
 * Handles resizing the bottom panel when the user drags the header. Should be invoked by using ng-mousedown on the
 * element that will allow for dragging.
 */
export const resizePanelStart = ({ e, parentHeight, updatePanelProps, bottomPanelsElm }: ResizePanelStartArgs) => {
  const throttledResizePanel = _.throttle(resizePanel, THROTTLE.SHORT);
  let startY = e.pageY;

  prepareDragResize(e as any, throttledResizePanel);

  /**
   * Figure out how much the mouse has moved and then use that to calculate the new height of the bottom panel. That
   * height is then converted to a percentage height rounded to the nearest 10th. That level of rounding allows the
   * size to change smoothly while also letting us know when the mouse has moved far enough that the startY needs to
   * be updated. To prevent the draggable control from being hidden we don't allow the percentage to drop below the
   * height of the drag bar.
   */
  function resizePanel(e) {
    const yDelta = e.pageY - startY;
    const bottomPanelHeight = bottomPanelsElm.getBoundingClientRect().height;
    const currentPercent = getPercentHeight(bottomPanelHeight, parentHeight);
    let newPercent = getPercentHeight(bottomPanelHeight - yDelta, parentHeight);
    if (newPercent < minPanelHeight(parentHeight)) {
      newPercent = 0;
    }

    if (newPercent !== currentPercent) {
      startY = e.pageY;
    }

    updatePanelProps(newPercent);

    adjustDropdownMenus();
  }
};

type ConstrainLeftPanelWidthArgs = {
  // the desired left panel width
  leftPanelWidth: number;
  showCapsulesPanel: boolean;
  bottomPanelsElm: HTMLDivElement;
};

/**
 * Sets the left panel display width to be the supplied value, or constrains it to be within the available space
 * if the bottom panel width does not allow enough horizontal space for the supplied width to be set.
 */
export const constrainLeftPanelWidth = ({
  leftPanelWidth,
  showCapsulesPanel,
  bottomPanelsElm,
}: ConstrainLeftPanelWidthArgs) => {
  if (!bottomPanelsElm) {
    return;
  }

  const bottomPanelWidth = bottomPanelsElm.getBoundingClientRect().width;
  const minPanelWidth = bottomPanelWidth > 650 ? 355 : 250; // Allow smaller width to support 1024x768 resolution

  if (!showCapsulesPanel) {
    return bottomPanelWidth;
  }
  if (leftPanelWidth >= minPanelWidth && leftPanelWidth <= bottomPanelWidth - minPanelWidth) {
    return leftPanelWidth;
  }
  if (leftPanelWidth < minPanelWidth || bottomPanelWidth < minPanelWidth * 2) {
    return minPanelWidth;
  }

  return bottomPanelWidth - minPanelWidth;
};

interface ResizeDividerStartArgs {
  e: React.MouseEvent<HTMLDivElement, MouseEvent>;
  startPanelWidth: number;
  constrainAndUpdatePanelProps: (width: number) => void;
}

/**
 * Handles resizing the bottom panel divider when the user drags the divider.
 * Should be invoked by using ng-mousedown on the element that will allow for dragging.
 *
 * @param {Object} e - The event
 */
export const resizeDividerStart = ({ e, startPanelWidth, constrainAndUpdatePanelProps }: ResizeDividerStartArgs) => {
  const throttledResizeDivider = _.throttle(resizeDivider, THROTTLE.SHORT);
  const startX = e.pageX;

  prepareDragResize(
    e as any,
    throttledResizeDivider,
    // Force ew-resize cursor during resize
    () => document.body.classList.add('globalCursorEastWest'),
    // Clear ew-resize cursor
    () => document.body.classList.remove('globalCursorEastWest'),
  );

  /**
   * Figure out how much the mouse has moved and then use that to calculate the new divider setting.
   *
   * @param {Object} e - The event
   */
  function resizeDivider(e) {
    const xDelta = e.pageX - startX;
    constrainAndUpdatePanelProps(startPanelWidth + xDelta);
  }
};

/**
 * Calculate the height of the panels from percentages
 *
 * @return {Number} - height in pixels
 */
export const panelHeight = (parentHeight: number, targetHeight: number) =>
  (Math.max(targetHeight, minPanelHeight(parentHeight)) / 100) * parentHeight;
