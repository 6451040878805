// @ts-strict-ignore
import React, { useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { addWorksheetToWorkbook, moveWorksheetInWorkbook } from '@/workbook/workbook.actions';
import { Icon } from '@/hybrid/core/Icon.atom';

import { APP_STATE } from '@/main/app.constants';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { ReadOnlyWorksheetPaneTile } from '@/hybrid/worksheets/ReadOnlyWorksheetPaneTile.molecule';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableWorksheetPaneTile from '@/hybrid/worksheets/DraggableWorksheetPaneTile.molecule';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import { sqWorkbookStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';
import withScrolling from 'react-dnd-scrolling';

const worksheetsPaneBindings = bindingsDefinition({
  activeWorkbookId: prop<string>(),
  activeWorksheetId: prop<string>(),
  $state: injected<ng.ui.IStateService>(),
});

const ScrollingComponent = withScrolling('div');

export const WorksheetsPane: SeeqComponent<typeof worksheetsPaneBindings> = (props) => {
  const { activeWorkbookId, activeWorksheetId } = props;
  const { $state } = useInjectedBindings(worksheetsPaneBindings);
  const { t } = useTranslation();

  const [disableAddWorksheet, setDisableAddWorksheet] = useState(false);
  const [goingToWorksheetId, setGoingToWorksheetId] = useState(undefined);

  const editMode = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.EDIT);
  const isReportBinder = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.isReportBinder);
  const worksheets = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.worksheets);

  const addActiveWorksheet = () => {
    const options = isReportBinder ? { prefix: t('ITEM_TYPES.DOCUMENT') } : {};
    setDisableAddWorksheet(true);

    doTrack('Workbench', `new Worksheet added to ${isReportBinder ? 'Topic' : 'Analysis'}`, 'from within document');

    return addWorksheetToWorkbook(activeWorkbookId, options)
      .then((worksheet) => gotoWorksheet(worksheet.worksheetId))
      .finally(() => setDisableAddWorksheet(false));
  };

  const gotoWorksheet = (worksheetId: string, viewOnly = false) => {
    // Prevent the user from commanding transition to the same worksheet while a transition to that worksheet
    // is already in progress (e.g. from a double click) Note: double clicks don't do anything special here.
    if (goingToWorksheetId === worksheetId) {
      return Promise.resolve();
    }

    setGoingToWorksheetId(worksheetId);

    const targetState = viewOnly ? APP_STATE.VIEW_WORKSHEET : APP_STATE.WORKSHEET;
    return $state
      .go(
        targetState,
        { workbookId: activeWorkbookId, worksheetId, workstepId: null },
        {
          // If we command a state transition to the current worksheet while another transition is in progress,
          // we MUST command a reload to guarantee that the ui-router will resolve and rehydrate the worksheet.
          // This prevents an edge condition where the selected worksheet won't display the correct state
          // because the user has clicked quickly on another worksheet and then back to the original worksheet
          // (CRAB-16564).
          reload: !!$state.transition && worksheetId === activeWorksheetId,
        },
      )
      .finally(() => setGoingToWorksheetId(undefined));
  };

  const moveWorksheet = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex) {
      return;
    }

    const modifiedIndex = oldIndex < newIndex ? newIndex + 1 : newIndex;

    return moveWorksheetInWorkbook(
      activeWorkbookId,
      sqWorkbookStore.worksheets[oldIndex],
      sqWorkbookStore.worksheets[modifiedIndex],
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      {editMode && (
        <div className="flexColumnContainer flexNoGrowNoShrink flexSpaceBetween pl5" id="wsScrollBtns">
          <a
            className={classNames('p10', {
              'disabledLook disabledBehavior': disableAddWorksheet,
            })}
            onClick={addActiveWorksheet}>
            <Icon
              icon="fa-plus"
              tooltip={isReportBinder ? 'NEW_DOCUMENT' : 'NEW_WORKSHEET'}
              tooltipPlacement="right"
              large={true}
              type="white"
              extraClassNames="iconHover specAddWorksheetButton"
              testId="addWorksheetButton"
            />
          </a>
        </div>
      )}

      <ScrollingComponent
        id="worksheetScrollArea"
        className="overflowAuto pl5 mt10 mb10 min-height-200"
        data-testid={editMode ? 'sortableWorksheetContainer' : 'nonSortableWorksheetContainer'}>
        {_.map(worksheets, (worksheet, index: number) =>
          editMode ? (
            <DraggableWorksheetPaneTile
              key={worksheet.worksheetId}
              index={index}
              worksheet={worksheet}
              gotoWorksheet={gotoWorksheet}
              workbookId={activeWorkbookId}
              isActive={worksheet.worksheetId === activeWorksheetId}
              moveWorksheet={moveWorksheet}
            />
          ) : (
            <ReadOnlyWorksheetPaneTile
              key={worksheet.worksheetId}
              worksheet={worksheet}
              workbookId={activeWorkbookId}
              gotoWorksheet={gotoWorksheet}
              isActive={worksheet.worksheetId === activeWorksheetId}
              index={index}
            />
          ),
        )}
      </ScrollingComponent>
    </DndProvider>
  );
};

export const sqWorksheetsPane = angularComponent(worksheetsPaneBindings, WorksheetsPane);
