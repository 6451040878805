// @ts-strict-ignore
import _ from 'lodash';
import angular from 'angular';

angular.module('Sq.Directives.GreaterThan', []).directive('sqGreaterThan', greaterThan);

function greaterThan() {
  const directive = {
    restrict: 'A',
    require: 'ngModel',
    link,
  };

  return directive;

  function link(scope: ng.IScope, elements: JQuery, attrs: ng.IAttributes, ngModel: ng.INgModelController) {
    ngModel.$validators.greaterThan = greaterThan;

    // if modelValue is a value with units object, look at the value of the object
    // otherwise, look at modelValue
    function greaterThan(modelValue) {
      if (_.isObject(modelValue)) {
        return _.isUndefined(modelValue.value) || modelValue.value > +attrs.sqGreaterThan;
      }
      return _.isUndefined(modelValue) || modelValue > +attrs.sqGreaterThan;
    }
  }
}
