// @ts-strict-ignore
import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ITEM_DATA_STATUS, ITEM_TYPES, TREND_PANELS } from '@/trendData/trendData.constants';
import { IconSelect, ReactSelectOption } from '@/hybrid/core/IconSelect.molecule';
import { TrendActions } from '@/trendData/trend.actions';
import { useAllTrendStoreItems } from '@/hybrid/core/hooks/useAllTrendStoreItems.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useTranslation } from 'react-i18next';
import { fetchCapsuleProperties } from '@/hybrid/utilities/investigateHelper.utilities';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { getDisplayedColumns } from '@/services/compareView.utilities';
import { useDidUpdate } from 'rooks';
import { useDebounce } from '@/hybrid/core/hooks/useDebounce.hook';
import { DEBOUNCE } from '@/core/core.constants';
import { useMemoCompare } from '@/hybrid/core/hooks/useMemoCompare.hook';
import { sqInvestigateStore, sqTrendSeriesStore, sqTrendStore } from '@/core/core.stores';

const compareViewToolbarBindings = bindingsDefinition({
  isSmall: prop<boolean>(),
  sqTrendActions: injected<TrendActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
});

export const CompareViewToolbar: SeeqComponent<typeof compareViewToolbarBindings> = ({ isSmall }) => {
  const { sqTrendActions, sqInvestigateActions } = useInjectedBindings(compareViewToolbarBindings);

  const { t } = useTranslation();

  const conditions = useAllTrendStoreItems({
    itemTypes: [ITEM_TYPES.CAPSULE_SET],
    workingSelection: true,
  });
  const capsuleSeries = useFluxPath(sqTrendSeriesStore, () => sqTrendSeriesStore.capsuleSeries);
  const capsuleProperties = useFluxPath(sqTrendStore, () => sqTrendStore.capsuleProperties);
  const separateByProperty = useFluxPath(sqTrendStore, () => sqTrendStore.separateByProperty);
  const firstColumn = useFluxPath(sqTrendStore, () => sqTrendStore.firstColumn);
  const colorByProperty = useFluxPath(sqTrendStore, () => sqTrendStore.colorByProperty);
  const activeTool = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.activeTool);

  const displayedColumnsFromCapsuleSeries = useMemo(
    () =>
      _.map(getDisplayedColumns(capsuleSeries), (column) => ({
        value: column,
        text: column,
      })),
    [capsuleSeries],
  );
  const displayedColumns = useMemoCompare(displayedColumnsFromCapsuleSeries, _.isEqual);

  const debouncedUpdateProperties = useDebounce(
    () => {
      if (!capsuleProperties?.length) {
        sqTrendActions.setSeparateByProperty(undefined);
        sqTrendActions.setColorByProperty(undefined);
        return;
      }

      const defaultPropertyName = capsuleProperties[0].value;
      if (_.findIndex(capsuleProperties, { value: separateByProperty }) === -1) {
        sqTrendActions.setSeparateByProperty(defaultPropertyName);
      }
      if (_.findIndex(capsuleProperties, { value: colorByProperty }) === -1) {
        sqTrendActions.setColorByProperty(defaultPropertyName);
      }
    },
    DEBOUNCE.LONG,
    { isAllowedDuringTest: true },
  );

  const conditionsRef = useRef([]);

  useEffect(() => {
    const shouldNotFetchProperties =
      _.isEqual(conditionsRef.current, conditions) ||
      _.some(conditions, (condition) => condition.dataStatus !== ITEM_DATA_STATUS.PRESENT);
    if (shouldNotFetchProperties) {
      return;
    }

    conditionsRef.current = conditions;
    _.chain(conditions)
      .map((condition) => fetchCapsuleProperties(condition.id))
      .thru((results) => Promise.all(results))
      .value()
      .then((responses) => {
        const properties = _.chain(responses)
          .thru(_.flatten)
          .uniqBy('name')
          .map(({ name }) => ({ value: name, text: name }))
          .value();
        sqTrendActions.setCapsuleProperties(properties);
      });
  }, [conditions]);

  useDidUpdate(() => {
    debouncedUpdateProperties();
  }, [capsuleProperties]);

  useEffect(() => {
    if (!colorByProperty && !separateByProperty && activeTool !== TREND_TOOLS.CONDITION_WITH_PROPERTIES) {
      sqInvestigateActions.setActiveTool(TREND_TOOLS.CONDITION_WITH_PROPERTIES);
    }
  }, [separateByProperty, colorByProperty]);

  useEffect(() => {
    const shouldUpdateFirstColumn =
      displayedColumns?.length > 0 &&
      !(displayedColumns.length === 1 && displayedColumns[0].value === t('COMPARE_VIEW.NO_PROPERTY_FOUND')) &&
      _.findIndex(displayedColumns, { value: firstColumn }) === -1;
    if (shouldUpdateFirstColumn) {
      // prevents a race condition when setting (trend) properties
      setTimeout(() => {
        sqTrendActions.setFirstColumn(displayedColumns[0].value);
      }, 0);
    }
  }, [displayedColumns, firstColumn]);

  return (
    <div
      className={classNames('btn-group forceFlex flexColumnContainer', {
        flexCenter: isSmall,
      })}>
      <div className="flexRowContainer">
        <div className="flexColumnContainer flexSpaceBetween flexAlignCenter">
          <div className="min-width-75 xsmall">{t('TOOLBAR.COMPARE.SEPARATE_BY')}:</div>
          <div className="min-width-150 pt2">
            <IconSelect
              extraClassNames="small-select"
              value={separateByProperty}
              selectOptions={capsuleProperties}
              testId="separateByPropertySelect"
              onChange={(option: ReactSelectOption<string>) => {
                sqTrendActions.setSeparateByProperty(option.value);
                sqTrendActions.addPropertiesColumn(TREND_PANELS.CAPSULES, { propertyName: option.value }, true);
              }}
            />
          </div>
        </div>

        <div className="flexColumnContainer flexSpaceBetween flexAlignCenter">
          <div className="min-width-75 xsmall">{t('TOOLBAR.COMPARE.FIRST_COLUMN')}:</div>
          <div className="min-width-150">
            <IconSelect
              extraClassNames="small-select"
              value={firstColumn}
              selectOptions={displayedColumns}
              testId="firstColumnSelect"
              onChange={(option: ReactSelectOption<string>) => sqTrendActions.setFirstColumn(option.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
