// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';

import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ReportActions } from '@/reportEditor/report.actions';
import { Icon } from '@/hybrid/core/Icon.atom';
import _ from 'lodash';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { FormulaService } from '@/services/formula.service';
import SelectItemWrapper from '@/hybrid/core/SelectItemWrapper.organism';
import { EditableText } from '@/hybrid/core/EditableText.atom';
import { sqTreesApi } from '@/sdk';
import { isAsset } from '@/hybrid/utilities/utilities';
import { CopyButtonGivenText } from '@/hybrid/core/CopyButtonGivenText.atom';
import { AssetSelection, MAX_ASSETS_FOR_SELECTION, ReportClickActions } from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';
import { sqReportStore } from '@/core/core.stores';

const reportConfigAssetSelectionBindings = bindingsDefinition({
  selection: prop<AssetSelection>(),
  onEdit: prop<(selection: AssetSelection) => void>(),
  actionWrapper: prop<(action: Function, actionType: ReportClickActions) => (...actionArgs) => any>(),
  reClickAction: prop<ReportClickActions>(),
  viewOnly: prop.optional<boolean>(),
  openBulkEditWithGivenAssetSelection: prop<(assetSelection: AssetSelection) => void>(),
  isValid: prop.optional<(string) => boolean>(),
  sqReportActions: injected<ReportActions>(),
  sqReportContent: injected<ReportContentService>(),
  sqFormula: injected<FormulaService>(),
});

export const ReportConfigAssetSelection: SeeqComponent<typeof reportConfigAssetSelectionBindings> = (props) => {
  const { sqReportActions, sqReportContent, sqFormula } = useInjectedBindings(reportConfigAssetSelectionBindings);
  const {
    selection,
    onEdit,
    actionWrapper,
    reClickAction,
    viewOnly = false,
    openBulkEditWithGivenAssetSelection,
    isValid = () => true,
  } = props;
  const sandboxMode = useFluxPath(sqReportStore, () => sqReportStore.sandboxMode);
  const assetSelectionToContentMap = useFluxPath(sqReportStore, () => sqReportStore.assetSelectionToContentMap);
  const [siblings, setSiblings] = useState<object[]>(null);
  const [name, setName] = useState(selection.name);
  // flag that will tell us if the override has already been processed so we don't apply it twice
  const [showCopyId, setShowCopyId] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted && sandboxMode.enabled) {
      onReClick(reClickAction);
    }
    return () => {
      mounted = false;
    };
  }, [reClickAction]);

  useEffect(() => {
    setName(selection.name);
  }, [selection.name]);

  const pathDepth = _.isNil(selection.assetPathDepth) ? undefined : selection.assetPathDepth;

  useEffect(() => {
    // Set up sibling options
    if (selection.asset.isRedacted) {
      setSiblings([selection.asset]);
    } else {
      const parent = _.last(selection.asset.ancestors);
      if (!parent) {
        return;
      }

      sqTreesApi.getTree({ id: parent.id, limit: MAX_ASSETS_FOR_SELECTION }).then(
        ({
          data: {
            item: { ancestors },
            children,
          },
        }) =>
          _.chain(children)
            .filter(isAsset)
            .map((child) => ({
              ..._.omit(child, ['properties']), // Omit properties to save memory and because not needed
              ancestors: ancestors.concat(parent), // Ensures parent can be found when sibling is selected
              name: sqFormula.getAssetPathFromAncestors(ancestors.concat([parent, child]), pathDepth),
            }))
            .thru(setSiblings)
            .value(),
      );
    }
  }, [selection.asset.id, pathDepth]);

  const changeSiblingAndTrack = (assetSelection: AssetSelection, asset: any) => {
    doTrack('Topic', 'Asset Selection', 'Changed');
    return sqReportActions.saveAssetSelection({ ...assetSelection, asset });
  };

  const onChangeSibling = (selectedAsset) => {
    actionWrapper(changeSiblingAndTrack, ReportClickActions.ChangeSibling)(selection, selectedAsset);
  };

  const isSelectionUsed = (assetSelectionId: string) => !_.isEmpty(assetSelectionToContentMap[assetSelectionId]);

  const openBulkEdit = (assetSelection: AssetSelection) => {
    doTrack('Topic', 'Asset Selection Tool', 'edit - warning icon');
    openBulkEditWithGivenAssetSelection(assetSelection);
  };

  const refreshContentUsingAssetSelection = () => {
    doTrack('Topic', 'Asset Selection Tool', 'refresh content');
    sqReportContent.forceRefreshContentUsingAssetSelection(selection.id);
  };

  const onClickWarning = () => {
    actionWrapper(openBulkEdit, ReportClickActions.Warning)(selection);
  };

  const onClickEdit = () => {
    actionWrapper((selection: AssetSelection) => {
      doTrack('Topic', 'Asset Selection Tool', 'edit - edit icon');
      onEdit(selection);
    }, ReportClickActions.Edit)(selection);
  };

  const onClickRefresh = () => {
    actionWrapper(refreshContentUsingAssetSelection, ReportClickActions.Refresh)(selection);
  };

  const editName = (assetSelection, newName) => {
    if (newName === name || !isValid(newName)) {
      return;
    }
    // Change rendered name early to prevent visual delay
    setName(newName);
    return sqReportActions.saveAssetSelection({
      ...assetSelection,
      name: newName,
    });
  };

  const onEditName = (name) => {
    actionWrapper(editName, ReportClickActions.ChangeName)(selection, name);
  };

  const onReClick = (action: ReportClickActions) =>
    ({
      [ReportClickActions.None]: _.noop,
      [ReportClickActions.Edit]: onClickEdit,
      [ReportClickActions.Refresh]: onClickRefresh,
      [ReportClickActions.Warning]: onClickWarning,
      [ReportClickActions.ChangeSibling]: onChangeSibling,
      [ReportClickActions.ChangeName]: onEditName,
    }[action](getReClickArg(action)));

  const getReClickArg = (action) => {
    if (action === ReportClickActions.ChangeSibling) {
      return selection.asset;
    } else if (action === ReportClickActions.ChangeName) {
      return selection.name;
    } else {
      return null;
    }
  };

  const displayThisSelection = viewOnly ? isSelectionUsed(selection.id) : true;
  return (
    displayThisSelection && (
      <div className="flexRowContainer mb5" data-testid={`assetSelection_${selection.id}`} data-idtocopy={selection.id}>
        <div className="flexColumnContainer mr5 pt5 pb5">
          <span
            className="flexFill flexColumnContainer"
            onMouseMove={() => setShowCopyId(true)}
            onMouseLeave={() => setShowCopyId(false)}>
            <EditableText
              testId={`${selection.id}_name_test_id`}
              value={name}
              onUpdate={onEditName}
              inputClasses="semi-bold flexFill"
              textClasses="semi-bold flexFill nowrap"
            />
            {!viewOnly && showCopyId && (
              <CopyButtonGivenText
                testId={`assetSelectionLinkIcon_${selection.id}`}
                id={`assetSelectionLinkIcon_${selection.id}`}
                tooltip="COPY_ID.TO_CLIPBOARD"
                textToCopy={selection.id}
                asType="icon"
                iconClass="fc-copy"
                notifyMessage="COPY_ID.SUCCESS"
                extraClassNames="fa-sm ml2"
                onCopyFinish={() => doTrack('Topic', 'Asset Selection Tool', 'copy id')}
              />
            )}
          </span>
          <a className="ml15 link-no-focus link-no-underline flexColumnContainer flexCenter cursorPointer">
            {isSelectionUsed(selection.id) && (
              <HoverTooltip text="REPORT.CONFIG.REFRESH_CONTENT_FOR_ASSET_SELECTION">
                <div data-testid={`assetSelectionRefreshIcon_${selection.id}`} onClick={onClickRefresh}>
                  <Icon icon="fc-redo fa-fw fa-sm" extraClassNames="cursorPointer ml5 mt2" />
                </div>
              </HoverTooltip>
            )}
            {!isSelectionUsed(selection.id) && (
              <HoverTooltip text="REPORT.CONFIG.ASSET_SELECTION_UNUSED">
                <div data-testid={`assetSelectionWarningIcon_${selection.id}`} onClick={onClickWarning}>
                  <Icon icon="fa-warning" extraClassNames="ml5 mt1" type="warning" />
                </div>
              </HoverTooltip>
            )}
            <div>
              <Icon
                icon="fa-fw fa-edit"
                extraClassNames="ml6 mr2 mt2"
                testId={`assetSelectionEditIcon_${selection.id}`}
                onClick={onClickEdit}
              />
            </div>
          </a>
        </div>
        <SelectItemWrapper
          key={`${selection.id}_selectItemWrapper`}
          items={siblings}
          selected={selection.asset}
          allowClear={false}
          isMultipleSelect={false}
          loadingMetadata={null}
          dropdownWidth="50"
          cssClassName="react-select"
          noWrap={false}
          onChange={onChangeSibling}
          onRemove={_.noop}
          isGrouped={false}
        />
      </div>
    )
  );
};
