import { sqDurationStore } from '@/core/core.stores';
import _ from 'lodash';
import { DurationActions } from '@/trendData/duration.actions';

export const BORDER_WIDTH = 2;

export function updateDisplayRangeAndRecalculatePositionForDrag(
  distanceOfLeftEdgeFromBorder: number,
  selectorWidth: number,
  startPosition: number,
  ratePx: number,
  recalculatePositionCallback: (displayRangeStartVal: number, displayRangeEndVal: number) => void,
  sqDurationActions: DurationActions,
): void | false {
  const investigateRange = sqDurationStore.investigateRange;
  const displayRange = sqDurationStore.displayRange;
  const investigateRangeStartVal = investigateRange.start.valueOf();

  const isLeftSelectorAtLeftEdgeOfInvestigateRange = !distanceOfLeftEdgeFromBorder;
  const potentialStart = isLeftSelectorAtLeftEdgeOfInvestigateRange
    ? investigateRangeStartVal
    : _.toNumber(((distanceOfLeftEdgeFromBorder + BORDER_WIDTH) / ratePx).toFixed(2)) + investigateRangeStartVal;
  const potentialEnd = isLeftSelectorAtLeftEdgeOfInvestigateRange
    ? investigateRangeStartVal + displayRange.duration.asMilliseconds()
    : _.toNumber(((distanceOfLeftEdgeFromBorder + selectorWidth) / ratePx).toFixed(2)) + investigateRangeStartVal;

  const { startTime, endTime } = exposedForTesting.buildSafeRange(potentialStart, potentialEnd);
  const sliderIsAlreadyAtLeftEdgeOfInvestigateRange =
    startTime === investigateRangeStartVal && startPosition === -BORDER_WIDTH;
  if (sliderIsAlreadyAtLeftEdgeOfInvestigateRange) {
    // We are sliding, but have reached the left edge and should not recalculate. Returning false will stop the
    // execution of onDrag
    return false;
  }
  updateAndRecalculateHelper(endTime, startTime, recalculatePositionCallback, sqDurationActions);
}

export function updateDisplayRangeAndRecalculatePositionForResize(
  deltaWidth: number,
  direction: string,
  ratePx: number,
  recalculatePositionCallback: (displayRangeStartVal: number, displayRangeEndVal: number) => void,
  sqDurationActions: DurationActions,
): void {
  const displayRange = sqDurationStore.displayRange;
  const investigateRange = sqDurationStore.investigateRange;
  let displayStart = displayRange.start.valueOf();
  let displayEnd = displayRange.end.valueOf();

  if (direction === 'left') {
    displayStart = _.toNumber(((deltaWidth + BORDER_WIDTH) / ratePx).toFixed(2)) + investigateRange.start.valueOf();
  }

  if (direction === 'right') {
    displayEnd = _.toNumber((deltaWidth / ratePx).toFixed(2)) + investigateRange.start.valueOf();
  }
  updateAndRecalculateHelper(displayEnd, displayStart, recalculatePositionCallback, sqDurationActions);
}

export function handleScrollResize(
  event: React.WheelEvent,
  ratePx: number,
  recalculatePositionCallback: (displayRangeStartVal: number, displayRangeEndVal: number) => void,
  sqDurationActions: DurationActions,
) {
  const displayRange = sqDurationStore.displayRange;
  const investigateRange = sqDurationStore.investigateRange;
  const zoomFactor = 0.2;
  const unitDelta = Math.sign(event.nativeEvent.deltaY * -1 || 1);
  const min = displayRange.start.valueOf();
  const max = displayRange.end.valueOf();
  const dx = max - min;
  // @ts-ignore - layerX is supported by all browsers we support
  const mouseXValue = event.nativeEvent.layerX;
  let leftFactor = (mouseXValue / ratePx + investigateRange.start.valueOf() - min) / dx;
  let rightFactor = (max - (investigateRange.start.valueOf() + mouseXValue / ratePx)) / dx;

  if (rightFactor < 0) rightFactor = 1;
  if (leftFactor < 0) leftFactor = 1;
  // Compute new start and end
  let start = min + dx * leftFactor * zoomFactor * unitDelta;
  let end = max - dx * rightFactor * zoomFactor * unitDelta;

  safeUpdateTimes(start, end, recalculatePositionCallback, sqDurationActions);
}

export function handleTimebarClickReposition(
  event: React.MouseEvent,
  ratePx: number,
  selectorWidth: number,
  recalculatePositionCallback: (displayRangeStartVal: number, displayRangeEndVal: number) => void,
  sqDurationActions: DurationActions,
) {
  const investigateRange = sqDurationStore.investigateRange;

  if (_.includes((event.target as HTMLDivElement).classList, 'moveSelectorAria')) {
    // @ts-ignore - layerX is supported by all browsers we support
    let startPx = event.nativeEvent.layerX - selectorWidth / 2;
    if (startPx < 0) {
      startPx = 0;
    }

    let potentialStart = _.toNumber((startPx / ratePx).toFixed(2)) + investigateRange.start.valueOf();
    let potentialEnd = _.toNumber((potentialStart + (selectorWidth - BORDER_WIDTH) / ratePx).toFixed(2));
    const { startTime, endTime } = exposedForTesting.buildSafeRange(potentialStart, potentialEnd);

    safeUpdateTimes(startTime, endTime, recalculatePositionCallback, sqDurationActions);
  }
}

/**
 * Returns a start and end value that are within the investigate range, and maintains the duration of the display
 * range. This should be used if displayRange duration is not allowed to change, i.e. when dragging or clicking. but
 * not when resizing
 */
const buildSafeRange = (start: number, end: number) => {
  let startTime = start;
  let endTime = end;
  if (end > sqDurationStore.investigateRange.end.valueOf()) {
    endTime = sqDurationStore.investigateRange.end.valueOf();
    startTime = endTime - sqDurationStore.displayRange.duration.asMilliseconds();
  } else if (start < sqDurationStore.investigateRange.start.valueOf()) {
    startTime = sqDurationStore.investigateRange.start.valueOf();
    endTime = startTime + sqDurationStore.displayRange.duration.asMilliseconds();
  }
  return { startTime, endTime };
};

/***
 * Updates the display range to the given start and end times, if they are within the investigate range.
 */
export const safeUpdateTimes = (
  start: number,
  end: number,
  recalculatePositionCallback: (displayRangeStartVal: number, displayRangeEndVal: number) => void = _.noop,
  sqDurationActions: DurationActions,
) => {
  // Ensure the investigate range is not exceeded
  const startTime = Math.max(start, sqDurationStore.investigateRange.start.valueOf());
  const endTime = Math.min(end, sqDurationStore.investigateRange.end.valueOf());

  sqDurationActions.displayRange.updateTimes(startTime, endTime);
  recalculatePositionCallback(startTime, endTime);
};

function updateAndRecalculateHelper(
  displayEnd: number,
  displayStart: number,
  recalculatePositionCallback: (displayRangeStartVal: number, displayRangeEndVal: number) => void,
  sqDurationActions: DurationActions,
): void {
  if (displayEnd - displayStart <= 0) {
    // The selected display range is invalid. Noop so the user can try again.
    return;
  }
  safeUpdateTimes(displayStart, displayEnd, recalculatePositionCallback, sqDurationActions);
}

export const exposedForTesting = {
  buildSafeRange,
};
