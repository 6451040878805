// @ts-strict-ignore
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { InputRangeSlider } from '@/hybrid/core/InputRangeSlider.atom';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import ValueWithUnits from '@/hybrid/trend/ValueWithUnits.atom';
import { DURATION_TIME_UNITS_ALL, DurationTimeUnit } from '@/main/app.constants';
import { TrendActions } from '@/trendData/trend.actions';
import { useTranslation } from 'react-i18next';
import { CopyButtonGivenText } from '@/hybrid/core/CopyButtonGivenText.atom';
import _ from 'lodash';
import { SummaryTypeEnum } from '@/sdk/model/ContentInputV1';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { FormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';
import { getTrendItemScopedTo } from '@/hybrid/trend/trendDataHelper.utilities';
import { FormulaService } from '@/services/formula.service';
import { HelpIcon } from '@/hybrid/core/Icon.atom';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { SummaryTypeSettings } from '@/trendData/trendData.constants';
import { sqTrendStore } from '@/core/core.stores';

const dataSummarySliderBindings = bindingsDefinition({
  min: prop.optional<number>(),
  max: prop.optional<number>(),
  step: prop.optional<number>(),
  value: prop<number>(),
  summary: prop<any>(),
  label: prop<string>(),
  onChange: prop<(event) => any>(),
  id: prop.optional<string>(),
  testId: prop.optional<string>(),
  updateValueWithUnits: prop.optional<any>(),
  showCopyButton: prop.optional<boolean>(),
  sqTrendActions: injected<TrendActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqFormulaToolActions: injected<FormulaToolActions>(),
  sqFormula: injected<FormulaService>(),
  $state: injected<ng.ui.IStateService>(),
});

export const DataSummarySlider: SeeqComponent<typeof dataSummarySliderBindings> = (props) => {
  const {
    min = 0,
    max = 10,
    id,
    label,
    onChange,
    step = 1,
    value,
    testId,
    summary,
    showCopyButton = true,
    updateValueWithUnits = (update) =>
      sqTrendActions.setSummary({
        type: summary.type,
        value: summary.value,
        discreteUnits: update,
        isSlider: false,
      }),
  } = props;
  const { $state, sqTrendActions, sqInvestigateActions, sqFormulaToolActions, sqFormula } =
    useInjectedBindings(dataSummarySliderBindings);
  const { t } = useTranslation();
  const currentFormula = sqTrendStore.discreteSummaryFormula;
  const isDiscrete = summary.type === SummaryTypeEnum.DISCRETE;
  const isAuto = summary.type === SummaryTypeEnum.AUTO;
  const discreteValueOptions: DurationTimeUnit[] = _.filter(
    DURATION_TIME_UNITS_ALL,
    (timeUnit) => !['s'].includes(timeUnit.momentUnit),
  );

  const defaultName = 'FORMULA.FORMULA';
  const duplicateSummarizationToFormula = () => {
    sqInvestigateActions.setActiveTool('formula');
    sqFormula.getDefaultName(t(defaultName), getTrendItemScopedTo(undefined, $state)).then((name) => {
      const value = `${summary.discreteUnits.value} ${summary.discreteUnits.units}`;
      sqInvestigateActions.setSearchName(TREND_TOOLS.FORMULA, `${name} (Summarized - ${value})`);
      const textAreaCollection = $('#psFormula').find('textarea').first();
      if (textAreaCollection.length > 0) {
        const textArea: HTMLTextAreaElement = textAreaCollection[0];
        textArea.focus();
        textArea.setSelectionRange(0, 0);
      }
    });
    sqFormulaToolActions.setFormula(sqTrendStore.discreteSummaryFormula);
  };

  const copyButton = (
    <CopyButtonGivenText
      disableButton={!sqTrendStore.isSummarizationActive}
      id="slider-copy-button"
      testId="slider-copy-button"
      notifyMessage="TOOLBAR.SUMMARY.COPIED"
      notifyMessageParams={{ item: currentFormula }}
      textToCopy={currentFormula}
      asType="button"
      extraClassNames="height-32 max-height-32 btn btn-default"
      tooltip="TOOLBAR.SUMMARY.COPY_BUTTON_TOOLTIP"
      iconClass="fc-formula"
      onCopyFinish={duplicateSummarizationToFormula}
    />
  );

  return (
    <>
      <Form data-testid={testId}>
        <Form.Group controlId={id}>
          {isAuto && (
            <Form.Text className="flexColumnContainer flexSpaceBetween mt10 mb10">
              <HoverTooltip text="TOOLBAR.SUMMARY.AUTO_MIN_TOOLTIP">
                <div>{t('TOOLBAR.SUMMARY.AUTO_MIN_LABEL')}</div>
              </HoverTooltip>
              <HoverTooltip text="TOOLBAR.SUMMARY.AUTO_MAX_TOOLTIP">
                <div>{t('TOOLBAR.SUMMARY.AUTO_MAX_LABEL')}</div>
              </HoverTooltip>
            </Form.Text>
          )}
          {isDiscrete && (
            <Form.Text className="flexColumnContainer flexJustifyCenter mt10 mb10">
              <span>
                {t('TOOLBAR.SUMMARY.CURRENT_VALUE_LABEL')}:{' '}
                <strong>
                  {summary.isSlider
                    ? `${summary.discreteUnits.value} ${summary.discreteUnits.units}`
                    : t('TOOLBAR.SUMMARY.CUSTOM')}
                </strong>
              </span>
            </Form.Text>
          )}
          <div className="mt5">
            <InputRangeSlider
              min={min}
              max={max}
              step={step}
              id={id}
              label={label}
              onChange={onChange}
              value={value}
              testId="summary-slider"
            />
          </div>
          {isAuto && (
            <Form.Text className="flexColumnContainer flexSpaceBetween">
              <div>{SummaryTypeSettings[SummaryTypeEnum.AUTO].min}</div>
              <div>
                {t('TOOLBAR.SUMMARY.CURRENT_VALUE_LABEL')}:{' '}
                <strong>{summary.value !== 0 ? summary.value : t('TOOLBAR.SUMMARY.OFF')}</strong>
              </div>
              <div>{SummaryTypeSettings[SummaryTypeEnum.AUTO].max}</div>
            </Form.Text>
          )}
          {isAuto && (
            <Form.Text className="flexJustifyCenter">
              {t('TOOLBAR.SUMMARY.AUTO_DESCRIPTION')}
              <HelpIcon
                tooltip="TOOLBAR.SUMMARY.LEARN_MORE"
                extraClassNames="pl3"
                onClick={() =>
                  open(
                    'https://telemetry.seeq.com/support-link/wiki/spaces/KB/pages/1218314299/Signal+Summarization',
                    '_blank',
                  )
                }
              />
            </Form.Text>
          )}
        </Form.Group>
        {isDiscrete && <Form.Text> {t('TOOLBAR.SUMMARY.DISCRETE_VALUE_WITH_UNITS_LABEL')} </Form.Text>}
      </Form>
      {isDiscrete && (
        <div className="flexAlignEnd flexColumnContainer">
          <div className="flexRowContainer mr5">
            <ValueWithUnits
              min={0}
              propName="discrete-input"
              defaultValue={summary.discreteUnits}
              onChange={updateValueWithUnits}
              availableUnits={discreteValueOptions}
              appendToBody={false}
            />
          </div>
          {showCopyButton && copyButton}
        </div>
      )}
    </>
  );
};

export default DataSummarySlider;
