import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import moment from 'moment';
import { doTrack } from '@/track/track.service';
import { cancelGroup } from '@/hybrid/requests/pendingRequests.utilities';
import { sqDatasourcesApi, sqUsageApi, UsageOutputV1 } from '@/sdk';
import { Card } from 'react-bootstrap';
import Highcharts from 'highcharts';
import { UsageSearchPanel } from '@/hybrid/administration/usage/UsageSearchPanel.molecule';
import HighchartsReact from 'highcharts-react-official';
import { UsageTable } from '@/hybrid/administration/usage/UsageTable.molecule';
import { useTranslation } from 'react-i18next';
import { AggregateByEnum } from '@/sdk/api/UsageApi';
import {
  getBarChartConfig,
  getHistogramChartConfig,
  getPieChartConfig,
  translateType,
} from '@/hybrid/administration/usage/usage.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { DATASOURCES_TAB_INDEX } from '@/administration/administration.constants';
import { setActiveTabIndex } from '@/administration/administration.actions';
import { formatValue } from '@/hybrid/utilities/logger.utilities';
import { isCanceled } from '@/hybrid/utilities/http.utilities';

export interface SelectOptionIF<T> {
  value: T;
  label: string;
}

const usageTabBindings = bindingsDefinition({});

export type SearchParametersIF = {
  startTime: number;
  endTime: number;
  type: SelectOptionIF<string>[];
  source: string;
  identityId: string[];
  aggregateBy: SelectOptionIF<AggregateByEnum>[];
};

export type UsageOutputV1WithId = UsageOutputV1 & { id: number };

const AGGREGATE_BY_ALL = [AggregateByEnum.Day, AggregateByEnum.Type, AggregateByEnum.Source, AggregateByEnum.User];

export const UsageTab: SeeqComponent<typeof usageTabBindings> = () => {
  const { t } = useTranslation();

  const aggregateByOptions: SelectOptionIF<AggregateByEnum>[] = _.map(AggregateByEnum, (option) => ({
    value: option,
    label: t(`USAGE.AGGREGATE_BY_OPTIONS.${option.toString().toUpperCase()}`),
  }));

  const initialAggregateByValue = _.find(aggregateByOptions, { value: AggregateByEnum.Month });
  const now = moment.utc();
  const defaultSearchParameters: SearchParametersIF = {
    startTime: now.clone().subtract(6, 'month').startOf('month').valueOf(),
    endTime: now.clone().endOf('day').valueOf(),
    type: [],
    source: '',
    identityId: [],
    aggregateBy: [initialAggregateByValue!],
  };

  const [searchParameters, setSearchParameters] = useState<SearchParametersIF>(defaultSearchParameters);
  const [backupSearchParameters, setBackupSearchParameters] = useState<SearchParametersIF>(defaultSearchParameters);
  const [chartConfig, setChartConfig] = useState<Highcharts.Options | undefined>(undefined);
  const [entries, setEntries] = useState<UsageOutputV1WithId[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const [signalCount, setSignalCount] = useState(0);
  const [datasourceNames, setDatasourceNames] = useState<string[]>([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [types, setTypes] = useState<SelectOptionIF<string>[]>([]);
  const [isDrilledIn, setIsDrilledIn] = useState(false);

  useEffect(() => {
    fetchUsage();
  }, [searchParameters]);

  useEffect(() => {
    fetchTypes();
    fetchDatasources();
  }, []);

  const cancellationGroup = 'usageSearch';

  const fetchTypes = () => {
    sqUsageApi.getTypes().then(({ data: { types } }) => {
      setTypes(
        _.map(types, (type) => ({
          value: type,
          label: translateType(type),
        })),
      );
    });
  };

  const fetchDatasources = () => {
    sqDatasourcesApi.getDatasources({ limit: 10000, includeArchived: false }).then(({ data: { datasources } }) => {
      const usageDatasources = _.chain(datasources)
        .reject('seeqInternal')
        .reject('storedInSeeq')
        .reject({ name: 'Example Data' })
        .reject(({ additionalProperties }) =>
          _.some(additionalProperties, { name: SeeqNames.Properties.Enabled, value: false }),
        )
        .value();
      setDatasourceNames(_.chain(usageDatasources).map('name').sort().value());
      setSignalCount(
        _.sumBy(
          usageDatasources,
          ({ additionalProperties }) =>
            _.find(additionalProperties, { name: SeeqNames.Properties.SignalCount })?.value || 0,
        ),
      );
    });
  };

  const fetchUsage = () => {
    doTrack('Usage', 'search', _.chain(searchParameters.aggregateBy).map('value').sort().join(', ').value(), true);
    setIsTableLoading(true);
    setErrorMessage('');
    cancelGroup(cancellationGroup, true)
      .then(() =>
        sqUsageApi.getUsage(
          {
            startTime: moment.utc(searchParameters.startTime).toISOString(),
            endTime: moment.utc(searchParameters.endTime).toISOString(),
            type: _.map(searchParameters.type, 'value'),
            source: searchParameters.source,
            identityId: searchParameters.identityId,
            aggregateBy: _.isEmpty(searchParameters.aggregateBy)
              ? AGGREGATE_BY_ALL
              : _.map(searchParameters.aggregateBy, 'value'),
          },
          {
            cancellationGroup,
          },
        ),
      )
      .then(({ data: { content, hasMore } }) => {
        setHasMore(hasMore);
        const entriesWithId = _.map(content, (entry, index) => ({
          ...entry,
          id: index,
        }));
        setEntries(entriesWithId);

        if (searchParameters.aggregateBy.length === 1 || searchParameters.aggregateBy.length === 2) {
          const hasDayOrMonth = _.some(searchParameters.aggregateBy, ({ value }) =>
            _.includes([AggregateByEnum.Day, AggregateByEnum.Month], value),
          );

          if (hasDayOrMonth) {
            setChartConfig(
              searchParameters.aggregateBy.length === 1
                ? getBarChartConfig(entriesWithId, drillIntoBar, searchParameters)
                : getHistogramChartConfig(entriesWithId, searchParameters),
            );
          } else if (searchParameters.aggregateBy.length === 1) {
            setChartConfig(getPieChartConfig(entriesWithId, searchParameters));
          } else {
            setChartConfig(undefined);
          }
        } else {
          setChartConfig(undefined);
        }

        setIsTableLoading(false);
      })
      .catch((e) => {
        setEntries([]);
        if (!isCanceled(e)) {
          setErrorMessage(e?.data?.statusMessage || formatValue(e));
        }
      })
      .finally(() => setIsTableLoading(false));
  };

  const drillIntoBar = (entry: any) => {
    setBackupSearchParameters(searchParameters);
    const hasDay = _.some(searchParameters.aggregateBy, { value: AggregateByEnum.Day });
    const startTime = moment.utc(entry.category);
    const newParams: SearchParametersIF = {
      ...searchParameters,
      startTime: startTime.valueOf(),
      endTime: startTime.endOf(hasDay ? 'day' : 'month').valueOf(),
      aggregateBy: [_.find(aggregateByOptions, { value: hasDay ? AggregateByEnum.User : AggregateByEnum.Day })!],
    };
    setSearchParameters(newParams);
    setIsDrilledIn(true);
  };

  const resetSearchParametersToDefault = () => {
    if (isDrilledIn) {
      setSearchParameters(backupSearchParameters);
      setIsDrilledIn(false);
    } else {
      setSearchParameters(defaultSearchParameters);
    }
  };

  return (
    <div className="m10 pr20 overflowYScroll flexRowContainer">
      {datasourceNames.length > 0 && (
        <Card className="mb5">
          <Card.Header>
            {t('USAGE.DATASOURCES_CONNECTED')}
            <HoverTooltip text={datasourceNames.join(', ')}>
              <strong data-testid="datasourceCount" className="pl5">
                <a
                  href="#"
                  onClick={() => setActiveTabIndex(DATASOURCES_TAB_INDEX)}
                  className="link-no-underline link-black">
                  {datasourceNames.length.toLocaleString()}
                </a>
              </strong>
            </HoverTooltip>
            <span className="pl20">
              {t('USAGE.SIGNALS_INDEXED')}{' '}
              <strong data-testid="signalCount" className="pl5">
                {signalCount.toLocaleString()}
              </strong>
            </span>
          </Card.Header>
        </Card>
      )}
      <UsageSearchPanel
        searchParameters={searchParameters}
        resetCallback={resetSearchParametersToDefault}
        searchCallback={setSearchParameters}
        itemTypeOptions={types}
        aggregateByOptions={aggregateByOptions}
        isDrilledIn={isDrilledIn}
        hasMore={hasMore}
      />
      {chartConfig && !errorMessage && (
        <div className="height-400" data-testid={`usageChart-${chartConfig.chart!.type}`}>
          <HighchartsReact highcharts={Highcharts} options={chartConfig} immutable={true} />
        </div>
      )}
      <UsageTable entries={entries} isLoading={isTableLoading} errorMessage={errorMessage} />
    </div>
  );
};
