// @ts-strict-ignore
/* istanbul ignore file */

import Plugin from 'ckeditor5/packages/ckeditor5-core/src/plugin';
import PageBreak from 'ckeditor5/packages/ckeditor5-page-break/src/pagebreak';
import { BasePluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { PluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/plugins/PluginDependencies';
import Widget from 'ckeditor5/packages/ckeditor5-widget/src/widget';
import { toWidget } from 'ckeditor5/packages/ckeditor5-widget/src/utils';

export class SeeqPageBreak extends Plugin {
  static pluginName = 'SeeqPageBreak';
  static setup = {
    name: SeeqPageBreak.pluginName,
    plugin: SeeqPageBreak,
  };

  static get requires() {
    return [PageBreak, Widget];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
  }

  private defineSchema() {
    const editor = this.editor;
    const schema = editor.model.schema;

    schema.register('seeqPageBreak', {
      isObject: true,
      allowWhere: '$block',
    });
  }

  private defineConverters() {
    const editor = this.editor;
    const conversion = editor.conversion;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

    conversion.for('editingDowncast').elementToElement({
      model: 'seeqPageBreak',
      view: (modelElement, { writer: viewWriter }) => {
        const div = viewWriter.createEditableElement('div', {
          style: 'page-break-after:always;',
        });

        return toWidget(div, viewWriter);
      },
    });

    // This is almost entirely copied from
    // https://github.com/ckeditor/ckeditor5/blob/55248f45a8ce74fdd0276b03c2cd8e339dd1f4a7/packages/ckeditor5-page-break/src/pagebreakediting.js#L85
    // This lets catch CK page breaks and turn them into hr's that the PDF view can break open.
    conversion.for('upcast').elementToElement({
      model: 'seeqPageBreak',
      // Catches the conversion before the built in pageBreak converter can act on it
      converterPriority: 'highest',
      view: (element) => {
        if (!deps.isPDF) {
          return;
        }

        // For upcast conversion it's enough if we check for element style and verify if it's empty
        // or contains only hidden span element.
        const hasPageBreakBefore = element.getStyle('page-break-before') === 'always';
        const hasPageBreakAfter = element.getStyle('page-break-after') === 'always';

        if (!hasPageBreakBefore && !hasPageBreakAfter) {
          return;
        }

        // The "page break" div accepts only single child or no child at all.
        if (element.childCount === 1) {
          const viewSpan = element.getChild(0);

          // The child must be the "span" element that is not displayed and has a space inside.
          if (!viewSpan.is('element', 'span') || viewSpan.getStyle('display') !== 'none' || viewSpan.childCount !== 1) {
            return;
          }

          const text = viewSpan.getChild(0);

          if (!text.is('$text') || text.data !== ' ') {
            return;
          }
        } else if (element.childCount > 1) {
          return;
        }

        return { name: true };
      },
    });
  }
}
