import classNames from 'classnames';
import React, { useState } from 'react';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';

export type OverviewTopSectionProps = {
  toolFilter: string;
  setToolFilter: (toolFilter: string) => void;
  clearResults: () => void;
};

/**
 * For searching/filtering through tools in the Overview section of the tool-selection-view
 */
export const OverviewTopSection: React.FunctionComponent<OverviewTopSectionProps> = ({
  toolFilter,
  setToolFilter,
  clearResults,
}) => {
  const { t } = useTranslation();

  const [toolsSearchBarFocus, setToolsSearchBarFocus] = useState<boolean>();

  return (
    <div data-testid="overviewTopSection" className="pt5 pr5 pb10 pl5 width-maximum">
      <div
        data-testid="toolsSearchContainer"
        className={classNames('toolsSearchBar flexColumnContainer pb5 width-maximum flexCenter', {
          active: toolsSearchBarFocus,
        })}>
        <Icon
          icon="fa-search"
          extraClassNames={classNames('fa-fw mb2', {
            'sq-text-primary': toolsSearchBarFocus,
            'sq-fairly-dark-gray': !toolsSearchBarFocus,
          })}
        />
        <input
          data-testid="toolsSearch"
          autoComplete="off"
          id="toolsSearch"
          type="text"
          className="ml5 unstyled-input flexFill"
          autoFocus={true}
          placeholder={t('INVESTIGATE_SEARCH_PROMPT')}
          onFocus={() => setToolsSearchBarFocus(true)}
          onBlur={() => setToolsSearchBarFocus(false)}
          value={toolFilter}
          onChange={(e) => setToolFilter(e.target.value)}
        />
        {toolFilter.length > 0 && (
          <Icon
            id="clearResults"
            testId="clearResults"
            icon="fa-remove"
            large={true}
            extraClassNames="fa-fw mb2 sq-text-primary"
            tooltip="CLEAR"
            onClick={clearResults}
          />
        )}
      </div>
    </div>
  );
};
