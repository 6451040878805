// @ts-strict-ignore
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ConditionProperty } from '@/hybrid/tools/conditionWithProperties/conditionWithProperties.store';
import { removeProperty, updateProperty } from '@/hybrid/tools/conditionWithProperties/conditionWithProperties.actions';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import _ from 'lodash';
import { ValueWithUnitsItem } from '@/hybrid/trend/ValueWithUnits.atom';

export const validatePropertyName = (propertyName: string, properties: ConditionProperty[]) =>
  _.chain(properties)
    .pickBy({ propertyName: _.trim(propertyName) })
    .values()
    .value().length > 1;

export const validateNameNoDecimals = (propertyName: string) => _.includes(propertyName as string, '.');

const findCustomErrorText = (propertyName: string, properties: ConditionProperty[]) => {
  if (validateNameNoDecimals(propertyName)) {
    return 'CONDITION_WITH_PROPERTIES.PROPERTY_DECIMAL_NAME_ERROR';
  } else if (validatePropertyName(propertyName, properties)) {
    return 'CONDITION_WITH_PROPERTIES.PROPERTY_UNIQUE_NAME_ERROR';
  }
};

export const createPropertyDefinition = (
  { stringSignal, propertyName }: ConditionProperty,
  index: number,
  properties: ConditionProperty[],
) => {
  const nonZeroBasedIndex = index + 1;

  const propertyDefinition: FormElement = {
    component: 'RemovableFormGroup',
    name: `propertyFormGroup${nonZeroBasedIndex}`,
    displayNumber: true,
    iconAction: () => removeProperty(index),
    hideIcon: properties.length < 2,
    components: [
      {
        component: 'ItemSelectFormComponent',
        name: `stringSignal${nonZeroBasedIndex}`,
        testId: `stringSignal${nonZeroBasedIndex}`,
        value: stringSignal,
        onChange: (stringSignal) => updateProperty({ value: { stringSignal }, index }),
        label: 'CONDITION_WITH_PROPERTIES.SELECT_STEP_SIGNAL',
        selectPlaceholder: 'CONDITION_WITH_PROPERTIES.SELECT_STEP_SIGNAL',
        itemTypes: [ITEM_TYPES.SERIES],
        includeOnlyStepSignals: true,
        includeMetadata: true,
      },
      {
        component: 'FormControlFormComponent',
        name: `propertyName${nonZeroBasedIndex}`,
        testId: `propertyName${nonZeroBasedIndex}`,
        label: 'CONDITION_WITH_PROPERTIES.PROPERTY_NAME',
        value: propertyName,
        onChange: (propertyName: string) => updateProperty({ value: { propertyName }, index }),
        onBlur: (propertyName: string) =>
          updateProperty({
            value: { propertyName: _.trim(propertyName) },
            index,
          }),
        validation: (propertyName: string) =>
          validateNameNoDecimals(propertyName) || validatePropertyName(propertyName, properties),
        customErrorText: findCustomErrorText(propertyName, properties),
        size: 'lg',
        required: true,
      },
    ],
  };

  return propertyDefinition;
};

export const createPropertiesDefinition = (properties: ConditionProperty[]) =>
  _.map<ConditionProperty, FormElement>(properties, createPropertyDefinition);

export const formatMaxCapsuleDuration = (maxCapsuleDuration: ValueWithUnitsItem) =>
  `${maxCapsuleDuration.value}${maxCapsuleDuration.units}`;

export const generateParameters = (properties: ConditionProperty[]) =>
  _.reduce(
    properties,
    (prev, curr, index) => ({
      ...prev,
      [`property${index + 1}Signal`]: _.get(curr, 'stringSignal.id'),
    }),
    {},
  );

export const generateFormula = (properties: ConditionProperty[]) => {
  const prefixArr = _.map(properties, (property, index) => {
    const toCondition = `$property${index + 1}Signal.toCondition("${property.propertyName}")`;

    return `$property${index + 1}Condition = ${toCondition}\n`;
  });
  const combineWith = `combineWith(${_.chain(properties)
    .map((p, index) => `$property${index + 1}Condition`)
    .join(', ')}).fragment(keepProperties())`;

  return `${prefixArr.join('')}${combineWith}`;
};

export const modifiedSelectedProperty = (
  propertyIndex: number,
  properties: ConditionProperty[],
  initialPropertyName?: string,
): string | undefined | null => {
  const propertyName = properties[propertyIndex]?.propertyName ?? (propertyIndex > -1 ? null : undefined);

  return propertyName !== initialPropertyName ? propertyName : undefined;
};
