import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { sqWorkbenchStore } from '@/core/core.stores';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { returnToState } from '@/hybrid/utilities/utilities';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { LoadingFallback } from '@/hybrid/main/LoadingFallback.atom';

const loadErrorBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
});

const WrappedLoadError: SeeqComponent<typeof loadErrorBindings> = () => {
  const { t } = useTranslation();
  const { $state } = useInjectedBindings(loadErrorBindings);
  const stateParams = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.stateParams);
  const { header, message1, message2, retryInterval } = stateParams;

  useEffect(() => {
    if (retryInterval) {
      const timeout = setTimeout(() => returnToState($state), retryInterval);
      return () => clearTimeout(timeout);
    }
  }, [retryInterval]);

  return (
    <div className="flexColumnContainer flexCenter fullViewport splashScreen">
      <div className="container ml0 mr0">
        <div className="row flexJustifyCenter">
          <div className="span12">
            <div className="jumbotron text-center">
              <p>
                <img src="/img/Seeq_logo_darkBlue_sm.png" />
              </p>
              <h2 className="mb10">{t(header)}</h2>
              <p>
                <span>{t(message1)}</span>
              </p>
              <p>
                <span>{t(message2)}</span>
              </p>
              {retryInterval ? <IconWithSpinner testId="loadErrorLoadingIcon" spinning={true} /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Wrap in Suspense to ensure translations are loaded  before displaying the component
export const LoadError: SeeqComponent<typeof loadErrorBindings> = () => {
  return (
    <React.Suspense fallback={<LoadingFallback fallbackLocation="Load error page" />}>
      <WrappedLoadError />
    </React.Suspense>
  );
};

export const sqLoadError = angularComponent(loadErrorBindings, LoadError);
