import React from 'react';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';

interface SelectAllIconProps {
  items: any[];
  selectAll: () => void;
  unselectAll: () => void;
}

export const SelectAllIcon: React.FunctionComponent<SelectAllIconProps> = ({ items, selectAll, unselectAll }) => {
  const anySelected = _.some(items, 'selected');

  return (
    <Icon
      onClick={anySelected ? unselectAll : selectAll}
      icon={anySelected ? 'fa-check-square' : 'fa-square-o'}
      tooltip={anySelected ? 'TOOLBAR.SELECT_NONE' : 'SELECT_ALL'}
      extraClassNames="fa-fw"
      tooltipPlacement="right"
      testId="selectAllIcon"
      type="text"
    />
  );
};
