// @ts-strict-ignore
import _ from 'lodash';
import { BaseToolStore } from '@/hybrid/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/hybrid/toolSelection/baseTool.constants';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';

export class DisplayToolStore extends BaseToolStore {
  static readonly storeName = 'sqDisplayToolStore';
  type = TREND_TOOLS.DISPLAY;

  initialize() {
    this.state = this.immutable({
      ...BASE_TOOL_COMMON_PROPS,
      asset: undefined,
      isScaled: true,
    });
  }

  get asset() {
    return this.state.get('asset');
  }

  get isScaled() {
    return this.state.get('isScaled');
  }

  dehydrate() {
    return this.state.serialize();
  }

  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  setAsset(payload) {
    this.state.set('asset', payload.asset);
  }

  setIsScaled(payload) {
    this.state.set('isScaled', payload.isScaled);
  }

  localHandlers = {
    DISPLAY_TOOL_ASSET: this.setAsset,
    DISPLAY_TOOL_IS_SCALED: this.setIsScaled,
  };

  handlers = _.assign({}, super.baseHandlers, this.localHandlers);
}
