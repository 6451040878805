// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { sqLicenseManagementStore, sqWorkbenchStore } from '@/core/core.stores';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { ContainerWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';
import moment from 'moment-timezone';
import { APP_STATE } from '@/main/app.constants';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { buildEmailLink } from '@/hybrid/utilities/utilities';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.constants.ts';
import { adminContactEmail, adminContactName } from '@/services/systemConfiguration.utilities';
import { LoadingFallback } from '@/hybrid/main/LoadingFallback.atom';

const licenseExpirationWarningBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
});

/**
 * Issues warning about license expiration.
 * Based on the user type different banners are displayed:
 * - users get a prompt to remind their admin to renew
 * - admins get a link to request a license.
 *
 * The warning that the license will expire soon can be snoozed or dismissed.
 */
const WrappedLicenseExpirationWarning: SeeqComponent<typeof licenseExpirationWarningBindings> = () => {
  const { $state, sqWorkbenchActions } = useInjectedBindings(licenseExpirationWarningBindings);

  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);
  const licenseExpirationSnoozeUntil = useFluxPath(
    sqWorkbenchStore,
    () => sqWorkbenchStore.licenseExpirationSnoozeUntil,
  );
  const { isTrial, license = {}, licenseStatus } = useFlux(sqLicenseManagementStore);

  const { t } = useTranslation();

  const [showAdminExpirationWarning, setShowAdminExpirationWarning] = useState<boolean>();
  const [showUserExpirationWarning, setShowUserExpirationWarning] = useState<boolean>();

  const { daysToExpiration } = license;
  const contactEmail = buildEmailLink(
    adminContactEmail(),
    adminContactName(),
    'LICENSE.EMAIL_LICENSE_REQUEST.SUBJECT',
    'LICENSE.EMAIL_LICENSE_REQUEST.RENEW',
  );
  const snoozeDays = !_.isNil(daysToExpiration) ? Math.ceil(daysToExpiration / 2) : undefined;

  /**
   * This effect sets the warning flags used by the html component to display the appropriate banner.
   * Based on the user type and the status of the license different banners will be displayed.
   *
   * This effect also manages the display of the notification that informs the user that new workbook and worksheet
   * creation is not supported with an expired license.
   *
   * This effect also supports the "snoozing" of the warning message by checking the snooze data against the
   * current date.
   */
  useEffect(() => {
    const isSnoozed = sqWorkbenchStore.isLicenseExpirationSnoozed();
    if (!isSnoozed && !_.isEmpty(currentUser) && licenseStatus) {
      if (
        currentUser.isAdmin &&
        (licenseStatus === LICENSE_STATUS.SHOW_LICENSE_WARNING ||
          licenseStatus === LICENSE_STATUS.SHOW_ADMIN_LICENSE_WARNING)
      ) {
        setShowAdminExpirationWarning(true);
      } else if (licenseStatus === LICENSE_STATUS.SHOW_LICENSE_WARNING) {
        setShowUserExpirationWarning(true);
      }
    }

    if (isSnoozed && (showAdminExpirationWarning || showUserExpirationWarning)) {
      setShowAdminExpirationWarning(false);
      setShowUserExpirationWarning(false);
    }
  }, [isTrial, license, currentUser, licenseExpirationSnoozeUntil]);

  /**
   * Dismisses the warning banner only until then page is reloaded.
   */
  const closeBanner = () => {
    setShowAdminExpirationWarning(false);
    setShowUserExpirationWarning(false);
  };

  /**
   * Snoozes the license warning.
   * Adds a Snooze Until entry to local storage that can be used to determine if warning messages are snoozed or not.
   *
   * The "snooze" duration is always half of the remaining days to license expiration.
   */
  const snoozeLicense = () => {
    const newWarningDisplayDate = moment().add(snoozeDays, 'days').toISOString();
    sqWorkbenchActions.setLicenseExpirationSnooze(newWarningDisplayDate);
    closeBanner();
  };

  // Warning banner that informs on upcoming license expiration
  if (showAdminExpirationWarning || showUserExpirationWarning) {
    return (
      <div className="pt20 pb20 flexColumnContainer flexCenter warning">
        {showAdminExpirationWarning && (
          <div className="flexFill ml20">
            {isTrial && (
              <span data-testid="adminExpirationWarningTrial">
                {t('LICENSE.ADMIN_EXPIRATION_WARNING_TRIAL', {
                  DAYS: daysToExpiration,
                })}
              </span>
            )}
            {!isTrial && (
              <span data-testid="adminExpirationWarning">
                {t('LICENSE.ADMIN_EXPIRATION_WARNING', {
                  DAYS: daysToExpiration,
                })}
              </span>
            )}
            &nbsp;
            <a
              href=""
              data-testid="expirationActionLink"
              onClick={(e) => {
                e.preventDefault();
                $state.go(APP_STATE.LICENSE);
              }}>
              {t('LICENSE.ADMIN_EXPIRATION_WARNING_CALL_TO_ACTION')}
            </a>
          </div>
        )}

        {showUserExpirationWarning && (
          <div className="flexFill ml20">
            <span data-testid="userThanks">{t('LICENSE.THANKS_FOR_INTEREST')}</span>
            <br />
            {isTrial && (
              <span data-testid="userExpirationWarningTrial">
                {t('LICENSE.USER_EXPIRATION_WARNING_TRIAL', {
                  DAYS: daysToExpiration,
                })}
              </span>
            )}
            {!isTrial && (
              <span data-testid="userExpirationWarning">
                {t('LICENSE.USER_EXPIRATION_WARNING', {
                  DAYS: daysToExpiration,
                })}
              </span>
            )}
            &nbsp;
            <ContainerWithHTML
              content={t('LICENSE.USER_EXPIRATION_CALL_TO_ACTION', {
                CONTACT: contactEmail,
              })}
            />
          </div>
        )}

        <div>
          <a
            href=""
            className="mr15"
            onClick={(e) => {
              e.preventDefault();
              snoozeLicense();
            }}
            data-testid="snoozeAction">
            {t('LICENSE.SNOOZE', { DAYS: snoozeDays })}
          </a>
          <Icon icon="fa-times" large={true} extraClassNames="closeIcon mr20" onClick={closeBanner} />
        </div>
      </div>
    );
  }

  return <></>;
};

// Wrap in Suspense to ensure translations are loaded before displaying the component
export const LicenseExpirationWarning: SeeqComponent<typeof licenseExpirationWarningBindings> = () => {
  return (
    <React.Suspense fallback={<LoadingFallback fallbackLocation="License expiration warning" />}>
      <WrappedLicenseExpirationWarning />
    </React.Suspense>
  );
};

export const sqLicenseExpirationWarning = angularComponent(licenseExpirationWarningBindings, LicenseExpirationWarning);
