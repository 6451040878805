// @ts-strict-ignore
import _ from 'lodash';
import { sqWorkbooksApi } from '@/sdk';
import {
  headlessRenderMode,
  isPresentationWorkbookMode,
  isViewOnlyWorkbookMode,
  workbookLoaded,
} from '@/hybrid/utilities/utilities';
import { apply } from '@/hybrid/worksteps/workstepUpgrader.utilities';
import { WORKSHEET_WITH_NO_WORKSTEPS, WORKSTEP_PUSH_DISABLED, WORKSTEP_SCHEMA_VERSION } from './worksteps.constant';

// ensure the response doesn't leak over to the new worksheet.
const cancellationOptions = {
  cancellationGroup: 'worksteps',
  cancelOnServer: false, // Still want the workstep request to finish on the appserver side
};

/**
 * Get workstep state and set it as the current workstep on the server, if specified.
 *
 * @param {String} workbookId - The workbook ID
 * @param {String} worksheetId - The worksheet ID
 * @param {String} [workstepId] - The workstep ID. If undefined then the current workstep will be found.
 * @param {Boolean} [setAsCurrent] - True if it should be set as the current workstep on the server. Only
 *   applicable if `workstepId` is set.
 * @returns {Promise} A promise that will resolve with state for the workstep or reject if workstep state is not
 *   available
 * Remove first parameter once react code avaible
 */
export function getWorkstep(workbookId, worksheetId, workstepId?, setAsCurrent = false) {
  let promise;
  if (workstepId) {
    if (setAsCurrent) {
      promise = setCurrentWorkstep(workbookId, worksheetId, workstepId);
    } else {
      promise = Promise.resolve(workstepId);
    }
  } else {
    promise = getCurrentWorkstepId(workbookId, worksheetId);
  }

  return promise
    .then((currentWorkstepId) =>
      sqWorkbooksApi.getWorkstep({ workbookId, worksheetId, workstepId: currentWorkstepId }, cancellationOptions),
    )
    .then((response) => workstepFromResponse(response.data));
}

/**
 * Push workstep state and make it the current workstep. If state is pushed when the current
 * workstep is not the last workstep, then the current workstep is first pushed, followed
 * by the workstep state.
 *
 * @param {String} workbookId - The workbook ID
 * @param {String} worksheetId - The worksheet ID
 * @param {Object} worksheetState - The worksheet state object
 * @returns {Promise} A promise that will resolve with state for the pushed workstep or reject if workstep state
 *   could not be pushed
 */
export function pushWorkstep(workbookId, worksheetId, worksheetState) {
  if (headlessRenderMode() || !workbookLoaded() || isViewOnlyWorkbookMode() || isPresentationWorkbookMode()) {
    return Promise.reject(WORKSTEP_PUSH_DISABLED);
  }

  return sqWorkbooksApi
    .createWorkstep(
      {
        data: JSON.stringify({
          version: WORKSTEP_SCHEMA_VERSION,
          state: worksheetState,
        }),
      },
      { workbookId, worksheetId },
      cancellationOptions,
    )
    .then((response) => workstepFromResponse(response.data));
}

/**
 * Gets the ID of a worksheet's current workstep.
 *
 * @param {String} workbookId - a workbook ID
 * @param {String} worksheetId - a worksheet ID
 * @returns {Promise} a promise that resolves with the workstep ID or WORKSHEET_WITH_NO_WORKSTEPS if the worksheet
 *   has no worksteps.
 */
export function getCurrentWorkstepId(workbookId, worksheetId) {
  return sqWorkbooksApi.getWorksheet({ workbookId, worksheetId }, cancellationOptions).then(function (response: any) {
    if (response.data.workstep) {
      return response.data.workstep;
    } else {
      return Promise.reject(WORKSHEET_WITH_NO_WORKSTEPS);
    }
  });
}

/**
 * Sets the current workstep.
 *
 * @param {String} workbookId - The workbook ID
 * @param {String} worksheetId - The worksheet ID
 * @param {String} workstepId - The workstep ID
 * @returns {Promise} A promise that resolves with the workstepId
 */
export function setCurrentWorkstep(workbookId, worksheetId, workstepId) {
  if (headlessRenderMode() || !workbookLoaded() || isViewOnlyWorkbookMode() || isPresentationWorkbookMode()) {
    return Promise.resolve(workstepId);
  }

  return sqWorkbooksApi
    .setCurrentWorkstep({ workbookId, worksheetId, workstepId }, cancellationOptions)
    .then(_.property('data.id'));
}

/**
 * Extracts the state, next, and previous steps from a workstep response. Rejects with an error if the JSON state
 * cannot be parsed.
 *
 * @param {Object} workstep - Response from an API call that returns workstep information
 * @returns {Object} The parsed workstep information or a rejected promise if state cannot be parsed.
 */
export function workstepFromResponse(workstep): Promise<any> {
  const parsedData = _.attempt(JSON.parse, workstep.data);
  if (!_.isError(parsedData)) {
    return apply(parsedData.state, parsedData.version).then((newState) => ({
      previous: workstep.previous,
      current: {
        id: workstep.id,
        state: newState,
      },
      next: workstep.next,
      last: workstep.last,
    }));
  } else {
    return Promise.reject(parsedData.message);
  }
}
