// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { APP_STATE, HOME_SCREEN_TABS } from '@/main/app.constants';
import { useTranslation } from 'react-i18next';
import { HomeScreenItemActions } from '@/hybrid/homescreen/HomeScreenItemActions.atom';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { SortIcon } from '@/hybrid/core/SortIcon.atom';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { sqHomeScreenStore, sqWorkbenchStore } from '@/core/core.stores';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { CELL_TYPES, Table, TableColumn } from '@/hybrid/core/Table.atom';
import Pagination from '@/hybrid/core/Pagination.organism';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { HomeScreenItemIconCell, NameCell } from '@/hybrid/homescreen/CellRender.atom';
import { TableLoadingIndicator } from '@/hybrid/core/TableLoadingIndicator.molecule';
import { logWarn } from '@/hybrid/utilities/logger';
import { cancelAll } from '@/hybrid/requests/pendingRequests.utilities';
import { HOME_SCREEN_SORT, HOME_SCREEN_TABLE_TYPE } from '@/hybrid/homescreen/homescreen.constants';
import { canModifyWorkbook } from '@/services/authorization.service';

export type HomeScreenItemType = {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  owner: { name: string };
};

const homeScreenTableBindings = bindingsDefinition({
  loadTable: prop<() => void>(),
  items: prop<HomeScreenItemType[]>(),
  tableType: prop.optional<string>(),
  isLoading: prop<boolean>(),
  showBreadcrumbs: prop<boolean>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
  $state: injected<ng.ui.IStateService>(),
});

export const HomeScreenTable: SeeqComponent<typeof homeScreenTableBindings> = (props) => {
  const { sqHomeScreenActions, sqHomeScreenUtilities, sqWorkbenchActions, $state } =
    useInjectedBindings(homeScreenTableBindings);
  const { isLoading, items, showBreadcrumbs, tableType, loadTable } = props;
  const { t } = useTranslation();
  const sortConfig: any = useFluxPath(sqHomeScreenStore, () =>
    sqHomeScreenStore.getSortForTable(tableType || HOME_SCREEN_TABLE_TYPE.TAB),
  );

  const sortField = sortConfig.sortProperty;
  const sortAsc = sortConfig.sortAsc;
  const supportSorting = tableType !== HOME_SCREEN_TABLE_TYPE.RECENT;
  const openingItemId = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.openingItemId);
  const isDisplayingTrash = useFluxPath(
    sqWorkbenchStore,
    () => sqHomeScreenStore.currentTab === HOME_SCREEN_TABS.TRASH,
  );
  const currentFolderId = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentFolderId);
  const resetLoadingId = () => sqWorkbenchActions.setOpeningAndLoadingItemId(null);
  const currentTab = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentTab);

  const open = (homeScreenItem, event?) => {
    if (sqHomeScreenUtilities.isFolder(homeScreenItem)) {
      cancelAll();
    }
    sqWorkbenchActions.setOpeningAndLoadingItemId(homeScreenItem.id);

    if (sqHomeScreenUtilities.isFolder(homeScreenItem)) {
      sqHomeScreenActions.setPageNumber(1, tableType);
      const folderId = homeScreenItem.id;
      if (folderId === null) {
        return $state.go(APP_STATE.WORKBOOKS).finally(resetLoadingId);
      }

      if (folderId !== currentFolderId) {
        return $state
          .go(APP_STATE.FOLDER_EXPANDED, { currentFolderId: folderId }, { reload: true })
          .finally(resetLoadingId);
      }

      logWarn(`Unhandled folder navigation for folder ID ${folderId}`);
      return resetLoadingId();
    } else if (sqHomeScreenUtilities.isProject(homeScreenItem)) {
      return sqHomeScreenUtilities.openProject(homeScreenItem.id, event).finally(resetLoadingId);
    } else {
      return sqHomeScreenUtilities
        .getWorkbook(homeScreenItem.id, {
          includeArchivedWorksheets: homeScreenItem.isArchived,
        })
        .then((workbook) => {
          const worksheetId = _.get(workbook, 'worksheets[0].worksheetId');
          const canModify = canModifyWorkbook(workbook);
          $state.goNewTab(
            canModify ? APP_STATE.WORKSHEET : APP_STATE.VIEW_WORKSHEET,
            {
              workbookId: homeScreenItem.id,
              worksheetId,
              currentFolderId: $state.params.currentFolderId,
            },
            event,
          );
        })
        .finally(resetLoadingId);
    }
  };

  const renderActions = (workbenchItem) => {
    const isFolder = sqHomeScreenUtilities.isFolder(workbenchItem);
    return (
      !workbenchItem.isUnmodifiable && (
        <div className="positionRelative nowrap" data-testid={`homeScreenActions_${workbenchItem.id}`}>
          <div className="homeScreenActions flexColumnContainer positionAbsolute">
            <HomeScreenItemActions
              item={workbenchItem}
              currentFolderId={currentFolderId}
              openAction={open}
              folderId={isFolder ? workbenchItem.id : currentFolderId}
              showOnlyFolderLinks={isFolder}
            />
          </div>
        </div>
      )
    );
  };

  const renderHeader = (title: string, sortProperty: string, noSort = false) => {
    const isSortable = !noSort && supportSorting;

    return (
      <div
        onClick={() =>
          isSortable
            ? sqHomeScreenActions.getSortedTableContents(sortProperty, tableType || HOME_SCREEN_TABLE_TYPE.TAB)
            : _.noop()
        }
        className={classNames('th', { cursorPointer: isSortable }, { 'sq-text-info': sortField === sortProperty })}>
        {t(title)}
        {isSortable && <SortIcon sortProperty={sortProperty} sortBy={sortField} sortAsc={sortAsc} />}
      </div>
    );
  };

  const renderRedactedOwner = (owner) => (
    <HoverTooltip placement="top" delay={500} text="HOME_SCREEN.REDACTED_OWNER_TOOLTIP">
      <div>
        <span className="fa fa-exclamation-triangle text-warning mr5" />
        {owner?.name}
      </div>
    </HoverTooltip>
  );

  const renderNonRedactedOwner = (owner) => <div>{owner?.name}</div>;

  const renderOwner = (owner) => {
    return owner?.isRedacted ? renderRedactedOwner(owner) : renderNonRedactedOwner(owner);
  };

  const onRowClick = (row, e) => {
    if (isDisplayingTrash && sqHomeScreenUtilities.isFolder(row)) {
      return;
    }
    open(row, e);
  };

  const columns: TableColumn[] = [
    {
      accessor: 'id',
      header: '',
      cellRenderFunction: (item: any) => <HomeScreenItemIconCell item={item} spinning={item.id === openingItemId} />,
      cellStyle: { width: 40 },
    },
    {
      accessor: 'name',
      headerRenderFunction: () => renderHeader('WORKBOOKS_SORT.NAME', HOME_SCREEN_SORT.NAME),
      cellRenderFunction: (item) => (
        <NameCell
          item={item}
          showBreadcrumbs={showBreadcrumbs}
          extraClassNames={(!isDisplayingTrash || !sqHomeScreenUtilities.isFolder(item)) && 'cursorPointer'}
        />
      ),
      cellStyle: { maxWidth: 150, minWidth: 120, wordWrap: 'break-word' },
    },
    {
      accessor: 'owner.name',
      headerRenderFunction: () => renderHeader('WORKBOOKS_SORT.OWNER', HOME_SCREEN_SORT.OWNER),
      cellRenderFunction: (row: any) => renderOwner(row.owner),
      cellStyle: { maxWidth: 150, minWidth: 120, wordWrap: 'break-word' },
    },
    {
      accessor: 'createdAt',
      headerRenderFunction: () => renderHeader('WORKBOOKS_SORT.CREATED_AT', HOME_SCREEN_SORT.CREATED_AT),
      cellType: CELL_TYPES.DATE_TIME,
      cellStyle: { maxWidth: 100, minWidth: 70 },
    },
    {
      accessor: 'updatedAt',
      headerRenderFunction: () => renderHeader('WORKBOOKS_SORT.UPDATED_AT', HOME_SCREEN_SORT.UPDATED_AT),
      cellType: CELL_TYPES.DATE_TIME,
      cellStyle: { maxWidth: 100, minWidth: 70 },
    },
    {
      accessor: 'sharedAt',
      headerRenderFunction: () => renderHeader('WORKBOOKS_SORT.SHARED_AT', HOME_SCREEN_SORT.SHARED_AT, true),
      cellType: CELL_TYPES.DATE_TIME,
      cellStyle: { maxWidth: 100, minWidth: 70 },
      excludeIf: currentTab !== HOME_SCREEN_TABS.SHARED,
    },
    {
      accessor: 'unused',
      header: '',
      cellRenderFunction: renderActions,
      cellStyle: { maxWidth: 100, minWidth: 100, padding: 0 },
    },
  ];

  const renderNoItems =
    isLoading ||
    (_.includes([HOME_SCREEN_TABLE_TYPE.PINNED, HOME_SCREEN_TABLE_TYPE.RECENT], tableType) &&
      _.isEmpty(items)) ? null : (
      <div className="flexRowContainer flexCenter homeScreenNoContent">{t('HOME_SCREEN.NO_TABLE_CONTENT')}</div>
    );

  const renderTable = (
    <div className="flexRowContainer min-height-200 height-maximum">
      <div>
        <Table columns={columns} items={items} tableClass="homeScreenTable" onRowClickCallback={onRowClick} />
      </div>
      <Pagination store={sqHomeScreenStore} actions={sqHomeScreenActions} tableType={tableType} loadTable={loadTable} />
    </div>
  );

  if (isLoading) {
    return <TableLoadingIndicator />;
  } else if (items) {
    return _.isEmpty(items) ? renderNoItems : renderTable;
  } else {
    return null;
  }
};
