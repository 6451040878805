import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { TrendActions } from '@/trendData/trend.actions';

import { LABEL_LOCATIONS } from '@/trendData/trendData.constants';
import { doTrack } from '@/track/track.service';

const signalLabelSelectionBindings = bindingsDefinition({
  sqTrendActions: injected<TrendActions>(),

  property: prop<string>(),
  labelValue: prop<string>(),
  trackLabel: prop<string>(),
  labelTitle: prop.optional<any>(),
});

export const SignalLabelSelection: SeeqComponent<typeof signalLabelSelectionBindings> = ({
  property,
  labelValue,
  trackLabel,
  labelTitle,
}) => {
  const { sqTrendActions } = useInjectedBindings(signalLabelSelectionBindings);
  const { t } = useTranslation();

  const options = [
    {
      id: 'labelOff',
      label: 'TOOLBAR.CHART_SIGNAL_LABELS_OFF',
      checked: labelValue === LABEL_LOCATIONS.OFF,
      value: labelValue,
      onChange: () => {
        sqTrendActions.setLabelDisplayConfiguration(property, LABEL_LOCATIONS.OFF);
        doTrack('Trend', `Signal Label - ${trackLabel}`, 'off');
      },
    },
    {
      id: 'labelLane',
      label: 'TOOLBAR.CHART_SIGNAL_LABELS_LANE',
      checked: labelValue === LABEL_LOCATIONS.LANE,
      value: labelValue,
      onChange: () => {
        sqTrendActions.setLabelDisplayConfiguration(property, LABEL_LOCATIONS.LANE);
        doTrack('Trend', `Signal Label - ${trackLabel}`, 'lane');
      },
    },
    {
      id: 'labelAxis',
      label: 'TOOLBAR.CHART_SIGNAL_LABELS_AXIS',
      checked: labelValue === LABEL_LOCATIONS.AXIS,
      value: labelValue,
      onChange: () => {
        sqTrendActions.setLabelDisplayConfiguration(property, LABEL_LOCATIONS.AXIS);
        doTrack('Trend', `Signal Label - ${trackLabel}`, 'axis');
      },
    },
  ];

  return (
    <div className="flexColumnContainer flexSpaceBetween flexBaseline pl20">
      <div>{t(labelTitle)}</div>
      <div className="btn-group flexColumnContainer flexSpaceBetween ml10">
        {_.map(options, (option) => (
          <Checkbox
            key={`${option.id}_checkbox`}
            classes={classNames('cursorPointer m5')}
            value={option.value}
            type="radio"
            id={`${property}-${option.id}`}
            label={option.label}
            isChecked={option.checked}
            onChange={option.onChange}
          />
        ))}
      </div>
    </div>
  );
};
