// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { CELL_TYPES, Table } from '@/hybrid/core/Table.atom';
import { ClearableInput } from '@/hybrid/core/ClearableInput.molecule';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { ItemAclModal } from '@/hybrid/accessControl/ItemAclModal.molecule';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { TableLoadingIndicator } from '@/hybrid/core/TableLoadingIndicator.molecule';
import { cancelDatasource, loadDatasources, setDatasourceEnabled } from '@/hybrid/utilities/datasourcesAdmin.utilities';

interface DatasourceTableProps {
  workbookId: string;
  worksheetId: string;
}

export const DatasourceTable: React.FunctionComponent<DatasourceTableProps> = ({ workbookId, worksheetId }) => {
  const { t } = useTranslation();

  useEffect(() => {
    refreshDatasources();
  }, []);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [sortProperty, setSortProperty] = useState('datasourceName');
  const [sortAsc, setSortAsc] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [datasources, setDatasources] = useState([]);
  const [itemAclId, setItemAclId] = useState<string | undefined>(undefined);

  const filteredDatasources = _.filter(datasources, (ds) => {
    return _.every(searchParams, (searchValue, columnName) => {
      if (_.isNil(searchValue) || searchValue === '') {
        return true;
      } else {
        return _.toString(ds[columnName]).toLowerCase().includes(_.toString(searchValue).toLowerCase());
      }
    });
  });
  const toLowerCase = (value) => (_.isString(value) ? value.toLowerCase() : value);
  const sortedFilteredDatasources = _.orderBy(
    filteredDatasources,
    [(ds) => toLowerCase(ds[sortProperty])],
    [sortAsc ? 'asc' : 'desc'],
  );

  const timingCellRenderFunction = (item, accessor) => {
    let timingDetails;
    if (item.medianTotalSeconds <= 0) {
      timingDetails = t('ADMIN.DATASOURCE.NO_REQUESTS');
    } else {
      timingDetails =
        `${item.medianQueuedSeconds} ${t('ADMIN.DATASOURCE.DURATIONS.QUEUE')}, ` +
        `${item.medianDatasourceSeconds} ${t('ADMIN.DATASOURCE.DURATIONS.DATASOURCE')}, ` +
        `${item.medianTotalSeconds} ${t('ADMIN.DATASOURCE.TOTAL')}`;
    }
    return (
      <HoverTooltip delay={500} placement="top" text={timingDetails}>
        <span>{item[accessor]}</span>
      </HoverTooltip>
    );
  };

  const numRequestsCellRenderFunction = (item, accessor) => {
    let numRequestsDetails;
    if (item.medianTotalSeconds <= 0) {
      numRequestsDetails = t('ADMIN.DATASOURCE.NO_REQUESTS');
    } else {
      numRequestsDetails =
        `${item.numSuccesses} ${t('ADMIN.DATASOURCE.COUNTS.SUCCESSES')}, ` +
        `${item.numFailures} ${t('ADMIN.DATASOURCE.COUNTS.FAILURES')}, ` +
        `${item.numTimeouts} ${t('ADMIN.DATASOURCE.COUNTS.TIMEOUTS')}, ` +
        `${item.numCancellations} ${t('ADMIN.DATASOURCE.COUNTS.CANCELLATIONS')}, ` +
        `${item.numTotal} ${t('ADMIN.DATASOURCE.TOTAL')}`;
    }
    return (
      <HoverTooltip delay={500} placement="top" text={numRequestsDetails}>
        <span>{item[accessor]}</span>
      </HoverTooltip>
    );
  };

  // A bit of a hack: in order to get the column headers to line up,
  // while some columns have a filter input box and others don't,
  // we add a disabled filter input box for the ones that don't make sense to filter.
  const renderDisabledFilterHeader = () => (
    <ClearableInput field={undefined} searchValue={undefined} filterTable={() => {}} disabled={true} />
  );

  const columns = [
    {
      accessor: 'datasourceGuid',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40 },
    },
    {
      accessor: 'datasourceName',
      searchProperty: 'datasourceName',
      header: 'ADMIN.DATASOURCE.NAME',
    },
    {
      accessor: 'datasourceType',
      searchProperty: 'datasourceType',
      header: 'ADMIN.DATASOURCE.TYPE',
      cellRenderFunction: (item, accessor) => t(item[accessor]),
    },
    {
      accessor: 'datasourceClass',
      searchProperty: 'datasourceClass',
      header: 'ADMIN.DATASOURCE.DATASOURCE_CLASS',
    },
    {
      accessor: 'datasourceId',
      searchProperty: 'datasourceId',
      header: 'ADMIN.DATASOURCE.DATASOURCE_ID',
    },
    {
      accessor: 'enabled',
      searchProperty: 'enabled',
      header: 'ADMIN.DATASOURCE.ENABLED',
      cellType: CELL_TYPES.CHECKMARK,
    },
    {
      accessor: 'lastQueriedDatetime',
      searchProperty: 'lastQueriedDatetime',
      header: 'ADMIN.DATASOURCE.LAST_QUERIED',
      cellStyle: { minWidth: 200 }, // ISO style dates are long...
    },
    {
      accessor: 'medianTotalSeconds',
      searchProperty: 'medianTotalSeconds',
      headerFilterFunction: renderDisabledFilterHeader,
      header: 'ADMIN.DATASOURCE.REQUEST_DURATION',
      cellRenderFunction: timingCellRenderFunction,
    },
    {
      accessor: 'numTotal',
      searchProperty: 'numTotal',
      headerFilterFunction: renderDisabledFilterHeader,
      header: 'ADMIN.DATASOURCE.NUM_REQUESTS',
      cellRenderFunction: numRequestsCellRenderFunction,
    },
    {
      accessor: 'totalNumDatums',
      searchProperty: 'totalNumDatums',
      headerFilterFunction: renderDisabledFilterHeader,
      header: 'ADMIN.DATASOURCE.NUM_DATUMS',
    },
  ];
  const sortableColumns = _.map(columns, (column) => _.assign({ sortable: true, filterable: true }, column));

  const refreshDatasources = () => {
    setIsRefreshing(true);

    return loadDatasources()
      .then((statuses) => {
        setDatasources(statuses);
      })
      .finally(() => {
        setIsRefreshing(false);
        setSelectedItems([]);
      });
  };

  const filterCallback = (option, field) => {
    setSearchParams({ ...searchParams, [field]: option.value });
  };

  const sortCallback = (field, oldSortOrder) => {
    setSortProperty(field);
    setSortAsc(!oldSortOrder);
  };

  const manageSelectedIds = (item) => {
    const itemIndex = _.indexOf(selectedItems, item);
    if (itemIndex > -1) {
      // Un-select an datasource
      setSelectedItems([]);
    } else {
      // Select a datasource. This table only allows one datasource to be selected.
      setSelectedItems([item]);
    }
  };

  const isAnyDatasourceSelected = () => selectedItems.length > 0;
  const isSystemDatasourceSelected = () => _.some(selectedItems, 'seeqInternal');

  const enableDatasource = () => {
    setIsRefreshing(true);

    return setDatasourceEnabled(selectedItems[0], true).finally(() => {
      refreshDatasources();
    });
  };

  const disableDatasource = () => {
    setIsRefreshing(true);

    return setDatasourceEnabled(selectedItems[0], false).finally(() => {
      refreshDatasources();
    });
  };

  const manageDatasourceAcl = () => {
    setItemAclId(selectedItems[0].datasourceGuid);
  };

  const handleCancelDatasourceClick = () => {
    setIsRefreshing(true);

    return cancelDatasource(selectedItems[0]).finally(() => {
      refreshDatasources();
    });
  };

  return (
    <div className="height-maximum">
      <div className="flexColumnContainer flexSpaceBetween mb5">
        <div className="flexColumnContainer">
          <TextButton
            id="enableSelectedDatasource"
            extraClassNames="mr5"
            onClick={enableDatasource}
            disabled={!isAnyDatasourceSelected() || isSystemDatasourceSelected() || isRefreshing}
            label="ADMIN.DATASOURCE.ENABLE_DATASOURCE"
          />
          <TextButton
            id="disableSelectedDatasource"
            extraClassNames="mr5"
            onClick={disableDatasource}
            disabled={!isAnyDatasourceSelected() || isSystemDatasourceSelected() || isRefreshing}
            label="ADMIN.DATASOURCE.DISABLE_DATASOURCE"
          />
          <TextButton
            id="openAclForSelectedDatasource"
            extraClassNames="mr5"
            onClick={manageDatasourceAcl}
            disabled={!isAnyDatasourceSelected() || isSystemDatasourceSelected() || isRefreshing}
            label="ADMIN.DATASOURCE.ACCESS_CONTROL"
          />
          <TextButton
            id="cancelAllRequestsForDatasource"
            extraClassNames="mr5"
            onClick={handleCancelDatasourceClick}
            disabled={!isAnyDatasourceSelected() || isRefreshing}
            label="ADMIN.DATASOURCE.CANCEL_ALL"
          />
        </div>
        <ButtonWithManagedSpinner
          buttonProps={{ id: 'refreshDatasources' }}
          action={refreshDatasources}
          icon="fa-repeat"
          spinnerIconProps={{ large: true, type: 'text' }}
          label="ADMIN.DATASOURCE.REFRESH"
        />
      </div>

      <div className="height-maximum width-maximum overflowAuto pb70">
        <Table
          testId="datasourceAdministrationTable"
          sortProperty={sortProperty}
          sortAscending={sortAsc}
          sortTableCallback={sortCallback}
          filterTableCallback={filterCallback}
          onRowSelectCallback={manageSelectedIds}
          selectedIds={_.map(selectedItems, 'datasourceGuid')}
          columns={sortableColumns}
          items={sortedFilteredDatasources}
          selectAllCallback={undefined}
          selectAll={false}
          searchParams={searchParams}
        />
        {isRefreshing && <TableLoadingIndicator />}
      </div>

      {itemAclId && (
        <ItemAclModal
          itemId={itemAclId}
          closeModal={() => setItemAclId(undefined)}
          workbookId={workbookId}
          worksheetId={worksheetId}
          includeLinksAndCorporateMessage={false}
        />
      )}
    </div>
  );
};
