// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ReportActions } from '@/reportEditor/report.actions';
import _ from 'lodash';
import { ReportConfigDateRangeGroup } from '@/hybrid/reportConfig/components/shared/ReportConfigDateRangeGroup.molecule';
import { formatMessage } from '@/hybrid/utilities/logger.utilities';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';

const reportConfigFixedDateRangeTableBindings = bindingsDefinition({
  timezone: prop<string>(),
  viewOnly: prop.optional<boolean>(),
  openDateSelector: prop<(dateRange: DateRange, isCapsuleSelect: boolean, checkFixed: boolean) => void>(),
  withDateRangeUpdating: prop<(action: Function) => void>(),
  dateRanges: prop<DateRange[]>(),
  areDateRangesReadOnly: prop<boolean>(),
  openBulkEditWithGivenRange: prop<(dateRange: DateRange) => void>(),
  sqReportActions: injected<ReportActions>(),
});

export const ReportConfigFixedDateRangeTable: SeeqComponent<typeof reportConfigFixedDateRangeTableBindings> = (
  props,
) => {
  const { sqReportActions } = useInjectedBindings(reportConfigFixedDateRangeTableBindings);

  const {
    timezone,
    openDateSelector,
    withDateRangeUpdating,
    viewOnly = false,
    dateRanges,
    areDateRangesReadOnly,
    openBulkEditWithGivenRange,
  } = props;

  const { t } = useTranslation();

  const fixedDateRanges = _.chain(dateRanges)
    .reject((dateRange) => !dateRange.auto || dateRange.auto.enabled)
    .sortBy('name')
    .value();

  /**
   * Moves all fixed date ranges to the latest time
   */
  const stepAllFixedDateRangesToEnd = () => {
    doTrack('Topic', 'DateRange Tool', 'edit - step all fixed to end');
    withDateRangeUpdating(() =>
      Promise.all(
        _.chain(fixedDateRanges)
          .reject((range) => range.condition.isCapsuleFromTable)
          .map((dateRange) => stepDateRangeToEndWithErrorHandling(dateRange))
          .value(),
      ),
    );
  };

  const decoratedStepAll = () => {
    const onAction = () => setTellChildToFireStep(false);
    const onSandbox = () => setTellChildToFireStep(true);
    sqReportActions.doActionElseActivateSandbox(stepAllFixedDateRangesToEnd, onAction, onSandbox)();
  };
  const [tellChildToFireStep, setTellChildToFireStep] = useState(false);

  /**
   * Moves a date range to the latest time
   *
   * @param {DateRange} dateRange - the date range to adjust
   */
  const stepDateRangeToEnd = (dateRange: DateRange) => {
    doTrack('Topic', 'Date Range Tool', 'edit - step individual fixed to end');
    withDateRangeUpdating(() => stepDateRangeToEndWithErrorHandling(dateRange));
  };

  /**
   * Wrapper around `sqReportActions.stepDateRangeToEnd` to handle the special case of a capsule not being found
   *
   * @param {DateRange} dateRange - the date range to adjust
   */
  const stepDateRangeToEndWithErrorHandling = (dateRange: DateRange) => {
    return sqReportActions.stepDateRangeToEnd(dateRange.id).catch((e: unknown) => {
      if (_.includes(formatMessage`${e}`, 'No Capsule found')) {
        errorToast({
          messageKey: 'REPORT.CONFIG.STEP_TO_END_CAPSULE_NOT_FOUND',
          messageParams: { dateVariableName: dateRange.name },
        });
      } else {
        errorToast({ httpResponseOrError: e });
        return Promise.reject(e);
      }
    });
  };

  const onClickAdd = () => {
    doTrack('Topic', 'Date Range Tool', 'new - fixed plus icon');
    openDateSelector(undefined, false, true);
  };

  return (
    <div className="flexRowContainer pt5 pb5" data-testid="fixedDateRangeTable">
      <ReportConfigDateRangeGroup
        triggerStepFunctionOnLoad={tellChildToFireStep}
        timezone={timezone}
        onClickStep={decoratedStepAll}
        onClickAdd={onClickAdd}
        dateRanges={fixedDateRanges}
        isAutoUpdate={false}
        viewOnly={viewOnly}
        areDateRangesReadOnly={areDateRangesReadOnly}
        openDateSelector={openDateSelector}
        stepDateRangeToEnd={stepDateRangeToEnd}
        openBulkEditWithGivenRange={openBulkEditWithGivenRange}
      />
    </div>
  );
};
