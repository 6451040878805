// @ts-strict-ignore
import { base64guid } from '@/hybrid/utilities/utilities';

let sessionId = null;

/**
 * Returns a new interactive session ID, which is just a GUID in base64
 *
 * @returns {String} interactive session ID
 */
export function generateInteractiveSessionId() {
  sessionId = base64guid();
  return sessionId;
}

/**
 * Returns a new interactive session ID, which is just a GUID in base64
 * If regenerate is set to true then a new sessionId will be generated.
 *
 * @returns {String} interactive session ID
 */
export function getSessionId(regenerate = false) {
  if (!sessionId || regenerate) {
    sessionId = generateInteractiveSessionId();
  }
  return sessionId;
}
