// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { XyPlotChartWrapper, XyPlotChartWrapperProps } from '@/hybrid/scatterPlot/XyPlotChartWrapper.organism';

export interface XyPlotContentProps extends XyPlotChartWrapperProps {
  onContentLoad: () => void;
}

/**
 * In order to match some of the functionality in the proper ScatterPlot visualization, we need to provide certain
 * properties to the underlying components (such as the current xValue of the chart to the ScatterPlot minimap).
 * This wraps the XyPlotChartWrapper component and passes that needed state to it.
 */
export const XyPlotContent: React.FunctionComponent<XyPlotContentProps> = (props) => {
  const { onContentLoad } = props;

  const [xValue, setXValue] = useState(null);

  useEffect(() => onContentLoad(), []);

  return <XyPlotChartWrapper {...props} xValue={xValue} setPointerValues={setXValue} isInTopic={true} />;
};
