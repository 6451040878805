// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ButtonWithPopover } from '@/hybrid/core/ButtonWithPopover.molecule';
import _ from 'lodash';

interface TableColumnMenuProps {
  columnActions: any[];
  columnKey: string;
}

/**
 * A three-dot "kebab" menu, with a dropdown. menuActions are dropdown options that are passed in as arguments.
 */
export const TableColumnMenu: React.FunctionComponent<TableColumnMenuProps> = ({ columnActions, columnKey }) => {
  const [show, setShow] = useState({});
  const updateState = (key, value) => {
    setShow(_.assign({}, show, { [key]: value }));
  };

  const { t } = useTranslation();
  const showDropdown = (key) => updateState(key, !show[key]);
  const hideDropdown = (key) => updateState(key, false);

  const renderNavDropdownItem = (menuEntry, parentKey = undefined) => {
    return (
      <NavDropdown.Item
        disabled={menuEntry.disabled}
        key={`dropdown_${menuEntry.translationKey}`}
        onClick={() => {
          menuEntry.action();
          if (!_.isUndefined(parentKey)) {
            hideDropdown(parentKey);
          }
        }}
        className="textPrimaryMenuItem"
        data-testid={`actions_${menuEntry.translationKey}`}>
        <Icon icon={menuEntry.iconClass} extraClassNames="pr10 fa-fw" type="inherit" />
        <span className="forceFont">{t(menuEntry.translationKey)}</span>
      </NavDropdown.Item>
    );
  };

  const renderSimpleMenuEntry = (menuEntry, parentKey = undefined) => {
    return !menuEntry.tooltip ? (
      renderNavDropdownItem(menuEntry, parentKey)
    ) : (
      <HoverTooltip key={`tooltipContainer_${menuEntry.translationKey}`} text={menuEntry.tooltip}>
        <div>
          {/* the tooltip does not appear for disabled entries if we do not have the div*/}
          {renderNavDropdownItem(menuEntry, parentKey)}
        </div>
      </HoverTooltip>
    );
  };

  const renderMenuEntry = (menuEntry) => {
    return (
      <NavDropdown
        title={
          <>
            <Icon icon={menuEntry.iconClass} extraClassNames="pr10 fa-fw" type="inherit" />
            <span className="forceFont">{t(menuEntry.translationKey)}</span>
          </>
        }
        id={`dropdown_${menuEntry.translationKey}`}
        key={`dropdown_${menuEntry.translationKey}`}
        drop="right"
        className="textPrimaryMenuItem"
        data-testid={`actions_${menuEntry.translationKey}`}
        show={show[menuEntry.translationKey]}
        onMouseEnter={() => showDropdown(menuEntry.translationKey)}
        onMouseLeave={() => hideDropdown(menuEntry.translationKey)}>
        {_.map(menuEntry.subMenuItems, (submenuItem) => renderSimpleMenuEntry(submenuItem, menuEntry.translationKey))}
      </NavDropdown>
    );
  };
  const renderDropdownEntry = (menuEntry) => {
    return menuEntry.subMenuItems && !menuEntry.disabled
      ? // workaround because the disable property of the NavDropdown does not work
        renderMenuEntry(menuEntry)
      : renderSimpleMenuEntry(menuEntry);
  };

  return (
    <>
      <ButtonWithPopover
        label={
          <div className="sq-icon-hover">
            <Icon icon="fc-more" extraClassNames="fa-fw mt2" type="gray" testId="columnMoreActions" />
          </div>
        }
        popoverConfig={{
          id: `dropdown-${columnKey}`,
          placement: 'bottom-end',
        }}>
        {_.map(columnActions, (action) => renderDropdownEntry(action))}
      </ButtonWithPopover>
    </>
  );
};
