import React, { useState } from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { useTranslation } from 'react-i18next';
import { JournalReplyIcon } from '@/hybrid/annotation/JournalReplyIcon.atom';
import { JournalComment, JournalCommentData } from '@/hybrid/annotation/JournalComment.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { doTrack } from '@/track/track.service';

interface JournalCommentsProps {
  id: string;
  isExpanded: boolean;
  isCommentsExpanded: boolean;
  isCommentAllowed: boolean;
  comments: JournalCommentData[];
  isPresentationMode: boolean;
  updateComment: (id: string, name: string) => void;
  deleteComment: (id: string) => void;
  canManage: (commentData: object) => boolean;
  customSaveComment: (id: string, commentText: string) => void;
  customExpandComments: (expand: boolean) => void;
}

export const JournalComments: React.FunctionComponent<JournalCommentsProps> = ({
  id,
  isExpanded,
  isCommentsExpanded,
  comments = [],
  isPresentationMode,
  isCommentAllowed,
  customExpandComments,
  customSaveComment,
  updateComment,
  deleteComment,
  canManage,
}) => {
  const { t } = useTranslation();
  const [commentText, setCommentText] = useState('');
  const [isCommenting, setIsCommenting] = useState(false);

  const onSaveComment = () => {
    customSaveComment(id, commentText);
    setIsCommenting(false);
    setCommentText('');
  };

  const onNewComment = () => {
    doTrack('Journal', 'Comments', 'new');
    setIsCommenting(true);
  };

  return (
    <div className="flexFill flexColumnContainer flexBasisAuto">
      {isExpanded && (
        <div
          data-testid="specToggleCommentsBtn"
          className="flexColumnContainer flexCenter toggleVisibilityBar"
          onClick={() => customExpandComments(!isCommentsExpanded)}>
          <Icon
            icon={isCommentsExpanded ? 'fa-angle-double-right' : 'fa-angle-double-left'}
            extraClassNames="pt10 pb10"
            tooltipPlacement="left"
            tooltip={isCommentsExpanded ? 'JOURNAL.TOOLTIP.COLLAPSE_COMMENTS' : 'JOURNAL.TOOLTIP.EXPAND_COMMENTS'}
          />
        </div>
      )}
      <div
        className={classNames('flexFill flexRowContainer flexBasisAuto dividerBorderTop', {
          'animateWidth250Linear': isExpanded,
          'animateHeight250Linear': !isExpanded,
          'height-300': !isExpanded && isCommentsExpanded,
          'height-25': !isExpanded && !isCommentsExpanded,
          'width-340 max-width-340': isExpanded && isCommentsExpanded,
          'width-0 max-width-0 pl0 pr0': isExpanded && !isCommentsExpanded,
        })}>
        <div
          data-testid="specCommentPanelToggle"
          className="height-25 cursorPointer flexColumnContainer flexAlignCenter flexNoGrowNoShrink hoverHighlightPanel pl5 pr5 mb5"
          onClick={() => customExpandComments(!isCommentsExpanded)}>
          <span className="sq-text-primary">{t('JOURNAL.ENTRY.COMMENTS')}</span>
          <div className="flexFill flexColumnContainer">
            {!isExpanded && (
              <Icon
                icon={isCommentsExpanded ? 'fa-chevron-down' : 'fa-chevron-right'}
                extraClassNames="mr25 sq-text-primary pl5 pt2"
                testId="journalCommentExpandedIcon"
                tooltip={isCommentsExpanded ? 'JOURNAL.TOOLTIP.COLLAPSE_COMMENTS' : 'JOURNAL.TOOLTIP.EXPAND_COMMENTS'}
              />
            )}
          </div>
          <JournalReplyIcon count={comments.length} />
        </div>
        <div
          className={classNames(
            'flexFillOverflow msOverflowStyleAuto flexRowContainer overflowAuto flexBasisZero pl5 pr5',
            {
              flexCenter: !comments.length,
            },
          )}>
          {!comments.length && (
            <div data-testid="specNoCommentText" className="sq-darkish-gray width-250 text-center">
              {t('JOURNAL.ENTRY.NO_COMMENTS_PLACEHOLDER')}
            </div>
          )}
          {comments.map((comment) => (
            <div key={comment.id}>
              <JournalComment
                comment={comment}
                isPresentationMode={isPresentationMode}
                updateComment={updateComment}
                deleteComment={deleteComment}
                canManage={canManage}
              />
            </div>
          ))}
        </div>
        {isCommenting && <div className="mt7 pb5 lightGreyBorderTop" />}
        {isCommenting && isCommentsExpanded && !isPresentationMode && (
          <div
            className="card card-primary flexRowContainer flexNoGrowNoShrink pl5 pr5"
            data-testid="specNewCommentEditor">
            <div className="card-body pl8 pt8 flexNoGrowNoShrink flexRowContainer">
              <div className="commentNameEdit flexNoGrowNoShrink flexRowContainer">
                <Form.Group controlId="specNewCommentEditor">
                  <Form.Control
                    autoFocus={true}
                    as="textarea"
                    data-testid="specTextarea"
                    rows={3}
                    className="form-control textAreaResizeNone overflowYAuto"
                    value={commentText}
                    onChange={(event) => setCommentText(event.currentTarget.value)}
                  />
                </Form.Group>
                <div className="pt5 pr0 pb0 text-right">
                  <TextButton
                    testId="specCancelNewCommentBtn"
                    extraClassNames="mr5 min-width-50"
                    size="sm"
                    onClick={() => setIsCommenting(false)}
                    label="CANCEL"
                  />
                  <TextButton
                    testId="specSaveNewCommentBtn"
                    extraClassNames="min-width-50"
                    size="sm"
                    variant="theme"
                    onClick={onSaveComment}
                    disabled={!commentText}
                    label="SAVE"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {isCommentsExpanded && (
          <div className="flexColumnContainer flexNoGrowNoShrink flexAlignEnd animateHeight125Linear p5">
            {isExpanded && (
              <a
                data-testid="specCollapseBtn"
                className="flexColumnContainer flexCenter cursorPointer link-no-underline"
                onClick={() => customExpandComments(!isCommentsExpanded)}>
                <div>
                  {t('COLLAPSE')}
                  <Icon icon="fa-chevron-right" extraClassNames="pl3" tooltip="JOURNAL.TOOLTIP.COLLAPSE_COMMENTS" />
                </div>
              </a>
            )}

            <div className="flexFill" />
            {!isPresentationMode && isCommentAllowed && (
              <a
                href="#"
                data-testid="specNewCommentBtn"
                className="flexColumnContainer flexAlignCenter text-right link-no-underline"
                onClick={onNewComment}>
                <div>
                  {t('JOURNAL.ENTRY.NEW_COMMENT')}
                  <Icon
                    icon="fc-comment"
                    extraClassNames="small pl5"
                    tooltipPlacement="left"
                    tooltip="JOURNAL.TOOLTIP.ADD_COMMENT"
                  />
                </div>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
