import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';

export type RadioButtonOption = {
  id: string;
  label: string | React.ReactNode;
  checked: boolean;
  onToggle: () => void;
  tooltip?: string;
  description?: string;
  disabled?: boolean;
};

type RadioButtonGroupProps = {
  options: RadioButtonOption[];
  gridLayout?: boolean;
  verticalLayout?: boolean;
  extraClassNames?: string;
  checkboxExtraClassNames?: string;
  onChange?: (option?: RadioButtonOption) => void;
};

/** Manage and display a group of radio buttons in a grid, vertical or horizontal (default) layout */
export const RadioButtonGroup: React.FunctionComponent<RadioButtonGroupProps> = ({
  options,
  gridLayout = false,
  verticalLayout,
  extraClassNames,
  checkboxExtraClassNames,
  onChange,
}) => {
  return (
    <div
      className={classNames(extraClassNames, {
        flexWrap: gridLayout,
        flexAlignCenter: !verticalLayout,
        flexColumnContainer: !verticalLayout,
        flexRowContainer: verticalLayout,
      })}>
      {_.map(options, (option) => (
        <Checkbox
          key={`${option.id}_checkbox`}
          classes={classNames('mr15', { 'width-100': gridLayout, 'mb0': verticalLayout }, checkboxExtraClassNames)}
          type="radio"
          id={option.id}
          label={option.label}
          isChecked={option.checked}
          disabled={option.disabled}
          tooltip={option.tooltip}
          description={option.description}
          value={option.id}
          onChange={() => {
            onChange?.(option);
            option.onToggle();
          }}
        />
      ))}
    </div>
  );
};
