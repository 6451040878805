// @ts-strict-ignore
import React, { useState } from 'react';
import { isPresentationWorkbookMode } from '@/hybrid/utilities/utilities';
import { BottomPanels } from '@/hybrid/trend/panels/bottomPanels/BottomPanels.organism';
import { TreemapWrapper } from '@/hybrid/treemap/TreemapWrapper.molecule';
import { angularComponent } from '@/hybrid/core/react2angular.util';

export const Treemap: React.FunctionComponent = () => {
  const [elementHeight, setElementHeight] = useState(null);
  const isPresentationMode = isPresentationWorkbookMode();

  return (
    <div
      className="treemapPage flexFill flexRowContainer resizablePanelContainer"
      data-testid="treemap"
      ref={(element) => setElementHeight(Number(element?.clientHeight))}>
      <TreemapWrapper />
      {!isPresentationMode && <BottomPanels hideCapsulesPanel={true} parentHeight={elementHeight} />}
    </div>
  );
};

export const sqTreemap = angularComponent(null, Treemap);
