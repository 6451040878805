// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useDatasources } from '@/hybrid/administration/datasources/datasources.hook';
import { useTranslation } from 'react-i18next';
import { FormWarning } from '@/hybrid/core/FormWarning.atom';
import {
  DatasourcesService,
  FilterParameters,
  ManageDatasourceParams,
} from '@/hybrid/administration/datasources/datasources.service';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { setActiveTabIndex, setSearchParams } from '@/administration/administration.actions';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import _ from 'lodash';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { DatasourcesFilterHeader } from '@/hybrid/administration/datasources/DatasourcesFilterHeader.molecule';
import { DatasourceCard } from '@/hybrid/administration/datasources/DatasourceCard.molecule';
import { ItemAclModal } from '@/hybrid/accessControl/ItemAclModal.molecule';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ManageConnectionModal } from '@/hybrid/administration/datasources/ManageConnectionModal';
import { ConnectionStatusOutputV1 } from '@/sdk';
import { ManageDatasourceModal } from '@/hybrid/administration/datasources/ManageDatasourceModal.molecule';
import { AGENTS_TAB_INDEX } from '@/administration/administration.constants';

const datasourcesTabBindings = bindingsDefinition({
  sqDatasourcesService: injected<DatasourcesService>(),
  workbookId: prop<string>(),
  worksheetId: prop<string>(),
});

export const DatasourcesTab: SeeqComponent<typeof datasourcesTabBindings> = ({ workbookId, worksheetId }) => {
  const { sqDatasourcesService } = useInjectedBindings(datasourcesTabBindings);

  const datasources = useDatasources();
  const { t } = useTranslation();

  const [filterParams, setFilterParams] = useState<FilterParameters>({
    name: '',
    datasourceClass: '',
    datasourceId: '',
    agentName: '',
    status: '',
  });
  const [datasourceAclId, setDatasourceAclId] = useState<string | undefined>(undefined);
  const [datasourcesExpansionStatus, setDatasourcesExpansionStatus] = useState(new Map());
  const [manageDatasourceParams, setManageDatasourceParams] = useState<ManageDatasourceParams | void>(undefined);
  const [connectionModalShown, setConnectionModalShown] = useState(false);
  const [isNewConnection, setIsNewConnection] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState<ConnectionStatusOutputV1>();

  const updateDatasourcesExpansionStatus = (datasourceIdKey, expansionState) => {
    setDatasourcesExpansionStatus((prev) => new Map(prev).set(datasourceIdKey, expansionState));
  };

  // Handlers
  const setFilterParam = (field, value) => {
    setFilterParams(_.assign({}, filterParams, { [field]: _.isEmpty(value) ? '' : value }));
  };

  const manageDatasource = (id: string) => {
    return sqDatasourcesService.fetchManageDatasourceParams(id).then((params) => {
      setManageDatasourceParams(params);
    });
  };

  const manageConnection = (connection) => {
    const isConnectionNotProvided = _.isNull(connection);
    const selectedConnection = isConnectionNotProvided ? { agentName: '', connectionId: '', name: '' } : connection;
    setIsNewConnection(isConnectionNotProvided);
    setSelectedConnection(selectedConnection);
    setConnectionModalShown(true);
    return Promise.resolve();
  };

  // Renderers
  const renderDatasourcesTable = () => {
    const disconnectedAgentsCount = sqDatasourcesService.countDisconnectedAgents(datasources.agents);
    const filteredDatasources = sqDatasourcesService.filterAndSortDatasources(datasources.datasources, filterParams);

    const renderDatasourcesCounts = () => (
      <>
        <div className="col-form-label font-weight-bold mr5" data-testid="datasourcesTabDatasourcesLabel">
          {t('ADMIN.DATASOURCES.DATASOURCES')}
        </div>
        <div className="col-form-label mr5" data-testid="datasourcesTabDatasourcesCounts">
          {datasources.connectedDatasourcesCount} / {datasources.totalDatasourcesCount}
        </div>
      </>
    );

    const renderConnectionsCounts = () => {
      if (
        datasources.connectedConnectionsCount === datasources.connectedDatasourcesCount &&
        datasources.totalConnectionsCount === datasources.totalDatasourcesCount
      ) {
        return null;
      }

      return (
        <>
          <div className="col-form-label font-weight-bold mr5" data-testid="datasourcesTabConnectionsLabel">
            {t('ADMIN.DATASOURCES.CONNECTIONS')}
          </div>
          <div className="col-form-label mr5" data-testid="datasourcesTabConnectionsCounts">
            {datasources.connectedConnectionsCount} / {datasources.totalConnectionsCount}
          </div>
        </>
      );
    };

    // TODO CRAB-24382 - Remove when development finishes
    // const stateForDebug = JSON.stringify(datasources, null, 2);

    return (
      <>
        {disconnectedAgentsCount > 0 && (
          <div className="mb10">
            <FormWarning
              warningText={t('ADMIN.DATASOURCES.AGENTS_DISCONNECTED_WARNING', {
                count: _.toNumber(disconnectedAgentsCount),
              })}
              onClick={() => {
                setActiveTabIndex(AGENTS_TAB_INDEX);
                setSearchParams({
                  field: 'status',
                  value: SeeqNames.Connectors.Connections.Status.Disconnected,
                });
              }}
              extraClassNames="sq-alert-warning p5 cursorPointer agentsWarning"
            />
          </div>
        )}

        <div className="flexColumnContainer mb10">
          {renderDatasourcesCounts()}

          {renderConnectionsCounts()}

          <div className="flexFill" />

          <TextButton
            testId="addConnectionButton"
            size="sm"
            label="ADMIN.DATASOURCES.ADD_CONNECTION"
            type="button"
            variant="theme"
            onClick={() => manageConnection(null)}
            icon="fa-plus"
            iconStyle="white"
          />
        </div>

        <DatasourcesFilterHeader
          filterParams={filterParams}
          filterCallback={setFilterParam}
          datasources={datasources.datasources}
        />

        <div className="flexRowContainer flexFillOverflow overflowAuto pr2">
          {_.map(filteredDatasources, (ds) => (
            <DatasourceCard
              key={ds.id}
              datasource={ds}
              agents={datasources.agents}
              onAclManage={() => setDatasourceAclId(ds.id)}
              onDatasourceManage={() => manageDatasource(ds.id)}
              onConnectionManage={manageConnection}
              onExpand={() => updateDatasourcesExpansionStatus(ds.id, !datasourcesExpansionStatus.get(ds.id))}
              expanded={datasourcesExpansionStatus.get(ds.id)}
            />
          ))}
        </div>

        {datasourceAclId && (
          <ItemAclModal
            itemId={datasourceAclId}
            closeModal={() => setDatasourceAclId(undefined)}
            workbookId={workbookId}
            worksheetId={worksheetId}
            includeLinksAndCorporateMessage={false}
          />
        )}

        {manageDatasourceParams && (
          <ManageDatasourceModal
            id={manageDatasourceParams.id}
            name={manageDatasourceParams.name}
            indexingScheduleSupported={manageDatasourceParams.indexingScheduleSupported}
            indexingFrequency={manageDatasourceParams.indexingFrequency}
            nextScheduledIndexAt={manageDatasourceParams.nextScheduledIndexAt}
            onClose={() => setManageDatasourceParams(undefined)}
          />
        )}

        {connectionModalShown && (
          <ManageConnectionModal
            selectedConnection={selectedConnection}
            agents={datasources.agents}
            isNew={isNewConnection}
            onClose={() => setConnectionModalShown(false)}
          />
        )}
      </>
    );
  };

  return !_.isNil(datasources) ? (
    renderDatasourcesTable()
  ) : (
    <div className="flexColumnContainer flexCenter pt50 pb50">
      <IconWithSpinner spinning={true} testId="datasourcesTabSpinner" />
    </div>
  );
};

export const sqDatasourcesTab = angularComponent(datasourcesTabBindings, DatasourcesTab);
