// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import _ from 'lodash';
import classNames from 'classnames';
import { axesToYs } from '@/scatterPlot/scatterPlot.store';
import { ChartRegion } from '@/chart/chart.module';
import { ScatterPlotChart, ScatterPlotChartExternalProps } from '@/hybrid/scatterPlot/ScatterPlotChart.molecule';
import { Visualization } from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities.constants';
import useResizableHighchart from '@/hybrid/core/hooks/useResizableHighchart.hook';
import { ScatterPlotMinimap, ScatterPlotMinimapProps } from '@/hybrid/scatterPlot/minimap/Minimap.molecule';
import { DisplayRangeSelector } from '@/hybrid/trend/DisplayRangeSelector.molecule';
import { findChartSeries, mouseLeftActualChartArea } from '@/hybrid/utilities/chartHelper.utilities';
import ScatterPlotLegend, { ScatterPlotLegendProps } from '@/hybrid/scatterPlot/ScatterPlotLegend.organism';
import { drawSelectedRegion } from '@/hybrid/utilities/chartSelection.utilities';
import { DensityPlotLegend } from '@/hybrid/scatterPlot/DensityPlotLegend.organism';
import {
  provideVisualizationData,
  SubVisualization,
} from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities';
import { DensityPlotChart, DensityPlotChartExternalProps } from '@/hybrid/scatterPlot/DensityPlotChart.organism';
import { cloneDeepOmit, headlessRenderMode } from '@/hybrid/utilities/utilities';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { deserializeRange, SerializedRange } from '@/hybrid/datetime/dateTime.utilities';
import { DENSITY_PLOT_ID, SCATTER_PLOT_ID, XYPlotRegion } from '@/scatterPlot/scatterPlot.constants';
import { Z_INDEX } from '@/hybrid/trend/trendViewer/trendViewer.constants';
import { AxisExtremeChange, createAxisControl } from '@/hybrid/utilities/axisControl.utilities';

/**
 * Converts the XYPlotRegion to a ChartRegion to more easily select coordinates in Highcharts. Any y range works
 * here so we just grab the first of them.
 */
function convertSomeXYRegionToChartRegion(region: XYPlotRegion): ChartRegion {
  const y = _.chain(region.ys).toArray().head().value();
  return {
    xMin: region.x?.min,
    xMax: region.x?.max,
    yMin: y?.min ?? null,
    yMax: y?.max ?? null,
  };
}

export interface XyPlotChartIdsInterface {
  chart?: Highcharts.Chart;
  seriesX?: { id: string };
  seriesY?: { id: string };
}

export interface XyPlotChartWrapperProps
  extends DensityPlotChartExternalProps,
    ScatterPlotChartExternalProps,
    ScatterPlotLegendProps,
    ScatterPlotMinimapProps {
  isScatterPlotView: boolean;
  isDensityPlotView: boolean;
  isInTopic?: boolean;
  canShowPlot: () => boolean;
  rangeEditingEnabled: boolean;
  autoUpdateDisabled?: boolean;
  zoomToRegion: (region: XYPlotRegion) => void;
  clearSelectedRegion: () => void;
  clearPointerValues: () => void;
  setPointerValues: (xValue: number, yValues: any[]) => void;
  selectedRegion: XYPlotRegion;
  chartConfig: any;
  seriesX: { id: string };
  seriesY: { id: string };
  stopUpdate?: boolean;
  afterChartUpdate?: () => void;
  /** Note: this needs to be serialized in order to render properly in Organizer as interactive content */
  displayRange: SerializedRange;
}

export const XyPlotChartWrapper: React.FunctionComponent<XyPlotChartWrapperProps> = (props) => {
  const { t } = useTranslation();
  const {
    isScatterPlotView,
    isDensityPlotView,
    canShowPlot,
    rangeEditingEnabled,
    autoUpdateDisabled,
    zoomToRegion,
    clearSelectedRegion,
    clearPointerValues,
    setPointerValues,
    selectedRegion,
    chartConfig,
    viewRegion,
    seriesX,
    seriesY,
    isInTopic = false,
    stopUpdate = false,
    afterChartUpdate = () => null,
    displayRange,
    timezone,
  } = props;

  const [chart, setChart] = useState<Highcharts.Chart>(null);
  const chartElementRef = useRef(null);
  const [axisControl, setAxisControl] = useState(null);
  // Chart selection service should probably be converted to a hook
  const drawSelectedRegionRef = useRef((selectedRegion: ChartRegion) => {});
  const deactivateScrollZoomRef = useRef(() => {});

  if (headlessRenderMode() && seriesX && seriesY) {
    provideVisualizationData({
      ...props,
      visualization: Visualization.XY_PLOT,
      subVisualization: isScatterPlotView ? SubVisualization.SCATTER_PLOT : SubVisualization.DENSITY_PLOT,
    });
  }

  const getOnMouseOver =
    ({ chart, seriesX, seriesY }: XyPlotChartIdsInterface) =>
    ({ target: point }) => {
      const time = point.time;
      const chartOrRegressionId = _.get(point, 'series.userOptions.id');
      const xId = !chart ? seriesX.id : _.get(chart, 'xAxis[0].userOptions.signalId');

      const ySignalIdIfScatterplot = chartOrRegressionId === SCATTER_PLOT_ID ? seriesY.id : chartOrRegressionId;
      const ySignalIdIfDensityPlot =
        chartOrRegressionId === DENSITY_PLOT_ID ? _.get(chart, 'yAxis[0].userOptions.signalId') : chartOrRegressionId;

      const yId = !chart ? ySignalIdIfScatterplot : ySignalIdIfDensityPlot;

      const pointerValues = _.filter(
        [
          {
            id: xId,
            pointValue: point.x,
            pointSelected: true,
          },
          {
            id: yId,
            pointValue: point.y,
            pointSelected: true,
          },
        ],
        (pointerValue) => _.isFinite(pointerValue.pointValue) && !_.isEmpty(pointerValue.id),
      );

      if (!_.isEmpty(pointerValues)) {
        setPointerValues(time, pointerValues);
      }
    };

  const onMouseOverRef = useRef(null);

  const setOnMouseOverRef = (mouseOverData) => {
    const updatedMouseOverData = {
      seriesX: mouseOverData?.seriesX ?? seriesX,
      seriesY: mouseOverData?.seriesY ?? seriesY,
    };

    if (_.isEmpty(mouseOverData)) {
      onMouseOverRef.current = getOnMouseOver(updatedMouseOverData);
    } else {
      onMouseOverRef.current = getOnMouseOver(mouseOverData);
    }
  };
  /**
   * Removes the chart.
   */
  const onDestroyChart = () => {
    chartElementRef.current = null;
    deactivateScrollZoomRef.current();
    clearPointerValuesIfLeft(null, chart);
  };

  const reflowChartCallback = () => {
    drawSelectedRegionRef.current(convertSomeXYRegionToChartRegion(selectedRegion));
  };

  useResizableHighchart({
    chart,
    chartElementRef,
    setChart,
    reflowChartCallback,
    onDestroyChart,
  });

  // Once we have the chart, set the drawSelectedRegion method and the axis control service, and create the cleanup
  useEffect(() => {
    if (_.isEmpty(chart)) {
      return;
    }
    setAxisControl(
      createAxisControl({
        x: {
          updateExtremes(extremesChanges: AxisExtremeChange[]) {
            if (_.isEmpty(chart)) {
              return;
            }
            zoomToRegion({
              x: {
                min: extremesChanges[0].oldExtremes.min + extremesChanges[0].changeInLow,
                max: extremesChanges[0].oldExtremes.max + extremesChanges[0].changeInHigh,
              },
              ys: axesToYs(chart.yAxis),
            });
          },
        },
        y: {
          updateExtremes(extremesChanges: AxisExtremeChange[]) {
            if (_.isEmpty(chart)) {
              return;
            }
            const ys = axesToYs(chart.yAxis);
            _.forEach(extremesChanges, (extremesChange) => {
              ys[extremesChange.axis.userOptions.signalId] = {
                min: extremesChange.oldExtremes.min + extremesChange.changeInLow,
                max: extremesChange.oldExtremes.max + extremesChange.changeInHigh,
              };
            });
            zoomToRegion({
              x: {
                min: chart.xAxis[0].min,
                max: chart.xAxis[0].max,
              },
              ys,
            });
          },
        },
      }),
    );
  }, [chart]);

  useEffect(() => {
    if (_.isEmpty(chart)) {
      return;
    }

    drawSelectedRegionRef.current = drawSelectedRegion(
      () => chart,
      {
        selection: Z_INDEX.SELECTED_REGION,
        button: Z_INDEX.SELECTED_REGION_REMOVE,
      },
      {
        clearSelection: clearSelectedRegion,
      },
    );
  }, [chart]);

  // Add events to the chart DOM element and update the minimap once the chart DOM element exists
  useEffect(() => {
    if (!chartElementRef.current || _.isEmpty(chart)) {
      return;
    }
    chartElementRef.current.addEventListener('mouseleave', (e) => clearPointerValuesIfLeft(e, chart));
    chartElementRef.current.addEventListener('mousemove', (e) => clearPointerValuesIfLeft(e, chart));

    return () => {
      chartElementRef.current?.removeEventListener('mouseleave', (e) => clearPointerValuesIfLeft(e, chart));
      chartElementRef.current?.removeEventListener('mousemove', (e) => clearPointerValuesIfLeft(e, chart));
    };
  }, [chartElementRef.current, chart]);

  // When we have an axis control, activate the zoom and collect the deactivateScrollZoom method
  useEffect(() => {
    if (_.isEmpty(chart) || !chartElementRef.current || !axisControl) {
      return;
    }
    deactivateScrollZoomRef.current = axisControl.activateScrollZoom(chart, chartElementRef.current);
  }, [axisControl, chart, chartElementRef.current]);

  // Only call updateChart (which calls chart.update()) if the chartConfig has changed
  useEffect(() => {
    updateChart(chartConfig);
  }, [chartConfig, chart]);

  useEffect(() => {
    if (_.isEmpty(chart)) {
      return;
    }
    drawSelectedRegionRef.current(convertSomeXYRegionToChartRegion(selectedRegion));
  }, [selectedRegion]);

  useEffect(() => {
    if (_.isEmpty(chart) || stopUpdate) {
      return;
    }
    updateViewRegion(viewRegion);
  }, [viewRegion]);

  useEffect(() => {
    setOnMouseOverRef({ seriesX, seriesY });
  }, [seriesX?.id, seriesY?.id]);

  /**
   * Clears the axis values when mouse leaves the chart
   *
   * @param e - The mouse event
   * @param highChart - the current chart object
   */
  function clearPointerValuesIfLeft(e: MouseEvent, highChart: Highcharts.Chart) {
    if (mouseLeftActualChartArea(e, highChart)) {
      clearPointerValues();
    }
  }

  /**
   * Updates the visible region displayed in the chart to a specific XY region
   *
   * @param viewRegion - the XYRegion we want to view on the chart
   */
  const updateViewRegion = (viewRegion: XYPlotRegion) => {
    if (_.isEmpty(chart)) {
      return;
    }
    chart.xAxis[0].setExtremes(viewRegion.x.min, viewRegion.x.max, false);
    _.forEach(chart.yAxis, (axis: Highcharts.Axis) => {
      const y = viewRegion.ys[axis.userOptions.signalId];
      axis.setExtremes(y?.min ?? null, y?.max ?? null, false);
    });
    chart.redraw();
    drawSelectedRegionRef.current(convertSomeXYRegionToChartRegion(selectedRegion));
  };

  /**
   * Processes changes to the chart configuration.
   *
   * @param chartConfig - Highcharts configuration settings to update on the chart
   */
  const updateChart = (chartConfig: Object) => {
    if (_.isEmpty(chart)) {
      return;
    }

    const clonedConfig = cloneDeepOmit(chartConfig, ['data']);

    _.set(clonedConfig, 'plotOptions.scatter.point.events.mouseOver', onMouseOverRef.current);
    _.set(clonedConfig, 'plotOptions.line.events.mouseOver', onMouseOverRef.current);

    // Avoid the expensive re-setting of data if it hasn't changed. Can use simple equality check because data is
    // immutable.
    _.forEach(clonedConfig.series, (series) => {
      const chartSeries = findChartSeries(chart, series.id);
      if (series.data === _.get(chartSeries, 'userOptions.data')) {
        delete series.data;
      } else if (chartSeries) {
        // We call setData() separately from update() because passing false as the fourth argument to
        // setData() prevents Highcharts from trying to modify the data array we pass in. There's no corresponding
        // option for update().
        chartSeries.setData(series.data, false, false, false);
        delete series.data;
      }
    });

    // Chart update with oneToOne=true removes the series when we pass axis with id and we flip the chart. The
    // workaround is to remove the id before updating the configuration
    // https://www.highcharts.com/forum/viewtopic.php?f=9&t=47427
    delete clonedConfig.xAxis.id;
    _.chain([clonedConfig.yAxis])
      .flatMap()
      .forEach((yAxis) => delete yAxis.id)
      .value();

    chart.update(clonedConfig, true, true, false);
    afterChartUpdate();
  };

  return (
    <>
      <div
        className={classNames({
          'flexGrow mr10': isInTopic,
          'flexFill flexRowContainer flexColumnContainer mr10': !isInTopic,
          'scatterPlot': isScatterPlotView,
          'densityPlot': isDensityPlotView,
        })}
        data-testid={isScatterPlotView ? 'scatterPlot' : 'densityPlot'}
        ref={chartElementRef}>
        {!canShowPlot() && (
          <div className="flexColumnContainer flexCenter flexFill">
            <div className="flexRowContainer flexCenter flexFill">
              <div className="alert alert-info fs16 p25 flexSelfCenter width-400">
                <Icon icon="fa-database" extraClassNames="pr3" />
                <span>{t('SCATTER.ADD_DATA')}</span>
              </div>
            </div>
          </div>
        )}

        {isScatterPlotView && canShowPlot() && (
          <ScatterPlotChart
            {...props}
            setChart={setChart}
            setOnMouseOverRef={setOnMouseOverRef}
            onMouseOverRef={onMouseOverRef}
          />
        )}

        {isDensityPlotView && canShowPlot() && (
          <DensityPlotChart
            {...props}
            setChart={setChart}
            setOnMouseOverRef={setOnMouseOverRef}
            onMouseOverRef={onMouseOverRef}
          />
        )}
      </div>

      {isDensityPlotView && canShowPlot() && <DensityPlotLegend />}

      {isScatterPlotView && <ScatterPlotLegend {...props} />}

      <div className="displayRangeSelector">
        <DisplayRangeSelector
          timezone={timezone}
          displayRange={deserializeRange(displayRange)}
          isInTopic={isInTopic}
          rangeEditingEnabled={rangeEditingEnabled}
          autoUpdateDisabled={autoUpdateDisabled}
        />
      </div>

      {isScatterPlotView && seriesX?.id && seriesY?.id && (
        <div className="minimapWrapper">
          <ScatterPlotMinimap {...props} />
        </div>
      )}
    </>
  );
};
