// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import {
  COMPARISON_OPERATORS,
  COMPARISON_OPERATORS_SYMBOLS,
  COMPARISON_OPPOSITE_OPERATORS,
  STRING_COMPARISON_OPERATORS,
} from '@/hybrid/toolSelection/investigate.constants';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { ValueSearchActions } from '@/trendData/valueSearch.actions';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { DISPLAY_MODE, MAX_DURATION_WARNING_MSG_PATH, RECOMMENDED_MAX_CAPSULE_DURATION } from '@/main/app.constants';
import { FORM_ERROR, FORM_WARNING, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { isStringSeries as isStringSeriesUtil, validateGuid } from '@/hybrid/utilities/utilities';
import { sqInvestigateStore, sqTrendCapsuleSetStore, sqValueSearchStore } from '@/core/core.stores';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { doTrack } from '@/track/track.service';
import { getDefaultMaxCapsuleDuration } from '@/services/systemConfiguration.utilities';

const valueSearchBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqValueSearchActions: injected<ValueSearchActions>(),
});

export const ValueSearch: SeeqComponent<typeof valueSearchBindings> = () => {
  const { sqInvestigateActions, sqValueSearchActions } = useInjectedBindings(valueSearchBindings);
  const { t } = useTranslation();

  const [color, setColor] = useState('');
  const [displayDownstreamWarning, setDisplayDownstreamWarning] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState('');

  const displayMode = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.displayMode);

  const {
    simpleOperator,
    simpleValue,
    simpleUpperValueInclusivity,
    simpleLowerValue,
    simpleLowerValueInclusivity,
    advancedEntryValue,
    advancedEntryOperator,
    advancedEntryLowerValue,
    advancedEntryDuration,
    advancedExitDuration,
    advancedExitValue,
    advancedExitOperator,
    originalParameters,
    inputSignal,
    isSimple,
    name,
    id,
    isCleansing,
    minDuration,
    maximumDuration,
    mergeDuration,
    useValidValues,
    isMigratedDeviationSearch,
  } = useFlux(sqValueSearchStore);

  const isNew = !id;
  const maxDurationWarningMessage = _.get(sqTrendCapsuleSetStore.findItem(id), MAX_DURATION_WARNING_MSG_PATH);
  const [operators, setOperators] = useState([]);
  const [entryOperators, setEntryOperators] = useState([]);
  const [exitOperators, setExitOperators] = useState([]);
  const operatorKeys = _.invert(COMPARISON_OPERATORS_SYMBOLS);
  const formatOperators = (operators) =>
    _.map(operators, (operator) => ({
      text: operator,
      value: operator,
      label: (
        <>
          <span className="inlineBlock width-25 text-center text-bolder text-monospace" id={operator}>
            {operator}
          </span>
          <span className="pl5">{t(`VALUE_SEARCH.OPERATORS.${operatorKeys[operator]}`)}</span>
        </>
      ),
    }));

  useEffect(() => {
    setApiErrorMessage('');
  }, [id]);

  const switchToSimple = (simple) => {
    sqValueSearchActions.setSearchType(simple);
    syncParams(simple);
  };

  useEffect(() => {
    // Update and format the operators given the type of signal
    const isStringSeries = isStringSeriesUtil(inputSignal);
    const operators = isStringSeries ? STRING_COMPARISON_OPERATORS : COMPARISON_OPERATORS;
    setOperators(formatOperators(operators));
    setEntryOperators(operators);
    setExitOperators(_.filter(operators, (operator) => !sqValueSearchActions.isOperatorBetween(operator)));

    // Clear inputs if necessary
    if (inputSignal?.id === simpleValue || (isStringSeries && validateGuid(simpleValue))) {
      sqValueSearchActions.setSimpleValue('');
    }

    if (inputSignal?.id === simpleLowerValue || (isStringSeries && validateGuid(simpleLowerValue))) {
      sqValueSearchActions.setSimpleLowerValue('');
    }
  }, [inputSignal]);

  const setSearchType = (setToSimple) => {
    if (isSimple !== setToSimple) {
      setDisplayDownstreamWarning(setToSimple && !isNew);

      doTrack('Workbench_Tool', 'Value Search', setToSimple ? 'Switch to Simple Mode' : 'Switch to Advanced Mode');
      switchToSimple(setToSimple);
    }
  };

  const executeValueSearch = () => {
    sqValueSearchActions.setIsMigratedDeviationSearch(false);
    const executeSearchPromise = isSimple
      ? sqValueSearchActions.executeSimpleSearch(color)
      : sqValueSearchActions.executeAdvancedSearch(color);
    return executeSearchPromise
      .then(() => doTrack('Workbench_Tool', 'Value Search', 'completed'))
      .catch((errorMessage) => {
        doTrack('Workbench_Tool', 'Value Search', 'error');
        setApiErrorMessage(_.replace(errorMessage, /\(.*\)/gm, ''));
      });
  };

  const syncParams = (setToSimple) => {
    if (setToSimple) {
      sqValueSearchActions.setSimpleOperator(sqValueSearchStore.advancedEntryOperator);

      // Only change the values to match if they weren't signals before
      if (!validateGuid(sqValueSearchStore.simpleValue)) {
        sqValueSearchActions.setSimpleValue(sqValueSearchStore.advancedEntryValue);
      }

      if (!validateGuid(simpleLowerValue) && sqValueSearchActions.isOperatorBetween(advancedEntryOperator)) {
        sqValueSearchActions.setSimpleLowerValue(advancedEntryLowerValue);
      }
    } else {
      const isValueItem = validateGuid(sqValueSearchStore.simpleValue);
      sqValueSearchActions.setAdvancedEntryOperator(sqValueSearchStore.simpleOperator);
      sqValueSearchActions.setAdvancedExitOperator(COMPARISON_OPPOSITE_OPERATORS[sqValueSearchStore.simpleOperator]);

      if (!isValueItem) {
        sqValueSearchActions.setAdvancedEntryValue(sqValueSearchStore.simpleValue);
        sqValueSearchActions.setAdvancedExitValue(sqValueSearchStore.simpleValue);
      }

      if (sqValueSearchActions.isOperatorBetween(sqValueSearchStore.simpleOperator)) {
        const isLowerValueItem = validateGuid(sqValueSearchStore.simpleLowerValue);
        if (!isLowerValueItem) {
          sqValueSearchActions.setAdvancedEntryLowerValue(sqValueSearchStore.simpleLowerValue);
        }
      }
    }
  };

  const getValue = (updatedValue: string | { id: string } = '') => {
    return typeof updatedValue === 'string' ? updatedValue : updatedValue.id ?? '';
  };

  const isBetweenSimpleOperator = sqValueSearchActions.isOperatorBetween(simpleOperator);
  const isBetweenAdvancedOperator = sqValueSearchActions.isOperatorBetween(advancedEntryOperator);

  const hasSymmetricConditions = () => {
    const hasSymmetricOperators =
      advancedEntryOperator === COMPARISON_OPPOSITE_OPERATORS[advancedExitOperator] &&
      !_.isEmpty(_.trim(advancedEntryOperator)) &&
      !_.isEmpty(_.trim(advancedExitOperator));
    const hasEqualValues =
      advancedEntryValue === advancedExitValue &&
      !_.isEmpty(_.trim(advancedEntryValue)) &&
      !_.isEmpty(_.trim(advancedExitValue));
    return (hasSymmetricOperators && hasEqualValues) || isBetweenAdvancedOperator;
  };

  ///////////////// Errors and Warnings ////////////////////////
  const sameInBetweenValuesError = sqValueSearchActions.sameBetweenValues(isSimple);
  const isSimpleLowerBoundGreater = sqValueSearchActions.isSimpleLowerBoundGreater(isSimple);
  const useMaxDuration = maximumDuration ? maximumDuration : getDefaultMaxCapsuleDuration();
  const maxDurationLengthWarning =
    moment.duration(useMaxDuration.value, useMaxDuration.units).as('months') >= RECOMMENDED_MAX_CAPSULE_DURATION;
  const symmetricConditionsWarning = hasSymmetricConditions();

  ///////////////// Simple Value Search specific fields ////////////////////////
  const simpleValueSearchFormSetup: FormElement = {
    component: 'FormGroup',
    name: 'simpleValueSearchGroup',
    includeIf: isSimple,
    testId: 'simpleValueSearch',
    components: [
      {
        component: 'FormGroup',
        name: 'thresholdGroup',
        components: [
          {
            component: 'IconSelectFormComponent',
            name: 'simpleValueSearchOperator',
            testId: 'simpleValueSearchOperator',
            value: simpleOperator,
            onChange: (operator) => sqValueSearchActions.setSimpleOperator(operator.value),
            formattedOptions: true,
            placeholder: 'VALUE_SEARCH.SELECT_OPERATOR',
            extraClassNames: 'specValueSearchSimpleOperator',
            selectOptions: operators,
          },
          {
            component: 'FormGroup',
            name: 'upperLimitGroup',
            components: [
              {
                component: 'LabelFormComponent',
                includeIf: isBetweenSimpleOperator,
                value: 'VALUE_SEARCH.UPPER_LIMIT',
                name: 'upperLimitLabel',
                extraClassNames: 'forceNoBottomMargin',
              },
              {
                component: 'FormRow',
                name: 'upperValueRow',
                components: [
                  {
                    component: 'OperatorSelectFormComponent',
                    name: 'upperInclusivity',
                    includeIf: isBetweenSimpleOperator,
                    testId: 'upperInclusivity',
                    value: simpleUpperValueInclusivity,
                    onChange: (operator) => sqValueSearchActions.setSimpleUpperValueInclusivity(operator.value),
                    extraClassNames: 'mr5',
                    operators: simpleOperator === COMPARISON_OPERATORS_SYMBOLS.IS_BETWEEN ? ['<', '<='] : ['>', '>='],
                  },
                  {
                    component: 'ItemSelectFormComponent',
                    name: 'simpleValueSearchValue',
                    testId: 'simpleValueSearchValue',
                    value: simpleValue ? simpleValue : undefined,
                    onChange: (itemOrValue) => {
                      const sanitizedValue = getValue(itemOrValue);
                      sqValueSearchActions.setSimpleValue(sanitizedValue);
                    },
                    excludedIds: [inputSignal?.id],
                    textEntryAllowed: true,
                    textToggleDisabled: isStringSeriesUtil(inputSignal),
                    includeMetadata: true,
                    disableAutoSelect: true,
                    itemTypes: [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR],
                    additionalItems: originalParameters,
                    textPlaceholder: isBetweenSimpleOperator
                      ? 'VALUE_SEARCH.UPPER_ENTRY_VALUE'
                      : 'VALUE_SEARCH.THRESHOLD',
                    selectPlaceholder: `VALUE_SEARCH.SELECT_${
                      isBetweenSimpleOperator ? 'UPPER_ENTRY_VALUE' : 'THRESHOLD'
                    }`,
                  },
                ],
              },
            ],
          },
          {
            component: 'FormGroup',
            name: 'lowerValueGroup',
            includeIf: isBetweenSimpleOperator,
            components: [
              {
                component: 'LabelFormComponent',
                includeIf: isBetweenSimpleOperator,
                value: 'VALUE_SEARCH.LOWER_LIMIT',
                name: 'lowerLimitLabel',
                extraClassNames: 'forceNoBottomMargin',
              },
              {
                component: 'FormRow',
                name: 'lowerValueRow',
                components: [
                  {
                    component: 'OperatorSelectFormComponent',
                    name: 'lowerInclusivity',
                    testId: 'lowerInclusivity',
                    value: simpleLowerValueInclusivity,
                    onChange: (operator) => sqValueSearchActions.setSimpleLowerValueInclusivity(operator.value),
                    extraClassNames: 'mr5',
                    operators: simpleOperator === COMPARISON_OPERATORS_SYMBOLS.IS_BETWEEN ? ['>', '>='] : ['<', '<='],
                  },
                  {
                    component: 'ItemSelectFormComponent',
                    name: 'simpleValueSearchLowerValue',
                    includeIf: isBetweenSimpleOperator,
                    testId: 'simpleValueSearchLowerValue',
                    value: simpleLowerValue ? simpleLowerValue : undefined,
                    onChange: (itemOrValue) => {
                      const sanitizedValue = getValue(itemOrValue);
                      sqValueSearchActions.setSimpleLowerValue(sanitizedValue);
                    },
                    excludedIds: [inputSignal?.id],
                    disableAutoSelect: true,
                    textEntryAllowed: true,
                    textToggleDisabled: isStringSeriesUtil(inputSignal),
                    includeMetadata: true,
                    itemTypes: [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR],
                    additionalItems: originalParameters,
                    textPlaceholder: 'VALUE_SEARCH.LOWER_ENTRY_VALUE',
                    selectPlaceholder: 'VALUE_SEARCH.SELECT_LOWER_ENTRY_VALUE',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        component: 'ErrorMessageFormComponent',
        name: 'greaterLowerBoundError',
        includeIf: isSimpleLowerBoundGreater,
        type: FORM_ERROR,
        failForm: true,
        value: 'VALUE_SEARCH.LIMIT_ERROR',
      },
      {
        component: 'ErrorMessageFormComponent',
        name: 'sameBetweenValues',
        includeIf: sameInBetweenValuesError,
        type: FORM_ERROR,
        failForm: true,
        value: 'VALUE_SEARCH.SAME_BETWEEN',
      },
    ],
  };

  ///////////////// Advanced Value Search specific fields ////////////////////////
  const advancedValueSearchFormSetup: FormElement = {
    component: 'FormGroup',
    name: 'advancedValueSearchFormSetup',
    includeIf: !isSimple,
    testId: 'advancedValueSearch',
    components: [
      {
        component: 'FormGroup',
        name: 'valueSearchConditionGroup',
        components: [
          {
            component: 'LabelFormComponent',
            name: 'simpleValueSearchValueLabel',
            value: 'VALUE_SEARCH.SPECIFY_ENTRY',
            testId: 'advancedEntryLabel',
            tooltip: isBetweenAdvancedOperator && t('VALUE_SEARCH.BETWEEN_INCLUSIVITY_TOOLTIP'),
          },
          {
            component: 'FormRow',
            name: 'valueSearchConditionGroupRow',
            components: [
              {
                component: 'OperatorSelectFormComponent',
                name: 'advancedEntryOperator',
                testId: 'advancedEntryOperatorSelect',
                value: advancedEntryOperator,
                onChange: (operator) => {
                  sqValueSearchActions.setAdvancedEntryOperator(operator.value);
                  sqValueSearchActions.setAdvancedExitOperator(COMPARISON_OPPOSITE_OPERATORS[operator.value]);
                },
                formattedOptions: true,
                placeholder: '',
                extraClassNames: 'specValueSearchAdvancedEntryOperator',
                operators: entryOperators,
              },
              {
                component: 'LabelFormComponent',
                name: 'advancedUpperInclusivity',
                testId: 'advancedUpperInclusivity',
                value: advancedEntryOperator === COMPARISON_OPERATORS_SYMBOLS.IS_BETWEEN ? '<' : '>=',
                includeIf: sqValueSearchActions.isOperatorBetween(advancedEntryOperator),
                extraClassNames: 'ml5 width-50 flexAlignCenter flexJustifyCenter',
              },
              {
                component: 'FormControlFormComponent',
                name: 'advancedSearchEntryValue',
                testId: 'valueSearchAdvancedEntryValue',
                value: advancedEntryValue,
                onBlur: (value) => {
                  // update the exit value to match (first time only)
                  if (_.isNull(advancedExitValue) && !!value) {
                    sqValueSearchActions.setAdvancedExitValue(value);
                  }
                },
                onChange: (value) => {
                  sqValueSearchActions.setAdvancedEntryValue(value);
                },
                size: 'sm',
                placeholder: isBetweenAdvancedOperator ? 'VALUE_SEARCH.UPPER_ENTRY_VALUE' : 'VALUE_SEARCH.ENTRY_VALUE',
                extraClassNames: 'width-110 pr3 ml5 height-34 specConditionValue',
              },
            ],
          },
          {
            component: 'FormRow',
            includeIf: isBetweenAdvancedOperator,
            name: 'advancedSearchLowerValueRow',
            components: [
              {
                component: 'LabelFormComponent',
                name: 'advancedLowerInclusivity',
                testId: 'advancedLowerInclusivity',
                value: advancedEntryOperator === COMPARISON_OPERATORS_SYMBOLS.IS_BETWEEN ? '>=' : '<',
                extraClassNames: 'ml55 mr5 width-50 flexAlignCenter flexJustifyCenter mt8',
              },
              {
                component: 'FormControlFormComponent',
                name: 'advancedSearchEntryLowerValue',
                testId: 'advancedValueSearchLowerEntryValue',
                value: _.isNil(advancedEntryLowerValue) ? '' : advancedEntryLowerValue,
                onChange: (value) => sqValueSearchActions.setAdvancedEntryLowerValue(value),
                size: 'sm',
                extraClassNames: 'width-110 pr3 height-34 specConditionValue',
                placeholder: 'VALUE_SEARCH.LOWER_ENTRY_VALUE',
              },
            ],
          },
          {
            component: 'ErrorMessageFormComponent',
            name: 'sameBetweenValues',
            includeIf: sameInBetweenValuesError,
            value: 'VALUE_SEARCH.SAME_BETWEEN',
            type: FORM_ERROR,
            failForm: true,
          },
          {
            component: 'FormRow',
            name: 'advancedSearchEntryValueWithUnitsRow',
            components: [
              {
                component: 'ValueWithUnitsFormComponent',
                name: 'advancedEntryDuration',
                value: advancedEntryDuration,
                onChange: sqValueSearchActions.setAdvancedEntryDuration,
                propName: 'VALUE_SEARCH_DURATIONS.ENTRY',
                min: 0,
                inlineLabel: 'VALUE_SEARCH.FOR',
              },
            ],
          },
        ],
      },
      {
        component: 'FormGroup',
        name: 'specExitValueSearchConditionGroup',
        includeIf: !isBetweenAdvancedOperator,
        components: [
          {
            component: 'LabelFormComponent',
            name: 'exitCriteriaPrompt',
            value: 'VALUE_SEARCH.SPECIFY_EXIT',
          },
          {
            component: 'FormRow',
            name: 'advancedSearchExitOperatorRow',
            components: [
              {
                component: 'OperatorSelectFormComponent',
                name: 'advancedExitOperator',
                testId: 'advancedExitOperatorSelect',
                value: advancedExitOperator,
                onChange: (operator) => sqValueSearchActions.setAdvancedExitOperator(operator.value),
                extraClassNames: 'specValueSearchAdvancedExitOperator',
                operators: exitOperators,
              },
              {
                component: 'FormControlFormComponent',
                name: 'advancedSearchExitValue',
                testId: 'valueSearchAdvancedExitValue',
                value: advancedExitValue,
                onChange: (value) => sqValueSearchActions.setAdvancedExitValue(value),
                size: 'sm',
                placeholder: 'VALUE_SEARCH.EXIT_VALUE',
                extraClassNames: 'width-110 pr3 ml5 height-34 specConditionValue',
              },
            ],
          },
          {
            component: 'FormRow',
            name: 'advancedSearchExitValueWithUnitsRow',
            components: [
              {
                component: 'ValueWithUnitsFormComponent',
                name: 'advancedExitDuration',
                value: advancedExitDuration,
                onChange: sqValueSearchActions.setAdvancedExitDuration,
                propName: 'VALUE_SEARCH_DURATIONS.EXIT',
                min: 0,
                inlineLabel: 'VALUE_SEARCH.FOR',
              },
            ],
          },
        ],
      },
      {
        component: 'FormRow',
        name: 'valueSearchMaximumDuration',
        testId: 'maxDuration',
        components: [
          {
            component: 'ValueWithUnitsFormComponent',
            name: 'maximumDuration',
            value: maximumDuration,
            onChange: (maxDuration) =>
              sqInvestigateActions.setMaximumDuration(TREND_TOOLS.VALUE_SEARCH, maxDuration.value, maxDuration.units),
            tooltip: 'MAXIMUM_CAPSULE_DURATION_TOOLTIP',
            label: 'MAXIMUM_CAPSULE_DURATION',
            min: 0,
            warningMessage: maxDurationWarningMessage,
          },
        ],
      },
      {
        component: 'ErrorMessageFormComponent',
        name: 'maxDurationTooLong',
        includeIf: maxDurationLengthWarning,
        value: 'VALUE_SEARCH.MAX_DURATION_TOO_LONG',
        type: FORM_WARNING,
        failForm: false,
        linkAction: () => switchToSimple(true),
      },
      {
        component: 'ErrorMessageFormComponent',
        name: 'symmetricConditions',
        includeIf: symmetricConditionsWarning,
        value: 'VALUE_SEARCH.SYMMETRIC_WARNING',
        failForm: false,
        type: FORM_WARNING,
        linkAction: () => switchToSimple(true),
      },
    ],
  };

  ///////////////// Form Fields ////////////////////////
  const formDataSetup: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'valueSearchTitle',
      value: name,
      onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.VALUE_SEARCH, name),
      id,
      onColorChange: setColor,
      searchIconClass: 'fc-search-limit',
      defaultName: 'VALUE_SEARCH.HEADER',
    },
    {
      component: 'ItemSelectFormComponent',
      name: 'inputSignal',
      displayNumber: true,
      testId: 'valueSearchInputSignal',
      value: inputSignal?.id,
      onChange: (item) => sqInvestigateActions.setParameterItem(TREND_TOOLS.VALUE_SEARCH, 'inputSignal', item),
      label: 'VALUE_SEARCH.SELECT_SERIES',
      itemTypes: [ITEM_TYPES.SERIES],
      includeMetadata: true,
    },
    {
      component: 'FormGroup',
      name: 'valueSearchNumberTwo',
      displayNumber: true,
      components: [
        {
          component: 'RadioButtonGroupFormComponent',
          name: 'isSimple',
          value: isSimple,
          onChange: _.noop,
          id: 'valueSearchRadios',
          label: 'VALUE_SEARCH.SPECIFY_SEARCH_TYPE',
          options: [
            {
              id: 'specValueSearchSimpleButton',
              label: 'VALUE_SEARCH.SIMPLE',
              checked: isSimple,
              onToggle: () => setSearchType(true),
            },
            {
              id: 'specValueSearchAdvancedButton',
              label: 'VALUE_SEARCH.ADVANCED',
              checked: !isSimple,
              onToggle: () => setSearchType(false),
            },
          ],
        },
        {
          name: 'downstreamWarning',
          component: 'ErrorMessageFormComponent',
          includeIf: displayDownstreamWarning,
          value: 'VALUE_SEARCH.CHANGE_MAY_BREAK_DOWNSTREAM',
          failForm: false,
          type: FORM_WARNING,
        },
        {
          component: 'ErrorMessageFormComponent',
          name: 'migratedFromDeviationSearch',
          includeIf: isMigratedDeviationSearch,
          value: 'VALUE_SEARCH.MIGRATED_DEVIATION_WARNING',
          failForm: false,
          type: FORM_WARNING,
        },
        simpleValueSearchFormSetup,
        advancedValueSearchFormSetup,
        {
          component: 'CheckboxFormComponent',
          name: 'validValuesCheckbox',
          value: useValidValues,
          onChange: sqValueSearchActions.toggleValidValues,
          checkboxLabel: 'VALUE_SEARCH.IGNORE_SIGNAL_GAPS',
          id: 'validValuesCheckbox',
        },
        {
          component: 'CheckboxFormComponent',
          name: 'cleansingCheckbox',
          includeIf: isSimple,
          value: isCleansing,
          onChange: () => sqValueSearchActions.toggleCleansing(),
          checkboxLabel: 'VALUE_SEARCH.IGNORE_SHORT_CAPSULES_OR_GAPS',
          id: 'cleansingCheckbox',
        },
        {
          component: 'ValueWithUnitsFormComponent',
          name: 'minDuration',
          includeIf: isCleansing && isSimple,
          testId: 'minDuration',
          value: minDuration,
          onChange: sqValueSearchActions.setMinDuration,
          min: 0,
          tooltip: 'VALUE_SEARCH.IGNORE_SHORT_CAPSULE_DURATION_TOOLTIP',
          label: 'VALUE_SEARCH.IGNORE_SHORT_CAPSULE_DURATION',
        },
        {
          component: 'ValueWithUnitsFormComponent',
          name: 'valueSearchMergeDuration',
          includeIf: isCleansing && isSimple,
          testId: 'mergeDuration',
          value: mergeDuration,
          onChange: sqValueSearchActions.setMergeDuration,
          tooltip: 'VALUE_SEARCH.IGNORE_SHORT_CAPSULE_GAPS_TOOLTIP',
          label: 'VALUE_SEARCH.IGNORE_SHORT_CAPSULE_GAPS',
          min: 0,
        },
      ],
    },
    {
      component: 'ErrorMessageFormComponent',
      name: 'apiError',
      includeIf: apiErrorMessage !== '',
      value: apiErrorMessage,
      type: FORM_ERROR,
      title: 'VALUE_SEARCH.FAILURE',
      dismissible: true,
      failForm: false,
      onClose: () => setApiErrorMessage(''),
    },
  ];

  const valueSearchBuilder = (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={executeValueSearch}
      closeFn={sqInvestigateActions.close}
      toolId={TREND_TOOLS.VALUE_SEARCH}
      submitBtnId="valueSearchNowButton"
    />
  );

  return displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT ? valueSearchBuilder : null;
};

export const sqValueSearch = angularComponent(valueSearchBindings, ValueSearch);
