import log4javascript from 'log4javascript';
import { headlessRenderMode } from '@/hybrid/utilities/utilities';
import { log, LOG_ROOT_NAME } from '@/hybrid/utilities/logger.utilities';

const ajaxAppender = new log4javascript.AjaxAppender('/error-log');
const jsonLayout = new log4javascript.JsonLayout();
const logger = log4javascript.getLogger(LOG_ROOT_NAME);

log4javascript.logLog.setQuietMode(true); // Don't throw popups if it has problems

// Note the ajaxAppender with the JSON layout can also batch requests instead of sending each log individually
ajaxAppender.addHeader('Content-Type', 'application/json;charset=utf-8');
ajaxAppender.setLayout(jsonLayout);
// Configure log4javascript object
logger.setLevel(log4javascript.Level.DEBUG);
logger.addAppender(ajaxAppender);

const usageLogger = log4javascript.getLogger('usage');
const usageAjaxAppender = new log4javascript.AjaxAppender('/usage');
usageAjaxAppender.addHeader('Content-Type', 'application/json;charset=utf-8');
usageAjaxAppender.setLayout(jsonLayout);
usageLogger.addAppender(usageAjaxAppender);

export function logTrace(message: string | Error, category?: string) {
  log('trace', message, category);
}

export function logDebug(message: string | Error, category?: string) {
  log('debug', message, category);
}

export function logInfo(message: string | Error, category?: string) {
  log('info', message, category);
}

export function logError(message: string | Error, category?: string) {
  log('error', message, category);
}

export function logFatal(message: string | Error, category?: string) {
  log('fatal', message, category);
}

export function logWarn(message: string | Error, category?: string) {
  log('warn', message, category);
}

export function logTrackEvent(event: string) {
  const logger = log4javascript.getLogger('usage');
  if (!headlessRenderMode()) {
    logger.info(event);
  }
}
