// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { ItemAclModal } from '@/hybrid/accessControl/ItemAclModal.molecule';
import { sqWorkbenchStore } from '@/core/core.stores';
import { prettyPermissions } from '@/hybrid/utilities/utilities';
import { getItemACL, getSwapSourceIdIfSwap } from '@/hybrid/utilities/acl.utilities';
import { canManageItem, isAdmin, userHasExclusiveAccess } from '@/services/authorization.service';

const aclStatusBindings = bindingsDefinition({
  item: prop<any>(),
});

export const AclStatus: SeeqComponent<typeof aclStatusBindings> = ({ item }) => {
  const { t } = useTranslation();

  const [exclusiveAccess, setExclusiveAccess] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [aclModalItemId, setAclModalItemId] = useState<string>(undefined);

  const canManage = () => canManageItem(item);
  const isAccessDetermined = () => !_.isUndefined(exclusiveAccess);

  /**
   * Loads the item (or swap source item) ACL to determine if the user has exclusive access to the item
   */
  const loadAcl = () => {
    setExclusiveAccess(undefined);
    const id = item?.id;

    if (!id) {
      return;
    }

    setIsLoading(true);
    // Look at the swap source's ACL entries if this is a swap
    getSwapSourceIdIfSwap(id)
      .then((id) => getItemACL(id))
      .then(({ entries }) => setExclusiveAccess(userHasExclusiveAccess(entries)))
      .catch(({ status }) => {
        if (status === HttpCodes.FORBIDDEN) {
          setExclusiveAccess(false);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => loadAcl(), [item]);

  const getLabel = () => {
    return `ACL_STATUS.${canManage() ? 'ACCESS_CONTROL' : 'YOUR_PERMISSIONS'}`;
  };

  const getAccess = () => {
    if (canManage()) {
      if (isAdmin()) {
        return 'ACL_STATUS.ACCESS.ADMIN';
      } else {
        return `ACL_STATUS.ACCESS.${exclusiveAccess ? 'ONLY_YOU' : 'YOU_AND_OTHERS'}`;
      }
    } else {
      return prettyPermissions(item.effectivePermissions, 'ACL_STATUS.ACCESS.NO_PERMISSIONS');
    }
  };

  const openACLModal = () => {
    getSwapSourceIdIfSwap(item?.id).then(setAclModalItemId);
  };

  return (
    <div data-testid="aclStatusWrapper" className="aclStatusWrapper flexRowContainer noBackground">
      <label className="specAclStatusLabel noBackground mb0">{t(getLabel())}</label>
      <div className="flexColumnContainer noBackground">
        {isLoading && <Icon icon="fa-spinner" extraClassNames="fa-spin flexSelfCenter" />}
        {!isLoading && !isAccessDetermined() && (
          <Icon
            testId="aclFailedIcon"
            icon="fa-exclamation-triangle"
            extraClassNames="specAclStatusLoadingFailed sq-text-danger flexSelfCenter"
            onClick={loadAcl}
            tooltip="ACL_STATUS.TOOLTIP.LOAD_FAILED"
          />
        )}
        {!isLoading && isAccessDetermined() && <div className="specAclStatusAccess">{t(getAccess())}</div>}
        {canManage() && (
          <HoverTooltip text="ACL_STATUS.TOOLTIP.MANAGE_ACCESS_CONTROL">
            <a href="#" onClick={openACLModal} className="specAclStatusManage link-underline-hover">
              <Icon icon="fc-share" extraClassNames="link-no-underline ml5 mr2" />
              {t('ACL_STATUS.MANAGE')}
            </a>
          </HoverTooltip>
        )}
      </div>
      {aclModalItemId && (
        <ItemAclModal
          worksheetId={sqWorkbenchStore.stateParams.worksheetId}
          workbookId={sqWorkbenchStore.stateParams.workbookId}
          itemId={aclModalItemId}
          closeModal={() => {
            setAclModalItemId(undefined);
            loadAcl();
          }}
          includeLinksAndCorporateMessage={false}
        />
      )}
    </div>
  );
};
