// @ts-strict-ignore
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  getBarOptions,
  getBarStackedOptions,
  getColumnOptions,
  getColumnStackedOptions,
  getPieOptions,
} from '@/hybrid/tableBuilder/tableViewer/chartConfigs';
import { ChartSettingsInterface } from './ChartSettings.molecule';
import { DEBOUNCE } from '@/core/core.constants';
import { useResizeWatcher } from '@/hybrid/core/hooks/useResizeWatcher.hook';
import { useDebounce } from '@/hybrid/core/hooks/useDebounce.hook';
import _ from 'lodash';

const DEFAULT_CHART_CONFIG = {
  plotOptions: {
    series: {
      animation: false,
    },
  },
};

interface ChartProps {
  tableData: any[][];
  settings?: ChartSettingsInterface;
  testId: string;
}

export const CHART_TYPES = [
  { id: 'column', label: 'Column Chart', config: getColumnOptions },
  { id: 'bar', label: 'Bar Chart', config: getBarOptions },
  {
    id: 'column_stacked',
    label: 'Column Chart Stacked',
    config: getColumnStackedOptions,
  },
  {
    id: 'bar_stacked',
    label: 'Bar Chart Stacked',
    config: getBarStackedOptions,
  },
  { id: 'pie', label: 'Pie Chart', config: getPieOptions },
];

export const Chart: React.FunctionComponent<ChartProps> = ({ tableData, settings, testId }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [chart, setChart] = useState(null);
  const chartElementRef = useRef(null);

  useEffect(() => {
    // Wait until the next render to ensure the bottom panel is finished rendering
    setTimeout(() => {
      setIsInitialized(true);
    }, 1);
  }, []);

  const reflowChart = useDebounce(() => {
    const element = chartElementRef.current;
    if (!element || !chart) {
      return;
    }
    chart.pointer.chartPosition = undefined;
    chart.reflow();
  }, DEBOUNCE.SHORT);

  useResizeWatcher({
    elementRef: chartElementRef,
    callback: reflowChart,
    callOnLoad: true,
  });

  const destroyChart = useCallback(() => {
    if (chart && chart.destroy) {
      // Highcharts-React should destroy the chart itself
      setChart(null);
    }
    if (chartElementRef.current) {
      chartElementRef.current.innerHTML = '';
    }
  }, [chart, chartElementRef.current]);

  // Once we have the chart, create the cleanup
  useEffect(() => {
    if (!chart) {
      return;
    }
    // cleanup
    return () => destroyChart();
  }, [chart, destroyChart]);

  if (!isInitialized || !tableData || tableData.length === 0 || tableData[0].length < 2) {
    return <div />;
  }

  const options = _.merge(
    {},
    // TODO: CRAB-28794
    CHART_TYPES.find((item) => item.id === (settings.chartType ?? CHART_TYPES[0].id)).config(tableData, settings),
    DEFAULT_CHART_CONFIG,
  );

  return (
    <div data-testid={testId} className="flexFill flexColumnContainer" ref={chartElementRef}>
      <HighchartsReact
        immutable={true}
        highcharts={Highcharts}
        options={options}
        containerProps={{ className: 'flexFill' }}
      />
    </div>
  );
};
