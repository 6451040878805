import React, { useEffect } from 'react';
import { ChartView, ChartViewActions, ChartViewConfiguration, ChartViewData } from '@/hybrid/trend/ChartView.organism';
import { DisplayRangeSelector } from '@/hybrid/trend/DisplayRangeSelector.molecule';

export interface ChartViewContentProperties {
  onContentLoad: () => void;
  data: ChartViewData;
  configuration: ChartViewConfiguration;
  actions: ChartViewActions;
}

/**
 * This component is for rendering interactive content in trend view
 */
export const ChartViewContent: React.FunctionComponent<ChartViewContentProperties> = (props) => {
  const { onContentLoad } = props;

  useEffect(() => onContentLoad(), []);

  return (
    <div className="flexFill flexRowContainer">
      <ChartView {...props} />
      <DisplayRangeSelector
        displayRange={props.data.sqDurationStoreData.displayRange}
        rangeEditingEnabled={false}
        autoUpdateDisabled={true}
        label={props.data.summaryLabel}
        timezone={props.data.selectedTimezone}
        investigateRangeCopyDisabled={true}
        isInTopic={true}
      />
    </div>
  );
};
