import { IGNORED_ITEM_TYPES, PATH_SEPARATOR } from '@/hybrid/assetGroupEditor/assetGroup.constants';
import _ from 'lodash';
import { API_TYPES } from '@/main/app.constants';
import { IconType } from '@/hybrid/core/Icon.atom';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import {
  getAssetAncestors,
  reset,
  setAssetGroup,
  setHasUnsavedChanges,
  setIsLoading,
} from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { sqTreesApi, TreeItemOutputV1 } from '@/sdk';
import {
  AssetAncestor,
  AssetGroupAsset,
  AssetGroupChild,
  AssetGroupInputParameter,
  AssetGroupItem,
} from '@/hybrid/assetGroupEditor/assetGroup.types';
import { SeeqNames } from '@/main/app.constants.seeqnames';

export function getPath(item: AssetAncestor) {
  if (item?.assetPath) {
    return item.assetPath;
  } else {
    return _.join(_.map(_.filter(item?.ancestors, { type: API_TYPES.ASSET }), 'name'), PATH_SEPARATOR);
  }
}

export function getNameWithoutPath(item: AssetGroupAsset) {
  return _.indexOf(item.name, PATH_SEPARATOR) < 0
    ? item.name
    : item.name.substring(_.lastIndexOf(item.name, PATH_SEPARATOR) + 1);
}

export function getNameWithPath(name: string, path: string) {
  return `${path}${_.isEmpty(path) ? '' : PATH_SEPARATOR}${name}`;
}

export function getDisplayPath(item: AssetGroupAsset) {
  return _.join(_.map(item?.ancestors, 'name'), ' » ');
}

export function findChild(asset: AssetGroupAsset, column: string): AssetGroupChild | undefined {
  return _.find(asset.children, (child) => child?.name === column);
}

export function getColumnsHelper(assets: AssetGroupAsset[]): AssetGroupChild[] {
  return _.chain(assets)
    .flatMap('children')
    .uniqBy('name')
    .sortBy((child) => _.toLower(child.name))
    .value();
}

export function getParametersUsedByFormula(
  parameters: AssetGroupInputParameter[],
  formula: string,
): AssetGroupInputParameter[] {
  const usedParameters = formula.match(/\$[a-z0-9_A-Z]+/g)?.map((s) => s.substring(1));

  return _.filter(parameters, (param: AssetGroupInputParameter) =>
    _.indexOf(usedParameters, param.name) > -1 ? param : null,
  );
}

/**
 * This function returns one child for a given column name if it can find one.
 */
export function findAtLeastOneChild(assets: AssetGroupAsset[], columnName: string): AssetGroupChild | undefined {
  const child = _.chain(assets)
    .map((asset) => _.find(asset.children, { name: columnName }))
    .filter((child) => !_.isNil(child))
    // allow calculations without parameters (they are valid, think Scalar!)
    .filter((child: AssetGroupChild) => !_.isEmpty(child.parameters) || child.columnType === 'Calculation')
    .first()
    .value() as AssetGroupChild;
  return child;
}

export function getCellIcon(assetChild?: AssetGroupChild): string {
  if (assetChild?.columnType === 'Calculation') {
    return 'fc-formula';
  } else if (!assetChild || _.isEmpty(assetChild.parameters)) {
    return 'fa-plus-circle';
  } else {
    return 'fa-check-circle';
  }
}

export function getCellIconType(assetChild?: AssetGroupChild): IconType {
  if (assetChild?.columnType === 'Calculation') {
    return 'theme';
  } else if (!assetChild || _.isEmpty(assetChild.parameters)) {
    return 'gray';
  } else if (assetChild.manuallyAdded) {
    return 'color';
  }
  return 'theme';
}

interface ErrorType {
  assetName: string;
  columnName: string;
}

interface Dependency {
  variableName: string;
  columnName: string;
  originalItemId: string;
}

/**
 * This function maps the "formula parameters" that contain column names to the actual entities that correspond to
 * the asset and replaces the parameter with the actual itemId. This is done as part of saving the asset group.
 *
 * The API expects a parameter to look like this: {name: <name>, id: <id of item>}  which is different from what
 * we use in "Formula" as there the parameter looks like this: {name: <name>, item: {id: <id>, name: <name of
 * item>}} (FormulaEditorParam)
 */
export function mapCalculationParamsToAssetChild(asset: AssetGroupAsset, params: AssetGroupInputParameter[]) {
  const errors: ErrorType[] = [];
  const dependencies: Dependency[] = [];
  const mappings = _.chain(params)
    .map(({ name, item, assetGroupColumnBased }) => {
      if (item.id === item.name || assetGroupColumnBased) {
        // if we end up here then it's an asset group column and needs to be replaced
        const child: any = findChild(asset, item.name);
        // "child" is now the asset group child which could be a calculation or an item
        if (!child) {
          // empty slot
          errors.push({ assetName: asset.name, columnName: item.name });
          return null;
        } else if (child.id) {
          return { id: child.id, name };
        } else if (_.isUndefined(child.id)) {
          dependencies.push({
            variableName: name,
            columnName: item.name,
            originalItemId: _.get(child, 'parameters[0].item.id'),
          });
          return null;
        } else {
          return null;
        }
      } else {
        return { name, id: item.id };
      }
    })
    .compact()
    .value();
  return { errors, mappings, dependencies };
}

export function validateItemToAdd({ item }: { item: { type: string } }) {
  // TODO CRAB-23867 remove this function if/when metrics and displays are supported
  if (item.type === API_TYPES.THRESHOLD_METRIC) {
    errorToast({ messageKey: 'ASSET_GROUP_EDITOR.ERROR_ADDING_METRIC' });
    return false;
  }
  if (item.type === API_TYPES.DISPLAY) {
    errorToast({ messageKey: 'ASSET_GROUP_EDITOR.ERROR_ADDING_DISPLAY' });
    return false;
  }
  return true;
}

interface ItemProperty {
  name: string;
  value?: string;
}

export function getItemProperty(item: { properties: ItemProperty[] }, property: string) {
  return _.find(item.properties, { name: property })?.value;
}

function prepareAssetGroupChildForUI(storedItem: TreeItemOutputV1): AssetGroupChild {
  const { id, name, type } = storedItem;

  const getParametersFromStoredItem = (item: TreeItemOutputV1): AssetGroupInputParameter[] => {
    return _.map(item.parameters, (param) => ({
      name: param.name,
      item: _.pick(param.item, ['id', 'name', 'type']) as AssetGroupItem,
    }));
  };

  const storedParameters = getParametersFromStoredItem(storedItem);
  const assetChild: AssetGroupChild = {
    id,
    name,
    type,
    assetPath: getItemProperty(storedItem, 'assetPath'),
    columnType: getItemProperty(storedItem, SeeqNames.Properties.ColumnType) === 'Calculation' ? 'Calculation' : 'Item',
    originalMapping: getItemProperty(storedItem, 'originalMapping'),
    manuallyAdded: getItemProperty(storedItem, 'manuallyAdded') === 'true',
    formula: getItemProperty(storedItem, SeeqNames.Properties.Formula) ?? '',
    addedBy: getItemProperty(storedItem, 'addedBy'),
    parameters: storedParameters,
  };

  if (assetChild.columnType === 'Calculation') {
    // parameters to column contains the mapping of a parameter name to a column
    const parametersToColumns = JSON.parse(
      (_.find(storedItem.properties, { name: 'parameterToColumn' }) as any)?.value,
    );
    _.map(storedParameters, (storedParam: AssetGroupInputParameter) => {
      const assetColumnParam = _.find(parametersToColumns, { parameterName: storedParam.name });

      if (assetColumnParam && !_.get(assetColumnParam, 'id')) {
        storedParam.assetGroupColumnBased = true;
      }
    });
  }
  return assetChild;
}

export function editAssetGroup(root: { id: string }, sqWorksheetActions: any) {
  sqWorksheetActions.setView(WORKSHEET_VIEW.ASSET_GROUP_EDITOR);
  reset();
  setIsLoading(true);
  // fetch the group based on the root:
  return sqTreesApi
    .getTree({ id: root.id, offset: 0, limit: 5000, includeDescendants: true })
    .then((response) => {
      /**
       * Asset Group are returned in the following format:
       *
       * -- Asset Group Name (as item.name)
       *    -- children: [
       *       -- ROW 1 Name (as child[0].name
       *            -- children: [
       *                -- column name as child[0].name
       *                -- column name as child[1].name
       *                ...
       *                -- column name as child[X].name
       *            ]
       *       -- ROW 2 Name (as child[1].name
       *            -- children: [
       *                 -- column name as child[0].name
       *                -- column name as child[1].name
       *                ...
       *                -- column name as child[X].name
       *            ]
       *      -- ....
       *      -- ROW X Name (as child[X-1].name
       *            -- children: [
       *                 -- column name as child[0].name
       *                -- column name as child[1].name
       *                ...
       *                -- column name as child[X].name
       *            ]
       *    ]
       *
       * The root reflects the Asset Group Root - the immediate children are the "rows" and the columns are created
       * based on the children of the "rows".
       */

      const { item, children, description } = response.data;
      const assetChildren: AssetGroupAsset[] = _.chain(children)
        .reject((child) => _.includes(IGNORED_ITEM_TYPES, child.type))
        .map((storedAssetItem) => {
          const { id, name, properties }: { id: string; name: string; properties: ItemProperty[] } = storedAssetItem;
          const type = '';
          const assetPath = getItemProperty({ properties }, SeeqNames.Properties.AssetPath);
          const ancestors = getAssetAncestors({ ...storedAssetItem, assetPath: undefined }).ancestors ?? [];
          const assetChild = { id, name, ancestors, type, assetPath };
          const children = _.chain(storedAssetItem.children)
            .reject((child) => _.includes(IGNORED_ITEM_TYPES, child.type))
            .map(prepareAssetGroupChildForUI)
            .value();
          return { ...assetChild, children };
        })
        .value();

      setAssetGroup({ id: item?.id, name: item?.name ?? '', description, assets: assetChildren, addedBy: '' });
    })
    .finally(() => {
      setIsLoading(false);
      setHasUnsavedChanges(false);
    });
}

export function getOneLevelUpPath(assets: AssetGroupAsset[]): AssetGroupAsset[] {
  return _.map(assets, (current) => {
    const startingPath = getPath(current);
    if (current.path) {
      // trim the path to "cut off" the path we've already extracted
      const remainingPath = startingPath.substring(0, startingPath.indexOf(current.path) - 1);
      const path = remainingPath.substring(_.lastIndexOf(remainingPath, PATH_SEPARATOR) + 1);
      return _.assign({}, current, {
        path: `${path}${PATH_SEPARATOR}${current.path}`,
      });
    } else {
      const path = startingPath.substring(_.lastIndexOf(startingPath, PATH_SEPARATOR) + 1);
      return _.assign({}, current, { path });
    }
  });
}

export function findExistingAsset(assets: AssetGroupAsset[], newAsset: AssetGroupAsset): AssetGroupAsset | null {
  return _.chain(assets)
    .filter((asset) => {
      if (getNameWithoutPath(asset) === getNameWithoutPath(newAsset)) {
        return newAsset.assetPath === asset.assetPath;
      }
      return false;
    })
    .head()
    .value() as AssetGroupAsset;
}

export function dependencyRemoved(asset: AssetGroupAsset, child: AssetGroupChild) {
  const availableColumns = asset.children;
  return _.some(child.parameters, (param) => {
    if (param.assetGroupColumnBased) {
      const assignedItem = param.item;
      const possibleColumnMatch = _.find(availableColumns, { name: assignedItem.name });
      if (possibleColumnMatch) {
        return possibleColumnMatch.columnType !== 'Calculation' && _.isEmpty(possibleColumnMatch.parameters);
      }
      return true;
    }
    return false;
  });
}

export function getDependents(asset: AssetGroupAsset, removedChild: AssetGroupChild) {
  return _.filter(asset.children, (assetChild) =>
    _.find(
      assetChild.parameters,
      ({ item, assetGroupColumnBased }) =>
        (item?.name === removedChild?.name && assetGroupColumnBased) || removedChild?.id === item?.id,
    ),
  );
}

export function isValidColumnName(assets: AssetGroupAsset[], columnName: string) {
  const allChildNames = _.chain(assets)
    .flatMap((asset) => asset.children)
    .map('name')
    .uniq()
    .value();
  return !_.includes(allChildNames, _.trim(columnName));
}
