// @ts-strict-ignore
import React, { useEffect } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { APP_STATE, HOME_SCREEN_TABS, SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';

import { useTranslation } from 'react-i18next';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { ExplorerModalBody } from '@/hybrid/explorer/ExplorerModalBody.molecule';
import { ExploreFolderModalActions } from '@/hybrid/explorer/exploreFolderModal.actions';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { ACL_MODAL_CHANGE_CHANNEL, emitBroadcastChannelMessage } from '@/services/broadcastChannel.utilities';
import { equalsIgnoreCase, truncate } from '@/hybrid/utilities/utilities';
import { successToast } from '@/hybrid/utilities/toast.utilities';
import { CancelAndSave } from '@/hybrid/core/CancelAndSave.molecule';
import { sqExploreFolderModalStore, sqHomeScreenStore } from '@/core/core.stores';
import { canModifyWorkbook } from '@/services/authorization.service';

const folderExplorerModalBindings = bindingsDefinition({
  corporateDriveMessage: prop.optional<boolean>(),
  isAclModalLocationInHeader: prop.optional<boolean>(),
  onClose: prop<any>(),
  item: prop<any>(),
  fetchInherited: prop.optional<any>(),
  itemId: prop.optional<string>(),
  itemName: prop.optional<string>(),
  currentFolderId: prop<string>(),
  currentTab: prop<string>(),
  aclModified: prop.optional<any>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqExploreFolderModalActions: injected<ExploreFolderModalActions>(),
  $state: injected<any>(),
});

export const FolderExplorerModal: SeeqComponent<typeof folderExplorerModalBindings> = (props) => {
  const {
    currentTab,
    item,
    onClose,
    fetchInherited,
    corporateDriveMessage,
    itemId,
    itemName,
    aclModified,
    isAclModalLocationInHeader,
  } = props;
  const { $state, sqHomeScreenUtilities, sqHomeScreenActions, sqExploreFolderModalActions } =
    useInjectedBindings(folderExplorerModalBindings);
  const { t } = useTranslation();
  const folder = useFluxPath(sqExploreFolderModalStore, () => sqExploreFolderModalStore.folder);
  const initialFolderId = useFluxPath(sqExploreFolderModalStore, () => sqExploreFolderModalStore.initialFolderId);
  const localizedFolder = sqHomeScreenUtilities.translateBreadcrumb(folder);

  useEffect(() => {
    sqExploreFolderModalActions.getInitialFolder({
      currentTab: corporateDriveMessage ? HOME_SCREEN_TABS.CORPORATE : currentTab,
      item,
      forceCorporate: corporateDriveMessage,
    });
  }, []);

  const close = (movedItem = false) => {
    if (movedItem) {
      sqHomeScreenActions.clearItems();
    }
    sqExploreFolderModalActions.clear();
    sqHomeScreenActions.setCurrentTab(currentTab);
    onClose();
  };

  const updateMovedItems = () => {
    return successToast({
      messageKey: 'WORKBENCH.ITEM_MOVED',
      messageParams: {
        item: corporateDriveMessage ? itemName : item.name,
        folder: folder.name,
      },
    });
  };

  const moveItemToFolder = () => {
    const currentFolderId = folder ? folder.id : SEARCH_ITEM_LOCATIONS.MY_FOLDER;
    return sqHomeScreenUtilities
      .moveItem(corporateDriveMessage ? itemId : item.id, currentFolderId)
      .then((response: any) => {
        if (!corporateDriveMessage) {
          close(true);
        }
        let folderId;
        let currentTab;
        let tabFolderPromise;
        if (!folder) {
          tabFolderPromise = sqHomeScreenUtilities.getTabFolder(HOME_SCREEN_TABS.MY_FOLDER);
        } else {
          tabFolderPromise = sqHomeScreenUtilities.getTabFolderName(currentFolderId).then((tabFolder) => {
            folderId = tabFolder ? null : currentFolderId;
            currentTab = tabFolder || null;
          });
        }
        return tabFolderPromise.then(() => {
          if (item !== undefined && !corporateDriveMessage) {
            return sqHomeScreenActions.loadFolderTree(folderId, currentTab).then((response: any) => {
              return $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId }, { reload: true }).then(() => {
                requestAnimationFrame(updateMovedItems);
                onClose();
              });
            });
          } else {
            emitBroadcastChannelMessage(ACL_MODAL_CHANGE_CHANNEL);
            sqHomeScreenActions.setCurrentFolder(currentFolderId);
            sqHomeScreenActions.setCurrentTab(currentTab);
            updateMovedItems();
            fetchInherited();
            onClose();
            if (!isAclModalLocationInHeader && !aclModified) {
              return sqHomeScreenActions
                .loadFolderTree(folderId, currentTab)
                .then((response: any) => $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId }, { reload: true }));
            } else {
              return sqHomeScreenActions.loadFolder(folderId, currentTab);
            }
          }
        });
      });
  };

  const noFolderOrNotCurrent = !folder || folder?.id !== sqHomeScreenStore.currentFolderId;
  const notFilteringByPublicOrPinned =
    sqExploreFolderModalStore.tableFilter !== SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC &&
    sqExploreFolderModalStore.tableFilter !== SEARCH_ITEM_LOCATIONS.PINNED;
  const folderOrFilteringByPublicOrPinned =
    canModifyWorkbook(folder, false) &&
    folder &&
    !equalsIgnoreCase(folder?.id, initialFolderId) &&
    !equalsIgnoreCase(folder?.id, item?.id) &&
    _.isUndefined(
      _.find(folder?.ancestors, (ancestor) => {
        return equalsIgnoreCase(ancestor.id, item?.id);
      }),
    );
  const canMoveItemWhenNoCorporateDriveMessage =
    !folder && notFilteringByPublicOrPinned ? true : folderOrFilteringByPublicOrPinned;

  // If folder is undefined it could be that we are trying to move to the "My Folder" that has yet to be created
  const canMoveItem = corporateDriveMessage
    ? noFolderOrNotCurrent && notFilteringByPublicOrPinned
    : canMoveItemWhenNoCorporateDriveMessage;

  const renderButtons = (
    <div className="flexColumnContainer flexJustifyEnd pt15">
      <CancelAndSave
        cancelFn={close}
        cancelBtnTestId="cancelMoveBtn"
        submitFn={moveItemToFolder}
        submitBtnTestId="moveToBtn"
        submitBtnLabel="WORKBENCH.MOVE_HERE"
        btnDisabled={!canMoveItem}
        submitClassNames={classNames({
          'disabledLook disabledBehavior': !canMoveItem,
        })}
        values={[]}
      />
    </div>
  );

  return (
    <Modal show={true} onHide={close} animation={false} backdrop="static" data-testid="folderExplorerModal">
      <Modal.Header closeButton={true}>
        <h4 className="modal-title flexColumnContainer flexAlignCenter">
          <HoverTooltip text={localizedFolder || ''} delay={500}>
            <span data-testid="folderExplorerTitle">
              <span>{t('WORKBENCH.MOVE_TO_FOLDER')}: </span>
              <span>{truncate(localizedFolder, 50, 20)}</span>
            </span>
          </HoverTooltip>
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className=" max-height-475 overflowAuto">
          <ExplorerModalBody
            show={true}
            store={sqExploreFolderModalStore}
            actions={sqExploreFolderModalActions}
            allowedTypes={['Folder']}
          />
        </div>
        {renderButtons}
      </Modal.Body>
    </Modal>
  );
};
