// @ts-strict-ignore
import { CopyButtonGivenText } from '@/hybrid/core/CopyButtonGivenText.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { FormulaDisplayOnly } from '@/hybrid/formula/FormulaDisplayOnly.atom';
import { FormulaParameters } from '@/hybrid/workbooks/FormulaParameters.molecule';
import { KEY_CODES } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { handleAnchorClick } from '@/hybrid/tools/itemProperties/propertiesPanel.utilities';
import { AUTO_FORMAT } from '@/hybrid/utilities/numberHelper.utilities';
import { AUTO_FORMAT as STRING_AUTO_FORMAT } from '@/hybrid/utilities/stringHelper.utilities';
import { TextButton } from '@/hybrid/core/TextButton.atom';

export type ItemPropertyListProps = {
  item: any;
  properties: any[];
  setProperty: (property: any, updatePropsOnly?: boolean) => Promise<void>;
  deleteProperty: (property: any) => Promise<void>;
  isMaxInterpolationEditable: boolean;
  numberFormatOverridden: boolean;
  isNumberFormatEditable: boolean;
  stringFormatOverridden: boolean;
  isStringFormatEditable: boolean;
  updatePropertyValue: (value: string, propertyIndex: number) => void;
  getValidatedNumber: (format: string) => any;
  getValidatedString: (format: string, sample: string) => any;
  defaultNumberFormat: any;
  defaultStringFormat: any;
};

export const ItemPropertyList = ({
  item,
  properties,
  setProperty,
  deleteProperty,
  isMaxInterpolationEditable,
  numberFormatOverridden,
  isNumberFormatEditable,
  stringFormatOverridden,
  isStringFormatEditable,
  updatePropertyValue,
  getValidatedNumber,
  getValidatedString,
  defaultNumberFormat,
  defaultStringFormat,
}: ItemPropertyListProps) => {
  const { t } = useTranslation();

  const ITEM_PROPS = SeeqNames.Properties;
  const NUMBER_FORMATS = [
    { name: 'PROPERTIES.NUMBER_FORMAT.AUTO', format: AUTO_FORMAT },
    { name: 'PROPERTIES.NUMBER_FORMAT.NUMBER', format: '#,##0.00' },
    {
      name: 'PROPERTIES.NUMBER_FORMAT.SCIENTIFIC_NOTATION',
      format: '0.0000E+0',
    },
    { name: 'PROPERTIES.NUMBER_FORMAT.SIG_FIG', format: 'SigFig:4' },
  ];
  const STRING_FORMATS = [
    { name: 'PROPERTIES.STRING_FORMAT.AUTO', format: STRING_AUTO_FORMAT },
    { name: 'PROPERTIES.STRING_FORMAT.MAX_LENGTH', format: '10' },
    { name: 'PROPERTIES.STRING_FORMAT.ELLIPSIS', format: '5...3' },
  ];

  const hasProperty = (property) => _.some(properties, { name: property });

  /**
   * Sets the number format of the item to the one provided.
   *
   * @param format the format to set
   */
  const setNumberFormat = (format: string) => {
    if (format === defaultNumberFormat) {
      deleteProperty({ name: SeeqNames.Properties.NumberFormat });
    } else {
      setProperty({ name: SeeqNames.Properties.NumberFormat, value: format });
    }
  };

  /**
   * Sets the string format of the item to the one provided.
   *
   * @param format the format to set
   */
  const setStringFormat = (format: string) => {
    if (format === defaultStringFormat) {
      deleteProperty({ name: SeeqNames.Properties.StringFormat });
    } else {
      setProperty({ name: SeeqNames.Properties.StringFormat, value: format });
    }
  };

  const renderPropValue = (property, index: number) => {
    const isValid = !_.isEmpty(property.value);
    switch (property.name) {
      case ITEM_PROPS.Formula:
        return <FormulaDisplayOnly formula={property.value} extraClassNames="width-maximum" />;

      case ITEM_PROPS.OverrideMaximumInterpolation:
        return (
          <div className={classNames({ 'has-error': !isValid })}>
            <input
              autoComplete="off"
              type="text"
              name="overrideMaximumInterpolation"
              data-testid="overrideMaximumInterpolation"
              className={classNames('form-control input-sm width-maximum', {
                'disabledLook disabledBehavior': !isMaxInterpolationEditable,
              })}
              value={property.value}
              onChange={(e) => updatePropertyValue(e.target.value, index)}
              autoFocus={property.triggerFocus}
              onFocus={(e) => e.target.select()}
              required={true}
              onBlur={() => isValid && setProperty(property)}
              onKeyUp={(e) => {
                e.keyCode === KEY_CODES.ENTER && isValid && setProperty(property);
                e.stopPropagation();
              }}
            />
          </div>
        );

      case ITEM_PROPS.NumberFormat:
        return (
          <CustomFormat
            property={property}
            index={index}
            isValid={isValid}
            isOverridden={numberFormatOverridden}
            isEditable={isNumberFormatEditable}
            onChange={updatePropertyValue}
            onSet={setNumberFormat}>
            {property.value !== AUTO_FORMAT && (
              <p className="mt5">
                <strong>{t('EXAMPLE')}</strong>&nbsp;
                {getValidatedNumber(property.value)}
              </p>
            )}

            <strong>{t('TABLE_BUILDER.PREDEFINED_FORMATS')}</strong>
            <ul className="pl20 mb0">
              {_.map(NUMBER_FORMATS, (format, index) => (
                <li key={index}>
                  <a href="#" onClick={handleAnchorClick(() => setNumberFormat(format.format))}>
                    {t(format.name)}
                  </a>
                </li>
              ))}
            </ul>
          </CustomFormat>
        );

      case ITEM_PROPS.StringFormat:
        return (
          <CustomFormat
            property={property}
            index={index}
            isValid={isValid}
            isOverridden={stringFormatOverridden}
            isEditable={isStringFormatEditable}
            onChange={updatePropertyValue}
            onSet={setStringFormat}>
            {property.value !== AUTO_FORMAT && (
              <p className="mt5">
                <strong>{t('EXAMPLE')}</strong>&nbsp;
                {t('PROPERTIES.STRING_FORMAT_SAMPLE')}
                &nbsp;&rarr;&nbsp;
                {getValidatedString(property.value, t('PROPERTIES.STRING_FORMAT_SAMPLE'))}
              </p>
            )}

            <strong>{t('TABLE_BUILDER.PREDEFINED_FORMATS')}</strong>
            <ul className="pl20 mb0">
              {_.map(STRING_FORMATS, (format, index) => (
                <li key={index}>
                  <a href="#" onClick={handleAnchorClick(() => setStringFormat(format.format))}>
                    {t(format.name)}
                  </a>
                </li>
              ))}
            </ul>
          </CustomFormat>
        );

      case ITEM_PROPS.MaximumDuration:
        return (
          <div className={classNames({ 'has-error': !isValid })}>
            {!isMaxInterpolationEditable && (
              <span>
                {property.value} {property.unitOfMeasure}
              </span>
            )}
            {isMaxInterpolationEditable && (
              <input
                autoComplete="off"
                type="text"
                name="maximumDuration"
                data-testid="maximumDuration"
                className="form-control input-sm width-maximum"
                value={property.value}
                onChange={(e) => updatePropertyValue(e.target.value, index)}
                autoFocus={property.triggerFocus}
                onFocus={(e) => e.target.select()}
                required={true}
                onBlur={() => isValid && setProperty(property)}
                onKeyUp={(e) => {
                  e.keyCode === KEY_CODES.ENTER && isValid && setProperty(property);
                  e.stopPropagation();
                }}
              />
            )}
          </div>
        );

      default:
        return (
          <>
            <span>{property.value}</span>
            {property.unitOfMeasure !== 'string' && <span>&nbsp;{property.unitOfMeasure}</span>}
          </>
        );
    }
  };

  return (
    <dl data-testid="itemPropertyList" className="dl-striped-reversed dl-horizontal-indented properties-list">
      {_.map(properties, (property, index) => {
        const isEven = (index + 1) % 2 === 0;

        return (
          <div className="propList__item" key={property.name}>
            <dt
              className={classNames('propList__item__name', {
                striped: isEven,
              })}>
              {property.name}
              {property.name === ITEM_PROPS.Id && (
                <CopyButtonGivenText
                  asType="icon"
                  textToCopy={item.id}
                  tooltip="COPY_ID.TO_CLIPBOARD"
                  extraClassNames="ml2"
                  containingClassNames="inlineBlock pl3"
                  notifyMessage="COPY_ID.SUCCESS"
                />
              )}
              {property.name === ITEM_PROPS.MaximumInterpolation &&
                !hasProperty(ITEM_PROPS.OverrideMaximumInterpolation) &&
                isMaxInterpolationEditable && (
                  <a
                    className="propList__item__override cursorPointer force-link-look pl3"
                    id="specMaxInterpolationOverride"
                    data-testid="specMaxInterpolationOverride"
                    onClick={handleAnchorClick(() =>
                      setProperty({
                        name: ITEM_PROPS.OverrideMaximumInterpolation,
                        value: property.value,
                      }),
                    )}>
                    {t('PROPERTIES.OVERRIDE')}
                  </a>
                )}

              {property.name === ITEM_PROPS.OverrideMaximumInterpolation && isMaxInterpolationEditable && (
                <Icon
                  icon="fa-close"
                  extraClassNames="btn-transparent removeButton"
                  onClick={() => deleteProperty(property)}
                  tooltip="REMOVE"
                  tooltipPlacement="top"
                />
              )}

              {property.name === ITEM_PROPS.NumberFormat && isNumberFormatEditable && (
                <OverridablePropertyIcons
                  property={property}
                  isOverridden={numberFormatOverridden}
                  help="PROPERTIES.NUMBER_FORMAT_HELP"
                  telemetry="https://telemetry.seeq.com/support-numbers"
                  onSet={setProperty}
                  onDelete={deleteProperty}
                  testId="numberFormatOverride"
                />
              )}

              {property.name === ITEM_PROPS.StringFormat && isStringFormatEditable && (
                <OverridablePropertyIcons
                  property={property}
                  isOverridden={stringFormatOverridden}
                  help="PROPERTIES.STRING_FORMAT_HELP"
                  onSet={setProperty}
                  onDelete={deleteProperty}
                  testId="stringFormatOverride"
                />
              )}
            </dt>

            <dd className="propList__item__value" data-testid={property.name}>
              <div className="aggressiveWordBreak max-height-200 overflowAuto">{renderPropValue(property, +index)}</div>
              {property.name === 'Formula' && (
                <div className="breakWord max-height-200 overflowAuto mt5">
                  <FormulaParameters selectedItem={item} />
                </div>
              )}
            </dd>
          </div>
        );
      })}
    </dl>
  );
};

type CustomFormatProps = {
  property: { value: string; triggerFocus: boolean };
  index: number;
  isValid: boolean;
  isOverridden: boolean;
  isEditable: boolean;
  onChange: (property: any, index: number) => void;
  onSet: (property: any) => void;
  children: object[];
};

function CustomFormat(props: CustomFormatProps) {
  const { property, index, isValid, isOverridden, isEditable, onChange, onSet, children } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames({ 'has-error': !isValid })}>
      {isOverridden && isEditable && (
        <div>
          <div className="input-group">
            <input
              autoComplete="off"
              type="text"
              name="overrideNumberFormat"
              data-testid="overrideNumberFormat"
              className="form-control input-sm width-maximum height-30"
              value={property.value}
              onChange={(e) => onChange(e.target.value, index)}
              autoFocus={property.triggerFocus}
              onFocus={(e) => e.target.select()}
              required={true}
              onKeyUp={(e) => {
                e.keyCode === KEY_CODES.ENTER && isValid && onSet(property.value);
                e.stopPropagation();
              }}
            />
            <span className="input-group-btn">
              <TextButton
                label="APPLY"
                extraClassNames="sq-btn-sm height-30"
                onClick={() => isValid && onSet(property.value)}
              />
            </span>
          </div>

          {children}
        </div>
      )}

      {(!isOverridden || !isEditable) && <div>{property.value}</div>}
    </div>
  );
}

type OverridablePropertyProps = {
  property: string;
  isOverridden: boolean;
  help: string;
  telemetry?: string;
  onSet: (property: any) => Promise<void>;
  onDelete: (property: any) => Promise<void>;
  testId: string;
};

function OverridablePropertyIcons(props: OverridablePropertyProps) {
  const { property, isOverridden, help, telemetry, onSet, onDelete, testId } = props;
  const { t } = useTranslation();

  return (
    <>
      {!isOverridden && (
        <a
          className="cursorPointer force-link-look pl3"
          data-testid={testId}
          onClick={handleAnchorClick(() => onSet(property))}>
          {t('PROPERTIES.OVERRIDE')}
        </a>
      )}

      {isOverridden && (
        <>
          <a
            href={telemetry ? 'https://telemetry.seeq.com/support-numbers' : null}
            target="_blank"
            className="link-no-underline">
            <Icon icon="fa-question-circle" extraClassNames="btn-transparent" tooltip={help} tooltipPlacement="top" />
          </a>
          <Icon
            icon="fa-close"
            extraClassNames="btn-transparent removeButton"
            onClick={() => onDelete(property)}
            tooltip="REMOVE"
            tooltipPlacement="top"
          />
        </>
      )}
    </>
  );
}
