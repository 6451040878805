import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { sqWorkbenchStore } from '@/core/core.stores';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { TourModal } from '@/hybrid/tour/TourModal.molecule';

const organizerTourModalBindings = bindingsDefinition({
  sqWorkbenchActions: injected<WorkbenchActions>(),
});

const ORGANIZER_STEP_COUNT = 4;
export const TOUR_NAME = 'organizerOverviewTour';

export const OrganizerTourModal: SeeqComponent<typeof organizerTourModalBindings> = () => {
  const { sqWorkbenchActions } = useInjectedBindings(organizerTourModalBindings);
  const organizerTourShown = useFluxPath(sqWorkbenchStore, () => _.includes(sqWorkbenchStore.toursShown, TOUR_NAME));

  const steps = _.map(_.range(1, ORGANIZER_STEP_COUNT + 1), (index) => ({
    heading: `TOUR.ORGANIZER.STEP_${index}`,
    gifSrc: `/img/OrganizerTourGif${index}.gif`,
  }));

  return (
    <TourModal
      tourTitle="TOUR.ORGANIZER.TITLE"
      displayTour={!organizerTourShown}
      tourSteps={steps}
      testId="organizerTour"
      trackName="organizer"
      onClose={() => sqWorkbenchActions.setTourShown(TOUR_NAME)}
    />
  );
};

export const sqOrganizerTourModal = angularComponent(organizerTourModalBindings, OrganizerTourModal);
