// @ts-strict-ignore
import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import bind from 'class-autobind-decorator';

import { ReportActions } from '@/reportEditor/report.actions';

import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { IPromise } from 'angular';
import { clearContentPropertyOverrides } from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities';
import {
  getContentJQuery,
  getContentWidgetViewElement,
  isContentInDocument,
} from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import WidgetResize from 'ckeditor5/packages/ckeditor5-widget/src/widgetresize';
import { ContentCallbacks, CustomPlugin } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { CONTENT_LOADED_EVENT } from '@/hybrid/annotation/ckEditorPlugins/plugins/content/ContentResize';
import { logWarn } from '@/hybrid/utilities/logger';
import { base64guid } from '@/hybrid/utilities/utilities';
import { formatMessage } from '@/hybrid/utilities/logger.utilities';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { CONTENT_MODEL_ATTRIBUTES } from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import i18next from 'i18next';
import {
  AssetSelection,
  Content,
  CONTENT_LOADING_CLASS,
  ContentDisplayMetadata,
  DateRange,
} from '@/reportEditor/report.constants';
import { sqReportStore } from '@/core/core.stores';

export const CK_RESIZER_CLASS = 'ck-widget_with-resizer';

@bind
export class CkReportContentService {
  constructor(private $injector: ng.auto.IInjectorService, private sqReportEditor: ReportEditorService) {}

  insertOrReplaceContent(contentId: string) {
    if (!isContentInDocument(contentId)) {
      // Insert
      const editor: any = this.sqReportEditor.getGlobalInstance();
      editor.execute('insertContent', editor.model.document.selection, contentId);
    } else {
      this.replaceContentIfExists(contentId);
    }
  }

  /**
   * A soft refresh differs from a forced refresh in that it doesn't clear the cached image in the backend. If the
   * image is already cached in the backend, we will receive the already cached image and not have to wait as long
   * for the image to generate.
   */
  private softRefreshContent(contentId: string, refresh: { silently?: boolean; deferImageUpdate?: boolean }) {
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');

    // Order of operations is important here. Setting the display metadata first lets the Content molecule grab it
    // to see what kind of refresh we need to do.
    sqReportActions.setContentDisplayMetadata({ contentId, refresh });
    sqReportActions.setContentHashCode(contentId, base64guid());
  }

  refreshAllContent(errorsOnly = false, deferImageUpdate = false, silently = false) {
    _.forEach(sqReportStore.contentNotArchived, (content) => {
      const metadata = sqReportStore.getContentDisplayMetadataById(content.id);
      if (!errorsOnly || metadata?.errorClass) {
        this.softRefreshContent(content.id, { silently, deferImageUpdate });
      }
    });
  }

  replaceContentIfExists(contentId: string, silently = false, deferImageUpdate = false): { isNewContent: boolean } {
    const isNewContent = !isContentInDocument(contentId);
    this.softRefreshContent(contentId, { silently, deferImageUpdate });
    return { isNewContent };
  }

  refreshContentUsingDate(dateRangeId: string, deferImageUpdate = false) {
    _.forEach(sqReportStore.contentUsingDateRange(dateRangeId), (content) => {
      this.softRefreshContent(content.id, { deferImageUpdate });
    });
  }

  contentError(contentId: string, error?: string, errorCode?: number): ContentDisplayMetadata {
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');
    const content = sqReportStore.getContentById(contentId);
    const errorClass = sqReportStore.getDateRangeById(content?.dateRangeId)?.auto.noCapsuleFound
      ? CONTENT_LOADING_CLASS.NO_CAPSULE_ERROR
      : CONTENT_LOADING_CLASS.ERROR;
    const displayMetadata = { contentId, errorClass, error, errorCode };
    if (content) {
      sqReportActions.setContent({ ...content, screenshotWarning: undefined });
      sqReportActions.setContentDisplayMetadata(displayMetadata);
      // Need to trigger a refresh of the content
      sqReportActions.setContentHashCode(contentId, base64guid());
    }
    sqReportActions.debouncedImageStateChanged();
    return displayMetadata;
  }

  reportScheduleError(error: string, errorCode: number) {
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');
    sqReportActions.setReportScheduleError(error, errorCode);
  }

  displayError(error) {
    if (error?.data?.statusMessage) {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
    } else {
      logWarn(formatMessage`Error generating content ${error}`);
    }
  }

  duplicateOrUnarchiveContent(
    content: Content,
    isContentInDocument: boolean,
    maybeDateRange?: DateRange,
    maybeAssetSelection?: AssetSelection,
  ): IPromise<any> {
    const sqReportContent = this.$injector.get<ReportContentService>('sqReportContent');

    return Promise.all([
      sqReportContent.copyDateRangeForPendingContent(maybeDateRange, Promise),
      sqReportContent.copyAssetSelectionForPendingContent(maybeAssetSelection, Promise),
    ])
      .then((result) => Promise.resolve([content, result[0], result[1]]))
      .then(([content, dateRangeId, assetSelectionId]) =>
        sqReportContent.duplicateOrUnarchivePendingContent(
          content as Content,
          isContentInDocument,
          Promise,
          dateRangeId as string,
          assetSelectionId as string,
        ),
      );
  }

  duplicateContentError(contentId: string, error: any): ContentDisplayMetadata {
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');
    // If pending content needs to be duplicated, and the current user doesn't have access to the content's
    // worksheet, then the user won't be able to finish loading the content.
    if (error.status === HttpCodes.FORBIDDEN) {
      const additionalMessage = i18next.t('REPORT.CONTENT.COULD_NOT_GENERATE');
      error.data.statusMessage = error.data.statusMessage.concat(`. ${additionalMessage}: ${contentId}`);
      sqReportActions.addContentError(contentId);
    } else if (error?.data?.statusMessage.includes('you do not have access to the source worksheet')) {
      // TO DO: CRAB-653 Refactor this when we internationalize error messages from the backend
      // This should catch only the backend error containing this message
      // If the user does not have access to the worksheet, display custom error
      sqReportActions.addContentError(contentId);
    }
    this.displayError(error);
    return this.contentError(contentId);
  }

  handleLiveScreenshotMessageForContent(
    contentId: string,
    hashCode: string,
    warning: string,
  ): { isNewContent: boolean } {
    const isNewContent = !isContentInDocument(contentId);
    const sqReportActions = this.$injector.get<ReportActions>('sqReportActions');

    sqReportActions.setContentDisplayMetadata({ contentId, refresh: {} });
    sqReportActions.setContentWarning(contentId, warning);
    sqReportActions.setContentHashCode(contentId, hashCode);
    return { isNewContent };
  }

  clearPropertyOverridesForContent(contentId: string) {
    const editor: Editor = this.sqReportEditor.getGlobalInstance() as any;
    clearContentPropertyOverrides(editor, contentId);
  }

  maybeClearVisualizationSpecificState(contentId: string, wasReact: boolean, isReact: boolean) {
    if (wasReact !== isReact) {
      const editor: Editor = this.sqReportEditor.getGlobalInstance() as any;
      const contentHtmlElement = getContentJQuery(contentId)[0];
      const widgetView = getContentWidgetViewElement(contentHtmlElement, editor);
      const widgetResizePlugin = editor.plugins.get(WidgetResize);
      const resizer = widgetResizePlugin.getResizerByViewElement(widgetView);
      if (!wasReact) {
        if (resizer) {
          // The below is necessary because the source element for the resizer doesn't exist at this point, so we
          // can't use the default `resizer.destroy()`. The below several changes are equivalent but they don't rely
          // on the source element existing.
          resizer.redraw = _.noop;
          resizer.stopListening();
          widgetResizePlugin._resizers.delete(widgetView);
          editor.editing.view.change((writer) => {
            writer.removeClass(CK_RESIZER_CLASS, widgetView);
          });
        }

        this.clearContentResize(contentId);
      } else {
        // The resizer occasionally draws a second resizing box on toggling, so we can rely on the destroy method to
        // delete the existing one and then manually fire an event which will create the correctly sized one.
        if (resizer) {
          resizer.destroy();
        }
        editor.model.document.fire(CONTENT_LOADED_EVENT, contentHtmlElement);
      }
    }
  }

  clearContentResize(contentId: string) {
    //region rant
    /*
    Overall CK is a very good editing system when the work you're doing is raw HTML heavy. However, the
    system was clearly not designed for managing complex React components. Because its generally well
    designed wrt customization, its totally doable, but this is one of those cases where its true focus
    rears its ugly head. Essentially, we need to keep track of the resizing in 3 places: the view, the model
    and the react component that is rendering MOST (but not all) of the view.

    In most cases, the view and the model should be close to 1:1, but due to Content being a Widget, CK
    explicitly does not update the view based on what's in the model, nor does it update the model
    based on changes in the view, but it does occasionally need to refresh the widgets's view (due to changes in
    spacing) and so refreshes the view with the classes/styles originally given to it, NOT what is in the
    model. Thankfully, CK exposes the view so we can handle this ourselves, resulting in us having to clear
    the state in the model, the state in the view, AND the state in the react component to keep everything
    in sync. Ideally we'd have a way to tell CK "if these model attributes change propagate the changes to the
    view". We partially do that with the the `editor.model.change`, as that hooks into an event defined in
    `Content.tsx::defineAttributeEvents` which fires a content specific event which `Content.molecule` is
    listening for. But we still have to listen in the content for that event, which is kind of annoying.
     */
    //endregion

    const editor: Editor = this.sqReportEditor.getGlobalInstance() as any;
    const contentCallbacks: ContentCallbacks = editor.config.get(CustomPlugin.Content).contentCallbacks;

    // Removes the width element from the parent div view that actually has the width style.
    editor.editing.view.change((writer) =>
      writer.removeStyle('width', getContentWidgetViewElement(getContentJQuery(contentId)[0], editor)),
    );
    // Removes the attribute from the model
    editor.model.change((writer) =>
      writer.removeAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT, contentCallbacks.getCurrentModel(contentId)),
    );
  }
}
