import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

const reportTimeZoneBindings = bindingsDefinition({
  onTzClick: prop<() => void>(),
  isDisabled: prop<boolean>(),
  timezoneFixed: prop<boolean>(),
  timezoneName: prop<string>(),
});

export const ReportTimeZone: SeeqComponent<typeof reportTimeZoneBindings> = (props) => {
  const { onTzClick, isDisabled, timezoneFixed, timezoneName } = props;
  const { t } = useTranslation();

  return (
    <a href="#" id="specReportCustomTimeZone" className={isDisabled ? 'disabled' : ''} onClick={onTzClick}>
      {timezoneFixed ? (
        <span id="specReportCustomTimeZoneName" className="link-no-focus link-no-underline">
          {timezoneName}
        </span>
      ) : (
        <HoverTooltip text="REPORT.CONFIG.TIME_ZONE_NONE_TOOLTIP">
          <span className="link-no-focus link-no-underline">
            <i>{t('REPORT.CONFIG.TIME_ZONE_NONE')}</i>
          </span>
        </HoverTooltip>
      )}
    </a>
  );
};
