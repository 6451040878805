// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { APP_STATE, HOME_SCREEN_TABS_AND_TRANSLATION_KEYS } from '@/main/app.constants';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { generateTabHash } from '@/hybrid/utilities/utilities';
import { HOME_SCREEN_TABLE_TYPE } from '@/hybrid/homescreen/homescreen.constants';
import { sqHomeScreenStore } from '@/core/core.stores';

const homeScreenBreadCrumbBindings = bindingsDefinition({
  currentTabName: prop.optional<string>(),
  inAdvancedSearch: prop.optional<boolean>(),
  clickBreadcrumb: prop.optional<any>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  $state: injected<any>(),
});

/** Creates a string of clickable breadcrumbs for home screen folders.
 * This is also used to display the current folder location in the home screen advanced search. **/
export const HomeScreenBreadCrumbs: SeeqComponent<typeof homeScreenBreadCrumbBindings> = ({
  currentTabName,
  inAdvancedSearch,
  clickBreadcrumb,
}) => {
  const { $state, sqHomeScreenActions } = useInjectedBindings(homeScreenBreadCrumbBindings);

  const { t } = useTranslation();

  const breadcrumbs = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.breadcrumbs);
  const currentTabKey = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentTab);
  const rootNodeDisplay = _.get(_.find(HOME_SCREEN_TABS_AND_TRANSLATION_KEYS, { value: currentTabKey }), 'text');

  const goToFolder = (folderId) => {
    sqHomeScreenActions.setPageNumber(1, HOME_SCREEN_TABLE_TYPE.TAB);
    return $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId: folderId });
  };

  // TODO: CRAB-28812 - clean this up to remove the need for the if statement to improve scalability
  const openRootNode = () => {
    if (inAdvancedSearch) {
      clickBreadcrumb({ inBreadcrumbsComponent: true });
    } else {
      sqHomeScreenActions.setPageNumber(1, HOME_SCREEN_TABLE_TYPE.TAB);
      sqHomeScreenActions.clearBreadcrumbs();
      return $state.go(APP_STATE.WORKBOOKS, {
        t: generateTabHash(currentTabKey),
      });
    }
  };

  const hasFolders = !_.isEmpty(breadcrumbs);

  if (!hasFolders) {
    return null;
  }

  return (
    <div id="breadcrumbLinks">
      <span className="homeScreenBreadcrumbLink cursorPointer" onClick={openRootNode}>
        {!inAdvancedSearch ? t(rootNodeDisplay) : t(`HOME_SCREEN.LOCATION.${currentTabName}`)}
        <span className="mr3 ml3">»</span>
      </span>
      {_.map(breadcrumbs, (crumb) => {
        const isLast = crumb === _.last(breadcrumbs);
        return (
          <span
            key={`crumbId_${crumb.id}`}
            className={classNames({
              'homeScreenBreadcrumbLink cursorPointer': !isLast,
            })}
            onClick={() =>
              inAdvancedSearch
                ? clickBreadcrumb({
                    folderId: crumb.id,
                    inBreadcrumbsComponent: false,
                  })
                : goToFolder(_.get(crumb, 'id'))
            }>
            {_.get(crumb, 'name')}
            {!isLast && <span className="mr3 ml3">»</span>}
          </span>
        );
      })}
    </div>
  );
};
