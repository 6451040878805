// @ts-strict-ignore
import angular from 'angular';

import _ from 'lodash';
import { sqReportEditorFroala } from '@/reportEditor/reportEditorFroala.service';
import { sqReportEditorCKEditor } from '@/reportEditor/reportEditorCKEditor.service';
import { sqAnnotationStore, sqReportStore } from '@/core/core.stores';
import { isCkEditorEnabled } from '@/services/systemConfiguration.utilities';

angular.module('Sq.Report').factory('sqReportEditor', sqReportEditorService);

function sqReportEditorService() {
  const editors = {
    ckEditor: sqReportEditorCKEditor(),
    froala: sqReportEditorFroala(),
  };

  const isCkEditor = (): boolean => {
    if (_.isUndefined(sqReportStore.isCkEnabled) && !sqAnnotationStore.isCkEnabled) {
      return canUpgradeToCk();
    }

    return sqReportStore.isCkEnabled || sqAnnotationStore.isCkEnabled;
  };

  const getEditorService = () => (isCkEditor() ? editors.ckEditor : editors.froala);

  const canUpgradeToCk = () => isCkEditorEnabled();

  return {
    saveReport: () => getEditorService().saveReport(),
    triggerDocumentChangedEvent: () => getEditorService().triggerDocumentChangedEvent(),
    getHtml: () => getEditorService().getHtml(),
    insertHtml: (html) => getEditorService().insertHtml(html),
    setHtml: (html, forceUpdate) => getEditorService().setHtml(html, forceUpdate),
    saveSelection: () => getEditorService().saveSelection(),
    restoreSelection: () => getEditorService().restoreSelection(),
    getScrollOffset: () => getEditorService().getScrollOffset(),
    setScrollOffset: (offset) => getEditorService().setScrollOffset(offset),
    setReportViewHtml: (document) => getEditorService().setReportViewHtml(document),
    setGlobalInstance: (instance) => getEditorService().setGlobalInstance(instance),
    getGlobalInstance: () => getEditorService().getGlobalInstance(),
    setCursorPosition: (cursorPosition) => getEditorService().setCursorPosition(cursorPosition),
    getCursorPosition: () => getEditorService().getCursorPosition(),
    focus: () => getEditorService().focus(),
    executeCommand: (options) => getEditorService().executeCommand(options),
    isCkEditor,
    canUpgradeToCk,
  };
}

interface ReportEditor {
  isCkEditor: () => boolean;
  canUpgradeToCk: () => boolean;
  setHtml: (html: string, forceUpdate: boolean) => void;
}

export type ReportEditorService = ReturnType<typeof sqReportEditorFroala> & ReportEditor;
