// @ts-strict-ignore
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import i18next from 'i18next';
import { useTimeoutWhen } from 'rooks';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { JournalFooter } from '@/hybrid/annotation/JournalFooter.molecule';
import { JournalComments } from '@/hybrid/annotation/JournalComments.molecule';
import { JournalHeader } from '@/hybrid/annotation/JournalHeader.molecule';
import { CustomPlugin } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { Editor } from '@/hybrid/annotation/Editor.organism';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { ResizableToolPanel } from '@/hybrid/core/ResizableToolPanel.atom';
import {
  hasLoadingPastedImages,
  isPresentationWorkbookMode,
  isTrendable,
  parseQueryString,
  validateGuid,
} from '@/hybrid/utilities/utilities';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { API_TYPES } from '@/main/app.constants';
import { FroalaPluginsService } from '@/reportEditor/froalaPlugins.service';
import { formatTime } from '@/hybrid/datetime/dateTime.utilities';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { sqAnnotationStore, sqWorkbenchStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import { ID_PLACEHOLDER, LINK_TYPE } from '@/annotation/annotation.constants';
import { WORKSHEET_SIDEBAR_TAB } from '@/worksheet/worksheet.constants';
import { canAnnotateItem, canManageItem, canWriteItem } from '@/services/authorization.service';
import { CopyForEmail } from '@/hybrid/annotation/ckEditorPlugins/plugins/CopyForEmail';
import { nameAndDescriptionFromDocument } from '@/hybrid/utilities/annotation.utilities';

const journalEntryBindings = bindingsDefinition({
  sqAnnotationActions: injected<AnnotationActions>(),
  sqReportEditor: injected<ReportEditorService>(),
  sqFroalaPlugins: injected<FroalaPluginsService>(),
});

export const JournalEntry: SeeqComponent<typeof journalEntryBindings> = () => {
  const { sqAnnotationActions, sqReportEditor, sqFroalaPlugins } = useInjectedBindings(journalEntryBindings);

  const ref = useRef(null);
  const isPresentationMode = isPresentationWorkbookMode();
  const {
    displayHeight: panelDisplayHeight,
    displayWidth: panelDisplayWidth,
    selectedSidebarTab,
  } = useFlux(sqWorksheetStore);

  const {
    id: entryId,
    document,
    annotations,
    isDiscoverable,
    isExpanded,
    isCommentsExpanded,
  } = useFlux(sqAnnotationStore);

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [savePromise, setSavePromise] = useState(null);
  const [startFocus, setStartFocus] = useState(false);

  const entry = _.find(annotations, ['id', entryId]);
  const workbookId = _.get(_.find(entry?.interests, ['item.type', API_TYPES.ANALYSIS, API_TYPES.TOPIC]), 'item.id');
  const worksheetId = _.get(_.find(entry?.interests, ['item.type', API_TYPES.WORKSHEET]), 'item.id');
  const comments = entry?.replies;

  const isDeleteAllowed = canManageItem(entry);
  const isEditAllowed =
    canWriteItem(entry) ||
    // For new journal entries or annotations, we don't have an entry to use for authorization check, so instead
    // allow editing of annotations if the user has read access to the workbook and only allow editing of journal
    // entries if the user has write access to the workbook.
    (entryId === ID_PLACEHOLDER &&
      (isDiscoverable ? sqWorkbookStore.effectivePermissions.read : sqWorkbookStore.effectivePermissions.write));
  const canEdit = isEditAllowed && !isPresentationMode;

  const isCommentAllowed = validateGuid(entryId) && canAnnotateItem(entry);
  const hasMultipleJournalEntries =
    sqAnnotationStore.findJournalEntries(annotations, sqWorkbenchStore.stateParams.worksheetId).length > 1;

  const toolbar = [
    CustomPlugin.JournalLink,
    'link',
    'uploadImage',
    'insertTable',
    '|',
    'heading',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'alignment',
    'outdent',
    'indent',
    'numberedList',
    'bulletedList',
    'pagebreak',
    '|',
    'removeFormat',
    CopyForEmail.pluginName,
    '|',
    'undo',
    'redo',
  ];

  const plugins = [CustomPlugin.JournalLink];

  const updatedAt = formatTime(entry?.updatedAt, sqWorksheetStore.timezone);
  const createdBy = _.get(entry, 'createdBy.name');
  const annotates = _.chain(annotations).find(['id', entryId]).get('items').value();
  const ranges = _.chain(annotates).filter(['type', API_TYPES.CAPSULE]).value();
  const interests = _.chain(annotates)
    .filter(isTrendable)
    .map((item: any) => ({ interestId: item.id }))
    .value();

  useEffect(() => {
    setStartFocus(selectedSidebarTab === WORKSHEET_SIDEBAR_TAB.ANNOTATE);
  }, [selectedSidebarTab]);

  useTimeoutWhen(
    () => {
      setStartFocus(false);
      sqReportEditor.focus();
    },
    0,
    startFocus,
  );

  /**
   * Update an existing comment
   *
   * @param id - the ID of the comment to update
   * @param name - the text of the comment
   */
  const updateComment = (id: string, name: string): void => {
    sqAnnotationActions
      .save({ id, name })
      .then(fetchAnnotations)
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  };

  /**
   * Updates the view model interests and ranges by examining all the seeq links in the document.
   *
   * @param document - a string containing the html document
   */
  const updateAnnotates = useCallback(
    (updatedDocument: string) => {
      let htmlElements, anchors;
      const newInterests = [];
      const newRanges = [];

      if (isDiscoverable) {
        // Ensure images don't load while parsing: https://stackoverflow.com/a/50194774/1108708
        const ownerDocument = window.document.implementation.createHTMLDocument('virtual');
        htmlElements = _.filter(
          $.parseHTML(updatedDocument, ownerDocument),
          (elements) => elements.nodeName !== '#text',
        );
        anchors = _.transform(
          htmlElements,
          (anchors, element: any) => {
            _.forEach([].slice.call(element.getElementsByTagName('a')), (a) => {
              anchors.push(a);
            });
          },
          [],
        );

        _.forEach(anchors, (a: any) => {
          let params;

          if (_.endsWith(a.pathname, '/links')) {
            params = parseQueryString(a.search);

            if (
              _.includes(
                [LINK_TYPE.SIGNAL, LINK_TYPE.CONDITION, LINK_TYPE.CAPSULE, LINK_TYPE.TABLE, LINK_TYPE.METRIC],
                params.type,
              )
            ) {
              if (validateGuid(params.item)) {
                newInterests.push({ interestId: params.item });
              }
            }

            if (params.type === LINK_TYPE.CAPSULE) {
              if (_.isNumber(+params.start) && _.isNumber(+params.end)) {
                newRanges.push({ start: +params.start, end: +params.end });
              }
            }
          }
        });
      }

      return {
        inputInterests: _.uniqWith(newInterests, _.isEqual),
        ranges: _.uniqWith(newRanges, _.isEqual),
      };
    },
    [isDiscoverable],
  );

  /**
   * Handler for callback notifications that the document has been changed by the user.
   *
   * @param document - the updated document
   * @param forceSave - true to force the document to save
   * @returns Promise that resolves when the document has been saved
   */
  const documentChanged = useRef(_.noop);
  useEffect(() => {
    documentChanged.current = (updatedDocument: string, forceSave: boolean): Promise<any> => {
      if (document === updatedDocument && !forceSave) {
        return Promise.resolve();
      }

      const { description, name } = nameAndDescriptionFromDocument(updatedDocument);
      const { inputInterests, ranges } = updateAnnotates(updatedDocument);

      return saveEntry({
        document: updatedDocument,
        name,
        description,
        inputInterests,
        ranges,
      });
    };
  }, [document, entryId, workbookId, worksheetId, isDiscoverable]);

  /**
   * Fetches the annotations for the items loaded in the trend as well as those scoped to the workbook.
   */
  const fetchAnnotations = () => {
    sqAnnotationActions.fetchAnnotations().catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
  };

  /**
   * Save a new comment
   *
   * @param  id - ID of the journal entry to which this is a comment
   * @param  name - the text of the comment
   */
  const saveComment = (id: string, name: string) => {
    sqAnnotationActions
      .save({ repliesTo: id, name })
      .then(fetchAnnotations)
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  };

  /**
   * Closes the editor panel and switches to the panel listing the annotations. Ensures that save is complete before
   * switching to ensure that the list of annotations shown is fresh.
   */
  const close = () => {
    (isSaving ? savePromise : Promise.resolve()).then(() => sqAnnotationActions.closeEntry());
  };

  /**
   * Helper function that saves a journal entry, its interests, and ranges. All arguments are optional because
   * default values from view model will be used for any arguments that are not provided.
   *
   * @param [args] - Object container
   * @param [args.name] - The name of the journal entry
   * @param [args.id] - The ID of the journal entry if saving an already existing entry.
   * @param [args.description] - The description of the journal entry
   * @param [args.document] - The journal entry document
   * @param [args.inputInterests] - The journal entry interests
   * @param [args.inputInterests[].interestId] - Id of item being annotated
   * @param [args.inputInterests[].detailId] - Id of item inside a set, such as a capsule, being annotated
   * @param [args.ranges] - The start/end pairs of interest. A new interest will be added for each range
   */
  const saveEntry = useCallback(
    (args?: {
      name: string;
      id?: string;
      description: string;
      document: string;
      inputInterests: Array<{
        interestId: string;
        detailId: string;
      }>;
      ranges: Array<any>;
    }) => {
      const defaultArgs = {
        id: validateGuid(entryId) ? entryId : undefined,
        discoverable: isDiscoverable,
        workbookId,
        worksheetId,
        name: '',
        description: '',
        document,
        inputInterests: interests,
        ranges,
      };

      if (isSaving && !validateGuid(entryId)) {
        // We're currently saving, but don't yet have an guid for the journal/annotation, so "queue" the save until a
        // guid has been assigned to avoid creating duplicates.
        return savePromise.finally(_.partial(saveEntry, args));
      }

      const mergedArgs = _.merge(defaultArgs, args);
      setIsSaving(true);
      const saveNewPromise = sqAnnotationActions
        .save(mergedArgs)
        .then((response) => {
          sqAnnotationActions.setId(response.id);
          if (!hasLoadingPastedImages(sqReportEditor.getHtml())) {
            setIsSaving(false);
            setIsSaved(true);
          }
          return response;
        })
        .catch((e) => {
          if (e.status === -1) {
            // Ignore local cancellations because another save request has already been made
            return;
          }
          setIsSaving(false);
          setIsSaved(false);
          errorToast({ httpResponseOrError: e });
        });

      setSavePromise(saveNewPromise);
    },
    [entryId, isDiscoverable, workbookId, worksheetId, document, interests, ranges, isSaving, savePromise],
  );

  /**
   * Callback from the journal editor before it executes $onInit
   */
  const beforeEditorOnInit = (editorOptions) => {
    sqFroalaPlugins.journalLink();

    // Toolbar in small spaces
    const compactToolbarButtons = {
      moreRich: {
        buttons: ['journalLink', 'insertLink', 'insertImage', 'insertTable'],
        buttonsVisible: 4,
      },
      moreParagraph: {
        buttons: [
          'paragraphFormat',
          'textColor',
          'backgroundColor',
          'align',
          'outdent',
          'indent',
          'formatOL',
          'formatUL',
        ],
        buttonsVisible: 4,
      },
      moreMisc: {
        buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'clearFormatting', 'undo', 'redo'],
        align: 'right',
        buttonsVisible: 0,
      },
    };

    // Toolbar in larger spaces
    const toolbarButtons = [
      'journalLink',
      'insertLink',
      'insertImage',
      'insertTable',
      '|',
      'paragraphFormat',
      'textColor',
      'backgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      '|',
      'align',
      'outdent',
      'indent',
      'formatOL',
      'formatUL',
      '|',
      'clearFormatting',
      '|',
      'undo',
      'redo',
    ];

    _.assign(editorOptions, {
      theme: 'analysis',
      // The scrollableContainer option is needed by the journal editor to make it function when it is expanded
      scrollableContainer: '.fr-wrapper',
      toolbarMaxWidthXS: 350, // Approximate width of the journal panel when collapsed
      toolbarButtonsXS: compactToolbarButtons,
      toolbarButtonsSM: toolbarButtons,
      toolbarButtonsMD: toolbarButtons,
      toolbarButtons,
      listAdvancedTypes: false,
      placeholderText: i18next.t('JOURNAL.ENTRY.PLACEHOLDER'),
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      imageEditButtons: [
        'imageReplace',
        'linkOpen',
        'imageLink',
        'imageAlign',
        'imageStyle',
        '-',
        'imageAlt',
        'linkEdit',
        'imageSize',
        'imageRemove',
      ],
    });
  };

  const journalComments = (
    <div className="flexRowContainer flexFill">
      <JournalComments
        id={entryId}
        comments={comments}
        isExpanded={isExpanded}
        isCommentsExpanded={isCommentsExpanded}
        isCommentAllowed={isCommentAllowed}
        isPresentationMode={isPresentationMode}
        updateComment={updateComment}
        deleteComment={sqAnnotationActions.delete}
        canManage={canManageItem}
        customSaveComment={saveComment}
        customExpandComments={sqAnnotationActions.setCommentsExpanded}
      />
    </div>
  );

  return (
    <div
      className={classNames('journalEntry flexFillOverflow flexRowContainer', {
        resizablePanel: isExpanded,
      })}
      ref={ref}>
      <ResizableToolPanel
        resizeEnabled={isExpanded}
        extraClassNames={classNames('flexRowContainer flexFillOverflow resizableJournalToolPanel', {
          'resizable showResizeablePanel': isExpanded,
        })}
        useDefaultClassNames={isExpanded}
        refLink={ref}
        width={isExpanded ? panelDisplayWidth : 'auto'}
        height={isExpanded ? panelDisplayHeight : 'auto'}
        minimumWidth={isExpanded ? 755 : undefined}
        minimumHeight={isExpanded ? 500 : undefined}
        setWidth={sqAnnotationActions.setWidth}
        setHeight={sqAnnotationActions.setHeight}>
        <div className="flexFillOverflow flexRowContainer">
          <JournalHeader
            showOverview={sqAnnotationActions.closeEntry}
            setExpanded={sqAnnotationActions.setExpanded}
            isExpanded={isExpanded}
            createdBy={createdBy}
            isSaving={isSaving}
            isSaved={isSaved}
            updatedAt={updatedAt}
            hasMultipleJournalEntries={hasMultipleJournalEntries}
            isDiscoverable={isDiscoverable}
            annotationId={entryId}
            deleteAnnotation={sqAnnotationActions.delete}
            close={close}
            isDeleteAllowed={isDeleteAllowed}
          />
          <div
            className={classNames(
              'flexFillOverflow ckEditorMain',
              isExpanded ? 'flexColumnContainer' : 'flexRowContainer',
            )}>
            <div className="flexFillOverflow flexRowContainer minWidthZero">
              <div
                className="journalOnly flexBasisZero flexFillOverflow flexRowContainer dividerBorderTop dividerBorderBottom
      min-height-100 editor">
                <Editor
                  id={entryId}
                  document={document}
                  documentChanged={documentChanged}
                  toolbar={toolbar}
                  plugins={plugins}
                  isJournal={true}
                  onDestroy={_.noop}
                  beforeOnInit={beforeEditorOnInit}
                  afterOnInit={_.noop}
                  canEdit={canEdit}
                  isCommentsExpanded={isCommentsExpanded}
                />
              </div>
              <JournalFooter
                isExpanded={isExpanded}
                isCommentsExpanded={isCommentsExpanded}
                isPresentationMode={isPresentationMode}
                commentsCount={comments?.length}
                customExpandComments={sqAnnotationActions.setCommentsExpanded}
              />
              <div className="flexRowContainer">{!isExpanded && journalComments}</div>
            </div>
            <div className="flexRowContainer">{isExpanded && journalComments}</div>
          </div>
        </div>
      </ResizableToolPanel>
    </div>
  );
};

export const sqJournalEntry = angularComponent(journalEntryBindings, JournalEntry);
