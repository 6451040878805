// @ts-strict-ignore
import React from 'react';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormulaLink } from '@/hybrid/formula/FormulaLink.atom';

const JOURNAL_LINK_SRC_SNIPPET = '/links?type=';

interface ContainerWithHTMLProps {
  content: string;
  testId?: string;
  isBlock?: boolean;
  id?: string;
  extraClassNames?: string;
}

// Without this the target attribute is stripped off and links will open
// in the same window. See https://github.com/cure53/DOMPurify/issues/317
DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node && !_.includes(node.href, JOURNAL_LINK_SRC_SNIPPET)) {
    // set all elements owning target to target=_blank
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

interface FormulaTextWithHTMLProps {
  content: string;
  requestDocumentation: (href: string) => void;
}

// Formula Documentation text may contain <sq-link> tags; Those tags must be rendered as proper links and point to
// the correct documentation as provided by the href attribute of the tag. To ensure we can click on the link we
// need to create a proper React component and call the provided click handler.
// To see an example of what is returned search for "IF97_absolutePressure()". The first description block will contain
// a link.
export const FormulaTextWithHTML: React.FunctionComponent<FormulaTextWithHTMLProps> = (props) => {
  const { content, requestDocumentation } = props;
  const endTag = '</sq-link>';
  const cleanedContent = [];
  let remainingContent = content ?? '';
  let sqLinkIdx = remainingContent.indexOf('<sq-link');
  while (sqLinkIdx > -1) {
    cleanedContent.push(
      <ContainerWithHTML content={remainingContent.substring(0, sqLinkIdx)} key={`before_${sqLinkIdx}`} />,
    );
    remainingContent = remainingContent.substring(sqLinkIdx);
    const endTagIndex = remainingContent.indexOf(endTag);
    const linkText = remainingContent.substring(0, endTagIndex);
    const href = linkText.substring(linkText.indexOf('/'), linkText.lastIndexOf('"'));
    const displayText = linkText.substring(linkText.indexOf('>') + 1);
    cleanedContent.push(
      <FormulaLink href={href} requestDocumentation={requestDocumentation} key={`link_${sqLinkIdx}`}>
        <ContainerWithHTML content={displayText} />
      </FormulaLink>,
    );
    remainingContent = remainingContent.substring(endTagIndex + _.size(endTag));
    sqLinkIdx = remainingContent.indexOf('<sq-link');
  }
  cleanedContent.push(<ContainerWithHTML content={remainingContent} key="remainingContent" />);
  return <>{_.map(cleanedContent, (content) => content)}</>;
};

/**
 * Generic binding mechanism for HTML, similar to AngularJS’s ng-bind-html.
 */
export const ContainerWithHTML: React.FunctionComponent<ContainerWithHTMLProps> = (props) => {
  const { content, testId, isBlock = false, extraClassNames, ...rest } = props;

  return React.createElement(isBlock ? 'div' : 'span', {
    ...rest,
    'data-testid': testId,
    'className': extraClassNames,
    'dangerouslySetInnerHTML': { __html: DOMPurify.sanitize(content) },
  });
};

interface TranslationWithHTMLProps {
  translationKey: string;
  translationParams?: object;
  extraClassName?: string;
}

/**
 * Binding mechanism that first translates and substitutes a provided key and properties before binding.
 * Note that translation strings should not include HTML. This component can be used when translation parameters are
 * used to display HTML inline, such as is necessary for inline icons.
 */
export const TranslationWithHTML: React.FunctionComponent<TranslationWithHTMLProps> = (props) => {
  const { translationKey, translationParams = {}, extraClassName } = props;
  const { t } = useTranslation();

  return (
    <ContainerWithHTML
      content={t(translationKey, {
        ...translationParams,
        interpolation: { escapeValue: false },
      })}
      extraClassNames={extraClassName}
    />
  );
};
