import angular from 'angular';
import _ from 'lodash';
import {
  EXPORT_TO_ODATA_TOOL_ID,
  InvestigateToolType,
  SCATTER_CONDITION_TOOL_ID,
} from '@/hybrid/toolSelection/investigate.constants';

const dependencies = [
  'Sq.Vendor',
  'Sq.TrendData',
  'Sq.Core',
  'Sq.LicenseManagement',
  'Sq.Worksheets',
  'Sq.Worksteps',
  'Sq.Workbench',
  'Sq.Services.CalculationRunner',
  'Sq.Services.ToolRunner',
];

/**
 * Investigate module provides tools for creating and manipulating signals and conditions
 */
angular.module('Sq.Investigate', dependencies);
/**
 * List all of all the tools and properties that describe them:
 *
 * @typedef {Object[]} InvestigateTool
 * @property {String} id - A unique identifier for the tool. Should not change as it is stored as the
 *   calculationType in the UIConfig.
 * @property {String} iconClass - The classes that give the tool a unique icon.
 * @property {String} name - The translation key for the name of the tool.
 * @property {String} description - The translation key for the description of the tool.
 * @property {String} parentId - The id of the group to which the tool belongs. Required on all tools except the
 *   root overview group.
 * @property {Boolean} [hideFromSearch=false] - True if it should not be included when filtering the list of
 *   tools, false to include it in the search.
 * @property {Boolean} [isGroup=false] - True if it is not a tool, but a group container for other tools.
 * @property {Boolean} [forAllItems=false] - True if the tool can be used by all types of items, false otherwise.
 * @property {Boolean} [forAllSeries=false] - True if the tool can be used by all signals, false otherwise.
 * @property {String} [defaultDisplayMode=NEW] - The default display mode when switching to the tool.
 */
const BASE_INVESTIGATE_TOOLS: InvestigateToolType[] = [
  {
    id: 'overview',
    trackAction: 'Overview',
    iconClass: 'fc fc-home',
    nameTranslationKey: 'TOOLBAR.HOME',
    descriptionTranslationKey: 'TOOLBAR.HOME',
    hideFromSearch: true,
    isGroup: true,
  },
  {
    id: 'identify',
    parentId: 'overview',
    trackAction: 'Identify',
    iconClass: 'fc fc-identify',
    nameTranslationKey: 'INVESTIGATE_TOOLS.IDENTIFY.HEADER',
    descriptionTranslationKey: 'INVESTIGATE_TOOLS.IDENTIFY.DESCRIPTION',
    isGroup: true,
  },
  {
    id: 'quantify',
    parentId: 'overview',
    trackAction: 'Quantify',
    iconClass: 'fc fc-quantify',
    nameTranslationKey: 'INVESTIGATE_TOOLS.QUANTIFY.HEADER',
    descriptionTranslationKey: 'INVESTIGATE_TOOLS.QUANTIFY.DESCRIPTION',
    isGroup: true,
  },
  {
    id: 'cleanse',
    parentId: 'overview',
    trackAction: 'Cleanse',
    iconClass: 'fc fc-cleanse',
    nameTranslationKey: 'INVESTIGATE_TOOLS.CLEANSE.HEADER',
    descriptionTranslationKey: 'INVESTIGATE_TOOLS.CLEANSE.DESCRIPTION',
    isGroup: true,
  },
  {
    id: 'model-and-predict',
    parentId: 'overview',
    trackAction: 'Model and Predict',
    iconClass: 'fc fc-model-and-predict',
    nameTranslationKey: 'INVESTIGATE_TOOLS.MODEL_PREDICT.HEADER',
    descriptionTranslationKey: 'INVESTIGATE_TOOLS.MODEL_PREDICT.DESCRIPTION',
    isGroup: true,
  },
  {
    id: 'import-export',
    parentId: 'overview',
    trackAction: 'Import/Export',
    iconClass: 'fc fc-export',
    nameTranslationKey: 'INVESTIGATE_TOOLS.IMPORT_EXPORT.HEADER',
    descriptionTranslationKey: 'INVESTIGATE_TOOLS.IMPORT_EXPORT.DESCRIPTION',
    isGroup: true,
  },
  {
    id: 'addon-tools-group',
    parentId: 'overview',
    trackAction: 'External Tools Group',
    iconClass: 'fa fa-external-link',
    nameTranslationKey: 'ADDON_TOOLS.NAME',
    descriptionTranslationKey: 'ADDON_TOOLS.DESCRIPTION',
    isGroup: true,
  },
  {
    id: 'display',
    parentId: 'overview',
    trackAction: 'Display',
    iconClass: 'fc fc-displays',
    nameTranslationKey: 'DISPLAYS.TOOL.HEADER',
    descriptionTranslationKey: 'DISPLAYS.TOOL.DESCRIPTION',
  },
  {
    id: 'manual-signal',
    parentId: 'import-export',
    trackAction: 'Manual Signal',
    iconClass: 'fc fc-series',
    nameTranslationKey: 'MANUAL_SIGNAL.HEADER',
    descriptionTranslationKey: 'MANUAL_SIGNAL.DESCRIPTION',
  },
  {
    id: 'value-search',
    parentId: 'identify',
    trackAction: 'Value Search',
    iconClass: 'fc fc-search-limit',
    nameTranslationKey: 'VALUE_SEARCH.HEADER',
    descriptionTranslationKey: 'VALUE_SEARCH.HEADER_DESCRIPTION',
  },
  {
    id: 'periodic-condition',
    parentId: 'identify',
    trackAction: 'Periodic Condition',
    iconClass: 'fc fc-periodic-condition',
    nameTranslationKey: 'PERIODIC_CONDITION.HEADER',
    descriptionTranslationKey: 'PERIODIC_CONDITION.HEADER_DESCRIPTION',
  },
  {
    id: 'composite-search',
    parentId: 'identify',
    trackAction: 'Composite Search',
    iconClass: 'fc fc-search-logic',
    nameTranslationKey: 'COMPOSITE_SEARCH.HEADER',
    descriptionTranslationKey: 'COMPOSITE_SEARCH.HEADER_DESCRIPTION',
  },
  {
    id: 'manual-condition',
    parentId: 'identify',
    trackAction: 'Custom Condition',
    iconClass: 'fc fc-capsule-set',
    nameTranslationKey: 'INVESTIGATE_TOOLS.MANUAL_CONDITION.HEADER',
    descriptionTranslationKey: 'INVESTIGATE_TOOLS.MANUAL_CONDITION.HEADER_DESCRIPTION',
  },
  {
    id: SCATTER_CONDITION_TOOL_ID,
    parentId: 'identify',
    trackAction: 'Scatter Condition',
    iconClass: 'fc fc-scatterplot-select',
    nameTranslationKey: 'INVESTIGATE_TOOLS.SCATTER_CONDITION.HEADER',
    descriptionTranslationKey: 'INVESTIGATE_TOOLS.SCATTER_CONDITION.HEADER_DESCRIPTION',
  },
  {
    id: 'profile-search',
    parentId: 'identify',
    trackAction: 'Profile Search',
    iconClass: 'fc fc-search-pattern',
    nameTranslationKey: 'PROFILE_SEARCH.HEADER',
    descriptionTranslationKey: 'PROFILE_SEARCH.HEADER_DESCRIPTION',
  },
  {
    id: 'condition-with-properties',
    parentId: 'identify',
    trackAction: 'Condition with Properties',
    // TODO: CRAB-28957 - update icon below
    iconClass: 'fc fc-capsule-set',
    nameTranslationKey: 'CONDITION_WITH_PROPERTIES.HEADER',
    descriptionTranslationKey: 'CONDITION_WITH_PROPERTIES.HEADER_DESCRIPTION',
  },
  {
    id: 'signal-from-condition',
    parentId: 'quantify',
    trackAction: 'Signal from Condition',
    iconClass: 'fc fc-series-gen',
    nameTranslationKey: 'SIGNAL_FROM_CONDITION.HEADER',
    descriptionTranslationKey: 'SIGNAL_FROM_CONDITION.HEADER_DESCRIPTION',
  },
  {
    id: 'threshold-metric',
    parentId: 'quantify',
    trackAction: 'Threshold Metric',
    iconClass: 'fc fc-metric',
    nameTranslationKey: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.HEADER',
    descriptionTranslationKey: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.HEADER_DESCRIPTION',
  },
  {
    id: 'aggregation-bins-table',
    parentId: 'quantify',
    trackAction: 'Aggregation Bins Table',
    iconClass: 'fc fc-bar-chart',
    nameTranslationKey: 'AGGREGATIONS.HEADER',
    descriptionTranslationKey: 'AGGREGATIONS.HEADER_DESCRIPTION',
  },
  {
    id: 'fftTable',
    parentId: 'quantify',
    trackAction: 'FFTTable',
    iconClass: 'fc fc-bell-curve',
    nameTranslationKey: 'INVESTIGATE_TOOLS.FFT.HEADER',
    descriptionTranslationKey: 'INVESTIGATE_TOOLS.FFT.HEADER_DESCRIPTION',
    forAllItems: true,
  },
  {
    id: 'formula',
    parentId: 'overview',
    trackAction: 'Formula',
    iconClass: 'fc fc-formula',
    nameTranslationKey: 'FORMULA.HEADER',
    descriptionTranslationKey: 'FORMULA.HEADER_DESCRIPTION',
  },
  {
    id: 'signal-smoothing',
    parentId: 'cleanse',
    trackAction: 'Signal Smoothing',
    iconClass: 'fc fc-lowpass',
    nameTranslationKey: 'SIGNAL_SMOOTHING.HEADER',
    descriptionTranslationKey: 'SIGNAL_SMOOTHING.HEADER_DESCRIPTION',
  },
  {
    id: 'reference',
    parentId: 'model-and-predict',
    trackAction: 'Reference',
    iconClass: 'fc fc-reference',
    nameTranslationKey: 'REFERENCE.HEADER',
    descriptionTranslationKey: 'REFERENCE.HEADER_DESCRIPTION',
  },
  {
    id: 'prediction',
    parentId: 'model-and-predict',
    trackAction: 'Prediction',
    iconClass: 'fc fc-prediction',
    nameTranslationKey: 'PREDICTION.HEADER',
    descriptionTranslationKey: 'PREDICTION.HEADER_DESCRIPTION',
  },
  {
    id: 'importdatafile',
    parentId: 'import-export',
    trackAction: 'Import Data File',
    iconClass: 'fc fc-import',
    nameTranslationKey: 'IMPORTDATAFILE.HEADER',
    descriptionTranslationKey: 'IMPORTDATAFILE.HEADER_DESCRIPTION',
  },
  {
    id: 'export-excel',
    parentId: 'import-export',
    iconClass: 'fc fc-excel',
    nameTranslationKey: 'EXPORT_EXCEL.HEADER',
    descriptionTranslationKey: 'EXPORT_EXCEL.HEADER_DESCRIPTION',
  },
  {
    id: 'properties',
    parentId: 'overview',
    trackAction: 'Properties',
    hideFromSearch: true,
    iconClass: 'fa fa-info-circle',
    nameTranslationKey: 'PROPERTIES.HEADER',
    descriptionTranslationKey: 'PROPERTIES.HEADER_DESCRIPTION',
    forAllItems: true,
  },
  {
    id: 'export-powerpoint',
    parentId: 'import-export',
    trackAction: 'Export Powerpoint',
    iconClass: 'fc fc-powerpoint',
    nameTranslationKey: 'EXPORT_POWERPOINT.HEADER',
    descriptionTranslationKey: 'EXPORT_POWERPOINT.DESCRIPTION',
  },
  {
    id: EXPORT_TO_ODATA_TOOL_ID,
    parentId: 'import-export',
    trackAction: 'Export OData',
    iconClass: 'fa fa-database',
    nameTranslationKey: 'EXPORT_ODATA.HEADER',
    descriptionTranslationKey: 'EXPORT_ODATA.DESCRIPTION',
  },
  {
    id: 'export-pi-vision',
    parentId: 'import-export',
    iconClass: 'fc fc-pivision',
    nameTranslationKey: 'EXPORT_PI_VISION.HEADER',
    descriptionTranslationKey: 'EXPORT_PI_VISION.HEADER_DESCRIPTION',
  },
  {
    id: 'digital-filter',
    parentId: 'cleanse',
    trackAction: 'Digital Filter',
    iconClass: 'fc fc-digital-filter',
    nameTranslationKey: 'DIGITAL_FILTER.HEADER',
    descriptionTranslationKey: 'DIGITAL_FILTER.HEADER_DESCRIPTION',
  },
];

/**
 * Investigate module provides tools for creating and manipulating signals and conditions
 */
angular.module('Sq.Investigate', dependencies);

export const TREND_TOOLS = _.transform(
  BASE_INVESTIGATE_TOOLS,
  function (TREND_TOOLS, tool) {
    TREND_TOOLS[_.toUpper(_.snakeCase(tool.id))] = tool.id;
  },
  {},
) as _.Dictionary<string>;

export const INVESTIGATE_TOOLS = _.clone(BASE_INVESTIGATE_TOOLS);

// All tools that have a preview
// If when editing a preview tool you see the preview on top of the original signal, add that tool here
export const PREVIEW_TREND_TOOLS = [
  TREND_TOOLS.DIGITAL_FILTER,
  TREND_TOOLS.SIGNAL_FROM_CONDITION,
  TREND_TOOLS.PREDICTION,
  TREND_TOOLS.SIGNAL_SMOOTHING,
  TREND_TOOLS.MANUAL_SIGNAL,
];
