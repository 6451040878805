import React, { useState } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import {
  addCalculationToAsset,
  applyFormulaToAll,
  getColumn,
  removeChildFromAsset,
} from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { FormulaFromScratch } from '@/hybrid/assetGroupEditor/calculations/FormulaFromScratch.atom';
import { FormulaErrorInterface } from '@/hybrid/formula/FormulaEditor.molecule';
import { validateAssetGroupFormula } from '@/hybrid/formula/formula.utilities';
import { successToast } from '@/hybrid/utilities/toast.utilities';
import { sqAssetGroupStore } from '@/core/core.stores';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { ConfirmDeleteModal } from '@/hybrid/core/ConfirmDeleteModal.molecule';
import { AssetGroupAsset, AssetGroupChild } from '@/hybrid/assetGroupEditor/assetGroup.types';
import { FormulaEditorParam } from '@/hybrid/formula/FormulaParametersTable.molecule';

interface CalculationDetailModalProps {
  onClose: () => void;
  columnName: string;
  item: AssetGroupChild;
  testId?: string;
  asset: AssetGroupAsset;
}

export const CalculationDetailModal: React.FunctionComponent<CalculationDetailModalProps> = ({
  onClose,
  item,
  asset,
  testId,
}) => {
  const { t } = useTranslation();

  const [formula, setFormula] = useState<string>(item?.formula);
  const [formulaErrors, setFormulaErrors] = useState<FormulaErrorInterface[]>([]);
  const [parameters, setParameters] = useState<FormulaEditorParam[]>(
    _.map(item?.parameters as FormulaEditorParam[], (parameter) => ({
      ...parameter,
      identifier: parameter.name,
    })),
  );

  const [applyToAll, setApplyToAll] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const removeItem = () => {
    removeChildFromAsset({ child: item, asset });
    onClose();
  };

  const doValidateFormula = () => {
    const columnFormula = getColumn(item.name);

    return Promise.all([
      validateAssetGroupFormula(sqAssetGroupStore.assets, formula, parameters, asset),
      validateAssetGroupFormula(sqAssetGroupStore.assets, columnFormula?.formula, columnFormula?.parameters),
    ])
      .then(([itemResponse, columnResponse]) => {
        const itemReturnType = itemResponse?.data.returnType;
        const columnReturnType = columnResponse?.data.returnType;
        if ((item.type && !_.includes(item.type, itemReturnType)) || !_.isEqual(columnReturnType, itemReturnType)) {
          setFormulaErrors([
            {
              column: 0,
              line: 0,
              message: t('ASSET_GROUP_EDITOR.ERROR_FORMULA_RETURN_TYPE', {
                columnType: columnReturnType,
                formulaType: itemReturnType,
              }),
            },
          ]);
          return false;
        }
        setFormulaErrors([]);
        return true;
      })
      .catch((formulaErrors) => {
        setFormulaErrors(formulaErrors);
        return false;
      });
  };

  const updateCalculation = () => {
    doValidateFormula().then((valid) => {
      if (valid) {
        if (applyToAll) {
          applyFormulaToAll(sqAssetGroupStore.assets, parameters, formula, item);
        } else {
          addCalculationToAsset({
            asset,
            columnName: item.name,
            formula,
            parameters,
          });
          successToast({
            messageKey: 'ASSET_GROUP_EDITOR.ADD_CALCULATION_SUCCESS',
          });
        }
        onClose();
      }
    });
  };

  return (
    <>
      <Modal show={true} onHide={onClose} animation={false} data-testid={testId} dialogClassName="min-width-900">
        <Modal.Header closeButton={true}>
          <h4>{`${asset.name} » ${item.name} ${t('ASSET_GROUP_EDITOR.ITEM_DETAILS')}`}</h4>
        </Modal.Header>
        <Modal.Body>
          <FormulaFromScratch
            existingParameters={parameters}
            item={item}
            validateFormula={doValidateFormula}
            formulaErrors={formulaErrors}
            setFormulaErrorsFn={setFormulaErrors}
            exposeParametersToParent={setParameters}
            exposeFormulaToParent={setFormula}
          />
          <div className="flexColumnContainer mt30">
            <div className="flexColumnContainer flexFill">
              <TextButton
                onClick={removeItem}
                label="ASSET_GROUP_EDITOR.REMOVE_MAPPING"
                size="sm"
                testId="removeAttributeBtn"
                extraClassNames="min-width 100 width-130 max-width-130 mr20"
                id="removeAttributeBtn"
              />
            </div>

            <Checkbox
              id="applyToAll"
              classes="mr10"
              label="ASSET_GROUP_EDITOR.APPLY_TO_ALL"
              isChecked={applyToAll}
              onChange={() => setApplyToAll(!applyToAll)}
            />

            <TextButton
              onClick={onClose}
              label="CLOSE"
              size="sm"
              testId="closeBtn"
              extraClassNames="min-width 100 width-100 max-width-100 mr20"
              id="closeBtn"
            />

            <TextButton
              onClick={
                applyToAll
                  ? () => {
                      doValidateFormula().then((valid) => {
                        if (valid) {
                          setShowWarning(true);
                        }
                      });
                    }
                  : updateCalculation
              }
              disabled={!_.isEmpty(formulaErrors)}
              label="SAVE"
              size="sm"
              testId="saveBtn"
              extraClassNames="min-width 100 width-100 max-width-100"
              id="saveBtn"
              variant="theme"
            />
          </div>
        </Modal.Body>
      </Modal>
      {showWarning && (
        <ConfirmDeleteModal
          onClose={() => setShowWarning(false)}
          action={updateCalculation}
          actionBtnLabel="OK"
          headerTranslationKey="ASSET_GROUP_EDITOR.CONFIRM_APPLY_TO_ALL"
          bodyTranslationKey="ASSET_GROUP_EDITOR.BULK_EDIT_WARNING"
          confirmBtnVariant="theme"
        />
      )}
    </>
  );
};
