// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { ReportActions } from '@/reportEditor/report.actions';
import { Alert, Modal } from 'react-bootstrap';
import { FormulaService } from '@/services/formula.service';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import SelectItemWrapper from '@/hybrid/core/SelectItemWrapper.organism';
import _ from 'lodash';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { useTranslation } from 'react-i18next';
import { DateRangeSwapInfo } from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';
import { sqReportStore } from '@/core/core.stores';

const reportDateRangeAssetSwapModalBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  sqReportActions: injected<ReportActions>(),
  sqFormula: injected<FormulaService>(),
});

export const ReportDateRangeAssetSwapModal: SeeqComponent<typeof reportDateRangeAssetSwapModalBindings> = ({
  onClose,
}) => {
  const { sqReportActions, sqFormula } = useInjectedBindings(reportDateRangeAssetSwapModalBindings);
  const { t } = useTranslation();
  const potentialSwaps: DateRangeSwapInfo[] = useFluxPath(
    sqReportStore,
    () => sqReportStore.activeDateRangeSwapInfo.potentialSwaps,
  );
  const options = _.map(potentialSwaps, (swap) => swap.dateRange);
  const [targetAssetName, setTargetAssetName] = useState(potentialSwaps[0]?.assetSelection?.asset.name);
  const [selected, setSelected] = useState(potentialSwaps[0]);

  useEffect(() => {
    sqFormula.getAssetPath(potentialSwaps[0].assetSelection.asset).then((path) => setTargetAssetName(path));
  }, []);

  const onChange = (selectedRange) => {
    setSelected(_.find(potentialSwaps, { dateRange: selectedRange }));
  };
  const onSave = () => {
    doTrack('DateRange Asset Swap', 'Choose Date Range', 'Date Range Selected');
    sqReportActions.setActiveDateRangeSwapInfo(selected, []);
    onClose();
    sqReportActions.setShowChooseCapsuleModal(true);
  };

  const resetAndClose = () => {
    doTrack('DateRange Asset Swap', 'Choose Date Range', 'Canceled');
    sqReportActions.setActiveDateRangeSwapInfo(undefined, []);
    onClose();
  };

  return (
    <Modal show={true} onHide={resetAndClose} animation={false}>
      <Modal.Header closeButton={true} onHide={resetAndClose}>
        <Modal.Title>{t('REPORT.MODAL.DATE_RANGE_ASSET_SWAP.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>
          {t('REPORT.MODAL.DATE_RANGE_ASSET_SWAP.TARGET_ASSET')}
          <span className="small text-italic sq-darkish-gray ml5">{targetAssetName}</span>
        </label>
        <Alert variant="info" transition={false}>
          {t('REPORT.MODAL.DATE_RANGE_ASSET_SWAP.HELP_TEXT')}
          <ul>
            <li>{t('REPORT.MODAL.DATE_RANGE_ASSET_SWAP.HELP_TEXT_CHOOSE')}</li>
            <li>{t('REPORT.MODAL.DATE_RANGE_ASSET_SWAP.HELP_TEXT_CANCEL')}</li>
          </ul>
        </Alert>
        <div className="flexRowContainer">
          <label>
            {selected.dateRange.name} {t('REPORT.MODAL.DATE_RANGE_ASSET_SWAP.CONDITION_LABEL')}
            <span className="small text-italic sq-darkish-gray ml5"> {selected.dateRange.condition.name}</span>
          </label>
          <label>
            {t('REPORT.MODAL.DATE_RANGE_ASSET_SWAP.CONDITION_ASSET')}
            <span className="small text-italic sq-darkish-gray ml5">{selected.swapAsset.formattedName}</span>
          </label>
        </div>
        <SelectItemWrapper
          items={options}
          selected={selected.dateRange}
          allowClear={false}
          isMultipleSelect={false}
          loadingMetadata={null}
          dropdownWidth="50"
          cssClassName="react-select"
          noWrap={false}
          onChange={onChange}
          onRemove={_.noop}
          isGrouped={false}
          insideModal={true}
          getCustomIcon={() => 'fa-calendar'}
        />
      </Modal.Body>
      <Modal.Footer>
        <div>
          <TextButton
            onClick={resetAndClose}
            label="REPORT.CANCEL"
            extraClassNames="mr5"
            testId="assetDateChooseCancel"
          />
          <TextButton testId="saveSwapRange" onClick={onSave} label="NEXT" variant="theme" />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
