// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import { itemIconClass } from '@/hybrid/utilities/utilities';
import { UnitOfMeasure } from '@/hybrid/core/UnitOfMeasure.atom';
import { isItemRedacted } from '@/hybrid/utilities/redaction.utilities';

interface SelectItemWrapperProps {
  items: object[];
  selected: object | string[];
  // for Asset Groups we need to compare the items to the group as well as the item ids will be the same for asset
  // column items
  selectByIdAndGroup?: boolean;
  allowClear: boolean;
  insideModal?: boolean;
  selectPlaceholder?: string;
  isMultipleSelect: boolean;
  disableAutoSelect?: boolean;
  loadingMetadata: boolean;
  dropdownWidth: string;
  cssClassName: string;
  noWrap: boolean;
  onRemove: (item: object) => void;
  onChange: (item: object) => void;
  isGrouped?: boolean;
  getCustomIcon?: (item: object) => string;
  stopPropagation?: boolean;
  addAllEnabled?: boolean;
}

export const SelectItemWrapper: React.FunctionComponent<SelectItemWrapperProps> = (props) => {
  const { t } = useTranslation();

  const {
    items,
    selected,
    allowClear,
    onChange,
    isMultipleSelect,
    dropdownWidth,
    onRemove,
    loadingMetadata,
    cssClassName,
    disableAutoSelect,
    insideModal,
    selectPlaceholder,
    noWrap = false,
    isGrouped = true,
    selectByIdAndGroup = false,
    addAllEnabled = false,
    getCustomIcon = itemIconClass,
    stopPropagation = false,
  } = props;

  const menuPosition = insideModal ? 'absolute' : 'fixed';
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (!selected && items?.length === 1 && !allowClear && !disableAutoSelect) {
      onChange(items[0]);
    }
  }, [items]);

  // We have to close empty selection menu after "Add all".
  // To do this we simulate click event on selector container
  const closeSelectorMenu = () => {
    const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

    const simulateMouseClick = (element) => {
      mouseClickEvents.forEach((mouseEventType) =>
        element.dispatchEvent(
          new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1,
          }),
        ),
      );
    };

    const element = document.querySelector('.react-multiple-select__control--menu-is-open');
    if (element) simulateMouseClick(element);
  };

  const groupItems = (ungrouped) => {
    const groupItem = (item) => {
      switch (item?.selectGroup) {
        case 'assetGroupColumn':
          return 'ASSET_GROUP_EDITOR.ASSET_GROUP_COLUMNS';
        case 'original':
          return 'ASSET_GROUP_EDITOR.ORIGINAL_ASSIGNMENT';
        case 'pinned':
          return 'SEARCH_DATA.PINNED';
        case 'recentlyAccessed':
          return 'SEARCH_DATA.RECENTLY_ACCESSED';
        default:
          return 'DETAILS';
      }
    };
    return _.chain(ungrouped)
      .groupBy(groupItem)
      .map((obj) => {
        return { label: t(groupItem(obj[0])), options: obj };
      })
      .value();
  };

  const displayEntry = (item, innerProps, wrapperStyle) => {
    if (!item) {
      return null;
    }

    const unitOfMeasure = item.displayUnitOfMeasure;
    const className = classNames('pr5', 'fa', 'sq-tool-description-color', getCustomIcon(item));

    if (isItemRedacted(item)) {
      return (
        <div {...innerProps} className="flexColumnContainer pt8">
          <Icon icon="fa-warning" type="danger" extraClassNames="pr5" />
          <div id="specSelectedSignal" className="flexRowContainer flexFill pb1">
            <div className="flexColumnContainer specChoiceName font-size-smaller">{t('NO_ITEM_ACCESS')}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div {...innerProps} className={wrapperStyle}>
          <Icon icon={className} type="color" color={item?.color || 'sq-text-color'} />
          <div
            id="specSelectedSignal"
            className={classNames('flexRowContainer flexFill pb1', {
              'text-nowrap': noWrap,
              'aggressiveWordBreak': !noWrap,
            })}>
            <div>
              <strong className="mr5 specChoiceName">{item?.name} </strong>
              <UnitOfMeasure unitOfMeasure={unitOfMeasure} />
            </div>

            <div className="xsmall itemAsset">
              {item?.assets?.[0]?.formattedName || item?.assets?.[0]?.datasource?.name}
            </div>
          </div>
        </div>
      );
    }
  };

  const SingleValue = (optionProps) => {
    const { innerProps, data } = optionProps;
    const currentItem: any = _.isObject(data) ? _.find(items, { id: data.id }) : _.find(items, { id: data });

    // ensure item is not pushed out of view inside the input field as the cursor is set onto a new line
    if (focused && !isMultipleSelect) {
      return <span />;
    }

    return displayEntry(currentItem, innerProps, 'flexColumnContainer flexCenter flexFill');
  };

  const Menu = (optionProps) => {
    const { innerProps, children } = optionProps;
    const extraStyles = insideModal ? { position: 'absolute', zIndex: 1 } : {};
    return (
      <div {...innerProps} className="react-select-menuList" style={{ ...extraStyles, width: dropdownWidth }}>
        {children}
      </div>
    );
  };

  const Option = (optionProps) => {
    const { innerProps } = optionProps;
    let { data } = optionProps;
    if (isMultipleSelect) {
      data = data.value;
    }
    let isSelected = _.includes(_.toArray(selected), data?.id);
    isSelected = selectByIdAndGroup ? isSelected && data.selectGroup === (selected as any).selectGroup : isSelected;
    const wrapperClassName = classNames(
      'flexColumnContainer',
      'flexCenter',
      'cursorPointer',
      'pl10',
      'pr10',
      'pt2',
      'pb2',
      isSelected ? 'selected-item-entry' : 'select-item-entry',
    );

    return displayEntry(data, innerProps, wrapperClassName);
  };

  const formatGroupLabel = (data) => {
    const groupStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };

    return (
      <div style={groupStyles}>
        <span>{data.label}</span>
        {isMultipleSelect && addAllEnabled && (
          <a
            href="#"
            onClick={() => {
              data.options.forEach((item) => onChange([item]));
              closeSelectorMenu();
            }}>
            {t('DETAILS_SELECTION.ADD_ALL')}
          </a>
        )}
      </div>
    );
  };

  const MultiValueRemove = (optionProps) => {
    return (
      <div
        {...optionProps.innerProps}
        onClick={() => {
          onRemove(_.find(items, { id: optionProps.data }));
        }}>
        <Icon icon="fa-close" type="inherit" />
      </div>
    );
  };

  const MultiValueLabel = SingleValue;

  const prepareItemsForDisplay = (items) => {
    let displayItems: any[] = _.reject(items, (item) => isItemRedacted(item));
    if (isMultipleSelect) {
      displayItems = _.reject(displayItems, (item) => _.includes(selected, item.id));
      const toOptions = (options) => _.map(options, (obj) => ({ label: obj.name, value: obj }));

      return isGrouped
        ? _.map(groupItems(displayItems), ({ label, options }) => ({
            label,
            options: toOptions(options),
          }))
        : toOptions(displayItems);
    } else {
      return isGrouped ? groupItems(displayItems) : displayItems;
    }
  };

  const matchesFilter = (option, input) => input === '' || _.includes(_.toLower(option.data.name), _.toLower(input));

  const displayItems = prepareItemsForDisplay(items);

  return (
    <div
      className={`${isGrouped ? 'flexAlignCenter ' : ''}flexFillOverflow flexColumnContainer`}
      onClick={(e) => stopPropagation && e.stopPropagation()}>
      <Select
        placeholder={selectPlaceholder ? t(selectPlaceholder) : t('SELECT_ITEM')}
        onMenuOpen={() => setFocused(true)}
        onMenuClose={() => setFocused(false)}
        className="reactSelectWrapper"
        isClearable={allowClear}
        classNamePrefix={cssClassName}
        value={selected}
        isMulti={isMultipleSelect}
        options={displayItems}
        formatGroupLabel={formatGroupLabel}
        onChange={onChange}
        components={{
          Option,
          SingleValue,
          MultiValueLabel,
          MultiValueRemove,
          Menu,
        }}
        menuPosition={menuPosition}
        menuPortalTarget={insideModal ? undefined : document.querySelector('#mainView')}
        menuPlacement="auto"
        isLoading={loadingMetadata}
        filterOption={matchesFilter}
        styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) }}
      />
    </div>
  );
};

export default SelectItemWrapper;
