// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CELL_TYPES } from '@/hybrid/core/Table.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { sqSystemApi } from '@/sdk';
import { ConfigurationOutputV1 } from '@/sdk/model/ConfigurationOutputV1';
import { ButtonVariant, TextButton } from '@/hybrid/core/TextButton.atom';
import { Icon, IconType } from '@/hybrid/core/Icon.atom';
import { AdminTableWrapper } from '@/hybrid/core/AdminTableWrapper.molecule';
import { TableLoadingIndicator } from '@/hybrid/core/TableLoadingIndicator.molecule';
import { EditConfigurationOptionModal } from '@/hybrid/administration/EditConfigurationOptionModal.molecule';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';
import { AxiosResponse } from 'axios';
import { ConfigurationPreviewModal } from '@/hybrid/administration/ConfigurationPreviewModal.molecule';

interface ConfigurationTableProps {}

export const ConfigurationTable: React.FunctionComponent<ConfigurationTableProps> = () => {
  const { t } = useTranslation();

  useEffect(() => {
    refreshConfigOptions();
  }, []);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [uneditedConfigOptions, setUneditedConfigOptions] = useState([]);
  const [currentConfigOptions, setCurrentConfigOptions] = useState([]);
  const [configurationOptions, setConfigurationOptions] = useState<any[]>();
  const configChanges = useRef<any>();

  type ModalType = 'editConfigurationOption' | 'none';
  const [modalShown, setModalShown] = useState<ModalType>('none');
  const [editConfigurationOption, setEditConfigurationOption] = useState({});

  const openEditConfigurationOptionModal = (item) => {
    setModalShown('editConfigurationOption');
    setEditConfigurationOption(_.cloneDeep(item));
  };

  /**
   * Gets the fields that have changed as compared to the API version of the configuration option
   *
   * @param {Object} option - The option from the grid
   */
  const getChangedFields = (option) => {
    const currentOption = _.find(uneditedConfigOptions, { path: option.path });
    return _.filter(
      ['defaultValue', 'value', 'note'],
      (field) => option && currentOption && option[field] !== currentOption[field],
    );
  };

  const closeModal = () => {
    setModalShown('none');
  };

  const displayConfigurationOptionUpdate = (option) => {
    if (option) {
      setEditConfigurationOption(
        _.assign(editConfigurationOption, option, {
          isDirty: getChangedFields(option).length > 0,
        }),
      );
      if (getChangedFields(option).length === 0) {
        closeModal();
      } else {
        const updatedCurrentOptions = _.map(currentConfigOptions, (item) =>
          item.path === option.path ? option : item,
        );
        // Replace the config options with a clone, to make React re-render
        setCurrentConfigOptions(_.cloneDeep(updatedCurrentOptions));
        closeModal();
      }
    }
  };

  const renderEditCell = (item) => (
    <HoverTooltip delay={500} placement="top" text={item.modifiable ? '' : 'ADMIN.CONFIGURATION.EDIT_DISABLED'}>
      <div
        className={item.modifiable ? 'cursorPointer' : 'cursorNotAllowed disabledLook'}
        onClick={() => item.modifiable && openEditConfigurationOptionModal(item)}>
        <Icon icon="fa-pencil" testId={`editConfiguration_${item.path}`} />
        <span className="sq-text-primary pl5">{t('EDIT')}</span>
      </div>
    </HoverTooltip>
  );

  const renderCell = (item, accessor) => {
    // Convert to string in case we have a boolean value so it displays
    const value = _.toString(item[accessor]);

    return (
      <HoverTooltip text={value} delay={500}>
        <div
          className={classNames('textOverflowEllipsis', 'overflowHidden', 'nowrap', { 'text-bolder': item.isDirty })}>
          {value}
        </div>
      </HoverTooltip>
    );
  };

  const columns = [
    {
      accessor: 'path',
      searchProperty: 'path',
      header: 'ADMIN.CONFIGURATION.NAME',
      cellStyle: { width: '25%' },
      cellRenderFunction: renderCell,
    },
    {
      accessor: 'advanced',
      searchProperty: 'advanced',
      header: 'ADVANCED',
      cellType: CELL_TYPES.CHECKMARK,
      cellStyle: { width: '6%', minWidth: 90 },
    },
    {
      accessor: 'isOverridden',
      searchProperty: 'isOverridden',
      header: 'ADMIN.CONFIGURATION.OVERRIDDEN',
      cellType: CELL_TYPES.CHECKMARK,
      cellStyle: { width: '6%', minWidth: 90 },
    },
    {
      accessor: 'defaultValue',
      searchProperty: 'defaultValue',
      header: 'ADMIN.CONFIGURATION.DEFAULT_VALUE',
      cellStyle: { width: '15%', maxWidth: 125 },
      cellRenderFunction: renderCell,
    },
    {
      accessor: 'units',
      searchProperty: 'units',
      header: 'ADMIN.CONFIGURATION.UNITS',
      cellStyle: { width: '5%', minWidth: 110 },
      cellRenderFunction: renderCell,
    },
    {
      accessor: 'value',
      searchProperty: 'value',
      header: 'ADMIN.CONFIGURATION.OVERRIDE_VALUE',
      cellStyle: { width: '15%', maxWidth: 125 },
      cellRenderFunction: renderCell,
    },
    {
      accessor: 'note',
      searchProperty: 'note',
      header: 'ADMIN.CONFIGURATION.NOTE',
      cellStyle: { width: '20%', maxWidth: 125 },
      cellRenderFunction: renderCell,
    },
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellStyle: { width: '4%', minWidth: 90 },
      cellRenderFunction: renderEditCell,
    },
  ];
  const sortableColumns = _.map(columns, (column) => _.assign({ sortable: true, filterable: true }, column));

  const refreshConfigOptions = () => {
    setIsRefreshing(true);
    return sqSystemApi
      .getConfigurationOptions({ offset: 0, limit: 1000 })
      .then(processConfigOptions)
      .finally(() => {
        setIsRefreshing(false);
      });
  };

  const processConfigOptions = ({ data: { configurationOptions } }: AxiosResponse<ConfigurationOutputV1>) => {
    const processedConfigOptions = _.map(configurationOptions, (option) => {
      // Add an "id" field to each config entry, because the Table component requires it
      // And create an 'isOverridden' column
      return _.assign(
        {
          id: option.path,
          isOverridden: _.has(option, 'value'),
        },
        option,
      );
    });

    // Cloned so that there is an unmodified copy of the data available for preview
    setUneditedConfigOptions(_.cloneDeep(processedConfigOptions));
    setCurrentConfigOptions(processedConfigOptions);
  };

  /**
   * Determines if preview button should be enabled.
   *
   * @return {boolean} True if preview should be enabled, false otherwise
   */
  const isPreviewConfigurationEnabled = () => _.some(currentConfigOptions, 'isDirty');

  /**
   * Opens a modal that allows the user to preview the changed configuration options
   */
  function previewConfigurationChanges() {
    configChanges.current = _.chain(currentConfigOptions)
      .filter('isDirty')
      .map((option) => ({
        path: option.path,
        value: option.value,
        note: option.note,
      }))
      .value();

    sqSystemApi
      .setConfigurationOptions({
        dryRun: true,
        configurationOptions: configChanges.current,
      })
      .then(({ data: { configurationOptions } }) =>
        _.chain(configurationOptions)
          .filter('modifiable')
          .reduce((accum, option) => {
            const changedFields = getChangedFields(option);
            if (changedFields.length) {
              accum.push({
                ...option,
                changedFields: _.zipObject(changedFields, _.fill(Array(changedFields.length), true)),
              });
            }
            return accum;
          }, [])
          .sortBy('path')
          .value(),
      )
      .then(setConfigurationOptions)
      .catch((e) => {
        errorToast({ httpResponseOrError: e });
        return Promise.reject(e);
      });
  }

  const handleConfigurationPreviewModalClose = (save: boolean) => {
    if (save) {
      sqSystemApi
        .setConfigurationOptions({
          configurationOptions: configChanges.current,
        })
        .then(processConfigOptions)
        .then(() => {
          successToast({ messageKey: 'ADMIN.CONFIGURATION.SAVED' });
        })
        .catch((error) => errorToast({ httpResponseOrError: error }));
    }
    setConfigurationOptions(undefined);
  };

  const SaveHelp = () => {
    if (isPreviewConfigurationEnabled()) {
      return <strong className="mr5">{t('ADMIN.CONFIGURATION.SAVE_HELP')}</strong>;
    } else {
      return <div />;
    }
  };

  return (
    <div className="height-maximum">
      <div className="flexColumnContainer flexSpaceBetween flexAlignCenter mb5">
        <strong className="pl3">{t('ADMIN.CONFIGURATION.SUPPORT_HELP')}</strong>
        <div className="flexColumnContainer flexAlignCenter">
          <SaveHelp />
          <TextButton
            id="previewConfiguration"
            variant={`${isPreviewConfigurationEnabled() ? 'warning' : 'theme'}` as ButtonVariant}
            disabled={!isPreviewConfigurationEnabled()}
            onClick={previewConfigurationChanges}
            icon="fa-save"
            iconStyle={`${isPreviewConfigurationEnabled() ? 'text' : 'white'}` as IconType}
            label="SAVE"
          />
        </div>
      </div>

      <div className="width-maximum height-maximum pb70 overflowAuto">
        <AdminTableWrapper
          testId="configurationAdministrationTable"
          items={currentConfigOptions}
          selectedItems={[]}
          setSelectedItems={([]) => {}}
          columns={sortableColumns}
          defaultSort={{ property: 'path', asc: true }}
        />
        {isRefreshing && <TableLoadingIndicator />}
      </div>

      {modalShown === 'editConfigurationOption' && (
        <EditConfigurationOptionModal
          configurationOption={editConfigurationOption}
          displayConfigurationOptionUpdate={displayConfigurationOptionUpdate}
          onClose={closeModal}
        />
      )}

      {configurationOptions?.length > 0 && (
        <ConfigurationPreviewModal
          configurationOptions={configurationOptions}
          onClose={handleConfigurationPreviewModalClose}
        />
      )}
    </div>
  );
};
