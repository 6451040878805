// @ts-strict-ignore
import Plugin from 'ckeditor5/packages/ckeditor5-core/src/plugin';
import { BasePluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { PluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/plugins/PluginDependencies';
import _ from 'lodash';
import { getWorkbenchAddress } from '@/hybrid/utilities/utilities';

const addBasePathToAttribute = (element: Element, attribute: string, basePath: string) => {
  const link: string = element.getAttribute(attribute) || '';
  if (link?.trim().indexOf('/') === 0) {
    element.setAttribute(attribute, `${basePath}${link}`);
  }
};

const correctRelativeUrl = (basePath: string, element: Element) => {
  if (element.tagName.toLowerCase() === 'a') {
    addBasePathToAttribute(element, 'href', basePath);
  } else if (element.tagName.toLowerCase() === 'img') {
    addBasePathToAttribute(element, 'src', basePath);
  }
  if (element.childElementCount > 0) {
    _.forEach(element.children, (item) => correctRelativeUrl(basePath, item));
  }
};

const removeAbsoluteUrl = (basePath: string, element: Element) => {
  if (element.tagName.toLowerCase() === 'a') {
    const link: string = element.getAttribute('href') || '';
    if (link?.trim().indexOf(basePath) === 0) {
      element.setAttribute('href', link.substring(basePath.length));
    }
  } else {
    if (element.childElementCount > 0) {
      _.forEach(element.children, (item) => removeAbsoluteUrl(basePath, item));
    }
  }
};

export class RelativeLinks extends Plugin {
  static pluginName = 'RelativeLinks';
  static setup = {
    name: RelativeLinks.pluginName,
    plugin: RelativeLinks,
    toolbar: RelativeLinks.pluginName,
  };
  deps: BasePluginDependencies;

  init() {
    this.deps = this.editor.config.get(PluginDependencies.pluginName);
    this.defineClipboardHandlers();
  }

  defineClipboardHandlers() {
    /**
     * We need to pre-process the HTML that is available once the user copied or cut the content
     * in order to change any relative urls to absolute urls. We will search for any <A> or <IMG>
     * and replace their attributes from relative urls to absolute where needed.
     * This event is handled by CKEditor as part of the Clipboard pipelines
     * Details on CK: https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/clipboard.html
     */
    this.editor.editing.view.document.on(
      'clipboardOutput',
      (evt, data) => {
        const dataToManipulate = this.editor.data.htmlProcessor.toData(data.content);
        const containerElement = window.document.createElement('div');
        containerElement.innerHTML = dataToManipulate;
        correctRelativeUrl(getWorkbenchAddress(), containerElement);
        data.dataTransfer.setData('text/html', containerElement.innerHTML);
      },
      { priority: 'low' },
    );

    /**
     * We need to pre-process the HTML in case someone is pasting content to our own CKEditor. We will
     * use this clipboardInput custom CKEvent and remove any absolute urls that are on the same DOMAIN
     * as the app is running in order to use relative urls internally.
     * So http://explore.seeq.com/workbook/view/123 will be changed to /workbook/view/123 if we're running
     * on explore.seeq.com in order to not save this in the DB. All <A> elements are handled for this
     */
    this.editor.editing.view.document.on(
      'clipboardInput',
      (evt, data) => {
        const content = data.dataTransfer.getData('text/html');
        if (this.editor.isReadOnly || content === '') {
          return;
        }
        const containerElement = window.document.createElement('div');
        containerElement.innerHTML = content;
        removeAbsoluteUrl(getWorkbenchAddress(), containerElement);
        data.dataTransfer.setData('text/html', containerElement.innerHTML);
        data.content = this.editor.data.htmlProcessor.toView(containerElement.innerHTML);
      },
      { priority: 'high' },
    );
  }
}
