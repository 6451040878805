import angular from 'angular';

const dependencies = [
  'Sq.AppConstants',
  'Sq.Core',
  'Sq.LicenseManagement',
  'Sq.Worksheets',
  'Sq.Worksteps',
  'Sq.Vendor',
  'Sq.HomeScreen',
  'Sq.Workbook',
];

/**
 * The Sq.Workbench module contains all functionality related to the workbench
 */
angular.module('Sq.Workbench', dependencies);
