import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';

const selectWorksheetHeaderBindings = bindingsDefinition({
  title: prop<string>(),
  subtitle: prop.optional<string>(),
  workbookId: prop<string>(),
  worksheetIds: prop<string[]>(),
  workstepIds: prop<string[]>(),
  totalWorksheets: prop<number>(),
  setWorksheetIds: prop<([]: any) => void>(),
  setWorkstepIds: prop<([]: any) => void>(),
  setTotalWorksheets: prop<([]: any) => void>(),
  isAllSelectedOnStartup: prop<boolean>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
});

export const SelectWorksheetHeader: SeeqComponent<typeof selectWorksheetHeaderBindings> = ({
  title,
  subtitle,
  workbookId,
  setWorksheetIds,
  workstepIds,
  setWorkstepIds,
  totalWorksheets,
  setTotalWorksheets,
  isAllSelectedOnStartup,
}) => {
  const { sqHomeScreenUtilities } = useInjectedBindings(selectWorksheetHeaderBindings);

  const { t } = useTranslation();

  const [allSelected, setAllSelected] = useState(false);
  const selectAllLabel = allSelected ? 'UNSELECT_ALL' : 'SELECT_ALL';
  const showButtonAndSubtitle = !!workbookId;

  useEffect(() => {
    setAllSelected(workstepIds.length === totalWorksheets);
  }, [workstepIds]);

  useEffect(() => {
    if (!_.isEmpty(workbookId) && isAllSelectedOnStartup) {
      selectAll();
    }
  }, []);

  const unselectAll = () => {
    setWorksheetIds([]);
    setWorkstepIds([]);
    setAllSelected(false);
  };

  const selectAll = () => {
    setAllSelected(true);
    sqHomeScreenUtilities.getWorkbook(workbookId, { includeWorkstepId: true }).then((workbook) => {
      setTotalWorksheets(workbook.worksheets.length);
      setWorksheetIds(_.map(workbook.worksheets, 'worksheetId'));
      setWorkstepIds(_.map(workbook.worksheets, 'workstepId'));
    });
  };

  return (
    <div className="flexRowContainer flexFill">
      <div className="flexColumnContainer flexAlignCenter ">
        <h4 className="mr10" id="example-modal-sizes-title-lg">
          {t(title)}
        </h4>
        <div className="flexFill" />
        {showButtonAndSubtitle && (
          <div className="pr10" data-testid="selectAll">
            <a
              href="#"
              onClick={() => {
                allSelected ? unselectAll() : selectAll();
              }}>
              {t(selectAllLabel)}
            </a>
          </div>
        )}
      </div>
      {showButtonAndSubtitle && !!subtitle && (
        <div className="alert-light text-italic" role="alert" data-testid="subtitle">
          {t(subtitle)}
        </div>
      )}
    </div>
  );
};
