// @ts-strict-ignore
import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { HomeScreenBreadCrumbs } from '@/hybrid/homescreen/HomeScreenBreadcrumbs.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { sqHomeScreenStore } from '@/core/core.stores';

/** The component that gets rendered in home screen advanced search that displays the breadcrumbs when filtering the
 *  search by a specific folder **/

const homeScreenAdvancedSearchBreadcrumbs = bindingsDefinition({
  itemLocationFilter: prop<{ text: string; value: string; label: string }>(),
  myFolderContent: prop<any>(),
  corporateFolderId: prop<string>(),
  advancedSearchSelectedFolder: prop<any>(),
  setAdvancedSearchSelectedFolder: prop<(selectedFolder: object) => void>(),
  breadcrumbClicked: prop<boolean>(),
  setBreadcrumbClicked: prop<(isBreadcrumbClicked: boolean) => void>(),
  breadcrumbsPresent: prop<boolean>(),
  setBreadcrumbsPresent: prop<(areBreadcrumbsPresent: boolean) => void>(),
  locationChangedInAdvanced: prop<boolean>(),
  setShowSelectFolderModal: prop<(showModal: boolean) => void>(),
  currentTabName: prop<string>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
});

export const HomeScreenAdvancedSearchBreadcrumbs: SeeqComponent<typeof homeScreenAdvancedSearchBreadcrumbs> = ({
  itemLocationFilter,
  myFolderContent,
  corporateFolderId,
  advancedSearchSelectedFolder,
  setAdvancedSearchSelectedFolder,
  breadcrumbClicked,
  setBreadcrumbClicked,
  breadcrumbsPresent,
  setBreadcrumbsPresent,
  locationChangedInAdvanced,
  setShowSelectFolderModal,
  currentTabName,
}) => {
  const { sqHomeScreenActions, sqHomeScreenUtilities } = useInjectedBindings(homeScreenAdvancedSearchBreadcrumbs);

  const areBreadcrumbsSetFromStore =
    breadcrumbsPresent &&
    sqHomeScreenStore.breadcrumbs.length !== 0 &&
    !advancedSearchSelectedFolder &&
    sqHomeScreenStore.currentFolderId !== undefined &&
    !locationChangedInAdvanced;

  const clickBreadcrumb = ({ folderId, crumb }) => {
    if (folderId && !_.has(crumb, 'ancestors') && !_.has(crumb, 'translationKey')) {
      sqHomeScreenUtilities
        .formatNestedFolderWithAncestors(folderId, itemLocationFilter.value, true)
        .then(setAdvancedSearchSelectedFolder);
    } else {
      setAdvancedSearchSelectedFolder(undefined);
      setBreadcrumbsPresent(false);
    }
    setBreadcrumbClicked(true);
  };

  const formatBreadcrumb = (crumb) => {
    if (crumb) {
      return (
        <div
          key={`advancedSearchCrumbId_${crumb.id}`}
          className="breadcrumbLink cursorPointer"
          data-testid="advancedBreadcrumbLinks"
          onClick={() =>
            clickBreadcrumb({
              folderId: _.get(crumb, 'id'),
              crumb,
            })
          }>
          {sqHomeScreenUtilities.translateBreadcrumb(
            crumb,
            crumb?.owner?.id === myFolderContent?.id,
            corporateFolderId,
          )}
          <span className="mr3 ml3">»</span>
        </div>
      );
    }
  };

  const editDeleteIcons = (
    <div>
      <Icon
        icon="fa-edit"
        type="theme"
        onClick={() => setShowSelectFolderModal(true)}
        testId="advancedSearchEdit"
        extraClassNames="ml10"
      />

      <Icon
        icon="fa-close"
        type="theme"
        onClick={() => {
          setAdvancedSearchSelectedFolder(undefined);
          sqHomeScreenActions.setCurrentFolder(undefined);
        }}
        testId="clearAdvancedSubFolders"
        extraClassNames="ml5"
      />
    </div>
  );

  return (
    <div id="breadcrumbLinks" className="flexColumnContainer flexWrap mt10">
      {advancedSearchSelectedFolder || (!advancedSearchSelectedFolder && breadcrumbClicked)
        ? _.map(advancedSearchSelectedFolder?.ancestors, formatBreadcrumb)
        : areBreadcrumbsSetFromStore && (
            <HomeScreenBreadCrumbs
              currentTabName={currentTabName}
              inAdvancedSearch={true}
              clickBreadcrumb={clickBreadcrumb}
            />
          )}
      {areBreadcrumbsSetFromStore && !breadcrumbClicked && editDeleteIcons}
      {advancedSearchSelectedFolder &&
        sqHomeScreenUtilities.translateBreadcrumb(
          advancedSearchSelectedFolder,
          advancedSearchSelectedFolder?.owner?.id === myFolderContent?.id &&
            advancedSearchSelectedFolder?.ancestors?.length === 0,
        )}
      {advancedSearchSelectedFolder && editDeleteIcons}
    </div>
  );
};
