import React from 'react';
import { AssetGroupEditorToolbar } from '@/hybrid/assetGroupEditor/AssetGroupEditorToolbar.organism';
import { AssetGroupEditor } from '@/hybrid/assetGroupEditor/AssetGroupEditor.page';
import { useTranslation } from 'react-i18next';
import { isEditWorkbookMode } from '@/hybrid/utilities/utilities';

export const AssetGroupView: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const isEditMode = isEditWorkbookMode();

  const renderAssetGroupBanner = (
    <div className="assetGroupHeader text-center flexRowContainer flexAlignCenter flexJustifyCenter height-50">
      <div className="text-bold fs15">{t('ASSET_GROUP_EDITOR.HEADER')}</div>
    </div>
  );

  return (
    <div className="flexFill flexRowContainer resizablePanelContainer" data-testid="assetGroupView">
      {isEditMode && renderAssetGroupBanner}
      <AssetGroupEditorToolbar />
      <AssetGroupEditor />
    </div>
  );
};
