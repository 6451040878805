// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';
import { formatTime } from '@/hybrid/datetime/dateTime.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { Timezone } from '@/datetime/timezone.service';
import { StringCapsulePropertyColorsConfig } from '@/scatterPlot/scatterPlot.constants';

interface ScatterPlotLegendColorStringCapsulePropertyProps {
  propertyName: string;
  colorsConfig: StringCapsulePropertyColorsConfig | undefined;
  timezone: Timezone;
}

/** Displays info about scatter plot colors when coloring by a string capsule property (or start/end) */
export const ScatterPlotLegendColorRainbowCapsuleProperty: React.FunctionComponent<
  ScatterPlotLegendColorStringCapsulePropertyProps
> = ({ propertyName, colorsConfig, timezone }) => {
  if (!propertyName || !colorsConfig || !colorsConfig.isStringProperty) {
    return null;
  }

  const formatProperty = _.includes([SeeqNames.CapsuleProperties.Start, SeeqNames.CapsuleProperties.End], propertyName)
    ? (value) => formatTime(parseInt(value, 10), timezone)
    : _.identity;

  return (
    <>
      {_.map(colorsConfig.valueColorMap, (value, key) => (
        <span
          className="mr10 text-nowrap specColorStringCapsulePropertyLegend"
          key={`${propertyName}.${key}`}
          data-testid="color-string-capsule-property-span">
          <Icon icon="fa-circle" type="color" color={value} extraClassNames="pr5" />
          {formatProperty(key)}
        </span>
      ))}
    </>
  );
};
