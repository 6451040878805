// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { DURATION_TIME_UNITS_ALL, MAX_NAME_LENGTH } from '@/main/app.constants';
import { parseLocaleDate } from '@/hybrid/datetime/dateTime.utilities';
import { sqWorkbenchStore } from '@/core/core.stores';
import { DatasourcesService } from '@/hybrid/administration/datasources/datasources.service';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { sqTimezones } from '@/hybrid/utilities/datetime.constants';

const manageDatasourceModalBindings = bindingsDefinition({
  sqDatasourcesService: injected<DatasourcesService>(),
  id: prop<string>(),
  name: prop<string>(),
  indexingScheduleSupported: prop<boolean>(),
  indexingFrequency: prop<{ value: number; units: string }>(),
  nextScheduledIndexAt: prop<string>(), // ISO 8601 in Zulu (e.g. '2021-06-22T20:23:00.000Z')
  onClose: prop<() => void>(),
});

export const ManageDatasourceModal: SeeqComponent<typeof manageDatasourceModalBindings> = (props) => {
  const { id, indexingScheduleSupported, onClose } = props;

  const { sqDatasourcesService } = useInjectedBindings(manageDatasourceModalBindings);

  const MIN_INDEXING_FREQUENCY = 5;
  const formatDate = (date: moment) => date.tz(timezone.name).format('l LT');
  // Smart parse allows the user to enter partial dates or times and have the rest filled
  // in based on the current value, or current time if the current value is not valid.
  const smartParseLocaleDateText = () => {
    const referenceDate = nextScheduledIndexAt.isValid() ? nextScheduledIndexAt : moment.utc();
    return parseLocaleDate(nextScheduledIndexAtText, timezone.name, referenceDate);
  };
  const updateNextScheduledIndexAt = () => {
    const date: moment = smartParseLocaleDateText();
    if (date.isValid()) {
      setNextScheduledIndexAtText(formatDate(date));
      setNextScheduledIndexAt(date.utc());
    }
  };
  const indexingFrequencyIsValid = ({ value, units } = { value: undefined, units: undefined }) => {
    const momentUnit = _.get(
      _.find(DURATION_TIME_UNITS_ALL, (dtu) => _.includes(dtu.unit, units)),
      'momentUnit',
    );
    return moment.duration(value, momentUnit).asMinutes() < MIN_INDEXING_FREQUENCY;
  };

  const { t } = useTranslation();
  const [timezone, setTimezone] = useState(sqWorkbenchStore.userTimeZone ?? sqTimezones.defaultTimezone);
  const [name, setName] = useState(props.name);
  const [indexingFrequency, setIndexingFrequency] = useState(props.indexingFrequency);
  const [nextScheduledIndexAt, setNextScheduledIndexAt] = useState(moment.utc(props.nextScheduledIndexAt));
  const [nextScheduledIndexAtText, setNextScheduledIndexAtText] = useState(undefined);

  useEffect(() => {
    setNextScheduledIndexAtText(nextScheduledIndexAt.isValid() ? formatDate(nextScheduledIndexAt) : '');
  }, [timezone, nextScheduledIndexAt]);

  const updateDatasource = () =>
    sqDatasourcesService
      .updateDatasource({
        id,
        name,
        indexingFrequency,
        nextScheduledIndexAt: nextScheduledIndexAt.toISOString(),
      })
      .catch((response) => {
        errorToast({ httpResponseOrError: response });
        return Promise.reject(); // reject to prevent modal from closing
      });

  const formDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'nameFormGroup',
      components: [
        {
          component: 'FormControlFormComponent',
          name: 'datasourceName',
          label: 'ADMIN.DATASOURCES.MANAGE_MODAL.NAME',
          testId: 'datasourceNameInput',
          value: name,
          onChange: (value: string) => setName(value.toString()),
          placeholder: 'ADMIN.DATASOURCES.MANAGE_MODAL.DATASOURCE_NAME_PROMPT',
          maxLength: MAX_NAME_LENGTH.TOOL,
          size: 'md',
          required: true,
        },
        {
          component: 'LabelFormComponent',
          name: 'nextScheduledIndexAtLabel',
          includeIf: indexingScheduleSupported,
          value: 'ADMIN.DATASOURCES.MANAGE_MODAL.NEXT_INDEXING_AT',
          extraClassNames: 'text-bolder',
        },
        {
          component: 'FormRow',
          name: 'nextScheduledIndexAtFormRow',
          includeIf: indexingScheduleSupported,
          components: [
            {
              component: 'FormControlFormComponent',
              name: 'nextScheduledIndexAt',
              testId: 'nextScheduledIndexAtInput',
              value: nextScheduledIndexAtText,
              onChange: (value) => setNextScheduledIndexAtText(_.trim(value?.toString())),
              onBlur: updateNextScheduledIndexAt,
              onKeyDown: (key) => key === 'Enter' && updateNextScheduledIndexAt(),
              validation: () => !smartParseLocaleDateText().isValid(),
              placeholder: 'ADMIN.DATASOURCES.MANAGE_MODAL.NEXT_INDEXING_AT_PROMPT',
              maxLength: MAX_NAME_LENGTH.TOOL,
              size: 'md',
              extraClassNames: 'height-34',
            },
            {
              component: 'TimeZoneSelectorFormComponent',
              name: 'datasourceTimeZoneSelector',
              testId: 'datasourceTimeZoneSelector',
              value: timezone,
              onChange: setTimezone,
              disabled: !smartParseLocaleDateText().isValid(),
              optional: false,
              enableAutoDetect: false,
            },
          ],
        },
        {
          component: 'ValueWithUnitsFormComponent',
          name: 'indexingFrequencyInput',
          label: 'ADMIN.DATASOURCES.MANAGE_MODAL.INDEXING_FREQUENCY',
          includeIf: indexingScheduleSupported,
          testId: 'indexingFrequencyInput',
          value: indexingFrequency,
          onChange: setIndexingFrequency,
          extendValidation: true,
          validation: indexingFrequencyIsValid,
          customErrorText: 'ADMIN.DATASOURCES.MANAGE_MODAL.MIN_INDEXING_FREQUENCY',
          customErrorParams: { min: `${MIN_INDEXING_FREQUENCY}` },
          insideModal: true,
          min: 0,
          minIsExclusive: true,
          availableUnits: DURATION_TIME_UNITS_ALL,
        },
      ],
    },
  ];

  return (
    <Modal show={true} onHide={() => onClose()} animation={false} data-testid="manageDatasourceModal">
      <Modal.Header closeButton={true}>
        <h3>{t('ADMIN.DATASOURCES.MANAGE_MODAL.MANAGE_DATASOURCE')}</h3>
      </Modal.Header>
      <Modal.Body>
        <SimpleSaveFormBuilder
          formDefinition={formDefinition}
          submitFn={() => updateDatasource().then(onClose).catch(_.noop)} // catch reject that keeps modal from closing
          closeFn={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};
