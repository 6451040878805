// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';

import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { Overlay, Popover } from 'react-bootstrap';
import { ContentMenu } from '@/hybrid/annotation/ckEditorPlugins/components/ContentMenu.molecule';
import {
  buildAbsoluteContentUrl,
  buildRelativeContentUrl,
  getContentSpecificCommand,
} from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import {
  ContentCallbacks,
  ContentDisplayMode,
  CustomPlugin,
  ImageContentListenerCommand,
} from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { useCkListener } from '@/hybrid/core/hooks/useCkListener.hook';
import { renderContentVisualization } from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities';
import { Visualization } from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities.constants';
import { CONTENT_MODEL_ATTRIBUTES } from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { Content, ContentDisplayMetadata } from '@/reportEditor/report.constants';
import { sqReportStore, sqWorksheetStore } from '@/core/core.stores';
import { getWorkbenchAddress } from '@/hybrid/utilities/utilities';

const refreshingContentBindings = bindingsDefinition({
  contentId: prop<string>(),
  updateContentSize: prop<(width: number, height: number, modelElement: any) => void>(),
  editor: prop<any>(),
  stopUpdate: prop.optional<boolean>(),
  displayMode: prop.optional<ContentDisplayMode>(),
  isExporting: prop.optional<boolean>(),
  sqReportActions: injected<ReportActions>(),
  sqReportContent: injected<ReportContentService>(),
  wrapperLoadedCallback: prop.optional<(element: HTMLDivElement) => void>(),
});

export const BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
const toPx = (value: number) => `${value}px`;

/**
 * This monitors the various inputs to a piece of content causes the content to refresh.
 */
export const RefreshingContent: SeeqComponent<typeof refreshingContentBindings> = ({
  contentId,
  updateContentSize,
  editor,
  displayMode = ContentDisplayMode.NORMAL,
  stopUpdate = false,
  wrapperLoadedCallback = _.noop,
}) => {
  const { sqReportActions, sqReportContent } = useInjectedBindings(refreshingContentBindings);

  const [loading, setLoading] = useState(true);
  const [src, setSrc] = useState(sqReportStore.getContentImageUrl(contentId));
  const [properties, setProperties] = useState(undefined);
  const [showMenu, setShowMenu] = useState(false);
  const target = useRef(null);

  const t = useTranslation();

  const contentCallbacks: ContentCallbacks = editor.config.get(CustomPlugin.Content).contentCallbacks;
  const model = contentCallbacks.getCurrentModel(contentId);

  const { showAssetSelectionWarnings } = useFlux(sqWorksheetStore);

  //region useCkListeners
  const useWidthPercent = !!useCkListener(
    editor,
    getContentSpecificCommand(ImageContentListenerCommand.RESIZE, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT),
  );
  const height = _.toNumber(
    useCkListener(
      editor,
      getContentSpecificCommand(ImageContentListenerCommand.HEIGHT, contentId),
      model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.HEIGHT),
    ),
  );
  const width = _.toNumber(
    useCkListener(
      editor,
      getContentSpecificCommand(ImageContentListenerCommand.WIDTH, contentId),
      model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH),
    ),
  );
  const border = useCkListener(
    editor,
    getContentSpecificCommand(ImageContentListenerCommand.BORDER, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.BORDER),
  );
  const noMargin = useCkListener(
    editor,
    getContentSpecificCommand(ImageContentListenerCommand.NO_MARGIN, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.NO_MARGIN),
  );
  const propertyOverrides = useCkListener(
    editor,
    getContentSpecificCommand(ImageContentListenerCommand.PROPERTY_OVERRIDES, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES),
  );
  //endregion

  const [refreshMeasurements, setRefreshMeasurements] = useState({
    height,
    width,
  });

  const updateMeasurements = (newHeight, newWidth) => {
    updateContentSize(newWidth, newHeight, model);
  };

  // The report store has a large number of updates that occur on every document update, but hashCodes only get set
  // every once in a while.
  const hashCode: string = useFluxPath(sqReportStore, () => sqReportStore.getContentById(contentId)?.hashCode);
  const showWarningMessage: boolean = useFluxPath(sqReportStore, () => sqReportStore.hasShowWarningMessage(contentId));
  const backupPreview = useFluxPath(sqReportStore, () => sqReportStore.backupPreview);
  const displayMetadata: ContentDisplayMetadata = sqReportStore.getContentDisplayMetadataById(contentId);
  const content: Content = sqReportStore.getContentById(contentId) ?? {};

  //region useEffects
  useEffect(() => () => sqReportActions.removeContentDisplayMetadata(contentId), []);

  useEffect(() => {
    // Store content gets set to what is in the backend frequently, so we want to ignore anytime the hashcode is
    // cleared completely.
    if (hashCode) {
      const upToDateMetadata = sqReportStore.getContentDisplayMetadataById(contentId);
      if (!upToDateMetadata?.errorClass) {
        // The only place that uses deferImageUpdate is the stepToNow logic, which will never be done silently
        const newSrc = sqReportStore.getContentImageUrl(contentId);
        // onLoad event won't fire if src hasn't changed
        if (newSrc !== src) {
          !upToDateMetadata?.refresh?.silently && setLoading(true);
          setSrc(newSrc);
        }
      }

      !refreshMeasurements &&
        setRefreshMeasurements({
          width: target.current.clientWidth,
          height: target.current.clientHeight,
        });
    }
  }, [hashCode]);
  //endregion

  const style = refreshMeasurements
    ? {
        width: toPx(refreshMeasurements.width),
        height: toPx(refreshMeasurements.height),
      }
    : {
        width: '100%',
        height: '100%',
        maxWidth: useWidthPercent || !content?.isReact ? undefined : toPx(width),
        maxHeight: useWidthPercent || !content?.isReact ? undefined : toPx(height),
        // We want to make sure that errored and loading content is never super tiny, which can occur in resize
        // situations.
        minHeight: loading || displayMetadata?.errorClass ? '100px' : undefined,
        minWidth: loading || displayMetadata?.errorClass ? '100px' : undefined,
      };

  const getContentUrl = () => {
    return displayMode === ContentDisplayMode.PDF
      ? buildAbsoluteContentUrl(
          getWorkbenchAddress(),
          content,
          sqReportStore.getDateRangeById(content.dateRangeId),
          sqReportStore.getAssetSelectionById(content.assetSelectionId),
        )
      : buildRelativeContentUrl(contentId);
  };

  const display = renderContentVisualization(
    {
      src,
      contentId,
      noMargin,
      border,
      loading,
      style,
      target,
      errorClass: displayMetadata?.errorClass,
      error: displayMetadata?.error,
      onLoad: (properties?: { visualization: Visualization }) => {
        setLoading(false);
        setProperties(properties);
      },
      onMeasurementsReady: (width, height) => {
        setRefreshMeasurements(undefined);
        sqReportActions.debouncedImageStateChanged();
        updateMeasurements(height, width);
      },
      onError: (error: string, errorCode: number) => sqReportContent.contentError(contentId, error, errorCode),
      propertyOverrides,
      stopUpdate,
      height,
      width,
    },
    content?.isReact,
  );

  const displayWrapperProps = {
    'href': getContentUrl(),
    'className': 'contentWrapper noCopy inheritMinHeight',
    'id': `contentWrapper-${contentId}`,
    'onClick':
      displayMode === ContentDisplayMode.NORMAL
        ? (e) => {
            e.preventDefault();
            setShowMenu(true);
          }
        : null,
    'data-testid': `content-wrapper-${contentId}`,
  };

  // Copying the HTML under an <a> makes all text be styled as links when pasted in another application. Using a div
  // prevents that, but we still want an a tag for view mode and pdfs.
  const displayWrapper =
    displayMode === ContentDisplayMode.NORMAL ? (
      <div ref={(element) => wrapperLoadedCallback(element)} {...displayWrapperProps}>
        {display}
      </div>
    ) : (
      <a ref={(element) => wrapperLoadedCallback(element)} {...displayWrapperProps}>
        {display}
      </a>
    );

  return (
    <>
      {displayMode === ContentDisplayMode.NORMAL && !backupPreview && (
        <div data-testid="refreshingContentOverlay">
          <Overlay
            transition={false}
            placement="bottom"
            target={target.current}
            rootClose={true}
            show={showMenu}
            onHide={() => setShowMenu(false)}>
            <Popover id={`contentMenu-${contentId}`}>
              <ContentMenu
                contentId={contentId}
                closeMenu={() => setShowMenu(false)}
                isReact={content.isReact}
                properties={properties}
              />
            </Popover>
          </Overlay>
        </div>
      )}
      {content.screenshotWarning && showAssetSelectionWarnings ? (
        <div className="figureWithCaption inheritMinHeight">
          {showWarningMessage && (
            <div className="caption sq-alert-warning">
              {
                <Icon
                  icon="fa-warning m3"
                  type="warning"
                  tooltipPlacement="right"
                  testId="assetSelectionWarningMessageIcon"
                  tooltip="REPORT.CONFIG.ASSET_SELECTION_WARNING_TOOLTIP_COLLAPSE"
                  onClick={() => sqReportActions.setContentShowWarningMessage(contentId, false)}
                />
              }
              {content.screenshotWarning}
            </div>
          )}
          <div className="positionRelative inheritMinHeight">
            <div className="positionAbsolute z102">
              {!showWarningMessage && (
                <Icon
                  extraClassNames="iconLeft"
                  icon="fa-warning"
                  type="warning"
                  testId="assetSelectionWarningIcon"
                  tooltip="REPORT.CONFIG.ASSET_SELECTION_WARNING_TOOLTIP_EXPAND"
                  tooltipPlacement="right"
                  onClick={() => sqReportActions.setContentShowWarningMessage(contentId, true)}
                />
              )}
            </div>
            {displayWrapper}
          </div>
        </div>
      ) : (
        <> {displayWrapper} </>
      )}
    </>
  );
};
