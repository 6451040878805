import angular from 'angular';
import { sqReportActions } from '@/reportEditor/report.actions';
import { CkReportContentService } from '@/hybrid/annotation/ckReportContent.service';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { sqReportContentActions } from '@/reportEditor/reportContent.actions';
import { sqReportContentStore } from '@/reportEditor/reportContent.store';

export const dependencies = [
  'Sq.Core',
  'Sq.AppConstants',
  'Sq.TrendData',
  'Sq.Search',
  'Sq.Investigate',
  'Sq.Worksheet',
  'Sq.Explorer',
];
/**
 * @module The ReportEditor module provides the report editor component
 */
angular
  .module('Sq.Report', dependencies)
  .service('sqReportActions', sqReportActions)
  .service('sqReportContentActions', sqReportContentActions)
  .store('sqReportContentStore', sqReportContentStore)
  .service('sqCkReportContent', CkReportContentService)
  .service('sqReportContent', ReportContentService);
