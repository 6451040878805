// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { sqPluginsApi } from '@/sdk';
import { useTranslation } from 'react-i18next';
import { CELL_TYPES, TableColumn } from '@/hybrid/core/Table.atom';
import _ from 'lodash';
import { ServerSideFilteringAndPaginatingTable } from '@/hybrid/core/ServerSideFilteringAndPaginatingTable.molecule';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import {
  loadPlugins,
  removePlugins,
  resetPageNumber,
  setFilter,
  setPageNumberAndGo,
  setPageSizeForTable,
  setSort,
} from '@/administration/pluginsAdmin.actions';
import { API_VERSION } from '@/hybrid/plugin/pluginApiVersion';
import { ItemAclModal } from '@/hybrid/accessControl/ItemAclModal.molecule';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TableLoadingIndicator } from '@/hybrid/core/TableLoadingIndicator.molecule';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';
import { sqPluginsAdminStore } from '@/core/core.stores';

interface PluginTableProps {
  workbookId: string;
  worksheetId: string;
}

export const PluginTable: React.FunctionComponent<PluginTableProps> = ({ workbookId, worksheetId }) => {
  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [aclItemId, setAclItemId] = useState<string | undefined>(undefined);
  const plugins = useFluxPath(sqPluginsAdminStore, () => sqPluginsAdminStore.plugins);

  const tableLoading = useFluxPath(sqPluginsAdminStore, () => sqPluginsAdminStore.isTableLoading);

  useEffect(() => {
    loadPlugins();
  }, []);

  const pluginInputFileRef = useRef<HTMLInputElement>(null);

  const renderEditAcls = (item) => (
    <div className="btn btn-sm" onClick={() => setAclItemId(item.id)} data-testid={`openACLModal_${item.id}`}>
      <Icon icon="fc-share" extraClassNames="pr5" type="text" />
    </div>
  );

  const columns: TableColumn[] = [
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40 },
    },
    {
      accessor: 'name',
      searchProperty: 'nameSearch',
      header: 'ADMIN.PLUGIN.NAME',
      cellStyle: { minWidth: 120, wordWrap: 'break-word' },
    },
    {
      accessor: 'identifier',
      searchProperty: 'identifierSearch',
      header: 'ADMIN.PLUGIN.IDENTIFIER',
      cellStyle: { wordWrap: 'break-word' },
    },
    {
      accessor: 'category',
      searchProperty: 'categorySearch',
      header: 'ADMIN.PLUGIN.CATEGORY',
      cellStyle: { wordWrap: 'break-word' },
    },
    {
      accessor: 'version',
      header: 'ADMIN.PLUGIN.VERSION',
      filterable: false,
      sortable: false,
      cellStyle: { width: 100, maxWidth: 100, wordWrap: 'break-word' },
    },
    {
      accessor: 'description',
      header: 'ADMIN.PLUGIN.DESCRIPTION',
      filterable: false,
      sortable: false,
      cellStyle: { wordWrap: 'break-word' },
    },
    {
      accessor: 'id',
      header: 'ADMIN.PLUGIN.ID',
      filterable: false,
      sortable: false,
      cellStyle: { width: 250, maxWidth: 300 },
    },
    {
      accessor: 'id',
      header: 'ADMIN.PLUGIN.ACCESS_CONTROL',
      cellStyle: { width: 100, maxWidth: 100 },
      cellRenderFunction: renderEditAcls,
      filterable: false,
      sortable: false,
    },
  ];

  const toggleItemSelected = (item) => {
    const itemIndex = _.indexOf(selectedIds, item.id);
    if (itemIndex > -1) {
      setSelectedIds([..._.pull(selectedIds, item.id)]);
    } else {
      setSelectedIds([...selectedIds, item.id]);
    }
  };

  const filterUsers = (option, field) => {
    setFilter(field, option.value);
    loadPlugins();
  };

  const sortPlugins = (newSortProperty, oldSortOrder) => {
    const oldSortProperty = sqPluginsAdminStore.sortProperty;
    setSort(newSortProperty, oldSortProperty === newSortProperty ? !oldSortOrder : true);
    loadPlugins();
  };

  const refreshPlugins = () => {
    setIsRefreshing(true);
    setSelectedIds([]);
    resetPageNumber();
    return loadPlugins().finally(() => setIsRefreshing(false));
  };

  const deletePlugins = () => {
    removePlugins(selectedIds).then(() => {
      setSelectedIds([]);
    });
  };

  const nothingSelected = _.isEmpty(selectedIds);

  const openFileUploader = () => {
    pluginInputFileRef.current.click();
  };

  const onUploadFileSelected = (event) => {
    const pluginFile = event.target.files[0];
    sqPluginsApi
      .uploadPlugin({ file: pluginFile })
      .then(() => {
        refreshPlugins();
        successToast({ messageKey: 'ADMIN.PLUGIN.UPLOADED' });
      })
      .catch((error) => errorToast({ httpResponseOrError: error }))
      .finally(() => {
        pluginInputFileRef.current.value = '';
      });
  };

  const loadPluginsTable = () => loadPlugins();

  return (
    <>
      <div className="flexColumnContainer flexSpaceBetween mb5">
        <TextButton
          id="deleteSelectedPlugins"
          onClick={deletePlugins}
          testId="deleteSelectedPlugins"
          disabled={nothingSelected}
          label="ADMIN.PLUGIN.DELETE_SELECTED"
        />

        <span className="mt5 text-muted">{t('ADMIN.PLUGIN.API_VERSION', { version: API_VERSION })}</span>

        <div className="flexColumnContainer">
          <ButtonWithManagedSpinner
            buttonProps={{ id: 'refreshPlugins', extraClassNames: 'mr5' }}
            spinnerIconProps={{ type: 'text', large: true }}
            label="ADMIN.PLUGIN.REFRESH_LIST"
            action={refreshPlugins}
            icon="fa-repeat"
          />

          <TextButton
            id="uploadPlugin"
            variant="theme"
            onClick={openFileUploader}
            icon="fa-upload"
            iconStyle="white"
            extraClassNames="mr15"
            label="ADMIN.PLUGIN.UPLOAD"
          />

          <input
            id="pluginFileInput"
            type="file"
            multiple={false}
            accept=".plugin"
            style={{ display: 'none' }}
            onChange={onUploadFileSelected}
            ref={pluginInputFileRef}
          />
        </div>
      </div>

      <ServerSideFilteringAndPaginatingTable
        testId="userAdministrationTable"
        rowSelectionCallback={toggleItemSelected}
        filterCallback={filterUsers}
        selectAllCallback={setSelectedIds}
        selectedIds={selectedIds}
        sortCallback={sortPlugins}
        columns={columns}
        items={plugins}
        store={sqPluginsAdminStore}
        actions={{
          setPageSizeForTable,
          setPageNumberAndGo,
        }}
        loadTable={loadPluginsTable}
      />
      {tableLoading && <TableLoadingIndicator />}
      {aclItemId && (
        <ItemAclModal
          itemId={aclItemId}
          closeModal={() => setAclItemId(undefined)}
          workbookId={workbookId}
          worksheetId={worksheetId}
          includeLinksAndCorporateMessage={false}
        />
      )}
    </>
  );
};
