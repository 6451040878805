// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useResizableHighchart from '@/hybrid/core/hooks/useResizableHighchart.hook';
import {
  chartMouseMove,
  getColorMap,
  getCompareViewChartOptions,
  getLabelCapsules,
  getNewConfig,
  isEqualOmitFunctions,
  omitData,
} from '@/services/compareView.utilities';
import { LegendWrapper } from '@/hybrid/trend/LegendWrapper.atom';
import {
  CUSTOMIZATION_MODES,
  EMPTY_DIFF,
  ITEM_FIELDS_TO_DIFF,
  ITEM_TYPES,
  LABEL_LOCATIONS,
} from '@/trendData/trendData.constants';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TrendStore } from '@/trendData/trend.store';
import {
  getItemYAxis,
  manageAxisOffsets,
  setPointsOnly,
  updateCapsuleAxis,
  updateSeriesYExtremes,
  updateYAxisPropertiesAndLabels,
} from '@/hybrid/utilities/chartHelper.utilities';
import { anyLabelsOnLocation, LabelDisplayConfiguration } from '@/hybrid/utilities/label.utilities';
import { diffItemArrays } from '@/hybrid/utilities/utilities';
import { DEBOUNCE } from '@/core/core.constants';
import { useDebounce } from '@/hybrid/core/hooks/useDebounce.hook';
import { CursorsService } from '@/hybrid/trend/trendViewer/cursors.service';
import { sqCursorStore, sqDurationStore, sqWorksheetStore } from '@/core/core.stores';
import { CAPSULE_LANE_HEIGHT } from '@/hybrid/trend/compareView.constants';
import { updateLaneDisplay } from '@/trendData/yAxis.actions';
import { TrendActions } from '@/trendData/trend.actions';

interface CompareViewProps {
  items: any[];
  labelDisplayConfiguration: LabelDisplayConfiguration;
  colorByProperty: string;
  isSignalColorMode: boolean;
  firstColumn: string;
  longestCapsuleSeriesDuration: number;
  customizationMode: CUSTOMIZATION_MODES;
  trendStoreData: TrendStore;
  sqTrendActions: TrendActions;
}

export const CompareView: React.FunctionComponent<CompareViewProps> = ({
  items,
  labelDisplayConfiguration,
  colorByProperty,
  isSignalColorMode,
  firstColumn,
  customizationMode,
  trendStoreData,
  longestCapsuleSeriesDuration,
  sqTrendActions,
}) => {
  const [chart, setChart] = useState<Highcharts.Chart | null>(null);
  const [colorMap, setColorMap] = useState({});
  const [chartOptions, setChartOptions] = useState(getCompareViewChartOptions());
  const lastOffsets = useRef({ offsetRight: 0, offsetLeft: 0 });
  const lastItems = useRef([]);
  const chartElementRef = useRef(null);
  const onMouseOver = useRef(_.noop);
  const sqCursors = useRef(new CursorsService());

  const getLaneOptions = (axisTitlePresent: boolean, itemOverride: any[] = items) => ({
    chart,
    getChart: () => chart,
    getItems: () => itemOverride,
    capsuleLaneHeight: CAPSULE_LANE_HEIGHT,
    isCapsuleTime: false,
    isCompareViewRainbowColorMode: !isSignalColorMode,
    capsuleTimeColorMode: undefined,
    lanes: _.chain(items).map('lane').reject(_.isNil).uniq().sort().value(),
    items: itemOverride,
    sqTrendStore: trendStoreData,
    sqTrendStoreData: trendStoreData,
    axisTitlePresent,
  });

  const updateChart = ({
    forceRedraw,
    oldItems,
    newItems,
  }: {
    forceRedraw?: boolean;
    oldItems?: any[];
    newItems?: any[];
  }) => {
    // make sure to update and check for axes
    // TODO CRAB-31202: Remove this _.reject and use both series and scalar types as the input to getColorMap()
    const itemsToUse = _.reject(newItems ?? items, ['itemType', ITEM_TYPES.SCALAR]);

    const colorMap = getColorMap(_.filter(itemsToUse, ['itemType', ITEM_TYPES.SERIES]));
    const modifiableItems = _.map(itemsToUse, (item) => ({ ...item })).concat(getLabelCapsules(itemsToUse));
    const axisTitlePresent = anyLabelsOnLocation(labelDisplayConfiguration, LABEL_LOCATIONS.AXIS);

    const laneOptions = getLaneOptions(axisTitlePresent, modifiableItems);

    const offsets = manageAxisOffsets({
      ...laneOptions,
      addAxisTitle: axisTitlePresent,
      skipAxisUpdate: true,
    });

    const diff = oldItems && newItems ? diffItemArrays(newItems, oldItems, ITEM_FIELDS_TO_DIFF) : EMPTY_DIFF;

    const { xAxis, series, yAxis } = getNewConfig({
      chart,
      items: modifiableItems,
      firstColumn,
      initialLeft: offsets.offsetLeft,
      initialRight: offsets.offsetRight,
      axisTitlePresent,
      laneOptions,
      colorMap,
      isSignalColorMode,
    });

    // It's possible to be in this state if items are loaded but the property columns are still being set.
    if (_.isEmpty(xAxis)) {
      return;
    }

    if (
      forceRedraw ||
      !_.isEqual(xAxis, chartOptions.xAxis) ||
      // We can skip checking the data because the capsule ID will change if the data is updated
      !isEqualOmitFunctions(omitData(series), omitData(chartOptions.series)) ||
      !isEqualOmitFunctions(yAxis, chartOptions.yAxis) ||
      !_.isEqual(lastOffsets.current, offsets)
    ) {
      const newChartOptions = { xAxis, series, yAxis };
      setChartOptions(newChartOptions);
      lastOffsets.current = offsets;

      chart.update(newChartOptions, false, true, false);

      updateCapsuleAxis({
        ...laneOptions,
        colors: {},
        sqTrendCapsuleSetStoreData: {} as any,
        showCapsuleLaneLabels: false,
        startingTop: 6,
      });
      updateYAxisPropertiesAndLabels(laneOptions);
    }

    if (diff.addedItems) {
      updateSeriesYExtremes({ items: diff.addedItems, chart });
    }

    if (diff.changes.yAxisConfig?.length) {
      updateSeriesYExtremes({ items: diff.changes.yAxisConfig, chart });
    }

    // We need to ensure that the axis min and max are always properly set. If you swap a Signal with another
    // Signal that has the same min/max values then no yAxisConfig changes will be detected and the signal is
    // not displayed as expected as Highcharts is too helpful in setting min/max axis values on data update.
    if (diff.changes.data?.length || diff.changes.yAxisType?.length) {
      updateLaneDisplay(sqTrendActions);
    }

    if (diff.changes.stringEnum?.length) {
      // This is a hack required to set the string labels initially,
      // due to the fact that we add the item to the chart prior to getting data
      if (!_.isEqual(_.countBy(oldItems, 'isStringSeries'), _.countBy(newItems, 'isStringSeries'))) {
        updateSeriesYExtremes({ items: diff.changes.stringEnum, chart });
      } else {
        // This enables us to update the labels on the y axis for string series during pan and zoom
        // without removing and re-adding the series
        _.forEach(diff.changes.stringEnum, (item) => {
          getItemYAxis(chart, item).update(
            {
              tickPositions: _.map(item.stringEnum, 'key').sort(),
            },
            false,
          );
        });
      }
    }

    if (diff.changes.sampleDisplayOption?.length) {
      setPointsOnly({
        ...laneOptions,
        items: diff.changes.sampleDisplayOption,
      });
    }

    chart.redraw();

    setColorMap(colorMap);
    lastItems.current = itemsToUse;
  };

  const onDestroyChart = () => {
    chartElementRef.current = null;
  };

  const debouncedReflowChartCallback = useDebounce(() => updateChart({ forceRedraw: true }), DEBOUNCE.SHORT);

  useResizableHighchart({
    chart,
    chartElementRef,
    setChart,
    onDestroyChart,
    reflowChartCallback: debouncedReflowChartCallback,
  });

  useEffect(() => {
    if (_.isEmpty(chart)) {
      return;
    }

    updateChart({ newItems: items, oldItems: lastItems.current });
  }, [items, chart, firstColumn]);

  useEffect(() => {
    if (_.isEmpty(chart) || !labelDisplayConfiguration) {
      return;
    }

    const axisTitlePresent = anyLabelsOnLocation(labelDisplayConfiguration, LABEL_LOCATIONS.AXIS);
    const laneOptions = getLaneOptions(axisTitlePresent);
    const offsets = manageAxisOffsets({
      ...laneOptions,
      addAxisTitle: axisTitlePresent,
      skipAxisUpdate: true,
    });
    if (!_.isEqual(offsets, lastOffsets.current)) {
      updateChart({ forceRedraw: true });
    } else {
      updateYAxisPropertiesAndLabels(laneOptions);
      chart.redraw();
    }
  }, [labelDisplayConfiguration, customizationMode]);

  useEffect(() => {
    if (!chart || !chartElementRef.current) {
      return;
    }

    chartElementRef.current.removeEventListener('mousemove', onMouseOver.current);

    onMouseOver.current = (e) =>
      chartMouseMove(e, {
        sqCursors: sqCursors.current,
        chart,
        items,
        sqTrendStoreData: trendStoreData,
        sqDurationStoreData: sqDurationStore,
        longestCapsuleSeriesDuration,
        sqCursorStoreData: sqCursorStore,
        timezoneName: sqWorksheetStore.timezone?.name,
        capsuleLaneHeight: CAPSULE_LANE_HEIGHT,
        colorMap: isSignalColorMode ? undefined : colorMap,
      });

    chartElementRef.current.addEventListener('mousemove', onMouseOver.current);
  }, [items, chart, chartElementRef.current, colorMap]);

  return (
    <div className="flexFill flexRowContainer chartView">
      {/* This prevents the legend and chart from overlapping */}
      <div className="flexFill" ref={chartElementRef} onMouseLeave={() => sqCursors.current?.clearHoverCursor()}>
        <HighchartsReact
          highcharts={Highcharts}
          options={getCompareViewChartOptions()}
          allowChartUpdate={false}
          callback={setChart}
          containerProps={{ style: { height: '100%' } }}
          testId="chartDisplay"
        />
      </div>
      {!isSignalColorMode && (
        <LegendWrapper title={colorByProperty}>
          {_.map(colorMap, (value, key) => (
            <div className="mr10 text-nowrap" key={key}>
              <Icon icon="fc-series" extraClassNames="pr5" type="color" color={value} testId={`${key}-colorIcon`} />
              {key}
            </div>
          ))}
        </LegendWrapper>
      )}
    </div>
  );
};
