// @ts-strict-ignore
import React, { useRef, useState } from 'react';
import { Button, Overlay } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/Overlay';
import classNames from 'classnames';
import { ToolbarDropdownTitle } from '@/hybrid/core/ToolbarDropdownTitle.molecule';
import _ from 'lodash';

interface ToolbarPopoverButtonProps {
  /** icon class to be used with the dropdown (i.e. 'fc-zoom') */
  icon: string;
  /** label for the toolbar item (shown only when toolbar is wide enough, pass in the translation key) */
  label: string | JSX.Element;
  /** text to be displayed on the icon's tooltip (pass in the translation key) */
  tooltipText?: string;
  tooltipPlacement?: Placement;
  /** true to render an icon-only dropdown button */
  isSmall?: boolean;
  extraClassNames?: string;
  buttonClasses?: string;
  /** true if popover is currently open/active */
  active?: boolean;
  testId?: string;
  disabled?: boolean;
  /** function called when popover is hidden */
  onHide?: () => void;
}

/**
 * Toolbar button that renders a popover element when clicked
 */
export const ToolbarPopoverButton: React.FunctionComponent<ToolbarPopoverButtonProps> = (props) => {
  const {
    children,
    icon,
    label,
    tooltipText,
    tooltipPlacement = 'top',
    isSmall = false,
    active = false,
    disabled = false,
    testId,
    extraClassNames,
    buttonClasses,
    onHide = _.noop,
  } = props;
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  return (
    <div ref={ref}>
      <Overlay
        placement="bottom"
        rootClose={true}
        transition={false}
        show={showPopover}
        target={target}
        onHide={() => {
          setShowPopover(false);
          onHide();
        }}>
        {children as any}
      </Overlay>
      <div className={extraClassNames}>
        <Button
          className={classNames(
            buttonClasses,
            'sq-btn-toolbar-dropdown',
            isSmall ? 'btn-sm-text sq-btn-toolbar-dropdown-sm' : 'btn-lg-text',
          )}
          type="button"
          size="sm"
          active={active}
          disabled={disabled}
          variant={null}
          bsPrefix="sq-btn"
          data-testid={testId}
          onClick={(e) => {
            e.stopPropagation();
            setTarget(ref.current);
            setShowPopover(!showPopover);
          }}>
          {typeof label === 'string' || isSmall ? (
            <ToolbarDropdownTitle
              label={typeof label === 'string' ? label : undefined}
              isSmall={isSmall}
              tooltipText={tooltipText}
              tooltipPlacement={tooltipPlacement}
              icon={icon}
            />
          ) : (
            label
          )}
        </Button>
      </div>
    </div>
  );
};
