// @ts-strict-ignore
import _ from 'lodash';
import { Duration, Moment } from 'moment';
import moment from 'moment-timezone';
import juration from 'juration';
import { H_PER_DAY, MIN_PER_DAY, S_PER_DAY, S_PER_H, S_PER_MIN } from '@/hybrid/utilities/datetime.constants';
import { DURATION_TIME_UNITS_ALL, DurationTimeUnit, LOCALES, NUMBER_CONVERSIONS } from '@/main/app.constants';
import i18next from 'i18next';
import { RangeExport } from '@/trendData/duration.store';
import { dateParse } from '@/track/track.helpers';
import { BackendDuration, FrontendDuration } from '@/services/systemConfiguration.constants';
import { sqWorkbenchStore } from '@/core/core.stores';
import { getLanguageForJuration } from '@/hybrid/utilities/utilities';

const TIME_24_HOUR_FORMAT = 'HH:mm'; // HTML time input values are always 24-hour
export const CACHE_REMOVE_SIZE = 10;

export function cacheFactory(capacity) {
  let caches = [];
  return {
    get: (key: string) => {
      const date = caches.filter((cache) => cache.key === key).pop();
      return date ? date.value : date;
    },
    put: (key, value) => {
      if (caches.length >= capacity) {
        caches = caches.splice(CACHE_REMOVE_SIZE);
      }
      caches.push({ key, value });
    },
  };
}

const momentCache = cacheFactory(8000);

// Set juration to parse months as 1/12 of a year, so that long durations better align with year lengths
juration.setUnitValue('months', moment.duration(1, 'year').asSeconds() / 12);

/**
 * Returns a Moment object representing the input date provided. Any precision beyond milliseconds is
 * automatically removed, since moment.js only supports millisecond resolution.
 *
 * This function utilizes an internal cache to return an existing moment if one has already
 * been created from the provided input.
 *
 * @param dateInput - The input date to parse. This argument can be either an ISO-8601 formatted
 *                                  string or a number representing milliseconds since the Unix epoch.
 * @param [timezoneName] - Timezone in which to parse the string if there is no offset information in the
 *   date string. If not provided, string is parsed in UTC.
 * @return A Moment object, either created or retrieved from an internal cache.
 */
export function getMoment(dateInput: string | number | Moment, timezoneName?: string): Moment {
  let returnMoment, parseMethods;

  // Use the cached value if it available
  const existing = momentCache.get(dateInput as string);
  if (existing) {
    return existing;
  }

  // If this is already a moment, just return it
  if (moment.isMoment(dateInput)) {
    return dateInput as Moment;
  }

  // If we were actually given a number, convert directly
  if (_.isFinite(dateInput)) {
    returnMoment = moment.utc(dateInput);
    return returnMoment;
  }

  // The sequence of parsing methods to use if the input is a string
  parseMethods = [
    _.partial(parseISODate, dateInput, timezoneName),
    _.partial(parseLocaleDate, dateInput, timezoneName),
  ];

  // Try each of the parsing methods in order until one succeeds
  _.forEach(parseMethods, (method: Function) => {
    returnMoment = method();
    if (returnMoment.isValid()) {
      // return false to exit the loop
      return false;
    }
  });

  // Store in the cache if the parsing was successful.
  // NOTE: It is important to evaluate .isValid() here since it is a lazily-loaded property.
  // Attempting to check it after the object is frozen will silently fail to set it, and
  // .isValid() will return undefined.
  if (returnMoment.isValid()) {
    // Creating moments from strings is very expensive, so we freeze and cache the moment
    returnMoment = Object.freeze(returnMoment);
    momentCache.put(dateInput, returnMoment);
  }

  return returnMoment;
}

/**
 * Parse an ISO-8601 formatted string into a moment object. Input string must have a fully specified date and time,
 * with fractional seconds and UTC offset as the only optional portions. Any fractional seconds below milliseconds
 * will be ignored. ISO-8601 is a locale-agnostic format.
 *
 * @example <caption>All of the following are valid strings</caption>
 * // December 20, 2015 at 1:20am UTC
 * 2015-12-20T01:20:00Z
 * // December 26, 2015 at 1:10:12pm, 2 hours ahead of UTC
 * 2015-12-26T13:10:12+0200
 * // January 1, 2015 at 8:01:15.332pm, 1 hour behind of UTC
 * 2015-01-01T20:01:15.332-0100
 *
 * @param dateInput - Input string to parse
 * @param [defaultTimezoneName] - Timezone to use if no offset information is found in the dateInput
 *   string. If the timezone is not provided and no offset is in the input string, date is parsed as UTC (Z).
 * @return Moment object created. .isValid() on the returned object will be false if parsing failed.
 */
export function parseISODate(dateInput: string, defaultTimezoneName?: string): Moment {
  let adjustedString;
  let tzBeginIndex, lastIndexofPeriod;
  const tz = defaultTimezoneName ? defaultTimezoneName : 'UTC';

  // Find seconds decimal place
  lastIndexofPeriod = dateInput.lastIndexOf('.');

  // Find beginning of timezone, making sure to skip the date portion (which may include dashes)
  tzBeginIndex = _.findLastIndex(dateInput, (char) => {
    return char === 'Z' || char === '+' || char === '-';
  });

  // If no timezone found, or if it isn't found near the end of the string where the tiemzone should be,
  // set to the length of the string to indicate that no offset was found.
  if (tzBeginIndex === -1 || dateInput.length - tzBeginIndex > 6) {
    tzBeginIndex = dateInput.length;
  }

  // Find the number of digits in the fractional seconds portion, so that we can pad or shrink it to be
  // exactly 3.

  if (lastIndexofPeriod === -1) {
    // no period detected; add one and three 0s
    adjustedString = [
      dateInput.substring(0, tzBeginIndex),
      '.000',
      dateInput.substring(tzBeginIndex, dateInput.length),
    ].join('');
  } else if (tzBeginIndex - lastIndexofPeriod > 4) {
    // too many seconds decimal places; remove some
    adjustedString = [
      dateInput.substring(0, lastIndexofPeriod + 4),
      dateInput.substring(tzBeginIndex, dateInput.length),
    ].join('');
  } else {
    // not enough seconds decimal places; add some
    adjustedString = [
      dateInput.substring(0, tzBeginIndex),
      _.repeat('0', 4 - (tzBeginIndex - lastIndexofPeriod)),
      dateInput.substring(tzBeginIndex, dateInput.length),
    ].join('');
  }

  if (tzBeginIndex === dateInput.length) {
    return moment.tz(adjustedString, 'YYYY-MM-DDTHH:mm:ss.SSS', true, tz);
  } else {
    return moment.tz(adjustedString, 'YYYY-MM-DDTHH:mm:ss.SSSZZ', true, tz);
  }
}

/**
 * Parse a date/time string formatted in the current locale into a moment object.
 *
 * @example <caption>Examples for 'en' locale</caption>
 * "12/1/2015 1:00 pm" -> December 1, 2015 at 1pm
 * "12/1/2015" -> December 1, 2015 at the time of the reference (or 00:00 if no reference provided)
 *
 * @example <caption>Examples for 'fr' locale</caption>
 * "1/12/2015 13:00" -> December 1, 2015 at 1pm
 * "1/12/2015" -> December 1, 2015 at the time of the reference (or 00:00 if no reference provided)
 *
 * @param input - Input string to parse
 * @param timezoneName - Timezone to use when parsing the input string. If the timezone is not provided
 *   and no offset is in the input string, date is parsed as UTC (Z).
 * @param reference - Reference date to use to fill in missing portions if the parsed string contains
 *   less than a completely specified date/time. The reference date will be used for the missing pieces.
 * @return Moment object created. .isValid() on the returned object will be false if parsing failed.
 */
export function parseLocaleDate(input: string, timezoneName: string, reference: Moment): Moment {
  const tz = timezoneName ? timezoneName : 'UTC';
  const ref = reference ? moment.tz(reference, tz) : undefined;
  const parseFormats = [];
  let result, successFormat;
  let offsetCorrection = 0;

  const shortDate = moment.localeData().longDateFormat('l').replace('YYYY', 'YY');
  const dateFormats = [
    'l',
    'L',
    'll',
    'LL',
    shortDate,
    'D-MMM-YYYY',
    'D-MMM-YY',
    'D MMM YYYY',
    'D MMM YY',
    'DMMMYYYY',
    'DMMMYY',
    'MMM YYYY',
    'MMMM YYYY',
  ];
  const dateMissingYear = ['D-MMM', 'D MMM', 'MMM-D', 'MMM D', 'MMMM D', 'M/D'];
  const yearOnly = ['YYYY'];
  const timeFormats = ['LT', 'LTS', 'H:mm', 'HH:mm', 'H:mm:ss', 'H'];
  const timeFormatPrependRef = 'YYYY-MM-DD ';
  if (display12HrClock()) {
    timeFormats.push('h A', 'hA', 'h:mmA', 'h:mm:ssA');
  }

  // Combine date/time formats to all fully-formed formats
  _.forEach(dateFormats, (date) => {
    _.forEach(timeFormats, (time) => {
      parseFormats.push({
        format: `${date} ${time}`,
        referenceValues: [],
        offsetAdjust: false,
      });
    });
  });

  // Add date-only formats
  _.forEach(dateFormats, (date) => {
    parseFormats.push({
      format: date,
      referenceValues: 'hour_minute_second_millisecond'.split('_'),
      offsetAdjust: true,
    });
  });

  // Add date-only formats without a year
  _.forEach(dateMissingYear, (date) => {
    parseFormats.push({
      format: date,
      referenceValues: 'year_hour_minute_second_millisecond'.split('_'),
      offsetAdjust: true,
    });
  });

  // Add year-only formats
  _.forEach(yearOnly, (year) => {
    parseFormats.push({
      format: year,
      referenceValues: 'month_date_hour_minute_second_millisecond'.split('_'),
      offsetAdjust: true,
    });
  });

  // Add time-only formats
  // For time-only formats, we prepend the reference year/month/date to the input string so that we
  // can let moment.timezone handle the offset and DST values properly
  _.forEach(timeFormats, (time) => {
    parseFormats.push({
      format: timeFormatPrependRef + time,
      referenceValues: [],
      prepend: ref ? ref.format(timeFormatPrependRef) : '',
      offsetAdjust: false,
    });
  });

  // attempt to parse in each format in sequence
  _.forEach(parseFormats, (formatObject) => {
    result = moment.tz(_.get(formatObject, 'prepend', '') + input, formatObject.format, true, tz);
    if (result.isValid()) {
      // if one succeeds, return false to exit the forEach loop
      successFormat = formatObject;
      return false;
    }
  });

  // If we successfully parsed the input and have a reference, see if we need to use it
  if (ref && successFormat) {
    if (successFormat.offsetAdjust) {
      // If a portion of the input is implied, then we need to account for any difference in UTC offset between the
      // reference and result dates. We need to 'undo' the difference in time. It is easiest to see in an example:
      //  Reference: "12/1/2015 9:30PM", US/Eastern
      //  Input: "9/1/2015" (note that this is on the other side of the DST boundary in US/Eastern)
      //  Result should be: "9/1/2015 9:30PM", US/Eastern, NOT "9/1/2015 8:30PM"
      offsetCorrection +=
        moment.tz.zone(tz).utcOffset(reference.valueOf()) - moment.tz.zone(tz).utcOffset(result.valueOf());
    }

    _.forEach(successFormat.referenceValues, (unit) => {
      result[unit](reference[unit]());
    });

    result.add(offsetCorrection, 'minutes');
  }

  return result;
}

/**
 * Parses a duration offset string into a duration with magnitude and direction (+ or -).
 * Input string must begin with a + or - in order to be parsed successfully.
 *
 * @param durationInput - Input string to parse
 * @return Duration parsed from the provided input string. If parsing fails, .valueOf()
 *   will be 0. (There is no .isValid() function on moment.duration objects.)
 */
export function parseDurationOffset(durationInput: string): Duration {
  const cleanedInput = durationInput.replace(/ /g, '');
  let direction, duration;

  if (cleanedInput[0] === '+') {
    direction = 1;
  } else if (cleanedInput[0] === '-') {
    direction = -1;
  } else {
    return moment.duration(0);
  }

  duration = parseDuration(cleanedInput.substring(1));
  if (duration.valueOf() === 0) {
    return duration;
  } else {
    return moment.duration(duration.asMilliseconds() * direction, 'milliseconds');
  }
}

/**
 * Juration has trouble parsing Japanese characters. This converts Japanese input to latin characters.
 * Only Japanese characters relevant to duration will be converted.
 *
 * @param durationInput - Input string to parse
 * @return durationInput modified to be parsed with latin characters or undefined if the input contained
 * irrelevant Japanese characters. If the user language is not Japanese, but Japanese characters are entered,
 * this will return undefined, as well.
 */
function formatToLatinCharacters(durationInput: string): string | undefined {
  const latinCharacters = /^[\w\u00c0-\u017e\s.,]+$/.test(durationInput);
  const originalInput = durationInput;
  if (_.includes(durationInput, '秒')) {
    durationInput = _.replace(durationInput, '秒', 'seconds');
  }
  if (_.includes(durationInput, '分')) {
    durationInput = _.replace(durationInput, '分', 'minute');
  }
  if (_.includes(durationInput, '時')) {
    durationInput = _.replace(durationInput, '時', 'hour');
  }
  if (_.includes(durationInput, '日')) {
    durationInput = _.replace(durationInput, '日', 'day');
  }
  if (_.includes(durationInput, '週')) {
    durationInput = _.replace(durationInput, '週', 'week');
  }
  if (_.includes(durationInput, 'か月')) {
    durationInput = _.replace(durationInput, 'か月', 'month');
  }
  if (_.includes(durationInput, '月')) {
    durationInput = _.replace(durationInput, '月', 'month');
  }
  if (_.includes(durationInput, '年')) {
    durationInput = _.replace(durationInput, '年', 'year');
  }
  if (sqWorkbenchStore.userLanguage === LOCALES.JA) {
    juration.setLanguage(getLanguageForJuration('en'));
  }

  if (latinCharacters || (originalInput !== durationInput && sqWorkbenchStore.userLanguage === LOCALES.JA)) {
    return durationInput;
  }
}

/**
 * Parses a human-readable string representing a duration into a moment.duration object.
 * Input string must start with a number in order to be parsed successfully.
 * Months are handled specially in this routine. Each single month represents 1/12 of a year, or 30.416 days.
 * This improves the accuracy of large intervals when added to moment dates.
 *
 * @example parseDuration('3 days') === moment.duration(3, 'days')
 * @example parseDuration('6 months') === moment.duration(182.5, 'days')
 *
 * @param durationInput - Input string to parse
 * @return moment.duration object created. If parsing fails, .valueOf() will be 0. (There is no
 *   .isValid() function on moment.duration objects.)
 */
export function parseDuration(durationInput: string): Duration {
  let parseResult;
  const invalidDuration = moment.duration(0);
  const trimmedInput = _.trim(durationInput);
  if (isNaN(Number(trimmedInput[0]))) {
    return invalidDuration;
  }

  if (!_.isError(_.attempt(juration.parse, durationInput))) {
    durationInput = formatToLatinCharacters(durationInput);
  }

  parseResult = _.attempt(juration.parse, durationInput);
  juration.setLanguage(getLanguageForJuration(sqWorkbenchStore.userLanguage));
  if (_.isError(parseResult)) {
    return invalidDuration;
  } else {
    return moment.duration(parseResult, 'seconds');
  }
}

/**
 * Parses a string representing two dates into two moment objects. The input string must contain a reference to
 * an anchor and a positive or negative offset. Anchors: * (Now) or $ (Start|End). The anchor is resolved to either
 * Start or End based on whether the offset is positive or negative. If start or end references are invalid,
 * strings referencing $ are returned as invalid.
 *
 * @example '*-2mo': Start=(nowReference - 2 months), End=(nowReference)
 * @example '$+1d': Start=(startReference), End=(startReference + 1 day)
 * @example '$-1d': Start=(endReference - 1 day), End=(endReference)
 *
 * @param input - Input string to be parsed
 * @param nowReference - Reference to use if '*' starts the input string
 * @param startReference - Reference to use for '$' if offset is positive
 * @param endReference - Reference to use for '$' if offset is negative
 * @return Object containing start and end properties with the parsed Moment objects.
 */
export function parseDurationIntoDates(
  input: string,
  nowReference: Moment,
  startReference: Moment,
  endReference: Moment,
): {
  start: Moment;
  end: Moment;
} {
  let anchorNow, duration;
  let remainingInput = input;
  const result = { start: moment.invalid(), end: moment.invalid() };

  // look at leading character to see if there is a reference anchor: * or $
  if (remainingInput[0] === '*') {
    anchorNow = true;
  } else if (
    remainingInput[0] === '$' &&
    moment.isMoment(startReference) &&
    startReference.isValid() &&
    moment.isMoment(endReference) &&
    endReference.isValid()
  ) {
    anchorNow = false;
  } else {
    return result;
  }

  remainingInput = remainingInput.substring(1);

  duration = parseDurationOffset(remainingInput);
  if (duration.valueOf() === 0) {
    return result;
  }

  if (duration.asMilliseconds() < 0) {
    if (anchorNow) {
      result.start = moment.utc(nowReference).add(duration);
      result.end = moment.utc(nowReference);
    } else {
      result.start = moment.utc(endReference).add(duration);
      result.end = moment.utc(endReference);
    }
  } else {
    if (anchorNow) {
      result.start = moment.utc(nowReference);
      result.end = moment.utc(nowReference).add(duration);
    } else {
      result.start = moment.utc(startReference);
      result.end = moment.utc(startReference).add(duration);
    }
  }

  return result;
}

/**
 * Creates a frontend duration that has a "units" property in place of the  "uom" property supplied by the backend
 *
 * @param duration - a backend duration
 * @returns a frontend duration
 */
export function convertDuration(duration: BackendDuration): FrontendDuration {
  // If undefined is passed then return undefined instead of an object with undefined properties
  if (_.isUndefined(duration)) {
    return duration;
  }

  return {
    value: _.get(duration, 'value'),
    units: _.get(duration, 'uom'),
  };
}

/**
 * Parses a string representing a relative date/time into a moment object. The string is a representation
 * of an offset from a reference, such as "+2mo" for adding two months to a reference date. Any duration
 * string supported by {@link parseDuration} can be used. Note that the first character of the string must
 * be one of the following: *$+-.
 *
 * If the first character is '*', then the reference date is nowReference.
 * If the first character is '$', then the reference date is otherReference.
 * If the first character is neither '*' nor '$', then the reference date is selfReference.
 *
 * @example '+2mo' === moment(selfReference).add(2, 'months')
 * @example '*-2d' === moment(nowReference).subtract(2, 'days')
 * @example '$+1.5h' === moment(otherReference).add(1.5, 'hours')
 * @example '*' === moment(nowReference)
 *
 * @param input - Input string to be parsed
 * @param nowReference - Reference to use if '*' starts the input string
 * @param [selfReference] - Reference to use if neither '*' nor '$' start input string
 * @param [otherReference] - Reference to use if '$' starts the input string
 * @return The resulting Moment object.
 */
export function parseRelativeDate(
  input: string,
  nowReference: Moment,
  selfReference?: Moment,
  otherReference?: Moment,
): Moment {
  let multiplier;
  let remainingInput = input.replace(/ /g, '');
  let valid = false;
  let reference = selfReference; // default to 'self'
  let duration = moment.duration(0);

  // look at leading character to see if there is a reference anchor: * or $
  if (remainingInput[0] === '*') {
    reference = nowReference;
    remainingInput = remainingInput.substring(1);
    valid = true;
  } else if (remainingInput[0] === '$') {
    reference = otherReference;
    remainingInput = remainingInput.substring(1);
    valid = true;
  }

  // look for leading + or -
  if (remainingInput[0] === '-') {
    multiplier = -1;
    remainingInput = remainingInput.substring(1);
    valid = true;
  } else if (remainingInput[0] === '+') {
    multiplier = 1;
    remainingInput = remainingInput.substring(1);
    valid = true;
  }

  if (!valid || _.isUndefined(reference) || !reference.isValid()) {
    return moment.invalid();
  }

  // Parse remaining string as duration
  if (remainingInput) {
    duration = parseDuration(remainingInput);
    if (duration.valueOf() === 0) {
      return moment.invalid();
    }
  }

  // Add to reference
  return moment(reference).add(duration.asMilliseconds() * multiplier, 'ms');
}

/**
 * Takes a start and end time and calculates new start/end times that inflate the time range by a specified
 * percentage.
 *
 * @param start - Start time. Can be either a moment object or Unix offset in milliseconds.
 * @param end - End time. Can be either a moment object or Unix offset in milliseconds.
 * @param inflation - Amount to inflate the range, where 1.0 specifies a 100% inflation factor and
 *   the resulting start/end time range would be twice the size of the input start/end times.
 *   An inflation of 0.0 would return the same start/end times it was passed.
 * @return Returns an object containing the new start and end times.
 */
export function inflateTimes(
  start: Moment,
  end: Moment,
  inflation: number,
): {
  start: Moment;
  end: Moment;
} {
  // Calculate the duration between start and end, then determine the amount of inflation on either side
  const inflationMilliseconds = moment.utc(end).diff(moment.utc(start)) * (inflation / 2.0);
  return {
    start: moment(start).subtract(inflationMilliseconds, 'milliseconds'),
    end: moment(end).add(inflationMilliseconds, 'milliseconds'),
  };
}

/**
 * Translates the duration into a human readable form.
 *
 * @example
 *  var duration = 60 *  60 * 1000; // One hour
 *  formatDuration(duration); // returns '01:00:00.000'
 *  formatDuration(duration, true); // returns '1h'
 * @param duration - The duration in milliseconds to be formatted to a human readable string
 * @param [simplify] - Minimize the footprint by removing unnecessary zeros, defaults to false
 * @return Returns the formatted duration in `M d HH:mm:ss.SSS` (or simpler)
 */
export function formatDuration(duration: number, simplify = false): string {
  let returnValue;
  let tempReturnValue;
  let tempStringArray;
  let tempStringArrayLength;
  const second = 1000;
  const minute = 60 * second;
  const hour = 60 * minute;
  let positive = true;
  const negString = '-';

  if (!_.isNumber(duration)) {
    return '';
  }

  if (duration < 0) {
    positive = false;
    duration = Math.abs(duration);
  }

  tempReturnValue = moment.duration(duration).format('M d HH:mm:ss.SSS');
  tempStringArray = tempReturnValue.split(' ');
  tempStringArrayLength = tempStringArray.length;
  switch (tempStringArrayLength) {
    case 1:
      returnValue = tempStringArray[0];
      break;
    case 2:
      returnValue = `${tempStringArray[0] + i18next.t('DURATIONS.DAYS')} ${tempStringArray[1]}`;
      break;
    case 3:
      returnValue = `${tempStringArray[0] + i18next.t('DURATIONS.MONTHS')} ${tempStringArray[1]}${i18next.t(
        'DURATIONS.DAYS',
      )} ${tempStringArray[2]}`;
      break;
    default:
      returnValue = tempReturnValue;
  }

  // Pad with zeros as needed so that the units are clear
  if (duration < second) {
    returnValue = `0.${returnValue}`; // return '0.SSS'
  } else if (duration < 10 * second) {
    returnValue = `0${returnValue}`; // return 'ss.SSS'
  } else if (duration >= minute && duration < 10 * minute) {
    returnValue = `00:0${returnValue}`; // return 'HH:mm:ss.SSS'
  } else if (duration >= 10 * minute && duration < hour) {
    returnValue = `00:${returnValue}`; // return 'HH:mm:ss.SSS'
  } else if (duration >= hour && duration < 10 * hour) {
    returnValue = `0${returnValue}`; // return 'HH:mm:ss.SSS'
  }

  // Do simplifying, remove extra zeros to improve readability (used for formatting axes)
  if (!_.isUndefined(simplify) && simplify === true) {
    if (returnValue.substr(-4, 4) === '.000' && duration >= minute) {
      returnValue = returnValue.slice(0, -4);
    }

    if (returnValue.substr(-3, 3) === ':00') {
      returnValue = returnValue.slice(0, -3);
    }

    if (returnValue.substr(-3, 3) === ':00') {
      returnValue = returnValue.slice(0, -3) + i18next.t('DURATIONS.HOURS');
    }

    if (returnValue.substr(-3, 3) === '00h') {
      returnValue = returnValue.slice(0, -4);
    }

    if (returnValue.substr(0, 1) === '0' && duration >= hour) {
      returnValue = returnValue.slice(1);
    }
  }

  if (!positive) {
    returnValue = negString.concat(returnValue);
  }

  return returnValue;
}

/**
 * Formats a duration as a string with the most relevant units based on the length of the duration.
 * The resulting string will be in the most relevant unit and have up to one decimal digit.
 * See input examples in the description of {@code getValueAndUnits}

 * @param duration - Duration to format
 * @return Formatted string
 */
export function formatSimpleDuration(duration: Duration): string {
  const { value, translationKey } = getValueAndUnits(duration);
  const formattedUnits = i18next.t(translationKey, {
    count: _.toNumber(value),
  });
  return `${value} ${formattedUnits}`;
}

/**
 * Finds the value and the most relevant units based on the length of the duration.
 * The resulting value will be in the most relevant unit and have up to one decimal digit.
 *
 * (Some examples use ISO8601 Duration notation, for brevity. See: https://en.wikipedia.org/wiki/ISO_8601#Durations)
 *
 * @example
 * '00:00:30' -> '30 seconds'
 * '00:01:00' -> '1 minute'
 * 'P2DT22H' -> '2.9 days'
 * 'P1M15D' -> '1.5 months'
 * 'P11M28D' -> '11.9 months'
 * 'P1Y' -> '1 year'
 * 'P1Y7M' -> '1.6 years'
 *
 * @param duration - Duration to format
 * @returns value, units and translation key
 */
export function getValueAndUnits(duration: Duration): {
  value: number;
  units: string;
  translationKey: string;
} {
  const inputValue = duration.valueOf();
  let formatUnitToken = 'seconds';
  let translationKey = 'UNITS.SECONDS';
  let units = _.find(DURATION_TIME_UNITS_ALL, { momentUnit: 's' }).unit[0];

  const breakpoints = [
    {
      value: moment.duration(0.95, 'minutes').as('ms'),
      units: _.find(DURATION_TIME_UNITS_ALL, { momentUnit: 'm' }).unit[0],
      token: 'minutes',
      translationKey: 'UNITS.MINUTES',
    },
    {
      value: moment.duration(0.95, 'hours').as('ms'),
      units: _.find(DURATION_TIME_UNITS_ALL, { momentUnit: 'h' }).unit[0],
      token: 'hours',
      translationKey: 'UNITS.HOURS',
    },
    {
      value: moment.duration(0.95, 'days').as('ms'),
      units: _.find(DURATION_TIME_UNITS_ALL, { momentUnit: 'd' }).unit[0],
      token: 'days',
      translationKey: 'UNITS.DAYS',
    },
    {
      value: moment.duration(0.95, 'months').as('ms'),
      units: _.find(DURATION_TIME_UNITS_ALL, { momentUnit: 'M' }).unit[0],
      token: 'months',
      translationKey: 'UNITS.MONTHS',
    },
    {
      value: moment.duration(11.95, 'months').as('ms'),
      units: _.find(DURATION_TIME_UNITS_ALL, { momentUnit: 'y' }).unit[0],
      token: 'years',
      translationKey: 'UNITS.YEARS',
    },
  ];

  _.forEach(breakpoints, (breakpoint) => {
    if (inputValue < breakpoint.value) {
      return false;
    } else if (inputValue === breakpoint.value) {
      formatUnitToken = breakpoint.token;
      translationKey = breakpoint.translationKey;
      return false;
    }

    formatUnitToken = breakpoint.token;
    translationKey = breakpoint.translationKey;
    units = breakpoint.units;
  });

  let value = moment.duration(duration).as(formatUnitToken).toFixed(1);
  if (value.slice(-2) === '.0') {
    value = value.substring(0, value.length - 2);
  }

  return { value, units, translationKey };
}

/**
 * Call this function to determine if dates should be displayed with month before
 * day, or day before month.
 *
 * @returns True if month should be displayed before day; false if day
 * should be displayed before month.
 */
export function displayMonthDay(): boolean {
  const localizedMedDateFormat = moment.localeData().longDateFormat('ll');
  const lowerCaseLDF = localizedMedDateFormat.toLowerCase();
  return lowerCaseLDF.indexOf('d') !== 0;
}

/**
 * Formats time in a localized format with the selected timezone.
 *
 * @param time - The time to be formatted, in milliseconds or as a moment.js object
 * @param selectedTimezone - The selected timezone.
 * @param [format] - A magical moment.js formatting string
 * @returns The localized date.
 */
export function formatTime(time: Number | Moment | Date, selectedTimezone: { name: string }, format = 'lll'): string {
  if (_.isNil(time)) {
    return '';
  } else {
    return moment(time).tz(selectedTimezone.name).format(format);
  }
}

/**
 * Determines if two time ranges overlap one another, inclusive of start and ends.
 *
 * @param {Object} range1 - The first time range
 * @param {number} range1.startTime - The start of the range
 * @param {number} range1.endTime - The end of the range
 * @param {Object} range2 - The other time range
 * @param {number} range2.startTime - The start of the range
 * @param {number} range2.endTime - The end of the range
 */
export function overlaps(range1, range2) {
  return range1.startTime < range2.endTime && range1.endTime > range2.startTime;
}

/**
 * Creates a capsule formula from a time range
 *
 * @param range - Time range over which to request the data
 * @param range.start - Start of the range
 * @param range.end - End of the range
 */
export function getCapsuleFormula(range: { start: Moment | number; end: Moment | number }): string {
  return `capsule("${moment.utc(range.start).toISOString()}", "${moment.utc(range.end).toISOString()}")`;
}

/**
 * Splits a duration string (e.g. '7h') into an object with value and units (e.g. { value: 7, units: 'h' })
 *
 * @param durationString - the string to be split
 * @returns a value duration object or undefined if a value
 *   and units could not be determined from the supplied string.
 */
export function splitDuration(durationString): { value: number; units: string } | undefined {
  let value, units;

  if (_.isString(durationString)) {
    value = _.get(durationString.match(/[-+]?[0-9]*\.?[0-9]+/), '[0]');

    if (value) {
      units = _.get(durationString.match(/[a-zA-Z]+/), '[0]');

      if (units) {
        return { value: +value, units };
      }
    }
  }
}

/**
 * Determines if a 12 hour clock should be displayed, based on moment's locale.
 *
 * @return True if it should display a 12 hour clock, false otherwise
 */
export function display12HrClock(): boolean {
  return _.last(moment.localeData().longDateFormat('LT')) === 'A';
}

/**
 * Converts the supplied interval into a more readable duration display (e.g "5 seconds" truncated to whole seconds,
 * instead of the millisecond equivalent)
 * Milliseconds that equal 1 minute and 20 seconds, for example, get displayed as 1 minute
 * Milliseconds that equal 1 minute and 40 seconds, for example, get displayed as 2 minutes
 *
 * @param interval - an interval in milliseconds
 * @returns a human readable string
 */
export function humanizeInterval(interval: number): string {
  const duration = moment.duration(interval);
  if (duration.asSeconds() < 60) {
    return `${duration.seconds()} ${i18next.t('UNITS.SECONDS', {
      count: _.toNumber(duration.seconds()),
    })}`;
  }
  if (duration.asMinutes() >= 1 && duration.asHours() < 1) {
    const roundUp = duration.seconds() >= 30;
    return `${roundUp ? duration.minutes() + 1 : duration.minutes()} ${i18next.t('UNITS.MINUTES', {
      count: roundUp ? _.toNumber(duration.minutes()) + 1 : _.toNumber(duration.minutes()),
    })}`;
  }
  if (duration.asHours() >= 1 && duration.asDays() < 1) {
    const roundUp = duration.minutes() >= 30;
    return `${roundUp ? duration.hours() + 1 : duration.hours()} ${i18next.t('UNITS.HOURS', {
      count: roundUp ? _.toNumber(duration.hours()) + 1 : _.toNumber(duration.hours()),
    })}`;
  }
  if (duration.asDays() >= 1 && duration.asMonths() < 1) {
    const roundUp = duration.hours() >= 12;
    return `${roundUp ? duration.days() + 1 : duration.days()} ${i18next.t('UNITS.DAYS', {
      count: roundUp ? _.toNumber(duration.days()) + 1 : _.toNumber(duration.days()),
    })}`;
  }
  if (duration.asMonths() >= 1 && duration.asYears() < 1) {
    const roundUp = duration.days() >= 15;
    return `${roundUp ? duration.months() + 1 : duration.months()} ${i18next.t('UNITS.MONTHS', {
      count: roundUp ? _.toNumber(duration.months()) + 1 : _.toNumber(duration.months()),
    })}`;
  }
  if (duration.asYears() >= 1) {
    const roundUp = duration.months() >= 6;
    return `${roundUp ? duration.years() + 1 : duration.years()} ${i18next.t('UNITS.YEARS', {
      count: roundUp ? _.toNumber(duration.years()) + 1 : _.toNumber(duration.years()),
    })}`;
  }
}

/**
 * Replaces the 'Z' at the end of a time string with '-0000'
 *
 * @param time - a time string
 * @returns a time string with 'Z' replaced if it was present
 */
export function reformatZuluOffset(time: string): string {
  return time.replace(/[zZ]$/, '-0000');
}

/**
 * Converts our units to moment time units
 *
 * @param inputUnit - a unit from `timeUnits` (any of the aliases)
 * @param [timeUnits] - an array of units in the format of `DURATION_TIME_UNITS`
 * @returns the moment measurement unit
 */
export function momentMeasurementStrings(
  inputUnit: string,
  timeUnits = DURATION_TIME_UNITS_ALL,
): moment.DurationConstructor | string {
  const momentUnit = _.get(getDurationTimeUnit(inputUnit, timeUnits), 'momentUnit');
  if (!_.isNil(momentUnit)) {
    return momentUnit;
  } else if (inputUnit === 'ms') {
    return inputUnit;
  } else if (!_.isNil(inputUnit)) {
    // inputUnit is sometimes null when the low pass filter panel is unloading
    throw new Error(`Unexpected unit, ${inputUnit}, without a moment duration equivalent`);
  }
}

/**
 * Finds the matching time unit from `timeUnits`.
 *
 * @param inputUnit - a unit from `timeUnits` (any of the aliases)
 * @param [timeUnits] - an array of units in the format of `DURATION_TIME_UNITS`
 * @returns one of the `timeUnits` or undefined if a match was not found
 */
export function getDurationTimeUnit(
  inputUnit: string,
  timeUnits = DURATION_TIME_UNITS_ALL,
): DurationTimeUnit | undefined {
  return _.find(timeUnits, ({ unit }) => _.includes(unit, inputUnit));
}

/**
 * Converts the period to the required units - does not convert frequencies
 *
 * @param period  The period to be converted
 * @param toUnits The desired units
 * @param fromUnits The current units, defaults to ms if not provided
 * @returns The period in the desired units
 */
export function updateUnits(period: number, toUnits: string, fromUnits: string): FrontendDuration {
  let duration;

  if (_.isUndefined(fromUnits)) {
    fromUnits = 'ms';
  }

  if (isFrequency(fromUnits) || isFrequency(toUnits)) {
    return {
      value: period,
      units: fromUnits,
    };
  }

  duration = moment.duration(period, momentMeasurementStrings(fromUnits));
  return {
    value: duration.as(momentMeasurementStrings(toUnits)),
    units: toUnits,
  };
}

/**
 * Determine the ideal units for display purposes
 *
 * @param duration The period being displayed in milliseconds
 * @returns The value and units to display
 */
export function determineIdealUnits(duration: FrontendDuration): FrontendDuration {
  if (!isFrequency(duration.units)) {
    const period = updateUnits(duration.value, 'ms', duration.units);

    if (period.value >= moment.duration(1, 'd').asMilliseconds()) {
      return {
        value: moment.duration(period.value).asDays(),
        units: 'day',
      };
    } else if (period.value >= moment.duration(1, 'h').asMilliseconds()) {
      return {
        value: moment.duration(period.value).asHours(),
        units: 'h',
      };
    } else if (period.value >= moment.duration(1, 'm').asMilliseconds()) {
      return {
        value: moment.duration(period.value).asMinutes(),
        units: 'min',
      };
    } else {
      return {
        value: moment.duration(period.value).asSeconds(),
        units: 's',
      };
    }
  } else {
    const frequencyDuration = convertToFrequencyPerDay(duration);

    if (frequencyDuration.value >= S_PER_DAY) {
      return {
        value: frequencyDuration.value / S_PER_DAY,
        units: 'Hz',
      };
    } else if (frequencyDuration.value >= MIN_PER_DAY) {
      return {
        value: frequencyDuration.value / MIN_PER_DAY,
        units: '/min',
      };
    } else if (frequencyDuration.value >= H_PER_DAY) {
      return {
        value: frequencyDuration.value / H_PER_DAY,
        units: '/h',
      };
    } else {
      return frequencyDuration;
    }
  }
}

/**
 * Determine if the unit is a frequency
 *
 * @param unit The unit to be tested
 * @returns Whether or not the unit is a frequency
 */
export function isFrequency(unit: string): boolean {
  return unit === 'Hz' || unit === '/min' || unit === '/h' || unit === '/day';
}

/**
 * Convert to frequency per day
 *
 * @param frequency - the frequency to convert
 * @returns the frequency per day
 */
export function convertToFrequencyPerDay(frequency: FrontendDuration): FrontendDuration {
  let frequencyDuration;

  if (frequency.units === 'Hz') {
    frequencyDuration = {
      value: frequency.value * S_PER_DAY,
      units: '/day',
    };
  } else if (frequency.units === '/min') {
    frequencyDuration = {
      value: frequency.value * MIN_PER_DAY,
      units: '/day',
    };
  } else if (frequency.units === '/h') {
    frequencyDuration = {
      value: frequency.value * H_PER_DAY,
      units: '/day',
    };
  } else {
    frequencyDuration = frequency;
  }

  return frequencyDuration;
}

/**
 * Convert a frequency to a period
 * @param frequency - the frequency to convert
 * @returns the period object
 */
export function convertFrequencyToPeriod(frequency: FrontendDuration): FrontendDuration {
  const newValue = _.isUndefined(frequency.value) ? undefined : 1 / frequency.value;
  let newUnits;
  if (_.isUndefined(frequency.units)) {
    newUnits = undefined;
  } else {
    newUnits = frequency.units === 'Hz' ? 's' : frequency.units.slice(1);
  }

  return {
    value: newValue,
    units: newUnits,
  };
}

/**
 * Convert a frequency in /min, /h, /day to Hz
 * @param frequency - the frequency to convert
 * @returns the frequency object with units of Hz
 */
export function convertFrequencyToHertz(frequency: FrontendDuration): FrontendDuration {
  if (_.isUndefined(frequency.units) || _.isUndefined(frequency.value)) {
    return frequency;
  }

  let value;
  let units = 'Hz';
  switch (frequency.units) {
    case '/min':
      value = frequency.value / S_PER_MIN;
      break;
    case '/h':
      value = frequency.value / S_PER_H;
      break;
    case '/day':
      value = frequency.value / S_PER_DAY;
      break;
    default:
      value = frequency.value;
      units = frequency.units;
      break;
  }
  return { value, units };
}

/**
 * Convert a period to a frequency
 * @param period - the period to convert
 * @returns the frequency object
 */
export function convertPeriodToFrequency(period: FrontendDuration): FrontendDuration {
  const newValue = _.isUndefined(period.value) ? undefined : 1 / period.value;
  let newUnits;
  if (_.isUndefined(period.units)) {
    newUnits = undefined;
  } else {
    newUnits = period.units === 's' ? 'Hz' : `/${period.units}`;
  }

  return {
    value: newValue,
    units: newUnits,
  };
}

/**
 * Converts time from 24 hour to locale-based time
 *
 * @param time - time value from time input (HH:mm, 24 hour)
 * @param fromTimezone - from moment timezone
 * @param toTimezone - to moment timezone
 * @param [padHour] - if true, will ensure that the hour is 2 digits
 * @returns the time in the locale of the specified timezone
 */
export function convert24HourTimeToLocaleTime(
  time: string,
  fromTimezone: string,
  toTimezone: string,
  padHour = false,
): string {
  const localeTime = formatTime(time24HourToMoment(time, fromTimezone), { name: toTimezone }, 'LT');
  if (!padHour || localeTime.match(/\d\d:\d\d/)) {
    return localeTime;
  }

  return `0${localeTime}`;
}

/**
 * Adjust time input to selected time zone
 *
 * @param time - time value from time input (HH:mm, 24 hour)
 * @param fromTimezone - from moment timezone
 * @param toTimezone - to moment timezone
 * @returns the time input adjusted to specified timezone (HH:mm, 24 hour)
 */
export function adjust24HourTimeToTimezone(time: string, fromTimezone: string, toTimezone: string): string {
  return formatTime(time24HourToMoment(time, fromTimezone), { name: toTimezone }, TIME_24_HOUR_FORMAT);
}

/**
 * Convert from time input to a Moment object
 *
 * @param time - time value from time input (HH:mm, 24 hour)
 * @param timezone - name of moment timezone
 * @returns the moment object
 */
export function time24HourToMoment(time: string, timezone: string): Moment {
  return moment.tz(time, TIME_24_HOUR_FORMAT, timezone);
}

export function attemptDateParse({ date, otherDate, fieldIdentifier, newDate, timezone, updateBothDates, updateDate }) {
  let parseResult, isStart;
  const origDate = date;
  const origPair = otherDate;
  let twoDateParseMethods;
  const singleDateParseMethods = [
    {
      name: 'parseISODate',
      method: (input) => parseISODate(input, timezone.name),
    },
    {
      name: 'parseLocaleDate',
      method: (input) => parseLocaleDate(input, timezone.name, origDate),
    },
    {
      name: 'parseRelativeDate',
      method: (input) => parseRelativeDate(input, moment.utc(), origDate, otherDate),
    },
  ];

  let successfulMethod = '';
  const trimmedInput = _.trim(newDate);

  // If we have two dates, initiate the two-date parsing methods for testing below
  if (otherDate && otherDate.isValid()) {
    isStart = date.isBefore(otherDate);

    twoDateParseMethods = [
      {
        name: 'parseDurationIntoDates',
        method: (input) =>
          parseDurationIntoDates(input, moment.utc(), isStart ? origDate : origPair, isStart ? origPair : origDate),
      },
    ];
  }

  // Try the two-date parsing methods
  _.forEach(twoDateParseMethods, (parseAttempt: any) => {
    parseResult = parseAttempt.method(trimmedInput);
    if (parseResult?.start?.isValid() && parseResult?.end?.isValid()) {
      updateBothDates(parseResult.start, parseResult.end);
      successfulMethod = parseAttempt.name;
      dateParse(
        fieldIdentifier,
        origDate,
        origPair,
        newDate,
        isStart ? parseResult.start : parseResult.end,
        isStart ? parseResult.end : parseResult.start,
        successfulMethod,
        timezone,
      );
      return false;
    }
  });

  // Try the single date parsing methods
  if (!successfulMethod) {
    _.forEach(singleDateParseMethods, (parseAttempt: any) => {
      parseResult = parseAttempt.method(trimmedInput);
      if (parseResult?.isValid()) {
        updateDate(parseResult);
        successfulMethod = parseAttempt.name;
        dateParse(fieldIdentifier, origDate, origPair, newDate, parseResult, undefined, successfulMethod, timezone);
        return false;
      }
    });
  }

  if (!successfulMethod) {
    dateParse(fieldIdentifier, origDate, origPair, newDate, parseResult, undefined, successfulMethod, timezone);
  }

  return successfulMethod;
}

/**
 * Creates a custom time format object that is used to control axis scale time formatting.
 * @returns A custom time format
 */
export function getCustomTimeFormat(): {
  millisecond: string;
  second: string;
  minute: string;
  hour: string;
  day: string;
  week: string;
  month: string;
  year: string;
} {
  const formats = {
    millisecond: '%l:%M:%S.%L %P',
    second: '%l:%M:%S %P',
    minute: '%l:%M %P',
    hour: '%l:%M %P',
    day: '%b %e',
    week: '%b %e',
    month: "%b '%y",
    year: '%Y',
  };

  if (!displayMonthDay()) {
    formats.day = '%e. %b';
    formats.week = '%e. %b';
  }

  if (!display12HrClock()) {
    formats.millisecond = '%H:%M:%S.%L';
    formats.second = '%H:%M:%S';
    formats.minute = '%H:%M';
    formats.hour = '%H:%M';
  }

  return formats;
}

export function getTimezoneOffset(timestamp: number, timezone: { name: string }): number {
  if (timezone) {
    return -moment.tz(timestamp, timezone.name).utcOffset();
  } else {
    return -moment(timestamp).utcOffset();
  }
}

/**
 * Converts nanoseconds to milliseconds. The fractional remainder is intentionally truncated to ensure that
 * this method and converting the equivalent ISO-8601 timestamp with nanoseconds returns identical results. This is
 * important since the backend can return the times as either an integer of nanoseconds or a ISO-8601 timestamp and
 * there are places that rely on such output being converted uniformly (e.g. CRAB-25869).
 *
 * @param nanos - The time in nanoseconds
 * @return The time in milliseconds, as an integer
 */
export function nanosToMillis(nanos: number): number {
  return Math.floor(nanos / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND);
}

export interface SerializedRange {
  start: number;
  end: number;
}

export function deserializeRange(range: SerializedRange): RangeExport {
  return {
    start: moment.utc(range.start),
    end: moment.utc(range.end),
    duration: moment.duration(range.end - range.start),
  };
}

export function serializeRange(range: RangeExport): SerializedRange {
  return {
    start: range.start.valueOf(),
    end: range.end.valueOf(),
  };
}

/**
 * Format a date
 *
 * @param timestamp - The timestamp
 * @return The formatted date
 */
export function formatDateWorkbook(timestamp: number): string {
  return moment(timestamp).format('l LT');
}
