// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { sqAgentsApi } from '@/sdk';
import _ from 'lodash';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';
import i18next from 'i18next';

interface StageVersionModalProps {
  onClose: () => void;
}

export const StageVersionModal: React.FunctionComponent<StageVersionModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [installer, setInstaller] = useState(undefined);
  const [displayInstallers, setDisplayInstallers] = useState([]);

  const formatInstallers = (installers) =>
    _.map(installers, (installer) => {
      return {
        text: installer.flag.includes('LOW_DISK_SPACE')
          ? i18next.t(`ADMIN.AGENTS.${installer.flag}`, {
              installerName: installer.marketingName,
            })
          : installer.marketingName,
        value: installer.name,
        isDisabled: installer.flag.includes('LOW_DISK_SPACE'),
        downloadFlag: installer.flag.includes('RETRIEVABLE'),
      };
    });

  useEffect(() => {
    sqAgentsApi
      .configuredDirectives()
      .then(({ data }) => {
        setDisplayInstallers(formatInstallers(data.installers));
        setInstaller(data.stageInstaller);
      })
      .catch((error) => errorToast({ httpResponseOrError: error }));
  }, [setDisplayInstallers]);

  const updateStageVersion = (data) => {
    const directiveInput = {
      stageInstaller: data.value,
      downloadInstaller: data.downloadFlag,
    };
    return sqAgentsApi
      .updateDirective(directiveInput)
      .then(() => {
        successToast({ messageKey: 'ADMIN.AGENTS.INSTALLER_STAGED' });
        onClose();
      })
      .catch((error) => {
        errorToast({
          httpResponseOrError: error,
          messageKey: error.statusText,
        });
        onClose();
      });
  };

  const formDefinition: FormElement[] = [
    {
      key: 'nameLabel',
      component: 'LabelFormComponent',
      value: 'ADMIN.AGENTS.STAGE_VERSION_DESC',
      name: 'nameLabel',
      testId: 'labelStageVersion',
    },
    {
      key: 'nameLabel2',
      component: 'LabelFormComponent',
      value: 'ADMIN.AGENTS.STAGE_VERSION_DESC2',
      name: 'nameLabel2',
      testId: 'labelStageVersion2',
    },
    {
      key: 'nameLabel3',
      component: 'LabelFormComponent',
      value: 'ADMIN.AGENTS.STAGE_VERSION_DESC3',
      name: 'nameLabel3',
      testId: 'labelStageVersion3',
    },
    {
      key: 'selectStageVersion',
      component: 'IconSelectFormComponent',
      name: 'selectStageVersion',
      testId: 'selectStageVersion',
      placeholder: 'ADMIN.AGENTS.STAGE_VERSION',
      insideModal: true,
      selectOptions: displayInstallers,
      value: installer,
      onChange: (installer) => setInstaller(installer),
    },
  ];

  return (
    <Modal
      show={true}
      onHide={onClose}
      animation={false}
      data-testid="stageVersionModal"
      className="modalSelectFormComponent">
      <Modal.Header closeButton={true} className="flexColumnContainer flexSpaceBetween">
        <h3>{t('ADMIN.AGENTS.STAGE_VERSION_MODAL')}</h3>
      </Modal.Header>
      <Modal.Body>
        <SimpleSaveFormBuilder
          formDefinition={formDefinition}
          submitBtnLabel={t('ADMIN.AGENTS.STAGE')}
          submitFn={() => updateStageVersion(installer)}
          closeFn={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};
