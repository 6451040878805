// @ts-strict-ignore
import _ from 'lodash';

import { formatAsQueryString } from '@/hybrid/utilities/httpHelpers.utilities';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { toNumber } from '@/hybrid/utilities/numberHelper.utilities';
import { InitializeMode } from '@/core/flux.service';
import { REPORT_CONTENT, SCREENSHOT_SIZE_TO_CONTENT } from '@/reportEditor/report.constants';
import { sqReportStore, sqWorksheetStore } from '@/core/core.stores';
import { parseSummaryToTypeAndValue } from '@/hybrid/utilities/froalaReportContent.utilities';

export type ReportContentStore = ReturnType<typeof sqReportContentStore>['exports'];

export function sqReportContentStore($injector: ng.auto.IInjectorService) {
  const DEFAULT_SIZE_KEY = REPORT_CONTENT.SIZE.MEDIUM;
  const DEFAULT_SHAPE_KEY = REPORT_CONTENT.SHAPE.RECTANGLE;
  const DEFAULT_HEIGHT = Math.round((DEFAULT_SIZE_KEY.width * DEFAULT_SHAPE_KEY.height) / DEFAULT_SHAPE_KEY.width);
  const DEFAULT_WIDTH = DEFAULT_SIZE_KEY.width;

  const getShapeFromKey = (key: string) => _.find(REPORT_CONTENT.SHAPE, { key });
  const getSizeFromKey = (key: string) => _.find(REPORT_CONTENT.SIZE, { key });

  const store = {
    persistenceLevel: 'WORKSHEET',

    initialize(initializeMode?: InitializeMode) {
      const saveState = this.state && initializeMode !== 'FORCE';
      this.state = this.immutable({
        id: undefined,
        modalName: '',
        evalParamsModified: 0,
        // for new content, these params will default to values from the last loaded content
        dateRangeId: saveState ? this.state.get('dateRangeId') : undefined, // undefined === inherit date range
        assetSelectionId: saveState ? this.state.get('assetSelectionId') : undefined, // undefined === inherit selection
        width: saveState ? this.state.get('width') : DEFAULT_WIDTH,
        height: saveState ? this.state.get('height') : DEFAULT_HEIGHT,
        sizeKey: saveState ? this.state.get('sizeKey') : DEFAULT_SIZE_KEY.key,
        shapeKey: saveState ? this.state.get('shapeKey') : DEFAULT_SHAPE_KEY.key,
        scale: saveState ? this.state.get('scale') : REPORT_CONTENT.SCALE.NORMAL.key,
        useSizeFromRender: false,
        canUseReact: false,
        isReact: false,
        summary: saveState ? this.state.get('summary') : undefined, // undefined === inherit summary
        previewUrl: this.monkey(
          ['id'],
          ['worksheetId'],
          ['workstepId'],
          ['height'],
          ['width'],
          ['scale'],
          ['useSizeFromRender'],
          ['dateRangeId'],
          ['assetSelectionId'],
          ['summary'],
          (
            contentId,
            worksheetId,
            workstepId,
            height,
            width,
            scale,
            useSizeFromRender,
            dateRangeId,
            assetSelectionId,
            summary,
          ) => {
            const potentialAsset = _.find(sqReportStore.assetSelectionsNotArchived, ['id', assetSelectionId])?.asset.id;
            const params = {
              worksheetId,
              workstepId,
              height,
              width,
              scale,
              dateRangeId,
              assetId: potentialAsset,
              timezone: sqWorksheetStore.timezone?.name,
              selector: useSizeFromRender ? SCREENSHOT_SIZE_TO_CONTENT.SELECTOR : undefined,
              reportId: sqReportStore.id,
              ...parseSummaryToTypeAndValue(summary),
            };

            const queryString = formatAsQueryString(params);

            return `${APPSERVER_API_PREFIX}/content/preview?${queryString}`;
          },
        ),
        paramsModified: this.monkey(
          ['id'],
          ['workstepId'],
          ['height'],
          ['width'],
          ['scale'],
          ['useSizeFromRender'],
          ['dateRangeId'],
          ['assetSelectionId'],
          ['summary'],
          ['isReact'],
          ['evalParamsModified'],
          (
            id,
            workstepId,
            height,
            width,
            scale,
            useSizeFromRender,
            dateRangeId,
            assetSelectionId,
            summary,
            isReact,
          ) => {
            if (!id) return true;
            const savedContent = sqReportStore.getContentById(id);
            return (
              savedContent.workstepId !== workstepId ||
              (!useSizeFromRender && (savedContent.height !== height || savedContent.width !== width)) ||
              savedContent.scale !== scale ||
              savedContent.useSizeFromRender !== useSizeFromRender ||
              savedContent.dateRangeId !== dateRangeId ||
              !_.isEqual(savedContent.summary, summary) ||
              savedContent.assetSelectionId !== assetSelectionId ||
              savedContent.isReact !== isReact
            );
          },
        ),
      });
    },

    exports: {
      get id() {
        return this.state.get('id');
      },

      get name() {
        return `content_${this.state.get('worksheetId')}_${this.state.get('workstepId')}`;
      },

      get isNew() {
        return !this.state.get('id');
      },

      get workbookId() {
        return this.state.get('workbookId');
      },

      get worksheetId() {
        return this.state.get('worksheetId');
      },

      get workstepId() {
        return this.state.get('workstepId');
      },

      get workbookName() {
        return this.state.get('workbookName');
      },

      get worksheetName() {
        return this.state.get('worksheetName');
      },

      get scale() {
        return this.state.get('scale');
      },

      get width() {
        return this.state.get('width');
      },

      get height() {
        return this.state.get('height');
      },

      get summary() {
        return this.state.get('summary');
      },

      get sizeKey() {
        return this.state.get('sizeKey');
      },

      get sizeConstant() {
        return getSizeFromKey(this.state.get('sizeKey'));
      },

      get shapeKey() {
        return this.state.get('shapeKey');
      },

      get shapeConstant() {
        return getShapeFromKey(this.state.get('shapeKey'));
      },

      get dateRangeId() {
        return this.state.get('dateRangeId');
      },

      get assetSelectionId() {
        return this.state.get('assetSelectionId');
      },

      get useSizeFromRender() {
        return this.state.get('useSizeFromRender');
      },

      get canUseReact() {
        return this.state.get('canUseReact');
      },

      get isReact() {
        return this.state.get('isReact');
      },

      get dateRange() {
        return _.find(sqReportStore.dateRanges, ['id', this.state.get('dateRangeId')]);
      },

      get paramsModified() {
        return this.state.get('paramsModified');
      },

      get autoUpdate() {
        return _.get(_.find(sqReportStore.dateRanges, ['id', this.state.get('dateRangeId')]), 'auto.enabled');
      },

      get previewUrl() {
        return this.state.get('previewUrl');
      },

      get sourceUrl() {
        return this.state.get('sourceUrl');
      },

      get modalName() {
        return this.state.get('modalName');
      },
    },

    dehydrate() {
      return {};
    },

    rehydrate() {
      // CRAB-23743: No-ops to prevent errant state persisting between worksheet duplication
    },

    handlers: {
      REPORT_CONTENT_CLEAR: 'clear',
      REPORT_CONTENT_SET_CONTENT_ID: 'setContentId',
      REPORT_CONTENT_SET_WORKBOOK_ID: 'setWorkbookId',
      REPORT_CONTENT_SET_WORKSHEET_ID: 'setWorksheetId',
      REPORT_CONTENT_SET_WORKSTEP_ID: 'setWorkstepId',
      REPORT_CONTENT_SET_WIDTH: 'setWidth',
      REPORT_CONTENT_SET_HEIGHT: 'setHeight',
      REPORT_CONTENT_SET_SIZE_KEY: 'setSizeKey',
      REPORT_CONTENT_SET_SHAPE_KEY: 'setShapeKey',
      REPORT_CONTENT_SET_SCALE: 'setScale',
      REPORT_CONTENT_SET_DATE_RANGE_ID: 'setDateRangeId',
      REPORT_SET_CONTENT: 'evalParamsModified',
      REPORT_CONTENT_SET_SOURCE_URL: 'setSourceUrl',
      REPORT_CONTENT_SET_USE_SIZE_FROM_RENDER: 'setUseSizeFromRender',
      REPORT_CONTENT_SET_CAN_USE_REACT: 'setCanUseReact',
      REPORT_CONTENT_SET_IS_REACT: 'setIsReact',
      REPORT_CONTENT_SET_TABLE_FILTER: 'setTableFilter',
      REPORT_CONTENT_SET_ITEM_TOTAL_FOR_TABLE: 'setItemTotalForTable',
      REPORT_CONTENT_SET_PAGE_SIZE: 'setPageSize',
      REPORT_CONTENT_SET_PAGE_NUMBER: 'setPageNumber',
      REPORT_CONTENT_SET_SEARCH_PARAMS: 'setSearchParams',
      REPORT_CONTENT_SET_ITEMS: 'setItems',
      REPORT_CONTENT_SET_LOADING: 'setLoading',
      REPORT_CONTENT_SET_FOLDER: 'setFolder',
      REPORT_CONTENT_SET_SORT: 'setSort',
      REPORT_CONTENT_SET_SHOW_RESULTS: 'setShowResults',
      REPORT_CONTENT_SET_SUMMARY: 'setSummary',
      REPORT_CONTENT_SET_ASSET_SELECTION_ID: 'setAssetSelectionId',
      REPORT_SET_DATE_RANGE: 'setDateRange',
      REPORT_CONTENT_SET_MODAL_NAME: 'setModalName',
    },

    /**
     * Clears the state for the currently loaded seeq content image.
     */
    clear() {
      this.initialize();
    },

    /**
     * Sets the sort field and direction that controls the sorting for workbooks.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.workbooksSortField - One of WORKBOOKS_SORT_FIELDS
     * @param {Boolean} payload.workbooksSortAsc - True to sort ascending, false otherwise
     */
    setWorkbooksSort(payload) {
      this.state.merge(_.pick(payload, ['workbooksSortField', 'workbooksSortAsc']));
    },

    /**
     * @param {string} id - ID of the content. May be empty if content is new and hasn't been saved to backend yet
     */
    setContentId(id) {
      this.state.set('id', id);
    },

    /**
     * @param {string} workbookId - workbook ID of the content
     */
    setWorkbookId(workbookId) {
      this.state.set('workbookId', workbookId);
    },

    /**
     * @param {string} worksheetId - worksheet ID of the content
     */
    setWorksheetId(worksheetId) {
      this.state.set('worksheetId', worksheetId);
    },

    /**
     * @param {string} workstepId - workstep ID of the content
     */
    setWorkstepId(workstepId) {
      this.state.set('workstepId', workstepId);
    },

    /**
     * @param {Number} width - width of the content
     */
    setWidth(width) {
      this.state.set('width', toNumber(width));
    },

    /**
     * Sets the scale setting of the content
     *
     * @param {Object} payload - Object container
     * @param {String} payload.scale - a REPORT_CONTENT SCALE key
     */
    setScale(payload) {
      const scale = toNumber(payload.scale);
      if (_.chain(REPORT_CONTENT.SCALE).values().map('key').includes(scale).value()) {
        this.state.set('scale', scale);
      }
    },

    /**
     * @param {Number} height - height of the content
     */
    setHeight(height) {
      this.state.set('height', toNumber(height));
    },

    /**
     * @param {string} sizeKey - Size key for the content size, from REPORT_CONTENT.SIZE objects
     */
    setSizeKey(sizeKey: string) {
      this.state.set('sizeKey', sizeKey);
    },

    /**
     * @param {string} shapeKey - Shape key for the content, from REPORT_CONTENT.SHAPE objects
     */
    setShapeKey(shapeKey) {
      this.state.set('shapeKey', shapeKey);
    },

    /**
     * @param {string} dateRangeId - id of the dateRange used for the content
     */
    setDateRangeId(dateRangeId) {
      this.state.set('dateRangeId', dateRangeId ? dateRangeId : undefined);
    },

    /**
     * @param id - id used for asset selection used for the content
     */
    setAssetSelectionId(id) {
      this.state.set('assetSelectionId', id ? id : undefined);
    },

    /**
     * Recalculate .paramsModified based on something happening outside this store, i.e. content
     * saved and updated in sqReportStore.
     */
    evalParamsModified() {
      const count = this.state.get('evalParamsModified');
      this.state.set('evalParamsModified', count + 1);
    },

    /**
     * Sets the URL to the source worksheet, with any override parameters specified (e.g. date ranges)
     *
     * @param {string} sourceUrl - URL for the source of this content, i.e. worksheet w/workstep
     */
    setSourceUrl(sourceUrl) {
      this.state.set('sourceUrl', sourceUrl);
    },

    /**
     * @param {Boolean} useSizeFromRender - if true the content size is determined by the size of the rendered content,
     *  and not any user-specified size/shape parameters
     */
    setUseSizeFromRender(useSizeFromRender) {
      const previousValue = this.state.get('useSizeFromRender');
      if (previousValue && !useSizeFromRender) {
        // When switching from true to false, reset size/shape to defaults
        this.state.set('height', DEFAULT_HEIGHT);
        this.state.set('width', DEFAULT_WIDTH);
        this.state.set('sizeKey', DEFAULT_SIZE_KEY.key);
        this.state.set('shapeKey', DEFAULT_SHAPE_KEY.key);
      }
      this.state.set('useSizeFromRender', !!useSizeFromRender);
    },

    setCanUseReact(canUseReact: boolean) {
      this.state.set('canUseReact', canUseReact);
    },

    setIsReact(isReact: boolean) {
      this.state.set('isReact', isReact);
    },

    setDateRange(dateRange) {
      if (dateRange.isArchived && dateRange.id === this.state.get('dateRangeId')) {
        this.state.set('dateRangeId', undefined);
      }
    },

    setSummary({ summary }) {
      this.state.set('summary', summary);
    },

    setModalName(modalName) {
      this.state.set('modalName', modalName);
    },
  };
  return store;
}
