// @ts-strict-ignore
import { CopyButtonGivenText } from '@/hybrid/core/CopyButtonGivenText.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { Table } from '@/hybrid/core/Table.atom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { generateDefaultSortValues, ModelTableData } from './prediction.utilities';

interface PredictionModelProps {
  disabled?: boolean;
  model: ModelTableData;
  coefficientClipboardText: string;
  statisticsClipboardText: string;
  modelExpanded: boolean;
  onToggleExpand: (expanded: boolean) => void;
  gettingPredictionModel: boolean;
}

export const PredictionModel = ({
  disabled,
  model,
  coefficientClipboardText,
  statisticsClipboardText,
  modelExpanded,
  onToggleExpand,
  gettingPredictionModel,
}: PredictionModelProps) => {
  const { t } = useTranslation();

  const [modelItems, setModelItems] = useState(
    model
      ? {
          legend: model.legend.items,
          coefficients: model.coefficients.items,
          stats: model.stats.items,
        }
      : undefined,
  );
  const [sortAsc, setSortAsc] = useState(generateDefaultSortValues(true));
  const [sortProperty, setSortProperty] = useState(generateDefaultSortValues(''));

  useEffect(() => {
    setModelItems(
      model
        ? {
            legend: [...model.legend.items],
            coefficients: [...model.coefficients.items],
            stats: [...model.stats.items],
          }
        : undefined,
    );
  }, [model]);

  useEffect(() => {
    setSortAsc(generateDefaultSortValues(true));
    setSortProperty(generateDefaultSortValues(''));
    if (!modelExpanded) {
      setModelItems(undefined);
    }
  }, [modelExpanded]);

  const toSortValue = (value) => {
    const numberValue = _.toNumber(value);
    if (!_.isNaN(numberValue)) return numberValue;

    return _.isString(value) ? value.toLowerCase() : value;
  };

  const sortModelItems = (tableName: string) => (columnName: string, sortAscending: boolean) => {
    const sortedItems = _.orderBy(
      _.get(modelItems, tableName),
      [(item) => toSortValue(item[columnName])],
      [sortAscending ? 'asc' : 'desc'],
    );

    setSortProperty({ ..._.set(sortProperty, tableName, columnName) });
    setSortAsc({ ..._.set(sortAsc, tableName, !sortAscending) });
    setModelItems({ ..._.set(modelItems, tableName, sortedItems) });
  };

  const toggleExpand = () => {
    if (!disabled) {
      onToggleExpand(!modelExpanded);
    }
  };

  return (
    <div id="predictionModel" className="flexRowContainer advancedPanel toolOptions mb15">
      <div
        className={classNames('pt5 pb5 sq-text-primary flexColumnContainer flexSpaceBetween', {
          cursorPointer: !disabled,
        })}
        onClick={toggleExpand}>
        <div>
          <Icon
            icon={modelExpanded && modelItems ? 'fa-minus' : 'fa-plus'}
            testId="advancedParametersIcon"
            extraClassNames={classNames({ disabled })}
          />
          <span className={classNames('ml10', { disabled })}>{t('PREDICTION.PREDICTION_MODEL')}</span>
        </div>

        {gettingPredictionModel && (
          <div className="mr5">
            <Icon icon="fa-spinner" extraClassNames="fa-spin ml5" />
          </div>
        )}
      </div>
      {modelExpanded && model && modelItems && (
        <>
          <div className="divider" />
          <div id="predictionModelTable">
            <div className="mt10">{t('PREDICTION.LEGEND')}</div>
            <Table
              columns={model.legend.columns}
              items={modelItems.legend}
              sortProperty={sortProperty.legend}
              sortAscending={sortAsc.legend}
              sortTableCallback={sortModelItems('legend')}
            />

            <div className="flexColumnContainer flexSpaceBetween mt10">
              <span>{t('PREDICTION.COEFFICIENTS')}</span>
              <CopyButtonGivenText
                textToCopy={coefficientClipboardText}
                extraClassNames="cursorPointer"
                asType="icon"
                disableButton={!coefficientClipboardText}
                tooltip="PREDICTION.COPY_COEFFICIENTS.TO_CLIPBOARD"
                containingClassNames="d-table-cell mr3"
                notifyMessage="PREDICTION.COPY_COEFFICIENTS.SUCCESS"
              />
            </div>
            <Table
              columns={model.coefficients.columns}
              items={modelItems.coefficients}
              sortProperty={sortProperty.coefficients}
              sortAscending={sortAsc.coefficients}
              sortTableCallback={sortModelItems('coefficients')}
            />

            <div className="flexColumnContainer flexSpaceBetween mt10">
              <span>{t('PREDICTION.STATISTICS')}</span>
              <CopyButtonGivenText
                textToCopy={statisticsClipboardText}
                extraClassNames="cursorPointer"
                asType="icon"
                disableButton={!statisticsClipboardText}
                tooltip="PREDICTION.COPY_STATISTICS.TO_CLIPBOARD"
                containingClassNames="d-table-cell mr3"
                notifyMessage="PREDICTION.COPY_STATISTICS.SUCCESS"
              />
            </div>
            <Table
              columns={model.stats.columns}
              items={modelItems.stats}
              sortProperty={sortProperty.stats}
              sortAscending={sortAsc.stats}
              sortTableCallback={sortModelItems('stats')}
            />
          </div>
        </>
      )}
    </div>
  );
};
