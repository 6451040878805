import angular from 'angular';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';

const dependencies = ['Sq.Core', 'Sq.TrendData'];

/**
 * @module The scatterPlot module provides the scatterPlot top level view directive, scatterPlotChart, and
 * the regionsSlider directive.
 */
angular.module('Sq.ScatterPlot', dependencies).service('sqScatterPlotActions', ScatterPlotActions);
