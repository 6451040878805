// @ts-strict-ignore
import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { RainbowLegendItems } from '@/hybrid/trend/RainbowLegendItems.molecule';
import { SignalGradientLegendItems } from '@/hybrid/trend/SignalGradientLegendItems.molecule';
import { ConditionGradientLegendItems } from '@/hybrid/trend/ConditionGradientLegendItems.molecule';
import { decorate } from '@/hybrid/trend/trendViewer/itemDecorator.utilities';
import { useTranslation } from 'react-i18next';
import { getColumnValueAndUOM } from '@/hybrid/utilities/columnHelper.utilities';
import { CapsuleTimeColorMode, PropertyColumn, TREND_VIEWS } from '@/trendData/trendData.constants';
import { isTimestampVisible } from '@/hybrid/utilities/utilities';

export const capsuleTimeLegendBindings = bindingsDefinition({
  view: prop<TREND_VIEWS>(),
  capsuleTimeColorMode: prop<CapsuleTimeColorMode>(),
  sortedColumn: prop<PropertyColumn>(),
  capsules: prop<any[]>(), // sqTrendCapsuleStore.items
  conditions: prop<{ id: string; color: string; name: string }[]>(),
});

export const CapsuleTimeLegend: SeeqComponent<typeof capsuleTimeLegendBindings> = ({
  capsules,
  conditions,
  capsuleTimeColorMode,
  view,
  sortedColumn,
}) => {
  const { t } = useTranslation();

  const isDisplayed = view === TREND_VIEWS.CAPSULE && capsuleTimeColorMode !== CapsuleTimeColorMode.Signal;
  const title = _.compact([_.get(sortedColumn, 'series.name'), t(sortedColumn.title)]).join(' ');
  const getCapsuleName = (capsule) => getColumnValueAndUOM(sortedColumn, capsule);

  const renderLegendItems = () => {
    let filteredCapsules = decorate({
      items: _.reject(capsules, (capsule) => !isTimestampVisible(capsule.startTime)),
    }) as any[];

    if (capsuleTimeColorMode !== CapsuleTimeColorMode.ConditionGradient) {
      filteredCapsules = _.reject(
        filteredCapsules,
        (capsule) => _.isUndefined(capsule.childColor) || _.isEmpty(getCapsuleName(capsule)),
      );
    }

    switch (capsuleTimeColorMode) {
      case CapsuleTimeColorMode.Rainbow:
        return <RainbowLegendItems capsules={filteredCapsules} getCapsuleName={getCapsuleName} title={title} />;
      case CapsuleTimeColorMode.SignalGradient:
        return <SignalGradientLegendItems capsules={filteredCapsules} getCapsuleName={getCapsuleName} title={title} />;
      case CapsuleTimeColorMode.ConditionGradient:
        return <ConditionGradientLegendItems capsules={filteredCapsules} conditions={conditions} />;
    }
  };

  return isDisplayed && renderLegendItems();
};
