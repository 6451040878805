import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyableInputField } from '@/hybrid/core/CopyableInputField.molecule';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';

interface GetLinkProps {
  workbookId: string;
  worksheetId: string;
  folderId?: string;
  showOnlyFolderLinks?: boolean;
}

export const GetLink: React.FunctionComponent<GetLinkProps> = ({
  workbookId,
  worksheetId,
  folderId,
  showOnlyFolderLinks,
}) => {
  const { t } = useTranslation();

  const pathHost = `${window.location.protocol}//${window.location.host}/`;

  const editFullPath = `${pathHost}${folderId ? `${folderId}/` : ''}workbook/${workbookId}/worksheet/${worksheetId}`;
  const viewFullPath = `${pathHost}view/${worksheetId}`;
  const presentFullPath = `${pathHost}present/worksheet/${workbookId}/${worksheetId}`;
  const folderPath = `${pathHost}${folderId}/folder/`;

  const getLinks = [
    {
      title: 'ITEM_ACL.EDIT_LINK',
      testId: 'editLink',
      value: editFullPath,
      tooltip: 'ITEM_ACL.EDIT_LINK_TOOLTIP',
    },
    {
      title: 'ITEM_ACL.VIEW_LINK',
      testId: 'viewLink',
      value: viewFullPath,
      tooltip: 'ITEM_ACL.VIEW_LINK_TOOLTIP',
    },
    {
      title: 'ITEM_ACL.PRESENTATION_LINK',
      testId: 'presentationLink',
      value: presentFullPath,
      tooltip: 'ITEM_ACL.PRESENTATION_LINK_TOOLTIP',
    },
  ];

  const folderLinks = [
    {
      title: 'ITEM_ACL.FOLDER_LINK',
      testId: 'folderLink',
      value: folderPath,
      tooltip: 'ITEM_ACL.FOLDER_LINK_TOOLTIP',
    },
  ];

  return (
    <>
      <h4>{t('ITEM_ACL.GET_LINK')}</h4>
      {_.map(showOnlyFolderLinks ? folderLinks : getLinks, (link) => (
        <div key={link.testId} className="flexRowContainer mt10">
          <div className="flexColumnContainer semi-bold pb5">
            <div className="min-width-390">
              {t(link.title)}
              <Icon icon="fa-info-circle" extraClassNames="ml5" tooltip={link.tooltip} />
            </div>
          </div>
          <div className="flexRowContainer flexFill">
            <CopyableInputField
              testId={link.testId}
              value={link.value}
              readOnly={true}
              fieldTooltip=""
              notifyMessage="COPY_URL.SUCCESS"
              buttonTooltip="COPY_URL.TO_CLIPBOARD"
              extraButton={
                <TextButton
                  label=""
                  icon="fa-external-link"
                  iconStyle="theme"
                  tooltip="ITEM_ACL.OPEN_LINK"
                  tooltipOptions={{ placement: 'top' }}
                  testId={`${link.testId}-openLinkButton`}
                  onClick={() => window.open(link.value, '_blank')}
                />
              }
            />
          </div>
        </div>
      ))}
    </>
  );
};
