import { useEffect } from 'react';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useInjectorGet } from '@/hybrid/core/hooks/useInjectorGet.hook';
import { DatasourcesStatusOutputV1 } from '@/sdk';
import { DatasourcesService } from '@/hybrid/administration/datasources/datasources.service';
import { sqDatasourcesStore } from '@/core/core.stores';

export function useDatasources(): DatasourcesStatusOutputV1 {
  const sqDatasourcesService = useInjectorGet<DatasourcesService>('sqDatasourcesService');
  const storeExports = useFlux(sqDatasourcesStore);

  useEffect(() => {
    sqDatasourcesService.subscribe();
    return () => sqDatasourcesService.unsubscribe();
  }, []);

  return storeExports.datasources;
}
