// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';
import { KEY_CODES } from '@/main/app.constants';
import Highlighter from 'react-highlight-words';
import { InvestigateToolType } from '@/hybrid/toolSelection/investigate.constants';
import { isSvgIcon } from '@/hybrid/utilities/utilities';
import { SvgIcon } from '@/hybrid/core/SvgIcon.atom';

export type ToolsBrowsePanelProps = {
  toolFilter: string;
  filteredTools: InvestigateToolType[];
  setActiveTool: (tool: InvestigateToolType) => void;
};

/**
 * Renders the search/filtered results from <OverviewTopSection />
 */
export const ToolsBrowsePanel: React.FunctionComponent<ToolsBrowsePanelProps> = ({
  toolFilter,
  filteredTools,
  setActiveTool,
}) => (
  <div id="toolsBrowsePanel" data-testid="toolsBrowsePanel" className="pt2 flexRowContainer overflowAuto">
    {_.map(filteredTools, (tool) => {
      const icon = tool.icon || tool.iconClass; // Use icon from plugin if it is available
      return (
        <div
          key={tool.id}
          id={tool.id}
          className="toolOverviewCard flexNoGrowNoShrink flexRowContainer cursorPointer"
          tabIndex={0}
          onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && setActiveTool(tool)}
          onClick={() => setActiveTool(tool)}>
          <div className="flexColumnContainer flexBaseline">
            {isSvgIcon(icon) ? (
              <SvgIcon icon={icon} extraClassNames="pluginToolSvg pluginToolSvgFill mr8" />
            ) : (
              <Icon icon={icon} extraClassNames="fa-lg mr8 headerIcon" type="inherit" />
            )}
            <Highlighter
              highlightClassName="highlight-search"
              className="headerName flexFill"
              autoEscape={true}
              searchWords={[toolFilter] ?? []}
              textToHighlight={tool.name}
            />
            {tool.isGroup && <Icon icon="fa-chevron-right" extraClassNames="tooltipIcon ml10" />}
          </div>
          <Highlighter
            highlightClassName="highlight-search"
            className="toolDescription"
            autoEscape={true}
            searchWords={[toolFilter] ?? []}
            textToHighlight={tool.description}
          />
        </div>
      );
    })}
  </div>
);
