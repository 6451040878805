// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { FORM_ERROR, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { AgentStatusOutputV1, ConnectionOutputV1, ConnectionStatusOutputV1 } from '@/sdk';
import { DatasourcesService } from '@/hybrid/administration/datasources/datasources.service';
import { useRandomId } from '@/hybrid/core/hooks/useRandomId.hook';

const connectionModalBindings = bindingsDefinition({
  sqDatasourcesService: injected<DatasourcesService>(),
  selectedConnection: prop<ConnectionStatusOutputV1>(),
  agents: prop<AgentStatusOutputV1[]>(),
  isNew: prop<boolean>(),
  onClose: prop<() => void>(),
});

export const ManageConnectionModal: SeeqComponent<typeof connectionModalBindings> = ({
  selectedConnection,
  agents,
  isNew,
  onClose,
}) => {
  const { sqDatasourcesService } = useInjectedBindings(connectionModalBindings);
  const { t } = useTranslation();

  const defaultConnection = {
    id: '',
    type: '',
    name: '',
    connectorName: '',
    agentName: '',
    enabled: true,
  };

  const [agentOptions, setAgentOptions] = useState([]);
  const [connectionOptions, setConnectionOptions] = useState([]);
  const [connectorOptions, setConnectorOptions] = useState([]);
  const [connection, setConnection] = useState<ConnectionOutputV1>(defaultConnection);
  const [connectionNameErrorMessage, setConnectionNameErrorMessage] = useState('');
  const [agentName, setAgentName] = useState(selectedConnection.agentName);
  const [connectorName, setConnectorName] = useState(selectedConnection.connectorName);
  const [connectorNameErrorMessage, setConnectorNameErrorMessage] = useState('');
  const [connectionName, setConnectionName] = useState(selectedConnection.name);
  const [datasourceId, setDatasourceId] = useState(useRandomId());
  const [transformError, setTransformError] = useState('');
  const [loading, setLoading] = useState(false);

  const validateMinLength = (value: string) => _.isEmpty(value) || _.size(_.trim(value)) < 4;

  useEffect(() => {
    if (!isNew) {
      loadConnection(connectionName);
    }
    // We must clear the default transform error in order to set a custom error.
    // Set it here after the default error in the transform JsonTextAreaFormComponent is initialized.
    setTransformError(null);
    setAgentOptions(generateAgentOptions());
  }, []);

  const changeConnectionField = useCallback(
    (key, value) => {
      setConnection((prevState) => {
        return { ...prevState, [key]: value };
      });
    },
    [connection],
  );

  const createOrUpdateConnection = () => {
    return sqDatasourcesService.createOrUpdateConnection(isNew, agentName, datasourceId, connection).then(onClose);
  };

  const onSave = () => {
    if (isNew) {
      return sqDatasourcesService
        .getConnection({
          connectorName: connection.connectorName,
          agentName,
          name: connection.name,
        })
        .then((connection) => {
          if (connection.isArchived) {
            return createOrUpdateConnection();
          } else {
            setConnectionNameErrorMessage(t('ADMIN.DATASOURCES.CONNECTION_MODAL.ERROR_MESSAGE_DATASOURCE_NAME_EXIST'));
          }
        })
        .catch(createOrUpdateConnection);
    } else {
      return createOrUpdateConnection();
    }
  };

  function loadConnection(name) {
    setLoading(true);
    return sqDatasourcesService
      .getConnection({
        connectorName,
        agentName,
        name,
      })
      .then((data: ConnectionOutputV1) => {
        if (isNew) {
          data.enabled = true;
          data.name = '';
        }
        setConnection(data);
      })
      .catch(() =>
        setConnectionNameErrorMessage(t('ADMIN.DATASOURCES.CONNECTION_MODAL.ERROR_MESSAGE_DATASOURCE_NAME_ERROR')),
      )
      .finally(() => setLoading(false));
  }

  function loadConnectionOptions(agentName: string, connectorName: string) {
    setLoading(true);
    setConnectorNameErrorMessage('');
    return sqDatasourcesService
      .getConnectionNames(agentName, connectorName)
      .then((connections: []) => setConnectionOptions(connections))
      .catch((reason) => {
        setConnectorName('');
        setConnectorNameErrorMessage(reason);
      })
      .finally(() => setLoading(false));
  }

  function isExternalCalc(name: string) {
    if (!name) return false;
    return [
      'Add-on Calculation Python Connector',
      'External Calculation Python Connector',
      'Add-on Calculation Excel VBA Connector',
      'External Calculation Excel VBA Connector',
      'Excel VBA Connector',
    ].some((connector) => name === connector);
  }

  function loadConnectorOptions(agentName: string) {
    setLoading(true);
    return sqDatasourcesService
      .getConnectorNames(agentName, (c) => c.Enabled && !isExternalCalc(c.Name))
      .then((connectors) => setConnectorOptions(connectors?.sort((a, b) => a.label.localeCompare(b.label))))
      .finally(() => setLoading(false));
  }

  const generateAgentOptions = () => {
    return _.map(agents, (agent) => ({
      value: agent.name,
      label: agent.name,
    }));
  };

  const formDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'editConnection',
      key: 'editConnection',
      components: [
        // agent name
        {
          component: 'SelectFormComponent',
          includeIf: isNew,
          label: 'ADMIN.DATASOURCES.CONNECTION_MODAL.AGENT_NAME',
          displayNumber: false,
          name: 'agentName',
          testId: 'agentNameSelectorTestId',
          value: agentName,
          placeholder: 'ADMIN.DATASOURCES.CONNECTION_MODAL.SELECT_AGENT_PLACEHOLDER',
          popoverContent: null,
          disabled: loading,
          onChange: (value) => {
            setConnectorName('');
            setConnectorOptions([]);
            setConnectionName('');
            setConnectionOptions([]);
            setAgentName(value);
            changeConnectionField('agentName', value);
            return loadConnectorOptions(value);
          },
          options: agentOptions,
          required: true,
          searchable: true,
          insideModal: true,
          extraClassNames: 'agentNameSelector_select',
        },
        // connector name
        {
          component: 'SelectFormComponent',
          includeIf: isNew,
          displayNumber: false,
          label: 'ADMIN.DATASOURCES.CONNECTION_MODAL.CONNECTOR_NAME',
          name: 'connectorsName',
          testId: 'connectorNameSelectorTestId',
          value: connectorName,
          placeholder: 'ADMIN.DATASOURCES.CONNECTION_MODAL.SELECT_CONNECTOR_PLACEHOLDER',
          popoverContent: null,
          disabled: loading || agentName.length === 0,
          onChange: (value) => {
            setConnectorName(value);
            setConnectionName('');
            setConnectionOptions([]);
            changeConnectionField('connectorName', value);
            return loadConnectionOptions(agentName, value);
          },
          options: connectorOptions,
          required: true,
          searchable: true,
          insideModal: true,
          extraClassNames: 'connectorSelector_select',
        },
        {
          includeIf: !_.isEmpty(connectorNameErrorMessage),
          component: 'ErrorMessageFormComponent',
          name: 'connectorNameError',
          key: 'connectorNameError',
          testId: 'connectorNameErrorTestId',
          value: connectorNameErrorMessage,
          type: FORM_ERROR,
          dismissible: false,
          failForm: false,
        },
        {
          includeIf: !isNew,
          component: 'LabelFormComponent',
          value: connectorName,
          name: 'connectorsNameValue',
          testId: 'connectorNameValueTestId',
          extraClassNames: 'text-italic',
        },
        // template
        {
          component: 'SelectFormComponent',
          includeIf: isNew,
          label: 'ADMIN.DATASOURCES.CONNECTION_MODAL.TEMPLATE_CONNECTION',
          displayNumber: false,
          name: 'templateConnectionName',
          testId: 'connectionNameSelectorTestId',
          value: connectionName,
          placeholder: 'ADMIN.DATASOURCES.CONNECTION_MODAL.SELECT_CONNECTION_PLACEHOLDER',
          popoverContent: null,
          disabled: loading || agentName.length === 0 || connectorName.length === 0,
          onChange: (value) => {
            changeConnectionField('name', '');
            loadConnection(value);
          },
          options: connectionOptions,
          required: false,
          searchable: true,
          insideModal: true,
          extraClassNames: 'connectionSelector_select',
        },
        // datasource name
        {
          component: 'FormControlFormComponent',
          includeIf: isNew,
          key: 'connectionNameKey',
          name: 'connectionName',
          label: 'ADMIN.DATASOURCES.CONNECTION_MODAL.DATASOURCE_NAME',
          value: connection.name,
          onChange: (value) => {
            setConnectionNameErrorMessage('');
            changeConnectionField('name', value);
          },
          testId: 'connectionNameTestId',
          disabled: loading,
          size: 'md',
          required: true,
          extendValidation: true,
          validation: validateMinLength,
          placeholder: 'ADMIN.DATASOURCES.CONNECTION_MODAL.DATASOURCE_NAME_PLACEHOLDER',
          customErrorText: 'ADMIN.DATASOURCES.CONNECTION_MODAL.ERROR_MESSAGE_DATASOURCE_NAME_VALIDATION',
        },
        {
          component: 'ErrorMessageFormComponent',
          includeIf: !_.isEmpty(connectionNameErrorMessage),
          name: 'connectionNameExistingError',
          key: 'errorConnectionNameExistsKey',
          value: connectionNameErrorMessage,
          type: FORM_ERROR,
          dismissible: false,
          failForm: false,
        },
        // datasource id
        {
          component: 'FormControlFormComponent',
          includeIf: isNew,
          label: 'ADMIN.DATASOURCES.CONNECTION_MODAL.DATASOURCE_ID',
          key: 'connectionDatasourceId',
          name: 'connectionDatasourceId',
          value: datasourceId,
          onChange: (value: string) => setDatasourceId(value),
          testId: 'connectionDatasourceTestId',
          disabled: loading,
          placeholder: 'ADMIN.DATASOURCES.CONNECTION_MODAL.DATASOURCE_ID_PLACEHOLDER',
          size: 'md',
          required: true,
        },
        // enabled
        {
          component: 'CheckboxFormComponent',
          id: 'connectionEnabledId',
          key: 'connectionEnabledComponentKey',
          label: 'ADMIN.DATASOURCES.CONNECTION_MODAL.ENABLED',
          checkboxLabel: '',
          onChange: () => changeConnectionField('enabled', !connection.enabled),
          testId: 'connectionEnabledTestId',
          disabled: loading,
          value: connection.enabled,
          type: 'checkbox',
          name: 'connectorEnabled',
        },
        // max concurrent requests
        {
          component: 'FormControlFormComponent',
          includeIf: connection?.pullConnection,
          key: 'connectionMaxConcurrentKey',
          label: 'ADMIN.DATASOURCES.CONNECTION_MODAL.MAX_CONCURRENT_REQUESTS',
          name: 'connectionMaxConcurrent',
          type: 'number',
          value: connection?.maxConcurrentRequests,
          onChange: (value: number) => changeConnectionField('maxConcurrentRequests', value),
          validation: (value) => {
            if (value === null || value === undefined || value === '') return false;

            const nValue = Number(value);
            return nValue < 1 || isNaN(nValue);
          },
          testId: 'connectionMaxConcurrentId',
          disabled: loading,
          size: 'md',
          required: false,
        },
        // max results per request
        {
          component: 'FormControlFormComponent',
          includeIf: connection?.pullConnection,
          label: 'ADMIN.DATASOURCES.CONNECTION_MODAL.MAX_RESULTS_PER_REQUEST',
          key: 'connectionMaxResultsPerRequestsKey',
          name: 'connectionMaxResultsPerRequests',
          value: _.toString(connection?.maxResultsPerRequests),
          type: 'number',
          onChange: (value: number) => changeConnectionField('maxResultsPerRequests', value),
          testId: 'connectionMaxResultsPerRequestsId',
          validation: (value) => {
            if (value === null || value === undefined || value === '') return false;

            const nValue = Number(value);
            return nValue < 1 || isNaN(nValue);
          },
          disabled: loading,
          size: 'md',
          required: false,
        },
        // transforms
        {
          component: 'JsonTextAreaFormComponent',
          includeIf: connection?.indexingConnection,
          key: 'transformsConnectionConfigKey',
          name: 'transformsConfig',
          label: 'ADMIN.DATASOURCES.CONNECTION_MODAL.TRANSFORMS',
          tooltip: 'ADMIN.DATASOURCES.CONNECTION_MODAL.TRANSFORMS_TOOLTIP',
          onTooltipClick: () =>
            open('https://telemetry.seeq.com/support-link/wiki/spaces/KB/pages/127009211', '_blank'),
          value: JSON.stringify(connection?.transforms, null, 4),
          testId: 'transformsConnectionConfig',
          disabled: loading,
          size: 'md',
          onChange: (value: string) => {
            try {
              const parsedValue = _.isEmpty(value) ? undefined : JSON.parse(value);
              changeConnectionField('transforms', parsedValue);
            } catch (e) {
              // We need to save only valid JSON.
              // On change triggers before the validation method, we need to
              // catch all errors, and then it rechecks the value in the validation method. If the value is not correct,
              // it provides an error message.
            }
          },
          validation: (value: any) => {
            if (value === null || value === undefined || value === '') return false;
            try {
              const transformsObj = JSON.parse(value);
              if (!Array.isArray(transformsObj)) {
                setTransformError(t('ADMIN.DATASOURCES.CONNECTION_MODAL.TRANSFORMS_VALIDATION_ARRAY_ERROR'));
                return true;
              }
            } catch (e) {
              setTransformError(e.toString());
              return true;
            }
            return false;
          },
          customErrorText: transformError,
          rows: connection.transforms ? 4 : 2,
          required: false,
          extraClassNames: 'min-height-30',
        },
        // additional configuration
        {
          key: 'connectionConfigKey',
          component: 'JsonTextAreaFormComponent',
          name: 'jsonConfig',
          label: 'ADMIN.DATASOURCES.CONNECTION_MODAL.ADDITIONAL_CONFIGURATION',
          value: JSON.stringify(connection.json, null, 4),
          onChange: (value: any) => {
            try {
              changeConnectionField('json', JSON.parse(value));
            } catch (e) {
              // We need to save only valid JSON.
              // On change triggers before the validation method, we need to
              // catch all errors, and then it rechecks the value in the validation method. If the value is not correct,
              // it provides an error message.
            }
          },
          testId: 'jsonConfigTestId',
          size: 'md',
          rows: connection.json ? 4 : 2,
          required: true,
          extraClassNames: 'min-height-30',
        },
      ],
    },
  ];

  const renderSubTitle = () => (
    <span>
      {connectionName} - {agentName}
    </span>
  );

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="manageConnectionModal">
      <Modal.Header closeButton={true}>
        <div className="flexRowContainer">
          <div className="flexColumnContainer flexFill">
            <Modal.Title>
              {isNew
                ? t('ADMIN.DATASOURCES.CONNECTION_MODAL.CREATE_NEW_TITLE')
                : t('ADMIN.DATASOURCES.CONNECTION_MODAL.MODIFY_TITLE')}
            </Modal.Title>
          </div>
          <div data-testid="modal-subtitle" className="sq-fairly-dark-gray text-italic">
            {!isNew && renderSubTitle()}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <SimpleSaveFormBuilder
          formDefinition={formDefinition}
          submitFn={() => onSave()}
          submitBtnLabel={isNew ? 'CREATE' : 'UPDATE'}
          closeFn={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};
