// @ts-strict-ignore
import React, { useState } from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from 'react-i18next';
import { JournalComment } from '@/hybrid/annotation/JournalComment.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';

import { ReportActions } from '@/reportEditor/report.actions';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { doTrack } from '@/track/track.service';
import { sqReportStore } from '@/core/core.stores';
import { canManageItem } from '@/services/authorization.service';

const reportCommentsBindings = bindingsDefinition({
  isPresentationMode: prop<boolean>(),
  sqReportActions: injected<ReportActions>(),
});

export const ReportComments: SeeqComponent<typeof reportCommentsBindings> = (props) => {
  const { sqReportActions } = useInjectedBindings(reportCommentsBindings);

  const [commentText, setCommentText] = useState('');
  const [isCommenting, setIsCommenting] = useState(false);
  useFluxPath(sqReportStore, () => sqReportStore.comments);

  const { id, comments = [] } = sqReportStore;
  const { updateComment, deleteComment, addComment } = sqReportActions;
  const { t } = useTranslation();

  const { isPresentationMode } = props;

  const onSaveComment = () => {
    addComment(id, commentText);
    setIsCommenting(false);
    setCommentText('');
    doTrack('Topic', 'Comments', 'save');
  };

  const onCancel = () => {
    setIsCommenting(false);
    setCommentText('');
    doTrack('Topic', 'Comments', 'cancel');
  };

  const onNewComment = () => {
    setCommentText('');
    setIsCommenting(true);
    doTrack('Journal', 'Comments', 'new');
  };

  return (
    <>
      <div
        className={classNames(
          'flexFillOverflow msOverflowStyleAuto flexRowContainer overflowAuto flexBasisZero pl5 pr5 mt10',
          {
            flexCenter: !comments.length,
          },
        )}>
        {!comments.length && (
          <div className="col-sm width-250 text-center">{t('REPORT.COMMENTS.NO_COMMENTS_PLACEHOLDER')}</div>
        )}
        {comments.map((comment) => (
          <div key={comment.id}>
            <JournalComment
              comment={comment}
              isPresentationMode={isPresentationMode}
              updateComment={updateComment}
              deleteComment={deleteComment}
              canManage={canManageItem}
            />
          </div>
        ))}
      </div>
      {isCommenting && <div className="mt7 pb5 lightGreyBorderTop" />}
      {isCommenting && !isPresentationMode && (
        <div
          className="card card-primary flexRowContainer flexNoGrowNoShrink pl5 pr5"
          data-testid="specNewCommentEditor">
          <div className="card-body pl8 pt8 flexNoGrowNoShrink flexRowContainer">
            <div className="commentNameEdit flexNoGrowNoShrink flexRowContainer">
              <Form.Group controlId="specNewCommentEditor">
                <Form.Control
                  autoFocus={true}
                  as="textarea"
                  data-testid="specTextarea"
                  rows={3}
                  className="flexNoGrowNoShrink form-control textAreaResizeNone overflowYAuto"
                  value={commentText}
                  onChange={(event) => setCommentText(event.currentTarget.value)}
                />
              </Form.Group>
              <div className="pt5 pr0 pb0 text-right">
                <TextButton
                  testId="specCancelNewCommentBtn"
                  extraClassNames="mr5 min-width-50"
                  onClick={onCancel}
                  label="CANCEL"
                  size="sm"
                />
                <TextButton
                  testId="specSaveNewCommentBtn"
                  extraClassNames="min-width-50"
                  onClick={onSaveComment}
                  disabled={!commentText}
                  label="SAVE"
                  size="sm"
                  variant="theme"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flexColumnContainer flexNoGrowNoShrink flexAlignEnd animateHeight125Linear p5">
        <div className="flexFill" />
        {!isPresentationMode && (
          <a data-testid="specNewCommentBtn" href="#" onClick={onNewComment}>
            <HoverTooltip text="JOURNAL.TOOLTIP.ADD_COMMENT" placement="left">
              <div>
                {t('JOURNAL.ENTRY.NEW_COMMENT')}
                <Icon icon="fc-comment" extraClassNames="small pl5" />
              </div>
            </HoverTooltip>
          </a>
        )}
      </div>
    </>
  );
};

export const sqReportComments = angularComponent(reportCommentsBindings, ReportComments);
