var api = require("!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../node_modules/css-loader/dist/cjs.js??ref--16-1!../../../node_modules/postcss-loader/dist/cjs.js??ref--16-2!../../../node_modules/sass-loader/dist/cjs.js??ref--16-3!./ckeditor5-seeq-theme.scss");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {"injectType":"singletonStyleTag","attributes":{"data-cke":true}};

options.insert = "head";
options.singleton = true;

var update = api(content, options);



module.exports = content.locals || {};