// @ts-strict-ignore
import angular from 'angular';

angular.module('Sq.Directives.SelectOnFocus', []).directive('sqSelectOnFocus', sqSelectOnFocus);

function sqSelectOnFocus($timeout, IS_PROTRACTOR) {
  return {
    restrict: 'A',
    link(scope, element) {
      let focusedElement = null;

      element.on('focus', function () {
        const vm = this;
        if (focusedElement !== vm && !IS_PROTRACTOR) {
          focusedElement = vm;
          $timeout(() => vm.select(), 10);
        }
      });

      element.on('blur', function () {
        focusedElement = null;
      });
    },
  };
}
