import React from 'react';

interface JournalMetadataProps {
  name: string;
  date: string;
}

export const JournalMetadata: React.FunctionComponent<JournalMetadataProps> = ({ name, date }) => {
  return (
    <div className="flexColumnContainer xsmall sq-fairly-dark-gray">
      <span className="specCreatedBy">{name}</span>
      <span className="pl3 pr3">&middot;</span>
      <span className="specUpdatedAt">{date}</span>
    </div>
  );
};
