import React from 'react';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { reload } from '@/hybrid/utilities/utilities';

const redactionBannerBindings = bindingsDefinition({
  workbookOwner: prop<string>(),
});

export const RedactionBanner: SeeqComponent<typeof redactionBannerBindings> = ({ workbookOwner }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flexRowContainer pl10 pr10 flexFill text-center">
        {t('ACCESS_CONTROL.REDACTION.WARNING', { owner: workbookOwner })}
      </div>
      <Icon
        testId="reloadIcon"
        tooltip="ACCESS_CONTROL.REDACTION.RELOAD"
        tooltipPlacement="bottom"
        icon="fa-refresh"
        extraClassNames="mr5 redactionBannerReload"
        onClick={() => reload()}
      />
    </>
  );
};
export const sqRedactionBanner = angularComponent(redactionBannerBindings, RedactionBanner);
