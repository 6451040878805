// @ts-strict-ignore
import { sqDurationStore, sqTrendStore } from '@/core/core.stores';
import { getInjector } from '@/hybrid/utilities/conversion.utilities';
import { Capsule } from '@/hybrid/utilities/datetime.constants';
import { getMSPerPixelWidth } from '@/hybrid/utilities/utilities';
import { ITEM_ICONS, NUMBER_CONVERSIONS, STRING_UOM } from '@/main/app.constants';
import { SPIKECATCHER_PER_PIXEL } from '@/services/formula.service';
import _ from 'lodash';
import tinycolor from 'tinycolor2';
import { RangeExport } from './duration.store';
import { TrendActions } from './trend.actions';
import {
  ALPHA_UNCERTAIN,
  DASH_STYLES,
  ENUM_REGEX,
  ITEM_CHILDREN_TYPES,
  MAX_SERIES_PIXELS,
  SAMPLE_OPTIONS,
} from './trendData.constants';
import moment from 'moment-timezone';
import { conditionFormula, findCapsulesQueryInterval } from '@/hybrid/tools/manualCondition/conditionFormula.service';

/**
 * De-dupes data based on x-value. Data is an Array of Arrays where the first entry corresponds to the x, the
 * second value to the y-axis ([[x,y], [x,y],[x, y]].
 * Step series contain 2 values for the same x-value - this is great if you're rendering them as lines, not so when
 * you want to visualize them as bars.
 *
 * @param {any[]} data - data to be de-duped.
 *
 * @return {any[]} without duplicated values
 */
function deDupeDataByXValue(data: any[]): any[] {
  let current;
  let i = 1;
  const deDupedData = [];
  let previous = data[0];

  for (i; i < data.length; i++) {
    current = data[i];
    // for uncertain data the backend will sometimes return the same timestamp twice, without a value however.
    // This can lead to samples "disappear" - so we need to check for a valid value before assigning it.
    if (previous.key !== current.key || (!_.isFinite(current.value) && _.isFinite(previous.value))) {
      deDupedData.push(previous);
    }

    previous = current;
  }

  // check the last one and see if it needs to be added:
  if (previous.key !== _.last(deDupedData).key) {
    deDupedData.push(_.last(data));
  }

  return deDupedData;
}

/**
 * Prepares data to display properly as bars.
 * Currently the Backend adds a sample point at the very beginning and at the end of the display range - that's
 * great when you're rendering a series as a line, it looks odd when you try to visualize a series as bars. To
 * prevent this oddness this function removes those artificial data points. In addition, null values, that produce
 * "holes" in series mess up the bar chart display as well - so those get pruned.
 *
 * Also manages step series data to ensure proper bar display of step series.
 *
 * @param {any[]} data -  an array of samples [{key: x, value: y}, {key: x, value: y, isUncertain:true}, ....]
 *
 * @returns {any[]} input data, de-duped by x-values. If the first and last datapoint are exact matches for the
 * display range start and end then those data points will be removed (as they are "artificial" points inserted by
 * the backend and don't display well as bars)
 */
export function prepareDataForBarChartDisplay(data: any[]): any[] {
  const start = sqDurationStore.displayRange.start.valueOf();
  const end = sqDurationStore.displayRange.end.valueOf();

  data = _.chain(data)
    .filter((sample) => _.isFinite(sample[1]) || sample.isUncertain || _.isFinite(sample.value))
    .map((point) => (_.has(point, 'key') ? point : { key: point[0], value: point[1] }))
    .value();

  if (data.length <= 3) {
    return data;
  }

  if (_.first(data).key === start) {
    data = data.slice(1);
  }

  if (_.last(data).key === end) {
    data = data.slice(0, -1);
  }

  const xValues = _.map(data, 'key');

  let barChartData;
  if (xValues.length === _.uniq(xValues).length) {
    barChartData = data;
  } else {
    barChartData = deDupeDataByXValue(data);
  }

  return barChartData;
}

/**
 * This helper gets resolves string values and booleans for use on the chart
 */
export function getYValue(value, stringEnum) {
  if (stringEnum && value && (_.isString(value) || _.isNumber(value))) {
    const match = value.toString().match(ENUM_REGEX);
    if (match) {
      return parseInt(_.result(_.find(stringEnum, ['stringValue', match[2]]), 'key'), 10);
    }

    return _.result(_.find(stringEnum, ['stringValue', value.toString()]), 'key');
  }

  if (_.isBoolean(value)) {
    return value ? 1 : 0;
  }

  if (_.isFinite(value)) {
    return value;
  }

  return null;
}

/**
 * We determine which points need to be shown as markers as we are moving through samples - as we are
 * constructing the data array. This helper, when called after every point is added will return all the indexes
 * that should be shown as markers
 */
export function getIndexToShowAsMarker(datums: [number, number | null][], last: boolean) {
  const datumCount = datums.length;
  const valid = (idx) => datums[idx][1] !== null;
  if (datumCount === 1 && last && valid(0)) {
    return 0; // the only datum is a valid point
  } else if (datumCount === 2 && valid(0) && !valid(1)) {
    return 0; // the first datum is a valid point and the second is not
  } else if (datumCount >= 2 && last && !valid(datumCount - 2) && valid(datumCount - 1)) {
    return datumCount - 1; // the last datum is a valid point and the second to last is not
  } else if (datumCount >= 3 && !valid(datumCount - 3) && valid(datumCount - 2) && !valid(datumCount - 1)) {
    return datumCount - 2; // valid datum in between two invalid datums
  } else {
    return null;
  }
}

interface TransformDataPointsParams {
  /** the input samples */
  rawSamples: any[];
  /** color of the signal represented as a hex code (e.g. #ffffff) */
  color: string;
  /** minimum time (in milliseconds) to subtract from all samples, for use in capsule time */
  minTime: number;
  /** one of SAMPLE_OPTIONS (line, bar, ..) */
  sampleDisplayOption: string;
  /** true if data has a lower and upper bound instead of just a value. if a series `hasBounds` it cannot display uncertainty, cannot be a string series, and will not display discrete samples */
  hasBounds: boolean;
  /** an enum to map between strings and numeric values. If provided, the series is  assumed to be a string series where the values are strings (potentially in an enum format). */
  stringEnum?: any[];
}

/**
 * Transform input samples for display. Also used to enable 'singleton' points (points that don't have neighbors).
 *
 * @returns {Object} An object with two lists of data: the unmodified samples and the display-ready data, as well
 * as the Highchart zones to indicate uncertainty.
 */
export function transformDataPoints({
  rawSamples,
  color,
  minTime,
  sampleDisplayOption,
  hasBounds,
  stringEnum,
}: TransformDataPointsParams) {
  // Because of the way Highcharts implements zones (https://github.com/highcharts/highcharts/issues/6928) we need
  // to calculate how much time we need to add to the last certain data point to represent a 1 pixel offset.
  const chartWidth = getInjector().get<TrendActions>('sqTrendActions').getChartWidth();
  const adjustment = _.get(sqDurationStore, 'displayRange.duration', 1) / chartWidth;

  // Useful to determine where zones should be drawn to show uncertainty.
  const isLine = sampleDisplayOption === SAMPLE_OPTIONS.LINE || sampleDisplayOption === SAMPLE_OPTIONS.LINE_AND_SAMPLE;

  // We only compress data if the signal is visualized as a LINE; if the signal is visualized other ways we need the
  // indicators of where an actual samples are available.
  const useCompressed = sampleDisplayOption === SAMPLE_OPTIONS.LINE;

  const samples =
    !hasBounds && sampleDisplayOption === SAMPLE_OPTIONS.BAR ? prepareDataForBarChartDisplay(rawSamples) : rawSamples;

  let compressionInProgress = false;
  const data = [];
  const displayData = [];
  let previousValue, previousUncertain, maximumCertain;
  _.forEach(samples, (point: any) => {
    // Because we maintain nanosecond precision on the backend, not all conversions result in whole numbers. When
    // this happens, signal segments might not appear as expected, especially in Chain View (CRAB-32768). Therefore,
    // we need to round the conversion to the chart displays the segments correctly. Note: Math.ceil works as well,
    // but the ideal solution, Math.floor does not work. Ideally, we would like to be able to pass an argument into
    // the formula request that specifies timestamp precision, so we don't have to manipulate here.
    const time = _.round(point.key / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND) - (minTime || 0);
    let returnValue;
    if (!hasBounds) {
      returnValue = [time, getYValue(point.value, stringEnum)];
    } else {
      const lower = getYValue(point.lower, stringEnum);
      const upper = getYValue(point.upper, stringEnum);
      if (!_.isNil(lower) && !_.isNil(upper) && lower <= upper) {
        returnValue = [time, lower, upper];
      } else {
        returnValue = [time, null, null];
      }
    }

    if (previousValue && useCompressed) {
      // Use state in the accumulator to figure out when to compress. The form of compression is: if 3 or more
      // consecutive samples have the same value, remove all but the first and last.
      if (previousValue[1] === returnValue[1] && previousValue[2] === returnValue[2]) {
        if (compressionInProgress) {
          displayData.pop();
        } else {
          compressionInProgress = true;
        }
      } else {
        compressionInProgress = false;
      }
    }

    if (previousValue && _.isNil(maximumCertain) && point.isUncertain) {
      // Check if we should start drawing uncertainty here.
      if (isLine && _.isNil(returnValue[1]) === _.isNil(previousValue[1])) {
        // A line segment before an uncertain sample should look uncertain.
        // If one xor the other end of the segment is null, there's no line segment and we don't need to worry about
        // it. If both ends have values, we need to move the uncertainty back to the first one. We also do this if
        // both ends are null (invalid) because otherwise a discrete signal won't ever show uncertain samples.
        maximumCertain = previousValue[0] + adjustment;
      } else if (!isLine && !previousUncertain) {
        // For a bar chart or individual samples, we just want to start at the last certain sample, valid or not.
        maximumCertain = previousValue[0] + adjustment;
      }
    }

    data.push(returnValue);
    displayData.push(returnValue);

    if (!hasBounds && sampleDisplayOption !== SAMPLE_OPTIONS.BAR) {
      // Display isolated samples as discrete points on the trend. To know if a point is a 'singleton' we look at
      // it's surrounding points - if they are both null or, if the  point is a beginning or endpoint and the only
      // possible neighbor is null we know we need to add a marker for it to show.
      const markerIndex = getIndexToShowAsMarker(displayData, point === _.last(samples));
      if (markerIndex !== null) {
        const [x, y] = displayData[markerIndex];
        displayData[markerIndex] = {
          x,
          y,
          marker: {
            enabled: true,
            symbol: 'square',
            radius: 1.5,
          },
        };
      }
    }

    previousValue = returnValue;
    previousUncertain = point.isUncertain;
  });

  // Check for special case with no certain, valid samples
  const firstValidSample = _.find(samples, (sample) => !_.isNil(getYValue(sample.value, stringEnum)));
  if (_.get(firstValidSample, 'isUncertain')) {
    // Turn everything uncertain, even though we never saw a certain -> uncertain transition.
    maximumCertain = data[0][0];
  }

  return {
    data: displayData,
    samples: rawSamples,
    zones: _.isNil(maximumCertain)
      ? []
      : [
          { value: maximumCertain },
          {
            color: tinycolor(color).setAlpha(ALPHA_UNCERTAIN).toString(),
            dashStyle: DASH_STYLES.DASH,
          },
        ],
  };
}

/**
 * Finds the minimum timestamp in the associated capsule.
 *
 * @param {Object} itemCursor - Cursor to the item
 *
 * @return {Number} The timestamp in milliseconds of the minimum.
 */
export function getMinTime(itemCursor): number | null {
  if (itemCursor.get('childType') === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE) {
    return itemCursor.get('startTime');
  } else {
    return null;
  }
}

function createStringEnum(samples) {
  let key, stringValue, stringValueMap;
  const returnStringEnum = [];

  _.forEach(samples, (point: any) => {
    if (_.isString(point.value) || _.isFinite(point.value)) {
      const stringPointValue = point.value.toString();
      const match = stringPointValue.match(ENUM_REGEX);
      if (match) {
        // If the regex matches, then the sample is an enumerated value
        if (_.isUndefined(_.find(returnStringEnum, ['stringValue', match[2]]))) {
          key = parseInt(match[1], 10);
          stringValue = match[2];
          returnStringEnum.push({
            key,
            stringValue,
          });
        }
      } else {
        // Create map object containing sorted string names that have increasing integer values starting at zero
        if (!stringValueMap) {
          stringValueMap = _.chain(samples)
            .map('value')
            .uniq()
            .sort()
            .transform(function (map, val, i) {
              map[val] = i;
            }, {})
            .value();
        }

        // String
        if (_.isUndefined(_.find(returnStringEnum, ['stringValue', stringPointValue]))) {
          returnStringEnum.push({
            key: stringValueMap[stringPointValue],
            stringValue: stringPointValue,
          });
        }
      }
    }
  });

  return returnStringEnum;
}

type GetDataPropsParams = {
  /** Object container for arguments */
  payload: {
    /** Array of data points */
    samples: {
      /** x-value of a data point */
      key: number;
      /** y-value of a data point (not present for bound) */
      value: number;
      /** y-value of a data point (lower value for bound) */
      lower: number;
      /** y-value of a data point (upper value for bound) */
      upper: number;
      /** true if this data point is uncertain */
      isUncertain: boolean;
    }[];
    /** Unit of Measure to be displayed */
    valueUnitOfMeasure: string;
    timingInformation;
    meterInformation;
    interpolationMethod;
    capsuleSegmentSamples;
    color;
  };
  /** One of SAMPLE_OPTIONS (line, bar, ..) */
  sampleDisplayOption: string;
  /** startTime if series from capsule */
  minTime?: number;
  /** true if data has a lower and upper bound instead of just a value */
  hasBounds?: boolean;
};

export function getDataProps({ payload, sampleDisplayOption, minTime, hasBounds }: GetDataPropsParams) {
  const isStringSeries = payload.valueUnitOfMeasure === STRING_UOM;

  const props = {
    valueUnitOfMeasure: payload.valueUnitOfMeasure,
    timingInformation: payload.timingInformation,
    meterInformation: payload.meterInformation,
    interpolationMethod: payload.interpolationMethod,
    data: [],
  };

  if (_.get(payload, 'samples.length')) {
    if (!hasBounds && isStringSeries) {
      const stringEnum = createStringEnum(payload.samples);
      const capsuleSegment = transformDataPoints({
        rawSamples: payload.capsuleSegmentSamples,
        color: payload.color,
        minTime,
        sampleDisplayOption: '',
        hasBounds: false,
        stringEnum,
      });

      _.assign(
        props,
        transformDataPoints({
          rawSamples: payload.samples,
          color: payload.color,
          minTime,
          sampleDisplayOption,
          hasBounds: false,
          stringEnum,
        }),
        {
          isStringSeries,
          iconClass: ITEM_ICONS.STRING_SERIES,
          stringEnum,
          calculatedStringEnum: stringEnum,
          capsuleSegmentSamples: capsuleSegment.samples,
          capsuleSegmentData: capsuleSegment.data,
          originalData: null,
          originalStringEnum: null,
          step: payload.interpolationMethod === 'Step' ? 'left' : null,
        },
      );
    } else {
      const capsuleSegment = transformDataPoints({
        rawSamples: payload.capsuleSegmentSamples,
        color: payload.color,
        minTime,
        sampleDisplayOption: '',
        hasBounds,
        stringEnum: null,
      });
      _.assign(
        props,
        transformDataPoints({
          rawSamples: payload.samples,
          color: payload.color,
          minTime,
          sampleDisplayOption,
          hasBounds,
          stringEnum: null,
        }),
        {
          capsuleSegmentSamples: capsuleSegment.samples,
          capsuleSegmentData: capsuleSegment.data,
        },
      );
    }
  }

  return props;
}

/**
 * Prepares the formula for fetching signal segments used in the capsule time view.
 * @param seriesId - The series id
 * @param capsules - The set of capsules for which we calculate the query interval
 * @param chartWidth - The chart width
 * @param longestCapsuleDuration - The longest capsule duration
 * @returns formula and parameters for retrieving the samples of the signal segments
 */
export function buildSignalSegmentsFormula(
  seriesId: string,
  capsules: Capsule[],
  chartWidth: number,
  longestCapsuleDuration: number,
): {
  formula: string;
  limit: number;
  capsulesQueryIntervals: Capsule[];
  range: RangeExport;
} {
  const numPixels = Math.min(chartWidth, MAX_SERIES_PIXELS);
  const offset = sqTrendStore.capsuleTimeOffsets;
  const queryDataOutsideCapsules = sqTrendStore.dimDataOutsideCapsules;

  const capsulesQueryIntervals = findCapsulesQueryInterval(
    seriesId,
    capsules,
    offset,
    longestCapsuleDuration,
    sqDurationStore.displayRange.end.valueOf(),
    queryDataOutsideCapsules,
  );

  const maxCapsuleDuration = _.maxBy(capsulesQueryIntervals, (capsule) => capsule.endTime - capsule.startTime);
  const laneWidthMillis = getMSPerPixelWidth(maxCapsuleDuration.endTime - maxCapsuleDuration.startTime, numPixels);
  const laneWidth = `${NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND * laneWidthMillis}ns`;
  const formulaRangeStart = moment.utc(_.minBy(capsulesQueryIntervals, 'startTime').startTime);
  const formulaRangeEnd = moment.utc(_.maxBy(capsulesQueryIntervals, 'endTime').endTime);
  const range: RangeExport = {
    start: formulaRangeStart,
    end: formulaRangeEnd,
    duration: moment.duration(formulaRangeEnd - formulaRangeStart),
  };

  const conditionFormulaValue = conditionFormula(capsulesQueryIntervals);
  const downSampleFormula = sqTrendStore.buildDownsampleFormula(laneWidth, seriesId, conditionFormulaValue);
  const summarizeFormula = sqTrendStore.buildSummarizeFormula(seriesId);

  const formula = `$series${summarizeFormula}${downSampleFormula}.parallelize()`;
  const limit = capsules.length * chartWidth * SPIKECATCHER_PER_PIXEL;

  return { formula, limit, capsulesQueryIntervals, range };
}

/**
 * Creates a unique id for capsule series
 *
 * @param {string} capsuleId - The id of the capsule
 * @param {string} signalId - The id of the signal
 */
export function createUniqueCapsuleSeriesId(capsuleId: string, signalId: string): string {
  return `${capsuleId}${signalId}`;
}
