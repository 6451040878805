// @ts-strict-ignore
import _ from 'lodash';
import React, { useState } from 'react';
import { Icon, IconType } from '@/hybrid/core/Icon.atom';
import { TableFilterPopover } from '@/hybrid/core/tableUtilities/TableFilterPopover.organism';
import { TableColumnFilter } from '@/hybrid/core/tableUtilities/tables';
import { ThresholdOutputV1 } from '@/sdk/model/ThresholdOutputV1';
import { Placement } from 'react-bootstrap/Overlay';

interface FilterDropdownProps {
  columnKey: string;
  target: any;
  setColumnFilter?: (key: string, filter: TableColumnFilter) => void;
  iconType?: IconType;
  columnFilter?: TableColumnFilter;
  isFilterDisabled?: boolean;
  isStringColumn?: boolean;
  isDurationColumn?: boolean;
  distinctStringValues?: string[];
  helpText?: string;
  thresholds?: ThresholdOutputV1[] | undefined;
  placement?: Placement;
  onIconClick?: () => void;
}

export const TableFilterIcon: React.FunctionComponent<FilterDropdownProps> = (props) => {
  const {
    columnKey,
    target,
    setColumnFilter,
    iconType = 'theme',
    columnFilter = undefined,
    isFilterDisabled = false,
    isStringColumn = false,
    isDurationColumn = false,
    distinctStringValues = undefined,
    helpText = undefined,
    thresholds = undefined,
    placement = 'bottom',
    onIconClick = _.noop,
  } = props;

  const isFiltered = !_.isUndefined(columnFilter);

  const [showColumnFilterPopover, setShowColumnFilterPopover] = useState(false);

  const renderFilterPopover = () => (
    <TableFilterPopover
      columnFilter={columnFilter}
      columnKey={columnKey}
      notifyOnClose={() => setShowColumnFilterPopover(false)}
      setColumnFilter={(key, filter) => setColumnFilter(key, filter)}
      isStringColumn={isStringColumn}
      isDurationColumn={isDurationColumn}
      distinctStringValues={distinctStringValues}
      thresholds={thresholds}
      helpText={helpText}
      placement={placement}
      target={target?.current}
      show={!isFilterDisabled && showColumnFilterPopover}
    />
  );

  const getFilterIconType = (hasFilter) => (!hasFilter ? 'gray' : iconType ?? 'theme');

  return (
    <>
      {!isFilterDisabled && isFiltered && (
        <div
          className="sq-icon-hover cursorPointer"
          data-testid="columnFilterIconWrapper"
          id={`filterDropdown-${columnKey}`}>
          <Icon
            icon="fa-filter"
            extraClassNames="fa-fw mt2"
            testId="columnFilterIcon"
            onClick={() => {
              onIconClick();
              setShowColumnFilterPopover(true);
            }}
            type={getFilterIconType(isFiltered)}
          />
        </div>
      )}
      {renderFilterPopover()}
      {isFilterDisabled && isFiltered && (
        <Icon
          icon="fa-filter"
          extraClassNames="fa-fw mt2 ml5"
          testId="columnFilterIcon"
          type={getFilterIconType(isFiltered)}
        />
      )}
    </>
  );
};
