// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import drilldown from 'highcharts/modules/drilldown.js';
import { sqWorkbenchStore } from '@/core/core.stores';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { sqContentApi } from '@/sdk';
import { useTranslation } from 'react-i18next';
import {
  getAxisForStat,
  getDrilldownFromOutput,
  getSeriesFromOutput,
  getTitleForStat,
  TextFilters,
} from '@/hybrid/administration/reportAdmin.utilities';
import { ClearableInput } from '@/hybrid/core/ClearableInput.molecule';
import { cancelGroup } from '@/hybrid/requests/pendingRequests.utilities';
import { sqTimezones } from '@/hybrid/utilities/datetime.constants';
import { StatEnum } from '@/sdk/api/ContentApi';

const cancellationGroup = 'reportAdminChart';

interface ReportChartsProps {
  hide: boolean;
  toggleHideButton: JSX.Element;
  reportTextFilters: TextFilters;
  setReportTextFilters: (filters: TextFilters) => void;
  triggerFetch: () => void;
  trigger: boolean;
}

drilldown(Highcharts);

export const ReportCharts: React.FunctionComponent<ReportChartsProps> = ({
  hide,
  toggleHideButton,
  reportTextFilters,
  setReportTextFilters,
  triggerFetch,
  trigger,
}) => {
  const timezone = sqWorkbenchStore.userTimeZone || sqTimezones.defaultTimezone;

  const { t } = useTranslation();
  const [chartLoading, setChartLoading] = useState(false);
  const [start, setStart] = useState(moment().tz(timezone.name).startOf('day'));
  const [stat, setStat] = useState<StatEnum>(StatEnum.AverageRunTime);
  const [chart, setChart] = useState(undefined);
  const [filterFired, setFilterFired] = useState(false);

  const [options, setOptions] = useState({
    // TODO: CRAB-28794
    chart: {
      type: 'column',
      events: {
        drilldown: (e) =>
          setOptions({
            title: { text: getTitleForStat(stat, true, t, e.point.drilldown) },
          }),
        drillup: (e) => setOptions({ title: { text: getTitleForStat(stat, false, t) } }),
      },
    },
    title: {
      text: getTitleForStat(stat, false, t),
    },
    subtitle: {
      text: t('ADMIN.REPORT.CHARTS.SUBTITLE'),
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      type: 'category',
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  } as any);

  const fetchSummaryForChart = () => {
    setChartLoading(true);
    cancelGroup(cancellationGroup)
      .then(() => {
        if (chart?.drilldownLevels?.length > 0) {
          chart?.drillUp();
        }
      })
      .then(() =>
        sqContentApi
          .getReportSummary(
            {
              start: start.format(),
              stat,
              ownerFilter: reportTextFilters.owner,
            },
            { cancellationGroup },
          )
          .then(({ data: { results } }) =>
            setOptions({
              series: [
                {
                  name: getTitleForStat(stat, false, t),
                  colorByPoint: true,
                  data: getSeriesFromOutput(stat, results, t),
                },
              ],
              drilldown: {
                series: getDrilldownFromOutput(stat, results, t),
              },
              yAxis: {
                title: {
                  text: getAxisForStat(stat, t),
                },
              },
              tooltip: {
                enabled: false,
              },
            }),
          ),
      );
  };

  useEffect(() => {
    fetchSummaryForChart();
  }, [trigger, filterFired]);

  return (
    <div style={{ display: hide ? 'none' : 'block' }} className="flexRowContainer flexFill height-800">
      <div className="flexColumnContainer flexSpaceBetween">
        <div className="flexColumnContainer">
          <strong className="m-2">{t('ADMIN.REPORT.OWNER')}</strong>
          <div className="width-150">
            <ClearableInput
              field="owner"
              searchValue={reportTextFilters.owner}
              filterTable={(option, field) => setReportTextFilters({ ...reportTextFilters, owner: field })}
              enterCallback={() => {
                // Workaround for a race condition in which the filter isn't set by the time the fetch actually occurs.
                // Forces React to process its new props before fetching.
                setFilterFired(!filterFired);
                triggerFetch();
              }}
            />
          </div>
        </div>
        {toggleHideButton}
      </div>

      <div className="width-auto height-maximum">
        {chartLoading && <HighchartsReact highcharts={Highcharts} options={options} callback={setChart} />}
      </div>
    </div>
  );
};
