import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconSelect } from '@/hybrid/core/IconSelect.molecule';

interface AdvancedSearchSelectProps {
  extraClassNames: string;
  onChange: (option: any) => void;
  value: any;
  options: any[];
  label: string;
  id: string;
}

export const AdvancedSearchSelect: React.FunctionComponent<AdvancedSearchSelectProps> = ({
  extraClassNames,
  onChange,
  value,
  options,
  label,
  id,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb5" data-testid={id}>
        {t(label)}
      </div>
      <IconSelect
        extraClassNames={extraClassNames}
        selectOptions={options}
        name="advancedSearch"
        placeholder=""
        value={value}
        onChange={onChange}
        insideModal={true}
      />
    </div>
  );
};
