// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { TrendActions } from '@/trendData/trend.actions';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { ToolbarDropdownButton } from '@/hybrid/core/ToolbarDropdownButton.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { IconSelect, ReactSelectOption } from '@/hybrid/core/IconSelect.molecule';
import { CompareViewColorMode, TREND_PANELS } from '@/trendData/trendData.constants';
import { doTrack } from '@/track/track.service';
import { sqTrendStore } from '@/core/core.stores';

const compareViewColorModeButtonBindings = bindingsDefinition({
  isSmall: prop<boolean>(),
  sqTrendActions: injected<TrendActions>(),
});

export const CompareViewColorModeButton: SeeqComponent<typeof compareViewColorModeButtonBindings> = ({ isSmall }) => {
  const { sqTrendActions } = useInjectedBindings(compareViewColorModeButtonBindings);

  const { t } = useTranslation();

  const compareViewColorMode = useFluxPath(sqTrendStore, () => sqTrendStore.compareViewColorMode);
  const colorByProperty = useFluxPath(sqTrendStore, () => sqTrendStore.colorByProperty);
  const capsuleProperties = useFluxPath(sqTrendStore, () => sqTrendStore.capsuleProperties);

  const setCompareViewColorModeAndTrack = (mode: CompareViewColorMode) => {
    doTrack('Trend', `${mode} Compare View Color`);
    sqTrendActions.setCompareViewColorMode(mode);
  };

  const dropdownItems = _.map(CompareViewColorMode, (mode) => (
    <div
      key={mode}
      onClick={() => setCompareViewColorModeAndTrack(mode)}
      data-testid={`color-mode-${mode}`}
      className="colorModeButtonItem">
      <span className="flexColumnContainer flexFill flexAlignCenter">
        <Icon
          icon={mode === compareViewColorMode ? 'fa-check-circle' : 'fa-circle-thin'}
          type="inherit"
          extraClassNames="fa-fw mr5"
        />
        <span>{t(`TOOLBAR.${mode.toUpperCase()}`)}</span>
      </span>
      {mode === CompareViewColorMode.Rainbow && compareViewColorMode === CompareViewColorMode.Rainbow && (
        <div className="min-width-150">
          <IconSelect
            extraClassNames="small-select"
            value={colorByProperty}
            selectOptions={capsuleProperties}
            testId="colorByPropertySelect"
            onChange={(option: ReactSelectOption<string>) => {
              sqTrendActions.setColorByProperty(option.value);
              sqTrendActions.addPropertiesColumn(TREND_PANELS.CAPSULES, { propertyName: option.value }, true);
            }}
          />
        </div>
      )}
    </div>
  ));

  return (
    <ToolbarDropdownButton
      icon="fa-tint"
      label="TOOLBAR.COLOR"
      isSmall={isSmall}
      dropdownItems={dropdownItems}
      tooltipText="TOOLBAR.COMPARE.COLOR_TOOLTIP"
      tooltipPlacement="top"
      id="toolbar-compare-view-colors"
      testId="colorMenu"
    />
  );
};
