// @ts-strict-ignore
import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { LogSenderModal } from '@/hybrid/logSender/LogSenderModal';
import { LogTrackerFilters } from '@/hybrid/logTracker/LogTrackerFilters.molecule';
import { LogTrackerTable } from '@/hybrid/logTracker/LogTrackerTable.molecule';
import { parseQueryString } from '@/hybrid/utilities/utilities';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import {
  fetchLogs as fetchLogsSqLogTracker,
  FetchLogsOptions,
  generateMoreLogsOptions,
} from '@/hybrid/utilities/LogTracker.utilities';
import { LoadingFallback } from '@/hybrid/main/LoadingFallback.atom';

const logTrackerBindings = bindingsDefinition({});

const WrappedLogTracker: SeeqComponent<typeof logTrackerBindings> = () => {
  const messagesEndRef = useRef(null);
  const urlArguments = parseQueryString(location.search);

  const [isFetching, setIsFetching] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(
    _.has(urlArguments, 'limit') ? _.toNumber(decodeURIComponent(urlArguments.limit as string)) : 20,
  );
  const [endTime, setEndTime] = useState(
    _.has(urlArguments, 'endTime') ? decodeURIComponent(urlArguments.endTime as string) : '',
  );
  const [fetchOptions, setFetchOptions] = useState({});

  const setValidEndTime = (endTime) => {
    if (
      endTime == null ||
      endTime.match(/^\s*$/) ||
      endTime.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:?\d{2}$/)
    ) {
      setEndTime(endTime);
    }
  };

  /**
   * Scroll to the latest message in table
   */
  const scrollToLastMessage = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  /**
   * Retrieves logs going back from the oldest log message last retrieved for the current log file being examined.
   *
   * @param {boolean} append - True to append more logs, false to prepend them
   */
  const fetchMoreLogs = (append: boolean) => {
    setIsFetching(true);
    const options = _.defaults(generateMoreLogsOptions(append, data), fetchOptions);
    setScrollToBottom(append);
    fetchLogsSqLogTracker(options, data)
      .then(setData)
      .catch((error) => errorToast({ httpResponseOrError: error, displayForbidden: true }))
      .finally(() => {
        setIsFetching(false);
        if (append) {
          scrollToLastMessage();
        }
      });
  };

  /**
   * Returns log messages based on the selected log file based and input filters. Ensures that no more than one
   * request is queued at a time.
   *
   * @param {FetchLogsOptions} options
   */
  const fetchLogs = (options: FetchLogsOptions) => {
    setFetchOptions(options);
    setIsFetching(true);
    fetchLogsSqLogTracker(options, data)
      .then(setData)
      .catch((error) => errorToast({ httpResponseOrError: error, displayForbidden: true }))
      .finally(() => {
        setIsFetching(false);
        if (scrollToBottom) {
          scrollToLastMessage();
        }
      });
  };

  return (
    <>
      <div className="logTracker flexRowContainer flexFill">
        <div className="flexRowContainer">
          <LogTrackerFilters
            onModifyFilters={fetchLogs}
            onShowModal={setShowModal}
            isFetching={isFetching}
            setFetching={setIsFetching}
            endTime={endTime}
            setEndTime={setValidEndTime}
            limit={limit}
            setLimit={setLimit}
          />
        </div>
        <LogTrackerTable
          messages={data}
          modifyEndTime={setEndTime}
          messagesEndRef={messagesEndRef}
          onFetchMoreLogs={fetchMoreLogs}
          limit={limit}
          isFetching={isFetching}
        />
      </div>
      {showModal && <LogSenderModal onClose={() => setShowModal(false)} />}
    </>
  );
};

export const LogTracker: SeeqComponent<typeof logTrackerBindings> = () => {
  return (
    <React.Suspense fallback={<LoadingFallback fallbackLocation="Audit trail page" />}>
      <WrappedLogTracker />
    </React.Suspense>
  );
};

export const sqLogTracker = angularComponent(logTrackerBindings, LogTracker);
