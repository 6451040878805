// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ClearableInput } from '@/hybrid/core/ClearableInput.molecule';
import { useDebounce } from '@/hybrid/core/hooks/useDebounce.hook';
import { reformatZuluOffset } from '@/hybrid/datetime/dateTime.utilities';
import { APPEND_DIRECTION, LEVELS, LIMITS } from '@/hybrid/logTracker/LogTracker.constants';
import { DEBOUNCE } from '@/core/core.constants';
import { getURIArgument } from '@/hybrid/utilities/utilities';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { getAvailableLogs as getAvailableLogsSqLogTracker } from '@/hybrid/utilities/LogTracker.utilities';
import { TextButton } from '@/hybrid/core/TextButton.atom';

const logsFiltersBindings = bindingsDefinition({
  onShowModal: prop<(boolean) => void>(),
  onModifyFilters: prop<(object) => void>(),
  isFetching: prop<boolean>(),
  setFetching: prop<(boolean) => void>(),
  endTime: prop<string>(),
  setEndTime: prop<(string) => void>(),
  limit: prop<number>(),
  setLimit: prop<(number) => void>(),
});

export const LogTrackerFilters: SeeqComponent<typeof logsFiltersBindings> = (props) => {
  const { onShowModal, onModifyFilters, isFetching, setFetching, endTime, setEndTime, limit, setLimit } = props;
  const { t } = useTranslation();

  const [isLogsPageLoaded, setIsLogsPageLoaded] = useState<boolean>(false);
  const [logsIds, setLogsIds] = useState<string[]>([]); // app-server
  const [logId, setLogId] = useState<string>('');
  const [source, setSource] = useState<string>(getURIArgument(location.search, 'threadContains'));
  const [message, setMessage] = useState<string>(getURIArgument(location.search, 'messageContains'));
  const [level, setLevel] = useState<string>(getURIArgument(location.search, 'level', 'TRACE'));
  // if no refs are used here the system tests will use out of date values :(
  const currentLimit = useRef(limit);
  const currentEndTime = useRef(endTime);

  const selectInputStyle = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
    }),
  };
  useEffect(() => {
    if (isLogsPageLoaded) {
      return;
    }
    getAvailableLogs();
  }, []);

  useEffect(() => {
    currentLimit.current = limit;
    currentEndTime.current = endTime;
    if (isLogsPageLoaded) {
      debounceFetchLogs();
    }
  }, [endTime, logId, limit, level]);

  /**
   * Retrieves a collection of available log files for querying.
   */
  const getAvailableLogs = () => {
    setIsLogsPageLoaded(true);
    setFetching(true);
    getAvailableLogsSqLogTracker()
      .then((logs: string[]) => {
        setLogsIds(logs);
        setLogId(getURIArgument(location.search, 'log', _.head(logs)));
      })
      .catch((error) => errorToast({ httpResponseOrError: error, displayForbidden: true }))
      .finally(() => {
        setFetching(false);
      });
  };

  /**
   * Update URL query based on filters
   */
  const updateUrlQuery = () => {
    const logIdEscaped = encodeURI(logId);
    const messageEscaped = encodeURI(message);
    const sourceEscaped = encodeURI(source);
    const endTimeEscaped = encodeURI(currentEndTime.current);

    let filterUrlQuery = `/logs?log=${logIdEscaped}`;
    if (message.length > 0) {
      filterUrlQuery += `&messageContains=${messageEscaped}`;
    }
    if (source.length > 0) {
      filterUrlQuery += `&threadContains=${sourceEscaped}`;
    }
    if (endTime.length > 0) {
      filterUrlQuery += `&endTime=${endTimeEscaped}`;
    }
    filterUrlQuery += `&level=${level}`;
    filterUrlQuery += `&limit=${currentLimit.current}`;
    history.replaceState(null, null, filterUrlQuery);
  };

  /**
   * Trigger fetch logs event.
   */
  const debounceFetchLogs = useDebounce(() => {
    const fetchOptions = {
      log: logId,
      startTime: '',
      endTime: reformatZuluOffset(currentEndTime.current),
      limit: _.toNumber(currentLimit.current),
      level: _.toString(level),
      sourceContains: source,
      messageContains: message,
      appendToLogs: false,
      appendDirection: APPEND_DIRECTION.DOWN,
    };
    updateUrlQuery();
    onModifyFilters(fetchOptions);
  }, DEBOUNCE.MEDIUM);

  return (
    <div
      id="specFilterContainer"
      data-testid="LogFiltersContainer"
      className="logFiltersContainer flexColumnContainer lightGreyBorderBottom p10">
      <div className="flexRowContainer flexBasis250 pr10 min-width-60">
        <label className="control-label flexFill">{t('LOGS.LOG')}</label>
        <div data-testid="specLog_select">
          <Select
            id="specLogName"
            classNamePrefix="spec_Log_filter react-select"
            value={{ value: logId, label: logId, logId }}
            options={_.map(logsIds, (logId) => ({
              value: logId,
              label: logId,
              logId,
            }))}
            isDisabled={isFetching}
            isSearchable={true}
            onChange={(e) => setLogId(e.value)}
            styles={selectInputStyle}
          />
        </div>
      </div>
      <div className="flexRowContainer pr10 flexBasis100 min-width-60">
        <label className="control-label flexFill">{t('LOGS.MIN_LEVEL')}</label>
        <div data-testid="specLogSeverity_select">
          <Select
            id="specLogSeverity"
            classNamePrefix="spec_LogSeverity_filter react-select"
            value={{ value: level, label: level, level }}
            options={_.map(LEVELS, (level) => ({
              value: level,
              label: level,
              level,
            }))}
            isDisabled={isFetching}
            isSearchable={false}
            onChange={(e) => setLevel(e.value)}
            styles={selectInputStyle}
          />
        </div>
      </div>
      <div className="flexRowContainer pr10 flexFillOverflow min-width-125">
        <label className="control-label">{t('LOGS.SOURCE_CONTAINS')}</label>
        <div>
          <ClearableInput
            field="specThread"
            searchValue={source}
            disabled={isFetching}
            filterTable={(field, value) => setSource(value)}
            iconClassName="fa-search"
            onBlurCallback={debounceFetchLogs}
            enterCallback={debounceFetchLogs}
          />
        </div>
      </div>
      <div className="flexRowContainer pr10 flexFillOverflow min-width-125">
        <label>{t('LOGS.MESSAGE_CONTAINS')}</label>
        <ClearableInput
          field="specMessage"
          searchValue={message}
          disabled={isFetching}
          filterTable={(field, value) => setMessage(value)}
          iconClassName="fa-search"
          onBlurCallback={debounceFetchLogs}
          enterCallback={debounceFetchLogs}
          trimValue={false}
        />
      </div>
      <div className="flexRowContainer pr10 flexBasis200 min-width-125">
        <label>{t('LOGS.END_TIME')}</label>
        <ClearableInput
          field="specEndTime"
          searchValue={endTime}
          disabled={isFetching}
          filterTable={(field, value) => setEndTime(value)}
          iconClassName="fa-search"
          onBlurCallback={debounceFetchLogs}
          enterCallback={debounceFetchLogs}
        />
      </div>
      <div className="flexRowContainer pr10 flexBasis100 min-width-60">
        <label className="flexFill">{t('LOGS.QUANTITY')}</label>
        <div data-testid="specLogLimit_select">
          <Select
            id="specLogQuantity"
            classNamePrefix="spec_LogLimit_filter react-select"
            value={{ value: limit, label: limit, limit }}
            options={_.map(LIMITS, (limit) => ({
              value: limit,
              label: limit,
              limit,
            }))}
            isDisabled={isFetching}
            isSearchable={false}
            onChange={(e) => setLimit(e.value)}
            styles={selectInputStyle}
          />
        </div>
      </div>
      <div className="flexRowContainer flexBasis100">
        <label className="flexFill">&nbsp;</label>
        <TextButton
          id="openModalButton"
          testId="openModal_button"
          variant="theme"
          extraClassNames="mr5 height-32 nowrap"
          onClick={() => onShowModal(true)}
          disabled={isFetching}
          label="LOGS.SEND_LOGS"
        />
      </div>
    </div>
  );
};
