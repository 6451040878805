// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IdentityPreviewV1 } from '@/sdk';
import { loadTable, removeUsers as removeUsersSqAdministrationAction } from '@/administration/administration.actions';
import { DEFAULT_ACE } from '@/hybrid/accessControl/itemAclModal.utilities';
import { fetchLicense } from '@/licenseManagement/licenseManagement.actions';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { warnToast } from '@/hybrid/utilities/toast.utilities';

interface RemoveUserModalProps {
  onClose: () => void;
  selectedIds: string[];
}

export const RemoveUserModal: React.FunctionComponent<RemoveUserModalProps> = ({ onClose, selectedIds }) => {
  const { t } = useTranslation();

  const [newOwner, setNewOwner] = useState(_.clone(DEFAULT_ACE));
  const [transferAccessControl, setTransferAccessControl] = useState(true);

  const setIdentity = (identity) => {
    if (identity.id) {
      setNewOwner({ ...newOwner, identity });
    } else {
      setNewOwner(_.clone(DEFAULT_ACE));
    }
  };

  const formDefinition: FormElement[] = [
    {
      component: 'LabelFormComponent',
      name: 'newOwnerLabel',
      value: 'ADMIN.USER.MODAL.NEW_OWNER',
      extraClassNames: 'mb10 text-bolder',
    },
    {
      component: 'SelectIdentityFormComponent',
      name: 'selectIdentity',
      onChange: setIdentity,
      value: newOwner.identity as IdentityPreviewV1,
      idForLabel: 'newOwner',
      includeAllProperties: true,
      startEditable: true,
      autoFill: false,
      placeholder: 'ADMIN.USER.MODAL.ENTER_USERNAME',
      customErrorText: 'ADMIN.USER.MODAL.TRANSFER_ACL_ERROR',
      skipStore: true,
    },
    {
      component: 'RadioButtonGroupFormComponent',
      name: 'acl',
      value: transferAccessControl,
      onChange: _.noop,
      skipStore: true,
      id: 'aclRadios',
      label: 'ADMIN.USER.MODAL.ACCESS_CONTROL',
      extraClassNames: 'mt15 text-bolder',
      verticalLayout: true,
      options: [
        {
          id: 'transferAcl',
          label: 'ADMIN.USER.MODAL.TRANSFER_ACL',
          checked: transferAccessControl,
          onToggle: () => setTransferAccessControl(true),
        },
        {
          id: 'removeAcl',
          label: 'ADMIN.USER.MODAL.REMOVE_ACL',
          checked: !transferAccessControl,
          onToggle: () => setTransferAccessControl(false),
        },
      ],
    },
  ];

  /**
   * Removes the users in selectedIds and transfers ownership of workbooks to the new owner, then closes
   * the modal.
   *
   * @returns {Promise} that resolves when the update is complete
   */
  function removeUsers() {
    if (_.isEmpty(selectedIds)) {
      warnToast({ messageKey: 'ADMIN.USER.SELECTION_REQUIRED' });
      return Promise.resolve();
    }

    return removeUsersSqAdministrationAction({
      ids: selectedIds,
      newOwnerId: newOwner.identity.id,
      transferAclAndGroupMembership: transferAccessControl,
    })
      .then(fetchLicense)
      .then(loadTable)
      .then(onClose);
  }

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="removeUserModal">
      <Modal.Header closeButton={true}>
        <h3>{t('ADMIN.USER.MODAL.TITLE.REMOVE')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="removeUserModalBody">
          <div className="mb10">{t('ADMIN.USER.MODAL.REMOVE_DESCRIPTION')}</div>
          <div className="mb15">{t('ADMIN.USER.MODAL.SEARCH_FOR_NEW_OWNER')}</div>
          <SimpleSaveFormBuilder
            formDefinition={formDefinition}
            submitFn={removeUsers}
            closeFn={onClose}
            submitBtnLabel="ADMIN.USER.MODAL.TRANSFER_OWNERSHIP"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
