// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { sqUsersApi, UserInputV1 } from '@/sdk';
import { saveNewUser } from '@/administration/administration.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';
import { fetchAuthenticationProviders } from '@/hybrid/utilities/authentication.utilities';
import { fetchLicense } from '@/licenseManagement/licenseManagement.actions';

interface AddUserModalProps {
  onClose: () => void;
}

export const AddUserModal: React.FunctionComponent<AddUserModalProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const [domainOptions, setDomainOptions] = useState([]);
  const [directory, setDirectory] = useState({ text: '', value: '' });

  useEffect(() => {
    fetchAuthenticationProviders().then((data) => {
      const domainOptions = _.map(data, (domain) => ({
        text: _.get(domain, 'datasourceId'),
        value: _.get(domain, 'datasourceClass'),
      }));
      setDomainOptions(domainOptions);
      const seeqDomain = _.find(data, ['datasourceId', SeeqNames.LocalDatasources.Authentication.DatasourceId]);
      if (seeqDomain) {
        setDirectory({
          text: seeqDomain.datasourceId,
          value: seeqDomain.datasourceClass,
        });
      }
    });
  }, []);

  const seeqDomainName = SeeqNames.LocalDatasources.Authentication.DatasourceId;
  const isSeeqDirectory = directory.text === seeqDomainName;

  /**
   * Submits a request to generate a new user.
   *
   * @returns {Promise} that resolves after the user was created.
   */
  const addUser = (values): Promise<void> => {
    const { firstName, lastName, username, email, isAdmin, domain, newPassword } = values;

    let newUser = {
      firstName,
      lastName,
      name: `${_.trim(firstName)} ${_.trim(lastName)}`,
      email,
      isAdmin,
      username,
      password: '',
      datasourceId: '',
      datasourceClass: '',
    };

    if (domain.text === seeqDomainName) {
      newUser = {
        ...newUser,
        username: email,
        password: newPassword,
      };
    } else {
      newUser = {
        ...newUser,
        datasourceId: domain.text,
        datasourceClass: domain.value,
      };
    }

    return sqUsersApi
      .createUser(newUser as UserInputV1)
      .then(({ data }) => saveNewUser(data))
      .then(fetchLicense)
      .then(() => successToast({ messageKey: 'USER.ADDED' }))
      .then(onClose)
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  };

  const formDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'userProfile',
      components: [
        {
          component: 'IconSelectFormComponent',
          validation: _.isUndefined,
          name: 'domain',
          label: 'LOGIN_PANEL.DOMAIN',
          value: { text: directory.text, value: directory.value },
          onChange: _.noop,
          skipStore: true,
          selectOptions: domainOptions,
          insideModal: true,
          testId: 'domain',
          wrapperClassNames: 'mt8',
        },
        {
          component: 'FormControlFormComponent',
          name: 'firstName',
          label: 'USER.FIRST_NAME',
          value: '',
          onChange: _.noop,
          placeholder: 'USER.FIRST_NAME_PROMPT',
          extraClassNames: 'mb10',
          size: 'md',
          testId: 'firstName',
          required: true,
        },
        {
          component: 'FormControlFormComponent',
          name: 'lastName',
          label: 'USER.LAST_NAME',
          value: '',
          onChange: _.noop,
          placeholder: 'USER.LAST_NAME_PROMPT',
          extraClassNames: 'mb10',
          size: 'md',
          testId: 'lastName',
        },
        {
          component: 'FormControlFormComponent',
          name: 'email',
          label: 'USER.EMAIL',
          value: '',
          onChange: _.noop,
          placeholder: 'USER.EMAIL_PROMPT',
          extraClassNames: 'mb10',
          size: 'md',
          testId: 'email',
        },
        {
          component: 'CheckboxFormComponent',
          id: 'isAdmin',
          name: 'isAdmin',
          value: false,
          onChange: _.noop,
          skipStore: true,
          checkboxLabel: 'ADMIN.USER.ADMINISTRATOR',
          wrapperClassNames: 'mt8 mb10',
        },
        {
          component: 'FormControlFormComponent',
          name: 'username',
          label: 'USER.USERNAME',
          value: '',
          onChange: _.noop,
          placeholder: 'USER.USERNAME_PROMPT',
          wrapperClassNames: 'mb10',
          size: 'md',
          testId: 'username',
          includeIf: !isSeeqDirectory,
        },
        {
          component: 'PasswordGroupFormComponent',
          name: 'passwordFormGroup',
          includeIf: isSeeqDirectory,
          value: 'passwordFormGroup',
        },
      ],
    },
  ];

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="addUserModal">
      <Modal.Header closeButton={true}>
        <h3>{t('ADMIN.USER.MODAL.TITLE.ADD')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="addUserModalBody">
          <SimpleSaveFormBuilder formDefinition={formDefinition} submitFn={addUser} closeFn={onClose} />
        </div>
      </Modal.Body>
    </Modal>
  );
};
