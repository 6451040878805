// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { TextButton } from '@/hybrid/core/TextButton.atom';

const logsNavigationBindings = bindingsDefinition({
  onFetchMoreLogs: prop<(boolean) => void>(),
  isFetching: prop<boolean>(),
  limit: prop<number>(),
});

export const LogNavigation: SeeqComponent<typeof logsNavigationBindings> = ({ isFetching, onFetchMoreLogs, limit }) => {
  const buttonsProperties = [
    {
      tooltip: 'LOGS.FETCH_PREVIOUS_TOOLTIP',
      testId: 'fetch_more_up_button',
      className: 'fa-arrow-up',
      append: false,
    },
    {
      tooltip: 'LOGS.FETCH_NEXT_TOOLTIP',
      testId: 'fetch_more_down_button',
      className: 'fa-arrow-down',
      append: true,
    },
  ];

  return (
    <div data-testid="LogNavigationContainer" className="logTrackerNavigationButtons">
      {_.map(buttonsProperties, (buttonProp: any, index: number) => (
        <div className={`mt${10 * index}`} key={index}>
          <TextButton
            testId={buttonProp.testId}
            disabled={isFetching}
            tooltip={buttonProp.tooltip}
            tooltipOptions={{
              delay: 200,
              placement: 'left',
              translateParams: { count: _.toNumber(limit) },
            }}
            label=""
            icon={buttonProp.className}
            onClick={() => onFetchMoreLogs(buttonProp.append)}
          />
        </div>
      ))}
    </div>
  );
};
