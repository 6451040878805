// @ts-strict-ignore
import _ from 'lodash';
import angular from 'angular';
import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { flux } from '@/core/flux.module';
import { StoredStatistic } from '../StatisticSelector.molecule';
import { ValueWithUnitsItem } from '@/hybrid/trend/ValueWithUnits.atom';
import { INTERPOLATION_METHODS, SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.constants';
import { sqSignalFromConditionStore } from '@/core/core.stores';

angular.module('Sq.Investigate').service('sqSignalFromConditionActions', sqSignalFromConditionActions);
export type SignalFromConditionActions = ReturnType<typeof sqSignalFromConditionActions>;

function sqSignalFromConditionActions(sqToolRunner: ToolRunnerService, sqCalculationRunner: CalculationRunnerService) {
  const service = {
    generate,
    getFormula,
    getParameters,
  };

  return service;

  /**
   * Generate a signal from condition
   *
   * @param {String} conditionId - The id of the condition
   * @param {String} signalId - The id of the signal
   * @param {String} [color] - Color of the new item
   * @param {Object} [maxInterpolation] - The max interpolation
   * @param {Object} [params] - The params
   */
  function generate(conditionId, signalId, color, maxInterpolation, params) {
    return sqToolRunner.panelExecuteSignal(
      sqSignalFromConditionStore.name,
      service.getFormula(params, maxInterpolation),
      service.getParameters(conditionId, signalId),
      sqSignalFromConditionStore.configParams,
      sqSignalFromConditionStore.id,
      color,
    );
  }

  /**
   * Gets the formula for the given parameters
   *
   * @param {Object} params - The params from the form
   * @param {Object} [maxInterpolation] - The max interpolation (ignored if using discrete)
   * @return {String} The resulting formula
   */
  function getFormula(params, maxInterpolation) {
    let maxGapSnippet;
    let formula;
    const keyMethod = _.find(SAMPLE_FROM_SCALARS.KEY_METHODS, ['key', params.keyMethod]) as any;

    // We don't always need to set the max interpolation. We can omit it when the input capsule series has a
    // predictable spacing, for example weeks() or months().
    maxGapSnippet = _.isNil(maxInterpolation) ? '' : `, ${maxInterpolation.value}${maxInterpolation.units}`;

    // For Discrete output, explicitly provide it to aggregate instead of chaining it afterwards.
    if (params.interpolation === INTERPOLATION_METHODS.DISCRETE) {
      maxGapSnippet = ', 0s'; // Units don't matter since the value is zero
    }

    const step = params.interpolation === INTERPOLATION_METHODS.STEP ? '.toStep()' : '';
    const stat = sqCalculationRunner.getStatisticFragment(params.stat);

    let series = '$series';
    if (_.get(sqSignalFromConditionStore.inputItem, 'itemType') === ITEM_TYPES.CAPSULE_SET) {
      const inputMaximumDuration = _.get(sqSignalFromConditionStore, 'inputItem.conditionMetadata.maximumDuration');
      if (_.isUndefined(inputMaximumDuration)) {
        series += getMaximumDurationFragment(params, 'input');
      }
    }

    let capsules = '$capsules';
    const boundingMaximumDuration = _.get(sqSignalFromConditionStore, 'condition.conditionMetadata.maximumDuration');
    if (_.isUndefined(boundingMaximumDuration)) {
      capsules += getMaximumDurationFragment(params, 'bounding');
    }

    formula = `${series}.aggregate(${stat}, ${capsules}, ${keyMethod.stat}${maxGapSnippet})${step}`;

    return formula;
  }

  /**
   * Gets a formula snippet for the maximum duration override (e.g. '.setMaximumDuration(2d)')
   *
   * @param {Object} params - the params from the form
   * @param {string} name - the override name (either 'input' or 'bounding')
   * @returns {string} a maximum duration override snippet
   * @throws {Error} an error if value and/or units are not defined
   */
  function getMaximumDurationFragment(params, name) {
    const value = _.get(params, `maximumDuration.${name}Override.value`);
    const units = _.get(params, `maximumDuration.${name}Override.units`);
    if (!_.isUndefined(value) && !_.isUndefined(units)) {
      return `.setMaximumDuration(${value}${units})`;
    } else {
      throw new Error(`Signal from Condition ${name} Condition must have a value for maximumDuration`);
    }
  }

  /**
   * Gets the parameters
   *
   * @param {String} conditionId - The id of the condition
   * @param {String} signalId - The id of the signal
   * @return {Object} Describes the named parameters of the formula
   */
  function getParameters(conditionId, signalId) {
    return { capsules: conditionId, series: signalId };
  }
}

export const setInterpolation = (interpolation) => {
  flux.dispatch('SIGNAL_FROM_CONDITION_SET_INTERPOLATION', { interpolation });
};

export const setStat = (stat: StoredStatistic) => {
  flux.dispatch('SIGNAL_FROM_CONDITION_SET_STAT', { stat });
};

export const setInputOverride = (inputOverride: ValueWithUnitsItem) => {
  flux.dispatch('SIGNAL_FROM_CONDITION_SET_INPUT_OVERRIDE', { inputOverride });
};

export const setBoundingOverride = (boundingOverride: ValueWithUnitsItem) => {
  flux.dispatch('SIGNAL_FROM_CONDITION_SET_BOUNDING_OVERRIDE', {
    boundingOverride,
  });
};

export const setKeyMethod = (keyMethod: string) => {
  flux.dispatch('SIGNAL_FROM_CONDITION_SET_KEY_METHOD', { keyMethod });
};
