import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Icon } from '@/hybrid/core/Icon.atom';

interface IrregularScheduleProperties {
  schedules: string[];
  onConvertIt: () => void;
}

export const IrregularSchedule: React.FunctionComponent<IrregularScheduleProperties> = (props) => {
  const { schedules, onConvertIt } = props;

  const { t } = useTranslation();

  return (
    <FormGroup data-testid="live-schedule-config">
      <FormLabel>{t('REPORT.MODAL.AUTO_UPDATE.IRREGULAR_SCHEDULE')}</FormLabel>
      {_.map(schedules, (schedule) => (
        <FormControl
          key={schedule}
          readOnly={true}
          className="mb15"
          data-testid="irregular-schedule-config__cron-schedule"
          as="input"
          type="string"
          value={schedule}
        />
      ))}

      <div className="flexColumnContainer flexAlignStart">
        <Icon icon="fa-info-circle" large={true} extraClassNames="btn-transparent" />
        <span>{t('REPORT.CONFIG.SCHEDULE_IRREGULAR')}</span>
        <TextButton
          testId="irregular-schedule-config__convert-it"
          extraClassNames="width-250"
          label="REPORT.CONFIG.SCHEDULE_IRREGULAR_CONVERT_IT"
          onClick={onConvertIt}
        />
      </div>
    </FormGroup>
  );
};
