// @ts-strict-ignore
import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { ITEM_ICONS } from '@/main/app.constants';
import { Icon } from '@/hybrid/core/Icon.atom';
import classNames from 'classnames';
import { validateGuid } from '@/hybrid/utilities/utilities';
import { sqAnnotationStore } from '@/core/core.stores';

const annotationIconBindings = bindingsDefinition({
  item: prop<any>(),
  extraClassNames: prop.optional<string>(),
  sqAnnotationActions: injected<AnnotationActions>(),
});

export const AnnotationIcon: SeeqComponent<typeof annotationIconBindings> = ({ item, extraClassNames }) => {
  const { sqAnnotationActions } = useInjectedBindings(annotationIconBindings);
  const annotatedItemIds = useFluxPath(sqAnnotationStore, () => sqAnnotationStore.annotatedItemIds);

  const id = annotatedItemIds[item.id];
  const isCapsuleId = !validateGuid(item.id);

  const onIconClick = (e) => {
    e.stopPropagation();
    if (isCapsuleId) {
      sqAnnotationActions.showEntry(id);
    } else {
      sqAnnotationActions.closeEntry();
      sqAnnotationActions.showJournalTab();
    }
  };

  return id ? (
    <Icon
      icon={ITEM_ICONS.ANNOTATION}
      type="info"
      onClick={onIconClick}
      tooltip={isCapsuleId ? 'JOURNAL.TOOLTIP.ANNOTATION' : 'JOURNAL.TOOLTIP.ANNOTATION_LIST'}
      extraClassNames={classNames('specAnnotationIcon', extraClassNames)}
      testId="annotationIcon"
    />
  ) : null;
};

export const sqAnnotationIcon = angularComponent(annotationIconBindings, AnnotationIcon);
