// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { ReportActions } from '@/reportEditor/report.actions';

import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { Form, FormControl, Modal } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { SelectAssetModal } from '@/hybrid/core/SelectAssetModal.molecule';
import { FormulaService } from '@/services/formula.service';
import { sqTreesApi } from '@/sdk';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { getNextDefaultName, isAsset } from '@/hybrid/utilities/utilities';
import { AssetSelection, MAX_ASSETS_FOR_SELECTION } from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';
import { sqReportStore } from '@/core/core.stores';

const configureAssetSelectionModalBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  assetSelections: prop<AssetSelection[]>(),
  currentSelection: prop.optional<AssetSelection | null>(),
  openBulkEditWithGivenAssetSelection: prop<(assetSelection: AssetSelection) => void>(),
  sqReportActions: injected<ReportActions>(),
  sqFormula: injected<FormulaService>(),
});

export const ConfigureAssetsSelectionModal: SeeqComponent<typeof configureAssetSelectionModalBindings> = ({
  onClose,
  assetSelections,
  currentSelection = null,
  openBulkEditWithGivenAssetSelection,
}) => {
  const { sqReportActions, sqFormula } = useInjectedBindings(configureAssetSelectionModalBindings);

  const { t } = useTranslation();
  const getDefaultName = () =>
    getNextDefaultName(
      _.map(assetSelections, (selection) => selection.name),
      'REPORT.CONFIG.ASSET_SELECTION',
    );

  const [selectionName, setSelectionName] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedDepth, setSelectedDepth] = useState(1);
  const [currentPath, setCurrentPath] = useState(null);
  const [canSave, setCanSave] = useState(false);
  const selectionId = currentSelection ? currentSelection.id : undefined;
  const [isNameNeeded, setIsNameNeeded] = useState(!currentSelection);
  const isNew = !currentSelection?.id;
  const shouldShowBulkEditModal = isNew && sqReportStore.contentNotArchived.length > 0;
  const saveText = shouldShowBulkEditModal ? 'Next' : 'SAVE';
  const checkThatSelectedItemHasAtLeastOneNonAssetChild = (item): Promise<boolean> =>
    _.isEmpty(item.ancestors)
      ? Promise.resolve(false)
      : (sqTreesApi
          .getTree({ id: item.id, limit: MAX_ASSETS_FOR_SELECTION })
          .then(({ data }) => _.some(data.children, (child) => !isAsset(child))) as Promise<boolean>);

  useEffect(() => {
    if (selectedAsset) {
      sqFormula.getAssetPath(selectedAsset, selectedDepth).then((path) => setCurrentPath(path));
      checkThatSelectedItemHasAtLeastOneNonAssetChild(selectedAsset).then((isValid) =>
        setCanSave(isValid && !!selectionName),
      );
    } else {
      setCanSave(false);
    }
    if (isNameNeeded && !currentSelection) {
      setIsNameNeeded(false);
      setSelectionName(getDefaultName());
    }
  });

  useEffect(() => {
    if (currentSelection) {
      setSelectedAsset(currentSelection.asset);
      setSelectionName(currentSelection.name);
      setSelectedDepth(currentSelection.assetPathDepth);
      sqFormula
        .getAssetPath(currentSelection.asset, currentSelection.assetPathDepth)
        .then((path) => setCurrentPath(path));
    } else {
      selectedAsset && setSelectedAsset(null);
      setSelectionName(getDefaultName());
    }
  }, [currentSelection]);

  const clearAndClose = () => {
    setSelectedAsset(null);
    setSelectedDepth(1);
    setCurrentPath(null);
    setSelectionName('');
    onClose();
    setIsNameNeeded(true);
  };

  const onSave = () => {
    doTrack('Topic', 'Asset Selection', currentSelection ? 'Updated' : 'Created');
    const newSelection = {
      asset: selectedAsset,
      name: selectionName,
      id: selectionId,
      isArchived: currentSelection ? currentSelection.isArchived : false,
      reportId: sqReportStore.id,
      assetPathDepth: selectedDepth,
    };
    sqReportActions.saveAssetSelection(newSelection).then((updatedSelectionId) => {
      if (shouldShowBulkEditModal) {
        openBulkEditWithGivenAssetSelection({
          ...newSelection,
          id: updatedSelectionId,
        });
      }
      clearAndClose();
    });
  };

  const onDelete = (selection) => {
    sqReportActions.removeAssetSelection(selection).then(() => clearAndClose());
  };

  const onSelect = (item) => {
    setSelectedAsset(item);
    sqFormula.getAssetPath(item, selectedDepth).then((path) => setCurrentPath(path));
  };

  const onChangePathDepth = (event) => setSelectedDepth(_.toNumber(event.target.value));
  const saveButtonTooltip = selectionName
    ? 'REPORT.CONFIG.ASSET_SELECTION_NOT_VALID_TO_SAVE'
    : 'REPORT.CONFIG.ASSET_SELECTION_MUST_HAVE_NAME';
  const saveButtonExtraClass = canSave ? '' : 'noMouse';

  const pathElement = (
    <Form className="flexRowContainer alert alert-info">
      <div className="flexColumnContainer flexSpaceBetween ">
        <div className="flexRowContainer flexAlignStart">
          <label>{t('REPORT.ASSET_SELECTION_PATH')}</label>
          <span className="flexWrap textAlignLeft">{currentPath}</span>
        </div>
        <div className="flexRowContainer flexAlignEnd pl2">
          <label className="nowrap" htmlFor="assetSelectionPathLevelInput">
            {t('REPORT.ASSET_SELECTION_PATH_LEVEL')}
          </label>
          <FormControl
            className="max-width-70"
            id="assetSelectionPathLevelInput"
            name="value"
            type="number"
            value={selectedDepth}
            onChange={onChangePathDepth}
            min={1}
          />
        </div>
      </div>
    </Form>
  );

  const footer = (
    <Modal.Footer>
      <div className="flexRowContainer flexFill">
        {currentPath && pathElement}
        <div className="flexColumnContainer flexSpaceBetween pt2">
          <div>
            {currentSelection && (
              <TextButton
                onClick={() => onDelete(currentSelection)}
                label="DELETE"
                variant="danger"
                testId="assetSelectionDelete"
              />
            )}
          </div>
          {!currentSelection && shouldShowBulkEditModal && (
            <div className="flexSelfCenter flexSpaceBetween">{t('REPORT.CONFIG.ASSET_SELECTION_CONFIGURE_NEXT')}</div>
          )}
          <div>
            <TextButton
              onClick={clearAndClose}
              label="REPORT.CANCEL"
              extraClassNames="mr5"
              testId="assetSelectionCancel"
            />
            <HoverTooltip text={!canSave ? saveButtonTooltip : null}>
              <span data-testid={saveButtonTooltip}>
                <TextButton
                  testId="assetSelectionSave"
                  extraClassNames={saveButtonExtraClass}
                  onClick={onSave}
                  label={saveText}
                  variant="theme"
                  disabled={!canSave}
                />
              </span>
            </HoverTooltip>
          </div>
        </div>
      </div>
    </Modal.Footer>
  );

  const body = <div className="alert alert-info">{t('REPORT.ASSET_SELECTION_HELP_TEXT')}</div>;

  const header = (
    <Form.Group className="flexColumnContainer flexCenter flexFill mb0">
      <Icon icon="fa-cube" extraClassNames="fa-2x mt2 mr5" />
      <Form.Control
        autoComplete="off"
        type="text"
        value={selectionName}
        required={true}
        isInvalid={!selectionName}
        onChange={(e) => setSelectionName(e.target.value)}
        placeholder={t('REPORT.ASSET_SELECTION_NAME')}
        className="input-lg"
      />
    </Form.Group>
  );

  return (
    <SelectAssetModal
      header={header}
      footer={footer}
      body={body}
      onClose={clearAndClose}
      showHeaderCloseButton={false}
      assetId={selectedAsset?.id}
      iconPartialTooltipKey="REPORT.CONFIG"
      onSelect={onSelect}
      validateAsset={checkThatSelectedItemHasAtLeastOneNonAssetChild}
      scopeIds={sqReportStore.contentWorkbookIds}
      modalId="reportAssetModal"
    />
  );
};
