import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import React, { useMemo, useRef, useState } from 'react';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import {
  createPropertiesDefinition,
  generateFormula,
  generateParameters,
  modifiedSelectedProperty,
} from '@/hybrid/tools/conditionWithProperties/conditionWithProperties.utilities';
import { addProperty } from '@/hybrid/tools/conditionWithProperties/conditionWithProperties.actions';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { HelpButton } from '@/hybrid/core/HelpButton.atom';
import { TrendActions } from '@/trendData/trend.actions';
import { useTranslation } from 'react-i18next';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { TREND_PANELS } from '@/trendData/trendData.constants';
import { doTrack } from '@/track/track.service';
import { sqConditionWithPropertiesStore, sqTrendStore } from '@/core/core.stores';
import _ from 'lodash';

const conditionWithPropertiesBindings = bindingsDefinition({
  sqTrendActions: injected<TrendActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqToolRunner: injected<ToolRunnerService>(),
});

export const ConditionWithProperties: SeeqComponent<typeof conditionWithPropertiesBindings> = () => {
  const { sqInvestigateActions, sqTrendActions, sqToolRunner } = useInjectedBindings(conditionWithPropertiesBindings);

  const { t } = useTranslation();

  const { id, name, properties, configParams } = useFlux(sqConditionWithPropertiesStore);
  const separateByProperty = useFluxPath(sqTrendStore, () => sqTrendStore.separateByProperty);
  const colorByProperty = useFluxPath(sqTrendStore, () => sqTrendStore.colorByProperty);
  const seperateByIndex = useMemo(
    () => _.findIndex(properties, { propertyName: separateByProperty }),
    [separateByProperty],
  );
  const colorByIndex = useMemo(() => _.findIndex(properties, { propertyName: colorByProperty }), [colorByProperty]);

  const popoverRef = useRef<HTMLDivElement>(null);
  const [color, setColor] = useState('');

  const formDefinition: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'conditionWithPropertiesSearchTitle',
      value: name,
      onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.CONDITION_WITH_PROPERTIES, name),
      id,
      onColorChange: setColor,
      // TODO: CRAB-28957 - update icon below
      searchIconClass: 'fc-capsule-set',
      defaultName: 'CONDITION_WITH_PROPERTIES.HEADER',
      label: '',
    },
    {
      component: 'DisplayOnlyFormElementWrapper',
      name: 'helpTooltip',
      children: (
        <div className="flexColumnContainer flexJustifyEnd pt10 pr15" ref={popoverRef}>
          <HelpButton
            iconClassNames="fa-xlg"
            placement="right"
            customChildren={
              <div className="width-650">
                <ol className="pl15">
                  <li>{t('CONDITION_WITH_PROPERTIES.HELP.EXECUTE')}</li>
                  <img
                    src="/img/batch-instructional-screen-3-steps.gif"
                    width="630px"
                    data-testid="compareViewConditionHelpPopover"
                  />
                  <li>{t('CONDITION_WITH_PROPERTIES.HELP.COMPARE_MODE')}</li>
                </ol>
              </div>
            }
          />
        </div>
      ),
    },
    {
      component: 'FormGroup',
      name: 'propertiesFormGroup',
      displayNumber: false,
      components: createPropertiesDefinition(properties),
    },
    {
      component: 'ClickableLinkFormComponent',
      extraClassNames: 'ml45 mb10',
      name: 'addSignalCondition',
      linkTestId: 'addAnotherPropertyLink',
      value: 'CONDITION_WITH_PROPERTIES.ADD_PROPERTY',
      icon: 'fa-plus-circle',
      linkAction: addProperty,
    },
  ];

  const createCondition = () => {
    const parameters = generateParameters(properties);
    const formula = generateFormula(properties);
    const separateBy = properties[0].propertyName;
    const colorBy = properties[1]?.propertyName;

    return sqToolRunner
      .panelExecuteCondition(name, formula, parameters, configParams, id, color)
      .then(() => {
        const columns = [];
        const modifiedSeparateBy = modifiedSelectedProperty(seperateByIndex, properties, separateByProperty);
        const separateBy = modifiedSeparateBy ?? properties[0].propertyName;
        if (!separateByProperty || modifiedSeparateBy || _.isNull(modifiedSeparateBy)) {
          sqTrendActions.setSeparateByProperty(separateBy);
          columns.push({ propertyName: separateBy });
        }
        const modifiedColorBy = modifiedSelectedProperty(colorByIndex, properties, colorByProperty);
        const colorBy = modifiedColorBy ?? properties[1]?.propertyName ?? separateBy;
        if (colorBy && (!colorByProperty || modifiedColorBy || _.isNull(modifiedColorBy))) {
          sqTrendActions.setColorByProperty(colorBy);
          columns.push({ propertyName: colorBy });
        }
        if (columns.length > 0) {
          sqTrendActions.addMultiplePropertiesColumn(TREND_PANELS.CAPSULES, columns, true);
        }
      })
      .finally(() => doTrack('Trend', 'Compare Mode', 'create condition executed'));
  };

  return (
    <ToolPanelFormBuilder
      formDefinition={formDefinition}
      submitFn={createCondition}
      closeFn={sqInvestigateActions.close}
      toolId={TREND_TOOLS.CONDITION_WITH_PROPERTIES}
      submitBtnId="conditionWithPropertiesButton"
    />
  );
};
