// @ts-strict-ignore
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { SEEQ_VERSION } from '@/services/buildConstants.service';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from 'react-i18next';
import { LoginForm } from '@/hybrid/main/auth/LoginForm.organism';
import { RegisterForm } from '@/hybrid/main/auth/RegisterForm.organism';
import { getStorageSafely } from '@/hybrid/utilities/storage.utilities';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { fetchAuthenticationProviders } from '@/hybrid/utilities/authentication.utilities';
import { LoadingBar } from '@/hybrid/main/LoadingBar.molecule';
import {
  atUserLimit as isAtUserLimit,
  authDefaultProviderId,
  fetchConfiguration,
  getLoginMessage,
  registrationEnabled as isRegistrationEnabled,
} from '@/services/systemConfiguration.utilities';
import { LoadingFallback } from '@/hybrid/main/LoadingFallback.atom';
import { ContainerWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';

const sqLoginBindings = bindingsDefinition({});

const WrappedLogin: SeeqComponent<typeof sqLoginBindings> = () => {
  const { t } = useTranslation();
  const [availableDomains, setAvailableDomains] = useState([]);
  const [displayLogin, setDisplayLogin] = useState(true);
  const [config, setConfig] = useState<any>({});

  const { atUserLimit, registrationEnabled } = config;

  const seeqDomain = _.find(availableDomains, ['datasourceId', SeeqNames.LocalDatasources.Authentication.DatasourceId]);

  const getDomain = () =>
    _.find(availableDomains, ['datasourceId', getStorageSafely().getItem('sqDomain')]) ||
    _.chain(availableDomains)
      .reject(['datasourceId', SeeqNames.LocalDatasources.Authentication.DatasourceId])
      .sortBy((domain) => {
        const defaultId = !_.isEmpty(config) && authDefaultProviderId();
        if (!defaultId) {
          return 1;
        }
        // Sort the list of domains so that the defaultId is first
        return domain.datasourceId === defaultId ? 0 : 1;
      })
      .first()
      .value() ||
    seeqDomain ||
    {};

  const [domain, setDomain] = useState(getDomain());

  useEffect(() => {
    fetchConfiguration().then(() => {
      setConfig({
        atUserLimit: isAtUserLimit(),
        registrationEnabled: isRegistrationEnabled(),
        loginMessage: getLoginMessage(),
      });
    });

    // Requests a list of supported authentication providers
    fetchAuthenticationProviders().then(setAvailableDomains);
  }, []);

  useEffect(() => setDomain(getDomain()), [seeqDomain, availableDomains, config]);

  const isSeeqAuthSelected = domain.datasourceId === SeeqNames.LocalDatasources.Authentication.DatasourceId;

  /**
   * Toggles the display mode between login and registration form.
   */
  const toggleDisplay = () => {
    setDisplayLogin((displayLogin) => !displayLogin);
  };

  const updateDomain = (value: string, property = 'datasourceId') => {
    const newDomain = _.find(availableDomains, [property, value]) || domain;
    setDomain(newDomain);

    return newDomain;
  };

  const renderForms = () => {
    if (_.isEmpty(availableDomains) || _.isEmpty(domain) || _.isEmpty(config)) {
      return (
        <div className="flexRowContainer flexAlignCenter">
          <IconWithSpinner spinning={true} />
        </div>
      );
    }

    return (
      <>
        {displayLogin && <LoginForm domain={domain} availableDomains={availableDomains} updateDomain={updateDomain} />}

        {!displayLogin &&
          (atUserLimit ? (
            <div>{t('LOGIN_PANEL.USER_LIMIT')}</div>
          ) : (
            <RegisterForm
              seeqDomain={seeqDomain}
              domain={domain}
              updateDomain={updateDomain}
              toggleDisplay={toggleDisplay}
            />
          ))}

        {registrationEnabled && displayLogin && isSeeqAuthSelected && (
          <div className="mt25 text-center">
            <span>{t('LOGIN_PANEL.NO_ACCOUNT')}</span>
            &nbsp;
            <a href="#" onClick={toggleDisplay} data-testid="registerNowLink">
              {t('LOGIN_PANEL.REGISTER_NOW')}
            </a>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <LoadingBar />
      <div className="loginFooter pl10 pr10 sq-darkish-gray font-size-smaller">
        <small>{SEEQ_VERSION}</small>
      </div>

      <div data-testid="loginPage" className="flexRowContainer fullViewport splashScreenLight loginBackdrop">
        <div className="loginPanel flexRowContainer min-width-400 overlayPanel flexFill flexCenter col-sm-3 offset-sm-2">
          <div className="width-maximum pl20 pr20">
            <div data-testid="logoAndSlogan" className="flexRowContainer flexCenter mb25">
              <img src="/img/Seeq_logo_darkBlue_sm.png" className="mb15" />
              <span className="h4 loginSlogan">{t('LOGIN_PANEL.SLOGAN')}</span>

              {!displayLogin && !atUserLimit && (
                <span className="text-color-default">{t('LOGIN_PANEL.REGISTRATION_PROMPT')}</span>
              )}
            </div>

            {renderForms()}
            {config.loginMessage !== '' ? (
              <div className="mt20">
                <ContainerWithHTML id="loginMessage" content={config.loginMessage} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

// Wrap in Suspense to ensure translations are loaded before displaying the component
export const Login: SeeqComponent<typeof sqLoginBindings> = () => {
  return (
    <React.Suspense fallback={<LoadingFallback fallbackLocation="Login page" />}>
      <WrappedLogin />
    </React.Suspense>
  );
};

export const sqLogin = angularComponent(sqLoginBindings, Login);
