// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { sqHomeScreenStore, sqWorkbenchStore } from '@/core/core.stores';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import Img from 'react-image';
import { Icon } from '@/hybrid/core/Icon.atom';
import _ from 'lodash';
import { VideoModal } from '@/hybrid/core/VideoModal.organism';
import { EnglishOnlyWarning } from '@/hybrid/core/EnglishOnlyWarning.atom';

import { HelpDisplay } from './homescreen.constants';
import { doTrack } from '@/track/track.service';

const homeScreenWelcomeBindings = bindingsDefinition({
  sqHomeScreenActions: injected<HomeScreenActions>(),
});

const COLLAPSE_HEIGHT = 700;

export const HomeScreenWelcome: SeeqComponent<typeof homeScreenWelcomeBindings> = () => {
  const { sqHomeScreenActions } = useInjectedBindings(homeScreenWelcomeBindings);
  const { t } = useTranslation();

  const [showVideoModal, setShowVideoModal] = useState(false);

  const userFirstName = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser.firstName);
  const helpDisplay: HelpDisplay = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.helpDisplay);
  const isHelpCollapsed = helpDisplay === 'COLLAPSED';
  const showHelp = _.includes(['COLLAPSED', 'EXPANDED'], helpDisplay);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < COLLAPSE_HEIGHT && helpDisplay === 'EXPANDED') {
        sqHomeScreenActions.setHelpDisplay(HelpDisplay.COLLAPSED);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const content = [
    {
      title: 'HOME_SCREEN.VIDEO_TITLE',
      onClick: () => {
        setShowVideoModal(true);
        doTrack('Training', 'home screen intro video opened');
      },
      videoLength: 3,
      englishOnly: true,
      icon: 'fa-play-circle-o',
      testId: 'seeqIntroVideo',
      previewComponent: (
        <div>
          <Img className="cursorPointer" src="/img/seeq-with-play-button.png" width="100%" />
        </div>
      ),
    },
    {
      title: 'HOME_SCREEN.QUICK_START',
      shortTitle: 'HOME_SCREEN.QUICK_START_GUIDE',
      description: 'HOME_SCREEN.QUICK_START_DESCRIPTION',
      onClick: () => {
        window.open('https://telemetry.seeq.com/quick-start', '_blank', 'noopener,noreferrer');
        doTrack('Training', 'home screen quick start guide opened');
      },
      videoLength: 30,
      englishOnly: true,
      icon: 'fc-quick-start',
      testId: 'quickStart',
      previewComponent: (
        <div className="flexRowContainer flexAlignCenter flexJustifyCenter width-100percent height-maximum sq-bg-theme">
          <div>
            <Icon icon="fc-quick-start" type="white" extraClassNames="m20 fs5vw" />
          </div>
        </div>
      ),
    },
  ];

  return (
    showHelp && (
      <>
        {showVideoModal && (
          <VideoModal
            videoSource="https://telemetry.seeq.com/seeq-university-intro-video"
            testId="homeScreenVideoModal"
            backupLink="https://telemetry.seeq.com/seeq-university-intro"
            onClose={() => setShowVideoModal(false)}
          />
        )}

        <div className="ml20 mr20 mb20 flexRowContainer flexAlignCenter lightGreyBorder border-radius-4 positionRelative">
          <div className="positionAbsolute welcomeActions">
            <Icon
              onClick={() =>
                sqHomeScreenActions.setHelpDisplay(isHelpCollapsed ? HelpDisplay.EXPANDED : HelpDisplay.COLLAPSED)
              }
              icon={isHelpCollapsed ? 'fa-chevron-up' : 'fa-chevron-down'}
              extraClassNames="fa-xlg mr10 cursorPointer hoverGray"
              testId="welcomeCollapsedIcon"
              type="gray"
            />
            <Icon
              onClick={() => {
                sqHomeScreenActions.setHelpDisplay(HelpDisplay.POPOVER);
                doTrack('Training', 'home screen welcome closed');
              }}
              icon="fa-close"
              extraClassNames="fa-xlg cursorPointer hoverGray"
              testId="welcomeDismissIcon"
              type="gray"
            />
          </div>

          <div className="flexRowContainer flexCenter width-100percent">
            {isHelpCollapsed ? (
              <>
                <h3 className="text-bolder mb0">{t('HOME_SCREEN.WELCOME')}</h3>
                <div className="flexColumnContainer width-100percent">
                  {_.map(content, (item) => (
                    <div
                      onClick={item.onClick}
                      data-testid={item.testId}
                      key={item.testId}
                      className="flexRowContainer homeScreenWelcomeTile m20 flexFillEqually cursorPointer">
                      <div className="flexColumnContainer flexWrap flexJustifyCenter flexAlignCenter">
                        <div className="sq-text-primary fs18 flexColumnContainer flexNoWrap flexAlignCenter">
                          <div>
                            <Icon icon={item.icon} extraClassNames="mr10 fa-xlg" />
                          </div>
                          {item.shortTitle ? t(item.shortTitle) : t(item.title)}
                        </div>
                        <div className="ml5 small">
                          ({item.videoLength} {t('UNITS.MINUTES')})
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <h1 className="text-bolder">{t('HOME_SCREEN.WELCOME')}</h1>
                <h4 className="mr20 ml20">{t('HOME_SCREEN.NEW_USER_MESSAGE', { name: userFirstName })}</h4>
                <div className="flexColumnContainer width-100percent">
                  {_.map(content, (item) => (
                    <div
                      className="flexRowContainer homeScreenWelcomeTile m20 flexFillEqually"
                      data-testid={item.testId}
                      key={item.testId}>
                      <div className="flexColumnContainer height-maximum">
                        <div className="flexRowContainer flexFillEqually flexJustifyCenter flexAlignCenter mr20">
                          <div
                            onClick={item.onClick}
                            data-testid={`${item.testId}-previewComponent`}
                            className="flexFillEqually width-maximum cursorPointer flexJustifyCenter flexRowContainer sq-bg-theme">
                            {item.previewComponent}
                          </div>
                        </div>
                        <div className="flexFillEqually flexRowContainer flexJustifyCenter">
                          <h3 className="text-bolder sq-text-primary">{t(item.title)}</h3>
                          {item.description && <div className="fs15 mb5">{t(item.description)}</div>}
                          <div className="mb5">
                            ({item.videoLength} {t('UNITS.MINUTES')})
                          </div>
                          {item.englishOnly && <EnglishOnlyWarning />}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    )
  );
};
