// @ts-strict-ignore
import _ from 'lodash';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { formatDateWorkbook } from '@/hybrid/datetime/dateTime.utilities';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { Icon } from '@/hybrid/core/Icon.atom';
import { WorksheetThumbnail } from '@/hybrid/worksheets/WorksheetThumbnail.atom';

const selectWorksheetBindings = bindingsDefinition({
  workbookId: prop<string>(),
  worksheetIds: prop<string[]>(),
  workstepIds: prop<string[]>(),
  setWorksheetIds: prop<([]: any) => void>(),
  setWorkstepIds: prop<([]: any) => void>(),
  setTotalWorksheets: prop<(number) => void>(),
  isLoading: prop.optional<boolean>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
});
export const SelectWorksheet: SeeqComponent<typeof selectWorksheetBindings> = (props) => {
  const { sqHomeScreenUtilities, sqWorkbenchActions } = useInjectedBindings(selectWorksheetBindings);
  const {
    isLoading = false,
    workbookId,
    worksheetIds,
    workstepIds,
    setWorksheetIds,
    setWorkstepIds,
    setTotalWorksheets,
  } = props;

  const [worksheets, setWorksheets] = useState([]);
  const [workbook, setWorkbook] = useState(undefined);

  const setSelectedWorksheets = (worksheetId: string, workstepId: string, totalWorksheets: number) => {
    _.includes(worksheetIds, worksheetId)
      ? setWorksheetIds(_.without(worksheetIds, worksheetId))
      : setWorksheetIds(worksheetIds.concat(worksheetId));
    _.includes(workstepIds, workstepId)
      ? setWorkstepIds(_.without(workstepIds, workstepId))
      : setWorkstepIds(workstepIds.concat(workstepId));
    setTotalWorksheets(totalWorksheets);
  };

  useEffect(() => {
    sqHomeScreenUtilities
      .getWorkbook(workbookId, { includeWorkstepId: true })
      .then((workbook) => {
        if ((_.isEmpty(worksheetIds) || _.first(worksheetIds) === undefined) && workbook?.worksheets?.length === 1) {
          const worksheet = _.first(workbook.worksheets) as any;
          setSelectedWorksheets(worksheet.worksheetId, worksheet.workstepId, 1);
        }
        setWorkbook(workbook);
      })
      .finally(() => sqWorkbenchActions.setOpeningAndLoadingItemId(null));
  }, []);

  useEffect(
    () =>
      workbook &&
      setWorksheets(
        _.map(workbook.worksheets, (worksheet) => (
          <div
            data-testid={`${worksheet.worksheetId}_selectWorksheet`}
            key={worksheet.worksheetId}
            className={classNames(
              'worksheetContainer cursorPointer text-center mr15 mb15',
              _.includes(worksheetIds, worksheet.worksheetId) ? 'active-worksheet' : '',
            )}
            id={worksheet.worksheetId}
            onClick={() =>
              setSelectedWorksheets(worksheet.worksheetId, worksheet.workstepId, workbook.worksheets.length)
            }>
            <WorksheetThumbnail
              workbookId={workbookId}
              worksheetId={worksheet.worksheetId}
              isActive={false}
              updatedAt={undefined}
            />
            <div className="mt5">
              {`${worksheet.name} `}
              {formatDateWorkbook(worksheet.updatedAt)}
            </div>
          </div>
        )),
      ),
    [workbook, worksheetIds, workstepIds],
  );

  return (
    <div className="flexRowContainer" data-testid="report-content-worksheet">
      <div className="report-worksheets-list flexColumnContainer flexFill flexWrap flexCenter">
        {_.isEmpty(worksheets) || isLoading ? (
          <Icon icon="fa-spinner fa-pulse" large={true} extraClassNames="sq-fairly-dark-gray" />
        ) : (
          worksheets
        )}
      </div>
    </div>
  );
};
