// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { Modal } from 'react-bootstrap';
import { ReportActions } from '@/reportEditor/report.actions';

import { ReportContentStore } from '@/reportEditor/reportContent.store';
import _ from 'lodash';
import { workstepFromResponse } from '@/hybrid/worksteps/worksteps.utilities';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { ContentWithMetadataOutputV1, sqContentApi } from '@/sdk';
import { findWorkSheetView } from '@/hybrid/worksheets/worksheetView.utilities';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { ReportContentModalButtons } from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/ReportContentModalButtons.molecule';
import { ReportContentBulkEditTable } from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/ReportContentBulkEditTable.molecule';
import { ReportContentBulkEditProperties } from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/ReportContentBulkEditProperties.molecule';
import { TableLoadingIndicator } from '@/hybrid/core/TableLoadingIndicator.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { WorksheetView } from '@/worksheet/worksheet.constants';

import {
  Content,
  KEEP_CURRENT_ASSET_SELECTION,
  KEEP_CURRENT_DATE_RANGE,
  KEEP_CURRENT_INTERACTIVE,
  KEEP_CURRENT_SCALE,
  KEEP_CURRENT_SHAPE,
  KEEP_CURRENT_SIZE,
  KEEP_CURRENT_SUMMARY,
  REPORT_CONTENT,
  ReportContentShape,
  ReportContentSize,
} from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';
import { sqReportStore } from '@/core/core.stores';
import {
  formatContentFromApiOutput,
  getContentIdsInDocumentOrder,
} from '@/hybrid/utilities/froalaReportContent.utilities';
import { getContentIdsInSelection } from '@/hybrid/utilities/froalaReportContent.helper';
import {
  BulkEditMode,
  InteractiveOptions,
} from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/reportContent.constants';

const reportContentBulkEditModalBindings = bindingsDefinition({
  show: prop<boolean>(),
  $state: injected<ng.ui.IStateService>(),
  sqReportActions: injected<ReportActions>(),
  sqReportContentStore: injected<ReportContentStore>(),
  sqReportContentActions: injected<ReportContentActions>(),
  sqReportContent: injected<ReportContentService>(),
});

export const ReportContentBulkEditModal: SeeqComponent<typeof reportContentBulkEditModalBindings> = ({ show }) => {
  const { sqReportContent, sqReportContentActions, sqReportActions } = useInjectedBindings(
    reportContentBulkEditModalBindings,
  );
  const { t } = useTranslation();
  const {
    bulkEditDisplayMode: displayMode,
    isBulkEditAdvancedOnly: isAdvancedOnly,
    bulkAssetSelection,
    bulkDateRange,
    shouldUpdateBulkWorkstep,
    bulkInteractive,
    bulkSize,
    bulkShape,
    bulkScale,
    bulkSummary,
  } = useFlux(sqReportStore);

  const [contentWithAdditionalProperties, setContentWithAdditionalProperties] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [isAdvancedDisplay, setIsAdvancedDisplay] = useState(isAdvancedOnly);
  const inheritFromWorksheet = t('REPORT.CONFIG.INHERIT_FROM_WORKSHEET');
  const dialogClassName = isAdvancedDisplay ? 'bulkEditModal' : '';
  const executeLabel = isAdvancedDisplay ? 'UPDATE' : 'ATTACH';
  const REDACTED = 'REPORT.CONFIG.BULK_EDIT_TABLE.REDACTED';
  const NOT_APPLICABLE = 'ADMIN.NOT_APPLICABLE';

  const shouldInheritDateRange = _.isNil(bulkDateRange);
  const shouldInheritAssetSelection = _.isNil(bulkAssetSelection);
  const shouldKeepWorkstep = !shouldUpdateBulkWorkstep;
  const shouldKeepContentInteractive = bulkInteractive.key === KEEP_CURRENT_INTERACTIVE.key;
  const shouldKeepContentShape = bulkShape.key === KEEP_CURRENT_SHAPE.key;
  const shouldKeepContentSize = bulkSize.key === KEEP_CURRENT_SIZE.key;
  const shouldKeepFontSize = bulkScale.key === KEEP_CURRENT_SCALE.key;
  const shouldKeepDateRange = !shouldInheritDateRange && bulkDateRange.id === KEEP_CURRENT_DATE_RANGE.id;
  const shouldKeepAssetSelection =
    !shouldInheritAssetSelection && bulkAssetSelection.id === KEEP_CURRENT_ASSET_SELECTION.id;
  const shouldKeepSummarization = !_.isNil(bulkSummary) && bulkSummary.key === KEEP_CURRENT_SUMMARY.key;

  const shouldKeepBasicProperties =
    shouldKeepWorkstep &&
    shouldKeepContentInteractive &&
    shouldKeepContentSize &&
    shouldKeepContentShape &&
    shouldKeepFontSize &&
    shouldKeepSummarization;

  const convertSummaryToString = (summary) => {
    if (summary === undefined) {
      return inheritFromWorksheet;
    }
    const summaryName = t(summary.name);
    if (summary.key === REPORT_CONTENT.SUMMARY.DISCRETE.key) {
      return `${summaryName} : ${summary.value.value}${summary.value.units}`;
    } else if (summary.key === REPORT_CONTENT.SUMMARY.AUTO.key) {
      return `${summaryName} : ${summary.value}`;
    } else {
      return summaryName;
    }
  };

  const calculateInteractiveOption = (canUseReact: boolean, isReact: boolean): InteractiveOptions => {
    if (canUseReact) {
      return isReact ? InteractiveOptions.YES : InteractiveOptions.NO;
    }
    return InteractiveOptions.NOT_APPLICABLE;
  };

  type ContentWithAttributes = ContentWithMetadataOutputV1 & {
    sourceView: WorksheetView;
    currentView: WorksheetView;
    workbookName: string;
    worksheetName: string;
    workstepStatus: string;
    currentWorkstepId: string;
    canUseReact?: boolean;
    interactiveContentStatus?: InteractiveOptions;
  };

  const fetchAttributes = (content: ContentWithMetadataOutputV1): Promise<ContentWithAttributes> => {
    const contentWithAttributes: ContentWithAttributes = {
      ...content,
      sourceView: null,
      currentView: null,
      workbookName: null,
      worksheetName: null,
      workstepStatus: null,
      currentWorkstepId: null,
      canUseReact: null,
      interactiveContentStatus: null,
    };

    if (_.isEmpty(content.sourceWorkstepBlob)) {
      contentWithAttributes.sourceView = { name: REDACTED } as WorksheetView;
      contentWithAttributes.currentView = {} as WorksheetView;
      contentWithAttributes.workbookName = t(REDACTED);
      contentWithAttributes.worksheetName = t(REDACTED);
      contentWithAttributes.workstepStatus = REDACTED;
      return Promise.resolve(contentWithAttributes);
    } else {
      return Promise.all([
        workstepFromResponse(content.sourceWorkstepBlob),
        workstepFromResponse(content.currentWorkstepBlob),
      ]).then((results) => {
        contentWithAttributes.sourceView = sqReportContent.getViewFromWorkstep(results[0]);
        contentWithAttributes.currentView = sqReportContent.getViewFromWorkstep(results[1]);
        contentWithAttributes.workbookName = content.workbookPreview.name;
        contentWithAttributes.worksheetName = content.worksheetPreview.name;
        contentWithAttributes.workstepStatus =
          content.sourceWorkstep === content.currentWorkstepBlob.id
            ? 'REPORT.CONFIG.WORKSTEP_STATUS.UP_TO_DATE'
            : 'REPORT.CONFIG.WORKSTEP_STATUS.NOT_UP_TO_DATE';
        contentWithAttributes.currentWorkstepId = content.currentWorkstepBlob.id;
        contentWithAttributes.canUseReact = sqReportContent.canBeInteractive(results[1]);
        contentWithAttributes.interactiveContentStatus = calculateInteractiveOption(
          contentWithAttributes.canUseReact,
          content.react,
        );
        return contentWithAttributes;
      });
    }
  };

  const decorateContent = (
    content: ContentWithAttributes,
    formattedContent: Content,
    size: ReportContentSize,
    shape: ReportContentShape,
    contentIdsInDocumentOrder: string[],
  ) => {
    const shapeName = formattedContent.useSizeFromRender
      ? NOT_APPLICABLE
      : shape?.name ?? REPORT_CONTENT.SIZE.CUSTOM.name;
    const sizeName = formattedContent.useSizeFromRender ? NOT_APPLICABLE : size.name;
    const width = formattedContent.useSizeFromRender ? t(NOT_APPLICABLE) : formattedContent.width;
    const height = formattedContent.useSizeFromRender ? t(NOT_APPLICABLE) : formattedContent.height;

    const summaryAsString = convertSummaryToString(formattedContent.summary);

    return {
      ...formattedContent,
      canUseReact: content.canUseReact,
      interactiveContentStatus: content.interactiveContentStatus,
      dateRangeName: content.dateRange ? content.dateRange.name : inheritFromWorksheet,
      assetSelectionName: content.assetSelection ? content.assetSelection.name : inheritFromWorksheet,
      scaleName: t(_.find(REPORT_CONTENT.SCALE, { key: content.scale }).name),
      shapeName: t(shapeName),
      sizeName: t(sizeName),
      workstepStatus: t(content.workstepStatus),
      viewName: t(content.sourceView.name),
      sourceViewKey: content.sourceView.key,
      currentViewKey: content.currentView.key,
      position: _.indexOf(contentIdsInDocumentOrder, content.id) + 1,
      summaryAsString,
      width,
      height,
      workbookName: content.workbookName,
      worksheetName: content.worksheetName,
      currentWorkstepId: content.currentWorkstepId,
    };
  };

  useEffect(() => {
    const selectedIds = getContentIdsInSelection();
    const selectedContent = [];
    const contentIdsInDocumentOrder = getContentIdsInDocumentOrder();

    sqContentApi
      .getContentsWithAllMetadata({ reportId: sqReportStore.id })
      .then(({ data: { contentItems } }) => {
        return Promise.all(_.chain(contentItems).reject('archived').map(fetchAttributes).value());
      })
      .then((contentItems) => {
        const tempContentWithAdditionalProperties = _.chain(contentItems)
          .reject((content) => !_.includes(contentIdsInDocumentOrder, content.id) || content.archived)
          .map((content) => {
            const { size, shape } = sqReportContentActions.calculateSizeAndShape(content.width, content.height);
            const formattedContent = formatContentFromApiOutput(content);
            const decoratedContent = decorateContent(content, formattedContent, size, shape, contentIdsInDocumentOrder);
            if (_.includes(selectedIds, decoratedContent.id)) {
              selectedContent.push(decoratedContent);
            }
            return decoratedContent;
          })
          .value();

        if (_.some(tempContentWithAdditionalProperties, (content) => content.workstepStatus === REDACTED)) {
          errorToast({ messageKey: 'REPORT.CONFIG.DETAILS_REDACTED' });
        }
        setContentWithAdditionalProperties(tempContentWithAdditionalProperties);
        sqReportActions.setSelectedBulkContent(selectedContent);
        setContentLoaded(true);
      })
      .catch((e) => {
        errorToast({ httpResponseOrError: e });
        sqReportActions.clearBulkProperties();
      });
  }, []);

  const keepAllProperties = shouldKeepBasicProperties && shouldKeepAssetSelection && shouldKeepDateRange;

  const isIntendedTypeSelected = {
    [BulkEditMode.ASSET_SELECTION]:
      shouldKeepBasicProperties && shouldKeepDateRange && !shouldKeepAssetSelection && !shouldInheritAssetSelection,
    [BulkEditMode.DATE_RANGE]:
      shouldKeepBasicProperties && shouldKeepAssetSelection && !shouldKeepDateRange && !shouldInheritDateRange,
    [BulkEditMode.ADVANCED_ONLY]: keepAllProperties,
  }[displayMode];

  const useAdvancedTitle = isAdvancedOnly || !isIntendedTypeSelected;

  const shouldShowSubtitle = isAdvancedDisplay || (!isAdvancedDisplay && !isIntendedTypeSelected);

  const title = (
    <div className="flexRowContainer flexAlignStart">
      <div>
        {useAdvancedTitle ? (
          t('REPORT.CONTENT.UPDATE_PROPERTIES')
        ) : (
          <div className="flexColumnContainer flexAlignCenter">
            <Icon
              testId="bulkTitleIcon"
              icon={
                {
                  [BulkEditMode.ASSET_SELECTION]: 'fa-cube',
                  [BulkEditMode.DATE_RANGE]: 'fa-calendar',
                }[displayMode]
              }
              extraClassNames="mr5"
            />
            <div className="text-bold" data-testid="bulkTitleName">
              {' '}
              {
                {
                  [BulkEditMode.ASSET_SELECTION]: t(bulkAssetSelection.name),
                  [BulkEditMode.DATE_RANGE]: t(bulkDateRange.name),
                }[displayMode]
              }
            </div>
            <div>{t('REPORT.CONTENT.ATTACH_TO_CONTENT')}</div>
          </div>
        )}
      </div>
      {shouldShowSubtitle && (
        <div className="small text-italic sq-darkish-gray">{t('REPORT.CONTENT.UPDATE_PROPERTIES_DESCRIPTION')}</div>
      )}
    </div>
  );

  const executeTooltip = keepAllProperties
    ? 'REPORT.MODAL.BULK_EDIT.REFRESH_CONTENT_TOOLTIP'
    : {
        [BulkEditMode.ASSET_SELECTION]:
          shouldKeepBasicProperties && shouldKeepDateRange && !shouldInheritAssetSelection
            ? t('REPORT.MODAL.BULK_EDIT.ATTACH_TOOLTIP', {
                TYPE: t('REPORT.CONFIG.ASSET_SELECTION'),
              })
            : t('REPORT.MODAL.BULK_EDIT.UPDATE_CONTENT_TOOLTIP'),
        [BulkEditMode.DATE_RANGE]:
          shouldKeepBasicProperties && shouldKeepAssetSelection && !shouldInheritDateRange
            ? t('REPORT.MODAL.BULK_EDIT.ATTACH_TOOLTIP', {
                TYPE: t('REPORT.CONFIG.DATE_RANGE'),
              })
            : t('REPORT.MODAL.BULK_EDIT.UPDATE_CONTENT_TOOLTIP'),
        [BulkEditMode.ADVANCED_ONLY]: 'REPORT.MODAL.BULK_EDIT.UPDATE_CONTENT_TOOLTIP',
      }[displayMode];

  const close = () => {
    if (sqReportStore.shouldShowConfigureAutoUpdateModal) {
      sqReportActions.setShowConfigureAutoUpdateModal(true, true);
    }
    sqReportActions.clearBulkProperties();
    setContentWithAdditionalProperties([]);
    setContentLoaded(false);
  };

  const submit = () => {
    doTrack('Topic', 'Topic Bulk Update', 'updated content');
    let updatedDate = false;
    let updatedInteractive = false;
    let updatedAssetSelection = false;
    let updatedScale = false;
    let updatedShapeSize = false;
    let updatedWorkstep = false;
    let updatedSummary = false;
    let couldNotUpdateWorkstep = false;
    let anyContentModified = false;

    _.forEach(sqReportStore.selectedBulkContent, (content) => {
      const modifiedContent = { ...content };

      if (sqReportStore.bulkInteractive.key !== KEEP_CURRENT_INTERACTIVE.key) {
        if (modifiedContent.canUseReact) {
          modifiedContent.isReact = sqReportStore.bulkInteractive.makeInteractive;
          updatedInteractive = true;
        }
      }

      // undefined === inherit date range
      if (_.isUndefined(sqReportStore.bulkDateRange)) {
        modifiedContent.dateRangeId = undefined;
        updatedDate = true;
      } else if (sqReportStore.bulkDateRange?.id !== KEEP_CURRENT_DATE_RANGE.id) {
        modifiedContent.dateRangeId = sqReportStore.bulkDateRange.id;
        updatedDate = true;
      }

      if (_.isUndefined(sqReportStore.bulkAssetSelection)) {
        modifiedContent.assetSelectionId = undefined;
        updatedAssetSelection = true;
      } else if (sqReportStore.bulkAssetSelection.id !== KEEP_CURRENT_ASSET_SELECTION.id) {
        modifiedContent.assetSelectionId = sqReportStore.bulkAssetSelection.id;
        updatedAssetSelection = true;
      }

      if (sqReportStore.bulkScale.key !== KEEP_CURRENT_SCALE.key) {
        modifiedContent.scale = sqReportStore.bulkScale.key;
        updatedScale = true;
      }

      // If size is staying the same so is shape
      if (sqReportStore.bulkSize.key !== KEEP_CURRENT_SIZE.key) {
        if (sqReportStore.bulkSize.key === REPORT_CONTENT.SIZE.CUSTOM.key) {
          modifiedContent.width = sqReportStore.bulkWidth;
          modifiedContent.height = sqReportStore.bulkHeight;
        } else {
          const size = sqReportStore.bulkSize;
          const shape = sqReportStore.bulkShape;

          modifiedContent.width = size.width;
          modifiedContent.height = sqReportContentActions.calculateHeight(size.width, shape);
        }

        updatedShapeSize = true;
      }

      // If the source view key is present, the user has access to the source worksheet
      if (sqReportStore.shouldUpdateBulkWorkstep && modifiedContent.sourceViewKey) {
        if (modifiedContent.workstepId !== modifiedContent.currentWorkstepId) {
          modifiedContent.workstepId = modifiedContent.currentWorkstepId;
          const currentView = findWorkSheetView(modifiedContent.currentViewKey);

          modifiedContent.useSizeFromRender = !!currentView.useSizeFromRender;
          if (
            !modifiedContent.useSizeFromRender &&
            !!findWorkSheetView(modifiedContent.sourceViewKey).useSizeFromRender
          ) {
            modifiedContent.width = REPORT_CONTENT.SIZE.MEDIUM.width;
            modifiedContent.height = sqReportContentActions.calculateHeight(
              modifiedContent.width,
              REPORT_CONTENT.SHAPE.RECTANGLE,
            );

            updatedShapeSize = true;
          }

          updatedWorkstep = true;
        }
      } else if (sqReportStore.shouldUpdateBulkWorkstep) {
        couldNotUpdateWorkstep = true;
      }

      if (_.isUndefined(sqReportStore.bulkSummary)) {
        modifiedContent.summary = undefined;
        updatedSummary = true;
      } else if (sqReportStore.bulkSummary.key !== KEEP_CURRENT_SUMMARY.key) {
        modifiedContent.summary = sqReportStore.bulkSummary;
        updatedSummary = true;
      }

      if (!_.isEqual(content, modifiedContent)) {
        if (modifiedContent.useSizeFromRender) {
          const undecoratedContent = sqReportStore.getContentById(modifiedContent.id);
          modifiedContent.shapeName = undecoratedContent.shapeName;
          modifiedContent.sizeName = undecoratedContent.sizeName;
          modifiedContent.width = undecoratedContent.width;
          modifiedContent.height = undecoratedContent.height;
        }

        anyContentModified = true;
        sqReportActions
          .saveContent(modifiedContent)
          .then((content: Content) => {
            sqReportContent.clearPropertyOverridesForContent(content.id);
            sqReportContent.insertOrReplaceContent(content.id);
          })
          .catch((error) => {
            errorToast({ httpResponseOrError: error });
          });
      }
    });

    // Update button was pressed and no content was modified => refresh selected content pieces
    if (!anyContentModified) {
      const contentIds = _.map(sqReportStore.selectedBulkContent, 'id');
      sqReportContent.forceRefreshMultipleContent(contentIds);
    }

    if (updatedDate) doTrack('Topic', 'Topic Bulk Update', 'update date range');
    if (updatedScale) doTrack('Topic', 'Topic Bulk Update', 'update scale');
    if (updatedShapeSize) doTrack('Topic', 'Topic Bulk Update', 'update size/shape');
    if (updatedWorkstep) doTrack('Topic', 'Topic Bulk Update', 'update workstep');
    if (updatedSummary) doTrack('Topic', 'Topic Bulk Update', 'update summary');
    if (updatedAssetSelection) doTrack('Topic', 'Topic Bulk Update', 'update asset selection');
    if (updatedInteractive) doTrack('Topic', 'Topic Bulk Update', 'update interactive');

    if (couldNotUpdateWorkstep) {
      errorToast({
        messageKey: 'REPORT.CONFIG.BULK_EDIT_TABLE.CANT_UPDATE_WORKSTEP',
      });
    }

    close();
  };

  const cancel = () => {
    doTrack('Topic', 'Topic Bulk Update', 'cancel');
    close();
  };

  const footer = (
    <ReportContentModalButtons
      cancel={cancel}
      submit={submit}
      executeLabel={executeLabel}
      customTooltip={executeTooltip}
    />
  );

  return (
    show && (
      <Modal
        show={show}
        onHide={cancel}
        animation={false}
        backdrop="static"
        dialogClassName={dialogClassName}
        size="lg">
        <Modal.Header closeButton={true} onHide={cancel}>
          <Modal.Title> {title} </Modal.Title>
          <div className="flexFill" />
        </Modal.Header>
        <Modal.Body>
          {' '}
          {contentLoaded ? (
            <div className="flexFillOverflow flexColumnContainer bulkEditModalBody">
              <ReportContentBulkEditTable
                allContent={contentWithAdditionalProperties}
                isAdvancedDisplay={isAdvancedDisplay}
                displayMode={displayMode}
              />
              {isAdvancedDisplay && <ReportContentBulkEditProperties />}
              {!isAdvancedOnly && (
                <div className="flexSelfCenter">
                  <Icon
                    testId="bulkEditArrows"
                    extraClassNames="bulkEditArrows"
                    icon={isAdvancedDisplay ? 'fa-angle-double-left' : 'fa-angle-double-right'}
                    tooltip={t(
                      isAdvancedDisplay
                        ? 'REPORT.MODAL.BULK_EDIT.SHOW_LESS_TOOLTIP'
                        : 'REPORT.MODAL.BULK_EDIT.SHOW_MORE_TOOLTIP',
                    )}
                    large={true}
                    type="color"
                    color="sq-lightest-gray"
                    onClick={() => setIsAdvancedDisplay(!isAdvancedDisplay)}
                    tooltipPlacement="left"
                  />
                </div>
              )}
            </div>
          ) : (
            <TableLoadingIndicator />
          )}{' '}
        </Modal.Body>
        <Modal.Footer> {footer} </Modal.Footer>
      </Modal>
    )
  );
};

export const sqReportContentBulkEditModal = angularComponent(
  reportContentBulkEditModalBindings,
  ReportContentBulkEditModal,
);
