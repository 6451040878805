import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import React from 'react';
import _ from 'lodash';
import { JournalCategory } from '@/hybrid/annotation/JournalCategory.molecule';
import { useTranslation } from 'react-i18next';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { sqAnnotationStore, sqWorkbenchStore } from '@/core/core.stores';
import { isPresentationWorkbookMode } from '@/hybrid/utilities/utilities';
import { canManageItem } from '@/services/authorization.service';

const journalOverviewBindings = bindingsDefinition({
  sqAnnotationActions: injected<AnnotationActions>(),
});

export const JournalOverview: SeeqComponent<typeof journalOverviewBindings> = () => {
  const { sqAnnotationActions } = useInjectedBindings(journalOverviewBindings);
  const { t } = useTranslation();
  const entries = useFluxPath(sqAnnotationStore, () => sqAnnotationStore.annotations);
  const highlightId = useFluxPath(sqAnnotationStore, () => sqAnnotationStore.highlightId);
  const isPresentationMode = isPresentationWorkbookMode();
  const worksheetEntries = entries
    ? sqAnnotationStore.findJournalEntries(entries, sqWorkbenchStore.stateParams.worksheetId)
    : [];
  const annotationEntries = entries ? _.filter(entries, _.property('discoverable')) : [];

  /**
   * When the overview is closed either redisplay the single existing journal entry or a new entry if one does not
   * already exist. The close overview button is not displayed if there are more than one journal entries on the
   * worksheet so the user must choose which journal entry they want to view.
   */
  const closeOverview = () => {
    if (worksheetEntries.length === 1) {
      sqAnnotationActions.showEntry(worksheetEntries[0].id);
    } else {
      sqAnnotationActions.newEntry();
    }
  };

  return (
    <div className="journalOverview overflowAuto scrollAreaTop msOverflowStyleAuto flexFillOverflow">
      <div className="flexRowContainer flexFillOverflow">
        {/*The JournalCategory below is here for backwards compatibility, and is not thoroughly tested as a result.
         Also, the placeholder never appears.*/}
        {worksheetEntries.length > 1 && (
          <JournalCategory
            name={t('JOURNAL.CATEGORY.WORKSHEET.HEADER')}
            placeholder={
              isPresentationMode
                ? 'JOURNAL.CATEGORY.WORKSHEET.PLACEHOLDER_NOEDIT'
                : 'JOURNAL.CATEGORY.WORKSHEET.PLACEHOLDER'
            }
            entries={worksheetEntries}
            highlightId={highlightId}
            display={sqAnnotationActions.showEntry}
            delete={sqAnnotationActions.delete}
            canManage={canManageItem}
          />
        )}

        <JournalCategory
          name={t('JOURNAL.CATEGORY.ANNOTATIONS.HEADER')}
          placeholder={t('JOURNAL.CATEGORY.ANNOTATIONS.PLACEHOLDER')}
          entries={annotationEntries}
          highlightId={highlightId}
          display={sqAnnotationActions.showEntry}
          delete={sqAnnotationActions.delete}
          canManage={canManageItem}>
          {worksheetEntries.length < 2 && (
            <TextButton
              testId="specJournalCloseBtn"
              id="specJournalCloseBtn"
              onClick={closeOverview}
              size="sm"
              extraClassNames="annotationsCloseBtn"
              label="CLOSE"
            />
          )}
        </JournalCategory>
      </div>
    </div>
  );
};
