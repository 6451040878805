import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { CancelAndSave } from '@/hybrid/core/CancelAndSave.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';

import { TrendActions } from '@/trendData/trend.actions';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { doTrack } from '@/track/track.service';

const scopeModalBindings = bindingsDefinition({
  itemId: prop<string>(),
  closeModal: prop<() => void>(),
  loadProperties: prop<() => void>(),
  sqTrendActions: injected<TrendActions>(),
});

export const ScopeModal: SeeqComponent<typeof scopeModalBindings> = ({ itemId, loadProperties, closeModal }) => {
  const { sqTrendActions } = useInjectedBindings(scopeModalBindings);

  const { t } = useTranslation();

  const [makeGlobal, setMakeGlobal] = useState(false);

  /**
   * Makes the item available outside this analysis
   */
  const setGlobalScope = () => sqTrendActions.setGlobalScope(itemId).then(loadProperties).then(closeModal);

  const handleTracking = (func: () => void, info: string) => {
    doTrack('Item Properties', 'scope dialog', info);

    return func();
  };

  return (
    <Modal show={true} onHide={closeModal} animation={false} data-testid="scopeModal">
      <Modal.Header closeButton={true}>
        <h4>{t('SCOPE.MODAL.HEADER')}</h4>
      </Modal.Header>

      <Modal.Body>
        <div className="modal-body text-center">
          <div>{t('SCOPE.MODAL.DESCRIPTION')}</div>
          <div className="sq-text-warning text-bolder">{t('SCOPE.MODAL.WARNING')}</div>
          <div
            className="mt10 mb10 font-size-larger text-interactive"
            onClick={() => setMakeGlobal((makeGlobal) => !makeGlobal)}
            data-testid="toggleScope">
            <Icon icon={makeGlobal ? 'fa-toggle-on' : 'fa-toggle-off'} large={true} extraClassNames="pr5" />
            <span>{t('SCOPE.MODAL.MAKE_GLOBAL')}</span>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <CancelAndSave
          submitFn={() => handleTracking(setGlobalScope, 'save')}
          cancelFn={() => handleTracking(closeModal, 'cancel')}
          btnDisabled={!makeGlobal}
          values={[]}
        />
      </Modal.Footer>
    </Modal>
  );
};
