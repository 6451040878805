// This file is seperated to allow mocking during tests

import { sqReportStore } from '@/core/core.stores';
import { attachEventHandlers, insertNewContent } from '@/hybrid/utilities/froalaReportContent.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import _ from 'lodash';

/**
 * Returns the JQuery instance for the content image.
 */
export const getContentImage = (id: string) => {
  return jQuery(`img[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}="${id}"]`);
};

/**
 * Updates Seeq content in the document. Uses the current contents of sqReportContentStore and
 * the current location of the content (for existing content items)
 *
 * @param contentId - The id of the content.
 * @param [silently] - If true, replaces existing image with no spinners or progress bars
 * @param [deferImageUpdate] - If true, avoids requesting image directly and waits for a message from a job
 * @returns { isNewContent: boolean } - True if new content was inserted
 */
export const replaceContentIfExists = (
  contentId: string,
  silently = false,
  deferImageUpdate = false,
): { isNewContent: boolean } => {
  const $image = getContentImage(contentId);
  if ($image.length === 0) return { isNewContent: true };

  // The anchor tag doesn't need to change so we only reload the image. Also there may be instances where the image
  // doesn't have a rendered width or height (e.g. when the document is being set from another event), so ensure that
  // we set the min/max-width and min/max-height so that the loading spinner will render into an image element with a
  // fixed size
  if (!silently) {
    const content = sqReportStore.getContentById(contentId);
    const renderedWidth = $image.width() || 0;
    const renderedHeight = $image.height() || 0;
    const width = renderedWidth > 0 ? renderedWidth : content.width;
    const height = renderedHeight > 0 ? renderedHeight : content.height;
    $image.css('min-width', width);
    $image.css('max-width', width);
    $image.css('min-height', height);
    $image.css('max-height', height);
    $image.removeAttr('src');
  }

  attachEventHandlers(contentId, silently);

  if (!deferImageUpdate) {
    $image.attr('src', sqReportStore.getContentImageUrl(contentId));
  }

  return { isNewContent: false };
};

/**
 * Inserts Seeq content into the document. For content items that are already in the document, the HTML element is
 * replaced at its current location. For content items that are not in the document, a new element is inserted at
 * the current cursor location or at the location of elementToReplace, if specified.
 *
 * @param {string} contentId - The id of the content.
 * @param {jQuery} elementToReplace - if specified, new content element will replace this element
 * @returns {object} { isNewContent: boolean } - True if new content was inserted
 */
export const insertOrReplaceContent = (
  contentId: string,
  elementToReplace: JQuery | undefined = undefined,
  sqReportEditor: any,
): { isNewContent: boolean } => {
  const $image = getContentImage(contentId);
  const isNewContent = $image.length === 0;

  if (isNewContent) {
    insertNewContent(contentId, elementToReplace, sqReportEditor);
  } else {
    replaceContentIfExists(contentId, false, false);
  }

  return { isNewContent };
};

/**
 * Returns all of the content Ids currently in the user's highlight/selection
 *
 * @return the list of selected content ids
 */
export const getContentIdsInSelection = (): string[] => {
  const selection = window.getSelection();
  if (selection?.rangeCount === 0) {
    return [];
  }

  return _.chain(
    selection?.getRangeAt(0).cloneContents().querySelectorAll(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`),
  )
    .map((content) => content.getAttribute(SeeqNames.TopicDocumentAttributes.DataSeeqContent) as string)
    .filter((content) => !_.isEmpty(content))
    .value();
};
