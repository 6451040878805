// @ts-strict-ignore
import _ from 'lodash';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqUserGroupsApi, UserGroupInputV1 } from '@/sdk';
import { EVERYONE_USERGROUP } from '@/hybrid/administration/Administration.page';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';
import {
  saveNewGroup,
  updateGroup as updateGroupSqAdministrationAction,
} from '@/administration/administration.actions';

/**
 * Determines if a group is readonly. All groups not from the Seeq datasource ID are readonly because they are
 * updated by a connector except the ones having 'Remote Group Editable' set to true.
 *
 * @param {Object} group - a group
 */
export function isGroupReadOnly(group) {
  if (_.isNil(group)) {
    return false;
  }

  return group.datasourceId !== SeeqNames.LocalDatasources.Authentication.DatasourceId && !group.isRemoteGroupEditable;
}

export function isGroupEveryone(group) {
  return group?.name === EVERYONE_USERGROUP;
}

export function formatGroups(groups) {
  return _.chain(groups)
    .map((group) => {
      const datasourceName = group?.datasource?.name || group?.datasourceName;
      const datasourceId = group?.datasource?.datasourceId || group?.datasourceId;
      // Add datasource name and ID so they can be referenced as a column definition field
      return _.omitBy({ ...group, datasourceName, datasourceId }, _.isUndefined);
    })
    .value();
}

export function updateGroup({ values, groupMembers, group, removeId, onClose, enabledValue, permissionValue }) {
  const promises = [];
  const payload = {
    name: values.name,
    description: values.description,
    isEnabled: enabledValue,
    removePermissions: permissionValue,
    remoteGroupEditable: group.isRemoteGroupEditable,
  };

  const isGroupEveryone = group ? group.name === EVERYONE_USERGROUP : false;

  promises.push(sqUserGroupsApi.updateUserGroup(payload, { userGroupId: group.id }));

  if (!isGroupEveryone) {
    _.forEach(removeId, (id) => {
      promises.push(
        sqUserGroupsApi.removeIdentityFromUserGroup({
          userGroupId: group.id,
          identityId: id,
        }),
      );
    });

    _.forEach(groupMembers, (identityToAdd) => {
      promises.push(
        sqUserGroupsApi.addIdentityToUserGroup({
          userGroupId: group.id,
          identityId: identityToAdd.id,
        }),
      );
    });
  }

  return Promise.all(promises)
    .then(() => sqUserGroupsApi.updateUserGroup(payload, { userGroupId: group.id }))
    .then(({ data }) => updateGroupSqAdministrationAction(data))
    .then(() => sqUserGroupsApi.getUserGroup({ userGroupId: group.id }))
    .then(() => successToast({ messageKey: 'ADMIN.GROUP.SAVED' }))
    .then(onClose)
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

export function addGroup(values, groupMembers, onClose) {
  const newGroup = {
    name: values.name,
    description: values.description,
  };

  return sqUserGroupsApi
    .createUserGroup(newGroup as UserGroupInputV1)
    .then(({ data }) => {
      saveNewGroup(data);
      return _.chain(groupMembers)
        .map((identityToAdd) =>
          sqUserGroupsApi.addIdentityToUserGroup({
            userGroupId: data.id,
            identityId: identityToAdd.id,
          }),
        )
        .thru((promises) => Promise.all(promises))
        .value();
    })
    .then(() => successToast({ messageKey: 'ADMIN.GROUP.SAVED' }))
    .then(onClose)
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

export function includePermissions(isReadOnly, enabledValue, group, accessControl) {
  return !isReadOnly && group && !enabledValue && accessControl;
}
