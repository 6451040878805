import _ from 'lodash';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import i18next from 'i18next';

/**
 * The job administration service handles the data and methods needed for job administration
 */

export function decorateJob(job: any) {
  switch (job.group) {
    case SeeqNames.JobGroup.Notebook:
      const scheduledNotebookPath = _.get(job, 'configuration.scheduledNotebookPath');
      const scheduledNotebookName = _.split(scheduledNotebookPath, '/').pop();
      const projectId = _.get(job, 'configuration.projectId').toUpperCase();
      job.isNotebookJobGroup = true;
      job.sourceName = scheduledNotebookName;
      job.scheduledNotebookUrl = encodeURI(`/data-lab/${projectId}/notebooks/${scheduledNotebookPath}`);
      break;
    case SeeqNames.JobGroup.Report:
      job.isReportJobGroup = true;
      job.sourceName = `${job.topicName} - ${job.documentName}`;
      break;
    case SeeqNames.JobGroup.Screenshot:
      job.isScreenshotJobGroup = true;
      job.sourceName = `${job.topicName} - ${job.documentName}`;
      break;
    default:
      job.sourceName = i18next.t('ADMIN.JOB.NOT_APPLICABLE');
  }

  const jobGroupSupportsDuration = !job.isNotebookJobGroup;

  if (jobGroupSupportsDuration && _.isNumber(job.duration)) {
    job.duration = _.round(
      job.duration / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND / NUMBER_CONVERSIONS.MILLISECONDS_PER_SECOND,
      1,
    );
  } else {
    job.duration = '';
  }
}
