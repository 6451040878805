// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { JournalReplyIcon } from '@/hybrid/annotation/JournalReplyIcon.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

interface JournalFooterProps {
  isExpanded: boolean;
  isCommentsExpanded: boolean;
  commentsCount: number;
  isPresentationMode: boolean;
  customExpandComments: (expand: boolean) => void;
}

export const JournalFooter: React.FunctionComponent<JournalFooterProps> = ({
  isExpanded,
  isCommentsExpanded,
  commentsCount,
  isPresentationMode,
  customExpandComments,
}) => {
  const { t } = useTranslation();

  const onExpandComments = (event) => {
    event.stopPropagation();
    customExpandComments(true);
  };

  return (
    <>
      {!isPresentationMode && !isCommentsExpanded && isExpanded && (
        <div className="journalFooter flexRowContainer height-25">
          <div className="flexColumnContainer flexJustifyEnd flexAlignCenter flexNoGrowNoShrink height-25">
            <HoverTooltip text="JOURNAL.TOOLTIP.EXPAND_COMMENTS">
              <div className="flexColumnContainer cursorPointer hoverHighlightPanel mr10" onClick={onExpandComments}>
                <Icon icon="fa-chevron-left" extraClassNames="sq-text-primary mr3 mt2" />
                <span className="sq-text-primary">{t('JOURNAL.ENTRY.COMMENTS')}</span>
                <div className="pl3">
                  <JournalReplyIcon count={commentsCount} />
                </div>
              </div>
            </HoverTooltip>
          </div>
        </div>
      )}
    </>
  );
};
