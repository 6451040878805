// @ts-strict-ignore
import { SeeqNames } from '@/main/app.constants.seeqnames';
import _ from 'lodash';
import moment from 'moment';

import { sqItemsApi } from '@/sdk';
import { formatDuration, formatTime, momentMeasurementStrings } from '@/hybrid/datetime/dateTime.utilities';
import { DurationActions } from '@/trendData/duration.actions';
import { ReportActions } from '@/reportEditor/report.actions';
import { validateGuid } from '@/hybrid/utilities/utilities';
import { PUSH_IGNORE } from '@/core/flux.service';
import { CAPSULE_SELECTION, DateRangeCondition, OFFSET_DIRECTION } from '@/reportEditor/report.constants';
import { sqDurationStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';
import { FrontendDuration } from '@/services/systemConfiguration.constants';
import { getDefaultMaxCapsuleDuration } from '@/services/systemConfiguration.utilities';
import { Item } from '@/hybrid/tools/histogram/aggregationBin.store';
import { computeCapsuleOffset } from '@/hybrid/utilities/froalaReportContent.utilities';
import { BulkEditMode } from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/reportContent.constants';

const DATE_RANGE_TRACK_ACTION = 'Date Range';

/**
 * Finds max duration that is configured on a condition.
 * */
export function findMaximumDurationForCondition(conditionId: string): Promise<FrontendDuration | null> {
  return sqItemsApi.getItemAndAllProperties({ id: conditionId }).then(({ data: item }) => {
    const maxDuration = _.find(item.properties, {
      name: SeeqNames.Properties.MaximumDuration,
    });
    if (maxDuration) {
      return {
        value: _.toNumber(maxDuration.value),
        units: maxDuration.unitOfMeasure,
      };
    } else {
      return null;
    }
  });
}

/**
 * Clones condition and assigns appropriate maxDuration. Either one was already on the condition, one that has been
 * configured from the date range, or default (40h).
 * */
export function calculateSelectedCondition(
  item: Item,
  selectedCondition,
  dateRange,
  maxDuration: FrontendDuration,
): DateRangeCondition | null {
  if (!validateGuid(item?.id)) {
    return null;
  }

  const clonedSelectedCondition = {
    ..._.cloneDeep(selectedCondition),
    ..._.pick(item, ['id', 'name']),
    description: 'REPORT.CONFIG.SELECTED_CONDITION',
  };

  if (!maxDuration) {
    clonedSelectedCondition.hasMaximumDuration = false;
    clonedSelectedCondition.maximumDuration = dateRange?.condition?.maximumDuration
      ? dateRange?.condition?.maximumDuration
      : getDefaultMaxCapsuleDuration();
  } else {
    clonedSelectedCondition.hasMaximumDuration = true;
    clonedSelectedCondition.maximumDuration = maxDuration;
  }

  return clonedSelectedCondition;
}

/**
 * Updates the duration store display range based on the date variable. Returns a partial DateRange with the proper
 * fields to update a dateRange with the new range.
 */
export function updateDisplayRange(
  dateRange,
  sqDurationActions: DurationActions,
): { range?; auto?: { duration }; condition: { range } } {
  const now = moment().utc().valueOf();
  let start;
  let end;
  if (dateRange?.auto?.enabled) {
    const momentOffsetUnit = momentMeasurementStrings(dateRange?.auto.offset?.units);
    const offset =
      (moment.duration(dateRange?.auto?.offset?.value, momentOffsetUnit) as any) *
      (dateRange.auto.offsetDirection === OFFSET_DIRECTION.FUTURE ? 1 : -1);
    start = now + offset - dateRange.auto.duration;
    end = now + offset;
  } else if (dateRange?.condition?.range?.start) {
    start = dateRange.condition.range.start;
    end = dateRange.condition.range.end;
  } else if (dateRange?.range?.start) {
    start = dateRange?.range?.start;
    end = dateRange?.range?.end;
  } else {
    // This is a new date range, so initialize it with the current time
    start = moment.utc(now).subtract(dateRange?.auto?.duration).valueOf();
    end = moment.utc(now).valueOf();
  }
  sqDurationActions.displayRange.updateTimes(start, end, PUSH_IGNORE);
  // In case there was a failure (ie, bad order), grab start, end, and duration from the store
  start = sqDurationStore.displayRange.start.valueOf();
  end = sqDurationStore.displayRange.end.valueOf();

  const partialDateRange = !dateRange?.condition?.id
    ? {
        range: { start, end },
        auto: {
          duration: sqDurationStore.displayRange.duration.valueOf(),
        },
      }
    : {};
  return {
    ...partialDateRange,
    condition: {
      range: {
        start,
        end,
      },
    },
  };
}

export function verifyUniqueName(name, dateRanges, dateRange) {
  return !_.chain(dateRanges)
    .reject(['id', dateRange?.condition?.id])
    .map('name')
    .map(_.toLower)
    .includes(_.toLower(name))
    .value();
}

/**
 * Format the computed start and end for display
 */
export function formatCapsuleForDisplay(start: number, end: number, timezone: { name: string }): string {
  const format = 'l LT';
  const startDisplay = formatTime(start, timezone, format);
  const endDisplay = formatTime(end, timezone, format);
  const durationDisplay = formatDuration(end - start, true);
  return `${startDisplay} - ${endDisplay} (${durationDisplay})`;
}

export function calculateCapsule(displayRange, selectedCondition, dateRange, sqReportActions: ReportActions) {
  const range = {
    start: displayRange?.start?.valueOf(),
    end: displayRange?.end?.valueOf(),
  };
  const offset = computeCapsuleOffset(dateRange?.condition);
  return Promise.all([
    sqReportActions.computeCapsule(selectedCondition, range, offset),
    sqReportActions.computeCapsuleCount(selectedCondition, range),
  ]).then(([capsule, count]) => {
    const capsuleCount = count;
    const capsuleCountLeft =
      dateRange?.condition?.reference === CAPSULE_SELECTION.REFERENCE.START
        ? Math.min(Math.abs(offset) - 1, count)
        : Math.max(0, count - Math.abs(offset));
    const capsuleCountRight = count - capsuleCountLeft - 1;

    return {
      capsuleCount,
      capsuleCountLeft,
      capsuleCountRight,
      capsule,
    };
  });
}

/**
 * Determines if the date variable can be saved.
 */
export function canSave(
  dateRange,
  dateRangeIsSaving,
  isConditionSelected,
  isSelectingRelative,
  maximumDurationValid,
  isMaximumDurationRequiredForItem,
  computedCapsuleDisplay,
  durationOffsetValid,
): boolean {
  if (dateRange?.irregularFormula || dateRangeIsSaving) {
    return false;
  }

  // If not auto-updating then if a condition is selected a valid range must also be selected
  if (
    !dateRange?.auto?.enabled &&
    isConditionSelected &&
    (!dateRange?.range || (isMaximumDurationRequiredForItem && !maximumDurationValid))
  ) {
    return false;
  }

  if (!dateRange?.auto?.enabled && isConditionSelected && !computedCapsuleDisplay && isSelectingRelative) {
    return false;
  }

  if (!dateRange?.auto?.enabled && !isSelectingRelative && !dateRange?.condition?.columns && isConditionSelected) {
    return false;
  }

  if (dateRange?.auto?.enabled && !durationOffsetValid) {
    return false;
  }

  return true;
}

export function save(
  dateRange,
  isSelectingRelative,
  hasReportSchedule,
  hasMultipleSchedules,
  reportSchedule,
  shouldShowBulkEditModal,
  sqReportActions: ReportActions,
) {
  const clonedNewDateRange = _.cloneDeep(dateRange);
  _.set(clonedNewDateRange, 'condition.isCapsuleFromTable', !isSelectingRelative);

  if (clonedNewDateRange?.auto?.enabled && hasReportSchedule && !hasMultipleSchedules) {
    _.set(clonedNewDateRange, 'auto.cronSchedule', reportSchedule?.cronSchedule);
    _.set(clonedNewDateRange, 'auto.background', reportSchedule?.background);
  }

  return sqReportActions.saveDateRange(clonedNewDateRange).then((newDateRangeId) => {
    doTrack('Topic', DATE_RANGE_TRACK_ACTION, `${clonedNewDateRange.auto.enabled ? 'Auto-Updating' : 'Static'}`);

    const canShowConfigureAutoUpdateModal = clonedNewDateRange.auto?.enabled && !hasReportSchedule;
    sqReportActions.setShouldShowConfigureAutoUpdateModal(canShowConfigureAutoUpdateModal);

    if (shouldShowBulkEditModal) {
      sqReportActions.setBulkEditDisplayMode(BulkEditMode.DATE_RANGE);
      sqReportActions.setBulkDateRange({
        ...clonedNewDateRange,
        id: newDateRangeId,
      });
    } else if (canShowConfigureAutoUpdateModal) {
      sqReportActions.setShowConfigureAutoUpdateModal(true, true);
    }
  });
}
