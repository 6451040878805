// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { useIntervalWhen } from 'rooks';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { ReportContentBulkEditModal } from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/ReportContentBulkEditModal.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { ReportContentModal } from '@/hybrid/reportEditor/components/reportContentModal/ReportContentModal.molecule';
import { PdfPreviewModal } from '@/hybrid/reportEditor/components/PdfPreviewModal.organism';
import { Editor } from '@/hybrid/annotation/Editor.organism';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from 'react-i18next';
import { ASSET_SELECTION_WARNING_STATUS } from '@/reportEditor/report.store';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';

import {
  headlessJobFormat,
  headlessRenderMode,
  isEditWorkbookMode,
  isPresentationWorkbookMode,
} from '@/hybrid/utilities/utilities';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { FroalaPluginsService } from '@/reportEditor/froalaPlugins.service';
import { CustomPlugin } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { ContentBorder } from '@/hybrid/annotation/ckEditorPlugins/plugins/content/ContentBorder';
import { ReportEditorRendererDisabledError } from '@/hybrid/reportEditor/components/ReportEditorRendererDisabledError.atom';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { doTrack } from '@/track/track.service';
import { CopyForEmail } from '@/hybrid/annotation/ckEditorPlugins/plugins/CopyForEmail';
import { sqReportStore, sqWorksheetStore } from '@/core/core.stores';
import {
  areDocumentsEqual,
  beforePasteCleanup,
  canModifyDocument,
  froalaCleanup,
  handleCopyHtml,
  handleImageRemoved,
  handlePastedHtml,
  loadAllPendingContent,
  postReportViewed,
  setAllContentUrlsToFullUrls,
} from '@/hybrid/utilities/froalaReportContent.utilities';

export const ReportEditorBindings = bindingsDefinition({
  sqReportActions: injected<ReportActions>(),
  sqReportContent: injected<ReportContentService>(),
  sqReportEditor: injected<ReportEditorService>(),
  sqFroalaPlugins: injected<FroalaPluginsService>(),
  sqWorksheetActions: injected<WorksheetActions>(),
});

export const ReportEditor: SeeqComponent<typeof ReportEditorBindings> = () => {
  const { sqReportActions, sqReportContent, sqReportEditor, sqFroalaPlugins, sqWorksheetActions } =
    useInjectedBindings(ReportEditorBindings);

  const {
    sandboxMode,
    isFixedWidth,
    id,
    showBulkEditModal,
    isScheduleEnabled,
    backupPreview,
    isLoadingReport,
    reportSchedule,
    document: editorDocument,
  } = useFlux(sqReportStore);

  const reportEditorRef = useRef(null);
  const { t } = useTranslation();

  const isCkEditor = sqReportEditor.isCkEditor();
  const canModify = canModifyDocument();
  const isEditMode = isEditWorkbookMode();
  const copyCutEvent = (event) => handleCopyHtml(sqReportContent);

  useEffect(() => {
    initializeEditor();
    if (!isCkEditor) {
      document.addEventListener('copy', copyCutEvent);
      document.addEventListener('cut', copyCutEvent);
    }

    return () => {
      if (!isCkEditor) {
        document.removeEventListener('copy', copyCutEvent);
        document.removeEventListener('cut', copyCutEvent);
      }
      onEditorDestroy();
    };
  }, []);

  const initializeEditor = () => {
    if (canModify || isCkEditor) {
      // Initial document must be supplied to Froala via binding. Not used by froala after initialization.
    } else {
      // Delay one cycle to enable the dom to render
      sqReportEditor.setReportViewHtml(sqReportStore.document);
      sqReportContent.refreshAllContent();
      if (headlessRenderMode()) {
        Promise.resolve(headlessJobFormat()).then((format) => {
          if (format === 'PDF') {
            // We have to replace the urls because we want to make sure that the screenshots we're returning are
            // from the point in time that the PDF Export was initiated, and not at the current point in time --
            // the links should match the content images in the PDF.
            setAllContentUrlsToFullUrls();
          }
        });
      }
    }

    if (!sqReportEditor.isCkEditor()) {
      loadAllPendingContent(sqReportContent, sqReportEditor);
    }

    sqReportContent.subscribeToReport(sqReportStore.id);
    postReportViewed();
  };

  useIntervalWhen(() => postReportViewed(), 1000 * 60 * 10, true);

  const editorToolbar = [
    CustomPlugin.Content,
    'link',
    'imageUpload',
    'insertTable',
    CustomPlugin.FixedWidth,
    'pagebreak',
    'previousPage',
    'nextPage',
    'pageNavigation',
    '|',
    'heading',
    'fontColor',
    'fontBackgroundColor',
    'fontFamily',
    'fontSize',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'alignment',
    'outdent',
    'indent',
    'numberedList',
    'bulletedList',
    '|',
    'removeFormat',
    '|',
    CustomPlugin.PdfExport,
    CopyForEmail.pluginName,
    '|',
    'undo',
    'redo',
    '|',
    ContentBorder.pluginName,
    'IframeContent',
  ];

  const plugins = [
    CustomPlugin.Content,
    CustomPlugin.PdfExport,
    CustomPlugin.FixedWidth,
    CustomPlugin.SeeqPageBreak,
    CustomPlugin.ContentBorder,
  ];

  /**
   * Callback that handles initialization that must be done before the editor is created
   *
   * @param {Object} editorOptions - object container for editor options
   */
  const beforeEditorOnInit = (editorOptions) => {
    sqFroalaPlugins.topicDocumentContent();
    sqFroalaPlugins.refreshImage();
    sqFroalaPlugins.pdfExport();
    sqFroalaPlugins.pageBreak();

    const toolbarButtons = [
      'insertSeeqContent',
      'insertLink',
      'insertImage',
      'insertTable',
      'pageBreak',
      '|',
      'paragraphFormat',
      'textColor',
      'backgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikeThrough',
      '|',
      'align',
      'outdent',
      'indent',
      'formatOL',
      'formatUL',
      '|',
      'clearFormatting',
      '|',
      'pdfExport',
      '|',
      'undo',
      'redo',
      '|',
      'seeqContentBorders',
      'seeqFixedWidth',
    ];
    _.assign(editorOptions, {
      attribution: false,
      theme: 'topic',
      placeholderText: t('REPORT.EDITOR.PLACEHOLDER'),
      toolbarButtons,
      listAdvancedTypes: false,
      imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
      imageEditButtons: [
        'updateSeeqContent',
        'imageReplace',
        'linkOpen',
        'imageLink',
        'refreshImage',
        'imageAlign',
        'imageStyle',
        '-',
        'imageAlt',
        'filteredLinkEdit',
        'imageSize',
        'imageRemove',
      ],
    });
  };

  /**
   * Callback that handles registration that must be done after the editor is created
   *
   * @param {Object} editor - an editor reference
   */
  const afterEditorOnInit = (editor) => {
    if (!isCkEditor) {
      editor.events.on('paste.afterCleanup', (html) => handlePastedHtml(html, sqReportContent, sqReportEditor));
      editor.events.on('paste.beforeCleanup', (html) => beforePasteCleanup(html));
      editor.events.on('image.removed', (img) => handleImageRemoved(img));

      // Froala specific events are also triggered when a single image selected in the editor. This does not happen for
      // listeners added with document.addEventListener.
      // Theoretically it would be enough to only add the listeners below but during testing, when only a part of a
      // table with images was selected, the 'cut' listener was not called at all. Registering listeners here and on the
      // document is not a problem, handleCopyHtml will not add seeq-src twice.
      editor.events.on('window.copy', () => handleCopyHtml(sqReportContent));
      editor.events.on('window.cut', () => handleCopyHtml(sqReportContent));

      froalaCleanup();
    }
  };

  /**
   * Called by Angular when the controller is destroyed
   */
  const onEditorDestroy = () => {
    sqReportContent.unsubscribeFromReport();
    if (sandboxMode.enabled && reportSchedule) {
      sqReportActions.saveReportSchedule({
        enabled: false,
        background: false,
        cronSchedule: reportSchedule?.cronSchedule,
      });
    }
  };

  /**
   * Handler for callback notifications that the document has been changed by the user.
   *
   * @param {string} _document - not used, but callback expects this argument
   * @param {boolean} forceSave - true to force the document to save
   * @returns {Promise} Promise that resolves when the document has been saved
   */
  const documentChanged = useRef(_.noop);

  useEffect(() => {
    documentChanged.current = (_document: string, forceSave: boolean): Promise<any> => {
      // We're setting this here to make sure that if a restore backup preview is cancelled, we retain the most
      // recent document changes
      const updatedDocument = sqReportEditor.getHtml();
      if (areDocumentsEqual(editorDocument, updatedDocument) && !forceSave) {
        return Promise.resolve();
      }

      // Can remove the Promise.resolve once these methods no longer use angular promises
      if (isLoadingReport) {
        return Promise.resolve(sqReportActions.load(id));
      }

      return Promise.resolve(sqReportActions.update(id, updatedDocument));
    };
  }, [editorDocument, id]);

  const cancelRestoreBackup = () => {
    sqReportActions.clearBackupPreview();
    doTrack('Topic', 'Version History', 'cancel');
  };
  const restoreBackup = () => {
    sqReportActions.restoreBackup();
    doTrack('Topic', 'Version History', 'version restored');
  };

  const { showAssetSelectionWarnings } = useFlux(sqWorksheetStore);
  const { assetSelectionShowWarningStatus } = useFlux(sqReportStore);

  const [assetSelectionWarningStatus, setAssetSelectionWarningStatus] = useState(getAssetSelectionWarningStatus());

  function getAssetSelectionWarningStatus() {
    return !sqWorksheetStore.showAssetSelectionWarnings
      ? ASSET_SELECTION_WARNING_STATUS.DISMISSED
      : assetSelectionShowWarningStatus;
  }

  useEffect(() => {
    setAssetSelectionWarningStatus(getAssetSelectionWarningStatus());
  }, [showAssetSelectionWarnings, assetSelectionShowWarningStatus]);

  return (
    <div
      ref={reportEditorRef}
      className={classnames(
        'reportEditor flexFillOverflow flexRowContainer widerScrollbar documentContent documentBackground' +
          ' height-maximum',
        { fixedWidth: isFixedWidth, ckEditorMain: isCkEditor },
      )}>
      {/*Preload loading and error images so they are cached in case content updating uses all browser connections*/}
      <img src="/img/loading-pulse.gif" className="d-none" />
      <img src="/img/error.png" className="d-none" />
      <img src="/img/fa-calendar-times.png" className="d-none" />

      {!isScheduleEnabled && !sandboxMode.enabled && (
        <div id="specScheduleDisabledBanner" className="alert-warning p10">
          <span>
            <span className="pr8">{t('REPORT.CONFIG.SCHEDULE', { schedule: '' })}</span>
            {isEditMode
              ? t('REPORT.EDITOR.SCHEDULE_DISABLED_EDIT_MODE')
              : t('REPORT.EDITOR.SCHEDULE_DISABLED_VIEW_OR_PRESENTATION_MODE')}
          </span>
        </div>
      )}

      {sqReportStore.hasAssetSelectionWarnings && (
        <div className="alert-warning p10 lightGreyBorderTop">
          <span className="pr8">{t('REPORT.CONFIG.ASSET_SELECTION_WITH_COLON')}</span>
          {t('REPORT.CONFIG.ASSET_SELECTION_WORKSHEET_HAS_WARNINGS', {
            count: sqReportStore.numAssetSelectionWarnings,
          })}

          {!isPresentationWorkbookMode() && (
            <span>
              {assetSelectionWarningStatus !== ASSET_SELECTION_WARNING_STATUS.MESSAGES && (
                <span>
                  {
                    <a
                      href="#"
                      onClick={() => {
                        if (sqWorksheetStore.showAssetSelectionWarnings) {
                          sqWorksheetActions.setShowAssetSelectionWarnings(false);
                        } else {
                          sqWorksheetActions.setShowAssetSelectionWarnings(true);
                          sqReportActions.setContentShowWarningMessage(null, false);
                        }
                      }}
                      className="pl3">
                      {assetSelectionWarningStatus === ASSET_SELECTION_WARNING_STATUS.DISMISSED
                        ? t('REPORT.CONFIG.ASSET_SELECTION_WARNING_BANNER_SHOW')
                        : t('REPORT.CONFIG.ASSET_SELECTION_WARNING_BANNER_HIDE')}
                    </a>
                  }
                  {t('REPORT.CONFIG.ASSET_SELECTION_ALL_WARNING_ICONS')}
                </span>
              )}
              {assetSelectionWarningStatus !== ASSET_SELECTION_WARNING_STATUS.DISMISSED && (
                <>
                  {
                    <HoverTooltip
                      text={
                        assetSelectionWarningStatus === ASSET_SELECTION_WARNING_STATUS.ICONS
                          ? 'REPORT.CONFIG.ASSET_SELECTION_WARNING_BANNER_TOOLTIP'
                          : ''
                      }>
                      <a
                        href="#"
                        className="pl3"
                        onClick={() => {
                          sqReportActions.setContentShowWarningMessage(
                            null,
                            assetSelectionWarningStatus === ASSET_SELECTION_WARNING_STATUS.ICONS,
                          );
                        }}>
                        {assetSelectionWarningStatus === ASSET_SELECTION_WARNING_STATUS.ICONS
                          ? t('REPORT.CONFIG.ASSET_SELECTION_WARNING_BANNER_SHOW')
                          : t('REPORT.CONFIG.ASSET_SELECTION_WARNING_BANNER_HIDE')}
                      </a>
                    </HoverTooltip>
                  }
                  {t('REPORT.CONFIG.ASSET_SELECTION_ALL_WARNING_MESSAGES')}
                </>
              )}
            </span>
          )}
        </div>
      )}

      <ReportEditorRendererDisabledError
        isEditMode={isEditMode}
        forbiddenContentErrorsCount={sqReportStore.forbiddenContentErrorsCount}
        isReportScheduleError={sqReportStore.isReportScheduleError}
      />

      {showBulkEditModal && <ReportContentBulkEditModal show={showBulkEditModal} />}
      {backupPreview && (
        <div className="reportBackupPreview flexColumnContainer flexAlignCenter flexNoGrowNoShrink">
          <Icon
            extraClassNames="cursorPointer ml15 backArrow"
            icon="fa-arrow-left fa-2x"
            onClick={cancelRestoreBackup}
            tooltip={t('REPORT.CONTENT.RETURN_TO_ORIGINAL')}
          />

          <div className="dateDisplay font-size-xlarge ml20">{backupPreview.formattedBackupDate}</div>
          <TextButton
            onClick={restoreBackup}
            label={t('REPORT.CONTENT.RESTORE')}
            size="sm"
            testId="restoreButton"
            extraClassNames="ml20"
            variant="theme"
          />
        </div>
      )}
      <div className="flexFill flexRowContainer height-maximum reportEditorWrapper">
        <Editor
          id={id}
          canEdit={canModify && !backupPreview}
          document={editorDocument}
          documentChanged={documentChanged}
          beforeOnInit={beforeEditorOnInit}
          afterOnInit={afterEditorOnInit}
          onDestroy={onEditorDestroy}
          toolbar={editorToolbar} //toolbar
          plugins={plugins}
          backupDocument={backupPreview?.document}
          showBackup={backupPreview}
        />
      </div>
      <ReportContentModal />
      <PdfPreviewModal />
    </div>
  );
};

export const sqReportEditor = angularComponent(ReportEditorBindings, ReportEditor);
