// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SelectItem } from '@/hybrid/core/SelectItem.organism';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/hybrid/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { useShowError } from '@/hybrid/formbuilder/hooks/useShowError';
import { FormFieldWrapper } from '@/hybrid/formbuilder/FormFieldWrapper';

export type ItemSelectValue = { id: string } | string | string[];

export interface ItemSelectIF extends ValidatingFormComponent<ItemSelectValue> {
  component: 'ItemSelectFormComponent';
  label?: string;
  /** true if the select can be toggled between a text input field and a select */
  textEntryAllowed?: boolean;
  textPlaceholder?: string;
  /** if true then only text input will be displayed - so this only applies if textEntryAllowed is set to true */
  textToggleDisabled?: boolean;
  selectPlaceholder?: string;
  insideModal?: boolean;
  excludeWorkbookItems?: boolean;
  /** true does not automatically select even if there's only one item */
  disableAutoSelect?: boolean;
  includeMetadata?: boolean;
  excludeStringSignals?: boolean;
  includeOnlyStringSignals?: boolean;
  includeOnlyStepSignals?: boolean;
  isMultipleSelect?: boolean;
  excludedIds?: string[];
  additionalItems?: object[];
  /** to limit items to only certain item types */
  itemTypes?: string[];
  /** index used to set the threshold in scorecard */
  thresholdLevel?: string;
  /** used by formula, the name */
  identifier?: string;
  /** used by formula, index */
  paramIndex?: string;
  items?: object[];
  allowClear?: boolean;
  /** Determines if the select element is allowed to wrap when the name is long */
  noWrap?: boolean;
  value: { id: string } | string | string[];
  onChange?: (selectedItemOrValue: { id: string } | string, level?: number | string) => void;
  onRemove?: (selectedItem: object) => void;
  customErrorText?: string;
  /** Function to add all items in the details pane to the item select, button will not appear if undefined */
  onDetailsButtonClick?: () => void;
  addAllEnabled?: boolean;
  removeAllEnabled?: boolean;
  stopPropagation?: boolean;
}

export const ItemSelectFormComponent: React.FunctionComponent<ItemSelectIF> = (props) => {
  const {
    name,
    validation,
    label,
    testId = '',
    value,
    textPlaceholder,
    selectPlaceholder,
    extendValidation,
    extraClassNames,
    customErrorText,
    isMultipleSelect,
    onDetailsButtonClick = undefined,
    required = true,
    addAllEnabled = false,
    removeAllEnabled = false,
  } = props;
  const { t } = useTranslation();
  const defaultValidation = (value) => required && _.isEmpty(value) && value?.id !== '';
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const form = useForm();
  const formState = form.getState();
  const { showError, setShowError } = useShowError(false);

  const resetField = () => {
    form.change(name, undefined);
    form.resetFieldState(name);
  };

  const getInputValueId = (inputValue) => (_.isObject(inputValue) ? inputValue.id : inputValue);
  const getSelectedItem = (value: any, inputValue: any) => {
    // in some cases the value is a number, in some cases it's an id and sometimes it's an object - so we do this
    return value !== '' && inputValue !== '' ? getInputValueId(inputValue) : value;
  };

  const showLabel = label || (onDetailsButtonClick && isMultipleSelect);

  return (
    <FormFieldWrapper
      id={name}
      testId={testId}
      wrapperClassNames={classNames('flexFill', extraClassNames)}
      showError={showError}
      customErrorText={customErrorText}
      label={
        showLabel && (
          <div
            className="pb5 flexColumnContainer flexAlignBottom flexSpaceBetween flexFill"
            data-testid={`${testId}Label`}>
            {t(label)}
            {onDetailsButtonClick && isMultipleSelect && (
              <TextButton
                icon="fa-area-chart"
                extraClassNames="height-22 sq-btn-xs"
                label="DETAILS"
                tooltip="ADD_FROM_DETAILS_PANE"
                onClick={onDetailsButtonClick}
              />
            )}
          </div>
        )
      }>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const selectedValue = isMultipleSelect
            ? _.map(value, (item, index) => getSelectedItem(item, input.value[index]))
            : getSelectedItem(value, input.value);
          const formFieldProps = getFormFieldProps(formState, input, meta, props);
          setShowError(formFieldProps.showError);

          return (
            <SelectItem
              {...formFieldProps}
              onSelect={(item: string | { id: string }, level?: number | string) => {
                props.onChange(item, level);
                input.onChange(item);
              }}
              selectedItemId={selectedValue}
              selectPlaceholder={selectPlaceholder}
              textPlaceholder={textPlaceholder}
              onToggle={() => resetField()}
              isMultipleSelect={isMultipleSelect}
              addAllEnabled={addAllEnabled}
              removeAllEnabled={removeAllEnabled}
              dataTestId={testId}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
