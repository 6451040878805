// @ts-strict-ignore
import { flux } from '@/core/flux.module';

export function setInterpolationMethod(interpolationMethod) {
  flux.dispatch('MANUAL_SIGNAL_SET_INTERPOLATION_METHOD', {
    interpolationMethod,
  });
}

export function setMaximumInterpolation(maximumInterpolation) {
  flux.dispatch('MANUAL_SIGNAL_SET_MAXIMUM_INTERPOLATION', {
    maximumInterpolation,
  });
}

export function setUom(uom) {
  flux.dispatch('MANUAL_SIGNAL_SET_UOM', { uom });
}

export function addSample(sample) {
  flux.dispatch('MANUAL_SIGNAL_ADD_SAMPLE', { sample });
}

export function removeSample(id) {
  flux.dispatch('MANUAL_SIGNAL_REMOVE_SAMPLE', { id });
}

export function updateSample(sample) {
  flux.dispatch('MANUAL_SIGNAL_UPDATE_SAMPLE', { sample });
}

export function setEditingSampleId(editingSampleId) {
  flux.dispatch('MANUAL_SIGNAL_SET_EDITING_SAMPLE_ID', { editingSampleId });
}

export function setSort(sort) {
  flux.dispatch('MANUAL_SIGNAL_SET_SORT', { sort });
}
