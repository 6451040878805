// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { HOME_SCREEN_TABS, HOME_SCREEN_TABS_AND_TRANSLATION_KEYS } from '@/main/app.constants';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { NavigationEntry } from '@/hybrid/homescreen/NavigationEntry.atom';
import { FolderTree } from '@/hybrid/homescreen/FolderTree.organism';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { HOME_SCREEN_TABLE_TYPE } from '@/hybrid/homescreen/homescreen.constants';
import { sqHomeScreenStore, sqPluginStore } from '@/core/core.stores';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';

const homeScreenNavigationBindings = bindingsDefinition({
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
});

export const HomeScreenNavigation: SeeqComponent<typeof homeScreenNavigationBindings> = () => {
  const { sqHomeScreenUtilities } = useInjectedBindings(homeScreenNavigationBindings);

  const currentTab = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentTab);
  useFlux(sqPluginStore);

  const availableTabs = sqHomeScreenUtilities.getFilteredLocations(HOME_SCREEN_TABS_AND_TRANSLATION_KEYS);

  const userHasNoHomeScreenAddOns = sqPluginStore.homeScreenPlugins().length === 0;

  const isCurrentTab = (tab) => tab === currentTab;

  return (
    <div className="flexRowContainer height-maximum overflowAuto pb70">
      <div className="overflowAuto">
        {_.chain(availableTabs)
          .reject((tab) => tab.value === HOME_SCREEN_TABS.ADDONS && userHasNoHomeScreenAddOns)
          .map((tab: { text: string; value: string; folderRoot: string; id: string }) => (
            <div key={tab.value}>
              {tab.value === HOME_SCREEN_TABS.ADDONS ? <hr className="addOnSeparator" /> : null}
              <NavigationEntry
                tabKey={tab.value}
                displayValue={tab.text}
                current={isCurrentTab(tab.value)}
                id={tab.id}
              />
              {isCurrentTab(tab.value) && tab.folderRoot ? (
                <FolderTree tableType={HOME_SCREEN_TABLE_TYPE.TAB} topLevel={true} root={tab.folderRoot} />
              ) : (
                ''
              )}
            </div>
          ))
          .value()}
      </div>
    </div>
  );
};
