// @ts-strict-ignore
import { sqWorkbenchStore } from '@/core/core.stores';
import { sqUsersApi, UserInputV1 } from '@/sdk';

/**
 * Get the current user's workbench state. If there is no state, it returns an empty object.
 * @returns {Object} state for the current user's workbench.
 */
export function getWorkbench() {
  if (sqWorkbenchStore.currentUser.workbench) {
    return JSON.parse(sqWorkbenchStore.currentUser.workbench).state;
  }
  return {};
}

/**
 * Set the workbench state for the current user
 * @param {Object} state - The workbench state object
 * @returns {Promise} A promise that will resolve when the current user's workbench state has been set.
 */
export function setWorkbench(state) {
  const workbenchData = {
    workbench: JSON.stringify({
      version: WORKBENCH_SCHEMA_VERSION,
      state,
    }),
  } as UserInputV1;
  return sqUsersApi.updateUser(workbenchData, {
    id: sqWorkbenchStore.currentUser.id,
  });
}

export const WORKBENCH_SCHEMA_VERSION = 1;
