// @ts-strict-ignore
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ButtonGroup, Form, Popover } from 'react-bootstrap';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { onEnterKeypress, onSubmitPreventDefault } from '@/hybrid/core/onEnterKeypress.util';
import { ToolbarButton } from '@/hybrid/core/ToolbarButton.molecule';
import { ScorecardStore } from '@/investigate/scorecard/scorecard.store';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { ToolbarPopoverButton } from '@/hybrid/core/ToolbarPopoverButton.molecule';
import { WorksheetViewSelector } from '@/hybrid/trend/WorksheetViewSelector.molecule';
import { ChartSettings } from '@/hybrid/tableBuilder/tableViewer/ChartSettings.molecule';
import { TableBuilderActions } from '@/hybrid/tableBuilder/tableBuilder.actions';
import { ItemPropertiesSelector } from '@/hybrid/utilities/ItemPropertiesSelector.molecule';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import {
  CustomPropertySelector,
  METRIC_CONDITION_PROPERTIES,
  SuggestedPropertiesMode,
} from '@/hybrid/utilities/CustomPropertySelector.atom';
import { TrendActions } from '@/trendData/trend.actions';
import { SelectAssetForTableModal } from '@/hybrid/tableBuilder/SelectAssetForTableModal.molecule';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { ConditionStatsPropertiesButton } from '@/hybrid/tableBuilder/ConditionStatsPropertiesButton.molecule';
import { HelpButton } from '@/hybrid/core/HelpButton.atom';
import { NewLineToBr } from '@/hybrid/core/NewLineToBr.atom';
import { useResizeWatcher } from '@/hybrid/core/hooks/useResizeWatcher.hook';
import { SMALL_SIZE_SCREEN_LIMIT } from '@/main/app.constants';
import { DateFormatSelector } from '@/hybrid/core/DateFormatSelector.molecule';
import { HeaderOptionsSelector } from '@/hybrid/core/HeaderOptionsSelector.molecule';
import { dataToTable, getCategoryName } from '@/hybrid/tableBuilder/tableViewer/ChartUtilities';
import { isPresentationWorkbookMode, isStringSeries, isViewOnlyWorkbookMode } from '@/hybrid/utilities/utilities';
import {
  COLUMNS_AND_STATS,
  CONDITION_TABLE_ALLOWED_COLUMNS,
  ITEM_TYPES,
  SERIES_PANEL_EXTRA_TREND_COLUMNS,
  SERIES_PANEL_REQUIRED_TREND_COLUMNS,
  TREND_CONDITION_STATS,
  TREND_SIGNAL_STATS,
} from '@/trendData/trendData.constants';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import {
  SIMPLE_TABLE_BUILDER_EXTRA_CUSTOMIZATION_COLUMNS,
  TABLE_BUILDER,
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableBuilderMode,
} from '@/hybrid/tableBuilder/tableBuilder.constants';
import { doTrack } from '@/track/track.service';
import { sqTableBuilderStore, sqWorksheetStore } from '@/core/core.stores';

const tableBuilderToolbarBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqTableBuilderActions: injected<TableBuilderActions>(),
  sqScorecardStore: injected<ScorecardStore>(),
  sqTrendActions: injected<TrendActions>(),
  copyToClipboard: prop<() => void>(),
  isTableEmpty: prop<boolean>(),
  setChartViewSettings: prop<(settings: any) => void>(),
});

export const TableBuilderToolbar: SeeqComponent<typeof tableBuilderToolbarBindings> = (props) => {
  const { sqInvestigateActions, sqTableBuilderActions, sqScorecardStore } =
    useInjectedBindings(tableBuilderToolbarBindings);
  const { t } = useTranslation();
  const { copyToClipboard, isTableEmpty, setChartViewSettings } = props;

  const isMigrationRequired = useFluxPath(sqScorecardStore, () => !_.isEmpty(sqScorecardStore.metrics));
  const {
    headers,
    columns,
    overriddenHeaderColumn,
    isTransposed,
    isTableStriped,
    assetId,
    isHomogenizeUnits,
    simpleTableData,
    chartViewSettings,
  } = useFlux(sqTableBuilderStore);
  const isSimpleMode = useFluxPath(sqTableBuilderStore, () => sqTableBuilderStore.isSimpleMode());
  const showChartView = useFluxPath(sqTableBuilderStore, () => sqTableBuilderStore.showChartView);
  const [isAssetModalShown, setIsAssetModalShown] = useState(false);
  const [isSmallButton, setIsSmallButton] = useState(false);
  const toolbarElement = useRef(null);

  const onResize = (data) => {
    setIsSmallButton(data.newWidth < SMALL_SIZE_SCREEN_LIMIT);
  };

  useResizeWatcher({
    elementRef: toolbarElement,
    callback: onResize,
    callOnLoad: true,
  });

  const isPresentationMode = isPresentationWorkbookMode();
  const canEdit = !isViewOnlyWorkbookMode();
  const items = sqTableBuilderStore.getTableItems();
  const conditionIds: string[] = _.chain(items)
    .filter(
      (item) =>
        item.itemType === ITEM_TYPES.CAPSULE_SET ||
        (item.itemType === ITEM_TYPES.METRIC && (item.definition?.aggregationConditionId ?? false)),
    )
    .flatMap((item) =>
      item.itemType === ITEM_TYPES.METRIC
        ? _.compact([item.definition.aggregationConditionId, item.definition.boundingCondition?.id])
        : item.id,
    )
    .value();
  const isUsingDateHeaderType = _.includes(
    [TableBuilderHeaderType.Start, TableBuilderHeaderType.End, TableBuilderHeaderType.StartEnd],
    headers.type,
  );
  const HEADER_TYPES = TableBuilderHeaderType;
  const HEADER_CONFIGURATIONS = [
    {
      type: TableBuilderHeaderType.None,
      key: 'TABLE_BUILDER.HEADER_NONE',
      simple: true,
    },
    {
      type: TableBuilderHeaderType.Start,
      key: 'TABLE_BUILDER.HEADER_START',
      simple: true,
    },
    {
      type: TableBuilderHeaderType.End,
      key: 'TABLE_BUILDER.HEADER_END',
      simple: true,
    },
    {
      type: TableBuilderHeaderType.StartEnd,
      key: 'TABLE_BUILDER.HEADER_START_END',
      simple: true,
    },
    {
      type: TableBuilderHeaderType.CapsuleProperty,
      key: 'TABLE_BUILDER.HEADER_CAPSULE_PROPERTY',
      simple: false,
    },
  ];
  const setModeAndTrack = (mode) => {
    doTrack(TABLE_BUILDER, 'mode', mode);
    sqTableBuilderActions.setMode(mode);
  };
  const toggleTransposed = () => sqTableBuilderActions.setIsTransposed(!isTransposed);
  const toggleTableStriped = () => sqTableBuilderActions.setIsTableStriped(!isTableStriped);

  const getHeaderOptions = (isSimple: boolean) =>
    _.chain(HEADER_CONFIGURATIONS)
      .reject(({ simple }) => (isSimple ? simple !== isSimple : false))
      .map(({ type, key }) => ({
        label: t(key),
        id: `headers${type}`,
        value: type,
      }))
      .value();

  const renderHeaderOptions = (isSimple: boolean) => (
    <HeaderOptionsSelector
      options={getHeaderOptions(isSimple)}
      onChange={sqTableBuilderActions.setHeadersType}
      name="content-select-table-builder"
      defaultValue={headers.type}
    />
  );

  const renderDateRangeFormatSelection = () => (
    <DateFormatSelector
      label={t('TABLE_BUILDER.DATE_FORMAT')}
      predefinedFormatsLabel={t('TABLE_BUILDER.PREDEFINED_FORMATS')}
      value={headers.format}
      id="headersFormat"
      onChange={sqTableBuilderActions.setHeadersFormat}
      onKeyUp={onEnterKeypress(sqTableBuilderActions.setHeadersFormat)}
      timezone={sqWorksheetStore.timezone.name}
    />
  );

  const trackAndSetIsAssetModalShown = () => {
    setIsAssetModalShown(true);
    doTrack(TABLE_BUILDER, 'Asset Button clicked');
  };

  const tableData = dataToTable(simpleTableData, columns, t);
  const columnOptions = columns.map((element, index) => ({
    value: index,
    label: tableData[0][index],
    isText: ['assets', 'string'].includes(element.style) || element.type === 'text',
  }));
  const seriesNamesOptions = columnOptions.filter((element) => element.isText);

  let xAxisOptions = columnOptions.filter((element) => !element.isText);
  let yAxisOptions = tableData.slice(1).map((item, index) => ({
    value: index + 1,
    label: getCategoryName(tableData, index + 1, chartViewSettings.categoryColumns),
    isText: false,
  }));

  if (isTransposed) {
    const swapValueHolder = xAxisOptions;
    xAxisOptions = yAxisOptions;
    yAxisOptions = swapValueHolder;
  }

  const conditionColumnsPopover = () => (
    <Popover className="max-width-400" id="item-properties-selector-popover-toolbar-condition">
      <Popover.Content>
        <ItemPropertiesSelector
          extraColumns={_.map(CONDITION_TABLE_ALLOWED_COLUMNS, (column) =>
            column.key === COLUMNS_AND_STATS.name.key
              ? {
                  ...column,
                  title: `TABLE_BUILDER.${isTransposed ? 'COLUMN_LABELS' : 'ROW_LABELS'}`,
                }
              : column,
          )}
          itemIds={_.map(items, 'id')}
          isColumnEnabled={sqTableBuilderActions.isTableColumnEnabled}
          toggleColumn={sqTableBuilderActions.toggleColumn}
          customContent={
            <TextButton
              label={`TABLE_BUILDER.${isTransposed ? 'ROW_CUSTOM_TEXT' : 'COLUMN_CUSTOM_TEXT'}`}
              testId="addTextColumnButton"
              extraClassNames="mt3"
              onClick={() => sqTableBuilderActions.addTextColumn()}
            />
          }
          showCustomPropertySelector={false}
        />
      </Popover.Content>
    </Popover>
  );

  const conditionHeadersPopover = () => (
    <Popover id="table-headers-popover">
      <Popover.Title>
        {!isTransposed
          ? t('TABLE_BUILDER.HEADERS_TOOLTIP.CONDITION')
          : t('TABLE_BUILDER.HEADERS_TRANSPOSED_TOOLTIP.CONDITION')}
      </Popover.Title>
      <Popover.Content>
        <div className="flexRowContainer flexNoGrowNoShrink">
          <Form onSubmit={onSubmitPreventDefault}>
            {renderHeaderOptions(false)}
            {headers.type !== HEADER_TYPES.None && <hr className="mt10 mb10" />}
            {isUsingDateHeaderType && renderDateRangeFormatSelection()}
            {headers.type === HEADER_TYPES.CapsuleProperty && (
              <div className="flexRowContainer form-check">
                {headers.property && (
                  <label className="form-check-label">
                    <Icon
                      icon="fa-close"
                      testId="propertyRemove"
                      extraClassNames="btn-transparent removeButton"
                      onClick={() => {
                        sqTableBuilderActions.setHeadersProperty('');
                        sqTableBuilderActions.setHeadersType(TableBuilderHeaderType.CapsuleProperty);
                      }}
                    />
                    <span className="text-not-bold">{headers.property}</span>
                  </label>
                )}
                {!headers.property && (
                  <CustomPropertySelector
                    excludedProperties={METRIC_CONDITION_PROPERTIES}
                    suggestedPropertiesMode={SuggestedPropertiesMode.Capsules}
                    itemIds={conditionIds}
                    dropdownPlaceholder="TABLE_BUILDER.HEADER_CAPSULE_PROPERTY"
                    addPropertyColumn={({ propertyName }) => sqTableBuilderActions.setHeadersProperty(propertyName)}
                  />
                )}
              </div>
            )}
          </Form>
        </div>
      </Popover.Content>
    </Popover>
  );

  const simpleHeadersPopover = () => {
    const formattedColumnOptions = [{ value: undefined, label: '-' }].concat(
      _.map(columns, (column) => ({
        value: column.key,
        label: column.header ?? (column.type === TableBuilderColumnType.Property ? column.key : t(column.shortTitle)),
      })),
    );
    return (
      <Popover id="table-headers-popover">
        <Popover.Title>{t('TABLE_BUILDER.HEADERS_POPOVER_TITLE.SIMPLE')}</Popover.Title>
        <Popover.Content>
          <div className="flexRowContainer flexNoGrowNoShrink">
            <Form onSubmit={onSubmitPreventDefault}>
              {renderHeaderOptions(true)}
              <hr className="mt10 mb10" />
              {renderDateRangeFormatSelection()}
              {headers.type !== TableBuilderHeaderType.None && (
                <>
                  <hr className="mt10 mb10" />
                  <Form.Group data-testid="headerColumnsSelect">
                    <p>{t('TABLE_BUILDER.HEADERS_POPOVER_TITLE.SIMPLE_PLACEHOLDER')}</p>
                    <Select
                      placeholder={t('TABLE_BUILDER.HEADERS_POPOVER_TITLE.SIMPLE_PLACEHOLDER')}
                      value={_.find(formattedColumnOptions, {
                        value: overriddenHeaderColumn?.key,
                      })}
                      options={formattedColumnOptions}
                      isSearchable={false}
                      onChange={(selectedOption) => sqTableBuilderActions.setHeaderOverridden(selectedOption.value)}
                      classNamePrefix="react-select"
                    />
                  </Form.Group>
                </>
              )}
            </Form>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  const simpleItemPropertiesPopover = () => {
    const extraColumns = _.chain(SERIES_PANEL_REQUIRED_TREND_COLUMNS)
      .concat(SERIES_PANEL_EXTRA_TREND_COLUMNS)
      .concat(SIMPLE_TABLE_BUILDER_EXTRA_CUSTOMIZATION_COLUMNS)
      .map((key) => COLUMNS_AND_STATS[key])
      .value();
    return (
      <Popover className="max-width-400" id="item-properties-selector-popover-toolbar-simple">
        <Popover.Content>
          <ItemPropertiesSelector
            statColumns={_.uniqBy(TREND_SIGNAL_STATS.concat(TREND_CONDITION_STATS), 'key')}
            extraColumns={extraColumns}
            propertyColumns={sqTableBuilderStore.propertyColumns}
            itemIds={_.map(items, 'id')}
            isColumnEnabled={sqTableBuilderActions.isTableColumnEnabled}
            toggleColumn={sqTableBuilderActions.toggleColumn}
            addPropertyColumn={sqTableBuilderActions.addPropertyColumn}
            customContent={
              <TextButton
                label={!isTransposed ? 'TABLE_BUILDER.COLUMN_CUSTOM_TEXT' : 'TABLE_BUILDER.ROW_CUSTOM_TEXT'}
                testId="addTextColumnButton"
                extraClassNames="mt3"
                onClick={() => sqTableBuilderActions.addTextColumn()}
              />
            }
          />
        </Popover.Content>
      </Popover>
    );
  };

  const chartViewPopover = () => {
    return (
      <Popover id="settings-chart-view-popover" className="medium">
        <Popover.Content className="mt10" data-testid="chartSettingsPopover">
          <ChartSettings
            changeCallback={setChartViewSettings}
            tableData={tableData}
            seriesNamesOptions={seriesNamesOptions}
            yAxisOptions={yAxisOptions}
            xAxisOptions={xAxisOptions}
            settings={chartViewSettings}
          />
        </Popover.Content>
      </Popover>
    );
  };
  const chartViewButtonTooltip = showChartView ? 'TABLE_BUILDER.HIDE_CHART_VIEW' : 'TABLE_BUILDER.SHOW_CHART_VIEW';

  return (
    !isPresentationMode && (
      <div
        className="flexColumnContainer flexAlignCenter toolbar"
        ref={(ref) => {
          toolbarElement.current = ref;
        }}>
        <WorksheetViewSelector />
        {!isMigrationRequired && canEdit && (
          <ButtonGroup className="flexColumnContainer">
            <ToolbarButton
              onClick={() => setModeAndTrack(TableBuilderMode.Simple)}
              active={isSimpleMode}
              label="TABLE_BUILDER.TABLE_TYPE_SIMPLE"
              icon="fc-series"
              tooltipText="TABLE_BUILDER.TABLE_TYPE_SIMPLE_TOOLTIP"
              tooltipPlacement="top"
              isSmall={isSmallButton}
              testId="simpleModeButton"
            />
            <ToolbarButton
              onClick={() => setModeAndTrack(TableBuilderMode.Condition)}
              active={!isSimpleMode}
              label="TABLE_BUILDER.TABLE_TYPE_CONDITION"
              icon="fc-capsule-set"
              tooltipText="TABLE_BUILDER.TABLE_TYPE_CONDITION_TOOLTIP"
              tooltipPlacement="top"
              isSmall={isSmallButton}
              testId="conditionModeButton"
            />
          </ButtonGroup>
        )}
        <ButtonGroup className="flexColumnContainer">
          <ToolbarPopoverButton
            icon="fc-bar-chart"
            label="TABLE_BUILDER.CHART_VIEW"
            tooltipText={!isSimpleMode ? 'TABLE_BUILDER.NOT_FOR_CONDITION' : chartViewButtonTooltip}
            tooltipPlacement="top"
            testId="showChartViewButton"
            isSmall={isSmallButton}
            active={showChartView}
            disabled={!isSimpleMode}
            extraClassNames={classNames({ mt2: isSmallButton })}>
            {chartViewPopover()}
          </ToolbarPopoverButton>
        </ButtonGroup>
        {!isMigrationRequired && canEdit && isSimpleMode && (
          <ButtonGroup
            className={classNames('flexColumnContainer', {
              flexAlignCenter: isSmallButton,
            })}>
            <ToolbarPopoverButton
              extraClassNames={classNames({
                'ml3 mr5': isTransposed,
                'mt3': isSmallButton,
              })}
              icon={!isTransposed ? 'fc-add-column' : 'fc-add-row'}
              label={!isTransposed ? 'TABLE_BUILDER.INSERT_COLUMN' : 'TABLE_BUILDER.INSERT_ROW'}
              tooltipText="TABLE_BUILDER.INSERT_STATS_PROPERTIES_TOOLTIP"
              tooltipPlacement="top"
              testId="itemPropertyButton"
              isSmall={isSmallButton}>
              {simpleItemPropertiesPopover()}
            </ToolbarPopoverButton>
            <ToolbarPopoverButton
              icon="fa-table fa-lg"
              label="TABLE_BUILDER.HEADERS"
              tooltipText={
                !isTransposed
                  ? t('TABLE_BUILDER.HEADERS_TOOLTIP.SIMPLE')
                  : t('TABLE_BUILDER.HEADERS_TRANSPOSED_TOOLTIP.SIMPLE')
              }
              tooltipPlacement="top"
              testId="headersButton"
              isSmall={isSmallButton}>
              {simpleHeadersPopover()}
            </ToolbarPopoverButton>
            <ToolbarButton
              onClick={toggleTransposed}
              icon="fa-exchange fa-rotate-270"
              label="TABLE_BUILDER.TRANSPOSE"
              tooltipText="TABLE_BUILDER.TRANSPOSE_TOOLTIP"
              tooltipPlacement="top"
              testId="transposeButton"
              active={isTransposed}
              isSmall={isSmallButton}
            />
            <ToolbarButton
              onClick={toggleTableStriped}
              icon="fa-minus-square"
              label="TABLE_BUILDER.STRIPED"
              tooltipText="TABLE_BUILDER.STRIPED_TOOLTIP"
              tooltipPlacement="top"
              testId="stripeButton"
              active={isTableStriped}
              isSmall={isSmallButton}
            />
            <ToolbarButton
              onClick={trackAndSetIsAssetModalShown}
              icon="fa-cube"
              label="TABLE_BUILDER.ASSET"
              tooltipText="TABLE_BUILDER.ASSET_TOOLBAR_TOOLTIP"
              tooltipPlacement="top"
              testId="assetButton"
              active={!!assetId}
              isSmall={isSmallButton}
            />
            <SelectAssetForTableModal
              show={isAssetModalShown}
              onClose={() => setIsAssetModalShown(false)}
              assetId={assetId}
              homogenizeUnits={isHomogenizeUnits}
            />
          </ButtonGroup>
        )}
        {!isMigrationRequired && canEdit && !isSimpleMode && (
          <ButtonGroup
            className={classNames('flexColumnContainer', {
              flexAlignCenter: isSmallButton,
            })}>
            <ToolbarPopoverButton
              extraClassNames={classNames('mr6', { mt3: isSmallButton })}
              icon={!isTransposed ? 'fc-add-column' : 'fc-add-row'}
              label={!isTransposed ? 'TABLE_BUILDER.INSERT_COLUMN' : 'TABLE_BUILDER.INSERT_ROW'}
              tooltipText={
                !isTransposed ? 'TABLE_BUILDER.INSERT_STATS_PROPERTIES_TOOLTIP' : 'TABLE_BUILDER.ROW_TRANSPOSE_TOOLTIP'
              }
              tooltipPlacement="top"
              testId="itemPropertyButton"
              isSmall={isSmallButton}>
              {conditionColumnsPopover()}
            </ToolbarPopoverButton>
            <ConditionStatsPropertiesButton
              isTransposed={isTransposed}
              propertyColumns={sqTableBuilderStore.propertyColumns}
              conditionIds={conditionIds}
              isColumnEnabled={sqTableBuilderActions.isTableColumnEnabled}
              toggleColumn={sqTableBuilderActions.toggleColumn}
              addPropertyColumn={sqTableBuilderActions.addPropertyColumn}
              removeColumn={sqTableBuilderActions.removeColumn}
              isStringSignal={isStringSeries}
              isSmallButton={isSmallButton}
              extraClassNames={isSmallButton ? 'mt3' : ''}
            />
            <ToolbarPopoverButton
              icon="fa-table fa-lg"
              label="TABLE_BUILDER.HEADERS"
              tooltipText={
                !isTransposed
                  ? 'TABLE_BUILDER.HEADERS_TOOLTIP.CONDITION'
                  : 'TABLE_BUILDER.HEADERS_TRANSPOSED_TOOLTIP.CONDITION'
              }
              tooltipPlacement="top"
              testId="headersButton"
              isSmall={isSmallButton}>
              {conditionHeadersPopover()}
            </ToolbarPopoverButton>
            <ToolbarButton
              onClick={toggleTransposed}
              icon="fa-exchange fa-rotate-270"
              label="TABLE_BUILDER.TRANSPOSE"
              tooltipText="TABLE_BUILDER.TRANSPOSE_TOOLTIP"
              tooltipPlacement="top"
              testId="transposeButton"
              active={isTransposed}
              isSmall={isSmallButton}
            />
            <ToolbarButton
              onClick={toggleTableStriped}
              icon="fa-minus-square"
              label="TABLE_BUILDER.STRIPED"
              tooltipText="TABLE_BUILDER.STRIPED_TOOLTIP"
              tooltipPlacement="top"
              testId="stripeButton"
              active={isTableStriped}
              isSmall={isSmallButton}
            />
            <ToolbarButton
              onClick={trackAndSetIsAssetModalShown}
              icon="fa-cube"
              label="TABLE_BUILDER.ASSET"
              tooltipText="TABLE_BUILDER.ASSET_TOOLBAR_TOOLTIP"
              tooltipPlacement="top"
              testId="assetButton"
              active={!!assetId}
              isSmall={isSmallButton}
            />
            <SelectAssetForTableModal
              show={isAssetModalShown}
              onClose={() => setIsAssetModalShown(false)}
              assetId={assetId}
              homogenizeUnits={isHomogenizeUnits}
            />
          </ButtonGroup>
        )}
        {!isMigrationRequired && canEdit && (
          <ButtonGroup>
            <ToolbarButton
              onClick={() => sqInvestigateActions.setActiveTool(TREND_TOOLS.THRESHOLD_METRIC)}
              label="TABLE_BUILDER.NEW_METRIC"
              tooltipText="TABLE_BUILDER.NEW_METRIC_TOOLTIP"
              tooltipPlacement="top"
              icon="fc-metric"
              isSmall={isSmallButton}
              testId="addNewMetricButton"
            />
          </ButtonGroup>
        )}
        {!isMigrationRequired && !canEdit && (
          <ButtonGroup
            className={classNames('flexColumnContainer', {
              flexAlignCenter: isSmallButton,
            })}>
            <ToolbarButton
              onClick={toggleTransposed}
              icon="fa-exchange fa-rotate-270"
              label="TABLE_BUILDER.TRANSPOSE"
              tooltipText="TABLE_BUILDER.TRANSPOSE_TOOLTIP"
              tooltipPlacement="top"
              testId="transposeButton"
              active={isTransposed}
              isSmall={isSmallButton}
            />
            <ToolbarButton
              onClick={toggleTableStriped}
              icon="fa-minus-square"
              label="TABLE_BUILDER.STRIPED"
              tooltipText="TABLE_BUILDER.STRIPED_TOOLTIP"
              tooltipPlacement="top"
              testId="stripeButton"
              active={isTableStriped}
              isSmall={isSmallButton}
            />
          </ButtonGroup>
        )}
        {!isMigrationRequired && (
          <ButtonGroup className="flexColumnContainer">
            <ToolbarButton
              onClick={copyToClipboard}
              icon="fa-copy"
              disabled={isTableEmpty}
              label="TABLE_BUILDER.COPY_TO_CLIPBOARD"
              tooltipText={
                isTableEmpty
                  ? 'TABLE_BUILDER.COPY_TO_CLIPBOARD_EMPTY_TABLE_TOOLTIP'
                  : 'TABLE_BUILDER.COPY_TO_CLIPBOARD_TOOLTIP'
              }
              tooltipPlacement="top"
              testId="copyButton"
              isSmall={isSmallButton}
            />
          </ButtonGroup>
        )}
        <div className="flexFill" />
        <HelpButton
          iconClassNames="fa-xlg pl8 mt5 text-interactive"
          customChildren={
            <div className="m5 flexRowContainer">
              <div className="flexColumnContainer flexSpaceAround">
                <span className="tableBuilderHelpText">
                  <strong>{t('TABLE_BUILDER.TABLE_TYPE_SIMPLE')}</strong>
                  <NewLineToBr lineToBreak={t('TABLE_BUILDER.HELP_INFO')} />
                  <strong className="coloredAnalysis">{t('TABLE_BUILDER.WHOLE_DISPLAY_RANGE')}</strong>
                </span>
                <span className="tableBuilderHelpText">
                  <strong>{t('TABLE_BUILDER.TABLE_TYPE_CONDITION')}</strong>
                  <NewLineToBr lineToBreak={t('TABLE_BUILDER.HELP_INFO')} />
                  <strong className="coloredReport">{t('TABLE_BUILDER.EACH_CAPSULE')}</strong>
                </span>
              </div>
              <img className="width-780 m10" src="/img/simple-vs-condition-table.png" />
            </div>
          }
          customKnowledgeBaseLink={
            <a
              target="_blank"
              className="ml5 mb5"
              href="https://telemetry.seeq.com/support-link/wiki/spaces/KB/pages/1617592515">
              <Icon icon="fc-knowledge-base" extraClassNames="mr5 fa-fw fa-lg" />
              {t('TABLE_BUILDER.HEADER_INFO_LINK')}
            </a>
          }
        />
      </div>
    )
  );
};

export const sqTableBuilderToolbar = angularComponent(tableBuilderToolbarBindings, TableBuilderToolbar);
