// Constants for tool ids of tools that can be opened from toolbar buttons
export const SCATTER_CONDITION_TOOL_ID = 'scatter-condition';
export const EXPORT_TO_ODATA_TOOL_ID = 'export-odata';

export type InvestigateToolType = {
  id: string;
  name?: string;
  nameTranslationKey?: string;
  parentId?: string;
  trackAction?: string;
  iconClass?: string;
  description?: string;
  descriptionTranslationKey?: string;
  hideFromSearch?: boolean;
  isGroup?: boolean;
  defaultDisplayMode?: string;
  forAllSeries?: true;
  forAllItems?: true;
  // For add-on tools
  targetUrl?: string;
  linkType?: string;
  windowDetails?: string;
  sortKey?: string;
  type?: string;
  reuseWindow?: boolean;
  // For plugin tools
  icon?: string; // may be icon class or SVG path
};

export const LOW_PASS_FILTER_CUTOFF = { value: undefined, units: undefined };
export const LOW_PASS_FILTER_PERIOD_CUTOFF_RATIO = 5;
export const NYQUIST_CUTOFF_RATIO = 2;
export const COMPARISON_OPERATORS = ['<', '<=', '>', '>=', '=', '!=', '><', '<>'];
export const STRING_COMPARISON_OPERATORS = ['~=', '!~'];
export const COMPARISON_OPPOSITE_OPERATORS = {
  '<': '>=',
  '>': '<=',
  '<=': '>',
  '>=': '<',
  '=': '!=',
  '!=': '=',
  '~=': '!~',
  '!~': '~=',
  '><': '<>',
  '<>': '><',
};
// Corresponds to the ScalarPredicates on the backend
export const PREDICATE_API = {
  '<': 'isLessThan',
  '<=': 'isLessThanOrEqualTo',
  '=': 'isEqualTo',
  '!=': 'isNotEqualTo',
  '~=': 'isMatch',
  '!~': 'isNotMatch',
  '>=': 'isGreaterThanOrEqualTo',
  '>': 'isGreaterThan',
  '><': 'isBetween',
  '<>': 'isNotBetween',
};
// Gets the symbol for an operator by name
export const COMPARISON_OPERATORS_SYMBOLS = {
  IS_LESS_THAN: '<',
  IS_LESS_THAN_OR_EQUAL_TO: '<=',
  IS_EQUAL_TO: '=',
  IS_NOT_EQUAL_TO: '!=',
  IS_MATCH: '~=',
  IS_NOT_MATCH: '!~',
  IS_GREATER_THAN_OR_EQUAL_TO: '>=',
  IS_GREATER_THAN: '>',
  IS_BETWEEN: '><',
  IS_NOT_BETWEEN: '<>',
};
export const DEVIATION_CONDITIONS = {
  ABOVE: 'ABOVE',
  BELOW: 'BELOW',
  OUTSIDE: 'OUTSIDE',
};
// Corresponds to the ReferenceTableStat constants on the backend
export const REFERENCE_STATS = {
  AVERAGE: 'Average',
  MIN: 'Min',
  MAX: 'Max',
  AVE_DEV: 'AveDev',
  MAX_DEV: 'MaxDev',
  STD_DEV: 'StdDev',
};
export const METRIC_COLORS = ['#ff0000', '#ffdd52', '#6263AD', '#068C45', '#ffffff'];
export const VALUE_SEARCH_DURATIONS = {
  MERGE: 'mergeDuration',
  MIN: 'minDuration',
  MAX: 'maxDuration',
  ENTRY: 'entryDuration',
  EXIT: 'exitDuration',
};
// When deprecating a tool, make sure to add an upgrade step similar to upgrade27 in the workstepUpgrader (CRAB-22371)
export const DEPRECATED_TOOL_NAMES = {
  LIMITS: 'limits',
  DEVIATION: 'deviation',
  LOW_PASS: 'low-pass-filter',
  VISUAL_SEARCH: 'visual-search',
  CAPSULE_SET_SERIES: 'capsule-set-series',
  POWER_SEARCH: 'power-search',
  CUSTOM_CONDITION: 'custom-condition',
};
