// @ts-strict-ignore
import React, { useState } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EditWorkbookModal } from '@/hybrid/homescreen/EditWorkbookModal.organism';
import { FolderExplorerModal } from '@/hybrid/explorer/FolderExplorerModal.organism';
import _ from 'lodash';
import { APP_STATE, HOME_SCREEN_TABS } from '@/main/app.constants';
import { PinnedActionIcon } from '@/hybrid/homescreen/PinnedActionIcon.molecule';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { HomeScreenInfoDropdown } from '@/hybrid/homescreen/HomeScreenInfoDropdown.organism';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';

import { EventCapture } from '@/hybrid/core/EventCapture.atom';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ItemAclModal } from '@/hybrid/accessControl/ItemAclModal.molecule';
import { getWorksheets } from '@/hybrid/worksheets/worksheets.utilities';
import { ButtonWithPopover } from '@/hybrid/core/ButtonWithPopover.molecule';
import { equalsIgnoreCase, generateTabHash } from '@/hybrid/utilities/utilities';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';
import { HOME_SCREEN_SORT, HOME_SCREEN_TABLE_TYPE, ITEM_TYPES } from '@/hybrid/homescreen/homescreen.constants';
import { sqHomeScreenStore } from '@/core/core.stores';
import { canManageItem, canModifyWorkbook, canReadItem } from '@/services/authorization.service';

const homeScreenItemActionBindings = bindingsDefinition({
  item: prop<any>(),
  openAction: prop<(object: { id: string }) => void>(),
  currentFolderId: prop<string>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  $state: injected<ng.ui.IStateService>(),
  folderId: prop.optional<string>(),
  showOnlyFolderLinks: prop.optional<boolean>(),
});

export const HomeScreenItemActions: SeeqComponent<typeof homeScreenItemActionBindings> = (props) => {
  const { sqHomeScreenUtilities, sqHomeScreenActions, $state } = useInjectedBindings(homeScreenItemActionBindings);

  const { currentFolderId, openAction, folderId, showOnlyFolderLinks } = props;

  let { item } = props;
  // legacy code that gets called relies on a 'workbookId'
  item = _.assign({}, item, { workbookId: item.id });

  const { t } = useTranslation();

  const [showFolderExplorerModal, setShowFolderExplorerModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showItemAclModal, setShowItemAclModal] = useState(false);
  const [workbookId, setWorkbookId] = useState('');
  const [worksheetId, setWorksheetId] = useState('');

  const [, setIsCopying] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const isReportBinder = _.get(item, 'type') === ITEM_TYPES.TOPIC;
  const baseClasses = classNames('sq-text-primary', 'fa', 'fa-fw', 'correctSpin', 'mr10', 'ml6', {
    folderActions: !(isMenuOpen || isInfoOpen),
  });

  const handleEditNameClose = () => setShowEditModal(false);
  const handleFolderExplorerClose = () => setShowFolderExplorerModal(false);
  const handleItemAclClose = () => setShowItemAclModal(false);
  const currentTab = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentTab);
  let corporateTab;

  const restoreWorkbook = () => {
    // Make the workbook visible in the all items view, this works only because the workbook is always restored to
    // the top level of the workbench (never to a parent folder). We also don't need to update the filter because
    // the user needed to have the workbook displayed to restore it.
    // Note that we do this before restoring the workbook so as to avoid race conditions where the User doesn't yet have
    // the right display selector saved by the time we're ready to show the workbooks.

    return sqHomeScreenActions
      .restoreWorkbook(item)
      .then(() => sqHomeScreenUtilities.getTabFolder(HOME_SCREEN_TABS.CORPORATE))
      .then((tabFolder) => {
        corporateTab = tabFolder;
      })
      .then(() => sqHomeScreenUtilities.getWorkbenchItem(item.id))
      .then((item) => {
        const parentId = _.chain(item).get('ancestors').last().get('id').value();
        if (_.isNil(parentId)) {
          return $state.go(APP_STATE.WORKBOOKS, { t: generateTabHash(HOME_SCREEN_TABS.MY_FOLDER) }, { reload: true });
        } else {
          const tab = equalsIgnoreCase(parentId, corporateTab.id)
            ? HOME_SCREEN_TABS.CORPORATE
            : HOME_SCREEN_TABS.MY_FOLDER;
          return $state.go(
            APP_STATE.FOLDER_EXPANDED,
            {
              currentFolderId: parentId,
              t: generateTabHash(tab),
            },
            { reload: true },
          );
        }
      })
      .then(() =>
        requestAnimationFrame(() =>
          successToast({
            messageKey: 'TRASH.ITEM_RESTORED_NOTIFICATION',
            messageParams: { ITEM_NAME: item.name },
          }),
        ),
      )
      .catch((error) => {
        errorToast({ httpResponseOrError: error, displayForbidden: true });
      });
  };

  const trashItem = () => {
    sqHomeScreenActions
      .removeWorkbook(item)
      .then(() => {
        // Try to keep the user in the same view/folder they were in before they removed the item
        if (sqHomeScreenStore.currentFolderId) {
          return $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId }, { reload: true });
        } else {
          return $state.go(APP_STATE.WORKBOOKS, {}, { reload: true });
        }
      })
      .then(() => {
        if (canManageItem(item)) {
          return requestAnimationFrame(() =>
            successToast({
              messageKey: 'TRASH.ITEM_TRASHED_NOTIFICATION',
              messageParams: { ITEM_NAME: item.name },
              buttonLabelKey: 'RESTORE',
              buttonAction: restoreWorkbook,
            }),
          );
        } else {
          return requestAnimationFrame(() =>
            successToast({
              messageKey: 'TRASH.ITEM_TRASHED_NOTIFICATION',
              messageParams: { ITEM_NAME: item.name },
            }),
          );
        }
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error, displayForbidden: true });
      });
  };

  const duplicate = () => {
    let duplicate;
    let myFolderTab;
    const parentFolderId = item.parentFolderId;
    setIsCopying(true);

    const getAllowedParentPromise = sqHomeScreenUtilities
      .getTabFolder(HOME_SCREEN_TABS.MY_FOLDER)
      .then((tabFolder) => {
        myFolderTab = tabFolder;
      })
      .then(() => sqHomeScreenActions.canCreateItemInFolder(parentFolderId))
      .then((canWrite) => (canWrite ? parentFolderId : myFolderTab.id));

    const navigateToItem = (item) => {
      if (item.parentFolderId !== myFolderTab.id) {
        return $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId: parentFolderId }, { reload: true });
      } else {
        return $state.go(APP_STATE.WORKBOOKS, { t: generateTabHash(HOME_SCREEN_TABS.MY_FOLDER) }, { reload: true });
      }
    };

    if (item.type === ITEM_TYPES.FOLDER) {
      return getAllowedParentPromise.then((parentFolderId) => {
        sqHomeScreenActions
          .addFolder({
            name: `${item.name} - ${t('COPY')}`,
            parentFolderId,
            branchFrom: item.id,
          })
          .then((folder) => navigateToItem(folder))
          .catch((error) => errorToast({ httpResponseOrError: error, displayForbidden: true }))
          .finally(() => setIsCopying(false));
      });
    } else {
      return getAllowedParentPromise.then((folderId) => {
        sqHomeScreenActions
          .addWorkbook({
            name: `${item.name} - ${t('COPY')}`,
            branchFrom: item.id,
            isReportBinder,
            folderId,
          })
          .then((item) => {
            duplicate = item;
            sqHomeScreenActions.setTableSort(HOME_SCREEN_SORT.CREATED_AT, false, HOME_SCREEN_TABLE_TYPE.TAB);
            navigateToItem(item).then(() => {
              requestAnimationFrame(() =>
                successToast({
                  messageKey: 'HOME_SCREEN.DUPLICATION_SUCCESS',
                  messageParams: { ITEM_NAME: duplicate.name },
                  buttonLabelKey: 'OPEN',
                  buttonAction: () => openAction(duplicate),
                  buttonIcon: null,
                }),
              );
            });
          })
          .catch((error) => errorToast({ httpResponseOrError: error, displayForbidden: true }))
          .finally(() => setIsCopying(false));
      });
    }
  };

  const renderDropdownEntry = ({ iconClass, translationKey, action, display = false }) => {
    if (display) {
      return (
        <Dropdown.Item key={`dropdown_${translationKey}`} onClick={action} className="sq-force-text-gray">
          <Icon icon={iconClass} extraClassNames="pr10" type="inherit" />
          <span className="forceFont" data-testid={`moreActions_${translationKey}`}>
            {t(translationKey)}
          </span>
        </Dropdown.Item>
      );
    }
  };

  const displayingTrash = currentTab === HOME_SCREEN_TABS.TRASH;

  const actions = [
    {
      iconClass: 'fa-edit',
      translationKey: 'WORKBENCH.EDIT',
      action: () => setShowEditModal(true),
      display: canModifyWorkbook(item) && !displayingTrash,
    },
    {
      iconClass: 'fa-copy',
      translationKey: 'WORKBENCH.DUPLICATE',
      action: duplicate,
      display: canReadItem(item) && !sqHomeScreenUtilities.isProject(item),
    },
    {
      iconClass: 'fc fc-share',
      translationKey: 'WORKBENCH.ACCESS_CONTROL',
      action: () => {
        setWorkbookId(item.workbookId);
        getWorksheets(item.workbookId)
          .then((worksheets) => setWorksheetId(_.first(worksheets).worksheetId))
          .finally(() => setShowItemAclModal(true));
      },
      display: canManageItem(item) && !displayingTrash,
    },
    {
      iconClass: 'fc fc-folder-move',
      translationKey: 'WORKBENCH.MOVE_TO_FOLDER',
      action: () => setShowFolderExplorerModal(true),
      display: canManageItem(item) && !displayingTrash,
    },
    {
      iconClass: 'fa-trash',
      translationKey: 'WORKBENCH.DELETE',
      action: trashItem,
      display: canModifyWorkbook(item) && !displayingTrash,
    },
    {
      iconClass: 'fc fc-restore',
      translationKey: 'RESTORE',
      action: restoreWorkbook,
      // Restoring an item may move it to the user's home folder, which would grant them manage permission, so they
      // need to already have it.
      display: canManageItem(item) && displayingTrash,
    },
  ];

  const onPinnedSet = (state) => {
    item.isPinned = state;
    if (currentTab === HOME_SCREEN_TABS.HOME) {
      sqHomeScreenActions.loadFolder(currentFolderId, currentTab);
    }
  };

  return (
    <div className="flexColumnContainer positionRelative">
      <EventCapture>
        {!displayingTrash && (
          <PinnedActionIcon item={item} onChange={onPinnedSet} activeWorkbook={isMenuOpen || isInfoOpen} />
        )}

        <div className={baseClasses} data-testid="homeScreenInfoDropdown">
          <HomeScreenInfoDropdown item={item} setIsOpen={setIsInfoOpen} />
        </div>

        {_.some(actions, _.property('display')) && (
          <div className={baseClasses}>
            <ButtonWithPopover
              label={
                <div className="sq-icon-hover">
                  <Icon icon="fc-more" extraClassNames="fa-fw width-20" testId="homeScreenActionMore" />
                </div>
              }
              popoverConfig={{
                id: `dropdown-basic-${item.id}`,
                placement: 'bottom-end',
              }}
              onToggle={setIsMenuOpen}>
              {_.map(actions, renderDropdownEntry)}
            </ButtonWithPopover>
          </div>
        )}

        {showEditModal && (
          <EditWorkbookModal
            onClose={handleEditNameClose}
            name={item.name}
            type={item.type}
            description={item.description}
            owner={item.owner}
            renderer={item.renderer}
            id={item.id}
            isCorporate={item.isCorporate}
          />
        )}

        {/* Do NOT rely on show property here as it will still render and execute the useEffect function that
         request the current folder!! */}
        {showFolderExplorerModal && (
          <FolderExplorerModal
            onClose={handleFolderExplorerClose}
            item={item}
            currentFolderId={currentFolderId}
            currentTab={currentTab}
          />
        )}

        {showItemAclModal && (
          <ItemAclModal
            item={item}
            itemId={item.id}
            closeModal={handleItemAclClose}
            workbookId={workbookId}
            worksheetId={worksheetId}
            includeLinksAndCorporateMessage={true}
            folderId={folderId}
            showOnlyFolderLinks={showOnlyFolderLinks}
            isAclModalLocationInHeader={false}
          />
        )}
      </EventCapture>
    </div>
  );
};
