// @ts-strict-ignore
import React, { useRef, useState } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-bootstrap';
import { useIntervalWhen } from 'rooks';

export interface SingletonTooltipProps {
  id: string;
}

/**
 * A single tooltip that is shown over each inner child that has tooltip data attributes. For sections which
 * contains many elements with tooltips (e.g. a table where each cell has a tooltip) it is recommended to use this
 * component instead of instancing a React Tooltip for each element. This will ensure good performance.
 *
 * Usage:
 * <SingletonTooltip> your component(s) </SingletonTooltip>
 * Each element with tooltip should have the following data attributes:
 *   data-tooltip-text - the translation key containing the text of the tooltip
 *   data-tooltip-placement - tooltip position. Optional. Default 'top'
 *
 * NOTE: when the inner components are using Icon elements, set hasExternalTooltipProvider=true
 */
export const SingletonTooltip: React.FunctionComponent<SingletonTooltipProps> = (props) => {
  const { id } = props;

  const tooltipTarget = useRef(null);
  const [tooltipText, setTooltipText] = useState(null);
  const [tooltipPlacement, setTooltipPlacement] = useState(null);
  const [tooltipTargetOriginalPosition, setTooltipTargetOriginalPosition] = useState(null);
  const [show, setShow] = useState(false);

  const { t } = useTranslation();

  // Tooltip target scroll handler
  // Check every 300ms if the tooltip target position has changed and hide the tooltip if this happens.
  useIntervalWhen(
    () => {
      const currentPosition = tooltipTarget.current.getBoundingClientRect();
      const originalPosition = tooltipTargetOriginalPosition;
      if (originalPosition.y !== currentPosition.y || originalPosition.x !== currentPosition.x) {
        setShow(false);
      }
    },
    300,
    show,
  );

  const onMouseMove = (e) => {
    if (!(e.target instanceof HTMLElement)) {
      return;
    }

    // Hide the tooltip first and show it on the next mouse move invocation.
    // Otherwise, for very close elements, the tooltip may remain in the previous position.
    if (tooltipTarget.current !== e.target) {
      setShow(false);
      tooltipTarget.current = e.target;
    } else if (!show) {
      const dataset = e.target?.dataset;
      if (dataset?.tooltipText) {
        setTooltipText(t(dataset.tooltipText));
        setTooltipPlacement(dataset?.tooltipPlacement ?? 'top');
        setTooltipTargetOriginalPosition(tooltipTarget.current.getBoundingClientRect());
        setShow(true);
      }
    }
  };

  const onMouseLeave = () => {
    setShow(false);
  };

  return (
    <>
      <Overlay target={tooltipTarget.current} show={show} placement={tooltipPlacement} transition={false}>
        <Tooltip id={id}>{tooltipText}</Tooltip>
      </Overlay>

      <div onMouseMove={onMouseMove} onMouseLeave={onMouseLeave} ref={tooltipTarget}>
        {props.children}
      </div>
    </>
  );
};
