// @ts-strict-ignore
import _ from 'lodash';
import i18next from 'i18next';
import Highcharts, { ChartSelectionAxisContextObject } from 'highcharts';
import { getLaneBuffer, getUniqueOrderedValuesByProperty } from '@/hybrid/utilities/utilities';
import {
  BAR_CHART_ESSENTIALS,
  ChartGetters,
  ChartLaneOptions,
  ChartRegion,
  DISABLED_MARKER,
  ENABLED_MARKER,
  GRAY_LANE_COLOR,
  LINE_WIDTHS,
  PixelTranslationFunction,
  SeriesGroupedByAxis,
  WHITE_LANE_COLOR,
  XYPixelRegion,
} from '@/chart/chart.module';
import {
  CapsuleTimeColorMode,
  ITEM_TYPES,
  PREVIEW_HIGHLIGHT_COLOR,
  PREVIEW_ID,
  SAMPLE_OPTIONS,
  TREND_STORES,
} from '@/trendData/trendData.constants';
import { findItemIn } from '@/hybrid/trend/trendDataHelper.utilities';
import {
  ConditionValuesForLabels,
  fetchTickAttributes,
  formatStringYAxisLabel,
  formatYAxisTick,
  getAxisDisplayText,
  getCapsuleAxisId,
  getCapsuleLaneLabelDisplayText,
  getLabelWidth,
  getLaneDisplayText,
  getNumericTickPositions as getNumericTickPositionsSqLabel,
  TrendValuesForLabels,
} from '@/hybrid/utilities/label.utilities';
import {
  DEFAULT_AXIS_LABEL_COLOR,
  DEFAULT_AXIS_LINE_COLOR,
  LANE_LABEL_CONFIG,
  PLOT_BAND_AXIS_ID,
} from '@/hybrid/trend/trendViewer/trendViewer.constants';
import { DEFAULT_EXTREMES, Extremes, XYPlotRegion } from '@/scatterPlot/scatterPlot.constants';

/**
 * detect incorrect regions on trend
 */
export function isInvalidPixel(capsuleRegion: XYPixelRegion) {
  const { xMinPixel, xMaxPixel } = capsuleRegion;
  return !_.isFinite(xMinPixel) || !_.isFinite(xMaxPixel) || xMinPixel >= xMaxPixel;
}

/**
 * translate the XYRegion to a pixel region (with an id and dateTime if it is a capsule)
 */
export function translateRegion(
  chart: Highcharts.Chart,
  xAxis: Highcharts.Axis,
  yAxis: Highcharts.Axis,
  translate: { x?: PixelTranslationFunction; y?: PixelTranslationFunction },
  chartRegion: ChartRegion,
): XYPixelRegion {
  const xMin = chartRegion.xMin;
  const xMax = chartRegion.xMax;
  const yMin = chartRegion.yMin;
  const yMax = chartRegion.yMax;
  const xPixels = translateAxis(xMin, xMax, xAxis, translate.x);
  const yPixels = translateAxis(yMin, yMax, yAxis, translate.y);

  function translateAxis(min, max, axis, translate) {
    if (translate) {
      return translate(chart, min, max);
    } else {
      const extremes = axis.getExtremes();
      return {
        minPixel: axis.translate(Math.max(min, extremes.min)),
        maxPixel: axis.translate(Math.min(max, extremes.max)),
      };
    }
  }

  return {
    xMinPixel: xPixels.minPixel,
    xMaxPixel: xPixels.maxPixel,
    yMinPixel: yPixels.minPixel,
    yMaxPixel: yPixels.maxPixel,
    id: chartRegion.id,
    dateTime: chartRegion.dateTime,
  };
}

/**
 * Checks to see if the mouse has left the plot area and resets the zoomInProgress flag to reset the selection
 * marker
 *
 * @param e - The mouse event
 * @param highChart - the current chart object
 */
export function mouseLeftActualChartArea(e: MouseEvent, highChart: Highcharts.Chart) {
  let plotBox;
  let downXPixels;
  let downYPixels;

  if (e && highChart && highChart.pointer) {
    downXPixels = highChart.pointer.normalize(e).chartX;
    downYPixels = highChart.pointer.normalize(e).chartY;
    plotBox = highChart.plotBox;
    return (
      downYPixels <= plotBox.y ||
      downYPixels > plotBox.y + plotBox.height ||
      downXPixels < plotBox.x ||
      downXPixels > plotBox.x + plotBox.width
    );
  } else {
    return true;
  }
}

/**
 * Find the chart series object that matches the id specified
 *
 * @param highChart - the current chart object
 * @param  id - ID value for which to search
 * @return Chart series object; undefined if not found
 */
export const findChartSeries = (highChart: Highcharts.Chart, id: string): Highcharts.Series => {
  return _.find(highChart?.series as Highcharts.Series[], { options: { id } });
};

/**
 * Transforms a pair of Highcharts X and Y selection objects with min and max into a matching XYRegion
 *
 * @param xAxis axis for min and max x points
 * @param yAxes axes for min and max y points
 * @return The selected region according to the axis objects
 */
export function getXYRegion(
  xAxis: ChartSelectionAxisContextObject,
  yAxes: ChartSelectionAxisContextObject[],
): XYPlotRegion {
  const x = {
    min: xAxis.min,
    max: xAxis.max,
  };
  const ys: Record<string, Extremes> = {};
  _.forEach(yAxes, (axis) => {
    ys[axis.axis.userOptions.signalId] = {
      min: axis.min,
      max: axis.max,
    };
  });
  return {
    x,
    ys,
  };
}

export function setScatterPlotExtremes(xAxis: Highcharts.Axis, yAxes: Highcharts.Axis[], region: XYPlotRegion) {
  xAxis.setExtremes(region.x.min, region.x.max, false);
  _.forEach(yAxes, (axis) => {
    const signalId = axis.userOptions.signalId;
    const y = region.ys[signalId] ?? DEFAULT_EXTREMES;
    axis.setExtremes(y.min, y.max, false);
  });
}

/**
 * Sets the label visibility and layout of a given axis. Does not redraw the chart.
 *
 * @param axis - The axis label to be updated
 * @param properties - Object containing the properties and their new values. Properties are merged
 *   with existing properties or the axis.
 */
export function setAxisProperties(axis: Highcharts.Axis, properties: any) {
  if (axis) {
    axis.update(properties, false);
  }
}

/**
 * Gets the axis associated with an item
 *
 * @param chart - The highcharts chart to look at
 * @param item - The item to find
 * @param  item.id - The id of the item to find
 * @returns  The requested axis; or undefined if not found.
 */
export function getItemYAxis(
  chart: Highcharts.Chart,
  item: { id: string; capsuleSetId: string; itemType: string },
): any | undefined {
  const id = item?.itemType === ITEM_TYPES.CAPSULE ? getCapsuleAxisId(item.capsuleSetId) : `yAxis-${item?.id}`;
  return _.find(chart.yAxis, { userOptions: { id } }) as any;
}

//region Lane Helpers

/**
 * Gets the list of lanes ordered from top to bottom based on the items passed to the chart. This is used in
 * favor of sqTrendStore.uniqueLanes because the items may be filtered down before being passed to the chart -
 * we want to avoid rendering empty lanes.
 */
export function getDisplayedLanes(items: { lane: string }[], lanes: number[], laneProperty = 'lane'): number[] {
  return getUniqueOrderedValuesByProperty(items, laneProperty, lanes);
}

/**
 * Clip signals to their lane so they can't be dragged into other lanes (CRAB-8895)
 *
 * This works by using the plotBand highlighting to create a clipRect for the lane and using it for all signals
 * within the lane. The clipRects are actually clipPaths[1] in the svg. All the signals can share the same
 * clipRect because highcharts uses the transform attribute[2] to move the svg path into position and the
 * clipping path is transformed with the data
 *
 * [1]: https://developer.mozilla.org/en-US/docs/Web/SVG/Element/clipPath
 * [2]: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/transform
 */
export function clipSignalsToLanes(options: {
  laneClipRect: Highcharts.ClipRectElement;
  chart: Highcharts.Chart;
  laneWidth: number;
  laneHeight: number;
}): Highcharts.ClipRectElement {
  const { laneHeight, chart, laneClipRect, laneWidth } = options;
  laneClipRect?.destroy?.();
  const newLaneClipRect = chart.renderer.clipRect(0, 0, laneWidth, laneHeight);

  _.forEach(chart.series, (chartSeries: any) => {
    if (chartSeries.visible && _.get(chartSeries, 'userOptions.lane', false) && !_.isUndefined(chartSeries.group)) {
      chartSeries.group.clip(newLaneClipRect);
      chartSeries.markerGroup.clip(newLaneClipRect);
    }
  });

  return newLaneClipRect;
}

/**
 * Returns an array of series organized by how their y-axes should be displayed.
 *
 * @return An array of series, organized by how they should be displayed
 *  .primarySeries - Primary series for this axis. The y-axis for this series is the one that is
 *    displayed for all items in .series.
 *  .series - All series that occupy the same axis area and should all be updated together
 *  .hide - If true, the axis shouldn't be displayed
 */
export function getSeriesForYAxisInteraction(options: { items: any[]; lane?: string }): SeriesGroupedByAxis[] {
  const { items, lane } = options;
  let series = _.filter(
    items,
    (item: any) => item.itemType === ITEM_TYPES.SERIES || item.itemType === ITEM_TYPES.SCALAR,
  );

  if (!_.isUndefined(lane)) {
    series = _.filter(series, { lane });
  }

  if (series.length === 0) {
    return [
      {
        primarySeries: undefined,
        series,
      },
    ];
  }

  // for multiple series which have the same yAxisAlignment, ensure that we only show one axis
  const skippedSeriesIds = [];
  return _.transform(
    series,
    (accum, singleSeries: any, index) => {
      const remainingSeries = _.slice(series, index + 1);
      const matches = _.chain(remainingSeries)
        .filter((other) => shareSameAxis([singleSeries, other]) && shareSameLane([singleSeries, other]))
        .forEach((match: any) => skippedSeriesIds.push(match.id))
        .value();

      if (!_.includes(skippedSeriesIds, singleSeries.id)) {
        accum.push({
          primarySeries: singleSeries,
          series: _.concat([singleSeries], matches),
          hide: false,
        });
      }
    },
    [],
  );
}

/**
 * Helper function to determine if all the provided Series share the same lane.
 *
 * @param seriesList - Array of series items.
 * @returns true if the series share the same lane, false if not.
 */
function shareSameLane(seriesList: any[]): boolean {
  return _.chain(seriesList).map('lane').uniq().value().length === 1;
}

/**
 * Helper function to determine if all the provided Series share the same axis.
 *
 * @param seriesList - Array of series items.
 * @returns true if the series share the same axis, false if not.
 */
function shareSameAxis(seriesList: any[]): boolean {
  return _.chain(seriesList).map('axisAlign').uniq().value().length === 1;
}

/**
 * Iterates through the y axes and pushes any necessary lane and formatting changes to the chart.
 *
 * @param options
 * @return the new plot band colors
 */
export function processYAxisChangesPerLane(options: {
  isCapsuleTime: boolean;
  isCompareViewRainbowColorMode?: boolean;
  items: any[];
  lanes: any[];
  chart: Highcharts.Chart;
  capsuleLaneHeight: number;
  capsuleTimeColorMode: CapsuleTimeColorMode;
  useDefaultColor?: boolean;
}): any {
  const {
    isCapsuleTime,
    isCompareViewRainbowColorMode,
    items,
    lanes: initialLanes,
    capsuleLaneHeight,
    capsuleTimeColorMode,
    chart,
    useDefaultColor = false,
  } = options;
  let labelColor;
  const plotBandColors = {};
  const laneBuffer = getLaneBuffer(isCapsuleTime);
  const lanes = getDisplayedLanes(items, initialLanes);
  const laneHeight = getLaneHeight(options);
  let opposite = false;
  const seriesByAxis = getSeriesForYAxisInteraction(options);

  _.forEach(lanes, (lane, idx) => {
    const seriesInLane = _.filter(seriesByAxis, (s) => _.get(s.primarySeries, 'lane') === lane);

    const top = laneHeight * idx + laneBuffer * idx + capsuleLaneHeight;

    _.forEach(seriesInLane, (seriesGroup) => {
      const axis = getItemYAxis(chart, seriesGroup.primarySeries);
      let visible = seriesGroup.primarySeries.axisVisibility;

      // When updating a series, the addition of its preview series causes an update before the preview has an
      // axis
      if (_.isUndefined(axis)) {
        return;
      }

      if (seriesGroup.series.length > 1) {
        const visibleSeries = _.chain(seriesGroup.series)
          .filter({ axisVisibility: true })
          .map((item: any) => (item.childType ? findItemIn(TREND_STORES, item.isChildOf) : item))
          .uniqBy('id')
          .value();

        const allColorsSame =
          visibleSeries.length > 0 ? _.every(visibleSeries, ['color', visibleSeries[0]?.color]) : false;
        const hasColors =
          isCompareViewRainbowColorMode ||
          (isCapsuleTime &&
            _.includes([CapsuleTimeColorMode.Rainbow, CapsuleTimeColorMode.ConditionGradient], capsuleTimeColorMode));
        if (allColorsSame && !hasColors) {
          labelColor = visibleSeries[0].color;
        } else {
          labelColor = DEFAULT_AXIS_LABEL_COLOR;
        }

        const temp = _.omitBy(seriesGroup.series, (tempSeries) => tempSeries.id === seriesGroup.primarySeries.id);
        _.forEach(temp, (t) => {
          const tempAxis = getItemYAxis(chart, t);
          setAxisProperties(tempAxis, {
            visible: false,
            height: laneHeight,
            top,
          });
        });
      } else {
        labelColor = seriesGroup.primarySeries.color;
        if (
          !_.isFinite(_.get(seriesGroup.primarySeries, 'yAxisConfig.min')) &&
          !_.isFinite(_.get(seriesGroup.primarySeries, 'yAxisConfig.max'))
        ) {
          visible = false;
        }
      }

      if (!_.isUndefined(_.find(seriesGroup.series as any[], (signal) => _.startsWith(signal.id, PREVIEW_ID)))) {
        plotBandColors[lane] = PREVIEW_HIGHLIGHT_COLOR;
      }

      opposite = seriesGroup.primarySeries.rightAxis;
      setAxisProperties(axis, {
        visible,
        lineColor: useDefaultColor ? DEFAULT_AXIS_LINE_COLOR : labelColor,
        lineWidth: 1,
        labels: {
          enabled: visible,
          style: {
            color: labelColor,
          },
          align: opposite ? 'left' : 'right',
          x: opposite ? 5 : -5,
        },
        height: laneHeight,
        formatOptions: seriesGroup.primarySeries.formatOptions || {},
        top,
        opposite,
        type: seriesGroup.primarySeries.yAxisType,
      });
    });
  });

  return plotBandColors;
}

/**
 * Updates the "lanes" background on the chart. The label object is defined so that the lane display can be
 * turned on/off based on the customizationMode in the trendStore.
 *
 * @param options.colors - Map of lane number to custom color for that lane
 */
export function updatePlotBands(options: {
  colors: any;
  items: any[];
  lanes: number[];
  isCapsuleTime: boolean;
  capsuleLaneHeight: number;
  chart: Highcharts.Chart;
}) {
  const { colors, items, lanes: initialLanes, isCapsuleTime, chart, capsuleLaneHeight } = options;
  // The plotbands are constructed from the bottom lane to the top lane
  const lanes = _.reverse(getDisplayedLanes(items, initialLanes));
  const laneCount = lanes.length;
  const capsuleSets = _.chain(items).filter({ itemType: ITEM_TYPES.CAPSULE }).map('capsuleSetId').uniq().value();
  const startWithColorOffset = !_.isEmpty(capsuleSets) && capsuleSets.length % 2 === 0 ? 1 : 0;
  const laneBuffer = getLaneBuffer(isCapsuleTime);
  const laneHeight = getLaneHeight(options);

  const plotBands = _.flatMap(lanes, (lane, idx) => [
    {
      color: colors[lane] ?? ((laneCount - idx + startWithColorOffset) % 2 === 0 ? WHITE_LANE_COLOR : GRAY_LANE_COLOR),
      from: laneHeight * idx + laneBuffer * idx,
      to: laneHeight * (idx + 1) + laneBuffer * idx,
      label: {
        ...LANE_LABEL_CONFIG,
        text: '', // Will be filled in by manageLaneLabelDisplay()
      },
    },
    {
      color: WHITE_LANE_COLOR,
      from: laneHeight * (idx + 1) + laneBuffer * idx,
      to: laneHeight * (idx + 1) + laneBuffer * (idx + 1),
    },
  ]);

  const axis = _.find(chart.yAxis, {
    userOptions: { id: PLOT_BAND_AXIS_ID },
  }) as any;
  const max = laneHeight * lanes.length + laneBuffer * (lanes.length - 1);
  axis.update(
    {
      plotBands,
      visible: true,
      min: 0,
      max,
      height: getChartSeriesDisplayHeight(options),
      top: capsuleLaneHeight,
    },
    false,
  );
}

/**
 * Tiny helper to return the appropriate height for the lanes.
 *
 * @returns the height in pixels of the lane
 */
export function getLaneHeight(options: ChartLaneOptions): number {
  const { items, lanes, isCapsuleTime } = options;
  const numberOfLanes = getDisplayedLanes(items, lanes).length;
  const displayHeight = getChartSeriesDisplayHeight(options);

  if (numberOfLanes > 0) {
    const laneBuffersHeight = (numberOfLanes - 1) * getLaneBuffer(isCapsuleTime);
    return (displayHeight - laneBuffersHeight) / numberOfLanes;
  } else {
    return displayHeight;
  }
}

/**
 * Tiny helper to return the appropriate height for everything that should not overlap the capsule lane.
 *
 * @returns the height in pixel that is available
 */
export function getChartSeriesDisplayHeight(options: { chart: Highcharts.Chart; capsuleLaneHeight: number }): number {
  return options.chart.plotHeight - options.capsuleLaneHeight;
}

/**
 * This function manages the axis offsets and, if addAxisTitle is true, the display of axis labels.
 * Conceptually this function:
 *  - iterates over all the displayed lane
 *  - then iterates over all the series assigned to each lane
 *  - adjust the offset for each series axis so that the labels do not overlap
 *  - keeps track of the biggest offset of any given axis so that the overall chart margins can be set accordingly.
 */
export function manageAxisOffsets(options: {
  addAxisTitle: boolean;
  items: any[];
  lanes: number[];
  isCapsuleTime: boolean;
  chart: Highcharts.Chart;
  capsuleLaneHeight: number;
  sqTrendStore: TrendValuesForLabels;
  skipAxisUpdate?: boolean;
}): { offsetLeft: number; offsetRight: number } {
  const { addAxisTitle, items, lanes: initialLanes, chart, skipAxisUpdate = false, sqTrendStore } = options;

  let largestOffsetLeft = 0;
  let largestOffsetRight = 0;
  const lanes = getDisplayedLanes(items, initialLanes);
  const laneCount = lanes.length;
  const laneHeight = getLaneHeight(options);

  _.forEach(lanes, (lane) => {
    // Find all the series that are in the current lane.
    const seriesInLane = _.filter(items, (item: any) => {
      return (
        (item.itemType === ITEM_TYPES.SERIES || item.itemType === ITEM_TYPES.SCALAR) && _.get(item, 'lane') === lane
      );
    });
    // Find all the unique axis that belong to this lane:
    const uniqueAxisAssignmentInLane = _.chain(seriesInLane).map('axisAlign').uniq().value();
    let offsetLeft = 0;
    let offsetRight = 0;
    let opposite;
    const padding = addAxisTitle ? 30 : 10;

    _.forEach(_.sortBy(uniqueAxisAssignmentInLane), (assignment) => {
      const seriesSharingAnAxis = _.filter(seriesInLane, {
        axisAlign: assignment,
      });
      _.forEach(seriesSharingAnAxis, (series) => {
        const axis = getItemYAxis(chart, series);
        if (_.get(axis, 'visible', false)) {
          const tickPositions = getNumericTickPositionsSqLabel(
            axis.userMin,
            axis.userMax,
            series,
            laneHeight,
            laneCount,
          );

          let axisUpdates = {
            title: {
              useHTML: true,
              enabled: false,
              text: '',
              style: {},
            },
          };

          if (addAxisTitle) {
            const axisTitle = getAxisDisplayText(
              items,
              assignment,
              seriesSharingAnAxis,
              axis.height ? axis.height : laneHeight,
              sqTrendStore,
            );

            axisUpdates = {
              title: {
                useHTML: true,
                text: axisTitle,
                enabled: true,
                style: {
                  // This fixes a highcharts issue where the rotation styles are not added in headless render mode
                  // so the content in organizer was displaying with horizontal labels (CRAB-30063)
                  transform: `rotate(${axis.opposite ? 90 : 270}deg)`,
                },
              },
            };
          }

          const labelLength = getLabelWidth(tickPositions, axis, laneHeight, padding, series.isStringSeries, series);

          opposite = axis.userOptions.opposite;
          if (!skipAxisUpdate) {
            setAxisProperties(
              axis,
              _.assign(axisUpdates, { offset: opposite ? offsetRight : offsetLeft }, { axisWidth: labelLength }),
            );
          }

          if (opposite) {
            offsetRight += labelLength;
            largestOffsetRight = _.max([offsetRight, largestOffsetRight]);
          } else {
            offsetLeft += labelLength;
            largestOffsetLeft = _.max([offsetLeft, largestOffsetLeft]);
          }
        }
      });
    });
  });

  return { offsetLeft: largestOffsetLeft, offsetRight: largestOffsetRight };
}

/**
 * This function renders the "Lane Label".
 */
export function manageLaneLabelDisplay(options: {
  chart: Highcharts.Chart;
  items: any[];
  lanes: number[];
  sqTrendStore: TrendValuesForLabels;
}) {
  const { chart, items, lanes: initialLanes, sqTrendStore } = options;
  const lanes = getDisplayedLanes(items, initialLanes);
  const labelAxis = _.find(chart.yAxis, {
    userOptions: { id: PLOT_BAND_AXIS_ID },
  });

  _.forEach(_.reverse(lanes), (lane, idx) => {
    // Set the lane display labels, odd plotLinesAndBands are spacers so multiply by 2
    const options = _.get(labelAxis, 'userOptions.plotBands', null);
    if (options && options[idx * 2] && options[idx * 2].label) {
      const labelText = getLaneDisplayText(items, lane, chart.plotWidth - 25, sqTrendStore);
      const userOptions = labelAxis.userOptions;
      userOptions.plotBands[idx * 2].label.text = labelText;
      labelAxis.update(userOptions, false);
    }
  });
}

/**
 * Updates the capsule time y-axis tick positions. It does not redraw the chart.
 */
export function updateYAxisAlignment(options: {
  chart: Highcharts.Chart;
  isCapsuleTime: boolean;
  items: { id: string }[];
}) {
  const { chart, isCapsuleTime, items } = options;
  if (isCapsuleTime) {
    // Iterate through and update all y axes
    _.forEach(chart.yAxis, (axis: any) => {
      // We don't want to update the plot band here
      if (axis.options.id === PLOT_BAND_AXIS_ID) {
        return;
      }

      const item = getAxisItem(items, axis);
      if (!item) {
        return;
      }

      axis.update(
        {
          tickPositions: item.isStringSeries ? _.map(item.stringEnum, 'key').sort() : undefined,
          labels: { enabled: true },
          startOnTick: false,
          endOnTick: false,
        },
        false,
      );
    });
  }
}

//endregion

export function getYAxisIdFromItem(item: { id: string }) {
  return `yAxis-${item.id}`;
}

/**
 * Gets the item associated with an axis
 *
 * @param items - The items to search through
 * @param axis - The axis
 * @returns The requested item; or undefined if not found.
 */
export function getAxisItem(items: { id: string }[], axis: Highcharts.Axis): any {
  return _.find(items, (item: any) => axis.series && axis.series[0] && axis.series[0].userOptions.id === item.id);
}

/**
 * Updates the y-axis extremes of the corresponding series. Does not redraw the chart.
 */
export function updateSeriesYExtremes(options: { items: readonly any[]; chart: Highcharts.Chart }) {
  const { items, chart } = options;
  _.chain(items)
    .filter('yAxisConfig')
    .forEach((item: any) => {
      const axis = getItemYAxis(chart, item);
      if (!axis) {
        return;
      }
      if (axis.logarithmic) {
        axis.setExtremes(Number(item.yAxisMin), Number(item.yAxisMax), false, false);
      } else {
        axis.setExtremes(item.yAxisConfig.min, item.yAxisConfig.max, false, false);
      }
    })
    .value();
}

/**
 * Get the y-axis ticks for an axis based on the items min/max to ensure y-axis labels
 * are only shown within the axis' range
 *
 * @returns an Array of tick positions.
 */
export function getNumericTickPositions(options: ChartLaneOptions & ChartGetters): () => number[] {
  return function () {
    options.chart = options.getChart();
    options.items = options.getItems();
    const item = getAxisItem(options.items, this);
    if (!options.chart || !item) {
      return;
    }

    const laneCount = _.get(getDisplayedLanes(options.items, options.lanes), 'length', 1);
    const laneHeight = getLaneHeight(options);

    const min = item?.yAxisConfig?.min ?? this.min;
    const max = item?.yAxisConfig?.max ?? this.max;

    return getNumericTickPositionsSqLabel(min, max, item, laneHeight, laneCount);
  };
}

/**
 * Formats the y-axis labels.
 */
export function yAxisFormatter(options: ChartLaneOptions & ChartGetters): () => string {
  return function () {
    options.chart = options.getChart();
    options.items = options.getItems();
    if (!options.chart) {
      return;
    }

    return formatYAxisTick(
      this.value,
      fetchTickAttributes(this.axis.min, this.axis.max, this.axis.userOptions.formatOptions, getLaneHeight(options)),
    );
  };
}

/**
 * Formats the y-axis string labels.
 */
export function yAxisStringFormatter(options: { getItems: () => any[] }): () => string {
  return function () {
    // this.axis.series[0].options.stringEnum does not get updated when the series updates, therefore get it
    // from items
    const series = _.find(options.getItems(), (item) => item.id === this.axis.series[0]?.options.id) as any;
    if (!series) {
      return;
    }
    return formatStringYAxisLabel(this.value, series, this.axis.userOptions.formatOptions?.stringFormat);
  };
}

export function updateYAxisPropertiesAndLabels(
  options: ChartLaneOptions & {
    sqTrendStore: TrendValuesForLabels;
    capsuleTimeColorMode: CapsuleTimeColorMode;
    axisTitlePresent: boolean;
  },
) {
  const colors = processYAxisChangesPerLane({
    ...options,
    useDefaultColor: true,
  });
  updatePlotBands({ ...options, colors });
  manageAxisOffsets({
    ...options,
    addAxisTitle: options.axisTitlePresent,
  });
  manageLaneLabelDisplay({ ...options });
}

/**
 * Toggles between line and point display.
 *
 * @param options.items - An Array of store items that have changed.
 */
export function setPointsOnly(options: { items: any[]; chart: Highcharts.Chart; isCapsuleTime: boolean }) {
  const { items, isCapsuleTime, chart } = options;
  const chartSeries = chart.series;
  _.forEach(items, (item: any) => {
    const seriesArray = _.filter(chartSeries, (series: any) => {
      return isCapsuleTime ? series.userOptions.isChildOf === item.isChildOf : series.userOptions.id === item.id;
    }) as any[];

    _.forEach(seriesArray, (series) => {
      if (item.sampleDisplayOption === SAMPLE_OPTIONS.BAR) {
        _.assign(series.options, BAR_CHART_ESSENTIALS, {
          pointWidth: item.lineWidth,
        });
      } else {
        series.options.type = 'line';
        if (item.sampleDisplayOption !== SAMPLE_OPTIONS.LINE) {
          if (item.sampleDisplayOption === SAMPLE_OPTIONS.LINE_AND_SAMPLE) {
            series.options.lineWidth = 0.5;
          } else {
            series.options.lineWidth = 0;
          }

          series.options.marker = ENABLED_MARKER;
        } else {
          series.options.lineWidth = LINE_WIDTHS[item.itemType];
          series.options.marker = DISABLED_MARKER;
        }
      }
      series.update(series.options, false);
    });
  });
}

/**
 * Formats the capsule labels
 */
export function formatCapsuleLabel(): string {
  if (!this.point.dataLabelString) {
    return null;
  }

  // Constrain the width to ensure long labels don't run outside the capsule
  const nextPoint = _.find<Highcharts.Point>(this.series.data, {
    index: this.point.index + 1,
  });
  const widthStyle = nextPoint ? `width: ${nextPoint.plotX - this.point.plotX}px` : '';
  return `<span style="${widthStyle}">${this.point.dataLabelString}</span>`;
}

/**
 * This function adds the background to the capsule lane that is currently being edited.
 * The boundaries for the plotband used to visualize the background are based of the yValues of the displayed
 * capsules (with some added buffer values).
 *
 * If no capsules are found an empty band is displayed.
 *
 * Plotlines are added to generate the border.
 *
 */
export function addCapsuleEditPlotBand(options: {
  capsuleEditingId?: string;
  chart: Highcharts.Chart;
  showCapsuleLaneLabels: boolean;
}) {
  const { capsuleEditingId, chart, showCapsuleLaneLabels } = options;
  const lookUpId = capsuleEditingId || PREVIEW_ID;
  let axis, plotLines, plotBand;
  const axisId = getCapsuleAxisId(lookUpId);

  if (chart?.yAxis) {
    axis = _.find(chart.yAxis, { userOptions: { id: axisId } });
  }

  if (!_.isUndefined(axis) && _.isFinite(axis.userOptions.min) && _.isFinite(axis.userOptions.max)) {
    // generate the "border" lines
    plotLines = [
      {
        value: axis.userOptions.min,
        color: '#ccc',
        width: 1,
      },
      {
        value: axis.userOptions.max,
        color: '#ccc',
        width: 1,
      },
    ];

    plotBand = {
      color: PREVIEW_HIGHLIGHT_COLOR,
      from: axis.userOptions.min,
      to: axis.userOptions.max,
    };

    if (showCapsuleLaneLabels) {
      _.assign(plotBand, {
        label: {
          ...LANE_LABEL_CONFIG,
          text: `<span class="text-with-shadow">${i18next.t('PREVIEW')}</span>`,
        },
      });
    }

    axis.update({ plotBands: [plotBand], plotLines }, true);
  }
}

const stripeByIndex = (index: number) => (index % 2 === 0 ? WHITE_LANE_COLOR : GRAY_LANE_COLOR);

/**
 * Each capsule series has it's own y-axis. The y-axis are stacked by using the height and top parameters of the
 * axis. To ensure proper display the capsuleLaneHeight variable is updated with the current total height of all
 * capsule lane axis.
 *
 * If lane labels are shown the buffer for each axis is increased to ensure the label can be displayed without
 * interfering with the capsule display.
 * The lane labels are displayed using plotband labels, so plotbands are added for each capsule axis.
 *
 * @return The new capsule lane height
 */
export function updateCapsuleAxis(options: {
  showCapsuleLaneLabels: boolean;
  capsuleLaneHeight: number;
  items: any[];
  chart: Highcharts.Chart;
  sqTrendCapsuleSetStoreData: ConditionValuesForLabels;
  sqTrendStoreData: TrendValuesForLabels;
  lanes: number[];
  isCapsuleTime: boolean;
  colors: any;
  startingTop?: number;
}): number {
  const {
    showCapsuleLaneLabels,
    items,
    chart,
    sqTrendCapsuleSetStoreData,
    sqTrendStoreData,
    startingTop = 0,
  } = options;
  let nextTop = startingTop;
  let index = 0;
  const extraTopBuffer = showCapsuleLaneLabels ? 12 : 0;

  _.chain(items)
    .sortBy('conditionLane')
    .filter({ itemType: ITEM_TYPES.CAPSULE })
    .groupBy('capsuleSetId')
    .forEach((capsuleRows, capsuleSetId) => {
      const axisForCapsuleRow = _.find(chart.yAxis, {
        userOptions: { id: getCapsuleAxisId(capsuleSetId) },
      });

      if (axisForCapsuleRow) {
        const maxYValue = (_.chain(capsuleRows).map('yValue') as any).max().value();
        const minYValue = (_.chain(capsuleRows).map('yValue') as any).min().value();
        const lineWidth = (_.chain(capsuleRows).map('lineWidth') as any).max().value();
        const buffer = lineWidth / 2;
        const dataLabelTitles = _.chain(capsuleRows).map('dataLabels.labelNames').flatten().compact().uniq().value();
        const laneColor = showCapsuleLaneLabels ? stripeByIndex(index) : WHITE_LANE_COLOR;
        const min = minYValue - buffer - extraTopBuffer;
        const max = maxYValue + buffer;
        const height = max - min;
        const plotBands = [
          {
            from: min,
            to: max,
            color: laneColor,
            label: {
              ...LANE_LABEL_CONFIG,
              text: getCapsuleLaneLabelDisplayText(
                capsuleSetId,
                dataLabelTitles,
                sqTrendCapsuleSetStoreData,
                sqTrendStoreData,
              ),
            },
          },
        ];

        axisForCapsuleRow.update(
          {
            height,
            top: nextTop,
            min,
            max,
            plotBands,
          },
          false,
        );

        if (!_.isFinite(height)) {
          throw new Error(`Capsule lane height of ${height} is not a finite number`);
        }

        nextTop += height;
        index++;
      }
    })
    .value();

  updatePlotBands({ ...options, capsuleLaneHeight: nextTop });
  addCapsuleEditPlotBand(options);

  return nextTop;
}

/**
 * Gets the y-axis range - [max, min] - for each item
 *
 * @returns an object containing the item ids as keys and the y-axis ranges as values
 */
export function getItemRanges(items: any[]): Record<string, [number, number]> {
  return _.chain(items)
    .uniqBy((item) => (item.isStringSeries ? item.id : item.interestId ?? item.id))
    .reduce((ranges, item) => {
      const itemId = item.isStringSeries ? item.id : item.interestId ?? item.id;
      ranges[itemId] = [item.yAxisMax, item.yAxisMin];

      return ranges;
    }, {})
    .value();
}
