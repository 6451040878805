// @ts-strict-ignore
import React from 'react';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';

interface PaginatorProps {
  gotoPage: (number) => void;
  pageCount: number;
  pageNumber: number;
  pageSize: number;
  setPageSize: (number) => void;
  insideModal?: boolean;
  total?: number;
  pageSizeOptionsCustom?: number[];
  small?: boolean;
}

export const Paginator: React.FunctionComponent<PaginatorProps> = ({
  gotoPage,
  pageCount,
  pageNumber,
  pageSize,
  setPageSize,
  insideModal,
  total,
  pageSizeOptionsCustom,
  small = false,
}) => {
  const { t } = useTranslation();
  const pageSizeOptions = pageSizeOptionsCustom ?? [5, 10, 15, 20, 50];

  const formattedOptions = _.map(pageSizeOptions, (size) => ({
    label: size,
    value: size,
  }));
  const formattedGoToPageOptions = _.map(_.range(1, pageCount + 1, 1), (size) => ({ label: size, value: size }));
  const menuPosition = insideModal ? 'absolute' : 'fixed';
  const portalStyle = { menuPortal: (base) => ({ ...base, zIndex: 9999 }) };

  return (
    <div className="paginatorWrapper">
      <div
        className={classNames(
          'paginator',
          'flexColumnContainerWrap',
          'flexAlignCenter',
          small ? 'mr5' : 'mr10',
          insideModal ? 'flexSpaceBetween' : 'pull-right',
        )}
        data-testid="paginator">
        <div className={classNames('flexColumnContainer', small ? 'ml5' : 'ml10')}>
          <div id="goToPageWrapper" className="flexColumnContainer flexAlignCenter">
            {total && (
              <span className="pr10">
                {t('PAGINATOR.TOTAL')}
                <strong>{total}</strong>
              </span>
            )}
            <span className="pr5">{t('PAGINATOR.PAGE')}</span>
            <Select
              menuPlacement="auto"
              placeholder=""
              menuPosition={menuPosition}
              styles={insideModal && portalStyle}
              menuPortalTarget={document.body}
              className={classNames(small ? 'width-55' : 'width-75', small ? 'pr5' : 'pr10')}
              classNamePrefix={small ? 'react-select-small' : 'react-select'}
              value={_.find(formattedGoToPageOptions, { value: pageNumber })}
              options={formattedGoToPageOptions}
              isSearchable={true}
              onChange={(option) => gotoPage(option.value)}
            />
          </div>
          <div id="itemCountWrapper" className="flexColumnContainer flexAlignCenter">
            <span className={classNames(small ? '' : 'pl10')}>{t('PAGINATOR.SHOW')}</span>
            <Select
              menuPlacement="auto"
              menuPosition={menuPosition}
              styles={insideModal && portalStyle}
              menuPortalTarget={document.body}
              className={classNames(small ? 'width-55' : 'width-75', 'pl5', small ? '' : 'pr10')}
              classNamePrefix={small ? 'react-select-small' : 'react-select'}
              value={_.find(formattedOptions, { value: pageSize })}
              options={formattedOptions}
              isSearchable={false}
              onChange={(option) => setPageSize(option.value)}
            />
          </div>
        </div>
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={insideModal ? 1 : 2}
          marginPagesDisplayed={insideModal ? 1 : 2}
          previousLabel={insideModal ? <Icon icon="fa-caret-left" /> : t('PAGINATOR.PREVIOUS')}
          nextLabel={insideModal ? <Icon icon="fa-caret-right" /> : t('PAGINATOR.NEXT')}
          breakLabel={t('PAGINATOR.BREAK')}
          forcePage={pageNumber - 1}
          onPageChange={(data) => gotoPage(data.selected + 1)}
          breakClassName="break-me"
          containerClassName={classNames(
            'pagination',
            'sq-btn-group',
            small ? 'pagination-small' : 'pagination-lg height-34',
          )}
          subContainerClassName="pages pagination"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
          disabledClassName="disabledLook disabledBehavior"
        />
      </div>
    </div>
  );
};
