// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SelectItem from '@/hybrid/core/SelectItem.organism';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { ScatterPlotColorModalColorRange } from '@/hybrid/scatterPlot/ScatterPlotColorModalColorRange.molecule';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { ColorPicker } from '../workbooks/ColorPicker.organism';
import { CustomPropertySelector, SuggestedPropertiesMode } from '@/hybrid/utilities/CustomPropertySelector.atom';
import { getAllItems } from '@/hybrid/trend/trendDataHelper.utilities';
import { TrendActions } from '@/trendData/trend.actions';
import { equalsIgnoreCase } from '@/hybrid/utilities/utilities';
import {
  CAPSULE_PANEL_TREND_COLUMNS,
  ITEM_TYPES,
  PROPERTIES_COLUMN_PREFIX,
  TREND_PANELS,
} from '@/trendData/trendData.constants';
import { doTrack } from '@/track/track.service';
import { sqScatterPlotStore, sqTrendStore } from '@/core/core.stores';

const scatterPlotColorModalBindings = bindingsDefinition({
  sqScatterPlotActions: injected<ScatterPlotActions>(),
  sqTrendActions: injected<TrendActions>(),
});

export const ScatterPlotColorModal: SeeqComponent<typeof scatterPlotColorModalBindings> = () => {
  const { sqScatterPlotActions, sqTrendActions } = useInjectedBindings(scatterPlotColorModalBindings);

  const { t } = useTranslation();

  const {
    colorConditionIds,
    colorSignalId,
    colorCapsuleProperty,
    capsulePropertyColorsConfig,
    showColorModal,
    colorByItemColor,
  } = useFlux(sqScatterPlotStore);

  const [showColorSignalDropdown, setShowColorSignalDropdown] = useState(!!colorSignalId);
  const onClose = () => sqScatterPlotActions.setShowColorModal(false);
  const setCapsulePropertyColor = (itemId, color) => sqScatterPlotActions.setColorForCapsuleProperty(color);
  const conditions = getAllItems({
    itemTypes: [ITEM_TYPES.CAPSULE_SET],
    workingSelection: true,
  });
  const setCapsuleProperty = (column) => {
    sqScatterPlotActions.setColorCapsuleProperty(column.propertyName);
    const columnPrefix = _.includes(_.map(CAPSULE_PANEL_TREND_COLUMNS, 'key'), column.propertyName)
      ? ''
      : PROPERTIES_COLUMN_PREFIX;
    if (_.includes(_.map(CAPSULE_PANEL_TREND_COLUMNS, 'propertyName'), column.propertyName)) {
      // Trend columns (start/end/duration) only need to be toggled (if not enabled), not added as property columns
      const fullColumn = _.find(CAPSULE_PANEL_TREND_COLUMNS, (trendColumn) =>
        equalsIgnoreCase(trendColumn.propertyName as string, column.propertyName),
      );
      if (!sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, fullColumn.key)) {
        sqTrendActions.toggleColumn(TREND_PANELS.CAPSULES, fullColumn.key);
      }
    } else if (!sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, columnPrefix + column.propertyName)) {
      sqTrendActions.addPropertiesColumn(TREND_PANELS.CAPSULES, column);
    }
  };

  const defaultColorOptions = [
    {
      id: 'colorByDisplayRange',
      label: 'SCATTER.COLOR_CRITERIA_RELATIVE_TIME',
      isChecked: !showColorSignalDropdown && !colorByItemColor,
      action: () => {
        doTrack('Scatterplot', 'use relative time coloring');
        sqScatterPlotActions.setColorByItemColor(false);
        sqScatterPlotActions.setColorSignal(undefined);
        setShowColorSignalDropdown(false);
      },
    },
    {
      id: 'colorByItemColor',
      label: 'SCATTER.COLOR_DETAILS_PANE',
      isChecked: !showColorSignalDropdown && colorByItemColor,
      action: () => {
        doTrack('Scatterplot', 'use color by item color');
        sqScatterPlotActions.setColorByItemColor(true);
        sqScatterPlotActions.setColorSignal(undefined);
        setShowColorSignalDropdown(false);
      },
    },
    {
      id: 'colorBySignal',
      label: 'SCATTER.COLOR_CRITERIA_SIGNAL',
      isChecked: showColorSignalDropdown,
      action: () => {
        sqScatterPlotActions.setColorByItemColor(false);
        setShowColorSignalDropdown(true);
      },
    },
  ];

  return (
    <Modal
      data-testid="scatterPlotColorModal"
      className="scatterPlotColorModal"
      show={showColorModal}
      onHide={onClose}
      animation={false}
      size="sm"
      // enforceFocus = false allows the user to type in the ColorPicker popover inputs
      enforceFocus={false}>
      <Modal.Header closeButton={true}>
        <h4>{t('SCATTER.COLOR_MODAL_TITLE')}</h4>
      </Modal.Header>
      <Modal.Body className="p10">
        <div className="mb10">
          <strong>{t('SCATTER.COLOR_CRITERIA_CAPSULE')}</strong>
          <p className="mb0">{t('SCATTER.COLOR_CRITERIA_CAPSULE_HELP')}</p>
        </div>
        <div className="mb10 scatterPlotCapsulePropertiesDropdown">
          <strong>{t('SCATTER.COLOR_CRITERIA_CAPSULE_PROPERTY')}</strong>
          {_.isEmpty(colorCapsuleProperty) && (
            <CustomPropertySelector
              extraProperties={!_.isEmpty(conditions) ? CAPSULE_PANEL_TREND_COLUMNS : []}
              itemIds={_.map(conditions, 'id')}
              suggestedPropertiesMode={SuggestedPropertiesMode.Capsules}
              addPropertyColumn={setCapsuleProperty}
              dropdownPlaceholder="CUSTOM_COLUMN_SELECTOR.CUSTOM_PROPERTY"
            />
          )}
          {!_.isEmpty(colorCapsuleProperty) && (
            <div className="flexColumnContainer flexAlignCenter flexSpaceBetween">
              <Checkbox
                classes="textPrimaryMenuItem mt3 mb3"
                id={`${colorCapsuleProperty}-checkbox`}
                key={`${colorCapsuleProperty}-checkbox`}
                label={colorCapsuleProperty}
                isChecked={true}
                onChange={() => sqScatterPlotActions.setColorCapsuleProperty(colorCapsuleProperty)}
                skipMemo={true}
              />
              {!!capsulePropertyColorsConfig && !capsulePropertyColorsConfig.isStringProperty && (
                <ColorPicker
                  color={capsulePropertyColorsConfig.maxColor}
                  itemId="capsulePropertyColor"
                  notifyOnSelect={setCapsulePropertyColor}
                  placement="right"
                />
              )}
            </div>
          )}
        </div>
        <div className="mb10" data-testid="colorConditions">
          <strong>{t('SCATTER.COLOR_CRITERIA_CONDITION')}</strong>
          <SelectItem
            isMultipleSelect={true}
            insideModal={true}
            selectedItemId={colorConditionIds}
            itemTypes={[ITEM_TYPES.CAPSULE_SET]}
            excludeWorkbookItems={true}
            onSelect={sqScatterPlotActions.addColorCondition}
            onRemove={sqScatterPlotActions.removeColorCondition}
          />
        </div>
        <div className="mb10">
          <strong>{t('SCATTER.COLOR_CRITERIA_TIME')}</strong>
          <ScatterPlotColorModalColorRange />
        </div>
        <div className="mb10">
          <strong>{t('SCATTER.COLOR_CRITERIA_DEFAULT')}</strong>
          {_.map(defaultColorOptions, (option) => (
            <Checkbox
              id={option.id}
              key={option.id}
              isChecked={option.isChecked}
              type="radio"
              label={option.label}
              onClick={option.action}
              skipMemo={true}
            />
          ))}
        </div>
        {showColorSignalDropdown && (
          <div data-testid="colorBySignalDropdown">
            <SelectItem
              selectedItemId={colorSignalId}
              itemTypes={[ITEM_TYPES.SERIES]}
              excludeStringSignals={true}
              excludeWorkbookItems={true}
              insideModal={true}
              disableAutoSelect={true}
              allowClear={true}
              onSelect={(value) => {
                doTrack('Scatterplot', 'add/remove color by signal');
                sqScatterPlotActions.setColorSignal(value);
              }}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="text-right">
        <TextButton testId="closeButton" size="sm" label={t('CLOSE')} onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
};
