// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import _ from 'lodash';
import { previousWorkstepAction, lastWorkstepAction, nextWorkstepAction } from '@/worksteps/worksteps.actions';
import { StateSynchronizerService } from '@/services/stateSynchronizer.service';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import classNames from 'classnames';
import { generate } from '@/hybrid/utilities/screenshot.utilities';
import { sqWorkbookStore, sqWorkstepsStore } from '@/core/core.stores';

export type WorkstepButtonType = 'previous' | 'next' | 'last';

const workstepIconsBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
  isViewMode: prop<boolean>(),
  isEditMode: prop<boolean>(),
  sqStateSynchronizer: injected<StateSynchronizerService>(),
  sqAnnotationActions: injected<AnnotationActions>(),
});

export const WorkstepIcons: SeeqComponent<typeof workstepIconsBindings> = ({ isViewMode, isEditMode }) => {
  const { $state, sqStateSynchronizer, sqAnnotationActions } = useInjectedBindings(workstepIconsBindings);

  const [isProcessing, setIsProcessing] = useState(false);
  const { previous, next, last, current, isError } = useFlux(sqWorkstepsStore);
  const previousAvailable = !_.isEmpty(previous);
  const nextAvailable = !_.isEmpty(next);
  const lastAvailable = !_.isEmpty(last) && last !== current.id;

  /**
   * Rehydrates a workstep, unless it is already in the middle of rehydrating.
   *
   * @param {Function} workstepAction - The action to take that gets the workstep to rehydrate
   * @return {Promise} The rehydrate promise
   */
  const rehydrateWorkstep = (workstepAction) => {
    if (!isProcessing) {
      setIsProcessing(true);
      return workstepAction()
        .then((response) => {
          const workbookId = $state.params.workbookId;
          const worksheetId = $state.params.worksheetId;
          sqStateSynchronizer.setLoadingWorksheet(workbookId, worksheetId);
          return sqStateSynchronizer.rehydrate(response.current.state, { workbookId, worksheetId }).finally(() => {
            sqStateSynchronizer.unsetLoadingWorksheet();
          });
        })
        .then(() => {
          generate($state.params.workbookId, $state.params.worksheetId);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      return Promise.resolve();
    }
  };

  /**
   * Navigates to the specified workstep if it's available.
   *
   * @param {String} which - The identifier (previous, next, last) of the workstep to navigate to
   * @param {Boolean} isAvailable - A flag identifying if the desired workstep is available
   * @returns {Promise} A promise that resolves when the workstep
   * has been rehydrated or rejects if rehydration is already in progress.
   */
  const goToWorkstep = (which: WorkstepButtonType, isAvailable) => {
    if (isAvailable) {
      return rehydrateWorkstep(() => {
        switch (which) {
          case 'previous':
            return previousWorkstepAction($state.params.workbookId, $state.params.worksheetId);
          case 'next':
            return nextWorkstepAction($state.params.workbookId, $state.params.worksheetId);
          case 'last':
            return lastWorkstepAction($state.params.workbookId, $state.params.worksheetId);
        }
      }).then(sqAnnotationActions.fetchAnnotations);
    }
  };

  return (
    <div
      className={isViewMode ? 'flexSelfStretch' : 'flexColumnContainer flexJustifyEnd ml10 mr1 flexNoGrowNoShrink'}
      data-testid="workstepIcons">
      {isEditMode && !sqWorkbookStore.isReportBinder && (
        <div className="unselectable">
          {isError && (
            <Icon
              icon="fa-exclamation-triangle"
              extraClassNames="workstepButton fa-xlg pr10"
              type="danger"
              tooltip="WORKSTEPS.ERROR"
              tooltipPlacement="bottom"
            />
          )}
          <Icon
            icon="fa-reply"
            extraClassNames={classNames({ workstepButton: previousAvailable }, 'fa-xlg pr12')}
            type={previousAvailable ? 'theme-light' : 'gray'}
            onClick={
              previousAvailable
                ? () => {
                    goToWorkstep('previous', previousAvailable);
                  }
                : null
            }
            testId="previousWorkstep"
            tooltip="WORKSTEPS.PREVIOUS"
            tooltipPlacement="bottom"
          />
          <Icon
            icon="fa-share"
            extraClassNames={classNames({ workstepButton: nextAvailable }, 'fa-xlg pr10')}
            type={nextAvailable ? 'theme-light' : 'gray'}
            onClick={
              nextAvailable
                ? () => {
                    goToWorkstep('next', nextAvailable);
                  }
                : null
            }
            testId="nextWorkstep"
            tooltip="WORKSTEPS.NEXT"
            tooltipPlacement="bottom"
          />
          <Icon
            icon="fa-reply-all"
            extraClassNames={classNames({ workstepButton: lastAvailable }, 'fa-xlg pr3 pl9 fa-flip-horizontal')}
            type={lastAvailable ? 'theme-light' : 'gray'}
            onClick={
              lastAvailable
                ? () => {
                    goToWorkstep('last', lastAvailable);
                  }
                : null
            }
            testId="lastWorkstep"
            tooltip="WORKSTEPS.LAST"
            tooltipPlacement="bottom"
          />
        </div>
      )}
    </div>
  );
};
