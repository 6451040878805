// @ts-strict-ignore
import angular from 'angular';
import { sqFormulaToolStore } from '@/hybrid/tools/formula/formulaTool.store';
import { sqFormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { sqTrendCapsuleSetStore, sqTrendScalarStore, sqTrendSeriesStore } from '@/core/core.stores';
import { TrendSeriesStore } from '@/trendData/trendSeries.store';
import { TrendScalarStore } from '@/trendData/trendScalar.store';

const dependencies = [
  'Sq.Vendor',
  'Sq.Core',
  'Sq.AppConstants',
  'Sq.TrendData',
  'Sq.Investigate',
  'Sq.Services.CalculationRunner',
  'Sq.Services.Formula',
  'Sq.Search',
  'Sq.LicenseManagement',
  'Sq.Worksheets',
  'Sq.Worksteps',
  'Sq.Workbench',
];

export let FORMULA_TOOL_TREND_STORES: [TrendScalarStore, TrendCapsuleSetStore, TrendSeriesStore];

export default angular
  .module('Sq.FormulaTool', dependencies)
  .service('sqFormulaToolActions', sqFormulaToolActions)
  .store('sqFormulaToolStore', sqFormulaToolStore)
  // Stores that contain items that can be created from the formula tool
  .factory('FORMULA_TOOL_TREND_STORES', () => [sqTrendScalarStore, sqTrendCapsuleSetStore, sqTrendSeriesStore])
  .run(($injector) => {
    FORMULA_TOOL_TREND_STORES = $injector.get('FORMULA_TOOL_TREND_STORES');
  });

export interface FormulaParameter {
  identifier: string;
  item: { id: string; name: string };
}

export interface FunctionDocItem {
  name: string;
  shortDescription: string;
  description: string;
  keywords: string[];
}
