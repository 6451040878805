import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { PluginHost } from '@/hybrid/plugin/PluginHost.atom';
import { pluginWorkerApi } from '@/webWorkers/plugin.worker-api';
import { sqPluginStore } from '@/core/core.stores';
import { generatePluginApi } from '@/hybrid/plugin/generatedPluginApi';

const toolPanePluginHostBindings = bindingsDefinition({
  pluginIdentifier: prop<string>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  $injector: injected<ng.auto.IInjectorService>(),
});

export const ToolPanePluginHost: SeeqComponent<typeof toolPanePluginHostBindings> = (props) => {
  const { pluginIdentifier } = props;
  const { $injector } = useInjectedBindings(toolPanePluginHostBindings);
  const createApi = (port: MessagePort) => generatePluginApi(pluginIdentifier, pluginWorkerApi(), $injector, port);

  const entryPoint = sqPluginStore.getPlugin(pluginIdentifier).entryPoint || '';

  return (
    <div className="flexFill flexRowContainer height-maximum width-maximum">
      <PluginHost entryPoint={entryPoint} createApi={createApi} />
    </div>
  );
};
