// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { RefreshingContent } from '@/hybrid/annotation/ckEditorPlugins/components/RefreshingContent.molecule';
import { CkReportContentService } from '@/hybrid/annotation/ckReportContent.service';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';

import { HtmlPortalNode } from 'react-reverse-portal';
import { ReportActions } from '@/reportEditor/report.actions';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';

import {
  ContentCallbacks,
  ContentDisplayMode,
  CustomPlugin,
} from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { isContentInDocument } from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { useTranslation } from 'react-i18next';
import { headlessRenderMode } from '@/hybrid/utilities/utilities';
import { CONTENT_MODEL_ATTRIBUTES } from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import {
  AssetSelection,
  Content as ContentItem,
  CONTENT_LOADING_CLASS,
  DateRange,
} from '@/reportEditor/report.constants';
import { sqReportStore } from '@/core/core.stores';
import { addObserverTarget, removeObserverTarget } from '@/hybrid/core/intersectionObserverHub';

const contentBindings = bindingsDefinition({
  // The below three props should be used in the initial render/useEffect. After that point, they may be out of
  // date due to duplication changing data behind the scenes. If additional functionality is added, make sure to use
  // `actualId` when referencing stores and the like.
  contentId: prop<string>(),
  modelElement: prop<any>(),
  portalNode: prop<HtmlPortalNode>(),
  displayMode: prop<ContentDisplayMode>(),
  sqCkReportContent: injected<CkReportContentService>(),
  sqReportContent: injected<ReportContentService>(),
  sqReportEditor: injected<ReportEditorService>(),
  sqReportActions: injected<ReportActions>(),
});

const MAX_TRIES = 100;

const minWidth = 100;
const maxWidth = 100;
const minHeight = 100;
const maxHeight = 100;
export const CONTENT_DATA_ID_PREFIX = 'content-wrapper-';

/**
 * Insertion in CKEditor in a synchronous process, so to copy or unarchive content we need to do it after the
 * component is inserted. This component either renders, duplicates, or unarchives the Content depending on the
 * below set of conditions.
 */
export const DuplicatingContent: SeeqComponent<typeof contentBindings> = (props) => {
  const { contentId, modelElement, portalNode, displayMode } = props;
  const { sqCkReportContent, sqReportEditor, sqReportActions, sqReportContent } = useInjectedBindings(contentBindings);

  const { t } = useTranslation();

  const editor: any = sqReportEditor.getGlobalInstance();
  const contentCallbacks: ContentCallbacks = editor.config.get(CustomPlugin.Content).contentCallbacks;

  // A = In store, B = Archived, C = has content display metadata (is visible)
  // !A, Content has been pasted from another document, needs duplication
  // A + B, Content has been cut and pasted, needs unarchival
  // A + !B + C, Content has been copied from and pasted to its current document, needs duplication
  // A + !B + !C, Content has not yet been displayed. Pass ID to actual Content component
  const canSkipDuplication =
    (sqReportStore.getContentById(contentId) &&
      !sqReportStore.getContentById(contentId)?.isArchived &&
      !isContentInDocument(contentId)) ||
    headlessRenderMode();
  const [error, setError] = useState(undefined);
  const [actualId, setActualId] = useState(canSkipDuplication && contentId);
  const [showRealContent, setShowRealContent] = useState(false);

  const duplicateOrUnarchiveContent = (content: ContentItem, dateRange: DateRange, assetSelection: AssetSelection) => {
    sqCkReportContent
      .duplicateOrUnarchiveContent(content, isContentInDocument(contentId), dateRange, assetSelection)
      .then((content) => {
        if (content) {
          sqReportActions.setContentDisplayMetadata({
            contentId: content.id,
            refresh: undefined,
            errorClass: undefined,
          });
          contentCallbacks.updateContentModelAndNode(
            editor,
            content.id,
            modelElement,
            portalNode,
            contentId !== content.id,
          );
          setActualId(content.id);
          setShowRealContent(true);
          sqReportActions.resetContentErrors();
        }
      })
      .catch((error) => setError(sqCkReportContent.duplicateContentError(contentId, error).errorClass));
  };

  const waitUntilDependenciesCopied = (
    content: ContentItem,
    dateRange: DateRange,
    assetSelection: AssetSelection,
    currentTry = 0,
  ) => {
    if (currentTry > MAX_TRIES) {
      errorToast({ messageKey: 'REPORT.CONTENT.DEPENDENCY_TIMEOUT' });
      setError(CONTENT_LOADING_CLASS.ERROR);
    } else {
      setTimeout(() => {
        if (
          sqReportContent.isDateRangeBeingCopied(dateRange) ||
          sqReportContent.isAssetSelectionBeingCopied(assetSelection)
        ) {
          waitUntilDependenciesCopied(content, dateRange, assetSelection, currentTry + 1);
        } else {
          duplicateOrUnarchiveContent(content, dateRange, assetSelection);
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (actualId) {
      sqReportActions.setContentDisplayMetadata({
        contentId: actualId,
        refresh: undefined,
        errorClass: undefined,
      });
      contentCallbacks.updateContentModelAndNode(editor, actualId, modelElement, portalNode);
      setShowRealContent(true);
    } else {
      contentCallbacks.updateContentAttributeWithoutSaving(CONTENT_MODEL_ATTRIBUTES.PENDING, contentId, modelElement);
      sqReportActions
        .fetchContent(contentId, false)
        .then(({ content, dateRange, assetSelection }) => {
          if (
            sqReportContent.isDateRangeBeingCopied(dateRange) ||
            sqReportContent.isAssetSelectionBeingCopied(assetSelection)
          ) {
            waitUntilDependenciesCopied(content, dateRange, assetSelection);
          } else {
            duplicateOrUnarchiveContent(content, dateRange, assetSelection);
          }
        })
        .catch((error) => setError(sqCkReportContent.duplicateContentError(contentId, error).errorClass));
    }
  }, []);

  // setup scroll check
  const [stopUpdate, setStopUpdate] = useState(displayMode !== ContentDisplayMode.PDF);
  const [contentWrapperElement, setContentWrapperElement] = useState<HTMLElement>(null);
  useEffect(() => {
    if (displayMode !== ContentDisplayMode.PDF && contentWrapperElement) {
      addObserverTarget(contentWrapperElement, setStopUpdate);
    }
  }, [actualId, contentWrapperElement]);
  useEffect(() => {
    return () => removeObserverTarget(contentWrapperElement);
  }, []);

  const displayClass = error ?? CONTENT_LOADING_CLASS.SPINNER;

  return actualId && showRealContent ? (
    <RefreshingContent
      contentId={actualId}
      editor={editor}
      updateContentSize={contentCallbacks.updateContentSize}
      displayMode={displayMode}
      stopUpdate={stopUpdate}
      wrapperLoadedCallback={(el) => setContentWrapperElement(el)}
    />
  ) : (
    <img
      data-testid={`${CONTENT_DATA_ID_PREFIX}${contentId}`}
      style={{ minHeight, minWidth, maxHeight, maxWidth }}
      className={displayClass}
    />
  );
};
