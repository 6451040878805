/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 58.8.10-v202403072038
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/hybrid/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqJobsApiClass {
 
   constructor() {}

  /**
   * @summary Create a job to capture a content screenshot at a regular interval
   */
  public createContentJob(
    body: string,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/jobs/content`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.JobOutputV1>;
  }

  /**
   * @summary Create a job to update a date range and its content at a regular interval
   */
  public createDateRangeJob(
    body: string,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/jobs/dateRange`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.JobOutputV1>;
  }

  /**
   * @summary Delete a scheduled job and cancel any currently running instance
   * @param {string} group - Group to which the job belongs
   * @param {string} id - ID of the job to delete
   */
  public deleteJob(
    {
      group,
      id
    } : {
      group: string,
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(group)) {
      throw new Error("'group' parameter required");
    }

    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/jobs/${encodeURIComponent(String(group))}/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary List of scheduled jobs
   * @param {number} [offset=0] - The pagination offset, the index of the first job that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of jobs that will be returned in this page of results
   * @param {string} [schedulerType=User] - Which scheduler to query for jobs. The options are User (default), System, and AsyncMigration.
   */
  public getJobs(
    {
      offset,
      limit,
      schedulerType
    } : {
      offset?: number,
      limit?: number,
      schedulerType?: SchedulerTypeEnum
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/jobs`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit,
        ['schedulerType']: schedulerType
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetJobsOutputV1>;
  }

  /**
   * @summary A simplified endpoint for fetching a screenshot of Seeq content at a regular interval.
   * @param {string} id - The content id to capture
   */
  public getScreenshotFromContent(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/jobs/content/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ScreenshotOutputV1>;
  }

  /**
   * @summary Validate a list of Quartz cron expressions
   */
  public validateCron(
    body: models.ValidateCronListInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/jobs/validateCron`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ValidateCronListOutputV1>;
  }

}

export enum SchedulerTypeEnum {
    User = 'User' as any,
    System = 'System' as any,
    AsyncMigration = 'AsyncMigration' as any
}

export const sqJobsApi = new sqJobsApiClass();
