import { SeeqNames } from '@/main/app.constants.seeqnames';
import { addPendingRequest, removePendingRequest } from '@/hybrid/requests/pendingRequests.utilities';
import { extractGuids } from '@/hybrid/utilities/utilities';
import { generateRequestId } from '@/hybrid/utilities/http.utilities';
import { SeeqAxiosRequestConfig, SeeqAxiosResponse } from '@/hybrid/requests/axios.utilities';

export function cancellationResponseInterceptor(response: SeeqAxiosResponse) {
  removeRequestCancellation(response);
  return response;
}

export function cancellationResponseErrorInterceptor(response: SeeqAxiosResponse) {
  removeRequestCancellation(response);
  return Promise.reject(response);
}

function removeRequestCancellation(response: SeeqAxiosResponse) {
  if (response.config?.headers) {
    const requestId = response.config.headers[SeeqNames.API.Headers.RequestId];
    if (requestId && response.config.cancellationGroup) {
      removePendingRequest(requestId);
    }
  }
  return response;
}

export function cancellationRequestInterceptor(config: SeeqAxiosRequestConfig) {
  if (config.cancellationGroup) {
    if (config.headers) {
      let requestId = config.headers[SeeqNames.API.Headers.RequestId];
      if (!requestId) {
        requestId = generateRequestId();
        config.headers[SeeqNames.API.Headers.RequestId] = requestId;
      }
      config.timeout = addPendingRequest(
        requestId,
        config,
        extractGuids(config.url)
          .concat(extractGuids(JSON.stringify(config.params)))
          .concat([config.cancellationGroup]),
      );
    }
  }

  return config;
}
