// @ts-strict-ignore
import _ from 'lodash';
import angular from 'angular';

/**
 * @module The 'Sq.Directives.ResizeNotify' module contains the 'sqResizeNotify' directive.
 * This directive invokes a user supplied callback when the size of the element on which it is
 * placed changes. The directive may only be used as an attribute. The callback function supplied
 * to the directive takes four optional arguments:
 * @param {Number} width The current width of the element on which the directive is placed
 * @param {Number} height The current height of the element on which the directive is placed
 * @param {Number} previousWidth The previous width of the element on which the directive is placed
 * @param {Number} previousHeight The previous height of the element on which the directive is placed
 * @example <div sq-resize-notify="onHighstockDivResize(width, height, previousWidth, previousHeight)"></div>
 */
angular.module('Sq.Directives.ResizeNotify', []).directive('sqResizeNotify', sqResizeNotify);

function sqResizeNotify() {
  interface IResizeNotifyScope extends ng.IScope {
    sqResizeNotify: Function;
    sqResizeNotifyInitial: boolean;
  }

  const directive = {
    link: linker,
    restrict: 'A',
    scope: {
      sqResizeNotify: '&',
      sqResizeNotifyInitial: '=',
    },
  };

  return directive;

  function linker($scope: IResizeNotifyScope, element: JQuery) {
    let notifyInitial = $scope.sqResizeNotifyInitial;

    // Initialize width and height state
    let currentWidth = element.width();
    let previousWidth = element.width();
    let currentHeight = element.height();
    let previousHeight = element.height();

    // Watch the width of the element and callback if it has changed
    $scope.$watch(function () {
      return element.width();
    }, _.partial(checkSizeAndNotify, 'width'));

    // Watch the height of the element and callback if it has changed
    $scope.$watch(function () {
      return element.height();
    }, _.partial(checkSizeAndNotify, 'height'));

    function checkSizeAndNotify(dimension, newVal, oldVal) {
      let hasChanged;

      // Record for use in the watcher callback
      if (dimension === 'height') {
        currentHeight = newVal;
        previousHeight = oldVal;
        hasChanged = currentHeight !== previousHeight;
      } else {
        currentWidth = newVal;
        previousWidth = oldVal;
        hasChanged = currentWidth !== previousWidth;
      }

      if (hasChanged || notifyInitial) {
        notifyInitial = false;
        $scope.sqResizeNotify({
          width: currentWidth,
          height: currentHeight,
          previousWidth,
          previousHeight,
        });
      }
    }
  }
}
