import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { TableColumnFilter } from '@/hybrid/core/tableUtilities/tables';
import { PropertyColumn as FormulaPropertyColumn } from '@/services/formula.service';

export enum TableBuilderMode {
  Simple = 'simple',
  Condition = 'condition',
}

export enum TableBuilderColumnType {
  Text = 'text', // Column that will be filled with custom text per metric
  Property = 'property', // Column that will be filled with the value of a specified item property
  CapsuleProperty = 'capsuleProperty', // Column that will be filled with the value of a specified capsule property
}

export enum TableBuilderHeaderType {
  None = 'none',
  Start = 'start',
  End = 'end',
  StartEnd = 'startEnd',
  CapsuleProperty = 'capsuleProperty',
}

export const STRIPED_CELL_COLOR = '#f9f9f9';
export const PRIORITY_COLUMN_NAME = 'Priority';
export const PRIORITY_NAME_COLUMN_NAME = 'Priority Name';
export const COLOR_COLUMN_NAME = 'Color';
export const TABLE_BUILDER = 'Table Builder';

export interface MetricPropertyColumn extends FormulaPropertyColumn {
  /**
   * The name of the property on the metric capsule.
   */
  metricProperty?: string;
  /**
   * The parameter expression used to get information about the metric item
   */
  expression?: string;
  /** A filter on the metric property column (optional) */
  filter?: TableColumnFilter;
}

export const SIMPLE_TABLE_ID_COLUMN = 'itemId';
export const ITEM_UOM = 'itemUom';
export const SIMPLE_TABLE_BUILDER_EXTRA_CUSTOMIZATION_COLUMNS = ['metricValue'];
export const SIMPLE_METRIC_FALLBACK_STATISTIC = { key: 'statistics.endValue' };
export const CONDITION_EXTRA_COLUMNS = [
  COLUMNS_AND_STATS.startTime.key,
  COLUMNS_AND_STATS.endTime.key,
  COLUMNS_AND_STATS.nameExpression.key,
  COLUMNS_AND_STATS.asset.key,
];
// Prefix added to columns in the formula so that they don't conflict with the names of actual properties added by
// the user
export const COLUMN_PREFIX = '__';
export const withDefaultFormatting = (column: any) => ({
  ...column,
  headerTextAlign: 'center',
  headerTextStyle: ['bold'],
});
export const SIMPLE_TABLE_DEFAULT_COLUMNS = [
  withDefaultFormatting(COLUMNS_AND_STATS.name),
  withDefaultFormatting(COLUMNS_AND_STATS['statistics.average']),
];
export const PREDEFINED_COLUMN_INDEX = {
  [COLUMNS_AND_STATS.name.key]: 0,
  [COLUMNS_AND_STATS.asset.key]: 1,
};
export const CONDITION_TABLE_DEFAULT_COLUMNS = [withDefaultFormatting({ key: COLUMNS_AND_STATS.name.key })];
export const CONDITION_METRIC_COLUMNS: MetricPropertyColumn[] = [
  {
    key: 'value',
    propertyName: SeeqNames.CapsuleProperties.Value,
    metricProperty: SeeqNames.CapsuleProperties.Value,
    invalidsFirst: true,
  },
  {
    key: 'priorityColor',
    propertyName: COLOR_COLUMN_NAME,
    metricProperty: COLOR_COLUMN_NAME,
  },
  {
    key: 'priority',
    propertyName: PRIORITY_COLUMN_NAME,
    metricProperty: PRIORITY_COLUMN_NAME,
  },
  // CRAB-27186 - This column is not used, but must be unique per metric to work with the mergeRows() algorithm
  {
    key: 'priorityName',
    propertyName: PRIORITY_NAME_COLUMN_NAME,
    metricProperty: PRIORITY_NAME_COLUMN_NAME,
  },
  {
    key: 'itemId',
    propertyName: 'itemId',
    expression: `property('${SeeqNames.Properties.Id}')`,
  },
];
