// @ts-strict-ignore
import _ from 'lodash';
import React, { useState } from 'react';
import { PriorityV1 } from '@/sdk';
import { TextFormatterPopover } from '@/hybrid/core/TextFormatterPopover.molecule';
import { TableTextFormatterIF } from '@/hybrid/tableBuilder/TableBuilder.molecule';
import { TableColumnFilter } from '@/hybrid/core/tableUtilities/tables';

import { TableFilterPopover } from '@/hybrid/core/tableUtilities/TableFilterPopover.organism';
import { TableColumnMenu } from '@/hybrid/core/tableUtilities/TableColumnMenu.organism';
import { TABLE_BUILDER } from '@/hybrid/tableBuilder/tableBuilder.constants';
import { doTrack } from '@/track/track.service';
import { priorityColors } from '@/services/systemConfiguration.utilities';

export enum TextHeaderMenuActions {
  Style = 'style',
  Rename = 'rename',
  Remove = 'remove',
  Filter = 'filter',
  Sort = 'sort',
}

export interface TextHeaderMenuIF {
  canRenameHeader: boolean;
  columnKey: string;
  columnBackgroundColor?: string;
  columnTextAlign?: string;
  columnTextColor?: string;
  columnTextStyle?: string[];
  headerBackgroundColor?: string;
  headerTextAlign?: string;
  headerTextColor?: string;
  headerTextStyle?: string[];
  isTransposed: boolean;
  target: any;
  textFormatter: TableTextFormatterIF;
  editHeaderValue: () => void;
  removeColumn: (key: string) => void;
  actions?: TextHeaderMenuActions[];
  setColumnFilter?: (key: string, filter: TableColumnFilter) => void;
  columnFilter?: TableColumnFilter;
  isFilterDisabled?: boolean;
  filterHelpText?: string;
  canSort?: boolean;
  canSortDisabledTooltip?: string;
  sortDirection?: string;
  sortByColumn: (key: string, direction: string) => void;
  isStringColumn?: boolean;
  isDurationColumn?: boolean;
  fetchStringColumnValues?: (columnKey: string, isStringColumn: boolean, cancellationGroup) => void;
  distinctStringValues?: string[];
  thresholds?: any[] | undefined;
}

const DATA_CANCELLATION_GROUP = 'tableBuilder';

export const TableBuilderTextHeaderMenu: React.FunctionComponent<TextHeaderMenuIF> = (props) => {
  const {
    canRenameHeader,
    columnKey,
    columnBackgroundColor,
    columnTextAlign,
    columnTextColor,
    columnTextStyle,
    headerBackgroundColor,
    headerTextAlign,
    headerTextColor,
    headerTextStyle,
    isTransposed,
    target,
    textFormatter,
    editHeaderValue,
    removeColumn,
    actions = _.values(TextHeaderMenuActions),
    setColumnFilter,
    columnFilter = undefined,
    isFilterDisabled = false,
    filterHelpText = undefined,
    canSort = false,
    canSortDisabledTooltip,
    sortByColumn,
    sortDirection = undefined,
    isStringColumn = false,
    isDurationColumn = false,
    fetchStringColumnValues = _.noop,
    distinctStringValues = undefined,
    thresholds = undefined,
  } = props;

  const priorities: PriorityV1[] = priorityColors();
  const colors: string[] = _.chain(priorities).map('color').uniq().value();

  const [showColumnFormatPopover, setShowColumnFormatPopover] = useState(false);
  const [showHeaderFormatPopover, setShowHeaderFormatPopover] = useState(false);
  const [showColumnFilterPopover, setShowColumnFilterPopover] = useState(false);

  const filterColumn = () => {
    setShowColumnFilterPopover(true);
    fetchStringColumnValues(columnKey, isStringColumn, DATA_CANCELLATION_GROUP);
  };

  const columnActions = _.filter(
    [
      {
        iconClass: 'fa-filter',
        translationKey: 'FILTER.FILTER',
        actionKey: TextHeaderMenuActions.Filter,
        action: () => filterColumn(),
        disabled: isFilterDisabled,
        tooltip: !isFilterDisabled ? false : 'TABLE_BUILDER.ONLY_ON_TABLE_WITH_ITEMS_SAME_TYPE',
      },
      {
        actionKey: TextHeaderMenuActions.Sort,
        iconClass: 'fa-sort',
        translationKey: 'TABLE_BUILDER.SORT',
        disabled: !canSort,
        tooltip: canSortDisabledTooltip,
        subMenuItems: [
          {
            disabled: sortDirection === 'asc',
            iconClass: 'fa-sort-amount-asc',
            translationKey: 'TABLE_BUILDER.SORT_ASCENDING',
            action: () => {
              sortByColumn(columnKey, 'asc');
              doTrack(TABLE_BUILDER, 'sort', 'enabled');
            },
          },
          {
            disabled: sortDirection === 'desc',
            iconClass: 'fa-sort-amount-desc',
            translationKey: 'TABLE_BUILDER.SORT_DESCENDING',
            action: () => {
              sortByColumn(columnKey, 'desc');
              doTrack(TABLE_BUILDER, 'sort', 'enabled');
            },
          },
          {
            disabled: _.isUndefined(sortDirection),
            iconClass: 'fa-times',
            translationKey: 'TABLE_BUILDER.SORT_REMOVE',
            action: () => {
              sortByColumn(columnKey, undefined);
              doTrack(TABLE_BUILDER, 'sort', 'disabled');
            },
          },
        ],
      },
      {
        iconClass: 'fa-list-alt',
        translationKey: 'TABLE_BUILDER.HEADER_PROPERTIES',
        actionKey: TextHeaderMenuActions.Style,
        action: () => setShowHeaderFormatPopover(true),
      },
      {
        iconClass: 'fa-columns',
        translationKey: isTransposed ? 'TABLE_BUILDER.ROW_PROPERTIES' : 'TABLE_BUILDER.COLUMN_PROPERTIES',
        actionKey: TextHeaderMenuActions.Style,
        action: () => setShowColumnFormatPopover(true),
      },
      {
        iconClass: 'fc-paintbrush',
        translationKey: 'TABLE_BUILDER.APPLY_STYLE_TO_ALL',
        actionKey: TextHeaderMenuActions.Style,
        subMenuItems: [
          {
            iconClass: 'fa-list-alt',
            translationKey: 'TABLE_BUILDER.HEADERS',
            action: () => textFormatter.setStyleToAllHeaders(columnKey),
          },
          {
            iconClass: 'fa-columns',
            translationKey: isTransposed ? 'TABLE_BUILDER.ROWS' : 'TABLE_BUILDER.COLUMNS',
            action: () => textFormatter.setStyleToAllColumns(columnKey),
          },
          {
            iconClass: 'fa-table',
            translationKey: isTransposed ? 'TABLE_BUILDER.ROWS_AND_HEADERS' : 'TABLE_BUILDER.COLUMNS_AND_HEADERS',
            action: () => textFormatter.setStyleToAllHeadersAndColumns(columnKey),
          },
        ],
      },
      {
        iconClass: 'fa-copy',
        translationKey: 'TABLE_BUILDER.COPY_STYLE',
        actionKey: TextHeaderMenuActions.Style,
        action: () => textFormatter.copyStyle(columnKey),
      },
      {
        iconClass: 'fc-paintbrush',
        translationKey: 'TABLE_BUILDER.PASTE_STYLE_OF',
        actionKey: TextHeaderMenuActions.Style,
        subMenuItems: [
          {
            iconClass: 'fa-list-alt',
            translationKey: 'TABLE_BUILDER.HEADER',
            action: () => textFormatter.pasteStyleOnHeader(columnKey),
          },
          {
            iconClass: 'fa-columns',
            translationKey: isTransposed ? 'TABLE_BUILDER.ROW' : 'TABLE_BUILDER.COLUMN',
            action: () => textFormatter.pasteStyleOnColumn(columnKey),
          },
          {
            iconClass: 'fa-table',
            translationKey: isTransposed ? 'TABLE_BUILDER.ROW_AND_HEADER' : 'TABLE_BUILDER.COLUMN_AND_HEADER',
            action: () => textFormatter.pasteStyleOnHeaderAndColumn(columnKey),
          },
        ],
      },
      {
        iconClass: 'fa-edit',
        translationKey: 'TABLE_BUILDER.RENAME_HEADER',
        actionKey: TextHeaderMenuActions.Rename,
        action: () => editHeaderValue(),
        disabled: !canRenameHeader,
      },
      {
        iconClass: 'fa-times',
        translationKey: 'REMOVE_COLUMN',
        actionKey: TextHeaderMenuActions.Remove,
        action: () => removeColumn(columnKey),
      },
    ],
    ({ actionKey }) => _.includes(actions, actionKey),
  );

  return (
    <div>
      <TableColumnMenu columnActions={columnActions} columnKey={columnKey} />
      <TextFormatterPopover
        backgroundColor={headerBackgroundColor}
        textAlign={headerTextAlign}
        textColor={headerTextColor}
        textStyle={headerTextStyle}
        colors={colors}
        columnKey={columnKey}
        onClose={() => setShowHeaderFormatPopover(false)}
        setTextAlign={(columnKey, alignment) => textFormatter.setHeaderTextAlign(columnKey, alignment)}
        setTextBackground={(columnKey, color) => textFormatter.setHeaderBackground(columnKey, color)}
        setTextColor={(columnKey, color) => textFormatter.setHeaderTextColor(columnKey, color)}
        setTextStyle={(columnKey, styles) => textFormatter.setHeaderTextStyle(columnKey, styles)}
        placement="bottom"
        target={target?.current}
        show={showHeaderFormatPopover}
        title="TABLE_BUILDER.HEADER_PROPERTIES"
      />
      <TextFormatterPopover
        backgroundColor={columnBackgroundColor}
        textAlign={columnTextAlign}
        textColor={columnTextColor}
        textStyle={columnTextStyle}
        colors={colors}
        columnKey={columnKey}
        onClose={() => setShowColumnFormatPopover(false)}
        setTextAlign={(columnKey, alignment) => textFormatter.setColumnTextAlign(columnKey, alignment)}
        setTextBackground={(columnKey, color) => textFormatter.setColumnBackground(columnKey, color)}
        setTextColor={(columnKey, color) => textFormatter.setColumnTextColor(columnKey, color)}
        setTextStyle={(columnKey, styles) => textFormatter.setColumnTextStyle(columnKey, styles)}
        placement="bottom"
        target={target?.current}
        show={showColumnFormatPopover}
        title={isTransposed ? 'TABLE_BUILDER.ROW_PROPERTIES' : 'TABLE_BUILDER.COLUMN_PROPERTIES'}
      />
      <TableFilterPopover
        columnFilter={columnFilter}
        columnKey={columnKey}
        notifyOnClose={() => setShowColumnFilterPopover(false)}
        setColumnFilter={(columnKey, filter) => setColumnFilter(columnKey, filter)}
        isStringColumn={isStringColumn}
        isDurationColumn={isDurationColumn}
        distinctStringValues={distinctStringValues}
        thresholds={thresholds}
        helpText={filterHelpText}
        placement="bottom"
        target={target?.current}
        show={_.includes(actions, TextHeaderMenuActions.Filter) && showColumnFilterPopover}
      />
    </div>
  );
};
