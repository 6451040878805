import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { doTrack } from '@/track/track.service';

interface CloseWhileSavingModalProps {
  onClose: () => void;
}

export const ClosedWhileSavingModal: React.FunctionComponent<CloseWhileSavingModalProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal animation={false} backdrop="static" show={true}>
      <Modal.Header>
        <Modal.Title>{t('ITEM_ACL.CLOSE_DURING_SAVE.HEADER')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('ITEM_ACL.CLOSE_DURING_SAVE.MESSAGE')}</Modal.Body>
      <Modal.Footer>
        <TextButton
          label="ITEM_ACL.CLOSE_DURING_SAVE.CLOSE"
          variant="theme"
          onClick={() => {
            doTrack('Access Control', 'close during save', 'close');
            onClose();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
