import React from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ChannelOutputV1 } from '@/sdk/model/models';

export interface SubscribersModalProps {
  channel: ChannelOutputV1;
  onClose: () => void;
}

export const SubscribersModal: React.FunctionComponent<SubscribersModalProps> = ({ channel, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="subscribersModal">
      <Modal.Header closeButton={true}>
        <div className="flexRowContainer">
          <h3>{t('ADMIN.SUBSCRIPTION.CHANNELS.SUBSCRIBERS')}</h3>
          <h5 className="mt0">{channel?.id}</h5>
        </div>
      </Modal.Header>
      <Modal.Body className="min-height-300 max-height-500 overflowYAuto">
        <table className="table table-condensed table-striped">
          <thead>
            <tr>
              <th>{t('ADMIN.SUBSCRIPTION.CHANNELS.USERNAME')}</th>
              <th>{t('ADMIN.SUBSCRIPTION.CHANNELS.SESSION_ID')}</th>
            </tr>
          </thead>
          <tbody>
            {_.map(channel?.subscriptions, ({ username, sessionId }) => (
              <tr key={sessionId}>
                <td>{username}</td>
                <td>{sessionId}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};
