// @ts-strict-ignore
import _ from 'lodash';

export function dataToTable(data, columns, t) {
  return [_.map(columns, (item) => item.header || t(item.shortTitle))].concat(
    _.map(data, (item) =>
      _.map(item.cells, (singleCell) => ({
        value: singleCell.value,
        uom: singleCell.units,
      })),
    ),
  );
}

export function getCategoryName(data: any[][], index: number, categoryColumns: number[]): string {
  return _.map(categoryColumns, (selectedColumn) => data[index][selectedColumn]?.value).join(' ');
}
