// @ts-strict-ignore
import React from 'react';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { ButtonWithPopover } from '@/hybrid/core/ButtonWithPopover.molecule';
import { Dropdown } from 'react-bootstrap';
import _ from 'lodash';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { isEditWorkbookMode } from '@/hybrid/utilities/utilities';

interface JournalHeaderProps {
  showOverview: () => any;
  isDeleteAllowed: boolean;
  setExpanded: (expand: boolean) => any;
  isExpanded: boolean;
  createdBy: string;
  isSaving: boolean;
  isSaved: boolean;
  updatedAt: string;
  deleteAnnotation: (id: string) => any;
  hasMultipleJournalEntries: boolean;
  isDiscoverable: boolean;
  close: () => any;
  annotationId: string;
}

export const JournalHeader: React.FunctionComponent<JournalHeaderProps> = ({
  showOverview,
  setExpanded,
  isExpanded,
  createdBy,
  isSaving,
  isSaved,
  updatedAt,
  hasMultipleJournalEntries,
  isDiscoverable,
  annotationId,
  deleteAnnotation,
  close,
  isDeleteAllowed,
}) => {
  const { t } = useTranslation();
  const isEditMode = isEditWorkbookMode();
  const showDeleteOption = isDeleteAllowed && isDiscoverable;
  const showSavedFlag = isEditMode && isSaved && !isSaving;
  const showAnnotationUI = isDiscoverable || hasMultipleJournalEntries;

  const renderDropdownEntry = ({ iconClass, translationKey, action, doRender }) => {
    if (doRender) {
      return (
        <Dropdown.Item
          key={`dropdown_${translationKey}`}
          onClick={action}
          className="journalHeaderDropdown sq-icon-hover dropdown-menu-sm cursorPointer pl30 pr30">
          <Icon icon={iconClass} extraClassNames="fa-fw mr5" />
          <span data-testid={`moreActions_${translationKey}`}>{t(translationKey)}</span>
        </Dropdown.Item>
      );
    }
  };

  const actions = [
    {
      iconClass: 'fc fc-annotate',
      translationKey: 'JOURNAL.CATEGORY.ANNOTATIONS.HEADER',
      action: showOverview,
      doRender: true,
    },
    {
      iconClass: 'fa-remove',
      translationKey: 'DELETE',
      action: () => deleteAnnotation(annotationId),
      doRender: showDeleteOption,
    },
  ];

  return (
    <div data-testid="journalHeaderId">
      <div className="flexColumnContainer flexSpaceBetween m10">
        <HoverTooltip text={t('JOURNAL.TOOLTIP.CREATED_BY') + createdBy}>
          <span id="specJournalHeaderLabel" className="sq-text-primary">
            <Icon icon="fc-journal" extraClassNames="pr5" large={true} />
            {isEditMode ? t('JOURNAL.ENTRY.EDITING') : t('JOURNAL.ENTRY.VIEWING')}
          </span>
        </HoverTooltip>
        {showSavedFlag && (
          <HoverTooltip text={`${t('JOURNAL.ENTRY.SAVED')} ${updatedAt}`}>
            <span
              data-testid="specJournalSaveStatus"
              className="flexColumnContainer flexAlignCenter sq-fairly-dark-gray cursorDefault">
              {t('JOURNAL.ENTRY.SAVED')}
            </span>
          </HoverTooltip>
        )}
        <div className="flexColumnContainer flexAlignCenter">
          {showAnnotationUI && (
            <HoverTooltip text={t('JOURNAL.TOOLTIP.CLOSE')}>
              <TextButton
                testId="specJournalCloseBtn"
                label="CLOSE"
                extraClassNames="sq-btn-xs ml10"
                onClick={close}
                variant="theme"
              />
            </HoverTooltip>
          )}
          <ButtonWithPopover
            label={
              <div data-testid="journalHeaderMenu" className="align-right pt2 pb2 pr2 pl2">
                <Icon
                  icon="fc fc-more"
                  extraClassNames="fa-med fa-fw folderAction"
                  testId="specJournalHeaderMenu"
                  tooltipDelay={500}
                  tooltip="JOURNAL.TOOLTIP.ADDITIONAL"
                  tooltipPlacement="top"
                />
              </div>
            }
            popoverConfig={{
              id: 'Menu',
              placement: 'bottom-start',
            }}>
            {_.map(actions, renderDropdownEntry)}
          </ButtonWithPopover>
          <Icon
            onClick={() => setExpanded(!isExpanded)}
            testId="specJournalHeaderExpand"
            icon={isExpanded ? 'fc-expand-close' : 'fc-expand-open'}
            extraClassNames="cursorPointer link-no-underline fc fa-lg ml10"
            tooltipPlacement="top"
            tooltip={t('JOURNAL.TOOLTIP.EXPAND')}
          />
        </div>
      </div>
      {showAnnotationUI && (
        <div className="flexColumnContainer flexCenter dividerBorderTop tableHighlightBackground">
          {t('ITEM_TYPES.ANNOTATION')}
        </div>
      )}
    </div>
  );
};
