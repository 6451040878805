import { useTranslation } from 'react-i18next';
import { IPromise } from 'angular';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

export type ItemDescriptionProps = {
  canWrite: () => boolean;
  originalDesc: string;
  updateDescription: (description: string) => IPromise<void>;
};

export const ItemDescription = ({ canWrite, originalDesc, updateDescription }: ItemDescriptionProps) => {
  const { t } = useTranslation();

  const [description, setDescription] = useState(originalDesc);

  useEffect(() => setDescription(originalDesc), [originalDesc]);

  return (
    <dl data-testid="itemDescription" className="dl-striped-reversed dl-horizontal-indented properties-list">
      {canWrite() && (
        <div className="striped propList__item" data-testid="descriptionContainer">
          <dt className="mb0 striped propList__item__name">{t('DESCRIPTION')}</dt>
          <dd className="propList__item__value">
            <textarea
              className="form-control min-height-18 mb5"
              id="specDescription"
              data-testid="specDescription"
              value={description ?? ''}
              onChange={(e) => setDescription(e.target.value)}
              onFocus={(e) => e.target.select()}
              onBlur={() => updateDescription(description)}
              rows={4}
            />
          </dd>
        </div>
      )}
      {!(_.isEmpty(description) || canWrite()) && (
        <div className="striped propList__item" data-testid="descriptionDisplayContainer">
          <dt className="mb0 striped">{t('DESCRIPTION')}</dt>
          <dd className="propList__item__value">
            <p className="text-pre-wrap mb5">{description}</p>
          </dd>
        </div>
      )}
    </dl>
  );
};
