// @ts-strict-ignore
import { FroalaPluginsService } from '@/reportEditor/froalaPlugins.service';
import React, { useEffect, useRef, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useResizeWatcher } from '@/hybrid/core/hooks/useResizeWatcher.hook';
import { ContainerWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { CustomPlugin } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import classNames from 'classnames';
import { ReportActions } from '@/reportEditor/report.actions';

import { getLanguage, isEditWorkbookMode } from '@/hybrid/utilities/utilities';

import { sqWorkbenchStore } from '@/core/core.stores';

/**
 * Initializes the Froala Journal editor
 */
export interface EditorDependencies {
  sqReportEditor: ReportEditorService;
  sqFroalaPlugins: FroalaPluginsService;
  $sce: ng.ISCEService;
  t: (label) => string;
  $injector: ng.auto.IInjectorService;
  $rootScope: ng.IRootScopeService;
  sqReportActions: ReportActions;
}

export interface EditorProps {
  beforeOnInit: (o: object) => void;
  afterOnInit: (editor: object) => void;
  document: string;
  id: string;
  // documentChanged should be a ref in order to have the correct context when it is used with the internal props
  documentChanged: React.RefObject<(d: string, f: boolean) => Promise<object>>;
  onDestroy: () => void;
  initialLanguage: string;
  toolbar?: string[];
  plugins?: CustomPlugin[];
  isJournal?: boolean;
}

export const baseEditorBindings = bindingsDefinition({
  afterOnInit: prop<(editor) => any>(),
  beforeOnInit: prop<(editorOptions) => any>(),
  canEdit: prop<boolean>(),
  onDestroy: prop<() => void>(),
  document: prop<any>(),
  documentChanged: prop<React.RefObject<(_document, forceSave) => any>>(),
  id: prop<string>(),

  setupEditor: prop.optional<(deps: EditorDependencies, props: EditorProps) => any>(),
  isCommentsExpanded: prop.optional<boolean>(),
  toolbar: prop.optional<string[]>(),
  plugins: prop.optional<CustomPlugin[]>(),
  isCkEditor: prop.optional<boolean>(),
  backupDocument: prop.optional<string>(),
  showBackup: prop.optional<boolean>(),
  isJournal: prop.optional<boolean>(),

  sqFroalaPlugins: injected<FroalaPluginsService>(),
  $sce: injected<ng.ISCEService>(),
  sqReportEditor: injected<ReportEditorService>(),
  sqReportActions: injected<ReportActions>(),
  $injector: injected<ng.auto.IInjectorService>(),
  $rootScope: injected<ng.IRootScopeService>(),
});

export const BaseEditor: SeeqComponent<typeof baseEditorBindings> = (props) => {
  const { sqFroalaPlugins, $sce, sqReportEditor, $injector, $rootScope, sqReportActions } =
    useInjectedBindings(baseEditorBindings);

  const { canEdit, setupEditor, isCkEditor, backupDocument, showBackup, isJournal, document } = props;
  const { t } = useTranslation();
  const userLanguage = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userLanguage);
  const language = getLanguage(userLanguage);
  const [isEditorLoaded, setIsEditorLoaded] = useState(null);
  const [editorElement, setEditorElement] = useState(null);
  const editorInstance = useRef(null);
  const isEditModeAndCanEdit = isEditWorkbookMode() && canEdit;

  const resizeEditor = ({ newWidth, newHeight }) => {
    if (isEditorLoaded) {
      editorInstance.current.resize(newHeight, newWidth);
    }
  };
  useResizeWatcher({ element: editorElement, callback: resizeEditor, callOnLoad: true }, [editorElement]);

  useEffect(() => {
    const froalaEditorInstance = setupEditor(
      {
        sqReportEditor,
        sqFroalaPlugins,
        $sce,
        t,
        $injector,
        $rootScope,
        sqReportActions,
      },
      { ...props, initialLanguage: language },
    );
    editorInstance.current = froalaEditorInstance;
    setIsEditorLoaded(true);

    return () => {
      editorInstance.current.destroy();
    };
  }, []);

  // Froala Editor only: fix for old system tests with froala editor in journal
  useEffect(() => {
    editorInstance.current.syncHtml?.(document);
  }, [document]);

  useEffect(() => {
    if (!isEditorLoaded) {
      return;
    }
    if (isEditModeAndCanEdit || isCkEditor) {
      editorInstance.current.init(isEditModeAndCanEdit);
    } else {
      editorInstance.current.destroy();
    }
  }, [isEditModeAndCanEdit, isEditorLoaded]);

  useEffect(() => {
    isEditorLoaded && editorInstance.current.handlePreview?.(showBackup);
  }, [isEditorLoaded, backupDocument, showBackup]);

  if (!isEditorLoaded) {
    return null;
  }

  editorInstance.current.setLanguage(language);

  return (
    <>
      <div id="journalEditorToolbarContainer" className={classNames({ hidden: !canEdit && isJournal && isCkEditor })} />
      <div
        className={classNames({
          flexRowContainer: !isCkEditor,
          flexFill: !isCkEditor,
          editorContainer: isCkEditor,
          // The flexBasisZero class is necessary for the froala editor in edit mode  to stay at a fixed size and
          // have a scroll bar. It's always necessary for the journal editor, but in the report editor it can mess
          // up printing in presentation mode.
          flexBasisZero: !isCkEditor && isEditModeAndCanEdit,
        })}
        ref={setEditorElement}>
        {(isEditModeAndCanEdit || isCkEditor) && <div id="journalEditor" data-testid="journalEditor" />}
        {!isEditModeAndCanEdit && !isCkEditor && (
          <ContainerWithHTML
            extraClassNames={classNames(
              'flexFillOverflow',
              'overflowYAuto',
              'msOverflowStyleAuto',
              'backgroundColorWhite',
              'p10',
              'fr-view',
              'fr-viewing',
            )}
            id="specJournalEntry"
            isBlock={true}
            content={showBackup ? backupDocument : document}
          />
        )}
      </div>
    </>
  );
};
