// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { sqLicenseManagementStore, sqWorkbenchStore } from '@/core/core.stores';
import { SEEQ_VERSION } from '@/services/buildConstants.service';
import { IS_PROTRACTOR } from '@/main/app.constants';
import { getUserTrackingData, headlessRenderMode } from '@/hybrid/utilities/utilities';
import {
  adminContactEmail,
  getFeedbackEnabled,
  isSeeqPreviewEnabled,
  isTelemetryAnonymized,
} from '@/services/systemConfiguration.utilities';

export const MOPINION_DEPLOYMENT_ID = 'PofxdKZReEC2L0ZHGVf4pmX2Hw6xfVLx7DKkjbOu';

const getFeedbackBindings = bindingsDefinition({});

/**
 * This component includes a mopinion feedback collection form as part of the application.
 */
export const GetFeedback: SeeqComponent<typeof getFeedbackBindings> = () => {
  React.useEffect(() => {
    if (getFeedbackEnabled() && !IS_PROTRACTOR && !headlessRenderMode()) {
      const contractId = _.isEmpty(sqLicenseManagementStore.license.contractNumber)
        ? 'DEV'
        : sqLicenseManagementStore.license.contractNumber;
      const { serverEmail, userName, userEmail } = getUserTrackingData({
        anonymizeTelemetry: isTelemetryAnonymized(),
        adminEmail: adminContactEmail(),
        username: sqWorkbenchStore.currentUser.username,
        userEmail: sqWorkbenchStore.currentUser.email,
      });

      // This data will be exposed globally so that we can include it as part of the feedback information collected.
      // To ensure this data is persisted as part of the feedback collection the form must contain a "block" that
      // sends website data and points to the mopinionTargetData variable.
      const mopinionTargetData = {
        contractId,
        userName,
        userEmail,
        serverEmail,
        seeqVersion: SEEQ_VERSION,
        isPreview: isSeeqPreviewEnabled(),
        language: sqWorkbenchStore.userLanguage,
      };
      window['mopinionTargetData'] = mopinionTargetData;
      addMOpinionForm(MOPINION_DEPLOYMENT_ID);
    }
  }, []);

  return null;
};

export const sqGetFeedback = angularComponent(getFeedbackBindings, GetFeedback);

// This piece of code is copy & paste from mopinion - it adds a script tag that loads the feedback forms.
export function addMOpinionForm(deploymentId) {
  const js = document.createElement('script');
  js.setAttribute('type', 'text/javascript');
  js.setAttribute('src', '//deploy.mopinion.com/js/pastease.js');
  js.setAttribute('id', 'mopinionScript');
  js.async = true;
  document.getElementsByTagName('body')[0].appendChild(js);
  const t = setInterval(function () {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Pastease not from our environment
      Pastease.load(deploymentId);
      clearInterval(t);
    } catch (e) {}
  }, 50);
}
