// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormCheck, FormGroup } from 'react-bootstrap';
import _ from 'lodash';

export class WeeklyScheduleData {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;

  constructor() {
    this.monday = false;
    this.tuesday = false;
    this.wednesday = false;
    this.thursday = false;
    this.friday = false;
    this.saturday = false;
    this.sunday = false;
  }
}

export const isWeeklyScheduleValid = ({
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
}: WeeklyScheduleData) => {
  return monday || tuesday || wednesday || thursday || friday || saturday || sunday;
};

const DAYS_TRANSLATIONS = {
  monday: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.WEEKLY.MONDAY',
  tuesday: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.WEEKLY.TUESDAY',
  wednesday: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.WEEKLY.WEDNESDAY',
  thursday: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.WEEKLY.THURSDAY',
  friday: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.WEEKLY.FRIDAY',
  saturday: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.WEEKLY.SATURDAY',
  sunday: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.WEEKLY.SUNDAY',
};

interface WeeklyScheduleBindings {
  days: WeeklyScheduleData;
  setDays: (DaysOfTheWeek) => void;
}

export const WeeklySchedule: React.FunctionComponent<WeeklyScheduleBindings> = (props) => {
  const { days, setDays } = props;
  const { t } = useTranslation();

  const toggleDay = (day) => {
    const nextDays = { ...days };
    nextDays[day] = !days[day];
    setDays(nextDays);
  };

  return (
    <FormGroup id="weeklyScheduleConfig" data-testid="weekly-schedule-config" className="ml8">
      {_.map(Object.keys(DAYS_TRANSLATIONS), (day) => {
        const id = `select-${day}`;
        return (
          <FormCheck
            id={id}
            key={day}
            data-testid={id}
            type="checkbox"
            inline={true}
            label={t(DAYS_TRANSLATIONS[day])}
            checked={days[day]}
            onChange={() => toggleDay(day)}
          />
        );
      })}
    </FormGroup>
  );
};
