import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { WorksheetDropdown } from '@/hybrid/workbooks/WorksheetDropdown.molecule';
import { BasicInfoDropdown } from '@/hybrid/workbooks/BasicInfoDropdown.molecule';

const homeScreenInfoPopoverBindings = bindingsDefinition({
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  item: prop<any>(),
  setIsOpen: prop<(isOpen: boolean) => void>(),
});

export const HomeScreenInfoDropdown: SeeqComponent<typeof homeScreenInfoPopoverBindings> = (props) => {
  const { item, setIsOpen } = props;
  const { sqHomeScreenUtilities } = useInjectedBindings(homeScreenInfoPopoverBindings);

  const isFolderOrProject = sqHomeScreenUtilities.isFolder(item) || sqHomeScreenUtilities.isProject(item);

  return (
    <>
      {isFolderOrProject ? (
        <BasicInfoDropdown item={item} setIsOpen={setIsOpen} />
      ) : (
        <WorksheetDropdown workbook={item} setIsOpen={setIsOpen} />
      )}
    </>
  );
};
