// @ts-strict-ignore
import _ from 'lodash';
import { react2angular } from 'react2angular';
import { Root, rootBindings } from '@/hybrid/core/Root';
import { bindingList } from '@/hybrid/core/bindings.util';
import React from 'react';

/**
 * Allow a component to be used in angular html templates.
 * NOTE the returned component must be included in reactComponents.module.ts
 *
 * @param bindings - the binding definition describing the component's props
 * @param Component - the implementation for the component
 * @return a component declaration that can be passed to angular.module(...).component()
 */
export function angularComponent<T extends BindingsDefinition>(bindings: T, Component: SeeqComponent<T>) {
  const propsList = bindingList('prop', bindings);
  const injectedList = bindingList('injected', rootBindings);

  const Wrapper = (props) => {
    return (
      <Root {...(_.pick(props, injectedList) as InjectedBindings<typeof rootBindings>)}>
        <Component {...(_.pick(props, propsList) as PropBindings<T>)} />
      </Root>
    );
  };

  return react2angular(Wrapper, propsList, injectedList);
}
