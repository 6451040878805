// @ts-strict-ignore
import _ from 'lodash';
import tinycolor from 'tinycolor2';
import { MouseEvent } from 'react';
import bind from 'class-autobind-decorator';
import { FormulaService } from '@/services/formula.service';
import { TableColumnFilter } from '@/hybrid/core/tableUtilities/tables';
import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { TrendActions } from '@/trendData/trend.actions';
import { getCapsuleFormula } from '@/hybrid/datetime/dateTime.utilities';
import { ScorecardStore } from '@/investigate/scorecard/scorecard.store';
import { ProcessTypeEnum } from '@/sdk/model/ThresholdMetricOutputV1';
import { getAllItems } from '@/hybrid/trend/trendDataHelper.utilities';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { METRIC_COLORS } from '@/hybrid/toolSelection/investigate.constants';
import { sqItemsApi, sqMetricsApi } from '@/sdk';
import { SearchResultUtilitiesService } from '@/hybrid/search/searchResult.utilities.service';
import { formatNumber } from '@/hybrid/utilities/numberHelper.utilities';
import { formatApiError, isPresentationWorkbookMode, validateGuid } from '@/hybrid/utilities/utilities';
import { COLUMNS_AND_STATS, ITEM_TYPES, PropertyColumn, StatisticColumn } from '@/trendData/trendData.constants';
import { isCanceled } from '@/hybrid/utilities/http.utilities';
import {
  AUTO_CLOSE_INTERVAL_LONG,
  errorToast,
  infoToast,
  successToast,
  warnToast,
} from '@/hybrid/utilities/toast.utilities';
import { cancelGroup } from '@/hybrid/requests/pendingRequests.utilities';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import i18next from 'i18next';
import {
  TableBuilderColumnType,
  TableBuilderHeaderType,
  TableBuilderMode,
} from '@/hybrid/tableBuilder/tableBuilder.constants';
import { sqDurationStore, sqTableBuilderStore, sqTrendStore, sqWorksheetStore } from '@/core/core.stores';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { priorityColors } from '@/services/systemConfiguration.utilities';

@bind
export class TableBuilderActions {
  constructor(
    private $state: ng.ui.IStateService,
    private $injector: ng.auto.IInjectorService,
    private sqScorecardStore: ScorecardStore,
    private sqWorksheetActions: WorksheetActions,
    private sqFormula: FormulaService,
  ) {}

  DATA_CANCELLATION_GROUP = 'tableBuilder';
  HOMOGENIZE_UNIT_FORMULA_EXCEPTIONS = [
    'is not compatible with',
    'non-linear, cannot convert',
    'Rows with different units are not allowed',
  ];

  /**
   * Sets the mode of the table builder
   *
   * @param mode - The mode
   */
  setMode(mode: TableBuilderMode) {
    flux.dispatch('TABLE_BUILDER_SET_MODE', { mode });
    this.fetchTable();
  }

  /**
   * Adds a column to the table that the user can use to input free-form text.
   */
  addTextColumn() {
    flux.dispatch('TABLE_BUILDER_ADD_COLUMN', {
      type: TableBuilderColumnType.Text,
    });
  }

  /**
   * Adds an item property column to the table
   *
   * @param column - The property column to add
   * @param [isCapsuleProperty] - True if this is a capsule property, false if it is a property on an item.
   */
  addPropertyColumn(column: { propertyName: string; style: string | undefined }, isCapsuleProperty = false) {
    flux.dispatch('TABLE_BUILDER_ADD_COLUMN', {
      type: isCapsuleProperty ? TableBuilderColumnType.CapsuleProperty : TableBuilderColumnType.Property,
      style: column.style,
      propertyName: column.propertyName,
    });
    this.fetchTable();
  }

  /**
   * Removes the specified column from the table
   *
   * @param key - The key that identifies the column
   */
  removeColumn(key: string) {
    if (_.has(_.find(sqTableBuilderStore.columns, { key }), 'filter')) {
      this.setColumnFilter(key, undefined);
    }
    flux.dispatch('TABLE_BUILDER_REMOVE_COLUMN', { key });
  }

  /**
   * Adds or removes a particular column into the table builder store.
   *
   * @param column - The column being toggled. One of COLUMNS_AND_STATS
   * @param [signalId] - The series if it is a statistic column for condition table
   */
  toggleColumn(column: PropertyColumn | StatisticColumn, signalId: string = null) {
    if (sqTableBuilderStore.isColumnEnabled(column, signalId)) {
      this.removeColumn(sqTableBuilderStore.getColumnKey(column, signalId));
    } else {
      const uom = COLUMNS_AND_STATS.valueUnitOfMeasure;
      const disableUnitHomogenization =
        column.key === uom.key && !_.isUndefined(sqTableBuilderStore.assetId) && sqTableBuilderStore.isHomogenizeUnits;
      if (disableUnitHomogenization) {
        infoToast({
          messageKey: 'TABLE_BUILDER.UNIT_HOMOGENIZATION_DISABLED',
        });
        this.setHomogenizeUnits(false, false);
      }

      flux.dispatch('TABLE_BUILDER_ADD_COLUMN', { column, signalId });
      this.fetchTable();
    }
  }

  /**
   * Moves the specified column to a new position
   *
   * @param key - The key that identifies the column.
   * @param newKey - The key that identifies the column that will be the new position
   */
  moveColumn(key: string, newKey: string) {
    flux.dispatch('TABLE_BUILDER_MOVE_COLUMN', { key, newKey });
  }

  isTableColumnEnabled(column: PropertyColumn | StatisticColumn, signalId: string = null) {
    return sqTableBuilderStore.isColumnEnabled(column, signalId);
  }

  /**
   * Sets the background color for a table column (header excluded).
   *
   * @param key - The key that identifies the column.
   * @param color - Background color for the column
   */
  setColumnBackground(key: string, color: string) {
    flux.dispatch('TABLE_BUILDER_SET_COLUMN_BACKGROUND', { key, color });
  }

  /**
   * Sets the text alignment for a table column (header excluded).
   *
   * @param key - The key that identifies the column.
   * @param align - CSS text-align value
   */
  setColumnTextAlign(key: string, align: string) {
    flux.dispatch('TABLE_BUILDER_SET_COLUMN_TEXT_ALIGN', { key, align });
  }

  /**
   * Sets the text color for a table column (header excluded).
   *
   * @param key - The key that identifies the column.
   * @param color - Text color
   */
  setColumnTextColor(key: string, color: string) {
    flux.dispatch('TABLE_BUILDER_SET_COLUMN_TEXT_COLOR', { key, color });
  }

  /**
   * Sets the text style for a table column (header excluded).
   *
   * @param key - The key that identifies the column.
   * @param style - zero or more text style attributes
   */
  setColumnTextStyle(key: string, style: string[]) {
    flux.dispatch('TABLE_BUILDER_SET_COLUMN_TEXT_STYLE', { key, style });
  }

  /**
   * Sets the background color for a table header.
   *
   * @param key - The key that identifies the column.
   * @param color - Background color for the header
   */
  setHeaderBackground(key: string, color: string) {
    flux.dispatch('TABLE_BUILDER_SET_HEADER_BACKGROUND', { key, color });
  }

  /**
   * Sets the text alignment for a table header.
   *
   * @param key - The key that identifies the column.
   * @param align - CSS text-align value
   */
  setHeaderTextAlign(key: string, align: string) {
    flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT_ALIGN', { key, align });
  }

  /**
   * Sets the text color for a table header.
   *
   * @param key - The key that identifies the column.
   * @param color - Text color
   */
  setHeaderTextColor(key: string, color: string) {
    flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT_COLOR', { key, color });
  }

  /**
   * Sets the text style for a table header.
   *
   * @param key - The key that identifies the column.
   * @param style - zero or more text style attributes
   */
  setHeaderTextStyle(key: string, style: string[]) {
    flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT_STYLE', { key, style });
  }

  /**
   * Applies the formatting of the specified column to all columns (headers excluded)
   * @param key - The key that identifies the column.
   */
  setStyleToAllColumns(key: string) {
    flux.dispatch('TABLE_BUILDER_SET_STYLE_TO_ALL_COLUMNS', { key });
  }

  /**
   * Applies the formatting of the specified column to all headers
   * @param key - The key that identifies the column.
   */
  setStyleToAllHeaders(key: string) {
    flux.dispatch('TABLE_BUILDER_SET_STYLE_TO_ALL_HEADERS', { key });
  }

  /**
   * Applies the formatting of the specified column&header to all columns and headers
   * @param key - The key that identifies the column.
   */
  setStyleToAllHeadersAndColumns(key: string) {
    flux.dispatch('TABLE_BUILDER_SET_STYLE_TO_ALL_HEADERS_AND_COLUMNS', {
      key,
    });
  }

  /**
   * Copies the formatting of the specified column&header.
   * @param key - The key that identifies the column.
   */
  copyStyle(key: string) {
    flux.dispatch('TABLE_BUILDER_COPY_STYLE', { key });
  }

  /**
   * Applies the copied formatting to the specified column header
   * @param key - The key that identifies the column.
   */
  pasteStyleOnHeader(key: string) {
    flux.dispatch('TABLE_BUILDER_PASTE_STYLE_ON_HEADER', { key });
  }

  /**
   * Applies the copied formatting to the specified column (header excluded)
   * @param key - The key that identifies the column.
   */
  pasteStyleOnColumn(key: string) {
    flux.dispatch('TABLE_BUILDER_PASTE_STYLE_ON_COLUMN', { key });
  }

  /**
   * Applies the copied formatting to the specified column&header
   * @param key - The key that identifies the column.
   */
  pasteStyleOnHeaderAndColumn(key: string) {
    flux.dispatch('TABLE_BUILDER_PASTE_STYLE_ON_HEADER_AND_COLUMN', { key });
  }

  /**
   * Filter a column in the Simple Table
   *
   * @param key - The key that identifies the column.
   * @param filter - filter to apply to the column
   */
  setColumnFilter(key: string, filter: TableColumnFilter) {
    flux.dispatch('TABLE_BUILDER_SET_COLUMN_FILTER', { key, filter });
    this.fetchTable();
  }

  sortByColumn(key: string, direction: string) {
    flux.dispatch('TABLE_BUILDER_SORT_BY_COLUMN', { key, direction });
    this.fetchTable();
  }

  /**
   * Sets the text for a scorecard column cell or header.
   *
   * @param key - The key that identifies the column.
   * @param text - Text for the cell
   * @param [cellKey] - The identifier for the cell. If not specified the column header text will be set.
   */
  setCellText(key: string, text: string, cellKey?: string) {
    flux.dispatch('TABLE_BUILDER_SET_CELL_TEXT', { key, text, cellKey });
  }

  /**
   * Sets the text for a table builder column header.
   *
   * @param columnKey - The key that identifies the column.
   * @param text - Text for the header
   */
  setHeaderText(columnKey: string, text: string) {
    flux.dispatch('TABLE_BUILDER_SET_HEADER_TEXT', { columnKey, text });
  }

  /**
   * Sets the header override flag for a column. Other column overrides will be disabled.
   *
   * @param columnKey - The key that identifies the column.
   */
  setHeaderOverridden(columnKey: string) {
    flux.dispatch('TABLE_BUILDER_SET_HEADER_OVERRIDE', { columnKey });
  }

  /**
   * Sets the header type for either scorecard columns that display the metric values or the name column for simple
   * tables.
   *
   * @param type - The type of header to display
   */
  setHeadersType(type: TableBuilderHeaderType) {
    flux.dispatch('TABLE_BUILDER_SET_HEADERS_TYPE', { type });
    if (type === TableBuilderHeaderType.CapsuleProperty) {
      this.fetchTable();
    }
  }

  /**
   * Sets the date format used for headers of metric value columns/name column when the type is one of the date types.
   *
   * @param format - A string that can be passed to moment's format()
   */
  setHeadersFormat(format: string) {
    flux.dispatch('TABLE_BUILDER_SET_HEADERS_FORMAT', { format });
  }

  /**
   * Sets the name of the capsule property used for headers of metric value columns when the type is CapsuleProperty.
   *
   * @param property - The capsule property name
   */
  setHeadersProperty(property: string) {
    flux.dispatch('TABLE_BUILDER_SET_HEADERS_PROPERTY', { property });
    this.fetchTable();
  }

  setIsTransposed(isTransposed: boolean) {
    flux.dispatch('TABLE_BUILDER_SET_IS_TRANSPOSED', { isTransposed });
  }

  /**
   * Sets or clears the asset id so the table can be run across all the child assets of the asset.
   *
   * @param assetId - The root asset to run the formula across or undefined to clear.
   */
  setAssetId(assetId: string | undefined) {
    flux.dispatch('TABLE_BUILDER_SET_ASSET_ID', { assetId });
    if (assetId) {
      flux.dispatch('TABLE_BUILDER_ADD_COLUMN', { column: { key: 'asset' } });
    } else {
      flux.dispatch('TABLE_BUILDER_REMOVE_COLUMN', { key: 'asset' });
    }

    this.fetchTable();
  }

  /**
   * Sets the homogenizeUnits attribute and removes the UOM column when the units are homogenized. In this case, we do
   * not want to show the UOM column. The unit of some values might be different than the unit of the item
   * @param homogenizeUnits - The homogenize units value
   * @param fetchTable - When true, it fetches the table
   * @return void if only homogenizeUnits is set. When fetch table is needed it returns a promise resolves when the
   * table has been been fetched
   */
  setHomogenizeUnits(homogenizeUnits: boolean, fetchTable = false): void | ng.IPromise<void | any[]> {
    flux.dispatch('TABLE_BUILDER_SET_HOMOGENIZE_UNITS', { homogenizeUnits });
    if (
      homogenizeUnits &&
      sqTableBuilderStore.isSimpleMode() &&
      sqTableBuilderStore.isColumnEnabled(COLUMNS_AND_STATS.valueUnitOfMeasure)
    ) {
      const key = COLUMNS_AND_STATS.valueUnitOfMeasure.key;
      flux.dispatch('TABLE_BUILDER_REMOVE_COLUMN', { key });
      infoToast({
        messageKey: 'TABLE_BUILDER.UNIT_COLUMN_REMOVED',
      });
    }

    if (fetchTable) {
      return this.fetchTable();
    }
  }

  /**
   * Changes to the specified the asset id only if a current asset is already set and the new one is different.
   *
   * @param assetId - The asset to change to
   */
  changeAssetId(assetId: string) {
    if (sqTableBuilderStore.assetId && sqTableBuilderStore.assetId !== assetId) {
      this.setAssetId(assetId);
    }
  }

  setIsMigrating(isMigrating: boolean) {
    flux.dispatch('TABLE_BUILDER_SET_IS_MIGRATING', { isMigrating });
  }

  setIsTableStriped(isTableStriped: boolean) {
    flux.dispatch('TABLE_BUILDER_SET_IS_TABLE_STRIPED', { isTableStriped });
  }

  setShowChartView(showChart: boolean) {
    flux.dispatch('TABLE_BUILDER_SET_CHART_VIEW', { enabled: showChart });
  }

  setChartViewSettings(settings: any) {
    flux.dispatch('TABLE_BUILDER_SET_CHART_VIEW_SETTINGS', {
      settings: { ...settings },
    });
  }

  /**
   * Remove the v1 metric.
   *
   * @param {string} metricId - The id of the metric
   */
  removeOldMetric(metricId) {
    flux.dispatch('SCORECARD_REMOVE_METRIC', { metricId });
  }

  /**
   * Fetches and dispatches the table data.
   *
   * @return {Promise} A promise that resolves when the table has been been fetched
   */
  fetchTable(): Promise<void | any[]> {
    if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TABLE) {
      return Promise.resolve();
    }
    cancelGroup(this.DATA_CANCELLATION_GROUP, false);
    if (this.sqScorecardStore.metrics.length) {
      return this.fetchOldMetrics();
    } else if (sqTableBuilderStore.isSimpleMode()) {
      return this.fetchSimpleTableData();
    } else {
      return this.fetchConditionTableData();
    }
  }

  /**
   * Fetches and dispatches the simple table data.
   *
   * @return A promise that resolves when the table has been been fetched
   */
  fetchSimpleTableData(): Promise<void | any[]> {
    const { formula, reduceFormula, parameters, root, columnPositions } = sqTableBuilderStore.getSimpleTableFetchParams(
      this.sqFormula,
    );
    if (_.isEmpty(formula)) {
      return Promise.resolve();
    }

    const itemIds = _.chain(parameters).values().filter(validateGuid).value();
    _.forEach(itemIds, (id) => {
      flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE);
    });

    return this.sqFormula
      .computeTable({
        formula,
        parameters,
        reduceFormula,
        root,
        cancellationGroup: this.DATA_CANCELLATION_GROUP,
        usePost: true, // Formula can be very long
      })
      .then((results) => {
        flux.dispatch(
          'TABLE_BUILDER_PUSH_SIMPLE_DATA',
          { data: results.data, headers: results.headers, columnPositions },
          PUSH_IGNORE,
        );
        _.forEach(itemIds, (id) => {
          flux.dispatch(
            'TREND_SET_DATA_STATUS_PRESENT',
            {
              id,
              warningCount: results.warningCount,
              warningLogs: results.warningLogs,
            },
            PUSH_IGNORE,
          );
        });
      })
      .then(() => this.fetchSimpleTableDistinctStringValues())
      .catch((e) => this.catchTableBuilderDataFailure(e, TableBuilderMode.Simple, sqTableBuilderStore.getTableItems()));
  }

  /**
   * Fetch the distinct string values for each string-valued column in the Simple Table.
   * Noops in presentation mode since the filters can't be changed.
   */
  fetchSimpleTableDistinctStringValues(): Promise<void> {
    if (isPresentationWorkbookMode()) {
      return Promise.resolve();
    }

    const { fetchParamsList, columnKeysNamesList } = sqTableBuilderStore.getSimpleTableStringColumnsFetchParams();
    return Promise.all(
      _.map(fetchParamsList, (tableFetchParam) =>
        this.sqFormula.computeTable({
          formula: tableFetchParam.formula,
          parameters: tableFetchParam.parameters,
          reduceFormula: tableFetchParam.reduceFormula,
          root: tableFetchParam.root,
          cancellationGroup: this.DATA_CANCELLATION_GROUP,
          usePost: true, // Formula can be very long
        }),
      ),
    ).then((stringValueTables) => {
      flux.dispatch('TABLE_BUILDER_SET_SIMPLE_DISTINCT_STRING_VALUES', {
        stringValueTables,
        columnKeysNamesList,
      });
    });
  }

  /**
   * Fetches and dispatches the condition table data.
   *
   * @return A promise that resolves when the table has been been fetched
   */
  fetchConditionTableData(): Promise<void | any[]> {
    const {
      ids: itemIds,
      assetId,
      propertyColumns,
      statColumns,
      customPropertyName,
      reduceFormula,
      sortParams,
      buildAdditionalFormula,
      itemColumnsMap,
      buildConditionFormula,
      buildStatFormula,
    } = sqTableBuilderStore.getConditionTableFetchParams(this.sqFormula);

    if (_.isEmpty(itemIds)) {
      return Promise.resolve();
    }

    _.forEach(itemIds, (id) => {
      flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE);
    });

    return this.sqFormula
      .computeCapsuleTable({
        columns: { propertyColumns, statColumns },
        range: sqDurationStore.displayRange,
        itemIds,
        buildConditionFormula,
        sortParams,
        root: assetId,
        reduceFormula,
        buildAdditionalFormula,
        buildStatFormula,
        offset: 0,
        limit: 10000,
        cancellationGroup: this.DATA_CANCELLATION_GROUP,
      })
      .then(({ warningCount, warningLogs, data: { headers, table } }) => {
        flux.dispatch(
          'TABLE_BUILDER_PUSH_CONDITION_DATA',
          { headers, table, itemColumnsMap, customPropertyName },
          PUSH_IGNORE,
        );
        _.forEach(itemIds, (id) => {
          flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', { id, warningCount, warningLogs }, PUSH_IGNORE);
        });
      })
      .catch((e) =>
        this.catchTableBuilderDataFailure(e, TableBuilderMode.Condition, sqTableBuilderStore.getTableItems()),
      );
  }

  /**
   * Performs all necessary steps to execute all v1 metrics using the current display range.
   *
   * @return Promise that resolves when the cell is computed
   */
  fetchOldMetrics(): Promise<void> {
    return (
      _.chain(this.sqScorecardStore.metrics)
        .map((metric) => {
          const statFragment = this.$injector
            .get<CalculationRunnerService>('sqCalculationRunner')
            .getStatisticFragment(metric.stat);
          const formula = `$series.aggregate(${statFragment}, ${getCapsuleFormula(sqDurationStore.displayRange)})`;
          return this.sqFormula.computeScalar({ formula, parameters: { series: metric.itemId } }).then((result) => {
            const payload = _.assign(
              {
                metricId: metric.metricId,
                valueResult: `${formatNumber(result.value)} ${result.uom}`,
              },
              this.computeColorForOldMetric(metric, result.value),
            );

            flux.dispatch('SCORECARD_VALUE_RESULT', payload);
          });
        })
        .thru((promises) => Promise.all(promises))
        .value()
        // Noop at the end so we have a void return type
        .then(() => {})
    );
  }

  /**
   * Compute the color for a given value. This finds the maximum threshold value that is less or equal to the value,
   * and returns that. It also computes the contrasting color for the foreground.
   *
   * @param {Object} metric - The metric object
   * @param {Object} metric.thresholds - Array of threshold objects
   * @param {Number} value - The value to choose colors for
   * @returns {{backgroundColor: string, foregroundColor: string}} - Map of background and foreground colors
   */
  computeColorForOldMetric(metric, value) {
    const color = (
      _.chain(metric.thresholds)
        .filter(function (t: any) {
          return !_.isUndefined(t.isMinimum) || t.threshold <= value;
        })
        .head() as any
    )
      .get('color', '#ddd')
      .value();
    return {
      backgroundColor: color,
      foregroundColor: tinycolor(color).isDark() ? '#fff' : '#000',
    };
  }

  /**
   * Displays a metric on the trend with a specific time region of the chart highlighted. It also takes care of
   * swapping to the new asset if the table is going across assets and the row that the user is clicking on is from a
   * different asset than the one currently being shown.
   *
   * @param metricId - The metric identifier
   * @param itemId - The id of the actual item in the row. This can be different from the metricId when swapping
   * @param start - The start time of the window to highlight
   * @param end - The end time of the window to highlight
   * @param event - The angular click event
   */
  displayMetricOnTrend(metricId: string, itemId: string, start: number, end: number, event: MouseEvent): Promise<any> {
    if ((event.view as any)?.getSelection().toString().length > 0) {
      return Promise.resolve(); // noop if the user is selecting the value
    }

    let metric = _.find(
      getAllItems({
        itemTypes: [ITEM_TYPES.METRIC],
      }),
      { id: metricId },
    );
    const isItemPresent = _.some(getAllItems({}), { id: itemId });
    let promise;
    if (!sqTableBuilderStore.assetId || isItemPresent) {
      promise = Promise.resolve();
    } else {
      // User clicked on a metric whose item is not in the details pane, so swap to its parent
      promise = this.sqFormula.getDependencies({ id: itemId }).then(({ assets }) => {
        if (assets.length && assets[0].pathComponentIds.length) {
          return this.$injector
            .get<SearchResultUtilitiesService>('sqSearchResultService')
            .swapAsset({ id: _.last(assets[0].pathComponentIds) })
            .then(() => {
              // Actual item clicked should now be in the details pane
              metric = _.find(getAllItems({ itemTypes: [ITEM_TYPES.METRIC] }), { id: itemId });
            });
        }
      });
    }

    return promise.then(() => {
      const sqTrendActions = this.$injector.get<TrendActions>('sqTrendActions');
      this.sqWorksheetActions.setView(WORKSHEET_VIEW.TREND);

      const isSimpleMetric = _.get(metric, 'definition.processType') === ProcessTypeEnum.Simple;
      const isBatchMetric = _.get(metric, 'definition.processType') === ProcessTypeEnum.Condition;
      const boundingCondition = _.get(metric, 'definition.boundingCondition', {});
      if (isBatchMetric && boundingCondition.id) {
        sqTrendActions.addItem(boundingCondition).then((bc) => sqTrendActions.setItemSelected(bc, true));
      }

      _.forEach(getAllItems({}), (item) => sqTrendActions.setItemSelected(item, item.id === metric.id));

      if (isSimpleMetric) {
        sqTrendActions
          .addItem(metric.definition.measuredItem)
          .then(() => sqTrendActions.alignMeasuredItemWithMetric(metric));
      }

      if (
        sqDurationStore.displayRange.start.valueOf() !== start ||
        sqDurationStore.displayRange.end.valueOf() !== end
      ) {
        sqTrendActions.setSelectedRegion(start, end);
      }

      if (!sqTrendStore.hideUnselectedItems) {
        sqTrendActions.toggleHideUnselectedItems();
      }
    });
  }

  /**
   * Migrates old scorecard to be backend threshold metric items.
   */
  migrate() {
    this.setIsMigrating(true);
    this.setMode(TableBuilderMode.Simple);
    this.setHeadersType(TableBuilderHeaderType.None);
    this.removeColumn(COLUMNS_AND_STATS['statistics.average'].key);
    this.toggleColumn(COLUMNS_AND_STATS.metricValue);
    _.chain(this.sqScorecardStore.metrics)
      .map((metric: any) =>
        this.getName(metric)
          .then((name) =>
            sqMetricsApi.createThresholdMetric({
              name,
              measuredItem: metric.itemId,
              aggregationFunction: this.$injector
                .get<CalculationRunnerService>('sqCalculationRunner')
                .getStatisticFragment(metric.stat),
              thresholds: this.getThresholds(metric),
            }),
          )
          .then(({ data: item }) => {
            this.removeOldMetric(metric.metricId);
            return item;
          })
          .catch((error) => {
            errorToast({ httpResponseOrError: error });
          }),
      )
      .thru((promises) => Promise.all(promises))
      .value()
      // Important that they are added in order to preserve the sort
      .then((items) =>
        Promise.all(
          _.map(items, (item) => {
            const promise = this.$injector.get<TrendActions>('sqTrendActions').addItem(item);
            this.$injector.get<TrendActions>('sqTrendActions').setItemSelected(item, true);
            return promise;
          }),
        ),
      )
      .finally(() => {
        this.setIsMigrating(false);
        successToast(
          {
            messageKey: 'TABLE_BUILDER.MIGRATION_SUCCESS',
          },
          { autoClose: AUTO_CLOSE_INTERVAL_LONG },
        );
      });
  }

  /**
   * Returns a name for the metric. Specifically handles the case of metric that had an empty name. It is not
   * guaranteed to be unique. Instead, since the backend does not enforce uniqueness, it assumes the user will figure
   * out the best way to disambiguate duplicate names when they edit it.
   *
   * @param {Object} metric - The metric
   * @return {Promise<String>} - Promise that resolves with a name for the metric
   */
  getName(metric) {
    return Promise.resolve(_.trim(metric.name))
      .then((name) => {
        if (_.isEmpty(name)) {
          return sqItemsApi.getItemAndAllProperties({ id: metric.itemId }).then(({ data: item }) => {
            const statTitle = i18next.t(
              _.get(_.find(SAMPLE_FROM_SCALARS.VALUE_METHODS, ['key', _.get(metric.stat, 'key')]), 'title'),
            );
            return `${statTitle} ${item.name}`;
          });
        } else {
          return name;
        }
      })
      .then((name) => {
        return this.sqFormula.getDefaultName(name, this.$state.params.workbookId);
      })
      .then((defaultName) => {
        // getDefaultName name will add a suffix, but if that suffix is 1 the name is unique so we don't need the
        // number
        if (_.endsWith(defaultName, ' 1')) {
          return defaultName.substr(0, defaultName.length - 2);
        } else {
          return defaultName;
        }
      });
  }

  /**
   * Gets the thresholds for a metric, mapped to the new priority levels. Makes no assumptions that the colors are
   * the same, but instead makes the assumption that the user ordered their metrics with the same priority order as
   * the order presented by METRIC_COLORS. It has the following known limitations:
   *  - It can assign the same level to two different thresholds if there is no corresponding new priority. This
   *  could happen, for example, if user used both the green and blue as thresholds, since blue was removed in the
   *  new scorecard.
   *  - If the thresholds have the colors in a random order, that order will not be preserved since the order cannot
   *  be changed for the new priorities.
   *  - If the user defined more thresholds than the number of new priorities then some of the old thresholds will
   *  be lost.
   *
   * @param {Object} metric - The metric
   * @return {String[]} Array of thresholds in the format of priorityLevel=value
   */
  getThresholds(metric): string[] {
    const highPriorities = _.filter(priorityColors(), (priority) => priority.level > 0);
    const lowercaseMetricColors = _.map(METRIC_COLORS, _.toLower);
    const priorityConversionMap = _.chain(lowercaseMetricColors)
      .initial() // discard the last color (white) since neutral is not included in priorities
      .reverse() // reverse it so that the indices correspond with the priority levels
      .transform((result, color, i) => {
        // Use the index to find the corresponding priority. Green's index is zero and with this algorithm that
        // means it ends up as the neutral priority, but that is ok since R21 moved green to a neutral color. The
        // rest of the colors will then map to their new corresponding priority level.
        result[color] = _.get(_.find(highPriorities, { level: i }), 'level', _.last(highPriorities).level);
      }, {} as { [s: string]: number })
      .value();

    // Needed because some old scorecards somehow have some of their colors as strings instead of hex codes
    const thresholds = _.map(metric.thresholds, (threshold: any) => {
      // yellow yields a different hex code
      const colorObj = threshold.color === 'yellow' ? tinycolor(METRIC_COLORS[1]) : tinycolor(threshold.color);
      const color = colorObj.isValid() ? colorObj.toHexString() : threshold.color;
      return { ...threshold, color };
    });

    return _.chain(thresholds)
      .transform((result, threshold: any, i) => {
        // Last one will not have a threshold value
        if (i === thresholds.length - 1) {
          return;
        }

        const currentColorIndex = _.indexOf(lowercaseMetricColors, threshold.color);
        const nextColorIndex = _.indexOf(lowercaseMetricColors, thresholds[i + 1].color);
        // Can tell if the priorities are high or low since the old METRIC_COLORS went from high to low
        const isHigh = currentColorIndex <= nextColorIndex;
        const color = lowercaseMetricColors[isHigh ? currentColorIndex : nextColorIndex];
        // White thresholds that were not used as neutral will not be in the map
        if (_.has(priorityConversionMap, color)) {
          const level = priorityConversionMap[color] * (isHigh ? 1 : -1);
          result.push([level, threshold.threshold]);
        }
      }, [])
      .uniqBy(_.head)
      .map(([level, threshold]) => `${level}=${threshold}`)
      .value();
  }

  /**
   * Error handler for when fetching the table data fails. It checks to see if changing the homogenize units setting
   * would help. Otherwise, it tries to determine which items that were used in the table builder formula are
   * causing the problem by fetching each one individually. This is expensive, but there's no other good way to
   * figure it out.
   *
   * @param error - The error that arose from fetching the data
   * @param mode - The current table builder mode
   * @param items - The items that were used to generate the table builder formula
   */
  catchTableBuilderDataFailure(error: any, mode: TableBuilderMode, items: any[]) {
    let apiMessage;
    let fetchFailedMessage = formatApiError(error).replace(/\((GET|POST) .*?\)/, '');
    const isHomogenizeError = this.HOMOGENIZE_UNIT_FORMULA_EXCEPTIONS.some((err) => fetchFailedMessage.includes(err));
    const isAssetError = fetchFailedMessage.includes('asset');
    const shouldFallbackToUnitless = !!sqTableBuilderStore.assetId && isHomogenizeError;

    if (shouldFallbackToUnitless) {
      warnToast({
        messageKey: 'TABLE_BUILDER.INCOMPATIBLE_UNITS_ACROSS_ASSETS',
        messageParams: { message: fetchFailedMessage },
      });
      flux.dispatch('TABLE_BUILDER_ADD_COLUMN', {
        column: COLUMNS_AND_STATS.valueUnitOfMeasure,
      });
      return this.setHomogenizeUnits(false, true);
    } else {
      const sqTrendActions = this.$injector.get<TrendActions>('sqTrendActions');
      let checkFailureForEachItem = false;
      if (isCanceled(error)) {
        fetchFailedMessage = undefined; // Request will be retried so don't flash an error message
      } else {
        if (_.isEmpty(fetchFailedMessage)) {
          fetchFailedMessage = i18next.t('LOGIN_PANEL.FRONTEND_ERROR');
        } else if (fetchFailedMessage.includes('must all be from a single asset')) {
          fetchFailedMessage += `\n\n${i18next.t('TABLE_BUILDER.REMOVE_ITEMS_SAME_ASSET')}`;
        } else if (!isAssetError && !isHomogenizeError) {
          // Sometimes we hit errors due to unit mismatches in the table data, so we provide the API error message in
          // addition to our fetch error message to give more information to the user
          apiMessage = fetchFailedMessage;
          fetchFailedMessage = i18next.t('TABLE_BUILDER.FETCH_ERROR');
          checkFailureForEachItem = true;
        }
      }

      if (checkFailureForEachItem && !isPresentationWorkbookMode()) {
        _.forEach(items, (item) => {
          this.sqFormula
            .canFetchData(item, sqDurationStore.displayRange, this.DATA_CANCELLATION_GROUP)
            .then(() => {
              flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', { id: item.id });
            })
            .catch((e) => sqTrendActions.catchItemDataFailure(item.id, this.DATA_CANCELLATION_GROUP, e));
        });
      } else {
        _.forEach(items, (item) => sqTrendActions.catchItemDataFailure(item.id, this.DATA_CANCELLATION_GROUP, error));
      }

      flux.dispatch('TABLE_BUILDER_SET_FETCH_FAILED_MESSAGE', {
        fetchFailedMessage,
        mode,
        apiMessage,
      });
    }
  }
}
