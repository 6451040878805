// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { ExplorerModalBody } from '@/hybrid/explorer/ExplorerModalBody.molecule';
import { ReportContentProperties } from '@/hybrid/reportEditor/components/reportContentModal/edit/ReportContentProperties.molecule';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { ReportContentStore } from '@/reportEditor/reportContent.store';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ReportActions } from '@/reportEditor/report.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { SelectWorksheet } from '@/hybrid/worksheets/SelectWorksheet.molecule';
import { WizardModal } from '@/hybrid/core/WizardModal.molecule';
import { ReportContentUrl } from '@/hybrid/reportEditor/components/reportContentModal/shared/ReportContentUrl.molecule';
import { SelectWorksheetHeader } from '@/hybrid/header/SelectWorksheetHeader.atom';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { CONTENT_STATE } from '@/reportEditor/report.constants';
import { ExploreWorkbookModalActions } from '@/hybrid/explorer/explorer.constants';
import { doTrack } from '@/track/track.service';
import { sqExploreWorkbookModalStore } from '@/core/core.stores';

const reportContentModalBindings = bindingsDefinition({
  sqReportEditor: injected<ReportEditorService>(),
  sqReportContentStore: injected<ReportContentStore>(),
  sqExploreWorkbookModalActions: injected<ExploreWorkbookModalActions>(),
  sqReportContentActions: injected<ReportContentActions>(),
  sqReportActions: injected<ReportActions>(),
  sqReportContent: injected<ReportContentService>(),
});

export const ReportContentModal: SeeqComponent<typeof reportContentModalBindings> = () => {
  const {
    sqReportContentStore,
    sqExploreWorkbookModalActions,
    sqReportEditor,
    sqReportContentActions,
    sqReportActions,
    sqReportContent,
  } = useInjectedBindings(reportContentModalBindings);

  const { workbookId } = useFlux(sqExploreWorkbookModalStore);
  const { modalName, sourceUrl } = useFlux(sqReportContentStore);

  const [evaluatingVisualizationOptions, setEvaluatingVisualizationOptions] = useState(false);
  const offset = sqReportEditor.getScrollOffset();
  const [allowedTypes] = useState([SeeqNames.Types.Folder, SeeqNames.Types.Analysis]);
  const [stepChanged, setStepChanged] = useState(false);
  const [worksheetIds, setWorksheetIds] = useState([]);
  const [workstepIds, setWorkstepIds] = useState([]);
  const [totalWorksheets, setTotalWorksheets] = useState(-1);
  const [contentItems, setContentItems] = useState([] as ReportContentStore[]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (modalName) {
      if (modalName === CONTENT_STATE.LOAD_PROPERTIES) {
        setContentItems([{ ...sqReportContentStore }]);
        sqReportContentActions.setModalName(CONTENT_STATE.PROPERTIES);
      } else {
        sqReportEditor.saveSelection();
      }
    }
  }, [modalName]);

  const onClose = () => {
    sqReportEditor.setScrollOffset(offset);
    sqReportEditor.restoreSelection();
    sqExploreWorkbookModalActions.clear();
    sqReportContentActions.clear();
    setIsSaving(false);
    sqReportContentActions.setModalName('');
    setWorksheetIds([]);
    setWorkstepIds([]);
    setContentItems([]);
  };

  /**
   * Inserts or replaces content in the report
   */
  const save = () => {
    setIsSaving(true);
    sqReportEditor.restoreSelection();

    return _.chain(contentItems)
      .map((contentItem) =>
        sqReportActions.saveContent({ ...contentItem, isArchived: false }).catch((error) => {
          errorToast({ httpResponseOrError: error });
        }),
      )
      .thru((promises) => Promise.all(promises))
      .value()
      .then((savedContentItems) => {
        _.chain(savedContentItems)
          .compact()
          .forEach((content: any) => {
            sqReportContent.insertOrReplaceContent(content.id);
            sqReportEditor.setScrollOffset(offset);
            onClose();
          })
          .value();
      });
  };

  const protectedSave = () => {
    setIsSaving(true);
    return save();
  };

  const proceedToProperties = () => {
    sqReportContentActions.setWorkbookId(workbookId);
    sqReportContentActions.setWorksheetId(_.first(worksheetIds));
    sqReportContentActions.setWorkstepId(_.first(workstepIds));
    setEvaluatingVisualizationOptions(true);

    return _.reduce(
      worksheetIds,
      (promise, worksheetId, i) =>
        promise.then((content) =>
          sqReportContent
            .evaluateVisualizationOptions(workbookId, worksheetId, workstepIds[i])
            .catch((error) => {
              errorToast({ httpResponseOrError: error });
            })
            .then(() => {
              content.push({
                ...sqReportContentStore,
                workbookId,
                worksheetId,
                workstepId: workstepIds[i],
              });
              return content;
            }),
        ),
      Promise.resolve([]),
    )
      .then((content) => {
        setContentItems(content);
      })
      .finally(() => {
        doTrack('Topic', 'Seeq-Link - Worksheet', 'Worksheet selected');
        setEvaluatingVisualizationOptions(false);
      });
  };

  const urlStepDefinitions = [
    {
      title: 'REPORT.CONTENT.LINK',
      component: <ReportContentUrl />,
      actionButtonLabel: 'USE',
      actionFn: () => {
        setContentItems([{ ...sqReportContentStore }]);
        sqReportContentActions.setWorkbookId(workbookId);
        doTrack('Topic', 'Seeq-Link - Worksheet', 'Analysis selected');
      },
      valid: !_.isEmpty(sourceUrl),
      modalClassName: 'report-content-modal',
    },
  ];

  const workbookStepDefinitions = [
    {
      title: 'REPORT.CONTENT.SELECT_WORKBOOK',
      component: (
        <ExplorerModalBody
          show={true}
          next={() => doTrack('Topic', 'Seeq-Link - Worksheet', 'Analysis selected')}
          store={sqExploreWorkbookModalStore}
          actions={sqExploreWorkbookModalActions}
          allowedTypes={allowedTypes}
        />
      ),
      shouldGoToNext: { canGoToNext: !!workbookId, showActionButton: false },
      modalClassName: 'report-content-modal',
    },
    {
      title: 'REPORT.CONTENT.SELECT_WORKSHEET',
      customHeader: (
        <SelectWorksheetHeader
          title="REPORT.CONTENT.SELECT_WORKSHEET"
          workbookId={workbookId}
          worksheetIds={worksheetIds}
          setWorksheetIds={setWorksheetIds}
          workstepIds={workstepIds}
          setWorkstepIds={setWorkstepIds}
          totalWorksheets={totalWorksheets}
          setTotalWorksheets={setTotalWorksheets}
          isAllSelectedOnStartup={false}
        />
      ),
      component: (
        <SelectWorksheet
          workbookId={workbookId}
          worksheetIds={worksheetIds}
          setWorksheetIds={setWorksheetIds}
          setWorkstepIds={setWorkstepIds}
          setTotalWorksheets={setTotalWorksheets}
          workstepIds={workstepIds}
          isLoading={evaluatingVisualizationOptions}
        />
      ),
      actionButtonLabel: 'NEXT',
      shouldGoToNext: { showActionButton: true },
      actionFn: () => proceedToProperties(),
      valid: worksheetIds.length > 0 && workstepIds.length > 0,
      backButtonFn: () => {
        sqExploreWorkbookModalActions.setWorkbookId(undefined);
        sqReportContentActions.setWorksheetId(undefined);
        sqReportContentActions.setWorkstepId(undefined);
        setWorksheetIds([]);
        setWorkstepIds([]);
        setStepChanged(!stepChanged);
      },
      modalClassName: 'report-content-modal',
      footerText: 'REPORT.CONTENT.SELECT_WORKSHEET_NEXT',
    },
  ];

  const propertiesStepDefinitions = [
    {
      title: 'REPORT.CONTENT.SET_PROPERTIES',
      component: <ReportContentProperties contentItems={contentItems} setContentItems={setContentItems} />,
      actionButtonLabel: 'INSERT',
      actionFn: () => {
        doTrack('Topic', 'Seeq-Link - Worksheet', 'Worksheet inserted');
        return protectedSave();
      },
      valid: !isSaving,
      modalClassName: 'report-content-modal',
    },
  ];

  const stepDefinitions = {
    [CONTENT_STATE.WORKBOOK]: [...workbookStepDefinitions, ...propertiesStepDefinitions],
    [CONTENT_STATE.LINK]: [...urlStepDefinitions, ...propertiesStepDefinitions],
    [CONTENT_STATE.PROPERTIES]: propertiesStepDefinitions,
  };

  return (
    modalName &&
    modalName !== CONTENT_STATE.LOAD_PROPERTIES && (
      <WizardModal
        stepDefinitions={stepDefinitions[modalName]}
        onClose={() => {
          doTrack('Topic', 'Seeq-Link }', 'cancel');
          onClose();
        }}
        testId="report-content-modal"
      />
    )
  );
};

export const sqReportContentModal = angularComponent(reportContentModalBindings, ReportContentModal);
