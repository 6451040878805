import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import React from 'react';

type ItemUsages = {
  showUsagesAndTrack: () => void;
};

export const ItemUsages: React.FunctionComponent<ItemUsages> = ({ showUsagesAndTrack }) => {
  const { t } = useTranslation();

  return (
    <div className="flexRowContainer propList__item" data-testid="itemUsages">
      <label className="mb0 pl3 pt3">{t('SEARCH_DATA.USAGES')}</label>
      <div className="pl10 pb5">
        <span>{t('SEARCH_DATA.SEARCH_USAGES_MESSAGE')}</span>
        <Icon
          testId="showUsagesAndTrack"
          icon="fc-usage"
          onClick={showUsagesAndTrack}
          tooltip="SEARCH_DATA.USAGES_TOOLTIP"
          tooltipPlacement="right"
          small={true}
          extraClassNames="ml5 mr2 text-interactive"
        />
        <span
          data-testid="showUsagesAndTrackText"
          className="sq-text-primary link-underline-hover cursorPointer"
          onClick={showUsagesAndTrack}>
          {t('SEARCH_DATA.SEARCH_USAGES_LINK')}
        </span>
      </div>
    </div>
  );
};
