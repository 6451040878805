// @ts-strict-ignore
import React from 'react';
import Select, { components } from 'react-select';
import _ from 'lodash';
import { Timezone } from '@/datetime/timezone.service';
import { sqTimezones } from '@/hybrid/utilities/datetime.constants';

interface TimeZoneSelectorProps {
  /** Selected time zone */
  timezone: Timezone;
  /** Alignment for dropdown */
  direction?: 'top' | 'bottom' | 'auto';
  /** Specify True to make selection of a time zone optional */
  optional?: boolean;
  /** Function to call when time zone is selected */
  onSelect: (any) => void;
  /** Specify True to present time zone selector as disabled */
  disabled?: boolean;
  /** CSS class names to apply to parent element around <select> */
  extraClassNames?: string;
  /** CSS class name to apply to <select> element */
  selectClassName?: string;
  /** Test ID */
  testId?: string;
}

/**
 * Searchable dropdown selector for selecting a time zone
 */
export const TimeZoneSelectorUnwrapped: React.FunctionComponent<TimeZoneSelectorProps> = (props) => {
  const {
    timezone,
    direction = 'auto',
    optional = false,
    onSelect,
    disabled = false,
    extraClassNames = '',
    selectClassName,
    testId,
  } = props;

  const Menu = (props) => <components.Menu className="timeZoneMenu" {...props} />;

  const groupedZones: any[] = _.chain(sqTimezones.timezones)
    .map((zone) => ({ value: zone, label: zone.displayName }))
    .sortBy('label')
    .groupBy((option) => option.value.offsetMinutes)
    .map((options: any[]) => ({ label: options[0].value.offset, options }))
    .sortBy((group) => group.options[0].value.offsetMinutes)
    .value();

  return (
    <div id="timeZoneSelector" data-testid={testId || 'timeZoneSelector'} className={extraClassNames}>
      <Select
        value={{ label: timezone?.displayName, value: timezone }}
        options={groupedZones}
        className={selectClassName ?? ''}
        classNamePrefix={`${selectClassName ?? ''} timeZoneSelector react-select`}
        components={{ Menu }}
        isClearable={optional}
        onChange={(newZone) => onSelect(newZone?.value)}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
        menuPlacement={direction}
        isDisabled={disabled}
      />
    </div>
  );
};

export const TimeZoneSelector = React.memo(
  TimeZoneSelectorUnwrapped,
  (prev, next) =>
    !(
      !_.isEqual(prev.timezone, next.timezone) ||
      prev.disabled !== next.disabled ||
      prev.extraClassNames !== next.extraClassNames
    ),
);

export default TimeZoneSelector;
