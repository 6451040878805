// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { HoverTooltip } from './HoverTooltip.atom';

interface UnitOfMeasureProps {
  unitOfMeasure: { value: any; isRecognized: boolean };
  isStandalone?: boolean;
}

/**
 * Renders the unit of measure if it is available. If the unit is rendered "standalone" no wrapping parenthesis
 * are included. If an unknown unit of measure is found it is displayed in italics and slightly transparent,
 * opaque on hover.
 */
export const UnitOfMeasure: React.FunctionComponent<UnitOfMeasureProps> = (props) => {
  const { unitOfMeasure, isStandalone } = props;
  if (_.isEmpty(unitOfMeasure) || !unitOfMeasure.value) {
    return null;
  }

  const style = classNames('inline', {
    'text-italic': !unitOfMeasure.isRecognized,
    'text-opaque-onhover': !unitOfMeasure.isRecognized,
  });

  return (
    <HoverTooltip text={unitOfMeasure.isRecognized ? null : 'UNRECOGNIZED_UNIT'} placement="top">
      <span className={style} data-testid="unitOfMeasure">
        {!isStandalone && '('}
        {unitOfMeasure.value}
        {!isStandalone && ')'}
      </span>
    </HoverTooltip>
  );
};
