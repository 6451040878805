// @ts-strict-ignore
import { APP_STATE, HOME_SCREEN_TABS, SEARCH_ITEM_LOCATIONS, USERS_FOLDER_NAME } from '@/main/app.constants';
import _ from 'lodash';
import { sqFoldersApi } from '@/sdk/api/FoldersApi';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { emitWorkbooks, onWorkbooks } from '@/services/notifier.service';
import { formatTime } from '@/hybrid/datetime/dateTime.utilities';
import { addWorksheetToWorkbook as addWorksheetToWorkbookAction, loadWorkbook } from '@/workbook/workbook.actions';
import { equalsIgnoreCase } from '@/hybrid/utilities/utilities';
import { cancelAll } from '@/hybrid/requests/pendingRequests.utilities';
import { flux } from '@/core/flux.module';
import { PUSH_WORKBENCH } from '@/core/flux.service';
import i18next from 'i18next';
import {
  HelpDisplay,
  HOME_SCREEN_CANCELLATION_GROUP,
  HOME_SCREEN_SORT,
  HOME_SCREEN_TABLE_TYPE,
  ITEM_TYPES,
} from '@/hybrid/homescreen/homescreen.constants';
import { sqHomeScreenStore, sqWorkbenchStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import moment from 'moment';
import { canModifyWorkbook } from '@/services/authorization.service';

export type HomeScreenActions = ReturnType<typeof sqHomeScreenActions>;

export function sqHomeScreenActions($state: ng.ui.IStateService, sqHomeScreenUtilities: HomeScreenUtilitiesService) {
  const service = {
    setCurrentTab,
    setCurrentFolder,
    loadFolder,
    resetStore,
    mergeSubfolderTree,
    expandFolder,
    collapseFolder,
    loadFolderContents,
    getSortedTableContents,
    clearBreadcrumbs,
    setTableSort,
    loadSearchTable,
    clearSearchResults,
    setSearchParams,
    setIsExact,
    setPageNumber,
    setPageNumberAndGo,
    setTabFolder,
    addFolder,
    addWorkbook,
    addProject,
    findOrAddWorkbook,
    canCreateItemInFolder,
    removeWorkbook,
    restoreWorkbook,
    // exposed for testing
    clearItems,
    loadRecentTable,
    loadPinnedTable,
    loadFolderTree,
    loadTabTable,
    setPageSizeForTable,
    setHelpDisplay,
    setDisplayedAddOnIdentifier,
    clearDisplayedAddOnIdentifier,
    setupNotifierForHomescreen,
  };

  return service;

  function setCurrentTab(tab) {
    flux.dispatch('SET_CURRENT_HOME_SCREEN_TAB', { tab }, PUSH_WORKBENCH);
  }

  function setCurrentFolder(folderId) {
    flux.dispatch('SET_CURRENT_HOME_SCREEN_FOLDER', { folderId }, PUSH_WORKBENCH);
  }

  function addExpandedFolderIds(expandedFolderIds) {
    flux.dispatch('ADD_EXPANDED_FOLDER_IDS', { expandedFolderIds });
  }

  function removeExpandedFolderIds(collapsedFolderIds) {
    flux.dispatch('REMOVE_EXPANDED_FOLDER_IDS', { collapsedFolderIds });
  }

  function mergeSubfolderTree(tree) {
    flux.dispatch('MERGE_HOME_SCREEN_FOLDER_TREE', { tree });
  }

  function setTableSort(sortProperty, sortAsc, table) {
    flux.dispatch('SET_HOME_SCREEN_TABLE_SORT', { sortProperty, sortAsc, table }, PUSH_WORKBENCH);
  }

  function clearBreadcrumbs() {
    flux.dispatch('CLEAR_BREADCRUMBS');
  }

  function clearItems() {
    flux.dispatch('CLEAR_HOME_SCREEN_ITEMS');
  }

  function setPageSizeForTable({ table, size }) {
    flux.dispatch('SET_PAGE_SIZE_FOR_TABLE', { table, size }, PUSH_WORKBENCH);
  }

  function setSearchParams(searchParams) {
    flux.dispatch('SET_HOME_SCREEN_SEARCH_PARAMS', { searchParams });
  }

  function setIsExact(isExact) {
    flux.dispatch('SET_HOME_SCREEN_SEARCH_IS_EXACT', { isExact });
  }

  function setPageNumber(pageNumber, table) {
    flux.dispatch('SET_HOME_SCREEN_PAGE_NUMBER', { pageNumber, table });
  }

  function setPageNumberAndGo(pageNumber, loadTable, table) {
    setPageNumber(pageNumber, table);
    loadTable(HOME_SCREEN_TABLE_TYPE.SEARCH ? sqHomeScreenStore.searchParams : null);
  }

  function loadPinnedTable() {
    const table = HOME_SCREEN_TABLE_TYPE.PINNED;
    const pinnedSort = sqHomeScreenStore.getSortForTable(HOME_SCREEN_TABLE_TYPE.PINNED);
    const searchParams = {
      sortOrder: `${pinnedSort.sortProperty} ${pinnedSort.sortAsc ? 'asc' : 'desc'}`,
      onlyPinned: true,
    };
    return loadTable({ searchParams, table });
  }

  function loadRecentTable() {
    const table = HOME_SCREEN_TABLE_TYPE.RECENT;
    const lruSort = sqHomeScreenStore.getSortForTable(HOME_SCREEN_TABLE_TYPE.RECENT);
    const searchParams = {
      sortOrder: `${lruSort.sortProperty} ${lruSort.sortAsc ? 'asc' : 'desc'}`,
    };

    return loadTable({ searchParams, table });
  }

  function loadTabTable() {
    const table = HOME_SCREEN_TABLE_TYPE.TAB;
    const tableSort = sqHomeScreenStore.getSortForTable(table);
    const searchParams = getSearchParams({
      currentTab: sqHomeScreenStore.currentTab,
      folderId: sqHomeScreenStore.currentFolderId,
      sortProperty: tableSort.sortProperty,
      sortAsc: tableSort.sortAsc,
    });
    return loadTable({ searchParams, table });
  }

  function loadSearchTable({
    searchParams = undefined,
    isCurrentFolderNested = false,
    advancedSearchSelectedFolder = '',
    locationChangedInAdvanced = false,
    breadcrumbClicked = false,
  }) {
    const table = HOME_SCREEN_TABLE_TYPE.SEARCH;
    const searchSort = sqHomeScreenStore.getSortForTable(table);
    const sortOrder = `${searchSort.sortProperty} ${searchSort.sortAsc ? 'asc' : 'desc'}`;
    searchParams
      ? _.assign(searchParams, { sortOrder })
      : setSearchParams({ ...sqHomeScreenStore.searchParams, sortOrder });
    searchParams = searchParams || sqHomeScreenStore.searchParams;
    return loadTable({
      searchParams,
      table,
      isCurrentFolderNested,
      advancedSearchSelectedFolder,
      locationChangedInAdvanced,
      breadcrumbClicked,
    });
  }

  async function loadTable({
    searchParams,
    table,
    isCurrentFolderNested = false,
    advancedSearchSelectedFolder = '',
    locationChangedInAdvanced = false,
    breadcrumbClicked = false,
  }) {
    flux.dispatch('SET_HOME_SCREEN_TABLE_LOADING', { table });

    const limit = sqHomeScreenStore.getPageSizeByTable(table);
    const currentPageNumber = sqHomeScreenStore.getPageNumberForTable(table);
    const offset = (currentPageNumber - 1) * limit;
    const folderId =
      searchParams.folderId ||
      (!isCurrentFolderNested && advancedSearchSelectedFolder !== '') ||
      (advancedSearchSelectedFolder === '' && (locationChangedInAdvanced || breadcrumbClicked))
        ? searchParams.folderId
        : sqHomeScreenStore.currentFolderId;

    let filter = searchParams.filter;
    if (folderId) {
      await sqHomeScreenUtilities.getFolder(folderId).then((folder) => {
        if (folder?.name === USERS_FOLDER_NAME) {
          filter = 'Users';
        }
      });
    }

    const getFolders = (limit: number, offset: number) => {
      return sqFoldersApi
        .getFolders(
          _.assign({}, searchParams, {
            filter: !filter ? undefined : filter,
            folderId,
            limit,
            offset,
          }),
          { cancellationGroup: HOME_SCREEN_CANCELLATION_GROUP },
        )
        .then(({ data }) => {
          return _.pick(data, ['content', 'totalResults', 'path']);
        });
    };

    return getFolders(limit, offset)
      .then((data) => {
        if (_.isEmpty(data.content) && currentPageNumber > 1) {
          // This can happen when items were deleted from the current page, and there are no more items to display
          // for it.  We simply re-fetch by setting the page to the previous one
          setPageNumber(currentPageNumber - 1, table);
          return getFolders(limit, (currentPageNumber - 2) * limit);
        }
        return data;
      })
      .then((data) => {
        flux.dispatch('SET_HOME_SCREEN_ITEMS_FOR_TABLE', {
          table,
          items: data.content,
          totalResults: data?.totalResults,
        });
        if (data?.path) {
          flux.dispatch('SET_BREADCRUMBS', { crumbs: data?.path });
        }
      })
      .finally(() => flux.dispatch('SET_HOME_SCREEN_TABLE_LOADING', { table }));
  }

  function loadFolderTree(folderId, currentTab) {
    let tabFolderPromise;
    let expandToFolder = false;

    // Check to see if folder is actually a tab,then use it
    tabFolderPromise = sqHomeScreenUtilities.getTabFolderName(folderId);

    return tabFolderPromise.then((tabFolder) => {
      const newTab = _.isUndefined(tabFolder) ? currentTab : tabFolder;
      const folderRoot = sqHomeScreenUtilities.getFolderRoot(newTab);
      let promise;

      if (folderId && _.isUndefined(tabFolder)) {
        expandToFolder = true;
        promise = sqFoldersApi.getAncestors(
          { folderId, root: folderRoot },
          { cancellationGroup: HOME_SCREEN_CANCELLATION_GROUP },
        );
      } else if (folderRoot) {
        promise = sqFoldersApi.getSubfolders(
          { folderId: folderRoot },
          { cancellationGroup: HOME_SCREEN_CANCELLATION_GROUP },
        );
        if (newTab !== sqHomeScreenStore.currentTab) {
          service.clearItems();
          service.setCurrentTab(newTab);
        }
      } else {
        return Promise.resolve({});
      }
      return promise.then(({ data: { id, subfolders } }) => {
        mergeSubfolderTree(subfolders);

        if (expandToFolder) {
          if (folderRoot === null && id) {
            if (id === SEARCH_ITEM_LOCATIONS.MY_FOLDER) {
              setCurrentTab(HOME_SCREEN_TABS.MY_FOLDER);
            } else if (id === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC) {
              setCurrentTab(HOME_SCREEN_TABS.SHARED);
              if (currentTab !== HOME_SCREEN_TABS.SHARED) {
                // CRAB-29112: The SHARED folder has special logic regarding how items are shown users so we must load
                // the folder with the `currentTab` correctly set
                service.clearItems();
                return service
                  .loadFolder(folderId, HOME_SCREEN_TABS.SHARED)
                  .then(() => addExpandedFolderIds(_.map(sqHomeScreenStore.breadcrumbs, 'id')));
              }
            } else if (id === SEARCH_ITEM_LOCATIONS.CORPORATE) {
              setCurrentTab(HOME_SCREEN_TABS.CORPORATE);
            } else if (id === SEARCH_ITEM_LOCATIONS.USERS) {
              setCurrentTab(HOME_SCREEN_TABS.USERS);
            }
          }

          addExpandedFolderIds(_.map(sqHomeScreenStore.breadcrumbs, 'id'));
        } else if (folderRoot) {
          // Set the tab folder as the current folder for the three that are actually folders
          if (folderRoot === SEARCH_ITEM_LOCATIONS.MY_FOLDER) {
            sqHomeScreenUtilities.getTabFolder(HOME_SCREEN_TABS.MY_FOLDER).then((folder) => {
              service.setCurrentFolder(folder.id);
            });
          } else if (folderRoot === SEARCH_ITEM_LOCATIONS.CORPORATE) {
            sqHomeScreenUtilities.getTabFolder(HOME_SCREEN_TABS.CORPORATE).then((folder) => {
              service.setCurrentFolder(folder.id);
            });
          } else if (folderRoot === SEARCH_ITEM_LOCATIONS.USERS) {
            sqHomeScreenUtilities.getTabFolder(HOME_SCREEN_TABS.USERS).then((folder) => {
              service.setCurrentFolder(folder.id);
            });
          }
        }
      });
    });
  }

  function loadHomeTab() {
    return Promise.all([loadPinnedTable(), loadRecentTable()]);
  }

  function loadTab(currentTab, folderId) {
    return Promise.all([service.loadTabTable(), service.loadFolderTree(folderId, currentTab)]);
  }

  // This function is used by the Folder Explorer Modal only - currently there is no way to sort or filter items
  // from there
  function loadFolderContents(folderId) {
    return sqFoldersApi
      .getFolders(
        { folderId, types: [ITEM_TYPES.FOLDER], limit: 1000 },
        { cancellationGroup: HOME_SCREEN_CANCELLATION_GROUP },
      )
      .then((response) => response.data.content);
  }

  function getSearchParams({ currentTab, folderId, sortProperty, sortAsc }) {
    const searchParams = {
      sortOrder: `${sortProperty} ${sortAsc ? 'asc' : 'desc'}`,
    };
    if (folderId) {
      return _.assign(searchParams, {
        folderId,
        filter: currentTab === HOME_SCREEN_TABS.SHARED ? SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC : undefined,
      });
    } else {
      switch (currentTab) {
        case HOME_SCREEN_TABS.USERS:
          return _.assign(searchParams, {
            folderId: SEARCH_ITEM_LOCATIONS.USERS,
          });
        case HOME_SCREEN_TABS.MY_FOLDER:
          return _.assign(searchParams, {
            folderId: SEARCH_ITEM_LOCATIONS.MY_FOLDER,
          });
        case HOME_SCREEN_TABS.SHARED:
          return _.assign(searchParams, {
            filter: SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC,
          });
        case HOME_SCREEN_TABS.CORPORATE:
          return _.assign(searchParams, {
            folderId: SEARCH_ITEM_LOCATIONS.CORPORATE,
          });
        case HOME_SCREEN_TABS.TRASH:
          return _.assign(searchParams, { isArchived: 'true' });
        default:
          return searchParams;
      }
    }
  }

  function loadFolder(folderId?, currentTab?): Promise<any> {
    cancelAll();
    currentTab = currentTab || HOME_SCREEN_TABS.HOME;
    clearBreadcrumbs();

    if (currentTab !== sqHomeScreenStore.currentTab) {
      clearItems();
    }

    // the API returns a magic string for the "root" tab of an item, so that we can show the selected tab without
    // encoding it in the URL.
    // Items can appear in more than one "tab". For example: my folder A is My Folder, but it's also under my home
    // folder in the Users tab.
    // Tabs are not persisted in the store between reloads - so if we have no currentTab we use the one the API
    // returns, if we have a tab we use that, as to avoid a jumpy tab selection.
    if (
      currentTab !== sqHomeScreenStore.currentTab ||
      (!(_.isNil(folderId) && _.isNil(sqHomeScreenStore.currentFolderId)) &&
        !equalsIgnoreCase(folderId, sqHomeScreenStore.currentFolderId))
    ) {
      service.setCurrentTab(currentTab);
      service.setCurrentFolder(folderId);
    }

    if (!folderId && currentTab === HOME_SCREEN_TABS.HOME) {
      return loadHomeTab() as Promise<any>;
    } else {
      return loadTab(currentTab, folderId) as Promise<any>;
    }
  }

  function collapseFolder(folderId) {
    removeExpandedFolderIds([folderId]);
  }

  function getSortedTableContents(sortProperty, table) {
    const tableSort = sqHomeScreenStore.getSortForTable(table);
    const sortAsc = tableSort.sortProperty === sortProperty ? !tableSort.sortAsc : true;
    setPageNumber(1, table);

    flux.dispatch('SET_HOME_SCREEN_TABLE_SORT', { sortProperty, sortAsc, table }, PUSH_WORKBENCH);

    switch (table) {
      case HOME_SCREEN_TABLE_TYPE.PINNED:
        return loadPinnedTable();
      case HOME_SCREEN_TABLE_TYPE.RECENT:
        return loadRecentTable();
      case HOME_SCREEN_TABLE_TYPE.SEARCH:
        return loadSearchTable({});
      default:
        return loadTabTable();
    }
  }

  // open a folder navigation tree but don't load the folder contents
  function expandFolder(folderId, folderRoot = null) {
    addExpandedFolderIds(_.uniq([...sqHomeScreenStore.expandedFolderIds, folderId]));
    sqFoldersApi.getSubfolders({ folderId, root: folderRoot }).then((response) => {
      service.mergeSubfolderTree([response.data]);
    });
  }

  function resetStore() {
    flux.dispatch('RESET_HOME_SCREEN_STORE');
  }

  function clearSearchResults() {
    flux.dispatch('SET_HOME_SCREEN_ITEMS_FOR_TABLE', {
      table: HOME_SCREEN_TABLE_TYPE.SEARCH,
      items: [],
    });
  }

  function addFolder({ parentFolderId, branchFrom = undefined, name = undefined, ownerId = undefined }) {
    const folderName = name ? name : sqHomeScreenUtilities.getDefaultFolderName();

    return service.canCreateItemInFolder(parentFolderId).then((folderWriteAccess) => {
      setTableSort(HOME_SCREEN_SORT.CREATED_AT, false, HOME_SCREEN_TABLE_TYPE.TAB);
      return sqHomeScreenUtilities
        .createFolder(folderName, folderWriteAccess && parentFolderId ? parentFolderId : null, branchFrom, ownerId)
        .then((folder) => {
          service.setCurrentFolder(folder.parentFolderId);
          return folder;
        });
    });
  }

  function addWorkbook({
    name = '',
    addNewWorksheet = true,
    isReportBinder = false,
    branchFrom = undefined,
    folderId = undefined,
  }) {
    const prefix = i18next.t(`ITEM_TYPES.${isReportBinder ? 'REPORT' : 'WORKBOOK'}`);
    const worksheetPrefix = isReportBinder ? i18next.t('ITEM_TYPES.DOCUMENT') : '';
    moment.locale(_.truncate(sqWorkbenchStore.userLanguage, { length: 2, omission: '' }));
    name = name || `${prefix} ${formatTime(new Date(), sqWorksheetStore.timezone)}`;

    return service
      .canCreateItemInFolder(folderId)
      .then((folderWriteAccess) =>
        sqHomeScreenUtilities.createWorkbook(
          name,
          branchFrom,
          folderWriteAccess ? folderId : null,
          isReportBinder ? 'Topic' : 'Analysis',
        ),
      )
      .then((workbook) => {
        if (addNewWorksheet && !branchFrom) {
          if (sqWorkbookStore.workbookId !== workbook.workbookId) {
            return loadWorkbook(sqHomeScreenUtilities, workbook.workbookId).then(() =>
              addWorksheetToWorkbook(workbook.workbookId, {
                prefix: worksheetPrefix,
              }),
            );
          } else {
            return addWorksheetToWorkbook(workbook.workbookId, {
              prefix: worksheetPrefix,
            });
          }
        } else if (branchFrom) {
          return loadWorkbook(sqHomeScreenUtilities, workbook.workbookId);
        } else {
          return workbook;
        }
      })
      .then((workbook) => _.tap(workbook, emitWorkbooks));
  }

  function addProject({ folderId = null, name = null }) {
    const prefix = i18next.t('ITEM_TYPES.PROJECT');
    name = name || `${prefix} ${formatTime(new Date(), sqWorksheetStore.timezone)}`;

    return service
      .canCreateItemInFolder(folderId)
      .then((folderWriteAccess) => sqHomeScreenUtilities.createProject(name, folderWriteAccess ? folderId : null))
      .then((project) => {
        flux.dispatch('WORKBENCH_ADD_PROJECT', project);
        emitWorkbooks();
        return project;
      });
  }

  function addWorksheetToWorkbook(workbookId, options?) {
    return addWorksheetToWorkbookAction(workbookId, options).then((worksheet) => {
      // addWorksheet sets the worksheet into the store and this makes the object immutable (freezes the object)
      // to add workbookId on the result we need to work with a clone
      return _.set(_.cloneDeep(worksheet), 'workbookId', workbookId);
    });
  }

  function findOrAddWorkbook(name, addNewWorksheet = false) {
    const searchParams = {
      textSearch: _.trim(name),
      folderId: SEARCH_ITEM_LOCATIONS.MY_FOLDER,
      types: [ITEM_TYPES.ANALYSIS],
    };
    return sqFoldersApi
      .getFolders(searchParams, {
        cancellationGroup: HOME_SCREEN_CANCELLATION_GROUP,
      })
      .then((response) => {
        const id = _.get(_.find(response.data?.content, ['name', name]), 'id');
        if (id) {
          if (addNewWorksheet) {
            if (sqWorkbookStore.workbookId !== id) {
              return loadWorkbook(sqHomeScreenUtilities, id).then(() => addWorksheetToWorkbook(id));
            } else {
              return addWorksheetToWorkbook(id);
            }
          } else {
            return sqHomeScreenUtilities.getWorkbook(id).then((workbook) => ({
              workbookId: id,
              worksheetId: (_.chain(workbook.worksheets).first() as any).get('worksheetId').value(),
            }));
          }
        } else {
          return service.addWorkbook({ name, addNewWorksheet }).then((workbook) => _.tap(workbook, emitWorkbooks));
        }
      });
  }

  function canCreateItemInFolder(folderId) {
    if (folderId) {
      return sqHomeScreenUtilities.getFolder(folderId).then((folder) => canModifyWorkbook(folder));
    }

    return Promise.resolve(true);
  }

  function removeWorkbook(workbook) {
    return Promise.resolve()
      .then(() => sqHomeScreenUtilities.setArchived(workbook.workbookId, true))
      .then((workbook) => _.tap(workbook, emitWorkbooks));
  }

  function restoreWorkbook(workbook) {
    return Promise.resolve()
      .then(() => sqHomeScreenUtilities.setArchived(workbook.workbookId, false))
      .then((workbook) => _.tap(workbook, emitWorkbooks));
  }

  /**
   * A helper function that sets up websocket workbooks notification and reloads workbooks if a workbook has
   * been added, removed, or renamed.
   */
  function setupNotifierForHomescreen() {
    onWorkbooks(function () {
      if ($state.current.name === APP_STATE.WORKBOOKS) {
        return loadFolder(sqHomeScreenStore.currentFolderId, sqHomeScreenStore.currentTab);
      }
    });
  }

  function setTabFolder(key, folder) {
    flux.dispatch('SET_TAB_FOLDER', { key, folder });
  }

  function setHelpDisplay(helpDisplay: HelpDisplay) {
    flux.dispatch('SET_HOME_SCREEN_HELP_DISPLAY', { helpDisplay }, PUSH_WORKBENCH);
  }

  function setDisplayedAddOnIdentifier(displayedAddOnIdentifier: string) {
    flux.dispatch('SET_HOME_SCREEN_DISPLAYED_ADDON_IDENTIFIER', { displayedAddOnIdentifier }, PUSH_WORKBENCH);
  }

  function clearDisplayedAddOnIdentifier() {
    setDisplayedAddOnIdentifier('');
  }
}
