import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { doTrack } from '@/track/track.service';

interface UnsavedChangesModalProps {
  onCancel: () => void;
  onDiscard: () => void;
}

export const UnsavedChangesModal: React.FunctionComponent<UnsavedChangesModalProps> = ({ onCancel, onDiscard }) => {
  const { t } = useTranslation();

  return (
    <Modal animation={false} backdrop="static" show={true}>
      <Modal.Header>
        <Modal.Title>{t('ITEM_ACL.UNSAVED_CHANGES.HEADER')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('ITEM_ACL.UNSAVED_CHANGES.MESSAGE')}</Modal.Body>
      <Modal.Footer>
        <TextButton
          label="ITEM_ACL.UNSAVED_CHANGES.DISCARD"
          onClick={() => {
            doTrack('Access Control', 'confirmation dialog', 'discard');
            onDiscard();
          }}
        />
        <TextButton
          label="CANCEL"
          variant="theme"
          onClick={() => {
            doTrack('Access Control', 'confirmation dialog', 'cancel');
            onCancel();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};
