// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { ReportActions } from '@/reportEditor/report.actions';

import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { ConfigureAssetsSelectionModal } from '@/hybrid/reportConfig/components/assetSelection/components/ConfigureAssetSelectionModal.molecule';
import { ReportConfigAssetSelection } from '@/hybrid/reportConfig/components/assetSelection/components/ReportConfigAssetSelection.atom';
import { ReClickTriggerProperties } from '@/hybrid/reportConfig/components/shared/ReportConfigDateRangeGroup.molecule';
import { AssetSelection, ReportClickActions } from '@/reportEditor/report.constants';
import { useModalManager } from '@/hybrid/core/hooks/useModalManager.hook';
import { sqReportStore } from '@/core/core.stores';
import { BulkEditMode } from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/reportContent.constants';

const reportConfigAssetsBindings = bindingsDefinition({
  viewOnlyMode: prop.optional<boolean>(),
  canApplyUrlOverride: prop.optional<boolean>(),
  sqReportActions: injected<ReportActions>(),
});

export const ReportConfigAssets: SeeqComponent<typeof reportConfigAssetsBindings> = (props) => {
  const { sqReportActions } = useInjectedBindings(reportConfigAssetsBindings);

  const { viewOnlyMode } = props;
  const { t } = useTranslation();
  const [showConfigureAssetsSelectionModal, setShowConfigureAssetsSelectionModal] =
    useModalManager(ConfigureAssetsSelectionModal);
  const [currentSelection, setCurrentSelection] = useState(null);
  const selectionsNotArchived = useFluxPath(sqReportStore, () => sqReportStore.assetSelectionsNotArchived);
  const selections = _.sortBy(selectionsNotArchived, 'name');

  const defaultReClickProperties: ReClickTriggerProperties = {
    action: ReportClickActions.None,
    nullableIndex: null,
    secondArg: null,
  };
  const [reClickProps, setReClickProps] = useState(defaultReClickProperties);
  const getOrderedIndex = (selection, assetSelections) => _.findIndex(assetSelections, { id: selection.id });

  /**
   * Given a function, this decorates that function with logic that makes the function EITHER execute, or activate
   * sandbox mode instead. Used to give the actions in ReportConfigAssetSelection a way to trigger sandbox mode as well
   * as to setup a re-click for the action in question once the sandbox selections are loaded.
   */
  const actionWrapper = (action: (...args) => any, actionType: ReportClickActions): ((...actionArgs) => any) => {
    const oldAssetSelections = _.cloneDeep(selections);
    const onSandbox = (...args) => {
      const selection = args[0];
      const index = getOrderedIndex(selection, oldAssetSelections);
      const secondArg =
        actionType === ReportClickActions.ChangeSibling || actionType === ReportClickActions.ChangeName
          ? args[1]
          : null;
      setReClickProps({
        action: actionType,
        nullableIndex: index,
        secondArg,
      });
    };
    const onAction = () => {
      setReClickProps(defaultReClickProperties);
    };
    return sqReportActions.doActionElseActivateSandbox(action, onAction, onSandbox);
  };

  const onClose = () => {
    setShowConfigureAssetsSelectionModal(false);
    setCurrentSelection(null);
  };

  const openBulkEditWithGivenAssetSelection = (assetSelection: AssetSelection) => {
    sqReportActions.setBulkEditDisplayMode(BulkEditMode.ASSET_SELECTION);
    sqReportActions.setBulkAssetSelection(assetSelection);
  };

  const validateSelection = (newName) => !_.some(selections, ({ name }) => _.toLower(name) === _.toLower(newName));

  return (
    <div className="flexRowContainer mb15 toolOptions">
      <div className="flexColumnContainer flexSpaceBetween pt10 pb10 pl3">
        <div>
          <Icon icon="fa-cube" type="text" large={true} />
          <span className="mb5 pl5">{t('REPORT.CONFIG.ASSET_SELECTIONS')}</span>
        </div>
        {!viewOnlyMode && (
          <div className="mr5 pr3 pl5 flexColumnContainer">
            <Icon
              testId="addAssetSelectionTestId"
              icon="fa-fw fa-plus"
              extraClassNames="cursorPointer ml5 mt2"
              large={true}
              type="theme"
              tooltip="REPORT.CONFIG.CONFIGURE_ASSET_SELECTION"
              onClick={() => setShowConfigureAssetsSelectionModal(true)}
            />
          </div>
        )}
      </div>
      <div className="divider" />
      {selections.length < 1 && !viewOnlyMode && (
        <div className="pt10 pb10 pl3">
          <a
            href="#"
            onClick={() => {
              setShowConfigureAssetsSelectionModal(true);
            }}>
            {t('REPORT.CONFIG.CONFIGURE_ASSET_SELECTION')}
          </a>
        </div>
      )}
      {viewOnlyMode && !sqReportStore.hasInUseAssetSelections() && (
        <span className="text-center">{t('REPORT.CONFIG.ASSET_SELECTIONS_NOT_USED')}</span>
      )}
      {_.map(selections, (selection) => {
        const isInitiatingReClick = reClickProps.nullableIndex === getOrderedIndex(selection, selections);
        const isDifferentAsset = isInitiatingReClick && reClickProps?.action === ReportClickActions.ChangeSibling;
        const isDifferentName = isInitiatingReClick && reClickProps?.action === ReportClickActions.ChangeName;
        const newSelection = {
          ...selection,
          asset: isDifferentAsset ? reClickProps.secondArg : selection.asset,
          name: isDifferentName ? reClickProps.secondArg : selection.name,
        };

        return (
          <ReportConfigAssetSelection
            key={selection.id}
            viewOnly={viewOnlyMode}
            selection={newSelection}
            onEdit={(selection) => {
              setCurrentSelection(selection);
              setShowConfigureAssetsSelectionModal(true);
            }}
            actionWrapper={actionWrapper}
            reClickAction={isInitiatingReClick ? reClickProps.action : ReportClickActions.None}
            openBulkEditWithGivenAssetSelection={openBulkEditWithGivenAssetSelection}
            isValid={validateSelection}
          />
        );
      })}
      {showConfigureAssetsSelectionModal && (
        <ConfigureAssetsSelectionModal
          onClose={onClose}
          assetSelections={selections}
          currentSelection={currentSelection}
          openBulkEditWithGivenAssetSelection={openBulkEditWithGivenAssetSelection}
        />
      )}
    </div>
  );
};
