// @ts-strict-ignore
import _ from 'lodash';
import jQuery from 'jquery';
import { AnnotationInputV1, AnnotationOutputV1, sqAnnotationsApi, sqItemsApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { CONTENT_MODEL_ATTRIBUTES } from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { logError } from '@/hybrid/utilities/logger';
import { formatMessage } from '@/hybrid/utilities/logger.utilities';
import { sqReportStore } from '@/core/core.stores';

function annotationInputFromOutput(output: AnnotationOutputV1, type: string): AnnotationInputV1 {
  return {
    description: output.description,
    interests: _.chain(output.interests)
      .uniqBy((interest) => [interest.item.id, interest.capsule?.id].join(''))
      .map((interest) => ({
        interestId: interest.item.id,
        detailId: interest.capsule?.id,
      }))
      .value(),
    name: output.name,
    type,
    reportInput:
      type === 'Report'
        ? {
            cronSchedule: output.cronSchedule,
            background: output.background,
            enabled: output.enabled,
          }
        : undefined,
    discoverable: output.discoverable,
  };
}

/**
 * Toggles the editor to the other version, saving or loading backups as needed so that when the page is refreshed
 * it is a good state.
 *
 * @param annotationId - The id of the annotation being migrated
 * @return Promise with the migrated or reverted document HTML.
 */
export function toggleEditor(annotationId: string): Promise<string> {
  const handleError = (error) => {
    errorToast({ httpResponseOrError: error, displayForbidden: true });
    return Promise.reject(error);
  };

  return Promise.all(
    // We need to get the item properties to get the type to provide some additional metadata data to the
    // annotation input object
    [sqAnnotationsApi.getAnnotation({ id: annotationId }), sqItemsApi.getItemAndAllProperties({ id: annotationId })],
  )
    .then(
      ([
        { data },
        {
          data: { type },
        },
      ]) => {
        if (data.ckEnabled) {
          return sqItemsApi
            .setProperty({ value: false }, { id: annotationId, propertyName: SeeqNames.Properties.IsCkEnabled })
            .then(() => sqItemsApi.getProperty({ id: annotationId, propertyName: SeeqNames.Properties.FroalaBackup }))
            .catch((error) => {
              // If the document was empty on conversion we don't want to produce an error
              if (error.status === 404 && _.includes(error.data.statusMessage, SeeqNames.Properties.FroalaBackup)) {
                return Promise.resolve({ data: { value: undefined } });
              }
              return Promise.reject(error);
            })
            .then(({ data: { value } }) =>
              sqAnnotationsApi
                .updateAnnotation(
                  { ...annotationInputFromOutput(data, type), document: value ?? '' },
                  { id: annotationId },
                )
                .then(() => value ?? ''),
            )
            .catch(handleError);
        } else {
          return Promise.resolve()
            .then(() => {
              try {
                return migrateDocumentFromFroalaToCK(data.document);
              } catch (e) {
                // Very unlikely the migration would fail, but if it it does the user should still get their document
                logError(formatMessage`Failed to migrate annotation ${annotationId} to CK: ${e}`);
                return data.document;
              }
            })
            .then((migratedDocument) =>
              sqAnnotationsApi
                .migrateAnnotationToCkEditor(
                  {
                    document: migratedDocument,
                  },
                  { id: annotationId },
                )
                .catch((e) => {
                  // Annotation may fail to save, such as if it has cross-linked annotation images
                  logError(formatMessage`Error saving annotation ${annotationId} while migrating: ${e}`);
                })
                .then(() => migratedDocument),
            );
        }
      },
    )
    .catch(handleError);
}

/**
 * Migrates a document from Froala to CK.
 *
 * @param document - The Froala document to migrate
 */
export function migrateDocumentFromFroalaToCK(document: string): string {
  if (!document) {
    return undefined;
  }

  // Adding a wrapper div makes getting the actual html easier at the end
  const jqueryDocument: any = jQuery(jQuery.parseHTML(`<div>${document}</div>`));

  // Convert Froala page breaks to CK page breaks
  jqueryDocument
    .find('hr.fr-page-break')
    .replaceWith(
      '<div class="page-break" style="page-break-after:always;"><span style="display:none;">&nbsp;</span></div>',
    );

  //region table styles
  // The below styles were found by creating an equivalent table in CK and copying the styles it used
  // We don't have to remove the froala specific classes because CK will remove any class it doesn't have a
  // converter for.

  const dashedBorders = '1px dashed #DDD';
  const dashedBordersStyles = {
    'border-bottom': dashedBorders,
    'border-left': dashedBorders,
    'border-right': dashedBorders,
    'border-top': dashedBorders,
  };

  const noBorders = '1px solid hsl(0, 0%, 100%)';
  const noBordersStyles = {
    'border-bottom': noBorders,
    'border-left': noBorders,
    'border-right': noBorders,
    'border-top': noBorders,
  };

  jqueryDocument.find('table').each((index, element) => {
    let alignment = '';
    let styleToApply = '';
    if (element.classList.contains('center-froala-table')) {
      // this is how froala centers it's tables
      alignment = '';
    } else if (!_.isEmpty(element.style['margin-left']) && _.isEmpty(element.style['margin-right'])) {
      alignment = 'right';
    } else if (_.isEmpty(element.style['margin-left']) && !_.isEmpty(element.style['margin-right'])) {
      alignment = 'left';
    } else if (!_.isEmpty(element.style['margin-left']) && !_.isEmpty(element.style['margin-right'])) {
      // format for 'width' is 12% - we need to extract only the number
      const remainingWidth = 100 - _.toNumber(_.head(element.style['width'].match(/^\d+/g)));
      // format for 'margin-left' is calc(45%) - we need to extract only the number
      const percentFromLeft =
        (_.toNumber(_.head(element.style['margin-left'].substr(5).match(/^\d+/g))) / remainingWidth) * 100;
      // format for 'margin-right' is calc(25%) - we need to extract only the number
      const percentFromRight =
        (_.toNumber(_.head(element.style['margin-right'].substr(5).match(/^\d+/g))) / remainingWidth) * 100;
      alignment = '';
      if (percentFromLeft > 75) {
        alignment = 'right';
      } else if (percentFromRight > 75) {
        alignment = 'left';
      }
    }
    if (alignment !== '') {
      styleToApply = `style="float: ${alignment};"`;
    }
    jQuery(element).wrap(`<figure class="table" ${styleToApply}></figure>`);
  });

  // In Froala, dashed and no borders could be on at the same time, but it would create a weird super thick dashed
  // border. Here, we just pick one to win out based on ordering.

  // No Borders
  const noBorderTable = jqueryDocument.find('table.table-no-border').css(noBordersStyles);
  noBorderTable.find('td').css(noBordersStyles);

  // Dashed Borders
  const dashedTable = jqueryDocument.find('table.fr-dashed-borders');
  dashedTable.css(dashedBordersStyles);
  dashedTable.find('td').css(dashedBordersStyles);

  // Highlighted Cells
  jqueryDocument.find('td.fr-highlighted').css('border', '1px double red');

  // Thick Cells
  jqueryDocument.find('td.fr-thick').css('border-width', '2px');

  // Striping
  jqueryDocument.find('table.fr-alternate-rows').parent().addClass('seeqTableStriped');

  // Certain ways of adding alignment and other stylings add extra divs to tables. This copies any relevant styles
  // to the parent td of the div and removes the div
  jqueryDocument.find('td > div').each((index, div) => {
    const divStyle = div.getAttribute('style') ?? '';
    const parentStyle = div.parentElement.getAttribute('style') ?? '';
    div.parentElement.setAttribute('style', `${divStyle} ${parentStyle}`);
  });

  // Removes extraneous divs that add extra spacing in CK
  jqueryDocument.find('td > div').contents().unwrap();

  //remove last br from each td element
  jqueryDocument.find('td > br:last-child').remove();

  // wrap br in p in order to not be converted in double br.
  jqueryDocument.find('td > br').each((index, element) => jQuery(element).wrap('<p></p>'));

  // Froala cell colors propagated to children that didn't have a color assigned. In CK each cell requires
  // specific colors. jQuery ordering is consistent, so we can go in reverse to apply the styles from the
  // innermost styled td first
  const backgroundColorKey = 'background-color';
  jQuery(jqueryDocument.find(`td[style*="${backgroundColorKey}"]`).get().reverse()).each((index, td) => {
    const backgroundColor = td.style[backgroundColorKey];
    jQuery(td)
      .find('td')
      .each((index, innerTd) => {
        if (!innerTd.style[backgroundColorKey]) {
          innerTd.style[backgroundColorKey] = backgroundColor;
        }
      });
  });

  //endregion

  //region content migration

  // Remove surrounding a tags
  jqueryDocument.find(`a [${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`).parent().contents().unwrap();

  // Migrate border attribute
  jqueryDocument
    .find(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}].report-image-border`)
    .attr(CONTENT_MODEL_ATTRIBUTES.BORDER, true);

  // Migrate no margin
  jqueryDocument
    .find(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}].report-no-margin`)
    .attr(CONTENT_MODEL_ATTRIBUTES.NO_MARGIN, true);

  // height, width, and width percent
  jqueryDocument.find(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}]`).each((index, content) => {
    if (!_.isEmpty(content.style['max-width'])) {
      content.setAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH, parseFloat(content.style['max-width']));
    }
    if (!_.isEmpty(content.style['max-height'])) {
      content.setAttribute(CONTENT_MODEL_ATTRIBUTES.HEIGHT, parseFloat(content.style['max-height']));
    }
    if (!_.isEmpty(content.style.width)) {
      content.setAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT, content.style.width);
    }
  });

  //endregion

  return jqueryDocument.get(0).innerHTML;
}
