// @ts-strict-ignore
import React from 'react';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TableBuilderActions } from '@/hybrid/tableBuilder/tableBuilder.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import classNames from 'classnames';
import _ from 'lodash';

import { TABLE_BUILDER } from '@/hybrid/tableBuilder/tableBuilder.constants';
import { doTrack } from '@/track/track.service';

const chartSettingsBindings = bindingsDefinition({
  tableData: prop<any[][]>(),
  settings: prop.optional<ChartSettingsInterface>(),
  changeCallback: prop<(settings: {}) => void>(),
  seriesNamesOptions: prop<any[]>(),
  xAxisOptions: prop<any[]>(),
  yAxisOptions: prop<any[]>(),
  sqTableBuilderActions: injected<TableBuilderActions>(),
});

export interface ChartSettingsInterface {
  title: string;
  legend: boolean;
  dataLabels: boolean;
  categoryLabels: boolean;
  chartType: string;
  categoryColumns: number[];
  rows: number[];
  columns: number[];
  showSettings?: boolean;
  position?: { x: number; y: number };
}

export const defaultSettings = {
  title: '',
  legend: true,
  dataLabels: true,
  categoryLabels: true,
  rows: [],
  columns: [],
  chartType: undefined,
  categoryColumns: [0],
  showSettings: false,
  position: { x: 100, y: 100 },
};

export const ChartSettings: SeeqComponent<typeof chartSettingsBindings> = ({
  tableData,
  settings,
  changeCallback,
  seriesNamesOptions,
  xAxisOptions,
  yAxisOptions,
}) => {
  const { sqTableBuilderActions } = useInjectedBindings(chartSettingsBindings);
  if (!tableData || _.isEmpty(tableData)) {
    return <div />;
  }

  const { t } = useTranslation();

  const setShowLegend = (showLegend) => {
    changeCallback({ legend: showLegend });
    doTrack(TABLE_BUILDER, 'legend', showLegend);
  };

  const setShowDataLabels = (showDataLabels) => {
    changeCallback({ dataLabels: showDataLabels });
    doTrack(TABLE_BUILDER, 'data', showDataLabels);
  };

  const setShowCategoryLabels = (showCategoryLabels) => {
    changeCallback({ categoryLabels: showCategoryLabels });
    doTrack(TABLE_BUILDER, 'category', showCategoryLabels);
  };

  const setSelectedRows = (selectedOptions) => {
    changeCallback({
      rows: _.map(selectedOptions, (option) => option.value),
    });
    doTrack(TABLE_BUILDER, 'changed series');
  };

  const setSelectedColumns = (selectedOptions) => {
    changeCallback({
      columns: _.map(selectedOptions, (option) => option.value),
    });
    doTrack(TABLE_BUILDER, 'changed categories');
  };

  const setChartType = (selectedChartType) => {
    changeCallback({ chartType: selectedChartType });
    doTrack(TABLE_BUILDER, 'show chart view', selectedChartType);
  };

  const setCategoryColumns = (selectedOptions) => {
    changeCallback({
      categoryColumns: selectedOptions.map((option) => option.value),
    });
    doTrack(TABLE_BUILDER, 'changed where to get series name');
  };

  const showCharts = () => {
    sqTableBuilderActions.setShowChartView(true);
  };

  const showTableView = () => {
    sqTableBuilderActions.setShowChartView(false);
    setChartType(undefined);
    doTrack(TABLE_BUILDER, 'return to table view');
  };

  const chartIcons = [
    { icon: 'fc-bar-chart', type: 'column', tooltip: 'SHOW_COLUMN_CHART' },
    {
      icon: 'fc-stacked-bar-chart',
      type: 'column_stacked',
      tooltip: 'SHOW_STACKED_COLUMN_CHART',
    },
    { icon: 'fa-pie-chart', type: 'pie', tooltip: 'SHOW_PIE_CHART' },
    { icon: 'fc-bar-chart-horizontal', type: 'bar', tooltip: 'SHOW_BAR_CHART' },
    {
      icon: 'fc-stacked-bar-horizontal',
      type: 'bar_stacked',
      tooltip: 'SHOW_STACKED_BAR_CHART',
    },
  ];

  return (
    <div className="settingsContainer">
      <div className="settingsIcons mb10">
        <div className="flexColumnContainer flexAlignCenter flexSpaceBetween">
          <Icon
            id="chartTable"
            extraClassNames={classNames('p5 textPrimaryChartSettings', {
              'sq-bg-theme-dark white': _.isUndefined(settings.chartType),
            })}
            icon="fc-table fa-xlg"
            testId="table-icon"
            tooltip="TABLE_BUILDER.CHART_VIEW_SETTINGS.SHOW_TABLE"
            tooltipPlacement="top"
            onClick={showTableView}
          />

          {_.map(chartIcons, (iconType) => (
            <Icon
              extraClassNames={classNames('p5 textPrimaryChartSettings', {
                'sq-bg-theme-dark white': settings.chartType === iconType.type,
              })}
              icon={classNames(iconType.icon, 'fa-xlg')}
              tooltip={`TABLE_BUILDER.CHART_VIEW_SETTINGS.${iconType.tooltip}`}
              tooltipPlacement="top"
              testId={`${iconType.type}_testId`}
              key={iconType.type}
              onClick={() => {
                showCharts();
                setChartType(iconType.type);
              }}
            />
          ))}
        </div>
      </div>

      <div className="mb10 flexColumnContainer flexSpaceBetween">
        <Checkbox
          id="chartShowLegend"
          key="chartShowLegend"
          isChecked={settings.legend}
          onChange={(e) => setShowLegend(e.target.checked)}
          label="TABLE_BUILDER.CHART_VIEW_SETTINGS.SHOW_LEGEND"
        />
        <Checkbox
          id="chartShowDataLabels"
          key="chartShowDataLabels"
          isChecked={settings.dataLabels}
          onChange={(e) => setShowDataLabels(e.target.checked)}
          label="TABLE_BUILDER.CHART_VIEW_SETTINGS.SHOW_DATA_LABELS"
        />
        <Checkbox
          id="chartShowXLabels"
          key="chartShowCategoryLabels"
          isChecked={settings.categoryLabels}
          onChange={(e) => setShowCategoryLabels(e.target.checked)}
          label="TABLE_BUILDER.CHART_VIEW_SETTINGS.SHOW_CATEGORY_LABELS"
        />
      </div>

      <div className="mb10">
        <div className="multiSelectTwoLine" data-testid="seriesNamesSelector">
          <label>{t('TABLE_BUILDER.CHART_VIEW_SETTINGS.SERIES_NAME')}</label>
          <Select
            isSearchable={true}
            isMulti={true}
            value={settings.categoryColumns.map((index) => seriesNamesOptions.find((item) => item.value === index))}
            options={seriesNamesOptions}
            onChange={setCategoryColumns}
            classNamePrefix="chartViewSeriesNamesSelector react-select"
            key="chartViewSeriesNamesSelector"
          />
        </div>
      </div>
      <div className="mb10">
        <div className="multiSelectTwoLine" data-testid="categoriesSelector">
          <label>{t('TABLE_BUILDER.CHART_VIEW_SETTINGS.X_AXIS')}</label>
          <Select
            isSearchable={true}
            isMulti={true}
            value={settings.columns.map((index) => xAxisOptions.find((item) => item.value === index))}
            options={xAxisOptions}
            onChange={setSelectedColumns}
            classNamePrefix="chartViewXAxisSelector react-select"
            key="chartViewXAxisSelector"
          />
        </div>
      </div>

      <div className="mb10">
        <label>{t('TABLE_BUILDER.CHART_VIEW_SETTINGS.Y_AXIS')}</label>
        <div className="multiSelectTwoLine" data-testid="seriesSelector">
          <Select
            isSearchable={true}
            isMulti={true}
            value={settings.rows.map((index) => yAxisOptions.find((item) => item.value === index))}
            options={yAxisOptions}
            onChange={setSelectedRows}
            classNamePrefix="chartViewYAxisSelector react-select"
            key="chartViewYAxisSelector"
          />
        </div>
      </div>
    </div>
  );
};
