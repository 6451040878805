import _ from 'lodash';
import { formatMessage } from '@/hybrid/utilities/logger.utilities';
import { flux } from '@/core/flux.module';
import { DocumentLayout, DocumentMarginUnits, DocumentPaperSize } from '@/reportEditor/report.constants';

export function setLayout(layout: DocumentLayout) {
  flux.dispatch('PDF_EXPORT_SET_LAYOUT', layout);
}

export function setPaperSize(paperSize: DocumentPaperSize) {
  flux.dispatch('PDF_EXPORT_SET_PAPER_SIZE', paperSize);
}

export function setMargin(value: number | string, unit: DocumentMarginUnits) {
  const num = _.isString(value) ? parseFloat(value) : value;
  if (!_.isFinite(num) || num < 0) {
    throw new TypeError(formatMessage`'${value}' is not a valid margin value`);
  }

  flux.dispatch('PDF_EXPORT_SET_MARGIN', { value: num, units: unit });
}

export function setShowModal(showModal: boolean) {
  flux.dispatch('PDF_EXPORT_SET_SHOW_MODAL', { showModal });
}
