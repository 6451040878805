// @ts-strict-ignore
import _ from 'lodash';
import { flux } from '@/core/flux.module';

/**
 * Set the input condition value that is used in the form.
 *
 * id - input condition id
 * index - the index of the input condition that is modified
 */
export function setInputCondition(condition, index: number) {
  flux.dispatch('REFERENCE_SET_INPUT_VALUE_PARAMS', {
    ..._.pick(condition, ['id', 'conditionMetadata']),
    index,
    paramName: 'inputCondition',
  });
}

/**
 * Set the input signal value that is used in the form.
 * inputSignal.id - input signal id
 * inputSignal.name - input signal name
 * index - the index of the input signal that is modified
 */
export function setInputSignal(id, index: number) {
  flux.dispatch('REFERENCE_SET_INPUT_VALUE_PARAMS', {
    id,
    paramName: 'inputSignal',
    index,
  });
}

/**
 * Add a new pair of input signal condition data
 */
export function addSignalCondition() {
  flux.dispatch('REFERENCE_ADD_SIGNAL_CONDITION');
}

/**
 * Remove an input pair
 * index - the pair index that has to be removed
 */
export function removeSignalCondition(index: number) {
  flux.dispatch('REFERENCE_REMOVE_SIGNAL_CONDITION', index);
}

/**
 * Set the training window start value that is used in the form.
 *
 * @param {Number} window.startTime - the start of the reference table training window
 * @param {Number} window.endTime - the end of the reference table training window
 */
export function setTrainingWindow(window) {
  flux.dispatch('REFERENCE_SET_TRAINING_WINDOW', { window });
}

/**
 * Sets the gridding period for used for building the reference model.
 *
 * @param {Object} period - The period
 * @param {String} period.value - The number that indicates how long the period is
 * @param {String} period.units - The units that the value represent
 */
export function setGriddingPeriod(period) {
  flux.dispatch('REFERENCE_SET_GRIDDING_PERIOD', period);
}

/**
 * Sets the reference table statistic that is used for building the model.
 *
 * @param {String} referenceStat - One of REFERENCE_STATS
 */
export function setReferenceStat(referenceStat) {
  flux.dispatch('REFERENCE_SET_REFERENCE_STAT', { referenceStat });
}

/**
 * Sets the multiplier that is used in conjunction with the reference statistic.
 *
 * @param {Number} multiplier - The multiplier
 */
export function setMultiplier(multiplier) {
  flux.dispatch('REFERENCE_SET_MULTIPLIER', { multiplier });
}

/**
 * Creates a paired version of the currently loaded reference profile.
 *
 * @param {String} name - The name of the new paired signal
 */
export function createPairing(name) {
  flux.dispatch('REFERENCE_CREATE_PAIRING', { name });
}
