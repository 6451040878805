// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { findItemIn, getTrendItemScopedTo } from '@/hybrid/trend/trendDataHelper.utilities';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAllTrendStoresProperty } from '@/hybrid/core/hooks/useAllTrendStoresProperty.hook';
import { useStateWithRef } from '@/hybrid/core/hooks/useStateWithRef.hook';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { FormControl, InputGroup } from 'react-bootstrap';
import { MAX_NAME_LENGTH } from '@/main/app.constants';
import { PREVIEW_ID, TREND_STORES } from '@/trendData/trendData.constants';
import { TrendActions } from '@/trendData/trend.actions';
import { ColorPicker } from '@/hybrid/workbooks/ColorPicker.organism';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { FormError } from '@/hybrid/core/FormError.atom';
import { FormulaService } from '@/services/formula.service';
import { useIsMounted } from '@/hybrid/core/hooks/useIsMounted.hook';

const searchTitleBindings = bindingsDefinition({
  name: prop<string>(),
  id: prop<string>(),
  onColorChange: prop<(color: string) => void>(),
  setSearchName: prop<(name: string) => void>(),
  searchIconClass: prop<string>(),
  defaultName: prop<string>(),
  fromFormBuilder: prop.optional<boolean>(),
  className: prop.optional<any>(),
  sqTrendActions: injected<TrendActions>(),
  $state: injected<ng.ui.IStateService>(),
  sqFormula: injected<FormulaService>(),
});

const SearchTitleUnwrapped: SeeqComponent<typeof searchTitleBindings> = (props) => {
  const { id, setSearchName, searchIconClass, defaultName, onColorChange, fromFormBuilder } = props;
  const { sqTrendActions, sqFormula, $state } = useInjectedBindings(searchTitleBindings);
  const { t } = useTranslation();
  const [color, setColor] = useState('');
  const [name, setName] = useStateWithRef(props.name ? props.name : '');
  const [loadingDefaultName, setLoadingDefaultName] = useState(false);

  const setItemColor = onColorChange
    ? useCallback(() => {
        const currentId = id || PREVIEW_ID;
        const existingItem = findItemIn(TREND_STORES, currentId);
        // HACK: somehow TEND_STORES is undefined when running FormulaTool.organism test
        const nextColor = !_.isEmpty(TREND_STORES) ? _.head(TREND_STORES).findNextColor() : '#CFCYDC';
        const itemColor = existingItem && existingItem.color ? existingItem.color : nextColor;
        if (color !== itemColor) {
          setColor(itemColor);
          onColorChange(itemColor);
        }
      }, [color])
    : _.noop;

  const isMounted = useIsMounted();

  useEffect(() => {
    setName(props.name);
  }, [props.name]);

  useAllTrendStoresProperty('items', setItemColor);
  useEffect(() => {
    setItemColor();
  }, [id, setItemColor]);

  useEffect(() => {
    if (_.isEmpty(name.current)) {
      setLoadingDefaultName(true);
      sqFormula.getDefaultName(t(defaultName), getTrendItemScopedTo(id, $state)).then((defaultName) => {
        if (isMounted.current) {
          setLoadingDefaultName(false);
          if (_.isEmpty(name.current)) {
            setName(defaultName);
            setSearchName(defaultName);
          }
        }
      });
    }
  }, []);

  const updateColor = onColorChange
    ? (itemId, color) => {
        // This accounts for the previous two-way binding this component had for the color property
        onColorChange(color);
        sqTrendActions.setItemColor(itemId, color);
      }
    : _.noop;

  const updateName = (event) => {
    const name = event.target.value;
    setName(name);
    setSearchName(name);
  };

  return (
    <div>
      <h4 className="card-title flexColumnContainer searchTitleDiv">
        <span className={classNames('pr5', loadingDefaultName ? 'mt7' : 'mt5')} data-testid="toolIcon">
          <IconWithSpinner spinning={loadingDefaultName} icon={searchIconClass} large={true} />
        </span>
        <InputGroup className="width-maximum searchTitleInput flexColumnContainer flexNoWrap pr5">
          <FormControl
            id="name"
            size="sm"
            value={name.current ? name.current : ''}
            maxLength={MAX_NAME_LENGTH.TOOL}
            onChange={updateName}
            onFocus={(e) => e.target.select()}
            data-testid="searchTitleInput"
            className={classNames('truncatedLabel', props.className)}
            required={true}
          />
          {onColorChange && (
            <InputGroup.Append>
              <InputGroup.Text>
                <ColorPicker color={color} itemId={id} placement="bottom" notifyOnSelect={updateColor} />
              </InputGroup.Text>
            </InputGroup.Append>
          )}
        </InputGroup>
      </h4>

      {!fromFormBuilder && _.isEmpty(_.trim(name.current)) && !loadingDefaultName && (
        <FormError extraClassNames="ml25 help-block" errorText="FORM.REQUIRED_FIELD" />
      )}

      {!fromFormBuilder && name.current?.length > MAX_NAME_LENGTH.TOOL && (
        <FormError
          extraClassNames="ml25 help-block"
          errorText="NAME_LENGTH_ERROR"
          errorParameters={{ count: MAX_NAME_LENGTH.TOOL }}
        />
      )}
    </div>
  );
};

export const sqSearchTitle = angularComponent(searchTitleBindings, SearchTitleUnwrapped);

export const SearchTitle = React.memo(
  SearchTitleUnwrapped,
  (prev, next) =>
    !(prev.searchIconClass !== next.searchIconClass || prev.className !== next.className || prev.name !== next.name),
);
