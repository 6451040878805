// @ts-strict-ignore
import _ from 'lodash';
import DOMPurify from 'dompurify';
import { ASSET_PATH_SEPARATOR } from '@/main/app.constants';
import { AUTO_FORMAT, formatNumber, roundWithPrecision } from '@/hybrid/utilities/numberHelper.utilities';
import { FormatOptions, formatString } from '@/hybrid/utilities/stringHelper.utilities';
import { getCertainId } from '@/hybrid/utilities/utilities';
import {
  CapsuleTimeColorMode,
  CUSTOMIZATION_MODES,
  ITEM_CHILDREN_TYPES,
  ITEM_TYPES,
  LABEL_LOCATIONS,
  PREVIEW_HIGHLIGHT_COLOR,
  PREVIEW_ID,
  TREND_BUFFER_FACTOR_LABEL,
  TREND_TOP_Y_AXIS_ID,
  TREND_VIEWS,
  Y_AXIS_TYPES,
} from '@/trendData/trendData.constants';
import { TrendCapsuleSetStore } from '@/trendData/trendCapsuleSet.store';
import { decorate } from '@/hybrid/trend/trendViewer/itemDecorator.utilities';
import { getYAxisIdFromItem } from '@/hybrid/utilities/chartHelper.utilities';
import i18next from 'i18next';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { DEFAULT_AXIS_LABEL_COLOR, PLOT_BAND_AXIS_ID, Z_INDEX } from '@/hybrid/trend/trendViewer/trendViewer.constants';
import { TrendStore } from '@/trendData/trend.store';
import { defaultNumberFormat } from '@/services/systemConfiguration.utilities';

export const Y_AXIS_LABEL_CHARACTER_WIDTH = 7;

interface LabelDefinition {
  html: string;
  text: string;
  isListItem?: boolean;
}

export type TrendValuesForLabels = Pick<
  TrendStore,
  | 'labelDisplayConfiguration'
  | 'view'
  | 'capsuleTimeColorMode'
  | 'compareViewColorMode'
  | 'isCompareMode'
  | 'customizationMode'
  | 'showGridlines'
  | 'showCapsuleLaneLabels'
>;

export type ConditionValuesForLabels = Pick<TrendCapsuleSetStore, 'items'>;

export interface LabelDisplayConfiguration {
  custom: LABEL_LOCATIONS;
  customLabels: any[];
  unitOfMeasure: LABEL_LOCATIONS;
  line: LABEL_LOCATIONS;
  asset: LABEL_LOCATIONS;
  name: LABEL_LOCATIONS;
  assetPathLevels: number;
}

// Array to store tickAttributes for lookup
const tickAttributesConfig = [];
// Max number of tickAttributes that will be stored
const maxStoredTickAttributes = 50;

/**
 * Returns the html-formatted label for a capsule lane label or an empty string if no label is enabled.
 *
 * @param capsuleSetId - the id of the capsule set
 * @param dataLabelTitles - the titles of any data labels that are shown
 * @param conditionValues
 * @param trendValues
 * @returns HTML for the capsule label.
 */
export function getCapsuleLaneLabelDisplayText(
  capsuleSetId: string,
  dataLabelTitles: string[],
  conditionValues: ConditionValuesForLabels,
  trendValues: TrendValuesForLabels,
): string {
  const condition = _.find(conditionValues.items, { id: capsuleSetId });
  const conditionName = _.get(condition, 'name');

  if (trendValues.showCapsuleLaneLabels && !_.isNil(conditionName)) {
    return [
      `<span style="color: ${_.get(condition, 'color', DEFAULT_AXIS_LABEL_COLOR)}" class="text-with-shadow pr5">`,
      DOMPurify.sanitize(conditionName),
      dataLabelTitles.length ? ` (${DOMPurify.sanitize(dataLabelTitles.join(', '))})` : '',
      '</span>',
    ].join('');
  } else {
    return '';
  }
}

/**
 * Helper function that returns nicely formatted asset names for the specified signal.
 *
 * @param {Object} signal - The signal to return assets for
 * @param {Number} assetPathLevels - The number of parent assets to return
 * @returns {string} A nicely formatted string of parent assets for the signal
 */
export function getAssetNames(signal, assetPathLevels) {
  return _.chain(signal.assets)
    .map(function (asset) {
      const pathElements = _.map(_.split(asset.formattedName, ASSET_PATH_SEPARATOR), DOMPurify.sanitize);
      return _.takeRight(pathElements, assetPathLevels).join(ASSET_PATH_SEPARATOR);
    })
    .compact()
    .join(', ')
    .value();
}

/**
 * Helper function that returns an HTML string that is labels of all the items in the lane and the custom label.
 * A wrapper div with a hard-coded width that is the same as the chart width allows the labels to be wrapped if
 * they can't fit in a single line. Each individual label is expected to be wrapped in a span with a
 * text-with-shadow class so that it can further styled via CSS.
 *
 * @param items - Array of items displayed by the chart
 * @param lane - the current lane
 * @param width - the width of only the chart lane, excluding the width of the axis
 * @param trendValues
 * @returns The label text for each item in the lane, wrapped in a container div.
 */
export function getLaneDisplayText(
  items: LabelDefinition[],
  lane: number,
  width: number,
  trendValues: TrendValuesForLabels,
): string {
  const labelsHtml = _.chain([
    getLaneCustomLabel(lane, trendValues),
    _.map(getLaneSeriesLabels(items, lane, trendValues), 'html').join(', '),
    getLaneConfigLabel(lane, trendValues),
  ])
    .reject(_.isEmpty)
    .join(' ')
    .value();
  return `<div class="text-right pl5 pr5" style="width: ${width}px;">${labelsHtml}</div>`;
}

/**
 * Helper function that returns the axis label display String. Based on the settings chosen by the user. Including
 * any/all of { signal names, units, custom labels } or an empty string.
 *
 * @param items - Array of items displayed by the chart
 * @param alignment - the current axis
 * @param series - Array of Series objects
 * @param height - the height of the axis as determined by axis.height
 * @param trendValues
 * @returns display for the Axis
 */
export function getAxisDisplayText(
  items: any[],
  alignment: string,
  series: any[],
  height: number,
  trendValues: TrendValuesForLabels,
): string {
  const labels = []
    .concat(getAxisCustomLabel(alignment, trendValues))
    .concat(getAxisSeriesLabels(items, series, trendValues));
  const charLength = _.chain(labels)
    .flatMap((label) => [label.text, label.unitOfMeasure ? label.unitOfMeasure.value : ''])
    .map((text) => text.length)
    .sum()
    .value();
  return _.chain(labels)
    .reduce(function (htmlLabels, label, index) {
      let numChars;
      const allowedChar = height / Y_AXIS_LABEL_CHARACTER_WIDTH;
      const uomChars = label.unitOfMeasure ? label.unitOfMeasure.value.length : 0;
      // Most units are less than 5 chars, so we allow short labels without truncation
      if (charLength > allowedChar && label.text.length + uomChars > 4) {
        // Divide allowed chars by num series, then divide by two to end up with the number of chars we can
        // display at either end Subtract one so that we leave a buffer
        numChars = Math.max(2, Math.floor(height / Y_AXIS_LABEL_CHARACTER_WIDTH / labels.length / 2 - 1));
        if (label.text.length + uomChars > numChars * 2) {
          if (label.unitOfMeasure && label.text) {
            // give units one more character than text
            const newText = label.text.slice(0, numChars - 1);
            const numSliced = label.text.length - newText.length;
            const extraToSlice = numSliced < numChars ? numChars + (numChars - numSliced) : 0;
            label.text = `${newText}..`;
            label.unitOfMeasure.value = label.unitOfMeasure.value.slice(-(numChars + extraToSlice + 1));
          } else if (label.unitOfMeasure) {
            const uomVal = label.unitOfMeasure.value;
            label.unitOfMeasure.value = `${uomVal.slice(0, numChars - 1)}..${uomVal.slice(-(numChars + 1))}`;
          } else {
            label.text = `${label.text.slice(0, numChars - 1)}..${label.text.slice(-(numChars + 1))}`;
          }
        }
      }

      const notLast = index < labels.length - 1;
      if (label.isListItem && notLast && labels[index + 1].isListItem) {
        if (label.unitOfMeasure) {
          label.unitOfMeasure.value = `${label.unitOfMeasure.value},`;
        } else {
          label.text = `${label.text},`;
        }
      }

      // It would be nice to just call `join(' ')` later on, but highcharts does not display spaces on their own -
      // they have to be alongside other text (inside the span, in this case).
      if (notLast) {
        if (label.unitOfMeasure) {
          label.unitOfMeasure.value = `${label.unitOfMeasure.value} `;
        } else {
          label.text = `${label.text} `;
        }
      }
      return [...htmlLabels, displayWithUnits(label).html];
    }, [])
    .compact()
    .join('')
    .value();
}

/**
 * Returns a list of one custom label for a lane if the lane is configured to show a custom label.
 *
 * @param lane - the lane for which to return the custom label
 * @param trendValues
 * @returns The html for the custom label, or an empty string
 */
export function getLaneCustomLabel(lane: number, trendValues: TrendValuesForLabels): string {
  if (!_.isNull(lane) && trendValues.labelDisplayConfiguration.custom === LABEL_LOCATIONS.LANE) {
    const labelText = _.get(
      _.find(trendValues.labelDisplayConfiguration.customLabels, {
        location: LABEL_LOCATIONS.LANE,
        target: lane,
      }),
      'text',
    );

    if (labelText) {
      return `<span class="text-with-shadow">${DOMPurify.sanitize(labelText)}</span>`;
    }
  }
  return '';
}

/**
 * Returns a list of one custom label for the axis if the axis is configured to show a custom label.
 *
 * @param alignment - the axis for which to return the custom label
 * @param trendValues
 * @returns an array of one custom label or an empty array
 */
function getAxisCustomLabel(alignment: string, trendValues: TrendValuesForLabels): { text: string }[] {
  if (trendValues.labelDisplayConfiguration.custom === LABEL_LOCATIONS.AXIS) {
    const customLabel = _.get(
      _.find(trendValues.labelDisplayConfiguration.customLabels, {
        location: LABEL_LOCATIONS.AXIS,
        target: alignment,
      }),
      'text',
    );

    if (customLabel) {
      return [{ text: customLabel }];
    }
  }
  return [];
}

/**
 * Returns a list of labels for the items in a lane.
 *
 * @param items - Array of items displayed by the chart
 * @param lane - the lane for which to return labels
 * @param trendValues
 * @returns an array of labels to display in the lane
 */
function getLaneSeriesLabels(items: any[], lane: number, trendValues: TrendValuesForLabels): LabelDefinition[] {
  const seriesInLane = _.chain(items)
    .filter((item: any) => _.includes([ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR, ITEM_TYPES.TABLE], item.itemType))
    .filter((item) => (!_.isNull(lane) ? _.get(item, 'lane') === lane : item))
    .filter(
      (item) =>
        !item.childType ||
        _.includes([ITEM_CHILDREN_TYPES.METRIC_DISPLAY, ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE], item.childType),
    )
    .uniqBy((item) => item.interestId || item.id)
    .reject(_.isNil)
    .map((item) => decorate({ items: item }))
    .value();

  if (
    trendValues.labelDisplayConfiguration.unitOfMeasure === LABEL_LOCATIONS.LANE &&
    trendValues.labelDisplayConfiguration.line !== LABEL_LOCATIONS.LANE &&
    trendValues.labelDisplayConfiguration.asset !== LABEL_LOCATIONS.LANE &&
    trendValues.labelDisplayConfiguration.name !== LABEL_LOCATIONS.LANE &&
    seriesInLane.length > 1 &&
    _.every(seriesInLane, (item) => item.displayUnitOfMeasure.value === seriesInLane[0].displayUnitOfMeasure.value) &&
    seriesInLane[0].displayUnitOfMeasure.value
  ) {
    const { value } = seriesInLane[0].displayUnitOfMeasure;
    return [
      {
        text: value,
        html: `<span class="text-with-shadow">${value}</span>`,
        isListItem: true,
      },
    ];
  } else if (
    trendValues.labelDisplayConfiguration.name === LABEL_LOCATIONS.LANE ||
    trendValues.labelDisplayConfiguration.asset === LABEL_LOCATIONS.LANE ||
    trendValues.labelDisplayConfiguration.line === LABEL_LOCATIONS.LANE ||
    trendValues.labelDisplayConfiguration.unitOfMeasure === LABEL_LOCATIONS.LANE
  ) {
    return _.chain(seriesInLane)
      .map(function (s: any) {
        let unitOfMeasure = null;
        let laneDisplayText = '';
        let dashStyle = null;
        const name = s.name || i18next.t('PREVIEW');

        if (trendValues.labelDisplayConfiguration.name === LABEL_LOCATIONS.LANE) {
          laneDisplayText = DOMPurify.sanitize(name);
        }

        if (trendValues.labelDisplayConfiguration.asset === LABEL_LOCATIONS.LANE && !_.isEmpty(s.assets)) {
          const assetNames = getAssetNames(s, trendValues.labelDisplayConfiguration.assetPathLevels);
          if (laneDisplayText) {
            laneDisplayText = assetNames.concat(ASSET_PATH_SEPARATOR, laneDisplayText);
          } else {
            laneDisplayText = assetNames;
          }
        }

        if (trendValues.labelDisplayConfiguration.line === LABEL_LOCATIONS.LANE) {
          dashStyle = s.dashStyle;
        }

        if (trendValues.labelDisplayConfiguration.unitOfMeasure === LABEL_LOCATIONS.LANE) {
          let displayUnitOfMeasure = s.displayUnitOfMeasure;
          if (s.calculationType && s.calculationType === TREND_TOOLS.FFT_TABLE) {
            displayUnitOfMeasure = { isRecognized: true, value: s.outputUnits };
          }

          if (displayUnitOfMeasure && displayUnitOfMeasure.value) {
            unitOfMeasure = laneDisplayText
              ? {
                  ...displayUnitOfMeasure,
                  value: ` (${displayUnitOfMeasure.value})`,
                }
              : displayUnitOfMeasure;
          }
        }

        if (!laneDisplayText && !unitOfMeasure && !dashStyle) {
          return;
        }

        const returnLabel = displayWithUnits(
          {
            text: laneDisplayText,
            unitOfMeasure,
            dashStyle,
            color: getLabelColorFromItem(s, trendValues),
          },
          { attributes: 'class="text-with-shadow"' },
        );

        return { ...returnLabel, isListItem: true };
      })
      .compact()
      .value();
  }

  return [];
}

/**
 * Returns a list of labels for the items on an axis.
 *
 * @param items - Array of items displayed by the chart
 * @param series - Array of Series objects
 * @param trendValues
 * @returns an array of labels to display on the axis
 */

function getAxisSeriesLabels(items, series, trendValues: TrendValuesForLabels): any[] {
  const seriesLabels = _.chain(series)
    .filter(
      (item) =>
        !item.childType ||
        _.includes([ITEM_CHILDREN_TYPES.METRIC_DISPLAY, ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE], item.childType),
    )
    .uniqBy((item) => item.interestId || item.id)
    .map((item) => decorate({ items: item }))
    .map(function (s) {
      let axisDisplayText = '';
      let dashStyle = null;
      const name = s.name || i18next.t('PREVIEW');

      if (trendValues.labelDisplayConfiguration.name === LABEL_LOCATIONS.AXIS) {
        axisDisplayText = name;
      }

      if (trendValues.labelDisplayConfiguration.asset === LABEL_LOCATIONS.AXIS && !_.isEmpty(s.assets)) {
        const assetNames = getAssetNames(s, trendValues.labelDisplayConfiguration.assetPathLevels);
        if (axisDisplayText) {
          axisDisplayText = assetNames.concat(ASSET_PATH_SEPARATOR, axisDisplayText);
        } else {
          axisDisplayText = assetNames;
        }
      }

      if (trendValues.labelDisplayConfiguration.line === LABEL_LOCATIONS.AXIS) {
        dashStyle = s.dashStyle;
      }

      let unitOfMeasure = null;
      if (trendValues.labelDisplayConfiguration.unitOfMeasure === LABEL_LOCATIONS.AXIS) {
        const displayUnitOfMeasure = s.displayUnitOfMeasure;

        if (displayUnitOfMeasure && displayUnitOfMeasure.value) {
          unitOfMeasure =
            axisDisplayText && displayUnitOfMeasure
              ? {
                  ...displayUnitOfMeasure,
                  value: ` (${displayUnitOfMeasure.value})`,
                }
              : displayUnitOfMeasure;
        }
      }

      if (axisDisplayText || unitOfMeasure || dashStyle) {
        return {
          text: axisDisplayText,
          unitOfMeasure,
          dashStyle,
          isListItem: true,
          color: getLabelColorFromItem(s, trendValues),
        };
      } else {
        return undefined;
      }
    })
    .compact()
    .value();

  // If we have identical labels from all the series, combine them and remove their colors to simplify things
  if (
    seriesLabels.length > 1 &&
    _.every(seriesLabels, (label) => _.isEqual(_.omit(label, ['color']), _.omit(seriesLabels[0], ['color'])))
  ) {
    return [_.omit(seriesLabels[0], ['color'])];
  }
  return seriesLabels;
}

/**
 * Returns a list of one configuration label for a lane if the lane is configured to show its configuration.
 *
 * @param lane - the lane for which to return the configuration label
 * @param trendValues
 * @returns the configuration label html or an empty string
 */
function getLaneConfigLabel(lane: number, trendValues: TrendValuesForLabels): string {
  if (!_.isNull(lane) && trendValues.customizationMode !== CUSTOMIZATION_MODES.OFF) {
    const text = `(Lane ${DOMPurify.sanitize(lane)})`;
    return `<span class="text-with-shadow">${text}</span>`;
  }

  return '';
}

/**
 * Returns a label with HTML for the passed in label without HTML.
 *
 * @param {Object} label - a label without HTML defined
 * @param {Object} options - Additional attributes to put in the html of the label
 * @returns {LabelDefinition} the label with defined HTML
 */
function displayWithUnits(label, options = { attributes: '' }): LabelDefinition {
  let unitOfMeasure = '';
  let dashStyle = '';
  if (label.unitOfMeasure) {
    const style = label.unitOfMeasure.isRecognized
      ? `color: ${label.color};`
      : `font-style: italic; color: ${label.color};`;
    unitOfMeasure = `<span style="${style}">${label.unitOfMeasure.value}</span>`;
  }

  if (label.dashStyle) {
    dashStyle = `<i class="fc fc-${_.kebabCase(label.dashStyle)} mr2 align-middle"></i>`;
  }

  return {
    html: `<span ${options.attributes}${label.color ? ` style="color: ${label.color};"` : ''}>${dashStyle}${
      label.text
    }${unitOfMeasure}</span>`,
    text: `${label.text}${label.unitOfMeasure ? label.unitOfMeasure.value : ''}`,
  };
}

/**
 * Returns the tick positions to be used for the Highcharts Axis labels.
 *
 * @param min - the y-axis min property
 * @param max - the y-axis max property
 * @param item - Object representing an item
 * @param {Number} item.yAxisMin - the yAxisMin set either via user input (customize) or based on the lane count.
 *   This is the cut-off used for the lower y-axis value.
 * @param {Object} item.yAxisMax - the yAxisMax set either via user input (customize) or based on the lane count.
 *   This is the cut-off used for the lower y-axis value.
 * @param laneHeight - the height of the lane that is available for signal display in pixels.
 * @param laneCount - the number of lanes displayed.
 * @returns array of tick positions.
 */
export function getNumericTickPositions(
  min: number,
  max: number,
  item: any,
  laneHeight: number,
  laneCount: number,
): number[] {
  const collapseLabelsLaneHeight = 130;
  const tickPositions = [];
  const labelBuffer = TREND_BUFFER_FACTOR_LABEL * laneCount;
  const range = max - min;

  // Return to let highcharts handle the tick positions for logarithmic axes
  if (!item || item.yAxisType === Y_AXIS_TYPES.LOGARITHMIC) {
    return;
  }

  const showExtremes = laneHeight < collapseLabelsLaneHeight;
  const constantSpacing = showExtremes;

  const { stepStart, stepSize, numberOfTicks, precision } = fetchTickAttributes(
    min,
    max,
    item.formatOptions,
    laneHeight,
  );
  const padding = labelBuffer * range;

  const minTickPosition = Number(item.yAxisMin);
  const maxTickPosition = Number(item.yAxisMax);

  if (showExtremes) {
    tickPositions.push(minTickPosition);
  }

  for (let i = 0; i <= numberOfTicks; i++) {
    let tickPosition;
    if (constantSpacing) {
      tickPosition = minTickPosition + (i * (maxTickPosition - minTickPosition)) / numberOfTicks;
    } else {
      tickPosition = roundWithPrecision(stepStart + i * stepSize, precision);
    }

    if (showExtremes && laneCount === 1) {
      if (tickPosition <= minTickPosition || tickPosition >= maxTickPosition) {
        continue;
      }

      if (
        Math.abs(tickPosition - minTickPosition) < stepSize * 0.5 ||
        Math.abs(tickPosition - maxTickPosition) < stepSize * 0.5
      ) {
        continue;
      }
    }

    if (tickPosition >= minTickPosition - padding && tickPosition <= maxTickPosition + padding) {
      tickPositions.push(tickPosition);
    }
  }

  if (showExtremes) {
    tickPositions.push(maxTickPosition);
  }
  return tickPositions;
}

/**
 * Fetches the tickAttributes that describe how labels should be formatted.
 * tickAttributes are cached up to maxStoredTickAttributes.
 *
 * @param min - the axis minimum
 * @param max - the axis maximum
 * @param formatOptions - the number formatting options to be used with the label
 * @param laneHeight - the height of the lane
 * @returns an Object describing how the axis labels (ticks) should be rendered.
 */
export function fetchTickAttributes(min: number, max: number, formatOptions: FormatOptions, laneHeight: number): any {
  const storedTickAttributes = _.find(tickAttributesConfig, {
    min,
    max,
    laneHeight,
  });

  let tickAttributes;
  if (_.isEmpty(storedTickAttributes)) {
    tickAttributes = calculateTickAttributesForChart(laneHeight, min, max);
    tickAttributesConfig.push({ tickAttributes, min, max, laneHeight });
  } else {
    tickAttributes = storedTickAttributes.tickAttributes;
  }

  if (tickAttributesConfig.length > maxStoredTickAttributes) {
    tickAttributesConfig.shift();
  }
  tickAttributes.formatOptions = formatOptions;
  return tickAttributes;
}

/**
 * Formats a given value according to the tickAttributes definition.
 *
 * @param value - the yaxis value
 * @param tickAttributes - Object defining how to display the value
 * @returns formatted, display ready y-axis label.
 */
export function formatYAxisTick(value: number, tickAttributes: any): string {
  if (!_.isFinite(value)) {
    return '';
  }

  let number;
  const isAutoFormat = _.get(tickAttributes.formatOptions, 'format', defaultNumberFormat()) === AUTO_FORMAT;
  if (isAutoFormat) {
    if (tickAttributes.precision > 0) {
      number = value.toFixed(tickAttributes.precision);
    } else {
      number = roundWithPrecision(value, 0);
      if (Math.abs(number) >= 1000000) {
        // Large numbers displayed using exponential notation in powers of 3
        number = formatNumber(number, { format: '##0.0E+0' });
      }
    }
  } else {
    number = formatNumber(value, tickAttributes.formatOptions);
  }

  if (number !== undefined) {
    // We don't need to sanitize this since it is immediately rendered as svg text through highcharts
    return number.toString();
  }
}

/**
 * Returns the appropriate String display value for a given y-axis value.
 *
 * @param value - the y-axis value
 * @param series - the series the axis belongs to
 * @param series.stringEnum - Array of Objects defining the string value pairs
 * @param format
 * @returns formatted, display ready y-axis label.
 */
export function formatStringYAxisLabel(value: number, series: any, format?: string): string {
  // We don't need to sanitize this since it is immediately rendered as svg text through highcharts
  return formatString(_.result(_.find(series.stringEnum, { key: value }), 'stringValue', ''), { format });
}

/**
 * Create an axis that can be used for the lanes 'back drop'. This is it's own axis
 * as the background shouldn't be turned off with the axis visibility.
 *
 * @returns A Highcharts y-axis definition.
 */
export function createPlotBandAxisDefinition(): Highcharts.YAxisOptions {
  return {
    id: PLOT_BAND_AXIS_ID,
    // NOTE: don't use the 'top' property or the bug logged in CRAB-4044 will return.
    gridLineWidth: 0,
    lineWidth: 0,
    zIndex: Z_INDEX.Y_AXIS_DEFINITION, // ensure vertical axis line is on top of the chain view drawings
    visible: true,
    opposite: false,
    labels: {
      enabled: false,
    },
    startOnTick: false,
    endOnTick: false,
    title: {
      text: undefined,
    },
    offset: 0,
  };
}

/**
 * Returns an id for a capsule axis.
 *
 * @param id - the id of the capsule set
 * @returns to be used to identify a capsule axis
 */
export function getCapsuleAxisId(id: string): string {
  return `${TREND_TOP_Y_AXIS_ID}_${getCertainId(id)}`;
}

type CreateYAxisDefinitionParams = {
  /** the item we need to get a y-axis for */
  item;
  /** yAxisStringFormatter function. */
  yAxisStringFormatter: () => string;
  /** yAxisFormatter function */
  yAxisFormatter: () => string;
  tickPositioner: () => number[];
  trendValues: TrendValuesForLabels;
};

/**
 * Helper function that creates a y-axis definition for the given item.
 */
export function createYAxisDefinition({
  item,
  yAxisStringFormatter,
  yAxisFormatter,
  tickPositioner,
  trendValues,
}: CreateYAxisDefinitionParams): Highcharts.YAxisOptions {
  let labels;
  const visible = _.get(item, 'axisVisibility', true);

  const labelColor = getLabelColorFromItem(item, trendValues);
  if (item.isStringSeries) {
    labels = {
      formatter: yAxisStringFormatter,
      enabled: visible,
      align: 'right',
      x: -5,
      y: -2,
      style: {
        color: labelColor,
        overflow: 'visible',
        textOverflow: 'none',
        whiteSpace: 'nowrap',
        minWidth: '10px',
      },
      useHTML: false, // never set to true as we rely on this to prevent XSS attacks on labels
    };
  } else {
    labels = {
      formatter: yAxisFormatter,
      enabled: visible,
      align: 'right',
      x: -5,
      style: {
        color: labelColor,
        minWidth: '10px',
      },
      useHTML: false, // never set to true as we rely on this to prevent XSS attacks on labels
    };
  }

  const yAxis = {
    id: getYAxisIdFromItem(item),
    lane: item.lane,
    // NOTE: don't use the 'top' property or the bug logged in CRAB-4044 will return.
    gridLineWidth: getGridlineWidth(trendValues.showGridlines),
    lineColor: labelColor,
    zIndex: Z_INDEX.Y_AXIS_DEFINITION, // ensure that vertical axis line is on top of chain view drawings
    startOnTick: false,
    endOnTick: false,
    title: {
      text: undefined,
    },
    labels,
    visible,
    opposite: false,
    yAxisMin: item.yAxisMin,
    yAxisMax: item.yAxisMax,
    custom: {
      allowNegativeLog: true,
    },
  };

  item.yAxis = yAxis.id;

  if (item.isStringSeries) {
    _.assign(yAxis, { tickPositions: _.map(item.stringEnum, 'key').sort() });
  } else {
    _.assign(yAxis, { tickPositioner, type: item.yAxisType });
  }

  if (_.startsWith(item.id, PREVIEW_ID)) {
    _.assign(yAxis, {
      plotBands: [
        {
          color: PREVIEW_HIGHLIGHT_COLOR,
          from: item.yAxisMin - 0.8,
          to: item.yAxisMax + 0.8,
        },
      ],
    });
  }
  return yAxis;
}

/**
 * Helper function that returns a capsule set axis definition.
 *
 * @param item - the item we need an axis for
 * @param axisId - the id for the axis.
 * @returns a Highcharts axis definition.
 */
export function getCapsuleAxisDefinition(item: any, axisId: string): Highcharts.YAxisOptions {
  return {
    id: axisId,
    lineWidth: 0,
    endOnTick: false,
    startOnTick: false,
    reversed: true,
    seeqDisallowZoom: true,
    capsuleSetId: item.capsuleSetId,
    customValue: item.yValue,
    offset: 0,
    title: {
      text: null,
    },
    labels: {
      enabled: false,
    },
    gridLineWidth: 0,
    min: 0,
    max: item.yValue,
  };
}

/**
 * Returns the width the labels of an axis will take.
 * This returns the width of the axis ticks with appropriate padding.
 *
 * @param tickPositions - array of where to place the ticks
 * @param axis - Highcharts axis Object
 * @param laneHeight - height of the lane available for trend display
 * @param padding - padding to be added to the label display.
 * @param isStringSeries - True if it is a string series
 * @param series - The series
 * @returns width of an axis with labels. This can be used to set the axis offset.
 */
export function getLabelWidth(
  tickPositions: number[],
  axis: any,
  laneHeight: number,
  padding: number,
  isStringSeries: boolean,
  series?: any,
): number {
  let chars = 1;
  if (!_.isEmpty(tickPositions)) {
    chars = _.chain(tickPositions)
      .map((pos) => {
        if (isStringSeries) {
          return formatStringYAxisLabel(pos, series, axis.userOptions.formatOptions?.stringFormat);
        } else {
          return formatYAxisTick(
            pos,
            fetchTickAttributes(
              axis.userOptions.yAxisMin,
              axis.userOptions.yAxisMax,
              axis.userOptions.formatOptions,
              laneHeight,
            ),
          );
        }
      })
      .reduce((maxChars, label) => {
        label = !label || label === 'undefined' ? '' : label;
        return maxChars > label.length ? maxChars : label.length;
      }, 0)
      .value();
  } else if (axis.logarithmic) {
    chars = _.chain([axis.userMax, axis.userMin])
      .map((num) => roundWithPrecision(num, 2).toString().length)
      .max()
      .value();
  }

  return chars * Y_AXIS_LABEL_CHARACTER_WIDTH + padding;
}

/**
 * Calculates the ideal tick attributes based on the chartHeight, the min and max axis values of
 * the item and the overall number of lanes displayed,
 *
 * @param laneHeight - the height of a lane on the chart in pixels
 * @param  yAxisMin - yAxis Minimum. Value in Axis Units that represents the lower boundary
 * of the lane the item is displayed on
 * @param yAxisMax - yAxis Maximum. Value in Axis Units that represents the upper boundary
 * of the lane the item is displayed on.
 * @return an Object that provides necessary information to properly place labels.
 */
export function calculateTickAttributesForChart(
  laneHeight: number,
  yAxisMin: number,
  yAxisMax: number,
): {
  stepSize: number;
  precision: number;
  stepStart: number;
  numberOfTicks: number;
} {
  let stepStart;
  const tickSpacing = 35;
  const numberOfTicks = Math.round(laneHeight / tickSpacing);
  const range = yAxisMax - yAxisMin;
  const tickAttributes = calculateTickAttributes(range, numberOfTicks);

  stepStart = yAxisMin - fmod(yAxisMin, tickAttributes.stepSize);

  return {
    stepSize: tickAttributes.stepSize,
    precision: tickAttributes.precision,
    stepStart,
    numberOfTicks: _.isFinite(numberOfTicks) ? numberOfTicks : 1,
  };
}

/**
 * Calculates an optimal step size and precision given a range and  the desired number of steps (aka ticks)
 * Taken from http://stackoverflow.com/a/15071978 but modified to some degree
 *
 * @param range - the range of the y-axis (max-min)
 * @param targetSteps - the number of ticks to display
 * @return Object that returns step size and precision
 */
export function calculateTickAttributes(range: number, targetSteps: number): { stepSize: number; precision: number } {
  let stepSize;
  // calculate an initial guess at step size
  const tempStep = range / targetSteps;
  const ln10 = Math.log(10);
  // get the magnitude of the step size
  const mag = Math.floor(Math.log(tempStep) / ln10);
  const magPow = Math.pow(10, mag);
  let precision = mag * -1;

  // calculate most significant digit of the new step size
  let magMsd = Math.round(tempStep / magPow + 0.5);

  // promote the MSD to either 2, 5, or 10
  if (magMsd > 5.0) {
    magMsd = 10.0;
  } else if (magMsd > 2.0) {
    magMsd = 5.0;
  } else if (magMsd > 1.0) {
    magMsd = 2.0;
  }

  stepSize = magMsd * magPow;
  stepSize = _.isFinite(stepSize) ? stepSize : 1;
  precision = _.isFinite(precision) ? precision : 1;
  return { stepSize, precision };
}

/**
 * Get a value for gridline width
 *
 * @return returns 1 if we want to show gridlines, 0 if we don't
 */
export function getGridlineWidth(showGridlines: boolean): number {
  return showGridlines ? 1 : 0;
}

/**
 * Floating-point modulo function.
 * Taken from https://gist.github.com/wteuber/6241786
 *
 * @param a - the dividend
 * @param b - the divisor
 * @return returns the remainder of a divided by b.
 */
function fmod(a: number, b: number): number {
  return Number((a - Math.floor(a / b) * b).toPrecision(8));
}

/**
 * Determines the color for the label based on the item. If in capsule mode and in a coloring mode where it
 * doesn't make sense to show the item's color it returns undefined so that it will show as the default neutral color.
 *
 * @param item - The item assigned to the lane or axis
 * @param trendValues
 * @return The item's color or undefined if in a specific capsule time color mode
 */
export function getLabelColorFromItem(item: any, trendValues: TrendValuesForLabels): string | undefined {
  const colorMode = trendValues.isCompareMode ? trendValues.compareViewColorMode : trendValues.capsuleTimeColorMode;

  return trendValues.view === TREND_VIEWS.CAPSULE &&
    _.includes([CapsuleTimeColorMode.Rainbow, CapsuleTimeColorMode.ConditionGradient], colorMode)
    ? undefined
    : item.color;
}

export function anyLabelsOnLocation(labelDisplayConfiguration: LabelDisplayConfiguration, location: LABEL_LOCATIONS) {
  return (
    labelDisplayConfiguration.unitOfMeasure === location ||
    labelDisplayConfiguration.asset === location ||
    labelDisplayConfiguration.name === location ||
    labelDisplayConfiguration.line === location ||
    labelDisplayConfiguration.custom === location
  );
}
