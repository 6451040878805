// @ts-strict-ignore
import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { DurationActions } from '@/trendData/duration.actions';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { TrendActions } from '@/trendData/trend.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ChartView, ChartViewActions, ChartViewConfiguration, ChartViewData } from './ChartView.organism';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useFluxEffect } from '@/hybrid/core/hooks/useFluxEffect.hook';
import _ from 'lodash';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import {
  sqAnnotationStore,
  sqDurationStore,
  sqTrendCapsuleStore,
  sqTrendSeriesStore,
  sqCursorStore,
  sqTrendCapsuleSetStore,
  sqTrendStore,
} from '@/core/core.stores';
import { serializeRange } from '@/hybrid/datetime/dateTime.utilities';
import {
  CAPSULE_PANEL_TREND_COLUMNS,
  PropertyColumn,
  TREND_PANELS,
  TREND_SIGNAL_STATS,
  TREND_VIEWS,
} from '@/trendData/trendData.constants';

const chartBindings = bindingsDefinition({
  breaks: prop<Object[]>(),
  capsuleTimeOffsets: prop<{ lower: number; upper: number }>(),
  clearPointerValues: prop<() => void>(),
  isCapsuleTime: prop<boolean>(),
  isDimmed: prop<boolean>(),
  isPickingMode: prop<boolean>(),
  items: prop<any>(),
  pickSelectedRegion: prop<() => void>(),
  removeSelectedRegion: prop<() => void>(),
  selectItems: prop<(item: Object, items: Object[], e: Object) => void>(),
  selectedRegion: prop<{ min: number; max: number }>(),
  selectedTimezone: prop<{
    name: string;
    displayName: string;
    offset: string;
    offsetMinutes: number;
  }>(),
  setPointerValues: prop<(xValue: number, yValues: any[]) => void>(),
  setSelectedRegion: prop<(min: number, max: number, option: string) => void>(),
  setYExtremes: prop<(extremes: { min: number; max: number; axisAlign: string }[]) => void>(),
  summaryLabel: prop.optional<string>(),
  trendEnd: prop<number>(),
  trendStart: prop<number>(),
  view: prop<TREND_VIEWS>(),
  // actions
  sqDurationActions: injected<DurationActions>(),
  sqTrendActions: injected<TrendActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqAnnotationActions: injected<AnnotationActions>(),
});

export const Chart: SeeqComponent<typeof chartBindings> = (props) => {
  const {
    breaks,
    capsuleTimeOffsets,
    clearPointerValues,
    isCapsuleTime,
    isDimmed,
    isPickingMode,
    items,
    pickSelectedRegion,
    removeSelectedRegion,
    selectItems,
    selectedRegion,
    selectedTimezone,
    setPointerValues,
    setSelectedRegion,
    setYExtremes,
    summaryLabel,
    trendEnd,
    trendStart,
    view,
  } = props;
  const { sqDurationActions, sqTrendActions, sqInvestigateActions, sqAnnotationActions } =
    useInjectedBindings(chartBindings);

  const annotatedItemIds = useFluxPath(sqAnnotationStore, (sqAnnotationStore) => sqAnnotationStore.annotatedItemIds);
  const autoUpdateMode = useFluxPath(sqDurationStore, (sqDurationStore) => sqDurationStore.autoUpdate.mode);
  const longestCapsuleSeriesDuration = useFluxPath(
    sqTrendSeriesStore,
    (sqTrendSeriesStore) => sqTrendSeriesStore.longestCapsuleSeriesDuration,
  );
  const capsuleEditingId = useFluxPath(sqTrendCapsuleStore, (sqTrendCapsuleStore) => sqTrendCapsuleStore.editingId);
  const [changes, cursorStoreChanged] = useFluxEffect(sqCursorStore);
  const capsuleTimeColorMode = useFluxPath(sqTrendStore, (sqTrendStore) => sqTrendStore.capsuleTimeColorMode);
  const labelDisplayConfiguration = useFluxPath(sqTrendStore, (sqTrendStore) => sqTrendStore.labelDisplayConfiguration);
  const showCapsuleLaneLabels = useFluxPath(sqTrendStore, (sqTrendStore) => sqTrendStore.showCapsuleLaneLabels);
  const customizationMode = useFluxPath(sqTrendStore, (sqTrendStore) => sqTrendStore.customizationMode);
  const showGridlines = useFluxPath(sqTrendStore, (sqTrendStore) => sqTrendStore.showGridlines);
  const lanes = useFluxPath(sqTrendStore, (sqTrendStore) => sqTrendStore.lanes);

  // CapsuleTimeLegend props needed at this level so they can be captured for interactive trend in ChartViewWrapper
  const propertyColumns = useFluxPath(sqTrendStore, () =>
    _.map(sqTrendStore.propertyColumns(TREND_PANELS.CAPSULES), (column) => ({
      ...column,
      style: 'string',
      title: column.propertyName,
    })),
  );
  const customColumns = useFluxPath(sqTrendStore, () =>
    _.map(sqTrendStore.customColumns(TREND_PANELS.CAPSULES), (column) =>
      _.assign(
        { series: sqTrendSeriesStore.findItem(column.referenceSeries) },
        _.find(TREND_SIGNAL_STATS, ['key', column.statisticKey]),
        column,
      ),
    ),
  );
  const { items: capsules } = useFlux(sqTrendCapsuleStore);
  const sortBy = useFluxPath(sqTrendStore, () => sqTrendStore.getPanelSort(TREND_PANELS.CAPSULES).sortBy);
  const allColumns = CAPSULE_PANEL_TREND_COLUMNS.concat(propertyColumns).concat(customColumns);
  const sortedColumn = _.find(allColumns, { key: sortBy }) || ({} as PropertyColumn);

  const chartViewProps: {
    actions: ChartViewActions;
    configuration: ChartViewConfiguration;
    data: ChartViewData;
  } = {
    actions: {
      clearPointerValues,
      findCapsuleItem: sqTrendCapsuleStore.findItem,
      findChartCapsule: sqTrendCapsuleStore.findChartCapsule,
      loadToolForEdit: sqInvestigateActions.loadToolForEdit,
      pickSelectedRegion,
      removeSelectedRegion,
      selectItems,
      setCapsuleTimeOffsets: sqTrendActions.setCapsuleTimeOffsets,
      setPointerValues,
      setSelectedRegion,
      setYExtremes,
      showAnnotationEntry: sqAnnotationActions.showEntry,
      updateDisplayRangeTimes: sqDurationActions.displayRange.updateTimes,
    },
    configuration: {
      autoUpdateMode,
      capsuleTimeColorMode,
      capsuleTimeOffsets,
      isCapsuleTime,
      isDimmed,
      isPickingMode,
      labelDisplayConfiguration,
      longestCapsuleSeriesDuration,
      showCapsuleLaneLabels,
      customizationMode,
      trendEnd,
      trendStart,
      view,
      showGridlines,
      isInTopic: false,
    },
    data: {
      annotatedItemIds,
      breaks,
      capsuleEditingId,
      items,
      lanes,
      selectedRegion,
      selectedTimezone,
      summaryLabel,
      serializedDateRange: serializeRange(sqDurationStore.displayRange),
      capsules,
      sortedColumn,
      cursorChange: cursorStoreChanged,
      sqTrendStoreData: _.pick(
        sqTrendStore,
        'view',
        'dimDataOutsideCapsules',
        'capsuleTimeOffsets',
        'lanes',
        'labelDisplayConfiguration',
        'capsuleTimeColorMode',
        'customizationMode',
        'showGridlines',
        'showCapsuleLaneLabels',
        'isCompareMode',
        'compareViewColorMode',
      ),
      sqDurationStoreData: _.pick(sqDurationStore, 'displayRange', 'autoUpdate'),
      sqCursorStoreData: _.pick(sqCursorStore, 'calendarCursors', 'capsuleCursors', 'showValues'),
      sqTrendCapsuleSetStoreData: { items: sqTrendCapsuleSetStore.items },
    },
  };

  return <ChartView {...chartViewProps} />;
};
