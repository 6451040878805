// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Ace } from '@/hybrid/accessControl/itemAclModal.utilities';
import { readableIdentifier } from '@/hybrid/utilities/utilities';

interface AcePopoverProps {
  ace: Ace;
}

export const AcePopover: React.FunctionComponent<AcePopoverProps> = (props) => {
  const { ace } = props;

  const { t } = useTranslation();

  const redactedMessage = <div className="flexRowContainer">{t('ITEM_ACL.TOOLTIP_REDACTED_IDENTITY')}</div>;

  const disabledMessage = <div className="flexRowContainer">{t('ITEM_ACL.TOOLTIP_DISABLED_IDENTITY')}</div>;

  const username = () => (
    <div className="aggressiveWordBreak">
      <span className="text-bolder">{t('ITEM_ACL.USERNAME')}</span>
      <span className="ml5">{readableIdentifier(ace.identity)}</span>
    </div>
  );

  const aceDetails = () => (
    <div className="flexRowContainer">
      {ace.identity.type === 'User' && readableIdentifier(ace.identity) && username()}
      <div className="textAlignLeft">
        <span className="text-bolder">{t('ITEM_ACL.DATASOURCE')}</span>
        <span className="ml5">{ace.identity.datasource.name}</span>
      </div>
    </div>
  );

  return (
    <div className="p5">
      {ace.identity.isRedacted && redactedMessage}
      {!ace.identity.isRedacted && !ace.identity.isEnabled && disabledMessage}
      {!ace.identity.isRedacted && ace.identity.isEnabled && aceDetails()}
    </div>
  );
};
