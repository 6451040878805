import _ from 'lodash';
import { headlessRenderMode } from '@/hybrid/utilities/utilities';

export enum HeadlessCategory {
  Screenshot = 'Screenshot',
  Thumbnail = 'Thumbnail',
}

let captureMetadata: { category?: HeadlessCategory } = {};

/**
 * Indicates what category of job this headless browser page is mapped to.
 *
 * @returns the category of the job for which this page is rendering. If this page is not rendering in a headless
 *   browser, then this function returns undefined
 */
export function headlessRenderCategory() {
  return captureMetadata.category;
}

/**
 * Test if {@param category} is a HeadlessCategory
 */
export function isHeadlessCategory(category: unknown): category is HeadlessCategory {
  return _.isString(category) && Object.values<string>(HeadlessCategory).includes(category);
}

/**
 * Fetches the category of headless capture being requested so that it can be accessed synchronously in the page load
 */
export function fetchHeadlessCaptureMetadata() {
  if (!headlessRenderMode()) {
    return Promise.resolve();
  }

  return Promise.resolve(window.seeqHeadlessCategory()).then((category) => {
    if (!isHeadlessCategory(category)) {
      throw new Error(`Error: '${category}' category is unexpected; the frontend enum likely needs to be updated`);
    }
    captureMetadata = {
      category,
    };
  });
}
