// @ts-strict-ignore
import _ from 'lodash';
import classNames from 'classnames';
import tinycolor from 'tinycolor2';
import React, { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import addTreemapModule from 'highcharts/modules/treemap';
import Highcharts, { ColorString, GradientColorObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { getAllItems } from '@/hybrid/trend/trendDataHelper.utilities';
import { TrendActions } from '@/trendData/trend.actions';
import { useTranslation } from 'react-i18next';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import useResizableHighchart from '@/hybrid/core/hooks/useResizableHighchart.hook';
import { formatNumber } from '@/hybrid/utilities/numberHelper.utilities';
import { AssetOutputV1, ScalarValueOutputV1 } from '@/sdk';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { sqTreemapStore } from '@/core/core.stores';
import { setParent } from '@/treemap/treemap.actions';

/**
 * Represents an item in the tree for a Treemap.
 */
export interface TreemapItem {
  asset?: AssetOutputV1;
  color: ColorString;
  displayScalars: TreemapScalar[];
  size?: number;
  isLeafAsset?: boolean;
  isUncertain?: boolean;
  priority?: number;
}

/**
 * Frontend representation of a ScalarValue for a Treemap
 */
interface TreemapScalar extends ScalarValueOutputV1 {
  title?: string;
  signal?: any;
}

/**
 * An extended Highcharts.Point with the extra data that we use for custom functionality in Treemap
 */
interface SeeqTreemapChartPoint extends Highcharts.Point {
  defaultColor: ColorString;
  name: string;
  value: number;
  color: GradientColorObject;
  size?: number;
  isLeafAsset?: boolean;
  isUncertain: boolean;
  displayScalars: TreemapScalar[];
  priority?: number;
  asset?: AssetOutputV1;
}

const treemapChartBindings = bindingsDefinition({
  tree: prop<TreemapItem[]>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqTrendActions: injected<TrendActions>(),
});

addTreemapModule(Highcharts);

export const TreemapChart: SeeqComponent<typeof treemapChartBindings> = (props) => {
  const { tree } = props;
  const { sqWorksheetActions, sqTrendActions } = useInjectedBindings(treemapChartBindings);

  const { t } = useTranslation();
  const [chart, setChart] = useState(null);
  const chartElementRef = useRef(null);
  const [options, setOptions] = useState({} as Highcharts.Options);

  useEffect(() => {
    const newOptions: Highcharts.Options = {
      plotOptions: {
        series: {
          dataLabels: {
            allowOverlap: true,
          },
        },
      },
      series: [
        {
          type: 'treemap',
          animation: false,
          layoutAlgorithm: 'strip',
          cursor: 'pointer',
          borderWidth: 4,
          borderRadius: 5,
          borderColor: '#fff',
          dataLabels: {
            useHTML: true,
            align: 'left',
            verticalAlign: 'top',
            crop: false,
            style: {
              fontFamily: 'inherit',
            },
            formatter() {
              const point = this.point as SeeqTreemapChartPoint;
              /* When we use gradient color for the nodes, the label colors ar not correctly set anymore */
              const textColor = tinycolor(point.defaultColor).isLight() ? '#000' : '#fff';

              const displayScalars = point.displayScalars;
              let details;
              if (!_.isEmpty(displayScalars)) {
                const formattedDisplayScalars = _.groupBy(displayScalars, 'signal');
                details = (
                  <div className="label-details-container">
                    {_.map(formattedDisplayScalars, (scalars, signal) => (
                      <div className="statistic-info" key={signal}>
                        <strong>{signal}</strong>
                        {_.map(scalars, (scalar) => (
                          <div className="statistic-details" key={scalar.title}>
                            {t(scalar.title)}:{' '}
                            {_.isNumber(scalar.value) ? `${formatNumber(scalar.value)} ${scalar.uom}` : scalar.value}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                );
              }

              const labelHeight = _.get(this.point, 'graphic.element.height.baseVal.value');
              const label = (
                <div
                  className="label-container"
                  data-testid={`highcharts-point-${_.kebabCase(this.point.name)}`}
                  style={{
                    color: textColor,
                    height: labelHeight ? labelHeight - 20 : 'auto',
                  }}>
                  <h4>{this.point.name}</h4>
                  {details}
                </div>
              );

              return renderToString(label);
            },
          },
          events: {
            click: (e) => {
              // we use cloneDeep here in order to avoid it mutating when the chart is redrawn by setView -
              // necessary only for systemTests because there the setView is executed immediately
              const point = _.cloneDeep(e.point as SeeqTreemapChartPoint);
              if (point.isLeafAsset) {
                sqWorksheetActions.setView(WORKSHEET_VIEW.TREND, false);
                const swapOutItemIds = _.chain(getAllItems({})).uniqBy('id').map('id').value();
                const swapPairs = _.mapValues(_.keyBy(swapOutItemIds), () => [
                  {
                    swapOut: sqTreemapStore.swap.id,
                    swapIn: point.asset.id,
                  },
                ]);
                sqTrendActions.swapAssets(swapPairs);
              } else {
                setParent(point.asset);
              }
            },
          },
          data: getFormattedData(),
        },
      ],
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
    };
    setOptions(newOptions);
  }, [tree]);

  const getNodeColor = (nodeData): GradientColorObject => {
    const nodeColor: GradientColorObject = {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 1 },
      stops: [
        [0.3, `${nodeData.color}ff`], // start
        [1, `${nodeData.color}7f`], // end
      ],
    };

    if (nodeData.isUncertain) {
      let i, count;
      const stopPoints = [];

      for (i = 0, count = 1; i <= 1; i += 0.03, count++) {
        const alphaColor = count % 2 ? 'ff' : '7f';
        // start, end colors
        stopPoints.push([i, `${nodeData.color}${alphaColor}`], [i + 0.03, `${nodeData.color}${alphaColor}`]);
      }
      nodeColor.stops = stopPoints;
    }

    return nodeColor;
  };

  const getFormattedData = () =>
    tree &&
    tree.map((data) => ({
      ...data,
      name: data.asset.name,
      value: data.size,
      id: data.asset.id,
      className: classNames(`highcharts-point-treemap highcharts-point-${_.kebabCase(data.asset.name)}`, {
        uncertain: data.isUncertain,
      }),
      defaultColor: data.color,
      // Linear gradient used as a color option
      color: getNodeColor(data),
    }));

  useEffect(() => {
    if (_.get(chart, 'series[0].points', []).length) {
      const pointsGraphics = chart.series[0].points.map((point: any) => point.graphic.element);
      pointsGraphics.forEach((elem, i) => {
        elem.setAttribute('data-testid', `highchart-point-testid-${_.lowerCase(elem.point.name).replace(' ', '-')}`);
      });
    }
  }, [chart?.series]);

  const afterChartCreated = (highchartsChart: Highcharts.Chart) => {
    setChart(highchartsChart);
  };

  useResizableHighchart({
    chart,
    chartElementRef,
    setChart,
  });

  return (
    <div className="flexFill flexRowContainer treemapContainer" ref={chartElementRef}>
      {!tree && (
        <div className="fs16 p25 flexSelfCenter width-400 text-center">
          <IconWithSpinner spinning={true} />
          {t('LOADING')}
        </div>
      )}
      {tree && !tree.length && (
        <div className="alert alert-info fs16 p25 flexSelfCenter width-400 text-center">
          {t('NO_RESULTS_DISPLAY_RANGE')}
        </div>
      )}
      {tree && !!tree.length && (
        <HighchartsReact
          useHTML={true}
          highcharts={Highcharts}
          options={options}
          callback={afterChartCreated}
          containerProps={{ style: { height: '100%' } }}
        />
      )}
    </div>
  );
};

export const sqTreemapChart = angularComponent(treemapChartBindings, TreemapChart);
