import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { LabelDisplayConfiguration } from '@/hybrid/utilities/label.utilities';
import { CompareView } from '@/hybrid/trend/CompareView.organism';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { sqCursorStore, sqScatterPlotStore, sqTrendSeriesStore, sqTrendStore } from '@/core/core.stores';
import { CompareViewColorMode } from '@/trendData/trendData.constants';
import { TrendActions } from '@/trendData/trend.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';

const compareViewWrapperBindings = bindingsDefinition({
  items: prop<any[]>(),
  labelDisplayConfiguration: prop<LabelDisplayConfiguration>(),
  sqTrendActions: injected<TrendActions>(),
});

export const CompareViewWrapper: SeeqComponent<typeof compareViewWrapperBindings> = ({
  items,
  labelDisplayConfiguration,
}) => {
  const { sqTrendActions } = useInjectedBindings(compareViewWrapperBindings);

  useFlux(sqCursorStore);
  useFlux(sqScatterPlotStore);

  const { firstColumn, colorByProperty, compareViewColorMode, customizationMode } = useFlux(sqTrendStore);

  const longestCapsuleSeriesDuration = useFluxPath(
    sqTrendSeriesStore,
    () => sqTrendSeriesStore.longestCapsuleSeriesDuration,
  );
  const isSignalColorMode = compareViewColorMode === CompareViewColorMode.Signal;

  return (
    <CompareView
      items={items}
      labelDisplayConfiguration={labelDisplayConfiguration}
      colorByProperty={colorByProperty}
      isSignalColorMode={isSignalColorMode}
      longestCapsuleSeriesDuration={longestCapsuleSeriesDuration}
      firstColumn={firstColumn}
      customizationMode={customizationMode}
      trendStoreData={sqTrendStore}
      sqTrendActions={sqTrendActions}
    />
  );
};
