import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import React from 'react';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { APP_STATE } from '@/main/app.constants';
import { sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { SandboxModeBanner } from '@/hybrid/header/SandboxModeBanner.molecule';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { setShowModal } from '@/reportEditor/pdfExport.actions';
import { canModifyWorkbook as authServiceCanModifyWorkbook } from '@/services/authorization.service';

const viewModeWorksheetActionsBindings = bindingsDefinition({
  isViewMode: prop<boolean>(),
  isEditMode: prop<boolean>(),
  $state: injected<ng.ui.IStateService>(),
});

export const ViewModeWorksheetActions: SeeqComponent<typeof viewModeWorksheetActionsBindings> = (props) => {
  const { isViewMode } = props;
  const { $state } = useInjectedBindings(viewModeWorksheetActionsBindings);

  const { t } = useTranslation();

  const { name, owner, effectivePermissions } = useFlux(sqWorkbookStore);
  const workbook = {
    name,
    owner,
    effectivePermissions,
  };

  const canModifyWorkbook = authServiceCanModifyWorkbook(workbook);
  const isReportBinder = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.isReportBinder);

  const onClickBadge = () => {
    // $state.go with { reload: true } doesn't fully reload the page here. So instead, we use href to force the page
    // to load. If we don't do this, state from view only mode can leak into edit mode, unintentionally changing a
    // workstep
    window.location.href = $state.href(APP_STATE.WORKSHEET, {
      workbookId: sqWorkbenchStore.stateParams.workbookId,
      worksheetId: sqWorkbenchStore.stateParams.worksheetId,
    });
  };

  const badgeTooltip = canModifyWorkbook ? 'VIEWING_ONLY.TOOLTIP' : 'VIEWING_ONLY.TOOLTIP_NO_ACCESS';

  return isViewMode ? (
    <div className="flexColumnContainer flexJustifyEnd ml10 flexNoGrowNoShrink flexSelfStretch">
      <div className="viewing-badge width-maximum height-maximum flexColumnContainer">
        <HoverTooltip text={badgeTooltip} placement="bottom">
          <span className="p3">{t('VIEWING_ONLY.HEADER')}</span>
        </HoverTooltip>
      </div>
      <SandboxModeBanner />
      {canModifyWorkbook && (
        <span className="p3">
          <Icon
            onClick={onClickBadge}
            icon="fa-pencil-square-o"
            tooltip="VIEWING_ONLY.EDIT"
            tooltipPlacement="bottom"
            large={true}
            type="theme-light"
            testId="viewOnlyEditIcon"
          />
        </span>
      )}
      {isReportBinder && (
        <span className="cursorPointer sq-navbar-default link-no-underline p3">
          <Icon
            extraClassNames="mr7 mt3"
            icon="fc-pdf-export"
            tooltip="REPORT.EDITOR.SAVE_AS_PDF_BUTTON_TITLE"
            tooltipPlacement="bottom"
            type="theme-light"
            large={true}
            onClick={() => setShowModal(true)}
            testId="pdfExportIcon"
          />
        </span>
      )}
    </div>
  ) : null;
};
