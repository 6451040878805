import classNames from 'classnames';
import React from 'react';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { DerivedDataTree } from '@/hybrid/trend/panels/derivedDataTree/DerivedDataTree.organism';

export type DerivedDataPanelProps = {
  derivedDataPanelOpen: boolean;
  setDerivedDataPanelOpen: () => void;
};

/**
 * A container for `DerivedDataTree`. Manages the toggling (open/close) of `DerivedDataTree`
 */
export const DerivedDataPanel: React.FunctionComponent<DerivedDataPanelProps> = ({
  derivedDataPanelOpen,
  setDerivedDataPanelOpen,
}) => {
  const { t } = useTranslation();

  return (
    <div id="derivedDataPanel" data-testid="derivedDataPanel" className="card flexRowContainer flexNoGrowNoShrink mb10">
      <div
        id="derivedDataPanelHeader"
        className="flexRowContainer lightGreyBorderBottom pl5 pb5 sq-text-primary cursorPointer flexNoGrowNoShrink"
        onClick={setDerivedDataPanelOpen}>
        <div className="flexColumnContainer flexAlignCenter flexNoGrowNoShrink">
          <div className="fs15 flexFill">{t('DERIVED_DATA.TITLE')}</div>
          <Icon
            icon={derivedDataPanelOpen ? 'fa-chevron-down' : 'fa-chevron-right'}
            testId="derivedDataPanelHeaderIcon"
            extraClassNames="pr8"
          />
        </div>
      </div>
      <div
        data-testid="tree-container"
        className={classNames('flexFill flexColumnContainer transitionMaxHeight p5 animated flexBasisAuto', {
          derivedDataPanelClosed: !derivedDataPanelOpen,
          derivedDataPanelOpen,
        })}>
        {/*  This tree can be expensive to render when the tree is huge, it is removed from the DOM if the user has collapsed it  */}
        {derivedDataPanelOpen && <DerivedDataTree />}
      </div>
    </div>
  );
};
