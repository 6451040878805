// @ts-strict-ignore
import jQuery from 'jquery';
import { FroalaEditor } from 'other_components/froalaEditor';

export function sqReportEditorFroala() {
  const service = {
    saveReport,
    triggerDocumentChangedEvent,
    getHtml,
    insertHtml,
    setHtml,
    saveSelection,
    restoreSelection,
    getScrollOffset,
    setScrollOffset,
    setReportViewHtml,
    setGlobalInstance,
    getGlobalInstance,
    getCursorPosition,
    setCursorPosition,
    focus,
    executeCommand,
  };

  return service;

  /**
   * Commands the froala editor to save the report
   */
  function saveReport() {
    if (FroalaEditor.INSTANCES.length !== 1) return;
    FroalaEditor.INSTANCES[0].undo.saveStep();
  }

  /**
   * Commands the Froala editor to check for updated content
   */
  function triggerDocumentChangedEvent() {
    if (FroalaEditor.INSTANCES.length !== 1) return;
    FroalaEditor.INSTANCES[0].events.trigger('contentChanged', [], false);
  }

  /**
   * Gets the html from the froala editor
   */
  function getHtml() {
    if (FroalaEditor.INSTANCES.length !== 1) return;
    return FroalaEditor.INSTANCES[0].html.get();
  }

  /**
   * Inserts html into the froala editor at the current cursor position
   *
   * @param {String} html - the html to insert
   */
  function insertHtml(html) {
    if (FroalaEditor.INSTANCES.length !== 1) return;
    FroalaEditor.INSTANCES[0].html.insert(html);
  }

  /**
   * Replaces all the html with a new html string
   *
   * @param {String} html - the html to set
   */
  function setHtml(html) {
    if (FroalaEditor.INSTANCES.length !== 1) return;
    FroalaEditor.INSTANCES[0].html.set(html);
    // Call undo.reset to avoid firing the 'contentChanged' event incorrectly
    //   https://github.com/froala/wysiwyg-editor/issues/3162#issuecomment-439430583
    FroalaEditor.INSTANCES[0].undo.reset();
  }

  /**
   * Commands the froala editor to save the current selection.
   * To ensure the correct selection we need to disable the blur event and
   * call the popups.hideAll function before saving the selection (CRAB-13535)
   */
  function saveSelection() {
    if (FroalaEditor.INSTANCES.length !== 1) return;
    const editor = FroalaEditor.INSTANCES[0];
    editor.events.disableBlur();
    editor.popups.hideAll();
    editor.selection.save();
  }

  /**
   * Commands the froala editor to restore the current selection
   */
  function restoreSelection() {
    if (FroalaEditor.INSTANCES.length !== 1) return;
    const editor = FroalaEditor.INSTANCES[0];
    editor.selection.restore();
    editor.events.enableBlur();
  }

  /**
   * Gets the froala editor scroll offset
   *
   * @returns {Number} the current scroll offset
   */
  function getScrollOffset() {
    return jQuery('.fr-wrapper').scrollTop();
  }

  /**
   * Sets the froala editor scroll offset
   *
   * @param {Number} offset - a scroll offset
   */
  function setScrollOffset(offset) {
    jQuery('.fr-wrapper').scrollTop(offset);
  }

  /**
   * Sets the document html of the report when in view mode. In view mode the document is displayed in a simple
   * div. In edit mode, the document displays in the Froala editor.
   *
   * @param {string} document - the html to set as the report view
   */
  function setReportViewHtml(document: string) {
    jQuery('#specJournalEntry').html(document);
  }

  /**
   * Function to set the global instance of the editor that can be used across app
   * This function is not used for Froala and it was added for compatibility with
   * the CKEditor Service
   */
  function setGlobalInstance(i) {
    // nothing to do here
  }

  /**
   * Function to set the global instance of the editor that can be used across app
   * This function is not used for Froala and it was added for compatibility with
   * the CKEditor Service
   */
  function getGlobalInstance() {
    // nothing to do here
  }

  function getCursorPosition(): any | undefined {
    // nothing to do here
  }

  function setCursorPosition(position) {
    // nothing to do here
  }

  function focus() {
    if (FroalaEditor.INSTANCES.length === 0) return;
    FroalaEditor.INSTANCES[0].el.focus();
  }

  function executeCommand(options) {
    // nothing to do here
    return null;
  }
}
