// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { IconSelect } from '@/hybrid/core/IconSelect.molecule';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { HelpIcon, Icon } from '@/hybrid/core/Icon.atom';
import { Form } from 'react-bootstrap';
import { ValueWithUnits } from '@/hybrid/trend/ValueWithUnits.atom';
import { DURATION_TIME_UNITS_ALL } from '@/main/app.constants';
import { CapsuleTable } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/components/CapsuleTable.molecule';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { sqItemsApi } from '@/sdk';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { FormulaService } from '@/services/formula.service';
import { CAPSULE_SELECTION, CAPSULE_SELECTION_OPTIONS, CapsuleSelectionValue } from '@/reportEditor/report.constants';

const TRANSLATIONS_MAP = {
  [CAPSULE_SELECTION.STRATEGY.CLOSEST_TO]: 'REPORT.CONFIG.CLOSEST_TO',
  [CAPSULE_SELECTION.STRATEGY.OFFSET_BY]: 'REPORT.CONFIG.OFFSET_BY',
  [CAPSULE_SELECTION.REFERENCE.START]: 'START',
  [CAPSULE_SELECTION.REFERENCE.END]: 'END',
};

const reportDateModalConfigureCapsuleSelectionBindings = bindingsDefinition({
  newDateRange: prop<any>(),
  isSelectingRelative: prop<any>(),
  isMaximumDurationRequiredForItem: prop<boolean>(),
  selectedCondition: prop<any>(),
  setSelectedCondition: prop<(condition) => void>(),
  isConditionSelected: prop<any>(),
  setNewDateRange: prop<any>(),
  capsuleCountData: prop<any>(),
  updateDateRangeFromCapsule:
    prop<(capsule: { start; end }, columns: string[], sortBy: string, sortAsc: boolean) => any>(),
  capsuleIsLoading: prop<any>(),
  computedCapsuleDisplay: prop<any>(),
  computedCapsuleProperties: prop<any>(),
  conditionMetadata: prop.optional<{ label: string; data: string; order: number }[]>(),
  sqFormula: injected<FormulaService>(),
});

export const ReportDateModalConfigureCapsuleSelection: SeeqComponent<
  typeof reportDateModalConfigureCapsuleSelectionBindings
> = ({
  newDateRange,
  isSelectingRelative,
  isMaximumDurationRequiredForItem,
  selectedCondition,
  setSelectedCondition,
  isConditionSelected,
  setNewDateRange,
  capsuleCountData,
  updateDateRangeFromCapsule,
  capsuleIsLoading,
  computedCapsuleDisplay,
  computedCapsuleProperties,
  conditionMetadata = [],
}) => {
  const { sqFormula } = useInjectedBindings(reportDateModalConfigureCapsuleSelectionBindings);
  const { t } = useTranslation();
  const [showCapsuleProperties, setShowCapsuleProperties] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);
  const [itemPropertiesAsMetaData, setItemPropertiesAsMetaData] = useState([]);

  const [conditionAsset, setConditionAsset] = useState(null);
  const assetPath = conditionAsset ? conditionAsset.formattedName : '';

  useEffect(() => {
    if (selectedCondition?.id) {
      sqFormula
        .getDependencies({ id: selectedCondition.id })
        .then(({ assets }) => (assets.length === 1 ? setConditionAsset(assets[0]) : setConditionAsset(null)));
      sqItemsApi.getItemAndAllProperties({ id: selectedCondition.id }).then(({ data }) => {
        const properties = _.chain(data.properties)
          .filter((property) =>
            _.includes([SeeqNames.Properties.SwapSourceId, SeeqNames.Properties.Unsearchable], property.name),
          )
          .map((property) => ({
            label: `${property.name}:`,
            data: property.value,
          }))
          .value();
        setItemPropertiesAsMetaData(properties);
      });
    } else {
      setConditionAsset(null);
    }
  }, [selectedCondition?.id]);

  const onMaximumDurationChange = (maximumDuration) => {
    const { propName, ...duration } = maximumDuration;
    setSelectedCondition({
      maximumDuration: duration,
    });

    setNewDateRange({
      condition: {
        maximumDuration: (selectedCondition.maximumDuration = maximumDuration),
      },
    });
  };

  const conditionPath = assetPath ? _.concat(assetPath, [' » ', selectedCondition?.name]) : selectedCondition?.name;
  const fullTargetPathLabelWithIcon = (
    <label>
      <Icon icon="fc-capsule-set" />
      {conditionPath}
    </label>
  );

  let metadata = _.concat(
    [
      {
        label: t('REPORT.MODAL.DATE_RANGE.METADATA.ID'),
        data: selectedCondition?.id,
        order: 4,
      },
    ],
    conditionMetadata,
  );
  _.forEach(itemPropertiesAsMetaData, (metadata) => {
    metadata.order = metadata.length + 1;
  });
  metadata = _.orderBy(_.concat(metadata, itemPropertiesAsMetaData), ['order', 'label']);

  const conditionMetaDataElement = (
    <>
      <span className="flexColumnContainer flexSpaceBetween flexAlignBottom mt5">
        <span>
          {t('REPORT.MODAL.DATE_RANGE.METADATA.LABEL_CHOOSE')}
          {fullTargetPathLabelWithIcon}
        </span>
        <Icon
          testId="conditionMetaDataTestId"
          icon="fa-info-circle"
          extraClassNames="ml5"
          onClick={() => setShowMetadata(!showMetadata)}
          tooltip={showMetadata ? 'REPORT.MODAL.DATE_RANGE.METADATA.HIDE' : 'REPORT.MODAL.DATE_RANGE.METADATA.SHOW'}
          tooltipPlacement="right"
        />
      </span>
      {showMetadata && (
        <div className="flexColumnContainer flexSpaceBetween alert lightGreyBorder backgroundColorOffWhite">
          <div className="flexRowContainer">
            <label>
              {t('REPORT.MODAL.DATE_RANGE_ASSET_SWAP.CONDITION_LABEL')}
              <Icon icon="fc-capsule-set" extraClassNames="mr2 ml2" />
              {selectedCondition?.name}
            </label>
            {_.map(metadata, ({ label, data }) => (
              <label key={label}>
                {label}
                <span className="small text-italic ml5">{data}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      {conditionMetaDataElement}
      <div className="flexRowContainer" data-testid="specReportDateModalConfigureCapsuleSelection">
        {(isSelectingRelative || newDateRange?.auto?.enabled) && (
          <div className="flexColumnContainer flexAlignStart mt10 mb0">
            <label className="mb0">{t('REPORT.CONFIG.CONFIGURE_CAPSULE_SELECTION')}</label>
            <div className="small text-italic col-sm ml5 mb5">{t('REPORT.CONFIG.SORTED_BY_START')}</div>
          </div>
        )}
        {isMaximumDurationRequiredForItem && (isSelectingRelative || newDateRange?.auto?.enabled) && (
          <div className="flexColumnContainer mb10">
            <div className="flexRowContainer">
              <div className="flexColumnContainer">
                {t('MAXIMUM_CAPSULE_DURATION')}
                <HelpIcon tooltip="MAXIMUM_CAPSULE_DURATION_OVERRIDE_TOOLTIP" extraClassNames="pl5" />
              </div>
              <ValueWithUnits
                propName="maximumDuration"
                insideModal={true}
                minIsExclusive={true}
                defaultValue={selectedCondition?.maximumDuration}
                availableUnits={DURATION_TIME_UNITS_ALL}
                min={0}
                onChange={onMaximumDurationChange}
              />
            </div>
          </div>
        )}
        {!isSelectingRelative && isConditionSelected && !newDateRange?.auto?.enabled && newDateRange?.range?.end && (
          <div>
            <CapsuleTable
              savedSortAsc={newDateRange.condition.sortAsc}
              savedEnd={newDateRange.range.end}
              savedStart={newDateRange.range.start}
              savedSortBy={newDateRange.condition.sortBy}
              savedColumns={newDateRange.condition.columns}
              updateDateRange={updateDateRangeFromCapsule}
              end={newDateRange.condition?.range?.end}
              start={newDateRange.condition?.range?.start}
              condition={selectedCondition}
            />
          </div>
        )}
        {(isSelectingRelative || newDateRange?.auto?.enabled) && (
          <div className="flexRowContainer">
            <p>{t('REPORT.CONFIG.SELECT_RELATIVE_TO_DURATION')}</p>
            <IconSelect
              testId="capsuleConfigSelect"
              selectOptions={CAPSULE_SELECTION_OPTIONS}
              value={_.find(
                CAPSULE_SELECTION_OPTIONS,
                ({ value }) =>
                  value.strategy === newDateRange.condition.strategy &&
                  value.reference === newDateRange.condition.reference,
              )}
              insideModal={true}
              onChange={(option) => {
                const { strategy, reference } = option.value as CapsuleSelectionValue;
                setNewDateRange({ condition: { strategy, reference } });
              }}
            />
            {newDateRange.condition.strategy === 'offsetBy' && (
              <div className="flexRowContainer">
                <p className="mt5">{t('REPORT.CONFIG.OFFSET_BY')}</p>
                <Form.Control
                  id="specCapsuleOffset"
                  data-testid="capsuleOffset"
                  type="number"
                  className="width-50 text-center p1"
                  name="offset"
                  min={1}
                  required={true}
                  onChange={(e) =>
                    setNewDateRange({
                      condition: { offset: e.currentTarget.value },
                    })
                  }
                  value={newDateRange.condition.offset || 0}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {/* <!-- Computed date range--> */}
      {isConditionSelected && (isSelectingRelative || newDateRange?.auto?.enabled) && (
        <div className="flexColumnContainer flexAlignStart mt20 mb0">
          <label className="mb0">{t('REPORT.CONFIG.COMPUTED_DATE_RANGE')}</label>
          <div className="small text-italic col-sm ml5 mb5">{t('REPORT.CONFIG.COMPUTED_DATE_RANGE_DESCRIPTION')}</div>
        </div>
      )}
      {isConditionSelected && capsuleIsLoading && (isSelectingRelative || newDateRange?.auto?.enabled) && (
        <div className="flexColumnContainer flexCenter mt10 mb10 col-sm">
          <IconWithSpinner spinning={true} extraClassNames="fa-pulse" large={true} />
          <span className="ml5 small">{t('LOADING_CAPSULE')}</span>
        </div>
      )}
      {isConditionSelected && !capsuleIsLoading && (isSelectingRelative || newDateRange?.auto?.enabled) && (
        <div className="flexColumnContainer">
          <div className="flexColumnContainer flexRowReverse width-120 m5 ml0 small sq-fairly-dark-gray">
            {!!capsuleCountData.capsuleCountLeft &&
              t('REPORT.CONFIG.CAPSULES_LEFT', {
                count: _.toNumber(capsuleCountData.capsuleCountLeft),
              })}
          </div>
          <div className="flexRowContainer">
            <div className="flexColumnContainer flexCenter width-300 alert lightGreyBorder backgroundColorOffWhite p5 mb0 positionRelative">
              {computedCapsuleDisplay && (
                <div id="specComputedDateRange" className="flexRowContainer">
                  <div className="flexSelfCenter">
                    {computedCapsuleDisplay}
                    <Icon
                      icon="fa-info-circle"
                      extraClassNames="ml2"
                      onClick={() => setShowCapsuleProperties(!showCapsuleProperties)}
                      tooltip={showCapsuleProperties ? 'HIDE_CAPSULE_PROPERTIES' : 'SHOW_CAPSULE_PROPERTIES'}
                    />
                  </div>
                  {showCapsuleProperties && (
                    <div className="flexFillOverflow overflowAuto max-height-75 mt5">
                      <table className="table table-striped small">
                        <tbody>
                          {computedCapsuleProperties.map((property, index) => (
                            <tr key={index}>
                              <td className="text-bolder">{property.name}</td>
                              <td>{property.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {!computedCapsuleDisplay && (
                <div className="small tightLines positionRelative" id="specComputedDateRange">
                  {t('REPORT.CONFIG.CAPSULES_NOT_FOUND')}
                </div>
              )}
            </div>
            <div className="flexFill positionRelative">
              {!computedCapsuleDisplay && !newDateRange.auto.enabled && !capsuleIsLoading && (
                <p className="help-block sq-text-danger ng-scope small">{t('REPORT.CONFIG.CAPSULES_VALIDATE')}</p>
              )}
            </div>
          </div>
          <div className="flexColumnContainer width-120 m5 mr0 small sq-fairly-dark-gray">
            {!!capsuleCountData.capsuleCountRight &&
              t('REPORT.CONFIG.CAPSULES_RIGHT', {
                count: _.toNumber(capsuleCountData.capsuleCountRight),
              })}
          </div>
        </div>
      )}
    </>
  );
};
