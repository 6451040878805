// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';

interface EditConfigurationOptionModalProps {
  displayConfigurationOptionUpdate: (item: any) => void;
  onClose: () => void;
  configurationOption: any;
}

export const EditConfigurationOptionModal: React.FunctionComponent<EditConfigurationOptionModalProps> = ({
  displayConfigurationOptionUpdate,
  onClose,
  configurationOption,
}) => {
  const { t } = useTranslation();

  const [isOverridden, setIsOverridden] = useState(configurationOption.isOverridden);
  const [booleanValue, setBooleanValue] = useState(
    configurationOption.isOverridden ? configurationOption.value : configurationOption.defaultValue,
  );

  const getInputType = () => {
    if (_.isBoolean(configurationOption.defaultValue)) {
      return 'checkbox';
    } else if (_.isNumber(configurationOption.defaultValue)) {
      return 'number';
    } else {
      return 'text';
    }
  };

  const updateConfigurationOption = (values) => {
    const { overrideDefault, numericValue, booleanValue, textValue, note } = values;

    const getInputValue = () => {
      if (!overrideDefault) {
        return undefined;
      } else if (getInputType() === 'checkbox') {
        return booleanValue;
      } else if (getInputType() === 'number') {
        return _.toNumber(numericValue);
      } else {
        return textValue;
      }
    };

    _.assign(configurationOption, { overrideDefault }, { value: getInputValue() }, { note });
    displayConfigurationOptionUpdate(configurationOption);
  };

  const formDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'configurationOption',
      components: [
        {
          component: 'LabelFormComponent',
          name: 'configurationNameLabel',
          value: 'ADMIN.CONFIGURATION.NAME',
          extraClassNames: 'mt8 text-bolder',
        },
        {
          component: 'LabelFormComponent',
          name: 'configurationName',
          value: configurationOption.path,
          testId: 'configurationName',
        },
        {
          component: 'LabelFormComponent',
          name: 'registrationDescription',
          value: configurationOption.registrationDescription,
          testId: 'registrationDescription',
          extraClassNames: 'help-block mt0',
        },
        {
          component: 'LabelFormComponent',
          name: 'configurationDescriptionLabel',
          value: 'ADMIN.CONFIGURATION.DESCRIPTION',
          extraClassNames: 'mt10 text-bolder',
        },
        {
          component: 'LabelFormComponent',
          name: 'configurationDescription',
          value: configurationOption.description,
          testId: 'configurationDescription',
        },
        {
          component: 'LabelFormComponent',
          name: 'defaultValueLabel',
          value: 'ADMIN.CONFIGURATION.DEFAULT_VALUE',
          extraClassNames: 'mt10 text-bolder',
        },
        {
          component: 'LabelFormComponent',
          includeIf: configurationOption.defaultValue,
          name: 'defaultValue',
          value: `${configurationOption.defaultValue} ${configurationOption.units ? configurationOption.units : ''}`,
          testId: 'defaultValue',
          extraClassNames: 'aggressiveWordBreak',
        },
        {
          component: 'LabelFormComponent',
          includeIf: !configurationOption.defaultValue,
          name: 'noDefaultValueLabel',
          value: 'ADMIN.CONFIGURATION.NO_DEFAULT_VALUE',
          extraClassNames: 'mt8 text-italic',
        },
        {
          component: 'LabelFormComponent',
          includeIf: configurationOption.defaultDescription,
          name: 'defaultDescription',
          value: configurationOption.defaultDescription,
          testId: 'defaultDescription',
          extraClassNames: 'help-block',
        },
        {
          component: 'CheckboxFormComponent',
          id: 'overrideDefault',
          name: 'overrideDefault',
          label: 'ADMIN.CONFIGURATION.OVERRIDE_VALUE',
          value: isOverridden,
          onChange: () => setIsOverridden(!isOverridden),
          checkboxLabel: 'ADMIN.CONFIGURATION.OVERRIDE_DEFAULT',
          wrapperClassNames: 'mt10',
        },
        {
          component: 'FormGroup',
          name: 'inputFormGroup',
          includeIf: isOverridden,
          testId: 'overrideInput',
          components: [
            {
              component: 'CheckboxFormComponent',
              includeIf: getInputType() === 'checkbox',
              id: 'booleanValue',
              name: 'booleanValue',
              value: booleanValue,
              onChange: () => setBooleanValue(!booleanValue),
              checkboxLabel: booleanValue
                ? 'ADMIN.CONFIGURATION.LABEL_VALUE_TRUE'
                : 'ADMIN.CONFIGURATION.LABEL_VALUE_FALSE',
              wrapperClassNames: 'mt8',
            },
            {
              component: 'FormControlFormComponent',
              includeIf: getInputType() === 'text',
              name: 'textValue',
              value: configurationOption.value ? configurationOption.value : configurationOption.defaultValue,
              onChange: _.noop,
              extraClassNames: 'mt8 mb10',
              size: 'md',
              as: 'textarea',
              rows: 2,
              testId: 'textValue',
            },
            {
              component: 'FormControlFormComponent',
              includeIf: getInputType() === 'number',
              name: 'numericValue',
              value: configurationOption.value ? configurationOption.value : configurationOption.defaultValue,
              onChange: _.noop,
              extraClassNames: 'mt8 mb10',
              size: 'md',
              type: 'number',
              testId: 'numericValue',
            },
          ],
        },
        {
          component: 'LabelFormComponent',
          includeIf: configurationOption.allowedValues,
          name: 'allowedValuesLabel',
          value: configurationOption.allowedValues,
          extraClassNames: 'help-block',
        },
        {
          component: 'FormControlFormComponent',
          name: 'note',
          label: 'ADMIN.CONFIGURATION.NOTE',
          value: configurationOption.note ? configurationOption.note : '',
          validation: _.isUndefined,
          onChange: _.noop,
          size: 'md',
          as: 'textarea',
          rows: 2,
          testId: 'note',
        },
        {
          component: 'LabelFormComponent',
          name: 'noteHelpLabel',
          value: 'ADMIN.CONFIGURATION.NOTE_HELP',
          extraClassNames: 'help-block mt8',
        },
      ],
    },
  ];

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="editConfigurationOptionModal">
      <Modal.Header closeButton={true}>
        <h3>{t('ADMIN.CONFIGURATION.EDIT_TITLE')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="editConfigurationOptionModalBody">
          <SimpleSaveFormBuilder
            formDefinition={formDefinition}
            submitFn={updateConfigurationOption}
            closeFn={onClose}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
