export enum Visualization {
  TABLE = 'table',
  XY_PLOT = 'xyPlot',
  TREND = 'trend',
  NONE = 'none',
}

export type VisualizationData = {
  visualization: Visualization;
  [s: string]: any;
};
