// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import _ from 'lodash';
import { InteractiveReportContent, KEEP_CURRENT_INTERACTIVE, KEEP_CURRENT_KEY } from '@/reportEditor/report.constants';
import { IconSelect, ReactSelectOption } from '@/hybrid/core/IconSelect.molecule';
import { useTranslation } from 'react-i18next';

const reportContentInteractiveBindings = bindingsDefinition({
  setInteractive: prop<(InteractiveReportContent) => void>(),
  interactiveOptions: prop<InteractiveReportContent[]>(),
});

export const ReportContentInteractive: SeeqComponent<typeof reportContentInteractiveBindings> = ({
  setInteractive,
  interactiveOptions,
}) => {
  const { t } = useTranslation();
  const keepCurrentOption: ReactSelectOption = {
    text: KEEP_CURRENT_INTERACTIVE.name,
    value: KEEP_CURRENT_INTERACTIVE.key,
  };

  const interactiveOptionsFormatted = _.chain(interactiveOptions)
    .reject({ key: KEEP_CURRENT_KEY })
    .map(
      (option): ReactSelectOption => ({
        text: option.name,
        value: option.key,
      }),
    )
    .concat(keepCurrentOption)
    .compact()
    .value();

  const [selectedInteractiveOption, setSelectedInteractiveOption] = useState<ReactSelectOption>(
    _.find(interactiveOptionsFormatted, { value: KEEP_CURRENT_KEY }),
  );

  const handleSetContentInteractiveStatus = (userChoice: ReactSelectOption) => {
    const reactMatch = _.find(interactiveOptions, { key: userChoice.value });
    setSelectedInteractiveOption(userChoice);
    setInteractive(reactMatch);
  };

  return (
    <div className="flexRowContainer p10">
      <label htmlFor="interactiveSelect">{t('REPORT.INTERACTIVE.LABEL')}</label>
      <IconSelect
        id="interactiveSelect"
        testId="interactiveSelect"
        insideModal={true}
        selectOptions={interactiveOptionsFormatted}
        value={selectedInteractiveOption}
        onChange={handleSetContentInteractiveStatus}
      />
    </div>
  );
};

export const sqReportContentInteractive = angularComponent(reportContentInteractiveBindings, ReportContentInteractive);
