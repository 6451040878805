// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import _ from 'lodash';
import { Dropdown, FormControl } from 'react-bootstrap';
import classNames from 'classnames';
import {
  ITEM_TYPES,
  overrideRegionValueWithXyAxis,
  XY_AXIS_REMOVE,
  XY_AXIS_X,
  XY_AXIS_Y,
} from '@/trendData/trendData.constants';
import { UnitOfMeasure } from '@/hybrid/core/UnitOfMeasure.atom';
import { IconSelect } from '@/hybrid/core/IconSelect.molecule';
import { ColorPicker } from '@/hybrid/workbooks/ColorPicker.organism';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { AnnotationIcon } from '@/hybrid/annotation/AnnotationIcon.atom';
import { AddToDisplayPane } from '@/hybrid/workbooks/AddToDisplayPane.molecule';
import { TrendActions } from '@/trendData/trend.actions';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { ButtonWithPopover } from '@/hybrid/core/ButtonWithPopover.molecule';
import { PathComponent, PathComponents } from '@/hybrid/utilities/PathComponents.atom';
import { onEventPreventPropagation } from '@/hybrid/core/onEnterKeypress.util';
import { infoToast } from '@/hybrid/utilities/toast.utilities';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { isStringSeries } from '@/hybrid/utilities/utilities';
import { getColumnValueAndUOM, isValueColumn } from '@/hybrid/utilities/columnHelper.utilities';
import { customizationDisabled, getSelectOptions, searchAsset } from '@/hybrid/utilities/detailsPanel.utilities';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { customizationEnabled } from '@/hybrid/utilities/detailsPanel.helper';
import { NUMBERS_ONLY_REGEX } from '@/core/core.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { doTrack } from '@/track/track.service';
import { sqScatterPlotStore, sqTreemapStore, sqWorksheetStore } from '@/core/core.stores';
import { updateLaneDisplay } from '@/trendData/yAxis.actions';
import { canReadItem as authServiceCanReadItem } from '@/services/authorization.service';
import { XYPlotRegion } from '@/scatterPlot/scatterPlot.constants';
import { isItemRedacted as isItemRedactedSq } from '@/hybrid/utilities/redaction.utilities';

const detailsPanelColumnBindings = bindingsDefinition({
  rowIndex: prop<number>(),
  item: prop<any>(),
  displayItems: prop<any[]>(),
  allItems: prop<any[]>(),
  column: prop<any>(),
  capsuleGroupingMode: prop<boolean>(),
  viewKey: prop.optional<string>(),
  sqTrendActions: injected<TrendActions>(),
  sqScatterPlotActions: injected<ScatterPlotActions>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  $injector: injected<ng.auto.IInjectorService>(),
  $state: injected<ng.ui.IStateService>(),
});

export const DetailsPanelColumn: SeeqComponent<typeof detailsPanelColumnBindings> = ({
  rowIndex,
  item,
  column,
  displayItems,
  allItems,
  capsuleGroupingMode,
  viewKey,
}) => {
  const { $state, sqWorksheetActions, sqTrendActions, sqScatterPlotActions, sqInvestigateActions } =
    useInjectedBindings(detailsPanelColumnBindings);

  const { key, style, selectWidthClass, doToggle, applyToAxis, trackAction } = column;

  const canReadItem = authServiceCanReadItem(item);
  const isItemRedacted = isItemRedactedSq(item);
  const isEnabled = customizationEnabled(item, key);
  const isDisabled = customizationDisabled(column, item);

  const value = item[key];

  // Grouping logic
  const signals = _.filter(allItems, { itemType: ITEM_TYPES.SERIES });
  const unassignedSignals = _.filter(signals, (signal) => _.findIndex(displayItems, { id: signal.id }) < 0);
  const assignedSignals = _.filter(signals, (signal: any) => {
    const signalItems: any = _.filter(displayItems, { id: signal.id });
    return _.size(signalItems) > 0 && _.findIndex(signalItems, { groupedTo: item.id }) < 0;
  });

  // Used to handle input field types since we only update the store onBlur/onEnter
  const [textValue, setTextValue] = useState(item[key]);
  useEffect(() => setTextValue(!_.isNaN(item[key]) ? item[key] : ''), [item[key]]);

  const isTreemapView = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.view.key === WORKSHEET_VIEW.TREEMAP);
  const priorityColors = useFluxPath(sqTreemapStore, () => sqTreemapStore.priorityColors);

  const regionFromRanges: () => XYPlotRegion = () => {
    const xAxisRange = sqScatterPlotStore.getXAxisRange();
    const region = {
      x: {
        min: xAxisRange.start,
        max: xAxisRange.end,
      },
      ys: {},
    };
    _.forEach(sqScatterPlotStore.ySignals, (ySignal) => {
      const range = sqScatterPlotStore.getYAxisRange(ySignal.id);
      region.ys[ySignal.id] = {
        min: range.start,
        max: range.end,
      };
    });

    return region;
  };

  const handleOnChangeItem = (item, key, value) => {
    if (key === 'axisAutoScale') {
      if (!sqScatterPlotStore.isViewRegionSet()) {
        sqScatterPlotActions.zoomToRegion(regionFromRanges());
      } else {
        sqScatterPlotActions.expandViewRegion();
      }
      return;
    }
    if (key === 'axisAlign') {
      if (
        ((item.axisAlign === XY_AXIS_X && value === XY_AXIS_Y) ||
          (item.axisAlign === XY_AXIS_Y && item.axisAlign === XY_AXIS_X)) &&
        sqScatterPlotStore.ySignals.length === 1
      ) {
        sqScatterPlotActions.flipXAndY();
      } else {
        if (value === XY_AXIS_X) {
          sqScatterPlotActions.removeSignal(item.id);
          sqScatterPlotActions.setXSignal({ id: item.id });
          doTrack('Scatterplot', 'X-Axis Signal selected (details pane)');
        } else if (value === XY_AXIS_Y) {
          sqScatterPlotActions.removeSignal(item.id);
          sqScatterPlotActions.setYSignal({ id: item.id });
          doTrack('Scatterplot', 'Y-Axis Signal selected (details pane)');
        } else if (value === XY_AXIS_REMOVE) {
          sqScatterPlotActions.removeSignal(item.id);
          doTrack('Scatterplot', 'Signal removed (details pane)');
        }
      }
    } else {
      if (item.axisAlign === XY_AXIS_Y || item.axisAlign === XY_AXIS_X) {
        sqScatterPlotActions.zoomToRegion(
          overrideRegionValueWithXyAxis(
            regionFromRanges(),
            key,
            _.toNumber(value),
            item.axisAlign === XY_AXIS_Y ? item.id : undefined,
          ),
        );
      }
    }
  };

  const updateCustomizationAndTrack = (newValue = undefined) => {
    const itemsToUpdate = applyToAxis ? _.filter(displayItems, ['axisAlign', item.axisAlign]) : [item];

    const propertiesToUpdate: { axisAutoScale?: boolean } = {};
    propertiesToUpdate[key] = doToggle ? !item[key] : newValue;

    if (key === 'axisAlign') {
      const existingAlignmentItems = _.filter(displayItems, ['axisAlign', newValue]);
      if (
        item.isStringSeries ||
        (_.some(existingAlignmentItems, 'isStringSeries') && existingAlignmentItems.length > 0)
      ) {
        infoToast({ messageKey: 'NO_STRING_DATA_Y_AXIS_SHARING' });
        return;
      } else {
        propertiesToUpdate.axisAutoScale = existingAlignmentItems[0]?.axisAutoScale ?? true;
      }
    }

    if (sqWorksheetStore.view.key === WORKSHEET_VIEW.SCATTER_PLOT) {
      if (item.itemType === ITEM_TYPES.SERIES && !isStringSeries(item)) {
        handleOnChangeItem(item, key, newValue);
      }
    } else {
      sqTrendActions.setCustomizationProps(
        _.map(itemsToUpdate, (item) => _.assign({ id: item.id }, propertiesToUpdate)),
      );
    }
    updateLaneDisplay(sqTrendActions);
    doTrack(_.startCase(_.toLower(viewKey || WORKSHEET_VIEW.TREND)), `display option ${trackAction} changed`);
  };

  const groupingDropdownItem = (item, signal) => (
    <Dropdown.Item key={signal.id} onClick={() => sqWorksheetActions.groupSignalToCondition(signal.id, item.id)}>
      <>
        <Icon icon="fc-series" type="color" color={signal.color} extraClassNames="fa-fw pr5" />
        <span className="pr20">{signal.name}</span>
        {_.map(signal.assets, (asset, index) => (
          <HoverTooltip text={asset.formattedName} key={index}>
            <>
              <span className="pull-right">{asset.name}</span>
              {_.toNumber(index) !== signal.assets.length - 1 && <span className="mr2">,</span>}
            </>
          </HoverTooltip>
        ))}
      </>
    </Dropdown.Item>
  );

  return (
    <td
      id={`${key}-${rowIndex}`}
      key={key}
      data-testid={key}
      className={classNames(
        'text-nowrap',
        `${key.split('.').pop()}Column`,
        { 'semi-bold': key === 'name' },
        { 'text-right': style === 'decimal' || style === 'percent' },
      )}>
      {key === 'name' && capsuleGroupingMode && item.itemType !== ITEM_TYPES.CAPSULE_SET && <span className="pl25" />}

      {isValueColumn(column) && (
        <span
          className={classNames({
            'text-italic': key === 'name' && item.isArchived,
          })}>
          {getColumnValueAndUOM(column, item)}
        </span>
      )}

      {key === 'valueUnitOfMeasure' && item.itemType !== ITEM_TYPES.CAPSULE_SET && (
        <UnitOfMeasure unitOfMeasure={item.displayUnitOfMeasure} isStandalone={true} />
      )}

      {style === 'assets' &&
        !isItemRedacted &&
        _.map(item.assets, (asset, index) => (
          <HoverTooltip text={asset.formattedName} key={index}>
            <a
              key={index}
              data-testid={key}
              className="force-link-look"
              onClick={onEventPreventPropagation(() => searchAsset(sqWorksheetActions, asset.id))}>
              <span>
                {asset.name}
                {_.toNumber(index) !== item.assets.length - 1 && <span className="mr2">,</span>}
              </span>
            </a>
          </HoverTooltip>
        ))}

      {style === 'fullpath' &&
        !isItemRedacted &&
        _.map(item.assets, (asset, index) => (
          <a key={index} onClick={(e) => e.stopPropagation()}>
            <PathComponents
              asset={asset}
              actionOnComponent={({ id }: PathComponent) => searchAsset(sqWorksheetActions, id)}
            />
            {_.toNumber(index) !== item.assets.length - 1 && <span className="mr2">,</span>}
          </a>
        ))}

      {(style === 'iconSelect' || style === 'stringSelect' || style === 'select') && isEnabled && canReadItem && (
        <IconSelect
          testId={`${key}-select`}
          wrapperClasses={classNames('height-20', selectWidthClass)}
          extraClassNames="small-select"
          value={value}
          skipMemo={true}
          onChange={(value) => updateCustomizationAndTrack(value.value)}
          disabled={isDisabled}
          selectOptions={getSelectOptions(column, item)}
        />
      )}

      {style === 'input' && isEnabled && canReadItem && (
        <FormControl
          data-testid={`${key}-input`}
          disabled={isDisabled}
          className="input-xs width-60"
          autoComplete="off"
          value={textValue ?? ''}
          isInvalid={!isDisabled && !NUMBERS_ONLY_REGEX.test(textValue)}
          onChange={(e) => setTextValue(e.target.value)}
          onBlur={(e) => updateCustomizationAndTrack(e.target.value)}
          onKeyUp={(e) => e.keyCode === 13 && updateCustomizationAndTrack(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          onFocus={(e) => e.target.select()}
        />
      )}

      {style === 'color' && canReadItem && (
        <span className="flexColumnContainer flexCenter mt3" onClick={(e) => e.stopPropagation()} data-testid={key}>
          <ColorPicker
            placement="top"
            heading={item.itemType === ITEM_TYPES.CAPSULE_SET && isTreemapView ? 'TREEMAP.PRIORITY_COLOR' : undefined}
            colors={item.itemType === ITEM_TYPES.CAPSULE_SET && isTreemapView ? priorityColors : undefined}
            color={item.color}
            limitToSwatches={item.itemType === ITEM_TYPES.CAPSULE_SET && isTreemapView}
            itemId={item.id}
          />
        </span>
      )}

      {style === 'group' && item.itemType === ITEM_TYPES.CAPSULE_SET && capsuleGroupingMode && (
        <ButtonWithPopover
          popoverConfig={{ id: 'groupingPopover', placement: 'auto' }}
          stopPropagation={true}
          label={
            <Icon
              icon="fc-group"
              type="color"
              color={item.color}
              testId="addToGroupIcon"
              extraClassNames="btn-transparent groupingButton"
              tooltip="CAPSULES_PANEL.GROUP"
            />
          }>
          <>
            {_.map(unassignedSignals, (signal) => groupingDropdownItem(item, signal))}
            {_.size(assignedSignals) > 0 && _.size(unassignedSignals) > 0 && <Dropdown.Divider />}
            {_.map(assignedSignals, (signal) => groupingDropdownItem(item, signal))}
          </>
        </ButtonWithPopover>
      )}

      {style === 'group' && item.itemType !== ITEM_TYPES.CAPSULE_SET && capsuleGroupingMode && (
        <Icon
          icon="fa-close"
          type="color"
          testId="removeFromGroupIcon"
          color={item.groupColor}
          extraClassNames="btn-transparent groupingButton fa-fw"
          onClick={() => sqWorksheetActions.groupSignalToCondition(item.id, item.groupedTo)}
          tooltip="CAPSULES_PANEL.REMOVE_GROUPING"
        />
      )}

      {style === 'icon' && isEnabled && canReadItem && (
        <span className="pt5 flexRowContainer flexCenter">
          <Icon
            testId={`${key}-icon`}
            icon={value ? column.onClass : column.offClass}
            type="text"
            onClick={onEventPreventPropagation(updateCustomizationAndTrack)}
          />
        </span>
      )}

      {key === 'name' && canReadItem && (
        <span className="ml5">
          <AnnotationIcon item={item} />
        </span>
      )}

      {key === 'name' && item.itemType === ITEM_TYPES.METRIC && canReadItem && (
        <AddToDisplayPane
          itemId={item.definition?.measuredItem.id}
          tooltip="INVESTIGATE_TOOLS.THRESHOLD_METRIC.ADD_MEASURED_ITEM"
          afterAdd={sqTrendActions.alignMeasuredItemWithMetric}
        />
      )}
    </td>
  );
};
