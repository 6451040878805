// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { LegendWrapper } from '@/hybrid/trend/LegendWrapper.atom';
import { Icon } from '@/hybrid/core/Icon.atom';

const signalGradientLegendItemsBindings = bindingsDefinition({
  getCapsuleName: prop<(any) => string>(),
  capsules: prop<any[]>(),
  title: prop<string>(),
});

export const SignalGradientLegendItems: SeeqComponent<typeof signalGradientLegendItemsBindings> = (props) => {
  const { getCapsuleName, capsules, title } = props;

  return (
    capsules.length && (
      <LegendWrapper title={title}>
        {_.chain([_.first(capsules), _.last(capsules)])
          .compact()
          .uniqBy('id')
          .value()
          .map((capsule, i, collection) => (
            <div className="text-nowrap" key={capsule.id}>
              <Icon icon="fc-series" extraClassNames="pr5" type="color" color={capsule.childColor} />
              {getCapsuleName(capsule)}
              {i === 0 && collection.length > 1 && <span className="ml5 mr5">...</span>}
            </div>
          ))}
      </LegendWrapper>
    )
  );
};
