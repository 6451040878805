// @ts-strict-ignore
import _ from 'lodash';
import angular from 'angular';
import moment from 'moment';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { COMPARISON_OPERATORS_SYMBOLS, PREDICATE_API } from '@/hybrid/toolSelection/investigate.constants';
import { ItemPreviewV1, sqFormulasApi, sqItemsApi, sqTreesApi, TableColumnOutputV1 } from '@/sdk';
import { getAssetFromAncestors } from '@/hybrid/utilities/httpHelpers.utilities';
import { API_TYPES, ASSET_PATH_SEPARATOR, STRING_UOM } from '@/main/app.constants';
import {
  encodeParameters,
  generateTemporaryId,
  getShortIdentifier,
  isAsset,
  isPresentationWorkbookMode,
  isStringSeries as isStringSeriesUtil,
} from '@/hybrid/utilities/utilities';
import { TableColumnFilter } from '@/hybrid/core/tableUtilities/tables';
import { RangeExport } from '@/trendData/duration.store';
import { sqDurationStore, sqTrendSeriesStore, sqTrendStore } from '@/core/core.stores';
import {
  CAPSULE_PANEL_TREND_COLUMNS,
  COLUMNS_AND_STATS,
  ITEM_TYPES,
  TREND_CONDITION_STATS,
  TREND_METRIC_STATS,
  TREND_PANELS,
  TREND_SIGNAL_STATS,
} from '@/trendData/trendData.constants';
import { ProcessTypeEnum } from '@/sdk/model/ThresholdMetricOutputV1';
import { getCapsuleFormula } from '@/hybrid/datetime/dateTime.utilities';
import { getViewCapsuleParameter } from '@/hybrid/utilities/tableHelper.utilities';
import { ValueWithUnitsItem } from '@/hybrid/trend/ValueWithUnits.atom';
import { AssetSelection, Range } from '@/reportEditor/report.constants';
import { flux } from '@/core/flux.module';
import { fetchCapsuleProperties } from '@/hybrid/utilities/investigateHelper.utilities';
import { isItemRedacted } from '@/hybrid/utilities/redaction.utilities';
import { ITEM_UOM } from '@/hybrid/tableBuilder/tableBuilder.constants';

const dependencies = ['Sq.TrendData'];

export type ParametersMap = { [key: string]: string };

export type BuildConditionFormulaCallback = (
  ids: string[],
  parameters: ParametersMap,
) => { formula: string; parameters: ParametersMap };

export type BuildAdditionalCapsuleTableFormulaCallback = (ids: string[], parameters: ParametersMap) => string;

export type BuildStatFormulaCallback = (statColumns: StatColumn[], parameters: ParametersMap) => string;

export const NAME_SEARCH_TYPES = [
  API_TYPES.CALCULATED_CONDITION,
  API_TYPES.CALCULATED_SIGNAL,
  API_TYPES.CALCULATED_SCALAR,
  API_TYPES.TABLE,
  API_TYPES.THRESHOLD_METRIC,
  API_TYPES.DATAFILE,
  SeeqNames.Types.DisplayTemplate,
];

/**
 * @file Allows for interacting with and running Formulas
 */
angular.module('Sq.Services.Formula', dependencies).service('sqFormula', sqFormula);

export type FormulaService = ReturnType<typeof sqFormula>;

function sqFormula() {
  const service = {
    computeSamples: _.partial(runFormula, ['SAMPLE_SERIES', 'SAMPLE_GROUP', 'SAMPLE_AND_STATS']),
    computeCapsules: _.partial(runFormula, ['CAPSULE_SERIES', 'CAPSULE']),
    computeCapsulesWithLimit,
    buildFilterFragmentForConditions,
    computeScalar: _.partial(runFormula, 'SCALAR'),
    computePredictionModel: _.partial(runFormula, 'PREDICTION_TABLE'),
    runTable,
    computeTable: _.partial(runFormula, 'TABLE'),
    computeCapsuleTable,
    canFetchData,
    buildCapsuleTableSortFragment,
    buildStatFormulaFragment,
    buildGroupFormulaFragment,
    buildPropertyColumnsFragment,
    buildTableLimitFormulaFragment,
    findColumnSuffix,
    buildSimpleTableFilterFormulaFragment,
    getCapsulesPanelStringColumnFetchParams,
    getStringStatFetchParams,
    fetchTableDistinctStringValues,
    buildTableFilterFormulaFragment,
    extractParametersMap,
    getDefaultName,
    getDependencies,
    getAssetPath,
    getAssetPathFromAncestors,
    getAssetSiblings,
    getBuildAdditionalFormula,
    getBuildStatFormulaFunctionCallback,
    getStringPropertyFetchParams,
    getPropertyAndStatisticsColumns,
    getColumnNameForAnyColumn,
    buildFilterFormula,
  };

  return service;

  interface RunFormulaInput {
    /**
     * Time range over which to request the data. Not allowed for when typeKey is SCALAR. Start and end of the range.
     * If a moment object it is converted to a UTC timestamp, otherwise it is assumed to be a number not in the time
     * domain.
     */
    range?: { start: number | Object; end: number | Object };
    /**
     * ID of the item on which to run the formula. It should be referenced using $series.
     * May also be the ID of a formula function, in which case the fragments property should contain formula fragments
     * for any unbound parameters.
     */
    id?: string;
    /**
     * A formula fragment object where the keys are the names of unbound formula function variables and the values are
     * the corresponding formula fragments that are used to compute the value of the variable.
     */
    fragments?: {};
    /**
     * Specifies the parameters that the formula can reference.
     */
    parameters?: ParametersMap;
    /**
     * The formula to pass to the Calculation Engine. If not provided the formula will just return the contents of the
     * item without any transformations.
     */
    formula?: string;
    /**
     * Used to run a formula across assets. The ID of the root asset.
     */
    root?: string;
    /**
     * Used when running a formula across assets, a formula that can further reduce the results of each asset result.
     */
    reduceFormula?: string;
    /**
     * A group name that can be used to cancel the requests
     */
    cancellationGroup?: string;
    /**
     * Used to limit the number of results returned
     */
    limit?: number;
    offset?: number;
    /**
     * Used to return only rows with distinct values in a particular column
     */
    distinct?: string;
    /**
     * If true, use POST /formula/run instead of GET /formula/run
     */
    usePost?: boolean;
  }

  /**
   * Run a formula and return its result. This has convenience parameters to allow for fetching the results for a
   * specific item by allowing the id to be passed in and defaulting the formula to $series.
   *
   * @param typeKey - The type(s) of result, one of the keys from FORMULA_RETURN_TYPES
   * @param args - The arguments for running the formula
   * @returns {Promise} Resolves with the result that corresponds to the returnType. Rejects if the specified
   *   returnType does not match the returnType returned by the API.
   */
  function runFormula(typeKey: string | string[], args: RunFormulaInput): Promise<any> {
    typeKey = _.flatten([typeKey]);
    const formattedStart = _.isObject(args.range?.start) ? (args.range.start as any).toISOString() : args.range?.start;
    const formattedEnd = _.isObject(args.range?.end) ? (args.range.end as any).toISOString() : args.range?.end;
    const body = {
      start: _.isEmpty(args.range) ? undefined : formattedStart,
      end: _.isEmpty(args.range) ? undefined : formattedEnd,
      limit: args.limit,
      formula: undefined,
      parameters: undefined,
      root: args.root,
      reduceFormula: args.reduceFormula,
    };

    /**
     * If there is/are formula fragment(s), then the ID is the ID of a previously compiled formula functions that we
     * need to evaluate with the fragment(s). Otherwise it's a standard formula with parameters.
     */
    if (args.fragments) {
      _.assign(body, {
        _function: args.id,
        fragments: encodeParameters(args.fragments),
      });
    } else {
      if (args.formula || args.id) {
        body.formula = args.formula || '$series';
      }

      if (args.parameters || args.id) {
        body.parameters = encodeParameters(args.parameters || { series: args.id });
      }
    }

    const run = (body, config) =>
      args.usePost ? sqFormulasApi.runFormula_1(body, config) : sqFormulasApi.runFormula(body, config);
    return run(body, { cancellationGroup: args.cancellationGroup }).then(({ headers: rawHeaders, data }) => {
      let returnKey;
      const allowedTypes = _.filter(FORMULA_RETURN_TYPES, (value, key) => _.includes(typeKey, key));

      if (!_.includes(allowedTypes, data.returnType)) {
        return Promise.reject(`Expected formula to return ${allowedTypes.join(', ')}, but data is ${data.returnType}`);
      }

      returnKey = _.findKey(FORMULA_RETURN_TYPES, _.partial(_.isEqual, data.returnType));

      const headers = {} as any;
      if (!_.isNil(rawHeaders['server-timing'])) {
        headers.timingInformation = rawHeaders['server-timing'];
      }
      if (!_.isNil(rawHeaders['server-meters'])) {
        headers.meterInformation = rawHeaders['server-meters'];
      }

      // Ensures that no capsules are missing IDs which can happen with unbounded capsules
      if (_.has(data, 'capsules')) {
        data.capsules.capsules = _.map(data.capsules.capsules, (capsule) =>
          capsule.id ? capsule : _.assign({}, capsule, { id: generateTemporaryId() }),
        );
      }

      const returnFields = FORMULA_RETURN_FIELDS[returnKey];
      if (!_.isArray(returnFields)) {
        return _.assign(headers, data[returnFields], _.pick(data, ['warningCount', 'warningLogs']));
      } else {
        if (returnKey === 'SAMPLE_AND_STATS') {
          (data as any).valueUnitOfMeasure = data.samples.valueUnitOfMeasure ? data.samples.valueUnitOfMeasure : '';
          (data as any).samples = data.samples.samples;
          (data as any).interpolationMethod = data.metadata['Interpolation Method'];
        }
        return _.assign(headers, _.pick(data, _.union(returnFields, ['warningCount', 'warningLogs'])));
      }
    });
  }

  /**
   * Determines if the calculated item can successfully fetch data for the given time range by using a simple
   * identity formula. Works for any type of item the formula endpoint supports that can be calculated using a start
   * and end time.
   *
   * @param item -The item to check
   * @param range - The date range to query
   * @param cancellationGroup - The cancellation group
   * @return Promise that resolves with true if it fetched successfully or false if it failed
   */
  function canFetchData(item, range: RangeExport, cancellationGroup: string) {
    let formula = '$item';
    let start = range.start.toISOString();
    let end = range.end.toISOString();
    if (item.itemType === ITEM_TYPES.METRIC && item.definition.processType === ProcessTypeEnum.Simple) {
      formula = `group(${getCapsuleFormula(range)}).toTable('test').addSimpleMetricColumn('item', $item)`;
      start = undefined;
      end = undefined;
    } else if (item.itemType === ITEM_TYPES.METRIC) {
      formula = '$item.toCondition()';
    } else if (item.itemType === ITEM_TYPES.SCALAR) {
      start = undefined;
      end = undefined;
    }

    return sqFormulasApi.runFormula(
      {
        formula,
        parameters: encodeParameters({ item: item.id }),
        start,
        end,
        limit: 1, // Data all still has to be processed on the backend, but limits how much is returned
      },
      { cancellationGroup },
    );
  }

  /**
   * Request capsules for a capsule set and group them using bucketize if there are more than the allowed limit.
   *
   * @param {Object} args - Object container for arguments
   * @param {String} args.id - ID of the capsule series
   * @param {Object} args.range - displayRange or investigateRange from duration store
   * @param {Number} args.limit - If the total number of individual capsules is below this threshold, no grouping is
   * done, otherwise bucketize will be used to group them.
   * @param {String} args.cancellationGroup - A group name that can be used to cancel the requests
   * @param propertyColumns - All enabled property columns used to build filter formula
   * @param statisticsColumns - All enabled statistics columns used to build filter formula
   * @return {Promise} Promise that is resolved with capsule results
   */
  function computeCapsulesWithLimit(
    args: {
      id: string;
      range: RangeExport;
      limit: number;
      cancellationGroup: string;
      isUnbounded: boolean;
    },
    propertyColumns: PropertyColumn[],
    statisticsColumns: StatColumn[],
  ) {
    if (args.limit <= 0) {
      throw new Error('bucketize requires limit to be greater than 0');
    }
    const conditionIdentifierFormula = 'series';
    const parameters = { series: args.id };
    const rangeFormula = getCapsuleFormula(args.range);
    const formula = buildFilterFragmentForConditions(
      conditionIdentifierFormula,
      args.id,
      rangeFormula,
      propertyColumns,
      statisticsColumns,
      parameters,
      args.isUnbounded,
    );

    args['parameters'] = parameters;
    return service.computeCapsules({ formula, ...args }).then((result) => {
      // If maximum were returned then switch to grouped results
      const bucketWidthArg = `${args.range.duration.asMilliseconds() / args.limit}ms`;

      return result.capsules.length < args.limit
        ? result
        : service.computeCapsules(
            _.assign(
              {
                formula: `${formula}.bucketize(${bucketWidthArg}).parallelize()`,
              },
              args,
            ),
          );
    });
  }

  /**
   * Build up a formula for filtering capsules on trend. Used to match filters applied on the capsules panel.
   *
   * @param conditionIdentifierFormula - identifier for the  condition ex. $series, $a
   * @param conditionId - Id for the condition being filtered
   * @param queryRangeCapsule - formula that represents the range
   * @param propertyColumns - all property columns with filters assigned
   * @param statColumns - all statistic columns with filters assigned
   * @param parameters - parameters that map variables to guids
   * @param isUnbounded - true if the condition is unbounded
   * @returns {formula, parameters} formula that will be run by runFormula and parameters holding all variables of
   * the formula
   * */
  function buildFilterFragmentForConditions(
    conditionIdentifierFormula: string,
    conditionId: string,
    queryRangeCapsule: string,
    propertyColumns: PropertyColumn[],
    statColumns: StatColumn[],
    parameters,
    isUnbounded = true,
  ) {
    const durationFilterFragment = '.keep($capsule -> $capsule.duration()';

    const buildFilterFragmentForProperties = (column: PropertyColumn) =>
      column.propertyName === SeeqNames.Properties.Duration
        ? buildDurationFormula(column)
        : `.keep('${getColumnNameForAnyColumn(column)}', ${buildTableFilterPredicate(column.filter)})`;

    const buildDurationFormula = ({ filter: { values, operator } }: PropertyColumn) => {
      const formattedValues = _.chain(values)
        .map(({ value, units }: ValueWithUnitsItem) => `${value}${units}`)
        .join(', ')
        .value();
      return `${durationFilterFragment}.${operator}(${formattedValues}))`;
    };

    const statisticsSetPropertyFragment = _.chain(statColumns)
      .reject((column) => _.isUndefined(column.filter))
      .map((column, index) => {
        const variableName = `${getShortIdentifier(index)}Stat`;
        parameters[variableName] = column.signalId;
        return `.setProperty('${getColumnNameForAnyColumn(column)}', $${variableName}, ${column.stat})`;
      })
      .join('')
      .value();

    const statisticsFilterFragment = _.chain(statColumns)
      .reject((column) => _.isUndefined(column.filter))
      .map((column) => `.keep('${getColumnNameForAnyColumn(column)}', ${buildTableFilterPredicate(column.filter)})`)
      .join('')
      .value();

    const propertyFilterFragment = _.chain(propertyColumns)
      .reject((column) => _.isUndefined(column.filter))
      .map((column) => buildFilterFragmentForProperties(column))
      .join('')
      .value();

    // fragment limits condition to current view range because condition needs maximum duration to use .setProperty()
    const limitToViewRangeFragment =
      !_.isEmpty(statisticsFilterFragment) || _.includes(propertyFilterFragment, durationFilterFragment)
        ? `.${isUnbounded ? 'inside' : 'touches'}(condition(${queryRangeCapsule}))`
        : '';

    return `$${conditionIdentifierFormula}${limitToViewRangeFragment}${statisticsSetPropertyFragment}${statisticsFilterFragment}${propertyFilterFragment}`;
  }

  /**
   *  Column name used on the backend for property columns is 'propertyName' otherwise it's 'key' attribute.
   * @param column - a property or statistics column to retrieve the name
   * @return The right key to use as that columns name
   * */
  function getColumnNameForAnyColumn(column: PropertyColumn | StatColumn): string {
    return isPropertyColumn(column) ? column.propertyName : column.key;
  }

  /**
   * Returns filtered and decorated property and statistic columns that are in the capsules panel.
   *
   *@return {Object} - an Object containing decorated property columns, statistic columns and custom column keys.
   * */
  function getPropertyAndStatisticsColumns(): {
    allDecoratedPropertyColumns: PropertyColumn[];
    allDecoratedStatColumns: StatColumn[];
    customColumnKeys: string[];
  } {
    const decorateColumnsWithFilters = (columns) =>
      _.map(columns, (column) => {
        const filter = sqTrendStore.getColumnFilter(getColumnNameForAnyColumn(column));
        return filter ? _.assign(column, { filter }) : _.omit(column, ['filter']);
      });

    const propertyColumns = _.map(sqTrendStore.propertyColumns(TREND_PANELS.CAPSULES), (column: PropertyColumn) => ({
      key: column.key,
      propertyName: column.propertyName,
      invalidsFirst: true,
    }));

    const statColumns = _.chain(sqTrendStore.customColumns(TREND_PANELS.CAPSULES))
      .reject((column: any) => isItemRedacted(sqTrendSeriesStore.findItem(column.referenceSeries)))
      // Do not compute statistics in presentation mode unless they can be on the trend
      .reject(
        (column: any) =>
          isPresentationWorkbookMode() && !sqTrendStore.isColumnEnabled(TREND_PANELS.CHART_CAPSULES, column.key),
      )
      .map((column: any) => _.assign({}, _.find(TREND_SIGNAL_STATS, ['key', column.statisticKey]), column))
      .map((column) => {
        column.signalId = column.referenceSeries;
        delete column.referenceSeries;
        return column;
      })
      .sortBy((column) => column.signalId)
      .value();

    const REQUIRED_COLUMN_KEYS = ['startTime', 'endTime', 'isReferenceCapsule'];
    const customColumnKeys = _.map(propertyColumns.concat(statColumns), 'key');
    const combinedColumns = _.chain(CAPSULE_PANEL_TREND_COLUMNS as PropertyColumn[])
      .filter(
        (column) =>
          _.includes(REQUIRED_COLUMN_KEYS, column.key) ||
          sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, column.key),
      )
      .concat<PropertyColumn>({
        key: 'isReferenceCapsule',
        propertyName: SeeqNames.CapsuleProperties.ReferenceCapsule,
        invalidsFirst: true,
      })
      .concat(propertyColumns)
      .concat(statColumns)
      .value();

    const FIXED_COLUMNS: PropertyColumn[] = [
      {
        key: 'capsuleId',
        invalidsFirst: true,
        propertyName: SeeqNames.CapsuleProperties.CapsuleId,
      },
      {
        key: 'isUncertain',
        invalidsFirst: true,
        propertyName: SeeqNames.CapsuleProperties.OriginalUncertainty,
      },
      {
        key: 'conditionId',
        invalidsFirst: true,
        propertyName: SeeqNames.CapsuleProperties.ConditionId,
      },
      {
        key: 'startTime',
        invalidsFirst: true,
        propertyName: SeeqNames.CapsuleProperties.Start,
      },
      {
        key: 'endTime',
        invalidsFirst: false,
        propertyName: SeeqNames.CapsuleProperties.End,
      },
    ];

    const columnsFiltered = _.reject(combinedColumns, (column) =>
      _.includes(
        _.flatMap(FIXED_COLUMNS, (col) => col.key),
        column.key,
      ),
    );
    const allColumns = _.concat(FIXED_COLUMNS as Partial<StatColumn & PropertyColumn>[], columnsFiltered);
    const allPropertyColumns = _.filter(allColumns, (column) => column.propertyName) as PropertyColumn[];
    const allStatColumns = _.filter(allColumns, (column) => column.stat) as StatColumn[];

    const allDecoratedPropertyColumns = decorateColumnsWithFilters(allPropertyColumns);
    const allDecoratedStatColumns = decorateColumnsWithFilters(allStatColumns);
    return {
      allDecoratedPropertyColumns,
      allDecoratedStatColumns,
      customColumnKeys,
    };
  }

  /**
   * Runs a formula function that generates a table and uses "unbound" parameters.
   *
   * @param {String} tableId - the id of the table formula to run.
   * @param {String} cancellationGroup - the group used to cancel the requests
   * @param {Object} args - Object container for arguments
   * @param {Object} args.fragments - A formula fragment object where the keys are the names of unbound formula function
   * variables and the values are the corresponding formula fragments that are used to compute the value of the
   * variable.

   * @return {Promise} that resolves when the formula run is complete and the data is available.
   */
  function runTable(tableId, cancellationGroup, args?) {
    const viewCapsule = getViewCapsuleParameter();
    let fragment = args && args.fragments ? args.fragments : {};
    fragment = _.set(fragment, viewCapsule.name, viewCapsule.formula);
    return sqFormulasApi
      .runFormula(
        {
          _function: tableId,
          fragments: encodeParameters(fragment),
          formula: null,
          parameters: null,
        },
        { cancellationGroup },
      )
      .then(({ headers, data }) => {
        if (!_.isNil(headers['server-timing'])) {
          (data as any).timingInformation = headers['server-timing'];
        }
        if (!_.isNil(headers['server-meters'])) {
          (data as any).meterInformation = headers['server-meters'];
        }

        return data;
      });
  }

  /**
   * Builds the table formula and sends it to the backend, returning the result as a CapsuleTable. Returns a row for
   * each capsule, and columns that are either capsule properties or signal statistics during that capsule. Both
   * propertyColumns and statColumns should be given in the desired output order.
   *
   * @param columns - The ordered columns of the desired tables, split into CapsulePropertyColumns and StatColumns
   * @param columns.propertyColumns - The columns that are properties of the capsule. These would be grouped using
   * group() in Formula. Ex: 'Start', 'End', 'Duration', etc
   * @param columns.statColumns - The columns of the table that are statistics of a signal during a given capsule. Ex:
   * $series.range(), $series.percentGood, etc
   * @param range - The time range that this query covers
   * @param itemIds - The array of Condition or Metric Ids. These will be converted to formulas using
   *    buildConditionFormula
   * @param sortParams - For sorting the table
   * @param cancellationGroup - the cancellation group
   * @param offset - Row offset into the table results
   * @param limit - max number of rows desired in returned table. If formula generates more than this, the
   * hasNextPage flag of the return value will be true.
   * @param additionalFormula - extra formula segment to be tacked onto the end of the capsule table formula
   * @param buildAdditionalFormula - Can be used to add additional formula snippet to the end of the formula
   * @param buildConditionFormula - converts the items ids and corresponding parameters to a formula
   * representation of the condition. (e.g. for metrics, the method passed in converts a metric to a condition
   * using formula and the parameters modified to support extra properties)
   * @param root - Used to run a formula across assets. The ID of the root asset.
   * @param reduceFormula - Used when running a formula across assets, a formula that can further reduce the results of
   *   each asset result.
   * @returns FormulaTable with the table and headers, and a flag for whether there are additional results
   * @throws Error if there is not at least one property column
   */
  function computeCapsuleTable({
    columns,
    range,
    itemIds,
    sortParams,
    offset,
    limit,
    buildAdditionalFormula = () => '',
    buildConditionFormula = buildBasicConditionFormula,
    buildStatFormula = buildStatFormulaFragment,
    root,
    reduceFormula,
    cancellationGroup,
  }: {
    columns: { propertyColumns: PropertyColumn[]; statColumns: StatColumn[] };
    range: Range;
    itemIds: string[];
    sortParams: TableSortParams;
    offset: number;
    limit: number;
    buildAdditionalFormula?: BuildAdditionalCapsuleTableFormulaCallback;
    buildConditionFormula?: BuildConditionFormulaCallback;
    buildStatFormula?: BuildStatFormulaCallback;
    root?: undefined | string;
    reduceFormula?: undefined | string;
    cancellationGroup: string;
  }): Promise<FormulaTable> {
    const { propertyColumns, statColumns } = columns;
    if (!_.some(propertyColumns, { key: 'capsuleSortKey' })) {
      propertyColumns.push({
        key: 'capsuleSortKey',
        propertyName: SeeqNames.CapsuleProperties.CapsuleIdSafe,
        invalidsFirst: true,
      });
    }
    const { orderedAdditionalSortPairs } = sortParams;
    const defaultSort = { sortBy: 'capsuleSortKey', sortAsc: true };
    sortParams.orderedAdditionalSortPairs = orderedAdditionalSortPairs || [];
    if (
      !_.some(sortParams.orderedAdditionalSortPairs, {
        sortBy: 'capsuleSortKey',
      })
    ) {
      sortParams.orderedAdditionalSortPairs.push(defaultSort);
    }
    const itemParameters = extractParametersMap(itemIds, statColumns);

    const { formula: conditionFormulas, parameters } = buildConditionFormula(itemIds, itemParameters);

    const formula =
      buildPropertyColumnsFragment(conditionFormulas, propertyColumns, range) +
      buildStatFormula(statColumns, itemParameters) +
      buildAdditionalFormula(itemIds, itemParameters) +
      buildCapsuleTableSortFragment(sortParams, propertyColumns, statColumns) +
      buildTableLimitFormulaFragment(offset, limit);

    let columnIndex = 0;
    const mapColumnKeysToColumnIndex = _.chain(propertyColumns)
      .concat(
        _.flatMap((column) => _.pick(column, 'key')),
        statColumns,
      )
      .reject((column) => column.propertyName === SeeqNames.CapsuleProperties.CapsuleIdSafe)
      .flatMap((column) => column.key)
      .transform((result, key) => {
        result[key] = columnIndex++;
      }, {} as { [k: string]: number })
      .value();

    return service
      .computeTable({
        formula,
        cancellationGroup,
        limit,
        offset,
        parameters,
        root,
        reduceFormula,
        usePost: true, // Formula can be very long
      })
      .then((results) => {
        // one column will be the Capsule SortKey, which we added w/out telling the caller, so we do not want to pass
        // it through to the caller
        const indexOfColumnToRemove = _.findIndex(results.headers, {
          name: SeeqNames.CapsuleProperties.CapsuleIdSafe,
        });
        _.pullAt(results.headers, [indexOfColumnToRemove]);
        _.forEach(results.data, (row) => {
          _.pullAt(row, [indexOfColumnToRemove]);
        });

        // Make it easy to find the header column for stat columns
        _.forEach(statColumns, (statColumn) => {
          if (results.headers && results.headers[mapColumnKeysToColumnIndex[statColumn.key]]) {
            results.headers[mapColumnKeysToColumnIndex[statColumn.key]].name = statColumn.key;
          }
        });

        // Add any columns that were dynamically added via .addColumn()
        _.forEach(results.headers, (header, index: number) => {
          if (!_.has(mapColumnKeysToColumnIndex, header.name)) {
            mapColumnKeysToColumnIndex[header.name] = index;
          }
        });

        results.table = _.map(results.data, (row) => {
          const indexOfCapsuleId = _.findIndex(results.headers, {
            name: SeeqNames.CapsuleProperties.CapsuleId,
          });
          if (indexOfCapsuleId >= 0 && !row[indexOfCapsuleId]) {
            // Generate a temporary ID for results missing one
            row[indexOfCapsuleId] = generateTemporaryId();
          }
          const newRow = _.transform(
            mapColumnKeysToColumnIndex,
            (rowObject, index: number, key) => {
              rowObject[key] = row[index];
            },
            {},
          );
          newRow.startTime = newRow.startTime ? moment(newRow.startTime).valueOf() : null;
          newRow.endTime = newRow.endTime ? moment(newRow.endTime).valueOf() : null;
          return newRow;
        });
        const hasNextPage = results.data.length > limit;
        if (hasNextPage) {
          results.table = results.table.splice(0, limit);
        }
        return {
          data: {
            table: results.table,
            hasNextPage,
            headers: results.headers,
          },
        };
      });
  }

  /**
   * Get the callback to pass to the formula service, that creates formulas for signal statistic columns
   * Used for computing the table.
   *
   * @param statColumns - list of statistic columns in the table
   * @param itemTransformer - callback function that returns item reference formula
   * @returns callback that gets the condition formulas for conditions
   */
  function getBuildStatFormulaFunctionCallback(
    statColumns: any[],
    itemTransformer: (itemIdentifier: string, signalId: string, parameters: ParametersMap) => string = (i) => i,
  ): BuildStatFormulaCallback {
    return (statsList: StatColumn[], parameters: ParametersMap) => {
      const mapIdsToShortIdentifiers = _.invert(parameters);
      const statColumnsFormula = _.chain(statColumns)
        .transform((memo, column: StatColumn) => {
          memo[column.signalId] = _.concat(memo[column.signalId] ?? [], column.stat);
        }, {} as { [key: string]: string[] })
        .flatMap((statsList: string[], signalId) => {
          const itemReference = itemTransformer(`$${mapIdsToShortIdentifiers[signalId]}`, signalId, parameters);
          const commaSeparatedStats = _.join(statsList, ', ');
          return `.addStatColumn('${signalId}', ${itemReference}, ${commaSeparatedStats})`;
        })
        .join('')
        .value();

      return `${statColumnsFormula}`;
    };
  }

  /**
   * Get distinct string values for a table
   *
   * Fetch the distinct string values for each string-valued column in a table
   * noop in presentation mode since the filters can't be changed.
   *
   * @param fetchParams - fetch param object, for the column that values are being fetched for
   * @param columnKeyAndName - column key and name for the table that will fetch values for string column
   * @param cancellationGroup - cancellation group
   * @param dispatchAction - action that is invoked on the returned string values
   * @returns promise for computing the table with values
   */
  function fetchTableDistinctStringValues(
    fetchParams,
    columnKeyAndName: { columnKey: string; columnName: string },
    dispatchAction: string,
    cancellationGroup: string,
  ): Promise<void> {
    const noResultsPayload = { data: { table: [] } };
    return (
      (
        _.isEmpty(fetchParams)
          ? Promise.resolve(noResultsPayload)
          : service.computeCapsuleTable({
              columns: {
                propertyColumns: fetchParams.propertyColumns,
                statColumns: fetchParams.statColumns,
              },
              range: sqDurationStore.displayRange,
              itemIds: fetchParams.ids,
              buildConditionFormula: fetchParams.buildConditionFormula,
              sortParams: fetchParams.sortParams,
              root: fetchParams.assetId,
              reduceFormula: fetchParams.reduceFormula,
              buildAdditionalFormula: fetchParams.buildAdditionalFormula,
              buildStatFormula: fetchParams.buildStatFormula,
              offset: 0,
              limit: 10000,
              cancellationGroup,
            })
      )
        // CRAB-27265: Do not error if run across assets and one of the conditions is not asset-based
        .catch(() => noResultsPayload)
        .then((stringValueTable) => {
          flux.dispatch(dispatchAction, {
            stringValueTable,
            columnKeyAndName,
          });
        })
    );
  }

  /**
   * Gets the necessary information to fetch distinct string values that appear in the column.
   * The fetch params correspond to a capsule table that has only the necessary information to get the
   * column's string values.
   *
   * @param allColumns - all columns enabled in the table
   * @param conditions - all conditions in capsules panel
   * @param statColumns - all enabled statistic columns
   * @param propertyColumns - all enabled property columns
   * @param columnKey - column for which to fetch string values
   * @returns obj.fetchParams: fetch param object, with a table formula
   *          obj.columnKeyAndName: array of {columnKey, columnNames} objects where columnKey is the key of
   *            the displayed frontend table, and columnNames contains the names of the corresponding columns in
   *            the computed table returned from the backend, since those columns are combined to form the
   *            displayed table.
   */
  function getCapsulesPanelStringColumnFetchParams(
    allColumns,
    conditions,
    statColumns: StatColumn[],
    propertyColumns: PropertyColumn[],
    columnKey: string,
  ): Promise<FetchParamsForColumn> {
    const statParams = service.getStringStatFetchParams(
      columnKey,
      statColumns,
      allColumns,
      _.map(conditions, 'id'),
      (statColumn) => service.getBuildStatFormulaFunctionCallback([statColumn]),
    );
    if (statParams) {
      return Promise.resolve(statParams);
    }

    const propertyColumn = _.find(propertyColumns, ({ propertyName }) => propertyName === columnKey);
    if (propertyColumn) {
      return service.getStringPropertyFetchParams(conditions, propertyColumn);
    }

    throw new Error('trying to fetch string values for an invalid column');
  }

  /**
   * Get the fetch params needed to get a pick-list of options for a statistic column. Only the endValue statistic
   * supports this.
   */
  function getStringStatFetchParams(
    columnKey: string,
    statColumns: StatColumn[],
    allColumns: any[],
    ids: string[],
    getBuildStatFormula: (statColumn: StatColumn) => BuildStatFormulaCallback,
    assetId?: string,
    buildConditionFormula?: BuildConditionFormulaCallback,
  ): FetchParamsForColumn | undefined {
    const statColumn = _.find(
      statColumns,
      (column: any) =>
        column.statisticKey === 'statistics.endValue' &&
        column.key === columnKey &&
        isStringSeriesUtil(sqTrendSeriesStore.findItem(column.signalId)),
    );
    if (statColumn) {
      const buildAdditionalFormula = () => `.distinctColumnValues('${statColumn.signalId} ${statColumn.columnSuffix}')`;
      return {
        columnKeyAndName: { columnKey: statColumn.key, columnName: statColumn.key },
        fetchParams: {
          ids,
          assetId,
          propertyColumns: [
            _.find(allColumns, {
              key: COLUMNS_AND_STATS.startTime.key,
            }) || COLUMNS_AND_STATS.startTime,
            _.find(allColumns, { key: COLUMNS_AND_STATS.endTime.key }) || COLUMNS_AND_STATS.endTime,
          ],
          statColumns: [statColumn],
          reduceFormula: assetId ? `$result${buildAdditionalFormula()}` : undefined,
          buildAdditionalFormula: assetId ? undefined : buildAdditionalFormula,
          buildConditionFormula,
          sortParams: DEFAULT_CONDITION_TABLE_SORT(),
          itemColumnsMap: undefined,
          buildStatFormula: getBuildStatFormula(statColumn),
        },
      };
    }
  }

  /**
   * Gets params needed to produce a pick-list for string-value property columns in a table.
   *
   * @param conditions - conditions to request capsule properties for.
   * @param propertyColumn - property column that matches columnKey that is being filtered.
   * @param assetId - The root asset id if it is run across assets.
   * @returns Promise that resolves to an array of params needed to build up a table formula that will return the
   * pick-list of values for the property.
   */
  function getStringPropertyFetchParams(
    conditions,
    propertyColumn: PropertyColumn,
    assetId?: string,
  ): Promise<FetchParamsForColumn> {
    return Promise.all(
      _.map(conditions, (condition) =>
        // Find all conditions that have the property
        fetchCapsuleProperties(condition.id).then((capsuleProperties) =>
          _.some(
            capsuleProperties,
            (capsuleProperty) =>
              capsuleProperty.name === propertyColumn.propertyName && capsuleProperty.unitOfMeasure === STRING_UOM,
          )
            ? condition.id
            : undefined,
        ),
      ),
    ).then((conditionIds) => {
      const buildAdditionalFormula = () => `.distinctColumnValues('${propertyColumn.propertyName}')`;
      return {
        columnKeyAndName: {
          columnKey: propertyColumn.key,
          columnName: propertyColumn.key,
        },
        fetchParams: {
          ids: _.compact(conditionIds),
          propertyColumns: [propertyColumn],
          statColumns: [],
          assetId,
          buildAdditionalFormula: assetId ? undefined : buildAdditionalFormula,
          reduceFormula: assetId ? `$result${buildAdditionalFormula()}` : undefined,
          sortParams: DEFAULT_CONDITION_TABLE_SORT(),
          itemColumnsMap: undefined,
        },
      };
    });
  }

  /**
   * Convert the condition ids to a formula counterpart
   *
   * @param ids - ids of all conditions in the table
   * @param parameters - The map between the parameter identifier and item id
   * @return The formula snippet that represents all the items and the parameter map.
   */
  function buildBasicConditionFormula(ids, parameters) {
    const idToShortName = _.invert(parameters);
    return {
      formula: _.map(ids, (id) => `$${idToShortName[id]}`).join(', '),
      parameters,
    };
  }

  /**
   * Extracts a map of short identifiers, used in Formula input, to their corresponding real id's
   *
   * @param conditionIds - The id's associated with the conditions in the intended Formula
   * @param statColumns - the statColumns that will be added in this Formula
   * @returns a map of short identifiers to real ids, in the form used by runFormula
   */
  function extractParametersMap(conditionIds: string[], statColumns: StatColumn[]): ParametersMap {
    let formulaVariableIndex = 0;
    return _.merge(
      _.transform(
        conditionIds,
        (memo, id) => {
          memo[getShortIdentifier(formulaVariableIndex++)] = id;
        },
        {} as { [id: string]: string },
      ),
      _.chain(statColumns)
        .uniqBy('signalId')
        .transform((memo, column) => {
          memo[getShortIdentifier(formulaVariableIndex++)] = column.signalId;
        }, {})
        .value(),
    );
  }

  /**
   * Builds up the Formula fragment for the stat columns desired for a particular table.
   *    Ex: ".addStatColumn('s1', $s1, average()).addStatColumn('s2', $s2, range(), average())"
   *
   * @param statColumns - ordered list of stats for this table. Stats are ordered by their signalId first, then by
   * their stat formula. E.g., in the above example, the statColumns would be in the order [s1.average, s2.range,
   * s2.average]
   * @param parameters - Formula parameters map. We may add additional parameters in the map
   * @returns the Formula segment for the given StatColumn(s), which can be appended to a table Formula. The order
   * of the columns will be ordered first by signalId, then by stat.
   */
  function buildStatFormulaFragment(statColumns: StatColumn[], parameters: ParametersMap): string {
    const mapIdsToShortIdentifiers = _.invert(parameters);
    return _.chain(statColumns)
      .transform((memo, column: StatColumn) => {
        memo[column.signalId] = (memo[column.signalId] ?? []).concat(column.stat);
      }, {} as { [key: string]: string[] })
      .flatMap((statsList: string[], signalId) => {
        const identifier = mapIdsToShortIdentifiers[signalId];
        const commaSeparatedStats = _.join(statsList, ', ');
        return `.addStatColumn('${signalId}', $${identifier}, ${commaSeparatedStats})`;
      })
      .join('')
      .value();
  }

  /**
   * Builds the Formula fragment that groups all the given PropertyColumns together
   *
   * @param propertyColumns - The columns that are properties of the capsule. At least one required.
   *    Ex: 'Start', 'End', 'Condition ID', 'Capsule ID', etc.
   * @returns the Formula fragment that groups together the property columns
   * @throws Error if there is not at least one property column
   */
  function buildGroupFormulaFragment(propertyColumns: PropertyColumn[]): string {
    assertAtLeastOnePropertyColumn(propertyColumns);
    const capsuleProperties = _.chain(propertyColumns)
      .map((column) => `'${column.propertyName}'`)
      .join(', ')
      .value();
    return `group(${capsuleProperties})`;
  }

  /**
   * throws an error if there is not at least one propertyColumn
   *
   * @param propertyColumns - The columns that are properties of the capsule. At least one required.
   *    Ex: 'Start', 'End', 'Condition ID', 'Capsule ID', etc.
   * @returns true if there is at least one property column
   * @throws Error if there is not at least one property column
   */
  function assertAtLeastOnePropertyColumn(propertyColumns: PropertyColumn[]) {
    if (propertyColumns.length < 1) {
      throw new TypeError('There must be at least one property column');
    }
  }

  /**
   * Builds the Formula fragment that constructs the CapsuleTable, with only the property columns.
   *
   * @param conditionFormula - The formula fragment the conditions
   * @param propertyColumns - The columns that are properties of the capsule. At least one required.
   *    Ex: 'Start', 'End', 'Condition ID', 'Capsule ID', etc.
   * @param range - The time range that this query covers
   * @returns the CapsuleTable() Formula fragment
   * @throws Error if there is not at least one property column
   */
  function buildPropertyColumnsFragment(conditionFormula: string, propertyColumns: PropertyColumn[], range: Range) {
    assertAtLeastOnePropertyColumn(propertyColumns);
    const propertyColumnFragment = buildGroupFormulaFragment(propertyColumns);
    return `capsuleTable(capsule('${moment(range.start).toISOString()}', '${moment(
      range.end,
    ).toISOString()}'), CapsuleBoundary.Overlap, ${propertyColumnFragment}, ${conditionFormula})`;
  }

  /**
   * Build the table limit() Formula fragment
   *
   * @param rowOffset - number of rows to offset into
   * @param maxRows - max number of rows to return
   * @returns the Formula fragment for setting a table limit
   */
  function buildTableLimitFormulaFragment(rowOffset: number, maxRows: number) {
    // the API says we start at zero, but the table object is 1 based, so we add 1
    const startRow = rowOffset + 1;
    // we also want one extra row than the maxRows in order to determine if there is more data
    const endRow = startRow + maxRows;
    return `.limit(${startRow}, ${endRow})`;
  }

  /**
   * Builds the table .sort() Fragment.
   *
   * @param sortParams
   * @param sortParams.sortBy - The column key to sort by
   * @param sortParams.sortAsc - False to sort descending
   * @param sortParams.orderedAdditionalSortPairs - a list of additional sort pairs. These will be secondary sorts
   * @param propertyColumns - The columns that are properties of the capsuleTable.
   * @param statColumns - The columns of the table that are statistics of a signal during a given capsule. Ex:
   * $series.range(), $series.percentGood, etc
   * @returns the Formula fragment that can be used to sort a table in Calc Engine
   */
  function buildCapsuleTableSortFragment(
    sortParams: TableSortParams,
    propertyColumns: PropertyColumn[],
    statColumns: StatColumn[],
  ) {
    assertAtLeastOnePropertyColumn(propertyColumns);
    const columns = _.concat(propertyColumns as Partial<StatColumn & PropertyColumn>[], statColumns);
    const { sortBy, sortAsc, orderedAdditionalSortPairs } = sortParams;
    const buildSecondarySortOrderString = (sortOrder: { sortBy: string; sortAsc: boolean }) => {
      const index = _.findIndex(columns, { key: sortOrder.sortBy });
      const column = columns[index] || { propertyName: sortOrder.sortBy };
      const identifier = (column as StatColumn).signalId;
      const propertyName = (column as PropertyColumn).propertyName;
      const columnSuffix = (column as StatColumn).columnSuffix;
      const columnHeader = propertyName ? propertyName : `${identifier} ${columnSuffix}`;
      const direction = sortOrder.sortAsc ? 'asc' : 'desc';
      return `'${columnHeader}', '${direction}'`;
    };
    let indexSortBy = _.findIndex(columns, (column) => column.key === sortBy);
    if (indexSortBy < 0 && !sortParams.isCustomColumn) {
      indexSortBy = _.findIndex(columns, {
        key: COLUMNS_AND_STATS.startTime.key,
      });
      indexSortBy = indexSortBy >= 0 ? indexSortBy : 0;
    }
    const column = columns[indexSortBy] || { propertyName: sortParams.sortBy };
    // Depending on the column type, the column MUST have either columnSuffix or propertyName. There is at least one
    // property column, so only one  of these will be undefined.
    const columnSuffix: string | undefined = (column as StatColumn).columnSuffix;
    const propertyName: string | undefined = (column as PropertyColumn).propertyName;

    // Sort invalids first is required for Capsule Panel Table when sorting ascending, except when sorting by endTime,
    // but default treatment of invalids is desired when sorting descending.
    const direction = column.invalidsFirst ? "'inv, asc'" : "'asc'";
    const identifier = (column as StatColumn).signalId;
    const columnToSort = propertyName ? propertyName : `${identifier} ${columnSuffix}`;
    const primarySortArgs = `'${columnToSort}', ${direction}`;
    const additionalSorts = _.chain(orderedAdditionalSortPairs)
      .flatMap((sortOrder) => buildSecondarySortOrderString(sortOrder))
      .join(', ')
      .value();
    const finalSortArguments = _.chain([primarySortArgs, additionalSorts]).reject(_.isEmpty).join(', ').value();
    const initialSortFragment = `.sort(${finalSortArguments})`;
    return sortAsc ? initialSortFragment : `${initialSortFragment}.reverse()`;
  }

  /**
   * Produces a formula to tack onto the end of a formula for a Simple Table, which will
   * determine which rows to keep.
   * Uses the .keepColumnValues() operator.
   *
   * @param columnName - name of the column in the table we want to filter
   * @param filter - the filter to apply, containing the operator and values
   * @param [aggregationFunction] - an aggregation function, relevant only if the stat doesn't map directly
   * to a column name (MinValue and MaxValue)
   * @returns a formula for the backend to use to filter the table
   */
  function buildSimpleTableFilterFormulaFragment(columnName: string, filter: TableColumnFilter): string {
    const predicate = buildTableFilterPredicate(filter);
    return `.keepColumnValues('${columnName}', ${predicate})`;
  }

  /**
   * Finds the column suffix for the specified aggregation function.
   *
   * @param aggregationFunction - the aggregation function
   * @returns the column suffix or an empty string
   */
  function findColumnSuffix(aggregationFunction: string): string {
    const statColumn = _.find([...TREND_SIGNAL_STATS, ...TREND_CONDITION_STATS, ...TREND_METRIC_STATS], (column) => {
      if (column.prefix) {
        return _.startsWith(aggregationFunction, column.prefix);
      }
      return column.stat === aggregationFunction;
    });
    return statColumn?.columnSuffix ?? '';
  }

  /**
   * build a predicate for filtering out rows from a table.
   *
   * @param filter - filter to be applied on the provided column's values
   * */
  function buildTableFilterPredicate(filter: TableColumnFilter): string {
    let predicate: string;
    if (
      _.includes(
        [
          PREDICATE_API[COMPARISON_OPERATORS_SYMBOLS.IS_MATCH],
          PREDICATE_API[COMPARISON_OPERATORS_SYMBOLS.IS_NOT_MATCH],
        ],
        filter.operator,
      )
    ) {
      if (filter.usingSelectedValues && filter.values.length > 1) {
        const regex = _.join(filter.values, '|');
        predicate = `${filter.operator}('/${regex}/')`;
      } else {
        predicate = `${filter.operator}('${filter.values[0]}')`;
      }
    } else {
      const formattedValues = _.chain(filter.values)
        .map((value) =>
          _.has(value, 'value') && _.has(value, 'units')
            ? `${(value as ValueWithUnitsItem).value}${(value as ValueWithUnitsItem).units}`
            : value,
        )
        .join(', ')
        .value();
      predicate = `${filter.operator}(${formattedValues})`;
    }
    return predicate;
  }

  /**
   * Produces a formula to tack onto the end of a formula for a Table, which will determine which rows to keep. Uses
   * the .keepRows() operator.
   *
   * @param columnName - name of the column in the table we want to filter
   * @param filter - the filter to apply, containing the operator and values
   * @returns a formula for the backend to use to filter the table
   */
  function buildTableFilterFormulaFragment(columnName: string, filter: TableColumnFilter): string {
    const fragmentStart = `.keepRows('${columnName}'`;
    const predicate = buildTableFilterPredicate(filter);
    return `${fragmentStart}, ${predicate})`;
  }

  /**
   * Returns a function that builds up part of the formula that filters table rows.
   * For condition table it builds additional formula fragments.
   *
   * @param propertyColumns - property columns
   * @param statisticsColumns - statistics columns
   * @param isCapsulesPanelTable - true if building filter formula for capsules pane, false if for condition table
   * @param metricFilterFormulas - formula fragment for metric filters
   * @param isRunAcrossAssets - true if condition table is run across asserts
   * @param convertUnitsIds - ids of units to convert
   * @param isHomogenizeUnits - true if units need to be homogenized
   */
  function getBuildAdditionalFormula(
    propertyColumns: PropertyColumn[],
    statisticsColumns: StatColumn[],
    isCapsulesPanelTable = false,
    metricFilterFormulas = [],
    isRunAcrossAssets = false,
    convertUnitsIds: string[] = [],
    isHomogenizeUnits = false,
  ): BuildAdditionalCapsuleTableFormulaCallback {
    return (ids, parameters) => {
      const filters = _.concat(buildFilterFormula(propertyColumns, statisticsColumns), metricFilterFormulas);
      if (isCapsulesPanelTable) {
        return filters.join('');
      }
      const idToShortName = _.invert(parameters);

      let convertUnitsFormula = '';
      if (isRunAcrossAssets) {
        if (isHomogenizeUnits) {
          _.forEach(convertUnitsIds, (id) => {
            const itemReference = idToShortName[id];
            const itemUomReference = `fixed_${itemReference}_${ITEM_UOM}`;
            parameters[itemUomReference] = `$${id}.property('${SeeqNames.Properties.ValueUom}')`;
            convertUnitsFormula += `.convertUnits('${id}_value', $${itemUomReference})`;
          });
        } else {
          _.forEach(convertUnitsIds, (id) => {
            convertUnitsFormula += `.convertUnits('${id}_value', '')`;
          });
        }
      }
      return `.mergeRows()${convertUnitsFormula}${filters.join('')}`;
    };
  }

  /**
   * build formula fragment for property columns and statistics columns.
   *
   * @param propertyColumns - property columns
   * @param statisticsColumns - statistics columns
   * */
  function buildFilterFormula(propertyColumns: PropertyColumn[], statisticsColumns: StatColumn[]): string[] {
    const propertyFilterFormulas = _.chain(propertyColumns)
      .filter((column) => !!column.filter)
      .map((column) => buildTableFilterFormulaFragment(column.propertyName, column.filter))
      .value();
    const statFilterFormulas = _.chain(statisticsColumns)
      .filter((column) => !!column.filter)
      .map((column) => buildTableFilterFormulaFragment(`${column.signalId} ${column.columnSuffix}`, column.filter))
      .value();
    return _.concat(statFilterFormulas, propertyFilterFormulas);
  }

  /**
   * Determines the default name for an item that can be created. Given a prefix it finds all other items
   * starting with that prefix and then finds the maximum in that set.
   *
   * @param {String} prefix - The prefix of the item, assumed to not have regex special characters
   * @param {String|undefined} scope - IDs of workbooks which will limit the results to those items that are scoped
   *   to the workbooks or are in the global scope, undefined to search all items.
   * @returns {Object} Empty object that fills in the name property when the promise resolves
   */
  function getDefaultName(prefix, scope) {
    // Get any items named with the prefix "Prefix" or the prefix with a number "Prefix 2" etc
    const searchRegex = new RegExp(`^\\s*${_.escapeRegExp(prefix)}\\s*(\\d+)?\\s*$`, 'i');
    return sqItemsApi
      .searchItems({
        filters: [`name==/${searchRegex.source}/`],
        types: NAME_SEARCH_TYPES,
        scope: scope ? [scope] : undefined,
        limit: 10000,
      })
      .then(({ data }) =>
        _.chain(data?.items)
          .map(({ name }) => {
            const match = searchRegex.exec(name);
            // Treat a "Prefix" as if it was "Prefix 1" since they are somewhat ambiguous. This lets us generate a
            // series of names like "Prefix", "Prefix 2", "Prefix 3" instead of "Prefix", "Prefix 1", "Prefix 2"
            return match && (_.isNil(match[1]) ? 1 : parseInt(match[1], 10));
          })
          .max()
          .thru((num) => `${prefix} ${(_.isFinite(num) ? num : 0) + 1}`)
          .value(),
      );
  }

  /**
   * Extracts dependency information from the provided item and adds the assets property.
   *
   * @param {Object} options - Item information
   * @param {String} options.id - Id of the item
   * @returns {Object} Transformed object containing new properties.
   *                   {Object[]} .assets - Array of asset dependencies
   *                   {String} .assets[].id - ID of asset dependency
   *                   {String} .assets[].name - Name of asset dependency
   *                   {String} .assets[].formattedName - Name that includes the full asset path
   */
  function getDependencies(options: { id: string }): ng.IPromise<any> {
    return sqItemsApi.getFormulaDependencies(options).then(({ data }) => {
      const items = getDependenciesWithRelevantAssets(data, data.dependencies);

      const assets = _.chain(items)
        .map('ancestors')
        .reject(_.isEmpty)
        .map(getAssetFromAncestors)
        .sortBy(['formattedName'])
        .uniqBy('id')
        .value();

      return { ...data, assets };
    });
  }

  /**
   * Find the items whose asset parents are the asset parents of this item.
   *
   * If this item has an asset parent, we will use that parent directly. Otherwise we'll (effectively) recurse
   * through the parameters until we find items with asset parents, or leaf nodes. See CRAB-16532.
   *
   * @param item The root item
   * @param dependencies The item's dependencies
   */
  function getDependenciesWithRelevantAssets(item, dependencies) {
    // Keep track of every ID we find that matters. This should end up being items with assets, or assetless leaf nodes.
    const resultIdSet = [];

    const findParameters = _.chain(dependencies)
      .flatMap((dependency) => _.map(dependency.parameterOf, (item) => [item.id, dependency]))
      .groupBy(_.first)
      .map((pairs, dependee) => [dependee, _.map(pairs, (pair) => pair[1])])
      .fromPairs()
      .value();

    // Items whose assets we're currently trying to find out. Start with the root item.
    let queue = [item];

    while (!_.isEmpty(queue)) {
      // take the first param.
      const current = queue.pop();

      if (_.includes(resultIdSet, current.id)) {
        continue;
      }

      if (!_.isEmpty(current.ancestors)) {
        // if it has an ancestor, put its id in the resultIds, we're done with it.
        resultIdSet.push(current.id);
      } else {
        // if not, add its parameters to the queue of possible ancestors
        const parameters = findParameters[current.id];

        if (_.isEmpty(parameters)) {
          // leaf node; add to results so we don't bother with it anymore
          resultIdSet.push(current.id);
        } else {
          // not already examined, no asset ancestor - recurse to its parameters
          queue = queue.concat(parameters);
        }
      }
    }

    // Include the original item in the possible results we pick from
    const possibleSet = _.concat(dependencies, item);

    return _.filter(possibleSet, (d) => _.includes(resultIdSet, d.id));
  }

  /**
   * Get the asset's path in its asset tree as a string, with >> as a separator
   *
   * @param asset - the child asset whose upstream path you want
   * @param maxDepth - The max number of layers to return of the path. 1 results in just the asset name, 2 results
   * in a path that includes the asset name, and the immediate parent's name, like PARENT >> CHOSEN_ASSET. Use
   * undefined for no limit
   */
  function getAssetPath(asset: any, maxDepth?: number | undefined): ng.IPromise<string> {
    return getDependencies({ id: asset.id }).then(({ ancestors, name }) =>
      getAssetPathFromAncestors(ancestors.concat({ name }), maxDepth),
    );
  }

  /**
   * Get the asset's path from ancestors as a string, with >> as a separator
   *
   * @param ancestors - asset ancestors
   * @param maxDepth - The max number of layers to return of the path. 1 results in just the asset name, 2 results
   * in a path that includes the asset name, and the immediate parent's name, like PARENT >> CHOSEN_ASSET. Use
   * undefined for no limit
   */
  function getAssetPathFromAncestors(ancestors: ItemPreviewV1[], maxDepth: number | undefined): string {
    return _.chain(!_.isNil(maxDepth) ? _.takeRight(ancestors, Math.max(1, maxDepth)) : ancestors)
      .map('name')
      .join(ASSET_PATH_SEPARATOR)
      .value();
  }

  /**
   * Fetch the siblings of an asset so we can display / change the values inside an AssetSelection
   * @param selection the specific assetSelection
   * @returns Promise<Asset[]> List of assets available for this AssetSelection
   */
  function getAssetSiblings(selection: AssetSelection) {
    const parent = _.last(selection.asset.ancestors);
    return sqTreesApi.getTree({ id: parent.id }).then(
      ({
        data: {
          item: { ancestors },
          children,
        },
      }) =>
        _.chain(children)
          .filter(isAsset)
          .map((child) => ({
            ..._.omit(child, ['properties']), // Omit properties to save memory and because not needed
            ancestors: ancestors.concat(parent), // Ensures parent can be found when sibling is selected
            name: this.getAssetPathFromAncestors(
              ancestors.concat([parent, child]),
              selection.assetPathDepth || undefined,
            ),
          }))
          .value(),
    );
  }
}

/**
 * describes the sort order(s) for sorting a table using the sort() Formula
 */
export interface TableSortParams {
  /** The key for the primary column to be sorted on */
  sortBy: string;
  /** True to sort the primary column in ascending order, and false to sort in descending order */
  sortAsc: boolean;
  /** Additional <sortBy, sortAsc> pairs, which describe the sort order for any additional columns to sort by. These
   *  should be given in the desired order of sorting.  */
  orderedAdditionalSortPairs?: { sortBy: string; sortAsc: boolean }[];
  /** True if it is a custom column added by a formula snippet */
  isCustomColumn?: boolean;
}

// This is a function because consumers of it add items to the sort pairs array which would modify the original
export const DEFAULT_CONDITION_TABLE_SORT: () => TableSortParams = () => ({
  sortBy: COLUMNS_AND_STATS.startTime.key,
  sortAsc: true,
  orderedAdditionalSortPairs: [],
});

/**
 * Describes a column in a table that is a property of the capsule. Ex: 'Start', 'Reference Capsule',
 * 'Duration', etc.
 */
export interface PropertyColumn extends BackwardsCompatibilityColumn {
  /** key for the property. Ex: 'startTime' is key for Start, used for sorting */
  key: string;
  /** Name for the property, used for referring to the column in Formula input*/
  propertyName: string;
  /** filter for the property column (optional) */
  filter?: TableColumnFilter;
  sort?: ColumnSortCriteria;
}

const isPropertyColumn = (column: PropertyColumn | StatColumn): column is PropertyColumn =>
  _.includes(column.key, 'properties');

export interface BackwardsCompatibilityColumn {
  /** Required for backwards compatibility in Capsule Pane, not needed for most uses. Used to force sort(), in
   *  Formula, to sort invalids first. */
  invalidsFirst?: boolean;
}

export interface ColumnSortCriteria {
  columnName: string;
  direction: 'asc' | 'desc';
  /** level represents the sort order */
  level: number;
}

/**
 * Describes a column in a table that is a statistic for a particular signal during a given capsule.
 */
export interface StatColumn extends BackwardsCompatibilityColumn {
  /** statistics key, Ex: statistics.range.{signalId} */
  key: string;
  /** Id for the signal that this statistic is being measured for */
  signalId: string;
  /** the stat Formula. Ex: range(), average(), endValue(true), etc*/
  stat: string;
  /** the column header string used in the backend to refer to a stat Operator. Required for sorting by stat columns. */
  columnSuffix: string;
  /** filter for the stat column (optional) */
  filter?: TableColumnFilter;
  sort?: ColumnSortCriteria;
}

export interface FetchParamsForColumn {
  fetchParams: any;
  columnKeyAndName: { columnKey: string; columnName: string };
}

/**
 * Represents a table that has been returned from the backend, constructed through Formula
 */
export interface FormulaTable {
  /** contains the resulting table and headers*/
  data: {
    /** an array of tableRows where each tableRow consists of key/pair relationships
     * between a column key and the value for that column in the given tableRow */
    table: { [key: string]: string }[];
    /** the in-order column headers */
    headers: TableColumnOutputV1[];
    /** true if the computed table has more results than there are tableRows */
    hasNextPage: boolean;
  };
  warningCount?: number;
  warningLogs?: [];
}

export const FORMULA_RETURN_TYPES = {
  SAMPLE_GROUP: 'Group:Sample',
  SAMPLE_SERIES: 'Signal',
  SAMPLE_AND_STATS: 'SignalAndStats',
  CAPSULE_SERIES: 'Condition',
  CAPSULE: 'Capsule',
  SCALAR: 'Scalar',
  PREDICTION_TABLE: 'RegressionModel',
  TABLE: 'Table',
};
export const FORMULA_RETURN_FIELDS = {
  SAMPLE_GROUP: 'samples',
  SAMPLE_SERIES: 'samples',
  SAMPLE_AND_STATS: ['samples', 'table', 'valueUnitOfMeasure', 'interpolationMethod'],
  CAPSULE_SERIES: 'capsules',
  CAPSULE: 'capsules',
  SCALAR: 'scalar',
  PREDICTION_TABLE: ['regressionOutput', 'table'],
  TABLE: 'table',
};
// The backend's spikecatcher algorithm that is used to downsample the values can return up to 6 data points for
// each pixel. An alternative to hardcoding 6 would be to paginate through the values if more than the limit were
// present, but the downside would be the extra code and requests.
export const SPIKECATCHER_PER_PIXEL = 6;
export const XY_TABLE_PER_PIXEL = 8;
