// @ts-strict-ignore
import { formatDuration } from '@/hybrid/datetime/dateTime.utilities';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { SelectAllIcon } from '@/hybrid/trend/panels/SelectAllIcon.atom';
import { TrendActions } from '@/trendData/trend.actions';
import { decorate } from '@/hybrid/trend/trendViewer/itemDecorator.utilities';
import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { AnnotationIcon } from '@/hybrid/annotation/AnnotationIcon.atom';
import { ReferenceCapsuleIcon } from '@/hybrid/trend/panels/capsulesPanel/ReferenceCapsuleIcon';
import { getColumnClass } from '@/hybrid/trend/panels/capsulesPanel/capsulePanel.service';
import { SingletonTooltip } from '@/hybrid/core/SingletonTooltip.atom';
import { Paginator } from '@/hybrid/core/Paginator.molecule';
import { CapsulesPanelHeader } from '@/hybrid/trend/panels/capsulesPanel/CapsulesPanelHeader.organism';
import { ItemPropertiesSelectorButton } from '@/hybrid/utilities/ItemPropertiesSelectorButton.molecule';
import { SuggestedPropertiesMode } from '@/hybrid/utilities/CustomPropertySelector.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { getColumnValueAndUOM } from '@/hybrid/utilities/columnHelper.utilities';
import { FormulaService } from '@/services/formula.service';
import { DetailsPanelColumn } from '@/hybrid/trend/panels/detailsPanel/DetailsPanelColumn.molecule';
import {
  sqInvestigateStore,
  sqScatterPlotStore,
  sqTrendCapsuleSetStore,
  sqTrendCapsuleStore,
  sqTrendSeriesStore,
  sqTrendStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { SCATTER_PLOT_VIEWS } from '@/scatterPlot/scatterPlot.constants';
import {
  CAPSULE_PANEL_LOOKUP_COLUMNS,
  CAPSULE_PANEL_TREND_COLUMNS,
  CapsuleTimeColorMode,
  CHART_CAPSULES_LIMIT,
  TREND_PANELS,
  TREND_SIGNAL_STATS,
} from '@/trendData/trendData.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { doTrack } from '@/track/track.service';
import { isPresentationWorkbookMode } from '@/hybrid/utilities/utilities';
import { isItemRedacted } from '@/hybrid/utilities/redaction.utilities';

const spacer = { key: 'spacer', type: 'spacer' };

const capsulePanelBindings = bindingsDefinition({
  sqTrendActions: injected<TrendActions>(),
  sqFormula: injected<FormulaService>(),
  resizePanelStart: prop<(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void>(),
  removeControls: prop.optional<boolean>(), // Optional, removes buttons for configuring the panel
  resizeEnabled: prop<boolean>(),
  singleSelect: prop.optional<boolean>(), // Optional, only allows a single capsule to be selected
});

export const CapsulePanel: SeeqComponent<typeof capsulePanelBindings> = ({
  resizePanelStart,
  removeControls,
  resizeEnabled,
  singleSelect,
}) => {
  const { sqTrendActions, sqFormula } = useInjectedBindings(capsulePanelBindings);

  const { t } = useTranslation();

  const {
    capsulePanelIsLoading,
    isTrendViewCapsuleTime,
    capsulePanelOffset,
    capsulePanelMaxOffset,
    capsulePanelHasNext,
  } = useFlux(sqTrendStore);
  const { selectedCapsules, capsulesPerPage, chartItems, items: undecoratedItems } = useFlux(sqTrendCapsuleStore);
  const { primarySeries, capsuleSeries } = useFlux(sqTrendSeriesStore);
  const { isCapsulePickingMode, activeToolName } = useFlux(sqInvestigateStore);
  const { plotView, colorCapsuleProperty, capsulePropertyColorsConfig } = useFlux(sqScatterPlotStore);
  const { capsulePanelDistinctStringValueMap } = useFlux(sqTrendStore);
  const { items: conditions } = useFlux(sqTrendCapsuleSetStore);
  const view = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.view);

  const capsulesSelectedSize = _.size(_.filter(chartItems, (item) => item.selected));
  const findPageCountSelected = () => {
    let chartItemsLength = 0;
    _.forEach(chartItems, (item) => {
      if (item.selected) {
        chartItemsLength += item.capsules.length;
      }
    });
    return chartItemsLength < CHART_CAPSULES_LIMIT ? _.ceil(chartItemsLength / capsulesPerPage) : undefined;
  };

  const pageCountWithSelectedCapsules = findPageCountSelected();
  const addOneMorePage = capsulePanelHasNext && capsulePanelOffset === capsulePanelMaxOffset;
  const pageCount =
    capsulesSelectedSize && pageCountWithSelectedCapsules
      ? pageCountWithSelectedCapsules
      : capsulePanelMaxOffset / capsulesPerPage + 1 + (addOneMorePage ? 1 : 0);
  const pageNumber = capsulePanelOffset / capsulesPerPage + 1;
  const isTrendViewCapsuleTimeVal = isTrendViewCapsuleTime();
  const toolName = activeToolName || 'CAPSULE_GROUP';
  const xAxisTimestamp = formatDuration(sqTrendStore.xValue, null);
  const anySelected = selectedCapsules.length > 0;
  const showCapsuleTimeChildIcon =
    isTrendViewCapsuleTimeVal && sqTrendStore.capsuleTimeColorMode !== CapsuleTimeColorMode.Signal;
  const showScatterPlotChildIcon =
    view.key === WORKSHEET_VIEW.SCATTER_PLOT && plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT && !!colorCapsuleProperty;

  const items = useMemo(
    (): any[] =>
      decorate({
        items: undecoratedItems,
        options: {
          singleSelect,
          pickingMode: isCapsulePickingMode,
        },
      }) ?? [],
    [undecoratedItems, singleSelect, isCapsulePickingMode, isTrendViewCapsuleTimeVal],
  );

  const selectableItems = useMemo(() => _.reject(items, 'notFullyVisible'), [items]);

  useEffect(() => {
    if (!items.length && !capsulePanelIsLoading && pageNumber !== 1) gotoPage(1);
  }, [items, capsulePanelIsLoading, pageNumber]);

  const propertyColumns = _.map(sqTrendStore.propertyColumns(TREND_PANELS.CAPSULES), (column) => ({
    ...column,
    title: column.propertyName,
    shortTitle: column.propertyName,
  }));

  const seriesColumns = _.chain(primarySeries)
    .thru((series) => decorate({ items: series }))
    .map((series) => ({
      key: series.id,
      title: series.name,
      type: 'series',
      series,
    }))
    .value();

  const customColumns = _.map(sqTrendStore.customColumns(TREND_PANELS.CAPSULES), (column) =>
    _.assign(
      { series: sqTrendSeriesStore.findItem(column.referenceSeries) },
      _.find(TREND_SIGNAL_STATS, ['key', column.statisticKey]),
      column,
    ),
  );

  const isColumnEnabled = (column) => sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, column.key);

  /**
   * All enabled columns, grouped by series so that the statistic for a given series
   * are displayed next to that series header
   */
  const enabledColumns = useMemo(
    () =>
      _.filter(CAPSULE_PANEL_TREND_COLUMNS, isColumnEnabled)
        .concat(_.filter(CAPSULE_PANEL_LOOKUP_COLUMNS, isColumnEnabled))
        .concat(_.filter(propertyColumns, isColumnEnabled))
        .concat(spacer)
        .concat(
          _.flatMap(seriesColumns, (column) =>
            _.filter(customColumns, ['referenceSeries', column.key]).concat([column]),
          ),
        ),
    [CAPSULE_PANEL_TREND_COLUMNS, CAPSULE_PANEL_LOOKUP_COLUMNS, propertyColumns, seriesColumns, customColumns],
  );

  /**
   * Checks if the column's series is redacted
   *
   * @param {Object} column - The column
   * @returns {boolean} - Whether or not the column's series is redacted
   */
  const isColumnRedacted = (column) => {
    const series = column.series;

    return _.isNil(series) ? false : isItemRedacted(series);
  };

  /**
   * Determines if a given statics columns is enabled for a given series
   *
   * @param {Object} statisticKey - One of TREND_SIGNAL_STATS column key
   * @param {Object} series - The series to be paired with the statistics column
   * @returns {Boolean} True if the statistic is enabled, false if not
   */
  const isStatisticsColumnEnabled = (statisticKey, series) =>
    sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, `${statisticKey}.${series?.id}`);

  const handleToggleColumn = (column) => {
    // remove if custom property column. The user may easily add it back using properties dropdown
    if (sqTrendActions.isPropertyColumn(column)) {
      return sqTrendActions.removePropertiesColumn(TREND_PANELS.CAPSULES, column);
    }

    const isEnabled = sqTrendActions.toggleColumn(TREND_PANELS.CAPSULES, column.key);
    // remove labels if property is removed/disabled in the capsules panel
    if (!isEnabled) {
      sqTrendActions.removeChartCapsulesProperty(column);
    }
  };

  const addPropertiesColumn = (column) => sqTrendActions.addPropertiesColumn(TREND_PANELS.CAPSULES, column);

  /**
   * Helper method that does not pass on any event information if in singleSelect mode so as to ignore multi-select
   * mode.
   *
   * @param {Object} item - The item to select
   * @param {Object} e - The click event
   */
  const selectItems = (item, e) => {
    if (item.notFullyVisible) {
      return;
    }

    const event = singleSelect ? {} : e;
    sqTrendActions.selectItems(item, items, event);
  };

  /**
   * Toggle selection of the item specified.
   *
   * @param {object} item - Item on which to toggle selection
   * @param e
   */
  const toggleItemSelected = (item, e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
    if (item.notFullyVisible) {
      return;
    }

    if (singleSelect) {
      selectItems(item, e);
    } else {
      sqTrendActions.toggleItemSelected(item);
    }

    doTrack(removeControls ? 'Topic' : 'Trend', removeControls ? 'Date Range Tool' : 'Capsule', 'selected');
  };

  /**
   * Checks if this capsule is used for coloring the scatter plot by capsule property
   *
   * @param item - the capsule to check for a capsule property
   * @returns true if there is a capsule property to color by and the capsule has that property, false otherwise
   */
  const showColorCapsulePropertyDot = (item) => {
    const propertyValue = sqScatterPlotStore.getColorPropertyValueFromCapsule(item);

    return (
      !!capsulePropertyColorsConfig &&
      !_.isNil(propertyValue) &&
      !_.isUndefined(sqScatterPlotStore.getCapsulePropertyColor(propertyValue, capsulePropertyColorsConfig))
    );
  };

  /**
   * Gets the color to use for the capsule property color dot in the capsules pane when coloring the
   * scatter plot by a capsule property
   *
   * @param item - the capsule to check for a capsule property
   */
  const getColorFromCapsuleProperty = (item) => {
    const colorsConfig = sqScatterPlotStore.capsulePropertyColorsConfig;
    const propertyValue = sqScatterPlotStore.getColorPropertyValueFromCapsule(item);

    return sqScatterPlotStore.getCapsulePropertyColor(propertyValue, colorsConfig);
  };

  /**
   * Determines if a series from capsule exists for the supplied series and capsule
   *
   * @param {Object} series - The series of interest
   * @param {Object} capsule - The capsule of interest
   * @returns {Boolean} True if it exists
   */
  const seriesFromCapsuleExists = (series, capsule) => {
    if (!series || !capsule) {
      return false;
    }

    return _.some(capsuleSeries, {
      interestId: series.id,
      capsuleId: capsule.id,
    });
  };

  /**
   * Returns the style attributes for a cell. If the 'eye' icon is enabled then the series style will be used.
   *
   * @param {Object} series - The series for a cell
   * @param {Object} capsule - The capsule for a cell
   * @returns {Object} Contains the color style
   */
  const getCellStyle = (series, capsule) => (seriesFromCapsuleExists(series, capsule) ? { color: series.color } : {});

  /**
   * Get the tooltip constant to display for a specified cell (column/item)
   *
   * @param {object} column - Column information
   * @param {string} [column.accessor] - When present, the key name to use for the value in object
   * @param {string} column.key - The key name to use for the value in object, when .accessor is not present
   * @param {object} item - Item for this to get a tooltip
   * @returns {string} translation key to use for the tooltip, or undefined if no tooltip
   */
  const getColumnTooltip = (column, item) => {
    const notFullyVisible = _.get(item, 'notFullyVisible');

    if (notFullyVisible) {
      return isTrendViewCapsuleTime()
        ? 'CAPSULES_PANEL.ZOOM_OUT_TO_VIEW_MORE_CAPSULE_VIEW'
        : 'CAPSULES_PANEL.ZOOM_OUT_TO_VIEW_MORE';
    }

    const value = _.get(item, column.accessor || column.key);
    if (_.isNil(value)) {
      return 'CAPSULES_PANEL.NO_VALUE';
    }
  };

  const gotoPage = (page: number) => {
    sqTrendActions.setCapsulePanelOffset((page - 1) * capsulesPerPage);
    doTrack('Workbench_Tool', 'CapsulesGoToPage', page.toString());
  };

  const findDistinctValuesForStringColumn = (columnKey) => {
    if (!sqTrendStore.isStringColumn(columnKey)) {
      return;
    }
    const statColumns = _.filter(enabledColumns, (column) =>
      isStatisticsColumnEnabled(column.statisticKey, column.series),
    );
    const statColumnsWithSignalId = _.map(statColumns, (column) => ({
      ...column,
      signalId: column.series.id,
    }));

    sqFormula
      .getCapsulesPanelStringColumnFetchParams(
        enabledColumns,
        conditions,
        statColumnsWithSignalId,
        propertyColumns,
        columnKey,
      )
      .then(({ fetchParams, columnKeyAndName }) =>
        sqFormula.fetchTableDistinctStringValues(
          fetchParams,
          columnKeyAndName,
          'TREND_SET_STRING_COLUMN_FILTER_VALUES',
          'CapsulePanelStringColumns',
        ),
      );
  };

  const setCapsulesPerPage = (capsulesPerPage: number) => {
    sqTrendActions.setCapsulesPerPage(capsulesPerPage);
    doTrack('Workbench_Tool', 'CapsulesPerPage', capsulesPerPage.toString());
  };

  const anyInTableSelected = (items) => _.some(items, 'selected');

  const renderHeader = () => {
    const isAnyInTableSelected = anyInTableSelected(items);

    return (
      !removeControls && (
        <div
          className={classNames('header flexColumnContainer flexCenter flexNoGrowNoShrink', {
            cursorResize: resizeEnabled,
          })}
          onMouseDown={(e) => resizeEnabled && resizePanelStart(e)}>
          <span className="mr10 flexFillOverflow">
            <Icon icon="fc fc-capsule" type="color" color="white" extraClassNames="ml5 mr5" />
            <span>{t('CAPSULES')}</span>
            {capsulePanelIsLoading && <Icon icon="fa fa-spinner" extraClassNames="fa-spin ml5" />}
          </span>
          {isTrendViewCapsuleTimeVal && <span className="text-nowrap xAxisTimestamp">{xAxisTimestamp}</span>}
          {!isPresentationWorkbookMode() && (
            <div className="sq-btn-group ml10 mtb2 height-20 flexColumnContainer">
              <TextButton
                testId="zoomToCapsulesBtn"
                extraClassNames="sq-btn-xs"
                tooltip={isAnyInTableSelected ? 'CAPSULES_PANEL.ZOOM_SELECTED' : 'CAPSULES_PANEL.ZOOM_ALL'}
                tooltipOptions={{ placement: 'left', delay: 500 }}
                label=""
                icon="fa-search-plus"
                iconStyle="text"
                onClick={() => {
                  sqTrendActions.zoomOutToCapsules();
                  doTrack(isAnyInTableSelected ? 'Selected Capsules' : 'All Capsules', 'Zoom To Capsules');
                }}
              />
              {!isCapsulePickingMode && (
                <TextButton
                  testId="unselectCapsulesBtn"
                  extraClassNames="sq-btn-xs"
                  tooltip={anySelected ? 'CAPSULES_PANEL.UNSELECT_ALL' : 'CAPSULES_PANEL.NONE_SELECTED'}
                  tooltipOptions={{ placement: 'left' }}
                  label=""
                  icon="fa-minus-square-o"
                  iconStyle={anySelected ? 'white' : 'text'}
                  variant={anySelected ? 'theme' : 'outline'}
                  onClick={sqTrendActions.unselectAllCapsules}
                />
              )}
            </div>
          )}
        </div>
      )
    );
  };

  const renderSpacer = (column) => {
    return (
      <th className="cursorPointer text-nowrap text-right pr15" key={column.key}>
        <div className="btn-stat-divider btn btn-stat btn-xs columnsBtn pt5 pb1">
          <ItemPropertiesSelectorButton
            extraColumns={CAPSULE_PANEL_TREND_COLUMNS}
            lookupColumns={CAPSULE_PANEL_LOOKUP_COLUMNS}
            propertyColumns={propertyColumns}
            itemIds={_.map(conditions, 'id')}
            suggestedPropertiesMode={SuggestedPropertiesMode.Capsules}
            isColumnEnabled={isColumnEnabled}
            toggleColumn={handleToggleColumn}
            addPropertyColumn={addPropertiesColumn}
            displaySectionHeaders={true}
            iconClasses="dropdown-toggle"
          />
        </div>
      </th>
    );
  };

  const renderTableHead = () => (
    <tr className="panelTableHeader">
      <th className="width-minimum">
        {!removeControls && !isCapsulePickingMode && (
          <SelectAllIcon
            items={selectableItems}
            selectAll={() => sqTrendActions.selectCapsules(selectableItems)}
            unselectAll={sqTrendActions.unselectAllCapsules}
          />
        )}
      </th>
      {!removeControls && <th className="width-minimum" />}
      {_.map(enabledColumns, (column) =>
        column.type === 'spacer' ? (
          renderSpacer(column)
        ) : (
          <CapsulesPanelHeader
            fetchStringValues={findDistinctValuesForStringColumn}
            key={column.key}
            column={column}
            handleToggleColumn={handleToggleColumn}
            isStatisticsColumnEnabled={isStatisticsColumnEnabled}
            isColumnRedacted={isColumnRedacted(column)}
            distinctStringValues={capsulePanelDistinctStringValueMap[column.key]}
          />
        ),
      )}
    </tr>
  );

  const renderTableBody = () => {
    if (!items.length) {
      return <></>;
    }

    return _.map(items, (item, rowIndex) => {
      const mainIconColor = item.color;

      return (
        <tr
          onClick={(e) => selectItems(item, e)}
          className={classNames(item.rowClasses, 'specCapsulesPanelRow')}
          id={`capsulePanelRow${rowIndex}`}
          key={item.id}>
          <td
            onClick={(e) => {
              toggleItemSelected(item, e);
              e.stopPropagation();
            }}>
            <Icon
              icon={item.selectIcon}
              extraClassNames="specRowCheckbox"
              type="inherit"
              tooltip={item.selectTooltip}
              tooltipPlacement="left"
              hasExternalTooltipHandler={true}
            />
          </td>
          {!removeControls && (
            <td className="text-nowrap">
              <Icon
                icon={classNames(item.iconClass, 'fa-fw')}
                tooltip={item.translateKey}
                tooltipPlacement="right"
                hasExternalTooltipHandler={true}
                extraClassNames="pl3 pr3 itemMainIcon"
                type={mainIconColor ? 'color' : undefined}
                color={mainIconColor}
                testId="capsule-icon"
              />
              {showCapsuleTimeChildIcon && item.childColor && (
                <Icon icon="fa-fw pl3 pr3 fc fc-series" type="color" color={item.childColor} />
              )}
              {showScatterPlotChildIcon && showColorCapsulePropertyDot(item) && (
                <Icon icon="fa-fw pl3 pr3 fa fa-circle" type="color" color={getColorFromCapsuleProperty(item)} />
              )}
            </td>
          )}
          {_.map(enabledColumns, (column, index) =>
            column.key === 'asset' ? (
              <DetailsPanelColumn
                rowIndex={rowIndex}
                key={`${column.key}_capsulePanel`}
                displayItems={[]}
                capsuleGroupingMode={false}
                allItems={[]}
                item={item}
                column={column}
              />
            ) : (
              <td
                key={column.key}
                data-tooltip-text={getColumnTooltip(column, item)}
                style={{ color: column.series?.color }}
                className={classNames('text-nowrap', `${_.camelCase(column.title)}Column`, getColumnClass(column))}>
                {column.type !== 'spacer' && (
                  <span style={getCellStyle(column.series, item)}>
                    {getColumnValueAndUOM(column, item)}
                    {column.key === 'endTime' && item.isUncertain && (
                      <Icon
                        icon="fa-info-circle"
                        testId="uncertainEndTimeInfoIcon"
                        type="darkish-gray"
                        tooltip="CAPSULES_PANEL.UNCERTAIN_INFO"
                        tooltipPlacement="top"
                        extraClassNames="ml5"
                      />
                    )}
                  </span>
                )}
                {index === 0 && item.isReferenceCapsule && <ReferenceCapsuleIcon item={item} extraClassNames="ml5" />}
                {index === 0 && <AnnotationIcon item={item} extraClassNames="ml5" />}
              </td>
            ),
          )}
        </tr>
      );
    });
  };

  return (
    <div className="flexRowContainer flexFill panelBorderLeft" id="capsulesPanel" data-testid="capsulesPanel">
      {renderHeader()}
      <div className="flexFill flexRowContainer msOverflowStyleAuto">
        <div className="tableWrapper">
          {/* An instructive message for when we are in capsule picking mode  */}
          {isCapsulePickingMode && (
            <div id="specCapsulesPaneAlert" className="text-center alert alert-info mb0 pl0 pr0 pb5 pt5">
              {t('CAPSULE_GROUP_PICKING.CLICK_TO_COPY', { toolName })}
            </div>
          )}

          {/* A containing div with display: table is needed so that the nav fills to the width of the table and not the
           width of the panel */}
          <div className="displayTable fixedHeaderTable">
            <SingletonTooltip id="CapsuleTableTooltip">
              <table className="table table-striped table-condensed mb0">
                <thead>{renderTableHead()}</thead>
                <tbody>{renderTableBody()}</tbody>
              </table>
            </SingletonTooltip>
          </div>
        </div>
        {!items.length && !capsulePanelIsLoading && (
          <div className="flexNoGrowNoShrink borderGray sq-bg-light-gray mt0 ml0 mr0 mb0 pt4 pb3 pl3 pr3 text-center">
            {t('NO_RESULTS_DISPLAY_RANGE')}
          </div>
        )}
        {capsulePanelIsLoading && (
          <div className="flexNoGrowNoShrink borderGray sq-bg-light-gray mt0 ml0 mr0 mb0 pt4 pb3 pl3 pr3 text-center">
            {t('LOADING')}
          </div>
        )}
        {!capsulePanelIsLoading && items.length > 0 && (
          <Paginator
            gotoPage={gotoPage}
            pageCount={pageCount}
            pageNumber={pageNumber}
            pageSize={capsulesPerPage}
            setPageSize={setCapsulesPerPage}
            pageSizeOptionsCustom={[10, 30, 50, 100, 200, 300]}
            insideModal={true}
            small={true}
          />
        )}
      </div>
    </div>
  );
};
