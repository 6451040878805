// @ts-strict-ignore
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { APP_STATE, DISPLAY_MODE, MIME_TYPES } from '@/main/app.constants';
import _ from 'lodash';
import FileSaver from 'file-saver';
import React, { useState } from 'react';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { toPowerpoint } from '@/hybrid/utilities/export.utilities';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { sqWorkbookStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';

const exportPowerpointBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
  sqInvestigateActions: injected<InvestigateActions>(),
});

type LinkTypeValue = 'none' | 'viewOnly' | 'full' | 'presentation';

type LinkType = {
  text: string;
  value: LinkTypeValue;
  hide?: boolean;
};

type Worksheets = 'ALL' | 'CURRENT';

const LINK_TYPES: LinkType[] = [
  {
    text: 'EXPORT_POWERPOINT.NONE',
    value: 'none',
  },
  {
    text: 'EXPORT_POWERPOINT.VIEW_ONLY',
    value: 'viewOnly',
  },
  {
    text: 'EXPORT_POWERPOINT.FULL',
    value: 'full',
  },
];

export const ExportPowerpoint: SeeqComponent<typeof exportPowerpointBindings> = () => {
  const { $state, sqInvestigateActions } = useInjectedBindings(exportPowerpointBindings);

  const { workbookId, worksheetId } = $state.params;
  const name = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.name);

  const [worksheetsOption, setWorksheetsOption] = useState<Worksheets>('CURRENT');
  const [linkType, setLinkType] = useState(LINK_TYPES[2]);

  const { t } = useTranslation();

  /**
   * Generate a Workbench URL to a specified Worksheet, with either full or view-only access
   *
   * @param {String} workbookId - ID of the Workbook
   * @param {String} worksheetId - ID of the Worksheet
   * @param {LinkType} linkType - One of LINK_TYPES
   * @param {Boolean} absolute - true if an absolute URL should be returned, false otherwise
   * @returns {String} Returns a relative URL, or undefined if linkType is 'none'
   */
  const makeWorksheetURL = (workbookId: string, worksheetId: string, linkType: LinkType, absolute: boolean) => {
    let state = APP_STATE.WORKSHEET;

    if (linkType.value === 'none') {
      return;
    }

    if (linkType.value === 'viewOnly') {
      state = APP_STATE.VIEW_WORKSHEET;
    } else if (linkType.value === 'presentation') {
      state = APP_STATE.PRESENT_WORKSHEET;
    }

    return $state.href(
      state,
      {
        workbookId,
        worksheetId,
      },
      {
        absolute,
      },
    );
  };

  /**
   * Generates the array of worksheet objects to send to the backend based on the selected options
   */
  const generateWorksheetsArgument = () => {
    const presentationLinkType: LinkType = {
      text: 'EXPORT_POWERPOINT.PRESENTATION',
      value: 'presentation',
    };
    if (worksheetsOption === 'CURRENT') {
      return [
        {
          worksheetId,
          screenshotURL: makeWorksheetURL(workbookId, worksheetId, presentationLinkType, true),
          linkURL: makeWorksheetURL(workbookId, worksheetId, linkType, true),
        },
      ];
    } else {
      return _.map(sqWorkbookStore.worksheets, ({ worksheetId }) => ({
        worksheetId,
        screenshotURL: makeWorksheetURL(workbookId, worksheetId, presentationLinkType, true),
        linkURL: makeWorksheetURL(workbookId, worksheetId, linkType, true),
      }));
    }
  };

  /**
   * Starts the export using the parameters from the model.
   */
  const execute = () => {
    doTrack('Export', 'Powerpoint', 'start');

    return toPowerpoint(name, generateWorksheetsArgument())
      .then((response: any) => new Blob([response.data], { type: MIME_TYPES.POWERPOINT }))
      .then((blob) => FileSaver.saveAs(blob, 'SeeqExport.pptx'))
      .catch((error) => errorToast({ httpResponseOrError: error }))
      .finally(() => sqInvestigateActions.setDisplayMode(DISPLAY_MODE.NEW));
  };

  const formDataSetup: FormElement[] = [
    {
      component: 'DisplayOnlyFormElementWrapper',
      name: 'title',
      children: (
        <h4 className="card-title flexColumnContainer pt10 pl15" data-testid="header">
          <Icon testId="titleIcon" icon="fc-powerpoint" extraClassNames="pr10" large={true} />
          <span>{t('EXPORT_POWERPOINT.HEADER')}</span>
        </h4>
      ),
    },
    {
      component: 'RadioButtonGroupFormComponent',
      testId: 'predictionOptions',
      name: 'predictionOptions',
      id: 'predictionOptions',
      value: worksheetsOption,
      verticalLayout: true,
      label: 'EXPORT_POWERPOINT.SELECT_WORKSHEETS',
      onChange: _.noop,
      options: [
        {
          id: 'allWorksheets',
          label: 'EXPORT_POWERPOINT.ALL_WORKSHEETS',
          checked: worksheetsOption === 'ALL',
          onToggle: () => setWorksheetsOption('ALL'),
        },
        {
          id: 'currentWorksheet',
          label: 'EXPORT_POWERPOINT.THIS_WORKSHEET_ONLY',
          checked: worksheetsOption === 'CURRENT',
          onToggle: () => setWorksheetsOption('CURRENT'),
        },
      ],
      displayNumber: true,
    },
    {
      component: 'ButtonGroupFormComponent',
      testId: 'linkType',
      name: 'linkType',
      label: 'EXPORT_POWERPOINT.SELECT_LINK_TYPE',
      tooltip: 'EXPORT_POWERPOINT.LINK_TYPE_TOOLTIP',
      tooltipPlacement: 'top',
      value: linkType.value,
      onChange: (value) => setLinkType(_.find(LINK_TYPES, { value })),
      buttonItems: _.map(LINK_TYPES, ({ text, value }) => ({
        label: text,
        id: value,
        value,
      })),
      displayNumber: true,
    },
  ];

  return (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={execute}
      closeFn={sqInvestigateActions.close}
      toolId={TREND_TOOLS.EXPORT_POWERPOINT}
      submitBtnId="exportPowerpointButton"
    />
  );
};

export const sqExportPowerpoint = angularComponent(exportPowerpointBindings, ExportPowerpoint);
