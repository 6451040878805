import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { ContainerWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { sqLicenseManagementStore, sqWorkbenchStore } from '@/core/core.stores';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.constants.ts';
import { adminContactEmail, adminContactName } from '@/services/systemConfiguration.utilities';

/**
 * Handles sending license renewal request to support@seeq.com
 */
export const EmailLicense: React.FunctionComponent<{}> = () => {
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);
  const { isTrial, licenseStatus, license = {} } = useFlux(sqLicenseManagementStore);

  const { hostname: host, level: licenseLevel, validity: licenseValidity } = license;

  const { t } = useTranslation();

  /**
   * Finds the appropriate translation key for the current license status.
   *
   * @returns {String} the translation key to use for the translation.
   */
  const licenseStatusInfoText = () => {
    if (isTrial) {
      return 'LICENSE.PURCHASE_TRIAL';
    }

    switch (licenseStatus) {
      case LICENSE_STATUS.UNKNOWN:
        return 'LICENSE.UNKNOWN_LICENSE_ADMIN';

      case LICENSE_STATUS.NONE:
        return 'LICENSE.NO_LICENSE_INFO_TEXT_ADMIN';

      default:
        return 'LICENSE.EXPIRING_LICENSE_INFO_TEXT_PART2';
    }
  };

  /**
   * Builds the email template to be used for the email message based on the current license status.
   *
   * @returns {String} the email template.
   */
  const getEmailTemplate = () => {
    const br = '<br>';
    let template = t('LICENSE.ADMIN_EMAIL_TEMPLATE.HELLO');
    template = template.concat(br);

    if (licenseStatus === LICENSE_STATUS.UNKNOWN) {
      template = template.concat(
        t('LICENSE.ADMIN_EMAIL_TEMPLATE.TALK_ISSUE', {
          LEVEL: licenseLevel,
          LICENSE_HOST: host,
        }),
        ' ',
        t('LICENSE.ADMIN_EMAIL_TEMPLATE.ISSUE_DETAILS', {
          LICENSE_VALIDITY: licenseValidity,
        }),
      );
    } else if (
      licenseStatus === LICENSE_STATUS.SHOW_ADMIN_LICENSE_WARNING ||
      licenseStatus === LICENSE_STATUS.SHOW_LICENSE_WARNING ||
      licenseStatus === LICENSE_STATUS.EXPIRED
    ) {
      if (isTrial) {
        template = template.concat(
          t('LICENSE.ADMIN_EMAIL_TEMPLATE.TALK_PURCHASE', {
            LICENSE_HOST: host,
          }),
        );
      } else {
        template = template.concat(
          t('LICENSE.ADMIN_EMAIL_TEMPLATE.TALK_RENEWAL', {
            LICENSE_HOST: host,
          }),
        );
      }
    } else if (licenseStatus === LICENSE_STATUS.VALID) {
      if (isTrial) {
        template = template.concat(
          t('LICENSE.ADMIN_EMAIL_TEMPLATE.TALK_PURCHASE', {
            LICENSE_HOST: host,
          }),
        );
      } else {
        template = template.concat(
          t('LICENSE.ADMIN_EMAIL_TEMPLATE.TALK_NEW', {
            LICENSE_HOST: host,
          }),
        );
      }
    } else {
      template = template.concat(
        t('LICENSE.ADMIN_EMAIL_TEMPLATE.TALK_REQUEST', {
          LICENSE_HOST: host,
        }),
      );
    }

    template = template.concat(
      br,
      t('LICENSE.ADMIN_EMAIL_TEMPLATE.THANKS'),
      br,
      adminContactName(),
      br,
      adminContactEmail(),
    );

    return template;
  };

  /**
   * Opens the Email Editor with the anticipated email text.
   */
  const emailLicenseRequest = () => {
    const emailBody = getEmailTemplate();
    window.open(
      `mailto:support@seeq.com?subject=License Request&body=${emailBody.replace(/<br>/g, '%0D%0A')}`,
      '_self',
    );
  };

  if (currentUser.isAdmin) {
    return (
      <div className="flexRowContainer section">
        <div className="text-center section mb20" data-testid="licenseEmailSupport">
          <span>{t(licenseStatusInfoText())}</span> &nbsp;
          <a href="#" onClick={emailLicenseRequest}>
            support@seeq.com
          </a>
        </div>

        <div className="flexColumnContainer flexCenter">
          <ContainerWithHTML
            testId="licenseEmailTemplate"
            content={getEmailTemplate()}
            extraClassNames="sqGrayBox p20 mb20 license-email-content"
          />

          <div className="text-center license-email-button pl20" data-testid="licenseEmailButton">
            <TextButton variant="theme" onClick={emailLicenseRequest} label="LICENSE.EMAIL" />
            <div className="mt8 mr6">{t('LICENSE.EMAIL_MANUAL')}</div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};
