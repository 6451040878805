// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { IdentityPreviewV1, sqUserGroupsApi } from '@/sdk';
import { EVERYONE_USERGROUP } from '@/hybrid/administration/Administration.page';
import {
  addGroup as addAdminGroup,
  includePermissions as includeAdminPermissions,
  updateGroup as updateAdminGroup,
} from '@/hybrid/administration/groupAdmin.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { Modal } from 'react-bootstrap';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { getDuplicateStringsInArray } from '@/hybrid/utilities/utilities';

interface EditAddGroupModalProps {
  group: {
    name: string;
    description: string;
    isEnabled: boolean;
    id: string;
    members: any;
    isRemoteGroupEditable: boolean;
    datasource: any;
    isUsedInAccessControlEntries: boolean;
    datasourceId: string;
  };
  isReadOnly: boolean;
  onClose: () => void;
  modalTitle?: JSX.Element;
}

export const EditAddGroupModal: React.FunctionComponent<EditAddGroupModalProps> = ({
  onClose,
  modalTitle,
  group,
  isReadOnly,
}) => {
  const { t } = useTranslation();

  const [groupMembers, setGroupMembers] = useState(group?.members ?? []);
  const [selectIdentityValue, setSelectIdentityValue] = useState([]);
  const [emptyList, setEmptyList] = useState(true);
  const [removeId, setRemoveId] = useState([]);
  const [permissionValue, setPermissionValue] = useState(false);
  const [enabledValue, setEnabledValue] = useState(true);
  const [accessControl, setAccessControl] = useState(true);
  const [groupName, setGroupName] = useState(group?.name || null);
  const [groupDescription, setGroupDescription] = useState(group?.description || null);

  const isGroupEveryone = group ? group.name === EVERYONE_USERGROUP : false;
  const isMemberUserType = SeeqNames.Types.User;
  const groupStatusOptions = [
    { value: true, text: 'ADMIN.ENABLED' },
    { value: false, text: 'ADMIN.DISABLED' },
  ];
  const includeMember = !isGroupEveryone && !isReadOnly;
  const getDuplicates = getDuplicateStringsInArray(_.map(groupMembers, (item) => item.name));
  const remoteGroupCheck = group && group.datasourceId !== SeeqNames.LocalDatasources.Authentication.DatasourceId;
  const includePermissions = includeAdminPermissions(isReadOnly, enabledValue, group, accessControl);

  useEffect(() => {
    if (group) {
      sqUserGroupsApi.getUserGroup({ userGroupId: group.id }).then(({ data }) => {
        setGroupMembers(
          _.chain(data.members)
            .map((member) =>
              _.assign(
                member,
                { datasource: { datasourceId: data.datasourceId } },
                { isRemoteGroupEditable: data.isRemoteGroupEditable },
              ),
            )
            .reject(
              (member) =>
                member.isArchived ||
                (remoteGroupCheck && !group.isRemoteGroupEditable && member.type === isMemberUserType),
            )
            .value(),
        );
        setEmptyList(false);
        setEnabledValue(group.isEnabled);
        setAccessControl(data.isUsedInAccessControlEntries);
      });
    }
  }, []);

  useEffect(() => {
    const members = _.chain(groupMembers)
      .map((member) => (member?.id !== group?.id ? member : null))
      .compact()
      .value();

    if (groupMembers.length && groupMembers.length !== members.length) {
      setGroupMembers(members);
    }
  }, [groupMembers]);

  const addGroup = (values) => addAdminGroup(values, groupMembers, onClose);

  const updateGroup = (values) =>
    updateAdminGroup({
      values,
      groupMembers,
      group,
      removeId,
      onClose,
      enabledValue,
      permissionValue,
    });

  const memberTable = (
    <div>
      {_.map(
        _.orderBy(groupMembers, (member) => _.toLower(member?.name), 'asc'),
        (member) => {
          return (
            <div
              key={member.id}
              data-testid="groupMembers"
              className="flexColumnContainer fakeTableRow
          fakeTableStriped flexNoGrowNoShrink">
              <div className="min-width-450 ml10">
                <span data-testid="memberName">
                  {member?.name.length > 65 ? _.truncate(member?.name, { length: 65, omission: '...' }) : member?.name}{' '}
                  {_.includes(getDuplicates, member?.name) && `(${member.datasource?.datasourceId})`}
                </span>
              </div>
              {!isReadOnly && (
                <a
                  href="#"
                  data-testid="removeGroupMember"
                  onClick={() => {
                    setRemoveId((idList) => [...idList, member.id]);
                    setGroupMembers(_.reject(groupMembers, member));
                  }}>
                  {t('ADMIN.GROUP.REMOVE')}
                </a>
              )}
            </div>
          );
        },
      )}
    </div>
  );

  const editAddFormDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'groupModal',
      extraClassNames: 'overflowVisible',
      components: [
        {
          component: 'FormControlFormComponent',
          name: 'name',
          label: 'ADMIN.GROUP.NAME',
          value: groupName,
          onChange: (name) => setGroupName(name.toString()),
          placeholder: 'ADMIN.GROUP.NAME',
          size: 'md',
          testId: 'groupName',
          validation: () => !groupName,
          disabled: isReadOnly || isGroupEveryone,
        },
        {
          component: 'FormControlFormComponent',
          name: 'description',
          label: 'ADMIN.GROUP.DESCRIPTION',
          value: groupDescription,
          onChange: (description) => setGroupDescription(description.toString()),
          placeholder: 'ADMIN.GROUP.DESCRIPTION',
          size: 'md',
          testId: 'groupDescription',
          required: false,
          disabled: isReadOnly || isGroupEveryone,
        },
        {
          component: 'IconSelectFormComponent',
          validation: _.isUndefined,
          name: 'isEnabled',
          label: 'ADMIN.GROUP.ENABLED_HEADER',
          value: group ? enabledValue : true,
          onChange: (value) => setEnabledValue(value.value),
          selectOptions: groupStatusOptions,
          insideModal: true,
          skipStore: true,
          testId: 'isEnabledGroup',
          disabled: isReadOnly,
          includeIf: group !== undefined,
        },
        {
          component: 'CheckboxFormComponent',
          id: 'removePermissionsCheckbox',
          name: 'removePermissionsCheckbox',
          label: 'ADMIN.GROUP.PERMISSIONS',
          value: permissionValue,
          onChange: () => setPermissionValue(!permissionValue),
          skipStore: true,
          checkboxLabel: 'ADMIN.GROUP.REMOVE_PERMISSIONS',
          includeIf: includePermissions,
          required: false,
          wrapperClassNames: 'mb-0',
        },
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'addGroup',
          includeIf: includePermissions,
          extraClassNames: 'mb8',
          children: (
            <div>
              {permissionValue ? (
                <div className="indented">
                  <Icon icon="fa-exclamation-triangle" type="danger" />
                  <span> {t('ADMIN.GROUP.REMOVE_PERMISSIONS_WARNING')} </span>
                </div>
              ) : (
                <div className="indented">
                  <Icon icon="fa-info-circle" type="info" />
                  <span> {t('ADMIN.GROUP.KEEP_PERMISSIONS_INFO')} </span>
                </div>
              )}
            </div>
          ),
        },
        {
          component: 'LabelFormComponent',
          name: 'membersLabel',
          value: 'ADMIN.GROUP.MEMBERS',
          extraClassNames: 'text-bolder',
        },
        {
          component: 'FormRow',
          name: 'memberAdd',
          testId: 'memberAdd',
          extraClassNames: 'flexColumnContainer overflowVisible',
          components: [
            {
              component: 'SelectIdentityFormComponent',
              name: 'selectIdentity',
              onChange: (identityToAdd) => {
                _.map(identityToAdd, (index) => setSelectIdentityValue([...selectIdentityValue, index]));
              },
              value: selectIdentityValue as Partial<IdentityPreviewV1>,
              allowGroups: true,
              idForLabel: 'identityName',
              includeAllProperties: true,
              startEditable: true,
              placeholder: 'ADMIN.GROUP.MEMBER_PLACEHOLDER',
              extraClassNames:
                selectIdentityValue.length > 2 ? 'multiSelectTwoLineModal w-100' : 'multiSelectTwoLine w-100',
              multiple: true,
              autoFill: false,
              includeIf: includeMember,
              skipStore: true,
              clearIdentityWhenEmpty: true,
              validation: () => {
                return false;
              },
              customErrorText: 'ADMIN.GROUP.MODAL.IDENTITY_ERROR',
            },
            {
              component: 'DisplayOnlyFormElementWrapper',
              name: 'addGroup',
              children: (
                <div className="flexColumnContainer ">
                  {!isGroupEveryone && !isReadOnly && (
                    <TextButton
                      testId="addMemberButton"
                      variant="theme"
                      disabled={selectIdentityValue.length < 1}
                      onClick={() => {
                        setGroupMembers(_.chain(selectIdentityValue).concat(groupMembers).uniqBy('id').value());
                        setEmptyList(false);
                        setSelectIdentityValue([]);
                      }}
                      iconStyle="white"
                      extraClassNames="ml15 float-right height-34"
                      label="ADMIN.GROUP.ADD"
                    />
                  )}
                </div>
              ),
            },
          ],
        },
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'members',
          children: (
            <div>
              {!isGroupEveryone && (
                <div>
                  <div className="mb5 mt5">
                    {group && remoteGroupCheck && !group.isRemoteGroupEditable && (
                      <span>{t('ADMIN.GROUP.MODAL.REMOTE_GROUP_WARNING')}</span>
                    )}
                  </div>
                  {!emptyList && groupMembers.length !== 0 ? (
                    <div
                      data-testid="memberTable"
                      className="flexRowContainer fakeTableWrapper table-striped
                 mt10 overflowYScroll max-height-100 border rounded">
                      {memberTable}
                    </div>
                  ) : (
                    <div data-testid="emptyList" className="mt10 text-center font-italic">
                      {t('ADMIN.GROUP.MEMBERS_ADD')}
                    </div>
                  )}
                </div>
              )}
              {isGroupEveryone && (
                <div data-testid="everyoneGroup" className="text-center font-italic">
                  {t('ADMIN.GROUP.MODAL.EVERYONE_GROUP_WARNING')}
                </div>
              )}
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="editAddGroupModal">
      <Modal.Header closeButton={true}>{modalTitle}</Modal.Header>
      <Modal.Body>
        <div data-testid="editAddGroupModalBody">
          <SimpleSaveFormBuilder
            formDefinition={editAddFormDefinition}
            submitFn={!group ? addGroup : updateGroup}
            closeFn={onClose}
            hideSubmit={isReadOnly}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
