import angular from 'angular';
import { sqHomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';

const dependencies = ['Sq.AppConstants'];

/**
 * The Sq.HomeScreen module contains all functionality related to the new HomeScreen
 */
export default angular
  .module('Sq.HomeScreen', dependencies)
  .service('sqHomeScreenActions', sqHomeScreenActions)
  .service('sqHomeScreenUtilities', HomeScreenUtilitiesService);
