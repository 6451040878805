// @ts-strict-ignore
import _ from 'lodash';
import '@/../other_components/piwik/piwik';
import moment from 'moment-timezone';
import { sqDurationStore, sqLicenseManagementStore, sqWorkbenchStore } from '@/core/core.stores';
import { findItemIn } from '@/hybrid/trend/trendDataHelper.utilities';
import { BROWSER_LANG } from '@/core/coreConfiguration.module';
import MixpanelTrackingService from '@/track/mixpanel.service';
import PiwikTrackingService from '@/track/piwik.service';
import { IS_PROTRACTOR } from '@/main/app.constants';
import { logTrackEvent } from '@/hybrid/utilities/logger';
import { getUserTrackingData, headlessRenderMode, randomInt } from '@/hybrid/utilities/utilities';
import { isFolder, isProject } from '@/hybrid/homescreen/homescreen.utilities';
import { isRehydrating } from '@/services/stateSynchronizer.utilities';
import {
  adminContactEmail,
  isMixpanelEnabled,
  isTelemetryAnonymized,
  isTelemetryEnabled,
} from '@/services/systemConfiguration.utilities';
import { FORMULA_TOOL_TREND_STORES } from '@/hybrid/tools/formula/formulaTool.module';
import { TrackerService } from '@/track/tracker.service';
import { TrackInformation } from '@/track/track.types';

let trackers = [];
const DEV_CONTRACT = 'DEV';

/**
 * This function calls the function that sends tracking events to Piwik. If we don't know if tracking is enabled or
 * not we make a call to initialize, otherwise we call the executeDoTrack function directly.
 *
 * @param category    - displayed as the 'Category' in the Piwik Console
 * @param action      - displayed as the 'Action' in the Piwik Console
 * @param [information] - displayed as the 'Event' in the Piwik Console
 * @param [includeDateRange] - optional, indicator whether or not to include the display and investigate
 *   range in the information field.
 */
export function doTrack(
  category: string,
  action: string,
  information: TrackInformation = '',
  includeDateRange = false,
) {
  if (includeDateRange) {
    const datePattern = 'MM-DD-YYYY HH:mm:ss.SSS';

    information += `Investigation Range: ${sqDurationStore.investigateRange.start.format(
      datePattern,
    )}-${sqDurationStore.investigateRange.end.format(datePattern)}`;

    information += `Display Range: ${sqDurationStore.displayRange.start.format(
      datePattern,
    )}-${sqDurationStore.displayRange.end.format(datePattern)}`;
  }

  executeDoTrack(category, action, information);
}

export function getTrackers(): TrackerService[] {
  if (_.isEmpty(trackers)) {
    const { serverEmail, userName, userEmail } = getUserTrackingData({
      anonymizeTelemetry: isTelemetryAnonymized(),
      adminEmail: adminContactEmail(),
      username: sqWorkbenchStore.currentUser.username,
      userEmail: sqWorkbenchStore.currentUser.email,
    });

    const contractNumber = getContractNumber();
    const companyName = sqLicenseManagementStore.license.companyName;

    // we want to always send tracking events to Piwik (at least for the time beeing)
    trackers = [
      new PiwikTrackingService({
        companyName,
        serverEmail,
        userName,
        userEmail,
        contractNumber,
        processPiwikRequest,
      }),
    ];
    if (isMixpanelEnabled() && isTelemetryEnabled()) {
      trackers.push(
        new MixpanelTrackingService({
          companyName,
          serverEmail,
          userName,
          userEmail,
          contractNumber,
          language: sqWorkbenchStore.userLanguage,
          darkMode: false,
        }),
      );
    }
  }
  return trackers;
}

function getContractNumber(): string {
  return _.isEmpty(sqLicenseManagementStore.license.contractNumber)
    ? DEV_CONTRACT
    : sqLicenseManagementStore.license.contractNumber;
}

// for testing
export function resetTrackers() {
  trackers = [];
}

/**
 * This function sends tracking events to Piwik.
 *
 * @param category    - displayed as the 'Category' in the Piwik Console
 * @param action      - displayed as the 'Action' in the Piwik Console
 * @param information - displayed as the 'Event' in the Piwik Console
 */
export function executeDoTrack(category: string, action: string, information: TrackInformation) {
  // Since the event is logged both locally and directly to piwik a unique event id is stored so they can be de-duped
  const uniqueEventId = randomInt();
  _.forEach(getTrackers(), (tracker) => {
    if (!isRehydrating() && !IS_PROTRACTOR && !headlessRenderMode() && tracker.shouldTrack()) {
      tracker.trackEvent(category, action, information, uniqueEventId, sqWorkbenchStore.userLanguage);
    }
  });
}

/**
 * A Piwik callback that is called whenever a request is about to be made. Always logs the event to a local log
 * with the timestamp so that it can be imported later in case telemetry is disabled or something like an
 * ad-blocker is blocking the Piwik URL.
 *
 * @param {string} request - The request params for recording a Piwik event
 * @return {string} If remote telemetry is disabled then an empty string which will cause Piwik to not issue any
 * request. Otherwise the original request params.
 */
export function processPiwikRequest(request) {
  logTrackEvent(`?${request}&cdt=${getUnixTimestamp()}`);
  if (!isTelemetryEnabled()) {
    return '';
  }

  return request;
}

/**
 * Helper function for generating a UNIX timestamp that can be passed to Piwik, such as for overriding the
 * datetime of the request.
 *
 * @return {number} A UNIX timestamp
 */
function getUnixTimestamp() {
  return Math.round(Date.now() / 1000);
}

/**
 * Helper function that returns a friendly string for the currently selected item.
 * Used for tracking.
 *
 * @param {Object} item - Object representing either a Folder, and Analysis or a Topic
 * @param {String} item.type - Folder or Workbook
 *
 * @returns {String} item type.
 */
export function getCurrentItemType(item) {
  if (isFolder(item)) {
    return 'Folder';
  } else if (isProject(item)) {
    return 'Project';
  } else if (_.get(item, 'type') === 'Topic') {
    return 'Topic';
  } else if (_.get(item, 'type') === 'Analysis') {
    return 'Analysis';
  }
}

/**
 * Tracks a date/time parsing action.
 *
 * @param {Object} payload - Payload containing parameters
 * @param {moment} payload.originalDate - Moment object prior to the parsing attempt
 * @param {String} payload.enteredString - String input that was parsed
 * @param {boolean} payload.success - True if parsing resulted in a successful date; otherwise false
 * @param {moment} payload.newDate - Moment object that was parsed; may be undefined if parsing failed
 * @param {String} payload.parser - Name of the parser which succeeded; may be undefined if parsing failed
 * @param {String} payload.locale - Locale used for parsing
 * @param {String} payload.lang - language used for parsing
 * @param {String} payload.tz - Timezone used for parsing
 */
export function trackDateParse(payload) {
  let info, action;

  if (payload.success) {
    action = `Parse Date: ${payload.parser}`;
    info = [`"${payload.enteredString}"`, '->', `"${payload.newDate.toISOString()}"`];
  } else {
    action = 'Parse Date: Failed';
    info = [payload.enteredString];
  }

  info = info.concat([
    '[ originalDate:',
    `"${payload.originalDate.toISOString()}",`,
    'lang:',
    payload.lang,
    ', locale:',
    payload.locale,
    ', tz:',
    payload.tz,
    ']',
  ]);

  doTrack('Date', action, info.join(' '));
}

/**
 * Track a duration parsing attempt.
 *
 * @param {String} originalDuration - String displayed for the previous duration
 * @param {Moment} originalStart - The pre-existing Moment for the start time of the duration.
 * @param {Moment} originalEnd - The pre-existing Moment for the end time of the duration.
 * @param {String} enteredString - The string that was entered and parsed.
 * @param {Moment.Duration} newDuration - Duration that was parsed from the input string, or undefined if no duration
 *   was parsed.
 * @param {Moment} newStart - New start time that was parsed from the input string, or undefined if no start time was
 *   parsed.
 * @param {Moment} newEnd - New end time that was parsed from the input string, or undefined if no end time was
 *   parsed.
 * @param {String} parser - Name of the parsing function that was successful, or undefined if parsing was not
 *   successful.
 * @param {Object} timezone - Timezone used for parsing.
 */
export function durationParse(
  originalDuration,
  originalStart,
  originalEnd,
  enteredString,
  newDuration,
  newStart,
  newEnd,
  parser,
  timezone,
) {
  const durationSuccess = !_.isUndefined(newDuration) && newDuration.valueOf() !== 0;
  const startEndSuccess = !_.isUndefined(newStart) && newStart.isValid() && !_.isUndefined(newEnd) && newEnd.isValid();
  trackDurationParse({
    originalDuration,
    originalStart,
    originalEnd,
    enteredString,
    newDuration: durationSuccess ? newDuration : undefined,
    newStart: startEndSuccess ? newStart : undefined,
    newEnd: startEndSuccess ? newEnd : undefined,
    durationSuccess,
    startEndSuccess,
    parser: durationSuccess || startEndSuccess ? parser : undefined,
    tz: timezone.name,
    lang: BROWSER_LANG,
    locale: moment.locale(),
  });
}

/**
 * Tracks a duration parsing action.
 *
 * @param {Object} payload - Payload containing parameters
 * @param {String} payload.originalDuration - String displayed for the previous duration
 * @param {Moment} payload.originalStart - The pre-existing Moment for the start time of the duration.
 * @param {Moment} payload.originalEnd - The pre-existing Moment for the end time of the duration.
 * @param {String} payload.enteredString - The string that was entered and parsed.
 * @param {Moment.Duration} payload.newDuration - Duration that was parsed from the input string, or undefined if no
 *   duration was parsed.
 * @param {Moment} payload.newStart - New start time that was parsed from the input string, or undefined if no start
 *   time was parsed.
 * @param {Moment} payload.newEnd - New end time that was parsed from the input string, or undefined if no end time
 *   was parsed.
 * @param {Boolean} payload.durationSuccess - True if a duration was successfully parsed
 * @param {Boolean} payload.startEndSuccess - True if a start and end date were successfully parsed
 * @param {String} payload.parser - Name of the parsing function that was successful, or undefined if parsing was not
 *   successful.
 * @param {String} payload.locale - Locale used for parsing
 * @param {String} payload.lang - language used for parsing
 * @param {String} payload.tz - Timezone used for parsing
 */
export function trackDurationParse(payload) {
  let info, action;

  if (payload.durationSuccess) {
    action = `Parse Duration: ${payload.parser}`;
    info = [`"${payload.enteredString}"`, '->', `"${payload.newDuration.asMilliseconds()}ms"`];
  } else if (payload.startEndSuccess) {
    action = `Parse Duration: ${payload.parser}`;
    info = [
      `"${payload.enteredString}"`,
      '->',
      `("${payload.newStart.toISOString()}"`,
      `, "${payload.newEnd.toISOString()}")`,
    ];
  } else {
    action = 'Parse Duration: Failed';
    info = [payload.enteredString];
  }

  info = info.concat([
    '[ originalStart:',
    `"${payload.originalStart.toISOString()}",`,
    'originalEnd:',
    `"${payload.originalEnd.toISOString()}",`,
    'originalDuration:',
    `"${payload.originalDuration}",`,
    'lang:',
    payload.lang,
    ', locale:',
    payload.locale,
    ', tz:',
    payload.tz,
    ']',
  ]);

  doTrack('Date', action, info.join(' '));
}

/**
 * Gets a JSON blob of data to be used as the 'information' field for tracking formula results
 *
 * @param {Object}  payload - container for arguments
 * @param {String}  payload.id - id of the item (maybe undefined if the formula is new and fails)
 * @param {Boolean} payload.success - true if this was a successful search
 * @param {String}  payload.name - name of the formula
 * @param {String}  payload.formula - text of the formula
 * @param {Object[]} payload.parameters - arguments to the formula
 * @param {String} payload.parameters[].identifier - name of the parameter in the formula
 * @param {Object} payload.parameters[].item - information about the item
 * @param {String} payload.parameters[].item.assets - the list of assets
 * @param {String} payload.parameters[].item.id - item's id
 * @param {String} payload.parameters[].item.name - the name property of the item
 * @param {Boolean} payload.isNew - true if this is a new item (otherwise an update)
 * @param {String} [payload.errorMessage] - if success is false contains the rejection message
 * @return {String} JSON encoded string containing info for tracking
 */
export function trackPowerSearchCompletedInfo(payload) {
  const parameters = _.map(payload.parameters, function (parameter: any) {
    const item = parameter.item;
    const type = _.get(findItemIn(FORMULA_TOOL_TREND_STORES, item.id), 'itemType');
    return [
      '$',
      parameter.identifier,
      ' = ',
      _.isEmpty(item.assets) ? item.name : `${_.get(item.assets, '[0].formattedName')} > ${item.name}`,
      // If the item is on the chart we know its type otherwise we wont show this info
      type ? ` (type:${type})` : '',
    ].join('');
  });

  doTrack('Workbench_Tool', 'Formula', JSON.stringify(_.assign(_.omit(payload, ['success']), { parameters })));
}
