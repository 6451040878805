// @ts-strict-ignore
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DraggableData, Rnd, RndResizeCallback } from 'react-rnd';
import _ from 'lodash';
import classNames from 'classnames';
import Highcharts, { SeriesSplineOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import boost from 'highcharts/modules/boost.js';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { DurationActions } from '@/trendData/duration.actions';
import { TrendActions } from '@/trendData/trend.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { DEBOUNCE, THROTTLE } from '@/core/core.constants';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { getCustomTimeFormat, getTimezoneOffset } from '@/hybrid/datetime/dateTime.utilities';
import { useThrottle } from 'rooks';
import useResizableHighchart from '@/hybrid/core/hooks/useResizableHighchart.hook';
import { preventIframeMouseEventSwallow, restoreIframeMouseEventBehavior } from '@/hybrid/utilities/utilities';
import { sqDurationStore, sqTimebarStore, sqTrendStore, sqWorksheetStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';
import { DraggableEvent, DraggableEventHandler } from 'react-draggable';
import {
  BORDER_WIDTH,
  handleScrollResize,
  handleTimebarClickReposition,
  updateDisplayRangeAndRecalculatePositionForDrag,
  updateDisplayRangeAndRecalculatePositionForResize,
} from '@/hybrid/trend/timebar.utilities';

const timebarBindings = bindingsDefinition({
  rangeEditingEnabled: prop<boolean>(),
  // Resize and drag end callbacks are passed as props here to ensure we can test that the update functions we want to
  // fire are fired while actively resizing or dragging the timebar rather than on stop.
  onResizeStop: prop.optional<() => void>(),
  onDragStop: prop.optional<() => void>(),
  sqDurationActions: injected<DurationActions>(),
  sqTrendActions: injected<TrendActions>(),
});

boost(Highcharts);

export const Timebar: SeeqComponent<typeof timebarBindings> = ({
  rangeEditingEnabled,
  onResizeStop = _.noop,
  onDragStop = _.noop,
}) => {
  const { sqDurationActions, sqTrendActions } = useInjectedBindings(timebarBindings);

  const { timezone } = useFlux(sqWorksheetStore);
  const { regions } = useFlux(sqTimebarStore);
  const { displayRange, investigateRange } = useFlux(sqDurationStore);
  const { capsulePreview } = useFlux(sqTrendStore);
  const { t } = useTranslation();

  const timebarRef = useRef(null);
  const [startPosition, setStartPosition] = useState(0);
  const [endPosition, setEndPosition] = useState(400);
  const [selectorWidth, setSelectorWidth] = useState(endPosition - startPosition);
  const [ratePx, setRatePx] = useState(1);
  const [chart, setChart] = useState<Highcharts.Chart>(null);
  const chartElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const delay = setTimeout(() => {
      sqTrendActions.fetchAllTimebarCapsules();
    }, DEBOUNCE.SHORT);
    return () => clearTimeout(delay);
  }, []);

  const investigateRangeStartVal = investigateRange.start.valueOf();
  const investigateRangeEndVal = investigateRange.end.valueOf();
  const displayRangeStart = displayRange.start.valueOf();
  const displayRangeEnd = displayRange.end.valueOf();

  const recalculatePositionCallback = useCallback(
    (displayRangeStartVal: number, displayRangeEndVal: number) => {
      const totalWidth = timebarRef?.current?.clientWidth;
      const investigateWidth = investigateRangeEndVal - investigateRangeStartVal;
      const ratePx = totalWidth / investigateWidth;
      const selectorWidth =
        _.toNumber(((displayRangeEndVal - displayRangeStartVal) * ratePx).toFixed(2)) + BORDER_WIDTH;

      const start = displayRangeStartVal - investigateRangeStartVal;
      const leftSelector = _.toNumber((start * ratePx).toFixed(2)) - BORDER_WIDTH;
      setStartPosition(leftSelector);
      setSelectorWidth(selectorWidth);
      setEndPosition(leftSelector + selectorWidth);
      setRatePx(ratePx);
    },
    [investigateRangeEndVal, investigateRangeStartVal],
  );

  useEffect(() => {
    recalculatePositionCallback(displayRangeStart, displayRangeEnd);
  }, [displayRangeStart, displayRangeEnd, recalculatePositionCallback, timebarRef?.current?.clientWidth]);

  const toggleCapsulePreview = () => {
    doTrack('Trend', 'Capsule Preview', 'Capsule Preview');
    sqTrendActions.setCapsulePreview(!capsulePreview);
  };

  const handleResizeStart = () => preventIframeMouseEventSwallow();
  const handleResizeStop = () => {
    onResizeStop();
    restoreIframeMouseEventBehavior();
  };

  /**
   * Handles the resize of the timebar
   * @param event
   * @param direction - 'right' if it is the right side selector being moved and 'left' if it is the left side selector
   */
  const handleResize: RndResizeCallback = (event, direction) => {
    const timebarOffset = timebarRef.current?.getBoundingClientRect().x || 0;
    const deltaWidth = (event as MouseEvent).x - timebarOffset - BORDER_WIDTH;
    updateDisplayRangeAndRecalculatePositionForResize(
      deltaWidth,
      direction,
      ratePx,
      recalculatePositionCallback,
      sqDurationActions,
    );
  };

  const [throttledHandleResize] = useThrottle(handleResize, THROTTLE.DRAG_TIMEBAR);

  const handleDrag: DraggableEventHandler = (event: DraggableEvent, dragData: DraggableData) => {
    return updateDisplayRangeAndRecalculatePositionForDrag(
      dragData.x,
      selectorWidth,
      startPosition,
      ratePx,
      recalculatePositionCallback,
      sqDurationActions,
    );
  };
  const [throttledHandleDrag] = useThrottle(handleDrag, THROTTLE.DRAG_TIMEBAR);

  const handleZoomSelector = (event: React.WheelEvent) => {
    event.stopPropagation();
    handleScrollResize(event, ratePx, recalculatePositionCallback, sqDurationActions);
  };

  const [throttledHandleZoomSelector] = useThrottle(handleZoomSelector, THROTTLE.SHORT);

  const handleTimebarClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (!rangeEditingEnabled) {
      return;
    }
    handleTimebarClickReposition(event, ratePx, selectorWidth, recalculatePositionCallback, sqDurationActions);
  };

  const afterChartCreated = (highchartsChart: Highcharts.Chart) => setChart(highchartsChart);

  useResizableHighchart({
    chart,
    chartElementRef,
    setChart,
  });

  Highcharts.setOptions({
    time: {
      useUTC: true,
      getTimezoneOffset(timestamp) {
        return getTimezoneOffset(timestamp, timezone);
      },
    },
  });

  const getInitialChartConfig = (): Highcharts.Options => ({
    chart: {
      type: 'spline',
      backgroundColor: 'none',
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: getCustomTimeFormat(),
      title: {
        text: 'Date',
      },
      minTickInterval: 0,
      tickLength: 8,
    },
    yAxis: {
      visible: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        data: [
          [investigateRange.start.utc().valueOf(), 0],
          [investigateRange.end.utc().valueOf(), 0],
        ],
      } as SeriesSplineOptions,
    ],
    title: {
      text: undefined,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  });

  return (
    <div className="flexColumnContainer pt5" id="timebar">
      <div className="flexFillOverflow">
        <div className="timebarWrapper">
          <div className="outer" ref={timebarRef} data-testid="timebar">
            <div className="moveSelectorAria" onClick={handleTimebarClick} />
            {capsulePreview && (
              <div className="regions" data-testid="capsuleRegions">
                {regions.map((region) => {
                  const leftRegion = (region.start - investigateRange.start.valueOf()) * ratePx;
                  const rightRegion = (region.end - investigateRange.start.valueOf()) * ratePx;

                  return (
                    <div
                      className="region"
                      key={`${region.capsuleSetId}-${region.start}-${region.end}`}
                      style={{
                        left: leftRegion,
                        width: rightRegion - leftRegion,
                        backgroundColor: region.color,
                        opacity: region.opacity,
                      }}
                    />
                  );
                })}
              </div>
            )}
            {rangeEditingEnabled && (
              <div onWheel={throttledHandleZoomSelector}>
                <Rnd
                  data-testid="timebarContainer"
                  className="timebarContainer"
                  default={{
                    x: startPosition,
                    y: 0,
                    width: selectorWidth,
                    height: 14,
                  }}
                  position={{ x: startPosition, y: 0 }}
                  size={{ width: selectorWidth, height: 14 }}
                  dragAxis="x"
                  enableResizing={{
                    top: false,
                    right: true,
                    bottom: false,
                    left: true,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false,
                  }}
                  resizeHandleClasses={{
                    left: 'leftSelector',
                    right: 'rightSelector',
                  }}
                  bounds="parent"
                  onResizeStart={handleResizeStart}
                  onResizeStop={handleResizeStop}
                  onResize={throttledHandleResize}
                  onDragEnd={onDragStop}
                  onDrag={throttledHandleDrag}>
                  <div className="rangeIndicator" data-testid="timebarRangeSelector">
                    <span
                      className={classNames('indicator selector', {
                        capsulePreview,
                      })}
                    />
                  </div>
                </Rnd>
              </div>
            )}
          </div>
          <div className="ticksContainer" ref={chartElementRef}>
            <HighchartsReact
              highcharts={Highcharts}
              options={getInitialChartConfig()}
              callback={afterChartCreated}
              containerProps={{ className: 'flexFill', style: { height: 30 } }}
            />
          </div>
        </div>
      </div>
      <div className="flexNoGrowNoShrink pl5 pr5 mr1 ml1">
        <Icon
          id="capsulePreviewToggle"
          testId="capsulePreviewToggle"
          onClick={toggleCapsulePreview}
          icon="fc-capsule-set"
          tooltip={
            capsulePreview
              ? t('TOOLBAR.CAPSULE_PREVIEW_TOOLTIP_ENABLED')
              : t('TOOLBAR.CAPSULE_PREVIEW_TOOLTIP_DISABLED')
          }
          tooltipDelay={1500}
          tooltipPlacement="bottom-end"
          extraClassNames={classNames('fa-fw p2 itemMainIcon cursorPointer width-20 mtn2', {
            'sq-bg-theme-dark': capsulePreview,
          })}
          type={capsulePreview ? 'white' : 'theme'}
        />
      </div>
    </div>
  );
};

export const sqTimebar = angularComponent(timebarBindings, Timebar);
