// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { IconSelect, ReactSelectOption } from '@/hybrid/core/IconSelect.molecule';
import {
  KEEP_CURRENT_KEY,
  KEEP_CURRENT_SIZE,
  REPORT_CONTENT,
  ReportContentSize as ContentSize,
} from '@/reportEditor/report.constants';

const reportContentSizeBindings = bindingsDefinition({
  useSizeFromRender: prop<boolean>(),
  isReact: prop.optional<boolean>(),
  isSizeCustom: prop<boolean>(),
  scales: prop<any>(),
  shapes: prop<any>(),
  sizes: prop<any>(),
  scale: prop<any>(),
  shape: prop<any>(),
  size: prop<ContentSize>(),
  setScale: prop<(any) => void>(),
  setShape: prop<(any) => void>(),
  setSize: prop<(any) => void>(),
  width: prop<number>(),
  height: prop<number>(),
  setHeight: prop<(number) => void>(),
  setWidth: prop<(number) => void>(),
});

export const ReportContentSize: SeeqComponent<typeof reportContentSizeBindings> = (props) => {
  const {
    sizes,
    scales,
    shapes,
    size,
    scale,
    shape,
    useSizeFromRender,
    isSizeCustom,
    setScale,
    setShape,
    setSize,
    width,
    height,
    setWidth,
    setHeight,
    isReact = false,
  } = props;
  const { t } = useTranslation();

  const customOption: ReactSelectOption = {
    text: sizes.CUSTOM.name,
    value: sizes.CUSTOM.key,
  };

  const keepCurrentOption: ReactSelectOption = {
    text: KEEP_CURRENT_SIZE.name,
    value: KEEP_CURRENT_SIZE.key,
  };

  const sizeOptions = _.chain(sizes)
    .reject((size) => size.key === REPORT_CONTENT.SIZE.CUSTOM.key || size.key === KEEP_CURRENT_KEY)
    .map(
      (size): ReactSelectOption => ({
        text: size.name,
        value: size.key,
        divider: size.key === 'large',
      }),
    )
    .concat([customOption, sizes.KEEP && keepCurrentOption])
    .compact()
    .value();

  const shapeOptions = _.map(
    shapes,
    (shape): ReactSelectOption => ({
      text: shape.name,
      value: shape.key,
      icon: shape.key !== 'keep' ? `fc-${shape.key}` : undefined,
    }),
  );

  /* Scale is surfaced to the user as "Font Size" */
  const scaleOptions = _.map(
    scales,
    (scale): ReactSelectOption => ({
      text: scale.name,
      value: scale.key,
    }),
  );

  const [selectedSizeOption, setSelectedSizeOption] = useState<ReactSelectOption>(
    size ? _.find(sizeOptions, { value: size.key }) : _.first(sizeOptions),
  );

  const [selectedShapeOption, setSelectedShapeOption] = useState<ReactSelectOption>(
    shape ? _.find(shapeOptions, { value: shape.key }) : _.first(shapeOptions),
  );

  const [selectedScaleOption, setSelectedScaleOption] = useState<ReactSelectOption>(
    scale ? _.find(scaleOptions, { value: scale.key }) : _.first(scaleOptions),
  );

  const dateRangeTopMargin = !useSizeFromRender ? 'mt15' : 'mt8';

  function clampContentSize(value) {
    if (_.isEmpty(value)) {
      return '';
    } else {
      return _.clamp(_.toNumber(value), REPORT_CONTENT.CONTENT_MIN_SIZE, REPORT_CONTENT.CONTENT_MAX_SIZE);
    }
  }

  const handleSetContentSize = (userChoice: ReactSelectOption) => {
    const sizeMatch = _.find(sizes, { key: userChoice.value });
    if (sizeMatch.key === sizes.CUSTOM.key) {
      setSize(sizes.CUSTOM);
    } else if (sizeMatch.key === KEEP_CURRENT_SIZE.key) {
      setSize(KEEP_CURRENT_SIZE);
    } else {
      setSize(sizeMatch);
    }
    setSelectedSizeOption(userChoice);
  };

  const handleSetContentShape = (userChoice: ReactSelectOption) => {
    const shapeMatch = _.find(shapes, { key: userChoice.value });
    setShape(shapeMatch);
    setSelectedShapeOption(userChoice);
  };

  const handleSetContentScale = (userChoice: ReactSelectOption) => {
    const scaleMatch = _.find(scales, { key: userChoice.value });
    setScale(scaleMatch);
    setSelectedScaleOption(userChoice);
  };

  return (
    <div className="flexRowContainer p10">
      {!useSizeFromRender && <label htmlFor="sizeSelect">{t('REPORT.SIZE.LABEL')}</label>}
      {!useSizeFromRender && (
        <div>
          <IconSelect
            id="sizeSelect"
            testId="sizeSelect"
            insideModal={true}
            selectOptions={sizeOptions}
            value={selectedSizeOption}
            onChange={handleSetContentSize}
          />
        </div>
      )}

      {!isSizeCustom && !useSizeFromRender && (
        <label htmlFor="resolutionDropdown" className="mt15">
          {t('REPORT.SHAPE.LABEL')}
        </label>
      )}

      {!isSizeCustom && !useSizeFromRender && (
        <div id="resolutionSelect">
          <IconSelect
            id="resolutionSelect"
            testId="resolutionSelect"
            insideModal={true}
            selectOptions={shapeOptions}
            value={selectedShapeOption}
            onChange={handleSetContentShape}
          />
        </div>
      )}

      {isSizeCustom && !useSizeFromRender && (
        <div className="flexColumnContainer flexCenter mt15">
          <div className="flexRowContainer">
            <label htmlFor="content-width">{t('WIDTH')}</label>
            <input
              autoComplete="off"
              id="content-width"
              type="number"
              className="form-control width-75"
              value={width || ''}
              onChange={(e) => setWidth(clampContentSize(e.target.value))}
              min={REPORT_CONTENT.CONTENT_MIN_SIZE}
              max={REPORT_CONTENT.CONTENT_MAX_SIZE}
            />
          </div>
          <div className="ml10 mt20">x</div>
          <div className="flexRowContainer ml10">
            <label htmlFor="content-height">{t('HEIGHT')}</label>
            <input
              autoComplete="off"
              id="content-height"
              type="number"
              className="form-control width-75"
              value={height || ''}
              onChange={(e) => setHeight(clampContentSize(e.target.value))}
              min={REPORT_CONTENT.CONTENT_MIN_SIZE}
              max={REPORT_CONTENT.CONTENT_MAX_SIZE}
            />
          </div>
        </div>
      )}

      {/* Scale = font size */}
      {!isReact && (
        <>
          <label htmlFor="scaleSelect" className={dateRangeTopMargin}>
            {t('REPORT.SCALE.LABEL')}
          </label>
          <div id="scaleDropdown">
            <IconSelect
              id="scaleSelect"
              testId="scaleSelect"
              insideModal={true}
              selectOptions={scaleOptions}
              value={selectedScaleOption}
              onChange={handleSetContentScale}
            />
          </div>
        </>
      )}
    </div>
  );
};

export const sqReportContentSize = angularComponent(reportContentSizeBindings, ReportContentSize);
