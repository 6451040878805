// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { parseInformationString } from '@/hybrid/utilities/detailsPanel.utilities';
import { humanReadableByteCount } from '@/hybrid/utilities/numberHelper.utilities';

// DO NOT CHANGE THESE unless DataConsumptionReportService.kt::DatumSize is also changed. They need to be kept in sync.
const BYTES_PER_SAMPLE = 16;
const BYTES_PER_CONDITION = 64;

const progressBarBindings = bindingsDefinition({
  informationString: prop<string>(),
  displayTime: prop<boolean>(),
  labelKey: prop<string>(),
  tooltipLookup: prop<any>(),
  setLegend: prop<(legend) => void>(),
  setTooltipLookup: prop<(tooltipLookup) => void>(),
  getTooltipFn: prop<(item, lookup) => JSX.Element>(),
  testId: prop<string>(),
  existingColors: prop.optional<boolean>(),
  displayDataSum: prop.optional<boolean>(),
});

export const ProgressBar: SeeqComponent<typeof progressBarBindings> = ({
  informationString,
  existingColors = false,
  setLegend,
  displayTime,
  labelKey,
  tooltipLookup,
  setTooltipLookup,
  getTooltipFn,
  testId,
  displayDataSum = false,
}) => {
  const { t } = useTranslation();

  const [isReady, setIsReady] = useState(false);
  const [colorAssignment, setColorAssignment] = useState([]);
  const [progressDisplay, setProgressDisplay] = useState(undefined);
  const [displayTotal, setDisplayTotal] = useState(undefined);
  const [dataTotal, setDataTotal] = useState<string | undefined>(undefined);

  useEffect(() => {
    doUpdateProgressForDisplay();
  }, [informationString, displayTime, existingColors]);

  const doUpdateProgressForDisplay = () => {
    const { tooltipLookup, rolledUpByLabelSortedAndColored, newLegend, progressDisplay, displayTotal, total } =
      parseInformationString(informationString, displayTime, colorAssignment, existingColors);

    setIsReady(_.isFinite(total));
    setColorAssignment(
      _.map(rolledUpByLabelSortedAndColored, (c) => ({
        label: c.label,
        color: c.color,
      })),
    );
    setTooltipLookup(tooltipLookup);
    setLegend(newLegend);
    setDisplayTotal(displayTotal);
    if (displayDataSum) {
      const slimLookup = tooltipLookup.map((lookup) => ({
        source: lookup.source,
        value: _.toNumber(lookup.displayValue.replace(/,/g, '').replace(/\./g, '')),
      }));
      const sampleTotal =
        BYTES_PER_SAMPLE *
        slimLookup
          .filter((lookup) => lookup.source.includes('Samples'))
          .map((lookup) => lookup.value)
          .reduce((value, acc) => value + acc, 0);
      const capsuleTotal =
        BYTES_PER_CONDITION *
        slimLookup
          .filter((lookup) => lookup.source.includes('Capsules'))
          .map((lookup) => lookup.value)
          .reduce((value, acc) => value + acc, 0);
      setDataTotal(humanReadableByteCount(sampleTotal + capsuleTotal));
    }
    setProgressDisplay(progressDisplay);
  };

  const total = (
    <div className="flexRowContainer">
      <span>{displayTotal}</span>
      {displayDataSum ? <span>{dataTotal}</span> : null}
    </div>
  );

  return (
    <div className="flexColumnContainer height-30 pl15 pr15 pb3" data-testid={testId}>
      <div className="width-70">{t(labelKey)}</div>
      {isReady && (
        <div className="progress width-370">
          {_.map(progressDisplay, (progress, index) => (
            <HoverTooltip
              text={`${progress.label}-tooltip`}
              formattedText={getTooltipFn(progress, tooltipLookup)}
              key={`progress-${index}-tooltip`}>
              <div
                key={index}
                data-testid={`${testId}-${index}`}
                style={{
                  width: `${progress.percent}%`,
                  backgroundColor: progress.color,
                }}
                className="progress-bar"
                role="progressbar">
                {progress.displayValue}
              </div>
            </HoverTooltip>
          ))}
        </div>
      )}

      {!isReady && (
        <div className="width-370 flexRowContainer flexAlignCenter">
          <IconWithSpinner
            spinning={true}
            extraClassNames="fa-2x text-inactive"
            testId="progressBarSpinner"
            correctSpin={false}
          />
        </div>
      )}

      <div className="width-70 flexColumnContainer flexJustifyEnd">{isReady && total}</div>
    </div>
  );
};
