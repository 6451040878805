import { useState } from 'react';

export const useConditionFormGroup = () => {
  const [detectedPropertiesLoading, setDetectedPropertiesLoading] = useState<Record<string, boolean>>({});
  const [detectedProperties, setDetectedProperties] = useState<Record<string, any[]>>({});

  const updateDetectedPropertiesLoading = (value: boolean, id: string) =>
    setDetectedPropertiesLoading((detectedPropertiesLoading) => ({
      ...detectedPropertiesLoading,
      [id]: value,
    }));

  const updateDetectedProperties = (value: any[], id: string) =>
    setDetectedProperties((detectedProperties) => ({
      ...detectedProperties,
      [id]: value,
    }));

  return {
    detectedPropertiesLoading,
    detectedProperties,
    updateDetectedPropertiesLoading,
    updateDetectedProperties,
  };
};
