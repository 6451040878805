import React from 'react';
import { ProfileSearch } from '@/hybrid/tools/profileSearch/ProfileSearch.organism';
import { ValueSearch } from '@/hybrid/tools/ValueSearch.organism';
import { ManualSignal } from '@/hybrid/tools/manualSignal/ManualSignal.organism';
import { CompositeSearch } from '@/hybrid/tools/compositeSearch/CompositeSearch.organism';
import { FormulaTool } from '@/hybrid/tools/formula/FormulaTool.organism';
import { SignalFromCondition } from '@/hybrid/tools/signalFromCondition/SignalFromCondition.organism';
import { ImportDatafile } from '@/hybrid/tools/importDatafile/ImportDatafile.organism';
import { Prediction } from '@/hybrid/tools/prediction/Prediction.organism';
import { ReferencePanel } from '@/hybrid/tools/referencePanel/ReferencePanel.organism';
import { PeriodicCondition } from '@/hybrid/tools/periodicCondition/PeriodicCondition.organism';
import { ManualCondition } from '@/hybrid/tools/manualCondition/ManualCondition.organism';
import { ScatterPlotSelection } from '@/hybrid/tools/scatterPlotSelection/ScatterPlotSelection.organism';
import { SignalSmoothing } from '@/hybrid/tools/signalSmoothing/SignalSmoothing.organism';
import { ThresholdMetric } from '@/hybrid/tools/thresholdMetric/ThresholdMetric.organism';
import { Histogram } from '@/hybrid/tools/histogram/Histogram.organism';
import { FrequencyAnalysis } from '@/hybrid/tools/frequencyAnalysis/FrequencyAnalysis.organism';
import { ExportExcel } from '@/hybrid/tools/exportExcel/ExportExcel.organism';
import { ExportPowerpoint } from '@/hybrid/tools/exportPowerpoint/ExportPowerpoint.organism';
import { ExportOData } from '@/hybrid/tools/exportOData/ExportOData.organism';
import { ExportPIVision } from '@/hybrid/tools/exportPivision/ExportPIVision.organism';
import { DigitalFilter } from '@/hybrid/tools/digitalFilter/DigitalFilter.organism';
import { PropertiesPanel } from '@/hybrid/tools/itemProperties/PropertiesPanel.organism';
import { DisplayTool } from '@/hybrid/tools/display/DisplayTool.organism';
import { ConditionWithProperties } from '@/hybrid/tools/conditionWithProperties/ConditionWithProperties.organism';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { ToolPanePluginHost } from '@/hybrid/plugin/ToolPanePluginHost.molecule';
import { sqPluginStore } from '@/core/core.stores';

type ToolPanesProps = {
  activeTool: string;
  isViewMode: boolean;
};

/**
 * A list of all available tools
 */
export const ToolPanes: React.FunctionComponent<ToolPanesProps> = ({ activeTool, isViewMode }) => {
  const isPlugin = !!sqPluginStore.getPlugin(activeTool);

  return (
    <div id="investigateToolPane" data-testid="toolPanes" className="flexFillOverflow overflowAuto infoPanel">
      {activeTool === TREND_TOOLS.PROFILE_SEARCH && <ProfileSearch />}
      {activeTool === TREND_TOOLS.CONDITION_WITH_PROPERTIES && <ConditionWithProperties />}
      {activeTool === TREND_TOOLS.VALUE_SEARCH && <ValueSearch />}
      {activeTool === TREND_TOOLS.MANUAL_SIGNAL && <ManualSignal />}
      {activeTool === TREND_TOOLS.COMPOSITE_SEARCH && <CompositeSearch />}
      {activeTool === TREND_TOOLS.FORMULA && <FormulaTool />}
      {activeTool === TREND_TOOLS.SIGNAL_FROM_CONDITION && <SignalFromCondition />}
      {activeTool === TREND_TOOLS.IMPORTDATAFILE && <ImportDatafile />}
      {activeTool === TREND_TOOLS.PREDICTION && <Prediction />}
      {activeTool === TREND_TOOLS.REFERENCE && <ReferencePanel />}
      {(activeTool === TREND_TOOLS.PROPERTIES || isViewMode) && <PropertiesPanel />}
      {activeTool === TREND_TOOLS.PERIODIC_CONDITION && <PeriodicCondition />}
      {activeTool === TREND_TOOLS.MANUAL_CONDITION && <ManualCondition />}
      {activeTool === TREND_TOOLS.SCATTER_CONDITION && <ScatterPlotSelection />}
      {activeTool === TREND_TOOLS.SIGNAL_SMOOTHING && <SignalSmoothing />}
      {activeTool === TREND_TOOLS.THRESHOLD_METRIC && <ThresholdMetric />}
      {activeTool === TREND_TOOLS.AGGREGATION_BINS_TABLE && <Histogram />}
      {activeTool === TREND_TOOLS.FFT_TABLE && <FrequencyAnalysis />}
      {activeTool === TREND_TOOLS.EXPORT_EXCEL && <ExportExcel />}
      {activeTool === TREND_TOOLS.EXPORT_POWERPOINT && <ExportPowerpoint />}
      {activeTool === TREND_TOOLS.EXPORT_ODATA && <ExportOData />}
      {activeTool === TREND_TOOLS.EXPORT_PI_VISION && <ExportPIVision />}
      {activeTool === TREND_TOOLS.DIGITAL_FILTER && <DigitalFilter />}
      {activeTool === TREND_TOOLS.DISPLAY && <DisplayTool />}
      {isPlugin && <ToolPanePluginHost pluginIdentifier={activeTool} />}
    </div>
  );
};
