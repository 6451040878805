// @ts-strict-ignore
import { flux } from '@/core/flux.module';

/**
 * Sets whether autoupdate is enabled for the time range
 *
 * @param {boolean} autoupdateTimeRange - True to enable autoupdate
 */
export function setAutoupdateTimeRange(autoupdateTimeRange) {
  flux.dispatch('EXPORT_ODATA_AUTOUPDATE_TIME_RANGE', { autoupdateTimeRange });
}

/**
 * Set time range start that is used in the export form.
 *
 * @param {moment} start - the start of the time range
 */
export function setTimeRangeStart(start) {
  flux.dispatch('EXPORT_ODATA_TIME_RANGE_START', { start });
}

/**
 * Set time range end that is used in the export form.
 *
 * @param {moment} end - the end of the time range
 */
export function setTimeRangeEnd(end) {
  flux.dispatch('EXPORT_ODATA_TIME_RANGE_END', { end });
}

/**
 * Sets the duration of the time range.
 *
 * @param {moment.duration} duration - The duration of the time range
 */
export function setDuration(duration) {
  flux.dispatch('EXPORT_ODATA_DURATION', { duration });
}

export function setExportTimeZone(exportTimeZone) {
  flux.dispatch('EXPORT_ODATA_EXPORT_TIME_ZONE', { exportTimeZone });
}

export function setGridOption(gridOption) {
  flux.dispatch('EXPORT_ODATA_GRID_OPTION', { gridOption });
}

export function setGridSize(gridSize) {
  flux.dispatch('EXPORT_ODATA_GRID_SIZE', { gridSize });
}

export function setGridOrigin(gridOrigin) {
  flux.dispatch('EXPORT_ODATA_GRID_ORIGIN', { gridOrigin });
}

export function setGridOriginEnabled(gridOriginEnabled) {
  flux.dispatch('EXPORT_ODATA_GRID_ORIGIN_ENABLED', { gridOriginEnabled });
}
