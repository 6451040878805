import React, { useRef } from 'react';
import Highlighter from 'react-highlight-words';
import { isSvgIcon } from '@/hybrid/utilities/utilities';
import { SvgIcon } from '@/hybrid/core/SvgIcon.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { DISPLAY_LOCATION } from '@/hybrid/homescreen/homescreen.constants';
import { useKey } from 'rooks';
import { sqWorkbenchStore } from '@/core/core.stores';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';

export interface HomeScreenAddOnProps {
  identifier?: string;
  name: string;
  description?: string;
  displayAddOn: (displayLocation: DISPLAY_LOCATION) => void;
  icon?: string;
  addOnFilter: string;
}

/**
 * Displays an element with information about a single add-on.
 * Opens the add-on on the current tab when focused and:
 *   - the Enter key is pressed
 *   - the left mouse button is clicked
 * Opens the add-on in a new tab when focused and:
 *   - the Control key (or Command on macOS) is depressed while pressing the Enter key
 *   - the middle mouse button is clicked
 */
export const HomeScreenAddOn: React.FunctionComponent<HomeScreenAddOnProps> = ({
  identifier = '',
  name,
  description = '',
  displayAddOn,
  icon = '',
  addOnFilter,
}) => {
  const addOnRef = useRef(null);

  useFlux(sqWorkbenchStore);

  const computeDisplayLocation = (event: { ctrlKey: boolean; metaKey: boolean }) =>
    sqWorkbenchStore.preferNewTab || event?.ctrlKey || event?.metaKey
      ? DISPLAY_LOCATION.NEW_TAB
      : DISPLAY_LOCATION.CURRENT_PAGE;

  useKey(
    ['Enter'],
    (event) => {
      displayAddOn(computeDisplayLocation(event));
    },
    { target: addOnRef },
  );

  return (
    <div
      ref={addOnRef}
      className="toolOverviewCard homeScreenToolCard flexNoGrowNoShrink flexRowContainer cursorPointer"
      data-testid={`${identifier}-homeScreenAddOn`}
      tabIndex={0}
      onClick={(event) => {
        displayAddOn(computeDisplayLocation(event));
      }}>
      <div key={identifier} className="flexColumnContainer flexBaseline">
        {isSvgIcon(icon) ? (
          <SvgIcon
            icon={icon}
            extraClassNames="pluginToolSvg pluginToolSvgFill mr8"
            testId={`${identifier}-homeScreenAddOnSvgIcon`}
          />
        ) : (
          <Icon
            icon={icon}
            extraClassNames="fa-lg mr8 headerIcon"
            type="inherit"
            testId={`${identifier}-homeScreenAddOnIcon`}
          />
        )}
        <Highlighter
          highlightClassName="highlight-search"
          className="headerName flexFill font-size-large"
          autoEscape={true}
          searchWords={[addOnFilter] ?? []}
          textToHighlight={name}
        />
      </div>
      <Highlighter
        highlightClassName="highlight-search"
        className="toolDescription"
        autoEscape={true}
        searchWords={[addOnFilter] ?? []}
        textToHighlight={description}
      />
    </div>
  );
};
