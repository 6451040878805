import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { doTrack } from '@/track/track.service';

export type ToolStore = {
  advancedParametersCollapsed: boolean;
};

const advancedParametersBindings = bindingsDefinition({
  toolId: prop<string>(),
  toolName: prop<string>(),
  alternateName: prop.optional<string>(),
  toolStore: prop<ToolStore>(),
  sqInvestigateActions: injected<InvestigateActions>(),
});

export const AdvancedParameters: SeeqComponent<typeof advancedParametersBindings> = (props) => {
  const { toolName, toolId, toolStore, alternateName = undefined, children } = props;
  const { sqInvestigateActions } = useInjectedBindings(advancedParametersBindings);
  const { t } = useTranslation();

  const collapsed = useFluxPath(toolStore, () => toolStore.advancedParametersCollapsed);

  const trackAndClick = () => {
    doTrack(toolName, 'Advanced Toggle', `Advanced ${collapsed ? 'expanded' : 'collapsed'}`);
    sqInvestigateActions.setAdvancedParametersCollapsedState(toolId, !collapsed);
  };

  return (
    <AdvancedSection
      collapsed={collapsed}
      alternateName={alternateName}
      trackAndClick={trackAndClick}
      children={children}
      t={t}
    />
  );
};

const advancedSectionBindings = bindingsDefinition({
  collapsed: prop<boolean>(),
  alternateName: prop.optional<string>(),
  trackAndClick: prop.optional<React.MouseEventHandler<HTMLDivElement>>(),
  children: prop.optional<any>(),
  t: prop<Function>(),
});

export const AdvancedSection: SeeqComponent<typeof advancedSectionBindings> = ({
  collapsed,
  alternateName,
  trackAndClick,
  children,
  t,
}) => {
  return (
    <div id="advancedParameters">
      <div data-testid="panelControl" className="cursorPointer pt5 pb5 sq-text-primary" onClick={trackAndClick}>
        <Icon icon={collapsed ? 'fa-plus' : 'fa-minus'} testId="advancedParametersIcon" />
        <a className="ml10 link-no-underline">{t(alternateName ? alternateName : 'ADVANCED')}</a>
      </div>
      {!collapsed && (
        <>
          <div className="divider" />
          {children}
        </>
      )}
    </div>
  );
};
