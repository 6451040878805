// @ts-strict-ignore
import moment from 'moment';
import _ from 'lodash';
import { AssetSelection, DateRange } from '@/reportEditor/report.constants';
import {
  formatAssetSelectionFromApiOutput,
  formatDateRangeFromApiOutput,
} from '@/hybrid/utilities/froalaReportContent.utilities';

enum SandboxReload {
  FORCE_RELOAD,
  NO_FORCE_RELOAD,
}

interface UrlParams {
  dateRangeStart?: string[];
  dateRangeEnd?: string[];
  assetSelection?: string[];
}

interface ParamKeyValMap {
  [key: string]: string;
}

function sandboxUrlParams(originalData, { utilities, sqReportActions, sqFormula }) {
  /**
   * This function will check for any URL parameters that can change the stores. The params that we're following now
   * are assetSelection, dateRangeStart and dateRangeEnd. Each change for an assetSelection or a dateRange will
   * result in a promise that returns FORCE_RELOAD. Each assetSelection or dateRange that doesn't have a change
   * will return NO_FORCE_RELOAD
   *
   * This will be used as a callback in the reportService.load function as a transform method after the data loaded
   *
   * If there's any FORCE_RELOAD then we need to ask the stores to be loaded again from the Backend
   * @param urlParams The URL Parameters that were received
   * @returns Promise<boolean> - true if needs reloading
   */
  function applyUrlParamsToData(urlParams: UrlParams): (data: any) => Promise<boolean> {
    return ([reportData, { data }]) => {
      const allChangesPromises = [];
      if (_.isArray(urlParams.assetSelection)) {
        const assetSelectionOverrides = utilities.splitParamToObjectValue(urlParams.assetSelection);
        // parse each assetSelection
        _.forEach(data.assetSelections, (singleAssetSelection) => {
          const formattedAssetSelection = formatAssetSelectionFromApiOutput(singleAssetSelection);
          allChangesPromises.push(changeAssetSelection(formattedAssetSelection, assetSelectionOverrides));
        });
      }
      if (_.isArray(urlParams.dateRangeStart) || _.isArray(urlParams.dateRangeEnd)) {
        const dateRangeStartOverrides = utilities.splitParamToObjectValue(urlParams.dateRangeStart);
        const dateRangeEndOverrides = utilities.splitParamToObjectValue(urlParams.dateRangeEnd);
        // parse each dateRange
        _.forEach(data.dateRanges, (singleDateRange) => {
          const formattedDateRange = formatDateRangeFromApiOutput(singleDateRange);
          allChangesPromises.push(changeDateRange(formattedDateRange, dateRangeStartOverrides, dateRangeEndOverrides));
        });
      }
      return Promise.all(allChangesPromises).then(
        (allResults) => _.indexOf(allResults, SandboxReload.FORCE_RELOAD) !== -1,
      );
    };
  }

  /**
   * This function will search for the new dateRange name in the original list and apply any changes for the
   * start / end time if received via UrlParams. If there's any change that we apply then we will return a FORCE_RELOAD
   * value that will tell the loading engine to reload all the data since there are changes that were applied
   *
   * @param dateRange New DateRange to be changed
   * @param startOverrides Overrides received via UrlParams for start time
   * @param endOverrides Overrides received via UrlParams for end time
   * @returns
   */
  function changeDateRange(
    dateRange: DateRange,
    startOverrides: ParamKeyValMap,
    endOverrides: ParamKeyValMap,
  ): Promise<SandboxReload> {
    const oldDateRange = _.find(originalData.dateRanges, ['name', dateRange.name]);
    const clonedDateRange = _.cloneDeep(dateRange);
    const startTime = moment(_.get(startOverrides, oldDateRange.id, -1), moment.ISO_8601);
    const endTime = moment(_.get(endOverrides, oldDateRange.id, -1), moment.ISO_8601);
    if (startTime.isValid()) {
      _.set(clonedDateRange, 'range.start', _.toNumber(startTime.format('x')));
    }
    if (endTime.isValid()) {
      _.set(clonedDateRange, 'range.end', _.toNumber(endTime.format('x')));
    }
    if (startTime.isValid() || endTime.isValid()) {
      return sqReportActions.updateDateRange(clonedDateRange).then(() => SandboxReload.FORCE_RELOAD);
    }
    return Promise.resolve(SandboxReload.NO_FORCE_RELOAD);
  }

  /**
   * This function will search for the new AssetSelection name in the original list and apply any changes for the
   * asset if received via UrlParams. If there's any change that we apply then we will return a FORCE_RELOAD
   * value that will tell the loading engine to reload all the data since there are changes that were applied
   *
   * @param assetSelection New AssetSelection to be changed
   * @param assetSelectionOverrides Overrides received via UrlParams for the assetSelections
   * @returns
   */
  function changeAssetSelection(
    assetSelection: AssetSelection,
    assetSelectionOverrides: ParamKeyValMap,
  ): Promise<SandboxReload> {
    // check if we need to change this asset selection
    const oldSelection = _.find(originalData.assetSelections, ['name', assetSelection.name]);
    const newAssetId = _.get(assetSelectionOverrides, oldSelection.id, false);
    if (oldSelection && newAssetId) {
      return sqFormula.getAssetSiblings(assetSelection).then((siblings) => {
        const newAsset = _.find(siblings, ['id', newAssetId]);
        if (newAsset) {
          assetSelection.asset = newAsset;
          return sqReportActions.updateAssetSelection(assetSelection).then(() => SandboxReload.FORCE_RELOAD);
        } else {
          return Promise.resolve(SandboxReload.NO_FORCE_RELOAD);
        }
      });
    } else {
      return Promise.resolve(SandboxReload.NO_FORCE_RELOAD);
    }
  }

  return {
    applyUrlParamsToData,
  };
}

export { sandboxUrlParams, SandboxReload };
