// @ts-strict-ignore
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import React, { useEffect, useState } from 'react';
import { ReportConfigDateRange } from '@/hybrid/reportConfig/components/shared/ReportConfigDateRange.atom';
import { DateRangeIcon } from '@/hybrid/reportEditor/DateRangeIcon.molecule';
import _ from 'lodash';
import { ReportActions } from '@/reportEditor/report.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';

import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { DateRange, ReportClickActions } from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';
import { sqReportStore } from '@/core/core.stores';

const reportConfigDateRangeGroupBindings = bindingsDefinition({
  timezone: prop<string>(),
  triggerStepFunctionOnLoad: prop.optional<boolean>(),
  dateRanges: prop<DateRange[]>(),
  isAutoUpdate: prop<boolean>(),
  viewOnly: prop<boolean>(),
  areDateRangesReadOnly: prop<boolean>(),
  openDateSelector: prop<(dateRange: DateRange, isCapsuleSelect: boolean, checkFixed: boolean) => void>(),
  onClickStep: prop<() => any>(),
  onClickAdd: prop<() => any>(),
  stepDateRangeToEnd: prop<((DateRange) => any) | undefined>(),
  openBulkEditWithGivenRange: prop<(dateRange: DateRange) => void>(),
  sqReportActions: injected<ReportActions>(),
});

export interface ReClickTriggerProperties {
  action: ReportClickActions;
  nullableIndex: number;
  secondArg?: any;
}

export const ReportConfigDateRangeGroup: SeeqComponent<typeof reportConfigDateRangeGroupBindings> = (props) => {
  const { sqReportActions } = useInjectedBindings(reportConfigDateRangeGroupBindings);
  const {
    timezone,
    dateRanges,
    isAutoUpdate,
    viewOnly,
    areDateRangesReadOnly,
    openDateSelector,
    onClickAdd,
    onClickStep,
    stepDateRangeToEnd,
    triggerStepFunctionOnLoad = false,
    openBulkEditWithGivenRange,
  } = props;

  const { t } = useTranslation();
  const headerTooltip = isAutoUpdate ? 'REPORT.CONFIG.STEP_ALL_AUTO_TO_END' : 'REPORT.CONFIG.STEP_ALL_FIXED_TO_END';
  const stepTestId = isAutoUpdate ? 'stepScheduledReportToNowIcon' : 'stepAllFixedDateRangesToNowIcon';
  const addTestId = isAutoUpdate ? 'addAutoDateRangeIcon' : 'addFixedDateRangeIcon';
  const { dateRangeToContentMap } = useFlux(sqReportStore);
  const hasInUseRanges = (dateRanges: DateRange[], map) =>
    _.some(dateRanges, (dateRange) => _.get(map, dateRange.id, []).length > 0);

  useEffect(() => {
    triggerStepFunctionOnLoad && onClickStep();
  }, [triggerStepFunctionOnLoad]);

  const defaultReClickProperties: ReClickTriggerProperties = {
    action: ReportClickActions.None,
    nullableIndex: null,
    secondArg: null,
  };

  const [reClickProps, setReClickProps] = useState(defaultReClickProperties);
  const stepDateEnd = isAutoUpdate ? undefined : stepDateRangeToEnd;
  const getOrderedIndex = (range, dateRanges) => _.findIndex(dateRanges, { id: range.id });

  /**
   * Given a function, this decorates that function with logic that makes the function EITHER execute, or activate
   * sandbox mode instead. Used to give the actions in ReportConfigDateRange a way to trigger sandbox mode as well
   * as to setup a re-click for the action in question once the sandbox date ranges are loaded.
   */
  const actionWrapper = (action: (...args) => any, actionType: ReportClickActions): ((...actionArgs) => any) => {
    const oldDateRanges = _.cloneDeep(dateRanges);
    const onSandbox = (...args) => {
      const range = args[0];
      const index = getOrderedIndex(range, oldDateRanges);
      setReClickProps({
        action: actionType,
        nullableIndex: index,
        secondArg: args.length > 1 ? args[1] : null,
      });
    };
    const onAction = (...args) => {
      setReClickProps(defaultReClickProperties);
    };
    return sqReportActions.doActionElseActivateSandbox(action, onAction, onSandbox);
  };

  const fixedHeader = <span className="mb0 pl5">{t('REPORT.CONFIG.FIXED')}</span>;

  const autoHeader = (
    <div className="flexColumnContainer">
      <div
        className="autoUpdateIconAndHeader"
        onClick={() => {
          doTrack('Topic', 'Date Range Tool', 'edit - auto-update icon');
          if (!areDateRangesReadOnly && !viewOnly) {
            sqReportActions.setShowConfigureAutoUpdateModal(true);
          }
        }}>
        <DateRangeIcon dateRange={dateRanges[0]} includeTooltip={true} extraClassNames="cursorPointer fa-fw pl5" />
        <span className="mb0 pl5">{t('REPORT.CONFIG.AUTO_UPDATE')}</span>
      </div>
    </div>
  );

  const renderThisGroup = !viewOnly ? true : hasInUseRanges(dateRanges, dateRangeToContentMap);
  return renderThisGroup ? (
    <>
      <div className="flexColumnContainer flexSpaceBetween pt3 pb3 reportConfigDateRangeHeader">
        {isAutoUpdate && autoHeader}
        {!isAutoUpdate && fixedHeader}
        <div className="pr12 pl5 flexColumnContainer">
          <div data-testid={stepTestId} onClick={onClickStep}>
            <Icon
              icon="fc-arrow-right-right"
              tooltip={headerTooltip}
              tooltipPlacement="top"
              extraClassNames="cursorPointer ml5 mt2"
              type={areDateRangesReadOnly ? 'inherit' : 'theme'}
            />
          </div>
          {!viewOnly && (
            <div data-testid={addTestId} onClick={onClickAdd}>
              <Icon
                icon="fa-plus"
                tooltip="REPORT.CONFIG.CONFIGURE"
                tooltipPlacement="top"
                extraClassNames="fa-fw cursorPointer ml5 mt2"
                type={areDateRangesReadOnly ? 'inherit' : 'theme'}
              />
            </div>
          )}
        </div>
      </div>
      <div className="divider" />
      {_.map(dateRanges, (range) => {
        const isInitiatingReClick = reClickProps.nullableIndex === getOrderedIndex(range, dateRanges);
        const isRename = reClickProps.action === ReportClickActions.ChangeName;
        return (
          <ReportConfigDateRange
            viewOnly={viewOnly}
            areDateRangesReadOnly={areDateRangesReadOnly}
            key={range.id}
            range={isInitiatingReClick && isRename ? { ...range, name: reClickProps.secondArg } : range}
            timezone={timezone}
            openDateSelector={openDateSelector}
            isAutoUpdate={isAutoUpdate}
            stepDateRangeToEnd={stepDateEnd}
            actionWrapper={actionWrapper}
            reClickAction={isInitiatingReClick ? reClickProps.action : ReportClickActions.None}
            openBulkEditWithGivenRange={openBulkEditWithGivenRange}
            reClickArg={isInitiatingReClick ? reClickProps.secondArg : undefined}
          />
        );
      })}
    </>
  ) : null;
};
