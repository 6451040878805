import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { LogNavigation } from '@/hybrid/logTracker/LogNavigation.molecule';

const logsTableBindings = bindingsDefinition({
  messages: prop<any>(),
  modifyEndTime: prop<(val: string) => void>(),
  messagesEndRef: prop<any>(),
  onFetchMoreLogs: prop<any>(),
  isFetching: prop<boolean>(),
  limit: prop<number>(),
});

export const LogTrackerTable: SeeqComponent<typeof logsTableBindings> = ({
  messages,
  modifyEndTime,
  messagesEndRef,
  isFetching,
  onFetchMoreLogs,
  limit,
}) => {
  return (
    <div data-testid="LogDataContainer" className="flexFillOverflow overflowAuto flexRowContainer">
      <LogNavigation onFetchMoreLogs={onFetchMoreLogs} limit={limit} isFetching={isFetching} />
      {_.map(messages, (msg: any, index) => (
        <div
          key={index}
          data-testid={`LogDataContainer_row_${index}`}
          className={`specLog text-monospace aggressiveWordBreak pl5 pr5 flexNoGrowNoShrink sq-bg-${msg?.level?.toLowerCase()}`}>
          <div className="inlineBlock width-40">
            <strong className="specLogLevel text-pre-wrap">{msg.level}</strong>
          </div>
          <a href="#" className="specEndTime mr5 ml5" onClick={() => modifyEndTime(msg.time)}>
            {msg.time}
          </a>
          <span className="specLogMessage text-pre-wrap">{`${msg.source} ${msg.message}`}</span>
        </div>
      ))}
      <div data-testid="LogDataContainer_last_item" ref={messagesEndRef} className="mb12" />
    </div>
  );
};
