// @ts-strict-ignore
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import classNames from 'classnames';
import _ from 'lodash';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { ReportContentStore } from '@/reportEditor/reportContent.store';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { getWorksheetUrlParams, isWorksheetUrl } from '@/hybrid/utilities/froalaReportContent.utilities';

const reportContentUrlBindings = bindingsDefinition({
  sqReportContentActions: injected<ReportContentActions>(),
  sqReportContentStore: injected<ReportContentStore>(),
  sqReportContent: injected<ReportContentService>(),
});

export const ReportContentUrl: SeeqComponent<typeof reportContentUrlBindings> = () => {
  const { sqReportContentActions, sqReportContentStore, sqReportContent } =
    useInjectedBindings(reportContentUrlBindings);

  const { t } = useTranslation();
  const { sourceUrl } = useFlux(sqReportContentStore);
  const [invalidReason, setInvalidReason] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [currentContentUrl, setCurrentContentUrl] = useState(sourceUrl);

  const validateContentUrl = (url: string) => {
    setCurrentContentUrl(url);
    setInvalidReason('');
    if (_.isEmpty(url)) {
      setIsInvalid(false);
      return;
    }

    if (!_.isEmpty(url) && !isWorksheetUrl(url)) {
      setIsInvalid(true);
      sqReportContentActions.setSourceUrl(undefined);
    } else {
      sqReportContentActions.setSourceUrl(url);
      getWorksheetUrlParams(url)
        .then((params) => {
          setIsInvalid(false);
          sqReportContentActions.setWorkbookId(params.workbookId);
          sqReportContentActions.setWorksheetId(params.worksheetId);
          sqReportContentActions.setWorkstepId(params.workstepId);
          sqReportContent.evaluateVisualizationOptions(params.workbookId, params.worksheetId, params.workstepId);
        })
        .catch((e) => {
          setIsInvalid(true);
          setInvalidReason(`${e?.data?.statusMessage || e} -`);
          sqReportContentActions.setSourceUrl(undefined);
        });
    }
  };

  return (
    <div className="reportContentUrl flexRowContainer" data-testid="report-content-link">
      <div className="alert alert-info alert-condensed mt0">{t('REPORT.CONTENT.LINK_HELP')}</div>
      <div className="flexColumnContainer flexSpaceBetween">
        {isInvalid ? (
          <h4 className="mt0 sq-text-danger">
            {invalidReason} {t('REPORT.CONTENT.LINK_INVALID')}
          </h4>
        ) : (
          !_.isEmpty(currentContentUrl) && <h4 className="mt0 sq-text-success">{t('REPORT.CONTENT.LINK_VALID')}</h4>
        )}
      </div>
      <textarea
        className={classNames('flexFill', {
          'sq-bg-danger': isInvalid,
          'sq-bg-success': !isInvalid && !_.isEmpty(currentContentUrl),
        })}
        placeholder={t('REPORT.CONTENT.LINK_PLACEHOLDER')}
        autoFocus
        onChange={(e) => validateContentUrl(e.target.value)}
        value={currentContentUrl}
      />
    </div>
  );
};
