import _ from 'lodash';

const requiredPropBinding = Object.freeze({
  scope: 'prop',
  required: 'required',
});
const optionalPropBinding = Object.freeze({
  scope: 'prop',
  required: 'optional',
});
const injectedBinding = Object.freeze({
  scope: 'injected',
  required: 'required',
});

/** Helper to help enforce the type of a bindings definition */
export function bindingsDefinition<T extends BindingsDefinition>(bindings: T): T {
  return Object.freeze(bindings);
}

/** Define a binding that is passed as a prop */
export function prop<T = unknown>() {
  return requiredPropBinding as Binding<'prop', 'required', T>;
}

/** Define a binding that is optionally passed as a prop */
prop.optional = function optional<T = unknown>() {
  return optionalPropBinding as Binding<'prop', 'optional', T>;
};

/** Define a injected binding */
export function injected<T = unknown>() {
  return injectedBinding as Binding<'injected', 'required', T>;
}

/** Helper to extract the list of props or injected items from the bindings as a string array */
export function bindingList<S extends BindingType, T extends BindingsDefinition>(
  scope: S,
  bindings: T,
): (keyof PickBindingsByScope<S, T>)[] {
  return _.chain(bindings)
    .pickBy((b) => b.scope === scope)
    .keys()
    .value() as any[];
}
