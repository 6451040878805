// @ts-strict-ignore
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { getSessionId } from '@/hybrid/core/sessionId.utilities';

/**
 * HTTP interceptor that adds the current SessionID as a header to all outgoing HTTP requests
 */
export function sessionIdInterceptor(config) {
  config.headers[SeeqNames.API.Headers.InteractiveSessionId] = getSessionId();
  return config;
}
