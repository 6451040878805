import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { ItemPropertiesSelector } from '@/hybrid/utilities/ItemPropertiesSelector.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { SuggestedPropertiesMode } from '@/hybrid/utilities/CustomPropertySelector.atom';
import { PropertyColumn } from '@/trendData/trendData.constants';

interface ItemPropertiesSelectorButtonProps {
  suggestedPropertiesMode?: SuggestedPropertiesMode;
  itemIds: string[];
  extraColumns?: any[];
  lookupColumns?: PropertyColumn[];
  propertyColumns: any;
  statColumns?: any[];
  showPropertyColumns?: boolean;
  isColumnEnabled: (column: any) => boolean;
  toggleColumn: (column: any) => void;
  addPropertyColumn: (column: any) => void;
  iconClasses?: string;
  displaySectionHeaders?: boolean;
}

export const ItemPropertiesSelectorButton: React.FunctionComponent<ItemPropertiesSelectorButtonProps> = ({
  suggestedPropertiesMode,
  itemIds,
  extraColumns,
  propertyColumns,
  statColumns,
  isColumnEnabled,
  toggleColumn,
  addPropertyColumn,
  iconClasses,
  showPropertyColumns,
  lookupColumns,
  displaySectionHeaders,
}) => {
  const config = {
    // allow the popover to be displayed above details pane
    modifiers: {
      preventOverflow: {
        enabled: false,
      },
    },
  };

  return (
    <OverlayTrigger
      trigger="click"
      rootClose={true}
      placement="top-end"
      transition={false}
      popperConfig={config as any}
      overlay={
        // marginRight because the popover is not completely shown when the button is placed near to the right border
        // Re-test without marginRight when Bootstrap 3 is removed.
        <Popover id="item-properties-selector-popover" style={{ maxWidth: '400px', marginRight: '40px' }}>
          <Popover.Content>
            <ItemPropertiesSelector
              suggestedPropertiesMode={suggestedPropertiesMode}
              itemIds={itemIds}
              extraColumns={extraColumns}
              lookupColumns={lookupColumns}
              propertyColumns={propertyColumns}
              statColumns={statColumns}
              showPropertyColumns={showPropertyColumns}
              isColumnEnabled={isColumnEnabled}
              toggleColumn={toggleColumn}
              addPropertyColumn={addPropertyColumn}
              displaySectionHeaders={displaySectionHeaders}
            />
          </Popover.Content>
        </Popover>
      }>
      <div>
        <div>
          <Icon
            extraClassNames={iconClasses}
            icon="fc-add-column"
            tooltip="CAPSULES_PANEL.ADD_CUSTOM_COLUMN"
            tooltipPlacement="top"
            tooltipDelay={500}
            type="inherit"
            testId="itemPropertiesSelectorButton"
          />
        </div>
      </div>
    </OverlayTrigger>
  );
};
