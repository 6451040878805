// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import timezoneData from '@/../public/resources/timezone-filter.json';

export type Timezone = {
  name: string;
  displayName?: string;
  offset: string;
  offsetMinutes?: number;
};

export class TimezonesService {
  #timezones;
  #defaultTimezone;

  constructor() {
    this.initialize();
  }

  /**
   * Gets the array of available time zones
   *
   * Note on offsets: The offset properties (.offset and .offsetMinutes) are the offsets for the current time in each
   * time zone. Since UTC offsets may change depending on the time of year (due to daylight savings time), they
   * should not be used directly to calculate times. They are intended to be used for sorting and grouping purposes.
   *
   * @returns {Object[]} Array of available timezones. Each object in the array contains the following properties:
   *          {String} name - Name for the time zone, for use with moment.js library
   *          {String} displayName - Name for display of the time zone
   *          {String} offset - String representation of the UTC offset for this timezone
   *          {Number} offsetMinutes - Number representation of the UTC offset, in minutes
   */
  get timezones(): Timezone[] {
    return this.#timezones;
  }

  /**
   * Gets the default time zone
   *
   * @returns {Object} The default timezone
   */
  get defaultTimezone(): Timezone {
    return this.#defaultTimezone;
  }

  /**
   * Initializes timezones and default timezone.
   *
   * @param {Object} zones - The zones to use
   */
  initialize(zones = timezoneData.zones) {
    this.#timezones = _.chain(zones)
      .map((zoneData) => {
        const zoneResult = {} as any;
        if (_.isString(zoneData)) {
          zoneResult.name = zoneData;
          zoneResult.displayName = zoneData;
        } else {
          zoneResult.name = zoneData.name;
          zoneResult.displayName = zoneData.displayName;
        }

        const zone = moment.tz.zone(zoneResult.name);
        if (!zone) {
          return undefined;
        } else {
          zoneResult.offset = moment.tz(zoneResult.name).format('Z');
          zoneResult.offsetMinutes = -1 * zone.utcOffset(moment.now());
          return zoneResult;
        }
      })
      .compact()
      .value();
    this.#defaultTimezone = this.findMostCommonTimeZone();
  }

  /**
   * Gets all timezones which are in regional format (Europe\Berlin) or in a UTC offset (UTC+1). This is due to the
   * create/update datafile endpoints only taking regional and UTC offset timezone formats, so all timezones not
   * in those formats have to be removed
   *
   * @returns {Object[]} Array of available timezones following the above conditions. Each object in the array
   * contains the following properties:
   *          {String} name - Name for the time zone, for use with moment.js library
   *          {String} displayName - Name for display of the time zone
   *          {String} offset - String representation of the UTC offset for this timezone
   *          {Number} offsetMinutes - Number representation of the UTC offset, in minutes
   */
  getRegionalAndUtcOffsetTimezones() {
    return _.reject(this.timezones, (timezone) => {
      // Rejects any timezone not in the form UTC+# or in regional form (Europe\Berlin), except for GMT/UTC
      // Example matches: PST8PDT, NZ-CHAT, WET
      return /^(([A-Za-z]{2}-[A-Za-z]{4})|([A-Za-z]{3}\d[A-Za-z]{3})|(?!(UTC|GMT))([A-Za-z]{3}))$/.test(
        timezone.displayName,
      );
    });
  }

  /**
   * Finds the most common timezone for the supplied offset, or guesses the best possible timezone if no offset is
   * provided.
   *
   * @param {String} [offset] - a time zone offset. If not supplied, then use moment to determine the best timezone.
   * @returns {Object} The timezone to be selected.
   */
  findMostCommonTimeZone(offset?) {
    const preferredTimeZoneNames = [
      'US/Pacific',
      'US/Mountain',
      'US/Central',
      'US/Eastern',
      'UTC',
      'WET',
      'CET',
      'EET',
    ];

    if (!offset) {
      // If a user offset was not supplied as an argument, then use moment to determine the best offset choice
      const momentTimezoneGuess = moment.tz.guess();
      offset = moment.tz(momentTimezoneGuess).format('Z');
      // Make the moment-supplied timezone guess a preferred name
      preferredTimeZoneNames.push(momentTimezoneGuess);
    }

    // Determine the list of possible time zones based on the offset
    const possibleTimeZones = _.filter(this.timezones, ['offset', offset]);

    // Pick one from our "preferred" list if the offsets match
    let [preferredTimeZone] = _.chain(preferredTimeZoneNames)
      .map((name) => _.find(possibleTimeZones, { name }))
      .compact()
      .value();

    // If we didn't find a preferred time zone then just pick the first possible one that matches the offset. Default
    // to UTC as a last resort if nothing matches.
    if (!preferredTimeZone) {
      preferredTimeZone = _.head(possibleTimeZones) || _.find(this.timezones, ['name', 'UTC']);
    }

    return preferredTimeZone;
  }
}
