import { TranslationWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DIMMING_ICON } from '@/hybrid/trend/trendHelp/trendHelp.constants';
import { AlertWarning } from '@/hybrid/trend/trendHelp/AlertWarning';
import { HelpButton } from '@/hybrid/core/HelpButton.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { sqTrendCapsuleStore } from '@/core/core.stores';

export const ChainViewCapsulesExceedLimit = () => {
  const { t } = useTranslation();

  const capsulesPerPage = useFluxPath(sqTrendCapsuleStore, (store) => store.capsulesPerPage);

  return (
    <AlertWarning>
      <div className="flexColumnContainer" data-testid="chainViewCapsulesExceedLimit">
        {t('TREND_HELP.CHAIN_VIEW_LIMITED', { count: capsulesPerPage })}
        <HelpButton
          iconClassNames="fa-xlg pl8 text-interactive"
          customChildren={
            <>
              <span className="ml5 flexSelfStart">{t('TREND_HELP.TAKE_ACTION')}</span>
              <ul>
                <li>{t('TREND_HELP.CHANGE_CAPSULES_PER_PAGE')}</li>
                <li>{t('TREND_HELP.REDUCE_DISPLAY_RANGE_DURATION')}</li>
                <li>
                  <TranslationWithHTML
                    translationKey="TREND_HELP.DIMMING_CHAIN_VIEW"
                    translationParams={{ DIMMING_ICON }}
                  />
                </li>
                <li>{t('TREND_HELP.FILTER_CAPSULES_PANE')}</li>
              </ul>
            </>
          }
        />
      </div>
    </AlertWarning>
  );
};
