// @ts-strict-ignore
import React, { useState } from 'react';
import classNames from 'classnames';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from 'react-i18next';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import _ from 'lodash';
import { IconSelect, ReactSelectOption } from '@/hybrid/core/IconSelect.molecule';
import { DateRangeIcon } from '@/hybrid/reportEditor/DateRangeIcon.molecule';
import { DateRange } from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';

const reportContentDateRangeBindings = bindingsDefinition({
  dateRange: prop<any>(),
  dateRanges: prop<any[]>(),
  setDateRange: prop<(any) => void>(),
});

export const ReportContentDateRange: SeeqComponent<typeof reportContentDateRangeBindings> = (props) => {
  const { dateRanges, dateRange, setDateRange } = props;
  const { t } = useTranslation();

  const iconLabel = (range: DateRange) => {
    return range?.auto?.enabled ? (
      <>
        <DateRangeIcon
          dateRange={range}
          includeTooltip={true}
          extraClassNames={classNames('p2', { mr5: !!range.name })}
        />
        {t(range.name)}
      </>
    ) : (
      t(range.name)
    );
  };

  const inheritOption: ReactSelectOption = {
    text: t('REPORT.CONFIG.INHERIT_FROM_WORKSHEET'),
    value: 'inherit',
    label: t('REPORT.CONFIG.INHERIT_FROM_WORKSHEET'),
  };

  const dateRangeOptions = [inheritOption].concat(
    _.chain(dateRanges)
      .sortBy(['name', 'id'])
      .compact()
      .map(
        (range): ReactSelectOption => ({
          text: t(range.name),
          value: range.id,
          label: iconLabel(range),
        }),
      )
      .value(),
  );

  const [selectedDateRange, setSelectedDateRange] = useState<ReactSelectOption>(
    dateRange ? _.find(dateRangeOptions, { value: dateRange.id }) : _.first(dateRangeOptions),
  );

  const setDateRangeAndTrack = (dateRange?) => {
    doTrack('Topic', 'Update Content', 'date range changed');
    setDateRange(dateRange);
  };

  const handleSetSelectedDateRange = (userChoice: ReactSelectOption) => {
    if (_.size(dateRanges)) {
      const assetSelectionMatch: DateRange = _.find(dateRanges, {
        id: userChoice.value,
      });
      setDateRangeAndTrack(assetSelectionMatch);
    } else {
      setDateRangeAndTrack(undefined);
    }
    setSelectedDateRange(userChoice);
  };

  return (
    <div className="flexRowContainer p10">
      <label htmlFor="dateRangeSelect">{t('REPORT.CONFIG.DATE_RANGE')}</label>
      <IconSelect
        id="dateRangeSelect"
        testId="dateRangeSelect"
        insideModal={true}
        selectOptions={dateRangeOptions}
        formattedOptions={true}
        value={selectedDateRange}
        onChange={handleSetSelectedDateRange}
      />
    </div>
  );
};

export const sqReportContentDateRange = angularComponent(reportContentDateRangeBindings, ReportContentDateRange);
