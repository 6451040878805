import _ from 'lodash';
import moment from 'moment';
import { humanScaledByteCount } from '@/hybrid/utilities/numberHelper.utilities';
import { UsageOutputV1 } from '@/sdk';
import { SearchParametersIF, SelectOptionIF } from '@/hybrid/administration/usage/UsageTab.page';
import { t } from 'i18next';
import { AggregateByEnum } from '@/sdk/api/UsageApi';
import { SeriesColumnOptions } from 'highcharts';
import { getCustomTimeFormat } from '@/hybrid/datetime/dateTime.utilities';
import { sqTimezones } from '@/hybrid/utilities/datetime.constants';
import { ColumnChartOptions, PieChartOptions } from 'other_components/highcharts';

// All times on the usage page are in UTC because that is what the backend uses for compacting and aggregating the data
export const USAGE_TIMEZONE = _.find(sqTimezones.timezones, { name: 'UTC' })!;

const UNITS_FOR_CHARTS = 'GB';

export const CHART_TYPE_COLORS = {
  Screenshot: '#39516b',
  Analysis: '#427c63',
  DataLab: '#f26722',
  OData: '#4B0082', // replace with Marketplace Purple
  Unknown: '#dd1e33',
} as const;

const AGGREGATE_TO_FIELD = {
  [AggregateByEnum.Type]: 'type',
  [AggregateByEnum.User]: 'identity',
  [AggregateByEnum.Source]: 'sourceLabel',
} as const;

const getTopEntries = (entries: UsageOutputV1[], field: typeof AGGREGATE_TO_FIELD[number]): UsageOutputV1[] => {
  const topEntries = entries.slice(0, 10);
  const otherEntry = entries.slice(10).reduce(
    (memo, entry) => {
      memo.bytes += entry.bytes;
      return memo;
    },
    { bytes: 0, [field]: t('USAGE.OTHER') } as UsageOutputV1,
  );
  if (otherEntry.bytes > 0) {
    topEntries.push(otherEntry);
  }

  return topEntries;
};

const getAggregateBy = (aggregateByOptions: SelectOptionIF<AggregateByEnum>[]): AggregateByEnum => {
  const aggregateBy = _.chain(aggregateByOptions)
    .map('value')
    .without(AggregateByEnum.Day, AggregateByEnum.Month)
    .head()
    .value();
  if (!aggregateBy) {
    throw new TypeError('Must aggregate by one other field besides Day');
  }

  return aggregateBy;
};

const getValueForAggregation = (entry: UsageOutputV1, aggregateBy: AggregateByEnum, shouldTranslate = true): string => {
  const value = entry[AGGREGATE_TO_FIELD[aggregateBy]];
  if (!_.isString(value)) {
    throw new TypeError(`No valid value found in consumption entry for aggregation field ${aggregateBy}`);
  }

  return aggregateBy === AggregateByEnum.Type && shouldTranslate ? translateType(value) : value;
};

const getColorForAggregation = (entry: UsageOutputV1, aggregateBy: AggregateByEnum): string | undefined => {
  return aggregateBy === AggregateByEnum.Type
    ? _.get(CHART_TYPE_COLORS, _.upperFirst(_.camelCase(getValueForAggregation(entry, aggregateBy, false)))) ||
        CHART_TYPE_COLORS.Unknown
    : undefined;
};

const DATE_FORMAT = 'll';
const getSharedConfig = (searchParameters: SearchParametersIF): Highcharts.Options => ({
  credits: {
    enabled: false,
  },
  title: {
    text: `${t('USAGE.DATA_USED')}
       ${moment.utc(searchParameters.startTime).format(DATE_FORMAT)}
        -
       ${moment.utc(searchParameters.endTime).format(DATE_FORMAT)}`,
  },
});

/**
 * Calculate the timeseries data in a format that can be consumed by a Highcharts pie chart
 * @param entries - Consumption entries that are aggregated only by day
 * @param onClickHandler - Action to take when user clicks on a bar in the chart
 * @param searchParameters - Parameters from the search
 */
export const getBarChartConfig = (
  entries: UsageOutputV1[],
  onClickHandler: (column: any) => void,
  searchParameters: SearchParametersIF,
): ColumnChartOptions => {
  return {
    ...getSharedConfig(searchParameters),
    chart: {
      type: 'column',
      style: { fontFamily: 'inherit' },
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: getCustomTimeFormat(),
    },
    yAxis: {
      title: { text: t('USAGE.DATA_USED_WITH_UNITS') },
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click() {
              onClickHandler(this);
            },
          },
        },
      },
    },
    series: [
      {
        name: t('USAGE.DATA_USED'),
        data: _.map(entries, (entry) => [
          moment.utc(entry.timestamp).valueOf(),
          humanScaledByteCount(entry.bytes, UNITS_FOR_CHARTS).amount,
        ]),
      },
    ] as SeriesColumnOptions[],
    tooltip: {
      formatter() {
        return `
        <div>
        <strong>${this.y} ${UNITS_FOR_CHARTS}</strong>
        </div>`;
      },
    },
  };
};

/**
 * Creates highcharts config for a stacked bar chart
 * @param entries - Consumption entries when aggregated by day and exactly one other field
 * @param searchParameters - Parameters from the search
 */
export const getHistogramChartConfig = (
  entries: UsageOutputV1[],
  searchParameters: SearchParametersIF,
): ColumnChartOptions => {
  const days = _.chain(entries)
    .map((e) => e.timestamp as string)
    .uniq()
    .value();
  const aggregateBy = getAggregateBy(searchParameters.aggregateBy);
  type ReduceType = { [k: string]: SeriesColumnOptions };
  const data: ReduceType = _.reduce(
    entries,
    (accum, entry) => {
      const name = getValueForAggregation(entry, aggregateBy);
      if (!_.has(accum, name)) {
        accum[name] = {
          type: 'column',
          name,
          color: getColorForAggregation(entry, aggregateBy),
          data: _.map(days, (day) => [moment.utc(day).valueOf(), 0]),
        };
      }

      const index = _.indexOf(days, entry.timestamp);
      (accum[name].data![index]! as [number, number])[1] = humanScaledByteCount(entry.bytes, UNITS_FOR_CHARTS).amount;
      return accum;
    },
    {} as ReduceType,
  );

  return {
    ...getSharedConfig(searchParameters),
    chart: {
      type: 'column',
      style: { fontFamily: 'inherit' },
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: getCustomTimeFormat(),
    },
    yAxis: {
      title: { text: t('USAGE.DATA_USED_WITH_UNITS') },
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter(): string {
            return this.y! > 0 ? `${this.percentage.toFixed(1)}%` : '';
          },
        },
      },
    },
    series: _.values(data),
  };
};

/**
 * Creates highcharts config for a pie chart
 * @param entries - Consumption entries when aggregated by exactly one field
 * @param searchParameters - Parameters from the search
 */
export const getPieChartConfig = (entries: UsageOutputV1[], searchParameters: SearchParametersIF): PieChartOptions => {
  const aggregateByOption = _.head(searchParameters.aggregateBy);
  if (!aggregateByOption || searchParameters.aggregateBy.length !== 1) {
    throw new TypeError('Can only show pie chart when aggregating by one field');
  }

  return {
    ...getSharedConfig(searchParameters),
    chart: {
      type: 'pie',
    },
    series: [
      {
        type: 'pie',
        name: 'By type',
        data: _.map(getTopEntries(entries, AGGREGATE_TO_FIELD[aggregateByOption.value]), (entry) => ({
          name: getValueForAggregation(entry, aggregateByOption.value),
          color: getColorForAggregation(entry, aggregateByOption.value),
          y: humanScaledByteCount(entry.bytes, UNITS_FOR_CHARTS).amount,
        })),
      },
    ],
    tooltip: {
      formatter() {
        return `
        <div>
          ${this.key}: ${this.percentage!.toFixed(1)}%
          <br/>
          <strong>${this.y} ${UNITS_FOR_CHARTS}</strong>
        </div>`;
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          format: '<strong>{point.name}</strong>: {point.percentage:.1f}%',
        },
      },
    },
  };
};

export const translateType = (type: string) =>
  t(`USAGE.TYPE_OPTIONS.${_.toUpper(_.snakeCase(type))}`, { defaultValue: type });
