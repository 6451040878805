// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { sqLicenseManagementStore, sqWorkbenchStore } from '@/core/core.stores';
import {
  fetchLicense,
  uploadLicense as licenseManagementUploadLicense,
} from '@/licenseManagement/licenseManagement.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import _ from 'lodash';
import { ContainerWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';
import moment from 'moment-timezone';
import { APP_STATE } from '@/main/app.constants';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { FileDropper } from '@/hybrid/core/FileDropper.atom';
import { buildEmailLink } from '@/hybrid/utilities/utilities';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.constants.ts';
import { adminContactEmail, adminContactName } from '@/services/systemConfiguration.utilities';

const sqLicenseUploadBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
  FileUploader: injected<any>(),
  showSuccess: prop<boolean>(),
  setShowSuccess: prop<(value: boolean) => void>(),
});

/**
 * Handles uploading of new license
 */
export const LicenseUpload: SeeqComponent<typeof sqLicenseUploadBindings> = ({ showSuccess, setShowSuccess }) => {
  const { $state } = useInjectedBindings(sqLicenseUploadBindings);

  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);
  const { license = {}, licenseStatus } = useFlux(sqLicenseManagementStore);

  const { t } = useTranslation();

  const [displayErrorStatus, setDisplayErrorStatus] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [initUploadCount, setInitUploadCount] = useState(0);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string>();
  const [file, setFile] = useState<File>();

  const fileName = file?.name;
  const hasAdditionalFeatures = !_.isEmpty(license.additionalFeatures);
  const { level: licenseLevel, validity: licenseValidity, daysToExpiration: days, userLimitRestrict } = license;
  const expirationDate = license.validThrough ? moment(license.validThrough, 'YYYY-MM-DD').format('LL') : undefined;

  const uploadTextTranslationKey =
    licenseStatus === LICENSE_STATUS.VALID || licenseStatus === LICENSE_STATUS.EXPIRED
      ? 'LICENSE.UPLOAD_OTHER'
      : undefined;
  const isNotUploaded = !uploadComplete || displayErrorStatus;

  useEffect(() => {
    if (uploadComplete) {
      if (_.includes([LICENSE_STATUS.NONE, LICENSE_STATUS.UNKNOWN, LICENSE_STATUS.EXPIRED], licenseStatus)) {
        setDisplayErrorStatus(true);
        setUploadComplete(false);
      } else {
        setShowSuccess(true);
      }
    }
  }, [license, licenseStatus, uploadComplete]);

  const userLimitInfo = (key: string) => (userLimitRestrict ? ` ${t(key, { LIMIT: userLimitRestrict })}` : '');

  /**
   * Finds the appropriate translation key for the current license status.
   *
   * @returns {String} the translation key to use for the translation.
   */
  const getInfoTextTranslation = () => {
    let emailLink = buildEmailLink(
      adminContactEmail(),
      adminContactName(),
      'LICENSE.EMAIL_LICENSE_REQUEST.SUBJECT',
      'LICENSE.EMAIL_LICENSE_REQUEST.BODY',
    );
    const showWarning =
      licenseStatus === LICENSE_STATUS.SHOW_ADMIN_LICENSE_WARNING ||
      licenseStatus === LICENSE_STATUS.SHOW_LICENSE_WARNING;

    if (!currentUser.isAdmin) {
      if (licenseStatus === LICENSE_STATUS.NONE) {
        return `${t('LICENSE.NOT_SET_UP')}<br>${t('LICENSE.NO_LICENSE_INFO_TEXT', {
          CONTACT: emailLink,
        })}`;
      }

      if (licenseStatus === LICENSE_STATUS.EXPIRED) {
        return t('LICENSE.EXPIRED_LICENSE_INFO_TEXT', {
          CONTACT: emailLink,
        });
      }

      if (licenseStatus === LICENSE_STATUS.UNKNOWN) {
        emailLink = buildEmailLink(
          adminContactEmail(),
          adminContactName(),
          'LICENSE.EMAIL_LICENSE_REQUEST.SUBJECT',
          'LICENSE.EMAIL_LICENSE_REQUEST.CHECK',
        );

        return `${t('LICENSE.LICENSE_PROBLEM')}<br>${t('LICENSE.USER_INSTRUCTIONS_UNKNOWN', {
          CONTACT: emailLink,
        })}`;
      }
    } else {
      if (showWarning) {
        if (showSuccess) {
          return (
            t('LICENSE.STATUS', {
              LEVEL: licenseLevel,
              LICENSE_VALIDITY: licenseValidity,
              EXPIRATION: expirationDate,
              DAYS: days,
            }) + userLimitInfo('LICENSE.USER_LIMIT')
          );
        }

        return (
          t('LICENSE.EXPIRING_LICENSE_INFO_TEXT_PART1', {
            LEVEL: licenseLevel,
            DAYS: days,
          }) + userLimitInfo('LICENSE.USER_LIMIT_TRIAL')
        );
      }

      if (licenseStatus === LICENSE_STATUS.NONE || licenseStatus === LICENSE_STATUS.UNKNOWN) {
        return t('LICENSE.HAVE_LICENSE');
      }

      if (licenseStatus === LICENSE_STATUS.EXPIRED) {
        return t('LICENSE.EXPIRED_LICENSE_UPLOAD', {
          LEVEL: licenseLevel,
        });
      }

      if (licenseStatus === LICENSE_STATUS.VALID) {
        if (showSuccess) {
          return (
            t('LICENSE.STATUS', {
              LEVEL: licenseLevel,
              LICENSE_VALIDITY: licenseValidity,
              EXPIRATION: expirationDate,
              DAYS: days,
            }) + userLimitInfo('LICENSE.USER_LIMIT')
          );
        }

        emailLink = buildEmailLink('support@seeq.com', 'support@seeq.com');

        return `${t('LICENSE.VALID_LICENSE_INFO', {
          LEVEL: licenseLevel,
          DAYS: days,
          EXPIRATION: expirationDate,
          CONTACT: emailLink,
        })}${userLimitInfo('LICENSE.USER_LIMIT')}<br>${t('LICENSE.VALID_LICENSE_QUESTIONS', {
          CONTACT: emailLink,
        })}`;
      }
    }
  };

  /**
   * Takes the user to the workbench view.
   */
  const launchWorkbench = () => $state.go(APP_STATE.WORKBOOKS);

  /**
   * Called when clicking the drag'n'drop file area to make sure the "Choose file" dialog opens.
   */
  const selectFile = () => setInitUploadCount((initUploadCount) => initUploadCount + 1);

  const getFileDropperText = () => {
    if (isUploading) {
      return t('LICENSE.UPLOADING');
    }

    return fileName ? fileName : t('LICENSE.UPLOAD_LICENSE_PROMPT');
  };

  /**
   * Uploader callback which is called when a file is either selected or dropped on the drop-area.
   * The callback will set the necessary request headers for the file and upload it.
   *
   * @param {File} file - The item to be uploaded
   */
  const onAfterAddingFile = (file: File) => {
    setDisplayErrorStatus(false);
    setUploadErrorMessage(undefined);
    setFile(file);
  };

  /**
   * Executes the license upload.
   */
  const uploadLicense = () => {
    setUploading(true);

    licenseManagementUploadLicense(file)
      .then(fetchLicense)
      .then(() => setUploadComplete(true))
      .catch((err) => setUploadErrorMessage(err?.response?.data?.statusMessage))
      .finally(() => {
        setUploading(false);
        setFile(undefined);
      });
  };

  const infoTextTranslation = getInfoTextTranslation();

  return (
    <>
      <div className="mb20 flexRowContainer section text-center">
        {infoTextTranslation && <ContainerWithHTML content={infoTextTranslation} />}
        {hasAdditionalFeatures && (
          <table className="table table-striped">
            <thead className="text-bolder">
              <tr>
                <td>{t('LICENSE.ADDITIONAL_FEATURE')}</td>
                <td>{t('LICENSE.FEATURE_STATUS')}</td>
                <td>{t('LICENSE.FEATURE_VALID_THROUGH')}</td>
              </tr>
            </thead>
            <tbody>
              {_.map(license.additionalFeatures, (feature) => (
                <tr key={feature.name}>
                  <td>{feature.name}</td>
                  <td>{feature.validity}</td>
                  <td>{moment(feature.validThrough).format('MMM D, YYYY')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {showSuccess && (
          <TextButton variant="theme" onClick={launchWorkbench} extraClassNames="mt35" label="LICENSE.LAUNCH_SEEQ" />
        )}
      </div>

      {currentUser.isAdmin && !showSuccess && (
        <>
          {uploadTextTranslationKey && <div className="text-center section mb20">{t(uploadTextTranslationKey)}</div>}

          <div className="flexRowContainer mb20 section">
            <div className="flexColumnContainer flexCenter flexAlignCenter mb10">
              {isNotUploaded && (
                <FileDropper
                  id="fileUpload"
                  testId="fileUpload"
                  text={getFileDropperText()}
                  actionText="FILE_UPLOADED_AS"
                  filename={fileName}
                  initUploadCount={initUploadCount}
                  fileDropCallback={onAfterAddingFile}
                  clearFileCallback={() => setFile(undefined)}
                  addFileIndicator={
                    isUploading ? (
                      <IconWithSpinner spinning={true} extraClassNames="licenseUploadIcon fa-3x" />
                    ) : (
                      <Icon icon="fa-plus-circle" extraClassNames="licenseUploadIcon fa-3x" />
                    )
                  }
                />
              )}

              <div data-testid="uploadActions" className="pl20 displayGrid width-25percent">
                <TextButton
                  variant="theme"
                  onClick={selectFile}
                  testId="browseFile"
                  extraClassNames="mb10"
                  label="LICENSE.BROWSE"
                />

                <TextButton
                  variant="theme"
                  onClick={uploadLicense}
                  disabled={!fileName}
                  testId="upload"
                  label="LICENSE.UPLOAD"
                />
              </div>
            </div>

            {displayErrorStatus && (
              <>
                <div className="sq-text-danger text-center">{t('LICENSE.UPLOADED_ERROR')}</div>

                <div className="sq-text-danger text-center">
                  {t('LICENSE.STATS', {
                    DAYS: days,
                    EXPIRATION: expirationDate,
                    LEVEL: licenseLevel,
                    LICENSE_VALIDITY: licenseValidity,
                  })}
                </div>
              </>
            )}

            {uploadErrorMessage && (
              <div className="sq-text-danger text-center" data-testid="uploadErrorMessage">
                {uploadErrorMessage}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
