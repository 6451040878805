// @ts-strict-ignore
import _ from 'lodash';
import React, { useEffect } from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useDrag } from 'react-dnd';
import { MinimapHandle } from '@/hybrid/scatterPlot/minimap/MinimapHandle.atom';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { MINIMAP_HANDLE_DRAG_SOURCE_TYPE } from '@/hybrid/scatterPlot/minimap/MinimapInner.molecule';

const draggableMinimapHandleBindings = bindingsDefinition({
  left: prop<number>(),
  dragAction: prop<(any) => void>(),
  color: prop<string>(),
  id: prop<string>(),
});

/**
 * The draggable handle for the minimap, aka the icon you click on to start dragging. This renders a visible icon
 * when you're not dragging, and is the DragSource.
 */
export const DraggableMinimapHandle: SeeqComponent<typeof draggableMinimapHandleBindings> = (props) => {
  const { left, dragAction, color, id } = props;

  const [{ item }, drag, preview] = useDrag(
    {
      item: { type: MINIMAP_HANDLE_DRAG_SOURCE_TYPE, id, left, dragAction },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        item: { type: MINIMAP_HANDLE_DRAG_SOURCE_TYPE, id, left, dragAction },
      }),
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - this is a hook that accepts a second argument, but ts doesn't think it does
    [id, dragAction, left],
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <a
      className="minimapHandle positionRelative cursorResizeEW"
      ref={drag}
      style={{ left: _.isFinite(left) ? left : 0 }}>
      <MinimapHandle color={color} id={id} />
    </a>
  );
};
