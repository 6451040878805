// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import ValueWithUnits, { ValueWithUnitsItem } from '@/hybrid/trend/ValueWithUnits.atom';
import {
  getFormFieldProps,
  getFormFieldWrapperProps,
  getValidationFunction,
} from '@/hybrid/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { DurationTimeUnit } from '@/main/app.constants';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { useShowError } from '@/hybrid/formbuilder/hooks/useShowError';
import { FormFieldWrapper } from '@/hybrid/formbuilder/FormFieldWrapper';

export interface ValueWithUnitsIF extends ValidatingFormComponent<ValueWithUnitsItem> {
  component: 'ValueWithUnitsFormComponent';
  min: number;
  propName?: string;
  minIsExclusive?: boolean;
  required?: boolean;
  availableUnits?: DurationTimeUnit[];
  insideModal?: boolean;
  disabled?: boolean;
  label?: string;
  step?: number | string;
  inlineLabel?: string;
  warningMessage?: string;
  includeAutoCheckbox?: boolean;
  autoCheckboxValue?: boolean;
  largeValueInput?: boolean;
  fullWidth?: boolean;
  autoCheckboxOnChange?: (value: boolean) => any;
  autoCheckboxId?: string;
  includeRemoveButton?: boolean;
  removeIconOnClick?: () => any;
}

export const ValueWithUnitsFormComponent: React.FunctionComponent<ValueWithUnitsIF> = (props) => {
  const { t } = useTranslation();
  const {
    label,
    value,
    tooltip,
    name,
    validation,
    inlineLabel,
    required = true,
    min,
    testId,
    minIsExclusive = false,
    warningMessage = '',
    extendValidation,
    includeAutoCheckbox = false,
    autoCheckboxValue = false,
    autoCheckboxOnChange = _.noop,
    autoCheckboxId,
    includeRemoveButton = false,
    removeIconOnClick,
    extraClassNames,
  } = props;

  const { showError, setShowError } = useShowError(false);

  const defaultValidation = (valueWithUnits) => {
    const { units, value } = valueWithUnits || {};
    if (required || !_.isNil(units)) {
      if (!_.isNil(min)) {
        return !(minIsExclusive ? value > min : value >= min);
      } else {
        return false;
      }
    }

    return false;
  };
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();

  return (
    <FormFieldWrapper {...getFormFieldWrapperProps(props)} showError={showError}>
      <div className="flexColumnContainer flexAlignCenter" id={`${testId}_valueWithUnits`}>
        {inlineLabel && <div className="mr5">{t(inlineLabel)}</div>}
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const properProps = getFormFieldProps(formState, input, meta, props);
            setShowError(properProps.showError);

            return (
              <ValueWithUnits {..._.omit(properProps, 'extraClassNames')} isValid={!showError} defaultValue={value} />
            );
          }}
        </Field>
        {includeAutoCheckbox && (
          <Checkbox
            id={autoCheckboxId}
            isChecked={autoCheckboxValue}
            classes="ml10"
            label="AUTO"
            onChange={autoCheckboxOnChange}
          />
        )}
        {includeRemoveButton && (
          <span data-testid="removeMaxDurationButton" onClick={removeIconOnClick}>
            <Icon icon="fa-close" tooltip="REMOVE" extraClassNames="ml10 cursorPointer fa-xlg" />
          </span>
        )}
        {!label && tooltip && (
          <Icon
            icon="fa-question-circle"
            tooltip={tooltip}
            tooltipPlacement="top"
            large={true}
            extraClassNames="text-interactive pl10"
            testId={`${testId}Tooltip`}
          />
        )}
        {warningMessage && (
          <Icon
            icon="fa-exclamation-circle"
            tooltip={warningMessage}
            tooltipPlacement="top"
            large={true}
            extraClassNames="fw sq-text-warning pl10"
          />
        )}
      </div>
    </FormFieldWrapper>
  );
};
