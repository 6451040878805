// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { TableBuilderActions } from '@/hybrid/tableBuilder/tableBuilder.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { Icon } from '@/hybrid/core/Icon.atom';
import { FormulaService } from '@/services/formula.service';
import { SelectAssetModal } from '@/hybrid/core/SelectAssetModal.molecule';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { sqTreesApi } from '@/sdk';
import { isAsset, itemIconClass } from '@/hybrid/utilities/utilities';
import { TABLE_BUILDER } from '@/hybrid/tableBuilder/tableBuilder.constants';
import { doTrack } from '@/track/track.service';

const selectAssetForTableModalBindings = bindingsDefinition({
  show: prop<boolean>(),
  onClose: prop<() => void>(),
  assetId: prop<string>(),
  homogenizeUnits: prop<boolean>(),
  sqTableBuilderActions: injected<TableBuilderActions>(),
  sqFormula: injected<FormulaService>(),
});

export const SelectAssetForTableModal: SeeqComponent<typeof selectAssetForTableModalBindings> = (props) => {
  const { sqTableBuilderActions, sqFormula } = useInjectedBindings(selectAssetForTableModalBindings);

  const { show, onClose, assetId, homogenizeUnits } = props;
  const { t } = useTranslation();
  const [assetName, setAssetName] = useState(null);

  useEffect(() => {
    if (assetId) {
      sqFormula.getAssetPath({ id: assetId }, undefined).then((path) => setAssetName(path));
    } else {
      setAssetName(null);
    }
  }, [assetId]);

  const checkThatSelectedItemHasAtLeastOneAssetChild = (item): Promise<boolean> =>
    sqTreesApi
      .getTree({ id: item.id })
      .then(({ data }) => _.some(data.children, (child) => isAsset(child))) as Promise<boolean>;

  const onAssetSelect = (item) => {
    sqTableBuilderActions.setHomogenizeUnits(true);
    sqTableBuilderActions.setAssetId(item.id);
    doTrack(TABLE_BUILDER, 'asset', 'enabled');
    onClose();
  };

  const clearAsset = () => {
    sqTableBuilderActions.setAssetId(undefined);
    doTrack(TABLE_BUILDER, 'asset', 'disabled');
    onClose();
  };

  const toggleHomogenizeUnits = () => {
    sqTableBuilderActions.setHomogenizeUnits(!homogenizeUnits, true);
    onClose();
  };

  const body = (
    <>
      {_.isNil(assetName) && (
        <Alert className="p5" variant="info" transition={false}>
          {t('TABLE_BUILDER.ASSET_HELP')}
        </Alert>
      )}
      {!_.isNil(assetName) && (
        <div className="sqGrayBox plr5 ptb1 mb5">
          <div className="flexColumnContainer flexSpaceBetween">
            <div>
              <Icon
                icon={itemIconClass({ type: SeeqNames.Types.Asset })}
                type="inherit"
                extraClassNames="sq-fairly-dark-gray pl2 pr2"
                large={true}
              />
              {assetName}
            </div>
            <span>
              <Icon
                onClick={clearAsset}
                icon="fa-remove"
                tooltip="CLEAR"
                extraClassNames="ml5 cursorPointer"
                type="theme"
                testId="clearAssetId"
                large={true}
              />
            </span>
          </div>
          <div>
            <Checkbox
              id="homogenizeUnitsCheckbox"
              isChecked={homogenizeUnits}
              label="TABLE_BUILDER.HOMOGENIZE_UNITS"
              onChange={toggleHomogenizeUnits}
            />
          </div>
        </div>
      )}
    </>
  );

  const title = <Modal.Title>{t('TABLE_BUILDER.ASSET_HEADER')}</Modal.Title>;

  return show ? (
    <SelectAssetModal
      onClose={onClose}
      iconPartialTooltipKey="TABLE_BUILDER"
      onSelect={onAssetSelect}
      validateAsset={checkThatSelectedItemHasAtLeastOneAssetChild}
      assetId={assetId}
      body={body}
      header={title}
      footer={null}
    />
  ) : null;
};
