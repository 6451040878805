// @ts-strict-ignore
import _ from 'lodash';
import { Y_AXIS_LABEL_CHARACTER_WIDTH } from '@/hybrid/utilities/label.utilities';
import i18next from 'i18next';

export const AUTO_FORMAT = 'auto';

export interface FormatOptions {
  format?: string;
}

function shortenIfNecessary(string, head, tail) {
  if (string.length > _.max([Y_AXIS_LABEL_CHARACTER_WIDTH, head + tail + 1])) {
    return tail === 0 ? `${string.slice(0, head)}...` : `${string.slice(0, head)}...${string.slice(-tail)}`;
  } else {
    return string;
  }
}

export function formatString(
  string: string,
  options?: FormatOptions,
  error: Function = _.noop,
  defaultNumberFormat: string = AUTO_FORMAT,
): string {
  const opts: FormatOptions = _.defaults({}, options, {
    format: defaultNumberFormat,
  });
  if (_.isNil(opts.format) || opts.format === AUTO_FORMAT) {
    return shortenIfNecessary(string, 2, 3);
  } else if (/^\d+$/.test(opts.format)) {
    const maxLength = _.parseInt(opts.format);
    return shortenIfNecessary(string, maxLength, 0);
  } else if (/^\d+\.{2,}\d+$/.test(opts.format)) {
    const [_ignored, headRaw, tailRaw] = /(\d+)\.{2,}(\d+)/.exec(opts.format);
    const head = _.parseInt(headRaw);
    const tail = _.parseInt(tailRaw);
    return shortenIfNecessary(string, head, tail);
  } else {
    error(
      i18next.t('PROPERTIES.STRING_FORMAT_UNSUPPORTED', {
        FORMAT: opts.format,
      }),
    );
    return shortenIfNecessary(string, 2, 3);
  }
}
