// @ts-strict-ignore
import { useContext, useEffect, useRef } from 'react';
import { useForceUpdate } from '@/hybrid/core/hooks/useForceUpdate.hook';
import { FluxContext } from '@/hybrid/core/flux.context';

/**
 * This hook behaves like the useFlux hook with one addition: it returns an array
 * container the store and a count variable that updates whenever any property in
 * the store changes
 *
 * @example
 * const Component = ({ sqExampleStore }) => {
 *   const [{ prop1, prop2 }, storeUpdate] = useFluxEffect(sqExampleStore);
 *
 *   useEffect(() => {
 *    // run this code-block anytime a prop updates in the store
 *   }, [storeUpdate]);
 *   // The above dependency array would have been much longer (basically containing
 *   // every single prop in the store) if we tried to do this with the `useFlux` hook
 * };
 *
 */
export function useFluxEffect<T>(store: T): [T, number] {
  const flux = useContext(FluxContext);
  const forceUpdate = useForceUpdate();
  const stateUpdateCount = useRef(0);

  useEffect(
    () =>
      flux.listenTo(
        store,
        undefined,
        () => {
          stateUpdateCount.current = stateUpdateCount.current > 1000 ? 0 : stateUpdateCount.current + 1;
          forceUpdate();
        },
        false,
      ),
    [flux, store],
  );

  return [store, stateUpdateCount.current];
}
