// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { AgentStatusOutputV1, DatasourcesStatusOutputV1, sqAgentsApi } from '@/sdk';
import { DatasourcesService } from '@/hybrid/administration/datasources/datasources.service';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';
import { useTranslation } from 'react-i18next';

const agentsFilterBindings = bindingsDefinition({
  sqDatasourcesService: injected<DatasourcesService>(),
  selectedAgents: prop<AgentStatusOutputV1[]>(),
  datasources: prop<DatasourcesStatusOutputV1>(),
  showManageAgents: prop<() => void>(),
  showStageVersionModal: prop<() => void>(),
  showUpdateVersionModal: prop<() => void>(),
});

export const AgentsCommandBar: SeeqComponent<typeof agentsFilterBindings> = (props) => {
  const { sqDatasourcesService } = useInjectedBindings(agentsFilterBindings);
  const { selectedAgents, datasources, showManageAgents, showStageVersionModal, showUpdateVersionModal } = props;

  const { t } = useTranslation();

  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  const openLogTab = () => {
    const agent = _.first(selectedAgents);
    openInNewTab(sqDatasourcesService.computeLogUrl(agent.name, datasources.agents));
  };

  const restartSelectedAgents = () =>
    _.chain(selectedAgents)
      .map(({ name }) => {
        return sqAgentsApi
          .restartAgent({ agentName: name })
          .then(() => {
            successToast({
              messageKey: 'ADMIN.AGENTS.RESTART_SUCCESS',
              messageParams: { name },
            });
          })
          .catch((error) => {
            errorToast({ httpResponseOrError: error });
          });
      })
      .thru((promises) => Promise.all(promises))
      .value();

  const archiveSelectedAgents = () =>
    _.chain(selectedAgents)
      .map(({ name }) => {
        return sqAgentsApi
          .archiveAgent({ agentName: name })
          .then(() => {
            successToast({
              messageKey: 'ADMIN.AGENTS.SUCCESSFULLY_ARCHIVED',
              messageParams: { name },
            });
          })
          .catch((error) => {
            errorToast({ httpResponseOrError: error });
          });
      })
      .thru((promises) => Promise.all(promises))
      .value();

  return (
    <div className="flexColumnContainer mb5" data-testid="AgentsCommandBarContainer">
      <ButtonWithManagedSpinner
        buttonProps={{
          testId: 'restartSelectedButton',
          size: 'sm',
          type: 'button',
          variant: 'outline',
          iconStyle: 'theme',
          extraClassNames: 'mr5',
          disabled: _.isEmpty(selectedAgents),
        }}
        label="ADMIN.AGENTS.RESTART"
        action={restartSelectedAgents}
        icon="fa-refresh"
      />
      <ButtonWithManagedSpinner
        buttonProps={{
          testId: 'archiveSelectedButton',
          size: 'sm',
          type: 'button',
          variant: 'outline',
          iconStyle: 'theme',
          extraClassNames: 'mr5',
          disabled: _.isEmpty(selectedAgents),
        }}
        action={archiveSelectedAgents}
        label="ADMIN.AGENTS.ARCHIVE"
        icon="fa-archive"
      />
      <TextButton
        testId="viewLogsButton"
        extraClassNames="mr5"
        size="sm"
        onClick={openLogTab}
        disabled={_.isEmpty(selectedAgents) || _.size(selectedAgents) > 1}
        label="ADMIN.AGENTS.VIEW_LOGS"
      />
      <TextButton
        testId="manageButton"
        extraClassNames="mr5"
        size="sm"
        onClick={showManageAgents}
        disabled={_.isEmpty(selectedAgents) || _.size(selectedAgents) > 1}
        label="ADMIN.AGENTS.MANAGE"
      />
      <TextButton
        testId="stageVersionButton"
        extraClassNames="mr5"
        size="sm"
        onClick={showStageVersionModal}
        disabled={_.size(selectedAgents) >= 1 && _.size(selectedAgents) < datasources.agents.length}
        label="ADMIN.AGENTS.STAGE_VERSION_MODAL"
      />
      <TextButton
        testId="updateVersionButton"
        extraClassNames="mr5"
        size="sm"
        onClick={showUpdateVersionModal}
        disabled={_.size(selectedAgents) >= 1 && _.size(selectedAgents) < datasources.agents.length}
        label="ADMIN.AGENTS.UPDATE_VERSION_MODAL"
      />
    </div>
  );
};
