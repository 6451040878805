// @ts-strict-ignore
import angular from 'angular';

angular.module('Sq.Directives.Onerror', []).directive('sqOnerror', sqOnerror);

function sqOnerror($parse) {
  return {
    restrict: 'A',
    link($scope, $element: JQuery, attrs) {
      const expressionToFunc = $parse(attrs.sqOnerror);
      $element.on('error', function ($event) {
        $scope.$evalAsync(() => {
          expressionToFunc($scope, { $element });
        });
      });
    },
  };
}
