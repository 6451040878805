import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { APP_STATE } from '@/main/app.constants';
import { DEBOUNCE } from '@/core/core.constants';
import { useDebounce } from '@/hybrid/core/hooks/useDebounce.hook';
import { useTranslation } from 'react-i18next';
import { generateTabHash } from '@/hybrid/utilities/utilities';

const navigationEntryBindings = bindingsDefinition({
  displayValue: prop<string>(),
  current: prop.optional<boolean>(),
  tabKey: prop<string>(),
  displaySelector: prop.optional<string>(),
  $state: injected<ng.ui.IStateService>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  id: prop<string>(),
});

export const NavigationEntry: SeeqComponent<typeof navigationEntryBindings> = ({
  displayValue,
  current,
  tabKey,
  id,
}) => {
  const { $state, sqHomeScreenActions } = useInjectedBindings(navigationEntryBindings);

  const { t } = useTranslation();

  const classes = classNames('cursorPointer', 'homeScreenNav', { current });

  const loadItems = useDebounce(
    () => {
      sqHomeScreenActions.resetStore();
      $state.go(APP_STATE.WORKBOOKS, { t: generateTabHash(tabKey) }, { reload: true });
    },
    DEBOUNCE.LONG,
    { leading: true, trailing: false },
  );

  return (
    <div className={classes} onClick={loadItems} id={id}>
      {t(displayValue)}
    </div>
  );
};
