import React from 'react';
import { FormulaExamples } from '@/hybrid/formula/FormulaExamples.atom';
import { FormulaFunctions } from '@/hybrid/formula/FormulaFunctions.atom';
import { FormulaParameters } from '@/hybrid/formula/FormulaParameters.atom';
import { FormulaSeeAlsos } from '@/hybrid/formula/FormulaSeeAlso.atom';
import { FormulaFamily } from '@/hybrid/formula/FormulaFamily.atom';
import { FormulaKeywords } from '@/hybrid/formula/FormulaKeywords.atom';
import { FormulaHelpFromCustomPackage } from '@/hybrid/formula/FormulaHelpFromCustomPackage.atom';
import { useTranslation } from 'react-i18next';
import { FormulaTextWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';

export interface FormulaPageViewProps {
  insertFormulaSnippet: (snippet: string) => void;
  requestDocumentation: (href: string) => any;
  functionHasNoHelp: boolean;
  helpText: any;
}

export const FormulaPageView: React.FunctionComponent<FormulaPageViewProps> = ({
  requestDocumentation,
  functionHasNoHelp,
  helpText,
  insertFormulaSnippet,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className="mt0">{helpText?.title}</h1>

      <p className="mb15">
        <FormulaTextWithHTML content={helpText?.description} requestDocumentation={requestDocumentation} />
      </p>

      <FormulaExamples
        requestDocumentation={requestDocumentation}
        examples={helpText?.examples}
        insertFormulaSnippet={insertFormulaSnippet}
      />

      <FormulaFunctions
        requestDocumentation={requestDocumentation}
        functions={helpText?.functions}
        insertFormulaSnippet={insertFormulaSnippet}
      />

      <FormulaParameters requestDocumentation={requestDocumentation} parameters={helpText?.parameters} />

      <FormulaSeeAlsos seeAlsos={helpText?.seeAlsos} requestDocumentation={requestDocumentation} />

      <FormulaFamily
        parents={helpText?.parents}
        children={helpText?.children}
        requestDocumentation={requestDocumentation}
      />

      <FormulaKeywords keywords={helpText?.keywords} />

      <FormulaHelpFromCustomPackage
        packageId={helpText?.packageId}
        packageVersion={helpText?.packageVersion}
        packageInstallerName={helpText?.packageInstallerName}
        packageCreatorName={helpText?.packageCreatorName}
        packageCreatorContactInfo={helpText?.packageCreatorContactInfo}
        packageCreatedAt={helpText?.packageCreatedAt}
        packageUpdatedAt={helpText?.packageUpdatedAt}
        docCreatedAt={helpText?.docCreatedAt}
        docUpdatedAt={helpText?.docUpdatedAt}
      />

      {functionHasNoHelp && <p>{t('FORMULA.DOCUMENTATION.UNABLE_TO_RETRIEVE')}</p>}
    </>
  );
};
