import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { APP_STATE } from '@/main/app.constants';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { isSeeqPreviewEnabled } from '@/services/systemConfiguration.utilities';

const headerLogoBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
});

export const HeaderLogo: SeeqComponent<typeof headerLogoBindings> = () => {
  const { $state } = useInjectedBindings(headerLogoBindings);

  const gotoHomeScreen = () => {
    $state.go(APP_STATE.WORKBOOKS, {}, { reload: true });
  };

  return (
    <div className="min-width-145" data-testid="headerLogo">
      <Icon
        type="white"
        onClick={gotoHomeScreen}
        icon="fa-th-large"
        extraClassNames="fa-xlg navbarHomeButton"
        testId="headerIcon"
        id="workbooksShowButton"
      />

      <div className="navbar-nav pl0 pr0 pt8 cursorPointer">
        <img
          className="mt4 mr15 mb5 ml5"
          src={`/img/Seeq_${isSeeqPreviewEnabled() ? 'preview_' : ''}logo_white@2x.png`}
          height={28}
          width={isSeeqPreviewEnabled() ? 130 : 80}
          onClick={gotoHomeScreen}
          data-testid="headerImage"
        />
      </div>
    </div>
  );
};
