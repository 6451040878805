// @ts-strict-ignore
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { InvestigateToolType } from '@/hybrid/toolSelection/investigate.constants';

import _ from 'lodash';
import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { ToolPanes } from '@/hybrid/toolSelection/ToolPanes';
import { Breadcrumbs } from '@/hybrid/toolSelection/Breadcrumbs';
import { DerivedDataPanel } from '@/hybrid/toolSelection/DerivedDataPanel';
import { ToolsBrowsePanel } from '@/hybrid/toolSelection/ToolsBrowsePanel';
import { OverviewTopSection } from '@/hybrid/toolSelection/OverviewTopSection';
import { sqInvestigateStore } from '@/core/core.stores';
import { INVESTIGATE_TOOLS, TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { doTrack } from '@/track/track.service';
import { isViewOnlyWorkbookMode } from '@/hybrid/utilities/utilities';
import { displaysEnabled } from '@/services/systemConfiguration.utilities';

const toolSelectionViewBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
});

/**
 * A component to search/filter and access/use all available tools.
 *
 * Also contains derived-data-tree
 */
export const ToolSelectionView: SeeqComponent<typeof toolSelectionViewBindings> = () => {
  const { sqInvestigateActions } = useInjectedBindings(toolSelectionViewBindings);

  const { activeTool, toolFilter, breadcrumbs, filteredTools, derivedDataPanelOpen } = useFlux(sqInvestigateStore);

  const filteredEnabledTools = displaysEnabled()
    ? filteredTools
    : _.filter(filteredTools, (tool) => tool.id !== TREND_TOOLS.DISPLAY);

  const isViewMode = isViewOnlyWorkbookMode();
  const showToolcards = _.get(_.find(INVESTIGATE_TOOLS, ['id', activeTool]), 'isGroup');

  const clearResults = () => sqInvestigateActions.setActiveTool(TREND_TOOLS.OVERVIEW);

  /**
   * Sets the active tool if it is a tool that is selected or, if it is an item, sets the investigate item.
   *
   * @param {InvestigateToolType} breadcrumb - The selected breadcrumb
   */
  const onBreadcrumbClick = (breadcrumb: InvestigateToolType) => {
    sqInvestigateActions.setActiveTool(breadcrumb.id);

    if (breadcrumb.id === TREND_TOOLS.OVERVIEW) {
      sqInvestigateActions.clearItem();
    }
  };

  const setActiveTool = (tool: InvestigateToolType) => {
    doTrack('Workbench_Tool', tool.trackAction ?? tool.name ?? tool.id, 'opened');
    sqInvestigateActions.setActiveTool(tool.id);
  };

  const setDerivedDataPanelOpen = () => {
    doTrack('Trend', 'Derived Data Panel', derivedDataPanelOpen ? 'closed' : 'opened');
    sqInvestigateActions.setDerivedDataPanelOpen(!derivedDataPanelOpen);
  };

  const setToolFilter = (toolFilter: string) => sqInvestigateActions.setToolFilter(toolFilter);

  return (
    <div
      id="investigateToolbar"
      data-testid="toolSelectionView"
      className="toolSelectionView flexFill flexRowContainer overflowHidden">
      {activeTool === 'overview' && (
        <OverviewTopSection toolFilter={toolFilter} setToolFilter={setToolFilter} clearResults={clearResults} />
      )}

      {breadcrumbs.length > 0 && !isViewMode && (
        <Breadcrumbs breadcrumbs={breadcrumbs} onBreadcrumbClick={onBreadcrumbClick} />
      )}

      {showToolcards && !isViewMode && (
        <ToolsBrowsePanel toolFilter={toolFilter} filteredTools={filteredEnabledTools} setActiveTool={setActiveTool} />
      )}

      <ToolPanes activeTool={activeTool} isViewMode={isViewMode} />

      <DerivedDataPanel derivedDataPanelOpen={derivedDataPanelOpen} setDerivedDataPanelOpen={setDerivedDataPanelOpen} />
    </div>
  );
};

export const sqToolSelectionView = angularComponent(toolSelectionViewBindings, ToolSelectionView);
