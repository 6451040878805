// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { DISPLAY_MODE, DURATION_TIME_UNITS_ALL } from '@/main/app.constants';
import { FORM_ERROR, FORM_WARNING, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { sqInvestigateStore, sqPeriodicConditionStore, sqWorkbenchStore } from '@/core/core.stores';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { getTrendItemScopedTo } from '@/hybrid/trend/trendDataHelper.utilities';
import { FormulaService } from '@/services/formula.service';
import { sqTimezones } from '@/hybrid/utilities/datetime.constants';
import {
  setDuration as setDurationAction,
  setOffset,
  setQuarterStart,
  setShift,
  setTimezone as setTimezoneAction,
  setWeekStart,
  toggleFrequency,
} from '@/hybrid/tools/periodicCondition/periodicCondition.actions';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import {
  DAYS,
  FREQUENCY_CHOICES,
  MONTHS,
  STANDARD_DURATIONS,
} from '@/hybrid/tools/periodicCondition/periodicCondition.constants';
import { doTrack } from '@/track/track.service';

const periodicConditionBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
  isReport: prop.optional<boolean>(),
  onSave: prop.optional<(item: { id: string; name: string }) => void>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
  sqToolRunner: injected<ToolRunnerService>(),
  sqFormula: injected<FormulaService>(),
});

export const PeriodicCondition: SeeqComponent<typeof periodicConditionBindings> = (props) => {
  const { isReport = false, onSave = _.noop } = props;
  const { sqInvestigateActions, sqWorkbenchActions, sqToolRunner, sqFormula, $state } =
    useInjectedBindings(periodicConditionBindings);
  const { t } = useTranslation();

  const [color, setColor] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');

  const displayMode = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.displayMode);
  const userTimeZone = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userTimeZone);

  const { id, name, shift, duration, frequencies, offset, quarterStart, timezone, weekStart } =
    useFlux(sqPeriodicConditionStore);

  const timezones = sqTimezones.timezones;
  const shiftOffset = shift.offset;
  const shiftDuration = shift.duration;
  const selectedTimezone = _.find(timezones, ['name', timezone]);
  const monthOfFirstQuarter = _.find(MONTHS, ['key', quarterStart.month]);
  const quarterDayChoices = _.range(1, monthOfFirstQuarter?.days + 1);
  const dayOfFirstQuarter = _.get(quarterDayChoices, quarterStart.day - 1);

  useEffect(() => {
    if (_.isUndefined(selectedTimezone)) {
      setTimeZone(userTimeZone);
    }
  }, [userTimeZone]);

  const setTimeZone = (timeZone) => {
    setTimezoneAction({ name: timeZone?.name });
    if (isReport) {
      doTrack('Topic', 'Date Range Tool', 'timezone assigned');
    }
  };

  const updateDuration = (duration) => {
    const durationKeys = _.map(STANDARD_DURATIONS, (duration) => t(duration.display));
    const currentNamePrefix = name ? _.trim(name.replace(/\d*$/gm, '')) : undefined;
    if (_.includes(_.concat(durationKeys, t('PERIODIC_CONDITION.HEADER')), currentNamePrefix) || _.isUndefined(name)) {
      const prefix = _.find(STANDARD_DURATIONS, ['key', duration.value])?.display ?? 'PERIODIC_CONDITION.HEADER';
      sqFormula
        .getDefaultName(t(prefix), getTrendItemScopedTo(id, $state))
        .then((name) => sqInvestigateActions.setSearchName(TREND_TOOLS.PERIODIC_CONDITION, name));
    }
    setDurationAction(duration.value);
  };

  const execute = () => {
    const { formula, parameters } = sqPeriodicConditionStore.getFormula();
    return sqToolRunner
      .panelExecuteCondition(
        name,
        formula,
        parameters,
        sqPeriodicConditionStore.configParams,
        sqPeriodicConditionStore.id,
        color,
      )
      .then((id) => {
        onSave({ id, name });
        doTrack('Workbench_Tool', 'Periodic Condition', 'completed');
      })
      .catch((errorMessage) => {
        doTrack('Workbench_Tool', 'Periodic Condition', 'error');
        setApiErrorMessage(_.replace(errorMessage, /\(.*\)/gm, ''));
      });
  };

  const formDataSetup: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'periodicConditionTitle',
      value: name,
      includeIf: !isReport,
      onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.PERIODIC_CONDITION, name),
      id,
      onColorChange: setColor,
      searchIconClass: 'fc-periodic-condition',
      defaultName: _.find(STANDARD_DURATIONS, ['key', duration])?.display ?? 'PERIODIC_CONDITION.HEADER',
      testId: 'periodicConditionTitle',
    },
    {
      component: 'IconSelectFormComponent',
      name: 'duration',
      testId: 'duration',
      label: 'DURATION',
      displayNumber: true,
      value: duration,
      onChange: updateDuration,
      extraClassNames: 'specDuration',
      insideModal: isReport,
      selectOptions: _.map(STANDARD_DURATIONS, (duration) => ({
        text: duration.display,
        value: duration.key,
      })),
      skipMemo: true,
    },
    {
      component: 'CheckboxTableFormComponent',
      name: 'frequenciesTable',
      includeIf: duration === 'days' || duration === 'months' || duration === 'quarters',
      displayNumber: true,
      extraClassNames: 'frequenciesTable',
      options: _.find(FREQUENCY_CHOICES, ['key', duration])?.choices ?? [],
      onChange: (item) => toggleFrequency(item === 'all' ? undefined : item),
      value: frequencies,
      includeAll: true,
    },
    {
      component: 'IconSelectFormComponent',
      name: 'shiftDuration',
      testId: 'shiftDuration',
      label: 'PERIODIC_CONDITION.SHIFT_DURATION',
      displayNumber: true,
      includeIf: duration === 'shifts',
      value: shiftDuration,
      validation: (shiftDuration) => _.isUndefined(shiftDuration),
      onChange: (shiftDuration) => setShift(shiftOffset, shiftDuration.value),
      extraClassNames: 'specShiftDuration',
      insideModal: isReport,
      selectOptions: _.map(_.range(1, 24), (hour) => ({
        text: hour.toString(),
        value: hour,
      })),
    },
    {
      component: 'FormControlFormComponent',
      name: 'shiftOffset',
      label: 'PERIODIC_CONDITION.SHIFT_OFFSET',
      value: shiftOffset,
      onChange: (shiftOffset) => setShift(shiftOffset, shiftDuration),
      extraClassNames: 'shiftOffset flexFill',
      type: 'number',
      size: 'lg',
      validation: (value) => value < 0 || value > 24,
      extendValidation: true,
      testId: 'shiftOffset',
      displayNumber: true,
      customErrorText: 'PERIODIC_CONDITION.SHIFT_OFFSET_VALIDATE',
      includeIf: duration === 'shifts',
    },
    {
      component: 'IconSelectFormComponent',
      name: 'weekStart',
      testId: 'weekStart',
      label: 'PERIODIC_CONDITION.WEEK_START',
      includeIf: duration === 'weeks',
      displayNumber: true,
      value: weekStart,
      onChange: (weekStart) => setWeekStart(weekStart.value),
      extraClassNames: 'specWeekStart',
      insideModal: isReport,
      selectOptions: _.map(DAYS, (day) => ({
        text: day.display,
        value: day.key,
      })),
    },
    {
      component: 'FormGroup',
      name: 'fiscalYearStartDateGroup',
      includeIf: duration === 'quarters',
      displayNumber: true,
      components: [
        {
          component: 'LabelFormComponent',
          name: 'fiscalLabel',
          value: 'PERIODIC_CONDITION.QUARTER_START',
        },
        {
          component: 'FormRow',
          name: 'fiscalYearStartDateRow',
          components: [
            {
              component: 'IconSelectFormComponent',
              name: 'monthOfFirstQuarter',
              testId: 'monthOfFirstQuarter',
              value: monthOfFirstQuarter,
              onChange: (month) => setQuarterStart(month.value.key, Math.min(dayOfFirstQuarter, month.value.days)),
              extraClassNames: 'specMonthOfFirstQuarter',
              insideModal: isReport,
              selectOptions: _.map(MONTHS, (month) => ({
                text: month.display,
                value: month,
              })),
              skipMemo: true,
            },
            {
              component: 'IconSelectFormComponent',
              name: 'dayOfFirstQuarter',
              testId: 'dayOfFirstQuarter',
              value: dayOfFirstQuarter,
              validation: (dayOfFirstQuarter) => _.isUndefined(dayOfFirstQuarter),
              onChange: (day) => setQuarterStart(monthOfFirstQuarter.key, day.value),
              extraClassNames: 'specDayOfFirstQuarter',
              insideModal: isReport,
              selectOptions: _.map(quarterDayChoices, (day) => ({
                text: day.toString(),
                value: day,
              })),
              skipMemo: true,
            },
          ],
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'timeZoneFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'TimeZoneSelectorFormComponent',
          label: 'PERIODIC_CONDITION.SELECT_TIME_ZONE',
          name: 'timeZoneSelector',
          testId: 'timeZoneSelector',
          value: selectedTimezone,
          defaultTimeZone: sqTimezones.defaultTimezone,
          optional: false,
          enableAutoDetect: false,
          onChange: setTimeZone,
        },
        {
          component: 'ErrorMessageFormComponent',
          name: 'timeZoneError',
          type: FORM_WARNING,
          failForm: true,
          extraClassNames: 'text-underline cursorPointer',
          linkAction: () => sqWorkbenchActions.setUserProfileDisplay(true),
          includeIf: _.isUndefined(selectedTimezone) || _.isEmpty(selectedTimezone),
          value: 'PERIODIC_CONDITION.TIME_ZONE_WARNING_LINK',
        },
      ],
    },
    {
      component: 'AdvancedFormGroup',
      name: 'advancedParametersFormGroup',
      toolName: isReport ? 'Topic' : TREND_TOOLS.PERIODIC_CONDITION,
      toolId: TREND_TOOLS.PERIODIC_CONDITION,
      toolStore: sqPeriodicConditionStore,
      components: [
        {
          component: 'FormControlFormComponent',
          name: 'reportName',
          label: 'NAME',
          includeIf: isReport,
          displayNumber: true,
          value: name,
          size: 'lg',
          testId: 'reportName',
          onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.PERIODIC_CONDITION, name),
        },
        {
          component: 'ValueWithUnitsFormComponent',
          name: 'offsetValueWithUnits',
          displayNumber: true,
          value: offset,
          insideModal: isReport,
          min: 0,
          validation: (value) => !_.trim(value?.value?.toString()),
          onChange: setOffset,
          availableUnits: DURATION_TIME_UNITS_ALL,
          label: 'PERIODIC_CONDITION.CAPSULE_OFFSET',
          testId: 'offset',
        },
      ],
    },
    {
      component: 'ErrorMessageFormComponent',
      name: 'apiError',
      includeIf: apiErrorMessage !== '',
      value: apiErrorMessage,
      type: FORM_ERROR,
      title: 'PERIODIC_CONDITION.FAILURE',
      dismissible: true,
      failForm: false,
      onClose: () => setApiErrorMessage(''),
    },
  ];

  const periodicConditionBuilder = (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={execute}
      closeFn={sqInvestigateActions.close}
      toolId={TREND_TOOLS.PERIODIC_CONDITION}
      submitBtnId="executePeriodicCondition"
      hideCancel={isReport}
      submitBtnLabel={isReport ? 'CONTINUE' : 'EXECUTE'}
    />
  );

  return displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT ? periodicConditionBuilder : null;
};

export const sqPeriodicCondition = angularComponent(periodicConditionBindings, PeriodicCondition);
