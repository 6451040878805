// @ts-strict-ignore
import _ from 'lodash';
import { BaseToolStoreService } from '@/hybrid/toolSelection/baseToolStore.service';
import { getCapsuleFormula } from '@/hybrid/datetime/dateTime.utilities';
import { toNumber } from '@/hybrid/utilities/numberHelper.utilities';
import { ValueWithUnitsItem } from '@/hybrid/trend/ValueWithUnits.atom';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { EMPTY_XY_REGION, ScatterPlotSignal, XYPlotRegion } from '@/scatterPlot/scatterPlot.constants';
import { FrontendDuration } from '@/services/systemConfiguration.constants';

export type ScatterConditionStore = ReturnType<typeof sqScatterConditionStore>['exports'];

export const DEFAULT_DURATION: FrontendDuration = { value: 0, units: 'min' };

export function sqScatterConditionStore(sqBaseToolStore: BaseToolStoreService) {
  const store = {
    initialize() {
      this.state = this.immutable(
        _.assign({}, sqBaseToolStore.COMMON_PROPS, {
          isBounding: false,
          isCleansing: false,
          minDuration: DEFAULT_DURATION,
          mergeDuration: DEFAULT_DURATION,
        }),
      );
    },

    exports: {
      get isBounding() {
        return this.state.get('isBounding');
      },

      get isCleansing() {
        return this.state.get('isCleansing');
      },

      get minDuration() {
        return this.state.get('minDuration');
      },

      get mergeDuration() {
        return this.state.get('mergeDuration');
      },

      getSelectedRegionFromFormula(
        formula: string,
        parameters: { name: string; item: { id: string } }[],
      ): XYPlotRegion {
        const xMin = toNumber(_.get(/(\$xSignal > (-?[0-9]+([,.]?)[0-9]*))/.exec(formula), '2'));
        const xMax = toNumber(_.get(/(\$xSignal < (-?[0-9]+([,.]?)[0-9]*))/.exec(formula), '2'));
        const yMin = toNumber(_.get(/(\$ySignal > (-?[0-9]+([,.]?)[0-9]*))/.exec(formula), '2'));
        const yMax = toNumber(_.get(/(\$ySignal < (-?[0-9]+([,.]?)[0-9]*))/.exec(formula), '2'));

        const parseForMerge = /merge\(([0-9]+([,.]?)[0-9]*)([a-z]+)/.exec(formula);
        const maybeMergeNumber = _.get(parseForMerge, '1') ? toNumber(_.get(parseForMerge, '1')) : null;
        const maybeMergeUnit = _.get(parseForMerge, '3');

        if (maybeMergeNumber && maybeMergeUnit) {
          this.setMergeDuration({
            mergeDuration: { value: maybeMergeNumber, units: maybeMergeUnit },
          });
          this.setCleansing({ isCleansing: true });
        }

        const parseForMin = /removeShorterThan\(([0-9]+([,.]?)[0-9]*)([a-z]+)/.exec(formula);
        const maybeMinNumber = _.get(parseForMin, '1') ? toNumber(_.get(parseForMin, '1')) : null;
        const maybeMinUnit = _.get(parseForMin, '3');

        if (maybeMinNumber && maybeMinUnit) {
          this.setMinDuration({
            minDuration: { value: maybeMinNumber, units: maybeMinUnit },
          });
          this.setCleansing({ isCleansing: true });
        }

        const yId = _.find(parameters, (parameter) => parameter.name === 'ySignal').item.id;

        return {
          x: { min: xMin, max: xMax },
          ys: { [yId]: { min: yMin, max: yMax } },
        };
      },

      /**
       * Create a condition formula representing a region on the scatter plot.
       * The basic formula consists of two intersecting value searches.
       *
       * @param region - region on the scatter plot to search within
       * @param xSeries - signal on the x-axis of the scatter plot
       * @param ySeries - signal on the y-axis of the scatter plot
       * @param isBounding - whether or not to only create capsules within the display range
       * @param {moment} displayRange - time range displayed on the scatter plot. Used if isBounding is true.
       * @param isCleansing
       * @param minDuration
       * @param mergeDuration
       */
      getFormulaAndParameters(
        region: XYPlotRegion,
        xSeries: ScatterPlotSignal,
        ySeries: ScatterPlotSignal,
        isBounding: boolean,
        displayRange,
        isCleansing: boolean,
        minDuration: ValueWithUnitsItem,
        mergeDuration: ValueWithUnitsItem,
      ) {
        if (!xSeries || !ySeries) {
          throw new Error('Expected series to be present on both axes of the scatter plot.');
        }

        if (!region || _.isEqual(region, EMPTY_XY_REGION)) {
          throw new Error('Expected a region to be selected on the scatter plot, but found no selected region.');
        }

        const y =
          region.ys[ySeries.id] ??
          // TODO CRAB-27993: Should always have the correct ID here
          _.chain(region.ys).toArray().head().value();
        if (region.x.max < region.x.min || y.max < y.min) {
          throw new Error('Expected maximum value to be greater than minimum value.');
        }

        const formulaParameters = {
          xSignal: xSeries.id,
          ySignal: ySeries.id,
        };
        const xSignalFormula = '$xSignal';
        const ySignalFormula = '$ySignal';

        let formula =
          `(${xSignalFormula} > ${region.x.min} ` +
          `\nand ${xSignalFormula} < ${region.x.max})` +
          `\nand (${ySignalFormula} > ${y.min} ` +
          `\nand ${ySignalFormula} < ${y.max})`;

        if (isBounding) {
          const capsuleFormula = getCapsuleFormula(displayRange);
          const conditionFormula = `condition(${displayRange.duration.asMilliseconds()} ms, ${capsuleFormula})`;
          formula = `(${formula})\nand ${conditionFormula}`;
        }

        if (isCleansing) {
          formula = `(${formula})`;
          formula += mergeDuration.value > 0 ? `\n.merge(${mergeDuration.value}${mergeDuration.units})` : '';
          formula += minDuration.value > 0 ? `\n.removeShorterThan(${minDuration.value}${minDuration.units})` : '';
        }
        return { formula, formulaParameters };
      },
    },

    /**
     * Exports state so it can be used to re-create the state later using `rehydrate`.
     *
     * @return {Object} State for the store
     */
    dehydrate() {
      return this.state.serialize();
    },

    /**
     * Sets the references panel state
     *
     * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
     */
    rehydrate(dehydratedState) {
      this.state.merge(dehydratedState);
    },

    handlers: {
      SCATTER_CONDITION_SET_BOUNDING: 'setBounding',
      SCATTER_CONDITION_SET_CLEANSING: 'setCleansing',
      SCATTER_CONDITION_SET_MIN_DURATION: 'setMinDuration',
      SCATTER_CONDITION_SET_MERGE_DURATION: 'setMergeDuration',
    },

    /**
     * Set whether or not we want to create capsules only within the display range
     *
     * @param payload - Object container for arguments
     * @param payload.isBounding - whether to limit the condition to within the display range
     */
    setBounding(payload: { isBounding: boolean }) {
      this.state.set('isBounding', payload.isBounding);
    },

    /**
     * Set whether or not we want to ignore short capsules/gaps
     *
     * @param payload - Object container for arguments
     * @param payload.isCleansing - whether to ignore short capsules/gaps
     */
    setCleansing(payload: { isCleansing: boolean }) {
      this.state.set('isCleansing', payload.isCleansing);
    },

    /**
     * Set the minimum capsule duration to allow
     *
     * @param payload - Object container for arguments
     * @param payload.minDuration - minimum allowable capsule duration
     */
    setMinDuration(payload: { minDuration: FrontendDuration }) {
      this.state.set('minDuration', payload.minDuration);
    },

    /**
     * Set the maximum gap duration over which to merge capsules
     *
     * @param payload - Object container for arguments
     * @param payload.mergeDuration - maximum duration to merge capsules over a gap
     */
    setMergeDuration(payload: { mergeDuration: FrontendDuration }) {
      this.state.set('mergeDuration', payload.mergeDuration);
    },
  };

  return sqBaseToolStore.extend(store, TREND_TOOLS.SCATTER_CONDITION);
}
