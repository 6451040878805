import angular from 'angular';
import { RequestsTabUtilities } from '@/hybrid/administration/requestsTab.utilities';
import { AdministrationUtilities } from '@/hybrid/administration/administration.utilities';

const dependencies = ['Sq.Workbench', 'Sq.Administration.Datasources', 'Sq.Administration.Agents'];

/**
 * The Sq.Administration module contains all functionality related to the administration panel
 */
angular
  .module('Sq.Administration', dependencies)
  .service('sqRequestsTabUtilities', RequestsTabUtilities)
  .service('sqAdministrationUtilities', AdministrationUtilities);
