// @ts-strict-ignore
import React, { useEffect, useReducer, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from 'react-i18next';

import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { ReportActions } from '@/reportEditor/report.actions';
import { getMoment } from '@/hybrid/datetime/dateTime.utilities';
import { DurationActions } from '@/trendData/duration.actions';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import {
  calculateCapsule,
  calculateSelectedCondition,
  canSave,
  findMaximumDurationForCondition,
  formatCapsuleForDisplay,
  save,
  updateDisplayRange,
} from './ReportDateModal.utilities';
import { DateRangeChooseCapsuleModal } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/components/DateRangeChooseCapsuleModal.molecule';
import { FullDateRangeModal } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/components/FullDateRangeModal.molecule';
import { IPromise } from 'angular';
import { validateGuid } from '@/hybrid/utilities/utilities';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { CAPSULE_SELECTION, DateRange, DEFAULT_DATE_RANGE, OFFSET_DIRECTION } from '@/reportEditor/report.constants';
import { sqDurationStore, sqReportStore, sqWorksheetStore } from '@/core/core.stores';
import { getDefaultMaxCapsuleDuration } from '@/services/systemConfiguration.utilities';
import { Item } from '@/hybrid/tools/histogram/aggregationBin.store';
import { quartzCronExpressionHelper } from '@/hybrid/utilities/froalaReportContent.utilities';
import { BulkEditMode } from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/reportContent.constants';

const TRANSLATIONS_MAP = {
  [OFFSET_DIRECTION.PAST]: 'REPORT.CONFIG.IN_THE_PAST',
  [OFFSET_DIRECTION.FUTURE]: 'REPORT.CONFIG.IN_THE_FUTURE',
  [CAPSULE_SELECTION.STRATEGY.CLOSEST_TO]: 'REPORT.CONFIG.CLOSEST_TO',
  [CAPSULE_SELECTION.STRATEGY.OFFSET_BY]: 'REPORT.CONFIG.OFFSET_BY',
  [CAPSULE_SELECTION.REFERENCE.START]: 'START',
  [CAPSULE_SELECTION.REFERENCE.END]: 'END',
};

export const sharedReportDateModalBindings = bindingsDefinition({
  onCloseModal: prop<Function>(),
  setNewDateRange: prop<(partialDateRange) => void>(),
  newDateRange: prop<DateRange>(),
  dateRangeRate: prop<any>(),
  hasMultipleSchedules: prop<boolean>(),
  displayRange: prop<any>(),
  computedCapsuleDisplay: prop<any>(),
  selectedCondition: prop<any>(),
  setSelectedCondition: prop<(partialCondition) => void>(),
  isSelectingRelative: prop<boolean>(),
  clearCapsuleDisplay: prop<() => any>(),
  isConditionSelected: prop<boolean>(),
  isMaximumDurationRequiredForItem: prop<boolean>(),
  updateSelectedCondition: prop<(condition) => IPromise<any>>(),
  updateDateRangeFromCapsule:
    prop<(capsule: { start; end }, columns: string[], sortBy: string, sortAsc: boolean) => any>(),
  capsuleCountData: prop<any>(),
  computedCapsuleProperties: prop<any>(),
  capsuleIsLoading: prop<boolean>(),
  canSave: prop<() => boolean>(),
  onSave: prop<() => any>(),
});

const reportDateModalBindings = bindingsDefinition({
  sqReportActions: injected<ReportActions>(),
  sqDurationActions: injected<DurationActions>(),
  onCloseModal: prop<() => any>(),
  selectedDateRange: prop<any>(),
  isCapsuleSelectOnly: prop.optional<boolean>(),
});

export const ReportDateModal: SeeqComponent<typeof reportDateModalBindings> = ({
  onCloseModal,
  selectedDateRange,
  isCapsuleSelectOnly = false,
}) => {
  const { sqReportActions, sqDurationActions } = useInjectedBindings(reportDateModalBindings);

  const { t } = useTranslation();

  const [dateRangeIsSaving, setDateRangeIsSaving] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [capsule, setCapsule] = useState({ start: null, end: null });
  const [computedCapsuleDisplay, setComputedCapsuleDisplay] = useState(undefined);
  const [computedCapsuleProperties, setComputedCapsuleProperties] = useState(undefined);
  const [isSelectingRelative, setIsSelectingRelative] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [durationOffsetValid, setDurationOffsetValid] = useState(true);
  const [capsuleIsLoading, setCapsuleIsLoading] = useState(false);
  const [dateRangeRate, setDateRangeRate] = useState(undefined);
  const [capsuleCountData, setCapsuleCountData] = useState({
    capsuleCount: undefined,
    capsuleCountLeft: undefined,
    capsuleCountRight: undefined,
  });
  const [selectedCondition, setSelectedCondition] = useReducer(
    (state, newState) => (!newState ? undefined : { ..._.merge(state, newState) }),
    {},
  );

  const [newDateRange, setNewDateRange] = useReducer(
    (state, newState) => {
      return { ..._.merge(state, newState) };
    },
    {
      name: '',
      ..._.cloneDeep(DEFAULT_DATE_RANGE),
    },
  );

  const isConditionSelected = validateGuid(selectedCondition?.id);

  const displayRange = useFluxPath(sqDurationStore, () => sqDurationStore.displayRange);
  const timezone = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone);
  const documentHasContent = useFluxPath(sqReportStore, () => sqReportStore.contentNotArchived.length > 0);

  const { reportSchedule, hasMultipleSchedules, hasReportSchedule } = useFlux(sqReportStore);

  useEffect(() => {
    if (selectedDateRange) {
      setNewDateRange(_.cloneDeep(selectedDateRange));
    }
    if (selectedDateRange?.id) {
      setIsNew(false);
      setSelectedCondition(selectedDateRange.condition);
      setCapsule({
        start: selectedDateRange.range.start,
        end: selectedDateRange.range.end,
      });
      if (selectedDateRange.condition.id) {
        findMaximumDurationForCondition(selectedDateRange.condition.id).then((duration) => {
          setSelectedCondition({
            maximumDuration: duration ?? getDefaultMaxCapsuleDuration(),
            hasMaximumDuration: !!duration,
          });
        });
      } else {
        setSelectedCondition(selectedDateRange.condition);
      }
    } else {
      setIsNew(true);
      sqDurationActions.displayRange.resetTimes();

      const cronSchedule = selectedDateRange?.auto?.cronSchedule;
      const dateRangeRate = quartzCronExpressionHelper().cronScheduleToRate(cronSchedule);

      setDateRangeRate(dateRangeRate);
      updateDisplayRange(newDateRange, sqDurationActions);
    }

    if (newDateRange?.auto?.enabled) {
      computeCapsule();
    }
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if (newDateRange?.id && newDateRange?.auto?.offsetDirection) {
      updateDisplayRange(newDateRange, sqDurationActions);
    }
  }, [newDateRange?.id, newDateRange?.auto?.offsetDirection, newDateRange?.range?.start, newDateRange?.range?.end]);

  useEffect(() => {
    if (newDateRange?.auto?.enabled || isSelectingRelative) {
      computeCapsule();
    }
  }, [
    isConditionSelected,
    newDateRange?.condition?.strategy,
    newDateRange?.condition?.reference,
    newDateRange?.condition?.offset,
    newDateRange?.auto,
    isSelectingRelative,
    selectedCondition?.maximumDuration?.units,
    selectedCondition?.id,
    displayRange.start.valueOf(),
    displayRange.end.valueOf(),
  ]);

  useEffect(() => {
    if (displayRange && newDateRange && !newDateRange.auto.enabled && isInitialized) {
      let clonedNewDateRange = {};
      const start = displayRange?.start?.valueOf();
      const end = displayRange?.end?.valueOf();

      if (!newDateRange?.condition?.id) {
        clonedNewDateRange = {
          range: {
            start,
            end,
          },
          auto: {
            duration: end - start,
          },
        };
      }
      clonedNewDateRange = {
        ...clonedNewDateRange,
        condition: {
          range: {
            start,
            end,
          },
        },
      };

      setNewDateRange(clonedNewDateRange);
    }
  }, [displayRange.start.valueOf(), displayRange.end.valueOf()]);

  useEffect(() => {
    if (_.isUndefined(newDateRange?.condition?.isCapsuleFromTable)) {
      setIsSelectingRelative(false);
    } else {
      setIsSelectingRelative(!newDateRange?.condition?.isCapsuleFromTable);
    }
  }, [newDateRange?.condition?.isCapsuleFromTable]);

  const offsetOptions = _.map(OFFSET_DIRECTION, (direction) => ({
    id: direction,
    value: direction,
    label: t(TRANSLATIONS_MAP[direction]),
  }));

  /**
   * Reset the date range to default values, preserving name/id/description.
   */
  const onReset = () => {
    setNewDateRange({
      ..._.cloneDeep(selectedDateRange),
      ..._.cloneDeep(DEFAULT_DATE_RANGE),
      id: selectedDateRange?.condition?.id,
      name: selectedDateRange.name,
      description: selectedDateRange.description,
    });

    setNewDateRange(updateDisplayRange(newDateRange, sqDurationActions));
  };

  /**
   * Determines the capsule that defines the auto update time range given the user selected condition and offset
   * parameters. Updates the capsule display if a capsule is found, clears it otherwise.
   */
  const computeCapsule = () => {
    if (isConditionSelected) {
      // Show spinner if capsule loading takes longer than a second
      let count = 0;
      const loadingDelay = setInterval(() => {
        if (!count) {
          setCapsuleIsLoading(true);
          ++count;
        } else {
          clearInterval(loadingDelay);
        }
      }, 1000);

      return calculateCapsule(displayRange, selectedCondition, newDateRange, sqReportActions)
        .then((result) => {
          const start = result.capsule.start / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND;
          const end = result.capsule.end / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND;
          setComputedCapsuleDisplay(formatCapsuleForDisplay(start, end, timezone));
          setComputedCapsuleProperties(result.capsule.properties);
          if (start !== capsule.start || end !== capsule.end) {
            setCapsule({ start, end });
          }

          setCapsuleCountData({
            capsuleCount: result.capsuleCount,
            capsuleCountLeft: result.capsuleCountLeft,
            capsuleCountRight: result.capsuleCountRight,
          });
        })
        .catch(clearCapsuleDisplay)
        .finally(() => {
          clearInterval(loadingDelay);
          setCapsuleIsLoading(false);
        });
    }
    clearCapsuleDisplay();
    return Promise.resolve();
  };

  const onOffsetDirectionChange = ({ value }) => {
    setNewDateRange({
      auto: {
        offsetDirection: value,
      },
    });
  };

  const setDurationOffset = (durationOffset) => {
    const { propName, ...offset } = durationOffset;
    setNewDateRange({
      auto: {
        offset,
      },
    });

    setDurationOffsetValid(durationOffset.valid);
    setNewDateRange(updateDisplayRange(newDateRange, sqDurationActions));
  };

  /**
   * Clears capsule display properties on the view model
   */
  const clearCapsuleDisplay = () => {
    setCapsuleCountData({
      capsuleCount: undefined,
      capsuleCountLeft: undefined,
      capsuleCountRight: undefined,
    });

    setComputedCapsuleDisplay(undefined);

    setNewDateRange({
      capsule: undefined,
    });
  };

  const updateSelectedCondition = (item: Item): IPromise<any> => {
    return findMaximumDurationForCondition(item.id).then((duration) => {
      const conditionWithDuration = calculateSelectedCondition(item, selectedCondition, newDateRange, duration);
      if (!conditionWithDuration) {
        return;
      }

      setNewDateRange({
        condition: {
          ...conditionWithDuration,
          columns: undefined,
          sortAsc: undefined,
          sortBy: undefined,
        },
      });

      setSelectedCondition(conditionWithDuration);
    });
  };

  /**
   * Determines if the maximum capsule duration input field should be displayed for the specified item. It is only
   * required if item is an unbounded condition and dateRange is using relative capsule selection.
   */
  const isMaximumDurationRequiredForItem = (): boolean => isSelectingRelative && !selectedCondition?.hasMaximumDuration;

  /**
   * Sets dateRange values based on a capsule picked from capsuleTable saves table columns and sorting to restore for
   * editing the date range
   */
  const updateDateRangeFromCapsule = (capsule: { start; end }, columns: string[], sortBy: string, sortAsc: boolean) => {
    const start = getMoment(capsule.start).valueOf();
    const end = getMoment(capsule.end).valueOf();
    setCapsule({ start, end });
    setNewDateRange({
      condition: {
        columns,
        sortBy,
        sortAsc,
      },
    });
  };

  const shouldShowBulkEditModal = isNew && documentHasContent;

  /**
   * Saves the dateRange, saves the report, and closes the modal
   */
  const onSave = () => {
    setDateRangeIsSaving(true);
    if (shouldShowBulkEditModal) {
      sqReportActions.setBulkEditDisplayMode(BulkEditMode.DATE_RANGE);
    }
    if (newDateRange.condition?.id) {
      newDateRange.range.start = capsule.start;
      newDateRange.range.end = capsule.end;
      newDateRange.condition.range.start = displayRange?.start.valueOf();
      newDateRange.condition.range.end = displayRange?.end.valueOf();
      newDateRange.auto.duration = displayRange.duration.valueOf();
    }

    save(
      newDateRange,
      isSelectingRelative,
      hasReportSchedule,
      hasMultipleSchedules,
      reportSchedule,
      shouldShowBulkEditModal,
      sqReportActions,
    )
      .then(onCloseModal)
      .catch((error) => errorToast({ httpResponseOrError: error, displayForbidden: true }))
      .finally(() => setDateRangeIsSaving(false));
  };

  const canSaveRange = () =>
    canSave(
      newDateRange,
      dateRangeIsSaving,
      isConditionSelected,
      isSelectingRelative,
      selectedCondition?.maximumDuration?.value > 0,
      isMaximumDurationRequiredForItem(),
      computedCapsuleDisplay,
      durationOffsetValid,
    );

  const sharedModalParams = {
    onCloseModal,
    dateRangeRate,
    setNewDateRange,
    newDateRange,
    displayRange,
    hasMultipleSchedules,
    computedCapsuleDisplay,
    updateSelectedCondition,
    canSave: canSaveRange,
    onSave,
    selectedCondition,
    isMaximumDurationRequiredForItem: isMaximumDurationRequiredForItem(),
    updateDateRangeFromCapsule,
    capsuleCountData,
    capsuleIsLoading,
    computedCapsuleProperties,
    clearCapsuleDisplay,
    isSelectingRelative,
    setSelectedCondition,
    isConditionSelected,
  };

  return isCapsuleSelectOnly ? (
    <DateRangeChooseCapsuleModal {...sharedModalParams} />
  ) : (
    <FullDateRangeModal
      {...sharedModalParams}
      hasReportSchedule={hasReportSchedule}
      isConditionSelected={isConditionSelected}
      setIsSelectingRelative={setIsSelectingRelative}
      onOffsetDirectionChange={onOffsetDirectionChange}
      offsetOptions={offsetOptions}
      setDurationOffset={setDurationOffset}
      setCapsuleIsLoading={setCapsuleIsLoading}
      documentHasContent={documentHasContent}
      onReset={onReset}
      isNew={isNew}
    />
  );
};

export const sqReportDateModal = angularComponent(reportDateModalBindings, ReportDateModal);
