import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import classNames from 'classnames';
import { HelpIcon } from '@/hybrid/core/Icon.atom';

export interface LabelIF extends ValidatingFormComponent<string> {
  component: 'LabelFormComponent';
  noMarginBottom?: boolean;
  onIconClick?: () => any;
  valueCustomParams?: Record<string, string | number>;
}

const LabelFormComponentUnwrapped: React.FunctionComponent<LabelIF> = ({
  value,
  tooltip,
  onIconClick,
  testId,
  extraClassNames,
  noMarginBottom,
  tooltipDelay,
  valueCustomParams,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('flexColumnContainer', extraClassNames, {
        mb5: !noMarginBottom,
      })}
      data-testid={testId}>
      {t(value, valueCustomParams)}
      {tooltip && (
        <HelpIcon
          tooltip={tooltip}
          tooltipDelay={tooltipDelay}
          extraClassNames="pl5"
          testId={`${testId}_icon`}
          onClick={_.isFunction(onIconClick) ? onIconClick : _.noop}
        />
      )}
    </div>
  );
};

export const LabelFormComponent = React.memo(
  LabelFormComponentUnwrapped,
  (prev, next) =>
    !(prev.value !== next.value || prev.tooltip !== next.tooltip || next.extraClassNames !== prev.extraClassNames),
);
