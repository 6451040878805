/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 58.8.10-v202403072038
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * A list of reports with statistical information added
 */
export interface ReportAdminOutputV1 {
    /**
     * Average run time across all content in the report
     */
    "averageRunTime"?: number;
    /**
     * The number of non-archived pieces of content in this report
     */
    "contentCount"?: number;
    /**
     * The document's ID
     */
    "documentId"?: string;
    /**
     * The name of the document the report is in
     */
    "documentName"?: string;
    /**
     * The id of the report
     */
    "id"?: string;
    /**
     * The last time the report ran
     */
    "lastRunTime"?: string;
    /**
     * The last time the report was updated
     */
    "lastUpdated"?: string;
    /**
     * The last time the report was looked at
     */
    "lastViewedAt"?: string;
    /**
     * The next time the report will run
     */
    "nextRunTime"?: string;
    /**
     * The owner of the report
     */
    "owner"?: string;
    /**
     * The name of the report
     */
    "reportName"?: string;
    /**
     * The type of the report. One of Scheduled, Live, Static
     */
    "reportType"?: ReportTypeEnum;
    /**
     * A human readable representation of the cron schedules the report uses
     */
    "schedules"?: Array<string>;
    /**
     * A plain language status message with information about any issues that may have been encountered during an operation. Null if the status message has not been set.
     */
    "statusMessage"?: string;
    /**
     * The topic's ID
     */
    "topicId"?: string;
    /**
     * The name of the topic the report is under
     */
    "topicName"?: string;
    /**
     * Sum of the run times of all content in the report
     */
    "totalRunTime"?: number;
}

export enum ReportTypeEnum {
    Scheduled = 'Scheduled' as any,
    Live = 'Live' as any,
    Static = 'Static' as any
}
