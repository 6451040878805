// @ts-strict-ignore
import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { LegendWrapper } from '@/hybrid/trend/LegendWrapper.atom';
import { Icon } from '@/hybrid/core/Icon.atom';

const rainbowLegendItemsBindings = bindingsDefinition({
  getCapsuleName: prop<(any) => string>(),
  capsules: prop<any[]>(),
  title: prop<string>(),
});

export const RainbowLegendItems: SeeqComponent<typeof rainbowLegendItemsBindings> = (props) => {
  const { getCapsuleName, capsules, title } = props;

  return (
    <LegendWrapper title={title}>
      {capsules.map((capsule) => (
        <div className="mr10 text-nowrap" key={capsule.id}>
          <Icon icon="fc-series" extraClassNames="pr5" type="color" color={capsule.childColor} />
          {getCapsuleName(capsule)}
        </div>
      ))}
    </LegendWrapper>
  );
};
