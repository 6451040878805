import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import utilities from './utilities';
import { successToast } from '@/hybrid/utilities/toast.utilities';

export type DisplayType = 'button' | 'icon';

interface CopyButtonGivenTextProps {
  id?: string;
  tooltip: string;
  textToCopy: string;
  // display as an icon or as a button
  asType?: DisplayType;
  // icon classname to display, default is fa-copy
  iconClass?: string;
  testId?: string;
  // notification message sent to user
  notifyMessage?: string;
  // object with translation params for notifications
  notifyMessageParams?: Object;
  extraClassNames?: string;
  // HoverTooltip requires containing div, set its class here
  containingClassNames?: string;
  // Optional function to execute once copy is complete. Default is noop
  onCopyFinish?: () => void;
  // Defaults to false
  disableButton?: boolean;
}

/**
 * Button to place specified text on the clipboard
 */
export const CopyButtonGivenText: React.FunctionComponent<CopyButtonGivenTextProps> = ({
  id,
  tooltip,
  textToCopy,
  asType = 'button',
  notifyMessage: messageKey,
  notifyMessageParams: messageParams,
  testId,
  iconClass = 'fa-copy',
  extraClassNames,
  containingClassNames,
  onCopyFinish = _.noop,
  disableButton = false,
}) => {
  const copyToClipboard = () => {
    utilities.copyTextToClipboard(textToCopy);

    if (!_.isNil(messageKey)) {
      successToast({ messageKey, messageParams });
    }
    onCopyFinish();
  };

  const renderButton = (
    <button disabled={disableButton} data-testid={testId} className={extraClassNames} onClick={copyToClipboard}>
      <Icon icon={iconClass} id={id} />
    </button>
  );

  const renderIcon = (
    <div data-testid={testId} className={containingClassNames}>
      <Icon
        icon={iconClass}
        extraClassNames={classNames('cursorPointer', extraClassNames)}
        onClick={copyToClipboard}
        id={id}
      />
    </div>
  );

  const render = asType === 'button' ? renderButton : renderIcon;

  return <HoverTooltip text={tooltip}>{render}</HoverTooltip>;
};
