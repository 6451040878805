// @ts-strict-ignore
import _ from 'lodash';
import angular from 'angular';
import jQuery from 'jquery';
import 'moment/min/locales.js';
import moment from 'moment';

const dependencies = [];

export let BROWSER_LOCALE: string;
export let BROWSER_LANG: string;

// We do not want jQuery falling back to using eval() when inserting HTML as that opens us up to all sorts of XSS
// vulnerabilities. See CRAB-22878 for more info.
jQuery.globalEval = _.noop;

angular
  .module('Sq.Core.Configuration', dependencies)
  // This will likely need to be somewhere configurable eventually
  .constant('BROWSER_LOCALE', _.head(window.navigator.languages) || window.navigator.language)
  .config(function (
    $provide: ng.auto.IProvideService,
    $compileProvider: ng.ICompileProvider,
    $locationProvider: ng.ILocationProvider,
    $httpProvider: ng.IHttpProvider,
    $animateProvider,
    BROWSER_LOCALE,
  ) {
    // For now we always want to use the browser's locale for moment's formatting
    moment.locale(BROWSER_LOCALE);

    // For now we use simplified language files, but at some point we may want a language file per variant
    const BROWSER_LANG = BROWSER_LOCALE.split('-')[0];
    $provide.constant('BROWSER_LANG', BROWSER_LANG);

    // Disable Angular debug data when in production mode
    if (process.env.NODE_ENV === 'production') {
      $compileProvider.debugInfoEnabled(false);
    }
    $compileProvider.preAssignBindingsEnabled(true);

    $locationProvider.html5Mode(true);

    // Allow selective disabling of animation via .ng-animate-disabled class and that spinning elements hide quickly
    // (http://stackoverflow.com/questions/24617821/stop-angular-animation-from-happening-on-ng-show-ng-hide)
    $animateProvider.classNameFilter(/^(?:(?!(ng-animate-disabled|fa-spin)).)*$/);

    // Ensure that defaults.transformResponse is an array so that we can always concat to it
    if (!_.isArray($httpProvider.defaults.transformResponse)) {
      $httpProvider.defaults.transformResponse = [];
    }

    $httpProvider.useApplyAsync(process.env.NODE_ENV !== 'test');
  })
  .run(($injector) => {
    BROWSER_LOCALE = $injector.get('BROWSER_LOCALE');
    BROWSER_LANG = $injector.get('BROWSER_LANG');
  });
