// @ts-strict-ignore
import React from 'react';
import { CELL_TYPES, TableColumn } from '@/hybrid/core/Table.atom';
import { AdminTableWrapper } from '@/hybrid/core/AdminTableWrapper.molecule';
import { useTranslation } from 'react-i18next';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { AgentStatusOutputV1 } from '@/sdk';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

interface AgentsTableProps {
  setSelectedAgents: (AgentStatusOutputV1) => void;
  selectedAgents: AgentStatusOutputV1[];
  agents: AgentStatusOutputV1[];
}

export const AgentsTable: React.FunctionComponent<AgentsTableProps> = (props) => {
  const { t } = useTranslation();
  const { setSelectedAgents, selectedAgents, agents } = props;

  const connectionStatusFilterOptions = [
    {
      value: SeeqNames.Connectors.Connections.Status.Connecting,
      text: 'ADMIN.DATASOURCES.FILTER.STATUS_CONNECTING',
    },
    {
      value: SeeqNames.Connectors.Connections.Status.Connected,
      text: 'ADMIN.DATASOURCES.FILTER.STATUS_CONNECTED',
    },
    {
      value: SeeqNames.Connectors.Connections.Status.Disconnected,
      text: 'ADMIN.DATASOURCES.FILTER.STATUS_DISCONNECTED',
    },
  ];

  const renderTooltip = (agent) => {
    return (
      <HoverTooltip placement="top" text={agent.remoteUpdateError}>
        <span>{agent.remoteUpdateStatus}</span>
      </HoverTooltip>
    );
  };

  const columns: TableColumn[] = [
    {
      accessor: 'id',
      searchProperty: 'id',
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40 },
      sortable: false,
      filterable: false,
    },
    {
      accessor: 'name',
      searchProperty: 'name',
      header: 'ADMIN.AGENTS.NAME',
      cellStyle: { minWidth: 120, wordWrap: 'break-word' },
      sortable: true,
      filterable: true,
    },
    {
      accessor: 'status',
      searchProperty: 'status',
      header: 'ADMIN.AGENTS.CONNECTION_STATUS',
      cellType: CELL_TYPES.DROPDOWN,
      dropdownOptions: connectionStatusFilterOptions,
      sortable: true,
      filterable: true,
    },
    {
      accessor: 'version',
      searchProperty: 'version',
      header: 'ADMIN.AGENTS.VERSION',
      cellStyle: { wordWrap: 'break-word' },
      sortable: true,
      filterable: true,
    },
    {
      accessor: 'remoteUpdateStatus',
      searchProperty: 'remoteUpdateStatus',
      header: 'ADMIN.AGENTS.VERSION_UPDATE_STATUS',
      cellStyle: { wordWrap: 'break-word' },
      sortable: true,
      filterable: true,
      cellRenderFunction: renderTooltip,
    },
  ];

  return (
    <AdminTableWrapper
      testId="AgentsAdministrationTable"
      columns={columns}
      items={agents}
      defaultSort={{ property: 'status', asc: true }}
      selectedItems={selectedAgents}
      setSelectedItems={setSelectedAgents}
      noItemsComponent={<span>{t('ADMIN.AGENTS.NO_AGENTS_FOUND')}</span>}
    />
  );
};
