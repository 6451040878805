import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { ToolbarButton } from '@/hybrid/core/ToolbarButton.molecule';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { TrendActions } from '@/trendData/trend.actions';
import { ToolbarDropdownButton } from '@/hybrid/core/ToolbarDropdownButton.molecule';
import { CapsuleTimeColorModeButton } from '@/hybrid/trend/CapsuleTimeColorModeButton.molecule';
import { SummarizeData } from '@/hybrid/trend/toolbar/SummarizeData.organism';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { WorksheetViewSelector } from '@/hybrid/trend/WorksheetViewSelector.molecule';
import { AnnotateButton } from '@/hybrid/trend/toolbar/AnnotateButton.molecule';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { LabelsPopover } from '@/hybrid/trend/toolbar/LabelsPopover.organism';
import { CompareViewToolbar } from '@/hybrid/trend/toolbar/CompareViewToolbar.organism';
import { HelpButton } from '@/hybrid/core/HelpButton.atom';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { useTranslation } from 'react-i18next';
import { sqInvestigateStore, sqTrendStore, sqWorksheetStore } from '@/core/core.stores';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { CompareViewColorModeButton } from '@/hybrid/trend/CompareViewColorModeButton.molecule';
import { TREND_VIEWS } from '@/trendData/trendData.constants';
import { doTrack } from '@/track/track.service';
import { useDidUpdate } from 'rooks';
import { oneLane, oneYAxis, resetAllAxes } from '@/trendData/yAxis.actions';

const trendToolbarBindings = bindingsDefinition({
  isSmall: prop<boolean>(),
  sqTrendActions: injected<TrendActions>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
});

export const TrendToolbar: SeeqComponent<typeof trendToolbarBindings> = ({ isSmall }) => {
  const { sqTrendActions, sqWorksheetActions, sqInvestigateActions } = useInjectedBindings(trendToolbarBindings);

  const { t } = useTranslation();

  const capsuleGroupMode = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.capsuleGroupMode);

  const {
    view,
    isRegionSelected,
    areCapsuleTimeOffsetsChanged,
    hideUnselectedItems,
    dimDataOutsideCapsules,
    showGridlines,
    isCompareMode,
  } = useFlux(sqTrendStore);

  const dimmingMenuChecked = dimDataOutsideCapsules || hideUnselectedItems;
  // TODO CRAB-25925 remove button hiding when signal selection will be available in group mode
  const showDimmingButton =
    (view !== TREND_VIEWS.CAPSULE && !(view === TREND_VIEWS.CHAIN && capsuleGroupMode)) || isCompareMode;
  const previousViewRef = useRef(view);

  useEffect(() => {
    // Switch dimming off when we are in grouping mode under chain view
    if (!showDimmingButton && hideUnselectedItems && view === TREND_VIEWS.CHAIN) {
      sqTrendActions.toggleHideUnselectedItems();
    }
  }, [showDimmingButton, hideUnselectedItems, view]);

  const viewButtonsConfiguration = [
    {
      id: 'calendarTimeBtn',
      active: view === TREND_VIEWS.CALENDAR,
      icon: 'fa-calendar',
      label: 'TOOLBAR.CALENDAR_TIME',
      tooltipText: 'TOOLBAR.CALENDAR_TIME_TOOLTIP',
      onClick: () => sqTrendActions.setView(TREND_VIEWS.CALENDAR),
      trackAction: 'switch to Calendar Time',
    },
    {
      id: 'chainViewBtn',
      active: view === TREND_VIEWS.CHAIN,
      icon: 'fa-link',
      label: 'TOOLBAR.CHAIN',
      tooltipText: 'TOOLBAR.CHAIN_TOOLTIP',
      onClick: () => sqTrendActions.setView(TREND_VIEWS.CHAIN),
      trackAction: 'switch to Chain View',
    },
    {
      id: 'capsuleTimeBtn',
      active: view === TREND_VIEWS.CAPSULE,
      icon: 'fa-tasks',
      label: 'TOOLBAR.CAPSULE_TIME',
      tooltipText: 'TOOLBAR.CAPSULE_TIME_TOOLTIP',
      onClick: () => sqTrendActions.setView(TREND_VIEWS.CAPSULE),
      trackAction: 'switch to Capsule Time',
    },
  ];

  const laneAndAxisButtonsConfiguration = [
    {
      id: 'yAxisAutoscale',
      icon: 'fa-exchange fa-rotate-90',
      secondIcon: 'fa-line-chart',
      label: 'TOOLBAR.AUTOSCALE_Y_AXIS',
      tooltipText: 'TOOLBAR.AUTOSCALE_Y_AXIS_TOOLTIP',
      onClick: () => oneLane(sqTrendActions),
      trackAction: 'One Lane',
    },
    {
      id: 'oneYAxis',
      icon: 'fa-exchange fa-rotate-90',
      secondIcon: 'fa-line-chart',
      label: 'TOOLBAR.ONE_Y_AXIS',
      tooltipText: 'TOOLBAR.ONE_Y_AXIS_TOOLTIP',
      onClick: () => oneYAxis(sqTrendActions),
      trackAction: 'One Y-Axis',
    },
    {
      id: 'yAxisSpreadBtn',
      icon: 'fa-expand fa-rotate-135',
      secondIcon: 'fa-line-chart',
      label: 'TOOLBAR.SPREAD_Y_AXES',
      tooltipText: 'TOOLBAR.SPREAD_Y_AXES_TOOLTIP',
      onClick: () => resetAllAxes(sqTrendActions),
      trackAction: 'Spread',
    },
  ];

  useDidUpdate(() => {
    if (
      view === TREND_VIEWS.CAPSULE &&
      previousViewRef.current === TREND_VIEWS.CALENDAR &&
      sqTrendStore.hideUnselectedItems
    ) {
      sqTrendActions.fetchTableAndChartCapsules();
    }
    previousViewRef.current = view;
  }, [view]);

  return (
    <div className="flexColumnContainer flexAlignBottom">
      <WorksheetViewSelector />
      <div className={classNames('btn-group forceFlex', { flexCenter: isSmall })}>
        {_.map(viewButtonsConfiguration, (config) => (
          <ToolbarButton
            key={config.id}
            icon={classNames(config.icon, { 'fa-lg': !isSmall })}
            label={config.label}
            active={config.active && !isCompareMode}
            isSmall={isSmall}
            id={config.id}
            testId={config.id}
            tooltipText={config.tooltipText}
            tooltipPlacement="top"
            onClick={() => {
              config.onClick();
              sqTrendActions.setIsCompareMode(false);
              doTrack('Trend', config.trackAction);
            }}
          />
        ))}

        <ToolbarButton
          icon={classNames('fc-compare', { 'fa-lg': !isSmall })}
          label="TOOLBAR.COMPARE.COMPARE"
          tooltipText="TOOLBAR.COMPARE.COMPARE_TOOLTIP"
          testId="compareBtn"
          onClick={() => {
            // TODO CRAB-28257: !! Temporary Hack !! Eventually "Compare Mode" will be one of the TREND_VIEWS
            // However, currently items are being provided to this view in the same way they are for Capsule Time
            // so the view needs to be "Capsule" until the logic is fixed to get the correct trend items when in
            // Compare Mode
            sqTrendActions.setView(TREND_VIEWS.CAPSULE);
            sqTrendActions.setIsCompareMode(true);
            doTrack('Trend', 'switch to Compare Mode');
          }}
          active={isCompareMode}
          isSmall={isSmall}
        />
      </div>

      {isCompareMode && <CompareViewToolbar isSmall={isSmall} />}

      <div
        className={classNames('btn-group forceFlex flexColumnContainer', {
          flexCenter: isSmall,
        })}>
        {isCompareMode && <CompareViewColorModeButton isSmall={isSmall} />}

        {_.map(laneAndAxisButtonsConfiguration, (config) => (
          <ToolbarButton
            key={config.id}
            icon={classNames(config.icon)}
            secondIcon={config.secondIcon}
            label={config.label}
            isSmall={isSmall}
            id={config.id}
            testId={config.id}
            tooltipText={config.tooltipText}
            tooltipPlacement="top"
            onClick={() => {
              config.onClick();
              doTrack('Trend', config.trackAction);
            }}
          />
        ))}

        <ToolbarDropdownButton
          icon="fa-tag"
          onClick={() => doTrack('Trend', 'Labels', 'displayed')}
          label="TOOLBAR.LABELS"
          tooltipText="TOOLBAR.LABEL_TOOLTIP"
          testId="labelDropdownBtn"
          id="labelDropdownBtn"
          isSmall={isSmall}
          dropdownItems={<LabelsPopover />}
        />

        {!isCompareMode && (
          <>
            <ToolbarButton
              onClick={() => {
                doTrack('Trend', `Gridlines ${!showGridlines ? 'Enabled' : 'Disabled'}`);
                sqTrendActions.setGridlines(!showGridlines);
              }}
              active={showGridlines}
              label="TOOLBAR.GRIDLINES"
              icon="fc-gridline"
              isSmall={isSmall}
              tooltipText="TOOLBAR.GRIDLINES_TOOLTIP"
              tooltipPlacement="top"
            />
          </>
        )}

        {(view === TREND_VIEWS.CAPSULE || view === TREND_VIEWS.CHAIN) && (
          <ToolbarButton
            onClick={() => {
              doTrack('Trend', 'Toggle Capsule Group Mode');
              sqWorksheetActions.toggleCapsuleGroupMode();
            }}
            active={capsuleGroupMode}
            isSmall={isSmall}
            label="TOOLBAR.GROUP"
            icon="fc-group"
            tooltipText="TOOLBAR.GROUP_TOOLTIP"
            tooltipPlacement="top"
          />
        )}

        {!isCompareMode && (
          <>
            <CapsuleTimeColorModeButton isSmall={isSmall} />
            <SummarizeData isSmall={isSmall} />
          </>
        )}

        {showDimmingButton && (
          <ToolbarButton
            key="specDimmingBtn"
            icon={classNames('fc-series-dim', { 'fa-lg': !isSmall })}
            label="TOOLBAR.DIMMED"
            active={dimmingMenuChecked}
            isSmall={isSmall}
            id="specDimmingBtn"
            testId="specDimmingBtn"
            tooltipText="TOOLBAR.SHOW_SELECTED_ONLY_TOOLTIP"
            tooltipPlacement="top"
            onClick={sqTrendActions.toggleHideUnselectedItems}
          />
        )}

        {!isCompareMode && (
          <>
            {view === TREND_VIEWS.CAPSULE && (
              <ToolbarDropdownButton
                icon="fc-series-dim"
                label="TOOLBAR.DIMMED"
                tooltipText="TOOLBAR.SHOW_SELECTED_ONLY_TOOLTIP"
                testId="dimmingBtns"
                active={dimDataOutsideCapsules || hideUnselectedItems}
                id="dimmingBtns"
                isSmall={isSmall}
                dropdownItems={
                  <div className="flexRowContainer m5">
                    <HoverTooltip text="TOOLBAR.SHOW_OUTSIDE_DATA_TOOLTIP" placement="right">
                      <span>
                        <Checkbox
                          classes="nowrap"
                          label="TOOLBAR.SHOW_OUTSIDE_DATA"
                          onClick={sqTrendActions.toggleDimDataOutsideCapsules}
                          isChecked={dimDataOutsideCapsules}
                          id="dimHideOutsideCapsule"
                          value={dimDataOutsideCapsules}
                        />
                      </span>
                    </HoverTooltip>

                    <HoverTooltip text="TOOLBAR.SHOW_SELECTED_ONLY_TOOLTIP" placement="right">
                      <span>
                        <Checkbox
                          classes="nowrap"
                          label="TOOLBAR.SHOW_SELECTED_ONLY"
                          onClick={sqTrendActions.toggleHideUnselectedItems}
                          isChecked={hideUnselectedItems}
                          id="hideUnselectedItems"
                          value={hideUnselectedItems}
                        />
                      </span>
                    </HoverTooltip>
                  </div>
                }
              />
            )}
          </>
        )}
      </div>

      {!isCompareMode && (
        <>
          <div
            className={classNames('btn-group forceFlex', {
              flexCenter: isSmall,
            })}>
            <ToolbarButton
              key="zoomButton"
              icon={classNames('fa-search-plus', { 'fa-lg': !isSmall })}
              label="TOOLBAR.ZOOM"
              isSmall={isSmall}
              disabled={!isRegionSelected()}
              id="zoomButton"
              testId="zoomButton"
              tooltipText={isRegionSelected() ? 'TOOLBAR.ZOOM_ENABLED' : 'TOOLBAR.ZOOM_DISABLED'}
              tooltipPlacement="top"
              onClick={() => {
                sqTrendActions.zoomToSelectedRegion();
                doTrack('Trend', 'Zoom');
              }}
            />

            {view === TREND_VIEWS.CAPSULE && (
              <ToolbarButton
                icon={classNames('fa-arrows-alt', { 'fa-lg': !isSmall })}
                label="TOOLBAR.RESET"
                isSmall={isSmall}
                disabled={!areCapsuleTimeOffsetsChanged}
                id="resetButton"
                testId="resetButton"
                tooltipText="TOOLBAR.RESET_CAPSULE_ZOOM"
                tooltipPlacement="top"
                onClick={sqTrendActions.resetCapsuleTimeOffsets}
              />
            )}
          </div>

          <div
            className={classNames('btn-group forceFlex', {
              flexCenter: isSmall,
            })}>
            <AnnotateButton isSmall={isSmall} />
          </div>
        </>
      )}

      {isCompareMode && (
        <>
          <div className="flexFill" />

          <HelpButton
            iconClassNames="fa-xlg"
            customChildren={
              <div className="width-200">
                <ol className="pl15">
                  <li>
                    {t('CONDITION_WITH_PROPERTIES.HELP.COMPARE')} &nbsp;
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        if (sqInvestigateStore.activeTool !== TREND_TOOLS.CONDITION_WITH_PROPERTIES) {
                          sqInvestigateActions.setActiveTool(TREND_TOOLS.CONDITION_WITH_PROPERTIES);
                        }
                      }}>
                      {t('CONDITION_WITH_PROPERTIES.HELP.CREATE_LINK')}
                    </a>{' '}
                    {t('CONDITION_WITH_PROPERTIES.HELP.TWO_PROPERTIES')}
                  </li>
                  <li>{t('CONDITION_WITH_PROPERTIES.HELP.SELECT')}</li>
                </ol>
              </div>
            }
          />
        </>
      )}
    </div>
  );
};
