// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import { useTranslation } from 'react-i18next';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ViewMode } from '@/hybrid/builder/builder.constants';
import { APP_STATE } from '@/main/app.constants';
import { logError } from '@/hybrid/utilities/logger';
import { formatApiError } from '@/hybrid/utilities/utilities';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { sqWorkbenchStore } from '@/core/core.stores';
import { runBuilder } from '@/hybrid/builder/builder.utilities';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';

const builderBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
});

export const Builder: SeeqComponent<typeof builderBindings> = () => {
  const { $state } = useInjectedBindings(builderBindings);
  const stateParams = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.stateParams);

  const { t } = useTranslation();
  const [buildError, setBuildError] = useState(null);

  const VIEW_STATES = {
    [ViewMode.View]: APP_STATE.VIEW_WORKSHEET,
    [ViewMode.Present]: APP_STATE.PRESENT_WORKSHEET,
    [ViewMode.Edit]: APP_STATE.WORKSHEET,
  };
  // Sanitize the input
  const builderParams = {
    ...stateParams,
    viewMode: _.isString(stateParams.viewMode) ? (_.toLower(stateParams.viewMode) as ViewMode) : ViewMode.Edit,
    startFresh: _.isString(stateParams.startFresh)
      ? _.toLower(stateParams.startFresh) === 'true'
      : stateParams.startFresh,
  };

  useEffect(() => {
    runBuilder(builderParams)
      .then((params) => $state.go(VIEW_STATES[builderParams.viewMode], params))
      .catch((e) => {
        if (_.get(e, 'status') === HttpCodes.UNAUTHORIZED) {
          $state.go(APP_STATE.LOGIN, {
            returnState: 'builder',
            returnParams: JSON.stringify(stateParams),
          });
        } else {
          logError(formatApiError(e));
          setBuildError(e);
        }
      });
  }, []);

  return (
    <div className="flexRowContainer flexCenter fullViewport splashScreen text-white">
      <Icon extraClassNames="fa-pulse fa-5x mb10" icon="fa-spinner" />
      <span className={classNames({ 'd-none': buildError })}>{t('LOADING')}</span>
      {buildError && (
        <p className="alert alert-danger">
          <span>{t('ERROR')}</span>
          {buildError.toString()}
        </p>
      )}
    </div>
  );
};

export const sqBuilding = angularComponent(null, Builder);
