// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { COMPARISON_OPERATORS_SYMBOLS } from '@/hybrid/toolSelection/investigate.constants';
import classNames from 'classnames';

const operatorSelectBindings = bindingsDefinition({
  operators: prop<any[]>(),
  value: prop<any>(),
  onChange: prop<(entryCondition: any) => void>(),
  extraClassNames: prop.optional<string>(),
  disabled: prop.optional<boolean>(),
  insideModal: prop.optional<boolean>(),
  appendToBody: prop.optional<boolean>(),
});

const OperatorsSelectUnwrapped: SeeqComponent<typeof operatorSelectBindings> = (props) => {
  const { operators, onChange, value, extraClassNames, disabled, insideModal, appendToBody = true } = props;
  const { t } = useTranslation();

  const operatorKeys = _.invert(COMPARISON_OPERATORS_SYMBOLS);
  const formattedOperators = _.map(operators, (operator) => ({
    text: operator,
    value: operator,
    label: (
      <>
        <span className="inlineBlock width-25 text-center text-bolder text-monospace" id={operator}>
          {operator}
        </span>
        <span className="pl5">{t(`VALUE_SEARCH.OPERATORS.${operatorKeys[operator]}`)}</span>
      </>
    ),
  }));

  const selectedValue = _.find(formattedOperators, { value });

  const menuPortalTarget = appendToBody ? document.body : null;
  const menuPosition = insideModal || !menuPortalTarget ? 'absolute' : 'fixed';
  const portalStyle = { menuPortal: (base) => ({ ...base, zIndex: 9999 }) };

  const SingleValue = (props) => <components.SingleValue {...props}>{props.data.text}</components.SingleValue>;

  return (
    <Select
      classNames={classNames('width-110', extraClassNames)}
      classNamePrefix="operator-select react-select"
      menuPosition={menuPosition}
      styles={insideModal && portalStyle}
      menuPortalTarget={menuPortalTarget}
      menuPlacement="auto"
      placeholder=""
      value={_.isUndefined(selectedValue) ? null : selectedValue}
      options={formattedOperators}
      isSearchable={false}
      isDisabled={disabled}
      components={{ SingleValue }}
      onChange={onChange}
    />
  );
};

export const OperatorsSelect = React.memo(
  OperatorsSelectUnwrapped,
  (prev, next) =>
    !(
      !_.isEqual(prev.value, next.value) ||
      !_.isEqual(prev.operators, next.operators) ||
      prev.disabled !== next.disabled ||
      prev.extraClassNames !== next.extraClassNames
    ),
);
