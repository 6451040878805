// @ts-strict-ignore
import _ from 'lodash';
import angular from 'angular';
import HttpCodes from 'http-status-codes';
import { TrendActions } from '@/trendData/trend.actions';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';

import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { ThresholdMetricInputV1 } from '@/sdk/model/ThresholdMetricInputV1';
import { sqMetricsApi } from '@/sdk/api/MetricsApi';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { getTrendItemScopedTo } from '@/hybrid/trend/trendDataHelper.utilities';
import { DISPLAY_MODE } from '@/main/app.constants';
import { sqFormulasApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { PUSH_IGNORE } from '@/core/flux.service';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { formatApiError } from '@/hybrid/utilities/utilities';

/**
 * @file Service that facilitates creating a items from tool panels.
 */
angular.module('Sq.Services.ToolRunner').factory('sqToolRunner', sqToolRunner);

export type ToolRunnerService = ReturnType<typeof sqToolRunner>;

function sqToolRunner(
  $state: ng.ui.IStateService,
  sqTrendActions: TrendActions,
  sqInvestigateActions: InvestigateActions,
  sqCalculationRunner: CalculationRunnerService,
) {
  const service = {
    panelExecute,
    panelExecuteFormulaFunction,
    panelExecuteThresholdMetric,
    panelExecuteCalculatedItem: panelExecuteFormula(sqCalculationRunner.createCalculatedItem),
    panelExecuteCondition: panelExecuteFormula(sqCalculationRunner.createCondition),
    panelExecuteSignal: panelExecuteFormula(sqCalculationRunner.createSignal),
    panelExecuteScalar: panelExecuteFormula(sqCalculationRunner.createScalar),
  };

  return service;

  /**
   * Handles the logistics of what should happen when the "Execute" button is clicked on a tool panel that creates an
   * item. Store the UI Config, add the item to the trend, fetch dependencies, handle errors
   *
   * @param {Function} create - the create function is called to create a new item; should resolve with the item to be
   *   added
   * @param {Function} update - the update function is called to update an existing item
   * @param {Object} config - the UI Config to set on the item
   * @param {string} [id] - the unique id of an item if it already exists, or undefined if a new item
   * @param {string} [color] - the color the item should appear in the details pane
   * @param {boolean} [notifyOnError] - show a notification on error
   * @param {boolean} [closeOnSuccess] - close the investigate panel on success
   * @returns {ng.IPromise<string>}
   */
  function panelExecute(
    create: () => Promise<any>,
    update: () => Promise<any>,
    config,
    id?: string,
    color?: string,
    { notifyOnError = true, closeOnSuccess = true } = {},
  ): Promise<any> {
    let promise: Promise<void>;
    let newItem: any;
    let props = {};
    const isNew = !id;

    if (config.type === TREND_TOOLS.FFT_TABLE || config.type === TREND_TOOLS.AGGREGATION_BINS_TABLE) {
      props = _.set(props, 'calculationType', config.type);
    }

    if (!isNew) {
      promise = update();
    } else {
      promise = create().then((item) => {
        id = item.id;
        newItem = item;
      });
    }

    return (
      promise
        // Set the UI Config as soon as we know the item exists, so that we reopen the correct tool panel on error
        .then(() =>
          sqItemsApi.setProperty(
            { value: JSON.stringify(config) },
            { id, propertyName: SeeqNames.Properties.UIConfig },
          ),
        )
        .then(() => {
          if (newItem) {
            if (color) {
              newItem.color = color;
            }

            return sqTrendActions.addItem(newItem, props);
          } else {
            // Specifically not returning the promise so that user does not need to wait for all items to fetch before
            // closing
            sqTrendActions.fetchItemAndDependents(id);
          }
        })
        .then(function () {
          if (closeOnSuccess) {
            sqInvestigateActions.close();
          }

          return Promise.resolve(id);
        })
        .catch(function (e) {
          const status = _.get(e, 'status');

          // 504 is triggered when user cancels
          if (status !== HttpCodes.GATEWAY_TIMEOUT) {
            if (status === HttpCodes.BAD_REQUEST) {
              if (e.data.statusMessage) {
                e.data.statusMessage = e.data.statusMessage.replace(/[\s\S]+(Formula error .*)/, '$1');
              } else {
                e.data.statusMessage = 'Formula error: Unknown';
              }
            }

            if (notifyOnError) {
              errorToast({ httpResponseOrError: e });
            }

            sqTrendActions.catchItemDataFailure(id, undefined, e);
          }

          if (isNew && id) {
            sqInvestigateActions.loadToolForEdit(id);
          } else {
            sqInvestigateActions.setDisplayMode(DISPLAY_MODE.EDIT, PUSH_IGNORE);
          }

          return Promise.reject(formatApiError(e));
        })
    );
  }

  /**
   * Creates or updates a threshold metric item and updates the state of the tool panel.
   *
   * @param {String} type - the type that the function should result in
   * @param {Object} definition - An object defining a table
   * @param {String} definition.name - Name for display in the details panel.
   * @param {String} definition.formula - The formula to be run to generate the tables.
   * @param {String[]} definition.parameters - The bound parameters to run the formula (unbound parameters are added by
   *   this function)
   * @param {Object} [config] - the UI Config to set on the item
   * @param {string} [id] - the unique id of an item if it already exists, or undefined if a new item
   * @param {string} [color] - the color the item should appear in the details pane
   * @param {boolean} [notifyOnError] - show a notification on error
   * @param {boolean} [closeOnSuccess] - close the investigate panel on success
   * @returns {Promise<string>}
   */
  function panelExecuteThresholdMetric(
    definition: ThresholdMetricInputV1,
    config,
    id?: string,
    color?: string,
    { notifyOnError = true, closeOnSuccess = true } = {},
  ) {
    _.assign(definition, { scopedTo: getTrendItemScopedTo(id, $state) });
    return service.panelExecute(
      () => sqMetricsApi.createThresholdMetric(definition).then(({ data }) => data),
      () => sqMetricsApi.putThresholdMetric(definition, { id }).then(({ data }) => data),
      config,
      id,
      color,
      { notifyOnError, closeOnSuccess },
    );
  }

  /**
   * Creates or updates a formula item and updates the state of the tool panel.
   *
   * @param {String} type - the type that the function should result in
   * @param {Object} definition - An object defining a table
   * @param {String} definition.name - Name for display in the details panel.
   * @param {String} definition.formula - The formula to be run to generate the tables.
   * @param {String[]} definition.parameters - The bound parameters to run the formula (unbound parameters are added by
   *   this function)
   * @param {Object} config - the UI Config to set on the item
   * @param {string} [id] - the unique id of an item if it already exists, or undefined if a new item
   * @param {string} [color] - the color the item should appear in the details pane
   * @param {boolean} [notifyOnError] - show a notification on error
   * @param {boolean} [closeOnSuccess] - close the investigate panel on success
   * @returns {Promise<string>}
   */
  function panelExecuteFormulaFunction(
    type,
    definition,
    config,
    id?: string,
    color?: string,
    { notifyOnError = true, closeOnSuccess = true } = {},
  ) {
    _.assign(definition, { scopedTo: getTrendItemScopedTo(id, $state) });
    return service.panelExecute(
      () => sqFormulasApi.createFunction({ type, ...definition }).then(({ data }) => data),
      () => sqFormulasApi.updateFunction(definition, { id }).then(({ data }) => data),
      config,
      id,
      color,
      { notifyOnError, closeOnSuccess },
    );
  }

  /**
   * Creates a function that creates or updates a formula item and updates the state of the tool panel.
   *
   * @param {Function} creator - function from sqCalculationRunner that will create the item
   * @returns {Function}
   */
  function panelExecuteFormula(creator) {
    /**
     * Creates or updates a formula item and updates the state of the tool panel.
     *
     * @param {String} name - Name for calculation
     * @param {String} formula - The formula to pass to the Calculation Engine
     * @param {Object} parameters - Map of parameter name to ID that are the top-level parameters used in the formula
     * @param {Object} config - the UI Config to set on the item
     * @param {string} [id] - the unique id of an item if it already exists, or undefined if a new item
     * @param {string} [color] - the color the item should appear in the details pane
     * @param {boolean} [notifyOnError] - show a notification on error
     * @param {boolean} [closeOnSuccess] - close the investigate panel on success
     * @returns {Promise<string>}
     */
    return function (
      name,
      formula,
      parameters,
      config,
      id?: string,
      color?: string,
      { notifyOnError = true, closeOnSuccess = true } = {},
    ) {
      return service.panelExecute(
        () => creator(name, getTrendItemScopedTo(id, $state), formula, parameters),
        () => sqCalculationRunner.updateFormulaItem(id, name, formula, parameters),
        config,
        id,
        color,
        { notifyOnError, closeOnSuccess },
      );
    };
  }
}
