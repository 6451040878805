// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FormControl, InputGroup } from 'react-bootstrap';
import { ADVANCED_SEARCH, APP_STATE, HOME_SCREEN_TABS, SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { useKey } from '@/hybrid/core/hooks/useKey.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { SearchResults } from '@/hybrid/homescreen/SearchResults.molecule';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { HomeScreenAdvancedSearch } from '@/hybrid/homescreen/HomeScreenAdvancedSearch.molecule';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { generateTabHash } from '@/hybrid/utilities/utilities';
import { sqFoldersApi, sqUsersApi } from '@/sdk';
import { RemovableTextButton } from '@/hybrid/core/RemovableTextButton.molecule';
import { DEFAULT_TYPE, HOME_SCREEN_TABLE_TYPE, ITEM_TYPES } from '@/hybrid/homescreen/homescreen.constants';
import { sqHomeScreenStore } from '@/core/core.stores';

/** Allows a user to do a simple search in the home screen, as well as including the component for advanced home screen searching **/

const homeScreenSearchBindings = bindingsDefinition({
  isLoading: prop<boolean>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  $state: injected<ng.ui.IStateService>(),
});

export const HomeScreenSearch: SeeqComponent<typeof homeScreenSearchBindings> = ({ isLoading }) => {
  const { sqHomeScreenActions, $state } = useInjectedBindings(homeScreenSearchBindings);

  useFlux(sqHomeScreenStore);

  const { t } = useTranslation();

  const enterKey = useKey(13);
  const currentTab = sqHomeScreenStore.currentTab;
  const hasSearchResults = sqHomeScreenStore.getItemsForTable(HOME_SCREEN_TABLE_TYPE.SEARCH) !== null;
  const defaultType = {
    text: DEFAULT_TYPE.text,
    label: t(DEFAULT_TYPE.text),
    value: DEFAULT_TYPE.value,
  };
  const [itemTypeFilter, setItemTypeFilter] = useState(defaultType);

  const locationValue = (location) => {
    switch (location) {
      case HOME_SCREEN_TABS.HOME:
        return SEARCH_ITEM_LOCATIONS.ALL;
      case HOME_SCREEN_TABS.SHARED:
        return SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC;
      case HOME_SCREEN_TABS.MY_FOLDER:
        return SEARCH_ITEM_LOCATIONS.MY_FOLDER;
      default:
        return _.toLower(currentTab);
    }
  };

  const defaultLocation = {
    text: currentTab,
    label: t(currentTab),
    value: locationValue(currentTab),
  };

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [advancedSearchSelectedFolder, setAdvancedSearchSelectedFolder] = useState(undefined);
  const [isCurrentFolderNested, setIsCurrentFolderNested] = useState(false);
  const [locationChangedInAdvanced, setLocationChangedInAdvanced] = useState(false);
  const [updateSearchResult, setUpdateSearchResult] = useState(false);
  const [breadcrumbClicked, setBreadcrumbClicked] = useState(false);
  const [itemLocationFilter, setItemLocationFilter] = useState(defaultLocation);
  const [myFolderContent, setMyFolderContent] = useState(undefined);
  const [corporateFolderId, setCorporateFolderId] = useState(undefined);
  const [ownedBy, setOwnedBy] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [searchText, setSearchText] = useState<string>();

  useEffect(() => {
    setSearchText(sqHomeScreenStore.searchParams.textSearch);
  }, [sqHomeScreenStore.searchParams.textSearch]);

  useEffect(() => {
    if (showAdvanced) {
      if (itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.MY_FOLDER && !myFolderContent) {
        sqUsersApi.getMe().then((response) => {
          setMyFolderContent(response.data);
        });
      }
      if (itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.CORPORATE && !corporateFolderId) {
        sqFoldersApi.getFolder({ folderId: 'corporate' }).then((response) => {
          setCorporateFolderId(response.data?.id);
        });
      }
    }
  }, [showAdvanced, itemLocationFilter]);

  useEffect(() => {
    if (enterKey && hasSearchText) executeSearch();
  }, [enterKey]);

  useEffect(() => {
    if (hasSearchResults && currentTab !== HOME_SCREEN_TABS.SEARCH) clearSearchResults(false);
  }, [currentTab]);

  useEffect(() => {
    if (updateSearchResult) {
      executeSearch();
    }
    setUpdateSearchResult(false);
  }, [updateSearchResult]);

  const hasSearchText = !_.isEmpty(_.trim(searchText));
  const hasTypeFilter = itemTypeFilter.value !== ITEM_TYPES.ANY;
  const hasLocationFilter = itemLocationFilter.value !== SEARCH_ITEM_LOCATIONS.ALL;

  const executeSearch = () => {
    setShowAdvanced(false);
    sqHomeScreenActions.resetStore();
    sqHomeScreenActions.setCurrentTab(HOME_SCREEN_TABS.SEARCH);
    const searchParams = {
      textSearch: searchText !== undefined ? _.trim(searchText) : '',
      onlyPinned: itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.PINNED,
      filter:
        itemLocationFilter.value !== SEARCH_ITEM_LOCATIONS.TRASH
          ? !advancedSearchSelectedFolder &&
            (itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.PINNED
              ? SEARCH_ITEM_LOCATIONS.ALL
              : itemLocationFilter.value)
          : undefined,
      folderId:
        advancedSearchSelectedFolder || locationChangedInAdvanced || breadcrumbClicked
          ? advancedSearchSelectedFolder?.id
          : sqHomeScreenStore.currentFolderId,
      types: hasTypeFilter ? [itemTypeFilter.value] : undefined,
      isExact: sqHomeScreenStore.isExact,
      isArchived: itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.TRASH,
      creatorIds: _.map(createdBy, 'id'),
      ownerIds: _.map(ownedBy, 'id'),
    };

    sqHomeScreenActions.setSearchParams(searchParams);
    return sqHomeScreenActions
      .loadSearchTable({
        searchParams,
        isCurrentFolderNested,
        advancedSearchSelectedFolder,
        locationChangedInAdvanced,
        breadcrumbClicked,
      })
      .then(() => createSearchString());
  };

  const clearSearchResults = (goToHomeTab = true) => {
    sqHomeScreenActions.setSearchParams({
      textSearch: '',
      onlyPinned: false,
      filter: defaultLocation.value,
      types: defaultType.value,
      isExact: sqHomeScreenActions.setIsExact(false),
    });

    setShowAdvanced(false);
    setSearchString(null);
    sqHomeScreenActions.clearSearchResults();
    if (goToHomeTab) {
      $state.go(APP_STATE.WORKBOOKS, { t: generateTabHash(HOME_SCREEN_TABS.HOME) }, { reload: true });
    }
  };

  const removeSearchStringResult = (removeResult: string, id?: string) => {
    setUpdateSearchResult(true);
    if (removeResult === ADVANCED_SEARCH.SEARCH_TEXT) {
      setSearchText(undefined);
    } else if (removeResult === ADVANCED_SEARCH.TYPE) {
      setItemTypeFilter(defaultType);
    } else if (removeResult === ADVANCED_SEARCH.LOCATION) {
      setAdvancedSearchSelectedFolder(undefined);
      setItemLocationFilter({
        text: HOME_SCREEN_TABS.HOME,
        label: HOME_SCREEN_TABS.HOME,
        value: 'all',
      });
      sqHomeScreenActions.setCurrentFolder(undefined);
    } else if (removeResult === 'owner') {
      ownedBy?.length === 1 ? setOwnedBy([]) : setOwnedBy(_.remove(ownedBy, (owner) => owner?.id !== id));
    } else if (removeResult === 'creator') {
      createdBy?.length === 1 ? setCreatedBy([]) : setCreatedBy(_.remove(createdBy, (creator) => creator?.id !== id));
    } else {
      sqHomeScreenActions.setIsExact(false);
    }
  };

  const createLocationSearchString = () => {
    if (advancedSearchSelectedFolder && sqHomeScreenStore.searchParams.folderId === advancedSearchSelectedFolder?.id) {
      return advancedSearchSelectedFolder?.name;
    } else if (
      !advancedSearchSelectedFolder &&
      sqHomeScreenStore.breadcrumbs?.length !== 0 &&
      !breadcrumbClicked &&
      sqHomeScreenStore.searchParams?.folderId
    ) {
      if (itemLocationFilter.value === 'all') {
        return t('HOME_SCREEN.LOCATION.ALL');
      } else {
        const lastBreadcrumb = sqHomeScreenStore.breadcrumbs.length;
        return sqHomeScreenStore.breadcrumbs[lastBreadcrumb - 1]?.name;
      }
    } else {
      if (itemLocationFilter.text === 'HOME') {
        return t('HOME_SCREEN.LOCATION.ALL');
      } else {
        return itemLocationFilter.text === 'MY_FOLDER' ? 'My Folder' : _.capitalize(t(itemLocationFilter.text));
      }
    }
  };

  const createSearchString = () => {
    const locationString = createLocationSearchString();
    const resultString = (
      <span>
        {searchText && sqHomeScreenStore.searchParams.textSearch !== '' && (
          <TextButton
            testId="searchTextResults"
            type="button"
            size="sm"
            variant="outline"
            formattedLabel={
              <RemovableTextButton
                label={`${t('HOME_SCREEN.SEARCH_TEXT_SEARCH_STRING')}: ${searchText}`}
                showFormat={true}
                onClick={() => removeSearchStringResult(ADVANCED_SEARCH.SEARCH_TEXT)}
              />
            }
            extraClassNames="mr10 mb10 cursorDefaultImportant"
          />
        )}
        {((hasTypeFilter && sqHomeScreenStore.searchParams.types !== undefined) || itemTypeFilter.value === 'Any') && (
          <TextButton
            testId="searchTypeResults"
            type="button"
            size="sm"
            variant="outline"
            formattedLabel={
              <RemovableTextButton
                label={`${t('HOME_SCREEN.TYPE_SEARCH_STRING')}: ${t(itemTypeFilter.text)}`}
                showFormat={itemTypeFilter.value !== 'Any'}
                onClick={() => removeSearchStringResult(ADVANCED_SEARCH.TYPE)}
              />
            }
            extraClassNames="mr10 mb10 cursorDefaultImportant"
          />
        )}
        {(hasLocationFilter || itemLocationFilter.value === 'all') && (
          <TextButton
            testId="searchLocationResults"
            type="button"
            size="sm"
            variant="outline"
            formattedLabel={
              <RemovableTextButton
                label={`${t('HOME_SCREEN.LOCATION_SEARCH_STRING')}: ${locationString}`}
                showFormat={itemLocationFilter.value !== 'all'}
                onClick={() => removeSearchStringResult(ADVANCED_SEARCH.LOCATION)}
              />
            }
            extraClassNames="mr10 mb10 cursorDefaultImportant"
          />
        )}
        {_.has(ownedBy[0], 'username') &&
          _.map(ownedBy, (owner) => {
            return (
              <TextButton
                testId="searchOwnerResults"
                type="button"
                size="sm"
                variant="outline"
                formattedLabel={
                  <RemovableTextButton
                    label={`${t('HOME_SCREEN.OWNER_SEARCH_STRING')}: ${owner.name}`}
                    showFormat={true}
                    onClick={() => removeSearchStringResult('owner', owner.id)}
                  />
                }
                extraClassNames="mr10 mb10 cursorDefaultImportant"
              />
            );
          })}
        {_.has(createdBy[0], 'username') &&
          _.map(createdBy, (creator) => {
            return (
              <TextButton
                testId="searchCreatorResults"
                type="button"
                size="sm"
                variant="outline"
                formattedLabel={
                  <RemovableTextButton
                    label={`${t('HOME_SCREEN.CREATOR_SEARCH_STRING')}: ${creator.name}`}
                    showFormat={true}
                    onClick={() => removeSearchStringResult('creator', creator.id)}
                  />
                }
                extraClassNames="mr10 mb10 cursorDefaultImportant"
              />
            );
          })}
        {sqHomeScreenStore.isExact && (
          <TextButton
            testId="searchExactMatchResults"
            type="button"
            size="sm"
            variant="outline"
            formattedLabel={
              <RemovableTextButton
                label={`${t('HOME_SCREEN.ADVANCED_SEARCH.EXACT_MATCH_CHECKBOX')}`}
                showFormat={true}
                onClick={() => removeSearchStringResult('exactMatch')}
              />
            }
            extraClassNames="mr10 mb10 cursorDefaultImportant"
          />
        )}
      </span>
    );
    setSearchString(resultString);
  };

  return (
    <div className="flexRowContainer height-maximum positionRelative">
      <InputGroup
        className="homeScreenSearchBar flexColumnContainer lightGreyBorder border-radius-4 form-control flexNoWrap"
        data-testid="searchBar">
        <FormControl
          className="homeScreenSearchInput hide-ms-clear height-32"
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={t('HOME_SCREEN.SEARCH_PLACEHOLDER')}
        />
        <InputGroup.Append>
          <InputGroup.Text className="searchButton cursorPointer advanced-options">
            <span data-testid="advancedOptionButton" onClick={() => setShowAdvanced(!showAdvanced)}>
              <Icon
                icon="fa-chevron-down"
                tooltip="HOME_SCREEN.ADVANCED_SEARCH.TOOLTIP"
                tooltipPlacement="top"
                large={true}
                type="gray"
                extraClassNames="p2"
              />
            </span>
          </InputGroup.Text>
          <InputGroup.Text className="searchButton cursorPointer search-icon">
            <span onClick={executeSearch} data-testid="searchButton">
              <Icon icon="fc-mag-glass-empty" type="gray" extraClassNames="p2" />
            </span>
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
      {showAdvanced && (
        <HomeScreenAdvancedSearch
          itemTypeFilter={itemTypeFilter}
          setItemTypeFilter={setItemTypeFilter}
          itemLocationFilter={itemLocationFilter}
          setItemLocationFilter={setItemLocationFilter}
          setShowAdvanced={setShowAdvanced}
          executeSearch={executeSearch}
          advancedSearchSelectedFolder={advancedSearchSelectedFolder}
          setAdvancedSearchSelectedFolder={setAdvancedSearchSelectedFolder}
          isCurrentFolderNested={isCurrentFolderNested}
          setIsCurrentFolderNested={setIsCurrentFolderNested}
          locationChangedInAdvanced={locationChangedInAdvanced}
          setLocationChangedInAdvanced={setLocationChangedInAdvanced}
          breadcrumbClicked={breadcrumbClicked}
          setBreadcrumbClicked={setBreadcrumbClicked}
          myFolderContent={myFolderContent}
          corporateFolderId={corporateFolderId}
          ownedBy={ownedBy}
          setOwnedBy={setOwnedBy}
          createdBy={createdBy}
          setCreatedBy={setCreatedBy}
        />
      )}
      {currentTab === HOME_SCREEN_TABS.SEARCH && (
        <SearchResults
          extraClassNames="mt20"
          isLoading={isLoading}
          onClear={clearSearchResults}
          resultsString={searchString}
        />
      )}
    </div>
  );
};
