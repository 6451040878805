import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import _ from 'lodash';
import classNames from 'classnames';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { formatTime } from '@/hybrid/datetime/dateTime.utilities';
import { TrendActions } from '@/trendData/trend.actions';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { sqTrendStore, sqWorksheetStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';
import { isPresentationWorkbookMode } from '@/hybrid/utilities/utilities';
import { CUSTOMIZATION_MODES } from '@/trendData/trendData.constants';
import { IS_PROTRACTOR } from '@/main/app.constants';

const detailsPanelHeaderBindings = bindingsDefinition({
  sqTrendActions: injected<TrendActions>(),
});

export const DetailsPanelHeader: SeeqComponent<typeof detailsPanelHeaderBindings> = () => {
  const { sqTrendActions } = useInjectedBindings(detailsPanelHeaderBindings);

  const { xValue, customizationMode } = useFlux(sqTrendStore);
  const { timezone, view } = useFlux(sqWorksheetStore);

  const hideTimestamp = sqTrendStore.isTrendViewCapsuleTime();
  const simplifiedView = _.includes([WORKSHEET_VIEW.SCATTER_PLOT, WORKSHEET_VIEW.TREEMAP], view.key);
  const isPresentationMode = isPresentationWorkbookMode();
  const showChartCustomizations = customizationMode !== CUSTOMIZATION_MODES.OFF;

  const xAxisTimestamp = formatTime(xValue, timezone);

  const { t } = useTranslation();

  return (
    <>
      <span className="mr10 flexFill">
        <Icon icon="fa-area-chart" type="white" extraClassNames="ml5 mr5" testId="detailsPanelHeaderIcon" />
        {t('DETAILS')}
      </span>

      {!hideTimestamp && (
        <span className="text-nowrap xAxisTimestamp" data-testid="xAxisTimestamp">
          {xAxisTimestamp}
        </span>
      )}

      <div className="ml10 mt2 mb2 max-height-22 overflowHidden">
        {!isPresentationMode && !simplifiedView && (
          <>
            {showChartCustomizations &&
              _.map(
                _.filter(CUSTOMIZATION_MODES, (mode) => mode !== CUSTOMIZATION_MODES.OFF),
                (mode) => (
                  <TextButton
                    key={`${mode}CustomizationButton`}
                    id={`${mode}CustomizationButton`}
                    iconStyle={customizationMode === mode ? 'white' : 'text'}
                    extraClassNames={classNames('sq-btn-xs mr5', {
                      borderColorWhite: customizationMode === mode,
                    })}
                    formattedLabel={<small>{t(`TREND_CUSTOMIZATIONS.${_.toUpper(mode)}`)}</small>}
                    variant={customizationMode === mode ? 'theme-light' : 'outline'}
                    onClick={() => {
                      doTrack('Trend', `Customize ${mode} in Details Panel`);
                      sqTrendActions.setCustomizationMode(mode);
                    }}
                  />
                ),
              )}

            <TextButton
              id="yAxisCustom"
              icon="fa-gears"
              iconStyle={showChartCustomizations ? 'white' : 'text'}
              extraClassNames={classNames('sq-btn-xs', {
                borderColorWhite: showChartCustomizations,
              })}
              formattedLabel={<small className="ml5">{t('CUSTOMIZE')}</small>}
              variant={showChartCustomizations ? 'theme' : 'outline'}
              onClick={() => {
                doTrack('Trend', 'Customize button in Details Panel');

                if (showChartCustomizations) {
                  sqTrendActions.setCustomizationMode(CUSTOMIZATION_MODES.OFF);
                } else {
                  sqTrendActions.setCustomizationMode(CUSTOMIZATION_MODES.STYLE);
                }
              }}
              tooltipOptions={{ placement: 'top' }}
              tooltip={
                IS_PROTRACTOR
                  ? undefined
                  : `TOOLBAR.CHART_CONFIGURATION_${showChartCustomizations ? 'SHOWN' : 'DISABLED'}`
              }
            />
          </>
        )}
      </div>
    </>
  );
};
