import { SeeqNames } from '@/main/app.constants.seeqnames';

export const CK_DROPDOWN_BUTTON_CLASS = 'ckEditorDropdownButton';

export const EDITABLE_CONTENT_MODEL_ATTRIBUTES = {
  WIDTH: SeeqNames.TopicDocumentAttributes.DataSeeqContentWidth,
  HEIGHT: SeeqNames.TopicDocumentAttributes.DataSeeqContentHeight,
  BORDER: SeeqNames.TopicDocumentAttributes.DataSeeqContentBorder,
  NO_MARGIN: SeeqNames.TopicDocumentAttributes.DataSeeqContentNoMargin,
  WIDTH_PERCENT: SeeqNames.TopicDocumentAttributes.DataSeeqContentPercentWidth,
  PROPERTY_OVERRIDES: SeeqNames.TopicDocumentAttributes.DataSeeqContentPropertyOverrides,
};

export const CONTENT_ATTRIBUTE_UPCAST_CONVERSIONS = {
  [EDITABLE_CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES]: (json: string) => JSON.parse(json),
  [EDITABLE_CONTENT_MODEL_ATTRIBUTES.WIDTH]: (width: string) => parseInt(width, 10) || 0,
  [EDITABLE_CONTENT_MODEL_ATTRIBUTES.HEIGHT]: (height: string) => parseInt(height, 10) || 0,
};

export const CONTENT_ATTRIBUTE_DOWNCAST_CONVERSIONS = {
  [EDITABLE_CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES]: (properties: any) => JSON.stringify(properties),
};

export const CONTENT_MODEL_ATTRIBUTES = {
  ...EDITABLE_CONTENT_MODEL_ATTRIBUTES,
  DATA_SEEQ_CONTENT: SeeqNames.TopicDocumentAttributes.DataSeeqContent,
  PENDING: SeeqNames.TopicDocumentAttributes.DataSeeqContentPending,
};

export const DATE_RANGE_LABEL_ATTRIBUTES = {
  DATA_DATE_RANGE_ID: SeeqNames.TopicDocumentAttributes.DataSeeqDateRangeId,
  DATA_DATE_RANGE_FORMAT: SeeqNames.TopicDocumentAttributes.DataSeeqDateRangeFormat,
  DATA_DATE_RANGE_CONTENT: SeeqNames.TopicDocumentAttributes.DataSeeqDateRangeContent,
};

export const ASSET_SELECTION_LABEL_ATTRIBUTES = {
  ASSET_SELECTION_ID: SeeqNames.TopicDocumentAttributes.DataSeeqAssetSelectionId,
  ASSET_SELECTION_DEPTH_LEVEL: SeeqNames.TopicDocumentAttributes.DataSeeqAssetSelectionDepthLevel,
};

export const IFRAME_CONTENT_ATTRIBUTES = {
  DATA_IFRAME_CONTENT_URL: SeeqNames.TopicDocumentAttributes.DataSeeqIframeContentUrl,
  DATA_IFRAME_CONTENT_WIDTH: SeeqNames.TopicDocumentAttributes.DataSeeqIframeContentWidth,
  DATA_IFRAME_CONTENT_HEIGHT: SeeqNames.TopicDocumentAttributes.DataSeeqIframeContentHeight,
};
