import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { sqPluginStore, sqSearchStore, sqWorkbenchStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import {
  headlessRenderMode,
  isDisplay,
  isEditWorkbookMode,
  isPresentationWorkbookMode,
  isViewOnlyWorkbookMode,
} from '@/hybrid/utilities/utilities';
import { PanelTabs } from '@/hybrid/worksheets/PanelTabs.molecule';
import { SearchModes, SearchPanes } from '@/search/search.constants';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { ASSET_GROUP_VIEW, WORKSHEET_TARGET, WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { SEARCH_TYPES } from '@/main/app.constants';
import { setDisplayLoading } from '@/search/search.actions';
import { doTrack } from '@/track/track.service';
import { TrendActions } from '@/trendData/trend.actions';
import { ToolSelectionView } from '@/hybrid/toolSelection/ToolSelectionView.organism';
import { JournalPanel } from '@/hybrid/annotation/JournalPanel.organism';
import { ReportConfig } from '@/hybrid/reportConfig/ReportConfig.organism';
import { ReportComments } from '@/hybrid/reportComments/ReportComments.molecule';
import { RedactionBanner } from '@/hybrid/worksheet/RedactionBanner.atom';
import { ReportEditor } from '@/hybrid/reportEditor/ReportEditor.organism';
import { loadDisplay } from '@/hybrid/displays/displays.actions';
import { OrganizerTourModal } from '@/hybrid/tour/OrganizerTourModal.organism';
import { TrendViewer } from '@/hybrid/trend/trendViewer/TrendViewer.organism';
import { WorkbenchTourModal } from '@/hybrid/tour/WorkbenchTourModal.organism';
import { XYPlot } from '@/hybrid/scatterPlot/XYPlot.page';
import { Treemap } from '@/hybrid/treemap/Treemap.molecule';
import { TableBuilderView } from '@/hybrid/tableBuilder/TableBuilderView.page';
import { AssetGroupView } from '@/hybrid/assetGroupEditor/AssetGroupView.molecule';
import { DisplayPanePluginHost } from '@/hybrid/plugin/DisplayPanePluginHost.molecule';
import { PluginOutputV1 } from '@/sdk';
import { WorkbookAccessWarningModal } from '@/hybrid/workbooks/WorkbookAccessWarningModal.molecule';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { StateSynchronizerService } from '@/services/stateSynchronizer.service';
import { SearchWidget } from '@/hybrid/search/SearchWidget.organism';
import { displaysEnabled, isCkAutoUpgradeEnabled } from '@/services/systemConfiguration.utilities';
import { isRedacted } from '@/hybrid/utilities/redaction.utilities';

const worksheetPageBindings = bindingsDefinition({
  sqWorkbenchActions: injected<WorkbenchActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqReportEditor: injected<ReportEditorService>(),
  sqTrendActions: injected<TrendActions>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  $state: injected<ng.ui.IStateService>(),
  sqStateSynchronizer: injected<StateSynchronizerService>(),
});

export const WorksheetPage: SeeqComponent<typeof worksheetPageBindings> = () => {
  const {
    sqWorkbenchActions,
    sqInvestigateActions,
    sqWorksheetActions,
    sqReportEditor,
    sqTrendActions,
    sqHomeScreenUtilities,
    $state,
    sqStateSynchronizer,
  } = useInjectedBindings(worksheetPageBindings);
  const { t } = useTranslation();

  const { isReportBinder, worksheets, owner, isArchived, workbookId } = useFlux(sqWorkbookStore);
  const { view, browsePanelCollapsed } = useFlux(sqWorksheetStore);
  const { worksheetPanelCollapsed, viewOnlyWorksheetPanelCollapsed } = useFlux(sqWorkbenchStore);
  const originalActiveTabIndex = useFluxPath(
    sqWorksheetStore,
    () => sqWorksheetStore.getTabset('sidebar').activeTabIndex,
  );
  const reportConfigTabIndex = useFluxPath(sqWorksheetStore, () =>
    sqWorksheetStore.getTabset('sidebar').tabs.indexOf('reportConfig'),
  );
  const reportCommentsTabIndex = useFluxPath(sqWorksheetStore, () =>
    sqWorksheetStore.getTabset('sidebar').tabs.indexOf('reportComments'),
  );
  const searchTabIndex = useFluxPath(sqWorksheetStore, () =>
    sqWorksheetStore.getTabset('sidebar').tabs.indexOf('search'),
  );
  const investigateTabIndex = useFluxPath(sqWorksheetStore, () =>
    sqWorksheetStore.getTabset('sidebar').tabs.indexOf('investigate'),
  );
  const annotateTabIndex = useFluxPath(sqWorksheetStore, () =>
    sqWorksheetStore.getTabset('sidebar').tabs.indexOf('annotate'),
  );
  const [workbookAccessModalWorkbook, setWorkbookAccessModalWorkbook] = useState({ name: '', workbookId: '' });
  const [showWorkbookAccessModal, setShowWorkbookAccessModal] = useState(false);

  const isViewMode = isViewOnlyWorkbookMode();
  const isWorksheetPanelCollapsed = isViewMode ? viewOnlyWorksheetPanelCollapsed : worksheetPanelCollapsed;

  const isPresentationMode = isPresentationWorkbookMode();
  const activeTabIndex = isViewMode && isReportBinder ? reportConfigTabIndex : originalActiveTabIndex;
  const isAssetGroupEditorView = view.key === ASSET_GROUP_VIEW.key;
  const SEARCH_PANES = { MAIN: 'main', MODAL: 'modal' } as Record<string, SearchPanes>;
  const searchTypes = displaysEnabled() ? SEARCH_TYPES : SEARCH_TYPES.filter((t) => t !== 'Display');

  useEffect(() => {
    displayArchivedModal();
  }, []);

  /**
   * When the workbook is archived, show a handy modal that will allow the user to restore or duplicate the workbook.
   */
  const displayArchivedModal = () => {
    if (isArchived && !headlessRenderMode()) {
      sqHomeScreenUtilities.getWorkbook(workbookId).then((workbook) => {
        setWorkbookAccessModalWorkbook(workbook);
        setShowWorkbookAccessModal(true);
      });
    }
  };

  const closeWorkbookAccessModal = () => setShowWorkbookAccessModal(false);

  /**
   * Called whenever a new "tab" in the side panel is selected
   *
   * activeTab - the selected tab, one of WORKSHEET_TABSETS
   */
  const changeSidebarTab = (activeTab: string) => {
    if (activeTab === 'investigate') {
      sqInvestigateActions.updateDerivedDataTree();
    }

    if (activeTab === 'annotate') {
      sqReportEditor.focus();
    }

    sqWorksheetActions.tabsetChangeTab('sidebar', activeTab);
  };

  /**
   * Tracks the adding of an item as well as actually adds the item.
   *
   * item - the item to be added.
   * mode - the current search pane mode
   * returns a promise that resolves then the item has been added
   */
  const trackAndAddItem = (item: object, mode: SearchModes): Promise<any> => {
    doTrack('Worksheets', 'Item added', mode);
    return sqTrendActions.addItem(item);
  };

  /**
   * Tracks the loading of a display as well as actually loads the display.
   *
   * display - the display to be loaded.
   * mode - the current search pane mode
   * returns a promise that resolves then the display has been added
   */
  const trackAndLoadDisplay = (display: any, mode: SearchModes): Promise<any> => {
    doTrack('Worksheets', 'Display loaded', mode);
    return loadDisplay($state, sqStateSynchronizer, sqWorksheetActions, sqTrendActions, display.id);
  };

  /**
   * Handle an item when it is clicked in the search pane. Track the action performed.
   *
   * item - the item that was clicked
   * mode - the current search pane mode
   * pane - the current search pane
   * returns a promise that resolves when the item has been added
   */
  const trackAndHandleItem = (item: any, mode: SearchModes, pane: SearchPanes): Promise<any> => {
    if (isDisplay(item)) {
      // Only load a display if another one is not already loading
      if (_.isEmpty(sqSearchStore.displayLoadingId)) {
        setDisplayLoading(pane, item.id);
        return trackAndLoadDisplay(item, mode).finally(() => {
          setDisplayLoading(pane!, '');
        });
      } else {
        return Promise.resolve();
      }
    } else {
      return trackAndAddItem(item, mode!);
    }
  };

  /**
   * Returns true if the Redaction banner at the top of the trend view should be shown
   */
  const shouldShowRedactionBanner = (): boolean => {
    return !headlessRenderMode() && isRedacted();
  };

  /**
   * Checks if we are transitioning (i.e. routing ) to a worksheet
   * True if transitioning, false otherwise
   */
  const transitioningToWorksheet = (): boolean => !!$state.transition;

  /**
   * Determines if a particular worksheet view is currently selected for display
   * target - A worksheet target (i.e. WORKSHEET_TARGET.DATA_PANEL or WORKSHEET_TARGET.WORKSHEET).
   * key - A worksheet key (e.g. WORKSHEET_VIEW.TREND, WORKSHEET_VIEW.EXPLORE, etc.). If this
   *                       value is not defined, then only the target parameter is used to determine if
   *                       the view is selected.
   * returns True if a view matching the supplied target and key is selected, false otherwise or
   *                    if transitioning to a worksheet.
   */
  const isSelectedView = (target: string, key?: string): boolean => {
    const currentTarget = sqWorksheetStore.view?.target;
    const currentKey = sqWorksheetStore.view?.key;
    return (
      currentTarget === target && (!key || currentKey === key) && !(transitioningToWorksheet() && !!worksheets.length)
    );
  };

  const isEditMode = isEditWorkbookMode();

  const selectedPlugin: any = _.find(sqPluginStore.displayPanePlugins(), (plugin: PluginOutputV1) =>
    isSelectedView(WORKSHEET_TARGET.DATA_PANEL, plugin.identifier),
  );

  return (
    <>
      {isSelectedView(WORKSHEET_TARGET.DATA_PANEL) && (
        <div className="worksheetColumn flexColumnContainer flexFill" data-testid="worksheetColumn">
          {!isPresentationMode && (
            <div
              className="flexColumnContainer flexCenter toggleVisibilityBar"
              id="spec-worksheets-bar"
              data-testid="worksheetsBar"
              onClick={sqWorkbenchActions.toggleWorksheetPanel}>
              <Icon
                extraClassNames="fa pt10 pb10"
                icon={isWorksheetPanelCollapsed ? 'fa-angle-double-right' : 'fa-angle-double-left'}
                tooltip={isWorksheetPanelCollapsed ? t('PANEL.WORKSHEET.OPEN') : t('PANEL.WORKSHEET.CLOSE')}
                tooltipPlacement="right"
              />
            </div>
          )}
          <div className="flexColumnContainer flexFill">
            {/* Left panel */}
            <div
              data-testid="worksheetLeftPanel"
              className={classNames('browseColumn flexRowContainer', {
                'd-none': browsePanelCollapsed || isPresentationMode,
              })}>
              <div className="flexNoGrowNoShrink">
                <PanelTabs
                  activeTabIndex={activeTabIndex}
                  isReportBinder={isReportBinder}
                  isPresentationMode={isPresentationMode}
                  changeSidebarTab={changeSidebarTab}
                  isAssetGroupEditorView={isAssetGroupEditorView}
                  isViewMode={isViewMode}
                  searchTabIndex={searchTabIndex}
                  investigateTabIndex={investigateTabIndex}
                  annotateTabIndex={annotateTabIndex}
                  reportConfigTabIndex={reportConfigTabIndex}
                  reportCommentsTabIndex={reportCommentsTabIndex}
                />
              </div>
              {/* placing the contents of the tabs after the uib-tabset instead of inside allows more control over flex
           properties */}

              {!isReportBinder && !isPresentationMode && activeTabIndex === searchTabIndex && (
                <SearchWidget
                  pane={SEARCH_PANES.MAIN}
                  searchTypes={searchTypes}
                  allowAssetReplacement={true}
                  onItemClick={trackAndHandleItem as any}
                />
              )}
              {!isReportBinder && !isPresentationMode && activeTabIndex === investigateTabIndex && (
                <ToolSelectionView />
              )}

              {!isReportBinder && !isPresentationMode && (
                <JournalPanel extraClassNames={activeTabIndex !== annotateTabIndex ? 'd-none' : ''} />
              )}

              {isReportBinder && activeTabIndex === reportConfigTabIndex && (
                <div
                  className={classNames('flexRowContainer flexFillOverflow overflowAuto min-width-340', {
                    'd-none': browsePanelCollapsed,
                  })}>
                  <ReportConfig viewOnlyMode={isViewMode} isCkAutoUpgradeEnabled={isCkAutoUpgradeEnabled()} />
                </div>
              )}

              {isReportBinder && activeTabIndex === reportCommentsTabIndex && (
                <div
                  data-testid="reportComments"
                  className={classNames('flexRowContainer flexFill min-width-340', {
                    'd-none': browsePanelCollapsed,
                  })}>
                  <ReportComments isPresentationMode={isPresentationMode} />
                </div>
              )}
            </div>

            {!isPresentationMode && (
              <div
                className={classNames('flexColumnContainer flexCenter', { toggleVisibilityBar: !isPresentationMode })}
                id="spec-browse-bar"
                onClick={sqWorksheetActions.toggleBrowsePanel}>
                <Icon
                  extraClassNames="fa pt10 pb10"
                  tooltip={browsePanelCollapsed ? t('PANEL.BROWSE.OPEN') : t('PANEL.BROWSE.CLOSE')}
                  tooltipPlacement="right"
                  icon={browsePanelCollapsed ? 'fa-angle-double-right' : 'fa-angle-double-left'}
                />
              </div>
            )}
            <div className="flexRowContainer flexWithoutOverflow width-maximum">
              {shouldShowRedactionBanner() && (
                <div className="flexColumnContainer flexCenter alert-warning p10">
                  <RedactionBanner workbookOwner={owner.name} />
                </div>
              )}
              {isReportBinder && <ReportEditor />}
              {isReportBinder && isEditMode && <OrganizerTourModal />}
              {!isReportBinder && isEditMode && <WorkbenchTourModal />}

              {!isReportBinder && (
                <div className="ng-animate-disabled flexFill flexColumnContainer resizablePanelWrapper">
                  {isSelectedView(WORKSHEET_TARGET.DATA_PANEL, WORKSHEET_VIEW.TREND) && <TrendViewer />}
                  {isSelectedView(WORKSHEET_TARGET.DATA_PANEL, WORKSHEET_VIEW.SCATTER_PLOT) && <XYPlot />}
                  {isSelectedView(WORKSHEET_TARGET.DATA_PANEL, WORKSHEET_VIEW.TREEMAP) && <Treemap />}
                  {isSelectedView(WORKSHEET_TARGET.DATA_PANEL, WORKSHEET_VIEW.TABLE) && <TableBuilderView />}
                  {isSelectedView(WORKSHEET_TARGET.DATA_PANEL, WORKSHEET_VIEW.ASSET_GROUP_EDITOR) && <AssetGroupView />}
                  {selectedPlugin && <DisplayPanePluginHost plugin={selectedPlugin} />}
                </div>
              )}
              {showWorkbookAccessModal && (
                <WorkbookAccessWarningModal
                  workbook={workbookAccessModalWorkbook}
                  isReportBinder={isReportBinder}
                  onClose={closeWorkbookAccessModal}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {transitioningToWorksheet() && !!worksheets.length && (
        <div className="flexColumnContainer flexCenter flexFill" data-testid="transitioningIndicator">
          <Icon icon="fa-spinner fa-pulse fa-5x" extraClassNames="fa sq-text-primary" />
        </div>
      )}
    </>
  );
};
export const sqWorksheetPage = angularComponent(worksheetPageBindings, WorksheetPage);
