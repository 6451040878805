// @ts-strict-ignore
import _ from 'lodash';
import $ from 'jquery';
import i18next from 'i18next';

/**
 * @file Service that facilitates creating a calculation and then using that calculation to create results, such as
 * generated capsules or markers.
 */

const MAX_NAME_LENGTH = 20;
const MAX_DESCRIPTION_LENGTH = 50;

/**
 * Helper function than strips html characters, tokenizes the words in text, and returns the words at indexes
 * between start and end.
 */
const getWords = (text: string, start: number, end: number) => {
  if (!text) {
    return '';
  }
  // Remove tags, collapse spaces, split on space, slice desired words, join and return
  return _.compact(
    text
      .replace(/<[^>]*>/g, ' ')
      .replace(/\s+/g, ' ')
      .split(/\s+/gi),
  )
    .slice(start, end)
    .join(' ');
};

/**
 * Helper that gets the document name  and description.
 * The name is created by taking the first non-empty block of text (up to MAX_NAME_LENGTH) as the name.
 * The description consists of up to the next MAX_DESCRIPTION_LENGTH words in the document.
 *
 * @param {String} document - the html document from which the name and description are pulled.
 */
export const nameAndDescriptionFromDocument = (htmlDocument: string) => {
  // Ensure images don't load while parsing: https://stackoverflow.com/a/50194774/1108708
  const ownerDocument = document.implementation.createHTMLDocument('virtual');
  const htmlElements = $.parseHTML(htmlDocument, ownerDocument);
  let name = '';
  _.forEach(htmlElements, function (child) {
    name = getWords(_.get(child, 'textContent'), 0, MAX_NAME_LENGTH);
    return _.isEmpty(name); // return false when name is determined to exit loop
  });

  name = name || i18next.t('UNNAMED');

  const start = _.split(name, ' ').length;
  const description = getWords(htmlDocument, start, start + MAX_DESCRIPTION_LENGTH);

  return { name, description };
};
