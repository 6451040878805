// @ts-strict-ignore
import _ from 'lodash';

/**
 * Get the CSS class for a column so it can be accessed via system tests. If it is a custom column it uses the
 * translation key since key does not have the calculation in it.
 *
 * @param {Object} column - One of the columns from enabledColumns
 * @returns {String} Formatted value
 */
export const getColumnClass = (column) =>
  `${column.series ? _.camelCase(_.get(column, 'title', '').split('.')[1]) : column.key.split('.').pop()}Column`;
