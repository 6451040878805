// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { REFERENCE_STATS } from '@/hybrid/toolSelection/investigate.constants';
import { DISPLAY_MODE } from '@/main/app.constants';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import {
  addSignalCondition,
  removeSignalCondition,
  setGriddingPeriod as setGriddingPeriodAction,
  setInputCondition as setInputConditionAction,
  setInputSignal,
  setMultiplier,
  setReferenceStat,
  setTrainingWindow,
} from '@/hybrid/tools/referencePanel/referencePanel.actions';
import { sqInvestigateStore, sqReferencePanelStore } from '@/core/core.stores';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { doTrack } from '@/track/track.service';
import { getDefaultMaxCapsuleDuration } from '@/services/systemConfiguration.utilities';

const referencePanelBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqToolRunner: injected<ToolRunnerService>(),
});

export const ReferencePanel: SeeqComponent<typeof referencePanelBindings> = () => {
  const { sqInvestigateActions, sqToolRunner } = useInjectedBindings(referencePanelBindings);

  const { t } = useTranslation();

  const displayMode = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.displayMode);

  const {
    id,
    name,
    originalParameters,
    inputGroups,
    repeatOverCondition,
    griddingPeriod,
    referenceStat,
    multiplier,
    maximumDuration,
    window: trainingWindow,
  } = useFlux(sqReferencePanelStore);

  const [color, setColor] = useState('');
  const [maxDurationValid, setMaximumDurationValid] = useState(true);
  const [trainingWindowValid, setTrainingWindowValid] = useState(true);
  const [griddingPeriodValid, setGriddingPeriodValid] = useState(true);

  /**
   * Handler that is executed when the user selects an input condition.
   * This will also set the repeatOverCondition if none has been specified yet.
   */
  const setInputCondition = (condition, inputConditionIndex) => {
    setInputConditionAction(condition, inputConditionIndex);
    if (!repeatOverCondition || repeatOverCondition?.id === condition?.id) {
      sqInvestigateActions.setParameterItem(TREND_TOOLS.REFERENCE, 'repeatOverCondition', condition);
    }
  };

  /**
   * If the supplied condition does not have a maximum capsule duration defined, set the maximum duration to the system
   * default and expand the advanced parameters so the maximum capsule duration will be visible to the user.
   */
  const setDefaultMaximumCapsuleDuration = () => {
    const defaultMCD = getDefaultMaxCapsuleDuration();
    sqInvestigateActions.setMaximumDuration(TREND_TOOLS.REFERENCE, defaultMCD.value, defaultMCD.units);
  };

  /**
   * Generates a condition using the formula from the store.
   */
  const execute = () => {
    const id = repeatOverCondition?.id;
    const maximumDurationCondition = !repeatOverCondition?.conditionMetadata?.maximumDuration
      ? maximumDuration
      : undefined;

    const { formula, parameters } = sqReferencePanelStore.formulaParams({
      data: inputGroups,
      repeatOverCondition: _.omitBy({ id, maximumDuration: maximumDurationCondition }, _.isNil),
    });

    return sqToolRunner
      .panelExecuteSignal(
        sqReferencePanelStore.name,
        formula,
        parameters,
        sqReferencePanelStore.configParams,
        sqReferencePanelStore.id,
        color,
      )
      .then(() => {
        doTrack('Workbench_Tool', 'Reference Profile', 'completed');
      })
      .catch(() => {
        doTrack('Workbench_Tool', 'Reference Profile', 'error');
      });
  };

  const setGriddingPeriod = (valueWithUnits: { valid: boolean; units: string; value: number }) => {
    setGriddingPeriodValid(valueWithUnits.valid);
    setGriddingPeriodAction(valueWithUnits);
  };

  /**
   * Helper function that determines if the maximum capsule duration input field should be displayed for the "apply
   * to condition" in the advanced section.
   * We only show a maximum duration field for the "apply to" condition if the repeat-over condition is the different
   * from the input condition and the selected "apply to" condition is unbounded.
   *
   * returns true if "apply to" condition should display its max capsule duration field, false if not
   */
  const repeatOverConditionNeedsMaximumDuration = () =>
    repeatOverCondition && !repeatOverCondition?.conditionMetadata?.maximumDuration;

  /**
   * Handler that is executed when the user selects a repeat over condition.
   */
  const setRepeatOverCondition = (repeatOverCondition) => {
    sqInvestigateActions.setParameterItem(TREND_TOOLS.REFERENCE, 'repeatOverCondition', repeatOverCondition);

    if (!repeatOverCondition?.conditionMetadata?.maximumDuration) {
      setDefaultMaximumCapsuleDuration();
    }
  };

  const onMaximumDurationChange = ({ value, units, valid }) => {
    setMaximumDurationValid(valid);
    sqInvestigateActions.setMaximumDuration(TREND_TOOLS.REFERENCE, value, units);
  };

  const STATS = REFERENCE_STATS;
  const STAT_OPTIONS = {
    [STATS.AVERAGE]: {
      hasMultiplier: false,
      label: 'STATISTICS.AVERAGE.LONG',
      text: t('STATISTICS.AVERAGE.LONG'),
      value: STATS.AVERAGE,
      className: 'statOption',
    },
    [STATS.MIN]: {
      hasMultiplier: false,
      label: 'STATISTICS.MINIMUM.LONG',
      text: t('STATISTICS.MINIMUM.LONG'),
      value: STATS.MIN,
      className: 'statOption',
    },
    [STATS.MAX]: {
      hasMultiplier: false,
      label: 'STATISTICS.MAXIMUM.LONG',
      text: t('STATISTICS.MAXIMUM.LONG'),
      value: STATS.MAX,
      className: 'statOption',
    },
    [STATS.AVE_DEV]: {
      hasMultiplier: true,
      label: 'STATISTICS.AVERAGE_DEVIATION.LONG',
      text: t('STATISTICS.AVERAGE_DEVIATION.LONG'),
      value: STATS.AVE_DEV,
      className: 'statOption',
    },
    [STATS.MAX_DEV]: {
      hasMultiplier: true,
      label: 'STATISTICS.MAXIMUM_DEVIATION.LONG',
      text: t('STATISTICS.MAXIMUM_DEVIATION.LONG'),
      value: STATS.MAX_DEV,
      className: 'statOption',
    },
    [STATS.STD_DEV]: {
      hasMultiplier: true,
      text: t('STATISTICS.STANDARD_DEVIATION.LONG'),
      label: 'STATISTICS.STANDARD_DEVIATION.LONG',
      value: STATS.STD_DEV,
      className: 'statOption',
    },
  };

  const getInputDataSetup = () => {
    const InputData: FormElement[] = [];
    const excludeIds = _.map(_.map(inputGroups, 'inputSignal'), 'id');
    _.forEach(inputGroups, (inputConfig, index: number) => {
      const itemIndexSuffix = index ? `${index + 1}` : '';
      InputData.push({
        component: 'RemovableFormGroup',
        name: `removableForm${itemIndexSuffix}`,
        iconAction: () => removeSignalCondition(index),
        hideIcon: inputGroups.length <= 1,
        components: [
          {
            component: 'ItemSelectFormComponent',
            label: 'REFERENCE.INPUT_SIGNAL',
            testId: `inputSignal${itemIndexSuffix}`,
            name: `inputSignal${itemIndexSuffix}_${inputConfig.inputSignal.id}`,
            itemTypes: [ITEM_TYPES.SERIES],
            additionalItems: originalParameters,
            excludedIds: _.filter(excludeIds, (id) => id !== inputConfig.inputSignal.id),
            value: inputConfig.inputSignal.id,
            excludeStringSignals: true,
            onChange: (item: { id: string }) => setTimeout(() => setInputSignal(item.id, index), 0),
          },
          {
            component: 'ItemSelectFormComponent',
            label: 'REFERENCE.INPUT_CONDITION',
            testId: `inputCondition${itemIndexSuffix}`,
            name: `inputCondition${itemIndexSuffix}_${inputConfig.inputCondition.id}`,
            itemTypes: [ITEM_TYPES.CAPSULE_SET],
            additionalItems: originalParameters,
            value: inputConfig.inputCondition.id,
            includeMetadata: true,
            onChange: (condition) => setInputCondition(condition, index),
          },
        ],
      });
    });

    return InputData;
  };

  const includeMaxCapsuleDuration = repeatOverConditionNeedsMaximumDuration();

  const formDataSetup: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'referenceSearchTitle',
      value: name,
      id,
      onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.REFERENCE, name),
      onColorChange: setColor,
      searchIconClass: 'fc-reference',
      defaultName: 'REFERENCE.HEADER',
    },
    {
      component: 'FormGroup',
      name: 'signalConditionPairsFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'LabelFormComponent',
          name: 'signalConditionPairsLabel',
          value: 'FORM.SIGNAL_CONDITION_PAIRS',
        },
        ...getInputDataSetup(),
      ],
    },
    {
      component: 'ClickableLinkFormComponent',
      extraClassNames: 'ml45',
      name: 'addSignalCondition',
      value: 'FORM.ADD_INPUT_PAIR_LINK',
      icon: 'fa-plus-circle',
      linkAction: addSignalCondition,
    },
    {
      component: 'CapsuleInputFormComponent',
      name: 'trainingWindow',
      testId: 'trainingWindow',
      label: 'FORM.TRAINING_WINDOW',
      displayNumber: true,
      value: trainingWindow,
      capsuleWindow: trainingWindow,
      onChange: setTrainingWindow,
      trackCategory: 'Reference Profile',
      trackAction: 'Training Window Change',
      setCapsuleInputValidStatus: setTrainingWindowValid,
      extendValidation: false,
      validation: () => !trainingWindowValid,
    },
    {
      component: 'ValueWithUnitsFormComponent',
      displayNumber: true,
      testId: 'griddingPeriod',
      label: 'REFERENCE.GRIDDING_PERIOD',
      name: 'griddingPeriod',
      value: griddingPeriod,
      min: 1,
      onChange: setGriddingPeriod,
    },
    {
      component: 'FormGroup',
      name: 'referenceStatFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'FormRow',
          name: 'referenceStatFormRow',
          components: [
            {
              component: 'IconSelectFormComponent',
              extraClassNames: 'specReferenceStatic',
              name: 'referenceStat',
              testId: 'referenceStat',
              label: 'REFERENCE.STATISTIC',
              selectOptions: _.omit(_.values(STAT_OPTIONS), ['hasMultiplier']),
              value: STAT_OPTIONS[referenceStat]?.value,
              onChange: (option) => setReferenceStat(option.value),
            },
            {
              component: 'FormControlFormComponent',
              name: 'multiplier',
              testId: 'multiplier',
              label: 'REFERENCE.MULTIPLIER',
              size: 'm',
              type: 'number',
              step: 1,
              value: multiplier,
              onChange: setMultiplier,
              extraClassNames: 'ml5',
              includeIf: STAT_OPTIONS[referenceStat]?.hasMultiplier,
              wrapperClassNames: 'width-100',
            },
          ],
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'applyToConditionAllFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'LabelFormComponent',
          name: 'applyToConditionLabel',
          value: 'REFERENCE.APPLY_TO_CONDITION',
          tooltip: 'REFERENCE.APPLY_TO_HELP',
        },
        {
          component: 'FormGroup',
          name: 'applyToConditionFormGroup',
          showBracket: true,
          components: [
            {
              component: 'ItemSelectFormComponent',
              name: 'repeatOverCondition',
              testId: 'repeatOverCondition',
              itemTypes: [ITEM_TYPES.CAPSULE_SET],
              additionalItems: originalParameters,
              extraClassNames: includeMaxCapsuleDuration ? undefined : 'mb-0',
              value: repeatOverCondition?.id,
              includeMetadata: true,
              onChange: setRepeatOverCondition,
            },
            {
              component: 'MaxCapsuleDurationFormComponent',
              name: 'maximumDuration',
              includeIf: includeMaxCapsuleDuration,
              maxDurationRequired: true,
              value: maximumDuration,
              testId: 'maximumDuration',
              minIsExclusive: true,
              tooltip: 'REFERENCE.MAX_CAPSULE_DURATION_HELP',
              onChange: onMaximumDurationChange,
              extraClassNames: 'mb-0',
            },
          ],
        },
      ],
    },
  ];

  const referenceSearchBuilder = (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={execute}
      closeFn={sqInvestigateActions.close}
      toolId={TREND_TOOLS.REFERENCE}
      submitBtnId="executeReferenceTool"
    />
  );

  return displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT ? referenceSearchBuilder : null;
};

export const sqReferencePanel = angularComponent(referencePanelBindings, ReferencePanel);
