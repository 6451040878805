// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useTranslation } from 'react-i18next';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Overlay, Popover } from 'react-bootstrap';
import { HelpIcon, Icon, IconType } from '@/hybrid/core/Icon.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { HoverIconPair } from '@/hybrid/core/HoverIconPair.atom';
import { ProgressBar } from '@/hybrid/trend/panels/detailsPanel/ProgressBar.atom';
import {
  aggregateTimeItems,
  cancelRequest,
  getRequestDetailsLegend,
  getTooltipInformation,
  handleItemChanges,
  onItemClick as onItemClickSqDetailsPanel,
  orderTimeItems,
} from '@/hybrid/utilities/detailsPanel.utilities';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { TrendActions } from '@/trendData/trend.actions';
import { doTrack } from '@/track/track.service';

const dataStatusIconBindings = bindingsDefinition({
  items: prop<any[]>(),
  header: prop<boolean>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqTrendActions: injected<TrendActions>(),
});

export type RequestProblemType = 'warning' | 'error' | 'metadataError';

export const DataStatusIcon: SeeqComponent<typeof dataStatusIconBindings> = ({ items, header }) => {
  const { sqInvestigateActions, sqTrendActions } = useInjectedBindings(dataStatusIconBindings);

  const { t } = useTranslation();
  const [isInProgress, setIsInProgress] = useState(false);
  const [displayRequestDetails, setDisplayRequestDetails] = useState(false);
  const [displayWrench, setDisplayWrench] = useState(false);
  const [canRetry, setCanRetry] = useState(false);
  const [timingLegend, setTimingLegend] = useState([]);
  const [samplesLegend, setSamplesLegend] = useState([]);
  const [progress, setProgress] = useState(undefined);
  const [icon, setIcon] = useState('');
  const [iconType, setIconType] = useState<IconType>(undefined);
  const [tooltip, setTooltip] = useState('');
  const [translateKeys, setTranslateKeys] = useState({});
  const [problemType, setProblemType] = useState<RequestProblemType>(undefined);
  const [timingLookup, setTimingLookup] = useState([]);
  const [meterLookup, setMeterLookup] = useState([]);
  const [requestDetailsLegend, setRequestDetailsLegend] = useState([]);
  const detailsPopoverRef = useRef(null);
  const rawTimingData = useRef(undefined);
  const rawSamplesData = useRef(undefined);
  const timedItemData = useRef(undefined);

  useEffect(() => {
    onChanges();
  }, [header, items]);

  useEffect(() => {
    if (!rawTimingData.current && !rawSamplesData.current) {
      setRequestDetailsLegend([]);
    } else {
      setRequestDetailsLegend(getRequestDetailsLegend(timingLegend, samplesLegend));
    }
  }, [timingLegend, samplesLegend]);

  const onChanges = () => {
    const {
      icon,
      iconType,
      problemType,
      isInProgress,
      progress,
      tooltip,
      displayWrench,
      translateKeys,
      canRetry,
      timedItem,
    } = handleItemChanges(items, header);

    setIcon(icon);
    setIconType(iconType);
    setProblemType(problemType);
    setIsInProgress(isInProgress);
    setProgress(progress);
    setTooltip(tooltip);
    setDisplayWrench(displayWrench);
    setTranslateKeys(translateKeys || {});
    setCanRetry(canRetry);

    if (header) {
      // Compute aggregate of all requests
      const timeItems = _.filter(timedItem, (ti) => !!ti.timingInformation && !!ti.meterInformation);
      if (timeItems.length > 0) {
        rawTimingData.current = aggregateTimeItems(timeItems, 'timingInformation', parseFloat);
        rawSamplesData.current = aggregateTimeItems(timeItems, 'meterInformation', parseInt);
        timedItemData.current = _.slice(orderTimeItems(timeItems), 0, 3);
      }
    } else {
      rawTimingData.current = timedItem.timingInformation;
      rawSamplesData.current = timedItem.meterInformation;
    }
  };

  const onItemClick = () => {
    setDisplayRequestDetails(false);
    rawTimingData.current = undefined;
    rawSamplesData.current = undefined;
    setTimingLegend([]);
    setSamplesLegend([]);

    onItemClickSqDetailsPanel(sqInvestigateActions, sqTrendActions, items);
  };

  const openPopoverAndTrack = (e) => {
    e.stopPropagation();
    setDisplayRequestDetails(!displayRequestDetails);
    doTrack('Request Details', 'Opened');
  };

  const getTooltip = (item, lookup) => (
    <div className="flexRowContainer">
      {_.map(getTooltipInformation(item, requestDetailsLegend, lookup), (displayString, index) => (
        <span className="noWrap" key={index}>
          {displayString}
        </span>
      ))}
    </div>
  );

  const requestDetailsHeader = (item) => {
    const assets = _.chain(item.assets ?? [])
      .map((a) => a.name)
      .join(', ')
      .value();
    return `${item.name}${assets ? ` [${assets}]` : ''}`;
  };

  return (
    <>
      {displayRequestDetails && (
        <Overlay
          onHide={() => setDisplayRequestDetails(false)}
          target={detailsPopoverRef?.current}
          show={true}
          rootClose={true}
          transition={false}>
          <Popover
            id="requestDetailsPopover"
            data-testid="requestDetailsPopover"
            className="large"
            onClick={(e) => e.stopPropagation()}>
            <div className="p10">
              <div className="width-maximum">
                <div className="flexColumnContainer width-maximum pt3">
                  <div className="requestDetailsTitle text-center text-truncate flexFill pl10 pr10 pb15">
                    {t('REQUEST_DETAILS.HEADER', {
                      item: header ? t('HOME_SCREEN.LOCATION.ALL') : requestDetailsHeader(items[0]),
                    })}
                  </div>
                  <Icon
                    icon="fa-close"
                    extraClassNames="cursorPointer"
                    type="text"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDisplayRequestDetails(false);
                    }}
                  />
                </div>
              </div>
              <div className="flexRowContainer flexAlignCenter width-maximum">
                <div className="flexRowContainer flexAlignCenter">
                  <ProgressBar
                    informationString={rawTimingData.current}
                    displayTime={true}
                    testId="timeProgressBar"
                    labelKey="REQUEST_DETAILS.TIME"
                    setLegend={setTimingLegend}
                    tooltipLookup={timingLookup}
                    setTooltipLookup={setTimingLookup}
                    getTooltipFn={getTooltip}
                  />

                  <ProgressBar
                    informationString={rawSamplesData.current}
                    displayTime={false}
                    displayDataSum={true}
                    testId="dataReadProgressBar"
                    labelKey="REQUEST_DETAILS.DATA_READ"
                    setLegend={setSamplesLegend}
                    getTooltipFn={getTooltip}
                    tooltipLookup={meterLookup}
                    setTooltipLookup={setMeterLookup}
                    existingColors={true}
                  />

                  <div className="flexColumnContainer">
                    {_.map(requestDetailsLegend, (legend) => (
                      <HoverTooltip
                        text="requestDetailsTooltip"
                        formattedText={getTooltip(legend, _.concat(timingLookup, meterLookup))}
                        key={`${legend.label}-tooltip`}>
                        <div className="flexColumnContainer mt3 mr20" key={legend.label}>
                          <div
                            className="requestDetailsLegendSwatch mt3 mr5"
                            style={{ backgroundColor: legend.color }}
                          />
                          <div>{legend.label}</div>
                        </div>
                      </HoverTooltip>
                    ))}
                  </div>
                </div>

                {!header && (
                  <div className="flexColumnContainer width-maximum flexAlignCenter flexFill">
                    <div className="flexColumnContainer flexFill flexCenter">
                      {!_.isUndefined(problemType) && (
                        <div
                          className={classNames(
                            'flexColumnContainer p10 mt10 overflowAuto',
                            problemType === 'warning' ? 'sq-bg-warning' : 'sq-bg-error',
                          )}>
                          <Icon icon={icon} type={iconType} extraClassNames="fa-fw mt3" testId="popoverProblemIcon" />
                          <span className="ml5">{t(tooltip, translateKeys ? translateKeys : null)}</span>
                        </div>
                      )}
                    </div>
                    <div className="flexColumnContainer flexAlignCenter flexJustifyEnd flexNoGrowNoShrink">
                      {isInProgress && !_.includes(['error', 'warning'], problemType) && (
                        <>
                          <span className="ml5 text-italic">{t('REQUEST_DETAILS.REQUEST_IN_PROGRESS')}</span>
                          <Icon
                            icon="fa-stop-circle"
                            testId="stopIcon"
                            extraClassNames="ml5 btn-transparent fa-xlg"
                            tooltip="DATA_STATUS_ICON_TOOLTIP.LOADING"
                            onClick={() => cancelRequest(items)}
                          />
                        </>
                      )}

                      {!isInProgress && problemType === 'error' && canRetry && (
                        <Icon
                          icon="fc-redo"
                          testId="retryIcon"
                          large={true}
                          tooltip="DATA_STATUS_ICON_TOOLTIP.RETRY"
                          onClick={onItemClick}
                          extraClassNames="ml5 btn-transparent"
                        />
                      )}

                      {!isInProgress && displayWrench && (
                        <Icon
                          icon="fa-wrench"
                          testId="wrenchIcon"
                          tooltip="DATA_STATUS_ICON_TOOLTIP.LOAD_TOOL"
                          extraClassNames="fa-xlg ml5 btn-transparent"
                          onClick={onItemClick}
                        />
                      )}

                      <a
                        href="https://telemetry.seeq.com/support-link/wiki/spaces/KB/pages/316997712/Request+Details"
                        target="_blank">
                        <HelpIcon
                          testId="helpIcon"
                          extraClassNames="btn-transparent cursorPointer fa-xlg ml5"
                          tooltip="REQUEST_DETAILS.MORE_INFORMATION"
                        />
                      </a>
                    </div>
                  </div>
                )}
              </div>

              {header && (
                <table data-testid="mostDemandingRequests" className="marginAuto mt20">
                  <thead>
                    <tr>
                      <td className="pt10 pb10 text-center font-size-larger" colSpan={3}>
                        {t('REQUEST_DETAILS.MOST_DEMANDING_REQUESTS')}
                      </td>
                    </tr>
                    <tr>
                      <td className="pr10 text-bold">{t('REQUEST_DETAILS.ITEM')}</td>
                      <td className="pr10 text-center text-bold">{t('REQUEST_DETAILS.DURATION')}</td>
                      <td className="pr10 text-center text-bold">{t('REQUEST_DETAILS.SAMPLES')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(timedItemData.current ?? [], (itemData) => (
                      <tr key={itemData[0].id}>
                        <td className="pr10 text-truncate max-width-360">{requestDetailsHeader(itemData[0])}</td>
                        <td className="pr10 text-center">{itemData[1]}</td>
                        <td className="pr10 text-center">{itemData[2]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {header && (
                <div className="flexColumnContainer flexAlignCenter flexJustifyEnd flexNoGrowNoShrink">
                  <a
                    href="https://telemetry.seeq.com/support-link/wiki/spaces/KB/pages/316997712/Request+Details"
                    target="_blank">
                    <HelpIcon
                      testId="helpIcon"
                      extraClassNames="btn-transparent cursorPointer fa-xlg ml5"
                      tooltip="REQUEST_DETAILS.MORE_INFORMATION"
                    />
                  </a>
                </div>
              )}
            </div>
          </Popover>
        </Overlay>
      )}

      {header && (
        <>
          {isInProgress && (
            <HoverIconPair
              tooltipProps={{ text: 'DATA_STATUS_ICON_TOOLTIP.LOADING' }}
              iconProps={{
                icon: 'fa-circle-o-notch',
                extraClassNames: 'fa-spin dataStatusIconHeader',
                large: true,
                testId: 'headerProgressIcon',
              }}
              hoverIconProps={{
                icon: 'fa-stop-circle',
                onClick: () => cancelRequest(items),
                large: true,
                testId: 'headerCancelIcon',
              }}
            />
          )}

          {!isInProgress && problemType === 'error' && (
            <HoverIconPair
              tooltipProps={{ text: 'DATA_STATUS_ICON_TOOLTIP.RETRY' }}
              iconProps={{ icon, type: iconType, testId: 'headerErrorIcon' }}
              hoverIconProps={{
                icon: 'fc-redo',
                onClick: onItemClick,
                testId: 'headerRedoIcon',
              }}
            />
          )}
        </>
      )}

      {(!header || (header && !isInProgress)) && (
        <span className="noOutline verticalAlignMiddle" ref={detailsPopoverRef}>
          {isInProgress && progress >= 1 && !_.includes(['warning', 'error'], problemType) && (
            <HoverTooltip text="DATA_STATUS_ICON_TOOLTIP.LOADING_ITEM">
              <span
                className="dataStatusIcon verticalAlignMiddle ml2"
                onClick={openPopoverAndTrack}
                data-testid="progressBar">
                <CircularProgressbar value={progress} text={progress.toString()} />
              </span>
            </HoverTooltip>
          )}

          {isInProgress && progress < 1 && (
            <Icon
              icon="fa-circle-o-notch"
              large={true}
              testId="loadingItemIcon"
              extraClassNames="fa-fw fa-spin"
              onClick={openPopoverAndTrack}
              tooltip="DATA_STATUS_ICON_TOOLTIP.LOADING_ITEM"
            />
          )}

          {!isInProgress && (header || _.isUndefined(problemType)) && problemType !== 'error' && (
            <Icon
              icon="fa-rocket"
              extraClassNames="btn-transparent"
              testId="requestDetailsIcon"
              onClick={openPopoverAndTrack}
              tooltip="DATA_STATUS_ICON_TOOLTIP.CLICK_FOR_REQUEST_DETAILS"
            />
          )}

          {!_.isUndefined(problemType) && !header && (
            <HoverIconPair
              tooltipProps={{
                text: 'DATA_STATUS_ICON_TOOLTIP.CLICK_FOR_DETAILS',
              }}
              iconProps={{
                icon,
                type: iconType,
                extraClassNames: 'mb3',
                testId: 'clickForDetailsIcon',
              }}
              hoverIconProps={{
                icon: 'fa-rocket',
                onClick: openPopoverAndTrack,
                testId: 'clickForDetailsRocket',
              }}
            />
          )}
        </span>
      )}
    </>
  );
};

export const sqDataStatusIcon = angularComponent(dataStatusIconBindings, DataStatusIcon);
