import React, { ChangeEvent, useEffect, useState } from 'react';
import _ from 'lodash';
import FormulaParametersTable, { FormulaEditorParam } from '@/hybrid/formula/FormulaParametersTable.molecule';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { getColumns, getDefaultAttributeName } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { addTextAtCursor, getNextFormulaIdentifier } from '@/hybrid/formula/formula.utilities';
import { AddParameterBtnAndModal } from '@/hybrid/formula/AddParameterBtnAndModal.atom';
import { FormulaEditor, FormulaErrorInterface } from '@/hybrid/formula/FormulaEditor.molecule';
import { useTranslation } from 'react-i18next';
import { FormControl } from 'react-bootstrap';
import { FormulaDocumentation } from '@/hybrid/formula/FormulaDocumentation.organism';
import { FormulaDocSummaryOutputV1, sqFormulasApi } from '@/sdk';
import { ShowFormulaHelp } from '@/hybrid/formula/ShowFormulaHelp.atom';
import { getMediumIdentifier } from '@/hybrid/utilities/utilities';
import { warnToast } from '@/hybrid/utilities/toast.utilities';
import { useIsMounted } from '@/hybrid/core/hooks/useIsMounted.hook';
import { sqAssetGroupStore } from '@/core/core.stores';
import { AssetGroupAsset, AssetGroupChild } from '@/hybrid/assetGroupEditor/assetGroup.types';
import { findAtLeastOneChild, isValidColumnName } from '@/hybrid/assetGroupEditor/assetGroup.utilities';

const formulaFromScratchBindings = bindingsDefinition({
  fromExisting: prop.optional<boolean>(),
  existingParameters: prop.optional<any[]>(),
  item: prop.optional<AssetGroupChild>(),
  exposeParametersToParent: prop<(params: FormulaEditorParam[]) => void>(),
  exposeFormulaToParent: prop<(formula: string) => void>(),
  exposeCalculationColumnNameToParent: prop.optional<(name: string) => void>(),
  validateFormula: prop.optional<() => void>(),
  showColumnNameField: prop.optional<boolean>(),
  formulaErrors: prop.optional<FormulaErrorInterface[] | undefined>(),
  setFormulaErrorsFn: prop.optional<(formulaErrors: any) => void>(),
  asset: prop.optional<AssetGroupAsset>(),
});

export const FormulaFromScratch: SeeqComponent<typeof formulaFromScratchBindings> = ({
  fromExisting = false,
  existingParameters = [],
  item, // item.name is the column name!
  exposeParametersToParent,
  exposeFormulaToParent,
  exposeCalculationColumnNameToParent,
  validateFormula = _.noop,
  formulaErrors,
  setFormulaErrorsFn,
  showColumnNameField = false,
}) => {
  const { t } = useTranslation();

  const formula = item?.formula ?? '';
  const isMounted = useIsMounted();
  const assets = sqAssetGroupStore.assets;

  const [editor, setEditor] = useState(undefined);
  const [calculationColumnName, setCalculationColumnName] = useState(getDefaultAttributeName());
  const [constants, setConstants] = useState<string[]>([]);
  const [operators, setOperators] = useState<FormulaDocSummaryOutputV1[] | undefined>([]);
  const [formulaHelpExpanded, setFormulaHelpExpanded] = useState(true);

  const [parameters, setParameters] = useState<FormulaEditorParam[]>([]);

  const toggleFormulaHelp = () => setFormulaHelpExpanded(!formulaHelpExpanded);
  console.log(getColumns());
  const columns = _.chain(getColumns())
    // we need at least one child so we can validate the formula
    .filter((column) => findAtLeastOneChild(assets, column.name))
    // only display columns as inputs that have been saved before (or are not calculations) - as we need an id to
    // run the formula for validation, and right now we can't validate a formula based on a formula without saving
    .filter((column) => column.columnType !== 'Calculation' || !_.isEmpty(column.id))
    // don't show the column we're editing as an input
    .filter((column) => column?.name !== item?.name)
    .value();

  const assetGroupColumnsAsParameters = _.chain(columns)
    .map((column) => ({
      id: column.name,
      name: column.name,
      iconClass: 'fa-columns',
      selectGroup: 'assetGroupColumn',
    }))
    .value();

  // Parameters that are currently used by the calculation. some of those parameters can be external
  // parameters and some of them can be asset group column based. We do not want to duplicate asset group column
  // based parameters in the select so be sure to filter those out.
  const itemsFromExisting = _.chain(existingParameters)
    .map((parameter) => {
      if (parameter.assetGroupColumnBased) {
        // we can get into a strange state if we delete a calculated input param and then re-add one with the same
        // name. To cover that use-case see if we can find teh asset group based parameter in the parameters if it
        // is still an option.
        const columnParameterAdded = _.find(assetGroupColumnsAsParameters, { name: parameter.item.name });
        if (!columnParameterAdded) {
          const child = findAtLeastOneChild(assets, parameter.item.name);
          if (child && !_.isEmpty(child.parameters)) {
            return { ...parameter.item, selectGroup: 'assetGroupColumn' };
          }
        }
        return null; // it's already part of the columns because of the column based parameters
      } else {
        return { ...parameter.item, selectGroup: 'original' };
      }
    })
    .compact()
    .value();

  // select items should always show ALL the columns + external variables that have been assigned
  const [selectItems, setSelectItems] = useState(_.concat(assetGroupColumnsAsParameters, itemsFromExisting));

  const columnNames = _.map(assetGroupColumnsAsParameters, 'name');

  useEffect(() => {
    // when an existing calculation is first added or if a calculation was saved using asset group based columns we
    // need to ensure that the asset group column parameters are displayed as selected
    const properlyReplacedExistingParameters = _.map(existingParameters, (parameter) => {
      const itemName = parameter.item?.name;
      if (_.includes(columnNames, itemName) && (fromExisting || parameter.assetGroupColumnBased)) {
        const column = _.find(assetGroupColumnsAsParameters, { name: itemName });
        return { ...parameter, item: { name: itemName, id: column?.id, selectGroup: 'assetGroupColumn' } };
      }
      return parameter;
    });

    const namesToAvoid = _.map(properlyReplacedExistingParameters, 'identifier');
    // now add parameters for the columns
    // this makes sure we don't overwrite the parameters that were assigned to the formula!
    const newParams = _.map(columns, (selectItem) => {
      // if an existing item already maps to columns we don't want to duplicate that
      const alreadyIncludedParam = _.find(
        properlyReplacedExistingParameters,
        (param) => param.item.name === selectItem.name,
      );
      if (!alreadyIncludedParam) {
        let paramName = getMediumIdentifier(selectItem.name, namesToAvoid);
        if (_.isEmpty(paramName)) {
          paramName = getNextFormulaIdentifier(selectItem);
        }
        namesToAvoid.push(paramName);
        return {
          name: paramName,
          identifier: paramName,
          item: { id: selectItem.name, name: selectItem.name },
        };
      }
    });

    updateParameters(
      _.chain([...newParams, ...properlyReplacedExistingParameters])
        .orderBy('identifier')
        .compact()
        .value(),
    );
  }, []);

  const updateParameters = (params: FormulaEditorParam[]) => {
    setParameters(params);
    exposeParametersToParent(params);
  };

  useEffect(() => {
    sqFormulasApi.getConstantNameList().then(({ data }) => isMounted.current && setConstants(data));
    sqFormulasApi.getFormulaDocs({}).then(({ data }) => isMounted.current && setOperators(data?.functions));
  }, []);

  const insertFormulaSnippet = (snippet: string) => addTextAtCursor(snippet, editor);

  const addParameter = ({ name, item, identifier }: FormulaEditorParam) => {
    if (_.some(parameters as any, { identifier })) {
      warnToast({ messageKey: 'FORMULA.VARIABLE_UNIQUE' });
    } else {
      setSelectItems([...selectItems, { ...item, selectGroup: 'original' }]);
      updateParameters(_.orderBy([...parameters, { name, item, identifier }], 'identifier'));
    }
  };

  const updateParameter = (updatedParameter: FormulaEditorParam, originalParameter: FormulaEditorParam) => {
    setSelectItems([...selectItems, updatedParameter.item]);
    updateParameters(
      _.orderBy(
        [..._.reject(parameters, { identifier: originalParameter.identifier }), updatedParameter],
        'identifier',
      ),
    );
  };

  const removeParameter = (identifier: string) => {
    updateParameters(_.reject(parameters, { identifier }));
  };

  const onItemSelect = (parameterIndex: number, item: any) => {
    const params = [...parameters];
    params[parameterIndex].item = item.item;
    updateParameters(params);
  };

  const updateColumnName = (event: ChangeEvent<HTMLInputElement>) => {
    const calculationName = event.target.value;

    setCalculationColumnName(calculationName);
    _.isFunction(exposeCalculationColumnNameToParent) && exposeCalculationColumnNameToParent(calculationName);
  };

  const columnNameError = !isValidColumnName(sqAssetGroupStore.assets, calculationColumnName);

  return (
    <div className="flexColumnContainer max-height-650 mb10">
      <div className="flexRowContainer flexFill mr15">
        {showColumnNameField && (
          <div className="flexColumnContainer flexCenter">
            <div className="flexRowContainer searchTitleInput flexFill mb15">
              <FormControl
                value={calculationColumnName}
                placeholder={t('ASSET_GROUP_EDITOR.CALCULATION_COLUMN_NAME')}
                name="calculationColumnName"
                onChange={updateColumnName}
                isInvalid={columnNameError}
              />
            </div>
            {!formulaHelpExpanded && (
              <ShowFormulaHelp showColumnNameField={showColumnNameField} onClick={toggleFormulaHelp} />
            )}
          </div>
        )}
        <div className="flexColumnContainer flexCenter mb15">
          <div className="flexFill">
            <h4>{t('ASSET_GROUP_EDITOR.VARIABLES')}</h4>
          </div>
          <div>
            <AddParameterBtnAndModal
              tooltip="FORMULA.TOOLTIP_SEARCH"
              parameters={parameters}
              addParameter={addParameter}
            />
          </div>
          {!showColumnNameField && !formulaHelpExpanded && (
            <ShowFormulaHelp showColumnNameField={showColumnNameField} onClick={toggleFormulaHelp} />
          )}
        </div>

        <div className="max-height-160 overflowYAuto mb15">
          <FormulaParametersTable
            parameters={parameters}
            updateParameterCallback={updateParameter}
            removeParameterCallback={removeParameter}
            insertParameter={(param) => addTextAtCursor(param, editor)}
            additionalItems={selectItems}
            excludeStoreItems={true}
            includeAddToDisplayPane={false}
            onItemSelect={onItemSelect}
          />
        </div>

        <FormulaEditor
          formula={formula}
          operators={operators as any}
          constants={constants}
          parameters={parameters}
          exposeFormulaToParent={exposeFormulaToParent}
          exposeEditorToParent={setEditor}
          onSave={validateFormula}
          showLineNumbers={true}
          formulaErrors={formulaErrors}
          setFormulaErrors={setFormulaErrorsFn}
        />
      </div>

      {formulaHelpExpanded && (
        <div className="max-width-360 pb50 formulaHelp">
          <FormulaDocumentation
            formulaHelpToggleFunction={toggleFormulaHelp}
            operators={operators}
            functions={_.filter(operators, (operator) => operator.name?.indexOf('()') !== -1)}
            insertFormulaSnippet={insertFormulaSnippet}
          />
        </div>
      )}
    </div>
  );
};
