import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { getAllItems } from '@/hybrid/trend/trendDataHelper.utilities';
import { makeLink } from '@/hybrid/annotation/Journal.utilities';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { ITEM_TYPES, TREND_VIEWS } from '@/trendData/trendData.constants';
import {
  createCapsuleLink,
  createItemLink,
  createItemLinkName,
  createTimeRangeLink,
  createWorkstepLink,
} from '@/hybrid/utilities/journalLink.utilities';
import {
  sqDurationStore,
  sqTrendCapsuleSetStore,
  sqTrendCapsuleStore,
  sqTrendMetricStore,
  sqTrendScalarStore,
  sqTrendSeriesStore,
  sqTrendStore,
  sqTrendTableStore,
  sqWorkstepsStore,
} from '@/core/core.stores';
import { isViewOnlyWorkbookMode } from '@/hybrid/utilities/utilities';
import { angularComponent } from '@/hybrid/core/react2angular.util';

const journalLinkBindings = bindingsDefinition({
  onLink: prop<(link: string) => void>(),
});

export const JournalLink: SeeqComponent<typeof journalLinkBindings> = ({ onLink }) => (
  <div className="flexNoGrowNoShrink flexRowContainer width-425">
    <WorkstepJournalLink onLink={onLink} />
    <ItemJournalLink onLink={onLink} />
    <TimeRangeJournalLink onLink={onLink} />
    <CapsuleJournalLink onLink={onLink} />
  </div>
);

export const sqJournalLink = angularComponent(journalLinkBindings, JournalLink);

//region JournalLink sub-components
const workstepJournalLinkBindings = bindingsDefinition({
  onLink: prop<(link: string) => void>(),
  $state: injected<ng.ui.IStateService>(),
});

const WorkstepJournalLink: SeeqComponent<typeof workstepJournalLinkBindings> = (props) => {
  const { onLink } = props;
  const { $state } = useInjectedBindings(workstepJournalLinkBindings);

  const { t } = useTranslation();
  useFlux(sqWorkstepsStore);

  const isViewMode = isViewOnlyWorkbookMode();
  const currentWorkstep = createWorkstepLink($state);

  return (
    <>
      {!isViewMode && (
        <>
          <div className="p2 pl5 backgroundColorOffWhite">{t('JOURNAL.ENTRY.LINK_WORKSTEP')}</div>
          <div className="flexColumnContainer flexNoGrowNoShrink flexWrap pl5 pr5 pt1 pb1">
            {makeLink(currentWorkstep, onLink)}
          </div>
        </>
      )}
    </>
  );
};

interface TimeRangeJournalLinkProps {
  onLink: (link: string) => void;
}

const TimeRangeJournalLink: React.FunctionComponent<TimeRangeJournalLinkProps> = ({ onLink }) => {
  const { t } = useTranslation();

  const { displayRange, investigateRange } = useFlux(sqDurationStore);
  const selectedRegion = useFluxPath(sqTrendStore, () => sqTrendStore.selectedRegion);
  const isCapsuleTime = useFluxPath(sqTrendStore, () => sqTrendStore.view === TREND_VIEWS.CAPSULE);

  const displayRangeName = t('DISPLAY_RANGE');
  const investigateRangeName = t('INVESTIGATE_RANGE');
  const selectedRegionName = t('SELECTED_REGION');

  const timeRanges = [
    createTimeRangeLink({
      label: displayRangeName,
      start: displayRange.start.valueOf(),
      end: displayRange.end.valueOf(),
    }),
    createTimeRangeLink({
      label: investigateRangeName,
      start: investigateRange.start.valueOf(),
      end: investigateRange.end.valueOf(),
    }),
  ];

  if (sqTrendStore.isRegionSelected() && !isCapsuleTime) {
    timeRanges.push(
      createTimeRangeLink({
        label: selectedRegionName,
        start: selectedRegion.min.valueOf(),
        end: selectedRegion.max.valueOf(),
      }),
    );
  }

  return (
    <>
      <div className="p2 pl5 backgroundColorOffWhite">{t('JOURNAL.ENTRY.LINK_RANGES')}</div>
      <div className="flexColumnContainer flexNoGrowNoShrink flexWrap pl5 pr5 pt1 pb1">
        {_.map(timeRanges, (timeRange) => makeLink(timeRange, onLink))}
      </div>
      {timeRanges?.length < 3 && (
        <div className="p2 pl5 sq-darkish-gray">{t('JOURNAL.ENTRY.LINK_NO_SELECTED_RANGE')}</div>
      )}
    </>
  );
};

interface CapsuleJournalLinkProps {
  onLink: (link: string) => void;
}

const CapsuleJournalLink: React.FunctionComponent<CapsuleJournalLinkProps> = ({ onLink }) => {
  const { t } = useTranslation();

  useFlux(sqTrendCapsuleStore);
  const { items: conditions } = useFlux(sqTrendCapsuleSetStore);

  const capsules = _.chain(sqTrendCapsuleStore.items)
    .filter('selected')
    .map((capsule) =>
      createCapsuleLink({
        conditionId: capsule.isChildOf,
        start: capsule.startTime,
        end: capsule.endTime,
      }),
    )
    .value();

  return (
    <>
      <div className="p2 pl5 backgroundColorOffWhite">{t('JOURNAL.ENTRY.LINK_CAPSULES')}</div>
      <div className="flexColumnContainer flexNoGrowNoShrink flexWrap pl5 pr5 pt1 pb1">
        {_.map(capsules, (item) => makeLink(item, onLink))}
      </div>
      {!capsules?.length && conditions?.length > 0 && (
        <div className="p2 pl5 sq-darkish-gray">{t('JOURNAL.ENTRY.LINK_NO_CAPSULES')}</div>
      )}
      {!capsules?.length && conditions?.length === 0 && (
        <div className="p2 pl5 sq-darkish-gray">{t('JOURNAL.ENTRY.LINK_NO_CONDITIONS_OR_CAPSULES')}</div>
      )}
    </>
  );
};

interface ItemJournalLinkProps {
  onLink: (link: string) => void;
}

const ItemJournalLink: React.FunctionComponent<ItemJournalLinkProps> = ({ onLink }) => {
  const { t } = useTranslation();

  useFlux(sqTrendCapsuleSetStore);
  useFlux(sqTrendSeriesStore);
  useFlux(sqTrendScalarStore);
  useFlux(sqTrendMetricStore);
  useFlux(sqTrendTableStore);

  const items = _.chain(getAllItems({}))
    .groupBy((item) => item.itemType)
    .mapValues((items) =>
      _.map(items, (item) => ({
        name: createItemLinkName(item),
        link: createItemLink(item),
      })),
    )
    .value();

  return (
    <>
      <div className="p2 pl5 backgroundColorOffWhite">{t('JOURNAL.ENTRY.LINK_DETAILS_ITEMS')}</div>
      <div className="flexColumnContainer flexNoGrowNoShrink flexWrap pl5 pr5 pt1 pb1">
        {_.map(items[ITEM_TYPES.SERIES], (item) => makeLink(item, onLink))}
        {_.map(items[ITEM_TYPES.SCALAR], (item) => makeLink(item, onLink))}
        {_.map(items[ITEM_TYPES.CAPSULE_SET], (item) => makeLink(item, onLink))}
        {_.map(items[ITEM_TYPES.TABLE], (item) => makeLink(item, onLink))}
        {_.map(items[ITEM_TYPES.METRIC], (item) => makeLink(item, onLink))}
      </div>
      {_.isEmpty(items[ITEM_TYPES.SERIES]) && (
        <div className="p2 pl5 sq-darkish-gray">{t('JOURNAL.ENTRY.LINK_NO_SIGNALS')}</div>
      )}
      {_.isEmpty(items[ITEM_TYPES.CAPSULE_SET]) && (
        <div className="p2 pl5 sq-darkish-gray">{t('JOURNAL.ENTRY.LINK_NO_CONDITIONS')}</div>
      )}
    </>
  );
};
//endregion
