// @ts-strict-ignore
import { MIME_TYPES } from '@/main/app.constants';
import _ from 'lodash';
import { sqExportApi, sqItemsApi, sqReportsApi } from '@/sdk';
import { getAxiosInstance } from '@/hybrid/requests/axios.utilities';
import { sqDurationStore, sqWorkbookStore } from '@/core/core.stores';
import { AxiosResponse } from 'axios';
import { doTrack } from '@/track/track.service';

/**
 * Export a set of items to an Excel worksheet.
 *
 * @param {String} exportName - Name for this export operation
 * @param {Object[]} items - Array of items to export
 * @param {String} items[].id - ID of item to export
 * @param {Object} range - range of time to use for exporting
 * @param {Moment} range.start - start of the time range to export for the items
 * @param {Moment} range.end - end of the time range to export for the items
 * @param {Boolean} statisticsEnabled - Boolean flag whether or not summary statistics are returned
 * @param {Boolean} gridEnabled - Boolean flag whether or not the data grid is returned
 * @param {String} gridSize - Size of grid, can be automatic or user defined (ex. '1 min')
 * @param {String} gridOrigin - The origin timestamp or null if not used
 * @param {Boolean} capsuleTime - Boolean flag whether or not capsule time is active
 * @param {Boolean} chainView- Boolean flag whether or not chain view is active
 * @param {String} [cancellationGroup] - A group name that can be used to cancel the requests
 * @param {Boolean} originalTimestampsEnabled - Boolean flag indicating whether to return original timestamps
 * @param {String} timeZone - The time zone to use for the export
 * @param {String} worksheetLink - View-only link of the worksheet that is to be exported
 * @returns {promise} Promise that resolves when the response is returned. Promise resolves to a
 *                    Blob containing the bytes to write.
 */
export function toExcel(
  exportName,
  items,
  range,
  statisticsEnabled,
  gridEnabled,
  gridSize,
  gridOrigin,
  capsuleTime,
  chainView,
  cancellationGroup,
  originalTimestampsEnabled,
  timeZone,
  worksheetLink,
) {
  const start = range.start.toISOString();
  const end = range.end.toISOString();
  return sqExportApi
    .createExport(
      {
        items: _.map(items, ({ id }) => ({ id, start, end, name: exportName })),
        format: 'xlsx',
        statisticsEnabled,
        gridEnabled,
        originalTimestampsEnabled,
        gridSize,
        gridOrigin,
        capsuleTime,
        chainView,
        exportName,
        timeZone,
        worksheetLink,
      },
      {
        headers: { Accept: MIME_TYPES.EXCEL },
        responseType: 'arraybuffer',
        cancellationGroup,
      },
    )
    .then((response) => {
      doTrack('Export', 'Excel', 'complete');
      return response as AxiosResponse<Blob>;
    })
    .catch(function (error) {
      doTrack('Export', 'Excel', 'error');

      // Replace the arraybuffer data with a JSON equivalent. This helps with correctly displaying the
      // error message in the UI, through the notifications service.
      if (error.data) {
        const decodedString = String.fromCharCode.apply(null, new Uint8Array(error.data));
        error.data = _.attempt(JSON.parse, decodedString);
      }

      return Promise.reject(error);
    });
}

/**
 * Export a set of items to OData.
 *
 * @param {string} exportName - Name for this export operation
 * @param {Object[]} items - Array of items to export
 * @param {string} items[].id - ID of item to export
 * @param {Object} range - range of time to use for exporting
 * @param {moment} range.start - start of the time range to export for the items
 * @param {moment} range.end - end of the time range to export for the items
 * @param {boolean} autoupdateTimeRange - Boolean flag whether time range should be updated to 'now' before
 * the export is started
 * @param {boolean} statisticsEnabled - Boolean flag whether or not statistics are enabled
 * @param {boolean} capsuleTime - Boolean flag whether or not capsule time is active
 * @param {Boolean} chainView - Boolean flag whether or not chain view is active
 * @param {boolean} exportCapsules - Boolean flag whether or not to export capsule table
 * @param {string} gridSize -  The desired sample period for the export. An automatic grid size is used when gridSize
 * is set to 'false'
 * @param {String} gridOrigin - The origin timestamp or null if not used
 * @param {String} timeZone - The time zone to use for the export
 * @param {string} scopedTo - ID of the workbook that this export was created from
 * @param {string} worksheetLink - View-only link of the worksheet that is to be exported
 * @returns {promise} Promise that resolves when the response is returned. Promise resolves to a
 *                    JSON text containing an OData endpoint.
 */
export function toOData(
  exportName,
  items,
  range,
  autoupdateTimeRange,
  statisticsEnabled,
  capsuleTime,
  chainView,
  exportCapsules,
  gridSize,
  gridOrigin,
  timeZone,
  worksheetLink,
) {
  const start = range.start.toISOString();
  const end = range.end.toISOString();
  return sqExportApi
    .createExport({
      items: _.map(items, ({ id }) => ({ id, start, end, name: exportName })),
      format: 'odata',
      statisticsEnabled,
      autoupdateTimeRange,
      capsuleTime,
      exportName,
      chainView,
      exportCapsules,
      gridSize,
      gridOrigin,
      timeZone,
      scopedTo: sqWorkbookStore.workbookId,
      worksheetLink,
    })
    .then((response) => {
      doTrack('Export', 'OData', 'urls retrieved');
      return response;
    })
    .catch(function (e) {
      doTrack('Export', 'OData', 'error');
      return Promise.reject(e);
    });
}

/**
 * Export a set of items to PIVision.
 *
 * @param {Object[]} items - Array of items to export
 * @param {String} items[].id - ID of item to export
 * @param {String} items[].start - ISO 8601 string for the start of the time range to export for this item
 * @param {String} items[].end - ISO 8601 string for the end of the time range to export for this item
 * @param {Boolean} statisticsEnabled - Boolean flag whether or not statistics are enabled
 * @param {Boolean} capsuleTime - Boolean flag whether or not capsule time is active
 * @returns {promise} Promise that resolves when the response is returned. Promise resolves to a
 *                    JSON text containing a PI Vision endpoint.
 */
export function toPIVision(items) {
  const startTime = sqDurationStore.displayRange.start.toISOString();
  const endTime = sqDurationStore.displayRange.end.toISOString();

  return _.chain(items)
    .map((item) => sqItemsApi.getItemAndAllProperties({ id: item.id }).then(({ data }) => data))
    .thru((promises) => Promise.all(promises))
    .value()
    .then((items) => {
      const dataItems = _.chain(items)
        .map(function (item) {
          const piPath = _.get(_.find(item.properties, ['name', 'PI Path']), 'value', undefined);
          const afPath = _.get(_.find(item.properties, ['name', 'AF Path']), 'value', undefined);
          return afPath || piPath;
        })
        .compact()
        .join(';')
        .value();
      return {
        queryString: `Displays/AdHoc?DataItems=${dataItems}&StartTime=${startTime}&EndTime=${endTime}`,
      };
    })
    .then((response) => {
      doTrack('Export', 'PiVision', 'complete');
      return response;
    })
    .catch(function (e) {
      doTrack('Export', 'PiVision', 'error');
      return Promise.reject(e);
    });
}

/**
 * Exports a set of items to a PowerPoint report
 *
 * @param  {String} title - Title for the report. If empty, no title slide is generated
 * @param  {Object[]} worksheets - Array of Worksheet items to include
 * @return {Promise} Promise that resolves when the response is returned. Promise resolves to a
 *                    Blob containing the bytes to write.
 */
export function toPowerpoint(title, worksheets) {
  return sqReportsApi
    .createReport(
      {
        items: worksheets,
        format: 'pptx',
        title,
      },
      {
        headers: { Accept: MIME_TYPES.POWERPOINT },
        responseType: 'arraybuffer',
      },
    )
    .then((response) => {
      doTrack('Export', 'Powerpoint', 'complete');
      return response;
    })
    .catch(function (error) {
      doTrack('Export', 'Powerpoint', 'error');
      // Replace the arraybuffer data with a JSON equivalent. This helps with correctly displaying the
      // error message in the UI, through the notifications service.
      if (error.data) {
        const decodedString = String.fromCharCode.apply(null, new Uint8Array(error.data));
        error.data = _.attempt(JSON.parse, decodedString);
      }

      return Promise.reject(error);
    });
}

/**
 * Exports a URL to a PDF file.
 *
 * @param {String} url - URL to render into a PDF
 * @param {Object} [options] - Options for the PDF export
 * @param {String} [options.layout] - Page orientation. Supported layouts: portrait or landscape
 * @param {String} [options.paperSize] - Page size. Supported sizes: A3, A4, A5, Legal, Letter, Tabloid
 * @param {String} [options.margin] - Margin size. Supported units: mm, cm, in, px
 * @param {String} [options.cancellationGroup] - ID to use for canceling the request
 * @returns {Promise} that resolves with the URL of the generated PDF file
 */
export function toPDF(
  url,
  { layout = 'portrait', paperSize = 'letter', margin = '0.5in', cancellationGroup = undefined } = {},
) {
  return getAxiosInstance()
    .post('/screenshots', {
      url,
      format: 'PDF',
      orientation: layout,
      paperSize,
      margin,
      cancellationGroup,
    })
    .then(({ headers: { location } }) => {
      doTrack('Export', 'PDF', 'complete');
      return location;
    })
    .catch((ex) => {
      doTrack('Export', 'PDF', 'error');
      return Promise.reject(ex);
    });
}
