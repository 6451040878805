import { SeeqNames } from '@/main/app.constants.seeqnames';
import _ from 'lodash';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import { AssetSelection, Content, DateRange } from '@/reportEditor/report.constants';
import { DEBOUNCE } from '@/core/core.constants';
import { ContentOutputV1, sqContentApi } from '@/sdk';
import {
  formatAssetSelectionFromApiOutput,
  formatContentFromApiOutput,
  formatDateRangeFromApiOutput,
} from '@/hybrid/utilities/froalaReportContent.utilities';

const DATA_SEEQ_CONTENT_PENDING_REGEX = new RegExp(
  `${SeeqNames.TopicDocumentAttributes.DataSeeqContentPending}="(.+?)"`,
  'g',
);

/**
 * Parses the HTML and returns a list of Seeq content ids
 *
 * @param {string} document - HTML document contents to parse
 * @returns {string[]} An array of seeq content
 */
export const parseSeeqContentIdsFromHtml = (document: string): string[] => {
  if (!document) return [];
  return _.map([...document.matchAll(DATA_SEEQ_CONTENT_PENDING_REGEX)], ([, id]) => id);
};

/**
 * Parses the HTML and returns a list of pending Seeq content ids
 *
 * @param {string} document - HTML document contents to parse
 * @returns {string[]} An array of pending Seeq content
 */
export const parsePendingSeeqContentIdsFromHtml = (document: string): string[] => {
  if (!document) return [];
  return _.map([...document.matchAll(DATA_SEEQ_CONTENT_PENDING_REGEX)], ([, id]) => id);
};

/**
 * Sets the hash code for the given piece of content.
 *
 * @param {string} contentId - ID of the content object to update
 * @param {string} hashCode - The unique identifier for the current variant of the image
 */
export const setContentHashCode = (contentId: string, hashCode: string) => {
  flux.dispatch('REPORT_SET_CONTENT_HASH_CODE', { contentId, hashCode }, PUSH_IGNORE);
};

/**
 * Updates or adds the Seeq content to the report store
 *
 * @param content - Content to add/update
 */
export const setContent = (content: Content) => {
  flux.dispatch('REPORT_SET_CONTENT', content, PUSH_IGNORE);
};

const imageStateChanged = () => {
  flux.dispatch('REPORT_IMAGE_STATE_CHANGED', null, PUSH_IGNORE);
};

export const debouncedImageStateChanged = _.debounce(imageStateChanged, DEBOUNCE.MEDIUM);

/**
 * Update the height and width of fixed-size content after the image has been rendered. Does not save the content
 * to the backend, since the backend representation doesn't need to be updated.
 *
 * @param {string} contentId - ID of content to update
 * @param {number} width - rendered width of content, in pixels
 * @param {number} height - rendered height of content, in pixels
 */
export const setContentRenderSize = (contentId: string, width: number, height: number) => {
  flux.dispatch('REPORT_SET_CONTENT_RENDER_SIZE', { contentId, width, height }, PUSH_IGNORE);
};

/**
 * Converts a single ContentOutputV1 object into its Content, DateRange and AssetSelection counterparts, optionally
 * populating them in the store.
 *
 * @param contentOutput
 * @param populateStore
 * @returns {Object} containing .content, .dateRange and .assetSelection objects
 */
function processContentResponse(
  contentOutput: ContentOutputV1,
  populateStore = true,
): {
  content: Content;
  dateRange?: DateRange;
  assetSelection?: AssetSelection;
} {
  const content = formatContentFromApiOutput(contentOutput);
  let dateRange;
  if (populateStore) {
    flux.dispatch('REPORT_SET_CONTENT', content, PUSH_IGNORE);
  }

  // If this content has a dateRange, populate it in store
  if (contentOutput.dateRange) {
    dateRange = formatDateRangeFromApiOutput(contentOutput.dateRange);
    if (populateStore) {
      flux.dispatch('REPORT_SET_DATE_RANGE', dateRange, PUSH_IGNORE);
    }
  }

  let assetSelection;
  if (contentOutput.assetSelection) {
    assetSelection = formatAssetSelectionFromApiOutput(contentOutput.assetSelection);
    if (populateStore) {
      flux.dispatch('REPORT_SET_ASSET_SELECTION', assetSelection, PUSH_IGNORE);
    }
  }

  return { content, dateRange, assetSelection };
}

/**
 * Fetches a single content along with associated date range and asset selection used in the current report,
 * optionally populating in sqReportStore.
 *
 * @param {string} id - ID of content to fetch
 * @param {boolean} [populateStore] - true to populate fetched content and dateRange in report store
 * @returns {Promise} that resolves when the content and date range have been retrieved and populated in the store.
 */
export const fetchContent = (
  id: string,
  populateStore = true,
): Promise<{
  content: Content;
  dateRange?: DateRange;
  assetSelection?: AssetSelection;
}> => {
  return sqContentApi.getContent({ id }).then(({ data }) => processContentResponse(data, populateStore));
};
