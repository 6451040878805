import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';

interface SearchResultsProps {
  isLoading: boolean;
  onClear: any;
  resultsString: any;
  extraClassNames?: string;
}

export const SearchResults: React.FunctionComponent<SearchResultsProps> = ({
  isLoading,
  onClear,
  resultsString,
  extraClassNames,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('width-maximum', 'flexRowContainer', extraClassNames)}>
      <h4>{t('HOME_SCREEN.SEARCH_RESULTS')}</h4>
      {!isLoading && (
        <div className="specActiveFilters text-italic mb10">
          {resultsString}
          <span
            className="specClearAllButton sq-text-primary cursorPointer text-interactive text-underline-onhover ml10"
            onClick={onClear}>
            <span>{t('HOME_SCREEN.CLEAR_ALL')} &times;</span>
          </span>
        </div>
      )}
    </div>
  );
};
