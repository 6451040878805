// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { HeaderLogo } from '@/hybrid/header/HeaderLogo.atom';
import { HamburgerMenu } from '@/hybrid/header/HamburgerMenu.atom';
import { ViewModeWorksheetActions } from '@/hybrid/header/ViewModeWorksheetActions.molecule';
import { WorksheetWatchers } from '@/hybrid/worksheets/WorksheetWatchers.atom';
import { AccessKeyModal } from '@/hybrid/header/AccessKeyModal.organism';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import _ from 'lodash';
import { sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { ItemAclModal } from '@/hybrid/accessControl/ItemAclModal.molecule';
import { WorkstepIcons } from '@/hybrid/header/WorkstepIcons.atom';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { EditableText } from '@/hybrid/core/EditableText.atom';
import { renameWorksheet, setWorkbookName } from '@/workbook/workbook.actions';
import { APP_STATE } from '@/main/app.constants';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { ButtonWithPopover } from '@/hybrid/core/ButtonWithPopover.molecule';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logout as logoutSqAuth } from '@/hybrid/utilities/authentication.utilities';
import { doTrack } from '@/track/track.service';
import { isEditWorkbookMode, isPresentationWorkbookMode, isViewOnlyWorkbookMode } from '@/hybrid/utilities/utilities';
import { canModifyWorkbook } from '@/services/authorization.service';
import { LoadingFallback } from '@/hybrid/main/LoadingFallback.atom';

const headerBindings = bindingsDefinition({
  toggleFullScreen: prop<() => void>(),
  initialFullScreenState: prop<boolean>(),
  worksheetShown: prop<boolean>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  $state: injected<ng.ui.IStateService>(),
});

const WrappedHeader: SeeqComponent<typeof headerBindings> = ({
  toggleFullScreen,
  initialFullScreenState,
  worksheetShown,
}) => {
  const { t } = useTranslation();
  const { sqWorkbenchActions, sqHomeScreenUtilities, $state } = useInjectedBindings(headerBindings);

  const [modalShown, setModalShown] = useState<'accessControl' | 'accessKeys' | 'none'>('none');
  const ancestors = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.ancestors);
  const isEditMode = worksheetShown && isEditWorkbookMode();
  const isViewMode = worksheetShown && isViewOnlyWorkbookMode();
  const isPresentationMode = worksheetShown && isPresentationWorkbookMode();
  const { name, owner, effectivePermissions } = useFlux(sqWorkbookStore);
  const workbook = {
    name,
    owner,
    effectivePermissions,
  };
  const breadcrumbs = isEditMode && canModifyWorkbook(workbook) ? ancestors : [];
  const renderDropdownEntry = (
    { href = '#', iconClass = '', translationKey = '', action = _.noop, display = false, divider = false, testId = '' },
    index,
  ) => {
    if (display) {
      return (
        <Dropdown.Item
          href={href}
          key={`dropdown_${translationKey}`}
          onClick={action}
          target="_blank"
          className="sq-force-text-gray"
          data-testid={testId}>
          <Icon icon={`${iconClass} fa-fw`} extraClassNames="pr10" type="inherit" />
          <span className="forceFont" data-testid={`moreActions_${translationKey}`}>
            {t(translationKey)}
          </span>
        </Dropdown.Item>
      );
    }
    if (divider) {
      return <div className="dropdown-divider" key={index} />;
    }
  };

  const actions = [
    {
      href: '#',
      iconClass: 'fa-user',
      translationKey: 'USER.EDIT_PROFILE',
      action: () => sqWorkbenchActions.setUserProfileDisplay(!sqWorkbenchStore.displayUserProfile),
      display: true,
      testId: 'editProfile',
    },
    {
      divider: true,
    },
    {
      href: '#',
      iconClass: 'fa-key',
      translationKey: 'ACCESS_KEY.ACCESS_KEYS',
      action: () => setModalShown('accessKeys'),
      display: true,
      testId: 'accessKeys',
    },
    {
      divider: true,
    },
    {
      href: '#',
      iconClass: 'fa-sign-out',
      translationKey: 'LOGOUT',
      action: logout,
      display: true,
      testId: 'logout',
    },
  ];

  function logout(returnState, returnParams, userInitiated) {
    logoutSqAuth($state, returnState, returnParams, userInitiated);
  }

  const clickHandler = (crumb) => {
    if (crumb.id && !crumb.tabHash) {
      $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId: crumb.id });
    } else {
      $state.go(APP_STATE.WORKBOOKS, { t: crumb.tabHash });
    }
  };

  return (
    <header className="mainHeader flexColumnContainer flexCenter" data-testid="header">
      <ToastContainer theme="colored" icon={false} />

      <HeaderLogo />

      <div className="flexGrowShrink flexRowContainer flexCenter flexNoOverflow">
        <div className="flexNoGrow flexNoOverflow flexColumnContainer flexCenter">
          {!!breadcrumbs.length && worksheetShown && (
            <h5 className="mt0 mb2 fs11">
              <ul className="unstyled-list text-not-bold flexColumnContainer headerBreadcrumbs folderBreadcrumbs mt0 mb0 flexNoGrow">
                {_.map(breadcrumbs, (crumb) => {
                  return (
                    <li className="ml2 " tabIndex={0} key={`crumbId_${crumb.id}`}>
                      {' '}
                      <a
                        className="text-underline"
                        onClick={() => clickHandler(crumb)}
                        data-testid={sqHomeScreenUtilities.translateBreadcrumb(crumb)}>
                        {sqHomeScreenUtilities.translateBreadcrumb(crumb)}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </h5>
          )}
        </div>

        <div className="flexNoGrow flexNoOverflow flexColumnContainer flexNoGrowNoShrink flexCenter truncatedLabel max-height-23">
          {worksheetShown && (
            <EditableText
              allowEditing={canModifyWorkbook(workbook) && isEditMode}
              onUpdate={(name) =>
                setWorkbookName(
                  sqWorkbenchStore.stateParams.workbookId,
                  name,
                  sqWorkbookStore.name,
                  sqHomeScreenUtilities,
                )
              }
              value={workbook.name}
              inputClasses="workbookName truncatedLabel headerLabel max-height-23 mt0 mb0"
              textClasses={breadcrumbs.length ? 'fs14' : 'fs17'}
              testId="workbookName"
              id="workbookName"
            />
          )}
          {workbook.name && sqWorkbenchStore.stateParams.worksheetId && (
            <p className="truncatedLabel pl3 pr3 pb2 fs17 mt0 mb0">-</p>
          )}
          {worksheetShown && (
            <EditableText
              allowEditing={canModifyWorkbook(workbook) && isEditMode}
              onUpdate={(name) => renameWorksheet(sqWorkbenchStore.stateParams.worksheetId, name)}
              value={
                sqWorkbenchStore.stateParams.worksheetId &&
                sqWorkbookStore.getWorksheetName(sqWorkbenchStore.stateParams.worksheetId)
              }
              textClasses={breadcrumbs.length ? 'fs14' : 'fs17'}
              testId="worksheetName"
              id="worksheetName"
              autoWidth={true}
            />
          )}
        </div>
      </div>

      <WorkstepIcons isEditMode={isEditMode} isViewMode={isViewMode} />
      <div className="flexColumnContainer">
        <ViewModeWorksheetActions isEditMode={isEditMode} isViewMode={isViewMode} />
      </div>
      {isEditMode && isViewMode && (
        <WorksheetWatchers compactMode={false} worksheetId={sqWorkbenchStore.stateParams.worksheetId} />
      )}
      {canModifyWorkbook(workbook) && isEditMode && (
        <HoverTooltip placement="bottom" text="ACL_STATUS.TOOLTIP.MANAGE_ACCESS_CONTROL">
          <div
            data-testid="manageAccessControl"
            className="sq-navbar-default fs13 flexColumnContainer flexJustifyEnd ml5 mr9 flexNoGrowNoShrink"
            onClick={() => {
              doTrack('Access Control', 'open', 'from toolbar');
              setModalShown('accessControl');
            }}>
            <Icon
              icon="fc-share-access"
              extraClassNames="fa-xlg mr7 cursorPointer sq-navbar-default link-no-underline"
              type="inherit"
            />{' '}
            {t('ACCESS_CONTROL.SHARE')}
          </div>
        </HoverTooltip>
      )}
      {modalShown === 'accessControl' && (
        <ItemAclModal
          itemId={$state.params.workbookId}
          workbookId={$state.params.workbookId}
          worksheetId={sqWorkbenchStore.stateParams.worksheetId}
          folderId={$state.params.currentFolderId}
          includeLinksAndCorporateMessage={true}
          closeModal={() => setModalShown('none')}
          isAclModalLocationInHeader={true}
        />
      )}

      {!isPresentationMode && (
        <div
          className="mr10 ml5 headerOptionsButton flexColumnContainer cursorPointer sq-navbar-default flexNoGrowNoShrink"
          id="userMenu">
          <ButtonWithPopover
            label={
              <div className="sq-navbar-default fs13">
                <Icon
                  icon="fa-user"
                  extraClassNames="fa-xlg flexNoGrowNoShrink mr5 "
                  type="inherit"
                  testId="userProfile"
                  id="userProfile"
                />
                <span>{sqWorkbenchStore.currentUser.firstName}</span>
              </div>
            }
            popoverConfig={{ id: 'userProfile', placement: 'bottom-end' }}>
            {_.map(actions, (action, index) => renderDropdownEntry(action, index))}
          </ButtonWithPopover>
        </div>
      )}

      <div className=" flexColumnContainer mt10 mb10 flexNoGrowNoShrink">
        <HamburgerMenu toggleFullScreen={toggleFullScreen} initialFullScreenState={initialFullScreenState} />
      </div>

      {modalShown === 'accessKeys' && <AccessKeyModal show={true} onClose={() => setModalShown('none')} />}
    </header>
  );
};

// Wrap in Suspense to ensure translations are loaded before displaying the component
export const Header: SeeqComponent<typeof headerBindings> = (props) => {
  return (
    <React.Suspense fallback={<LoadingFallback fallbackLocation="Header" />}>
      <WrappedHeader {...props} />
    </React.Suspense>
  );
};

export const sqHeader = angularComponent(headerBindings, Header);
