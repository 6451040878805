// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { Table, TableColumn } from '@/hybrid/core/Table.atom';
import { sqAuditApi } from '@/sdk';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Paginator } from '@/hybrid/core/Paginator.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { formatTime } from '@/hybrid/datetime/dateTime.utilities';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import j2y from 'json2yaml';
import { ErrorWithBackground } from '@/hybrid/core/ErrorWithBackground.atom';
import { Alert } from 'react-bootstrap';
import { CopyButtonGivenText } from '@/hybrid/core/CopyButtonGivenText.atom';
import { NewLineToBr } from '@/hybrid/core/NewLineToBr.atom';
import { ChangeTypeEnum } from '@/sdk/model/AuditOutputV1';
import { cancelGroup } from '@/hybrid/requests/pendingRequests.utilities';
import { sqWorksheetStore } from '@/core/core.stores';

interface AuditTableProps {
  range: { start: number; end: number };
  itemIds?: string[];
  userIds?: string[];
  itemTypes?: string[];
  changeTypes?: ChangeTypeEnum[];
}

const COLUMN_BORDER = 'solid 0.5px rgb(224,224,224)';

export const AuditTable: React.FunctionComponent<AuditTableProps> = (props) => {
  const { range, itemIds, userIds, itemTypes, changeTypes } = props;

  const [tableLoading, setTableLoading] = useState(true);
  const [entries, setEntries] = useState([]);
  const [page, setPage] = useState(1);
  const [sortAsc, setSortAsc] = useState(false);
  const [limit, setLimit] = useState(500);
  const [totalResults, setTotalResults] = useState(-1);
  const [errorMessage, setErrorMessage] = useState('');
  const noEntriesFound = entries.length === 0 && errorMessage === '' && totalResults === 0 && !tableLoading;

  const { t } = useTranslation();
  const cancellationGroup = 'auditTable';

  useEffect(() => {
    setPage(1);
    if (page === 1) {
      fetchEntries();
    }
  }, [limit, range, itemIds, userIds, sortAsc]);

  useEffect(() => {
    fetchEntries();
  }, [page]);

  const renderTimestamp = (column, columnName) => {
    const formattedColumn = formatTime(column[columnName], sqWorksheetStore.timezone);
    return renderFormattedCell(
      formattedColumn,
      columnName,
      formatTime(column[columnName], sqWorksheetStore.timezone, ''),
    );
  };

  const renderBeforeAfter = (column, columnName) => {
    const jsonObj = JSON.parse(column[columnName]);
    const formattedColumn = j2y.stringify(sanitize(jsonObj)).replace('---\n', '');
    return renderFormattedCell(formattedColumn, columnName);
  };

  const renderIdColumn = (column, columnName) => {
    const cell = renderFormattedCell(column[columnName].substring(0, 8), columnName, column[columnName]);
    return (
      <div className="flexColumnContainer">
        {cell}
        <CopyButtonGivenText
          id="copyIdButton"
          tooltip={t('COPY_ID.TO_CLIPBOARD')}
          textToCopy={column[columnName]}
          asType="icon"
          iconClass="fa-copy"
          extraClassNames="ml5"
          notifyMessage="COPY_ID.SUCCESS"
        />
      </div>
    );
  };

  const renderFormattedCell = (formattedCell, columnName, tooltip?: string) => {
    const cellTextWithNewLines = <NewLineToBr lineToBreak={formattedCell} />;
    const cell = <span data-testid={`column-${columnName}`}>{cellTextWithNewLines}</span>;
    return <HoverTooltip text={tooltip}>{cell}</HoverTooltip>;
  };

  const renderColumn = (column, columnName) => {
    return <div data-testid={`column-${columnName}`}>{column[columnName]}</div>;
  };

  // Makes before and after columns more readable by removing ScalarModel attributes (found in ScalarModel.java)
  const sanitize = (json) => {
    _.forEach(json, (value, key) => {
      if (_.has(value, 'stringValue')) {
        json[key] = value.stringValue;
      } else if (_.has(value, 'booleanValue')) {
        json[key] = value.booleanValue;
      } else if (_.has(value, 'longValue')) {
        json[key] = value.longValue + value.uom;
      } else if (_.has(value, 'doubleValue')) {
        json[key] = value.doubleValue + value.uom;
      } else if (_.isObject(value)) {
        json[key] = sanitize(value);
      }
    });
    return json;
  };

  const columns: TableColumn[] = [
    {
      header: 'Timestamp',
      accessor: 'timeStamp',
      searchProperty: 'timeStamp',
      sortable: true,
      filterable: false,
      cellStyle: {
        minWidth: '130px',
        wordWrap: 'break-word',
        borderRight: COLUMN_BORDER,
        borderLeft: COLUMN_BORDER,
      },
      cellRenderFunction: renderTimestamp,
    },
    {
      header: 'Username',
      accessor: 'userName',
      searchProperty: 'userName',
      sortable: false,
      filterable: false,
      cellStyle: {
        wordWrap: 'break-word',
        minWidth: '80px',
        maxWidth: '170px',
        borderRight: COLUMN_BORDER,
      },
      cellRenderFunction: renderColumn,
    },
    {
      header: 'User ID ',
      accessor: 'userId',
      searchProperty: 'userId',
      sortable: false,
      filterable: false,
      cellStyle: {
        wordWrap: 'break-word',
        minWidth: '90px',
        borderRight: COLUMN_BORDER,
      },
      cellRenderFunction: renderIdColumn,
    },
    {
      header: 'Item Name',
      accessor: 'itemName',
      searchProperty: 'itemName',
      sortable: false,
      filterable: false,
      cellStyle: {
        wordWrap: 'break-word',
        maxWidth: '120px',
        minWidth: '80px',
        borderRight: COLUMN_BORDER,
      },
      cellRenderFunction: renderColumn,
    },
    {
      header: 'Item Type',
      accessor: 'itemType',
      searchProperty: 'itemType',
      sortable: false,
      filterable: false,
      cellStyle: {
        wordWrap: 'break-word',
        minWidth: '70px',
        borderRight: COLUMN_BORDER,
      },
      cellRenderFunction: renderColumn,
    },
    {
      header: 'Item ID',
      accessor: 'itemId',
      searchProperty: 'itemId',
      sortable: false,
      filterable: false,
      cellStyle: {
        wordWrap: 'break-word',
        minWidth: '90px',
        borderRight: COLUMN_BORDER,
      },
      cellRenderFunction: renderIdColumn,
    },
    {
      header: 'Change Type',
      accessor: 'changeType',
      searchProperty: 'changeType',
      sortable: false,
      filterable: false,
      cellStyle: { minWidth: '90px', borderRight: COLUMN_BORDER },
      cellRenderFunction: renderColumn,
    },
    {
      header: 'Before',
      accessor: 'beforeSummary',
      searchProperty: 'beforeSummary',
      sortable: false,
      filterable: false,
      cellStyle: {
        wordWrap: 'break-word',
        minWidth: '120px',
        whiteSpace: 'pre-wrap',
        borderRight: COLUMN_BORDER,
      },
      cellRenderFunction: renderBeforeAfter,
    },
    {
      header: 'After',
      accessor: 'afterSummary',
      searchProperty: 'afterSummary',
      sortable: false,
      filterable: false,
      cellStyle: {
        wordWrap: 'break-word',
        minWidth: '120px',
        whiteSpace: 'pre-wrap',
        borderRight: COLUMN_BORDER,
      },
      cellRenderFunction: renderBeforeAfter,
    },
  ];

  const fetchEntries = () => {
    setTableLoading(true);
    cancelGroup(cancellationGroup)
      .then(() =>
        sqAuditApi.getAuditEntries(
          {
            startTime: range.start ? moment(range.start).toISOString() : null,
            endTime: range.end ? moment(range.end).toISOString() : null,
            itemID: itemIds,
            userID: userIds,
            itemType: itemTypes,
            changeType: changeTypes as any,
            limit,
            offset: (page - 1) * limit,
            sortAsc,
          },
          {
            cancellationGroup,
          },
        ),
      )
      .then(({ data: { content, totalResults } }) => {
        const entriesWithId = _.map(content, (entry, index) => ({
          ...entry,
          id: index,
        }));
        setEntries(entriesWithId);
        setTotalResults(totalResults);
      })
      .catch((e) => wipeTable(e))
      .finally(() => setTableLoading(false));
  };

  const wipeTable = (e?) => {
    setEntries([]);
    setTotalResults(0);
    setErrorMessage(e?.data?.statusMessage ?? e);
  };

  const toggleSortAsc = () => {
    setSortAsc(!sortAsc);
  };

  return (
    <div data-testid="auditTable" className="flexRowContainer flexFill mb5 mt10">
      {tableLoading && (
        <div className="flexColumnContainer flexCenter pt50 pb50">
          <Icon icon="fa-spinner fa-pulse fa-5x" extraClassNames="sq-text-primary" />
        </div>
      )}
      {!tableLoading && errorMessage === '' && !noEntriesFound && (
        <div className="tableWrapper aggressiveWordBreak">
          <Table
            sortTableCallback={toggleSortAsc}
            sortProperty="timeStamp"
            sortAscending={sortAsc}
            columns={columns}
            items={entries}
            tableClass="fixedHeaderTable"
          />
        </div>
      )}
      {errorMessage !== '' && (
        <ErrorWithBackground
          errorHeading={t('AUDIT_TRAIL.SEARCH_ERROR')}
          errorText={errorMessage}
          onClose={() => {
            setErrorMessage('');
          }}
        />
      )}
      {noEntriesFound && (
        <Alert className="sq-alert-warning" variant="secondary" dismissible={false}>
          <div className="fs14 text-bolder mt0">{t('AUDIT_TRAIL.SEARCH_ERROR')}</div>
          <p className="max-height-120 overflowAuto text-pre-wrap breakWord">{t('AUDIT_TRAIL.NO_LOGS_FOUND')}</p>
        </Alert>
      )}
      {!tableLoading && errorMessage === '' && !noEntriesFound && (
        <Paginator
          gotoPage={(page) => setPage(page)}
          pageCount={totalResults === 0 ? 0 : totalResults / limit}
          pageNumber={page}
          pageSize={limit}
          setPageSize={setLimit}
          total={totalResults}
          pageSizeOptionsCustom={[50, 100, 500, 5000]}
        />
      )}
    </div>
  );
};
