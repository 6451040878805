// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { CapsuleInput } from '@/hybrid/investigate/customCondition/CapsuleInput.molecule';
import { getFormFieldProps, getValidationFunction, canUseMemo } from '@/hybrid/formbuilder/formbuilder.utilities';
import { Field, useForm } from 'react-final-form';
import { Capsule } from '@/hybrid/utilities/datetime.constants';
import { FormFieldWrapper } from './FormFieldWrapper';

export interface CapsuleInputIF extends ValidatingFormComponent<string> {
  component: 'CapsuleInputFormComponent';
  trackCategory: string;
  trackAction: string;
  capsuleWindow?: Capsule;
  setCapsuleInputValidStatus?: (status) => void;
  disabled?: boolean;
}

const CapsuleInputFormComponentUnwrapped: React.FunctionComponent<CapsuleInputIF> = (props) => {
  const { name, validation, extendValidation, disabled, extraClassNames, testId, label } = props;

  const defaultValidation = (value) => _.isEmpty(value);
  const formState = useForm().getState();

  return (
    <FormFieldWrapper testId={testId} label={label}>
      <Field name={name} validate={getValidationFunction(defaultValidation, extendValidation, validation)}>
        {({ input, meta }) => (
          <div className={extraClassNames} id="specTrainingWindow">
            <CapsuleInput
              {...getFormFieldProps(formState, input, meta, props)}
              isDisabled={disabled}
              appendToBody={true}
            />
          </div>
        )}
      </Field>
    </FormFieldWrapper>
  );
};

export const CapsuleInputFormComponent = React.memo(CapsuleInputFormComponentUnwrapped, canUseMemo);
