// @ts-strict-ignore
import React, { useState } from 'react';
import classNames from 'classnames';
import { formatTime } from '@/hybrid/datetime/dateTime.utilities';
import { JournalReplyIcon } from '@/hybrid/annotation/JournalReplyIcon.atom';
import { JournalMetadata } from '@/hybrid/annotation/JournalMetadata.atom';
import { useTranslation } from 'react-i18next';
import { ContainerWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';
import { Card } from 'react-bootstrap';
import { EllipsisDropdownMenu } from '@/hybrid/core/EllipsisDropdownMenu.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { doTrack } from '@/track/track.service';
import { sqWorksheetStore } from '@/core/core.stores';
import { isPresentationWorkbookMode } from '@/hybrid/utilities/utilities';

interface JournalCardProp {
  entry: {
    id: string;
    name: string;
    description: string;
    replies: any[];
    createdBy: any;
    updatedAt: string;
  };
  highlightId: string;
  delete: (id: string) => void;
  display: (id: string) => void;
  canManage: (entry: object) => boolean;
}

export const JournalCard: React.FunctionComponent<JournalCardProp> = (props) => {
  const { entry, highlightId, display, canManage, delete: deleteEntry } = props;

  const { t } = useTranslation();
  const isPresentationMode = isPresentationWorkbookMode();
  const formatDate = (date) => formatTime(date, sqWorksheetStore.timezone);
  const [queryRemove, setQueryRemove] = useState(false);
  const menuClass = canManage(entry) ? 'sq-text-primary' : 'sq-fairly-dark-gray-always';

  const manageMenuItems = [
    {
      classes: classNames({ cursorNotAllowed: !canManage(entry) }),
      onClick: () => canManage(entry) && setQueryRemove(true),
      icon: <Icon testId="specCommentDeleteBtn" icon="fa-fw fa-remove" type="inherit" />,
      label: 'DELETE',
      testid: 'commentDelete',
    },
  ];

  return (
    <Card
      onClick={() => display(entry.id)}
      data-testid="journalCardContainer"
      className={classNames('annotationCard cursorPointer', {
        'card-primary': highlightId === entry.id,
      })}>
      <Card.Body className="pt0 pb0">
        <Card.Title className="flexColumnContainer flexSpaceBetween flexAlignCenter mb2">
          <ContainerWithHTML
            data-testid="specJournalCardTitle"
            content={entry.name}
            isBlock={true}
            extraClassNames="text-bolder flexFill"
          />
          {!isPresentationMode && <EllipsisDropdownMenu itemsList={manageMenuItems} iconExtraClass={menuClass} />}
        </Card.Title>
        <Card.Text as="div" className="card-body pt0 pr0 pl0 pb5">
          <ContainerWithHTML
            content={entry.description}
            isBlock={true}
            extraClassNames="commentName max-height-4em journal-card-overflow"
          />
        </Card.Text>
        <Card.Text as="div" className="flexColumnContainer flexSpaceBetween pb10">
          <JournalMetadata name={entry.createdBy.name} date={formatDate(entry.updatedAt)} />
          <JournalReplyIcon count={entry.replies.length} small={true} />
        </Card.Text>
        {queryRemove && (
          <Card.Text as="div" className="text-center pb10">
            <hr className="mt10 mb10" />
            <TextButton
              testId="specConfirmDeleteBtn mr5"
              variant="danger"
              label="DELETE"
              extraClassNames="specConfirmDeleteBtn"
              onClick={() => {
                doTrack('Journal', 'delete', '');
                deleteEntry(entry.id);
                setQueryRemove(false);
              }}
            />

            <span
              data-testid="specCancelDeleteBtn"
              className="btn btn-sm btn-link specCancelDeleteBtn"
              onClick={(event) => {
                setQueryRemove(false);
                doTrack('Journal', 'cancel', '');
                event.stopPropagation();
                event.preventDefault();
              }}>
              {t('CANCEL')}
            </span>
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};
