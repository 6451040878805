// @ts-strict-ignore
import React, { useState } from 'react';
import {
  AutoUpdateTimeScheduleEntry,
  DEFAULT_TIME_ENTRY,
  ReportAutoUpdateTimeScheduleProperties,
} from '@/hybrid/reportConfig/components/dateRanges/autoUpdateModal/components/ReportAutoUpdateTimeScheduleProperties.molecule';
import { Form, FormGroup } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import TimeZoneSelector from '@/hybrid/core/TimeZoneSelector.molecule';

const ReportAutoUpdateTimeScheduleBindings = bindingsDefinition({
  timezone: prop<any>(),
  setTimezone: prop<(timezone: any) => void>(),
  entries: prop.optional<AutoUpdateTimeScheduleEntry[]>(),
  setEntries: prop<(entries: AutoUpdateTimeScheduleEntry[]) => void>(),
});

const determineInvalidEntries = (entries): boolean[] => {
  // In future, account for different strategies too.
  const times = entries.map((entry) => entry.time);

  // find duplicates
  return times.map((value, index) => {
    const firstFindIndex = times.indexOf(value);
    const lastFindIndex = times.lastIndexOf(value);

    return firstFindIndex === lastFindIndex ? false : index !== firstFindIndex;
  });
};

export const areTimeEntriesValid = (entries: AutoUpdateTimeScheduleEntry[]) => {
  if (_.isEmpty(entries)) return false;
  return _.every(determineInvalidEntries(entries), (isInvalid) => !isInvalid);
};

export const ReportAutoUpdateTimeSchedule: SeeqComponent<typeof ReportAutoUpdateTimeScheduleBindings> = (props) => {
  const { timezone, setTimezone, entries, setEntries } = props;

  const sortByLocalTime = (entries) => {
    return _.chain(entries).sortBy(['time']).value();
  };

  const setAllEntries = (entries) => {
    setEntries(entries);
    setLocalTimeEntries(entries);
  };

  const onEntryChange = (targetIndex: number, updatedEntry: AutoUpdateTimeScheduleEntry) => {
    const updatedEntries = _.map(localTimeEntries, (existingEntry, index) =>
      index === targetIndex ? updatedEntry : existingEntry,
    );
    setAllEntries(updatedEntries);
  };

  const insertDefaultTimeEntry = () => {
    const updatedEntries = [
      ..._.cloneDeep(localTimeEntries),
      {
        ...DEFAULT_TIME_ENTRY,
      },
    ];
    setAllEntries(updatedEntries);
  };

  const removeTimeEntry = (targetIndex: number) => {
    const updatedEntries = localTimeEntries.filter((entry, index) => index !== targetIndex);
    setAllEntries(updatedEntries);
  };

  const { t } = useTranslation();
  const [localTimeEntries, setLocalTimeEntries] = useState(sortByLocalTime(entries));

  const isEntryInvalid = determineInvalidEntries(localTimeEntries);

  return (
    <FormGroup className="flexColumnContainer">
      <div className="flexRowContainer flexNoShrink mr15">
        <FormGroup className="flexColumnContainer">
          <Form.Label className="reportAutoUpdateTimezone mr5">
            {t('REPORT.MODAL.AUTO_UPDATE.TIME.TIME_ZONE')}
          </Form.Label>
          <TimeZoneSelector extraClassNames="width-220" timezone={timezone} onSelect={setTimezone} />
        </FormGroup>
        {_.map(localTimeEntries, (entry, index) => (
          <ReportAutoUpdateTimeScheduleProperties
            key={`entry${index}`}
            isInvalid={isEntryInvalid[index]}
            showRemoveIcon={localTimeEntries.length > 1}
            entry={entry}
            onChange={(entry) => onEntryChange(index, entry)}
            onClickRemove={() => removeTimeEntry(index)}
          />
        ))}
        <Form.Label>
          <a href="#" className="link-no-focus link-no-underline" onClick={() => insertDefaultTimeEntry()}>
            {t('REPORT.MODAL.AUTO_UPDATE.TIME.ADD_ANOTHER_TIME')}
          </a>
        </Form.Label>
      </div>
    </FormGroup>
  );
};
