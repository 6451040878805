// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { removeChildFromAsset, restoreItem } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { sqItemsApi } from '@/sdk';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { getDisplayPath } from '@/hybrid/assetGroupEditor/assetGroup.utilities';
import { doTrack } from '@/track/track.service';
import { AssetAncestor, AssetGroupAsset, AssetGroupChild } from '@/hybrid/assetGroupEditor/assetGroup.types';

interface AssetDetailModalProps {
  onClose: () => void;
  swapItem: () => void;
  item: AssetGroupChild;
  asset: AssetGroupAsset;
  testId?: string;
}

interface AssetMapping {
  id: string;
  name: string;
  ancestors: AssetAncestor[];
}

export const AssetDetailModal: React.FunctionComponent<AssetDetailModalProps> = ({
  onClose,
  item,
  swapItem,
  asset,
  testId,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [originalMapping, setOriginalMapping] = useState<AssetMapping>();
  const [currentMapping, setCurrentMapping] = useState<AssetMapping>();

  const removeItem = () => {
    removeChildFromAsset({ child: item, asset });
    onClose();
  };

  useEffect(() => {
    setLoading(true);
    sqItemsApi.getFormulaDependencies({ id: item.parameters[0].item.id }).then((response) => {
      const { ancestors, name, id } = response.data;
      setCurrentMapping({ name, ancestors, id });
    });
  }, [item]);

  // once we have the "current item" we can use that to determine if there was an "original item".
  useEffect(() => {
    if (currentMapping && item?.originalMapping && currentMapping?.id !== item.originalMapping) {
      sqItemsApi
        .getFormulaDependencies({ id: item.originalMapping })
        .then(({ data }) => setOriginalMapping(data as unknown as AssetMapping))
        .finally(() => setLoading(false));
    } else if (!_.isEmpty(currentMapping)) {
      setLoading(false);
    }
  }, [currentMapping]);

  const restoreOriginalMapping = () => {
    doTrack('Asset Group Editor', 'Details Modal', 'Original Mapping Restored');
    restoreItem({ asset, columnName: item.name, itemId: originalMapping.id });
    onClose();
  };

  const renderDetails = (
    <div className="flexRowContainer">
      <div className="flexColumnContainer flexAlignCenter">
        <div data-testid="attributeName" className="text-bold font-size-larger mr15">
          {currentMapping?.name}
        </div>
        <div>
          <Icon
            icon="fa-edit"
            tooltip="EDIT"
            type="theme"
            testId="editAssetMapping"
            extraClassNames="cursorPointer"
            onClick={swapItem}
          />
        </div>
      </div>
      <span>{getDisplayPath(currentMapping)}</span>
      {item.manuallyAdded && (
        <span className="mt5">
          <i>{t('ASSET_GROUP_EDITOR.MANUALLY_ASSIGNED')}</i>
        </span>
      )}
      {!_.isEmpty(originalMapping) && (
        <>
          <hr className="mt30 mb15 width-maximum" />
          <div>
            {t('ASSET_GROUP_EDITOR.ORIGINAL_MAPPING')}&nbsp;
            <b>{`${originalMapping?.name} - ${getDisplayPath(originalMapping)}`}</b>
            <a href="#" className="font-size-smaller nowrap" onClick={restoreOriginalMapping}>
              <Icon
                icon="fa-reply"
                type="theme"
                extraClassNames="ml15 mr5 cursorPointer"
                onClick={restoreOriginalMapping}
              />
              {t('ASSET_GROUP_EDITOR.RESTORE')}
            </a>
          </div>
        </>
      )}
    </div>
  );

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid={testId}>
      <Modal.Header closeButton={true}>
        <h4>{`${asset.name} » ${item.name} ${t('ASSET_GROUP_EDITOR.ITEM_DETAILS')}`}</h4>
      </Modal.Header>
      <Modal.Body>
        {!loading && renderDetails}
        {loading && (
          <div className="flexRowContainer flexAlignCenter mb30 mt40">
            <IconWithSpinner spinning={true} large={true} />
          </div>
        )}

        <div className="flexColumnContainer mt30">
          <div className="flexColumnContainer flexFill">
            <TextButton
              onClick={removeItem}
              label="ASSET_GROUP_EDITOR.REMOVE_MAPPING"
              size="sm"
              testId="removeAttributeBtn"
              disabled={loading}
              extraClassNames="min-width 100 width-130 max-width-130 mr20"
              id="removeAttributeBtn"
            />
          </div>

          <TextButton
            onClick={onClose}
            label="CLOSE"
            size="sm"
            testId="closeBtn"
            extraClassNames="min-width 100 width-100 max-width-100"
            id="closeBtn"
            variant="theme"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
