// @ts-strict-ignore
import _ from 'lodash';
import { isInvalidPixel, translateRegion } from '@/hybrid/utilities/chartHelper.utilities';
import { ChartRegion, PixelTranslationFunction } from '@/chart/chart.module';

/**
 * Creates and returns a function that will draw a 2d box overlay over a specified selection of a Highcharts chart
 * object. The function is designed to be called any time the selected area needs to be redrawn (if the area or
 * chart displayed change).
 *
 * The selection will contain at least one button: an 'x' meant for clearing the selection, which will fire the
 * 'clearSelection' function from the set of 'handler' callbacks passed in. This does NOT automatically update the
 * overlay, it expects the draw function to be called again with an updated (empty) region.
 *
 * By default, the overlay (pixel) position and size are determined using the chart's axes' "translate" function.
 * However, this can be overridden by passing in custom translate functions. This is how trend view ignores the
 * y-axis entirely and extends the overlay to the top and bottom of the chart.
 *
 * An optional '+' button can be used to "pick" the selection. The handler for this (if provided) gives both a
 * function to determine if picking mode is active (toggling the button on or off), and the handler that should be
 * fired when the button is clicked.
 *
 * @param getChart returns the chart to draw the selection on
 * @param zIndex constants to use for setting the zIndex of the selection and buttons
 * @param handlers handlers for the buttons
 * @param translate optional translation functions to convert XY coordinates into pixel coordinates
 * @param pickSelection optional handler for the "pick" button
 * @return {function} - void function that draws or updates the selection overlay on a chart
 */
export function drawSelectedRegion(
  getChart,
  zIndex: { selection: number; button: number },
  handlers: { clearSelection: () => any },
  translate: {
    x: PixelTranslationFunction | undefined;
    y: PixelTranslationFunction | undefined;
  } = {
    x: undefined,
    y: undefined,
  },
  pickSelection = { isPickingMode: () => false, pickSelection: _.noop },
): (selectedRegion: ChartRegion) => void {
  const element = {
    selectedRegionRect: undefined,
    removeRegionButton: undefined,
    pickRegionButton: undefined,
  };

  return (selectedRegion: ChartRegion) => {
    const chart = getChart();

    const REMOVE_BUTTON_PADDING = 15;

    if (!_.isObject(chart)) {
      return;
    }

    // Use the x-axis to translate the x-values to pixel locations
    const xAxis = chart.xAxis[0];

    if (!_.isObject(xAxis) || !_.isObject(chart.plotBox) || !selectedRegion) {
      return;
    }

    const yAxis = chart.yAxis[0];
    if (!translate.y && !_.isObject(yAxis)) {
      return;
    }

    const pixelRegion = translateRegion(chart, xAxis, yAxis, translate, selectedRegion);
    if (isInvalidPixel(pixelRegion)) {
      if (_.isObject(element.selectedRegionRect)) {
        element.selectedRegionRect.destroy();
      }

      if (_.isObject(element.removeRegionButton)) {
        element.removeRegionButton.destroy();
      }

      if (_.isObject(element.pickRegionButton)) {
        element.pickRegionButton.destroy();
      }

      element.selectedRegionRect = undefined;
      element.removeRegionButton = undefined;
      element.pickRegionButton = undefined;
      return;
    }

    if (!_.isObject(element.selectedRegionRect)) {
      // It's not ideal to have this CSS hard-coded here rather than in a .css file, but we
      // haven't been able to figure out how to get Highcharts to use an externally-defined style.
      element.selectedRegionRect = chart.renderer
        .rect(0, 0, 0, 0, 0)
        .css({
          'stroke': '#39516b',
          'stroke-width': 0.5,
          'fill': '#eaf3f4',
          'fill-opacity': 0.6,
        })
        .attr({
          class: 'highcharts-selected-region',
          zIndex: zIndex.selection,
        })
        .add()
        .toFront();

      element.removeRegionButton = chart.renderer
        .text('\uF00D', 0, 0, true) // fa-times
        .css({
          cursor: 'pointer',
          opacity: 0.3,
          fontSize: '16px',
          fontFamily: 'FontAwesome',
          zIndex: zIndex.button,
        })
        .attr({
          class: 'specRemoveSelectedRegionBtn',
        })
        .on('click', handlers.clearSelection)
        .add();
    }

    const { xMinPixel, xMaxPixel, yMinPixel, yMaxPixel } = pixelRegion;

    element.selectedRegionRect.attr({
      x: xMinPixel + chart.plotLeft,
      y: chart.plotHeight + chart.plotTop - yMaxPixel,
      width: xMaxPixel - xMinPixel,
      height: yMaxPixel - yMinPixel,
    });

    const smallSelection = element.selectedRegionRect.attr('width') < REMOVE_BUTTON_PADDING * 2.5;
    element.removeRegionButton.attr({
      x: element.selectedRegionRect.attr('x') + element.selectedRegionRect.attr('width') - REMOVE_BUTTON_PADDING,
      y: element.selectedRegionRect.attr('y') + REMOVE_BUTTON_PADDING,
    });

    if (pickSelection.isPickingMode()) {
      if (!_.isObject(element.pickRegionButton)) {
        element.pickRegionButton = chart.renderer
          .text('\uF055', 0, 0) // fa-circle-plus
          .css({
            cursor: 'pointer',
            opacity: 0.3,
            fontSize: '16px',
            fontFamily: 'FontAwesome',
          })
          .attr({
            class: 'specAddSelectedRegionBtn',
            zIndex: zIndex.button,
          })
          .on('click', pickSelection.pickSelection)
          .add();
      }

      element.pickRegionButton.attr({
        x:
          element.selectedRegionRect.attr('x') +
          element.selectedRegionRect.attr('width') -
          REMOVE_BUTTON_PADDING * (smallSelection ? 1 : 2.25),
        y: element.selectedRegionRect.attr('y') + REMOVE_BUTTON_PADDING * (smallSelection ? 2.25 : 1),
      });
    } else {
      if (_.isObject(element.pickRegionButton)) {
        element.pickRegionButton.destroy();
      }

      element.pickRegionButton = undefined;
    }
  };
}
