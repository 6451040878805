// @ts-strict-ignore
import _ from 'lodash';
import angular from 'angular';
import { sqLicenseManagementStore, sqWorkbenchStore, sqWorkbookStore } from '@/core/core.stores';
import { APP_STATE } from '@/main/app.constants';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { headlessRenderMode, isPresentationWorkbookMode, isViewOnlyWorkbookMode } from '@/hybrid/utilities/utilities';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';

/**
 * Controller for main Workbench view
 */
const dependencies = ['Sq.Workbook', 'Sq.Workbench', 'Sq.LicenseManagement', 'Sq.SystemWarning'];

angular.module('Sq.Main', dependencies).controller('MainCtrl', MainCtrl);

function MainCtrl($scope: ng.IScope, $state: ng.ui.IStateService, sqWorkbenchActions: WorkbenchActions) {
  const vm = this;

  $scope.$listenTo(sqWorkbenchStore, setMainVars);
  $scope.$listenTo(sqWorkbookStore, setMainVars);
  $scope.$listenTo(sqLicenseManagementStore, setLicenseVars);
  $scope.$on('$stateChangeSuccess', setMainVars);

  function setMainVars() {
    // Hold the workbook vars constant until the state change is complete. Otherwise, the error page will flash
    // because the workbook display has not been set.
    if ($state.transition) {
      return;
    }

    vm.state = $state.current ? $state.current.name : '';

    vm.params = $state.params;

    /**
     * True if a worksheet is being displayed, false otherwise.
     */
    vm.worksheetDisplayed = _.includes(
      [APP_STATE.WORKSHEET, APP_STATE.VIEW_WORKSHEET, APP_STATE.PRESENT_WORKSHEET],
      vm.state,
    );

    vm.isScreenshotMode = headlessRenderMode();
    vm.isPresentationMode = vm.worksheetDisplayed && isPresentationWorkbookMode();

    /**
     * True if the 'Edit Profile' window is being displayed, false otherwise.
     */
    vm.userProfileDisplayed =
      (sqWorkbenchStore.displayUserProfile || !sqWorkbenchStore.userTimeZone) &&
      !vm.isPresentationMode &&
      !vm.isScreenshotMode;
    vm.worksheetDisplayEdit = vm.worksheetDisplayed && sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.EDIT;

    /**
     * True if administration is being displayed, false otherwise.
     */
    vm.administrationDisplayed = vm.state === APP_STATE.ADMINISTRATION;

    /**
     * True if licenseDisplayed is being displayed, false otherwise.
     */
    vm.licenseDisplayed = vm.state === APP_STATE.LICENSE;

    /**
     * True if logTracker is being displayed, false otherwise.
     */
    vm.logTrackerDisplayed = vm.state === APP_STATE.LOG_TRACKER;

    /**
     * True if auditTrail is being displayed, false otherwise.
     */
    vm.auditTrailDisplayed = vm.state === APP_STATE.AUDIT_TRAIL;

    vm.reportTemplateDisplayed = vm.state === APP_STATE.REPORT_TEMPLATE;

    vm.homeScreenAddOnDisplayed = vm.state === APP_STATE.HOME_SCREEN_ADD_ON;

    /**
     * Update worksheetPanel collapsed state. This needs to be here because the
     * worksheets panel is used in both the workbook and worksheet states.
     */
    vm.worksheetsPanelCollapsed =
      vm.worksheetDisplayed &&
      (isViewOnlyWorkbookMode()
        ? sqWorkbenchStore.viewOnlyWorksheetPanelCollapsed
        : sqWorkbenchStore.worksheetPanelCollapsed);

    /**
     * Whenever we update the main vars, we also want to check if we need to update
     * the document title
     */
    $state.current.data.title = getDocumentTitle();
    $state.current.data.isAnalysis =
      _.indexOf([APP_STATE.PRESENT_WORKSHEET, APP_STATE.WORKSHEET, APP_STATE.VIEW_WORKSHEET], $state.current.name) >
        -1 && !sqWorkbookStore.isReportBinder;
    $state.current.data.isReport =
      _.indexOf([APP_STATE.PRESENT_WORKSHEET, APP_STATE.WORKSHEET, APP_STATE.VIEW_WORKSHEET], $state.current.name) >
        -1 && sqWorkbookStore.isReportBinder;
  }

  vm.closeUserProfileEditModal = () => sqWorkbenchActions.setUserProfileDisplay(false);

  /**
   * Syncs with the license management store
   */
  function setLicenseVars() {
    vm.displayExpirationWarning = sqLicenseManagementStore.displayLicenseWarning;
  }

  function getDocumentTitle() {
    const documentTitle = [];

    if (vm.worksheetDisplayed) {
      documentTitle.push(sqWorkbookStore.getWorksheetName($state.params.worksheetId));
      documentTitle.push(sqWorkbookStore.name);
    }

    if (vm.administrationDisplayed) {
      documentTitle.push('Administration');
    }

    if (vm.logTrackerDisplayed) {
      documentTitle.push('View Logs');
    }

    if (vm.licenseDisplayed) {
      documentTitle.push('Manage License');
    }

    if (vm.auditTrailDisplayed) {
      documentTitle.push('Audit Trail');
    }

    return documentTitle.join(' - ');
  }
}
