import { PluginOutputV1 } from '@/sdk/model/PluginOutputV1';
import { sqPluginsApi } from '@/sdk';
import { flux } from '@/core/flux.module';
import { PluginState } from '@/hybrid/plugin/pluginApiDefinition';
import { PluginIdentifier } from '@/hybrid/plugin/pluginHost.constants';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { getInjector } from '@/hybrid/utilities/conversion.utilities';

export function load() {
  return sqPluginsApi.getPlugins({ offset: 0, limit: 5000 }).then(({ data: { plugins } }) => {
    setPlugins(plugins);
    getInjector().get<InvestigateActions>('sqInvestigateActions').setPluginTools(plugins);
  });
}

export function setPlugins(plugins: PluginOutputV1[] = []) {
  flux.dispatch('PLUGINS_SET', { plugins });
}

export function setPluginState(pluginIdentifier: PluginIdentifier, pluginState: PluginState) {
  flux.dispatch('PLUGINS_SET_STATE', { pluginIdentifier, pluginState });
}

export function setDisplayPaneRenderComplete(complete: boolean) {
  flux.dispatch('PLUGINS_SET_DISPLAY_PANE_RENDER_COMPLETE', { complete });
}

export function setQueryParam(queryParam: string) {
  flux.dispatch('PLUGINS_SET_QUERY_PARAM', { queryParam });
}
