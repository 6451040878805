// @ts-strict-ignore
import moment from 'moment-timezone';
import _ from 'lodash';
import { STRING_UOM } from '@/main/app.constants';
import { INTERPOLATION_TYPES } from '@/hybrid/tools/manualSignal/manualSignal.constants';
import { sqManualSignalStore } from '@/core/core.stores';

export type ManualSignalUtilitiesService = ReturnType<typeof sqManualSignalService>;

export function sqManualSignalService() {
  const service = {
    getSampleFormula,
    generateFormula,
  };

  return service;

  function getSampleFormula(sample, uom, isStringSignal) {
    const value = isStringSignal ? `'${sample.value}'` : `${sample.value}${_.isUndefined(uom) ? '' : uom}`;
    return `sample('${moment(sample.dateTime).toISOString()}', ${value})`;
  }

  function generateFormula() {
    const { interpolationMethod, maximumInterpolation, samples, uom } = sqManualSignalStore;
    const isStringSignal = uom === STRING_UOM || _.isNaN(_.toNumber(samples[0].value));
    const samplesForFormula = _.chain(samples)
      .map((sample) => service.getSampleFormula(sample, uom, isStringSignal))
      .join(',\n')
      .value();
    const maxInterp =
      interpolationMethod === INTERPOLATION_TYPES.DISCRETE
        ? '0h'
        : `${maximumInterpolation.value}${maximumInterpolation.units}`;

    return `signal(InterpolationMethod.${interpolationMethod}, ${maxInterp},\n${samplesForFormula})`;
  }
}
