// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { refreshAdminContactInfo, setActiveTabIndex } from '@/administration/administration.actions';
import { AgentKeyOutputV1, sqSystemApi } from '@/sdk';
import { AdminContactModal } from '@/hybrid/administration/AdminContactModal.molecule';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { CopyableInputField } from '@/hybrid/core/CopyableInputField.molecule';
import { UserTable } from '@/hybrid/administration/UserTable.page';
import { GroupsTab } from '@/hybrid/administration/groups/GroupsTab.page';
import { ReportTab } from '@/hybrid/administration/ReportTab.page';
import { RequestsTab } from '@/hybrid/administration/RequestsTab.page';
import { AgentsTab } from '@/hybrid/administration/agents/AgentsTab.page';
import { SubscriptionsTab } from '@/hybrid/administration/SubscriptionsTab.page';
import { JobsTab } from '@/hybrid/administration/JobsTab.page';
import { ConfigurationTable } from '@/hybrid/administration/ConfigurationTable.page';
import { ExportsTab } from '@/hybrid/administration/ExportsTab.page';
import { UsageTab } from '@/hybrid/administration/usage/UsageTab.page';
import { AccessKeyTable } from '@/hybrid/administration/AccessKeyTable.page';
import { PluginTable } from '@/hybrid/administration/PluginTable.page';
import { Tab, Tabs } from 'react-bootstrap';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { AdministrationUtilities } from '@/hybrid/administration/administration.utilities';
import { DatasourcesTab } from '@/hybrid/administration/datasources/DatasourcesTab.page';
import { DatasourceTable } from '@/hybrid/administration/DatasourceTable.page';
import { logError } from '@/hybrid/utilities/logger';
import { sqAdministrationStore } from '@/core/core.stores';
import {
  ACCESS_KEY_TAB_INDEX,
  AGENTS_TAB_INDEX,
  CONFIGURATION_TAB_INDEX,
  DATASOURCES_TAB_INDEX,
  EXPORTS_TAB_INDEX,
  GROUPS_TAB_INDEX,
  JOBS_TAB_INDEX,
  PLUGINS_TAB_INDEX,
  REPORT_TAB_INDEX,
  REQUESTS_TAB_INDEX,
  SUBSCRIPTIONS_TAB_INDEX,
  USAGE_TAB_INDEX,
  USERS_TAB_INDEX,
} from '@/administration/administration.constants';
import {
  datasourceManagementEnabled,
  hardwareWarningsEnabled,
  pluginsEnabled,
  serverSpecs,
} from '@/services/systemConfiguration.utilities';
import { LoadingFallback } from '@/hybrid/main/LoadingFallback.atom';

const administrationBindings = bindingsDefinition({
  sqAdministrationUtilities: injected<AdministrationUtilities>(),
  workbookId: prop<string>(),
  worksheetId: prop<string>(),
});

const WrappedAdministration: SeeqComponent<typeof administrationBindings> = ({ workbookId, worksheetId }) => {
  const { sqAdministrationUtilities } = useInjectedBindings(administrationBindings);
  const { t } = useTranslation();

  const { adminContactInfo, activeTabIndex } = useFlux(sqAdministrationStore);
  const [systemAgentKey, setSystemAgentKey] = useState<AgentKeyOutputV1>(undefined);

  type ModalType = 'adminContact' | 'none';
  const [modalShown, setModalShown] = useState<ModalType>('none');
  const closeModal = () => setModalShown('none');

  useEffect(() => {
    sqSystemApi
      .getAgentKey()
      .then(({ data }) => setSystemAgentKey(data))
      .catch((ex) => logError(ex));

    refreshAdminContactInfo();
  }, []);

  const adminTabs = [
    {
      title: 'USERS',
      icon: 'fa-user',
      component: <UserTable />,
      id: 'users',
      index: USERS_TAB_INDEX,
    },
    {
      title: 'GROUPS',
      icon: 'fa-group',
      component: <GroupsTab />,
      id: 'groups',
      index: GROUPS_TAB_INDEX,
    },
    {
      title: 'REPORTS',
      icon: 'fa-file',
      component: <ReportTab />,
      id: 'reports',
      index: REPORT_TAB_INDEX,
    },
    {
      title: 'USAGE',
      icon: 'fa-tachometer',
      component: <UsageTab />,
      id: 'usage',
      index: USAGE_TAB_INDEX,
    },
    {
      title: 'REQUESTS',
      icon: 'fc-requests',
      component: <RequestsTab />,
      id: 'requests',
      index: REQUESTS_TAB_INDEX,
    },
    {
      title: 'DATASOURCES',
      icon: 'fa-database',
      component: datasourceManagementEnabled() ? (
        <DatasourcesTab workbookId={workbookId} worksheetId={worksheetId} />
      ) : (
        <DatasourceTable workbookId={workbookId} worksheetId={worksheetId} />
      ),
      id: 'datasources',
      index: DATASOURCES_TAB_INDEX,
    },
    {
      title: 'AGENTS',
      icon: 'fa-database',
      component: <AgentsTab />,
      id: 'agents',
      index: AGENTS_TAB_INDEX,
      hide: !datasourceManagementEnabled(),
    },
    {
      title: 'SUBSCRIPTIONS',
      icon: 'fc-subscription',
      component: <SubscriptionsTab />,
      id: 'subscriptions',
      index: SUBSCRIPTIONS_TAB_INDEX,
    },
    {
      title: 'JOBS',
      icon: 'fc-jobs',
      component: <JobsTab />,
      id: 'jobs',
      index: JOBS_TAB_INDEX,
    },
    {
      title: 'CONFIGURATION',
      icon: 'fa-gears',
      component: <ConfigurationTable />,
      id: 'configuration',
      index: CONFIGURATION_TAB_INDEX,
    },
    {
      title: 'EXPORTS',
      icon: 'fc-export',
      component: <ExportsTab />,
      id: 'exports',
      index: EXPORTS_TAB_INDEX,
    },
    {
      title: 'ACCESS_KEYS',
      icon: 'fa-key',
      component: <AccessKeyTable />,
      id: 'accessKey',
      index: ACCESS_KEY_TAB_INDEX,
    },
    {
      title: 'PLUGINS',
      icon: 'fa-flask',
      component: <PluginTable workbookId={workbookId} worksheetId={worksheetId} />,
      id: 'plugins',
      index: PLUGINS_TAB_INDEX,
      hide: !pluginsEnabled(),
    },
  ];

  const renderTabTitle = (tab) => (
    <>
      <Icon icon={tab.icon} type="theme" extraClassNames="tab-icon pr3" />
      <span>{t(`ADMIN.TABS.${tab.title}`)}</span>
    </>
  );

  return (
    <div className="flexRowContainer flexFill administration">
      <div className="flexColumnContainer flexSpaceBetween mb5">
        <div className="flexRowContainer">
          <h4 className="mt0">{t('ADMIN.CONFIGURATION.TITLE')}</h4>
          <div>
            <span>{t('ADMIN.CONFIGURATION.CONTACT_INFORMATION_SHORT')}</span>
            <Icon
              icon="fa-question-circle"
              large={true}
              extraClassNames="mr5 ml5 text-interactive"
              tooltip="ADMIN.CONFIGURATION.CONTACT_INFORMATION_TOOLTIP"
              tooltipPlacement="top"
              testId="adminInfoIcon"
            />
            <a id="openAdminContactInfoModal" href="#" onClick={() => setModalShown('adminContact')}>
              {adminContactInfo?.name ? adminContactInfo.name : t('ADMIN.CONFIGURATION.NOT_CONFIGURED')}
            </a>
          </div>

          {modalShown === 'adminContact' && (
            <AdminContactModal name={adminContactInfo.name} email={adminContactInfo.email} onClose={closeModal} />
          )}
        </div>
        <div>
          <span className="admin">{t('ADMIN.SEEQ_AGENT_KEY.HEADER')}</span>
          <span className="input-group">
            <span className="input-group-btn">
              <CopyableInputField
                testId="agentKey"
                value={systemAgentKey?.agentKey}
                readOnly={true}
                fieldTooltip=""
                notifyMessage="ADMIN.SEEQ_AGENT_KEY.COPY_SUCCESS"
                buttonTooltip="ADMIN.SEEQ_AGENT_KEY.COPY_TO_CLIPBOARD"
              />
            </span>
          </span>
        </div>
      </div>

      <div className="flexColumnContainer flexFill">
        {!_.isEmpty(serverSpecs()) && (
          <div className="flexRowContainer">
            <strong className="mb0">{t('ADMIN.CONFIGURATION.HEADER_SERVER_INFO')}</strong>
            {_.map(serverSpecs(), (component, index) => (
              <div className="mt5 mb5 mr10 flexSpaceBetween flexColumnContainer" key={index}>
                <div className="mr5">{component.componentName}:</div>
                <div className="nowrap">
                  {sqAdministrationUtilities.getFriendlySystemSpec(component.systemSpecValue)}
                  {hardwareWarningsEnabled() && (
                    <Icon {...sqAdministrationUtilities.getComponentIconProps(component)} extraClassNames="ml5" />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="flexColumnContainer flexFill">
          <div className="flexRowContainer flexFill">
            <Tabs
              activeKey={activeTabIndex}
              onSelect={(index) => setActiveTabIndex(_.toInteger(index), true)}
              mountOnEnter={true}
              unmountOnExit={true}
              transition={false}>
              {_.map(_.reject(adminTabs, 'hide'), (tab) => (
                <Tab eventKey={_.toString(tab.index)} key={tab.index} title={renderTabTitle(tab)} id={`${tab.id}Tab`}>
                  <div
                    id={`${tab.id}Administration`}
                    className="height-maximum width-maximum overflowAuto flexRowContainer flexFill pl20 pt5 leftBorder">
                    {tab.component}
                  </div>
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

// Wrap in Suspense to ensure translations are loaded before displaying the component
export const Administration: SeeqComponent<typeof administrationBindings> = (props) => {
  return (
    <React.Suspense fallback={<LoadingFallback fallbackLocation="Administration page" />}>
      <WrappedAdministration {...props} />
    </React.Suspense>
  );
};

export const sqAdministration = angularComponent(administrationBindings, Administration);
export const API_KEY_SUFFIX = '_api_key';
export const SYSTEM_USERNAME = 'system';
export const EVERYONE_USERGROUP = 'Everyone';
