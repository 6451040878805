// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { TrendActions } from '@/trendData/trend.actions';
import { useTranslation } from 'react-i18next';
import { LABEL_LOCATIONS } from '@/trendData/trendData.constants';

const customLabelInputBindings = bindingsDefinition({
  label: prop<{ target: string; location: LABEL_LOCATIONS; text: string }>(),
  sqTrendActions: injected<TrendActions>(),
});

/**
 * Input and label for use in the Custom Lane/Axis Labels in the trend labels popover.
 */
export const CustomLabelInput: SeeqComponent<typeof customLabelInputBindings> = ({ label }) => {
  const { sqTrendActions } = useInjectedBindings(customLabelInputBindings);
  const { t } = useTranslation();

  const [labelText, setLabelText] = useState(label.text);

  useEffect(() => {
    setLabelText(label.text);
  }, [label.text]);

  let customTranslateLabel;
  if (label.location === LABEL_LOCATIONS.LANE) {
    customTranslateLabel = 'TOOLBAR.LANE';
  } else if (label.location === LABEL_LOCATIONS.AXIS) {
    customTranslateLabel = 'TOOLBAR.AXIS';
  }

  return (
    <div className="form-group ml10" key={label.target}>
      <label>{`${t(customTranslateLabel)} ${label.target}`}</label>
      <input
        type="text"
        className="form-control"
        placeholder={`${t(customTranslateLabel)} ${label.target}`}
        id={`customLabel${label.target}`}
        data-testid={`customLabel${label.target}`}
        value={labelText}
        onChange={(e) => setLabelText(e.target.value)}
        onBlur={(e) => sqTrendActions.setCustomLabel(label.location, label.target, e.target.value)}
      />
    </div>
  );
};
