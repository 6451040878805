import React from 'react';

export interface FormulaLinkProps {
  href: string;
  requestDocumentation: (href: string) => void;
}

export const FormulaLink: React.FunctionComponent<FormulaLinkProps> = ({ href, requestDocumentation, children }) => (
  <a href="#" onClick={() => requestDocumentation(href)}>
    {children}
  </a>
);
