// @ts-strict-ignore
import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { Icon } from '@/hybrid/core/Icon.atom';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { viewsWorkSheetView } from '@/hybrid/worksheets/worksheetView.utilities';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { sqWorksheetStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';
import { isSvgIcon } from '@/hybrid/utilities/utilities';
import { SvgIcon } from '@/hybrid/core/SvgIcon.atom';

const worksheetViewSelectorBindings = bindingsDefinition({
  sqWorksheetActions: injected<WorksheetActions>(),
  extraClassNames: prop.optional<string>(),
});

/**
 * Displays the active view and allows the user to choose the view that should be displayed from the list of available
 * views.
 */
export const WorksheetViewSelector: SeeqComponent<typeof worksheetViewSelectorBindings> = ({ extraClassNames }) => {
  const { sqWorksheetActions } = useInjectedBindings(worksheetViewSelectorBindings);

  const { view } = useFlux(sqWorksheetStore);
  const { t } = useTranslation();

  const setViewAndTrack = (view) => {
    doTrack('change view', view.trackAction);
    sqWorksheetActions.setView(view.key);
  };

  const views = _.filter(viewsWorkSheetView(), (view) => !view.hideFromViewSelector);

  const dropdownItems = _.map(views, (view) => (
    <Dropdown.Item
      key={view.key}
      onClick={(e) => {
        e.preventDefault();
        setViewAndTrack(view);
      }}
      className="mb10">
      {isSvgIcon(view.icon) ? (
        <SvgIcon icon={view.icon} extraClassNames="viewSelectorSvg viewSelectorSvgDropdown" />
      ) : (
        <Icon icon={view.icon} extraClassNames="fa-xlg mr10" type="inherit" />
      )}
      {t(view.name)}
    </Dropdown.Item>
  ));

  return (
    <Dropdown
      className={classNames(extraClassNames, 'flexRowContainer worksheetViewSelector mr5')}
      onClick={(e) => e.stopPropagation()}>
      <Dropdown.Toggle
        data-testid="worksheetViewSelectorToggle"
        className="flexColumnContainer flexCenter sq-btn-theme"
        bsPrefix="sq-btn">
        {isSvgIcon(view.icon) ? (
          <SvgIcon icon={view.icon} extraClassNames="viewSelectorSvg" />
        ) : (
          <Icon icon={view.icon} extraClassNames="icon fa-xlg flexNoGrowNoShrink" type="inherit" />
        )}
        <Icon icon="fa-chevron-down" extraClassNames="flexNoGrowNoShrink pl5" type="inherit" />
      </Dropdown.Toggle>
      <Dropdown.Menu>{dropdownItems}</Dropdown.Menu>
    </Dropdown>
  );
};
