// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';

import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { Icon } from '@/hybrid/core/Icon.atom';
import { errorToast } from '@/hybrid/utilities/toast.utilities';

const pinnedActionIconBindings = bindingsDefinition({
  item: prop<{ isPinned: boolean; id: string }>(),
  onChange: prop.optional<(Boolean) => void>(),
  activeWorkbook: prop<boolean>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
});

export const PinnedActionIcon: SeeqComponent<typeof pinnedActionIconBindings> = ({
  activeWorkbook,
  item,
  onChange = () => {},
}) => {
  const { sqHomeScreenUtilities } = useInjectedBindings(pinnedActionIconBindings);

  const [isPinned, setIsPinned] = useState(item.isPinned);

  const toggle = () => {
    const originalState = isPinned;
    setIsPinned(!isPinned);
    // legacy code relies on a workbookId
    return sqHomeScreenUtilities
      .toggleIsPinned(_.assign({}, item, { workbookId: item.id }))
      .then(() => onChange(isPinned))
      .catch((e) => {
        onChange(originalState);
        setIsPinned(originalState);
        errorToast({ httpResponseOrError: e });
      });
  };

  const baseClasses = classNames('sq-text-primary', 'fa-fw', 'correctSpin');
  const pinnedIconClasses = isPinned
    ? classNames(baseClasses, 'fc', 'fc-pin', 'cursorPointer', 'mt12')
    : classNames(baseClasses, 'fc-pin-o', 'fc', 'cursorPointer', {
        folderActions: !activeWorkbook,
      });

  return (
    <span className="pt10 pb10 pr6 pl6 sq-icon-hover cursorPointer" onClick={toggle}>
      <Icon
        icon={pinnedIconClasses}
        tooltip={item.isPinned ? 'WORKBENCH.UNMARK_FAVORITE' : 'WORKBENCH.MARK_FAVORITE'}
        tooltipPlacement="top"
        tooltipDelay={500}
        testId="homeScreenActionPin"
        id="favorite"
      />
    </span>
  );
};
