import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { CancelAndExecute } from '@/hybrid/core/CancelAndExecute.molecule';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { sqReportStore } from '@/core/core.stores';

interface ReportContentModalButtonsProps {
  cancel: any;
  submit: any;
  executeLabel: string;
  customTooltip: string;
}

export const ReportContentModalButtons: React.FunctionComponent<ReportContentModalButtonsProps> = ({
  cancel,
  submit,
  executeLabel,
  customTooltip,
}) => {
  const { t } = useTranslation();

  const selectedContent = useFluxPath(sqReportStore, () => sqReportStore.selectedBulkContent);

  const updateTooltip = !_.isEmpty(selectedContent) ? customTooltip : undefined;

  const submitBtnFormattedLabel = (
    <>
      {t(executeLabel)} ({_.size(selectedContent)})
    </>
  );

  return (
    <CancelAndExecute
      cancelAction={cancel}
      submitAction={submit}
      btnDisabled={_.isEmpty(selectedContent)}
      submitBtnTestId="bulkUpdateButton"
      submitBtnFormattedLabel={submitBtnFormattedLabel}
      submitBtnTooltip={updateTooltip}
    />
  );
};
