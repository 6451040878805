// @ts-strict-ignore
import _ from 'lodash';
import {
  COMPARISON_OPERATORS_SYMBOLS,
  DEPRECATED_TOOL_NAMES,
  DEVIATION_CONDITIONS,
} from '@/hybrid/toolSelection/investigate.constants';
import i18next from 'i18next';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { SMOOTHING_ALGORITHMS } from '@/hybrid/tools/signalSmoothing/signalSmoothing.constants';
import { serverTimezone } from '@/services/systemConfiguration.utilities';

export const CONFIG_SCHEMA_VERSION = 11;

/**
 * A utility that manages changes to the UI Config (see workstepUpgrader for workstep upgrades)
 * 1. Create a function named `upgradeX` where X is the current CONFIG_SCHEMA_VERSION and add it to the upgraders
 * object.
 * 2. Increase CONFIG_SCHEMA_VERSION by one
 */

/**
 * The collection of upgrade functions, one per version. Each accepts config from that version and returns the
 * migrated config.
 */
const upgraders = {
  upgrade1,
  upgrade2,
  upgrade3,
  upgrade4,
  upgrade5,
  upgrade6,
  upgrade7,
  upgrade8,
  upgrade9,
  upgrade10,
};

/**
 * Upgrades the config for a tool from the specified version to the latest version. Runs the config
 * through a series of transform functions, in order, from the state's version to the specified version.
 *
 * @param {Object} config - The configuration to be upgraded
 * @param {Number} fromVersion - The version number from when the config was created
 * @param {Number} [toVersion=CONFIG_SCHEMA_VERSION] - The version number up to which the config will be migrated.
 * Useful only for testing.
 * @returns {Object} The transformed config.
 */
export function applyConfigUpgrade(config, fromVersion, toVersion?) {
  toVersion = toVersion || CONFIG_SCHEMA_VERSION;
  fromVersion = _.isUndefined(fromVersion) ? 1 : fromVersion;
  return _.assign(
    _.reduce(
      _.range(fromVersion, toVersion, 1),
      function (newConfig, newVersion) {
        return upgraders[`upgrade${newVersion}`](newConfig);
      },
      config,
    ),
    { configVersion: CONFIG_SCHEMA_VERSION },
  );
}

/**
 * Renames "limits" to "value-search"
 */
function upgrade1(config) {
  if (config.type === DEPRECATED_TOOL_NAMES.LIMITS) {
    _.set(config, 'type', TREND_TOOLS.VALUE_SEARCH);
  }
  return config;
}

/**
 * Migrates a Deviation Search config to a Value Search (if simple) or the formula tool (if advanced)
 */
function upgrade2(config) {
  if (config.type === DEPRECATED_TOOL_NAMES.DEVIATION) {
    const isSimpleDeviationSearch =
      config.advancedParametersCollapsed ||
      ((config.startLocation ? config.startLocation : config.startDuration?.value === 0) &&
        (config.endLocation ? config.endLocation : config.endDuration?.value === 0));

    if (isSimpleDeviationSearch) {
      _.set(config, 'type', TREND_TOOLS.VALUE_SEARCH);
      // This is used to show a warning to users the first time it is opened in the Value Search tool
      _.set(config, 'isMigratedDeviationSearch', true);

      // Migrate parameters
      const inputSignal = _.find(config.parameters, { name: 'input' }) as any;
      _.set(inputSignal, 'name', 'a');
      const upperSignal = _.find(config.parameters, {
        name: 'boundary1',
      }) as any;
      _.set(upperSignal, 'name', 'b');

      // Set necessary store variables for value search
      _.assign(config, {
        isSimple: true,
        simpleValue: upperSignal.item.id,
        version: 'V3',
      });

      // Set operator, formula, and lower value (if applicable)
      switch (config.condition) {
        case DEVIATION_CONDITIONS.ABOVE:
          _.set(config, 'simpleOperator', COMPARISON_OPERATORS_SYMBOLS.IS_GREATER_THAN);
          _.set(config, 'formula', '$a > $b');
          break;
        case DEVIATION_CONDITIONS.BELOW:
          _.set(config, 'simpleOperator', COMPARISON_OPERATORS_SYMBOLS.IS_LESS_THAN);
          _.set(config, 'formula', '$a < $b');
          break;
        case DEVIATION_CONDITIONS.OUTSIDE:
          const lowerValue = _.find(config.parameters, {
            name: 'boundary2',
          }) as any;
          _.set(lowerValue, 'name', 'c');
          _.set(config, 'simpleLowerValue', lowerValue.item.id);
          _.set(config, 'simpleOperator', COMPARISON_OPERATORS_SYMBOLS.IS_NOT_BETWEEN);
          _.set(config, 'formula', '$a > $b || $a < $c');
          break;
      }

      // Set durations
      if (!config.advancedParametersCollapsed && (config.startDuration?.value || config.endDuration?.value)) {
        _.set(config, 'isCleansing', true);
        _.set(config, 'minDuration', config.startDuration);
        _.set(config, 'mergeDuration', config.endDuration);
      }
    } else {
      _.set(config, 'type', TREND_TOOLS.FORMULA);

      // Adds a comment to the formula to notify users about the migration
      const formula = `${
        `/**\n * ${i18next.t('FORMULA.DEVIATION_MIGRATION')}\n` + ` * ${i18next.t('FORMULA.RESULT_UNCHANGED')}\n */\n`
      }${config.formula}`;
      _.set(config, 'formula', formula);
    }

    // Remove old configuration information
    delete config.advancedParametersCollapsed;
    delete config.condition;
    delete config.startDuration;
    delete config.startLocation;
    delete config.endDuration;
    delete config.endLocation;
    delete config.conditionMetadata;
  }

  return config;
}

/**
 * Migrates Low Pass Filter to the Signal Smoothing Tool
 */
function upgrade3(config) {
  if (config.type === DEPRECATED_TOOL_NAMES.LOW_PASS) {
    _.set(config, 'type', TREND_TOOLS.SIGNAL_SMOOTHING);

    // This is used to show a warning to users the first time it is opened in the Signal Smoothing tool
    _.set(config, 'isMigratedLowPassFilter', true);

    // Migrate Parameters
    const inputSignal = _.find(config.parameters, { name: 'series' }) as any;
    _.set(inputSignal, 'name', 'inputSignal');

    // Migrate UI Config
    _.set(config, 'algorithmSelectedValue', SMOOTHING_ALGORITHMS.LOW_PASS.VALUE);
    _.set(config, 'isCutoffAuto', false);
    _.set(config, 'samplingRate', config.period);
    _.set(config, 'isSamplingRateAuto', config.autoPeriod);
    // Smoothing Window = Period * Taps
    _.set(config, 'smoothingWindow', {
      value: config.period.value * config.taps,
      units: config.period.units,
      valid: true,
    });

    // Force advanced panel to open so users see the cutoff
    _.set(config, 'advancedParametersCollapsed', false);

    // Remove the old config information
    _.forEach(['period', 'autoPeriod', 'taps', 'autoTaps', 'samplePeriod'], (property) => delete config[property]);
  }
  return config;
}

/**
 * Adds between operator inclusivity information to simple Value Searches
 */
function upgrade4(config) {
  if (config.type === TREND_TOOLS.VALUE_SEARCH && config.isSimple) {
    if (config.simpleOperator === COMPARISON_OPERATORS_SYMBOLS.IS_BETWEEN) {
      _.set(config, 'simpleUpperValueInclusivity', COMPARISON_OPERATORS_SYMBOLS.IS_LESS_THAN);
      config?.formula.includes('.isBetween')
        ? _.set(config, 'simpleLowerValueInclusivity', COMPARISON_OPERATORS_SYMBOLS.IS_GREATER_THAN_OR_EQUAL_TO)
        : _.set(config, 'simpleLowerValueInclusivity', COMPARISON_OPERATORS_SYMBOLS.IS_GREATER_THAN);
    }

    if (config.simpleOperator === COMPARISON_OPERATORS_SYMBOLS.IS_NOT_BETWEEN) {
      config?.formula.includes('.isNotBetween')
        ? _.set(config, 'simpleUpperValueInclusivity', COMPARISON_OPERATORS_SYMBOLS.IS_GREATER_THAN_OR_EQUAL_TO)
        : _.set(config, 'simpleUpperValueInclusivity', COMPARISON_OPERATORS_SYMBOLS.IS_GREATER_THAN);
      _.set(config, 'simpleLowerValueInclusivity', COMPARISON_OPERATORS_SYMBOLS.IS_LESS_THAN);
    }
  }
  return config;
}

/**
 * Renames "visual-search" to "profile-search"
 */
function upgrade5(config) {
  if (config.type === DEPRECATED_TOOL_NAMES.VISUAL_SEARCH) {
    _.set(config, 'type', TREND_TOOLS.PROFILE_SEARCH);
  }
  return config;
}

/**
 * Renames "capsule-set-series" to "signal-from-condition"
 */
function upgrade6(config) {
  if (config.type === DEPRECATED_TOOL_NAMES.CAPSULE_SET_SERIES) {
    _.set(config, 'type', TREND_TOOLS.SIGNAL_FROM_CONDITION);
  }
  return config;
}

/**
 * Renames "power-search" to "formula"
 */
function upgrade7(config) {
  if (config.type === DEPRECATED_TOOL_NAMES.POWER_SEARCH) {
    _.set(config, 'type', TREND_TOOLS.FORMULA);
  }
  return config;
}

/**
 * Renames "custom-condition" to "manual-condition"
 */
function upgrade8(config) {
  if (config.type === DEPRECATED_TOOL_NAMES.CUSTOM_CONDITION) {
    _.set(config, 'type', TREND_TOOLS.MANUAL_CONDITION);
  }
  return config;
}

/**
 * Renames "savitsky-golay" to "savitzky-golay"
 */
function upgrade9(config) {
  if (config.type === TREND_TOOLS.SIGNAL_SMOOTHING && config.algorithmSelectedValue === 'savitsky-golay') {
    _.set(config, 'algorithmSelectedValue', SMOOTHING_ALGORITHMS.SAVITZKY.VALUE);
  }
  return config;
}

/**
 * Periodic conditions created with undefined timezone will now default to the server timezone
 */
function upgrade10(config) {
  if (config.type === TREND_TOOLS.PERIODIC_CONDITION && _.isUndefined(config.timezone)) {
    _.set(config, 'timezone', serverTimezone()?.name);
  }
  return config;
}
