// @ts-strict-ignore
import _ from 'lodash';
import { ErrorTypeEnum } from '@/sdk/model/FormulaErrorOutputV1';
import moment from 'moment';
import i18next from 'i18next';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { DISPLAY_NUMBER_LENGTH, formatNumber, roundWithPrecision } from '@/hybrid/utilities/numberHelper.utilities';
import {
  AXIS_ALIGN,
  AXIS_CUSTOMIZATIONS_COLUMNS,
  CAPSULE_TIME_COLUMNS,
  CUSTOMIZATION_MODES,
  CUSTOMIZATIONS_COLUMNS,
  EXTRA_COLUMNS,
  EXTRA_CUSTOMIZATION_COLUMNS,
  ITEM_CHILDREN_TYPES,
  ITEM_DATA_STATUS,
  ITEM_TYPES,
  REQUIRED_COLUMNS,
  STAT_COLUMNS,
  TREND_COLOR_COLUMN,
  TREND_PANELS,
  TREND_VIEWS,
} from '@/trendData/trendData.constants';
import { cancelGroup } from '@/hybrid/requests/pendingRequests.utilities';
import { customizationEnabled } from '@/hybrid/utilities/detailsPanel.helper';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { sqPluginStore, sqTrendStore, sqWorksheetStore } from '@/core/core.stores';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { exploreAssetSearchActions } from '@/search/search.actions';

const COLORS = [
  '#00B7C3',
  '#C30052',
  '#2B78C0',
  '#FB763A',
  '#6DAB51',
  '#FFB900',
  '#CA5010',
  '#9A0089',
  '#10893E',
  '#2D7D9A',
  '#ffbb00',
  '#7cbb00',
  '#b84592',
  '#ff9900',
  '#537b35',
  '#832561',
  '#b3dcff',
  '#205081',
  '#bf033b',
];

const OTHER_COLOR = '#CCCCCC';

/**
 * This function is used when the capsule grouping mode is enabled.
 * It returns the background color for the table so that all the signals grouped with a condition will receive the
 * same background color as that condition.
 * This logic is based on the index of the conditions as the driver for the background striping.
 */
export function applyTableBackground(item, conditions) {
  const lookUpId = item.itemType === ITEM_TYPES.CAPSULE_SET ? item.id : item.groupedTo;
  const index = _.findIndex(conditions, { id: lookUpId });
  return index % 2;
}

export function cancelRequest(items) {
  _.chain(items as any[])
    .filter(['dataStatus', ITEM_DATA_STATUS.LOADING])
    .forEach((item) => cancelGroup(item.id))
    .value();
}

export function customizationDisabled(column, item) {
  if (column.disabledIf) {
    return _.iteratee(column.disabledIf)(item);
  } else if (column.disabledIfNot) {
    return !_.iteratee(column.disabledIfNot)(item);
  } else {
    return false;
  }
}

export function formatItemsForGroupingModeDisplay(items, sort, capsuleGroupingMode) {
  if (!capsuleGroupingMode) {
    return items;
  }

  const conditions = _.filter(items, { itemType: ITEM_TYPES.CAPSULE_SET });
  let displayItems = [];
  _.forEach(conditions, (condition: any) => {
    if (applyTableBackground(condition, conditions)) {
      condition.rowClasses = [...condition.rowClasses, 'striped'];
    }
    displayItems.push(condition);
    const assignedSignals = [];
    _.forEach(sqWorksheetStore.conditionToSeriesGrouping[condition.id], (seriesId) => {
      const entry: any = _.assign({}, _.find(items, { id: seriesId }), {
        groupColor: condition.color,
        trackId: `${seriesId}_${condition.id}`,
        groupedTo: condition.id,
      });
      if (applyTableBackground(entry, conditions)) {
        entry.rowClasses = [...entry.rowClasses, 'striped'];
      }
      assignedSignals.push(entry);
    });
    displayItems = _.concat(displayItems, _.orderBy(assignedSignals, [sort.sortBy], [sort.sortAsc ? 'asc' : 'desc']));
  });

  return displayItems;
}

/**
 *  Limits the width of pointValue for string series by shortening strings longer than six characters
 */
export function formatPointValue(item) {
  let pointValue =
    item.itemType === ITEM_TYPES.SCALAR ? item.value : _.get(sqTrendStore.pointValues, [item.id, 'pointValue']);

  if (item.isStringSeries) {
    if (_.get(pointValue, 'length') > DISPLAY_NUMBER_LENGTH + 1) {
      pointValue = `${pointValue.slice(0, 2)}..${pointValue.slice(3 - DISPLAY_NUMBER_LENGTH)}`;
    }
  } else {
    pointValue = formatNumber(pointValue, item.formatOptions);
  }
  return pointValue;
}

export function getDisplayText(value, displayTime) {
  return displayTime ? getDisplayTime(parseFloat(value)) : parseFloat(value).toLocaleString();
}

export function getDisplayTime(duration) {
  const durationInSeconds = duration / NUMBER_CONVERSIONS.MILLISECONDS_PER_SECOND;
  if (durationInSeconds >= NUMBER_CONVERSIONS.SECONDS_PER_HOUR) {
    return moment.utc(duration).format('hh:mm:ss');
  } else if (durationInSeconds > 90) {
    return moment.utc(duration).format('mm:ss');
  } else {
    return `${roundWithPrecision(durationInSeconds, 2)}s`;
  }
}

/**
 * Returns all enabled columns. When in Capsule Time do not return auto, min, or max.
 * If Capsule Group Mode is enabled the "Group" column is added.
 */
export function getEnabledColumns({ simplifiedView, allowStats, isGrouping, propertyColumns, items }) {
  let columns = REQUIRED_COLUMNS;
  const customizationMode = sqTrendStore.customizationMode;

  if (!simplifiedView && _.includes([CUSTOMIZATION_MODES.ALL, CUSTOMIZATION_MODES.STYLE], customizationMode)) {
    columns = _.concat(columns, TREND_COLOR_COLUMN);
  }

  if (isGrouping) {
    columns = _.concat(columns, CAPSULE_TIME_COLUMNS);
  }

  if (simplifiedView || customizationMode === CUSTOMIZATION_MODES.OFF) {
    // This is the default view that contains columns that can be added using the add column dropdown
    if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREND) {
      columns = _.concat(columns, TREND_COLOR_COLUMN);
    }

    if (sqWorksheetStore.view.key === WORKSHEET_VIEW.SCATTER_PLOT) {
      columns = columns.concat(AXIS_ALIGN).concat(AXIS_CUSTOMIZATIONS_COLUMNS);
    }

    let optionalColumns = _.concat(
      EXTRA_COLUMNS,
      EXTRA_CUSTOMIZATION_COLUMNS.filter((column) => isCustomizationColumnEnabled(column.key, items)),
    );

    if (allowStats) {
      optionalColumns = _.concat(optionalColumns, STAT_COLUMNS);
    }

    if (!simplifiedView) {
      optionalColumns = _.concat(optionalColumns, propertyColumns);
    }

    columns = _.concat(
      columns,
      _.filter(optionalColumns, (column) => sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, column.key)),
    );
  } else {
    // This is the "Customize" view where in trend view the look of signals can be configured
    const customizationColumns =
      customizationMode === CUSTOMIZATION_MODES.ALL
        ? CUSTOMIZATIONS_COLUMNS
        : _.filter(CUSTOMIZATIONS_COLUMNS, ['mode', customizationMode]);

    columns = _.concat(
      columns,
      _.filter(customizationColumns, (column) => isCustomizationColumnEnabled(column.key, items)),
    );
  }

  return columns;
}

export function getClickPrompt(items) {
  if (items?.length > 1) {
    return '';
  }

  const calcType = _.first(items)['calculationType'];
  const itemType = _.first(items)['itemType'];
  const maxInterpExceeded = hasWarnings(items, 'EXCEEDS_MAXIMUM_INTERPOLATION');

  if (calcType === TREND_TOOLS.FORMULA) {
    return 'DATA_STATUS_ICON_TOOLTIP.MORE_INFO';
  } else if (!_.isUndefined(calcType) && itemType === ITEM_TYPES.SERIES) {
    return 'DATA_STATUS_ICON_TOOLTIP.OPEN_TOOL';
  } else if (_.isUndefined(calcType) && itemType === ITEM_TYPES.CAPSULE_SET) {
    return 'DATA_STATUS_ICON_TOOLTIP.MODIFY_DURATION';
  } else if (_.isUndefined(calcType) && maxInterpExceeded) {
    return 'DATA_STATUS_ICON_TOOLTIP.MODIFY_GAP';
  }

  return '';
}

export function getRequestDetailsLegend(timingLegend, samplesLegend) {
  let doAddOthers = false;
  let others;

  if (_.get(_.last(timingLegend), 'label') === 'Other') {
    others = timingLegend.pop();
    doAddOthers = true;
  }
  if (_.get(_.last(samplesLegend), 'label') === 'Other') {
    doAddOthers = false;
  }
  return _.chain(timingLegend)
    .concat(samplesLegend)
    .filter((legendItem) => !_.isUndefined(legendItem))
    .uniqBy('label')
    .sortBy('label')
    .concat(doAddOthers ? others : [])
    .value();
}

export function getSelectOptions(column, item) {
  return _.chain(column.options as any[])
    .filter((option) => {
      if (option.if) {
        return _.iteratee(option.if)(item);
      } else if (option.ifNot) {
        return !_.iteratee(option.ifNot)(item);
      } else {
        return true;
      }
    })
    .map((option) => {
      if (!_.isUndefined(option.value) && column.style === 'select') {
        return { value: option.value, text: option.value };
      } else if (_.isUndefined(option.value)) {
        return { value: option, text: option };
      } else {
        return {
          value: option.value,
          icon: option.icon,
          text: option.text || '',
        };
      }
    })
    .value();
}

export function getTooltipInformation(item, requestDetailsLegend, lookup) {
  let information;
  if (_.isEmpty(item)) {
    return;
  }

  if (item.label === 'Other') {
    const displayed = _.chain(requestDetailsLegend).map('label').without('Other').value();
    information = _.chain(lookup)
      .filter((current) => _.indexOf(displayed, current.label) < 0)
      .compact()
      .value();
  } else {
    information = _.filter(lookup, { label: item.label });
  }

  return _.map(information, (info) => `${info.source}: ${info.displayValue}`);
}

export function handleItemChanges(items, header) {
  let problemType,
    translateKeys = {},
    displayWrench = false,
    tooltip = '',
    canRetry = true,
    isInProgress = false,
    progress,
    icon = '',
    iconType,
    timedItem;

  if (hasMetadataError(items)) {
    // if the error is related to maximum capsule duration a wrench displays instead of the error triangle as well as
    // a prompt to edit the failing condition.
    problemType = 'metadataError';
    displayWrench = true;
    translateKeys = {
      message: _.chain(items).find('statusMessage').get('statusMessage').value(),
    };
    tooltip = 'DATA_STATUS_ICON_TOOLTIP.FAILURE_METADATA';
    const item = items[0];
    translateKeys = _.assign(translateKeys, {
      prompt:
        item.errorType === ErrorTypeEnum.MAXDURATIONREQUIRED
          ? i18next.t('DATA_STATUS_ICON_TOOLTIP.FAILURE_METADATA_CLICK_PROMPT_MAX_DURATION_REQUIRED')
          : i18next.t('DATA_STATUS_ICON_TOOLTIP.FAILURE_METADATA_CLICK_PROMPT_MAX_DURATION_REMOVED'),
    });
  } else if (_.some(items, ['dataStatus', ITEM_DATA_STATUS.FAILURE])) {
    problemType = 'error';
    tooltip = 'DATA_STATUS_ICON_TOOLTIP.FAILURE';
    translateKeys = {
      message: _.chain(items).find('statusMessage').get('statusMessage').value(),
    };
  } else if (items.length > 0 && _.every(items, ['dataStatus', ITEM_DATA_STATUS.REDACTED])) {
    // Note `_.every` used here intentionally so that an overall error isn't shown at the top of the column
    problemType = 'error';
    canRetry = false;
    tooltip = _.some(items, 'statusMessage')
      ? _.chain(items).find('statusMessage').get('statusMessage').value()
      : 'DATA_STATUS_ICON_TOOLTIP.REDACTED';
  } else if (_.some(items, ['dataStatus', ITEM_DATA_STATUS.LOADING])) {
    icon = 'fa-circle-o-notch fa-spin fa-lg';
    iconType = 'theme';
    tooltip = header ? 'DATA_STATUS_ICON_TOOLTIP.LOADING' : 'DATA_STATUS_ICON_TOOLTIP.LOADING_ITEM';
    // in order to make the progress indicators fit better with the other icons the size was optimized to fit 99 -
    // not 100. As 100 means it's completed anyways we'll just show the rocket instead.
    isInProgress = true;

    if (items.length === 1) {
      progress = items[0].progress ? items[0].progress : 0;
      isInProgress = progress < 100;
    } else {
      progress = 0;
    }
  } else if (hasWarnings(items)) {
    const clickPrompt = getClickPrompt(items);
    problemType = 'warning';
    icon = 'fa-exclamation-circle';
    displayWrench = clickPrompt !== '';
    tooltip = 'DATA_STATUS_ICON_TOOLTIP.WARNING';
    translateKeys = {
      count: _.first(items)['warningCount'],
      clickPrompt: i18next.t(clickPrompt),
      message: (_.chain(items).first() as any)
        .get('warningLogs[0].formulaLogEntries')
        .values()
        .first()
        .get('logDetails[0].message')
        .value(),
    };
  } else if (_.some(items, ['dataStatus', ITEM_DATA_STATUS.CANCELED])) {
    problemType = 'error';
    tooltip = 'DATA_STATUS_ICON_TOOLTIP.CANCELED';
  } else if (
    sqWorksheetStore.view.key === WORKSHEET_VIEW.TREND &&
    _.includes([TREND_VIEWS.CALENDAR, TREND_VIEWS.CHAIN], sqTrendStore.view) &&
    someNonConditionItemHasNoData(items)
  ) {
    problemType = 'warning';
    icon = 'fa-info-circle';
    tooltip = 'DATA_STATUS_ICON_TOOLTIP.NO_DATA';
  } else {
    // only display the Request Details for the individual items, not rolled up.
    icon = '';
    iconType = undefined;
  }

  switch (problemType) {
    case 'error':
      iconType = 'danger';
      icon = 'fa-exclamation-triangle';
      break;
    case 'warning':
      iconType = 'warning';
      break;
    case 'metadataError':
      iconType = 'danger';
      icon = 'fa-wrench';
      break;
  }

  if (header) {
    timedItem = _.filter(items, (item) =>
      'childType' in item ? item.childType === ITEM_CHILDREN_TYPES.METRIC_DISPLAY : true,
    );
  } else {
    // Metrics should get their info from their display item child
    timedItem = _.find(items, { childType: ITEM_CHILDREN_TYPES.METRIC_DISPLAY }) || _.first(items as any[]);
  }

  return {
    icon,
    iconType,
    problemType,
    isInProgress,
    progress,
    tooltip,
    displayWrench,
    translateKeys,
    canRetry,
    timedItem,
  };
}

export function hasMetadataError(items) {
  return _.some(
    items,
    (item) =>
      item.dataStatus === ITEM_DATA_STATUS.FAILURE &&
      (item.errorType === ErrorTypeEnum.MAXDURATIONREQUIRED || item.errorType === ErrorTypeEnum.MAXDURATIONPROHIBITED),
  );
}

export function hasWarnings(items: Object[], ofType: string = undefined) {
  const item = _.first(items) as any;
  return (
    items.length === 1 &&
    item.dataStatus === ITEM_DATA_STATUS.PRESENT &&
    item.warningCount > 0 &&
    !_.isEmpty(item.warningLogs) &&
    (_.isUndefined(ofType) || _.some(item.warningLogs, (log) => _.has(log, `formulaLogEntries.${ofType}`)))
  );
}

/**
 * Determine if a customization column is enabled. A column is enabled if any item has the customization enabled.
 */
export function isCustomizationColumnEnabled(property: string, items) {
  const isPluginShown = !!sqPluginStore.getPlugin(sqWorksheetStore.view.key);
  const pluginShownColumns = sqWorksheetStore.getPluginShownColumns(sqWorksheetStore.view.key, TREND_PANELS.SERIES);
  return (
    (!isPluginShown || pluginShownColumns.includes(property)) &&
    _.some(items, (item) => customizationEnabled(item, property))
  );
}

export function onItemClick(sqInvestigateActions, sqTrendActions, items) {
  if (hasWarnings(items) || hasMetadataError(items)) {
    const item = _.first(items) as any;
    // Open the properties panel for store series and load the appropriate tool for other items
    if (_.isUndefined(item.calculationType) && item.itemType === ITEM_TYPES.SERIES) {
      sqInvestigateActions.setItem(item.id, {
        propertiesFocus: SeeqNames.Properties.MaximumInterpolation,
      });
      sqInvestigateActions.setActiveTool(TREND_TOOLS.PROPERTIES);
    } else if (_.isUndefined(item.calculationType) && item.itemType === ITEM_TYPES.CAPSULE_SET) {
      sqInvestigateActions.setItem(item.id, {
        propertiesFocus: SeeqNames.Properties.MaximumDuration,
      });
      sqInvestigateActions.setActiveTool(TREND_TOOLS.PROPERTIES);
    } else {
      sqInvestigateActions.setItem(item.id, {
        errorType: item.errorType,
        errorCategory: item.errorCategory,
      });
      sqInvestigateActions.loadToolForEdit(item.id);
    }
  }

  _.chain(items as any[])
    .filter((item) => item.dataStatus === ITEM_DATA_STATUS.FAILURE || item.dataStatus === ITEM_DATA_STATUS.CANCELED)
    .reject((item) => hasMetadataError([item]))
    .tap((erroredItems) => {
      if (!_.isEmpty(erroredItems)) {
        sqTrendActions.fetchItems(erroredItems, {
          fetchFailed: true,
          fetchCapsulesLater: true,
        });
      }
    })
    .value();
}

/**
 * This function takes the information provided by the backend (as a String that looks like this:
 * 'aa;a=1;desc="Alpha",bb;b=2;desc="Beta",cc;c=3;dec="Gamma",dd;d=4;dec="Delta"';) and updates the appropriate
 * variables for display.
 */
export function parseInformationString(informationString, displayTime, colorAssignment, existingColors) {
  let progressDisplay;

  const splitString = (input) =>
    _.isUndefined(input) || input === '' ? '' : input.substring(_.indexOf(input, '=') + 1);
  const getTotals = (data) => _.reduce(data, (total, current: any) => total + current?.value || 0, 0);
  const getOthersTotal = (data) => getTotals(_.slice(data, 3));

  const tooltipLookup = _.chain(informationString)
    .split(',')
    .map((e) => {
      if (_.isUndefined(e) || e === '') {
        return;
      }

      e = e.substring(_.indexOf(e, ';') + 1);
      const valueAndSource = _.split(e, ';');
      const value = splitString(valueAndSource[0]);
      const source = _.replace(splitString(valueAndSource[1]), new RegExp('"', 'g'), '');
      // CRAB-17814: rename misleading labels for more clarity
      let label = _.replace(source, /^Request Queue$/, 'Queue');
      label = _.replace(label, /^Calc Engine Queue$/, 'Queue');
      if (label !== 'Seeq Database') {
        // CRAB-23283 'Seeq Database' is more informative than simply 'Database'
        label = _.replace(label, / .*/, '');
      }

      return {
        source,
        label,
        value: parseFloat(value),
        displayValue: getDisplayText(value, displayTime),
      };
    })
    .reject({ source: 'Total' })
    .reject({ source: 'Database Items Read' })
    .reject({ source: 'Database Relationships Read' })
    .reject(_.isUndefined)
    .value();

  // To keep the UI somewhat clean and simple we roll-up everything that starts with the same word into one entry
  const rolledUpByLabelSortedAndColored = _.chain(tooltipLookup)
    .reduce((result, current: any) => {
      const existing = _.find(result, { label: _.get(current, 'label') });
      if (existing) {
        // add up the values:
        existing.value += current.value;
      } else {
        result.push(current);
      }
      return result;
    }, [])
    // assign a color - things with the same name get assigned the same color (across both bars)
    .map((entry, idx) => {
      if (existingColors) {
        // see if we already assigned a color to that label:
        const existing = _.find(colorAssignment, {
          label: _.get(entry, 'label'),
        });

        if (existing) {
          return _.assign(entry, { color: (existing as any).color });
        } else {
          return _.assign(entry, {
            color: COLORS[idx + colorAssignment.length],
          });
        }
      } else {
        return _.assign(entry, { color: COLORS[idx] });
      }
    })
    // sort AFTER assigning a color to ensure that the same things always get the same color assigned!
    .sortBy('value')
    .reverse()
    .value();

  const total = getTotals(rolledUpByLabelSortedAndColored);
  if (!_.isFinite(total)) {
    return { total };
  }

  // take the top 3, combine the rest into "others"
  progressDisplay = _.chain(rolledUpByLabelSortedAndColored).slice(0, 3).sortBy('label').value();

  const timingOthersTotal = getOthersTotal(rolledUpByLabelSortedAndColored);
  progressDisplay.push({
    source: 'Other',
    label: 'Other',
    value: timingOthersTotal,
    color: OTHER_COLOR,
    displayValue: getDisplayText(timingOthersTotal, displayTime),
  });

  progressDisplay = _.map(progressDisplay, (e: any) => {
    const percent = total > 0 ? (e.value / total) * 100 : 0;
    return _.assign({}, e, {
      percent,
      displayValue: percent > 10 ? getDisplayText(e.value, displayTime) : '',
    });
  });

  const newLegend = _.chain(progressDisplay)
    .map((current) => {
      const c: any = _.pick(current, ['label', 'color', 'percent']);
      return !_.isFinite(c.percent) || c.percent < 1 ? null : c;
    })
    .compact()
    .value();

  const displayTotal = getDisplayText(total, displayTime);

  return {
    tooltipLookup,
    rolledUpByLabelSortedAndColored,
    newLegend,
    progressDisplay,
    displayTotal,
    total,
  };
}

export function removeItem(sqTrendActions, sqWorksheetActions, item) {
  sqTrendActions.removeItem(item);

  if (item.itemType === ITEM_TYPES.CAPSULE_SET && sqWorksheetStore.conditionToSeriesGrouping[item.id]) {
    sqWorksheetActions.removeConditionToSeriesGrouping(item);
  } else {
    sqWorksheetActions.removeSeriesFromGrouping(item.id);
  }
}

export function searchAsset(sqWorksheetActions, assetId: string) {
  sqWorksheetActions.setBrowsePanelCollapsed(false);
  sqWorksheetActions.tabsetChangeTab('sidebar', 'search');
  exploreAssetSearchActions('main', assetId);
}

export function setInvestigateItem(sqInvestigateActions, id: string, tool = TREND_TOOLS.OVERVIEW) {
  sqInvestigateActions.setActiveTool(tool);
  sqInvestigateActions.setItem(id);
}

export function someNonConditionItemHasNoData(items) {
  const nonConditionItemsWithDataPresent = _.chain(items)
    .reject(['itemType', ITEM_TYPES.CAPSULE_SET])
    .reject(['itemType', ITEM_TYPES.TABLE])
    .reject(['itemType', ITEM_TYPES.METRIC])
    .filter(['dataStatus', ITEM_DATA_STATUS.PRESENT])
    .value();
  const numberNonConditionItemsWithDataPresent = nonConditionItemsWithDataPresent.length;
  const someNonConditionItemsHaveNoData = _.some(
    nonConditionItemsWithDataPresent,
    _.flow(
      (items) => _.get(items, 'data'),
      (data) => _.map(data, (datum) => (_.isArray(datum) ? _.last(datum) : _.get(datum, 'y'))),
      (data) => _.reject(data, _.isNil),
      (data) => _.get(data, 'length'),
      (dataLength) => _.isEqual(dataLength, 0),
    ),
  );

  return someNonConditionItemsHaveNoData && numberNonConditionItemsWithDataPresent > 0;
}

/**
 * Performs the aggregation for a given property (timingInformation or meterInformation) over all the requests
 */
export function aggregateTimeItems(
  timeItems: { meterInformation: string; timingInformation: string }[],
  infoProperty: keyof typeof timeItems[number],
  parseFn: (string) => number,
) {
  const infoTokens = _.map(timeItems, (timeItem) => _.split(timeItem[infoProperty], ';'));
  const durIndices = _.filter(
    _.map(infoTokens[0], (token, index) => (_.startsWith(token, 'dur=') ? index : -1)),
    (value) => value >= 0,
  );

  return _.chain(infoTokens)
    .reduce(
      (previous, current) =>
        _.map(current, (value, index) =>
          _.includes(durIndices, index) ? previous[index] + parseFn(value.substr(4)) : value,
        ),
      Array(infoTokens[0].length).fill(0),
    )
    .map((value) => (_.isNumber(value) ? `dur=${value}` : value))
    .join(';')
    .value();
}

/**
 * Orders the requests by total "working" duration (duration excluding queueing time)
 */
export function orderTimeItems(timeItems: { meterInformation: string; timingInformation: string }[]) {
  const timeInfo = _.split(timeItems[0].timingInformation, ';');
  const queueIndices = _.chain(timeInfo)
    .map((token, index) => (_.includes(token, 'Queue') ? index - 1 : -1))
    .filter((value) => value >= 0)
    .value();
  const totalIndex = timeInfo.length - 2;
  const meterInfo = _.split(timeItems[0].meterInformation, ';');
  const durIndices = _.chain(meterInfo)
    .map((token, index) => (_.startsWith(token, 'dur=') ? index : -1))
    .filter((value) => value >= 0)
    .value();

  return _.chain(timeItems)
    .map((timeItem) => {
      const timeSplit = _.split(timeItem.timingInformation, ';');
      const time =
        parseFloat(timeSplit[totalIndex].substr(4)) -
        _.sum(_.map(queueIndices, (index) => parseFloat(timeSplit[index].substr(4))));
      const meterSplit = _.split(timeItem.meterInformation, ';');
      const samples = _.sum(_.map(durIndices, (index) => parseInt(meterSplit[index].substr(4), 10)));
      return [timeItem, Number(_.round(time / 1000, 2)), samples];
    })
    .orderBy('1', 'desc')
    .value();
}
