import React, { useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import _ from 'lodash';
import { getAllItems } from '@/hybrid/trend/trendDataHelper.utilities';
import { useProperties } from '@/hybrid/tools/itemProperties/hooks/useProperties';
import classNames from 'classnames';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { AdvancedParameters } from '@/hybrid/core/AdvancedParameters.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { EDIT_MODE } from '@/main/app.constants';
import { ItemSelect } from '@/hybrid/tools/itemProperties/ItemSelect';
import { ItemDescription } from '@/hybrid/tools/itemProperties/ItemDescription';
import { ItemPropertyList } from '@/hybrid/tools/itemProperties/ItemPropertyList';
import { ItemUsages } from '@/hybrid/tools/itemProperties/ItemUsages';
import { ItemScope } from '@/hybrid/tools/itemProperties/ItemScope';
import { ButtonWithPopoverAttached } from '@/hybrid/core/ButtonWithPopoverAttached.molecule';
import { AclStatus } from '@/hybrid/accessControl/AclStatus.molecule';
import { ItemPropertiesService } from '@/hybrid/tools/itemProperties/propertiesPanel.service';
import { handleAnchorClick } from '@/hybrid/tools/itemProperties/propertiesPanel.utilities';
import { ScopeModal } from '@/hybrid/tools/itemProperties/ScopeModal';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { doTrack } from '@/track/track.service';
import { isViewOnlyWorkbookMode } from '@/hybrid/utilities/utilities';
import { sqItemPropertiesStore } from '@/core/core.stores';
import { defaultNumberFormat, defaultStringFormat } from '@/services/systemConfiguration.utilities';

const propertiesPanelBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqItemProperties: injected<ItemPropertiesService>(),
});

export const PropertiesPanel: SeeqComponent<typeof propertiesPanelBindings> = () => {
  const { sqInvestigateActions, sqItemProperties } = useInjectedBindings(propertiesPanelBindings);

  const { t } = useTranslation();

  const [isScopeModalOpen, setScopeModalOpen] = useState(false);

  const {
    setArchived,
    canWrite,
    setProperty,
    deleteProperty,
    setCacheEnabled,
    clearCache,
    updatePropertyValue,
    updateDescription,
    loadProperties,
    tProperties,
  } = useProperties();

  const {
    item,
    properties,
    propertiesAdvanced,
    isUserCreatedType,
    availableOutsideAnalysis,
    scopeMessage,
    canShowUsages,
    originalDesc,
    isMaxInterpolationEditable,
    numberFormatOverridden,
    isNumberFormatEditable,
    stringFormatOverridden,
    isStringFormatEditable,
    cached,
    isCacheSupported,
    cacheChangeInProgress,
    clearCacheTrackingInfo,
    canManage,
  } = tProperties;

  const DUPLICABLE_NON_FORMULA_TOOLS = [TREND_TOOLS.THRESHOLD_METRIC];
  const isItemPresent = !!_.get(item, 'id');

  const shouldAllowSelectionChange =
    isViewOnlyWorkbookMode() ||
    _.some(getAllItems({}), {
      id: item?.id,
    });

  /**
   * Duplicates the item which is defined by item.
   *
   * @param {Boolean} toFormula - True if the duplication should be forced to a Formula Power Search Pane
   */
  const duplicateItem = (toFormula: boolean) => {
    const editMode = toFormula ? EDIT_MODE.COPY_TO_FORMULA : EDIT_MODE.COPY;
    sqInvestigateActions.loadToolForEdit(item.id, editMode);
  };

  /**
   * Finds whether an item can be duplicated.
   *
   * @return {Boolean} True if the item is calculated
   */
  const shouldAllowDuplicateItem = () =>
    _.some(_.filter(properties, { name: SeeqNames.Properties.Formula })) ||
    _.indexOf(DUPLICABLE_NON_FORMULA_TOOLS, sqItemProperties.getToolType(propertiesAdvanced)) > -1;

  /**
   * Finds whether an item can be duplicated to a Formula Power Search Pane.
   *
   * @return {Boolean} True if the item is calculated and would not already open as a Formula
   */
  function shouldAllowDuplicateItemToFormula() {
    if (!shouldAllowDuplicateItem()) {
      // Can't duplicate if it's not calculated
      return false;
    }

    const toolType = sqItemProperties.getToolType(propertiesAdvanced);

    return !(
      _.isUndefined(toolType) ||
      toolType === TREND_TOOLS.FORMULA ||
      toolType === TREND_TOOLS.AGGREGATION_BINS_TABLE ||
      _.indexOf(DUPLICABLE_NON_FORMULA_TOOLS, toolType) > -1
    );
  }

  const disabledClass = {
    'disabledLook disabledBehavior': cacheChangeInProgress || !canWrite(),
  };
  const isDuplicateItemToFormulaAllowed = shouldAllowDuplicateItemToFormula();

  return (
    <div className="card" data-testid="properties">
      {/* <!-- ensure focus starts the top of the form --> */}
      <div tabIndex={-1} hidden={true} data-testid="getFocus" />

      <div className="card-body">
        <h4 data-testid="header" className="flexColumnContainer">
          <Icon icon="fa-info-circle" large={true} extraClassNames="pr10" />
          <span className="flexFill">{t('PROPERTIES.HEADER')}</span>
        </h4>

        {shouldAllowSelectionChange && (
          <ItemSelect
            item={item}
            isUserCreatedType={isUserCreatedType}
            setItem={sqItemProperties.setItem}
            setArchived={setArchived}
          />
        )}

        {!shouldAllowSelectionChange && (
          <div className="flexColumnContainer mb10">
            <label className="pr5 mb0 text-nowrap">{t('PROPERTIES.ITEM_NAME')}</label>
            <div className="flexFill breakLongWords">{item.name}</div>
          </div>
        )}

        <div className="striped pb5 pl3">{properties && <AclStatus item={item} />}</div>

        {properties && isUserCreatedType && (
          <ItemScope
            canManage={canManage}
            availableOutsideAnalysis={availableOutsideAnalysis}
            scopeMessage={scopeMessage}
            openScopeModal={() => setScopeModalOpen(true)}
          />
        )}

        {isScopeModalOpen && (
          <ScopeModal itemId={item.id} closeModal={() => setScopeModalOpen(false)} loadProperties={loadProperties} />
        )}

        {properties && canShowUsages && (
          <ItemUsages showUsagesAndTrack={() => sqItemProperties.showUsagesAndTrack(item)} />
        )}

        <ItemDescription canWrite={canWrite} originalDesc={originalDesc} updateDescription={updateDescription} />

        <ItemPropertyList
          item={item}
          properties={properties}
          setProperty={setProperty}
          deleteProperty={deleteProperty}
          isMaxInterpolationEditable={isMaxInterpolationEditable}
          numberFormatOverridden={numberFormatOverridden}
          isNumberFormatEditable={isNumberFormatEditable}
          stringFormatOverridden={stringFormatOverridden}
          isStringFormatEditable={isStringFormatEditable}
          updatePropertyValue={updatePropertyValue}
          getValidatedNumber={sqItemProperties.getValidatedNumber}
          getValidatedString={sqItemProperties.getValidatedString}
          defaultNumberFormat={defaultNumberFormat()}
          defaultStringFormat={defaultStringFormat()}
        />

        {isItemPresent && propertiesAdvanced?.length > 0 && (
          <div data-testid="advancedPanel" className="advancedPanelNoMargin toolOptions mb10">
            <AdvancedParameters
              toolName={TREND_TOOLS.PROPERTIES}
              toolId={TREND_TOOLS.PROPERTIES}
              toolStore={sqItemPropertiesStore}>
              <div className="flexColumnContainer mb10" data-testid="advancedPanelContent">
                <dl className="dl-striped dl-horizontal-indented width-maximum mb0">
                  {_.map(propertiesAdvanced, (property, index) => (
                    <div key={property.name} className="advancedProperty">
                      <dt
                        className={classNames({
                          striped: (+index + 1) % 2 === 1,
                        })}>
                        {property.name}
                      </dt>
                      <dd>
                        <div className="aggressiveWordBreak max-height-200 overflowAuto">
                          <span className="width-maximum">{property.value}</span>
                        </div>
                      </dd>
                    </div>
                  ))}
                  <div>
                    {isCacheSupported && (
                      <dt>
                        <span>{t('CACHE')}</span>
                        <span className="pl5 pr5">
                          {cached && (
                            <a
                              className={classNames('btn btn-xs force-link-color', disabledClass)}
                              onClick={handleAnchorClick(() => setCacheEnabled(false))}>
                              <Icon icon="fa-toggle-on" large={true} /> &nbsp;
                              <span>{t('CONFIGURATION.ON')}</span>
                            </a>
                          )}
                          {!cached && (
                            <a
                              className={classNames('btn btn-xs force-link-color', disabledClass)}
                              onClick={handleAnchorClick(() => setCacheEnabled(true))}>
                              <Icon icon="fa-toggle-off" large={true} /> &nbsp;
                              <span>{t('CONFIGURATION.OFF')}</span>
                            </a>
                          )}
                        </span>
                        <HoverTooltip text="CLEAR_CACHED_VALUES_TOOLTIP">
                          <TextButton
                            extraClassNames={classNames('sq-btn-xs m4 ml6', disabledClass)}
                            label="CLEAR_CACHED_VALUES"
                            onClick={() => {
                              doTrack('Item', 'Cache Clear', clearCacheTrackingInfo);
                              clearCache();
                            }}
                          />
                        </HoverTooltip>
                      </dt>
                    )}
                  </div>
                </dl>
              </div>
            </AdvancedParameters>
          </div>
        )}

        {/* "Action" buttons (Trash/Restore, Duplicate, Duplicate to Formula, and Cancel) */}
        <div className="flexColumnContainer flexNoGrowNoShrink 5">
          {isUserCreatedType && canWrite() && (
            <div className="mt5 mr5">
              {!item.isArchived && (
                <TextButton
                  variant="danger"
                  label=""
                  extraClassNames="height-30"
                  tooltip="TRASH.MOVE_TO_TRASH"
                  icon="fa-trash fa-lg"
                  iconStyle="white"
                  id="specItemTrashBtn"
                  onClick={() => setArchived(true)}
                />
              )}
              {item.isArchived && (
                <TextButton
                  variant="danger"
                  label="RESTORE"
                  extraClassNames="height-30"
                  tooltip="TRASH.MOVE_TO_TRASH"
                  icon="fc-restore fa-lg"
                  iconStyle="white"
                  id="specItemRestoreBtn"
                  onClick={() => setArchived(false)}
                />
              )}
            </div>
          )}

          <div className="flexFill text-center mt5">
            <TextButton label="CLOSE" onClick={sqInvestigateActions.close} testId="closeButton" />
            {shouldAllowDuplicateItem() && !isViewOnlyWorkbookMode() && (
              <ButtonWithPopoverAttached
                id="duplicate"
                label="DUPLICATE"
                testId="duplicate"
                onClick={() => duplicateItem(false)}
                showPopover={isDuplicateItemToFormulaAllowed}
                popoverContent={
                  <li>
                    <a
                      className="cursorPointer pt5 pb5 pl20 pr20"
                      onClick={handleAnchorClick(() => duplicateItem(true))}>
                      <span data-testid="duplicateToFormulaButton">{t('DUPLICATE_TO_FORMULA')}</span>
                    </a>
                  </li>
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
