// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { ReportConfigContentInfo } from '@/hybrid/reportConfig/components/meta/components/ReportConfigContentInfo.molecule';
import { ReportTimeZone } from '@/hybrid/reportConfig/components/meta/components/ReportTimeZone.atom';
import { ReportConfigMetaRow } from '@/hybrid/reportConfig/components/meta/components/ReportConfigMetaRow.atom';
import { ReportEditingState } from '@/reportEditor/report.store';
import { formatTime } from '@/hybrid/datetime/dateTime.utilities';
import { ReportActions } from '@/reportEditor/report.actions';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import classNames from 'classnames';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TimezoneModal } from '@/hybrid/core/TimezoneModal.molecule';
import { useIntervalWhen } from 'rooks';
import { sqReportStore, sqWorksheetStore } from '@/core/core.stores';
import { BulkEditMode } from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/reportContent.constants';

/**
 * Returns the class for the editing state icon based on the current editing state of the report.
 */
function editingIconClass(editingState: ReportEditingState) {
  const classes = {
    [ReportEditingState.Saving]: 'fa fa-spinner fa-spin text-muted',
    [ReportEditingState.Saved]: 'fa fc fc-circle-check text-muted',
    [ReportEditingState.Stale]: 'fa fc fc-circle_warning text-danger',
    [ReportEditingState.Offline]: 'fa fc fc-circle_warning text-danger',
  };
  return classes[editingState];
}

/**
 * Returns the text describing the current editing state of the report.
 */
function editingStateText(editingState: ReportEditingState) {
  const editStateText = {
    [ReportEditingState.Saving]: 'REPORT.CONFIG.EDIT_SAVING',
    [ReportEditingState.Saved]: 'REPORT.CONFIG.EDIT_SAVED',
    [ReportEditingState.Stale]: 'REPORT.CONFIG.EDIT_STALE',
    [ReportEditingState.Offline]: 'REPORT.CONFIG.EDIT_OFFLINE',
  };
  return editStateText[editingState];
}

function editingStateTextClass(editingState: ReportEditingState) {
  return _.includes([ReportEditingState.Offline, ReportEditingState.Stale], editingState) ? 'text-bolder' : '';
}

/**
 * Updates information on the status of each piece of Seeq content in the document
 */
function getContentProgress(status) {
  const totalContent = _.reduce(status, (result, value) => result + value, 0);
  return {
    contentProgress: [
      {
        key: 'LOADED',
        count: status.loaded,
        percent: (100 * status.loaded) / totalContent,
        class: 'success',
      },
      {
        key: 'IN_PROGRESS',
        count: status.inProgress,
        percent: (100 * status.inProgress) / totalContent,
        class: 'info',
      },
      {
        key: 'FAILED',
        count: status.failed,
        percent: (100 * status.failed) / totalContent,
        class: 'danger',
      },
    ],
    contentTotal: totalContent,
    contentLoaded: status.loaded,
    contentInError: status.failed,
    isContentLoading: !!status.inProgress,
  };
}

/**
 * Returns true if date ranges should not be edited because we are previewing a backup or already updating a date
 * range
 */
function areDateRangesReadOnly(sqReportStore): boolean {
  return sqReportStore.backupPreview || sqReportStore.dateRangeUpdating;
}

/**
 * Triggered when the timezone is clicked.
 */
function onTzClick(sqReportStore, sqReportActions, setShowTimezoneModal: (show: boolean) => void) {
  if (areDateRangesReadOnly(sqReportStore)) return;

  if (sqReportStore.hasReportSchedule && sqReportStore.reportSchedule?.background && sqReportStore.hasAutoDateRanges) {
    // Force configuration of timezone via Configure Autoupdate Modal for reports that have a background schedule
    sqReportActions.setShowConfigureAutoUpdateModal(true);
  } else {
    sqReportActions.setShowConfigureAutoUpdateModal(false);
    setShowTimezoneModal(true);
  }
}

/**
 * Updates any content to match the time zone information selected on this report
 */
function updateContentWithTimeZone(sqReportActions, timezone?) {
  sqReportActions.updateTimezone(timezone);
}

// create the container for the reportConfigMeta
const reportConfigMetaBindings = bindingsDefinition({
  isCKEditor: prop.optional<boolean>(),
  sqReportContent: injected<ReportContentService>(),
  sqReportActions: injected<ReportActions>(),
});

export const ReportConfigMeta: SeeqComponent<typeof reportConfigMetaBindings> = (props) => {
  const { sqReportContent, sqReportActions } = useInjectedBindings(reportConfigMetaBindings);
  const { isCKEditor = false } = props;

  // force the props to be updated so we can get the new name
  const timezoneFixed = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezoneFixed);
  const timezone = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone);
  const timezoneName = timezone?.name;
  const [showTimezoneModal, setShowTimezoneModal] = useState(false);

  const [contentMayBeLoading, setContentMayBeLoading] = useState(true);
  const [contentProgressInfo, setContentProgressInfo] = useState(
    getContentProgress(sqReportContent.getAllContentStatus()),
  );
  // Must poll because the DOM is the source of truth for when the content is done loading
  useIntervalWhen(
    () => {
      const progressInfo = getContentProgress(sqReportContent.getAllContentStatus());
      setContentProgressInfo(progressInfo);
      if (!progressInfo.isContentLoading) {
        setContentMayBeLoading(false);
      }
    },
    100,
    contentMayBeLoading,
  );

  const { contentNotArchived } = useFlux(sqReportStore);
  useEffect(() => {
    setContentMayBeLoading(true);
  }, [contentNotArchived]);

  const initialLoad = useRef(false);
  useEffect(() => {
    if (initialLoad.current) {
      updateContentWithTimeZone(sqReportActions, timezoneFixed ? timezone : undefined);
    } else {
      initialLoad.current = true;
    }
  }, [timezoneFixed, timezone]);

  const editingState: ReportEditingState = sqReportStore.editingState;
  const { name: createdByName } = sqReportStore.createdBy || { name: '' };
  const createdAt: string = formatTime(sqReportStore.createdAt, sqWorksheetStore.timezone);
  const updatedAt: string = formatTime(sqReportStore.updatedAt, sqWorksheetStore.timezone);
  const onTimezoneClick = onTzClick.bind(null, sqReportStore, sqReportActions, setShowTimezoneModal);

  const { t } = useTranslation();

  return (
    <div className="flexRowContainer mb15">
      <ReportConfigMetaRow label={t('REPORT.CONFIG.CREATOR')} value={createdByName} />
      <ReportConfigMetaRow label={t('REPORT.CONFIG.CREATED')} value={createdAt} />
      <ReportConfigMetaRow label={t('REPORT.CONFIG.UPDATED')} value={updatedAt} />
      <ReportConfigMetaRow
        label={t('REPORT.CONFIG.EDITING_STATE')}
        extraValueClasses={classNames('specSaveStatus', editingStateTextClass(editingState))}>
        <Icon icon={editingIconClass(editingState)} extraClassNames="pr5" />
        {t(editingStateText(editingState))}
      </ReportConfigMetaRow>

      <ReportConfigMetaRow label={t('REPORT.CONFIG.CONTENT')} extraValueClasses="flexFill">
        <ReportConfigContentInfo
          isCKEditor={isCKEditor}
          showBulkEditModal={() => {
            sqReportActions.setBulkEditDisplayMode(BulkEditMode.ADVANCED_ONLY);
          }}
          refreshErrorContent={() => sqReportContent.refreshAllContent(true)}
          contentTotal={contentProgressInfo.contentTotal}
          contentInError={contentProgressInfo.contentInError}
          contentProgress={contentProgressInfo.contentProgress}
          isContentLoading={contentProgressInfo.isContentLoading}
        />
      </ReportConfigMetaRow>

      <ReportConfigMetaRow label={t('REPORT.CONFIG.TIME_ZONE')}>
        <ReportTimeZone
          onTzClick={onTimezoneClick}
          isDisabled={areDateRangesReadOnly(sqReportStore)}
          timezoneFixed={timezoneFixed}
          timezoneName={timezoneName}
        />
      </ReportConfigMetaRow>

      {showTimezoneModal && (
        <TimezoneModal
          onChange={sqReportContent.forceRefreshAllContent}
          close={() => setShowTimezoneModal(false)}
          userFallback={false}
        />
      )}
    </div>
  );
};
