// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { DISPLAY_MODE, DURATION_TIME_UNITS_ALL } from '@/main/app.constants';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { ThresholdMetricStore } from './thresholdMetric.store';
import { ProcessTypeEnum } from '@/sdk/model/ThresholdMetricOutputV1';
import { PriorityV1, ThresholdMetricInputV1 } from '@/sdk';
import {
  broadcastSimpleMetricCreated,
  setAggregationOperator,
  setBoundingConditionMaximumDuration,
  setCustomThresholdColor,
  setDuration,
  setPeriod,
  setProcessType,
  setThreshold as setThresholdAction,
} from '@/hybrid/tools/thresholdMetric/thresholdMetric.actions';
import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { ToolRunnerService } from '@/services/toolRunner.service';

import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { findChildrenIn } from '@/hybrid/trend/trendDataHelper.utilities';
import { ErrorTypeEnum } from '@/sdk/model/FormulaErrorOutputV1';
import { ITEM_DATA_STATUS, ITEM_TYPES, TREND_STORES } from '@/trendData/trendData.constants';
import { sqInvestigateStore, sqWorksheetStore } from '@/core/core.stores';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { doTrack } from '@/track/track.service';
import { FrontendDuration } from '@/services/systemConfiguration.constants';
import { priorityColors as getPriorityColors } from '@/services/systemConfiguration.utilities';
import { isItemRedacted } from '@/hybrid/utilities/redaction.utilities';

interface CustomPriority extends PriorityV1 {
  inUse?: boolean;
}

const thresholdMetricBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  sqThresholdMetricStore: injected<ThresholdMetricStore>(),
  sqCalculationRunner: injected<CalculationRunnerService>(),
  sqToolRunner: injected<ToolRunnerService>(),
});

export const ThresholdMetric: SeeqComponent<typeof thresholdMetricBindings> = () => {
  const { sqInvestigateActions, sqWorksheetActions, sqThresholdMetricStore, sqCalculationRunner, sqToolRunner } =
    useInjectedBindings(thresholdMetricBindings);

  const { view } = useFlux(sqWorksheetStore);

  const {
    id,
    name,
    duration,
    processType,
    measuredItem,
    period,
    originalParameters,
    aggregationOperator,
    boundingCondition,
    boundingConditionMaximumDuration,
    thresholds,
    neutralColor,
    configParams,
  } = useFlux(sqThresholdMetricStore);

  const { displayMode, item } = useFlux(sqInvestigateStore);

  const [priorities, setPriorities] = useState(getPriorityColors() as CustomPriority[]);
  const [colors, setColors] = useState([]);
  const [color, setColor] = useState('');
  const [allThresholds, setAllThresholds] = useState(thresholds);
  const [itemWithError, setItemWithError] = useState(null);

  const isScorecardView = view.key === WORKSHEET_VIEW.TABLE;
  const setScorecardView = () => sqWorksheetActions.setView(WORKSHEET_VIEW.TABLE);

  useEffect(() => {
    const priorityColors = getPriorityColors() as CustomPriority[];
    _.forEach(priorityColors, (priority) => {
      priority.inUse = _.has(thresholds, priority.level);
    });

    setPriorities(priorityColors);
    setColors(_.chain(priorityColors).map('color').uniq().value());
    setAllThresholds(getAllThresholds());
  }, [thresholds]);

  useEffect(() => {
    const itemWithError = _.chain([item])
      .concat(findChildrenIn(TREND_STORES, item.id))
      .find((currentItem) => currentItem.dataStatus === ITEM_DATA_STATUS.FAILURE)
      .thru((currentItem) => (_.isEmpty(currentItem) ? item : currentItem))
      .value();

    setItemWithError(itemWithError);
  }, [item]);

  const execute = () => {
    const definition: ThresholdMetricInputV1 = {
      name,
      measuredItem: measuredItem.id,
      aggregationFunction: _.isNil(aggregationOperator.key)
        ? undefined
        : sqCalculationRunner.getStatisticFragment(aggregationOperator),
      thresholds: _.map(thresholds, (v, k) => `${k}${v.color ?? ''}=${v.threshold}`),
      neutralColor,
    };

    const isContinuous = processType === ProcessTypeEnum.Continuous;
    const isBatch = processType === ProcessTypeEnum.Condition;
    const isSimple = processType === ProcessTypeEnum.Simple;

    if (isContinuous) {
      definition.duration = `${duration.value}${duration.units}`;
      definition.period = `${period.value}${period.units}`;
    } else if (isBatch) {
      definition.boundingCondition = boundingCondition.id;
      if (isBoundingConditionMaximumDurationRequired && !_.isEmpty(boundingConditionMaximumDuration)) {
        const { value, units } = boundingConditionMaximumDuration;
        definition.boundingConditionMaximumDuration = `${value}${units}`;
      }
    }

    return sqToolRunner
      .panelExecuteThresholdMetric(definition, configParams, id, color)
      .then((newId) => {
        doTrack('Workbench_Tool', 'Threshold Metric', 'completed');
        if (!id && !!newId && isSimple) {
          broadcastSimpleMetricCreated(newId, definition);
        }
      })
      .catch(() => doTrack('Workbench_Tool', 'Threshold Metric', 'error'));
  };

  const close = sqInvestigateActions.close;
  const setMeasuredItem = (item) =>
    sqInvestigateActions.setParameterItem(TREND_TOOLS.THRESHOLD_METRIC, 'measuredItem', item);

  const getPriority = (level) => _.find(priorities, { level }) || {};

  /**
   * Determines if the maximum capsule duration input field should be displayed for the specified item. It is only
   * required if item is an unbounded condition.
   *
   * @param [extraValidation] - Additional validation to be performed
   * @returns True if the duration should be shown, false otherwise
   */
  const isMaximumDurationRequiredForItem = (extraValidation = _.constant(true)): boolean => {
    return (
      extraValidation() &&
      !!boundingCondition &&
      boundingCondition?.itemType === ITEM_TYPES.CAPSULE_SET &&
      !boundingCondition?.conditionMetadata?.maximumDuration
    );
  };

  const isBoundingConditionMaximumDurationRequired = isMaximumDurationRequiredForItem(
    () => processType === ProcessTypeEnum.Condition,
  );

  /**
   * Set a threshold value.
   *
   * @param threshold - The boundary of the threshold. A scalar formula or signal/condition item ID.
   * An item object may also be supplied and the ID property will be used from the item object.
   * @param level - The priority level
   */
  const setThreshold = (thresholdData: string | object, level: number) => {
    const threshold: string = _.get(thresholdData, 'id') || thresholdData;
    setThresholdAction(level, threshold);
  };

  const getAllThresholds = () => {
    const allThresholds = {
      ...thresholds,
      // add neutral so it always displays when other thresholds are displayed
      0: { color: neutralColor },
    };

    return _.chain(allThresholds)
      .keys()
      .map(_.toNumber)
      .sortBy()
      .reverse()
      .map((level, index) => ({
        // use merge to ensure undefined color key in thresholds don't overwrite priority color
        ..._.merge({}, getPriority(level), allThresholds[level]),
        index, // used to compute vertical offset of threshold control
        // Create an ID based on the metric and the level that ng-repeat can track by. This prevents ng-repeat from
        // reusing the sq-select-item subcontrol when the metric tool is open and switching between metrics.
        id: `${id}_${level}`,
      }))
      .value();
  };

  const removeThreshold = (level) => {
    setThresholdAction(level, undefined);
  };

  const showMaxDurationItem = () => {
    return itemWithError?.errorType === ErrorTypeEnum.MAXDURATIONPROHIBITED;
  };

  const getThresholdDisplay = () => {
    const thresholdColorsFormElements: FormElement = {
      component: 'FormGroup',
      name: 'thresholdColorsFormGroup',
      extraClassNames: 'flexNoGrow',
      components: [],
    };
    const thresholdFormElements: FormElement = {
      component: 'FormGroup',
      name: 'thresholdInputsFormGroup',
      components: [],
    };

    _.forEach(allThresholds, (threshold) => {
      if (!_.isNumber(threshold.level)) return;

      thresholdColorsFormElements.components.push({
        component: 'ColorPickerFormComponent',
        name: `colorPicker-${threshold.level}`,
        includeIf: allThresholds.length > 1,
        dataTestId: `${threshold.name}ThresholdColorPicker`,
        placement: 'top',
        extraClassNames: 'priorityColor animatedThreshold mb10',
        color: threshold.color,
        value: '',
        colors,
        itemId: threshold.level,
        notifyOnSelect: setCustomThresholdColor,
        tooltip: 'COLOR_PICKER.PICKER',
        validation: () => false,
      });

      thresholdFormElements.components.push({
        component: 'FormRow',
        name: `thresholdColorsFormGroup-${threshold.level}`,
        testId: `${threshold.name}ThresholdRow`,
        key: `${threshold.level}ThresholdFullRowKey`,
        includeIf: threshold.level !== 0,
        extraClassNames: 'mb10',
        components: [
          {
            component: 'IconFormComponent',
            name: `lineDelimiter-${threshold.level}`,
            testId: `lineDelimiter-${threshold.level}`,
            value: '',
            iconClasses: 'fa-minus mt10 ml1 mr1',
          },
          {
            component: 'ItemSelectFormComponent',
            name: `threshold-${threshold.level}`,
            testId: `threshold-${threshold.level}`,
            extraClassNames: 'thresholdEntry',
            thresholdLevel: threshold.level,
            textEntryAllowed: true,
            disableAutoSelect: true,
            allowClear: false,
            value: threshold.threshold,
            itemTypes: [ITEM_TYPES.CAPSULE_SET, ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR],
            additionalItems: originalParameters,
            onChange: (item) => setThreshold(item, threshold.level),
          },
          {
            component: 'IconFormComponent',
            name: `removeThreshold-${threshold.level}`,
            testId: `removeThreshold-${threshold.level}`,
            value: '',
            iconClasses: 'fa-close btn-transparent removeButton mt5',
            tooltip: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.REMOVE_THRESHOLD',
            onIconClick: (event) => {
              removeThreshold(threshold.level);
              event.stopPropagation();
            },
          },
        ],
      });
    });

    return [thresholdColorsFormElements, thresholdFormElements];
  };

  const includeMaxCapsuleDuration = isMaximumDurationRequiredForItem(() => processType === ProcessTypeEnum.Condition);

  const formDataSetup: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'thresholdMetricSearchTitle',
      value: name,
      id,
      onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.THRESHOLD_METRIC, name),
      onColorChange: setColor,
      searchIconClass: 'fc-metric',
      defaultName: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.HEADER',
    },
    {
      component: 'RadioButtonGroupFormComponent',
      name: 'processType',
      displayNumber: true,
      value: processType,
      onChange: _.noop,
      id: 'processTypeRadios',
      label: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.SELECT_PROCESS_TYPE',
      tooltip: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.LEARN_MORE',
      fieldExtraClassNames: 'flexRowContainer flexAlignStart',
      checkboxExtraClassNames: 'flexRowContainer flexAlignStart',
      options: [
        {
          id: 'radioOptionSimple',
          label: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.SIMPLE',
          description: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.DESCRIPTION_SIMPLE',
          checked: processType === ProcessTypeEnum.Simple,
          onToggle: () => setProcessType(ProcessTypeEnum.Simple),
        },
        {
          id: 'radioOptionBatch',
          label: 'ITEM_TYPES.CONDITION',
          description: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.DESCRIPTION_CONDITION',
          checked: processType === ProcessTypeEnum.Condition,
          onToggle: () => setProcessType(ProcessTypeEnum.Condition),
        },
        {
          id: 'radioOptionContinuous',
          label: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.CONTINUOUS',
          description: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.DESCRIPTION_CONTINUOUS',
          checked: processType === ProcessTypeEnum.Continuous,
          onToggle: () => setProcessType(ProcessTypeEnum.Continuous),
        },
      ],
    },
    {
      component: 'ItemSelectFormComponent',
      name: 'measuredItem',
      testId: 'measuredItem',
      label: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.SELECT_MEASURED_ITEM',
      includeMetadata: true,
      value: measuredItem?.id,
      itemTypes: [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET],
      additionalItems: originalParameters,
      validation: (value: any) => {
        const isItemRedactedInvalid = value ? isItemRedacted(value?.id ? value : { id: value }) : true;
        return isItemRedactedInvalid || (_.isEmpty(value) && value?.id !== '');
      },
      onChange: setMeasuredItem,
      displayNumber: true,
    },
    {
      component: 'StatisticSelectorFormComponent',
      name: 'aggregationOperator',
      testId: 'aggregationOperator',
      displayNumber: true,
      label: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.SELECT_STATISTIC',
      extraClassNames: 'flexFillOverflow',
      isRequired: measuredItem?.itemType === ITEM_TYPES.CAPSULE_SET,
      outputType: ['sample'],
      onChange: setAggregationOperator,
      value: aggregationOperator,
      item: measuredItem,
      onValidate: (isValid) => {},
      validation: () =>
        measuredItem?.itemType === ITEM_TYPES.CAPSULE_SET
          ? !aggregationOperator || _.isEmpty(aggregationOperator.key)
          : false,
    },
    {
      component: 'FormGroup',
      name: 'boundingConditionGroup',
      displayNumber: true,
      includeIf: processType === ProcessTypeEnum.Condition,
      components: [
        {
          component: 'LabelFormComponent',
          name: 'boundingConditionLabel',
          value: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.SELECT_BOUNDING_CONDITION',
        },
        {
          component: 'FormGroup',
          name: 'boundingInputGroup',
          showBracket: true,
          components: [
            {
              component: 'ItemSelectFormComponent',
              name: 'boundingCondition',
              testId: 'boundingCondition',
              extraClassNames: includeMaxCapsuleDuration ? undefined : 'mb-0',
              includeMetadata: true,
              value: boundingCondition?.id,
              itemTypes: [ITEM_TYPES.CAPSULE_SET],
              additionalItems: originalParameters,
              validation: (value: any) => {
                const isItemRedactedInvalid = value ? isItemRedacted(value?.id ? value : { id: value }) : true;
                return isItemRedactedInvalid || (_.isEmpty(value) && value?.id !== '');
              },
              onChange: (item) =>
                sqInvestigateActions.setParameterItem(TREND_TOOLS.THRESHOLD_METRIC, 'boundingCondition', item),
            },
            {
              component: 'MaxCapsuleDurationFormComponent',
              name: 'boundingConditionMaximumDuration',
              value: boundingConditionMaximumDuration,
              testId: 'boundingConditionMaximumDuration',
              tooltip: 'MAXIMUM_CAPSULE_DURATION_TOOLTIP',
              maxDurationRequired: true,
              onChange: ({ value, units }) => {
                setBoundingConditionMaximumDuration(value, units);
              },
              minIsExclusive: true,
              includeIf: includeMaxCapsuleDuration,
              extraClassNames: 'mb-0',
            },
          ],
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'durationAndPeriodFormGroup',
      displayNumber: true,
      includeIf: processType === ProcessTypeEnum.Continuous,
      components: [
        {
          component: 'FormRow',
          name: 'durationFormRow',
          extraClassNames: 'flexSpaceBetween',
          components: [
            {
              component: 'ValueWithUnitsFormComponent',
              extraClassNames: 'flexJustifyEnd',
              name: 'duration',
              testId: 'duration',
              value: duration,
              label: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.DURATION',
              availableUnits: DURATION_TIME_UNITS_ALL,
              min: 1,
              onChange: (value: FrontendDuration) => setDuration(value),
            },
          ],
        },
        {
          component: 'FormRow',
          name: 'periodFormRow',
          extraClassNames: 'flexSpaceBetween mt10',
          components: [
            {
              component: 'ValueWithUnitsFormComponent',
              name: 'period',
              testId: 'period',
              value: period,
              label: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.PERIOD',
              availableUnits: DURATION_TIME_UNITS_ALL,
              min: 1,
              onChange: setPeriod,
            },
          ],
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'thresholdsFromGroup',
      displayNumber: true,
      components: [
        {
          component: 'FormGroup',
          name: 'thresholdButtonAndPopover',
          components: [
            {
              component: 'LabelFormComponent',
              name: 'thresholdLabel',
              value: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.THRESHOLDS',
            },
            {
              component: 'PriorityPickerFormComponent',
              name: 'priorityPicker',
              buttonClasses: 'fa-plus-circle fa-2x min-width-25 cursor-pointer',
              buttonTestId: 'addThresholdButton',
              buttonTooltip: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.ADD_THRESHOLD',
              placement: 'top-start',
              value: priorities,
              onChange: (level) => setThreshold('', level),
            },
          ],
        },
        {
          component: 'FormRow',
          name: 'thresholdDisplayGroup',
          testId: 'thresholdDisplayGroup',
          extraClassNames: 'thresholdTool',
          components: getThresholdDisplay(),
        },
      ],
    },
    {
      component: 'FormGroup',
      name: 'switchToScorecardViewFormGroup',
      testId: 'switchToScorecardViewFormGroup',
      extraClassNames: 'pb15',
      components: [
        {
          component: 'ClickableLinkFormComponent',
          name: 'switchToScorecardView',
          linkTestId: 'switchToScorecardViewLink',
          extraClassNames: 'ml40',
          value: 'INVESTIGATE_TOOLS.THRESHOLD_METRIC.SWITCH_TO_TABLE_VIEW',
          includeIf: !isScorecardView,
          icon: 'fa-table p5',
          linkAction: () => setScorecardView(),
        },
      ],
    },
    {
      component: 'ErrorMessageFormComponent',
      name: 'maxDurationRemoved',
      value: 'CAPSULE_DURATION.NO_LONGER_REQUIRED',
      failForm: false,
      includeIf: showMaxDurationItem(),
    },
  ];

  const referenceSearchBuilder = (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={() => execute()}
      closeFn={() => close()}
      toolId={TREND_TOOLS.THRESHOLD_METRIC}
      submitBtnId="executeThresholdMetric"
    />
  );

  return displayMode === DISPLAY_MODE.NEW ||
    displayMode === DISPLAY_MODE.EDIT ||
    displayMode === DISPLAY_MODE.IN_PROGRESS
    ? referenceSearchBuilder
    : null;
};

export const sqThresholdMetric = angularComponent(thresholdMetricBindings, ThresholdMetric);
