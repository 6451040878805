// @ts-strict-ignore
import { SeriesColumnOptions } from 'highcharts';
import { TREND_COLORS } from '@/trendData/trendData.constants';

const getColumnOptions = (tableData, settings) => {
  const axisUsed = {};
  const axisOptions = [];
  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      style: {
        fontFamily: 'inherit',
      },
    },
    title: {
      text: settings.title,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: settings.legend,
    },
    xAxis: {
      categories: [...tableData[0].slice(1)],
      labels: {
        enabled: settings.categoryLabels || false,
      },
      crosshair: true,
    },
    colors: TREND_COLORS,
    plotOptions: {
      series: {
        stacking: undefined,
        dataLabels: {
          enabled: false,
          rotation: 0,
        },
        events: {
          mouseOver() {
            this.yAxis.update({
              labels: {
                style: {
                  color: '#000000',
                },
              },
              title: {
                style: {
                  color: '#000000',
                },
              },
            });
          },
          mouseOut() {
            this.yAxis.update({
              labels: {
                style: {
                  color: '#CCCCCC',
                },
              },
              title: {
                style: {
                  color: '#CCCCCC',
                },
              },
            });
          },
        },
      },
    },
    series: [],
  };
  if (settings.stacked) {
    options.plotOptions.series.stacking = 'normal';
  }
  if (settings.dataLabels) {
    Object.assign(options.plotOptions.series.dataLabels, { enabled: true });
  }
  tableData.slice(1).forEach((row) => {
    if (!axisUsed[row[1].uom]) {
      axisOptions.push({
        title: {
          text: '',
          style: {
            color: '#CCCCCC',
          },
        },
        labels: {
          format: `{value}${row[1].uom}`,
          style: {
            color: '#CCCCCC',
          },
        },
        opposite: axisOptions.length % 2 === 1,
      });
      axisUsed[row[1].uom] = axisOptions.length;
    }
    const chartSeriesItem: SeriesColumnOptions = {
      name: row[0],
      data: row.slice(1).map((info) => Number(info.value)),
      type: 'column',
      yAxis: axisUsed[row[1].uom] - 1,
      tooltip: {
        valueSuffix: ` ${row[1].uom}`,
      },
    };
    options.yAxis = axisOptions;
    options.series.push(chartSeriesItem);
  });
  return options;
};

export default getColumnOptions;
