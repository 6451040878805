// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ReportActions } from '@/reportEditor/report.actions';
import _ from 'lodash';
import { ReportConfigDateRangeGroup } from '@/hybrid/reportConfig/components/shared/ReportConfigDateRangeGroup.molecule';
import { DateRange } from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';

const reportConfigAutoUpdateDateRangeTableBindings = bindingsDefinition({
  timezone: prop<string>(),
  viewOnly: prop.optional<boolean>(),
  openDateSelector: prop<(dateRange: DateRange, isCapsuleSelect: boolean, checkFixed: boolean) => void>(),
  withDateRangeUpdating: prop<(action: Function) => void>(),
  sqReportActions: injected<ReportActions>(),
  dateRanges: prop<DateRange[]>(),
  areDateRangesReadOnly: prop<boolean>(),
  openBulkEditWithGivenRange: prop<(dateRange: DateRange) => void>(),
});

export const ReportConfigAutoUpdateDateRangeTable: SeeqComponent<
  typeof reportConfigAutoUpdateDateRangeTableBindings
> = (props) => {
  const { sqReportActions } = useInjectedBindings(reportConfigAutoUpdateDateRangeTableBindings);
  const {
    timezone,
    openDateSelector,
    withDateRangeUpdating,
    viewOnly = false,
    areDateRangesReadOnly,
    dateRanges,
    openBulkEditWithGivenRange,
  } = props;

  const autoUpdateDateRanges = _.chain(dateRanges)
    .filter((dateRange) => dateRange?.auto?.enabled)
    .sortBy('name')
    .value();

  const stepScheduledReportToNow = () => {
    doTrack('Topic', 'Date Range Tool', 'edit - step auto-updating to end');
    withDateRangeUpdating(() => sqReportActions.stepScheduledReportToNow());
  };
  const [tellChildToFireStep, setTellChildToFireStep] = useState(false);

  /**
   * This new step function will operate normally unless we are in viewOnly mode. In this case, we will
   *  1. Activate sandbox mode, and then
   *  2. Set Step-Fire flag to true, which will be passed to ReportConfigDateRangeGroup, which
   *  3. Will cause decoratedStep to fire again, this time firing stepScheduledReportToNow on the new ranges
   */
  const decoratedStep = () => {
    const onAction = () => setTellChildToFireStep(false);
    const onSandbox = () => setTellChildToFireStep(true);
    return sqReportActions.doActionElseActivateSandbox(stepScheduledReportToNow, onAction, onSandbox)();
  };

  const onClickAdd = () => {
    doTrack('Topic', 'Date Range Tool', 'new - auto-update plus icon');
    openDateSelector(undefined, false, false);
  };

  return (
    <div className="flexRowContainer pt5 pb5 report" data-testid="autoUpdateDateRangeTable">
      <ReportConfigDateRangeGroup
        triggerStepFunctionOnLoad={tellChildToFireStep}
        timezone={timezone}
        onClickStep={decoratedStep}
        onClickAdd={onClickAdd}
        dateRanges={autoUpdateDateRanges}
        isAutoUpdate={true}
        viewOnly={viewOnly}
        areDateRangesReadOnly={areDateRangesReadOnly}
        openDateSelector={openDateSelector}
        stepDateRangeToEnd={undefined}
        openBulkEditWithGivenRange={openBulkEditWithGivenRange}
      />
    </div>
  );
};
