// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { DurationActions } from '@/trendData/duration.actions';

import { DateTimeEntry } from '@/hybrid/core/DateTimeEntry.atom';
import { DurationEntry } from '@/hybrid/core/DurationEntry.atom';
import { AutoUpdate } from '@/hybrid/trend/AutoUpdate.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { RangeExport } from '@/trendData/duration.store';
import { Timezone } from '@/datetime/timezone.service';
import { isPresentationWorkbookMode } from '@/hybrid/utilities/utilities';

const displayRangeSelectorBindings = bindingsDefinition({
  sqDurationActions: injected<DurationActions>(),
  formMode: prop.optional<boolean>(),
  autoUpdateDisabled: prop.optional<boolean>(),
  rangeEditingEnabled: prop.optional<boolean>(),
  durationEditingEnabled: prop.optional<boolean>(),
  investigateRangeCopyDisabled: prop.optional<boolean>(),
  investigateRangeCopyNonExistent: prop.optional<boolean>(),
  timeZoneEditingEnabled: prop.optional<boolean>(),
  label: prop.optional<string>(),
  halfRangeButtonDisabled: prop.optional<boolean>(),
  trackCategory: prop.optional<string>(),
  trackAction: prop.optional<string>(),
  inputExtraClassNames: prop.optional<string>(),
  displayRange: prop<RangeExport>(),
  isInTopic: prop.optional<boolean>(),
  timezone: prop<Timezone>(),
});

export const DisplayRangeSelector: SeeqComponent<typeof displayRangeSelectorBindings> = (props) => {
  const { sqDurationActions } = useInjectedBindings(displayRangeSelectorBindings);

  const {
    label,
    formMode,
    autoUpdateDisabled,
    rangeEditingEnabled,
    durationEditingEnabled,
    timeZoneEditingEnabled = true,
    investigateRangeCopyDisabled,
    investigateRangeCopyNonExistent,
    halfRangeButtonDisabled,
    trackCategory,
    trackAction,
    inputExtraClassNames,
    displayRange,
    isInTopic = false,
    timezone,
  } = props;

  const { t } = useTranslation();

  const isPresentationMode = isPresentationWorkbookMode();
  const displayRangeActions = sqDurationActions.displayRange;

  return (
    <div
      className={classNames('flexColumnContainer flexSpaceBetween displayRangeSelector pb2', { flexWrap: isInTopic })}
      data-testid="displayRangeSelector">
      <div className="flexRowContainer">
        {formMode && (
          <label
            className={classNames(
              'font-size-smaller',
              { ml15: !investigateRangeCopyNonExistent },
              { disabled: !rangeEditingEnabled },
            )}>
            {t('START')}
          </label>
        )}
        <div className="flexColumnContainer">
          {!isPresentationMode && !investigateRangeCopyDisabled && (
            <span onClick={sqDurationActions.investigateRange.copyFromDisplayRange} className="copyToInvestigateIcon">
              <Icon
                icon="fc-arrow-down"
                tooltip="RANGE_SELECTOR.COPY_TO_INVESTIGATE"
                tooltipDelay={1500}
                tooltipPlacement="bottom"
                extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline"
              />
            </span>
          )}
          {isPresentationMode && <div className="ml35" />}

          <div
            id="displayRangeStart"
            className={classNames('displayRangeDateTime', {
              ml15: investigateRangeCopyNonExistent ? false : !isPresentationMode && investigateRangeCopyDisabled,
            })}>
            <DateTimeEntry
              extraClassName={classNames('small', {
                readOnly: !rangeEditingEnabled,
              })}
              testId="specDisplayRangeStart"
              date={displayRange.start}
              updateDate={(date) => setTimeout(() => displayRangeActions.updateStart(date))}
              inputExtraClassNames="'textAlignLeft'"
              updateBothDates={displayRangeActions.updateTimes}
              timezone={timezone}
              otherDate={displayRange.end}
              fieldIdentifier="'DISPLAY_RANGE_START'"
              readOnly={!rangeEditingEnabled}
              timeZoneReadOnly={!timeZoneEditingEnabled}
              trackCategory="'RangeSelector'"
              trackAction="'DateTimeEntry'"
              trackInformation="'Start Date set'"
            />
          </div>
        </div>
      </div>

      <div className="flexRowContainer">
        {label && <em>{label}</em>}
        {formMode && (
          <label
            className={classNames('flexSelfCenter font-size-smaller text-center mr20', { mr20: rangeEditingEnabled })}>
            {t('DURATION')}
          </label>
        )}

        <div
          className={classNames('flexColumnContainer', {
            flexCenter: formMode,
          })}
          id="displayRangeNavigation">
          {rangeEditingEnabled && (
            <span
              onClick={displayRangeActions.stepBack}
              data-testid="rangeSelectorStepBackFull"
              className="rangeSelectorStep">
              <Icon
                icon="fc-step-back-full"
                tooltip="RANGE_SELECTOR.STEP_BACK_FULL"
                tooltipDelay={1500}
                tooltipPlacement="bottom"
                extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline"
              />
            </span>
          )}
          {rangeEditingEnabled && !halfRangeButtonDisabled && (
            <span
              onClick={displayRangeActions.stepBackHalf}
              data-testid="rangeSelectorStepBackHalf"
              className="rangeSelectorStep">
              <Icon
                icon="fc-step-back-half"
                tooltip="RANGE_SELECTOR.STEP_BACK_HALF"
                tooltipDelay={1500}
                tooltipPlacement="bottom"
                extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline"
              />
            </span>
          )}

          {(rangeEditingEnabled || durationEditingEnabled) && (
            <DurationEntry
              extraClassNames={classNames({ 'min-width-50': formMode })}
              inputExtraClassNames={inputExtraClassNames}
              startDate={displayRange.start}
              endDate={displayRange.end}
              updateDuration={displayRangeActions.updateDuration}
              updateDates={displayRangeActions.updateTimes}
              timezone={timezone}
              read-only={!rangeEditingEnabled && !durationEditingEnabled}
            />
          )}
          {rangeEditingEnabled && !halfRangeButtonDisabled && (
            <span
              onClick={displayRangeActions.stepForwardHalf}
              data-testid="rangeSelectorStepForwardHalf"
              className="rangeSelectorStep">
              <Icon
                icon="fc-step-fore-half"
                tooltip="RANGE_SELECTOR.STEP_FORE_HALF"
                tooltipDelay={1500}
                tooltipPlacement="bottom"
                extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline"
              />
            </span>
          )}
          {rangeEditingEnabled && (
            <>
              <span
                onClick={displayRangeActions.stepForward}
                data-testid="rangeSelectorStepForward"
                className="rangeSelectorStep">
                <Icon
                  icon="fc-step-fore-full"
                  tooltip="RANGE_SELECTOR.STEP_FORE_FULL"
                  tooltipDelay={1500}
                  tooltipPlacement="bottom"
                  extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline"
                />
              </span>
              <span
                onClick={displayRangeActions.stepToEnd}
                data-testid="rangeSelectorStepToEnd"
                className="rangeSelectorStep">
                <Icon
                  icon="fc-arrow-right-right"
                  tooltip="RANGE_SELECTOR.STEP_TO_END"
                  tooltipDelay={1500}
                  tooltipPlacement="bottom"
                  extraClassNames="displayRangeIcon btn btn-link sq-btn-xs cursorPointer link-no-underline"
                />
              </span>
            </>
          )}
          {!isPresentationMode && !autoUpdateDisabled && sqDurationActions.autoUpdate.allowed() && <AutoUpdate />}
        </div>
      </div>
      <div className="flexRowContainer">
        {formMode && (
          <label
            className={classNames('font-size-smaller mr10', {
              disabled: !rangeEditingEnabled,
            })}>
            {t('END')}
          </label>
        )}
        <div
          className={classNames('flexColumnContainer displayRangeDateTime displayRangeEnd', {
            'min-width-195': !formMode && !isInTopic,
          })}
          id="displayRangeEnd">
          <DateTimeEntry
            testId="specDisplayRangeEnd"
            extraClassName={classNames('small flexFill', {
              'min-width-150': !isInTopic,
              'pl26': isInTopic,
              'text-right': !formMode,
              'mr15': !formMode,
              'readOnly': !rangeEditingEnabled,
            })}
            date={displayRange.end}
            updateDate={displayRangeActions.updateEnd}
            updateBothDates={displayRangeActions.updateTimes}
            timezone={timezone}
            otherDate={displayRange.start}
            fieldIdentifier="'DISPLAY_RANGE_END'"
            readOnly={!rangeEditingEnabled}
            timeZoneReadOnly={!timeZoneEditingEnabled}
            trackCategory={trackCategory}
            trackAction={trackAction}
            trackInformation="'End Date set'"
          />
        </div>
      </div>
    </div>
  );
};
