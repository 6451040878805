import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';

export type ItemScopeProps = {
  availableOutsideAnalysis: boolean;
  scopeMessage: string;
  canManage: boolean;
  openScopeModal: () => void;
};

export const ItemScope: React.FunctionComponent<ItemScopeProps> = ({
  availableOutsideAnalysis,
  scopeMessage,
  canManage,
  openScopeModal,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flexRowContainer mb5" data-testid="itemScope">
      <div className="flexColumnContainer flexFill">
        <label className="mb0">{t('PROPERTIES.SCOPE')}</label>
        <a
          href="https://telemetry.seeq.com/support-link/wiki/spaces/KB/pages/565609205/Integrated+Security#IntegratedSecurity-Scope"
          target="_blank"
          className="ml5">
          <Icon
            icon="fa-question-circle"
            tooltip="SCOPE.TOOLTIP"
            tooltipPlacement="right"
            extraClassNames="text-interactive"
          />
        </a>
      </div>
      <div className="flexColumnContainer mt0 mb0 ml5">
        <span
          className={classNames('specScopeText', {
            'text-italic': availableOutsideAnalysis,
          })}>
          {t(scopeMessage)}
        </span>
        {!availableOutsideAnalysis && canManage && (
          <div
            className="specScopeLink ml5 sq-text-primary link-underline-hover cursorPointer"
            data-testid="scopeChange"
            onClick={openScopeModal}>
            {t('SCOPE.CHANGE')}
          </div>
        )}
      </div>
    </div>
  );
};
