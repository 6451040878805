// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { CELL_TYPES, Table, TableColumn } from '@/hybrid/core/Table.atom';
import { ReportActions } from '@/reportEditor/report.actions';

import _ from 'lodash';
import classNames from 'classnames';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { APP_STATE } from '@/main/app.constants';
import { sqReportStore } from '@/core/core.stores';
import { BulkEditMode } from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/reportContent.constants';

const reportContentBulkEditTableBindings = bindingsDefinition({
  allContent: prop<any[]>(),
  isAdvancedDisplay: prop<boolean>(),
  displayMode: prop<BulkEditMode>(),
  sqReportActions: injected<ReportActions>(),
  $state: injected<ng.ui.IStateService>(),
});

export const ReportContentBulkEditTable: SeeqComponent<typeof reportContentBulkEditTableBindings> = ({
  allContent,
  isAdvancedDisplay,
  displayMode,
}) => {
  const { t } = useTranslation();
  const { sqReportActions, $state } = useInjectedBindings(reportContentBulkEditTableBindings);
  const SMALL_CELL_WIDTH = 90;
  const LARGE_CELL_WIDTH = 140;
  const POSITION_FIELD = 'position';
  const DATE_RANGE_NAME = 'dateRangeName';
  const ASSET_SELECTION_NAME = 'assetSelectionName';

  const selectedContent = useFluxPath(sqReportStore, () => sqReportStore.selectedBulkContent);
  const [selectAll, setSelectAll] = useState(false);
  const [searchParams, setSearchParams] = useState({});
  const [sortOptions, setSortOptions] = useState({
    field: POSITION_FIELD,
    asc: true,
  });

  let filteredAndSortedContent = _.chain(allContent)
    .reject((content) =>
      _.some(
        _.keys(searchParams),
        (field) => !content[field].toString().toLowerCase().includes(searchParams[field].toString().toLowerCase()),
      ),
    )
    .sortBy(sortOptions.field)
    .value();

  if (!sortOptions.asc) {
    filteredAndSortedContent = _.reverse(filteredAndSortedContent);
  }

  const selectAllCallback = () => {
    sqReportActions.setSelectedBulkContent(selectAll ? [] : filteredAndSortedContent);
    setSelectAll(!selectAll);
  };

  const onRowSelectCallback = (content) => {
    sqReportActions.toggleSpecificSelectedContent(content);
  };

  const filterCallback = (option, field) => {
    if (option.value !== '') {
      searchParams[field] = option.value;
    } else {
      delete searchParams[field];
    }
    setSearchParams({ ...searchParams });
  };

  const sortCallback = (field) => {
    setSortOptions({
      field,
      asc: field === sortOptions.field ? !sortOptions.asc : true,
    });
  };

  const renderWorksheetCell = (content) => (
    <div className={classNames('cursorPointer', `min-width-${LARGE_CELL_WIDTH}`)}>
      <a
        target="_blank"
        data-testid="worksheetCell"
        href={$state.href(APP_STATE.WORKSHEET, {
          workbookId: content.workbookId,
          worksheetId: content.worksheetId,
        })}>
        {content.worksheetName}
      </a>
    </div>
  );

  const renderInteractiveStatusCell = (item) => (
    <div className="flexColumnContainer flexCenter">
      {item.canUseReact ? (
        <span>{item.isReact ? t('ADMIN.YES') : t('ADMIN.NO')}</span>
      ) : (
        <span>{t('ADMIN.NOT_APPLICABLE')}</span>
      )}
    </div>
  );

  const allColumns: TableColumn[] = [
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40 },
      isResizable: false,
    },
    {
      accessor: 'workbookName',
      searchProperty: 'workbookName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.WORKBOOK_NAME',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: LARGE_CELL_WIDTH, wordWrap: 'break-word' },
    },
    {
      accessor: 'worksheetName',
      searchProperty: 'worksheetName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.WORKSHEET_NAME',
      sortable: true,
      filterable: true,
      cellRenderFunction: renderWorksheetCell,
    },
    {
      accessor: 'interactiveContentStatus',
      searchProperty: 'interactiveContentStatus',
      header: 'REPORT.CONTENT.INTERACTIVE',
      cellType: CELL_TYPES.YES_NO_NOTAPPLICABLE,
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: LARGE_CELL_WIDTH - 50, wordWrap: 'break-word' },
      cellRenderFunction: renderInteractiveStatusCell,
    },
    {
      accessor: 'viewName',
      searchProperty: 'viewName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.WORKSHEET_TYPE',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: LARGE_CELL_WIDTH - 20, wordWrap: 'break-word' },
    },
    {
      accessor: DATE_RANGE_NAME,
      searchProperty: DATE_RANGE_NAME,
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.DATE_RANGE',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: LARGE_CELL_WIDTH - 20, wordWrap: 'break-word' },
    },
    {
      accessor: POSITION_FIELD,
      searchProperty: POSITION_FIELD,
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.POSITION',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH + 5 },
    },
    {
      accessor: 'workstepStatus',
      searchProperty: 'workstepStatus',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.WORKSTEP_STATUS',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH + 5 },
    },
    {
      accessor: 'scaleName',
      searchProperty: 'scaleName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.SCALE_NAME',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH },
    },
    {
      accessor: 'sizeName',
      searchProperty: 'sizeName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.SIZE_NAME',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH },
    },
    {
      accessor: 'shapeName',
      searchProperty: 'shapeName',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.SHAPE_NAME',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH },
    },
    {
      accessor: 'summaryAsString',
      searchProperty: 'summaryAsString',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.SUMMARY',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: LARGE_CELL_WIDTH },
    },
    {
      accessor: ASSET_SELECTION_NAME,
      searchProperty: ASSET_SELECTION_NAME,
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.ASSET_SELECTION',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: LARGE_CELL_WIDTH - 20, wordWrap: 'break-word' },
    },
    {
      accessor: 'height',
      searchProperty: 'height',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.HEIGHT',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH },
    },
    {
      accessor: 'width',
      searchProperty: 'width',
      header: 'REPORT.CONFIG.BULK_EDIT_TABLE.WIDTH',
      sortable: true,
      filterable: true,
      cellStyle: { minWidth: SMALL_CELL_WIDTH },
    },
  ];

  const typeAccessor = {
    [BulkEditMode.ASSET_SELECTION]: ASSET_SELECTION_NAME,
    [BulkEditMode.DATE_RANGE]: DATE_RANGE_NAME,
    [BulkEditMode.ADVANCED_ONLY]: null,
  }[displayMode];

  const basicColumnAccessors = _.compact(['id', 'workbookName', 'worksheetName', 'viewName', typeAccessor]);

  const basicColumns: TableColumn[] = _.concat(
    _.filter(allColumns, (column) => _.includes(basicColumnAccessors, column.accessor)),
    _.filter(allColumns, ['accessor', POSITION_FIELD]),
  );

  const columns = isAdvancedDisplay ? allColumns : basicColumns;

  return (
    <div className="bulkEditTable flexFill">
      <Table
        onRowSelectCallback={onRowSelectCallback}
        selectedIds={_.map(selectedContent, 'id')}
        columns={columns}
        items={filteredAndSortedContent}
        selectAllCallback={selectAllCallback}
        selectAll={selectAll}
        filterTableCallback={filterCallback}
        sortAscending={sortOptions.asc}
        sortTableCallback={sortCallback}
        sortProperty={sortOptions.field}
        searchParams={searchParams}
      />
    </div>
  );
};
