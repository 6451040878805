import React, { useState } from 'react';
import _ from 'lodash';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AssetColumnCalculationModal } from '@/hybrid/assetGroupEditor/AssetColumnCalculationModal.molecule';
import { ToolbarDropdownButton } from '@/hybrid/core/ToolbarDropdownButton.molecule';
import { addNewColumn } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { doTrack } from '@/track/track.service';

interface AddAssetGroupColumnBtnProps {
  isSmall: boolean;
}

export const AddAssetGroupColumnBtn: React.FunctionComponent<AddAssetGroupColumnBtnProps> = ({ isSmall }) => {
  type ModalType = 'calculation' | 'none';
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<ModalType>('none');

  const menuOptions = [
    {
      key: 'ADD_ATTRIBUTE',
      testId: 'addItemAttribute',
      action: () => {
        doTrack('Asset Group Editor', 'Add Column');
        addNewColumn();
      },
    },
    {
      key: 'ADD_CALCULATION',
      testId: 'addCalculationAttribute',
      action: () => {
        doTrack('Asset Group Editor', 'Add Calculation');
        setModalShown('calculation');
      },
    },
  ];

  const dropdownItems = _.map(menuOptions, ({ action, key, testId }) => (
    <Dropdown.Item key={key} data-testid={testId} onClick={action}>
      <div className="flexColumnContainer flexFill">
        <span>{t(`ASSET_GROUP_EDITOR.${key}`)}</span>
      </div>
    </Dropdown.Item>
  ));

  return (
    <>
      <ToolbarDropdownButton
        label="ASSET_GROUP_EDITOR.ADD_ATTRIBUTE"
        icon="fc-add-column-2"
        isSmall={isSmall}
        tooltipText="ASSET_GROUP_EDITOR.ADD_ATTRIBUTE"
        testId="addNewColumn"
        dropdownItems={dropdownItems}
      />
      {modalShown === 'calculation' && <AssetColumnCalculationModal onClose={() => setModalShown('none')} />}
    </>
  );
};
