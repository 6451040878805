// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { CELL_TYPES, Table, TableColumn } from '@/hybrid/core/Table.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ReportFilter, ReportFilters } from '@/hybrid/administration/ReportFilter.molecule';
import { APP_STATE, NUMBER_CONVERSIONS } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ReportTypeEnum } from '@/sdk/model/ReportAdminOutputV1';
import { DEFAULT_TYPE_FILTERS, TextFilters } from '@/hybrid/administration/reportAdmin.utilities';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { cancelGroup } from '@/hybrid/requests/pendingRequests.utilities';
import { sqContentApi, sqItemsApi } from '@/sdk';
import { ReportAdminOutputV1 } from '@/sdk/model/models';
import { Paginator } from '@/hybrid/core/Paginator.molecule';
import { SortByEnum } from '@/sdk/api/ContentApi';

const cancellationGroup = 'reportAdminTable';

const reportTableBindings = bindingsDefinition({
  hide: prop<boolean>(),
  toggleHideButton: prop<JSX.Element>(),
  reportTextFilters: prop<TextFilters>(),
  setReportTextFilters: prop<(filters: TextFilters) => void>(),
  triggerFetch: prop<() => void>(),
  trigger: prop<boolean>(),
  $state: injected<ng.ui.IStateService>(),
});

export const ReportTable: SeeqComponent<typeof reportTableBindings> = (props) => {
  const { hide, toggleHideButton, reportTextFilters, setReportTextFilters, triggerFetch, trigger } = props;

  const { $state } = useInjectedBindings(reportTableBindings);

  const [selectedIds, setSelectedIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [reports, setReports] = useState([]);
  const [sort, setSort] = useState({
    sortBy: SortByEnum.AverageRunTime,
    sortAsc: true,
  });
  const [reportTypeFilters, setReportTypeFilters] = useState(DEFAULT_TYPE_FILTERS);
  // React compares numbers in useEffect by value, so instead use an object
  const [page, setPage] = useState({ value: 1 });
  const [limit, setLimit] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);

  const filterToApiList = (filter: ReportFilters): any[] => {
    const list: ReportTypeEnum[] = [];
    if (filter.live) list.push(ReportTypeEnum.Live);
    if (filter.scheduled) list.push(ReportTypeEnum.Scheduled);
    if (filter.static) list.push(ReportTypeEnum.Static);

    return list;
  };

  const fetchReports = () => {
    setTableLoading(true);
    return cancelGroup(cancellationGroup)
      .then(() =>
        (
          sqContentApi.getReportStatistics(
            {
              types: filterToApiList(reportTypeFilters),
              offset: (page.value - 1) * limit,
              limit,
              sortBy: sort.sortBy,
              sortAsc: sort.sortAsc,
              isEnabled: reportTypeFilters.enabled,
              nameFilter: reportTextFilters.name,
              scheduleFilter: reportTextFilters.schedules,
              ownerFilter: reportTextFilters.owner,
            },
            {
              cancellationGroup,
            },
          ) as Promise<any>
        ).then(({ data: { reports, totalCount } }) => {
          setReports(reports);
          setTotalCount(totalCount);
        }),
      )
      .finally(() => {
        setTableLoading(false);
        setSelectedIds([]);
      });
  };

  useEffect(() => {
    setPage({ value: 1 });
  }, [limit, sort, reportTypeFilters, trigger]);

  useEffect(() => {
    fetchReports();
  }, [page]);

  const renderTopicDocumentName = (report: ReportAdminOutputV1): JSX.Element => {
    const params = {
      workbookId: report.topicId,
      worksheetId: report.documentId,
    };
    return (
      <a href={$state.href(APP_STATE.WORKSHEET, params)} target="_blank">
        {report.topicName} - {report.documentName}
      </a>
    );
  };

  const renderSchedules = (report: ReportAdminOutputV1): JSX.Element => (
    <div className="flexRowContainer">
      {_.map(report.schedules, (schedule, index) => (
        <span key={index}>{schedule}</span>
      ))}
    </div>
  );

  const renderLastViewedAt = (report: ReportAdminOutputV1): JSX.Element => (
    <>{report.lastViewedAt && `${moment.duration(moment.utc().diff(moment.utc(report.lastViewedAt))).humanize()}`}</>
  );

  const renderDurationStat = (field): JSX.Element => <>{field / NUMBER_CONVERSIONS.MILLISECONDS_PER_SECOND}</>;

  const enterCallback = () => {
    setPage({ value: 1 });
    triggerFetch();
  };

  const columns: TableColumn[] = [
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40 },
    },
    {
      accessor: 'name',
      header: 'ADMIN.REPORT.NAME',
      filterable: true,
      sortable: true,
      searchProperty: 'name',
      cellStyle: { width: 200, maxWidth: 200 },
      cellRenderFunction: renderTopicDocumentName,
      enterCallback,
    },
    {
      accessor: 'nextRunTime',
      header: 'ADMIN.REPORT.NEXT_RUN_TIME',
      filterable: false,
      sortable: true,
      cellStyle: { minWidth: 75 },
    },
    {
      accessor: 'contentCount',
      header: 'ADMIN.REPORT.CONTENT_COUNT',
      filterable: false,
      sortable: true,
      cellStyle: { maxWidth: 100 },
    },
    {
      accessor: 'averageRunTime',
      header: 'ADMIN.REPORT.AVERAGE_RUN_TIME',
      filterable: false,
      sortable: true,
      cellStyle: { maxWidth: 100 },
      cellRenderFunction: (report: ReportAdminOutputV1) => renderDurationStat(report.averageRunTime),
      tooltip: 'ADMIN.REPORT.TOOLTIPS.AVERAGE_RUN_TIME',
    },
    {
      accessor: 'totalRunTime',
      header: 'ADMIN.REPORT.TOTAL_RUN_TIME',
      filterable: false,
      sortable: true,
      cellStyle: { maxWidth: 100 },
      cellRenderFunction: (report: ReportAdminOutputV1) => renderDurationStat(report.totalRunTime),
      tooltip: 'ADMIN.REPORT.TOOLTIPS.TOTAL_RUN_TIME',
    },
    {
      accessor: 'owner',
      header: 'ADMIN.REPORT.OWNER',
      filterable: true,
      sortable: true,
      searchProperty: 'owner',
      cellStyle: { maxWidth: 150, wordWrap: 'break-word' },
      enterCallback,
    },
    {
      accessor: 'lastRunTime',
      header: 'ADMIN.REPORT.LAST_RUN_TIME',
      filterable: false,
      cellStyle: { minWidth: 75 },
    },
    {
      accessor: 'lastViewedAt',
      header: 'ADMIN.REPORT.TIME_SINCE_VIEWED',
      filterable: false,
      sortable: true,
      cellStyle: { minWidth: 75 },
      cellRenderFunction: renderLastViewedAt,
    },
    {
      accessor: 'schedules',
      header: 'ADMIN.REPORT.SCHEDULES',
      filterable: true,
      cellStyle: { minWidth: 100 },
      searchProperty: 'schedules',
      cellRenderFunction: renderSchedules,
      enterCallback,
    },
    {
      accessor: 'reportType',
      header: 'ADMIN.REPORT.REPORT_TYPE',
      filterable: false,
      cellStyle: { maxWidth: 70 },
    },
  ];

  const selectCallback = (item: ReportAdminOutputV1) => {
    const itemIndex = _.indexOf(selectedIds, item.id);
    if (itemIndex > -1) {
      setSelectedIds([..._.pull(selectedIds, item.id)]);
    } else {
      setSelectedIds([...selectedIds, item.id]);
    }
  };

  const selectAllCallback = () => {
    if (allSelected) {
      setSelectedIds([]);
    } else {
      setSelectedIds(_.map(reports, 'id'));
    }
    setAllSelected(!allSelected);
  };

  const filterReports = (option, field) => {
    if (reportTextFilters[field] !== option.value) {
      setReportTextFilters({ ...reportTextFilters, [field]: option.value });
    }
  };

  const sortReports = (sortBy, sortAsc) => {
    setSort({
      sortBy: SortByEnum[(sortBy[0].toUpperCase() + sortBy.slice(1)) as keyof typeof SortByEnum],
      sortAsc: !sortAsc,
    });
  };

  const renderTableLoadingIndicator: JSX.Element = (
    <div className="flexColumnContainer flexCenter pt50 pb50">
      <Icon icon="fa-spinner fa-pulse fa-5x" extraClassNames="sq-text-primary" />
    </div>
  );

  const disableReports = () => {
    setSelectedIds([]);
    setReports([]);
    setTableLoading(true);
    _.chain(selectedIds)
      .map((id) => sqItemsApi.setProperty({ value: false }, { id, propertyName: SeeqNames.Properties.Enabled }))
      .thru((promises) => Promise.all(promises).then(() => fetchReports()))
      .value();
  };

  return (
    <div style={{ display: hide ? 'none' : 'block' }} className="height-maximum">
      <div className="flexColumnContainer flexSpaceBetween min-height-32 mb5">
        <TextButton
          id="disableReports"
          type="button"
          extraClassNames="mr5"
          size="sm"
          onClick={disableReports}
          disabled={!reportTypeFilters.enabled || _.isEmpty(selectedIds)}
          label="ADMIN.REPORT.DISABLE"
        />

        <ReportFilter filters={reportTypeFilters} setFilters={(filters) => setReportTypeFilters({ ...filters })} />

        <div className="flexColumnContainer">
          <ButtonWithManagedSpinner
            buttonProps={{ id: 'refreshReports', extraClassNames: 'mr5' }}
            action={fetchReports}
            icon="fa-repeat"
            spinnerIconProps={{ large: true, type: 'text' }}
            label="ADMIN.REPORT.REFRESH"
          />

          {toggleHideButton}
        </div>
      </div>

      <div className="height-maximum overflowAuto pb125">
        {!tableLoading && (
          <Table
            testId="reportAdministrationTable"
            onRowSelectCallback={selectCallback}
            selectAllCallback={selectAllCallback}
            selectAll={allSelected}
            selectedIds={selectedIds}
            filterTableCallback={filterReports}
            searchParams={reportTextFilters}
            sortTableCallback={sortReports}
            sortProperty={sort.sortBy[0].toLowerCase() + sort.sortBy.toString().slice(1)}
            sortAscending={sort.sortAsc}
            columns={columns}
            items={reports}
          />
        )}

        {!tableLoading && (
          <Paginator
            gotoPage={(page) => setPage({ value: page })}
            pageCount={totalCount === 0 ? 0 : totalCount / limit}
            pageNumber={page.value}
            pageSize={limit}
            setPageSize={setLimit}
            total={totalCount}
          />
        )}

        {tableLoading && renderTableLoadingIndicator}
      </div>
    </div>
  );
};
