// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CancelAndSave } from '@/hybrid/core/CancelAndSave.molecule';
import { doTrack } from '@/track/track.service';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqItemsApi } from '@/sdk';
import { ContainerWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';
import { toggleEditor } from '@/hybrid/utilities/migration.utilities';

import { reload } from '@/hybrid/utilities/utilities';

interface CkMigrationModalProps {
  annotationId: string;
  onClose: () => void;
  toCk: boolean;
  ckDisabled?: boolean;
}

const TRANSLATE_PREFIX = 'REPORT.EDITOR.MIGRATION';

export const CkMigrationModal: React.FunctionComponent<CkMigrationModalProps> = (props) => {
  const { annotationId, onClose, toCk, ckDisabled = false } = props;

  const { t } = useTranslation();

  const [migrating, setMigrating] = useState(false);
  const [froalaBackup, setFroalaBackup] = useState('');

  const getTranslateString = (suffix: string) => t(`${TRANSLATE_PREFIX}.${toCk ? 'TO_CK' : 'TO_FROALA'}.${suffix}`);

  useEffect(() => {
    sqItemsApi
      .getProperty({
        id: annotationId,
        propertyName: SeeqNames.Properties.FroalaBackup,
      })
      .then((response) => {
        setFroalaBackup(response.data.value);
      });
  }, []);

  const close = () => {
    setMigrating(false);
    onClose();
  };

  const migrate = () => {
    doTrack('CKEditor', toCk ? 'Upgrade' : 'Revert');
    setMigrating(true);
    return toggleEditor(annotationId).then(() => reload());
  };

  return (
    <Modal show={true} onHide={() => !migrating && close()} animation={false} backdrop="static">
      <Modal.Header closeButton={true}>
        <Modal.Title>{getTranslateString('TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{getTranslateString('BODY')}</p>
        {!toCk && (
          <ContainerWithHTML
            content={froalaBackup}
            isBlock={true}
            extraClassNames="height-300 overflowYScroll formula-border fr-element fr-view p5 border-radius-4"
          />
        )}
        {toCk && (
          <ul>
            <li>{t('REPORT.EDITOR.MIGRATION.TO_CK.FEATURE_1')}</li>
            <li>{t('REPORT.EDITOR.MIGRATION.TO_CK.FEATURE_2')}</li>
            <li>{t('REPORT.EDITOR.MIGRATION.TO_CK.FEATURE_3')}</li>
            <li>{t('REPORT.EDITOR.MIGRATION.TO_CK.FEATURE_4')}</li>
          </ul>
        )}
        {!toCk && ckDisabled && (
          <>
            <br />
            <br />
            {getTranslateString('WARNING')}
          </>
        )}
        {toCk && getTranslateString('WARNING')}
      </Modal.Body>
      <Modal.Footer>
        <CancelAndSave
          submitFn={() => migrate().then(close)}
          cancelFn={close}
          values={[]}
          btnDisabled={false}
          submitBtnLabel={getTranslateString('MIGRATE')}
          cancelBtnLabel={getTranslateString('CANCEL')}
          submitBtnVariant={toCk ? 'theme' : 'danger'}
        />
      </Modal.Footer>
    </Modal>
  );
};
