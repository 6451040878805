// @ts-strict-ignore
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';

import { PredictionPanelActions } from '@/hybrid/tools/prediction/predictionPanel.actions';
import { FORM_ERROR, FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { DISPLAY_MODE } from '@/main/app.constants';
import { DEBOUNCE } from '@/core/core.constants';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { PredictionModel } from './PredictionModel';
import { FormulaService } from '@/services/formula.service';
import {
  getCoefficientClipboardText,
  getModelTableData,
  getStatisticsClipboardText,
  ModelTableData,
  openTooltipLink,
} from '@/hybrid/tools/prediction/prediction.utilities';
import { TrendActions } from '@/trendData/trend.actions';
import { useDebounce } from '@/hybrid/core/hooks/useDebounce.hook';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { POLYNOMIAL_VALUES, PREDICTION } from '@/hybrid/tools/prediction/prediction.constants';
import { isCanceled } from '@/hybrid/utilities/http.utilities';
import { ITEM_TYPES, PREVIEW_ID } from '@/trendData/trendData.constants';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { cancelGroup, count } from '@/hybrid/requests/pendingRequests.utilities';
import {
  createFormula as createFormulaSqPredictionHelper,
  transformTableResponse,
} from '@/hybrid/utilities/predictionHelper.utilities';
import { sqInvestigateStore, sqPredictionPanelStore } from '@/core/core.stores';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { PREDICTION_OPERATION } from '@/services/calculationRunner.constants';
import { doTrack } from '@/track/track.service';

const predictionBindings = bindingsDefinition({
  sqPredictionPanelActions: injected<PredictionPanelActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqFormula: injected<FormulaService>(),
  sqTrendActions: injected<TrendActions>(),
  sqScatterPlotActions: injected<ScatterPlotActions>(),
});

export const Prediction = () => {
  const { t } = useTranslation();
  const { sqPredictionPanelActions, sqInvestigateActions, sqFormula, sqTrendActions, sqScatterPlotActions } =
    useInjectedBindings(predictionBindings);

  const {
    id,
    name,
    targetSignal,
    originalParameters,
    inputSignals,
    window: trainingWindow,
    option,
    polynomialValue,
    condition,
    regressionMethod,
    mustGoThroughZero,
    variableSelectionEnabled,
    variableSelection,
    principalComponentsToKeep,
    modelExpanded,
    lambda,
    configParams,
  } = useFlux(sqPredictionPanelStore);

  const displayMode = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.displayMode);

  const inProgress = useRef(false);
  const lastModelFormula = useRef<any>();
  const lastPreviewFormula = useRef<any>();
  const [coefficientClipboardText, setCoefficientClipboardText] = useState('');
  const [statsClipboardText, setStatisticsClipboardText] = useState('');
  const rawModel = useRef<any>();

  const [color, setColor] = useState('');
  const [trainingWindowValid, setTrainingWindowValid] = useState(true);
  const [model, setModel] = useState<ModelTableData>();
  const [gettingPredictionModel, setGettingPredictionModel] = useState(false);

  const translate = useMemo(
    () => ({
      variable: t('PREDICTION.VARIABLE'),
      name: t('PREDICTION.NAME'),
      coefficient: t('PREDICTION.COEFFICIENT'),
      standardError: t('PREDICTION.STANDARD_ERROR'),
      value: t('PREDICTION.VALUE'),
      legend: t('PREDICTION.LEGEND'),
      pValue: t('PREDICTION.PVALUE'),
    }),
    [],
  );

  const regressionMethodOptions = useMemo(
    () =>
      _.map(PREDICTION.REGRESSION_METHODS, (value, key) => ({
        value,
        label: t(`PREDICTION.REGRESSION_METHODS.${key}`),
      })),
    [],
  );

  const isReady = targetSignal && inputSignals && inputSignals.length > 0 && !inProgress.current;

  /**
   * Builds a formula based on the properties from the vm and from the store
   *
   * @param {String} option - one of PREDICTION_OPERATION
   * @returns {Object} output from `sqPredictionHelper.createFormula`
   */
  const createFormula = (option: string) =>
    createFormulaSqPredictionHelper(
      option,
      targetSignal.id,
      inputSignals,
      condition?.id,
      principalComponentsToKeep,
      configParams,
    );

  /**
   * Calls the backend to get the prediction model, formats some numbers for display
   * and sets the result to model for display in table.
   */
  const updatePredictionModel = () => {
    if (!modelExpanded) {
      return;
    }

    const { formula, parameters, inputLegend, groupArray } = createFormula(PREDICTION_OPERATION.PREDICTION_MODEL);

    setGettingPredictionModel(true);
    const cancellationGroup = 'updatePredictionModel';
    cancelGroup(cancellationGroup, true);

    return sqFormula
      .computePredictionModel({ formula, parameters, cancellationGroup })
      .then((res) => transformTableResponse(res, inputLegend, groupArray))
      .then((model) => {
        rawModel.current = model;
        setModel(getModelTableData(model));
      })
      .catch((error) => {
        const canceled = isCanceled(error);

        if (canceled && !count(cancellationGroup) && !_.get(error, 'config.refetching', false)) {
          return;
        }

        errorToast({ httpResponseOrError: error });
        setModel(undefined);
        setCoefficientClipboardText(undefined);
        setStatisticsClipboardText(undefined);
      })
      .finally(() => {
        setGettingPredictionModel(false);
      });
  };

  /**
   * Generates the prediction preview.
   */
  function generatePreview() {
    const { formula, parameters } = createFormula(PREDICTION_OPERATION.PREDICTION);

    sqTrendActions.generatePreviewSeries(formula, parameters, id || PREVIEW_ID, color);
  }

  const runPreview = useDebounce(() => {
    if (isReady && sqInvestigateStore.activeTool === TREND_TOOLS.PREDICTION) {
      generatePreview();
    }
  }, DEBOUNCE.PREVIEW);

  if (isReady) {
    const { PREDICTION_MODEL, PREDICTION } = PREDICTION_OPERATION;
    const previewModal = _.assign(createFormula(PREDICTION), { color });
    const modelFormula = _.assign(createFormula(PREDICTION_MODEL), {
      modelExpanded,
    });

    // Assume that if the formula's are identical the resulting preview and model will be the same
    if (!_.isEqual(lastModelFormula.current, modelFormula)) {
      updatePredictionModel();
      lastModelFormula.current = modelFormula;
    }

    if (!_.isEqual(lastPreviewFormula.current, previewModal)) {
      runPreview();
      lastPreviewFormula.current = previewModal;
    }
  }

  useEffect(() => {
    return () => {
      sqTrendActions.removePreviewSeries();
      runPreview.cancel && runPreview.cancel();
      sqTrendActions.cancelPreviewSeries();
    };
  }, []);

  useEffect(() => {
    if (rawModel.current) {
      const coefficientClipboardText = getCoefficientClipboardText(rawModel.current, translate);
      const statisticsClipboardText = getStatisticsClipboardText(rawModel.current, translate);

      coefficientClipboardText && setCoefficientClipboardText(coefficientClipboardText);
      statisticsClipboardText && setStatisticsClipboardText(statisticsClipboardText);
    }
  }, [rawModel.current]);

  const handleChangePolynomialValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    sqPredictionPanelActions.setPolynomialValue(Number(e.target.value));
  };

  const formDataSetup: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'predictionSearchTitle',
      value: name,
      onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.PREDICTION, name),
      id,
      onColorChange: setColor,
      searchIconClass: 'fc-prediction',
      defaultName: 'PREDICTION.HEADER',
    },
    {
      component: 'ItemSelectFormComponent',
      name: 'targetSignal',
      testId: 'targetSignal',
      displayNumber: true,
      value: targetSignal?.id,
      onChange: (item) => sqInvestigateActions.setParameterItem(TREND_TOOLS.PREDICTION, 'targetSignal', item),
      label: 'PREDICTION.TARGET',
      itemTypes: [ITEM_TYPES.SERIES],
      additionalItems: originalParameters,
      excludedIds: [id],
    },
    {
      component: 'ItemSelectFormComponent',
      name: 'inputSignals',
      testId: 'inputSignals',
      displayNumber: true,
      value: _.map(inputSignals, 'id') as any,
      onChange: (item) => sqInvestigateActions.setParameterItem(TREND_TOOLS.PREDICTION, 'inputSignals', item),
      onRemove: (item) => sqInvestigateActions.unsetParameterItem(TREND_TOOLS.PREDICTION, 'inputSignals', item),
      label: 'PREDICTION.INPUT',
      itemTypes: [ITEM_TYPES.SERIES],
      additionalItems: originalParameters,
      excludedIds: [id, targetSignal?.id],
      excludeStringSignals: true,
      isMultipleSelect: true,
      onDetailsButtonClick: () =>
        sqInvestigateActions.setParameterItemFromDetailsPane(
          TREND_TOOLS.PREDICTION,
          'inputSignals',
          [ITEM_TYPES.SERIES],
          [id, targetSignal?.id],
          true,
        ),
    },
    {
      // TODO CRAB-29288: work on this
      component: 'FormGroup',
      name: 'trainingWindowFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'LabelFormComponent',
          name: 'trainingWindowLabel',
          value: 'FORM.TRAINING_WINDOW',
        },
        {
          component: 'CapsuleInputFormComponent',
          name: 'trainingWindow',
          value: trainingWindow,
          capsuleWindow: trainingWindow,
          onChange: sqPredictionPanelActions.setTrainingWindow,
          trackCategory: 'Prediction',
          trackAction: 'Training Window Change',
          setCapsuleInputValidStatus: setTrainingWindowValid,
          extendValidation: false,
          validation: () => !trainingWindowValid,
        },
      ],
    },
    {
      component: 'RadioButtonGroupFormComponent',
      name: 'predictionOptions',
      id: 'predictionOptions',
      value: option,
      onChange: _.noop,
      displayNumber: true,
      verticalLayout: true,
      label: 'PREDICTION.SCALE.LABEL',
      options: [
        {
          id: 'radioOptionLinear',
          label: 'PREDICTION.SCALE.LINEAR',
          checked: option === PREDICTION.SCALE.LINEAR,
          onToggle: () => sqPredictionPanelActions.setOption(PREDICTION.SCALE.LINEAR),
        },
        {
          id: 'radioOptionLog',
          label: 'PREDICTION.SCALE.LOG',
          checked: option === PREDICTION.SCALE.LOG,
          onToggle: () => sqPredictionPanelActions.setOption(PREDICTION.SCALE.LOG),
        },
        {
          id: 'radioOptionPoly',
          label: (
            <div className="inlineFlex flexAlignCenter">
              {t('PREDICTION.SCALE.POLYNOMIAL')}
              <select
                name="polynomialSelect"
                id="polynomialSelect"
                data-testid="polynomialSelect"
                className="ml5"
                value={polynomialValue}
                onChange={handleChangePolynomialValue}
                disabled={option !== PREDICTION.SCALE.POLYNOMIAL}>
                {_.map(POLYNOMIAL_VALUES, (value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          ),
          checked: option === PREDICTION.SCALE.POLYNOMIAL,
          onToggle: () => sqPredictionPanelActions.setOption(PREDICTION.SCALE.POLYNOMIAL),
        },
        {
          id: 'radioOptionExpanded',
          label: 'PREDICTION.SCALE.EXPANDED_BASIS',
          checked: option === PREDICTION.SCALE.EXPANDED_BASIS,
          onToggle: () => sqPredictionPanelActions.setOption(PREDICTION.SCALE.EXPANDED_BASIS),
        },
      ],
    },
    {
      component: 'AdvancedFormGroup',
      name: 'advancedPredictionParametersFormGroup',
      toolId: TREND_TOOLS.PREDICTION,
      toolName: 'Prediction',
      toolStore: sqPredictionPanelStore,
      components: [
        {
          component: 'ItemSelectFormComponent',
          name: 'condition',
          testId: 'condition',
          displayNumber: true,
          value: condition?.id,
          onChange: (item) => sqInvestigateActions.setParameterItem(TREND_TOOLS.PREDICTION, 'condition', item),
          label: 'PREDICTION.CONDITION',
          itemTypes: [ITEM_TYPES.CAPSULE_SET],
          additionalItems: originalParameters,
          allowClear: true,
          validation: () => false,
        },
        {
          component: 'SelectFormComponent',
          name: 'selectRegressionMethod',
          testId: 'selectRegressionMethod',
          label: 'PREDICTION.REGRESSION_METHOD',
          tooltip: 'PREDICTION.REGRESSION_METHOD_TOOLTIP',
          onTooltipClick: openTooltipLink,
          value: regressionMethod,
          onChange: sqPredictionPanelActions.setRegressionMethod,
          options: regressionMethodOptions,
          required: true,
          displayNumber: true,
        },
        {
          component: 'CheckboxFormComponent',
          name: 'predictionMustGoThroughZero',
          testId: 'predictionMustGoThroughZero',
          id: 'predictionMustGoThroughZero',
          value: mustGoThroughZero,
          onChange: () => sqPredictionPanelActions.setMustGoThroughZero(!mustGoThroughZero),
          checkboxLabel: 'PREDICTION.ZERO',
          tooltip: 'PREDICTION.ZERO_TOOLTIP',
          onHelpIconClick: openTooltipLink,
          displayNumber: true,
          includeIf: regressionMethod === PREDICTION.REGRESSION_METHODS.ORDINARY_LEAST_SQUARES,
        },
        {
          component: 'FormRow',
          name: 'lambdaFormRow',
          includeIf: regressionMethod === PREDICTION.REGRESSION_METHODS.RIDGE,
          displayNumber: true,
          extraClassNames: 'flexAlignCenter',
          components: [
            {
              component: 'LabelFormComponent',
              value: 'PREDICTION.LAMBDA',
              name: 'lambdaValueLabel',
              noMarginBottom: true,
            },
            {
              component: 'FormControlFormComponent',
              name: 'lambda',
              testId: 'lambda',
              value: lambda,
              onChange: (value: string) => {
                const parsedLambda = Number.parseFloat(value);
                let adjustedLambda = 0.0;
                if (!Number.isNaN(parsedLambda)) {
                  // Make sure lambda is at least 0
                  adjustedLambda = Math.max(parsedLambda, 0.0);
                }

                sqPredictionPanelActions.setLambda(adjustedLambda);
              },
              size: 'sm',
              extraClassNames: 'width-75 ml3',
              tooltip: 'PREDICTION.LAMBDA_RIDGE_TOOLTIP',
              onTooltipClick: openTooltipLink,
            },
          ],
        },
        {
          component: 'FormRow',
          name: 'pcaFormRow',
          includeIf: regressionMethod === PREDICTION.REGRESSION_METHODS.PRINCIPAL_COMPONENT_REGRESSION,
          displayNumber: true,
          extraClassNames: 'flexAlignCenter',
          components: [
            {
              component: 'LabelFormComponent',
              value: 'PREDICTION.PRINCIPAL_COMPONENTS_TO_KEEP',
              name: 'pcaValueLabel',
              noMarginBottom: true,
            },
            {
              component: 'FormControlFormComponent',
              name: 'pca',
              testId: 'pca',
              value: principalComponentsToKeep,
              onChange: sqPredictionPanelActions.setPrincipalComponentsToKeep,
              size: 'sm',
              extraClassNames: 'width-75 ml3 mb-0',
              tooltip: 'PREDICTION.PRINCIPAL_COMPONENTS_TO_KEEP_TOOLTIP',
              onTooltipClick: openTooltipLink,
            },
          ],
        },
        {
          component: 'FormRow',
          name: 'variableSelectionRow',
          displayNumber: true,
          extraClassNames: 'flexAlignCenter',
          components: [
            {
              component: 'CheckboxFormComponent',
              name: 'variableSelectionEnabled',
              testId: 'variableSelectionEnabled',
              id: 'variableSelectionEnabled',
              value: variableSelectionEnabled,
              onChange: () => sqPredictionPanelActions.setVariableSelectionEnabled(!variableSelectionEnabled),
              checkboxLabel: 'PREDICTION.VARIABLE_SELECTION',
            },
            {
              component: 'FormControlFormComponent',
              name: 'variableSelection',
              testId: 'variableSelection',
              value: variableSelection,
              onChange: (value) => sqPredictionPanelActions.setVariableSelection(Number(value)),
              size: 'sm',
              extraClassNames: 'width-100 ml3 mb-0',
              tooltip: 'PREDICTION.VARIABLE_SELECTION_TOOLTIP',
              disabled: !variableSelectionEnabled,
              type: 'number',
              step: 0.01,
              max: 1,
              min: 0,
              validation: (value) => {
                const numberValue = Number(value);

                return !value || numberValue < 0 || numberValue > 1;
              },
              onTooltipClick: openTooltipLink,
            },
          ],
        },
        {
          component: 'ErrorMessageFormComponent',
          name: 'variableSelectionError',
          testId: 'variableSelectionError',
          value: 'PREDICTION.VARIABLE_SELECTION_ERROR',
          includeIf: variableSelection === '' || variableSelection > 1 || variableSelection < 0,
          failForm: true,
          type: FORM_ERROR,
          extraClassNames: 'ml35',
        },
      ],
    },
    {
      component: 'DisplayOnlyFormElementWrapper',
      name: 'predictionModel',
      children: (
        <PredictionModel
          disabled={!isReady}
          model={model}
          coefficientClipboardText={coefficientClipboardText}
          statisticsClipboardText={statsClipboardText}
          modelExpanded={modelExpanded}
          onToggleExpand={sqPredictionPanelActions.setModelExpanded}
          gettingPredictionModel={gettingPredictionModel}
        />
      ),
    },
  ];

  const generate = () => {
    inProgress.current = true;
    sqTrendActions.removePreviewSeries();
    const isNew = !id;

    const { formula, parameters } = createFormula(PREDICTION_OPERATION.PREDICTION);

    return sqPredictionPanelActions
      .generate(name, formula, parameters, color)
      .then((id) => {
        doTrack('Workbench_Tool', 'Prediction', 'completed');
        sqScatterPlotActions.autoAddNewSignalAsFxLine(id, isNew);
      })
      .catch(() => {
        doTrack('Workbench_Tool', 'Prediction', 'error');
      })
      .finally(() => {
        inProgress.current = false;
      });
  };

  const predictionBuilder = (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={generate}
      closeFn={sqInvestigateActions.close}
      toolId={TREND_TOOLS.PREDICTION}
      submitBtnId="predictionButton"
    />
  );

  return displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT ? predictionBuilder : null;
};

export const sqPrediction = angularComponent(predictionBindings, Prediction);
