import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { ManualConditionStore } from '@/hybrid/tools/manualCondition/manualCondition.store';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { DISPLAY_MODE } from '@/main/app.constants';
import { DEBOUNCE } from '@/core/core.constants';
import React, { useEffect, useRef, useState } from 'react';
import { PREVIEW_ID } from '@/trendData/trendData.constants';
import { useDebounce } from '@/hybrid/core/hooks/useDebounce.hook';
import { TrendActions } from '@/trendData/trend.actions';
import _ from 'lodash';
import {
  conditionFormula,
  makeCapsulesUnique,
  removeInvalidCapsules,
} from '@/hybrid/tools/manualCondition/conditionFormula.service';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { fetchPropertiesAndBuildFormula } from '@/hybrid/tools/manualCondition/manualCondition.actions';

import { sqCapsuleGroupStore, sqInvestigateStore, sqTrendCapsuleStore } from '@/core/core.stores';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { doTrack } from '@/track/track.service';

const manualConditionBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqManualConditionStore: injected<ManualConditionStore>(),
  sqTrendActions: injected<TrendActions>(),
  sqToolRunner: injected<ToolRunnerService>(),
});

export const ManualCondition: SeeqComponent<typeof manualConditionBindings> = () => {
  const { sqInvestigateActions, sqManualConditionStore, sqTrendActions, sqToolRunner } =
    useInjectedBindings(manualConditionBindings);

  const { displayMode, activeTool } = useFlux(sqInvestigateStore);
  const { editingId } = useFlux(sqTrendCapsuleStore);
  const { id, name, configParams } = useFlux(sqManualConditionStore);
  const { capsules } = useFlux(sqCapsuleGroupStore);

  const [color, setColor] = useState('');
  const searchInProgress = useRef(false);
  const isFormValid = useRef(false);

  const toolName = TREND_TOOLS.MANUAL_CONDITION;

  /**
   * Sends a preview to the chart (without properties)
   *
   * @returns {Promise} when preview is complete
   */
  const preview = () => {
    if (!isFormValid || capsules.length === 0) {
      sqTrendActions.displayEmptyPreviewCapsuleLane(); // Note: the empty lane isn't shown until it succeeds once
      return Promise.resolve();
    }

    return Promise.resolve(capsules)
      .then((capsules) =>
        _.map(capsules, ({ startTime, endTime }) => ({
          startTime,
          endTime,
          properties: [],
        })),
      )
      .then(removeInvalidCapsules)
      .then(makeCapsulesUnique)
      .then(conditionFormula)
      .then((formula) =>
        sqTrendActions.generatePreviewCapsules(
          formula,
          {},
          id,
          color,
          true, // usePost - Formulas can become large enough to exceed node's max http header size
        ),
      );
  };

  const runPreview = useDebounce(() => {
    if (!searchInProgress.current && activeTool === toolName) {
      if (_.isNil(editingId)) {
        sqTrendActions.setEditModeForCapsuleSet(id);
      }

      preview();
    }
  }, DEBOUNCE.PREVIEW);

  const stopPreview = () => {
    sqTrendActions.removePreviewCapsules();
    runPreview.cancel && runPreview.cancel();
    sqTrendActions.cancelPreviewCapsules();
  };

  const closeFn = () => {
    stopPreview();
    sqTrendActions.fetchTableAndChartCapsules();
  };

  useEffect(() => closeFn, []);

  useEffect(() => {
    const timer = setTimeout(runPreview, 1);

    return () => clearTimeout(timer);
  }, [capsules]);

  /**
   * Generates the condition with properties
   */
  const execute = () => {
    searchInProgress.current = true;

    return Promise.resolve()
      .then(fetchPropertiesAndBuildFormula)
      .then((formula) =>
        sqToolRunner.panelExecuteCondition(
          name,
          formula,
          {},
          configParams,
          id,
          color,
          { closeOnSuccess: false }, // Call our close method instead so that all capsules are deselected
        ),
      )
      .then(() => {
        doTrack('Workbench_Tool', 'Manual Condition', 'completed');
        sqTrendActions.unselectAllCapsules();
        sqInvestigateActions.close();
      })
      .catch((e) => {
        doTrack('Workbench_Tool', 'Manual Condition', 'error');

        return Promise.reject(
          _.tap(e, () =>
            errorToast({
              doNotTrack: true,
              httpResponseOrError: e,
              displayForbidden: true,
            }),
          ),
        );
      })
      .finally(() => {
        searchInProgress.current = false;
        stopPreview();
      });
  };

  const formDataSetup: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'manualConditionSearchTitle',
      value: name,
      onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.MANUAL_CONDITION, name),
      id,
      onColorChange: setColor,
      searchIconClass: 'fc-capsule-set',
      defaultName: 'INVESTIGATE_TOOLS.MANUAL_CONDITION.HEADER',
    },
    {
      component: 'CapsuleGroupFormComponent',
      name: 'capsules',
      displayNumber: true,
      label: 'CAPSULE_GROUP_PICKING.HOW_TO',
      previewId: id || PREVIEW_ID,
      customErrorText: 'CAPSULE_GROUP_ERROR',
      value: capsules,
    },
  ];

  const manualConditionBuilder = (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={execute}
      closeFn={sqInvestigateActions.close}
      toolId={toolName}
      submitBtnId="manualConditionExecuteBtn"
      setIsValid={(isValid) => {
        isFormValid.current = isValid;
      }}
    />
  );

  return displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT ? manualConditionBuilder : null;
};

export const sqManualCondition = angularComponent(manualConditionBindings, ManualCondition);
