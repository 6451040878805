// @ts-strict-ignore
import _ from 'lodash';
import juice from 'juice/client';
import tinycolor from 'tinycolor2';
import { isAuthOnContentImageEnabled } from '@/services/systemConfiguration.utilities';
import { getWorkbenchAddress } from '../utilities/utilities';

const HSL_REGEX = /hsl(a?)\(\s*(\d+)\s*,\s*(\d*(?:\.\d+)?%)\s*,\s*(\d*(?:\.\d+)?%)(,\s*(\d*(?:\.\d+)?))?\)/gi;

// hsla(321, 10%, 45%, 0.5)  =>  #EE34A2A0
const hslaToHex = (h: number, s: number, l: number, alpha: number = null): string => {
  let hslString = hslToHex(h, s, l);
  if (!_.isNull(alpha)) {
    hslString += rgbToHex(alpha);
  }
  return hslString;
};

const hslToHex = (h: number, s: number, l: number): string => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const rgbToHex = (rgb: number): string => {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = `0${hex}`;
  }
  return hex;
};

export const convertContentHslToHex = (input: string): string => {
  const allColors = input.matchAll(HSL_REGEX);
  let iterator = allColors.next();
  let convertedString = '';
  let oldIndex = 0;
  while (!iterator.done) {
    const match = iterator.value;
    const rgbValue = hslaToHex(
      _.parseInt(match[2]),
      _.parseInt(match[3]),
      _.parseInt(match[4]),
      match[6] ? _.toInteger(_.toNumber(match[6]) * 255) : null,
    );
    convertedString += `${input.substring(oldIndex, match.index)}${rgbValue}`;
    oldIndex = match.index + match[0].length;
    iterator = allColors.next();
  }
  convertedString += `${input.substring(oldIndex)}`;

  return convertedString;
};

export const convertImagesSrc = (input: string): string => {
  if (!isAuthOnContentImageEnabled()) {
    return input;
  }
  const containerElement = window.document.createElement('div');
  containerElement.innerHTML = input;

  containerElement.querySelectorAll('img:not([seeq-src]').forEach((img) => {
    const srcData = getImageDataURL(img as HTMLImageElement);
    img.setAttribute('src', srcData);
  });

  return containerElement.innerHTML;
};

/**
 * Creates the dataURL of the image. It can be used as value for a html image src
 *
 * @param img {Image} - the input image
 * @returns dataURL of the image
 */
export const getImageDataURL = (img: HTMLImageElement): string => {
  const canvas = document.createElement('canvas');
  // search for this image already loaded in our body
  const imageSelector = `img[src="${img.getAttribute('src')}"]`.replace(getWorkbenchAddress(), '');
  const bodyImg = window.document.querySelector(imageSelector) as HTMLImageElement;
  if (!bodyImg) {
    return img.getAttribute('src');
  }
  canvas.width = bodyImg.naturalWidth;
  canvas.height = bodyImg.naturalHeight;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(bodyImg as HTMLImageElement, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL('image/png');
};

export const convertStyleToInline = (input: string, styleToUse: string): string => {
  // <style data-cke="true">...</style>
  const juiceOptions = {
    extraCss: styleToUse,
    preserveMediaQueries: false,
    preserveKeyFrames: false,
  };
  return juice(input, juiceOptions);
};

export const getCKEditorCssStyle = (): string => {
  return window.document.querySelector('style[data-cke=true]').textContent;
};

/**
 * Compute a color so that it is as close to white (#fff) as possible.
 *
 * @param {string} color - The color for which we calculate the factor
 * @param {number} darkenFactor - A fractional number that darkens the color. Default of 0 means it does not
 * darken at all.
 * @param {number} prevFactor - previous value of the factor (used internally for recursive search)
 * @param {number} factor - factor value to check if we reached white color (used internally for recursive search)
 * @param {number} maxFactor - the maximum factor value (used internally for recursive search)
 * @return {string} the color, as a hex string, that is closest to white, but adjusted using the darkenFactor
 */
export const computeLightestColor = (color, darkenFactor = 0, prevFactor = 0, factor = 50, maxFactor = 100) => {
  if (factor >= maxFactor) {
    return tinycolor(color)
      .lighten(prevFactor - prevFactor * darkenFactor)
      .toString('rgb');
  }
  if (tinycolor(color).lighten(factor).toString() === '#ffffff') {
    return computeLightestColor(color, darkenFactor, factor, Math.round(factor / 2), factor);
  }
  if (tinycolor(color).lighten(factor).toString() !== '#ffffff') {
    return computeLightestColor(color, darkenFactor, factor, factor + Math.round((maxFactor - factor) / 2), maxFactor);
  }
};
