// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export const LegendWrapper: React.FunctionComponent<{ title?: string }> = ({ title, children }) => {
  const { t } = useTranslation();
  return (
    !_.isEmpty(children) && (
      <div
        className="mb10 mlr25 flexColumnContainer flexWrap sqGrayBox plr10 ptb0 legend-wrapper"
        data-testid="legend-wrapper">
        {title && <strong className="mr10">{t(title)}:</strong>}
        {children}
      </div>
    )
  );
};
