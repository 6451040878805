// @ts-strict-ignore
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import _ from 'lodash';
import { TrendActions } from '@/trendData/trend.actions';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { ToolbarDropdownButton } from '@/hybrid/core/ToolbarDropdownButton.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { CapsuleTimeColorMode, TREND_VIEWS } from '@/trendData/trendData.constants';
import { doTrack } from '@/track/track.service';
import { sqTrendStore } from '@/core/core.stores';

const capsuleTimeColorModeButtonBindings = bindingsDefinition({
  isSmall: prop<boolean>(),
  sqTrendActions: injected<TrendActions>(),
});

export const CapsuleTimeColorModeButton: SeeqComponent<typeof capsuleTimeColorModeButtonBindings> = ({ isSmall }) => {
  const { sqTrendActions } = useInjectedBindings(capsuleTimeColorModeButtonBindings);
  const { t } = useTranslation();

  const view = useFluxPath(sqTrendStore, () => sqTrendStore.view);
  const capsuleTimeColorMode = useFluxPath(sqTrendStore, () => sqTrendStore.capsuleTimeColorMode);

  const setCapsuleTimeColorModeAndTrack = (mode: CapsuleTimeColorMode) => {
    doTrack('Trend', `${mode} Capsule Time Color`);
    sqTrendActions.setCapsuleTimeColorMode(mode);
  };

  const dropdownItems = _.map(CapsuleTimeColorMode, (mode) => (
    <Dropdown.Item key={mode} onClick={() => setCapsuleTimeColorModeAndTrack(mode)} data-testid={`color-mode-${mode}`}>
      <span className="flexColumnContainer flexFill flexAlignCenter">
        <Icon
          icon={mode === capsuleTimeColorMode ? 'fa-check-circle' : 'fa-circle-thin'}
          type="inherit"
          extraClassNames="fa-fw mr5"
        />
        <span>{t(`TOOLBAR.${mode.toUpperCase()}`)}</span>
      </span>
    </Dropdown.Item>
  ));

  return (
    view === TREND_VIEWS.CAPSULE && (
      <ToolbarDropdownButton
        icon="fa-tint"
        label="TOOLBAR.COLOR"
        isSmall={isSmall}
        dropdownItems={dropdownItems}
        tooltipText="TOOLBAR.COLOR_TOOLTIP"
        tooltipPlacement="top"
        id="toolbar-capsule-time-colors"
        testId="colorMenu"
      />
    )
  );
};
