import React from 'react';
import { Icon } from '@/hybrid/core/Icon.atom';

export const TableLoadingIndicator: React.FunctionComponent = () => {
  return (
    <div className="flexColumnContainer flexCenter pt50 pb50">
      <Icon icon="fa-spinner" extraClassNames="fa-pulse fa-5x" />
    </div>
  );
};
