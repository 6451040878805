// @ts-strict-ignore
import _ from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';
import DOMPurify from 'dompurify';
import { ScatterPlotSignal } from '@/scatterPlot/scatterPlot.constants';

export const DEFAULT_SCATTER_PLOT_SYMBOLS = ['circle', 'square', 'diamond', 'triangle', 'triangle-down'] as const;
type Symbol = typeof DEFAULT_SCATTER_PLOT_SYMBOLS[number];
const SCATTER_PLOT_SYMBOL_ICONS: { [key in Symbol]: string } = {
  'circle': 'fa fa-circle',
  'square': 'fc fc-square',
  'diamond': 'fc fc-diamond',
  'triangle': 'fc fc-triangle',
  'triangle-down': 'fc fc-triangle fa-flip-vertical',
};

/**
 * Figures out which yAxis to use for the given f(x) line.
 *
 * @param line - the f(x) line needing a yAxis value
 * @param ySignals - the list of ySignals
 * @returns - If the line's ySignalId is found in ySignals, then it uses the ySignal's index, as that corresponds to
 * the highcharts yAxis index, defaulting to 0 if the signal is not found (rightmost signal in chart). If ySignals
 * are empty, we return undefined, which prevents Highcharts error 18.
 */
function getYAxisForFxLine(line: { ySignalId: string; id: string }, ySignals: ScatterPlotSignal[]): number | undefined {
  if (ySignals.length === 0) {
    return undefined;
  }
  if (line.ySignalId) {
    const yAxisIndex = _.findIndex(ySignals, (ySignal) => ySignal.id === line.ySignalId);
    if (yAxisIndex > -1) {
      return yAxisIndex;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

const makeTrendSignalSelector = () =>
  createStructuredSelector({
    id: (item) => _.get(item, 'id', ''),
    name: (item) => _.get(item, 'name', ''),
    color: (item) => _.get(item, 'color', 'transparent'),
    valueUnitOfMeasure: (item) => _.get(item, 'valueUnitOfMeasure'),
    format: (item) => _.get(item, 'formatOptions.format'),
    symbol: (item) => _.get(item, 'symbol'),
  });

const makeTrendSignalsSelector = () =>
  createSelector(_.identity, (signals) => _.map(signals, (signal) => makeTrendSignalSelector()(signal)));

const scatterPlotSelector = createStructuredSelector({
  connect: (state: any) => state.connect,
  fxLines: (state: any) => state.fxLines,
  showTooltips: (state: any) => state.showTooltips,
  markerSize: (state: any) => state.markerSize,
  colorByItemColor: (state: any) => state.colorByItemColor,
});

const scatterDataSelector = createStructuredSelector({
  scatterData: (scatterData: any) => scatterData,
});

const getTextForAxis = (item) => [item.name, item.valueUnitOfMeasure ? ` (${item.valueUnitOfMeasure})` : ''].join('');
const getColorForAxis = (item) => (item.color ? item.color : 'transparent');

const getHTMLForAxis = (item: any, symbol: Symbol | undefined, useItemColor: boolean) => {
  const icon = symbol
    ? `<i class="${SCATTER_PLOT_SYMBOL_ICONS[symbol]} xyPlotIcon mr5 fa-fw ${
        !useItemColor && 'sq-force-text-gray'
      }"></i>`
    : '';
  const unitOfMeasure = item.valueUnitOfMeasure ? `(${DOMPurify.sanitize(item.valueUnitOfMeasure)})` : '';
  return `
      <span style="color: ${getColorForAxis(item)};">
        ${icon}
        <span>
          ${DOMPurify.sanitize(item.name)} ${unitOfMeasure}
        </span>
      </span>
    `;
};

/**
 * A selector that takes in the scatter plot state and the X and Y signals and produces Highcharts config object
 * that it can use to re-render.
 *
 * @param {Object} scatterPlotState - The state from the sqScatterPlotStore
 * @param {Object} xSignalState - The state from trendSignalSelector(xSignal)
 * @param {Object} ySignalState - The state from trendSignalSelector(ySignal)
 * @return {Function => Object} Highcharts config for the axes, the scatter plot series, and regression lines.
 */
export const getScatterPlotChartConfig = createSelector(
  createSelector(({ state }) => state, scatterPlotSelector),
  createSelector(({ scatterData }) => scatterData, scatterDataSelector),
  createSelector(({ xSignal }) => xSignal, makeTrendSignalSelector()),
  createSelector(
    ({ ySignals }) => ySignals,
    (ySignals) => makeTrendSignalsSelector()(ySignals),
  ),
  createSelector(
    ({ fxLines }) => fxLines,
    (fxLines) => fxLines,
  ),
  (scatterPlot, scatterData, xSignal, ySignals, fxLines): Highcharts.Options => ({
    xAxis: {
      id: xSignal.id,
      signalId: xSignal.id,
      title: {
        text: getTextForAxis(xSignal),
        style: { color: getColorForAxis(xSignal) },
      },
    },
    yAxis: _.map(ySignals, (ySignal, index) => ({
      id: ySignal.id,
      signalId: ySignal.id,
      // The default tick behavior (for the y-axis only) causes the axis to snap extremes to the nearest tick every
      // time you change it, which causes very weird behavior when panning and zooming on it
      startOnTick: false,
      endOnTick: false,
      lineWidth: index === 0 ? 1 : undefined,
      gridLineWidth: index === 0 ? 1 : undefined,
      title: {
        useHTML: true,
        text: getHTMLForAxis(
          ySignal,
          ySignals.length > 1 ? DEFAULT_SCATTER_PLOT_SYMBOLS[index % DEFAULT_SCATTER_PLOT_SYMBOLS.length] : undefined,
          scatterPlot.colorByItemColor,
        ),
        style: {
          // This fixes a highcharts issue where the rotation styles are not added in headless render mode
          // so the content in organizer was displaying with horizontal labels. Upgrade may fix it (CRAB-30063)
          transform: 'rotate(270deg)',
          color: getColorForAxis(ySignal),
        },
      },
    })),
    tooltip: {
      enabled: scatterPlot.showTooltips,
    },
    series: _.chain(scatterPlot.fxLines)
      .map((line) => ({
        ...line,
        type: 'line',
        lineWidth: 1,
        xNumberFormat: xSignal.format,
        yAxis: getYAxisForFxLine(line, ySignals),
      }))
      .concat(
        _.map(scatterData.scatterData, (data, index: number) => ({
          type: 'scatter',
          id: `scatterPlotChart_${index}`,
          data,
          lineWidth: scatterPlot.connect ? 1 : 0,
          marker: {
            symbol:
              ySignals[index]?.symbol ?? DEFAULT_SCATTER_PLOT_SYMBOLS[index % DEFAULT_SCATTER_PLOT_SYMBOLS.length],
            radius: scatterPlot.markerSize,
          },
          yAxis: index,
          xNumberFormat: xSignal.format,
          yNumberFormat: ySignals[index]?.format,
          xSignalName: xSignal.name,
          ySignalName: ySignals[index]?.name,
        })),
      )
      .reverse() // Highcharts expects first series to be the scatter one
      .value(),
  }),
);
