// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import heatmap from 'highcharts/modules/heatmap.src.js';
import { formatSimpleDuration } from '@/hybrid/datetime/dateTime.utilities';
import { getXYRegion, setScatterPlotExtremes } from '@/hybrid/utilities/chartHelper.utilities';
import {
  BIN_SIZE_MULTIPLIER,
  SeeqDensityPlotPoint,
  TIME_COLOR_STOPS_WITH_ZERO,
} from '@/scatterPlot/densityPlot.selectors';
import { XyPlotChartIdsInterface } from '@/hybrid/scatterPlot/XyPlotChartWrapper.organism';
import { formatNumber } from '@/hybrid/utilities/numberHelper.utilities';
import { useTranslation } from 'react-i18next';
import { XYPlotRegion } from '@/scatterPlot/scatterPlot.constants';

export interface DensityPlotChartProps {
  viewRegion: XYPlotRegion;
  setSelectedRegion: (region: XYPlotRegion) => void;
  setChart: (chart: Highcharts.Chart) => void;
  setOnMouseOverRef: (chartData: XyPlotChartIdsInterface) => void;
  onMouseOverRef: any;
}

export interface DensityPlotChartExternalProps
  extends Omit<DensityPlotChartProps, 'setChart' | 'onMouseOverRef' | 'setOnMouseOverRef'> {}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore Errors because we extend Highcharts.Options with a new series type
heatmap(Highcharts);

export const DensityPlotChart: React.FunctionComponent<DensityPlotChartProps> = ({
  viewRegion,
  setSelectedRegion,
  setChart,
  setOnMouseOverRef,
  onMouseOverRef,
}) => {
  const { t } = useTranslation();
  const durationLabel = t('DENSITY_PLOT.DURATION');

  const formatTooltip = (point: SeeqDensityPlotPoint) => {
    const seriesUserOptions = point.series.userOptions;

    const xRange = seriesUserOptions.colsize / BIN_SIZE_MULTIPLIER;
    const yRange = seriesUserOptions.rowsize / BIN_SIZE_MULTIPLIER;
    const xSignalName = seriesUserOptions.xSignalName;
    const ySignalName = seriesUserOptions.ySignalName;

    const xMinFormatted = formatNumber(point.x - xRange / 2, {
      format: seriesUserOptions.xNumberFormat,
    });
    const xMaxFormatted = formatNumber(point.x + xRange / 2, {
      format: seriesUserOptions.xNumberFormat,
    });
    const yMinFormatted = formatNumber(point.y - yRange / 2, {
      format: seriesUserOptions.yNumberFormat,
    });
    const yMaxFormatted = formatNumber(point.y + yRange / 2, {
      format: seriesUserOptions.yNumberFormat,
    });
    const tooltipFormatted =
      `${xSignalName}: <strong>${xMinFormatted} - ${xMaxFormatted}</strong><br/>` +
      `${ySignalName}: <strong>${yMinFormatted} - ${yMaxFormatted}</strong><br/>`;
    const timeFormatted = formatSimpleDuration(moment.duration(point.value));
    return `${tooltipFormatted}${durationLabel}: <strong>${timeFormatted}</strong>`;
  };

  /**
   * Callback method to be passed into the HighchartsReact object so we have access to the Highcharts Chart
   *
   * @param highchartsChart - the chart object from Highcharts
   */
  const afterChartCreated = (highchartsChart: Highcharts.Chart) => {
    setChart(highchartsChart);
    setOnMouseOverRef({ chart: highchartsChart });
  };

  const getInitialChartConfig = (): Highcharts.Options => ({
    chart: {
      animation: false,
      type: 'heatmap',
      zoomType: 'xy',
      alignTicks: false,
      events: {
        selection(e) {
          setSelectedRegion(getXYRegion(_.head(e.xAxis), e.yAxis));
          return false;
        },
        load() {
          setScatterPlotExtremes(_.head(this.xAxis), this.yAxis, viewRegion);
        },
      },
    },
    tooltip: {
      animation: false,
      hideDelay: 100,
      headerFormat: '',
      formatter() {
        const point = this.point as SeeqDensityPlotPoint;
        // turn off tooltips for null or 0 values
        if (_.isNil(point.value) || point.value === 0) {
          return false;
          // custom tooltip for the real values
        } else {
          return formatTooltip(point);
        }
      },
    },
    plotOptions: {
      series: {
        turboThreshold: 0,
        animation: false,
        states: {
          hover: {
            lineWidthPlus: 0,
          },
          inactive: {
            enabled: false,
          },
        },
        borderWidth: 0,
      },
      heatmap: {
        point: {
          events: { mouseOver: onMouseOverRef.current },
        },
        pointPadding: 0,
        minPadding: 0,
        maxPadding: 0,
        crisp: false,
      },
    },
    colorAxis: {
      startOnTick: false,
      endOnTick: false,
      type: 'linear',
      stops: TIME_COLOR_STOPS_WITH_ZERO,
      labels: {
        formatter() {
          return formatSimpleDuration(moment.duration(this.value));
        },
        rotation: 15,
      },
    },
    credits: { enabled: false },
    title: { text: null },
    xAxis: {
      title: {
        text: '',
      },
      lineWidth: 1,
      tickWidth: 1,
      maxPadding: 0,
      minPadding: 0,
    },
    yAxis: {
      title: {
        text: '',
      },
      // The default tick behavior (for the y-axis only) causes the axis to snap extremes to the nearest tick every
      // time you change it, which causes very weird behavior when panning and zooming on it
      startOnTick: false,
      endOnTick: false,
      lineWidth: 1,
      tickWidth: 1,
      gridLineWidth: 0,
      maxPadding: 0,
      minPadding: 0,
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      width: 100,
    },
  });

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={getInitialChartConfig()}
      // Don't let the chart auto-update because we have to handle updates in a custom way (see updateChart())
      allowChartUpdate={false}
      callback={afterChartCreated}
      containerProps={{ className: 'flexFill' }}
    />
  );
};
