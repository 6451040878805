import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { useTranslation } from 'react-i18next';
import { CAPSULE_MODES } from '@/hybrid/tools/histogram/histogram.constants';
import React from 'react';
import _ from 'lodash';

interface CapsuleModesContentProps {
  id: string;
  value: [string, string];
  onChange: (args: [string, string]) => void;
}

export const CapsuleModesContent: React.FC<CapsuleModesContentProps> = ({ id, value, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className="pt5 pb5 pr15 pl15">
      <span>{t('AGGREGATIONS.CAPSULE_MODE.DESCRIPTION_DETAIL')}</span>
      <table>
        <tbody>
          <tr>
            <td>{t('AGGREGATIONS.CAPSULE_MODE.CONDITION_INTRO')}</td>
            <td>
              <img src="/img/capsuleModeCondition.png" className="width-150" />
            </td>
          </tr>
          <tr>
            <td>{t('AGGREGATIONS.CAPSULE_MODE.DISPLAY_RANGE')} </td>
            <td>
              <img src="/img/capsuleModeDisplayRange.png" className="width-150" />
            </td>
          </tr>
          {_.map(CAPSULE_MODES, (mode) => (
            <tr key={mode.key}>
              <td className="radio pt5 mb0">
                <Checkbox
                  id={mode.key}
                  type="radio"
                  label={mode.display}
                  isChecked={value[0] === mode.key}
                  onChange={() => onChange([mode.key, id])}
                />
              </td>
              <td>
                <img src={`/img/${mode.image}`} className="width-150" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
