// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { Icon, IconType } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { HoverTooltip, HoverTooltipProps } from '@/hybrid/core/HoverTooltip.atom';

export type ButtonType = 'button' | 'reset' | 'submit' | 'link';
export type ButtonSize = 'sm' | 'lg';
export type ButtonVariant = 'outline' | 'theme' | 'warning' | 'danger' | 'no-border' | 'theme-light';

export interface TextButtonProps {
  /** function to call when clicking the button (takes no parameters) */
  onClick?: () => any;
  /** label translation key on the button (i.e. SUBMIT) */
  label?: string;
  /** the translation parameters to be used in the label translation */
  labelTranslationParameters?: object;
  /** style of button you want (outline is default, see type definition) */
  variant?: ButtonVariant;
  /** type of button (i.e button/submit/reset, default is button) */
  type?: ButtonType;
  /** size of the button (i.e. sm/lg, default is normal) */
  size?: ButtonSize;
  /** true if button should be disabled */
  disabled?: boolean;
  /** extra class names to be placed on the Icon component */
  extraClassNames?: string;
  /** icon class to be used if an icon should go before the text (i.e. fc-zoom) */
  icon?: string;
  /** icon color option (text, white, theme, color, default is "text") */
  iconStyle?: IconType;
  /** color of the icon if it is custom (required if iconStyle is "color") */
  iconColor?: string;
  /** id to place on the button element */
  id?: string;
  /** id that will be used in the data-testid attribute on the button element */
  testId?: string;
  /** If present, overrides label */
  formattedLabel?: JSX.Element | React.ReactNode;
  /** a tooltip for the button */
  tooltip?: string;
  /** options for the tooltip */
  tooltipOptions?: Omit<HoverTooltipProps, 'text'>;
  /** if false, will not stopPropagation onClick **/
  stopPropagation?: boolean;
}

/**
 * Generic button with text and/or icon
 */
export const TextButton: React.FunctionComponent<TextButtonProps> = ({
  onClick,
  label,
  labelTranslationParameters,
  variant = 'outline',
  type = 'button',
  iconStyle = 'text',
  size,
  disabled,
  extraClassNames,
  icon,
  iconColor,
  id,
  testId,
  formattedLabel,
  tooltip,
  tooltipOptions,
  stopPropagation = true,
}) => {
  const { t } = useTranslation();
  const button = (
    <Button
      className={classNames(extraClassNames, `sq-btn-${variant}`, type === 'link' ? 'sq-btn-link' : '')}
      id={id}
      type={type === 'link' ? 'button' : type}
      variant={type === 'link' ? 'link' : null}
      size={size}
      onClick={(e) => {
        stopPropagation && e.stopPropagation();
        onClick && onClick();
      }}
      disabled={disabled}
      bsPrefix={type !== 'link' ? 'sq-btn' : ''}
      data-testid={testId}>
      <div className="flexColumnContainer flexAlignCenter flexJustifyCenter">
        {icon && <Icon icon={icon} type={iconStyle} color={iconColor} extraClassNames={label ? 'mr5' : ''} />}
        {formattedLabel ? formattedLabel : t(label, labelTranslationParameters)}
      </div>
    </Button>
  );

  return tooltip ? (
    <HoverTooltip text={tooltip} {...tooltipOptions}>
      {button}
    </HoverTooltip>
  ) : (
    button
  );
};
