// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import classNames from 'classnames';
import { Icon } from '@/hybrid/core/Icon.atom';
import { canUseMemo } from '@/hybrid/formbuilder/formbuilder.utilities';

export interface ClickableLinkIF extends ValidatingFormComponent<string> {
  component: 'ClickableLinkFormComponent';
  linkTestId?: string;
  icon?: string;
  linkAction?: () => void;
}

const ClickableLinkFormComponentUnwrapped: React.FunctionComponent<ClickableLinkIF> = (props) => {
  const { value, linkAction, icon, extraClassNames = '', linkTestId = 'linkTestId' } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames(extraClassNames)}>
      <a href="#" onClick={linkAction} data-testid={linkTestId}>
        <Icon icon={icon} extraClassNames="mr5" />
        <span>{t(value)}</span>
      </a>
    </div>
  );
};
export const ClickableLinkFormComponent = React.memo(ClickableLinkFormComponentUnwrapped, canUseMemo);
