// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormControl, FormGroup } from 'react-bootstrap';
import {
  DailySchedule,
  DailyScheduleType,
} from '@/hybrid/reportConfig/components/dateRanges/autoUpdateModal/components/DailySchedule.atom';
import {
  WeeklySchedule,
  WeeklyScheduleData,
} from '@/hybrid/reportConfig/components/dateRanges/autoUpdateModal/components/WeeklySchedule.atom';
import {
  MonthlySchedule,
  MonthlyScheduleData,
} from '@/hybrid/reportConfig/components/dateRanges/autoUpdateModal/components/MonthlySchedule.atom';

export enum ScheduleTypeName {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  LIVE = 'live',
}

class ScheduleData {
  [ScheduleTypeName.DAILY]: DailyScheduleType;
  [ScheduleTypeName.WEEKLY]: WeeklyScheduleData;
  [ScheduleTypeName.MONTHLY]: MonthlyScheduleData;
}

export class ScheduleType {
  selectedType: ScheduleTypeName;
  data: ScheduleData;
}

interface ScheduleTypeBindings {
  scheduleType: ScheduleType;
  setScheduleType: (ScheduleType) => void;
}

const TRANSLATION_FOR_SCHEDULE_TYPE = {
  [ScheduleTypeName.DAILY]: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.DAILY.NAME',
  [ScheduleTypeName.WEEKLY]: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.WEEKLY.NAME',
  [ScheduleTypeName.MONTHLY]: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.MONTHLY.NAME',
  [ScheduleTypeName.LIVE]: 'REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.LIVE.NAME',
};

export const SelectScheduleType: React.FunctionComponent<ScheduleTypeBindings> = (props) => {
  const { scheduleType, setScheduleType } = props;
  const { selectedType, data } = scheduleType;
  const { t } = useTranslation();

  const selectType = (option: React.ChangeEvent<HTMLInputElement>) => {
    const selectedType = option.target.value.toLowerCase();
    setScheduleType({ ...scheduleType, selectedType });
  };
  const setDailySchedule = (dailyScheduleType) => {
    const nextScheduleType = { ...scheduleType };
    nextScheduleType.data[ScheduleTypeName.DAILY] = dailyScheduleType;
    setScheduleType(nextScheduleType);
  };
  const setWeeklySchedule = (daysOfTheWeek) => {
    const nextScheduleType = { ...scheduleType };
    nextScheduleType.data[ScheduleTypeName.WEEKLY] = daysOfTheWeek;
    setScheduleType(nextScheduleType);
  };
  const setMonthlySchedule = (monthlySchedule) => {
    const nextScheduleType = { ...scheduleType };
    nextScheduleType.data[ScheduleTypeName.MONTHLY] = monthlySchedule;
    setScheduleType(nextScheduleType);
  };

  return (
    <FormGroup>
      <div className="flexRowContainer">
        <label>{t('REPORT.MODAL.AUTO_UPDATE.SCHEDULE_TYPE.HOW_OFTEN')}</label>
        <div className="flexColumnContainer mb10">
          <div data-testid="select-schedule-type" className="flexNoShrink">
            <FormControl
              data-testid="select-schedule-type__type"
              as="select"
              value={selectedType}
              onChange={selectType}>
              {_.map(Object.values(ScheduleTypeName), (type) => (
                <option key={type} value={type}>
                  {t(TRANSLATION_FOR_SCHEDULE_TYPE[type])}
                </option>
              ))}
            </FormControl>
          </div>
          <div className="ml15 mt5 text-italic sq-darkish-gray">
            {selectedType === ScheduleTypeName.LIVE && t('REPORT.MODAL.AUTO_UPDATE.OCCURS_WHEN_OPENED')}
            {(selectedType === ScheduleTypeName.DAILY ||
              selectedType === ScheduleTypeName.WEEKLY ||
              selectedType === ScheduleTypeName.MONTHLY) &&
              t('REPORT.MODAL.AUTO_UPDATE.OCCURS_ANYTIME')}
          </div>
        </div>
        <div className="flexRowContainer">
          <div className="flexColumnContainer flexGrow-4 flexNoShrink">
            {selectedType === ScheduleTypeName.DAILY && (
              <DailySchedule selectedType={data[ScheduleTypeName.DAILY]} onSelect={setDailySchedule} />
            )}
            {selectedType === ScheduleTypeName.WEEKLY && (
              <WeeklySchedule days={data[ScheduleTypeName.WEEKLY]} setDays={setWeeklySchedule} />
            )}
            {selectedType === ScheduleTypeName.MONTHLY && (
              <MonthlySchedule
                monthlySchedule={data[ScheduleTypeName.MONTHLY]}
                setMonthlySchedule={setMonthlySchedule}
              />
            )}
          </div>
        </div>
      </div>
    </FormGroup>
  );
};
