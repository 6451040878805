// @ts-strict-ignore
import _ from 'lodash';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { FormulaService } from '@/services/formula.service';

/**
 * Contains utility function fetch and compute capsule regions representing where and how often capsules occur within a
 *   given range. The result can be used for visualization purposes, such as in the timebar or minimap.
 */

const opacity = { base: 0.2, add: 0.08, max: 0.75 };

interface CalculateParams {
  /** Condition whose Capsules to retrieve */
  item: any;
  /** displayRange or investigateRange from duration store */
  range: any;
  /** The duration of each bucket (ex: 600000ms) */
  bucketWidthArg: string;
  /** Identifier to use for canceling requests */
  cancellationGroup: string;
  sqFormula: FormulaService;
}

/**
 * Calculates a set of buckets representing counts of capsules within a specified range. Used to generate
 * regions for indicating where capsules occur for a given Condition.
 *
 * @return {Promise} A promise that resolves with the retrieved regions
 */
export function calculate({
  item,
  range,
  bucketWidthArg,
  cancellationGroup,
  sqFormula,
}: CalculateParams): Promise<any[]> {
  // calculate new regions for this item
  return sqFormula
    .computeCapsules({
      id: item.id,
      range,
      formula: `$series.bucketize(${bucketWidthArg}).parallelize()`,
      cancellationGroup,
    })
    .then(({ capsules }) => {
      return (
        _.chain(capsules)
          .map(function (capsule) {
            const count = _.get(_.find(capsule.properties, ['name', 'Count']), 'value', 0);
            return {
              capsuleSetId: item.id,
              start: capsule.start / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
              end: capsule.end / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND,
              color: item.color,
              count,
              opacity: Math.min(opacity.max, opacity.base + (count - 1) * opacity.add),
            };
          })
          .filter((capsule) => _.isFinite(capsule.start))
          .filter((capsule) => _.isFinite(capsule.end))
          // Compresses the number of regions returned from the backend. Bucketize will split a capsule and will
          // return hundreds of regions, the number of regions should be reduced before display to avoid a moiré
          // effect on the timebar and minimap components. This is done by merging regions that are end to end.
          .reduce((compressedCapsules, capsule, index, capsules) => {
            const nextCapsule = index === capsules.length - 1 ? null : capsules[index + 1];
            if (nextCapsule && capsule.count === nextCapsule.count && capsule.end === nextCapsule.start) {
              capsules[index + 1].start = capsule.start;
            } else {
              compressedCapsules.push(capsule);
            }
            return compressedCapsules;
          }, [])
          .value()
      );
    });
}
