// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import classNames from 'classnames';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Collapse, Form, Modal } from 'react-bootstrap';

import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ReportActions } from '@/reportEditor/report.actions';
import { ValueWithUnits } from '@/hybrid/trend/ValueWithUnits.atom';
import { DURATION_TIME_UNITS_ALL, KEY_CODES } from '@/main/app.constants';

import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { DisplayRangeSelector } from '@/hybrid/trend/DisplayRangeSelector.molecule';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { verifyUniqueName } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/ReportDateModal.utilities';
import { ReportDateModalAutoUpdate } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/components/ReportDateModalAutoUpdate.molecule';
import { ReportDateModalCreateFindCondition } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/components/ReportDateModalCreateFindCondition.molecule';
import { ReportDateModalConfigureCapsuleSelection } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/components/ReportDateModalConfigureCapsuleSelection.molecule';
import { AdvancedSection } from '@/hybrid/core/AdvancedParameters.atom';
import { sharedReportDateModalBindings } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/ReportDateModal.molecule';
import { useEffectOnceWhen } from 'rooks';
import { getNextName } from '@/hybrid/utilities/utilities';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { doTrack } from '@/track/track.service';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { sqReportStore, sqWorksheetStore } from '@/core/core.stores';
import { Item } from '@/hybrid/tools/histogram/aggregationBin.store';

const fullDateRangeModalBindings = bindingsDefinition({
  sqReportActions: injected<ReportActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  isNew: prop<boolean>(),
  onReset: prop<() => any>(),
  setDurationOffset: prop<(offset) => void>(),
  offsetOptions: prop<any>(),
  hasReportSchedule: prop<boolean>(),
  setIsSelectingRelative: prop<(isTrue) => void>(),
  onOffsetDirectionChange: prop<({ value }) => any>(),
  setCapsuleIsLoading: prop<(isLoading) => void>(),
  documentHasContent: prop<boolean>(),
  ...sharedReportDateModalBindings,
});

export const FullDateRangeModal: SeeqComponent<typeof fullDateRangeModalBindings> = ({
  onCloseModal,
  setNewDateRange,
  newDateRange,
  dateRangeRate,
  displayRange,
  hasMultipleSchedules,
  computedCapsuleDisplay,
  setDurationOffset,
  offsetOptions,
  hasReportSchedule,
  setSelectedCondition,
  isConditionSelected,
  isSelectingRelative,
  selectedCondition,
  setIsSelectingRelative,
  onOffsetDirectionChange,
  setCapsuleIsLoading,
  clearCapsuleDisplay,
  computedCapsuleProperties,
  capsuleIsLoading,
  capsuleCountData,
  updateDateRangeFromCapsule,
  isMaximumDurationRequiredForItem,
  updateSelectedCondition,
  onSave,
  canSave,
  onReset,
  documentHasContent,
  isNew,
}) => {
  const { sqReportActions } = useInjectedBindings(fullDateRangeModalBindings);
  const { t } = useTranslation();

  const [advancedSectionExpanded, setAdvancedSectionExpanded] = useState(false);
  const [isDateNameUnique, setIsDateNameUnique] = useState(true);
  const { timezone } = useFlux(sqWorksheetStore);

  useEffectOnceWhen(() => {
    if (!isNew) {
      setAdvancedSectionExpanded(true);
    }
  }, !!newDateRange?.condition?.id);

  useEffectOnceWhen(() => {
    setNewDateRange({
      name: getNextName(_.map(sqReportStore.dateRanges, 'name'), t('REPORT.CONFIG.DATE_RANGE')),
    });
  }, isNew);

  const onDateNameChange = (e) => {
    const { value } = e.currentTarget;
    setNewDateRange({
      name: value,
    });

    setIsDateNameUnique(verifyUniqueName(value, sqReportStore.dateRanges, newDateRange));
  };

  const onRefreshChange = (autoIsEnabled) => {
    setNewDateRange({
      auto: {
        enabled: autoIsEnabled,
      },
    });
  };

  const isConfiguredAutoUpdate = () => newDateRange?.auto?.enabled && hasReportSchedule;

  const onConditionChange = (option) => {
    if (option) {
      setSelectedCondition({
        id: option.id,
      });
    } else {
      setSelectedCondition({
        id: null,
        name: null,
      });
      setNewDateRange({
        condition: {
          id: null,
          isPeriodic: null,
        },
      });
    }
  };

  /**
   * Handler for user selection of either an existing condition or new periodic condition
   */
  const onConditionSelected = (item: Item) => {
    setCapsuleIsLoading(true);

    setNewDateRange({
      condition: {
        columns: undefined,
        sortAsc: undefined,
        sortBy: undefined,
        maximumDuration: null,
      },
    });

    clearCapsuleDisplay();
    updateSelectedCondition(item);
  };

  const onDelete = () => {
    sqReportActions
      .removeDateRange(newDateRange)
      .then(() => onCloseModal())
      .catch((error) => errorToast({ httpResponseOrError: error }));
    doTrack('Topic', 'Date Range Tool', 'cancelled');
  };

  const shouldShowAutoUpdateModal = newDateRange?.auto?.enabled && !hasReportSchedule;
  const shouldShowBulkEditModal = !newDateRange?.id && documentHasContent;
  const getSaveText = () => (shouldShowBulkEditModal || shouldShowAutoUpdateModal ? 'NEXT' : 'SAVE');

  const onClose = () => {
    onCloseModal();
    doTrack('Topic', 'Date Range Tool', 'cancelled');
  };

  return (
    <Modal className="reportDateModal" show={true} onHide={onCloseModal} animation={false}>
      <Modal.Header>
        <div
          className={classNames('mb0 flexColumnContainer flexCenter flexFill', {
            'has-error': !isDateNameUnique,
          })}>
          <Icon icon="fa-calendar" type="text" extraClassNames="fa-2x mt2 mr5 icon-default-color" />
          <Form.Group className="flexFillOverflow mb0">
            <Form.Control
              as="input"
              data-testid="specNameInput"
              autoComplete="false"
              onChange={onDateNameChange}
              required={true}
              placeholder={t('REPORT.CONFIG.ENTER_NAME')}
              defaultValue={newDateRange?.name}
              className="form-control input-lg"
            />
          </Form.Group>
          {!isDateNameUnique && <p className="help-block sq-text-danger">{t('FORM.DUPLICATE_NAME')}</p>}
          {!newDateRange?.name && <p className="help-block sq-text-danger">{t('FORM.REQUIRED_FIELD')}</p>}
        </div>
      </Modal.Header>
      <Modal.Body className="modal-body reportDateModal">
        {!!newDateRange?.irregularFormula && (
          <div>
            <div>{t('REPORT.MODAL.DATE_RANGE.IRREGULAR_FORMULA')}</div>
            <div className="flexColumnContainer">
              <pre className="flexFillOverflow aggressiveWordBreak text-pre-wrap p8">
                {newDateRange?.irregularFormula}
              </pre>
              <div>
                <TextButton
                  extraClassNames="ml8 mr8"
                  variant="danger"
                  onClick={() => onReset()}
                  label={t('REPORT.MODAL.DATE_RANGE.IRREGULAR_RESET')}
                />
              </div>
            </div>
            <div className="flexColumnContainer flexAlignStart mt10 mb0">
              <label className="mr10 p5">{t('REPORT.CONFIG.COMPUTED_DATE_RANGE')}</label>
              <div
                className="flexColumnContainer flexCenter width-300 alert lightGreyBorder
              backgroundColorOffWhite p5 mb0 positionRelative">
                {computedCapsuleDisplay}
              </div>
            </div>
          </div>
        )}

        {!newDateRange?.irregularFormula && (
          <div className="form-group">
            <div className="flexRowContainer">
              <div className="ml5 mb10 flexAlignLeft">
                <div className="form-check form-check-inline flexColumnContainer">
                  <Checkbox
                    id="dateRangeTypeFixed"
                    type="radio"
                    onChange={() => onRefreshChange(false)}
                    isChecked={!newDateRange?.auto?.enabled || false}
                    label={t('REPORT.MODAL.DATE_RANGE.FIXED')}
                    classes="form-check-input"
                    extraLabel={
                      <span className="ml5 small text-italic sq-darkish-gray">
                        {t('REPORT.MODAL.DATE_RANGE.FIXED_DESCRIPTION')}
                      </span>
                    }
                  />
                </div>
                <div className="form-check form-check-inline flexColumnContainer">
                  <Checkbox
                    id="dateRangeTypeAutoUpdate"
                    type="radio"
                    onChange={() => onRefreshChange(true)}
                    isChecked={newDateRange?.auto?.enabled || false}
                    label={t('REPORT.MODAL.DATE_RANGE.AUTO_UPDATE')}
                    classes="form-check-input"
                    extraLabel={
                      <span className="ml5 small text-italic sq-darkish-gray">
                        {t('REPORT.MODAL.DATE_RANGE.AUTO_UPDATE_DESCRIPTION')}
                      </span>
                    }
                  />
                </div>
              </div>
              <div className="ml10">
                {newDateRange?.auto?.enabled ? (
                  <div>
                    <ReportDateModalAutoUpdate
                      setNewDateRange={setNewDateRange}
                      hasMultipleSchedules={hasMultipleSchedules}
                      dateRangeRate={dateRangeRate}
                      newDateRange={newDateRange}
                      displayRange={displayRange}
                    />
                  </div>
                ) : (
                  <DisplayRangeSelector
                    displayRange={displayRange}
                    timezone={timezone}
                    trackAction="Date Range Tool"
                    trackCategory="Topic"
                    formMode={true}
                    investigateRangeCopyDisabled={true}
                    investigateRangeCopyNonExistent={true}
                    autoUpdateDisabled={true}
                    halfRangeButtonDisabled={true}
                    timeZoneEditingEnabled={false}
                    durationEditingEnabled={true}
                    rangeEditingEnabled={true}
                    inputExtraClassNames="min-width-20 p0"
                  />
                )}
              </div>
            </div>

            {/* <!-- Advanced section --> */}
            <div className="form-group ml15 mb0">
              <div
                className="flexColumnContainer flexSpaceBetween mt5 mb10 cursorPointer noOutline"
                tabIndex={0}
                data-testid="specAdvanceSectionButton"
                onClick={() => setAdvancedSectionExpanded(!advancedSectionExpanded)}
                onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && setAdvancedSectionExpanded(!advancedSectionExpanded)}>
                <AdvancedSection collapsed={!advancedSectionExpanded} t={t} />
              </div>

              {/* <!-- Offset --> */}
              <Collapse in={advancedSectionExpanded}>
                <div id="conditionEditor" className="ml20 mr10">
                  {newDateRange?.auto?.enabled && (
                    <div className="mb15">
                      <div className="flexColumnContainer flexAlignStart">
                        <label className="mb0">{t('OFFSET')}</label>
                        <div className="small text-italic sq-darkish-gray ml5">
                          <p>{t('REPORT.CONFIG.OFFSET_DESCRIPTION')}</p>
                        </div>
                      </div>
                      <div className="flexColumnContainer">
                        <ValueWithUnits
                          onChange={setDurationOffset}
                          insideModal={true}
                          min={0}
                          propName="durationOffset"
                          availableUnits={DURATION_TIME_UNITS_ALL}
                          required={true}
                          defaultValue={newDateRange.auto.offset}
                        />
                        <Select
                          options={offsetOptions}
                          classNamePrefix="spec_reportDateModal_offset react-select"
                          isSearchable={false}
                          value={_.find(offsetOptions, {
                            value: newDateRange.auto.offsetDirection,
                          })}
                          className="width-115 height-32 mt5"
                          onChange={onOffsetDirectionChange}
                        />
                      </div>
                    </div>
                  )}

                  <ReportDateModalCreateFindCondition
                    newDateRange={newDateRange}
                    isSelectingRelative={isSelectingRelative}
                    selectedCondition={selectedCondition}
                    isConditionSelected={isConditionSelected}
                    onConditionChange={onConditionChange}
                    setIsSelectingRelative={setIsSelectingRelative}
                    onConditionSelected={onConditionSelected}
                  />

                  {isConditionSelected && (
                    <ReportDateModalConfigureCapsuleSelection
                      newDateRange={newDateRange}
                      isSelectingRelative={isSelectingRelative}
                      isMaximumDurationRequiredForItem={isMaximumDurationRequiredForItem}
                      selectedCondition={selectedCondition}
                      setSelectedCondition={setSelectedCondition}
                      isConditionSelected={isConditionSelected}
                      setNewDateRange={setNewDateRange}
                      capsuleCountData={capsuleCountData}
                      updateDateRangeFromCapsule={updateDateRangeFromCapsule}
                      capsuleIsLoading={capsuleIsLoading}
                      computedCapsuleDisplay={computedCapsuleDisplay}
                      computedCapsuleProperties={computedCapsuleProperties}
                    />
                  )}
                </div>
              </Collapse>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isNew && (!!newDateRange?.id || !!newDateRange?.condition?.id) && (
          <TextButton testId="deleteButton" extraClassNames="mr15" variant="danger" onClick={onDelete} label="DELETE" />
        )}
        <div className="flexFill" />
        {shouldShowBulkEditModal && (
          <div className="text-right pr10 text-italic">{t('REPORT.MODAL.DATE_RANGE.ASSIGN_TO_CONTENT')}</div>
        )}
        {!shouldShowBulkEditModal && shouldShowAutoUpdateModal && (
          <div className="text-right pr10 text-italic">{t('REPORT.MODAL.DATE_RANGE.AUTO_UPDATE_NOT_CONFIGURED')}</div>
        )}
        <TextButton testId="cancelButton" extraClassNames="btn btn-default mr15" onClick={onClose} label="CANCEL" />
        <TextButton
          label={getSaveText()}
          testId="saveOrNextButton"
          extraClassNames="ml15"
          variant="theme"
          onClick={canSave() && onSave}
          disabled={!canSave()}
          icon={isConfiguredAutoUpdate() ? 'fc-arrow-right-right' : ''}
          iconStyle="inherit"
          tooltip={isConfiguredAutoUpdate() ? 'REPORT.CONFIG.SAVE_STEP_ALL_AUTO_TO_END' : ''}
          tooltipOptions={{ placement: 'top' }}
        />
      </Modal.Footer>
    </Modal>
  );
};
