// @ts-strict-ignore
import React, { useCallback } from 'react';
import _ from 'lodash';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import SelectItem from '@/hybrid/core/SelectItem.organism';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { ScatterPlotSignal } from '@/scatterPlot/scatterPlot.constants';
import { doTrack } from '@/track/track.service';

const scatterPlotSignalDropdownBindings = bindingsDefinition({
  xSignal: prop<ScatterPlotSignal>(),
  ySignals: prop<ScatterPlotSignal[]>(),
  setSignals: prop<({ xSignal, ySignals }: { xSignal: ScatterPlotSignal; ySignals: ScatterPlotSignal[] }) => void>(),
  isScatterPlotView: prop<boolean>(),
});

/**
 * A popover to select y signals and an x signal for scatter plot.
 */
export const ScatterPlotSignalDropdown: SeeqComponent<typeof scatterPlotSignalDropdownBindings> = ({
  ySignals,
  xSignal,
  setSignals,
  isScatterPlotView,
}) => {
  const { t } = useTranslation();

  const updateXSignal = useCallback(
    (newXSignal: ScatterPlotSignal) => {
      doTrack('Scatterplot', 'X-Axis Signal selected (dropdown)');

      // If the new signal was in the y signal list, add the previous x signal to the y signals
      const ySignalToAdd = _.includes(_.map(ySignals, 'id'), newXSignal.id) && xSignal ? xSignal : [];
      setSignals({
        xSignal: newXSignal,
        ySignals: _.chain(ySignals).reject({ id: newXSignal.id }).concat(ySignalToAdd).value(),
      });
    },
    [ySignals, xSignal?.id],
  );

  const selectItemForYSignal = (signalId: string | undefined, index: number) => (
    <div className="width-95percent mt3 flexAlignStart" key={`${signalId}_${index}`}>
      <SelectItem
        selectedItemId={signalId}
        itemTypes={[ITEM_TYPES.SERIES]}
        excludeStringSignals={true}
        excludeWorkbookItems={true}
        disableAutoSelect={true}
        allowClear={index > 0}
        showAddToDisplayPane={false}
        excludedIds={_.filter(_.map(ySignals, 'id'), (id) => id !== signalId)}
        insideModal={true}
        dataTestId={`YSignalDropdown_${index}`}
        extraClassNames={`ySignalDropdown_${index}`}
        onSelect={(newYSignal?: ScatterPlotSignal) => {
          if (newYSignal) {
            doTrack('Scatterplot', 'Y-Axis Signal selected (dropdown)');
            setSignals({
              xSignal: newYSignal.id === xSignal?.id ? _.find(ySignals, { id: signalId }) : xSignal,
              ySignals: _.chain(ySignals).reject({ id: signalId }).concat(newYSignal).uniq().value(),
            });
          } else {
            doTrack('Scatterplot', 'Signal removed (dropdown)');
            setSignals({
              xSignal: signalId === xSignal?.id ? undefined : xSignal,
              ySignals: _.reject(ySignals, { id: signalId }),
            });
          }
        }}
      />
    </div>
  );

  return (
    <div className="flexRowContainer width-350">
      {isScatterPlotView ? (
        <>
          <strong>{t('SCATTER.Y_AXIS_SIGNALS')}</strong>
          {/* Adding undefined to the ySignals array gives us the empty select for adding new y axis signals */}
          {_.map(_.concat(ySignals, undefined), (signal, index) => selectItemForYSignal(signal?.id, index))}
        </>
      ) : (
        <>
          <strong>{t('SCATTER.Y_AXIS_SERIES')}</strong>
          {selectItemForYSignal(ySignals[0]?.id, 0)}
        </>
      )}

      <strong className="flexCenter mt10">{t('SCATTER.X_AXIS_SERIES')}</strong>
      <div className="flexAlignStart mt3 width-95percent">
        {/* By mapping this with a key, we can force re-renders when the key changes, as SelectItem does not display an
       empty input if it moves from having a value to having undefined. */}
        {_.map([0], (index) => (
          <SelectItem
            selectedItemId={xSignal?.id}
            key={`${xSignal?.id}_${_.join(_.map(ySignals, 'id'), '-')}_${index}`}
            itemTypes={[ITEM_TYPES.SERIES]}
            excludeStringSignals={true}
            excludeWorkbookItems={true}
            disableAutoSelect={true}
            showAddToDisplayPane={false}
            insideModal={true}
            dataTestId="xSignalDropdown"
            extraClassNames="xSignalDropdown"
            onSelect={updateXSignal}
          />
        ))}
      </div>
    </div>
  );
};
