// @ts-strict-ignore
import React, { useEffect } from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { Chart } from '@/hybrid/tableBuilder/tableViewer/Chart.molecule';
import { ChartSettingsInterface } from './ChartSettings.molecule';
import _ from 'lodash';
import { dataToTable, getCategoryName } from '@/hybrid/tableBuilder/tableViewer/ChartUtilities';

const tableViewerBindings = bindingsDefinition({
  data: prop<any[]>(),
  columns: prop<any[]>(),
  isTransposed: prop<boolean>(),
  storeSettings: prop<any>(),
  testId: prop<string>(),
});

const transpose = (matrix) => matrix[0].map((x, i) => matrix.map((x) => x[i]));

const convertToNumber = (stringToBeParsed) => {
  if (!_.isNaN(_.toNumber(stringToBeParsed))) {
    return _.toNumber(stringToBeParsed);
  }
  if (stringToBeParsed[stringToBeParsed.length - 1] === '%') {
    return _.toNumber(stringToBeParsed.substr(0, stringToBeParsed.length - 1));
  }
};

const filterTableData = (data: any[][], settings: ChartSettingsInterface, isTransposed: boolean): any[][] | null => {
  const filterRows = !isTransposed ? settings.rows : settings.columns;
  const filterColumns = !isTransposed ? settings.columns : settings.rows;

  return _.chain(data)
    .map((row, rowIndex) =>
      _.map(row, (cell, columnIndex) => {
        if (rowIndex === 0) return cell;
        return columnIndex === 0
          ? getCategoryName(data, rowIndex, settings.categoryColumns) || ''
          : { value: convertToNumber(cell.value), uom: cell.uom || '' };
      }),
    )
    .filter((row, rowIndex) => rowIndex === 0 || _.includes(filterRows, rowIndex) || _.isEmpty(filterRows))
    .map((row) =>
      _.filter(
        row,
        (cell, columnIndex) => columnIndex === 0 || _.includes(filterColumns, columnIndex) || _.isEmpty(filterColumns),
      ),
    )
    .value();
};

export const TableViewer: SeeqComponent<typeof tableViewerBindings> = ({
  data,
  columns,
  isTransposed,
  storeSettings,
  testId,
}) => {
  if (!data || _.isEmpty(data)) {
    return <div />;
  }

  const { t } = useTranslation();
  const tableData = dataToTable(data, columns, t);
  let filteredData = filterTableData(tableData, storeSettings, isTransposed);
  const { position, showSettings, ...settingsForChart } = storeSettings;

  if (isTransposed) {
    if (filteredData) {
      filteredData = transpose(filteredData);
    }
  }

  return <Chart tableData={filteredData} settings={settingsForChart} testId={testId} />;
};
