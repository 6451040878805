// @ts-strict-ignore
import _ from 'lodash';
import {
  DASH_STYLES,
  ITEM_DATA_STATUS,
  ITEM_TYPES,
  SAMPLE_OPTIONS,
  Y_AXIS_TYPES,
} from '@/trendData/trendData.constants';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { BaseItemStore } from './baseItem.store';
import { PROPS_TO_DEHYDRATE } from './baseItem.constants';

export class TrendMetricStore extends BaseItemStore {
  static readonly storeName = 'sqTrendMetricStore';

  dehydrate() {
    return {
      items: _.chain(this.state.get('items'))
        .filter(super.shouldDehydrateItem)
        .map((item) => _.pick(item, PROPS_TO_DEHYDRATE))
        .value(),
    };
  }

  rehydrate(dehydratedState) {
    this.state.merge(_.omit(dehydratedState, ['items']));
    this.state.set(
      'items',
      _.map(dehydratedState.items, (item) =>
        this.createMetric(item.id, item.name, item.lane, item.alignment, _.omit(item, ['id', 'name'])),
      ),
    );
  }

  protected readonly handlers = {
    ...super.baseHandlers,
    TREND_ADD_METRIC: (payload) => {
      this.state.push(
        'items',
        this.createMetric(payload.id, payload.name, payload.lane, payload.alignment, _.pick(payload, ['color'])),
      );
    },
  };

  /**
   * Adds a Metric item to the specified panel.
   *
   * @param payload - Object container
   * @param payload.id- The id of the metric
   * @param payload.name - The name of the metric
   * @param payload.lane - Lane of the new item
   * @param payload.alignment - Alignment of the new item
   * @param [payload.color] - optional color.
   */
  addMetric(payload: { id: string; name: string; lane: string; alignment: string; color: [] }) {
    this.state.push(
      'items',
      this.createMetric(payload.id, payload.name, payload.lane, payload.alignment, _.pick(payload, ['color'])),
    );
  }

  /**
   * Creates a metric item.
   *
   * @param id - The id of the metric
   * @param name - The name of the metric
   * @param lane - Lane to use for the new series
   * @param alignment - Alignment to use for the new series
   * @param props[] - Array of properties to be set on the item.
   * @return {Object} an table item.
   */
  createMetric(id: string, name: string, lane: string, alignment: string, props) {
    return this.createItem(
      id,
      name,
      ITEM_TYPES.METRIC,
      _.assign(
        {
          calculationType: TREND_TOOLS.THRESHOLD_METRIC,
          yAxisConfig: {},
          dashStyle: _.get(props, 'dashStyle', DASH_STYLES.SOLID),
          lane,
          axisAlign: alignment,
          axisVisibility: _.get(props, 'axisVisibility', true),
          axisAutoScale: _.get(props, 'axisAutoScale', true),
          lineWidth: _.get(props, 'lineWidth', 1),
          sampleDisplayOption: props.sampleDisplayOption || SAMPLE_OPTIONS.LINE,
          dataStatus: ITEM_DATA_STATUS.INITIALIZING,
          yAxisType: Y_AXIS_TYPES.LINEAR,
          data: [],
          statusMessage: '',
        },
        props,
      ),
    );
  }
}
