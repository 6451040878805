// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { sqLicenseManagementStore, sqWorkbenchStore } from '@/core/core.stores';
import { fetchLicense } from '@/licenseManagement/licenseManagement.actions';
import { useTranslation } from 'react-i18next';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { LicenseUpload } from '@/hybrid/licenseManagement/LicenseUpload';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { EmailLicense } from '@/hybrid/licenseManagement/EmailLicense';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.constants.ts';
import { LoadingFallback } from '@/hybrid/main/LoadingFallback.atom';

const sqLicenseBindings = bindingsDefinition({});

/**
 * Handles license management.
 */
const WrappedLicense: SeeqComponent<typeof sqLicenseBindings> = () => {
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);
  const { licenseStatus, isTrial } = useFlux(sqLicenseManagementStore);

  const { t } = useTranslation();

  const [showSuccess, setShowSuccess] = useState(false);

  /**
   * Determines if the upload or the email text is displayed first. The display changes based on the license status.
   * (NONE, and UNKNOWN will show the email first, all others will show the upload first)
   *
   * @constant {Boolean} true if the upload component should be shown first, false otherwise.
   */
  const showUploadFirst = licenseStatus !== LICENSE_STATUS.NONE && licenseStatus !== LICENSE_STATUS.UNKNOWN;

  useEffect(() => {
    fetchLicense();
  }, []);

  /**
   * Finds the appropriate translation key for the current license status.
   *
   * @returns {String} the translation key to use for the translation.
   */
  const headerTranslationKey = () => {
    if (!currentUser.isAdmin) {
      if (licenseStatus === LICENSE_STATUS.NONE) {
        return 'LICENSE.THANKS_FOR_INTEREST';
      } else if (licenseStatus === LICENSE_STATUS.EXPIRED) {
        return isTrial ? 'LICENSE.USER_EXPIRED_WARNING_TRIAL' : 'LICENSE.USER_EXPIRED_WARNING';
      }
    } else {
      if (licenseStatus === LICENSE_STATUS.NONE) {
        return 'LICENSE.THANKS_FOR_INSTALLING';
      } else {
        if (licenseStatus === LICENSE_STATUS.UNKNOWN) {
          return 'LICENSE.PROBLEM_HEADER';
        }

        if (isTrial) {
          return 'LICENSE.THANKS_FOR_TRYING';
        } else {
          return 'LICENSE.THANKS_FOR_USING';
        }
      }
    }
  };

  if (showSuccess) {
    return (
      <div className="flexRowContainer flexCenter fullViewport license-management">
        <div className="text-center flexRowContainer section">
          <div>
            <img src="/img/Seeq_logo_darkBlue_sm.png" />
          </div>
          <span className="title mb10">{t('LICENSE.UPLOAD_SUCCESS')}</span>
          <LicenseUpload showSuccess={true} setShowSuccess={setShowSuccess} />
        </div>
      </div>
    );
  }

  return (
    <div className="fullWidth flexAlignCenter flexRowContainer license-management">
      <div className="text-center license-header">
        <div>
          <img src="/img/Seeq_logo_darkBlue_sm.png" />
        </div>
        <span className="title mb10">{t(headerTranslationKey())}</span>
      </div>

      {showUploadFirst && (
        <>
          <LicenseUpload showSuccess={showSuccess} setShowSuccess={setShowSuccess} />
          <EmailLicense />
        </>
      )}

      {!showUploadFirst && (
        <>
          <EmailLicense />
          <LicenseUpload showSuccess={showSuccess} setShowSuccess={setShowSuccess} />
        </>
      )}
    </div>
  );
};

export const License: SeeqComponent<typeof sqLicenseBindings> = () => {
  return (
    <React.Suspense fallback={<LoadingFallback fallbackLocation="License page" />}>
      <WrappedLicense />
    </React.Suspense>
  );
};

export const sqLicenseManagement = angularComponent(sqLicenseBindings, License);
