// @ts-strict-ignore
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useTranslation } from 'react-i18next';
import { IdentityPreviewV1, sqUsersApi } from '@/sdk';
import { Icon } from '@/hybrid/core/Icon.atom';
import { isAdmin } from '@/services/authorization.service';

const selectIdentityBindings = bindingsDefinition({
  idForLabel: prop.optional<string>(),
  setIdentity: prop<(object: any) => void>(),
  identity: prop<Partial<IdentityPreviewV1>>() || prop<Partial<IdentityPreviewV1>[]>(),
  allowGroups: prop.optional<boolean>(),
  includeAllProperties: prop.optional<boolean>(),
  startEditable: prop.optional<boolean>(),
  prefillIdentity: prop.optional<boolean>(),
  placeholder: prop.optional<string>(),
  tooltip: prop.optional<string>(),
  isInvalid: prop.optional<boolean>(),
  autoFill: prop.optional<boolean>(),
  unauthorizedTooltip: prop.optional<string>(),
  multiple: prop.optional<boolean>(),
  clearIdentityWhenEmpty: prop.optional<boolean>(),
});

/** Input field for searching and selecting Seeq Identities (Users or Groups) */
export const SelectIdentity: SeeqComponent<typeof selectIdentityBindings> = (props) => {
  const {
    setIdentity,
    identity,
    prefillIdentity = false,
    placeholder,
    tooltip,
    unauthorizedTooltip,
    idForLabel,
    allowGroups = false,
    includeAllProperties = false,
    startEditable = false,
    multiple = false,
    isInvalid,
    clearIdentityWhenEmpty = false,
    autoFill = true,
  } = props;
  const { t } = useTranslation();

  const [loadingIdentities, setLoadingIdentities] = useState(false);
  const [options, setOptions] = useState([]);
  const [editingIdentity, setEditingIdentity] = useState(startEditable);
  const [typeahead, setTypeahead] = useState(null);
  const [showPreselectedIdentity, setShowPreselectedIdentity] = useState(true);

  useEffect(() => {
    setShowPreselectedIdentity(false);
  }, []);

  useEffect(() => {
    if (_.isEmpty(identity) && clearIdentityWhenEmpty && !loadingIdentities) {
      typeahead?.getInstance().clear();
    }
  }, [identity]);

  const renderMenu = (option, props) => (
    <div>
      {option.type === 'User' && <Icon icon="fa-user" extraClassNames="width-18" type="text" />}
      {option.type === 'UserGroup' && <Icon icon="fa-users" extraClassNames="width-18" type="text" />}
      <Highlighter search={props.text}>{option.name}</Highlighter>
      {option.type === 'User' && (
        <div>
          <small>{option.email || option.username}</small>
        </div>
      )}
    </div>
  );

  const handleIdentitiesSearch = (query) => {
    setLoadingIdentities(true);
    setIdentity(multiple ? [] : {});
    const toArrayIfMultiple = (object) => (multiple ? [object] : object);

    return (
      sqUsersApi
        .autocompleteUsersAndGroups({
          query,
        })
        // Filter out user groups
        .then((response) => {
          const items = allowGroups ? response.data.items : _.filter(response.data.items, { type: 'User' });
          const filtered = includeAllProperties
            ? items
            : _.map(items, (item) => _.pick(item, ['id', 'name', 'username', 'type', 'email']));
          setOptions(filtered as any);
          if (filtered.length === 1 && autoFill) {
            setIdentity(toArrayIfMultiple(filtered[0]));
          }
          setLoadingIdentities(false);
        })
    );
  };

  const onInputChange = (selected) =>
    multiple ? setIdentity(selected) : setIdentity(selected.length === 1 ? _.first(selected) : {});

  const editIdentity = () => {
    setEditingIdentity(true);
    setIdentity({});
  };

  const renderIdentity = (option) => {
    if (option?.email) {
      return `${option.name} (${option.email})`;
    } else if (option?.username) {
      return `${option.name} (${option.username})`;
    } else {
      return option?.name;
    }
  };

  const renderEditable = (
    <div className="input-group width-maximum" data-testid={`selectIdentityInput_${idForLabel}`}>
      <AsyncTypeahead
        id={idForLabel}
        ref={setTypeahead}
        labelKey={(option) => renderIdentity(option)}
        allowNew={false}
        multiple={multiple}
        options={options}
        isLoading={loadingIdentities}
        onSearch={handleIdentitiesSearch}
        onChange={onInputChange}
        renderMenuItemChildren={renderMenu}
        placeholder={t(placeholder)}
        selected={showPreselectedIdentity && prefillIdentity ? identity : undefined}
        isInvalid={isInvalid}
      />
    </div>
  );

  const renderDisplayMode = (
    <div className="input-group width-maximum" data-testid={`selectIdentityViewMode_${idForLabel}`}>
      <input id={idForLabel} className="form-control" type="text" disabled={true} value={renderIdentity(identity)} />
      <span
        className="sq-btn input-group-btn input-group-text cursorPointer fs15"
        data-testid={`selectIdentityEditIdentity_${idForLabel}`}
        onClick={editIdentity}>
        <Icon icon="fc-edit" type="text" tooltip={tooltip} />
      </span>
    </div>
  );

  const renderViewOnly = (
    <div className="input-group width-maximum" data-testid={`selectIdentityViewMode_${idForLabel}`}>
      <HoverTooltip text={unauthorizedTooltip} placement="top">
        <input id={idForLabel} className="form-control" type="text" disabled={true} value={renderIdentity(identity)} />
      </HoverTooltip>
    </div>
  );

  if (editingIdentity) {
    return renderEditable;
  } else if (isAdmin()) {
    return renderDisplayMode;
  } else {
    return renderViewOnly;
  }
};

export default SelectIdentity;
