// @ts-strict-ignore
import bind from 'class-autobind-decorator';
import _ from 'lodash';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { FlameChartOptions } from 'other_components/highcharts';
import tinygradient from 'tinygradient';
import tinycolor from 'tinycolor2';
import moment from 'moment-timezone';
import { FoldedStackNodeV1 } from '@/sdk';
import { base64guid } from '@/hybrid/utilities/utilities';

@bind
export class RequestsTabUtilities {
  constructor() {}

  getAggregatedRequestDetails(requests) {
    return _.chain(requests)
      .map((request) => _.get(_.last(request.activities), 'properties'))
      .filter(
        (props) => props && props[SeeqNames.Properties.DatasourceClass] && props[SeeqNames.Properties.DatasourceId],
      )
      .countBy((props) => {
        /*
         * Collection.countBy(Iteratee) creates an object composed of keys generated from the results of
         * running each element of collection thru iteratee. In this case, iteratee contains an array of
         * properties. By overriding the standard toString() function of the countAndKeys array, we cause
         * countBy to return a key that contains custom formatting. This formatting is propagated to the
         * frontend.
         */
        const countAndKey = [props[SeeqNames.Properties.DatasourceClass], props[SeeqNames.Properties.DatasourceId]];
        (countAndKey as String[]).toString = () =>
          `${props[SeeqNames.Properties.DatasourceName]} (${countAndKey[0]}, ${countAndKey[1]})`;
        return countAndKey;
      })
      .map((count, key) => [count, key])
      .sortBy((countAndKey) => -countAndKey[0])
      .take(10)
      .value();
  }

  formatRequests(requests) {
    _.forEach(requests, (request) => {
      _.set(request, 'methodAndUri', this.getMethodAndUri(request));
      _.set(request, 'status', _.get(_.last(request?.activities), 'description'));
      _.set(request, 'duration', this.getDuration(request));
      _.set(request, 'percentProgress', this.getPercentProgress(request));
      _.set(request, 'id', base64guid()); // needed to handle selection in the table
      _.set(request, 'userFullName', _.head(request?.parameters?.['user name']));
    });

    return requests;
  }

  getMethodAndUri(request) {
    const { requestMethod, requestPath } = request;
    return _.chain([requestMethod || '', requestPath || ''])
      .filter((item) => !_.isEmpty(item))
      .join(' ')
      .value();
  }

  getDuration(request) {
    return _.round(
      request.runDuration / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND / NUMBER_CONVERSIONS.MILLISECONDS_PER_SECOND,
      1,
    );
  }

  getPercentProgress(request) {
    return request.progress.totalCount === 0
      ? 0
      : Math.round((request.progress.completedCount / request.progress.totalCount) * 100);
  }
}

const getIntervalDuration = (stack: FoldedStackNodeV1): number =>
  stack.intervalStart && stack.intervalEnd
    ? moment.utc(stack.intervalEnd).valueOf() - moment.utc(stack.intervalStart).valueOf()
    : 0;

export type ActivityProperties = Omit<FoldedStackNodeV1, 'children'> & {
  percentValue: number;
  intervalColor: string;
  intervalTextColor: string;
  intervalDays: number;
};
type FlamePoint = Highcharts.Point & { low: number; high: number; custom: ActivityProperties };
export const getHighchartsFlamegraph = (
  foldedStack: FoldedStackNodeV1,
  [yAxisMin, yAxisMax],
  setActivityProperties: (props: ActivityProperties | undefined) => void,
  setYExtremes: (props: [number, number]) => void,
): FlameChartOptions => {
  // Corresponds to the number of days of interval expansion in relation to the parent interval. 30 days chosen as
  // the highest limit at which to show the darkest color.
  const GRADIENT_STOPS = 30;
  // Backend samples the activities at 10 per second
  const SAMPLES_PER_SECOND = 10;
  const gradient = tinygradient(['#ffecb5', '#eb0c16']).rgb(GRADIENT_STOPS);
  const root = {
    stack: foldedStack,
    x: 2,
    low: 0,
    parentIntervalDuration: getIntervalDuration(foldedStack),
  };
  const queue = [root];
  const data = [];
  let id = 1;
  while (queue.length) {
    const node = queue.shift();
    const intervalDuration = getIntervalDuration(node.stack);
    const intervalDays = Math.round(moment.duration(intervalDuration - node.parentIntervalDuration, 0).asDays());
    const intervalColor = gradient[Math.min(GRADIENT_STOPS - 1, Math.max(intervalDays, 0))].toString();
    const intervalTextColor = tinycolor(intervalColor).isLight() ? '#000' : '#fff';
    const custom: ActivityProperties = {
      ..._.pick(node.stack, ['name', 'intervalStart', 'intervalEnd', 'properties']),
      value: _.round(node.stack.value / SAMPLES_PER_SECOND, 1),
      intervalDays,
      intervalColor,
      intervalTextColor,
      percentValue: _.round((node.stack.value / root.stack.value) * 100, 1),
    };
    data.push({
      name: node.stack.name,
      id: _.toString(id++),
      value: node.stack.value,
      low: node.low,
      high: node.low + node.stack.value,
      x: node.x * -1,
      color: intervalColor,
      custom,
    });
    let childLow = node.low;
    queue.push(
      ..._.chain(node.stack.children)
        .sortBy('name')
        .map((child) => {
          const childNode = {
            stack: child,
            x: node.x + 1,
            low: childLow,
            parentIntervalDuration: intervalDuration,
          };
          childLow += child.value;
          return childNode;
        })
        .value(),
    );
  }

  return {
    chart: {
      inverted: true,
      animation: false,
    },
    plotOptions: {
      series: {
        animation: false,
        tooltip: {
          headerFormat: '',
          pointFormat: '<b>{point.custom.value}s ({point.custom.percentValue}%)</b> {point.name}',
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: [
      {
        visible: false,
      },
      {
        visible: false,
        startOnTick: false,
        endOnTick: false,
        minPadding: 0,
        maxPadding: 0,
      },
    ],
    yAxis: [
      {
        visible: false,
      },
      {
        visible: false,
        min: yAxisMin,
        max: yAxisMax,
        maxPadding: 0,
        startOnTick: false,
        endOnTick: false,
      },
    ],
    series: [
      {
        type: 'flame',
        data: data.reverse(),
        yAxis: 1,
        xAxis: 1,
        dataLabels: {
          format: undefined,
          formatter() {
            const point = this.point as FlamePoint;
            return `<span style="color: ${point.custom.intervalTextColor}">${point.name}</span>`;
          },
        },
        point: {
          events: {
            click() {
              const point = this as FlamePoint;
              setYExtremes([point.low, point.high]);
              setActivityProperties(point.custom);
            },
            mouseOver() {
              const point = this as FlamePoint;
              // Needs this so to avoid console errors that come if chart is redrawn before Highcharts finishes
              // executing its mouseOver command
              setTimeout(() => setActivityProperties(point.custom), 1);
            },
          },
        },
      },
    ],
  };
};
