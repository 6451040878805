import angular from 'angular';
import { sqExploreFolderModalActions } from '@/hybrid/explorer/exploreFolderModal.actions';
import { sqExploreWorkbookModalActions } from '@/hybrid/explorer/exploreWorkbookModal.actions';

const dependencies = ['Sq.Core', 'Sq.AppConstants', 'Sq.TrendData', 'Sq.Search', 'Sq.Investigate', 'Sq.Worksheet'];

/**
 * The Sq.Explorer module contains all functionality related to the Explorer Modal
 */
export default angular
  .module('Sq.Explorer', dependencies)
  .service('sqExploreFolderModalActions', sqExploreFolderModalActions)
  .service('sqExploreWorkbookModalActions', sqExploreWorkbookModalActions);
