import React from 'react';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/hybrid/formbuilder/formbuilder.utilities';
import { CapsuleGroup } from '@/hybrid/tools/manualCondition/CapsuleGroup';
import _ from 'lodash';
import { useShowError } from '@/hybrid/formbuilder/hooks/useShowError';
import { FormFieldWrapper } from '@/hybrid/formbuilder/FormFieldWrapper';
import { CapsuleGroupCapsule } from '@/hybrid/tools/manualCondition/manualCondition.constants';

export interface CapsuleGroupIF extends ValidatingFormComponent<CapsuleGroupCapsule[]> {
  component: 'CapsuleGroupFormComponent';
  previewId: string;
}

export const CapsuleGroupFormComponent: React.FunctionComponent<CapsuleGroupIF> = (props) => {
  const {
    name,
    testId = 'capsuleGroup',
    label,
    validation,
    extendValidation,
    customErrorText,
    previewId,
    value,
  } = props;

  const defaultValidation = () => !value || !_.isArray(value) || value.length === 0;

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  const formState = useForm().getState();
  const { showError, setShowError } = useShowError(false);

  return (
    <FormFieldWrapper testId={testId} label={label} showError={showError} customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const { showError } = getFormFieldProps(formState, input, meta, props);
          setShowError(showError);

          return <CapsuleGroup previewId={previewId} />;
        }}
      </Field>
    </FormFieldWrapper>
  );
};
