import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { getMoment } from '@/hybrid/datetime/dateTime.utilities';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { flux } from '@/core/flux.module';
import { sqProfileSearchStore } from '@/core/core.stores';

/**
 * Initiate a profile search, using the specified parameters.
 * The parameters patternItem, patternStart, and patternEnd describe the reference pattern,
 * while searchSeries, rangeStart, and rangeEnd describe the search space.
 *
 * @param {string} inputSignalId - ID of item on which the pattern occurs.
 * @param {string} [color] - The desired result color, in hex code (e.g. #CCCCCC)
 */
export function search(
  inputSignalId: string,
  color: string,
  sqToolRunner: ToolRunnerService,
  sqCalculationRunner: CalculationRunnerService,
) {
  const patternStart = getMoment(sqProfileSearchStore.selectedRegion.min);
  const patternEnd = getMoment(sqProfileSearchStore.selectedRegion.max);
  const { formula, parameters } = sqCalculationRunner.profileSearch(
    inputSignalId,
    patternStart,
    patternEnd,
    sqProfileSearchStore.similarity,
    sqProfileSearchStore.normalizeAmplitude,
    sqProfileSearchStore.normalizeLocation,
  );

  return sqToolRunner.panelExecuteCondition(
    sqProfileSearchStore.name,
    formula,
    parameters,
    sqProfileSearchStore.configParams,
    sqProfileSearchStore.id,
    color,
  );
}

/**
 * Sets the similarity for the profile search.
 *
 * @param {string} similarity - The similarity for the search.
 */
export function setSimilarity(similarity: string) {
  flux.dispatch('PROFILE_SEARCH_SET_SIMILARITY', { similarity });
}

/**
 * Sets the choice to normalize amplitude for the profile search.
 *
 * @param {boolean} normalizeAmplitude - True to enable.
 */
export function setNormalizeAmplitude(normalizeAmplitude: boolean) {
  flux.dispatch('PROFILE_SEARCH_SET_NORMALIZE_AMPLITUDE', {
    normalizeAmplitude,
  });
}

/**
 * Sets the choice to normalize location for the profile search.
 *
 * @param {boolean} normalizeLocation - True to enable.
 */
export function setNormalizeLocation(normalizeLocation: boolean) {
  flux.dispatch('PROFILE_SEARCH_SET_NORMALIZE_LOCATION', {
    normalizeLocation,
  });
}
