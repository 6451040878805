import React from 'react';
import _ from 'lodash';
import { LegendWrapper } from '@/hybrid/trend/LegendWrapper.atom';
import { Icon } from '@/hybrid/core/Icon.atom';

interface ConditionGradientLegendItemsProps {
  capsules: { isChildOf: string }[];
  conditions: { id: string; color: string; name: string }[];
}

export const ConditionGradientLegendItems: React.FunctionComponent<ConditionGradientLegendItemsProps> = ({
  capsules,
  conditions,
}) => {
  const getCondition = (id: string) => _.find(conditions, (condition) => condition.id === id);

  return (
    <LegendWrapper>
      {_.chain(capsules)
        .uniqBy('isChildOf')
        .map((capsule) => getCondition(capsule.isChildOf))
        .compact()
        .uniqBy('id')
        .map((condition) => (
          <div className="mr10 text-nowrap" key={condition.id}>
            <Icon icon="fa-circle" extraClassNames="pr5" type="color" color={condition.color} />
            {condition.name}
          </div>
        ))
        .value()}
    </LegendWrapper>
  );
};
