import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { MAX_NAME_LENGTH, SMALL_SIZE_SCREEN_LIMIT } from '@/main/app.constants';
import { ButtonGroup, FormControl } from 'react-bootstrap';
import {
  addNewAsset,
  deleteAssetGroup,
  getAssetGroupSummary,
  reset,
  saveOrUpdateAssetGroup,
  setDescription,
  setHasUnsavedChanges,
  setName,
} from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { useTranslation } from 'react-i18next';
import { ToolbarButton } from '@/hybrid/core/ToolbarButton.molecule';
import { ConfirmDeleteModal } from '@/hybrid/core/ConfirmDeleteModal.molecule';
import { AssetPropertiesModal } from '@/hybrid/assetGroupEditor/AssetPropertiesModal.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { AddAssetGroupColumnBtn } from '@/hybrid/assetGroupEditor/AddAssetGroupColumnBtn.atom';
import { TrendActions } from '@/trendData/trend.actions';
import { sqAssetGroupStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import { doTrack } from '@/track/track.service';
import { isPresentationWorkbookMode } from '@/hybrid/utilities/utilities';
import { useResizeWatcher } from '@/hybrid/core/hooks/useResizeWatcher.hook';
import { clearSelection } from '@/search/search.actions';

const assetGroupEditorToolbarBindings = bindingsDefinition({
  sqWorksheetActions: injected<WorksheetActions>(),
  sqTrendActions: injected<TrendActions>(),
  $state: injected<ng.ui.IStateService>(),
});

export const AssetGroupEditorToolbar: SeeqComponent<typeof assetGroupEditorToolbarBindings> = () => {
  type ModalType = 'deleteConfirmation' | 'properties' | 'calculation' | 'none';

  const { sqWorksheetActions, $state, sqTrendActions } = useInjectedBindings(assetGroupEditorToolbarBindings);

  const { id, description, nameError, name } = useFlux(sqAssetGroupStore);

  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<ModalType>('none');
  const [inProgress, setInProgress] = useState(false);
  const [isSmallToolbar, setIsSmallToolbar] = useState(false);
  const toolbarElement = useRef<HTMLDivElement | null>(null);

  const isPresentationMode = isPresentationWorkbookMode();

  const updateName = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const closeModal = () => setModalShown('none');

  useEffect(() => {
    if (_.isEmpty(name)) {
      setName(`${t('ASSET_GROUP_EDITOR.ASSET_GROUP')} ${_.size(sqWorkbookStore.assetGroups) + 1}`);
    }
  }, []);

  useResizeWatcher({
    elementRef: toolbarElement,
    callback: (data) => setIsSmallToolbar(data.newWidth < SMALL_SIZE_SCREEN_LIMIT),
    callOnLoad: true,
  });

  const updatePropertiesInStore = ({ name, description }: { name: string; description: string }): void => {
    setName(name);
    setDescription(description);
    closeModal();
  };

  const deleteAssetGroupAction = () =>
    deleteAssetGroup({
      sqWorksheetActions,
      $state,
    });

  return !isPresentationMode ? (
    <>
      <div
        className="flexColumnContainer flexAlignCenter toolbar min-height-50"
        data-testid="assetGroupToolbar"
        ref={(ref) => {
          toolbarElement.current = ref;
        }}>
        <div className="flexColumnContainer width-maximum flexAlignCenter">
          <Icon icon="fa-cubes" extraClassNames="fa-xlg fa-fw mr10" testId="assetGroupIcon" />
          <FormControl
            id="name"
            size="lg"
            value={name}
            placeholder={t('ASSET_GROUP_EDITOR.NAME_PLACEHOLDER')}
            maxLength={MAX_NAME_LENGTH.TOOL}
            onChange={updateName}
            onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.currentTarget.select()}
            data-testid="assetGroupNameInput"
            className={classNames('width-250', 'mr5', {
              'error-border': nameError,
            })}
            required={true}
          />

          <ButtonGroup>
            <ToolbarButton
              onClick={() => setModalShown('properties')}
              label="ASSET_GROUP_EDITOR.EDIT_GROUP"
              tooltipText="ASSET_GROUP_EDITOR.EDIT_GROUP"
              icon="fa-edit"
              isSmall={isSmallToolbar}
              testId="assetGroupProperties"
            />
          </ButtonGroup>

          <ButtonGroup id="assetGroupBtns">
            <ToolbarButton
              onClick={addNewAsset}
              label="ASSET_GROUP_EDITOR.ADD_ASSET"
              tooltipText="ASSET_GROUP_EDITOR.ADD_ASSET"
              icon="fc-add-row-2"
              isSmall={isSmallToolbar}
              testId="addNewAsset"
            />
            <AddAssetGroupColumnBtn isSmall={isSmallToolbar} />
          </ButtonGroup>
        </div>

        {id && (
          <div
            className="text-underline-onhover mr20 cursorPointer sq-text-primary nowrap"
            onClick={() => setModalShown('deleteConfirmation')}>
            <Icon
              testId="deleteAssetGroupBtn"
              icon="fa-trash"
              tooltip={isSmallToolbar ? 'ASSET_GROUP_EDITOR.DELETE_ASSET_GROUP' : ''}
            />
            {!isSmallToolbar && <span className="ml5">{t('ASSET_GROUP_EDITOR.DELETE_ASSET_GROUP')}</span>}
          </div>
        )}

        <TextButton
          onClick={() => {
            clearSelection();
            reset();
            sqWorksheetActions.setView(sqWorksheetStore.returnView);
          }}
          label="CLOSE"
          size="sm"
          testId="cancelAssetGroupBtn"
          extraClassNames="min-width 100 width-100 max-width-100 mr20"
          id="cancelAssetGroupBtn"
          variant="outline"
        />

        <TextButton
          onClick={() => {
            setInProgress(true);
            doTrack('Asset Group Editor', 'Save', JSON.stringify(getAssetGroupSummary()));
            saveOrUpdateAssetGroup({
              sqTrendActions,
              sqWorksheetActions,
              $state,
            })
              .then(() => {
                setHasUnsavedChanges(false);
                sqWorksheetActions.setView(sqWorksheetStore.returnView);
              })
              .catch(() => {})
              .finally(() => setInProgress(false));
          }}
          label="SAVE"
          disabled={inProgress || nameError || sqWorkbookStore.workbookDisplay === WORKBOOK_DISPLAY.VIEW}
          size="sm"
          testId="saveAssetGroupBtn"
          extraClassNames="min-width 100 width-100 max-width-100"
          id="saveAssetGroupBtn"
          variant="theme"
        />
      </div>

      {modalShown === 'deleteConfirmation' && (
        <ConfirmDeleteModal
          onClose={closeModal}
          bodyTranslationKey="ASSET_GROUP_EDITOR.DELETE_MODAL_BODY"
          action={deleteAssetGroupAction}
        />
      )}

      {modalShown === 'properties' && (
        <AssetPropertiesModal
          name={name}
          description={description}
          onClose={closeModal}
          onSave={updatePropertiesInStore}
        />
      )}
    </>
  ) : null;
};
