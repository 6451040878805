import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { KEY_CODES } from '@/main/app.constants';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

export interface ButtonItem {
  label: string;
  id: string;
  value: string;
  disabled?: boolean;
  tooltip?: string;
}

/**
 * An input component with a group of buttons corresponding to selectable options
 */
export interface ButtonGroupProps {
  buttonItems: ButtonItem[];
  name: string;
  value: string;
  onChange: (value: string) => void;
  testId?: string;
}

export const ButtonGroup: React.FunctionComponent<ButtonGroupProps> = ({
  buttonItems,
  name,
  value,
  onChange,
  testId,
}) => {
  const selected = useMemo(() => value || buttonItems[0]?.value, [value, buttonItems]);
  const [tabIndex, setTabIndex] = useState(-1);
  const { t } = useTranslation();

  useEffect(() => {
    document.querySelector<HTMLInputElement>(`[data-index="${name}-${tabIndex}"`)?.focus?.();
  }, [tabIndex]);

  const renderButtonItem = (buttonItem: ButtonItem, index: number) => {
    const item = (
      <button
        data-name={name}
        data-index={`${name}-${index}`}
        type="button"
        key={buttonItem.id}
        id={buttonItem.id}
        onClick={() => {
          if (!buttonItem.disabled) {
            onChange(buttonItem.value);
            setTabIndex(index);
          }
        }}
        onFocus={() => setTabIndex(index)}
        onKeyDown={(e) => e.keyCode === KEY_CODES.ENTER && !buttonItem.disabled && onChange(buttonItem.value)}
        tabIndex={index === tabIndex ? 0 : undefined}
        className={classNames(
          'sq-btn sq-btn-outline  sq-btn-xs cursorPointer',
          'font-weight-normal pt3 min-height-25 min-width-70 default-layer text-center',
          {
            'disabledLook forceCursorNotAllowed': buttonItem.disabled,
            'active sq-bg-light-gray activeInnerShadow': buttonItem.value === selected,
          },
        )}>
        {t(buttonItem.label)}
      </button>
    );

    if (buttonItem.tooltip) {
      return (
        <HoverTooltip text={buttonItem.tooltip} key={buttonItem.id}>
          {item}
        </HoverTooltip>
      );
    }

    return item;
  };

  return (
    <div
      className="sq-btn-group mt5"
      data-testid={testId}
      onKeyDown={(e) => {
        if (e.keyCode === KEY_CODES.RIGHT_ARROW) {
          setTabIndex((tabIndex) => (tabIndex < buttonItems.length - 1 ? tabIndex + 1 : tabIndex));
        } else if (e.keyCode === KEY_CODES.LEFT_ARROW) {
          setTabIndex((tabIndex) => (tabIndex > 0 ? tabIndex - 1 : tabIndex));
        }
      }}>
      {_.map(buttonItems, renderButtonItem)}
    </div>
  );
};
