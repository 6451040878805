// @ts-strict-ignore
import _ from 'lodash';
import { findItemIn, getAlignableItems, getAllChildItems, getAllItems } from '@/hybrid/trend/trendDataHelper.utilities';
import {
  ITEM_CHILDREN_TYPES,
  TREND_BUFFER_FACTOR,
  TREND_BUFFER_FACTOR_STRING,
  TREND_STORES,
} from '@/trendData/trendData.constants';
import { TrendActions } from '@/trendData/trend.actions';
import { isNumeric } from '@/hybrid/utilities/numberHelper.utilities';
import { getUniqueOrderedValuesByProperty, headlessRenderMode } from '@/hybrid/utilities/utilities';
import { ITEM_TYPES } from './trendData.constants';
import { infoToast } from '@/hybrid/utilities/toast.utilities';
import { getGridlineWidth } from '@/hybrid/utilities/label.utilities';
import { flux } from '@/core/flux.module';
import { isHidden } from '@/hybrid/utilities/trendChartItemsHelper.utilities';
import { PUSH_IGNORE } from '@/core/flux.service';
import { DEBOUNCE } from '@/core/core.constants';
import { sqTrendStore } from '@/core/core.stores';
import { shouldGridlinesBeShown } from '@/trendData/trend.utilities';

/**
 * Returns the y axis config values for the item, based on all the items in the lane.
 *
 * @param id - Id of the item
 */
export function getYAxisConfig(id: string) {
  const seriesToAlign = getYAxisItems();
  const item = findItemIn(TREND_STORES, id);
  const laneCount = getDisplayedLanesCount();

  return getYAxisConfigValues(item, seriesToAlign, laneCount);
}

/**
 * Assigns all (selected) trends to the same y-axis scale.
 */
export function oneYAxis(sqTrendActions: TrendActions) {
  let seriesToAlign = getAlignableItems({
    workingSelection: true,
  });

  if (_.some(seriesToAlign, 'isStringSeries') && seriesToAlign.length > 1) {
    infoToast({
      messageKey: 'NO_STRING_DATA_Y_AXIS_SHARING',
    });
    seriesToAlign = _.reject(seriesToAlign, 'isStringSeries');
  }

  flux.dispatch(
    'TREND_SET_CUSTOMIZATIONS',
    {
      items: _.map(seriesToAlign, ({ id }) => ({
        id,
        axisAlign: undefined,
      })),
    },
    PUSH_IGNORE,
  );

  const nextAlignment = sqTrendStore.nextAlignment;

  flux.dispatch('TREND_SET_CUSTOMIZATIONS', {
    items: _.map(seriesToAlign, ({ id }) => ({
      id,
      axisAlign: nextAlignment,
      yAxisType: seriesToAlign[0].yAxisType,
    })),
  });

  updateLaneDisplay(sqTrendActions);
}

/**
 * "Flattens" the trend by placing every series on the same lane, and an individual axis.
 * If series are selected then only the selected series will be placed on the same lane.
 * If there are unaligned y axes we're trying to put into one lane, removes gridlines if they exist.
 */
export function oneLane(sqTrendActions: TrendActions) {
  const seriesToAlign = getAlignableItems({
    workingSelection: true,
  });

  flux.dispatch(
    'TREND_SET_CUSTOMIZATIONS',
    {
      items: _.map(seriesToAlign, ({ id }) => ({
        id,
        lane: undefined,
      })),
    },
    PUSH_IGNORE,
  );

  const nextLane = sqTrendStore.nextLane;

  flux.dispatch('TREND_SET_CUSTOMIZATIONS', {
    items: _.map(seriesToAlign, ({ id }) => ({
      id,
      lane: nextLane,
    })),
  });

  updateLaneDisplay(sqTrendActions);
}

/**
 * Resets all lane and axis assignments and places each series on its own lane and axis.
 */
export function resetAllAxes(sqTrendActions: TrendActions) {
  flux.dispatch('TREND_SET_CUSTOMIZATIONS', {
    items: _.map(getAlignableItems({}), (item, index) => ({
      id: item.id,
      lane: sqTrendStore.lanes[index],
      axisAlign: sqTrendStore.alignments[index],
      axisVisibility: true,
      axisAutoScale: true,
      rightAxis: false,
    })),
  });

  updateLaneDisplay(sqTrendActions);
}

/**
 * Sets the Y-extremes for a collection of items.
 *
 * @param {Object} extremes - Array of extremes
 * @param {Number} extremes[].min - y-axis lower bound
 * @param {Number} extremes[].max - y-axis upper bound
 * @param {String} extremes[].axisAlign - the y-axis to set the extremes for
 */
export function setYExtremes(extremes) {
  // Performance wise, it is very important to push only distinct values. Otherwise we'll trigger many unnecessary
  // event dispatches and costly store updates.
  const uniqueExtremes = _.uniqWith(extremes, _.isEqual);

  const fullItemList = getAlignableItems({});
  const laneCount = getDisplayedLanesCount();

  _.forEach(uniqueExtremes, function (extreme: any) {
    const lanesOnSameAxis = _.filter(fullItemList, {
      axisAlign: extreme.axisAlign,
    });

    const extremesToUpdate = _.map(lanesOnSameAxis, function (item: any) {
      const minAndMax = getBufferedExtremes(
        {
          min: extreme.min,
          max: extreme.max,
        },
        item.isStringSeries,
        laneCount,
      );

      return {
        id: item.id,
        yAxisConfig: {
          min: minAndMax.min,
          max: minAndMax.max,
        },
        axisAutoScale: false,
        yAxisMin: extreme.min,
        yAxisMax: extreme.max,
      };
    });

    flux.dispatch('TREND_SET_CUSTOMIZATIONS', { items: extremesToUpdate });
  });
}

/**
 * Handles the removal of items.
 * This function ensures that 'gaps' in alignment and lane assignments are corrected so proper rendering is
 * ensured.
 *
 */
export function removeGaps(sqTrendActions: TrendActions) {
  findGapAndAdjust('axisAlign', sqTrendStore.alignments);
  findGapAndAdjust('lane', sqTrendStore.lanes);
  updateLaneDisplay(sqTrendActions);

  function findGapAndAdjust(property, availableOptions) {
    const items = getAlignableItems({});
    const remaining = _.chain(items).map(property).sortBy().uniq().value();
    let lastUsedIndex = 0;

    let assignmentsToAdjust = [];
    for (let i = 0; i < remaining.length; i++) {
      if (remaining[i] !== availableOptions[i]) {
        // we found a gap - everything from here on out needs to be corrected:
        assignmentsToAdjust = _.slice(remaining, i);
        break;
      } else {
        lastUsedIndex = i + 1;
      }
    }

    const itemsToAdjust = [];
    if (!_.isEmpty(assignmentsToAdjust)) {
      _.forEach(assignmentsToAdjust, function (oldPropertyValue) {
        itemsToAdjust.push(_.filter(items, (item) => item[property] === oldPropertyValue));
      });
    }

    const dispatchItems = [];
    _.forEach(itemsToAdjust, function (items) {
      const newOptionValue = availableOptions[lastUsedIndex];
      _.forEach(items, function (item: any) {
        dispatchItems.push({
          id: item.id,
          [property]: newOptionValue,
        });
      });

      lastUsedIndex++;
    });

    if (dispatchItems.length > 0) {
      flux.dispatch('TREND_SET_CUSTOMIZATIONS', { items: dispatchItems });
    }
  }
}

export function getYAxisValues(seriesToAlign: any[]) {
  // Must be done before we evaluate the min and the max of the y-axis
  mergeCapsuleTimeStringSeriesEnums();

  const laneCount = getDisplayedLanesCount();

  const yAxisValues = _.chain(getAlignableItems({}))
    .groupBy('lane')
    .flatMap((seriesInLane) =>
      _.map(seriesInLane, (series) =>
        _.assign({ id: series.id }, getYAxisConfigValues(series, seriesToAlign, laneCount)),
      ),
    )
    .value();

  return yAxisValues;
}

/**
 * Adjusts the y-axes of all of the selected trends so that they each take up their own horizontal "lane" on the
 * chart.
 * For each series re-compute the min and max y-value based on the lane the series is displayed within.
 * If the series is a straight line then a range is artificially created so that the series can be spread properly.
 * String series are not considered for the range calculation as it doesn't make sense to "align" axis scale for
 * Strings.
 */
function updateLane(sqTrendActions: TrendActions) {
  const seriesToAlign = getYAxisItems();
  const yAxisValues = getYAxisValues(seriesToAlign);

  // Remove gridlines and show a warning if we're putting multiple y axes in one lane
  if (sqTrendStore.showGridlines && !shouldGridlinesBeShown(seriesToAlign)) {
    infoToast({
      messageKey: 'NO_GRIDLINES_FOR_MULTIPLE_Y_AXES_ONE_LANE',
    });
    sqTrendActions.setGridlines(false, true);
  }

  flux.dispatch('TREND_SET_CUSTOMIZATIONS', { items: yAxisValues }, PUSH_IGNORE);
}

// debounced because it is expensive due to iterating over all items. Not debounced in headlessRTenderMode b/c
// we need the screenshot service to pick up final changes TODO: Remove ternary after CRAB-30173
export const updateLaneDisplay = headlessRenderMode() ? updateLane : _.debounce(updateLane, DEBOUNCE.SHORT);

/**
 * Merges the string enum for string series in capsule time so that they display correctly. Without enum merging,
 * string series that share the same axis could display in a misleading way because a string value from each
 * signal could map to a different value on the chart.
 */
export function mergeCapsuleTimeStringSeriesEnums() {
  _.chain(
    getAllChildItems({
      itemTypes: [ITEM_TYPES.SERIES],
    }),
  )
    .filter('isStringSeries')
    .groupBy('isChildOf')
    .forEach((series) => {
      // Prefer larger enums first since they are more likely to contain all the values needed - this leads to the
      // enum being more consistent with calendar view most of the time
      const sortedSeries = _.orderBy(series, [(s) => s.calculatedStringEnum.length], ['desc']);
      const mergedStringEnum = _.reduce(
        sortedSeries,
        (stringEnum, item: any) => {
          const unusedKeys = _.filter(
            _.range(stringEnum.length + item.calculatedStringEnum.length),
            (i) => !_.find(stringEnum, ['key', i]),
          );
          const missingEnum = _.chain(item.calculatedStringEnum)
            .filter(({ stringValue }) => !_.find(stringEnum, ['stringValue', stringValue]))
            .map(({ stringValue, key }) => {
              if (!_.find(stringEnum, ['key', key])) {
                return {
                  stringValue,
                  key,
                };
              } else {
                const key = unusedKeys.shift();
                return {
                  stringValue,
                  key,
                };
              }
            })
            .value();
          return _.concat(stringEnum, missingEnum);
        },
        [],
      );

      flux.dispatch(
        'TREND_SIGNAL_SET_STRING_ENUM',
        {
          ids: _.map(series, 'id'),
          stringEnum: mergedStringEnum,
        },
        PUSH_IGNORE,
      );
    })
    .value();
}

/**
 * Given the minimum and maximum y-values from across a set of series, return a set of y-axis extremes that
 * will show the range from the minimum to maximum y-values plus a buffer on each end.
 *
 * @param minAndMaxYValues - An object containing the min and max y-values from a set of series
 * @param minAndMaxYValues.min - The minimum y-value found in a set of series
 * @param minAndMaxYValues.max - The maxiumum y-value found in a set of series
 * @param isStringSeries - Flag indicating if the series is a String Series (they get a bigger buffer to
 * ensure the labels are not cut off)
 * @param laneCount - Number of lanes displayed. Used to adjust the buffer dynamically.
 *
 * @return {Object} An object containing the calculated extremes as newMin, newMax
 */
export function getBufferedExtremes(
  minAndMaxYValues: { min: number | string; max: number | string },
  isStringSeries: boolean,
  laneCount: number,
) {
  let newRange;
  const buffer = isStringSeries ? TREND_BUFFER_FACTOR_STRING : TREND_BUFFER_FACTOR * laneCount;

  newRange = Number(minAndMaxYValues.max) - Number(minAndMaxYValues.min);
  return {
    min: Number(minAndMaxYValues.min) - buffer * newRange,
    max: Number(minAndMaxYValues.max) + buffer * newRange,
  };
}

/**
 * Given a set of series, return the minimum and maximum y-value across all series.
 * @param {Object} item - The current item that contains the configuration for the axis
 * @param {Object[]} seriesToAlign - An array of series that contain data for this lane
 * @return {Object} An object containing the calculated min and max
 */
export function getMinAndMaxYValue(item, seriesToAlign: any[]) {
  // Add item to the list before we filter for those that are not autoScaled.  Sometimes statistics wil not be
  // included in the seriesToAlign list, but we will use their min/max
  const scaledSeries = _.filter(_.concat(seriesToAlign, [item]), ['axisAutoScale', false]);
  let autoScale = scaledSeries.length === 0;

  if (item.isStringSeries) {
    autoScale = true; // we can never not auto-scale string series as that will just look really bad.
  }

  if (!autoScale) {
    const minValues = [];
    const maxValues = [];
    _.forEach(scaledSeries, function (s: any) {
      if (isNumeric(s.yAxisMin) && isNumeric(s.yAxisMax)) {
        minValues.push(s.yAxisMin);
        maxValues.push(s.yAxisMax);
      }
    });
    if (minValues.length > 0 && maxValues.length > 0) {
      return {
        min: _.min(minValues),
        max: _.max(maxValues),
      };
    }
  }

  let returnMin = Infinity;
  let returnMax = -Infinity;
  _.forEach(seriesToAlign, (series) =>
    _.forEach(series.data, (datum) => {
      if (_.isArray(datum) && _.isFinite(datum[1])) {
        returnMin = Math.min(returnMin, datum[1]);
        returnMax = Math.max(returnMax, datum[1]);
      }

      if (_.isArray(datum) && _.isFinite(datum[2])) {
        // upper value for shaded area
        returnMin = Math.min(returnMin, datum[2]);
        returnMax = Math.max(returnMax, datum[2]);
      }

      if (!_.isArray(datum) && _.isFinite(datum.y)) {
        // marker for discrete sample
        returnMin = Math.min(returnMin, datum.y);
        returnMax = Math.max(returnMax, datum.y);
      }
    }),
  );

  if (!_.isFinite(returnMin) || !_.isFinite(returnMax)) {
    // When there is no data or no series to align then use 0 so that we get a blank axis from -1 to 1
    returnMin = returnMax = 0;
  }

  if (returnMax - returnMin === 0) {
    // Artificially manipulate the min and max values for a flat line
    const mag = Math.floor(Math.log(Math.abs(returnMin)) / Math.log(10));
    const magPow = returnMin !== 0 ? Math.pow(10, mag) : 1;
    returnMin -= magPow;
    returnMax += magPow;
  }

  return {
    min: returnMin,
    max: returnMax,
  };
}

export function getYAxisConfigValues(item, seriesToAlign: any[], laneCount) {
  // find all the series with the same alignment designation:
  const seriesInAlignmentGroup = _.chain(seriesToAlign).filter(['axisAlign', item.axisAlign]).uniq().value();

  const rawMinMax = getMinAndMaxYValue(item, seriesInAlignmentGroup);
  const minAndMax = getBufferedExtremes(rawMinMax, item.isStringSeries, laneCount);

  return {
    yAxisConfig: {
      min: minAndMax.min,
      max: minAndMax.max,
    },
    gridLineWidth: getGridlineWidth(sqTrendStore.showGridlines),
    yAxisMin: rawMinMax.min,
    yAxisMax: rawMinMax.max,
    yAxisType: item.yAxisType,
    rightAxis: _.get(item, 'rightAxis', false),
  };
}

/**
 * Gets the number of displayed lanes on the trend
 */
export function getDisplayedLanesCount() {
  return getUniqueOrderedValuesByProperty(
    getAlignableItems({
      workingSelection: sqTrendStore.hideUnselectedItems,
    }),
    'lane',
    sqTrendStore.lanes,
  ).length;
}

/**
 * Gets all the signals that will be shown in the axis, this must include previews and children
 * items so that the extremes take into account those items
 *
 * @return {Object[]} A subset of series on which the user is currently acting
 */
function getYAxisItems() {
  // TODO Cody Ray Hoeft [Metrics] - Boundaries are not shown in capsule time! (CRAB-12176)
  // It is unclear how we would make that work with the current system where each
  // SERIES_FROM_CAPSULE is an independent item in store. It is also unclear how valuable
  // capsule time would be for metrics (especially wouldn't thresholds be a mess?)
  return _.reject(
    getAllItems({
      includeSignalPreview: true,
      itemTypes: [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR],
      itemChildrenTypes: _.compact([
        !sqTrendStore.isTrendViewCapsuleTime() && ITEM_CHILDREN_TYPES.ANCILLARY,
        !sqTrendStore.isTrendViewCapsuleTime() && ITEM_CHILDREN_TYPES.METRIC_DISPLAY,
        !sqTrendStore.isTrendViewCapsuleTime() && ITEM_CHILDREN_TYPES.METRIC_THRESHOLD,
        sqTrendStore.isTrendViewCapsuleTime() && ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE,
      ]),
    }),
    (item) => isHidden({ item }),
  );
}
