import React from 'react';
import { Table, TableColumn } from '@/hybrid/core/Table.atom';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ErrorWithBackground } from '@/hybrid/core/ErrorWithBackground.atom';
import { Alert } from 'react-bootstrap';
import { humanReadableByteCount } from '@/hybrid/utilities/numberHelper.utilities';
import { UsageOutputV1 } from '@/sdk';
import { translateType } from '@/hybrid/administration/usage/usage.utilities';
import { UsageOutputV1WithId } from '@/hybrid/administration/usage/UsageTab.page';

interface UsageTableIF {
  entries: UsageOutputV1WithId[];
  isLoading: boolean;
  errorMessage: string;
}

const COLUMN_BORDER = 'solid 0.5px rgb(224,224,224)';

export const UsageTable: React.FunctionComponent<UsageTableIF> = ({ entries, isLoading, errorMessage }) => {
  const { t } = useTranslation();

  const buildColumns = (entries: UsageOutputV1[]): TableColumn[] => {
    return _.chain(entries)
      .head()
      .keys()
      .filter((columnName) => allColumns[columnName])
      .map((columnName) => allColumns[columnName])
      .value();
  };

  const renderTimestamp = (column: UsageOutputV1) => (
    <span data-testid="column-timestamp">{moment(column.timestamp).utc().format('l')}</span>
  );

  const renderIdentity = (column: UsageOutputV1) => <span data-testid="column-identity">{column.identity}</span>;

  const renderType = (column: UsageOutputV1) => <span data-testid="column-type">{translateType(column.type!)}</span>;

  const renderOrigin = (column: UsageOutputV1) => {
    return column.sourceUrl ? (
      <a data-testid="column-sourceLabel" href={`${column.sourceUrl}`} target="_blank">
        {column.sourceLabel}
      </a>
    ) : (
      <span data-testid="column-sourceLabel">{column.sourceLabel}</span>
    );
  };

  const renderAmount = (column: UsageOutputV1) => (
    <span data-testid={`column-amount`}>{humanReadableByteCount(_.toNumber(column.bytes))}</span>
  );

  const allColumns: { [key: string]: TableColumn } = {
    timestamp: {
      header: t('USAGE.TABLE_COLUMN_HEADER.DATE'),
      accessor: 'timestamp',
      searchProperty: 'timestamp',
      sortable: false,
      filterable: false,
      cellStyle: {
        minWidth: '80px',
        maxWidth: '80px',
        wordWrap: 'break-word',
        borderRight: COLUMN_BORDER,
      } as React.CSSProperties,
      cellRenderFunction: renderTimestamp,
    },
    identity: {
      header: t('USAGE.TABLE_COLUMN_HEADER.USER'),
      accessor: 'identity',
      searchProperty: 'identity',
      sortable: false,
      filterable: false,
      cellStyle: {
        wordWrap: 'break-word',
        minWidth: '70px',
        maxWidth: '110px',
        borderRight: COLUMN_BORDER,
      } as React.CSSProperties,
      cellRenderFunction: renderIdentity,
    },
    sourceLabel: {
      header: t('USAGE.TABLE_COLUMN_HEADER.SOURCE'),
      accessor: 'source',
      searchProperty: 'source',
      sortable: false,
      filterable: false,
      cellStyle: {
        wordWrap: 'break-word',
        minWidth: '210px',
        maxWidth: '290px',
        borderRight: COLUMN_BORDER,
      } as React.CSSProperties,
      cellRenderFunction: renderOrigin,
    },
    type: {
      header: t('USAGE.TABLE_COLUMN_HEADER.TYPE'),
      accessor: 'type',
      searchProperty: 'type',
      sortable: false,
      filterable: false,
      cellStyle: {
        wordWrap: 'break-word',
        minWidth: '40px',
        borderRight: COLUMN_BORDER,
      } as React.CSSProperties,
      cellRenderFunction: renderType,
    },
    bytes: {
      header: t('USAGE.TABLE_COLUMN_HEADER.AMOUNT'),
      accessor: 'bytes',
      searchProperty: 'bytes',
      sortable: false,
      filterable: false,
      cellStyle: {
        wordWrap: 'break-word',
        minWidth: '40px',
        textAlign: 'right',
      } as React.CSSProperties,
      cellRenderFunction: renderAmount,
    },
  };

  const hasNoEntries = entries.length === 0 && errorMessage === '' && !isLoading;

  return (
    <div data-testid="usageTable" className="flexRowContainer mb5 mt10">
      {isLoading && (
        <div className="flexColumnContainer flexCenter pt50 pb50">
          <Icon icon="fa-spinner fa-pulse fa-5x" extraClassNames="sq-text-primary" />
        </div>
      )}
      {!isLoading && !errorMessage && !hasNoEntries && (
        <div className="tableWrapper">
          <Table
            sortAscending={true}
            columns={buildColumns(entries)}
            items={entries}
            tableClass="fixedHeaderTable w-100"
            headerClass="sq-light-theme-header"
          />
        </div>
      )}
      {errorMessage && <ErrorWithBackground errorHeading={t('USAGE.SEARCH_ERROR')} errorText={errorMessage} />}
      {hasNoEntries && (
        <Alert className="sq-alert-warning" variant="secondary" dismissible={false}>
          <div className="fs14 text-bolder mt0">{t('USAGE.SEARCH_ERROR')}</div>
          <p className="max-height-120 overflowAuto text-pre-wrap breakWord">{t('USAGE.NO_ENTRIES_FOUND')}</p>
        </Alert>
      )}
    </div>
  );
};
