// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import ValueWithUnits, { ValueWithUnitsItem } from '@/hybrid/trend/ValueWithUnits.atom';
import { canUseMemo, getFormFieldProps, getValidationFunction } from '@/hybrid/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { useShowError } from '@/hybrid/formbuilder/hooks/useShowError';
import { FormFieldWrapper } from '@/hybrid/formbuilder/FormFieldWrapper';

export interface MaxCapsuleDurationIF extends ValidatingFormComponent<ValueWithUnitsItem> {
  component: 'MaxCapsuleDurationFormComponent';
  minIsExclusive?: boolean;
  required?: boolean;
  insideModal?: boolean;
  icon?: string;
  label?: string;
  maxDurationRequired: boolean;
}

const MaxCapsuleDurationFormComponentUnwrapped: React.FunctionComponent<MaxCapsuleDurationIF> = (props) => {
  const {
    label = 'MAXIMUM_CAPSULE_DURATION',
    tooltip = 'MAXIMUM_CAPSULE_DURATION_OVERRIDE_TOOLTIP',
    value,
    name,
    validation,
    required = true,
    testId,
    minIsExclusive = false,
    extendValidation,
    maxDurationRequired,
    extraClassNames,
  } = props;

  const { showError, setShowError } = useShowError(false);
  const min = 0;

  const defaultValidation = (valueWithUnits) => {
    const { units, value } = valueWithUnits || {};
    if (required || !_.isNil(units)) {
      if (!_.isNil(min)) {
        return !(minIsExclusive ? value > min : value >= min);
      } else {
        return false;
      }
    }
    return false;
  };

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();

  return (
    <FormFieldWrapper
      testId={testId}
      label={label}
      tooltip={tooltip}
      wrapperClassNames={extraClassNames}
      showError={showError && maxDurationRequired}
      customErrorText="MAXIMUM_DURATION_REQUIRED_DEFAULT_ADDED">
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const properProps = getFormFieldProps(formState, input, meta, props);
          setShowError(properProps.showError);

          return (
            <ValueWithUnits
              {...properProps}
              min={min}
              required={maxDurationRequired}
              isValid={!showError}
              defaultValue={value}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};

export const MaxCapsuleDurationFormComponent = React.memo(MaxCapsuleDurationFormComponentUnwrapped, canUseMemo);
