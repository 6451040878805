import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { sqWorkbenchStore } from '@/core/core.stores';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { TourModal } from '@/hybrid/tour/TourModal.molecule';

const workbenchTourModalBindings = bindingsDefinition({
  sqWorkbenchActions: injected<WorkbenchActions>(),
});

export const TOUR_NAME = 'workbenchOverviewTour';
const WORKBENCH_STEP_COUNT = 4;

export const WorkbenchTourModal: SeeqComponent<typeof workbenchTourModalBindings> = () => {
  const { sqWorkbenchActions } = useInjectedBindings(workbenchTourModalBindings);
  const workbenchTourShown = useFluxPath(sqWorkbenchStore, () => _.includes(sqWorkbenchStore.toursShown, TOUR_NAME));

  const steps = _.map(_.range(1, WORKBENCH_STEP_COUNT + 1), (index) => ({
    heading: `TOUR.WORKBENCH.STEP_${index}`,
    gifSrc: `/img/WorkbenchTourGif${index}.gif`,
  }));

  return (
    <TourModal
      tourTitle="TOUR.WORKBENCH.TITLE"
      displayTour={!workbenchTourShown}
      tourSteps={steps}
      testId="workbenchTour"
      trackName="workbench"
      onClose={() => sqWorkbenchActions.setTourShown(TOUR_NAME)}
    />
  );
};

export const sqWorkbenchTourModal = angularComponent(workbenchTourModalBindings, WorkbenchTourModal);
