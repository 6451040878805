// @ts-strict-ignore
import _ from 'lodash';
import { sqLogsApi } from '@/sdk';
import { APPEND_DIRECTION, SortOrder } from '@/hybrid/logTracker/LogTracker.constants';
import { reformatZuluOffset } from '@/hybrid/datetime/dateTime.utilities';

export interface FetchLogsOptions {
  log: string;
  startTime: string;
  limit: number;
  endTime?: string;
  sorted?: SortOrder;
  level?: string;
  sourceContains?: string;
  messageContains?: string;
  appendToLogs?: boolean;
  appendDirection?: string;
}

export interface LogItem {
  level: string;
  message: string;
  source: string;
  time: string;
}

/**
 * Retrieves a collection of available log files for querying.
 * @return {Promise} Array of log names
 */
export function getAvailableLogs() {
  return sqLogsApi.getAvailableLogFiles().then((response) => response.data);
}

/**
 * Fetch logs, depends on options result will be appended to existing messages or added before messages
 *
 * @param {FetchLogsOptions} options
 * @param {LogItem[]} currentMessages - existing messages
 * @return {Promise} Messages
 */
export function fetchLogs(options: FetchLogsOptions, currentMessages: LogItem[]) {
  options.sorted = _.isEmpty(options.startTime) ? 'DESC' : 'ASC';
  return sqLogsApi.getLogs(options).then(({ data }) => {
    return _.chain(data)
      .thru((messages) => {
        if (options.sorted === 'DESC') {
          return _.reverse(messages);
        }
        return messages;
      })
      .thru((messages) => {
        if (options.appendToLogs && messages.length > 0) {
          const lastSeen =
            options.appendDirection === APPEND_DIRECTION.DOWN ? _.last(currentMessages) : _.head(currentMessages);
          let filteredMessages = _.reject(messages, _.partial(_.isEqual, lastSeen));
          if (options.appendDirection === APPEND_DIRECTION.DOWN) {
            return _.concat(currentMessages, filteredMessages);
          } else {
            return _.concat(filteredMessages, currentMessages);
          }
        } else {
          return messages;
        }
      })
      .value();
  });
}

/**
 * When a user clicks up or down button, need to generate options based on button, before we make request
 * It takes last or first message time and fills startTime or endTime depending on 'append' property
 *
 * @param {boolean} append
 * @param {LogItem[]} messages - existing messages
 * @return {object} options with startTime, endTime, appendDirection
 */
export function generateMoreLogsOptions(append: boolean, messages: LogItem[]) {
  const options = {} as FetchLogsOptions;

  if (!_.isEmpty(messages)) {
    if (append) {
      options.appendDirection = APPEND_DIRECTION.DOWN;
      options.startTime = reformatZuluOffset(_.last(messages).time);
      options.endTime = '';
    } else {
      options.appendDirection = APPEND_DIRECTION.UP;
      options.endTime = reformatZuluOffset(_.head(messages).time);
      options.startTime = '';
    }
    options.appendToLogs = true;
  }
  return options;
}
