// @ts-strict-ignore
import _ from 'lodash';
import { sqExportApi } from '@/sdk/api/ExportApi';
import { sqJobsApi } from '@/sdk/api/JobsApi';
import { sqSystemApi } from '@/sdk/api/SystemApi';
import { sqSubscriptionsApi } from '@/sdk/api/SubscriptionsApi';
import { sqRequestsApi, sqUserGroupsApi, sqUsersApi, UserGroupInputV1, UserInputV1, UserOutputV1 } from '@/sdk';
import { sqAccessKeysApi } from '@/sdk/api/AccessKeysApi';
import { errorToast, successToast, warnToast } from '@/hybrid/utilities/toast.utilities';
import { flux } from '@/core/flux.module';
import { sqAdministrationStore } from '@/core/core.stores';
import { CONFIGURATION_TAB_INDEX, JOBS_TAB_INDEX } from '@/administration/administration.constants';

/**
 * Makes required asynchronous calls to load user groups state.
 */
export function load() {
  flux.dispatch('ADMINISTRATION_CLEAR_GROUPS');

  return sqUserGroupsApi.getUserGroups({ limit: 5000 }).then(({ data }) => {
    _.forEach(data.items, saveNewGroup);
  });
}

export function setActiveTabIndex(tab: number, setDefaultSearchParamsForTab = false) {
  flux.dispatch('ADMINISTRATION_SET_ACTIVE_TAB_INDEX', tab);
  resetSearchParams();

  if (setDefaultSearchParamsForTab) {
    switch (_.toNumber(tab)) {
      case JOBS_TAB_INDEX:
        return setSearchParams({ field: 'group', value: 'ScreenshotCapture' });
      case CONFIGURATION_TAB_INDEX:
        return setSearchParams({ field: 'advanced', value: false });
      default:
    }
  }
}

export function setSearchParams({ field, value }: { field: string; value: string | string[] | boolean }) {
  flux.dispatch('ADMINISTRATION_SET_SEARCH_PARAMS', { field, value });
}

export function resetSearchParams() {
  flux.dispatch('ADMINISTRATION_RESET_SEARCH_PARAMS');
}

export function saveNewUser(user: UserOutputV1) {
  flux.dispatch('ADMINISTRATION_ADD_USER', user);
}

export function saveNewGroup(group: UserGroupInputV1) {
  flux.dispatch('ADMINISTRATION_ADD_GROUP', group);
}

export function updateUser(user: UserOutputV1) {
  flux.dispatch('ADMINISTRATION_UPDATE_USER', user);
}

export function updateGroup(group: UserGroupInputV1) {
  flux.dispatch('ADMINISTRATION_UPDATE_GROUP', group);
}

export function enableUsers({ enabled, ids }: { enabled: boolean; ids?: string[] }) {
  if (_.isEmpty(ids)) {
    warnToast({ messageKey: 'ADMIN.USER.SELECTION_REQUIRED' });
    return Promise.resolve();
  }

  const users = _.map(ids, (id) => sqUsersApi.updateUser({ isEnabled: enabled } as UserInputV1, { id }));

  return Promise.all(users)
    .then(() => {
      const payload = {
        ids,
        enable: enabled,
      };
      const msg = enabled ? 'ADMIN.USER.USERS_ENABLED' : 'ADMIN.USER.USERS_DISABLED';
      flux.dispatch('ADMINISTRATION_ENABLE_USERS', payload);
      successToast({ messageKey: msg });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
      loadTable();
    });
}

export function removeUsers({
  ids,
  newOwnerId,
  transferAclAndGroupMembership,
}: {
  ids: string[];
  newOwnerId: string;
  transferAclAndGroupMembership: boolean;
}) {
  const removed = _.map(ids, (id) => sqUsersApi.deleteUser({ id, newOwnerId, transferAclAndGroupMembership }));

  return Promise.all(removed)
    .then(() => {
      flux.dispatch('ADMINISTRATION_REMOVE_USERS', ids);
      successToast({ messageKey: 'ADMIN.USER.USERS_REMOVED' });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
      loadTable();
    });
}

/**
 * Remove groups
 * If no groups are selected a warning to select groups is displayed.
 *
 * @param {String[]} ids - The ids of the groups to remove
 * @returns {Promise} that resolves when the removal is complete
 */
export function removeGroups(ids?: string[]) {
  if (_.isEmpty(ids)) {
    warnToast({ messageKey: 'ADMIN.GROUP.SELECTION_REQUIRED' });
    return Promise.resolve();
  }

  const removed = _.map(ids, (userGroupId) => sqUserGroupsApi.removeUserGroup({ userGroupId }));

  return Promise.all(removed)
    .then(() => {
      flux.dispatch('ADMINISTRATION_REMOVE_GROUPS', ids);
      successToast({ messageKey: 'ADMIN.GROUP.REMOVED' });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
      load();
    });
}

export function refreshAdminContactInfo() {
  return sqSystemApi.getAdministratorContactInformation().then(({ data }) => {
    flux.dispatch('ADMINISTRATION_UPDATE_CONTACT_INFO', _.pick(data, ['name', 'email']));
  });
}

export function updateAdminContactInfo(adminContactInfo: { name: string; email: string }) {
  return sqSystemApi.setAdministratorContactInformation(adminContactInfo).then(() => {
    flux.dispatch('ADMINISTRATION_UPDATE_CONTACT_INFO', adminContactInfo);
  });
}

export function refreshRequests() {
  return sqRequestsApi.getRequests({ limit: 10000 }).then(({ data }) => {
    return data;
  });
}

export function cancelRequests(ids: string[]) {
  const cancelled = _.map(ids, (requestId) => sqRequestsApi.cancelRequest({ requestId }));

  return Promise.all(cancelled);
}

export function refreshSubscriptions() {
  return sqSubscriptionsApi.getChannels({ limit: 1000 }).then(({ data }) => data);
}

export function refreshJobs() {
  return sqJobsApi.getJobs({ limit: 1000 });
}

export function stopJobs(jobs: { id: string; group: string }[]) {
  const stopped = _.map(jobs, (job) => sqJobsApi.deleteJob(job));

  return Promise.all(stopped);
}

export function refreshExports() {
  return sqExportApi
    .getExports({ limit: 5000 })
    .then(({ data }) => data)
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
    });
}

export function archiveExports(ids: string[]) {
  const archived = _.map(ids, (id) => sqExportApi.archiveExport({ id }));

  return Promise.all(archived)
    .then(() => {
      successToast({ messageKey: 'ADMIN.EXPORT.TRASHED' });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
      load();
    });
}

export function setFilter(field: string, value: string | boolean) {
  flux.dispatch('ADMINISTRATION_SET_PAGE_NUMBER', { pageNumber: 1 });
  flux.dispatch('ADMINISTRATION_SET_SEARCH_PARAMS', { field, value });
}

export function setSort(field: string, sortAscending: boolean) {
  if (sqAdministrationStore.sortProperty === field) {
    sortAscending = !sortAscending;
  }

  flux.dispatch('ADMINISTRATION_SET_PAGE_NUMBER', { pageNumber: 1 });
  flux.dispatch('ADMINISTRATION_SET_SORT', { field, sortAscending });
}

export function setPageSizeForTable({ size }: { size: number }) {
  flux.dispatch('ADMINISTRATION_SET_PAGE_SIZE', { size });
}

export function setPageNumberAndGo(pageNumber: number) {
  flux.dispatch('ADMINISTRATION_SET_PAGE_NUMBER', { pageNumber });
  loadTable();
}

/**
 * Loads the user table using the pageSize and currentPageNumber to fetch the proper page content.
 */
export function loadTable() {
  const limit = sqAdministrationStore.getPageSizeByTable();
  const currentPageNumber = sqAdministrationStore.getPageNumberForTable();
  const offset = (currentPageNumber - 1) * limit;
  const sortOrder = `${sqAdministrationStore.sortProperty} ${sqAdministrationStore.sortAscending ? 'asc' : 'desc'}`;
  flux.dispatch('ADMINISTRATION_SET_USER_TABLE_LOADING', { loading: true });

  return sqUsersApi
    .getUsers(
      _.assign({}, sqAdministrationStore.searchParams, {
        limit,
        offset,
        sortOrder,
        isCreated: true,
      }),
    )
    .then((result) => {
      flux.dispatch('ADMINISTRATION_SET_USERS', result.data.users);
      flux.dispatch('ADMINISTRATION_SET_TOTAL_USER_COUNT', {
        userCount: result.data.totalResults,
      });
    })
    .finally(() =>
      flux.dispatch('ADMINISTRATION_SET_USER_TABLE_LOADING', {
        loading: false,
      }),
    );
}

export function resetTable() {
  flux.dispatch('ADMINISTRATION_SET_PAGE_NUMBER', { pageNumber: 1 });
}

export function refreshKeys() {
  return sqAccessKeysApi.getKeys({ offset: 0, limit: 1000, getAll: true });
}

export function deleteKeys(name: string[]) {
  const deleted = _.map(name, (name) => sqAccessKeysApi.deleteKey({ keyName: name }));
  return Promise.all(deleted);
}
