// @ts-strict-ignore
import React, { useRef, useState } from 'react';
import { Form, Overlay, Popover } from 'react-bootstrap';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import _ from 'lodash';
import { renderBorderCommand } from './content.utilities';

const ALLOWED_HOSTNAMES = ['iotavue-demo.com', 'iotavue.site', 'iota-dev.com'];

const iframeContentBindings = bindingsDefinition({
  url: prop<string>(),
  width: prop<number>(),
  height: prop<number>(),
  viewMode: prop<boolean>(),
  updateUrl: prop.optional<(src: string) => void>(),
  updateWidth: prop.optional<(src: number) => void>(),
  updateHeight: prop.optional<(src: number) => void>(),
});

export const IframeContent: SeeqComponent<typeof iframeContentBindings> = (props) => {
  const { url, width, height, updateUrl, updateWidth, updateHeight, viewMode } = props;

  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [isHeightValid, setIsHeightValid] = useState(true);
  const [isWidthValid, setIsWidthValid] = useState(true);
  const urlInputRef = useRef(null);
  const widthInputRef = useRef(null);
  const heightInputRef = useRef(null);
  const style = { width: `${width}px`, height: `${height}px` };

  const saveSettings = () => {
    if (urlInputRef.current && _.isFunction(updateUrl) && isUrlValid) {
      updateUrl(urlInputRef.current.value);
    }
    if (widthInputRef.current && _.isFunction(updateWidth) && isWidthValid) {
      updateWidth(widthInputRef.current.value);
    }
    if (heightInputRef.current && _.isFunction(updateHeight) && isHeightValid) {
      updateHeight(heightInputRef.current.value);
    }
    if (isUrlValid && isWidthValid && isHeightValid) {
      setShowMenu(false);
    }
  };

  const validateUrl = (newUrl) => {
    try {
      const url = new URL(newUrl);
      // we need to always get the DOMAIN name not the entire subdomain (example: programmer.subdomain.iotavue.site)
      // in order to do this, we just use the last 2 elements after splitting by "."
      const domain = url.hostname.split('.').slice(-2).join('.');
      if (ALLOWED_HOSTNAMES.includes(domain)) {
        setIsUrlValid(true);
      } else {
        setIsUrlValid(false);
      }
    } catch (e) {
      setIsUrlValid(false);
    }
  };
  const validateInputAsNumber = (value: string, setFunction: (value: boolean) => void) => {
    if (_.isFinite(_.toNumber(value))) {
      setFunction(true);
    } else {
      setFunction(false);
    }
  };
  const settingsTarget = useRef(null);

  return (
    <>
      {renderBorderCommand(
        () => setShowMenu(!showMenu),
        'settingsTrigger',
        'fa-cog',
        'settingsTrigger',
        settingsTarget,
      )}
      {renderBorderCommand(
        () => window.open(url),
        { offset1: !viewMode, alwaysVisible: viewMode },
        'fa-external-link',
        'openUrlInTabTrigger',
      )}
      <div className="iframeContentContainer" style={style} data-testid="iframe_content">
        {!viewMode && showMenu && (
          <Overlay
            transition={false}
            placement="left-start"
            target={settingsTarget.current}
            rootClose={true}
            show={showMenu}
            onHide={() => setShowMenu(false)}>
            <Popover className="iframeContentPopover" id="iframe_content_popover">
              <Popover.Title>{t('REPORT.IFRAME_CONTENT.CONFIGURATION')}</Popover.Title>
              <Popover.Content className="ml10 mr10">
                <Form validated={true}>
                  <Form.Group controlId="iframe_url">
                    <Form.Label>{t('REPORT.IFRAME_CONTENT.SET_URL')}</Form.Label>
                    <Form.Control
                      defaultValue={url}
                      as="input"
                      ref={urlInputRef}
                      onChange={(e) => validateUrl(e.currentTarget.value)}
                      isInvalid={!isUrlValid}
                    />
                    {!isUrlValid && (
                      <Form.Control.Feedback type="invalid">
                        {t('REPORT.IFRAME_CONTENT.INVALID_URL')}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group controlId="iframe_height">
                    <Form.Label>{t('REPORT.IFRAME_CONTENT.SET_HEIGHT')}</Form.Label>
                    <Form.Control
                      defaultValue={height}
                      as="input"
                      ref={heightInputRef}
                      onChange={(e) => validateInputAsNumber(e.currentTarget.value, setIsHeightValid)}
                      isInvalid={!isHeightValid}
                    />
                    {!isHeightValid && (
                      <Form.Control.Feedback type="invalid">
                        {t('REPORT.IFRAME_CONTENT.INVALID_HEIGHT')}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group controlId="iframe_width">
                    <Form.Label>{t('REPORT.IFRAME_CONTENT.SET_WIDTH')}</Form.Label>
                    <Form.Control
                      defaultValue={width}
                      as="input"
                      ref={widthInputRef}
                      onChange={(e) => validateInputAsNumber(e.currentTarget.value, setIsWidthValid)}
                      isInvalid={!isWidthValid}
                    />
                    {!isWidthValid && (
                      <Form.Control.Feedback type="invalid">
                        {t('REPORT.IFRAME_CONTENT.INVALID_WIDTH')}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <TextButton
                    onClick={saveSettings}
                    label="REPORT.IFRAME_CONTENT.SAVE_SETTINGS"
                    size="sm"
                    testId="executeButton"
                    extraClassNames="min-width-100"
                    variant="theme"
                  />
                </Form>
              </Popover.Content>
            </Popover>
          </Overlay>
        )}
        <iframe src={url} data-testid="iframe_element" />
      </div>
    </>
  );
};
