// @ts-strict-ignore
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { CustomToggle, Toggler } from '@/hybrid/core/CustomToggle.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { getAllItems } from '@/hybrid/trend/trendDataHelper.utilities';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { sqTrendStore, sqWorkbenchStore } from '@/core/core.stores';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import _ from 'lodash';
import { CopyableInputField } from '@/hybrid/core/CopyableInputField.molecule';
import { toPIVision } from '@/hybrid/utilities/export.utilities';
import { EXPORTABLE_ITEM_TYPES, ITEM_DATA_STATUS } from '@/trendData/trendData.constants';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { doTrack } from '@/track/track.service';

const exportPIVisionBindings = bindingsDefinition({
  sqWorkbenchActions: injected<WorkbenchActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
});

export const ExportPIVision: SeeqComponent<typeof exportPIVisionBindings> = () => {
  const { sqWorkbenchActions, sqInvestigateActions } = useInjectedBindings(exportPIVisionBindings);

  const { t } = useTranslation();

  const { piVisionHomeURL: piVisionHomeURLFromStore } = useFlux(sqWorkbenchStore);

  const [piVisionQueryString, setPiVisionQueryString] = useState('');
  const [piVisionHomeURL, setPiVisionHomeURL] = useState(piVisionHomeURLFromStore);
  const [dataItems, setDataItems] = useState(<></>);
  const [piVisionAdHocDisplayURL, setPiVisionAdHocDisplayURL] = useState('');

  useEffect(() => {
    const items = getAllItems({
      workingSelection: sqTrendStore.hideUnselectedItems,
      excludeDataStatus: [ITEM_DATA_STATUS.FAILURE, ITEM_DATA_STATUS.REDACTED],
      itemTypes: EXPORTABLE_ITEM_TYPES,
    });
    toPIVision(items).then(({ queryString }) => {
      setPiVisionQueryString(queryString);

      setDataItems(
        queryString.lastIndexOf('\\\\') === -1 ? (
          <>{t('EXPORT_PI_VISION.NO_COMPATIBLE_ITEMS')}</>
        ) : (
          <ul>
            <li>{_.join(queryString.match(/\\\\.*?;/g)).replace(/;/g, '')}</li>
            <li>
              {queryString
                .slice(queryString.lastIndexOf('\\\\'))
                .match(/\\\\.*?\u0026/g)[0]
                .slice(0, -1)
                .replace(/;/g, '')}
            </li>
          </ul>
        ),
      );
      formatDisplayUrl(piVisionHomeURL, queryString);
    });
  }, []);

  const exportData = () => {
    doTrack('Export', 'PiVision', 'start');

    sqWorkbenchActions.setPIVisionHomeURL(piVisionHomeURL);
    window.open(piVisionAdHocDisplayURL, '_blank');
    sqInvestigateActions.close();
  };

  const isUrl = (s: string) => /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(s);

  const getHomeUrlPrepended = (url: string) => (url.match(/^https?:\/\/.*/i) ? url : `http://${url}`);

  const getHomeUrlPermissive = (url: string) => url.replace(/\/?#?\/?$/, '/#/');

  const formatDisplayUrl = (url: string, queryString = piVisionQueryString) => {
    if (!url) {
      setPiVisionAdHocDisplayURL('');
    } else {
      const newHomeUrlPrepended = getHomeUrlPrepended(url);
      const newHomeUrlPermissive = getHomeUrlPermissive(newHomeUrlPrepended);
      setPiVisionAdHocDisplayURL(newHomeUrlPermissive + queryString);
      setPiVisionHomeURL(url);
    }
  };

  const isValidPIVisionHomeURL = (url: string) => {
    if (!url) {
      return false;
    }

    const newHomeUrlPermissive = getHomeUrlPermissive(getHomeUrlPrepended(url));

    return (
      isUrl(newHomeUrlPermissive) &&
      _.endsWith(newHomeUrlPermissive, '/#/') &&
      newHomeUrlPermissive.indexOf('#') === newHomeUrlPermissive.length - 2
    );
  };

  const formDataSetup: FormElement[] = [
    {
      component: 'DisplayOnlyFormElementWrapper',
      name: 'title',
      children: (
        <div className="flexColumnContainer pt10 pl10 pr5 flexBaseline">
          <Icon icon="fc-pivision" large={true} testId="titleIcon" />
          <div className="flexRowContainer ml10">
            <h4 className="mb10">{t('EXPORT_PI_VISION.HEADER')}</h4>
            <span>{t('EXPORT_PI_VISION.TITLE')}</span>
          </div>
        </div>
      ),
    },
    {
      component: 'DisplayOnlyFormElementWrapper',
      name: 'detailsAccordion',
      children: (
        <Accordion className="pt10 pl10 pr5">
          <CustomToggle eventKey="0" Component={Toggler} label="EXPORT_PI_VISION.DETAILS.TITLE" showDivider={true} />
          <Accordion.Collapse eventKey="0" data-testid="instructions">
            <ol>
              <li>{t('EXPORT_PI_VISION.DETAILS.DESCRIPTION')}</li>
              <li>{t('EXPORT_PI_VISION.DETAILS.ENTER_PI_VISION_URL')}</li>
              <li>{t('EXPORT_PI_VISION.DETAILS.SEND_TO_PI_VISION')}</li>
              <li>{t('EXPORT_PI_VISION.DETAILS.ONLY_EXPORTS')}</li>
            </ol>
          </Accordion.Collapse>
        </Accordion>
      ),
    },
    {
      component: 'FormGroup',
      name: 'piVisionFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'FormControlFormComponent',
          name: 'piVisionHomeURL',
          testId: 'piVisionHomeURL',
          value: piVisionHomeURL,
          onChange: formatDisplayUrl,
          size: 'lg',
          label: 'EXPORT_PI_VISION.PI_VISION_HEADINGS.PI_VISION_HOME',
          extraClassNames: 'piVisionBorder',
          extendValidation: true,
          validation: (value) => !isValidPIVisionHomeURL(value as string),
        },
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'dataItems',
          children: (
            <div className="flexRowContainer">
              <span className="mt10 pb5">{t('EXPORT_PI_VISION.PI_VISION_HEADINGS.ITEMS_TO_EXPORT')}</span>
              <div className="piVisionBorder">{dataItems}</div>
            </div>
          ),
        },
        {
          component: 'DisplayOnlyFormElementWrapper',
          name: 'piVisionAdHocDisplayURL',
          children: (
            <div className="flexRowContainer">
              <span className="mt10 pb5">{t('EXPORT_PI_VISION.PI_VISION_HEADINGS.AD_HOC_DISPLAY')}</span>
              <CopyableInputField
                testId="piVisionAdHocDisplayURL"
                readOnly={true}
                fieldTooltip={piVisionAdHocDisplayURL}
                value={piVisionAdHocDisplayURL}
                notifyMessage="EXPORT_PI_VISION.LINK_COPIED"
                buttonTooltip="COPY"
              />
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={exportData}
      closeFn={sqInvestigateActions.close}
      toolId={TREND_TOOLS.EXPORT_PI_VISION}
      submitBtnId="exportPIVisionButton"
      submitBtnLabel="EXPORT"
    />
  );
};

export const sqExportPiVision = angularComponent(exportPIVisionBindings, ExportPIVision);
