// @ts-strict-ignore
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import classNames from 'classnames';
import React from 'react';
import { sqTrendCapsuleSetStore } from '@/core/core.stores';

const referenceCapsuleIconBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  item: prop<any>(),
  extraClassNames: prop<string>(),
});

export const ReferenceCapsuleIcon: SeeqComponent<typeof referenceCapsuleIconBindings> = ({ item, extraClassNames }) => {
  const { sqInvestigateActions } = useInjectedBindings(referenceCapsuleIconBindings);

  const iconColor = item.color;

  /**
   * Edits the item
   */
  const editItem = () => {
    sqInvestigateActions.loadToolForEdit(sqTrendCapsuleSetStore.findItem(item.isChildOf).id);
  };

  return (
    <Icon
      icon="icon fc fc-search-pattern"
      extraClassNames={classNames('sq-text-info', extraClassNames)}
      type={iconColor ? 'color' : undefined}
      color={iconColor}
      onClick={(e) => {
        editItem();
        e.stopPropagation();
      }}
      tooltip="PROFILE_SEARCH.SEARCH_PATTERN"
      tooltipPlacement="bottom"
      testId="referenceCapsuleIcon"
    />
  );
};
