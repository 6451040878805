// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { Icon } from '@/hybrid/core/Icon.atom';
import { Placement } from 'react-bootstrap/Overlay';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';

interface ToolbarButtonProps {
  /** function to call when clicking the button (takes no parameters) */
  onClick?: () => any;
  /** icon class to be used for the button (i.e. 'fc-zoom') */
  icon: string;
  /** label for the toolbar item (shown only when toolbar is wide enough) */
  label: string;
  /** true to give the button the "pressed" look */
  active?: boolean;
  /** true to render an icon-only dropdown button */
  isSmall?: boolean;
  /** true to render button as disabled */
  disabled?: boolean;
  /** extra class names to be placed on the Button component */
  extraClassNames?: string;
  /** an id that can be placed on the Button component */
  id?: string;
  testId?: string;
  /** second icon class to be used for the button (i.e. 'fc-zoom') */
  secondIcon?: string;
  tooltipText?: string;
  tooltipPlacement?: Placement;
}

/**
 * Toolbar button for single click action (e.g. trend toolbar zoom)
 */
export const ToolbarButton: React.FunctionComponent<ToolbarButtonProps> = ({
  onClick,
  label,
  active,
  disabled,
  extraClassNames,
  icon,
  isSmall = false,
  id,
  testId,
  tooltipText,
  secondIcon = undefined,
  tooltipPlacement = 'bottom',
}) => {
  const { t } = useTranslation();

  return (
    <HoverTooltip text={tooltipText} placement={tooltipPlacement}>
      <div className={classNames({ buttonDisabledTooltip: !!disabled })}>
        <Button
          className={classNames(extraClassNames, 'flexRowContainer', 'flexAlignCenter', 'sq-btn-toolbar', 'btn', {
            'sq-btn-toolbar-sm flexJustifyCenter': isSmall,
          })}
          onClick={onClick}
          disabled={disabled}
          active={active}
          type="button"
          size="sm"
          variant={null}
          bsPrefix="sq-btn"
          id={id}
          data-testid={testId}>
          <div className="flexColumnContainer flexAlignCenter height-15 mt2">
            <Icon icon={icon} type="text" testId="firstIcon" large={!secondIcon} />
            {secondIcon && <Icon icon={secondIcon} type="text" testId="secondIcon" />}
          </div>
          {!isSmall && <small>{t(label)}</small>}
        </Button>
      </div>
    </HoverTooltip>
  );
};
