// @ts-strict-ignore
import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import { APPSERVER_API_PREFIX, GUID_REGEX_PATTERN } from '@/main/app.constants';
import { formatTime, parseISODate } from '@/hybrid/datetime/dateTime.utilities';
import { formatAsQueryString } from '@/hybrid/utilities/httpHelpers.utilities';
import { AnnotationOutputV1 } from '@/sdk';
import { areAssetSelectionsSimilar, areDateRangesSimilar } from '@/hybrid/utilities/utilities';
import { InitializeMode, PersistenceLevel, Store } from '@/core/flux.service';
import {
  AssetSelection,
  Content,
  ContentDisplayMetadata,
  DateRange,
  DateRangeSwapInfo,
  InteractiveReportContent,
  KEEP_CURRENT_ASSET_SELECTION,
  KEEP_CURRENT_DATE_RANGE,
  KEEP_CURRENT_INTERACTIVE,
  KEEP_CURRENT_SCALE,
  KEEP_CURRENT_SHAPE,
  KEEP_CURRENT_SIZE,
  KEEP_CURRENT_SUMMARY,
  QUARTZ_CRON_PENDING_INPUT,
  ReportSchedule,
  SandboxMode,
} from '@/reportEditor/report.constants';
import { sqWorkbenchStore, sqWorksheetStore } from '@/core/core.stores';
import { BulkEditMode } from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/reportContent.constants';

export enum ReportEditingState {
  Saved = 'Saved',
  Saving = 'Saving',
  Offline = 'Offline',
  Stale = 'Stale',
}

export enum ReportEditingStateEvent {
  SaveComplete = 'SaveComplete',
  SaveStarted = 'SaveStarted',
  Offline = 'Offline',
  Online = 'Online',
}

export enum ASSET_SELECTION_WARNING_STATUS {
  DISMISSED,
  ICONS,
  MESSAGES,
  MIXED,
}

const IMAGE_SRC_REGEX = new RegExp(`src="${APPSERVER_API_PREFIX}/content/(${GUID_REGEX_PATTERN})/image"`, 'g');

export class ReportStore extends Store {
  static readonly storeName = 'sqReportStore';
  persistenceLevel: PersistenceLevel = 'NONE';

  initialize(initializeMode: InitializeMode) {
    const saveState = this.state && initializeMode !== 'FORCE';
    this.state = this.immutable({
      imageStateChanges: 0, // Let listeners know that the report changed (e.g. image state changes)
      isLoadingReport: false,
      dateRanges: [],
      content: [],
      contentErrors: [],
      assetSelections: [],
      comments: [],
      reportSchedule: undefined,
      nextRunTime: null,
      scheduledUpdateCount: null,
      lastSavedTimezone: null,
      canRevertToFroala: false,
      dateRangesNotArchived: this.monkey(['dateRanges', (dateRanges) => _.reject(dateRanges, 'isArchived')]),
      contentNotArchived: this.monkey(['content', (content) => _.reject(content, 'isArchived')]),
      assetSelectionsNotArchived: this.monkey(['assetSelections', (selection) => _.reject(selection, 'isArchived')]),
      hasReportSchedule: this.monkey(['reportSchedule'], (reportSchedule) => !_.isEmpty(reportSchedule?.cronSchedule)),
      hasDateRangeSchedule: this.monkey(['dateRangesNotArchived'], (dateRanges: DateRange[]) =>
        _.some(dateRanges, (dateRange) => {
          const schedule = _.get(dateRange, 'auto.cronSchedule');
          return schedule && !_.isEmpty(schedule) && !_.isEqual(schedule, [QUARTZ_CRON_PENDING_INPUT]);
        }),
      ),
      hasMultipleSchedules: this.monkey(
        ['hasReportSchedule'],
        ['dateRangesNotArchived'],
        (hasReportSchedule: boolean, dateRanges: DateRange[]) => {
          if (hasReportSchedule) return false;

          return (
            _.chain(dateRanges)
              .filter((dateRange) => dateRange?.auto?.enabled && !_.isEmpty(dateRange?.auto?.cronSchedule))
              .map((dateRange) => dateRange?.auto?.cronSchedule?.sort()?.join('|')) // join array as string for easier
              // comparison
              .thru((schedules) => new Set(schedules))
              .value().size > 1
          );
        },
      ),
      isScheduleEnabled: this.monkey(
        ['hasReportSchedule'],
        ['reportSchedule'],
        ['hasDateRangeSchedule'],
        ['dateRangesNotArchived'],
        (hasReportSchedule: boolean, reportSchedule: any, hasDateRangeSchedule: boolean, dateRanges: DateRange[]) => {
          const scheduleConfigured = hasReportSchedule || hasDateRangeSchedule;
          if (!scheduleConfigured) {
            // If a schedule isn't configured yet, then we shouldn't consider the schedule "disabled".
            return true;
          } else if (hasReportSchedule) {
            return reportSchedule.enabled;
          } else if (hasDateRangeSchedule) {
            return _.every(dateRanges, (dateRange) => dateRange?.enabled);
          }
        },
      ),
      hasAutoDateRanges: this.monkey(['dateRangesNotArchived'], (dateRanges: DateRange[]) => {
        return !_.isEmpty(dateRanges.filter((dateRange) => dateRange?.auto?.enabled));
      }),
      hasFixedDateRanges: this.monkey(['dateRangesNotArchived'], (dateRanges: DateRange[]) => {
        return !_.chain(dateRanges)
          .reject((dateRange) => dateRange?.auto?.enabled)
          .isEmpty()
          .value();
      }),
      hasLiveOrScheduledContent: this.monkey(
        ['liveOrScheduledContent'],
        (liveOrScheduledContent: Content[]) => liveOrScheduledContent.length > 0,
      ),
      liveOrScheduledContent: this.monkey(
        ['contentNotArchived'],
        ['dateRangesNotArchived'],
        (content: Content[], dateRanges: DateRange[]): Content[] => {
          return _.chain(dateRanges)
            .filter((dateRange) => dateRange?.auto?.enabled)
            .map((dateRanges) => _.filter(content, ['dateRangeId', dateRanges.id]))
            .flatten()
            .value();
        },
      ),
      hasLiveContent: this.monkey(
        ['contentNotArchived'],
        ['dateRangesNotArchived'],
        ['reportSchedule'],
        ['hasReportSchedule'],
        ['isScheduleEnabled'],
        (content: Content[], dateRanges: DateRange[], reportSchedule, hasReportSchedule, isScheduleEnabled) => {
          if (!hasReportSchedule || reportSchedule.background || !isScheduleEnabled) {
            return false;
          }
          return (
            _.chain(dateRanges)
              .filter((dateRange) => dateRange?.auto?.enabled)
              .map((dateRanges) => _.filter(content, ['dateRangeId', dateRanges.id]))
              .flatten()
              .value().length > 0
          );
        },
      ),
      hasFixedContent: this.monkey(
        ['contentNotArchived'],
        ['dateRangesNotArchived'],
        (content: Content[], dateRanges: DateRange[]) => {
          return (
            _.chain(dateRanges)
              .reject((dateRange) => dateRange?.auto?.enabled)
              .map((dateRanges: any) => _.filter(content, ['dateRangeId', dateRanges.id]))
              .flatten()
              .value().length > 0
          );
        },
      ),
      dateRangeToContentMap: this.monkey(['contentNotArchived'], (content) => {
        return _.groupBy(content, (content) => (content.dateRangeId ? content.dateRangeId : 'none'));
      }),
      assetSelectionToContentMap: this.monkey(['contentNotArchived'], (content) => {
        return _.groupBy(content, (content) => (content.assetSelectionId ? content.assetSelectionId : 'none'));
      }),
      backups: [],
      // In fast-follow mode we have to keep this id around so that we can make a request for the rest of the data
      id: saveState ? this.state.get('id') : undefined,
      editingState: ReportEditingState.Saved,
      isFixedWidth: false,
      bulkEditDisplayMode: BulkEditMode.DO_NOT_SHOW,
      bulkInteractive: KEEP_CURRENT_INTERACTIVE,
      bulkShape: KEEP_CURRENT_SHAPE,
      bulkScale: KEEP_CURRENT_SCALE,
      bulkSize: KEEP_CURRENT_SIZE,
      bulkDateRange: KEEP_CURRENT_DATE_RANGE,
      bulkWidth: 500,
      bulkHeight: 500,
      bulkSummary: KEEP_CURRENT_SUMMARY,
      bulkAssetSelection: KEEP_CURRENT_ASSET_SELECTION,
      shouldUpdateBulkWorkstep: false,
      selectedBulkContent: [],
      shouldShowConfigureAutoUpdateModal: false,
      showConfigureAutoUpdateModal: false,
      reportScheduleOverride: false,
      contentDisplayMetadata: [],
      contentShowWarningMessage: {},
      sandboxMode: {
        enabled: false,
        originalWorksheetId: null,
        sandboxedWorksheetId: null,
        sandboxedWorkbookId: null,
      },
      activeDateRangeSwapInfo: {
        currentSwap: undefined,
        potentialSwaps: [],
      },
      showChooseAssetSwapModal: false,
      showChooseCapsuleModal: false,
      assetSelectionShowWarningStatus: ASSET_SELECTION_WARNING_STATUS.ICONS,
    });
  }

  get sandboxMode() {
    return this.state.get('sandboxMode');
  }

  get assetSelections() {
    return this.state.get('assetSelections');
  }

  get assetSelectionsNotArchived() {
    return this.state.get('assetSelectionsNotArchived');
  }

  get id() {
    return this.state.get('id');
  }

  get createdBy() {
    return this.state.get('createdBy');
  }

  get renderer() {
    return this.state.get('renderer');
  }

  get createdAt() {
    return this.state.get('createdAt');
  }

  get updatedAt() {
    return this.state.get('updatedAt');
  }

  get document(): string {
    return this.state.get('document');
  }

  get isLoadingReport(): boolean {
    return this.state.get('isLoadingReport');
  }

  get comments() {
    return this.state.get('comments');
  }

  get nextRunTime() {
    return this.state.get('nextRunTime');
  }

  get scheduledUpdateCount() {
    return this.state.get('scheduledUpdateCount');
  }

  get content() {
    return this.state.get('content');
  }

  get contentErrors() {
    return this.state.get('contentErrors');
  }

  get forbiddenContentErrorsCount() {
    return _.filter(this.state.get('contentDisplayMetadata'), {
      errorCode: HttpCodes.FORBIDDEN,
    }).length;
  }

  get isReportScheduleError() {
    return !_.isEmpty(this.state.get('reportScheduleError'));
  }

  get sandboxOriginalCreatorName() {
    return this.state.get('sandboxMode', 'sandboxOriginalCreatorName');
  }

  get contentNotArchived(): Content[] {
    return this.state.get('contentNotArchived');
  }

  get contentWorkbookIds() {
    return _.chain(this.state.get('contentNotArchived'))
      .map('workbookId')
      .concat(sqWorkbenchStore.stateParams.workbookId)
      .uniq()
      .value();
  }

  get dateRanges(): DateRange[] {
    return this.state.get('dateRanges');
  }

  get dateRangesNotArchived(): DateRange[] {
    return this.state.get('dateRangesNotArchived');
  }

  get backups() {
    return this.state.get('backups');
  }

  get backupPreview() {
    return this.state.get('backupPreview');
  }

  get dateRangeUpdating() {
    return this.state.get('dateRangeUpdating');
  }

  get editingState() {
    return this.state.get('editingState');
  }

  getContentById(contentId) {
    return _.find(this.state.get('content'), ['id', contentId]);
  }

  getDateRangeById(dateRangeId): DateRange {
    return _.find(this.state.get('dateRanges'), ['id', dateRangeId]);
  }

  hasInUseDateRanges() {
    const ranges = this.state.get('dateRanges');
    const dateRangeToContentMap = this.state.get('dateRangeToContentMap');
    return _.some(ranges, (dateRange) => _.get(dateRangeToContentMap, dateRange.id, []).length > 0);
  }

  hasInUseAssetSelections() {
    const selections = this.state.get('assetSelections');
    const selectionToContentMap = this.state.get('assetSelectionToContentMap');
    return _.some(selections, (selection) => _.get(selectionToContentMap, selection.id, []).length > 0);
  }

  /**
   * Two date ranges are considered similar if they have matching conditions, matching durations, and both are
   * auto or both are not auto. To prevent duplication, dateRanges with different ranges (but same duration),
   * different offset, different auto.chronSchedule, different auto.background, etc., are all considered similar.
   *
   * @param dateRange - The date range we want to find a suitable alternative for
   * @returns - A similar dateRange if it exists, otherwise undefined.
   */
  findSimilarDateRange(dateRange: DateRange): DateRange | undefined {
    return _.find(this.state.get('dateRanges'), (dr) => areDateRangesSimilar(dr, dateRange));
  }

  /**
   * Sometimes we need to find a daterange that has the same name (switch to sandbox mode)
   * @param name name to search by
   * @returns
   */

  findDateRangeByName(name: string): DateRange | undefined {
    return _.find(this.state.get('dateRanges'), ['name', name]);
  }

  /**
   * Finds the first asset selection which has the same assetId. Archived asset selections are ignored.
   * @param assetSelection - the source asset selection
   * @returns - The closest asset selection if exists, else undefined
   */
  findSimilarAssetSelection(assetSelection: AssetSelection): AssetSelection | undefined {
    const assetSelections = this.state.get('assetSelections');
    return _.find(assetSelections, (sel) => areAssetSelectionsSimilar(assetSelection, sel));
  }

  /**
   * Computes a non conflicting asset selection name by adding '(n)' to the name if the asset selection name
   * already exits.
   * @param assetSelectionName - the asset selection name
   * @returns - The original name if it does not exist, otherwise the name with a '(n)' suffix.
   */
  computeNonConflictingAssetSelectionName(assetSelectionName: string): string {
    const assetSelectionNames = _.map(this.state.get('assetSelections'), (selection) => selection.name);

    let index = 1;
    let nonConflictingName = assetSelectionName;
    while (_.some(assetSelectionNames, (name) => name === nonConflictingName)) {
      nonConflictingName = `${assetSelectionName} (${index++})`;
    }

    return nonConflictingName;
  }

  isDateRangeUpdating(dateRangeId): boolean {
    return _.get(_.find(this.state.get('dateRanges'), ['id', dateRangeId]), 'auto.enabled', false);
  }

  get hasMultipleSchedules(): boolean {
    return this.state.get('hasMultipleSchedules');
  }

  get reportSchedule(): ReportSchedule | undefined {
    return this.state.get('reportSchedule');
  }

  get hasReportSchedule(): boolean {
    return this.state.get('hasReportSchedule');
  }

  get isScheduleEnabled(): boolean {
    return this.state.get('isScheduleEnabled');
  }

  get hasFixedDateRanges(): boolean {
    return this.state.get('hasFixedDateRanges');
  }

  get hasAutoDateRanges(): boolean {
    return this.state.get('hasAutoDateRanges');
  }

  get liveOrScheduledContent(): Content[] {
    return this.state.get('liveOrScheduledContent');
  }

  get hasLiveOrScheduledContent(): boolean {
    return this.state.get('hasLiveOrScheduledContent');
  }

  get hasLiveContent(): boolean {
    return this.state.get('hasLiveContent');
  }

  get hasFixedContent(): boolean {
    return this.state.get('hasFixedContent');
  }

  get dateRangeToContentMap() {
    return this.state.get('dateRangeToContentMap');
  }

  get assetSelectionToContentMap() {
    return this.state.get('assetSelectionToContentMap');
  }

  contentUsingAssetSelection(assetSelectionId = 'none') {
    return _.get(this.state.get('assetSelectionToContentMap'), assetSelectionId, []);
  }

  contentUsingDateRange(dateRangeId = 'none') {
    return _.get(this.state.get('dateRangeToContentMap'), dateRangeId, []);
  }

  getWorksheetUrl(contentId): string {
    return `${APPSERVER_API_PREFIX}/content/${contentId}/sourceUrl`;
  }

  get hasAssetSelectionWarnings(): boolean {
    return _.some(this.state.get('contentNotArchived'), 'screenshotWarning');
  }

  get numAssetSelectionWarnings(): number {
    return _.filter(this.state.get('contentNotArchived'), 'screenshotWarning').length;
  }

  get canRevertToFroala() {
    return this.state.get('canRevertToFroala');
  }

  get isFixedWidth(): boolean {
    return this.state.get('isFixedWidth');
  }

  get isCkEnabled(): boolean {
    return this.state.get('isCkEnabled');
  }

  get isBulkEditAdvancedOnly(): boolean {
    return this.state.get('bulkEditDisplayMode') === BulkEditMode.ADVANCED_ONLY;
  }

  get bulkEditDisplayMode(): BulkEditMode {
    return this.state.get('bulkEditDisplayMode');
  }

  get showBulkEditModal(): boolean {
    return this.state.get('bulkEditDisplayMode') !== BulkEditMode.DO_NOT_SHOW;
  }

  get bulkInteractive(): InteractiveReportContent {
    return this.state.get('bulkInteractive');
  }

  get bulkScale(): any {
    return this.state.get('bulkScale');
  }

  get bulkShape(): any {
    return this.state.get('bulkShape');
  }

  get bulkSize(): any {
    return this.state.get('bulkSize');
  }

  get bulkWidth(): any {
    return this.state.get('bulkWidth');
  }

  get bulkHeight(): any {
    return this.state.get('bulkHeight');
  }

  get bulkDateRange(): any {
    return this.state.get('bulkDateRange');
  }

  get bulkSummary(): any {
    return this.state.get('bulkSummary');
  }

  get bulkAssetSelection(): any {
    return this.state.get('bulkAssetSelection');
  }

  get shouldUpdateBulkWorkstep(): boolean {
    return this.state.get('shouldUpdateBulkWorkstep');
  }

  get selectedBulkContent(): any {
    return this.state.get('selectedBulkContent');
  }

  get shouldShowConfigureAutoUpdateModal(): boolean {
    return this.state.get('shouldShowConfigureAutoUpdateModal');
  }

  get showConfigureAutoUpdateModal(): boolean {
    return this.state.get('showConfigureAutoUpdateModal');
  }

  get showChooseAssetSwapModal(): boolean {
    return this.state.get('showChooseAssetSwapModal');
  }

  get activeDateRangeSwapInfo(): any {
    return this.state.get('activeDateRangeSwapInfo');
  }

  get showChooseCapsuleModal(): boolean {
    return this.state.get('showChooseCapsuleModal');
  }

  get reportScheduleOverride(): boolean {
    return this.state.get('reportScheduleOverride');
  }

  getContentDisplayMetadataById(contentId: string): ContentDisplayMetadata {
    return _.find(this.state.get('contentDisplayMetadata'), ['contentId', contentId]);
  }

  hasShowWarningMessage(contentId: string): boolean {
    return this.state.get('contentShowWarningMessage', contentId) ?? false;
  }

  getAssetSelectionById(assetSelectionId: string) {
    return _.find(this.state.get('assetSelections'), ['id', assetSelectionId]);
  }

  get assetSelectionShowWarningStatus(): ASSET_SELECTION_WARNING_STATUS {
    const someExpanded = _.some(this.state.get('contentNotArchived'), (content) =>
      content?.id ? this.state.get('contentShowWarningMessage', content.id) : false,
    );

    const someCollapsed = _.some(this.state.get('contentNotArchived'), (content) =>
      content?.id ? !this.state.get('contentShowWarningMessage', content.id) : true,
    );

    if (someExpanded && someCollapsed) {
      return ASSET_SELECTION_WARNING_STATUS.MIXED;
    } else if (someExpanded) {
      return ASSET_SELECTION_WARNING_STATUS.MESSAGES;
    } else {
      return ASSET_SELECTION_WARNING_STATUS.ICONS;
    }
  }

  /**
   * Sets the data in the state tree if the data and the current state fail a deep equality check. This should be
   * used for data that is pushed into the store frequently, but which is likely not to be changed (such as after
   * each save of a document as a user types). The benefit of not setting data that hasn't changed is that React
   * components (which rely on shallow object comparison) won't have a re-render triggered. While there is some
   * cost to the deep equality check, performance tests show it is faster or the same as the time to set the data
   * in the immutable store.
   *
   * @param stateKey - The key in the state tree to get/set the data
   * @param data - The new data to set, if different from current data
   */
  setIfNotEqual(stateKey: string, data: any) {
    if (!_.isEqual(this.state.get(stateKey), data)) {
      this.state.set(stateKey, data);
    }
  }

  /**
   * Generates an image URL for a piece of content that allows for the image to be fetched async and that solves
   * caching issues. The hash code as a query param is necessary to ensure that the image is not re-fetched
   * needlessly, such as when only the text has been updated in a concurrent editing situation. However, if the image
   * has been updated then the hash code will change and thus the image will be forced to be re-fetched.
   *
   * @param {string} contentId - The id of the content
   * @param {boolean} useAsync - If the image should be fetched async. Should only be used by the preview window
   * which does not have the problem on bottlenecking on concurrent requests to the same host.
   */
  getContentImageUrl(contentId, useAsync = true): string {
    const content: Content = _.find(this.state.get('content'), ['id', contentId]);
    const hashCode = content?.hashCode;
    const params = formatAsQueryString({
      useAsync: useAsync ? true : undefined,
      hash: hashCode,
    });
    return `${APPSERVER_API_PREFIX}/content/${contentId}/${content?.isReact ? 'react' : 'image'}${
      _.isEmpty(params) ? '' : `?${params}`
    }`;
  }

  protected readonly handlers = {
    REPORT_SET_BULK_EDIT_DISPLAY_MODE: ({ bulkEditDisplayMode }: { bulkEditDisplayMode: BulkEditMode }) => {
      this.state.set('bulkEditDisplayMode', bulkEditDisplayMode);
    },

    REPORT_SET_BULK_INTERACTIVE: ({ bulkInteractive }: { bulkInteractive: InteractiveReportContent }) => {
      this.state.set('bulkInteractive', bulkInteractive);
    },

    REPORT_SET_BULK_SHAPE: ({ bulkShape }: { bulkShape: string }) => {
      this.state.set('bulkShape', bulkShape);
    },

    REPORT_SET_BULK_SIZE: ({ bulkSize }: { bulkSize: string }) => {
      this.state.set('bulkSize', bulkSize);
    },

    REPORT_SET_BULK_SCALE: ({ bulkScale }) => {
      this.state.set('bulkScale', bulkScale);
    },

    REPORT_SET_BULK_HEIGHT: ({ bulkHeight }) => {
      this.state.set('bulkHeight', bulkHeight);
    },

    REPORT_SET_BULK_WIDTH: ({ bulkWidth }) => {
      this.state.set('bulkWidth', bulkWidth);
    },

    REPORT_SET_BULK_DATE_RANGE: ({ bulkDateRange }) => {
      this.state.set('bulkDateRange', bulkDateRange);
    },

    REPORT_SET_SHOULD_UPDATE_BULK_WORKSTEP: ({ shouldUpdateBulkWorkstep }) => {
      this.state.set('shouldUpdateBulkWorkstep', shouldUpdateBulkWorkstep);
    },

    REPORT_SET_SELECTED_BULK_CONTENT: ({ selectedBulkContent }) => {
      this.state.set('selectedBulkContent', selectedBulkContent);
    },

    REPORT_SET_BULK_SUMMARY: ({ bulkSummary }) => {
      this.state.set('bulkSummary', bulkSummary);
    },

    REPORT_SET_BULK_ASSET_SELECTION: ({ bulkAssetSelection }) => {
      this.state.set('bulkAssetSelection', bulkAssetSelection);
    },

    REPORT_SET_CAN_REVERT_TO_FROALA: (payload: { canRevertToFroala: boolean }) => {
      this.state.set('canRevertToFroala', payload.canRevertToFroala);
    },

    REPORT_SET_SHOULD_SHOW_CONFIGURE_AUTO_UPDATE_MODAL: ({ shouldShowConfigureAutoUpdateModal }) => {
      this.state.set('shouldShowConfigureAutoUpdateModal', shouldShowConfigureAutoUpdateModal);
    },

    REPORT_SET_SHOW_ASSET_SWAP_MODAL: (showModal: boolean) => {
      this.state.set('showChooseAssetSwapModal', showModal);
    },

    REPORT_SET_SWAP_RANGE_INFO: (payload: { currentSwap: DateRangeSwapInfo; potentialSwaps: DateRangeSwapInfo[] }) => {
      this.state.set('activeDateRangeSwapInfo', payload);
    },

    REPORT_SET_SHOW_CAPSULE_MODAL: (showModal: boolean) => {
      this.state.set('showChooseCapsuleModal', showModal);
    },

    REPORT_SET_SHOW_CONFIGURE_AUTO_UPDATE_MODAL: ({ showConfigureAutoUpdateModal, reportScheduleOverride = false }) => {
      this.state.set('reportScheduleOverride', reportScheduleOverride);
      this.state.set('showConfigureAutoUpdateModal', showConfigureAutoUpdateModal);
    },

    REPORT_TOGGLE_SPECIFIC_SELECTED_CONTENT: (content) => {
      const index = _.findIndex(this.state.get('selectedBulkContent'), ['id', content.id]);
      if (index >= 0) {
        this.state.splice('selectedBulkContent', [index, 1]);
      } else {
        this.state.push('selectedBulkContent', content);
      }
    },

    REPORT_UPDATE_CLEAR_BULK_PROPERTIES: () => {
      this.state.set('bulkEditDisplayMode', BulkEditMode.DO_NOT_SHOW);
      this.state.set('bulkInteractive', KEEP_CURRENT_INTERACTIVE);
      this.state.set('bulkShape', KEEP_CURRENT_SHAPE);
      this.state.set('bulkScale', KEEP_CURRENT_SCALE);
      this.state.set('bulkSize', KEEP_CURRENT_SIZE);
      this.state.set('selectedBulkContent', []);
      this.state.set('bulkHeight', 500);
      this.state.set('bulkWidth', 500);
      this.state.set('bulkDateRange', KEEP_CURRENT_DATE_RANGE);
      this.state.set('bulkAssetSelection', KEEP_CURRENT_ASSET_SELECTION);
      this.state.set('bulkSummary', KEEP_CURRENT_SUMMARY);
      this.state.set('shouldUpdateBulkWorkstep', false);
    },

    REPORT_IMAGE_STATE_CHANGED: () => {
      const count = this.state.get('imageStateChanges');
      this.state.set('imageStateChanges', count + 1);
    },

    /**
     * Sets the report
     *
     * @param {Object} report - the report object
     * @param {String} report.id - the report id
     * @param {String} report.name - the report name
     * @param {String} report.createdBy - the report createdBy name
     * @param {String} report.renderer - the report renderer name
     * @param {String} report.createdAt - the report createdAt timestamp
     * @param {String} report.updatedAt - the report updatedAt timestamp
     * @param {String} report.document - the report document
     * @param {String} report.comments - the report comments
     * @param {String} [report.cronSchedule] - the report cronSchedule
     * @param {boolean} [report.background] - whether report's schedule runs when there are no listeners to its channel
     * @param {String} [report.nextRunTime] - the report's next run time
     * @param {String} report.backups - the report backups
     */
    REPORT_SET: (report: AnnotationOutputV1) => {
      this.state.set('id', report.id);
      this.state.set('name', report.name);
      this.state.set('createdBy', report.createdBy);
      this.state.set('renderer', report.createdBy);
      this.state.set('createdAt', report.createdAt);
      this.state.set('updatedAt', report.updatedAt);
      this.state.set(
        'document',
        report.document?.replace(IMAGE_SRC_REGEX, (match, contentId) => `src="${this.getContentImageUrl(contentId)}"`),
      );
      this.setIfNotEqual('comments', report.replies);
      this.state.set('reportSchedule', _.pick(report, ['cronSchedule', 'background', 'enabled']));
      this.state.set('nextRunTime', report.nextRunTime);
      this.state.set('isFixedWidth', report.fixedWidth);
      this.state.set('isCkEnabled', report.ckEnabled);

      // Sort backups in reverse chronological order and add a formatted date string
      this.state.set(
        'backups',
        _.chain(report.backups)
          .sortBy((backup) => -parseISODate(backup.backupDate))
          .map((backup) => {
            return _.merge({}, backup, {
              formattedBackupDate: formatTime(parseISODate(backup.backupDate), sqWorksheetStore.timezone),
            });
          })
          .value(),
      );
    },

    /**
     * Set all asset selections. Will overwrite asset selections already in the store
     */
    REPORT_SET_ALL_ASSET_SELECTIONS: (payload: AssetSelection[]) => {
      this.setIfNotEqual('assetSelections', payload);
    },

    /**
     * Add or update a given asset selection
     */
    REPORT_SET_ASSET_SELECTION: (payload: AssetSelection) => {
      const index = _.findIndex(this.state.get('assetSelections'), {
        id: payload.id,
      });
      if (index >= 0) {
        this.state.set(['assetSelections', index], payload);
      } else {
        this.state.push('assetSelections', payload);
      }
    },

    /**
     * Adds (or updates) an individual piece of Seeq Content in this store, for caching purposes.
     *
     * @param {Object} payload - Seeq Content
     * @param {String} payload.id - ID of Seeq Content
     */
    REPORT_SET_CONTENT: (payload) => {
      const index = _.findIndex(this.state.get('content'), ['id', payload.id]);
      if (index >= 0) {
        this.state.set(['content', index], payload);
      } else {
        this.state.push('content', payload);
      }
    },

    /**
     * Adds all Seeq Content items to the store, for caching purposes
     *
     * @param {Object[]} payload - array of Seeq Content items
     */
    REPORT_SET_ALL_CONTENT: (payload) => {
      this.setIfNotEqual('content', payload);
    },

    /**
     * Specify whether the report store is in the middle of loading
     *
     * @param {boolean} isLoadingReport - True if report is in middle of loading
     */
    REPORT_SET_IS_LOADING: (isLoadingReport: boolean) => {
      this.state.set('isLoadingReport', isLoadingReport);
    },

    /**
     * Updates the height and width of content with the rendered size. Content specified must have
     * .useSizeFromRender set to true.
     *
     * @param {string} payload.contentId - ID of content to update
     * @param {number} payload.height - height of rendered content, in pixels
     * @param {number} payload.width - width of rendered content, in pixels
     */
    REPORT_SET_CONTENT_RENDER_SIZE: ({ contentId, height, width }) => {
      const index = _.findIndex(this.state.get('content'), ['id', contentId]);

      if (index >= 0) {
        let content = this.state.get(['content', index]);
        if (content.useSizeFromRender) {
          content = { ...content, height, width };
          this.state.set(['content', index], content);
        }
      }
    },

    /**
     * Sets the hash code for the given piece of content.
     *
     * @param {string} payload.contentId - ID of the content object to update
     * @param {string} payload.hashCode - The unique identifier for the current variant of the image
     */
    REPORT_SET_CONTENT_HASH_CODE: ({ contentId, hashCode }) => {
      const index = _.findIndex(this.state.get('content'), ['id', contentId]);
      if (index >= 0) {
        this.state.set(['content', index, 'hashCode'], hashCode);
        // Bizarrely, if we don't commit here, the CK Image component's useEffect hook which listens on the hashCode
        // will trigger every once in a while as opposed to every time. As utterly baffling as that is, I am not
        // able to explain why - Ryan L.
        this.state.commit();
      }
    },

    REPORT_SET_CONTENT_WARNING: ({ contentId, warning }) => {
      const index = _.findIndex(this.state.get('content'), ['id', contentId]);
      if (index >= 0) {
        this.state.set(['content', index, 'screenshotWarning'], warning);
      }
    },

    /**
     * Updates the timezone for the given piece of content.
     *
     * @param {string} payload.contentId - ID of the content object to update
     * @param {string} payload.timezone - The name of the desired timezone
     */
    REPORT_UPDATE_CONTENT_TIMEZONE: ({ contentId, timezone }) => {
      const index = _.findIndex(this.state.get('content'), ['id', contentId]);
      if (index >= 0) {
        this.state.merge(['content', index], { timezone });
      }
    },

    /**
     *
     * @param payload - object containing:
     *  contentId - If falsy, all content in this worksheet will have showWarningMessage updated
     *  showWarningMessage - If true, warning message is shown for specified piece of content. If not, the warning
     *  message is not shown.
     */
    REPORT_SET_CONTENT_SHOW_WARNING_MESSAGE: (payload: { contentId: string; showWarningMessage: boolean }) => {
      if (!payload.contentId) {
        _.forEach(this.state.get('content'), (content) => {
          this.state.set(['contentShowWarningMessage', content.id], payload.showWarningMessage);
        });
      } else {
        if (payload.showWarningMessage) {
          this.state.set(['contentShowWarningMessage', payload.contentId], true);
        } else {
          this.state.unset(['contentShowWarningMessage', payload.contentId]);
        }
      }
    },

    /**
     * Removes all content from the store
     */
    REPORT_REMOVE_ALL_CONTENT: () => {
      this.state.set('content', []);
    },

    /**
     * Sets the report renderer
     *
     * @param {Object} payload - an object container
     * @param {Object} payload.renderer - the renderer identity
     */
    REPORT_SET_RENDERER: (payload) => {
      this.state.set('renderer', payload.renderer);
    },

    /**
     * Updates or adds the date variable in the list of date variables.
     *
     * @param {Object} dateRange - date range to set
     */
    REPORT_SET_DATE_RANGE: (dateRange: DateRange) => {
      // The range will be empty if this date range is defined by a capsule but no capsule was found.
      if (_.isEmpty(dateRange.range) && _.get(dateRange, 'auto.enabled')) {
        _.set(dateRange, 'auto.noCapsuleFound', true);
      }

      const index = _.findIndex(this.state.get('dateRanges'), ['id', dateRange.id]);

      if (index >= 0) {
        this.state.set(['dateRanges', index], dateRange);
      } else {
        this.state.push('dateRanges', dateRange);
      }
    },

    /**
     * Sets the list of date ranges
     *
     * @param dateRanges: list of all date ranges to set
     */
    REPORT_SET_ALL_DATE_RANGES: (dateRanges: DateRange[]) => {
      _.chain(dateRanges)
        .filter((dateRange) => _.isEmpty(dateRange.range) && _.get(dateRange, 'auto.enabled'))
        .forEach((dateRange) => _.set(dateRange, 'auto.noCapsuleFound', true))
        .value();

      this.setIfNotEqual('dateRanges', dateRanges);
    },

    /**
     * Removes all date ranges from the store
     */
    REPORT_REMOVE_ALL_DATE_RANGES: () => {
      this.state.set('dateRanges', []);
    },

    /**
     * Updates the backup preview
     *
     * @param {Object} payload - an object container
     * @param {Object} payload.backupPreview - The backup preview
     */
    REPORT_SET_BACKUP_PREVIEW: (payload) => {
      this.state.set('backupPreview', payload.backupPreview);
    },

    /**
     * Set whether or not date ranges are being updated
     *
     * @param {Object} payload - an object container
     * @param {boolean} payload.dateRangeUpdating - true if a date range is updating, false if not.
     */
    REPORT_SET_DATE_RANGE_UPDATING: (payload: { dateRangeUpdating: boolean }) => {
      this.state.set('dateRangeUpdating', payload.dateRangeUpdating);
    },

    /**
     * Handles events that may update the editing state.
     *
     * @param {Object} payload - an object container
     * @param {string} payload.event - the editing state event that occurred
     */
    REPORT_EDITING_STATE_EVENT: (payload) => {
      const transitions = {
        [ReportEditingStateEvent.SaveStarted]: (state) =>
          state === ReportEditingState.Offline ? ReportEditingState.Offline : ReportEditingState.Saving,
        [ReportEditingStateEvent.SaveComplete]: (state) => ReportEditingState.Saved,
        [ReportEditingStateEvent.Online]: (state) =>
          state === ReportEditingState.Offline ? ReportEditingState.Stale : state,
        [ReportEditingStateEvent.Offline]: (state) => ReportEditingState.Offline,
      };
      const currentState = this.state.get('editingState');
      this.state.set('editingState', transitions[payload.event](currentState));
    },

    /**
     * Update the start and end times for the given date range.
     * @param {String} dateRangeId
     * @param {String} start
     * @param {String} end
     */
    REPORT_UPDATE_RANGE_START_AND_END: ({ dateRangeId, start, end }) => {
      const index = _.findIndex(this.state.get('dateRanges'), ['id', dateRangeId]);
      if (index >= 0) {
        this.state.merge(['dateRanges', index, 'range'], { start, end });
      }
    },

    /**
     * Sets the given date range's "no capsule found" flag to the desired value (true, by default)
     * @param {String} dateRangeId
     * @param {Boolean} value
     */
    REPORT_UPDATE_NO_CAPSULE_FOUND: ({ dateRangeId, value }) => {
      const index = _.findIndex(this.state.get('dateRanges'), ['id', dateRangeId]);
      if (index >= 0) {
        this.state.merge(['dateRanges', index, 'auto'], {
          noCapsuleFound: value,
        });
      }
    },

    /**
     * Clears the report store in preparation for loading a new report.
     */
    REPORT_RESET: () => {
      this.initialize('FORCE');
    },

    /**
     * Adds (or updates) a comment
     *
     * @param {Object} comment - Seeq comment
     */
    REPORT_SET_COMMENT: (comment) => {
      const index = _.findIndex(this.state.get('comments'), ['id', comment.id]);
      if (index >= 0) {
        this.state.set(['comments', index], comment);
      } else {
        this.state.push('comments', comment);
      }
    },

    /**
     * Removes a comment
     *
     * @param {Object} id - id of the content
     */
    REPORT_REMOVE_COMMENT: (id) => {
      const index = _.findIndex(this.state.get('comments'), ['id', id]);

      if (index >= 0) {
        this.state.splice('comments', [index, 1]);
      }
    },

    REPORT_SET_REPORT_SCHEDULE: (reportSchedule: ReportSchedule | undefined) => {
      this.state.set('reportScheduleError', undefined);
      this.state.set('reportSchedule', reportSchedule);
    },

    REPORT_SET_NEXT_RUN_TIME: (formattedTime) => {
      this.state.set('nextRunTime', formattedTime);
    },

    REPORT_SCHEDULED_UPDATE_RECEIVED: () => {
      const count = this.state.get('scheduledUpdateCount');
      this.state.set('scheduledUpdateCount', count + 1);
    },

    REPORT_SET_LAST_SAVED_TIMEZONE: (timezone: string) => {
      this.state.set('lastSavedTimezone', timezone);
    },

    REPORT_SET_IS_FIXED_WIDTH: (payload: { isFixedWidth: boolean }) => {
      this.state.set('isFixedWidth', payload.isFixedWidth);
    },

    REPORT_SET_SANDBOX_MODE: (payload: SandboxMode) => {
      this.state.set('sandboxMode', payload);
    },

    REPORT_SET_CONTENT_DISPLAY_METADATA: (payload: { contentId: string; displayMetadata: ContentDisplayMetadata }) => {
      const index = _.findIndex(this.state.get('contentDisplayMetadata'), ['contentId', payload.contentId]);
      if (index >= 0) {
        this.state.set(['contentDisplayMetadata', index], payload);
      } else {
        this.state.push('contentDisplayMetadata', payload);
      }
    },

    REPORT_SET_REPORT_SCHEDULE_ERROR: (payload: { error: string; errorCode: number }) => {
      this.state.set('reportScheduleError', payload);
    },

    REPORT_REMOVE_CONTENT_DISPLAY_METADATA: (contentId: string) => {
      const index = _.findIndex(this.state.get('contentDisplayMetadata'), ['contentId', contentId]);

      if (index >= 0) {
        this.state.splice('contentDisplayMetadata', [index, 1]);
      }
    },

    REPORT_ADD_CONTENT_ERROR: ({ contentId }) => {
      if (!_.some(this.state.get('contentErrors'), (el) => el === contentId)) {
        this.state.push('contentErrors', contentId);
      }
    },

    REPORT_RESET_CONTENT_ERRORS: () => {
      this.state.set('contentErrors', []);
    },
  };
}
