// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Modal } from 'react-bootstrap';
import SelectItem from '@/hybrid/core/SelectItem.organism';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import _ from 'lodash';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { FormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';
import { itemIconClass } from '@/hybrid/utilities/utilities';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { doTrack } from '@/track/track.service';
import { sqScatterPlotStore, sqTrendSeriesStore } from '@/core/core.stores';

const scatterPlotFxLineModalBindings = bindingsDefinition({
  closeModal: prop<() => void>(),
  signalIdsToExclude: prop<(fxLines) => Promise<string[]>>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqFormulaToolActions: injected<FormulaToolActions>(),
  sqScatterPlotActions: injected<ScatterPlotActions>(),
});

export const ScatterPlotFxLineModal: SeeqComponent<typeof scatterPlotFxLineModalBindings> = (props) => {
  const { t } = useTranslation();
  const { sqScatterPlotActions, sqInvestigateActions, sqFormulaToolActions } =
    useInjectedBindings(scatterPlotFxLineModalBindings);

  const { closeModal, signalIdsToExclude } = props;

  const [fxLinesLocal, setFxLinesLocal] = useState([]);
  const { fxLines, ySignals } = useFlux(sqScatterPlotStore);
  const [excludeIds, setExcludeIds] = useState([]);

  useEffect(() => {
    setFxLinesLocal(
      _.chain(fxLines)
        .map((line) => ({
          ...sqTrendSeriesStore.findItem(line.id),
          ySignalId: line.ySignalId,
        }))
        .uniq()
        .value(),
    );
  }, [fxLines]);

  useEffect(() => {
    signalIdsToExclude(fxLines).then(setExcludeIds);
  }, [fxLines]);

  const addFxLine = (item) => {
    sqScatterPlotActions.addFxLine(item);
  };

  const removeFxLineAndTrack = (item) => {
    sqScatterPlotActions.removeFxLine(item);
    doTrack('scatterPlot', 'Remove f(x) line');
  };

  const openPredictionTool = () => {
    sqInvestigateActions.setActiveTool(TREND_TOOLS.PREDICTION);
    if (sqScatterPlotStore.xSignal) {
      sqInvestigateActions.setParameterItem(TREND_TOOLS.PREDICTION, 'inputSignals', sqScatterPlotStore.xSignal);
    }
    if (!_.isEmpty(sqScatterPlotStore.ySignals)) {
      // TODO CRAB-27607: Arbitrarily picked first signal. Perhaps not ideal.
      sqInvestigateActions.setParameterItem(TREND_TOOLS.PREDICTION, 'targetSignal', sqScatterPlotStore.ySignals[0]);
    }
    closeModal();
  };

  const openFormulaTool = () => {
    sqInvestigateActions.setActiveTool(TREND_TOOLS.FORMULA);
    if (sqScatterPlotStore.xSignal) {
      sqFormulaToolActions.removeAllParameters();
      sqFormulaToolActions.addParameter({
        identifier: 'x',
        item: sqScatterPlotStore.xSignal,
      });
    }
    closeModal();
  };

  return (
    <Modal onHide={closeModal} show={true} data-testid="fxLineModal" size="sm" animation={false}>
      <Modal.Header closeButton={true}>
        <h3>{t('SCATTER.FX_LINE_TOOLTIP')}</h3>
      </Modal.Header>
      <Modal.Body>
        <p>{t('SCATTER.FX_LINE_HELP')}</p>
        <p className="text-bold">{t('SCATTER.SELECT_SIGNAL')}</p>
        <SelectItem
          insideModal={true}
          selectedItemId=""
          itemTypes={[ITEM_TYPES.SERIES]}
          excludedIds={excludeIds}
          excludeStringSignals={true}
          excludeWorkbookItems={true}
          disableAutoSelect={true}
          showAddToDisplayPane={false}
          isGrouped={false}
          onSelect={addFxLine}
          dataTestId="FxLine"
          extraClassNames="fxLineDropdown"
        />
        <p className="mt10">{t('SCATTER.NEW_OPTION')}</p>
        <ul>
          <li key="predictionLink">
            <a href="#" onClick={() => openPredictionTool()}>
              {t('SCATTER.CREATE_PREDICTION')}
            </a>
          </li>
          <li key="formulaLink">
            <a href="#" onClick={() => openFormulaTool()}>
              {t('SCATTER.CREATE_FORMULA')}
            </a>
          </li>
        </ul>
        {fxLinesLocal.length > 0 && (
          <div>
            <hr className="mt10 mb10" />
            <div className="flexColumnContainer">
              <p>{t('SCATTER.FX_LINES_DISPLAYED')}</p>
              <Icon tooltip="SCATTER.FX_LINE_Y_SIGNAL_HELP" icon="fa-question-circle" extraClassNames="ml5" />
            </div>
            <ul className="mb0 list-unstyled">
              {_.map(fxLinesLocal, (item) => (
                <li key={item.id} id={item.id}>
                  <div className="flexColumnContainer mb5">
                    <div className="flexColumnContainer flexAlignCenter pb5">
                      <Icon type="color" icon={itemIconClass(item)} color={item.color} extraClassNames="pr5 fa" />
                      <strong>{item.name}</strong>
                      <a
                        data-testid={`removeLine_${item.id}`}
                        href="#"
                        onClick={() => removeFxLineAndTrack(item)}
                        className="ml10">
                        <Icon icon="fa-close" extraClassNames="fa fa-lg" />
                      </a>
                    </div>
                    <div className="ml10 mr10 width-200">
                      <SelectItem
                        selectedItemId={item.ySignalId}
                        itemTypes={[ITEM_TYPES.SERIES]}
                        excludeStringSignals={true}
                        excludeWorkbookItems={true}
                        excludeStoreItems={true}
                        additionalItems={_.map(ySignals, (ySignal) => sqTrendSeriesStore.findItem(ySignal.id))}
                        allowClear={true}
                        disableAutoSelect={true}
                        showAddToDisplayPane={false}
                        insideModal={true}
                        extraClassNames={`fxYSignal_${item.id}`}
                        onSelect={(value: { id?: string }) => {
                          doTrack('Scatterplot', 'Set Fx Line to YSignal');
                          sqScatterPlotActions.setFxLineYSignalId(item, value?.id);
                        }}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <TextButton
          type="button"
          size="sm"
          id="testHelper"
          extraClassNames="mr20"
          onClick={() => closeModal()}
          label="CLOSE"
        />
      </Modal.Footer>
    </Modal>
  );
};
