// @ts-strict-ignore
import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { VariableSizeList } from 'react-window';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { APP_STATE } from '@/main/app.constants';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { sqHomeScreenStore } from '@/core/core.stores';

const folderTreeBindings = bindingsDefinition({
  sqHomeScreenActions: injected<HomeScreenActions>(),
  $state: injected<ng.ui.IStateService>(),
  tableType: prop.optional<string>(),
  topLevel: prop.optional<Boolean>(),
  root: prop.optional<string>(),
});

const Row = ({ index, style, data: { flattenedFolders, toggleFolder, openFolder, setRowHeight } }) => {
  const { folder, isOpen, depth, isCurrentFolder } = flattenedFolders[index];

  const rowRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.clientHeight);
    }
  }, [rowRef, index]);

  return (
    <div style={{ ...style, paddingLeft: depth * 15 }} className="flexRowContainer folderNav">
      <div className="cursorPointer flexColumnContainer">
        <i
          data-testid={`folderToggle_${folder.id}`}
          className={classNames('fa', 'mr5', 'mt2', 'gray-text', isOpen ? 'fa-chevron-down' : 'fa-chevron-right')}
          onClick={() => toggleFolder(folder.id)}
        />
        <span
          ref={rowRef}
          className={classNames('gray-text', {
            'text-bolder': isCurrentFolder,
          })}
          onClick={() => openFolder(folder.id)}>
          {folder.name}
        </span>
      </div>
    </div>
  );
};

export const FolderTree: SeeqComponent<typeof folderTreeBindings> = (props) => {
  const { sqHomeScreenActions, $state } = useInjectedBindings(folderTreeBindings);
  const { tableType, topLevel, root } = props;
  const folders = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.folderTree);
  const expandedFolderIds = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.expandedFolderIds);
  const currentFolderId = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentFolderId);
  const listRef = useRef<VariableSizeList>(null);
  const rowHeights = useRef([]);

  const toggleFolder = (folderId) => {
    _.indexOf(expandedFolderIds, folderId) > -1
      ? sqHomeScreenActions.collapseFolder(folderId)
      : sqHomeScreenActions.expandFolder(folderId, root);
  };

  const openFolder = (folderId) => {
    if (folderId !== $state.params.currentFolderId) {
      sqHomeScreenActions.setPageNumber(1, tableType);
      return $state.go(APP_STATE.FOLDER_EXPANDED, {
        currentFolderId: folderId,
      });
    }
  };

  const flattenedFolders = [];
  const addFlattened = (folders, depth) => {
    _.forEach(folders, (folder) => {
      const isOpen = _.indexOf(expandedFolderIds, folder.id) > -1;
      flattenedFolders.push({
        folder,
        isOpen,
        depth,
        isCurrentFolder: currentFolderId === folder.id,
      });
      if (isOpen && !_.isEmpty(folder.subfolders)) {
        addFlattened(folder.subfolders, depth + 1);
      }
    });
  };
  addFlattened(folders, 0);

  const rowHeight = 24;

  const setRowHeight = (index, size) => {
    rowHeights.current[index] = size;
    // https://react-window.vercel.app/#/api/VariableSizeList
    // resetAfterIndex clears the cache after the specified index.  So if an items size changes this will clear the
    // cache and rerender
    listRef.current.resetAfterIndex(0);
  };

  const getRowHeight = (index) => {
    const shortRowHeight = 18;

    return rowHeights.current[index] || shortRowHeight;
  };

  const itemKey = (index, data) => {
    const item = data.flattenedFolders[index];
    return item.folder.id;
  };

  return (
    <div
      className={classNames('max-height-20em', 'overflowYScroll', {
        ml15: !topLevel,
      })}>
      <VariableSizeList
        height={_.min([260, rowHeight * flattenedFolders.length])}
        itemCount={flattenedFolders.length}
        itemSize={getRowHeight}
        itemKey={itemKey}
        ref={listRef}
        itemData={{
          flattenedFolders,
          toggleFolder,
          openFolder,
          setRowHeight,
        }}
        width="100%">
        {Row}
      </VariableSizeList>
    </div>
  );
};
