import React, { useState } from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { warnToast } from '@/hybrid/utilities/toast.utilities';
import { deleteDisplays } from '@/hybrid/displays/displays.actions';
import { doTrack } from '@/track/track.service';
import { deleteItem } from '@/search/search.actions';

interface DeleteDisplayModalProps {
  onClose: () => void;
  display: { id: string; name: string; type: string };
  displaysCount: number;
  isPinned: boolean;
  restrictExploration?: boolean;
}

export const DeleteDisplayModal: React.FunctionComponent<DeleteDisplayModalProps> = ({
  onClose,
  display,
  displaysCount,
  isPinned,
  restrictExploration = false,
}) => {
  const { t } = useTranslation();

  const [deleteAll, setDeleteAll] = useState(false);

  const formDefinitionDisplayTemplate: FormElement[] = [
    {
      component: 'LabelFormComponent',
      name: 'deleteDisplayLabel',
      value: 'DISPLAYS.MODAL.DELETE.DISPLAY_TEMPLATE.DELETE_BODY',
      extraClassNames: 'mb10',
    },
    {
      component: 'RadioButtonGroupFormComponent',
      name: 'deleteDisplay',
      value: deleteAll,
      onChange: _.noop,
      skipStore: true,
      id: 'deleteDisplayRadios',
      verticalLayout: true,
      options: [
        {
          id: 'thisDisplay',
          label: t('DISPLAYS.MODAL.DELETE.DISPLAY.RADIO_OPTION', {
            ITEM_NAME: display.name,
          }),
          checked: !deleteAll,
          onToggle: () => setDeleteAll(false),
        },
        {
          id: 'thisDisplayTemplate',
          label: t('DISPLAYS.MODAL.DELETE.DISPLAY_TEMPLATE.RADIO_OPTION', {
            ITEM_NAME: display.name,
            SCALE_COUNT: displaysCount - 1,
          }),
          checked: deleteAll,
          onToggle: () => setDeleteAll(true),
        },
      ],
    },
  ];

  const formDefinitionDisplay: FormElement[] = [
    {
      component: 'LabelFormComponent',
      name: 'deleteDisplayLabel',
      value: 'DISPLAYS.MODAL.DELETE.DISPLAY.DELETE_BODY',
      valueCustomParams: { ITEM_NAME: display.name },
      extraClassNames: 'mb10',
    },
  ];

  /**
   * Deletes the display item or all scaled displays by deleting the display template
   *
   * @returns {Promise} that resolves when the update is complete
   */
  function deleteDisplay() {
    if (_.isEmpty(display)) {
      warnToast({ messageKey: 'DISPLAYS.MODAL.DELETE.SELECTION_REQUIRED' });
      return Promise.resolve();
    }

    if (deleteAll) {
      doTrack('Search results', 'Delete Display', _.join(['delete display template', display.name], ' '));
      return deleteDisplays(display, displaysCount, isPinned, restrictExploration).then(() => {
        onClose();
      });
    } else {
      doTrack('Search results', 'Delete Display', _.join(['delete display item', display.name], ' '));
      return deleteItem(
        display,
        'DISPLAYS.MODAL.DELETE.DISPLAY.DELETED',
        'DISPLAYS.MODAL.DELETE.DISPLAY.ERROR_DELETING',
        { ITEM_NAME: display.name },
        {},
        isPinned,
        true,
        restrictExploration,
      ).then(() => {
        onClose();
      });
    }
  }

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="deleteDisplayModal">
      <Modal.Header closeButton={true}>
        <Modal.Title>{t('DISPLAYS.MODAL.DELETE.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="deleteDisplayModalBody">
          <SimpleSaveFormBuilder
            testId="deleteSimpleSaveFormBuilder"
            formDefinition={displaysCount > 1 ? formDefinitionDisplayTemplate : formDefinitionDisplay}
            submitFn={deleteDisplay}
            closeFn={onClose}
            submitBtnVariant="danger"
            submitBtnLabel={displaysCount > 1 && deleteAll ? 'DELETE_ALL' : 'DELETE'}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
