// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { AgentsCommandBar } from '@/hybrid/administration/agents/AgentsCommandBar.molecule';
import { AgentsTable } from '@/hybrid/administration/agents/AgentsTable.molecule';
import { ManageAgentModal } from '@/hybrid/administration/agents/ManageAgentModal';
import { useDatasources } from '@/hybrid/administration/datasources/datasources.hook';
import { StageVersionModal } from '@/hybrid/administration/agents/StageVersionModal';
import { UpdateVersionModal } from '@/hybrid/administration/agents/UpdateVersionModal';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';

interface AgentsTabProps {}

export const AgentsTab: React.FunctionComponent<AgentsTabProps> = () => {
  const datasources = useDatasources();
  const [agents, setAgents] = useState([]);
  const [modalShown, setModalShown] = useState(false);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [stageVersionModalShown, setStageVersionModalShown] = useState(false);
  const [updateVersionModalShown, setUpdateVersionModalShown] = useState(false);

  useEffect(() => {
    const agentsWithIds = !_.isNil(datasources)
      ? _.map(datasources.agents, (agent) => {
          return { ...agent, id: agent.name };
        })
      : [];
    setAgents(agentsWithIds);
  }, [datasources]);

  const showManageAgents = () => setModalShown(true);
  const closeModal = () => setModalShown(false);

  const showStageVersionModal = () => setStageVersionModalShown(true);
  const closeStageVersionModal = () => setStageVersionModalShown(false);
  const showUpdateVersionModal = () => setUpdateVersionModalShown(true);
  const closeUpdateVersionModal = () => setUpdateVersionModalShown(false);

  return !_.isNil(datasources) ? (
    <div className="flexRowContainer flexFill">
      <AgentsCommandBar
        selectedAgents={selectedAgents}
        showManageAgents={showManageAgents}
        datasources={datasources}
        showStageVersionModal={showStageVersionModal}
        showUpdateVersionModal={showUpdateVersionModal}
      />
      <div className="flexRowContainer flexFillOverflow overflowAuto">
        <AgentsTable setSelectedAgents={setSelectedAgents} selectedAgents={selectedAgents} agents={agents} />
      </div>
      {modalShown && <ManageAgentModal agentName={_.first(selectedAgents).name} onClose={closeModal} />}
      {stageVersionModalShown && <StageVersionModal onClose={closeStageVersionModal} />}
      {updateVersionModalShown && <UpdateVersionModal onClose={closeUpdateVersionModal} />}
    </div>
  ) : (
    <div className="flexColumnContainer flexCenter pt50 pb50">
      <IconWithSpinner spinning={true} testId="agentsTabSpinner" />
    </div>
  );
};
