import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { sqPluginStore } from '@/core/core.stores';
import { generateHomeScreenAddOnHash } from '@/hybrid/utilities/utilities';
import { APP_STATE, KEY_CODES } from '@/main/app.constants';
import { HomeScreenAddOn } from '@/hybrid/homescreen/HomeScreenAddOn.molecule';
import { DISPLAY_LOCATION } from '@/hybrid/homescreen/homescreen.constants';
import { Icon } from '@/hybrid/core/Icon.atom';
import { FormControl, InputGroup } from 'react-bootstrap';
import { useFlux } from '../core/hooks/useFlux.hook';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';

const homeScreenAddOnsBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
});

/**
 * Displays a filter input and home screen add-ons.
 */
export const HomeScreenAddOns: SeeqComponent<typeof homeScreenAddOnsBindings> = () => {
  const { $state } = useInjectedBindings(homeScreenAddOnsBindings);
  const { t } = useTranslation();
  const [addOnFilter, setAddOnFilter] = useState('');
  useFlux(sqPluginStore);

  const shouldBeDisplayed = (name: string, description = '') =>
    addOnFilter.length === 0 ||
    name?.toLowerCase()?.includes(addOnFilter?.toLowerCase()) ||
    description?.toLowerCase()?.includes(addOnFilter?.toLowerCase());

  const addOnsToDisplay = _.filter(sqPluginStore.homeScreenPlugins(), (plugin) =>
    shouldBeDisplayed(plugin.name, plugin.description),
  );

  const clearFilterIfEscapeKey = (e: { keyCode: number }) => {
    if (e.keyCode === KEY_CODES.ESCAPE) {
      setAddOnFilter('');
    }
  };

  const createDisplayFunction = (identifier = '') => {
    const identifierHash = generateHomeScreenAddOnHash(identifier);
    return (displayOn: DISPLAY_LOCATION) => {
      if (displayOn === DISPLAY_LOCATION.NEW_TAB) {
        window.open($state.href(APP_STATE.HOME_SCREEN_ADD_ON, { a: identifierHash }), '_blank');
      } else {
        $state.go(APP_STATE.HOME_SCREEN_ADD_ON, { a: identifierHash });
      }
    };
  };

  const renderFilter = () => (
    <div className="m20">
      <div className="flexRowContainer height-maximum positionRelative">
        <InputGroup
          className="homeScreenSearchBar flexColumnContainer lightGreyBorder border-radius-4 form-control flexNoWrap"
          data-testid="searchBar">
          <FormControl
            className="homeScreenSearchInput hide-ms-clear height-32"
            type="text"
            value={addOnFilter}
            onChange={(e) => setAddOnFilter(e.target.value)}
            onKeyDown={clearFilterIfEscapeKey}
            placeholder={t('HOME_SCREEN.ADDONS.FILTER')}
          />
          {addOnFilter?.length > 0 ? (
            <span className="addOnFilterClear" data-testid="addonFilterClearButton" onClick={() => setAddOnFilter('')}>
              <Icon
                icon="fa-times"
                tooltip="HOME_SCREEN.ADDONS.CLEAR_FILTER"
                tooltipPlacement="top"
                large={true}
                type="gray"
                extraClassNames="p2"
              />
            </span>
          ) : null}
        </InputGroup>
      </div>
    </div>
  );

  const renderAddOns = () => (
    <div className="height-maximum width-maximum p10 flexRowContainer homeScreenMainPanel overflowYAuto overflowXHidden">
      {addOnsToDisplay.length > 0 ? (
        <div className="flexColumnContainer flexWrap flexBasisContent flexAlignStart">
          {_.map(addOnsToDisplay, (plugin) => (
            <HomeScreenAddOn
              key={plugin.identifier}
              identifier={plugin.identifier}
              name={plugin.name}
              description={plugin.description}
              displayAddOn={createDisplayFunction(plugin.identifier)}
              icon={plugin.icon}
              addOnFilter={addOnFilter}
            />
          ))}
        </div>
      ) : (
        <div className="flexRowContainer flexAlignCenter mt50">{t('HOME_SCREEN.ADDONS.NONE_MATCH')}</div>
      )}
    </div>
  );

  return (
    <>
      {renderFilter()}
      {renderAddOns()}
    </>
  );
};
