import angular from 'angular';
// These are external JS libraries that are imported for the side-effect of loading them. This is the webpack
// equivalent of adding a <script> tag
import 'jquery-minicolors/jquery.minicolors.js';
import 'clipboard';
import 'angular-ui-bootstrap';
import 'codemirror/lib/codemirror.js';
import 'codemirror/addon/display/placeholder.js';
import 'codemirror/addon/edit/matchbrackets.js';
import 'codemirror/addon/lint/lint.js';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/mode/clike/clike.js';
import 'codemirror/mode/css/css.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/php/php.js';
import 'codemirror/mode/xml/xml.js';
import 'angular-ui-router';
import 'angular-file-upload';
// These are some other oddball modules that we need to make sure get loaded for their side effects
import 'moment';
import 'moment-duration-format';
import 'other_components/jquery-ui-custom.js';
import 'other_components/jquery.insert-at-caret.js';
import 'other_components/piwik/piwik.js';

// These are all angular modules that are created by some of the above libraries
const dependencies = ['angularFileUpload', 'ui.bootstrap', 'ui.router'];

angular.module('Sq.Vendor', dependencies);
