// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { AdvancedSearchSelect } from '@/hybrid/homescreen/AdvancedSearchSelect.atom';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { AdvancedSearchSubFolderModal } from '@/hybrid/homescreen/AdvancedSearchSubFolderModal.molecule';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { SEARCH_ITEM_LOCATIONS, SEARCH_LOCATION_OPTIONS_ADVANCED } from '@/main/app.constants';
import { FilterOwnerCreatorAdvancedSearch } from '@/hybrid/homescreen/FilterOwnerCreatorAdvancedSearch.molecule';
import { HomeScreenAdvancedSearchBreadcrumbs } from '@/hybrid/homescreen/HomeScreenAdvancedSearchBreadcrumbs.molecule';
import { TYPE_OPTIONS } from '@/hybrid/homescreen/homescreen.constants';
import { sqExploreFolderModalStore, sqHomeScreenStore } from '@/core/core.stores';

/** The component that gets rendered when a user clicks on the down chevron icon in the home screen search bar. Advanced search allows
 * for filtering by type, exact match, and tab. If a tab has subfolders, the user can select a specific folder. **/

const homeScreenAdvancedSearchBindings = bindingsDefinition({
  itemTypeFilter: prop<{ text: string; value: string; label: string }>(),
  setItemTypeFilter: prop<(itemType: object) => void>(),
  itemLocationFilter: prop<{ text: string; value: string; label: string }>(),
  setItemLocationFilter: prop<(itemLocation: object) => void>(),
  setShowAdvanced: prop<(showAdvanced: boolean) => void>(),
  executeSearch: prop<() => void>(),
  advancedSearchSelectedFolder: prop<any>(),
  setAdvancedSearchSelectedFolder: prop<(selectedFolder: object) => void>(),
  isCurrentFolderNested: prop<boolean>(),
  setIsCurrentFolderNested: prop<(isFolderNested: boolean) => void>(),
  locationChangedInAdvanced: prop<boolean>(),
  setLocationChangedInAdvanced: prop<(isLocationChanged: boolean) => void>(),
  breadcrumbClicked: prop<boolean>(),
  setBreadcrumbClicked: prop<(isBreadcrumbClicked: boolean) => void>(),
  myFolderContent: prop<any>(),
  corporateFolderId: prop<string>(),
  ownedBy: prop<any[]>(),
  setOwnedBy: prop<(owner: object) => void>(),
  createdBy: prop<any[]>(),
  setCreatedBy: prop<(creator: object) => void>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
});

export const HomeScreenAdvancedSearch: SeeqComponent<typeof homeScreenAdvancedSearchBindings> = ({
  itemTypeFilter,
  setItemTypeFilter,
  itemLocationFilter,
  setItemLocationFilter,
  setShowAdvanced,
  executeSearch,
  advancedSearchSelectedFolder,
  setAdvancedSearchSelectedFolder,
  isCurrentFolderNested,
  setIsCurrentFolderNested,
  locationChangedInAdvanced,
  setLocationChangedInAdvanced,
  breadcrumbClicked,
  setBreadcrumbClicked,
  myFolderContent,
  corporateFolderId,
  ownedBy,
  setOwnedBy,
  createdBy,
  setCreatedBy,
}) => {
  const { sqHomeScreenActions, sqHomeScreenUtilities } = useInjectedBindings(homeScreenAdvancedSearchBindings);

  const { t } = useTranslation();

  const currentFolderId = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentFolderId);

  // For now, we do not allow searching by most recent- CRAB-19839
  const searchLocations = sqHomeScreenUtilities.getFilteredLocations(SEARCH_LOCATION_OPTIONS_ADVANCED, false);
  const hasSubFolders =
    itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.MY_FOLDER ||
    itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.CORPORATE ||
    itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.USERS ||
    itemLocationFilter.value === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC;

  /** False when the root breadcrumb has been clicked **/
  const [breadcrumbsPresent, setBreadcrumbsPresent] = useState(true);
  const [showSelectFolderModal, setShowSelectFolderModal] = useState(false);

  useEffect(() => {
    setIsCurrentFolderNested(sqHomeScreenStore.breadcrumbs.length !== 0 && !advancedSearchSelectedFolder);
  }, [currentFolderId]);

  const currentTabName = itemLocationFilter.label === 'My Folder' ? 'MY_FOLDER' : _.toUpper(itemLocationFilter.label);
  const initialFolderId = useFluxPath(sqExploreFolderModalStore, () => sqExploreFolderModalStore.initialFolderId);

  const handleAdvancedModalClose = () => {
    setBreadcrumbClicked(false);
    setShowSelectFolderModal(false);
  };

  return (
    <div className="lightGreyBorder p10 advancedPopoverAdvancedSearch flexRowContainer" data-testid="advancedPopover">
      <div id="advancedSearch" className="flexColumnContainerWrap">
        <div className="flexRowContainer col-sm-6">
          <AdvancedSearchSelect
            extraClassNames="itemTypeSelect"
            label="HOME_SCREEN.ADVANCED_SEARCH.CONTENT_TYPE"
            value={itemTypeFilter.value}
            options={TYPE_OPTIONS}
            onChange={setItemTypeFilter}
            id="advancedSearchType"
          />
        </div>
        <div className="flexRowContainer col-sm-6">
          <AdvancedSearchSelect
            extraClassNames="itemLocationSelect"
            label="HOME_SCREEN.ADVANCED_SEARCH.LOCATION"
            value={itemLocationFilter.value}
            options={searchLocations}
            onChange={(option) => {
              setItemLocationFilter(option);
              setAdvancedSearchSelectedFolder(undefined);
              setLocationChangedInAdvanced(true);
            }}
            id="advancedSearchLocation"
          />

          {hasSubFolders &&
            advancedSearchSelectedFolder === undefined &&
            (currentFolderId === undefined ||
              sqHomeScreenStore.breadcrumbs.length === 0 ||
              locationChangedInAdvanced ||
              breadcrumbClicked ||
              !breadcrumbsPresent) && (
              <a
                href="#"
                data-testid="advancedSubFolders"
                className="mt10"
                onClick={() => setShowSelectFolderModal(true)}>
                {t('HOME_SCREEN.SELECT_SPECIFIC_FOLDER')}
              </a>
            )}

          <HomeScreenAdvancedSearchBreadcrumbs
            itemLocationFilter={itemLocationFilter}
            myFolderContent={myFolderContent}
            corporateFolderId={corporateFolderId}
            advancedSearchSelectedFolder={advancedSearchSelectedFolder}
            setAdvancedSearchSelectedFolder={setAdvancedSearchSelectedFolder}
            breadcrumbClicked={breadcrumbClicked}
            setBreadcrumbClicked={setBreadcrumbClicked}
            breadcrumbsPresent={breadcrumbsPresent}
            setBreadcrumbsPresent={setBreadcrumbsPresent}
            locationChangedInAdvanced={locationChangedInAdvanced}
            setShowSelectFolderModal={setShowSelectFolderModal}
            currentTabName={currentTabName}
          />
        </div>
        <FilterOwnerCreatorAdvancedSearch
          ownerCreator={ownedBy}
          setOwnerCreator={setOwnedBy}
          label="HOME_SCREEN.ADVANCED_SEARCH.OWNED_BY"
          labelId="ownedByAdvanced"
        />
        <FilterOwnerCreatorAdvancedSearch
          ownerCreator={createdBy}
          setOwnerCreator={setCreatedBy}
          label="HOME_SCREEN.ADVANCED_SEARCH.CREATED_BY"
          labelId="createdByAdvanced"
        />
      </div>

      <div className="flexColumnContainer col-sm-6 pt10">
        <Checkbox
          label="HOME_SCREEN.ADVANCED_SEARCH.EXACT_MATCH_CHECKBOX"
          isChecked={sqHomeScreenStore.isExact}
          id="HOME_SCREEN.ADVANCED_SEARCH.EXACT_MATCH_CHECKBOX"
          onChange={() => sqHomeScreenActions.setIsExact(!sqHomeScreenStore.isExact)}
        />
      </div>

      <div className="mt10 flexColumnContainer flexJustifyCenter">
        <TextButton onClick={() => setShowAdvanced(false)} label="CANCEL" extraClassNames="mr10" />
        <TextButton onClick={executeSearch} label="SEARCH" variant="theme" />
      </div>

      {showSelectFolderModal && (
        <AdvancedSearchSubFolderModal
          onClose={handleAdvancedModalClose}
          currentTabName={currentTabName}
          advancedSearchSelectedFolder={advancedSearchSelectedFolder}
          setAdvancedSearchSelectedFolder={setAdvancedSearchSelectedFolder}
          isCurrentFolderNested={isCurrentFolderNested}
          breadcrumbsPresent={breadcrumbsPresent}
          itemLocationFilter={itemLocationFilter}
          breadcrumbClicked={breadcrumbClicked}
          locationChangedInAdvanced={locationChangedInAdvanced}
          initialFolderId={initialFolderId}
          myFolderContent={myFolderContent}
          corporateFolderId={corporateFolderId}
        />
      )}
    </div>
  );
};
