// @ts-strict-ignore
import _ from 'lodash';
import { PersistenceLevel, Store } from '@/core/flux.service';
import { generateTabHash } from '@/hybrid/utilities/utilities';

/**
 * The Flux store for workbook state
 */
export const LOAD_STATUS = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
  NOT_LOADED: 'NOT_LOADED',
};

export class WorkbookStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqWorkbookStore';

  initialize() {
    this.state = this.immutable({
      name: '',
      description: '',
      owner: '',
      createdAt: 0,
      isReportBinder: false,
      updatedAt: 0,
      worksheets: [],
      workbookDisplay: undefined,
      pinned: [],
      pinnedLoadStatus: LOAD_STATUS.NOT_LOADED,
      recentlyAccessed: [],
      recentlyAccessedLoadStatus: LOAD_STATUS.NOT_LOADED,
      assetGroups: [],
      assetGroupsLoadStatus: LOAD_STATUS.NOT_LOADED,
      isArchived: false,
      parentFolderId: '',
      ancestors: [],
      effectivePermissions: {},
      viewers: [],
    });
  }

  get name() {
    return this.state.get('name');
  }

  get description() {
    return this.state.get('description');
  }

  get owner() {
    return this.state.get('owner');
  }

  get createdAt() {
    return this.state.get('createdAt');
  }

  get updatedAt() {
    return this.state.get('updatedAt');
  }

  // TODO: CRAB-30046- update this name
  get isReportBinder() {
    return this.state.get('type') === 'Topic';
  }

  get type() {
    return this.state.get('type');
  }

  getWorksheetName(worksheetId) {
    return this.state.get('worksheets', { worksheetId }, 'name');
  }

  getWorksheetCurrentWorkstepId(worksheetId) {
    return this.state.get('worksheets', { worksheetId }, 'workstep');
  }

  getWorksheetIndex(worksheetId) {
    return _.findIndex(this.state.get('worksheets'), ['worksheetId', worksheetId]);
  }

  get worksheets() {
    return this.state.get('worksheets');
  }

  get workbookId() {
    return this.state.get('workbookId');
  }

  get pinned() {
    return this.state.get('pinned');
  }

  get pinnedLoadStatus() {
    return this.state.get('pinnedLoadStatus');
  }

  get recentlyAccessed() {
    return this.state.get('recentlyAccessed');
  }

  get recentlyAccessedLoadStatus() {
    return this.state.get('recentlyAccessedLoadStatus');
  }

  get assetGroups() {
    return this.state.get('assetGroups');
  }

  get assetGroupsLoadStatus() {
    return this.state.get('assetGroupsLoadStatus');
  }

  get workbookDisplay() {
    // It is important that no code assumes that workbookDisplay will be undefined if a workbook has not been set.
    // Otherwise that assumption would be incorrect when switching to $states that don't set the workbook.
    if (_.isUndefined(this.state.get('workbookDisplay'))) {
      throw new Error('Workbook display mode has not been set');
    }
    return this.state.get('workbookDisplay');
  }

  get acl() {
    return this.state.get('acl');
  }

  get isArchived() {
    return this.state.get('isArchived');
  }

  get parentFolderId() {
    return this.state.get('parentFolderId');
  }

  get isWorkbookLoaded() {
    return !_.isUndefined(this.state.get('workbookDisplay'));
  }

  get ancestors() {
    return this.state.get('ancestors');
  }

  get effectivePermissions() {
    return this.state.get('effectivePermissions') || {};
  }

  get viewers() {
    return this.state.get('viewers');
  }

  get workbook() {
    return this.state.get();
  }

  protected readonly handlers = {
    WORKBOOK_SET: (payload) => {
      if (payload) {
        this.state.set('workbookId', payload.workbookId);
        this.state.set('name', payload.name);
        this.state.set('description', payload.description);
        this.state.set('owner', payload.owner);
        this.state.set('createdAt', payload.createdAt);
        this.state.set('isReportBinder', _.get(JSON.parse(payload.data || '{}'), 'isReportBinder', false));
        this.state.set('updatedAt', payload.updatedAt);
        this.state.set('worksheets', payload.worksheets);
        this.state.set('workbookDisplay', payload.workbookDisplay);
        this.state.set('type', payload.type);
        if (!_.isUndefined(payload.pinned)) {
          this.state.set('pinned', payload.pinned);
        }
        if (!_.isUndefined(payload.recentlyAccessed)) {
          this.state.set('recentlyAccessed', payload.recentlyAccessed);
        }
        if (!_.isUndefined(payload.assetGroups)) {
          this.state.set('assetGroups', payload.assetGroups);
        }
        this.state.set('isArchived', payload.isArchived);
        this.state.set('parentFolderId', payload.parentFolderId);
        if (payload.ancestors) {
          payload.ancestors.forEach((ancestor) => {
            if (ancestor.translationKey) {
              ancestor.tabHash = generateTabHash(ancestor.translationKey);
            }
          });
        }
        this.state.set('ancestors', payload.ancestors);
        this.state.set('effectivePermissions', payload.effectivePermissions);
      }
    },

    /**
     * Sets one or more workbook properties
     *
     * @param {Object} payload - Key/value pairs of workbook properties
     */
    WORKBOOK_SET_PROP: (payload) => {
      this.state.merge(payload);
    },
    /**
     * Removes a worksheet from the array of worksheets
     *
     * @param {Object} payload - Object container for properties
     * @param {String} payload.worksheetId - The worksheet unique ID
     */
    WORKBOOK_REMOVE_WORKSHEET: (payload) => {
      const index = _.findIndex(this.state.get('worksheets'), ['worksheetId', payload.worksheetId]);

      if (index > -1) {
        this.state.splice('worksheets', [index, 1]);
      }
    },
    /**
     * Sets the pinned items
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.items - The array of pinned items
     */
    WORKBOOK_SET_PINNED: (payload) => {
      this.state.set('pinned', payload.items);
    },

    /**
     * Sets the recently accessed items
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.items - The array of recently accessed items
     */
    WORKBOOK_SET_RECENTLY_ACCESSED: (payload) => {
      this.state.set('recentlyAccessed', payload.items);
    },

    /**
     * Sets the asset group items
     *
     * @param {Object} payload - Object container for arguments
     * @param {Object[]} payload.items - The array of asset groups
     */
    WORKBOOK_SET_ASSET_GROUPS: (payload) => {
      this.state.set('assetGroups', payload.items);
    },

    /**
     * Sets the load status for pinned items
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.loadStatus - new load status (see LOAD_STATUS from workbook.store)
     */
    WORKBOOK_SET_PINNED_LOAD_STATUS: (payload) => {
      this.state.set('pinnedLoadStatus', payload.loadStatus);
    },

    /**
     * Sets the load status for recently accessed items
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.loadStatus - new load status (see LOAD_STATUS from workbook.store)
     */
    WORKBOOK_SET_RECENTLY_ACCESSED_LOAD_STATUS: (payload) => {
      this.state.set('recentlyAccessedLoadStatus', payload.loadStatus);
    },

    /**
     * Sets the load status for asset groups
     *
     * @param {Object} payload - Object container for arguments
     * @param {String} payload.loadStatus - new load status (see LOAD_STATUS from workbook.store)
     */
    WORKBOOK_SET_ASSET_GROUPS_LOAD_STATUS: (payload) => {
      this.state.set('assetGroupsLoadStatus', payload.loadStatus);
    },

    /**
     * Renames a worksheet
     *
     * @param {Object} payload - Object container for properties
     * @param {String} payload.worksheetId - The worksheet unique ID
     * @param {String} payload.name - Name for the worksheet
     */
    WORKBOOK_RENAME_WORKSHEET: (payload) => {
      const index = _.findIndex(this.state.get('worksheets'), ['worksheetId', payload.worksheetId]);

      if (index > -1) {
        this.state.set(['worksheets', index, 'name'], payload.name);
      }
    },

    /**
     * Move a worksheet to a new location on a Workbook
     *
     * @param  {Object} payload - Object container for properties
     * @param  {String} payload.worksheetId - The ID of the worksheet to move
     * @param  {String} payload.nextWorksheetId - The ID of the worksheet immediately after the new location
     */
    WORKBOOK_MOVE_WORKSHEET: (payload) => {
      const cursor = this.state.select('worksheets');
      const index = _.findIndex(cursor.get(), ['worksheetId', payload.worksheetId]);

      if (index >= 0) {
        const worksheet = cursor.get(index);
        cursor.splice([index, 1]);

        const newIndex = _.findIndex(cursor.get(), ['worksheetId', payload.nextWorksheetId]);
        if (payload.nextWorksheetId && newIndex >= 0) {
          cursor.splice([newIndex, 0, worksheet]);
        } else {
          cursor.push(worksheet);
        }
      }
    },

    /**
     * Add a worksheet to the workbook if it is not already present
     *
     * @param {Object} worksheet - The worksheet to be added to the store
     * @param {number} position - position where the worksheet will be added. When not provided, it will be added at
     * the end of worksheet list
     */
    WORKBOOK_ADD_WORKSHEET: ({ worksheet, position }) => {
      const cursor = this.state.select('worksheets');
      const index = _.findIndex(cursor.get(), ['worksheetId', worksheet.worksheetId]);
      if (index < 0) {
        if (!_.isUndefined(position) && position >= 0) {
          cursor.splice([position, 0, worksheet]);
        } else {
          cursor.push(worksheet);
        }
      }
    },

    /**
     * Sets a property on one of the worksheets
     *
     * @param {Object} payload - Object container for properties
     * @param {String} payload.worksheetId - ID of the worksheet on which to set the property
     * @param {String} payload.propertyName - Name of the property to set
     * @param {String} payload.propertyValue - Value for the property
     */
    WORKBOOK_SET_WORKSHEET_PROPERTY: (payload) => {
      const newProperty = {};
      const cursor = this.state.select('worksheets');
      const index = _.findIndex(cursor.get(), ['worksheetId', payload.worksheetId]);

      if (index >= 0) {
        newProperty[payload.propertyName] = payload.propertyValue;
        cursor.merge(index, newProperty);
      }
    },

    /**
     * Sets the ACL for workbook. This is used by the "Get Link" feature to display who has access to the current
     * workbook.
     *
     * @param  {Object} payload - Object container for properties
     * @param  {Object[]} payload.acl - the workbook ACL
     */
    WORKBOOK_SET_ACL: (payload) => {
      this.state.set('acl', payload.acl);
    },

    /**
     * Clears the worksheets.
     */
    WORKBOOKS_ADD_WORKBOOK: () => {
      this.state.set('worksheets', []);
    },

    /**
     * Sets the workbook owner.
     *
     * @param {Object} payload - The new owner
     * @param payload.id - The ID of the new owner
     * @param payload.name - The name of the new owner
     */
    WORKBOOK_SET_OWNER: (payload: { id: string; name: string }) => {
      this.state.set('owner', payload);
    },

    /**
     * Sets the viewers of this workbook.
     *
     * @param {Object[]} payload - The new list of viewers
     * @param {String} payload.id - ID of the user
     * @param {String} payload.name - name of the user
     * @param {String} payload.workbookDisplay - the display mode of the user
     * @param {String} payload.worksheetId - ID of the worksheet the user is viewing
     */
    WORKBOOK_SET_VIEWERS: (payload) => {
      this.state.set('viewers', payload);
    },
  };
}
