// @ts-strict-ignore
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomPropertySelector, SuggestedPropertiesMode } from '@/hybrid/utilities/CustomPropertySelector.atom';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { PropertyColumn, StatisticColumn } from '@/trendData/trendData.constants';

interface ItemPropertiesSelectorProps {
  suggestedPropertiesMode?: SuggestedPropertiesMode;
  itemIds: string[];
  extraColumns?: PropertyColumn[];
  lookupColumns?: PropertyColumn[];
  extraSelectorProperties?: PropertyColumn[];
  propertyColumns?: PropertyColumn[];
  statColumns?: StatisticColumn[];
  showPropertyColumns?: boolean;
  isColumnEnabled: (column: any) => boolean;
  toggleColumn: (column: any) => void;
  addPropertyColumn?: (column: any) => void;
  customContent?: React.ReactNode;
  showCustomPropertySelector?: boolean;
  maxHeightPropertyColumns?: number;
  displaySectionHeaders?: boolean;
}

export const ItemPropertiesSelector: React.FunctionComponent<ItemPropertiesSelectorProps> = ({
  suggestedPropertiesMode,
  itemIds,
  extraColumns = [],
  extraSelectorProperties = [],
  propertyColumns = [],
  statColumns,
  showPropertyColumns = true,
  isColumnEnabled,
  toggleColumn,
  addPropertyColumn = _.noop,
  customContent,
  showCustomPropertySelector = true,
  maxHeightPropertyColumns = 65,
  lookupColumns,
  displaySectionHeaders = false,
}) => {
  const { t } = useTranslation();

  const customStyles = {
    menu: (provided) => ({ ...provided, width: '160px' }),
    option: (styles) => ({ ...styles, padding: '2px' }),
  };

  // Don't display property columns that we're already showing as extra columns
  const propertyColumnsToDisplay = _.reject(propertyColumns, (column) =>
    _.some(extraColumns, (extraColumn) => extraColumn.key === column.key),
  );

  const mapColumnsToList = (columns: PropertyColumn[] | StatisticColumn[]) =>
    _.map(columns, (column: StatisticColumn | PropertyColumn) => (
      <Checkbox
        classes="textPrimaryMenuItem"
        id={`${column.key}-checkbox`}
        key={`${column.key}-checkbox`}
        label={t(column.title || column.shortTitle)}
        isChecked={isColumnEnabled(column)}
        onChange={() => toggleColumn(column)}
        skipMemo={true}
      />
    ));

  return (
    <div className="flexColumnContainer" data-testid="itemPropertiesSelector">
      {statColumns && statColumns.length > 0 && <div>{mapColumnsToList(statColumns)}</div>}
      <div className="ml10" style={{ minWidth: '150px' }}>
        {!_.isEmpty(lookupColumns) && (
          <>
            {displaySectionHeaders && <h5>{t('CAPSULES_PANEL.CONDITION_PROPERTIES')}</h5>}
            {mapColumnsToList(lookupColumns)}
            <hr className="mt5 mb5" />
          </>
        )}
        {!_.isEmpty(extraColumns) && (
          <>
            {displaySectionHeaders && <h5>{t('CAPSULES_PANEL.CAPSULE_PROPERTIES')}</h5>}
            {mapColumnsToList(extraColumns)}
            <hr className="mt5 mb5" />
          </>
        )}
        {showPropertyColumns && (
          <>
            <div className="tableWrapper" style={{ maxHeight: `${maxHeightPropertyColumns}px` }}>
              {mapColumnsToList(propertyColumnsToDisplay)}
            </div>
            {showCustomPropertySelector && (
              <CustomPropertySelector
                excludedProperties={_.map(propertyColumns, (propertyColumn) => propertyColumn.shortTitle)}
                extraProperties={extraSelectorProperties}
                suggestedPropertiesMode={suggestedPropertiesMode}
                itemIds={itemIds}
                addPropertyColumn={addPropertyColumn}
                customStyles={customStyles}
                dropdownPlaceholder="CUSTOM_COLUMN_SELECTOR.CUSTOM_PROPERTY"
              />
            )}
          </>
        )}
        {customContent}
      </div>
    </div>
  );
};
