import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { ReportActions } from '@/reportEditor/report.actions';

import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { Accordion, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReportConfigBackupsEntry } from '@/hybrid/reportConfig/components/backups/components/ReportConfigBackupsEntry.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import _ from 'lodash';
import { CustomToggle, Toggler } from '@/hybrid/core/CustomToggle.atom';
import { sqReportStore } from '@/core/core.stores';

const reportConfigBackupsBindings = bindingsDefinition({
  sqReportActions: injected<ReportActions>(),
});

export const ReportConfigBackups: SeeqComponent<typeof reportConfigBackupsBindings> = () => {
  const { sqReportActions } = useInjectedBindings(reportConfigBackupsBindings);

  const backups = useFluxPath(sqReportStore, () => sqReportStore.backups);
  const backupPreview = useFluxPath(sqReportStore, () => sqReportStore.backupPreview);
  const { t } = useTranslation();

  return (
    <Accordion className="reportBackups">
      <Card uib-accordion-group="true">
        <Card.Header>
          <CustomToggle eventKey="0" Component={Toggler} label="REPORT.CONFIG.HISTORY" />
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {_.map(backups, (el, index) => (
              <ReportConfigBackupsEntry
                onClickHandler={() => sqReportActions.setBackupPreview(el)}
                key={`bkup_${index}`}
                date={el.formattedBackupDate}
                displayName={el.username}
                selected={backupPreview && el.backupName === backupPreview.backupName}
              />
            ))}
            {backups.length === 0 && (
              <div className="text-italic sq-fairly-dark-gray p5">{t('REPORT.CONFIG.HISTORY_EMPTY')}</div>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
