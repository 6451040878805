// @ts-strict-ignore
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import Highchart from 'highcharts';
import { useResizeWatcher } from '@/hybrid/core/hooks/useResizeWatcher.hook';
import { useDebounce } from '@/hybrid/core/hooks/useDebounce.hook';
import { DEBOUNCE } from '@/core/core.constants';

export interface ResizableHighchartsProps {
  /** the chart instance */
  chart: Highchart.Chart;
  /** the DOM element to check for resize events */
  chartElementRef: React.RefObject<HTMLElement>;
  /** the callback function used by the parent to set the new chart value */
  setChart: React.Dispatch<any>;
  /** the callback function to be used if we need to extend the default */
  reflowChartCallback?: () => void;
  /** true if the resize callback should be called on load as well */
  callOnLoad?: boolean;
  /** the callback function to be used if we need to extend the default */
  onDestroyChart?: () => void;
  /** milliseconds used for debounce */
  reflowWait?: number;
}

/**
 * A hook to handle the resize event for highcharts
 */

export default function useResizableHighchart({
  chart,
  chartElementRef,
  setChart,
  reflowChartCallback = _.noop,
  callOnLoad = true,
  onDestroyChart = _.noop,
  reflowWait = DEBOUNCE.MEDIUM,
}: ResizableHighchartsProps) {
  const reflowChart = useDebounce(() => {
    const element = chartElementRef.current;
    if (!element || _.isEmpty(chart)) {
      return;
    }

    reflowChartCallback();

    chart.reflow();
    element.className = element.className.replace(/\binvisible\b/g, '');
  }, reflowWait);

  useResizeWatcher({
    elementRef: chartElementRef,
    callback: reflowChart,
    callOnLoad,
  });

  /**
   * Removes the chart.
   */
  const destroyChart = useCallback(() => {
    onDestroyChart();

    if (chart && chart.destroy) {
      chart.destroy();
      // Highcharts-React should destroy the chart itself
      setChart(null);
    }

    if (chartElementRef.current) {
      chartElementRef.current.innerHTML = '';
    }
  }, [chart, chartElementRef.current]);

  useEffect(() => {
    return () => destroyChart();
  }, []);
}
