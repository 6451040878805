// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { sqAgentsApi } from '@/sdk';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';

interface AgentModalProps {
  agentName: string;
  onClose: () => void;
}

export const ManageAgentModal: React.FunctionComponent<AgentModalProps> = ({ agentName, onClose }) => {
  const { t } = useTranslation();
  const [agentConfig, setAgentConfig] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    sqAgentsApi
      .getAgent({ agentName })
      .then(({ data }) => {
        setAgentConfig(data.json);
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [agentName]);

  const updateAgent = ({ agentName, config }) => {
    return sqAgentsApi
      .createOrUpdateAgent({ json: config }, { agentName })
      .then(() => {
        successToast({ messageKey: 'ADMIN.AGENTS.SUCCESSFULLY_UPDATED' });
        onClose();
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  };

  const formDefinition: FormElement[] = [
    {
      key: 'nameLabel',
      component: 'LabelFormComponent',
      value: agentName,
      name: 'nameLabel',
      testId: 'labelAgentConfig',
    },
    {
      key: 'agentConfig',
      component: 'JsonTextAreaFormComponent',
      name: 'agentConfig',
      value: agentConfig,
      testId: 'jsonAgentConfig',
      disabled: loading,
      size: 'lg',
      onChange: (config) => setAgentConfig(config.toString()),
      rows: 7,
    },
  ];

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="manageAgentModal">
      <Modal.Header closeButton={true} className="flexColumnContainer flexSpaceBetween">
        <h3>{t('ADMIN.AGENTS.MANAGE_AGENT_MODAL')}</h3>
      </Modal.Header>
      <Modal.Body>
        <SimpleSaveFormBuilder
          formDefinition={formDefinition}
          submitFn={() => updateAgent({ agentName, config: agentConfig })}
          closeFn={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};
