// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { updateAdminContactInfo } from '@/administration/administration.actions';
import { handleModalOpenErrors } from '@/hybrid/utilities/utilities';

interface AdminContactModalProps {
  name: string;
  email: string;
  onClose: () => void;
}

export const AdminContactModal: React.FunctionComponent<AdminContactModalProps> = ({ name, email, onClose }) => {
  const { t } = useTranslation();

  /**
   * Save the administrator contact information from this form.
   *
   * @returns {Promise} that resolves when the update is complete
   */
  const updateAdminContactInStore = (adminContactInfo) => {
    return updateAdminContactInfo({
      name: adminContactInfo.name,
      email: adminContactInfo.email,
    })
      .then(onClose)
      .catch(handleModalOpenErrors);
  };

  const formDefinition: FormElement[] = [
    {
      component: 'FormControlFormComponent',
      name: 'name',
      label: 'USER.NAME',
      value: name,
      onChange: _.noop,
      size: 'md',
      testId: 'name',
      required: true,
    },
    {
      component: 'FormControlFormComponent',
      name: 'email',
      label: 'USER.EMAIL',
      value: email,
      onChange: _.noop,
      size: 'md',
      testId: 'email',
      required: true,
    },
  ];

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="adminContactModal">
      <Modal.Header closeButton={true}>
        <h3>{t('ADMIN.CONFIGURATION.CONTACT_INFORMATION')}</h3>
      </Modal.Header>
      <Modal.Body>
        <SimpleSaveFormBuilder formDefinition={formDefinition} submitFn={updateAdminContactInStore} closeFn={onClose} />
      </Modal.Body>
    </Modal>
  );
};
