// @ts-strict-ignore
import { sqUsersApi } from '@/sdk';
import _ from 'lodash';
import { useState } from 'react';
import { useLogin } from '@/hybrid/main/auth/hooks/useLogin.hook';

export const useAuth = ({ seeqDomain, domain, updateDomain }) => {
  const { doLogin } = useLogin({ domain, updateDomain });

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    passwordCheck: '',
  });
  const [serverMessage, setServerMessage] = useState('');

  const { firstName, lastName, username, password } = formData;

  const updateFormData = (data: Partial<typeof formData>) =>
    setFormData((formData) => ({
      ...formData,
      ...data,
    }));

  /**
   * Submits a request to generate a new user and executes a consecutive login.
   *
   * @returns {Promise} that resolves after the user was created and logged in
   */
  const register = () => {
    setServerMessage('');

    return sqUsersApi
      .createUser({
        firstName,
        lastName,
        username,
        email: username,
        password,
      })
      .then(() => doLogin({ username, password }, seeqDomain.datasourceClass, seeqDomain.datasourceId))
      .catch((e) => {
        const statusMessage = _.get(e, 'response.data.statusMessage');
        if (statusMessage) {
          setServerMessage(`(${statusMessage})`);
        }
      }) as Promise<any>;
  };

  return { updateFormData, formData, register, serverMessage };
};
