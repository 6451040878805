// @ts-strict-ignore
import React from 'react';
import { Modal } from 'react-bootstrap';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { SearchWidget } from '@/hybrid/search/SearchWidget.organism';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { onEventPreventPropagation } from '@/hybrid/core/onEnterKeypress.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import _ from 'lodash';
import { isAsset } from '@/hybrid/utilities/utilities';

const selectAssetModalBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  showHeaderCloseButton: prop.optional<boolean>(),
  onSelect: prop<(item) => void>(),
  iconPartialTooltipKey: prop<string>(),
  assetId: prop<string>(),
  validateAsset: prop<(item) => Promise<boolean>>(),
  scopeIds: prop.optional<string[]>(),
  excludeGloballyScoped: prop.optional<boolean>(),
  testId: prop.optional<string>(),
  header: prop<JSX.Element | HTMLElement>(),
  /** HTML that should appear above search widget, but below title **/
  body: prop<JSX.Element | HTMLElement | null>(),
  /** HTML that should appear below the search widget **/
  footer: prop<JSX.Element | HTMLElement | null>(),
  modalId: prop.optional<string>(),
});

export const SelectAssetModal: SeeqComponent<typeof selectAssetModalBindings> = ({
  onClose,
  header,
  body,
  footer,
  showHeaderCloseButton = true,
  onSelect,
  iconPartialTooltipKey,
  assetId,
  validateAsset,
  scopeIds,
  excludeGloballyScoped = false,
  testId = 'selectAssetModal',
  modalId = testId,
}) => {
  const SEARCH_TYPES = [SeeqNames.Types.Asset, SeeqNames.Types.AssetSelection];

  const searchResultIcons = (item): Promise<JSX.Element | void> => {
    if (!isAsset(item)) {
      return Promise.resolve();
    }
    return validateAsset(item).then((isValid) => {
      const isSelected = assetId ? item.id === assetId : false;
      return isValid ? (
        <span onClick={onEventPreventPropagation(() => onSelect(item))}>
          <Icon
            testId="assetSelect"
            extraClassNames="searchBtn"
            tooltip={`${iconPartialTooltipKey}.ASSET_TOOLTIP`}
            large={true}
            icon={isSelected ? 'fa-check-circle' : 'fa-circle-o'}
          />
        </span>
      ) : (
        <span onClick={onEventPreventPropagation(_.noop)}>
          <Icon
            testId="assetSelectInvalid"
            extraClassNames="disabled mr3 p3"
            type="color"
            color="grey"
            tooltip={`${iconPartialTooltipKey}.ASSET_TOOLTIP_INVALID`}
            large={true}
            icon="fa-circle-o"
          />
        </span>
      );
    });
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      animation={false}
      size="sm"
      scrollable={true}
      contentClassName="scrollableSearchModal"
      data-testid={testId}>
      <Modal.Header closeButton={showHeaderCloseButton}>{header}</Modal.Header>
      <Modal.Body>
        {body}
        <SearchWidget
          isSelectingAsset={true}
          iconClasses="sq-text-darkest-gray"
          pane="modal"
          searchTypes={SEARCH_TYPES}
          searchResultIcons={searchResultIcons}
          allowAssetReplacement={false}
          showOnlyResults={true}
          scopeIds={scopeIds}
          excludeGloballyScoped={excludeGloballyScoped}
          modalId={modalId}
        />
      </Modal.Body>
      {footer}
    </Modal>
  );
};
