// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { addItemToAsset } from '@/hybrid/assetGroupEditor/assetGroup.actions';
import { SearchWidget } from '@/hybrid/search/SearchWidget.organism';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { validateItemToAdd } from '@/hybrid/assetGroupEditor/assetGroup.utilities';
import { clear } from '@/search/search.actions';

interface AssetFinderProps {
  // 2 different functions for closing here as on regular close we close the modal and return to the details modal
  // if applicable, if we added an item however we always entirely close the modal.
  onClose: () => void;
  onCloseAfterAssignment?: () => void;
  asset: { name: string };
  columnName: string;
  testId?: string;
}
export const SEARCH_TYPES_ASSET_GROUP = [
  SeeqNames.Types.Asset,
  SeeqNames.Types.StoredCondition,
  SeeqNames.Types.CalculatedCondition,
  SeeqNames.Types.StoredSignal,
  SeeqNames.Types.CalculatedSignal,
  SeeqNames.Types.CalculatedScalar,
];
export const AssetFinderModal: React.FunctionComponent<AssetFinderProps> = ({
  onClose,
  asset,
  columnName,
  onCloseAfterAssignment,
  testId,
}) => {
  const { t } = useTranslation();

  const closeAndReset = (didAddItem) => {
    clear('modal', SEARCH_TYPES_ASSET_GROUP);
    didAddItem && _.isFunction(onCloseAfterAssignment) ? onCloseAfterAssignment() : onClose();
  };

  return (
    <Modal
      show={true}
      onHide={() => closeAndReset(false)}
      animation={false}
      scrollable={true}
      contentClassName="scrollableSearchModal"
      data-testid={testId}>
      <Modal.Header closeButton={true}>
        <div className="flexRowContainer">
          <h4>{t('ASSET_GROUP_EDITOR.FIND_SIGNAL')}</h4>
          <span>{`${asset.name} » ${columnName}`}</span>
        </div>
      </Modal.Header>

      <Modal.Body>
        <SearchWidget
          pane="modal"
          modalId="assetFinderModal"
          searchTypes={SEARCH_TYPES_ASSET_GROUP}
          allowAssetReplacement={false}
          restrictExploration={true}
          onItemClick={(item) => {
            if (validateItemToAdd({ item })) {
              addItemToAsset({
                asset,
                columnName,
                item,
                type: item.type,
              });
            }
            closeAndReset(true);
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
