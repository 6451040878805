// @ts-strict-ignore
import { useTranslation } from 'react-i18next';
import { CAPSULE_MODES } from '@/hybrid/tools/histogram/histogram.constants';
import React from 'react';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';
import classNames from 'classnames';

export const CapsuleModesLabel = ({ capsuleMode, extraClassNames }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('btn btn-default form-control mt3', extraClassNames)} tabIndex={0}>
      <div className="flexColumnContainer flexSpaceBetween flexAlignCenter">
        <strong>{t(_.find(CAPSULE_MODES, { key: capsuleMode })?.display)}</strong>
        <Icon icon="fa-caret-down" />
      </div>
    </div>
  );
};
