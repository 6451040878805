// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { ReportActions } from '@/reportEditor/report.actions';

import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { ReportConfigDateRangeTable } from '@/hybrid/reportConfig/components/dateRanges/dateRangeTable/ReportConfigDateRangeTable.molecule';
import { ConfigureAutoUpdateModal } from '@/hybrid/reportConfig/components/dateRanges/autoUpdateModal/ConfigureAutoUpdateModal.organism';
import { AutoUpdateSection } from '@/hybrid/reportConfig/components/dateRanges/autoUpdateModal/AutoUpdateSection.molecule';

import { ReportDateModal } from '@/hybrid/reportConfig/components/dateRanges/reportDateModal/ReportDateModal.molecule';
import { ReportDateRangeAssetSwapModal } from '@/hybrid/reportConfig/components/dateRanges/dateRangeAssetSwapModal/ReportDateRangeAssetSwapModal.organism';
import { logError } from '@/hybrid/utilities/logger';
import { formatMessage } from '@/hybrid/utilities/logger.utilities';
import { DateRange } from '@/reportEditor/report.constants';
import { sqReportStore, sqWorksheetStore } from '@/core/core.stores';
import { useModalManager } from '@/hybrid/core/hooks/useModalManager.hook';
import { isViewOnlyWorkbookMode as isViewOnlyWorkbookModeSqUtilities } from '@/hybrid/utilities/utilities';
import { BulkEditMode } from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/reportContent.constants';

/**
 * Returns true if date ranges should not be edited because we are previewing a backup or already updating a date
 * range
 */
function areDateRangesReadOnly(sqReportStore): boolean {
  return sqReportStore.backupPreview || sqReportStore.dateRangeUpdating;
}

/**
 * Error handling helper for managing the `dateRangeUpdating` flag. It ensures that two actions don't try
 * and manipulate the date ranges at the same time.
 *
 */
function withDateRangeUpdating(sqReportStore, sqReportActions) {
  return (action) => {
    if (areDateRangesReadOnly(sqReportStore)) {
      return;
    }
    sqReportActions.setDateRangeUpdating(true);
    return Promise.resolve()
      .then(action)
      .catch((e) => logError(formatMessage`Error updating date range from topic left panel: ${e}`))
      .finally(() => {
        sqReportActions.setDateRangeUpdating(false);
      });
  };
}

/**
 * Opens the date selector modal.
 *
 */
function openDateSelector(
  openReportDateModal,
  setSelectedDateRange,
): (dateRange: DateRange, isCapsuleSelectMode: boolean) => void {
  return (dateRange, isCapsuleSelectMode: boolean) => {
    const dr = dateRange ? _.cloneDeep(dateRange) : { auto: { enabled: false } };
    setSelectedDateRange(dr);
    openReportDateModal(isCapsuleSelectMode);
  };
}

function onTzClose(sqReportActions) {
  sqReportActions.setShowConfigureAutoUpdateModal(false);
}

const reportConfigDatesBindings = bindingsDefinition({
  sqReportActions: injected<ReportActions>(),
  canApplyUrlOverride: prop.optional<boolean>(),
});

export const ReportConfigDates: SeeqComponent<typeof reportConfigDatesBindings> = () => {
  const { sqReportActions } = useInjectedBindings(reportConfigDatesBindings);
  const [selectedDateRange, setSelectedDateRange] = useState(undefined);
  const [showReportDateModal, setShowReportDateModal] = useModalManager(ReportDateModal);

  const openBulkEditWithGivenRange = (dateRange: DateRange) => {
    sqReportActions.setBulkEditDisplayMode(BulkEditMode.DATE_RANGE);
    sqReportActions.setBulkDateRange(dateRange);
  };

  // force the props to be updated so we can get the new name
  const timezoneName = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone?.name);
  const {
    backupPreview,
    sandboxMode,
    dateRangeUpdating,
    dateRangesNotArchived,
    activeDateRangeSwapInfo,
    showChooseCapsuleModal,
    showChooseAssetSwapModal,
    showConfigureAutoUpdateModal,
    shouldShowConfigureAutoUpdateModal,
  } = useFlux(sqReportStore);
  const dateRangeToSwap = activeDateRangeSwapInfo.currentSwap?.dateRange;
  const viewOnlyWorkbookMode = isViewOnlyWorkbookModeSqUtilities();
  const isSandboxMode = sandboxMode.enabled;
  const readOnly = viewOnlyWorkbookMode && !isSandboxMode;
  const openAsSelectCapsuleForSwap =
    !!dateRangeToSwap && showChooseCapsuleModal && !showConfigureAutoUpdateModal && !shouldShowConfigureAutoUpdateModal;
  const openAsSelectCapsuleOnly = !dateRangeToSwap && showChooseCapsuleModal;

  useEffect(() => {
    if (openAsSelectCapsuleForSwap) {
      setSelectedDateRange(dateRangeToSwap);
    }
    setShowReportDateModal(openAsSelectCapsuleForSwap || openAsSelectCapsuleOnly);
  }, [openAsSelectCapsuleForSwap, openAsSelectCapsuleOnly]);

  const openReportDateModal = (isCapsuleSelectMode: boolean) => {
    if (isCapsuleSelectMode) {
      sqReportActions.setShowChooseCapsuleModal(true);
    } else {
      setShowReportDateModal(true);
    }
  };

  const closeReportDateModal = () => {
    setShowReportDateModal(false);
    if (showChooseCapsuleModal) {
      sqReportActions.setActiveDateRangeSwapInfo(undefined, []);
      sqReportActions.setShowChooseCapsuleModal(false);
    }
  };

  return (
    <>
      <ReportConfigDateRangeTable
        isSandboxMode={isSandboxMode}
        viewOnly={viewOnlyWorkbookMode}
        backupPreview={backupPreview}
        dateRanges={dateRangesNotArchived}
        dateRangeUpdating={dateRangeUpdating}
        hasAutoDateRanges={sqReportStore.hasAutoDateRanges}
        hasFixedDateRanges={sqReportStore.hasFixedDateRanges}
        openDateSelector={openDateSelector(openReportDateModal, setSelectedDateRange)}
        timezone={timezoneName}
        withDateRangeUpdating={withDateRangeUpdating(sqReportStore, sqReportActions)}
        openBulkEditWithGivenRange={openBulkEditWithGivenRange}
      />
      {sqReportStore.showConfigureAutoUpdateModal && !readOnly && (
        <ConfigureAutoUpdateModal
          schedule={sqReportStore.reportSchedule}
          onClose={onTzClose.bind(null, sqReportActions)}
          reportScheduleOverride={sqReportStore.reportScheduleOverride}
        />
      )}
      {sqReportStore.hasAutoDateRanges && (
        <div className="flexRowContainer toolOptions mb20">
          <AutoUpdateSection timezone={timezoneName} viewOnly={viewOnlyWorkbookMode} />
        </div>
      )}
      {showReportDateModal && selectedDateRange && (
        <ReportDateModal
          selectedDateRange={selectedDateRange}
          onCloseModal={closeReportDateModal}
          isCapsuleSelectOnly={openAsSelectCapsuleForSwap || openAsSelectCapsuleOnly}
        />
      )}
      {showChooseAssetSwapModal && (
        <ReportDateRangeAssetSwapModal onClose={() => sqReportActions.setShowChooseAssetSwapModal(false)} />
      )}
    </>
  );
};
