// @ts-strict-ignore
import _ from 'lodash';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { defer } from '@/hybrid/utilities/utilities';

export type RequestId = string;
export type DeferredPromise = {
  resolve: (data: any) => any;
  reject: (error: any) => any;
};

export type AsyncResponseMessage = {
  status: number;
  statusText: string;
  requestId: string;
  requestUrl: string;
  headers: { [_: string]: string };
  body: any;
};

type AsyncResponseWaiter = {
  id: string;
  accepted: boolean;
  deferred: DeferredPromise;
};

const waiters: AsyncResponseWaiter[] = [];
let unsubscribe: () => void = _.noop;

// for testing only!
export function getAllRequestIds() {
  return _.map(waiters, 'id');
}

export function getOutstandingRequestIds() {
  return _.chain(waiters).filter('accepted').map('id').value();
}

export function hasOutstandingRequests() {
  return waiters.length !== 0;
}

/**
 * Subscribes to async responses for this session. Also unsubscribes from the subscription to the previous
 * interactive session, if present.
 *
 * @param {String} sessionId - Interactive session ID that identifies this client connection.
 */
export function subscribeToAsyncResponse(sessionId, subscribe) {
  unsubscribe();
  unsubscribe = subscribe({
    channelId: [SeeqNames.Channels.AsyncResponse, sessionId],
    onMessage: onResponse,
    useSubscriptionsApi: false, // Channel is auto-subscribed by the backend
  });
}

/**
 * Returns a promise that is resolved/rejected when the async response for the request ID specified is received.
 *
 * @param {String} requestId - ID of the HTTP request to wait for
 * @returns {Promise} that resolves with the response
 */
export function waitForAsyncResponse(requestId: RequestId) {
  const waitHandler = {
    id: requestId,
    accepted: false,
    deferred: defer(),
  };
  waiters.push(waitHandler);
  return waitHandler.deferred;
}

/**
 * Should be called when the backend responds to a request with a 202 status indicating that the response will be
 * sent asynchronously. Sets an accepted flag on the waiter that is used to filter waiters from being in the
 * outstandingRequestsIds list until the backend has processed and accepted the async request.
 *
 * @param {String} requestId - Request ID to acknowledge
 */
export function asyncRequestAccepted(requestId: RequestId) {
  const acceptedWaiters = _.filter(waiters, { id: requestId });
  _.forEach(acceptedWaiters, (waiter) => {
    waiter.accepted = true;
  });
}

/**
 * Cancels and removes any handlers waiting on the specified requestId
 *
 * @param {String} requestId - Request ID to cancel
 */
export function cancelWait(requestId: RequestId) {
  const waitersToCancel = _.filter(waiters, { id: requestId });
  const resolutions = _.map(waitersToCancel, (waiter) => {
    _.pull(waiters, waiter);

    // The following fields are mainly for type conformance: the `status: -1` is the only really-needed part.
    return waiter.deferred.resolve({
      requestId,
      requestUrl: 'about:blank',
      statusText: 'canceled',
      body: '{"statusMessage": "canceled"}',
      headers: {},
      // See special handling in httpHelpers.response -> sqHttpHelpers.isCanceled
      status: -1,
    });
  });

  return Promise.all(resolutions);
}

/**
 * Internal handler for socket responses. Resolves the appropriate waiter promise(s) when responses are received.
 *
 * @param {Object} response - Data received from websocket
 */
function onResponse(response) {
  const waitersToComplete = _.filter(waiters, { id: response.requestId });
  const resolutions = _.map(waitersToComplete, (waiter) => {
    _.pull(waiters, waiter);
    return waiter.deferred.resolve(response);
  });
  return Promise.all(resolutions);
}

/**
 * Determines if the supplied request ID is outstanding.
 *
 * @param {String} requestId - a request ID
 * @returns {boolean} true if the request is outstanding, false otherwise
 */
export function isOutstandingRequest(requestId: RequestId) {
  return getOutstandingRequestIds().indexOf(requestId) >= 0;
}
