/**
 * @exports the browser id (i.e., 'IE 11' 'Chrome 90')
 * @see http://stackoverflow.com/questions/2400935/browser-detection-in-javascript
 */
export const browserId: string = (function () {
  let tem;
  const ua = navigator.userAgent;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return `IE ${tem[1] || ''}`;
  }

  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge?)\/(\d+)/);
    if (tem !== null) {
      return tem.slice(1).join(' ').replace('OPR', 'Opera').replace('Edg ', 'Edge ');
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) !== null) {
    M.splice(1, 1, tem[1]);
  }

  return M.join(' ');
})();
export const browserName = browserId && browserId.split(' ', 2)[0];
export const browserVersion = browserId && parseInt(browserId.split(' ', 2)[1], 10);
export const browserIsFirefox = browserId && browserName === 'Firefox';
export const browserIsEdgeBeforeChromium =
  browserName && browserVersion && browserName === 'Edge' && browserVersion < 70;
