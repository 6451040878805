import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MinimapInner } from '@/hybrid/scatterPlot/minimap/MinimapInner.molecule';
import { MinimapDragLayer } from '@/hybrid/scatterPlot/minimap/MinimapDragLayer.atom';
import { SerializedRange } from '@/hybrid/datetime/dateTime.utilities';

export interface ScatterPlotMinimapProps {
  displayRange: SerializedRange;
  xValue: number;
  plotSeries: any[];
  regions: { start: number; end: number; color: string; id: string }[];
  selectorLow: number;
  selectorHigh: number;
  setSelectors: any;
  useSelectors: boolean;
  backgroundColor?: string;
  isContent?: boolean;
}

/**
 * Wraps the Minimap with a DnDProvider and incorporates the MinimapDragLayer at the same level as the
 *  ScatterPlotMinimap, which is the drag container.
 **/
export const ScatterPlotMinimap: React.FunctionComponent<ScatterPlotMinimapProps> = (props) => (
  <DndProvider backend={HTML5Backend}>
    <div className="flexFill flexRowContainer">
      <div className="minimapDragLayer">
        <MinimapDragLayer />
      </div>
      <MinimapInner {...props} />
    </div>
  </DndProvider>
);
