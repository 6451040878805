import _ from 'lodash';
import { findItemIn } from '@/hybrid/trend/trendDataHelper.utilities';
import { InitializeMode, PersistenceLevel, Store } from '@/core/flux.service';
import { sqTrendScalarStore, sqTrendSeriesStore } from '@/core/core.stores';

export class TreemapStore extends Store {
  static readonly storeName = 'sqTreemapStore';
  persistenceLevel: PersistenceLevel = 'WORKSHEET';

  /**
   * Initializes the store by setting default values to the stored state
   */
  initialize(initializeMode: InitializeMode) {
    const saveState = this.state && initializeMode !== 'FORCE';
    this.state = this.immutable({
      swap: {},
      parent: {},
      breadcrumbs: [],
      tree: undefined,
      isFetching: false,
      helpKey: undefined,
      statistics: [],
      priorityColors: saveState ? this.state.get('priorityColors') : [],
      neutralColor: saveState ? this.state.get('neutralColor') : undefined,
    });
  }

  get swap() {
    return this.state.get('swap');
  }

  get parent() {
    return this.state.get('parent');
  }

  get breadcrumbs(): any[] {
    return this.state.get('breadcrumbs');
  }

  get tree() {
    return this.state.get('tree');
  }

  get helpKey() {
    return this.state.get('helpKey');
  }

  get statistics(): any[] {
    return this.state.get('statistics');
  }

  /**
   * Returns an array that is ordered by index of only those statistics that have both id and key.
   */
  get validStatistics() {
    return _.chain(this.state.get('statistics'))
      .reject(
        (statistic) =>
          _.isNil(statistic.key) ||
          _.isNil(statistic.id) ||
          _.isEmpty(findItemIn([sqTrendSeriesStore, sqTrendScalarStore], statistic.id)),
      )
      .sortBy(['index'])
      .value();
  }

  get priorityColors(): string[] {
    return this.state.get('priorityColors');
  }

  get neutralColor(): string {
    return this.state.get('neutralColor');
  }

  /**
   * Sets the asset that is being swapped out. Invalidates the parent when it changes since a new swap out asset
   * means a new hierarchy.
   *
   * @param {Object} payload - Object container for arguments
   */
  private setSwap = (payload: {
    /** The ID of the asset */
    id: string;
    /** The name of the asset */
    name: string;
  }) => {
    if (!_.isEmpty(this.state.get('swap')) && payload.id !== this.state.get('swap').id) {
      this.state.set('parent', {});
    }

    this.state.set('swap', payload);
  };

  /**
   * Sets the parent asset for swapping, the children underneath it will be displayed in the treemap. Since setting
   * the parent changes the breadcrumbs those are cleared.
   *
   * @param {Object} payload - Object container for arguments
   */
  private setParent = (payload: {
    /** The ID of the asset */
    id: string;
  }) => {
    this.state.set('parent', payload);
    this.state.set('breadcrumbs', []);
    this.state.set('tree', undefined);
  };

  /**
   * Sets the breadcrumbs that help the user navigate to the current parent.
   *
   * @param {Object} payload - Object container for arguments
   */
  private setBreadcrumbs = (payload: {
    /** An array of assets that lead up to the current parent.  */
    breadcrumbs: Record<string, any>[];
  }) => {
    this.state.set('breadcrumbs', payload.breadcrumbs);
  };

  /**
   * Sets the tree data for generating the treemap.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object[]} payload.tree - An array of data for each asset.
   * @param {Object} payload.tree[].asset - The asset to which this result corresponds.
   * @param {String} payload.tree[].color - The color of the box. Should be one of the treemap colors.
   * @param {Number} payload.tree[].size - The relative box size.
   * @param {Boolean} payload.tree[].isLeafAsset - True if it is a leaf asset, false otherwise.
   */
  private setTree = (payload: any) => {
    this.state.set('tree', payload.tree);
    this.state.set('helpKey', undefined);
  };

  /**
   * Sets the translation key that tells the user why the treemap cannot be loaded.
   *
   * @param {Object} payload - Object container for arguments
   */
  private setHelpKey = (payload: {
    /** A translation key that tells the user why the treemap can't be loaded. */
    helpKey: boolean;
  }) => {
    this.state.set('helpKey', payload.helpKey);
  };

  /**
   * Set a statistic to be displayed on each asset node.
   *
   * @param {Object} payload - Object container for arguments
   */
  private setStatistic = (payload: {
    /** Which statistic is being set */
    index: number;
    /** The id of the signal to use for calculating the statistic */
    id: string;
    /** One of the statistic keys from SAMPLE_FROM_SCALARS.VALUE_METHODS */
    key: string;
  }) => {
    const currentStatistic = this.state.get('statistics')[payload.index];
    this.state.set(['statistics', payload.index], {
      ...currentStatistic,
      ...payload,
    });
  };

  /**
   * Sets the colors for the treemap priorities.
   *
   * @param {Object} payload - Object container for arguments
   */
  private setColors = (payload: {
    /** The list of colors, ordered by priority from highest to lowest, with the neutral color last. */
    colors: string[];
  }) => {
    this.state.set('priorityColors', _.initial(payload.colors));
    this.state.set('neutralColor', _.last(payload.colors));
  };

  /**
   * Updates the selected statistics when items in the details are asset swapped.
   *
   * @param {Object} payload - Object container for arguments
   * @param {Object} payload.swaps - The items that were swapped where the keys are the swapped out ids and the
   *   values are the corresponding swapped in ids.
   * @param {Object} payload.outAsset - Asset that was swapped out
   * @param {String} payload.outAsset.id - The ID of the asset to swapped out
   * @param {String} payload.inAsset.name - The name of the asset that was swapped out
   * @param {Object} payload.inAsset - Asset that was swapped in
   * @param {String} payload.inAsset.id - The ID of the asset that was swapped in
   * @param {String} payload.inAsset.name - The name of the asset that was swapped in
   */
  private swapItems = ({ swaps }: any) => {
    const statistics = this.state.get(['statistics']);
    _.forEach(statistics, (stat, index) => {
      if (swaps[stat.id]) {
        this.state.set(['statistics', index, 'id'], swaps[stat.id]);
      }
    });
  };

  protected readonly handlers = {
    TREEMAP_SET_SWAP: this.setSwap,
    TREEMAP_SET_PARENT: this.setParent,
    TREEMAP_SET_BREADCRUMBS: this.setBreadcrumbs,
    TREEMAP_SET_TREE: this.setTree,
    TREEMAP_SET_HELP_KEY: this.setHelpKey,
    TREEMAP_SET_STATISTIC: this.setStatistic,
    TREEMAP_SET_COLORS: this.setColors,
    TREND_SWAP_ITEMS: this.swapItems,
  };

  /**
   * Dehydrates the item by retrieving the current set parameters in view.
   *
   * @returns {Record<string, any>} An object with the state properties as JSON
   */
  dehydrate(): Record<string, any> {
    return _.pick(this.state.serialize(), ['parent', 'statistics']);
  }

  /**
   * Rehydrates the store from dehydrated state.
   *
   * @param {Record<string, any>} dehydratedState - State object that should be restored
   */
  rehydrate(dehydratedState: Record<string, any>) {
    this.state.merge(dehydratedState);
  }
}
