// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { setActiveTabIndex, setSearchParams } from '@/administration/administration.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { APP_STATE } from '@/main/app.constants';
import { SUBSCRIPTIONS_TAB_INDEX } from '@/administration/administration.constants';

const jobDetailsModalBindings = bindingsDefinition({
  job: prop<any>(),
  onClose: prop<() => void>(),
  $state: injected<ng.ui.IStateService>(),
});

export const JobDetailsModal: SeeqComponent<typeof jobDetailsModalBindings> = ({ job, onClose }) => {
  const { $state } = useInjectedBindings(jobDetailsModalBindings);
  const { t } = useTranslation();

  const formatConfiguration = (config) =>
    _.chain(config)
      .merge({ documentId: job.documentId, topicId: job.topicId })
      .map((value, key) => ({ key, value }))
      .filter('value')
      .value();

  const formattedJob = {
    ...job,
    configuration: formatConfiguration(job?.configuration),
  };

  const formatValue = (key, value) => {
    const setTabAndFilter = () => {
      setActiveTabIndex(SUBSCRIPTIONS_TAB_INDEX);
      setSearchParams({ field: 'id', value });
    };

    switch (key) {
      case 'channelId':
      case 'reportChannelId':
        return (
          <a href="#" onClick={setTabAndFilter}>
            {value}
          </a>
        );
      case 'worksheetId':
        const params = {
          worksheetId: job?.configuration.worksheetId,
          workstepId: job?.configuration.workstepId,
        };
        return (
          <a href={$state.href(APP_STATE.WORKSHEET, params)} target="_blank">
            {value}
          </a>
        );
      default:
        return value;
    }
  };

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="jobDetailsModal">
      <Modal.Header closeButton={true}>
        <h3>{t('ADMIN.JOB.JOB_DETAILS')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="flexRowContainer">
          <h5 className="mt0">
            <span className="text-bold">{t('ADMIN.JOB.GROUP')}</span>: {job?.group}
          </h5>
          <h5 className="mt0">
            <span className="text-bold">{t('ADMIN.JOB.ID')}</span>: {job?.id}
          </h5>
          <h5 className="mt0">
            <span className="text-bold">{t('ADMIN.JOB.SOURCE')}</span>: {job?.sourceName}
          </h5>
        </div>
        <table className="table table-condensed table-striped">
          <thead>
            <tr>
              <th>{t('ADMIN.JOB.KEY')}</th>
              <th>{t('ADMIN.JOB.VALUE')}</th>
            </tr>
          </thead>
          <tbody>
            {_.map(formattedJob?.configuration, ({ key, value }) => (
              <tr key={key}>
                <td className="text-bold">{key}</td>
                <td>{formatValue(key, value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
};
