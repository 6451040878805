// @ts-strict-ignore
import React, { useState } from 'react';
import { SearchWidget } from '@/hybrid/search/SearchWidget.organism';

interface SearchForExistingConditionOrCalculatedSignalInterface {
  setSelectedItem: (input: { name: string; item: { name: string; id: string } }) => void;
}

const SEARCH_TYPES = ['Asset', 'Condition', 'CalculatedSignal', 'CalculatedScalar', 'CalculatedCondition'];

export const SearchForExistingConditionOrCalculatedSignal: React.FunctionComponent<
  SearchForExistingConditionOrCalculatedSignalInterface
> = ({ setSelectedItem }) => {
  const [selected, setSelected] = useState(undefined);

  return (
    <div className="flexRowContainer max-height-600 overflowHidden">
      <SearchWidget
        modalId="existingSearchModal"
        pane="modal"
        searchTypes={SEARCH_TYPES}
        allowAssetReplacement={false}
        onItemClick={(item) => {
          setSelectedItem(item);
          setSelected(item);
        }}
        indicateSelection={true}
        selectedItemId={selected?.id}
        restrictExploration={true}
      />
    </div>
  );
};
