import React, { useEffect, useRef, useState } from 'react';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { PluginHost } from '@/hybrid/plugin/PluginHost.atom';
import { generatePluginApi } from '@/hybrid/plugin/generatedPluginApi';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { WorksheetViewSelector } from '@/hybrid/trend/WorksheetViewSelector.molecule';
import { DisplayRangeSelector } from '@/hybrid/trend/DisplayRangeSelector.molecule';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { sqDurationStore, sqWorksheetStore } from '@/core/core.stores';
import { InvestigateRangeSelector } from '@/hybrid/trend/InvestigateRangeSelector.molecule';
import { BottomPanels } from '@/hybrid/trend/panels/bottomPanels/BottomPanels.organism';
import { useResizeWatcher } from '@/hybrid/core/hooks/useResizeWatcher.hook';
import { PluginConfig } from './pluginHost.constants';
import { setDisplayPaneRenderComplete } from '@/hybrid/plugin/plugin.actions';
import { isPresentationWorkbookMode } from '@/hybrid/utilities/utilities';
import { pluginWorkerApi } from '@/webWorkers/plugin.worker-api';

const displayPanePluginHostProps = bindingsDefinition({
  plugin: prop<PluginConfig>(),
  $injector: injected<ng.auto.IInjectorService>(),
});

/**
 * Displays the worksheet view selector and allows a plugin to control display of the display/investigate range
 * selectors and bottom panels.
 */
export const DisplayPanePluginHost: SeeqComponent<typeof displayPanePluginHostProps> = ({ plugin }) => {
  const { $injector } = useInjectedBindings(displayPanePluginHostProps);
  const isPresentationMode = isPresentationWorkbookMode();
  const showDisplayRangeSelector = !plugin?.host?.hideDisplayRangeSelector;
  const showInvestigateRangeSelector = !plugin?.host?.hideInvestigateRangeSelector;
  const showBottomPanels = !plugin?.host?.hideBottomPanels;
  const hideCapsulesPanel = !!plugin?.host?.hideCapsulesPanel;
  const { timezone } = useFlux(sqWorksheetStore);
  const { displayRange } = useFlux(sqDurationStore);
  const [parentHeight, setParentHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useResizeWatcher({
    elementRef: ref as React.MutableRefObject<HTMLElement>,
    callback: () => {
      setParentHeight(Number(ref?.current?.clientHeight));
    },
    callOnLoad: true,
  });

  useEffect(() => {
    setDisplayPaneRenderComplete(false);
    return () => setDisplayPaneRenderComplete(true);
  }, []);

  const createApi = (port: MessagePort) => generatePluginApi(plugin?.identifier, pluginWorkerApi(), $injector, port);

  return (
    <div ref={ref} className="flexFill flexRowContainer resizablePanelContainer">
      {isPresentationMode ? <div className="mb10" /> : null}
      <div className="flexFillOverflow flexRowContainer" style={{ position: 'relative' }}>
        <PluginHost entryPoint={plugin?.entryPoint} createApi={createApi} />
        {!isPresentationMode ? (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 999,
              padding: '6px 11px',
            }}>
            <WorksheetViewSelector extraClassNames="flexSelfStart" />
          </div>
        ) : null}
      </div>
      {!isPresentationMode && showDisplayRangeSelector ? (
        <DisplayRangeSelector
          rangeEditingEnabled={!isPresentationMode}
          displayRange={displayRange}
          timezone={timezone}
        />
      ) : null}
      {!isPresentationMode && showInvestigateRangeSelector ? (
        <InvestigateRangeSelector
          displayRangeEditingEnabled={!isPresentationMode}
          investigateRangeEditingEnabled={!isPresentationMode}
        />
      ) : null}
      {!isPresentationMode && showBottomPanels ? (
        <BottomPanels parentHeight={parentHeight} hideCapsulesPanel={hideCapsulesPanel} />
      ) : null}
    </div>
  );
};

export const sqDisplayPanePluginHost = angularComponent(displayPanePluginHostProps, DisplayPanePluginHost);
