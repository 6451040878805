// @ts-strict-ignore
import React, { Fragment, useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import moment from 'moment-timezone';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import _ from 'lodash';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { ColorPicker } from '@/hybrid/workbooks/ColorPicker.organism';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { CapsuleInput } from '@/hybrid/investigate/customCondition/CapsuleInput.molecule';
import { FormError } from '@/hybrid/core/FormError.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import {
  formatDuration as formatDateDuration,
  formatTime as formatDateTime,
  overlaps,
} from '@/hybrid/datetime/dateTime.utilities';
import { Capsule, CAPSULE_GROUP_DATE_FORMAT } from '@/hybrid/utilities/datetime.constants';
import { base64guid } from '@/hybrid/utilities/utilities';
import { sqDurationStore, sqScatterPlotStore, sqWorksheetStore } from '@/core/core.stores';
import { TREND_COLORS } from '@/trendData/trendData.constants';
import { ScatterPlotColorRange } from '@/scatterPlot/scatterPlot.constants';

const scatterPlotColorModalColorRangeBindings = bindingsDefinition({
  sqScatterPlotActions: injected<ScatterPlotActions>(),
});

export const ScatterPlotColorModalColorRange: SeeqComponent<typeof scatterPlotColorModalColorRangeBindings> = () => {
  const { sqScatterPlotActions } = useInjectedBindings(scatterPlotColorModalColorRangeBindings);

  const { colorRanges } = useFlux(sqScatterPlotStore);
  const { displayRange } = useFlux(sqDurationStore);
  const { timezone } = useFlux(sqWorksheetStore);

  const prepNewColorRange = (useNewEditingColorRanges = true) => {
    let numRanges;
    return _.chain(colorRanges)
      .concat(useNewEditingColorRanges ? [newColorRange, editingColorRange] : [])
      .reject(_.isUndefined)
      .thru((ranges) => {
        numRanges = ranges.length;
        return ranges;
      })
      .reverse()
      .head()
      .thru((colorRange) => {
        const startTime = colorRange ? moment.utc(colorRange.range.endTime) : displayRange.start;
        const endTime = displayRange.end.isAfter(startTime) ? displayRange.end : startTime.clone().add(1, 'minute');

        return {
          id: base64guid(),
          color: TREND_COLORS[numRanges % TREND_COLORS.length],
          range: { startTime: startTime.valueOf(), endTime: endTime.valueOf() },
        };
      })
      .value();
  };

  const [newColorRange, setNewColorRange] = useState(prepNewColorRange(false));
  const [editingColorRange, setEditingColorRange] = useState(undefined);

  const { t } = useTranslation();

  /**
   * Sets the color of the color range being edited. Called by the color picker.
   *
   * @param {string} itemId - Id of the range being added
   * @param {string} color - hex code for the new color
   */
  const setColorOfRange = (itemId, color) => {
    if (newColorRange?.id === itemId) {
      setNewColorRange({ ...newColorRange, color });
    }
  };

  /**
   * Adds the new color range to the list.
   */
  const addColorRange = () => {
    sqScatterPlotActions.addColorRange(newColorRange);
    prepAndSetNewColorRange();
  };

  /**
   * Sets the specified color range to be the one being edited.
   *
   * @param {ScatterPlotColorRange} colorRange - The one to edit
   */
  const editColorRange = (colorRange) => setEditingColorRange(_.cloneDeep(colorRange));

  /**
   * Determines if the specified color range is the one being edited.
   *
   * @param {ScatterPlotColorRange} colorRange - The one to check
   * @return {boolean} True if it is the one being edited, false otherwise
   */
  const isEditingColorRange = (colorRange) => editingColorRange?.id === colorRange.id;

  /**
   * Updates the color of the color range being edited. Called by the color picker.
   *
   * @param {string} itemId - Id of the range being edited
   * @param {string} color - hex code for the new color
   */
  const updateColorOfRange = (itemId, color) => {
    if (editingColorRange?.id === itemId) {
      setEditingColorRange({ ...editingColorRange, color });
    }
  };

  /**
   * Closes the edit form for a color range.
   */
  const closeColorRangeEdit = () => setEditingColorRange(undefined);

  /**
   * Commits the changes of the color range being edited.
   */
  const updateColorRange = () => {
    sqScatterPlotActions.updateColorRange(editingColorRange);
    closeColorRangeEdit();
    prepAndSetNewColorRange();
  };

  /**
   * Initializes a new color range. It finds the most recent color range and uses its end time as the new start so
   * that it's easy to create ranges that abut each other. If there are no ranges it defaults to the current display
   * range.
   */
  const prepAndSetNewColorRange = () => setNewColorRange(prepNewColorRange());

  /**
   * Determines if the specified color range overlaps an existing one. Note that it allows to the start and ends to
   * be equal to make it easy to have a contiguous set of date ranges. Otherwise the form would need to allow the
   * user to specify milliseconds.
   *
   * @param {ScatterPlotColorRange} colorRange - The color range to check.
   * @return {boolean} True if it overlaps one of the other ranges, false otherwise
   */
  const isOverlappingAnotherRange = (colorRange: ScatterPlotColorRange) => {
    return _.chain(colorRanges)
      .reject((otherColorRange) => colorRange.id === otherColorRange.id)
      .map('range')
      .some((otherRange) => overlaps(colorRange.range, otherRange))
      .value();
  };

  /**
   * Sync the view-model with the react date time component data
   *
   * @param capsule
   */
  const setColorRange = ({ startTime, endTime }: Capsule) =>
    setNewColorRange({
      ...newColorRange,
      range: {
        startTime,
        endTime,
      },
    });

  const setColorRangeEditing = ({ startTime, endTime }: Capsule) =>
    setEditingColorRange({
      ...editingColorRange,
      range: { startTime, endTime },
    });

  const removeColorRange = (range) => sqScatterPlotActions.removeColorRange(range.id);

  const formatDuration = (startTime, endTime) => formatDateDuration(endTime - startTime, true);
  const formatTime = (time) => formatDateTime(time, timezone, CAPSULE_GROUP_DATE_FORMAT);

  return (
    <div className="colorModalColorRanges">
      <Form>
        <Card className="sqGrayBox mtb10">
          <Card.Body>
            <div className="flexColumnContainer">
              <HoverTooltip text="COLOR_PICKER.PICKER" placement="top">
                <ColorPicker
                  color={newColorRange.color}
                  itemId={newColorRange.id}
                  placement="top"
                  notifyOnSelect={setColorOfRange}
                />
              </HoverTooltip>
              <CapsuleInput
                onChange={setColorRange}
                capsuleWindow={newColorRange.range}
                trackCategory="Scatterplot"
                trackAction="'Add Scatterplot Time Range'"
              />
            </div>
            {isOverlappingAnotherRange(newColorRange) && <FormError errorText="SCATTER.DATE_OVERLAP" />}
            <div className="text-center">
              <TextButton
                label="ADD"
                onClick={addColorRange}
                disabled={isOverlappingAnotherRange(newColorRange)}
                testId="addColorRangeButton"
                size="sm"
              />
            </div>
          </Card.Body>
        </Card>
      </Form>
      {colorRanges.length > 0 && (
        <div className="tableWrapper mt15 mb0 small">
          <table className="table table-striped table-condensed mb0">
            <thead>
              <tr>
                <th />
                <th />
                <th>{t('START')}</th>
                <th>{t('END')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {_.map(colorRanges, (colorRange) => {
                return (
                  <Fragment key={colorRange.id}>
                    <tr>
                      <td className="noWrap">
                        <Icon
                          icon="fa-edit"
                          tooltip="EDIT"
                          type="text"
                          onClick={() => editColorRange(colorRange)}
                          testId="editColorRangeButton"
                        />
                      </td>
                      <td>
                        <Icon icon="fa-circle" type="color" color={colorRange.color} testId="colorRangeCircle" />
                      </td>
                      <td>
                        <HoverTooltip
                          text={`${t('DURATION')} ${formatDuration(
                            colorRange.range.startTime,
                            colorRange.range.endTime,
                          )}`}>
                          <a>{formatTime(colorRange.range.startTime)}</a>
                        </HoverTooltip>
                      </td>
                      <td>
                        <HoverTooltip
                          text={`${t('DURATION')} ${formatDuration(
                            colorRange.range.startTime,
                            colorRange.range.endTime,
                          )}`}>
                          <a>{formatTime(colorRange.range.endTime)}</a>
                        </HoverTooltip>
                      </td>
                      <td className="nowrap">
                        <Icon
                          icon="fa-remove"
                          tooltip="REMOVE"
                          type="text"
                          onClick={() => removeColorRange(colorRange)}
                          testId="removeColorRangeButton"
                        />
                      </td>
                    </tr>
                    {isEditingColorRange(colorRange) && (
                      <tr>
                        <td colSpan={5}>
                          <Form>
                            <Card className="sqGrayBox mtb10">
                              <Card.Body>
                                <div className="flexColumnContainer">
                                  <HoverTooltip text="COLOR_PICKER.PICKER" placement="top">
                                    <ColorPicker
                                      color={editingColorRange.color}
                                      itemId={editingColorRange.id}
                                      placement="top"
                                      notifyOnSelect={updateColorOfRange}
                                    />
                                  </HoverTooltip>
                                  <CapsuleInput
                                    onChange={setColorRangeEditing}
                                    capsuleWindow={editingColorRange.range}
                                    trackCategory="Scatterplot"
                                    trackAction="'Edit Scatterplot Time Range'"
                                  />
                                </div>
                                {isOverlappingAnotherRange(editingColorRange) && (
                                  <FormError errorText="SCATTER.DATE_OVERLAP" />
                                )}
                                <div className="text-center pt5">
                                  <TextButton
                                    label="CANCEL"
                                    extraClassNames="mr5"
                                    onClick={closeColorRangeEdit}
                                    size="sm"
                                  />
                                  <TextButton
                                    label="SAVE"
                                    onClick={updateColorRange}
                                    disabled={isOverlappingAnotherRange(editingColorRange)}
                                    testId="saveColorRangeButton"
                                    size="sm"
                                  />
                                </div>
                              </Card.Body>
                            </Card>
                          </Form>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
