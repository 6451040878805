// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { sqAgentsApi } from '@/sdk';
import { CancelAndSave } from '@/hybrid/core/CancelAndSave.molecule';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';

interface UpdateVersionModalProps {
  onClose: () => void;
}

export const UpdateVersionModal: React.FunctionComponent<UpdateVersionModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const [runVersion, setRunVersion] = useState('');
  const isStaged = runVersion !== '';
  const regex = /R\d+\.\d+\.\d+(?:-v\d{12})?(?:-[A-Z]+)?/g;

  useEffect(() => {
    sqAgentsApi
      .configuredDirectives()
      .then(({ data }) => {
        setRunVersion(data.stageInstaller.match(regex)?.[0] ?? '');
      })
      .catch((error) => errorToast({ httpResponseOrError: error }));
  }, []);

  const updateAgent = ({ agentName, config }) => {
    sqAgentsApi
      .createOrUpdateAgent({ json: config }, { agentName })
      .then(() => {
        successToast({ messageKey: 'ADMIN.AGENTS.SUCCESSFULLY_UPDATED' });
        onClose();
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  };

  const updateToStageVersion = () => {
    const directiveInput = {
      runVersion,
    };
    return sqAgentsApi
      .updateDirective(directiveInput)
      .then(() => {
        successToast({ messageKey: 'ADMIN.AGENTS.SAVE_UPDATE_VERSION' });
        onClose();
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      animation={false}
      data-testid="updateVersionModal"
      className="modalSelectFormComponent">
      <Modal.Header closeButton={true} className="flexColumnContainer flexSpaceBetween">
        <h3>{t('ADMIN.AGENTS.UPDATE_VERSION_MODAL')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="flexColumnContainer p5" data-testid="labelRunVersion">
          {isStaged ? t('ADMIN.AGENTS.UPDATE_VERSION_DESC4') : t('ADMIN.AGENTS.UPDATE_VERSION_NOT_STAGED')} {runVersion}
        </div>
        <div className="flexColumnContainer p5" data-testid="labelUpdateVersion">
          {t('ADMIN.AGENTS.UPDATE_VERSION_DESC')}
        </div>
        <div className="flexColumnContainer p5" data-testid="labelUpdateVersion2">
          {t('ADMIN.AGENTS.UPDATE_VERSION_DESC2')}
        </div>
        <div className="flexColumnContainer p5" data-testid="labelUpdateVersion3">
          {t('ADMIN.AGENTS.UPDATE_VERSION_DESC3')}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CancelAndSave
          submitFn={updateToStageVersion}
          cancelFn={onClose}
          values={[]}
          btnDisabled={!isStaged}
          submitBtnLabel={t('ADMIN.AGENTS.UPDATE')}
          cancelBtnLabel={t('ADMIN.AGENTS.UPDATE_CANCEL')}
        />
      </Modal.Footer>
    </Modal>
  );
};
