import _ from 'lodash';

let visible = !document.hidden;
let resolve = _.noop;

const visibilityPromise = visible
  ? Promise.resolve()
  : new Promise((newResolve) => {
      resolve = newResolve;
    });

document.addEventListener('visibilitychange', handleVisibilityChange);

window.onunload = () => {
  document.removeEventListener('visibilitychange', handleVisibilityChange);
};

function handleVisibilityChange() {
  if (!visible && !document.hidden) {
    visible = true;
    resolve();
  }
}

export const waitForVisibility = () => {
  return visibilityPromise;
};
