// @ts-strict-ignore
import _ from 'lodash';
import angular from 'angular';
import HttpCodes from 'http-status-codes';
import workstepAnalyzerTemplate from '@/tools/workstepAnalyzer/workstepAnalyzer.html';
import administrationTemplate from '@/administration/administration.html';
import buildingTemplate from '@/hybrid/builder/building.html';
import footerTemplate from '@/footer/footer.html';
import headerTemplate from '@/header/header.html';
import licensePageTemplate from '@/licenseManagement/licensePage.html';
import logPageTemplate from './logPage.html';
import auditTrailPageTemplate from '@/auditTrail/auditTrailPage.html';
import workbenchExplorerTemplate from '@/workbenchExplorer/workbenchExplorer.html';
import worksheetTemplate from '@/worksheet/worksheet.html';
import worksheetsTemplate from '@/worksheets/worksheets.html';
import loadErrorTemplate from './loadError.html';
import homeScreenAddOnHostTemplate from '@/templates/homeScreenAddOnHostTemplate.html';
import loginTemplate from './login.html';
import mainTemplate from './main.html';
import unauthorizedTemplate from './unauthorized.html';
import reportTemplateTemplate from '@/templates/reportTemplate.html';
import { StateSynchronizerService } from '@/services/stateSynchronizer.service';
import reactComponentsModule from '@/hybrid/reactComponents.module';
import formBuilderModule from '@/hybrid/formbuilder/formBuilder.module';
import homeScreenModule from '@/hybrid/homescreen/homescreen.module';
import formulaToolModule from '@/hybrid/tools/formula/formulaTool.module';
import importDatafileModule from '@/hybrid/tools/importDatafile/importDatafile.module';
import tableBuilderModule from '@/hybrid/tableBuilder/tableBuilder.module';
import explorerModule from '@/hybrid/explorer/explorer.module';
import manualSignalModule from '@/hybrid/tools/manualSignal/manualSignal.module';
import assetGroupModule from '@/hybrid/assetGroupEditor/assetGroup.module';
import { getWorkbench } from '@/hybrid/workbench/workbench.utilities';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import {
  loadWorkbook,
  resetViewingState,
  setupNotifierForWorkbook,
  setViewers,
  setWorksheetProperty,
} from '@/workbook/workbook.actions';
import {
  sqAnnotationStore,
  sqHomeScreenStore,
  sqReportStore,
  sqWorkbenchStore,
  sqWorkbookStore,
} from '@/core/core.stores';
import { TrendActions } from '@/trendData/trend.actions';
import { DurationActions } from '@/trendData/duration.actions';
import { getWorkbook } from '@/hybrid/workbooks/workbook.utilities';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { ReportActions } from '@/reportEditor/report.actions';
import { createWorksheet, getWorksheet, getWorksheets } from '@/hybrid/worksheets/worksheets.utilities';
import { splitDuration } from '@/hybrid/datetime/dateTime.utilities';

import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { waitForVisibility } from '@/services/visibility.service';
import {
  ACL_MODAL_CHANGE_CHANNEL,
  AUTH_CHANGE_BROADCAST_CHANNEL,
  cleanupBroadcastChannels,
  subscribeToBroadcastChannel,
} from '@/services/broadcastChannel.utilities';
import { API_TYPES, APP_STATE, APPSERVER_API_PREFIX, FAVICON, JOURNAL_PREFIX_PATH } from '@/main/app.constants';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { checkLicenseStatus } from '@/licenseManagement/licenseManagement.actions';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { load as loadPlugins, setQueryParam } from '@/hybrid/plugin/plugin.actions';
import { SummaryTypeEnum } from '@/sdk/model/ContentInputV1';
import datasourcesModule from '@/hybrid/administration/datasources/datasources.module';
import agentsModule from '@/hybrid/administration/agents/agents.module';
import { sqItemsApi, sqTreesApi } from '@/sdk';
import { SearchResultUtilitiesService } from '@/hybrid/search/searchResult.utilities.service';
import { getCsrfToken, isCsrfSet } from '@/hybrid/utilities/auth.utilities';
import { logError, logInfo, logWarn } from '@/hybrid/utilities/logger';
import { open as openSocket, subscribe } from '@/hybrid/utilities/socket.utilities';
import { fetchHeadlessCaptureMetadata } from '@/services/headlessCapture.utilities';
import { isCanceled } from '@/hybrid/utilities/http.utilities';
import {
  getCurrentTabFromHash,
  getHomeScreenAddOnIdentifierFromHash,
  getReturnToParams,
  headlessRenderMode,
  returnToState,
  switchLanguage,
} from '@/hybrid/utilities/utilities';
import { formatMessage } from '@/hybrid/utilities/logger.utilities';
import { infoToast } from '@/hybrid/utilities/toast.utilities';
import { getReturnToLink, isAxiosError } from '@/hybrid/requests/axios.utilities';
import { cancelAll, hasVolatilePromises } from '@/hybrid/requests/pendingRequests.utilities';
import { WORKSHEET_WITH_NO_WORKSTEPS } from '@/hybrid/worksteps/worksteps.constant';
import { logout } from '@/hybrid/utilities/authentication.utilities';
import { AxiosError } from 'axios';
import { flux } from '@/core/flux.module';
import { PUSH_WORKSTEP_IMMEDIATE } from '@/core/flux.service';
import { executeLinkAction } from '@/hybrid/utilities/journalLink.utilities';
import {
  ALLOWED_SCREENSHOT_MODE_PATHS,
  fetchHeadlessCaptureMetadata as fetchHeadlessCaptureMetadataSqScreenshot,
  initializeHeadlessCaptureMode,
  notifyCapture,
  notifyError,
  notifyLoading,
  onStateExit,
} from '@/hybrid/utilities/screenshot.utilities';
import i18next from 'i18next';
import { AUTO_UPDATE, SummarySliderValues } from '@/trendData/trendData.constants';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import { doTrack } from '@/track/track.service';
import { FrontendDuration } from '@/services/systemConfiguration.constants';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { currentWorkstepAction, getWorkstepAction } from '@/worksteps/worksteps.actions';
import { addEventListeners, onSystemMessage } from '@/services/notifier.service';
import { setSystemMessage } from '@/hybrid/systemWarning/systemWarning.actions';
import { addOnToolsEnabled, fetchConfiguration, fetchEveryoneDisabled } from '@/services/systemConfiguration.utilities';
import { canReadAuditTrail, canViewLogs, canWriteItem } from '@/services/authorization.service';
import { setInjector } from '@/hybrid/utilities/conversion.utilities';
import { initializeRedactionService } from '@/hybrid/utilities/redaction.utilities';
import { setParent } from '@/treemap/treemap.actions';

export const dependencies = [
  reactComponentsModule.name,
  'Sq.Vendor',
  'Sq.Main',
  'Sq.Core',
  'Sq.Administration',
  'Sq.Investigate',
  'Sq.Search',
  'Sq.Workbench',
  'Sq.Header',
  formulaToolModule.name,
  homeScreenModule.name,
  formBuilderModule.name,
  explorerModule.name,
  assetGroupModule.name,
  manualSignalModule.name,
  importDatafileModule().name,
  'Sq.Worksheet',
  'Sq.Worksheets',
  'Sq.Workbook',
  'Sq.Worksteps',
  'Sq.AppConstants',
  tableBuilderModule.name,
  'Sq.LicenseManagement',
  'Sq.SystemWarning',
  'Sq.Directives.AutoFocus',
  'Sq.Directives.Onerror',
  'Sq.Directives.Onload',
  'Sq.Directives.ResizeNotify',
  'Sq.Directives.GreaterThan',
  'Sq.Directives.SelectOnFocus',
  'Sq.Report',
  'Sq.TrendData',
  'Sq.ScatterPlot',
  datasourcesModule.name,
  agentsModule.name,
];

let unsubscribeFromWorkbook = _.noop;
let unsubscribeFromWorkstepChannel = _.noop;
let unsubscribeFromReportUpdatesChannel = _.noop;
let stateBeingTransitionedTo = {} as any;

const app = angular.module('sqApp', dependencies);

app
  .controller('AppCtrl', function ($rootScope: ng.IRootScopeService, $state: ng.ui.IStateService) {
    const vm = this;
    vm.currentFavIcon = null;
    $rootScope.$watch(
      () => {
        return _.get($state, 'current.data.isAnalysis');
      },
      (isAnalysis) => {
        vm.isAnalysis = isAnalysis ?? false;
        vm.isReport = $state?.current?.data?.isReport ?? false;
        const favIConTopic = vm.isReport ? FAVICON.BLUE : FAVICON.WORKBENCH;
        const expectedFavIcon = vm.isAnalysis ? FAVICON.GREEN : favIConTopic;
        if (expectedFavIcon !== vm.currentFavIcon) {
          vm.currentFavIcon = expectedFavIcon;
          // ng-href adds an additional '/' at the end of the fav-icon link which prevents it from loading
          jQuery('#favicon').attr('href', vm.currentFavIcon);
        }
      },
    );

    $rootScope.$watch(
      () => {
        return _.get($state, 'current.data.title');
      },
      function (title) {
        vm.title = title || 'Seeq';
        if (vm.title !== 'Seeq') {
          vm.title += ' - Seeq';
        }
        if (process.env.NODE_ENV === 'development') {
          vm.title = `[DEV] ${vm.title}`;
        }
      },
    );
  })
  .config(function (
    $urlRouterProvider: ng.ui.IUrlRouterProvider,
    $urlMatcherFactoryProvider: ng.ui.IUrlMatcherFactory,
    $stateProvider: ng.ui.IStateProvider,
    $compileProvider: ng.ICompileProvider,
    $locationProvider: ng.ILocationProvider,
    $uibTooltipProvider: ng.ui.bootstrap.ITooltipProvider,
    $animateProvider,
    $provide: ng.auto.IProvideService,
  ) {
    // The new home screen opens Analyses and Topics in a new tab. Extend $state to make it easy to do so:
    // https://stackoverflow.com/questions/23516289/angularjs-state-open-link-in-new-tab
    $provide.decorator('$state', [
      '$delegate',
      '$window',
      ($delegate, $window) => {
        const extended = {
          goNewTab: (stateName, params, event) => {
            if (sqWorkbenchStore.preferNewTab || event?.ctrlKey || event?.metaKey) {
              $window.open($delegate.href(stateName, params, { absolute: true }), '_blank');
              return true;
            } else {
              $delegate.go(stateName, params);
              return false;
            }
          },
        };
        angular.extend($delegate, extended);
        return $delegate;
      },
    ]);

    $uibTooltipProvider.options({
      popupDelay: 300,
      appendToBody: true,
    });

    // NOTE: Any new routes must also be added as routes in server.js
    $urlRouterProvider.otherwise('/workbooks');
    $stateProvider
      .state('workbench', {
        abstract: true,
        template: mainTemplate,
        controller: 'MainCtrl as main',
        resolve: {
          // Note: workbenchState must be injected into other child states to guarantee it is resolved first
          workbenchState(
            sqStateSynchronizer: StateSynchronizerService,
            $q: ng.IQService,
            sqWorkbenchActions: WorkbenchActions,
          ) {
            // Initialize the redaction service before any items load so we can recognize if any items were forbidden
            initializeRedactionService();
            return waitForVisibility()
              .then(() =>
                $q.all([
                  // Fetch initial data
                  fetchConfiguration(),
                  fetchEveryoneDisabled(),
                  loadPlugins().catch(_.noop),
                  sqWorkbenchActions.setCurrentUser(),
                  checkLicenseStatus(stateBeingTransitionedTo.name),
                  // Miscellaneous setup
                  fetchHeadlessCaptureMetadataSqScreenshot(),
                  fetchHeadlessCaptureMetadata(),
                  openSocket(sqWorkbenchStore.interactiveSessionId, getCsrfToken()),
                ]),
              )
              .then(() => {
                sqStateSynchronizer.rehydrate(getWorkbench(), {
                  persistenceLevel: 'WORKBENCH',
                  initializeMode: 'SOFT',
                });
              })
              .then(() => {
                // Set language and force loading of translation files
                // This has to happen AFTER the workbench state is available
                return switchLanguage(sqWorkbenchStore.userLanguage);
              });
          },
        },
      })
      .state('workbench.common', {
        abstract: true,
        data: {
          title: '',
        },
        views: {
          header: {
            template: headerTemplate,
            controller: 'HeaderCtrl as ctrl',
          },
          workbooks: {
            template: workbenchExplorerTemplate,
          },
          worksheets: {
            template: worksheetsTemplate,
          },
          administration: {
            template: administrationTemplate,
          },
          license: {
            template: licensePageTemplate,
          },
          logs: {
            template: logPageTemplate,
          },
          auditTrail: {
            template: auditTrailPageTemplate,
          },
          homeScreenAddOn: {
            template: homeScreenAddOnHostTemplate,
          },
          footer: {
            template: footerTemplate,
          },
          reportTemplate: {
            template: reportTemplateTemplate,
          },
        },
      })
      .state(APP_STATE.LOG_TRACKER, {
        url: '/logs',
        resolve: {
          checkAccess(workbenchState, $q: ng.IQService) {
            return !canViewLogs() ? $q.reject(ROUTE_FORBIDDEN) : undefined;
          },
        },
      })
      .state(APP_STATE.REPORT_TEMPLATE, {
        url: '/report-template/:templateId',
      })
      // This is a workaround to handle data-lab links in a URL because of
      // https://stackoverflow.com/questions/28127661/how-to-get-angular-ui-router-to-respect-non-routed-urls/28137063#28137063
      // This state can be removed on upgrade to React (unless it suffers from the same bug)
      .state(APP_STATE.DATA_LAB, {
        url: '/data-lab/*ignored',
        resolve: {
          redirect($window: ng.IWindowService) {
            window.location.href = $window.location.href;
          },
        },
      })
      .state(APP_STATE.AUDIT_TRAIL, {
        url: '/auditTrail',
        resolve: {
          checkAccess(workbenchState, $q: ng.IQService) {
            return !canReadAuditTrail() ? $q.reject(ROUTE_FORBIDDEN) : undefined;
          },
        },
      })
      .state(APP_STATE.WORKBOOKS, {
        url: '/workbooks?t',
        resolve: {
          rehydratedState(
            workbenchState,
            $stateParams: ng.ui.IStateParamsService,
            $q: ng.IQService,
            sqHomeScreenActions: HomeScreenActions,
          ) {
            return $q.resolve().then(() => {
              sqHomeScreenActions.clearDisplayedAddOnIdentifier();
              return sqHomeScreenActions.loadFolder(null, $stateParams.t ? getCurrentTabFromHash($stateParams.t) : '');
            });
          },
        },
      })
      .state(APP_STATE.HOME_SCREEN_ADD_ON, {
        url: '/hsa?a&q',
        resolve: {
          rehydratedState(
            workbenchState,
            $stateParams: ng.ui.IStateParamsService,
            $q: ng.IQService,
            sqHomeScreenActions: HomeScreenActions,
            $state: ng.ui.IStateService,
          ) {
            return $q
              .resolve()
              .then(() => {
                const identifier = getHomeScreenAddOnIdentifierFromHash($stateParams.a);
                if (identifier) {
                  sqHomeScreenActions.setDisplayedAddOnIdentifier(identifier);
                  setQueryParam($stateParams.q);
                } else {
                  $state.go(APP_STATE.WORKBOOKS);
                }
              })
              .catch(() => {
                $state.go(APP_STATE.WORKBOOKS);
              });
          },
        },
      })
      .state(APP_STATE.FOLDER_EXPANDED, {
        // this is the state to display all the contents of a folder
        url: '^/:currentFolderId/folder/?t',
        resolve: {
          // TODO: CRAB-23748 (fix as part of React refactor) original comment: Cody Ray Hoeft this endpoint seems
          //  ambiguous. We should consider combine it with the /workbooks state
          rehydratedState(
            workbenchState,
            $stateParams: ng.ui.IStateParamsService,
            $q: ng.IQService,
            sqHomeScreenActions: HomeScreenActions,
          ) {
            return $q
              .resolve()
              .then(() =>
                sqHomeScreenActions
                  .loadFolder(
                    $stateParams.currentFolderId,
                    $stateParams.t ? getCurrentTabFromHash($stateParams.t) : sqHomeScreenStore.currentTab,
                  )
                  .catch((e) => checkForNotFoundOrForbidden(ROUTE_NO_WORKBOOK, ROUTE_FORBIDDEN, e)),
              );
          },
        },
      })
      .state(APP_STATE.FOLDER, {
        // this is the state that is used to preview the folder in the side panel
        url: '^/:currentFolderId/folder/:folderId',
        params: {
          currentFolderId: {
            value: null,
            squash: true,
          },
        },
        resolve: {
          rehydratedState(
            workbenchState,
            $stateParams: ng.ui.IStateParamsService,
            $q: ng.IQService,
            sqHomeScreenActions: HomeScreenActions,
          ) {
            // TODO: CRAB-23748 - revisit: Cody Ray Hoeft ui-router calls this endpoint for calls like 'GUID/folder/'
            //  because it ignores the empty url parameters. we should find some way to handle that ambiguity or
            //  simplify our states
            return sqHomeScreenActions
              .loadFolder($stateParams.currentFolderId, sqHomeScreenStore.currentTab)
              .catch((response) => checkForNotFoundOrForbidden(ROUTE_NO_WORKBOOK, ROUTE_FORBIDDEN, response));
          },
        },
      })
      .state(APP_STATE.PROJECT, {
        url: '^/:currentFolderId/project/:projectId?open',
        params: {
          currentFolderId: {
            value: null,
            squash: true,
          },
        },
        resolve: {
          rehydratedState(
            workbenchState,
            $q: ng.IQService,
            $state: ng.ui.IStateService,
            $location: ng.ILocationService,
            $stateParams: ng.ui.IStateParamsService,
            sqHomeScreenUtilities: HomeScreenUtilitiesService,
            sqHomeScreenActions: HomeScreenActions,
          ) {
            return sqHomeScreenActions
              .loadFolder($stateParams.currentFolderId, sqHomeScreenStore.currentTab)
              .then(() => {
                // this is for the case where we want to open a project on page load
                if ($stateParams.open === 'true') {
                  sqHomeScreenUtilities.openProject($stateParams.projectId);
                  // this serves to clear the open query parameter state, so that projects don't open when the user
                  // clicks to select them
                  $state.transitionTo(
                    APP_STATE.PROJECT,
                    {
                      projectId: $stateParams.projectId,
                      currentFolderId: $stateParams.currentFolderId,
                    },
                    { reload: true, inherit: false },
                  );
                }
              })
              .catch((response) => checkForNotFoundOrForbidden(ROUTE_NO_WORKBOOK, ROUTE_FORBIDDEN, response));
          },
        },
      })
      .state(APP_STATE.WORKSHEET, {
        url: '^/:currentFolderId/workbook/:workbookId/worksheet/:worksheetId?workstepId&displayRangeStart&displayRangeEnd&timezone&assetId',
        params: {
          currentFolderId: {
            value: null,
            squash: true,
          },
          archived: null,
        },
        views: {
          'worksheet@workbench': {
            template: worksheetTemplate,
          },
        },
        resolve: {
          rehydratedState(
            workbenchState,
            $injector: ng.auto.IInjectorService,
            $stateParams: ng.ui.IStateParamsService,
          ) {
            return loadWorksheet($injector, _.assign({ workbookDisplay: WORKBOOK_DISPLAY.EDIT }, $stateParams));
          },
        },
      })

      /**
       * This endpoint has no view because it just redirects to a more fully qualified url. If it fails then
       * ROUTE_NO_WORKBOOK will be returned and the user will end up at the /workbooks view
       */
      .state(APP_STATE.VIEW, {
        url: '/view/:viewId?workstepId&displayRangeStart&displayRangeEnd&timezone&dateRangeStart&dateRangeEnd&assetSelection',
        params: {
          dateRangeStart: { array: true },
          dateRangeEnd: { array: true },
          assetSelection: { array: true },
        },
        resolve: {
          redirect(
            workbenchState,
            $state: ng.ui.IStateService,
            $stateParams: ng.ui.IStateParamsService,
            $q: ng.IQService,
            $location: ng.ILocationService,
          ) {
            return sqItemsApi
              .getItemAndAllProperties({ id: $stateParams.viewId })
              .then(({ data: item }) => {
                if (item.type !== API_TYPES.WORKSHEET) {
                  return $q.reject(ROUTE_NO_WORKBOOK);
                }

                // Makes sure that there is an entry in the history for this state. Rejected state transitions don't
                // update the url, so manually setting it is necessary so that the correct url (/view/{id}) is
                // saved
                $location.url($state.href(APP_STATE.VIEW, $stateParams));
                return $q.reject({
                  redirect: {
                    to: APP_STATE.VIEW_WORKSHEET,
                    toParams: {
                      workbookId: item.workbookId,
                      worksheetId: item.id,
                      workstepId: $stateParams.workstepId,
                      displayRangeStart: $stateParams.displayRangeStart,
                      displayRangeEnd: $stateParams.displayRangeEnd,
                      timezone: $stateParams.timezone,
                      dateRangeStart: $stateParams.dateRangeStart,
                      dateRangeEnd: $stateParams.dateRangeEnd,
                      assetSelection: $stateParams.assetSelection,
                    },
                    options: {
                      location: false,
                    },
                  },
                });
              })
              .catch((e) => checkForNotFoundOrForbidden(ROUTE_NO_WORKBOOK, ROUTE_FORBIDDEN, e));
          },
        },
      })
      .state(APP_STATE.VIEW_WORKSHEET, {
        url: '^/:currentFolderId/view/worksheet/:workbookId/:worksheetId?workstepId&displayRangeStart&displayRangeEnd&timezone&assetId&dateRangeStart&dateRangeEnd&assetSelection',
        views: {
          'worksheet@workbench': {
            template: worksheetTemplate,
          },
        },
        params: {
          currentFolderId: {
            value: null,
            squash: true,
          },
          dateRangeStart: { array: true },
          dateRangeEnd: { array: true },
          assetSelection: { array: true },
        },
        resolve: {
          rehydratedState(
            workbenchState,
            $injector: ng.auto.IInjectorService,
            $stateParams: ng.ui.IStateParamsService,
          ) {
            return loadWorksheet($injector, _.assign({ workbookDisplay: WORKBOOK_DISPLAY.VIEW }, $stateParams)).then(
              () => infoToast({ messageKey: 'VIEWING_ONLY.TOOLTIP' }),
            );
          },
        },
      })
      .state(APP_STATE.PRESENT_WORKSHEET, {
        url: '/present/worksheet/:workbookId/:worksheetId?workstepId&displayRangeStart&displayRangeEnd&timezone&summaryType&summaryValue&assetId&requestIdPrefix&originURL&originLabel',
        views: {
          'worksheet@workbench': {
            template: worksheetTemplate,
          },
        },
        resolve: {
          rehydratedState(
            workbenchState,
            $injector: ng.auto.IInjectorService,
            $stateParams: ng.ui.IStateParamsService,
          ) {
            return loadWorksheet($injector, _.assign({ workbookDisplay: WORKBOOK_DISPLAY.PRESENT }, $stateParams));
          },
        },
      })
      .state(APP_STATE.ADMINISTRATION, {
        url: '/administration',
        resolve: {
          rehydratedState(workbenchState, $q: ng.IQService) {
            if (!sqWorkbenchStore.currentUser.isAdmin) {
              return $q.reject(ROUTE_FORBIDDEN);
            }
          },
        },
      })
      .state(APP_STATE.LICENSE, {
        url: '/license',
      })
      .state(APP_STATE.BUILDER, {
        url:
          '/workbook/builder?workbookName&worksheetName&trendItems&expandedAsset&assetSwap&viewMode&startFresh&' +
          'investigateStartTime&investigateEndTime&displayStartTime&displayEndTime&selectedTab&workbookFilter',
        data: { title: 'Loading...' },
        params: { trendItems: { array: true } },
        template: buildingTemplate,
        resolve: {
          initTools() {
            return Promise.resolve()
              .then(() => fetchConfiguration())
              .then(() => switchLanguage(sqWorkbenchStore.userLanguage));
          },
        },
      })
      .state(APP_STATE.LOAD_ERROR, {
        url: '/load-error?returnState&returnParams',
        data: { title: 'Error' },
        params: {
          header: 'LOAD_ERROR.SERVER_HEADER',
          message1: 'LOAD_ERROR.SERVER_MESSAGE1',
          message2: 'LOAD_ERROR.SERVER_MESSAGE2',
          showSpinner: true,
          retryInterval: 1000,
        },
        template: loadErrorTemplate,
      })
      .state(APP_STATE.LOGIN, {
        url: '/login?returnState&returnParams&directoryId&autoLogin&code&state&session_state&error&error_description',
        data: { title: 'Login' },
        template: loginTemplate,
      })
      .state(APP_STATE.UNAUTHORIZED, {
        url: '/unauthorized',
        template: unauthorizedTemplate,
        data: { title: 'Unauthorized' },
      })
      .state(APP_STATE.HEADLESS_CAPTURE_STANDBY, {
        url: '/headless-capture-standby',
        template: '<div></div>',
        data: { title: 'Blank' },
      });

    // Allow workstep analyzer to be used if not in the production environment
    if (process.env.NODE_ENV !== 'production') {
      $stateProvider.state('workstep-analyzer', {
        url: '/tools/workstep-analyzer',
        template: workstepAnalyzerTemplate,
        controller:
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          require('@/tools/workstepAnalyzer/workstepAnalyzer.controller').default,
        controllerAs: 'ctrl',
        data: { title: 'Workstep Analyzer' },
        resolve: {
          socketOpen() {
            return openSocket(sqWorkbenchStore.interactiveSessionId, getCsrfToken());
          },
        },
      });
    }
  })
  .factory('$exceptionHandler', ($injector: ng.auto.IInjectorService) => (error) => {
    logError(formatMessage`Unhandled exception: ${error}`);
  })
  .run(function (
    $rootScope: ng.IRootScopeService,
    $state: ng.ui.IStateService,
    $window: ng.IWindowService,
    $exceptionHandler: ng.IExceptionHandlerService,
    $location: ng.ILocationService,
    sqWorkbenchActions: WorkbenchActions,
    sqDurationActions: DurationActions,
    sqStateSynchronizer: StateSynchronizerService,
    sqTrendActions: TrendActions,
    sqAnnotationActions: AnnotationActions,
    sqHomeScreenActions: HomeScreenActions,
    sqHomeScreenUtilities: HomeScreenUtilitiesService,
    $injector: ng.auto.IInjectorService,
  ) {
    // all functions that should run once upon page load
    onSystemMessage(setSystemMessage);
    addEventListeners();
    setupNotifierForWorkbook($state, sqHomeScreenUtilities);
    sqAnnotationActions.setupNotifierForAnnotation();
    sqHomeScreenActions.setupNotifierForHomescreen();
    setInjector($injector);
    $rootScope.$on('$stateChangeStart', () => onStateExit($state));
    $rootScope.$on('$destroy', () => onStateExit($state));
    $rootScope.$on('onBeforeUnload', () => onStateExit($state));
    $rootScope.$on('$destroy', () => cleanupBroadcastChannels());

    $window.onerror = function (errorMsg, url, lineNumber, colNumber, errObject) {
      if (errObject && errObject.stack) {
        $exceptionHandler(errObject);
      } else {
        $exceptionHandler(new Error(`${errorMsg} in ${url}, line: ${lineNumber}, column: ${colNumber}`));
      }
    };

    $window.onbeforeunload = function () {
      $rootScope.$broadcast('onBeforeUnload');

      cancelRequests();
      // Force http calls to flush in IE, see http://stackoverflow.com/a/38251551/1108708
      // Only initiate a digest cycle if we aren't already in one
      if (!$rootScope.$$phase) {
        $rootScope.$digest();
      }
    };

    window.addEventListener('unhandledrejection', function (event) {
      logError(formatMessage`Unhandled exception: ${event.reason}`);
      event.preventDefault();
    });

    $rootScope.$on('$locationChangeStart', function (event, newUrl) {
      if (_.isEqual($location.path(), JOURNAL_PREFIX_PATH) && $state.current.name !== '') {
        executeLinkAction({
          sqStateSynchronizer,
          sqTrendActions,
          sqDurationActions,
          params: $location.search(),
        });
        event.preventDefault();
      }

      // Circumvent ui-router for links to /content/sourceUrl, since those will redirect to a new location
      if (newUrl.match(/\/api\/content\/.*\/sourceUrl/)) {
        event.preventDefault();
        window.location = newUrl;
      }
    });

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {
      // Needed because $state refers to the previous state during transition
      sqWorkbenchActions.setStateParams(toParams);
      // Needed in workbench common resolve because $state is empty during transition
      stateBeingTransitionedTo = toState;
      if (!!$state.transition || hasVolatilePromises()) {
        // If we are already transitioning, it is unsafe to start another transition without canceling or otherwise
        // letting the in progress transition finish normally. It isn't safe because loading most states requires
        // asynchronous requests that change global state (the stores), if two transitions are running at the same
        // time then that leads to race conditions concerning the final global state. The only way to effectively
        // cancel the transition is to cause the page to reload. This isn't ideal for the user experience, but it is
        // better than letting state from two worksheets mingle (CRAB-14011)
        // In addition, there are some promise chains in webserver that can continue to run asynchronously
        // even after we switch states, which can result in incorrect data in the toState and the fromState. We
        // reload the page if any of those volatilePromises in progress, which aborts them.
        const href = $state.href(toState.name, toParams);
        logWarn(`Preventing parallel transition to ${href}, reloading page instead`);
        event.preventDefault();
        $window.location.href = href;
        return;
      }

      if (_.includes([APP_STATE.WORKSHEET, APP_STATE.VIEW_WORKSHEET, APP_STATE.PRESENT_WORKSHEET], fromState.name)) {
        // Cancels pending requests if transitioning away from a worksheet.
        cancelAll();
        sqDurationActions.cleanup();
        // Can't know if they're changing to a worksheet in the same workbook and so always unsubscribe.
        unsubscribeFromWorkbook();
        unsubscribeFromWorkstepChannel();
        unsubscribeFromReportUpdatesChannel();
      }

      if (headlessRenderMode()) {
        const href = $state.href(toState.name, toParams) || '';
        if (href && !ALLOWED_SCREENSHOT_MODE_PATHS.test(href.replace(/^#!/, ''))) {
          event.preventDefault();
          notifyError(`Could not load path not allowed in screenshot render mode: ${href}`);
          return;
        }
      }

      if (toState.name === APP_STATE.LOGIN) {
        // if we have a return to link we don't want to end up in a redirect loop
        if (!getReturnToLink()) {
          if (isCsrfSet()) {
            // We are heading to the login page, but we might have a valid session. This can happen if users
            // bookmark the login page instead of the workbench page; redirect to the correct state instead. If we
            // aren't authenticated then we'll get a 401, clear the csrf, and be redirected back to the login page.
            event.preventDefault();
            returnToState($state, toParams).catch((error) => {
              logError(formatMessage`Error transitioning directly to previous state from login url: ${error}`);
            });
          }
        }
      }
    });

    const unregisterErrorHandler = $rootScope.$on(
      '$stateChangeError',
      function (event, toState, toParams, fromState, fromParams, error) {
        event.preventDefault();
        // Unsubscribe from subscription channel.
        unsubscribeFromWorkbook();
        unsubscribeFromWorkstepChannel();
        unsubscribeFromReportUpdatesChannel();
        if (error === ROUTE_NO_WORKBOOK) {
          $state.go(APP_STATE.WORKBOOKS);
        } else if (error === ROUTE_FORBIDDEN) {
          $state.go(APP_STATE.UNAUTHORIZED);
        } else if (error === ROUTE_NO_WORKSHEET) {
          // This handles the case, where a link to a particular worksheet has been saved/bookmarked but that
          // worksheet has been deleted (deleted as in DELETE in api, archived worksheets load).
          // This isn't the greatest behavior because the action the user should take isn't very clear and there is no
          // button to take the user back to the workbench. However, it is a little bit better than having a link
          // that doesn't work and redirects to the homescreen.
          $state.go(APP_STATE.LOAD_ERROR, {
            returnState: APP_STATE.WORKBOOKS,
            returnParams: JSON.stringify({}),
            header: 'LOAD_ERROR.NO_WORKSHEET_HEADER',
            message1: 'LOAD_ERROR.NO_WORKSHEET_MESSAGE1',
            message2: 'LOAD_ERROR.NO_WORKSHEET_MESSAGE2',
            retryInterval: 0,
          });
        } else if (_.get(error, 'status') === HttpCodes.UNAUTHORIZED) {
          notifyError(formatMessage`Invalid auth token detected: ${error}`);
          logout($state, toState, toParams, false);
        } else if (_.isObject(error) && _.isObject(error.redirect)) {
          $state.go(error.redirect.to, error.redirect.toParams, error.redirect.options);
          if (error.redirect.message) {
            infoToast({ messageKey: error.redirect.message });
          }
        } else {
          if (
            _.includes(
              [
                '/api/auth/providers', // The login page
                '/api/users/me', // other pages
              ],
              _.get(error, 'config.url'),
            ) &&
            _.includes([-1, HttpCodes.NOT_FOUND, HttpCodes.BAD_GATEWAY], _.get(error, 'status'))
          ) {
            // With the status code of the first api call, /api/users/me, we can infer info about webserver and
            // appserver
            logInfo(
              `Unable to connect to the Server. Most likely ${
                {
                  [-1]: 'webserver is offline',
                  [HttpCodes.NOT_FOUND]: 'appserver is online, but not responding to requests yet (might be upgrading)',
                  [HttpCodes.BAD_GATEWAY]: 'appserver is offline',
                }[error.status]
              }`,
            );
          } else {
            logError(formatMessage`Error transitioning to '${$state.href(toState, toParams)}': ${error}`);
          }

          // If the reason for the failed transition is because one of the requests was canceled then we can go
          // directly back to the state rather than flashing the error screen and waiting for it to redirect them.
          if (isCanceled(error)) {
            const href = $state.href(toState.name, toParams);
            logWarn(`Reloading ${href}, since it was canceled while loading`);
            $window.location.href = href;
          } else {
            $state.go(
              APP_STATE.LOAD_ERROR,
              {
                returnState: toState.name,
                returnParams: JSON.stringify(toParams),
              },
              {
                reload: true,
              },
            );
          }
        }
      },
    );

    // Prevent temporarily navigating to the error page if a problem occurs, such as from cancellation, while
    // unloading
    $rootScope.$on('onBeforeUnload', unregisterErrorHandler);

    // Initialize the screenshot callbacks that puppeteer can call
    initializeHeadlessCaptureMode(sqStateSynchronizer, $location, $rootScope);

    // Redirect to the logout page if auth changed by another tab or redirect from the login page if authenticated
    subscribeToBroadcastChannel({
      channelId: AUTH_CHANGE_BROADCAST_CHANNEL,
      onMessage() {
        // The csrf is used as a proxy for authentication here if the other tab set the csrf then it is likely
        // that we are authenticated. Similarly, if the csrf has been cleared we are unauthenticated.
        if (isCsrfSet() && $state.current.name === APP_STATE.LOGIN) {
          returnToState($state).catch((error) => {
            logError(formatMessage`Error transitioning to previous state: ${error}`);
          });
        } else if (!isCsrfSet() && $state.current.name !== APP_STATE.LOGIN) {
          $window.location.href = $state.href(APP_STATE.LOGIN, getReturnToParams($state.current, $state.params));
        }
      },
    });

    subscribeToBroadcastChannel({
      channelId: ACL_MODAL_CHANGE_CHANNEL,
      onMessage() {
        if ($state.current.name === APP_STATE.FOLDER_EXPANDED || $state.current.name === APP_STATE.WORKBOOKS) {
          const currentFolderId = sqHomeScreenStore.currentFolderId;
          $state.go(APP_STATE.FOLDER_EXPANDED, { currentFolderId }, { reload: true });
        }
      },
    });
  });

/**
 * Loads a worksheet using a specified set of parameters.
 *
 * @param  {Object} $injector - Angular DI service
 * @param  {Object} loadOptions - object container for the workbookId, worksheetId, and workbookDisplay
 * @param  {String} loadOptions.workbookId - ID of the workbook to load
 * @param  {String} loadOptions.worksheetId - ID of the worksheet to load
 * @param  {String} [loadOptions.currentFolderId] - ID of the current folder
 * @param  {String} loadOptions.workbookDisplay - one of WORKBOOK_DISPLAY
 * @param  {String} [loadOptions.workstepId] - ID of the workstep to load (defaults to current workstep for
 *   worksheet)
 * @param  {String} [loadOptions.displayRangeStart] - ISO-8601 date/time to use for the display range
 * @param  {String} [loadOptions.displayRangeEnd] - ISO-8601 date/time to use for the display range
 * @return {Promise} - Resolves when the worksheet has been loaded and it's state has been rehydrated
 */
function loadWorksheet($injector: ng.auto.IInjectorService, loadOptions) {
  loadOptions = _.omitBy(loadOptions, _.isNil); // Remove any optional undefined query parameters
  return $injector.invoke(function (
    $q: ng.IQService,
    $state: ng.ui.IStateService,
    $timeout: ng.ITimeoutService,
    sqStateSynchronizer: StateSynchronizerService,
    sqDurationActions: DurationActions,
    sqAnnotationActions: AnnotationActions,
    sqReportActions: ReportActions,
    sqHomeScreenUtilities: HomeScreenUtilitiesService,
    sqInvestigateActions: InvestigateActions,
    sqReportContent: ReportContentService,
  ) {
    const workbookLoadNeeded =
      // Reload because workbook is changed
      loadOptions.workbookId !== _.get($state.params, 'workbookId') ||
      // also reload if last state is not APP_STATE.WORKSHEET (e.g. coming from home screen)
      $state.current.name !== APP_STATE.WORKSHEET;

    return (
      $q
        .resolve()
        .then(() => {
          return workbookLoadNeeded
            ? loadWorkbook(sqHomeScreenUtilities, loadOptions.workbookId, loadOptions.workbookDisplay).catch((e) =>
                checkForNotFoundOrForbidden(ROUTE_NO_WORKBOOK, ROUTE_FORBIDDEN, e),
              )
            : // $timeout is used here to avoid that the whole block here is executed in one dispatch cycle.
              // see
              // https://bitbucket.org/seeq12/crab/pull-requests/9365/crab-19353-suppress-workbook-load-when#comment-145759312
              // for a detailed explanation why
              $timeout(() => sqWorkbookStore.workbook, 0);
        })
        .then((workbook) => {
          if (
            loadOptions.workbookDisplay === WORKBOOK_DISPLAY.EDIT &&
            (!canWriteItem(workbook) || workbook.isArchived)
          ) {
            // This is the only case where the write permission in the response needs to be checked manually so we
            // can redirect to the view-only worksheet view. All other cases will be handled by the
            // $stateChangeError ROUTE_FORBIDDEN error handler which redirects to the unauthorized route.
            return $q.reject({
              redirect: {
                to: APP_STATE.VIEW_WORKSHEET,
                toParams: {
                  workbookId: loadOptions.workbookId,
                  worksheetId: loadOptions.worksheetId,
                  workstepId: loadOptions.workstepId,
                  currentFolderId: loadOptions.currentFolderId || '',
                },
                // location: 'replace' ensures the browser history gets overwritten and prevents CRAB-15738
                options: { location: 'replace' },
                message: 'REDIRECT_WORKSHEET',
              },
            });
          }

          if (!headlessRenderMode()) {
            sqHomeScreenUtilities.updateOpenedAt(loadOptions.workbookId);
          }
          return workbook;
        })
        .then(() => {
          // If coming from a worksheet then flush any state changes that have not yet been pushed because they
          // were debounced. Note that this check is possible because $state.params points to PREVIOUS params
          if ($state.current.name === APP_STATE.WORKSHEET) {
            return sqStateSynchronizer.push(
              PUSH_WORKSTEP_IMMEDIATE,
              _.pick($state.params, ['workbookId', 'worksheetId']),
            );
          }
        })
        // If a workbook somehow ends up with no worksheets (and thus no worksheet ID), add a worksheet to avoid an
        // error state.
        .then(() => {
          if (!loadOptions.worksheetId) {
            return getWorksheets(loadOptions.workbookId)
              .then((worksheets) => _.head(worksheets) || createWorksheet(loadOptions.workbookId, '1'))
              .then((worksheet) =>
                $q.reject({
                  redirect: {
                    to:
                      loadOptions.workbookDisplay === WORKBOOK_DISPLAY.EDIT
                        ? APP_STATE.WORKSHEET
                        : APP_STATE.VIEW_WORKSHEET,
                    toParams: {
                      workbookId: loadOptions.workbookId,
                      worksheetId: worksheet.worksheetId,
                      currentFolderId: loadOptions.currentFolderId || '',
                    },
                    // location: 'replace' ensures the browser history gets overwritten and prevents CRAB-15738
                    options: { location: 'replace' },
                  },
                }),
              );
          }
        })
        // The location of this line is important, because it means that all flux.dispatch calls for the old
        // worksheet must have completed, including any that are done as part of $onDestroy or timeouts which do
        // not happen until the sqWorkbookActions.load() finishes. It must be before rehydration starts, however, to
        // ensure that all subsequent flux.dispatch calls will apply to the new worksheet.
        .then(() => sqStateSynchronizer.setLoadingWorksheet(loadOptions.workbookId, loadOptions.worksheetId))
        .then(() => getWorkbook(loadOptions.workbookId))
        .then((workbookState) =>
          sqStateSynchronizer.rehydrate(workbookState, {
            persistenceLevel: 'WORKBOOK',
            initializeMode: 'FORCE',
            workbookId: loadOptions.workbookId,
            worksheetId: loadOptions.worksheetId,
          }),
        )
        .then(() => loadWorkstep($injector, loadOptions))
        .then(() => {
          const loadAddOnTools =
            addOnToolsEnabled() &&
            workbookLoadNeeded &&
            !sqWorkbookStore.isReportBinder &&
            loadOptions.workbookDisplay !== WORKBOOK_DISPLAY.PRESENT
              ? () => sqInvestigateActions.loadAddOnTools()
              : () => $q.resolve();
          return $q.all([
            sqAnnotationActions.fetchAnnotations(loadOptions.workbookId, loadOptions.worksheetId),
            // Load add-on tools after we have rehydrated the workstep and in parallel with annotations to save time
            loadAddOnTools(),
          ]);
        })
        .then(() => {
          if (!headlessRenderMode()) {
            // Subscribe to this workbook's subscription channel to get updates about who is viewing this workbook.
            // This is a low priority subscription, so we should not wait for it to complete nor should we fail if
            // it doesn't succeed.
            const unsubscribeMethod = subscribe({
              channelId: [SeeqNames.Channels.WorkbookChannel, loadOptions.workbookId, 'subscriptions'],
              onMessage: setViewers,
              subscriberParameters: {
                worksheetId: loadOptions.worksheetId,
                worksheetDisplay: loadOptions.workbookDisplay,
              },
            });

            unsubscribeFromWorkbook = () => {
              unsubscribeMethod();
              // The workbook store had an out-of-date list of viewers when transitioning workbooks. This clears the
              // viewers so that the user doesn't see off-by-one user counts on the worksheet they're coming from.
              flux.dispatch('WORKBOOK_SET_VIEWERS', []);
            };

            // Subscribe to this worksheet's workstep channel to follow worksteps.
            unsubscribeFromWorkstepChannel = subscribe({
              channelId: [SeeqNames.Channels.WorkstepChannel, loadOptions.worksheetId],
              onMessage: sqStateSynchronizer.onWorkstep,
            });

            // Subscribe to this reports update channel to follow report updates.
            if (sqWorkbookStore.isReportBinder) {
              unsubscribeFromReportUpdatesChannel = subscribe({
                channelId: [SeeqNames.Channels.ReportUpdateChannel, loadOptions.worksheetId],
                onMessage: sqReportActions.debouncedOnReport,
                onClose: (event) => sqReportActions.setIsOffline(true),
                onSubscribe: () => sqReportActions.setIsOffline(false),
              });
            }
          }

          if (sqWorkbookStore.isReportBinder) {
            // Try to get the worksheet from the workbook store. It should be there unless it has been trashed.
            const worksheet = _.find(sqWorkbookStore.worksheets, ['worksheetId', loadOptions.worksheetId]);

            // The worksheet can be undefined if a user accesses the topic document using a shared URL after the
            // document has been trashed from the topic. Normally we can avoid an extra call to the backend to get
            // the worksheet but in the case where is has been trashed we need to make it because we want the
            // trashed document to still be viewable via the URL.
            return Promise.resolve(
              _.isNil(worksheet) ? getWorksheet(loadOptions.workbookId, loadOptions.worksheetId, true) : worksheet,
            ).then(({ reportId }) =>
              (sqReportActions.load(reportId) as any).then(() =>
                setWorksheetProperty(loadOptions.worksheetId, 'reportId', reportId),
              ),
            );
          } else {
            if (
              !headlessRenderMode() &&
              loadOptions.workbookDisplay === WORKBOOK_DISPLAY.EDIT &&
              !sqAnnotationStore.findJournalEntries(sqAnnotationStore.annotations, loadOptions.worksheetId).length
            ) {
              return sqAnnotationActions.save({
                workbookId: loadOptions.workbookId,
                worksheetId: loadOptions.worksheetId,
                name: i18next.t('UNNAMED'),
              });
            }
          }
        })
        .then(() => sqAnnotationActions.displayNewOrExisting(loadOptions.worksheetId))
        .then(() => {
          const displayMode = _.last(loadOptions.workbookDisplay.split('.'));
          if (sqWorkbookStore.isReportBinder) {
            const liveDoc = sqReportStore.hasLiveContent;
            doTrack(
              'Topic',
              'Opened',
              `mode=${displayMode}, liveDoc=${liveDoc}, workbookId=${loadOptions.workbookId}, worksheetId=${loadOptions.worksheetId}`,
            );
          } else {
            doTrack(
              'Analysis',
              'Opened',
              `mode=${displayMode}, workbookId=${loadOptions.workbookId}, worksheetId=${loadOptions.worksheetId}`,
            );
          }
        })
        .then(notifyLoading)
        .then(sqDurationActions.autoUpdate.initialize)
        .then(() =>
          notifyCapture({
            sqReportContent,
            $$testability: $injector.get<any>('$$testability'),
          }),
        )
        .catch((error) => {
          notifyError(formatMessage`Could not load worksheet: ${error}`);
          return Promise.reject(error);
        })
        .finally(() => {
          sqStateSynchronizer.unsetLoadingWorksheet();
        })
    );
  });
}

/**
 * Loads the  workstep for a worksheet.
 *
 * @param  {Object} $injector - Angular DI service
 * @param  {Object} loadOptions - object container for parameters
 * @param  {String} loadOptions.workbookId - ID of the workbook to load
 * @param  {String} loadOptions.worksheetId - ID of the worksheet to load
 * @param  {String} loadOptions.workbookDisplay - one of WORKBOOK_DISPLAY
 * @param  {String} [loadOptions.workstepId] - ID of the workstep to load otherwise the current workstep will be
 *   used
 * @param  {String} [loadOptions.displayRangeStart] - ISO-8601 date/time to use for the display range
 * @param  {String} [loadOptions.displayRangeEnd] - ISO-8601 date/time to use for the display range
 * @param  {String} [loadOptions.summaryType] - Fixed or Auto
 * @param  {String} [loadOptions.summaryValue] - Either a FrontendDuration-esque string, or an arbitrary value
 * @param  {String} [loadOptions.assetId] - ID for the asset to select on
 * @return {Promise} - Resolves when the workstep has been loaded and its state has been rehydrated
 */
function loadWorkstep($injector: ng.auto.IInjectorService, loadOptions) {
  return $injector.invoke(function (
    $q: ng.IQService,
    $state: ng.ui.IStateService,
    sqStateSynchronizer: StateSynchronizerService,
    sqSearchResultService: SearchResultUtilitiesService,
    sqTrendActions: TrendActions,
    sqDurationActions: DurationActions,
    sqWorksheetActions: WorksheetActions,
  ) {
    // Builder takes care of loading the workstep and in view mode the workstep is ephemeral
    if ($state.current.name === APP_STATE.BUILDER) {
      return $q.resolve();
    }
    return $q
      .resolve()
      .then(() => {
        if (loadOptions.workstepId) {
          return getWorkstepAction(loadOptions.workbookId, loadOptions.worksheetId, loadOptions.workstepId);
        } else {
          return getWorkstepAction(
            loadOptions.workbookId,
            loadOptions.worksheetId,
            sqWorkbookStore.getWorksheetCurrentWorkstepId(loadOptions.worksheetId),
          );
        }
      })
      .catch((e) => checkForNotFoundOrForbidden(ROUTE_NO_WORKSHEET, ROUTE_FORBIDDEN, e))
      .then((response) => _.get(response, 'current.state'))
      .catch((e) => {
        if (e !== WORKSHEET_WITH_NO_WORKSTEPS) {
          return $q.reject(e);
        }

        // If a worksheet does not have any worksteps then we create an initial empty one. This allows the
        // user to hit the previous button and eventually return to a blank slate rather than their first
        // action.
        sqStateSynchronizer.initialize('WORKSHEET');
        return sqStateSynchronizer
          .push(PUSH_WORKSTEP_IMMEDIATE, _.pick(loadOptions, ['workbookId', 'worksheetId']))
          .then(() => currentWorkstepAction(loadOptions.workbookId, loadOptions.worksheetId))
          .then((response) => _.get(response, 'current.state'));
        // Note that we don't call rehydrate again because the state is already accurate because of the call to
        // initialize
      })
      .then((workstepState) => {
        // The worksheet will show a spinner until the promise returned resolved. Don't return the promise here so
        // that all the data on the chart will instead load asynchronously. The stores' state will be restored
        // synchronously, but data will be fetched from the server in the background
        sqStateSynchronizer
          .rehydrate(workstepState, {
            beforeFetch,
            workbookId: loadOptions.workbookId,
            worksheetId: loadOptions.worksheetId,
          })
          .catch((error) => {
            logError(formatMessage`Error while rehydrating workstep: ${error}`);
          });

        function beforeFetch() {
          return $q.resolve().then(() => {
            const promises = [];
            const displayStart = _.get(loadOptions, 'displayRangeStart');
            const displayEnd = _.get(loadOptions, 'displayRangeEnd');
            const timezone = _.get(loadOptions, 'timezone');
            const summaryType = _.get(loadOptions, 'summaryType');
            const summaryValue = _.get(loadOptions, 'summaryValue');
            const assetId = _.get(loadOptions, 'assetId');

            if (displayStart && displayEnd) {
              sqDurationActions.displayRange.setParamsInStore(displayStart, displayEnd);
              // Override investigate range as well to avoid it being completely disconnected from the display
              // range
              sqDurationActions.investigateRange.copyFromDisplayRange();
              // Override auto-update to ensure it doesn't override the hard-coded date range given in the URL
              sqDurationActions.autoUpdate.setMode(AUTO_UPDATE.MODES.OFF);
            }

            if (timezone) {
              sqWorksheetActions.setTimezone({ name: timezone });
            }

            if (summaryType && summaryValue) {
              const discreteUnits = splitDuration(summaryValue);
              const value = discreteUnits
                ? _.findKey(SummarySliderValues[SummaryTypeEnum.DISCRETE].value, discreteUnits)
                : summaryValue;
              const discrete: FrontendDuration = discreteUnits ?? {
                value: 0,
                units: 'min',
              };
              sqTrendActions.setSummary(
                {
                  type: summaryType,
                  value,
                  discreteUnits: discrete,
                  isSlider: true,
                },
                false,
              );
            }

            if (assetId) {
              promises.push(
                sqTreesApi
                  .getTree({ id: assetId })
                  // Make sure we set the correct parent for treemap
                  .then(({ data: tree }) => setParent(_.last(tree.item?.ancestors)).then(() => tree))
                  .then((tree) => sqSearchResultService.swapAsset(tree.item)),
              );
            }

            if (loadOptions.workbookDisplay !== WORKBOOK_DISPLAY.EDIT) {
              resetViewingState($injector);
            }

            return Promise.all(promises);
          });
        }
      });
  });
}

/**
 * Helper function that checks if a request returned a NOT_FOUND, BAD_REQUEST, or FORBIDDEN and, if so, returns the
 * the specified route constant which allows the stateChangeError handler to respond correctly. BAD_REQUEST is
 * checked because the backend will return that status code if the guid in the URL is missing or malformed.
 *
 * @param routeConstantNotFound - Constant to return if response status is NOT_FOUND or BAD_REQUEST
 * @param routeConstantForbidden - Constant to return if response status is FORBIDDEN
 * @param error - The error response
 * @returns Rejects with either the routeConstant or the original error
 */
function checkForNotFoundOrForbidden(
  routeConstantNotFound: string,
  routeConstantForbidden: string,
  error: unknown,
): Promise<string | AxiosError> {
  const isForbidden = isAxiosError(error) && error.response.status === HttpCodes.FORBIDDEN;
  const isNotFoundOrBadRequest =
    isAxiosError(error) && _.includes([HttpCodes.NOT_FOUND, HttpCodes.BAD_REQUEST], error.response.status);

  if (isForbidden) {
    return Promise.reject(routeConstantForbidden);
  } else if (isNotFoundOrBadRequest) {
    return Promise.reject(routeConstantNotFound);
  } else {
    return Promise.reject(error);
  }
}

/**
 * Helper function that cancels all the requests for a user's specific session
 *
 * @param {Object} sqWorkbenchStore - the workbench store
 */
function cancelRequests() {
  const headers = new Headers({
    [SeeqNames.API.Headers.Csrf]: getCsrfToken(),
    Accept: 'application/vnd.seeq.v1+json',
  });

  const sessionId = sqWorkbenchStore.interactiveSessionId;
  // Fetch is being used here, with the keepalive flag set to true, since several browsers no longer support XHR
  // during the onbeforeunload event
  fetch(`${APPSERVER_API_PREFIX}/requests/me/${sessionId}`, {
    method: 'DELETE',
    keepalive: true,
    headers,
  });
}

export const ROUTE_NO_WORKBOOK = 'ROUTE_NO_WORKBOOK';
export const ROUTE_NO_WORKSHEET = 'ROUTE_NO_WORKSHEET';
export const ROUTE_FORBIDDEN = 'ROUTE_FORBIDDEN';
