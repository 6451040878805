// @ts-strict-ignore
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { TrendActions } from '@/trendData/trend.actions';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { SortIcon } from '@/hybrid/core/SortIcon.atom';
import { TableFilterPopover } from '@/hybrid/core/tableUtilities/TableFilterPopover.organism';
import { TableFilterIcon } from '@/hybrid/core/tableUtilities/TableFilterIcon.organism';
import { useTranslation } from 'react-i18next';
import { TableColumnMenu } from '@/hybrid/core/tableUtilities/TableColumnMenu.organism';
import { ButtonWithPopover } from '@/hybrid/core/ButtonWithPopover.molecule';
import { Icon } from '@/hybrid/core/Icon.atom';
import { isPresentationWorkbookMode, isStringSeries } from '@/hybrid/utilities/utilities';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { Placement } from 'react-bootstrap/Overlay';
import { FormulaService } from '@/services/formula.service';
import { CAPSULE_PANEL_LOOKUP_COLUMNS, TREND_PANELS, TREND_SIGNAL_STATS } from '@/trendData/trendData.constants';
import { sqTrendStore } from '@/core/core.stores';
import { CONDITION_EXTRA_COLUMNS } from '@/hybrid/tableBuilder/tableBuilder.constants';

const capsulesPanelHeaderBindings = bindingsDefinition({
  sqTrendActions: injected<TrendActions>(),
  sqFormula: injected<FormulaService>(),
  column: prop<any>(),
  handleToggleColumn: prop<(column) => void>(),
  isStatisticsColumnEnabled: prop<(column, series) => boolean>(),
  isColumnRedacted: prop<boolean>(),
  distinctStringValues: prop.optional<string[]>(),
  fetchStringValues: prop<(columnKey) => void>(),
});

export enum CapsulesPanelHeaderMenuActions {
  Remove = 'remove',
  Filter = 'filter',
}

/**
 * Capsule panel header component. For each column to have it's own header component rendered with additional and
 * customizable functionality ex: sorting filtering, remove column.
 */
export const CapsulesPanelHeader: SeeqComponent<typeof capsulesPanelHeaderBindings> = ({
  column,
  handleToggleColumn,
  isColumnRedacted,
  isStatisticsColumnEnabled,
  distinctStringValues,
  fetchStringValues,
}) => {
  const { sqTrendActions, sqFormula } = useInjectedBindings(capsulesPanelHeaderBindings);

  const { t } = useTranslation();
  const [showColumnFilterPopover, setShowColumnFilterPopover] = useState(false);
  const sort = sqTrendStore.getPanelSort(TREND_PANELS.CAPSULES);
  const ref = useRef<HTMLTableDataCellElement>(null);
  const columnKey = sqFormula.getColumnNameForAnyColumn(column);
  const canSort = !_.includes(_.map(CAPSULE_PANEL_LOOKUP_COLUMNS, 'key'), columnKey);
  const canShowFilter = !_.includes(
    [...CONDITION_EXTRA_COLUMNS, ..._.map(CAPSULE_PANEL_LOOKUP_COLUMNS, 'key')],
    columnKey,
  );

  const toggleShowFilterPopover = () => {
    if (canShowFilter) {
      setShowColumnFilterPopover(!showColumnFilterPopover);
    }
  };

  const filterAction = {
    iconClass: 'fa-filter',
    translationKey: 'FILTER.FILTER',
    actionKey: CapsulesPanelHeaderMenuActions.Filter,
    action: () => {
      toggleShowFilterPopover();
      fetchStringValues(columnKey);
    },
    disabled: false,
    tooltip: 'CAPSULES_PANEL.ONLY_ON_TABLE_WITH_ITEMS_SAME_TYPE',
  };
  const removeColumnAction = {
    iconClass: 'fa-times',
    translationKey: 'REMOVE_COLUMN',
    actionKey: CapsulesPanelHeaderMenuActions.Remove,
    action: () => handleToggleColumn(column),
  };
  const columnActions = canShowFilter ? [filterAction, removeColumnAction] : [removeColumnAction];

  const toggleSort = (sortBy: string) => sqTrendActions.togglePanelSort(TREND_PANELS.CAPSULES, sortBy);

  const renderSortableAndFilterableColumn = (column) => {
    const shortTitle = t(column.shortTitle);

    const filterRenderProps = {
      columnKey,
      columnFilter: sqTrendStore.getColumnFilter(columnKey),
      distinctStringValues,
      isStringColumn: sqTrendStore.isStringColumn(columnKey),
      placement: 'top' as Placement,
      isDurationColumn: column?.propertyName === SeeqNames.Properties.Duration,
    };

    return (
      column.type !== 'series' &&
      !isColumnRedacted && (
        <div>
          <div className="flexColumnContainer" data-testid={shortTitle}>
            <div
              onClick={() => {
                column.type !== 'series' && canSort && toggleSort(column.key);
              }}>
              <span
                style={{ ...column.series?.style, color: column.series?.color }}
                className={classNames({
                  'sq-text-info': sort.sortBy === column.key,
                })}>
                {shortTitle}
              </span>
              {canSort && <SortIcon sortProperty={column.key} sortBy={sort.sortBy} sortAsc={sort.sortAsc} />}
            </div>
            <TableFilterIcon
              {...filterRenderProps}
              target={ref}
              setColumnFilter={sqTrendActions.setColumnFilter}
              iconType={null}
              isFilterDisabled={!canShowFilter}
              onIconClick={() => fetchStringValues(columnKey)}
            />
            <TableColumnMenu columnActions={columnActions} columnKey={columnKey} />
          </div>
          <TableFilterPopover
            {...filterRenderProps}
            notifyOnClose={() => setShowColumnFilterPopover(false)}
            setColumnFilter={(columnKey, filter) => sqTrendActions.setColumnFilter(columnKey, filter)}
            target={ref?.current}
            show={showColumnFilterPopover}
          />
        </div>
      )
    );
  };

  const renderSortOptions = (column) => {
    const dropdownIconColor = column.series?.color;

    return (
      column.type === 'series' && (
        <div>
          {!isPresentationWorkbookMode() && (
            <ButtonWithPopover
              label={
                <Icon
                  icon="fc-add-column"
                  type={dropdownIconColor ? 'color' : undefined}
                  color={dropdownIconColor}
                  tooltip={isColumnRedacted ? 'CAPSULES_PANEL.REDACTED_SIGNAL' : column.title}
                  tooltipPlacement="top"
                  testId={column.title}
                  extraClassNames="dropdown-toggle"
                />
              }
              disabled={isColumnRedacted}
              alwaysShowTooltip={true}
              popoverConfig={{
                id: columnKey,
                placement: 'bottom-end',
              }}
              closeOnClick={false}
              closeWhenMoved={false}
              extraClassNames="statsBtn btn btn-stat pt5 pr5 pb1 pl5">
              <div className="flexRowContainer">
                {_.map(TREND_SIGNAL_STATS, (extraColumn) => (
                  <div
                    key={extraColumn.key}
                    className={classNames({
                      'disabledLook disabledBehavior sq-force-text-gray':
                        isStringSeries(column.series) && !extraColumn.isStringCompatible,
                    })}>
                    <Checkbox
                      isChecked={isStatisticsColumnEnabled(extraColumn.key, column.series)}
                      id={extraColumn.title}
                      label={extraColumn.title}
                      onChange={(e) => {
                        e.preventDefault();
                        sqTrendActions.toggleStatisticsColumn(extraColumn, column.series.id);
                        sqTrendActions.removeColumnFilter(`${extraColumn.key}.${column.key}`);
                      }}
                    />
                  </div>
                ))}
              </div>
            </ButtonWithPopover>
          )}
        </div>
      )
    );
  };

  return (
    <th ref={ref} className="cursorPointer text-nowrap width-minimum">
      {renderSortOptions(column)}
      {renderSortableAndFilterableColumn(column)}
    </th>
  );
};
