// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useTranslation } from 'react-i18next';
import { WorksheetViewSelector } from '@/hybrid/trend/WorksheetViewSelector.molecule';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import TreeMapHelp from '@/hybrid/treemap/TreeMapHelp.atom';
import { DisplayRangeSelector } from '@/hybrid/trend/DisplayRangeSelector.molecule';
import { TreemapChart } from '@/hybrid/treemap/TreemapChart.atom';
import { getAllItems } from '@/hybrid/trend/trendDataHelper.utilities';
import { IconSelect, ReactSelectOption } from '@/hybrid/core/IconSelect.molecule';
import { isPresentationWorkbookMode, isStringSeries as isStringSeriesUtil } from '@/hybrid/utilities/utilities';
import {
  sqDurationStore,
  sqTreemapStore,
  sqTrendScalarStore,
  sqTrendSeriesStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.constants';
import { setParent, setStatistic } from '@/treemap/treemap.actions';

export const TreemapWrapper = () => {
  const { t } = useTranslation();

  const isPresentationMode = isPresentationWorkbookMode();
  const STATISTICS_COUNT = 2;
  const { statistics, swap, helpKey, breadcrumbs, parent, tree } = useFlux(sqTreemapStore);

  // getAllItems uses these stores to source items, so we must respond to changes in those stores
  useFlux(sqTrendSeriesStore);
  useFlux(sqTrendScalarStore);

  const [allStatisticOptions, setAllStatisticOptions] = useState(
    _.filter(
      SAMPLE_FROM_SCALARS.VALUE_METHODS,
      (stat) => _.includes(stat.input, 'sample') && !stat.needsPercentile,
    ).map((statistic) => ({
      ...statistic,
      label: t(statistic.title),
      value: statistic.key,
      text: t(statistic.title),
    })),
  );

  const { displayRange } = useFlux(sqDurationStore);
  const { timezone } = useFlux(sqWorksheetStore);

  const signals = _.chain(getAllItems({ itemTypes: [ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR] }))
    .filter((signal: any) => {
      return _.some(signal.assets, ['id', swap.id]);
    })
    .map((signal) => ({ ...signal, value: signal.id, label: signal.name }))
    .value();

  return (
    <div className="flexFill flexRowContainer" data-testid="treemapWrapper">
      {isPresentationMode && <div className="mb10" />}
      {!isPresentationMode && (
        <div className="toolbar flexWrap flexColumnContainer mb10 treemapToolbar" data-testid="treemapToolbar">
          <WorksheetViewSelector />
          <div className="btn-group flexColumnContainer flexCenter flexWrap">
            <strong className="flexCenter pr5">{t('TREEMAP.STATISTICS')}</strong>
            {_.map(_.range(0, STATISTICS_COUNT), (statisticRange) => {
              const statisticsOptions = _.filter(allStatisticOptions, (item) => {
                const isStringSeries = isStringSeriesUtil(
                  _.find(signals, (signal) => signal.id === statistics[statisticRange]?.id),
                );
                return isStringSeries ? _.includes(item.input, 'string') : true;
              });

              // Added to fix the invalid signal&statistic combination set in the old version
              if (
                statistics[statisticRange]?.key &&
                !_.find(
                  statisticsOptions,
                  (statisticsOption) => statisticsOption.value === statistics[statisticRange].key,
                )
              ) {
                setStatistic(statisticRange, { key: null });
              }

              return (
                <div
                  className="flexRowContainer mr5"
                  key={`statisticSelector-${statisticRange}`}
                  data-testid="statisticsRange">
                  <div className="mb1 min-width-130">
                    <IconSelect
                      extraClassNames={`signalRange-${statisticRange}`}
                      onChange={(option: ReactSelectOption<string>) =>
                        setStatistic(statisticRange, {
                          id: option.value,
                          key: null,
                        })
                      }
                      placeholder="SELECT_SIGNAL"
                      value={statistics[statisticRange]?.id as string}
                      selectOptions={signals}
                      formattedOptions={true}
                      name={`signalSelector-${statisticRange}`}
                    />
                  </div>
                  <div className="mb1 min-width-130">
                    <IconSelect
                      extraClassNames={`statisticsRange-${statisticRange}`}
                      name={`statisticSelector-${statisticRange}`}
                      onChange={(statistic: ReactSelectOption<string>) =>
                        setStatistic(statisticRange, {
                          key: statistic.value,
                        })
                      }
                      placeholder={t('SIGNAL_FROM_CONDITION.SELECT_STATISTIC_PLACEHOLDER')}
                      value={statistics[statisticRange]?.key}
                      selectOptions={statisticsOptions}
                      formattedOptions={true}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!helpKey && (
        <ol className="breadcrumb ml10 mr10" data-testid="breadcrumbs">
          {breadcrumbs.map((breadcrumb) => (
            <li
              className={classNames('breadcrumb-item', {
                active: breadcrumb.id === parent.id,
              })}
              key={breadcrumb.id}>
              {breadcrumb.id !== parent.id && (
                <a href="#" onClick={() => setParent(breadcrumb)}>
                  {breadcrumb.name}
                </a>
              )}
              {breadcrumb.id === parent.id && <span>{breadcrumb.name}</span>}
            </li>
          ))}
        </ol>
      )}
      {helpKey && (
        <div className="flexRowContainer flexCenter flexFill" data-testid="treemapHelp">
          <div className="alert alert-info fs16 p25 flexSelfCenter width-400">
            <TreeMapHelp type={helpKey} />
          </div>
        </div>
      )}
      {!helpKey && (
        <div className="flexFill flexColumnContainer mr0 mb0 ml0 mtn10" data-testid="treemapChart">
          <TreemapChart tree={tree} />
        </div>
      )}
      <DisplayRangeSelector
        timezone={timezone}
        displayRange={displayRange}
        rangeEditingEnabled={!isPresentationMode}
        investigateRangeCopyDisabled={true}
      />
    </div>
  );
};
