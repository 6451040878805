import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface AddGroupingButtonProps {
  onClick: () => void;
  testId?: string;
}

export const AddGroupingButton: React.FC<AddGroupingButtonProps> = ({ onClick, testId = 'groupingButton' }) => {
  const { t } = useTranslation();

  return (
    <div className="flexColumnContainer flexAlignCenter ml15 mb10 cursorPointer" onClick={onClick} data-testid={testId}>
      <span className="min-width-30">
        <Icon icon="fa-plus-circle" extraClassNames="sq-text-primary fa-2x min-width-30" testId={`${testId}AddIcon`} />
      </span>
      <a href="#">{t('AGGREGATIONS.SUB_GROUP')}</a>
    </div>
  );
};
