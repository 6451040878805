// @ts-strict-ignore
import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { Icon } from '@/hybrid/core/Icon.atom';
import { LOAD_STATUS } from '@/workbook/workbook.store';
import { useTranslation } from 'react-i18next';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { SearchResult } from '@/hybrid/search/SearchResult.molecule';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { canModifyWorkbook } from '@/services/authorization.service';
import { sqLicenseManagementStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import { SearchModes, SearchPanes } from '@/search/search.constants';
import { ASSET_GROUP_VIEW } from '@/worksheet/worksheet.constants';
import { clearSelection, getStoreForPane, setMode } from '@/search/search.actions';

const searchGroupBindings = bindingsDefinition({
  loadStatus: prop<string>(),
  label: prop<string>(),
  mode: prop<SearchModes>(),
  searchMode: prop<SearchModes>(),
  searchTypes: prop<string[]>(),
  scopeIds: prop<string[]>(),
  restrictExploration: prop<boolean>(),
  onErrorClick: prop<() => void>(),
  onClickItem: prop<(item) => void>(),
  items: prop<any[]>(),
  pane: prop<SearchPanes>(),
  indicateSelection: prop.optional<boolean>(),
  selectedItemId: prop.optional<string>(),
  searchResultIcons: prop<(item) => Promise<JSX.Element | void>>(),
  noItemsMessage: prop.optional<string>(),
  sqWorksheetActions: injected<WorksheetActions>(),
  isSelectingAsset: prop.optional<boolean>(),
});

export const SearchGroup: SeeqComponent<typeof searchGroupBindings> = ({
  items,
  loadStatus,
  label,
  mode,
  pane,
  onErrorClick,
  onClickItem,
  searchResultIcons,
  searchMode,
  searchTypes,
  scopeIds,
  restrictExploration,
  noItemsMessage,
  indicateSelection = false,
  selectedItemId,
  isSelectingAsset = false,
}) => {
  const { sqWorksheetActions } = useInjectedBindings(searchGroupBindings);

  const { t } = useTranslation();
  const isAssetGroupView = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.view.key === ASSET_GROUP_VIEW.key);
  const store = getStoreForPane(pane);
  const selectedItemIds = useFluxPath(store, () => store.selectedItemIds);
  const showCreateAssetGroupButton =
    searchMode === 'asset-groups' &&
    sqLicenseManagementStore.hasAssetGroups() &&
    pane === 'main' &&
    canModifyWorkbook(sqWorkbookStore.workbook);
  const searchingUsages = mode === 'usages';

  const renderAssetGroupButton = (
    <TextButton
      testId="createButton"
      label="SEARCH_DATA.CREATE"
      onClick={() => {
        sqWorksheetActions.setReturnViewKey(sqWorksheetStore.view.key);
        sqWorksheetActions.setView(ASSET_GROUP_VIEW.key);
      }}
      disabled={isAssetGroupView}
      size="sm"
      extraClassNames="sq-btn-xs pr5 mr5"
    />
  );

  const nonClickableHeading = (
    <span className="flexColumnContainer flexSpaceBetween flexAlignCenter heading text-interactive fs15">
      {t(`SEARCH_DATA.${label}`)}
      {showCreateAssetGroupButton && renderAssetGroupButton}
    </span>
  );

  return (
    <div>
      {loadStatus === LOAD_STATUS.LOADING && !searchingUsages && (
        <div>
          {nonClickableHeading}
          <div className="pt10 pb10 flexNoGrowNoShrink flexColumnContainer flexCenter">
            <IconWithSpinner spinning={true} large={true} />
            <span className="mb5 ml5 mt5">{t(`SEARCH_DATA.LOADING_${label}`)}</span>
          </div>
        </div>
      )}

      {loadStatus === LOAD_STATUS.ERROR && !searchingUsages && (
        <div>
          {nonClickableHeading}
          <div className="flexColumnContainer sq-text-danger cursorPointer pt3 pb6" onClick={onErrorClick}>
            <Icon icon="fa-exclamation-triangle" type="danger" extraClassNames="flexCenter pl5 pr10 pt2" />
            <div className="flexFill pl5">
              {t(`SEARCH_DATA.LOAD_${label}_FAILED`)}
              <br />
              <span className="text-underline">{t('SEARCH_DATA.RETRY')}</span>
            </div>
          </div>
        </div>
      )}

      {items.length === 0 &&
        ((mode === searchMode && !searchingUsages) || mode === 'overview') &&
        noItemsMessage &&
        pane === 'main' && (
          <div>
            {nonClickableHeading}
            <div className="m5">{t(noItemsMessage)}</div>
          </div>
        )}

      {items.length > 0 && (mode === searchMode || mode === 'overview') && (
        <div>
          {mode === 'overview' && (
            <div className="flexColumnContainer flexSpaceBetween flexAlignCenter heading">
              <span
                className="text-interactive flexFill fs15"
                onClick={() => setMode(pane, searchMode, searchTypes, restrictExploration, scopeIds)}>
                {t(`SEARCH_DATA.${label}`)}
              </span>
              {showCreateAssetGroupButton ? (
                renderAssetGroupButton
              ) : (
                <Icon
                  icon="fa-chevron-right"
                  extraClassNames="text-interactive pl5 pr5"
                  onClick={() => setMode(pane, searchMode, searchTypes, restrictExploration, scopeIds)}
                />
              )}
            </div>
          )}

          {_.map(items, (item: any) => (
            <SearchResult
              isSelectingAsset={isSelectingAsset}
              key={item.id}
              item={item}
              items={items}
              iconsPromise={searchResultIcons(item)}
              itemSelected={_.indexOf(selectedItemIds, item.id) > -1}
              onClickItem={() => {
                clearSelection(); // unselect selected items
                onClickItem(item);
                sqWorksheetActions.setReturnViewKey(sqWorksheetStore.view.key);
              }}
              isSelected={indicateSelection && selectedItemId === item.id}
            />
          ))}
        </div>
      )}
    </div>
  );
};
