// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { setActiveTabIndex, setSearchParams } from '@/administration/administration.actions';
import { JOBS_TAB_INDEX } from '@/administration/administration.constants';
import Highcharts, { FlameChartOptions } from 'other_components/highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FoldedStackNodeV1, RequestOutputV1, sqRequestsApi } from '@/sdk';
import { ActivityProperties, getHighchartsFlamegraph } from '@/hybrid/administration/requestsTab.utilities';
import { humanizeInterval } from '@/hybrid/datetime/dateTime.utilities';
import { NUMBER_CONVERSIONS } from '@/main/app.constants';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';

interface RequestDetailsModalProps {
  request: RequestOutputV1;
  onClose: () => void;
}

const ORDERED_PROPERTIES = ['timers', 'meters', 'start', 'end', 'requested duration', 'formula', 'parameters'];
type RequestDetails = { property: string; value: string | number }[];
const ZOOMED_OUT: [number, number | null] = [0, null];
export const RequestDetailsModal: React.FunctionComponent<RequestDetailsModalProps> = ({ request, onClose }) => {
  const { t } = useTranslation();

  const [requestDetails, setRequestDetails] = useState<RequestDetails>([]);
  const [chartOptions, setChartOptions] = useState<FlameChartOptions>(undefined);
  const [activityDetails, setActivityDetails] = useState<ActivityProperties>(undefined);
  const [isNotRunning, setIsNotRunning] = useState(false);
  const [yAxisExtremes, setYAxisExtremes] = useState(ZOOMED_OUT);
  const [flameGraph, setFlameGraph] = useState<FoldedStackNodeV1>(undefined);

  const createRequestDetails = (requestOutput: RequestOutputV1): RequestDetails =>
    _.chain(request)
      .pick(['requested duration', 'methodAndUri', 'status', 'duration', 'percentProgress'])
      .map((value, property) => ({ property, value: value as string }))
      .concat(
        _.chain(requestOutput.parameters)
          .map((value, property) => ({ property, value: _.join(value, ' ') }))
          .sortBy(({ property }) =>
            _.includes(ORDERED_PROPERTIES, property) ? ORDERED_PROPERTIES.indexOf(property) : 100,
          )
          .value(),
      )
      .value();

  const refreshRequest = (details: RequestDetails) => {
    const requestId = _.find(details, { property: 'request ID' })?.value as string;
    return !requestId
      ? Promise.resolve()
      : sqRequestsApi
          .getRequest({ requestId })
          .then(({ data: { request: latestRequest } }) => {
            setRequestDetails(createRequestDetails(latestRequest));
            setFlameGraph(latestRequest.flameGraph);
          })
          .catch(() => {
            setIsNotRunning(true);
          });
  };

  useEffect(() => {
    setChartOptions(
      flameGraph ? getHighchartsFlamegraph(flameGraph, yAxisExtremes, setActivityDetails, setYAxisExtremes) : undefined,
    );
  }, [flameGraph, yAxisExtremes]);
  useEffect(() => {
    const details = createRequestDetails(request);
    setRequestDetails(details);
    setChartOptions(undefined);
    setActivityDetails(undefined);
    setIsNotRunning(false);
    refreshRequest(details);
  }, [request]);

  const formatKeyValue = (values) => (
    <>
      {_.chain(values)
        .split(' ')
        .map((keyValue) => <div key={keyValue}>{keyValue}</div>)
        .value()}
    </>
  );

  const formatRequestId = (value) => (
    <>
      {_.includes(value, 'Invocation^') ? (
        <span className="force-link-look cursorPointer" onClick={() => inspectJob(value)}>
          {value}
        </span>
      ) : (
        value
      )}
    </>
  );

  const formatRequestedDuration = (value) => (
    <>{humanizeInterval(value / NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND)}</>
  );

  const PROPERTY_FORMATTERS = {
    'timers': formatKeyValue,
    'meters': formatKeyValue,
    'parameters': formatKeyValue,
    'request ID': formatRequestId,
    'requested duration': formatRequestedDuration,
  };

  const inspectJob = (value) => {
    const prefix = _.chain(value).split('*').head().split('^').last().value();
    setActiveTabIndex(JOBS_TAB_INDEX);
    setSearchParams({ field: 'id', value: prefix });
    onClose();
  };

  const isZoomedIn = yAxisExtremes !== ZOOMED_OUT;
  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="requestDetailsModal" size="xl" scrollable={true}>
      <Modal.Header closeButton={true}>
        <div className="flexColumnContainer flexCenter flexFill flexSpaceBetween">
          <h3>{t('ADMIN.REQUEST.REQUEST_DETAILS')}</h3>
          {isNotRunning && (
            <Alert className="p10 m0" transition={false} variant="danger">
              {t('ADMIN.REQUEST.NOT_RUNNING')}
            </Alert>
          )}
          <ButtonWithManagedSpinner
            spinnerIconProps={{ type: 'text', large: true, extraClassNames: 'pt2 pb2' }}
            label=""
            action={() => refreshRequest(requestDetails)}
            buttonProps={{ tooltip: 'ADMIN.REQUEST.REFRESH_REQUEST' }}
            icon="fa-repeat"
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-condensed table-striped">
          <thead>
            <tr>
              <th>{t('ADMIN.REQUEST.PROPERTY')}</th>
              <th>{t('ADMIN.REQUEST.VALUE')}</th>
            </tr>
          </thead>
          <tbody>
            {_.map(requestDetails, ({ property, value }) => (
              <tr key={property}>
                <td className="text-bold">{property}</td>
                <td>{PROPERTY_FORMATTERS[property] ? PROPERTY_FORMATTERS[property](value) : value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr className="m5" />
        {chartOptions && (
          <>
            {isZoomedIn && <TextButton label="ADMIN.REQUEST.RESET_ZOOM" onClick={() => setYAxisExtremes(ZOOMED_OUT)} />}
            <div className="flexFill flexColumnContainer" data-testid="flamegraph">
              <HighchartsReact
                immutable={true}
                allowChartUpdate={true}
                highcharts={Highcharts}
                options={chartOptions}
                containerProps={{ className: 'flexFill' }}
              />
            </div>
          </>
        )}
        {activityDetails && (
          <table className="table table-condensed table-striped">
            <tbody>
              <tr>
                <td className="text-bold">{t('NAME')}</td>
                <td className="text-bold">
                  {activityDetails.value}s ({activityDetails.percentValue}%) {activityDetails.name}
                </td>
              </tr>
              <tr>
                <td className="text-bold">{t('ADMIN.REQUEST.INTERVAL')}</td>
                <td>
                  {activityDetails.intervalStart} - {activityDetails.intervalEnd}
                </td>
              </tr>
              <tr>
                <td className="text-bold">{t('ADMIN.REQUEST.INTERVAL_EXPANSION')}</td>
                <td
                  style={{ backgroundColor: activityDetails.intervalColor, color: activityDetails.intervalTextColor }}>
                  {activityDetails.intervalDays} {t('UNITS.DAYS', { NUM: activityDetails.intervalDays })}
                </td>
              </tr>
              {_.map(activityDetails.properties, (value, property) => (
                <tr key={property}>
                  <td className="text-bold">{property}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Modal.Body>
    </Modal>
  );
};
