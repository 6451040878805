// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Icon } from '@/hybrid/core/Icon.atom';
import moment from 'moment-timezone';
import { doTrack } from '@/track/track.service';

export interface VideoModalProps {
  onClose: () => any;
  videoSource: string;
  testId: string;
  backupLink?: string;
  trackFunction?: (category: string, action: string, information: string, includeDateRange?: boolean) => void;
}

export const VideoModal: React.FunctionComponent<VideoModalProps> = ({ onClose, videoSource, testId, backupLink }) => {
  const { t } = useTranslation();
  const startTime = moment.now();

  const captureWatchTimeAndClose = () => {
    const endTime = moment.now();
    const duration = endTime - startTime;
    doTrack('Training', 'video watched', moment(duration).format('mm:ss'));
    onClose();
  };

  return (
    <Modal show={true} onHide={captureWatchTimeAndClose} animation={false} data-testid={testId} size="lg">
      <Modal.Body>
        <button type="button" className="close mb5" onClick={captureWatchTimeAndClose}>
          <Icon icon="fa-close" type="text" />
        </button>
        <video controls={true} autoPlay={true} disablePictureInPicture={true} controlsList="nodownload" width="100%">
          <source src={videoSource} />
        </video>

        {backupLink && (
          <div className="mt5 xsmall text-italic">
            {t('VIDEO.TROUBLE_VIEWING')}
            <a className="ml5" href={backupLink} target="_blank" rel="noopener noreferrer">
              {t('VIDEO.WATCH_HERE')}
            </a>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
