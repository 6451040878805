import React from 'react';
import { getSvgIconPath } from '@/hybrid/utilities/utilities';

export interface SvgIconProps {
  icon: string;
  extraClassNames: string;
  viewBox?: string;
  testId?: string;
}

/**
 * Renders an icon that has an SVG icon path (see {@link isSvgIcon() })
 *
 * @param icon - the SVG icon
 * @param extraClassNames - extra class names to apply
 * @param [viewBox='0 0 19 19'] - optional SVG view box
 * @param testId TestID to add to component
 */
export const SvgIcon: React.FunctionComponent<SvgIconProps> = ({
  icon,
  extraClassNames,
  viewBox = '0 0 19 19',
  testId,
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={extraClassNames} viewBox={viewBox} data-testid={testId}>
      <path d={getSvgIconPath(icon)} />
    </svg>
  );
};
