// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/hybrid/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { FileDropper, FileDropperProps } from '@/hybrid/core/FileDropper.atom';

export interface FileDropperIF extends FileDropperProps, ValidatingFormComponent<string | number> {
  component: 'FileDropperFormComponent';
}

export const FileDropperFormComponent: React.FunctionComponent<FileDropperIF> = (props) => {
  const { name, extendValidation, validation, id } = props;

  const defaultValidation = (value) => _.isEmpty(_.trim(value));

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();

  return (
    <Field name={name} validate={appliedValidation}>
      {({ input, meta }) => {
        const properProps = getFormFieldProps(formState, input, meta, props);

        return <FileDropper {...properProps} isValid={!properProps.showError} />;
      }}
    </Field>
  );
};
