// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { executeDisplay, setAsset, setIsScaled } from '@/hybrid/tools/display/displayTool.actions';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { useAllTrendStoreItems } from '@/hybrid/core/hooks/useAllTrendStoreItems.hook';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { sqDisplayToolStore, sqInvestigateStore } from '@/core/core.stores';
import { DISPLAY_MODE } from '@/main/app.constants';
import { getLocalTreeRoots, getRecommendedAsset, getValidAncestors } from '@/hybrid/displays/displays.utilities';
import { displaysEnabled } from '@/services/systemConfiguration.utilities';
import { exploreAssetSearchActions, setHighlightItem } from '@/search/search.actions';

const displayToolBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqWorksheetActions: injected<WorksheetActions>(),
});

export const DisplayTool: SeeqComponent<typeof displayToolBindings> = () => {
  const { $state, sqInvestigateActions, sqWorksheetActions } = useInjectedBindings(displayToolBindings);

  const displayMode = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.displayMode);

  const { name, asset, isScaled } = useFlux(sqDisplayToolStore);

  const displayItems = useAllTrendStoreItems({
    // We only want to re-calculate ancestors and asset recommendation when the items themselves have changed, or their
    // 'assets' property has been updated
    memoComparison: (previous, next) =>
      _.isArray(previous) &&
      _.isArray(next) &&
      _.isEqual(
        _.map(previous, (item) => _.pick(item, ['id', 'assets'])),
        _.map(next, (item) => _.pick(item, ['id', 'assets'])),
      ),
  });

  const [localTreeRoots, setLocalTreeRoots] = useState(null);
  const [defaultAssetProvided, setDefaultAssetProvided] = useState(false);
  useEffect(() => {
    getLocalTreeRoots($state.params.workbookId).then((rootIds) => {
      setLocalTreeRoots(rootIds);
    });
  }, []);

  useEffect(() => {
    getRecommendedAsset(localTreeRoots, displayItems)?.then((item) => {
      setDefaultAssetProvided(true);
      setAsset(item);
      if (!_.isEmpty(item.ancestors)) {
        exploreAssetSearchActions('modal', _.last(item.ancestors).id);
      }
    });
  }, [localTreeRoots, displayItems]);

  const validAncestors = useMemo(() => getValidAncestors(localTreeRoots, displayItems), [localTreeRoots, displayItems]);
  const assetValidation = useCallback(
    (item) => isScaled && validAncestors && !_.includes(validAncestors, item?.id),
    [isScaled, validAncestors],
  );
  const canScale = useMemo(() => !validAncestors || !_.isEmpty(validAncestors), [validAncestors]);
  useEffect(() => {
    if (!canScale) {
      setIsScaled(false);
    }
  }, [canScale]);

  const formDataSetup: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'displayToolTitle',
      value: name,
      onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.DISPLAY, name),
      id: 'displayToolTitle',
      searchIconClass: 'fc-displays',
      defaultName: 'DISPLAYS.TOOL.HEADER',
    },
    {
      component: 'FormGroup',
      name: 'selectAssetFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'LabelFormComponent',
          name: 'selectAssetLabel',
          value: 'DISPLAYS.TOOL.SELECT_ASSET_LABEL',
        },
        {
          component: 'SelectAssetFormComponent',
          value: asset,
          name: 'displayToolSelectAsset',
          testId: 'displayToolSelectAsset',
          onChange: setAsset,
          scopeIds: [$state.params.workbookId],
          excludeGloballyScoped: true,
          validation: assetValidation,
          extendValidation: true,
          defaultProvided: defaultAssetProvided,
          customErrorText: 'DISPLAYS.TOOL.SELECT_ASSET_ERROR',
          iconPartialTooltipKey: 'DISPLAYS.TOOL.SELECT_ASSET_TOOLTIP',
          helpTextKey: 'DISPLAYS.TOOL.SELECT_ASSET_HELP_TEXT',
        },
      ],
    },
    {
      component: 'RadioButtonGroupFormComponent',
      name: 'isScaled',
      value: isScaled,
      onChange: _.noop,
      id: 'displayToolRadios',
      displayNumber: true,
      verticalLayout: true,
      options: [
        {
          id: 'displayToolScaleRadio',
          label: 'DISPLAYS.TOOL.RADIOS.SCALE',
          checked: isScaled,
          disabled: !canScale,
          tooltip: canScale ? undefined : 'DISPLAYS.TOOL.RADIOS.CANT_SCALE_TOOLTIP',
          onToggle: () => setIsScaled(true),
        },
        {
          id: 'displayToolNoScaleRadio',
          label: 'DISPLAYS.TOOL.RADIOS.NO_SCALE',
          checked: !isScaled,
          onToggle: () => setIsScaled(false),
        },
      ],
    },
  ];

  const submit = () => {
    return executeDisplay($state.params.workbookId, $state.params.worksheetId, name, asset, isScaled)
      .then((display) => {
        const messageKey = display.template.displayCount > 1 ? 'DISPLAYS.TOOL.SUCCESS_SCALED' : 'DISPLAYS.TOOL.SUCCESS';
        successToast({
          messageKey,
          messageParams: {
            DISPLAY_NAME: name,
            ASSET_NAME: asset.name,
            SCALE_COUNT: display.template.displayCount - 1,
          },
        });
        sqInvestigateActions.close();
        sqWorksheetActions.tabsetChangeTab('sidebar', 'search');
        return exploreAssetSearchActions('main', asset.id).then(() => setHighlightItem('main', display.id));
      })
      .catch((error) => {
        errorToast({
          httpResponseOrError: error,
        });
      });
  };

  const displayToolBuilder = (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={submit}
      closeFn={sqInvestigateActions.close}
      toolId={TREND_TOOLS.DISPLAY}
      submitBtnId="displayToolExecuteButton"
    />
  );

  const displayToolEnabled =
    (displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT) && displaysEnabled();

  return displayToolEnabled ? displayToolBuilder : null;
};

export const sqDisplayTool = angularComponent(displayToolBindings, DisplayTool);
