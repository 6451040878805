// @ts-strict-ignore
import _ from 'lodash';
import Essentials from 'ckeditor5/packages/ckeditor5-essentials/src/essentials';
import Paragraph from 'ckeditor5/packages/ckeditor5-paragraph/src/paragraph';
import Bold from 'ckeditor5/packages/ckeditor5-basic-styles/src/bold';
import Italic from 'ckeditor5/packages/ckeditor5-basic-styles/src/italic';
import Underline from 'ckeditor5/packages/ckeditor5-basic-styles/src/underline';
import Strikethrough from 'ckeditor5/packages/ckeditor5-basic-styles/src/strikethrough';
import Link from 'ckeditor5/packages/ckeditor5-link/src/link';
import AutoLink from 'ckeditor5/packages/ckeditor5-link/src/autolink';
import Table from 'ckeditor5/packages/ckeditor5-table/src/table';
import TableToolbar from 'ckeditor5/packages/ckeditor5-table/src/tabletoolbar';
import TableProperties from 'ckeditor5/packages/ckeditor5-table/src/tableproperties';
import TableCellProperties from 'ckeditor5/packages/ckeditor5-table/src/tablecellproperties';
import PageBreak from 'ckeditor5/packages/ckeditor5-page-break/src/pagebreak';
import Heading from 'ckeditor5/packages/ckeditor5-heading/src/heading';
import Alignment from 'ckeditor5/packages/ckeditor5-alignment/src/alignment';
import Font from 'ckeditor5/packages/ckeditor5-font/src/font';
import Indent from 'ckeditor5/packages/ckeditor5-indent/src/indent';
import IndentBlock from 'ckeditor5/packages/ckeditor5-indent/src/indentblock';
import ListStyle from 'ckeditor5/packages/ckeditor5-list/src/liststyle';
import RemoveFormat from 'ckeditor5/packages/ckeditor5-remove-format/src/removeformat';
import Image from 'ckeditor5/packages/ckeditor5-image/src/image';
import ImageToolbar from 'ckeditor5/packages/ckeditor5-image/src/imagetoolbar';
import ImageInsert from 'ckeditor5/packages/ckeditor5-image/src/imageinsert';
import ImageStyle from 'ckeditor5/packages/ckeditor5-image/src/imagestyle';
import ImageUpload from 'ckeditor5/packages/ckeditor5-image/src/imageupload';
import ImageResize from 'ckeditor5/packages/ckeditor5-image/src/imageresize';
import LinkImage from 'ckeditor5/packages/ckeditor5-link/src/linkimage';
// TODO CRAB-29697 - update path when this plugin will be included into a release version
import { TableColumnResize } from './ckEditorPlugins/plugins/ckeditor5-table-resize/src';
import {
  BasePluginDependencies,
  CustomPlugin,
  CustomPluginSetup,
} from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { PluginDependencies } from '@/hybrid/annotation/ckEditorPlugins/plugins/PluginDependencies';
import { SeeqImageUpload, SeeqImageUploadAdapter } from '@/hybrid/annotation/ckEditorPlugins/plugins/SeeqImageUpload';
import { SeeqImageBorder } from '@/hybrid/annotation/ckEditorPlugins/plugins/SeeqImageBorder';
import { NestedTables } from '@/hybrid/annotation/ckEditorPlugins/plugins/NestedTables';
import Clipboard from 'ckeditor5/packages/ckeditor5-clipboard/src/clipboard';
import Pagination from '@ckeditor/ckeditor5-pagination/src/pagination';
import PasteFromOffice from 'ckeditor5/packages/ckeditor5-paste-from-office/src/pastefromoffice';
import { StripedTables } from '@/hybrid/annotation/ckEditorPlugins/plugins/StripedTables';
import { DateRangeLabelPlugin } from '@/hybrid/annotation/ckEditorPlugins/plugins/DateRangeLabel';
import { AssetSelectionLabelPlugin } from '@/hybrid/annotation/ckEditorPlugins/plugins/AssetSelectionLabel';
import { IframeContentPlugin } from './ckEditorPlugins/plugins/IframeContent';
import i18next from 'i18next';
import { CopyForEmail } from '@/hybrid/annotation/ckEditorPlugins/plugins/CopyForEmail';
import { RelativeLinks } from '@/hybrid/annotation/ckEditorPlugins/plugins/RelativeLinks';

const isExternalUrl = (urlToTest) => {
  // consider internal if there's no window (some tests) OR urls start with "/"
  if (!window || !window.location || !urlToTest || urlToTest.startsWith('/')) {
    return false;
  }
  // check domain if we have a full url
  try {
    const urlObj = new URL(urlToTest);
    if (urlObj.hostname === window.location.hostname) {
      return false;
    }
  } catch (error) {
    // the URL could not be extracted - consider external to be on the safe side
    // no-op
  }
  return true;
};

export const DefaultCKConfig = {
  language: 'en',
  plugins: [
    Alignment,
    AutoLink,
    Bold,
    Clipboard,
    Essentials,
    Font,
    Heading,
    Image,
    ImageInsert,
    ImageStyle,
    ImageResize,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    ListStyle,
    PageBreak,
    Pagination,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    Strikethrough,
    Table,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    Underline,
    // custom plugins
    StripedTables,
    PluginDependencies,
    SeeqImageUpload,
    SeeqImageUploadAdapter,
    SeeqImageBorder,
    NestedTables,
    DateRangeLabelPlugin,
    AssetSelectionLabelPlugin,
    IframeContentPlugin,
    CopyForEmail,
    RelativeLinks,
  ],
  toolbar: [
    'imageInsert',
    '|',
    'link',
    'insertTable',
    'pagebreak',
    '|',
    'heading',
    CopyForEmail.pluginName,
    'fontFamily',
    'fontSize',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    '|',
    'alignment',
    'outdent',
    'indent',
    'numberedList',
    'bulletedList',
    '|',
    'removeFormat',
    '|',
    'undo',
    'redo',
  ],
  pagination: {},
  licenseKey:
    process.env.NODE_ENV === 'production'
      ? 'ceuGAKdAHFiCbuT8k3sDWpvwEeT6WQL3TI0DoUktkfa8eu8='
      : 'cElUNoM4O0rhgLlYzQomBBgOnvFTMfVH/xXCDJ0aUil/X7c=',
  table: {
    contentToolbar: [
      'tableRow',
      'tableColumn',
      'mergeTableCells',
      'tableProperties',
      'tableCellProperties',
      StripedTables.pluginName,
    ],
  },
  seeqUpload: {
    withCredentials: true,
  },
  image: {
    upload: {
      types: ['jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'svg+xml'],
    },
    toolbar: [
      'imageStyle:inline',
      'imageStyle:block',
      '|',
      'imageResize',
      '|',
      'imageTextAlternative',
      '|',
      'linkImage',
      '|',
      SeeqImageBorder.pluginName,
    ],
    options: {
      styles: ['inline', 'block'],
    },
  },
  link: {
    defaultProtocol: 'http://',
    decorators: {
      isExternal: {
        mode: 'automatic',
        callback: isExternalUrl,
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3',
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4',
      },
    ],
  },
};

export function createCustomConfig(
  customPlugins: CustomPlugin[],
  basePluginDependencies: BasePluginDependencies,
  customToolbar: string[] = undefined,
) {
  const config = { ...DefaultCKConfig };
  if (customToolbar) config.toolbar = customToolbar;

  addConfigOptionsWithTranslation(config);

  _.chain(customPlugins)
    .map((plugin) => CustomPluginSetup[plugin])
    .forEach((setup) => {
      config.plugins = [...config.plugins, setup.plugin];
      if (setup.config) {
        config[setup.name] = setup.config;
      }
      if (setup.toolbar && !_.includes(config.toolbar, setup.toolbar)) {
        throw new Error(`Plugin ${setup.name} has toolbar options and was not included in the toolbar.`);
      }
    })
    .value();

  config[PluginDependencies.pluginName] = { ...basePluginDependencies };

  return config;
}

function addConfigOptionsWithTranslation(config) {
  const existingOption = _.find(config.heading.options, ['model', 'pre']) as HeadingOption;
  if (existingOption) {
    existingOption.title = i18next.t('REPORT.EDITOR.CODE');
  } else {
    config.heading.options.push({
      model: 'pre',
      title: i18next.t('REPORT.EDITOR.CODE'),
      view: 'pre',
      class: 'codePreformat',
    });
  }
}
