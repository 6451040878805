import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { IconSelect } from '@/hybrid/core/IconSelect.molecule';
import { useTranslation } from 'react-i18next';
import { CompositeLogic } from '@/hybrid/tools/compositeSearch/compositeSearch.constants';

const compositeSelectorBindings = bindingsDefinition({
  selectedLogic: prop<CompositeLogic>(),
  logicFormulas: prop<CompositeLogic[]>(),
  onChangeLogic: prop<(logic: string) => void>(),
  insideModal: prop.optional<boolean>(),
  extraClassNames: prop.optional<string>(),
});
export const CompositesSelectorUnwrapped: SeeqComponent<typeof compositeSelectorBindings> = (props) => {
  const { selectedLogic, logicFormulas, onChangeLogic, insideModal, extraClassNames } = props;
  const { t } = useTranslation();

  const formattedLabels = _.map(logicFormulas, (logic) => ({
    text: logic.key,
    value: logic.key,
    label: (
      <>
        <div data-testid="optionText">
          <strong>{t(`COMPOSITE_SEARCH.${logic.key}.LABEL`)}</strong>
        </div>
        <small>{t(`COMPOSITE_SEARCH.${logic.key}.DESCRIPTION`)}</small>
      </>
    ),
  }));

  return (
    <IconSelect
      onChange={(option) => onChangeLogic(_.toString(option.value))}
      name="compositeLogic"
      selectOptions={formattedLabels}
      formattedOptions={true}
      placeholder="COMPOSITE_SEARCH.SELECT_LOGIC"
      value={selectedLogic?.key}
      insideModal={insideModal}
      extraClassNames={classNames(extraClassNames, 'specLogicSelect', 'overflowVisible', {
        'min-height-40 pt2': !_.isUndefined(selectedLogic?.key),
      })}
    />
  );
};

export const CompositesSelector = React.memo(
  CompositesSelectorUnwrapped,
  (prev, next) =>
    !(
      !_.isEqual(prev.selectedLogic, next.selectedLogic) ||
      !_.isEqual(prev.logicFormulas, next.logicFormulas) ||
      prev.extraClassNames !== next.extraClassNames
    ),
);
