// @ts-strict-ignore
import React from 'react';
import { ValidatingFormComponent } from '@/hybrid/formbuilder/formBuilder.module';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/hybrid/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/hybrid/formbuilder/FormFieldWrapper';
import _ from 'lodash';
import { ButtonWithPopover, PopoverConfig } from '@/hybrid/core/ButtonWithPopover.molecule';
import { useShowError } from '@/hybrid/formbuilder/hooks/useShowError';

export interface ButtonWithPopoverIF extends ValidatingFormComponent<any> {
  component: 'ButtonWithPopoverFormComponent';
  popoverLabel: ({ className }) => React.ReactNode;
  popoverContent: ({ onChange, value }) => React.ReactNode;
  popoverConfig: PopoverConfig;
  closeOnClick?: boolean;
}

export const ButtonWithPopoverFormComponent: React.FunctionComponent<ButtonWithPopoverIF> = (props) => {
  const {
    name,
    testId = 'buttonWithPopover',
    label,
    popoverLabel,
    popoverConfig,
    popoverContent,
    closeOnClick,
    extendValidation,
    validation,
    customErrorText,
  } = props;

  const formState = useForm().getState();
  const { showError, setShowError } = useShowError(false);

  const defaultValidation = _.isUndefined;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <FormFieldWrapper testId={testId} label={label} showError={showError} customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const { onChange, value, showError, className } = getFormFieldProps(formState, input, meta, props);
          setShowError(showError);

          return (
            <ButtonWithPopover
              label={popoverLabel({ className })}
              popoverConfig={popoverConfig}
              closeOnClick={closeOnClick}>
              {popoverContent({ onChange, value })}
            </ButtonWithPopover>
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
