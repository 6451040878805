import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { overwriteDisplay } from '@/hybrid/displays/displays.actions';
import { doTrack } from '@/track/track.service';

interface OverwriteDisplayModalProps {
  onClose: () => void;
  display: {
    id: string;
    name: string;
    type: string;
    ancestors: { id: string }[];
  };
  displaysCount: number;
}

export const OverwriteDisplayModal: React.FunctionComponent<OverwriteDisplayModalProps> = ({
  onClose,
  display,
  displaysCount,
}) => {
  const { t } = useTranslation();

  const formDefinition: FormElement[] = [
    {
      component: 'LabelFormComponent',
      name: 'overwriteDisplayLabel',
      value: 'DISPLAYS.MODAL.OVERWRITE.BODY_DISPLAY',
      valueCustomParams: { ITEM_NAME: display.name },
    },
    {
      component: 'LabelFormComponent',
      name: 'renameDisplayWarning',
      value: 'DISPLAYS.MODAL.MULTIPLE_DISPLAY_WARNING',
      valueCustomParams: { SCALE_COUNT: displaysCount - 1 },
      extraClassNames: 'text-italic',
      includeIf: displaysCount > 1,
    },
  ];

  /**
   * Resets the current workstep of the display and all scaled displays to the current workstep
   *
   * @returns {Promise} that resolves when the update is complete
   */
  function trackAndOverwriteDisplay() {
    doTrack('Search results', 'Overwrite Display', `overwrite display item ${display.name}`);
    return overwriteDisplay(display).then(() => {
      onClose();
    });
  }

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="overwriteDisplayModal">
      <Modal.Header closeButton={true}>
        <Modal.Title>{t('DISPLAYS.MODAL.OVERWRITE.TITLE')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="overwriteDisplayModalBody">
          <SimpleSaveFormBuilder
            testId="overwriteSimpleSaveFormBuilder"
            formDefinition={formDefinition}
            submitFn={trackAndOverwriteDisplay}
            closeFn={onClose}
            submitBtnLabel="SEARCH_DATA.DISPLAYS.OVERWRITE"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
