// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { FormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';
import { Icon } from '@/hybrid/core/Icon.atom';
import { FormulaToolStore } from '@/hybrid/tools/formula/formulaTool.store';
import { SEARCH } from '@/hybrid/tools/formula/formulaTool.constants';
import { FormControl } from 'react-bootstrap';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';

const formulaDocumentationHeaderBindings = bindingsDefinition({
  backEnabled: prop<boolean>(),
  functionFilter: prop<string>(),
  clearHelpText: prop<(searchText: string) => void>(),
  goBack: prop<() => void>(),
  goHome: prop<() => void>(),
  loadFullList: prop<() => void>(),
  formulaHelpToggleFunction: prop.optional<() => void>(),
  sqFormulaToolActions: injected<FormulaToolActions>(),
  sqFormulaToolStore: injected<FormulaToolStore>(),
});

export const FormulaDocumentationHeader: SeeqComponent<typeof formulaDocumentationHeaderBindings> = ({
  backEnabled,
  functionFilter,
  clearHelpText,
  goBack,
  goHome,
  loadFullList,
  formulaHelpToggleFunction,
}) => {
  const { sqFormulaToolStore } = useInjectedBindings(formulaDocumentationHeaderBindings);

  const [canGoBack, setCanGoBack] = useState(backEnabled);
  const navigationStack = useFluxPath(sqFormulaToolStore, () => sqFormulaToolStore.navigationStack);

  useEffect(() => {
    setCanGoBack(
      backEnabled &&
        !(navigationStack.length === 1 && _.first(navigationStack) === SEARCH) &&
        navigationStack.length > 0,
    );
  }, [backEnabled, navigationStack]);

  return (
    <div className="flexColumnContainer flexSpaceBetween flexNoGrowNoShrink flexCenter" data-testid="formulaDocsHeader">
      <Icon
        icon="fa-arrow-left"
        tooltip="Previous help"
        tooltipPlacement="top"
        type={canGoBack ? 'theme' : 'gray'}
        large={true}
        extraClassNames="pr10"
        onClick={canGoBack ? goBack : _.noop}
        testId="goBackIcon"
      />

      <Icon
        icon="fa-home"
        tooltip="Return to the introduction page"
        tooltipPlacement="top"
        large={true}
        extraClassNames="pr10 cursorPointer sq-text-primary"
        onClick={goHome}
        testId="goHomeIcon"
      />
      <div>
        <Icon
          icon="fa-list-ul"
          tooltip="View a list of all functions"
          tooltipPlacement="top"
          large={true}
          extraClassNames="pr10 cursorPointer sq-text-primary"
          onClick={loadFullList}
          testId="fullListIcon"
        />
      </div>

      <FormControl
        autoComplete="off"
        type="text"
        placeholder="Search documentation"
        value={functionFilter ?? ''}
        onFocus={(e) => e.target.select()}
        onChange={(e) => clearHelpText(e.target.value)}
      />

      {_.isFunction(formulaHelpToggleFunction) && (
        <div
          className="text-underline ml15 mr7 cursorPointer sq-text-primary nowrap"
          onClick={formulaHelpToggleFunction}>
          Hide Help
        </div>
      )}
    </div>
  );
};
