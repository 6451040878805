// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { ToolbarPopoverButton } from '@/hybrid/core/ToolbarPopoverButton.molecule';
import { Popover } from 'react-bootstrap';
import { InputRangeSlider } from '@/hybrid/core/InputRangeSlider.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { doTrack } from '@/track/track.service';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { sqScatterPlotStore } from '@/core/core.stores';
import { MAX_MARKER_SIZE_CUSTOM, MIN_MARKER_SIZE_CUSTOM } from '@/scatterPlot/scatterPlot.constants';

const scatterPlotMarkerSizePopoverButtonBindings = bindingsDefinition({
  sqScatterPlotActions: injected<ScatterPlotActions>(),
  isSmallButton: prop.optional<boolean>(),
});

export const ScatterPlotMarkerSizePopoverButton: SeeqComponent<typeof scatterPlotMarkerSizePopoverButtonBindings> = (
  props,
) => {
  const { sqScatterPlotActions } = useInjectedBindings(scatterPlotMarkerSizePopoverButtonBindings);
  const { isSmallButton } = props;
  const { t } = useTranslation();

  const { markerSize } = useFlux(sqScatterPlotStore);
  // sliderValue is a local state variable so the user can adjust the slider without the
  // scatter plot having to re-render with every adjustment.
  const [sliderValue, setSliderValue] = useState(markerSize);
  // useEffect ensures that sliderValue will update to the markerSize from the store on re-render.
  // Without useEffect, sliderValue will instead maintain its current state.
  useEffect(() => {
    setSliderValue(markerSize);
  }, [markerSize]);

  const handleMouseUp = (value) => {
    doTrack('Scatterplot', 'change marker size');
    setSliderValue(value);
    sqScatterPlotActions.setMarkerSize(value);
  };

  const markerSizePopover = (
    <Popover id="table-headers-popover">
      <Popover.Title>{t('SCATTER.MARKER_SIZE_TOOLTIP')} </Popover.Title>
      <Popover.Content>
        <div className="flexRowContainer flexNoGrowNoShrink">
          <InputRangeSlider
            min={MIN_MARKER_SIZE_CUSTOM}
            max={MAX_MARKER_SIZE_CUSTOM}
            step={(MAX_MARKER_SIZE_CUSTOM - MIN_MARKER_SIZE_CUSTOM) / 100}
            value={sliderValue}
            onMouseUp={handleMouseUp}
            onChange={setSliderValue}
            label=""
            testId="scatterPlotMarkerSizeSlider"
          />
          <TextButton
            label="TOOLBAR.RESET"
            onClick={() => {
              doTrack('Scatterplot', 'reset marker size');
              sqScatterPlotActions.resetMarkerSize();
              setSliderValue(sqScatterPlotStore.markerSize);
            }}
          />
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <ToolbarPopoverButton
      active={null}
      label={!isSmallButton ? 'SCATTER.MARKER_SIZE' : ''}
      icon="fa-dot-circle-o"
      testId="scatterPlotMarkerSizeButton"
      tooltipText="SCATTER.MARKER_SIZE_TOOLTIP"
      tooltipPlacement="top">
      {markerSizePopover}
    </ToolbarPopoverButton>
  );
};
