import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useResizeWatcher } from '@/hybrid/core/hooks/useResizeWatcher.hook';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { TrendToolbar } from '@/hybrid/trend/toolbar/TrendToolbar.organism';
import TrendHelp from '@/hybrid/trend/TrendHelp.atom';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import {
  checkCanShowChart,
  determineBarChartHeight,
  isToolPanelsShown,
  selectItems,
  showToolPanels,
} from '@/hybrid/trend/trendViewer/trendViewer.utilities';
import { ITEM_TYPES, TREND_VIEWS } from '@/trendData/trendData.constants';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { CapsulePickerPrompt } from '@/hybrid/trend/CapsulePickerPrompt.atom';
import _ from 'lodash';
import { TableVisualization } from '@/hybrid/trend/tableVisualization/TableVisualization.organism';
import { Chart } from '@/hybrid/trend/Chart.organism';
import { TrendActions } from '@/trendData/trend.actions';
import { pickSelectedRegion } from '@/hybrid/tools/manualCondition/capsuleGroup.actions';
import {
  sqDurationStore,
  sqInvestigateStore,
  sqTrendScalarStore,
  sqTrendCapsuleStore,
  sqTrendSeriesStore,
  sqTrendTableStore,
  sqWorksheetStore,
  sqTrendStore,
} from '@/core/core.stores';
import { DisplayRangeSelector } from '@/hybrid/trend/DisplayRangeSelector.molecule';
import { useDidUpdate } from 'rooks';
import { chartItems as getChartItems } from '@/hybrid/utilities/trendChartItemsHelper.utilities';
import {
  collectionPropertyChanged,
  headlessRenderMode,
  isPresentationWorkbookMode,
  propertyChanged,
} from '@/hybrid/utilities/utilities';
import { InvestigateRangeSelector } from '@/hybrid/trend/InvestigateRangeSelector.molecule';
import { BottomPanels } from '@/hybrid/trend/panels/bottomPanels/BottomPanels.organism';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFluxPaths } from '@/hybrid/core/hooks/useFluxPaths.hook';
import { LISTEN_ITEM_PROPERTIES } from '@/hybrid/trend/trendViewer/trendViewer.constants';
import { isProtractor } from '@/hybrid/core/utilities';
import { BaobabEvent } from '@/core/flux.constants';
import { CompareViewWrapper } from '@/hybrid/trend/trendViewer/CompareViewWrapper.organism';
import { setYExtremes, updateLaneDisplay } from '@/trendData/yAxis.actions';
import { ChainViewCapsulesExceedLimit } from '@/hybrid/trend/trendHelp/ChainViewCapsulesExceedLimit';
import { AlertWarning } from '@/hybrid/trend/trendHelp/AlertWarning';

const trendViewerBindings = bindingsDefinition({
  sqWorksheetActions: injected<WorksheetActions>(),
  sqTrendActions: injected<TrendActions>(),
});

export const TrendViewer: SeeqComponent<typeof trendViewerBindings> = () => {
  const { sqWorksheetActions, sqTrendActions } = useInjectedBindings(trendViewerBindings);

  const {
    isCompareMode,
    view,
    capsuleTimeOffsets,
    selectedRegion,
    showLaneLabels,
    separateByProperty,
    colorByProperty,
    hideUnselectedItems,
    capsulePanelHasNext,
  } = useFluxPaths(sqTrendStore, [
    'isCompareMode',
    'view',
    'capsuleTimeOffsets',
    'selectedRegion',
    'showLaneLabels',
    'separateByProperty',
    'colorByProperty',
    'hideUnselectedItems',
    'capsulePanelHasNext',
  ]);

  const breaks = useFluxPath(sqTrendCapsuleStore, () => sqTrendCapsuleStore.stitchBreaks);
  const chartItemsFromStore = useFluxPath(sqTrendCapsuleStore, () => sqTrendCapsuleStore.chartItems);

  useFlux(sqTrendSeriesStore, syncTrendStores);
  useFlux(sqTrendScalarStore, syncTrendStores);
  useFlux(sqTrendTableStore, () => updateChartItems());
  const isDimmed = useFluxPath(sqTrendStore, () => sqTrendStore.dimDataOutsideCapsules);
  const labelDisplayConfiguration = useFluxPath(sqTrendStore, () => sqTrendStore.labelDisplayConfiguration, _.isEqual);
  const isCapsuleTimeLimited = useFluxPath(sqTrendStore, () => sqTrendStore.capsuleTimeLimited.enabled);
  const toolName = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.activeToolName || 'CAPSULE_GROUP');
  const isPickingMode = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.isCapsulePickingMode);
  const selectedTimezone = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone);
  const conditionToSeriesGrouping = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.conditionToSeriesGrouping);
  const displayRange = useFluxPath(sqDurationStore, () => sqDurationStore.displayRange);

  const trendViewerRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const trendDisplayAreaRef = useRef<HTMLDivElement>(null);
  const [initialLoadFinished, setInitialLoadFinished] = useState(false);
  const [isSmallToolbar, setSmallToolbar] = useState(false);
  const [displayTables, setDisplayTables] = useState<any[]>([]);
  const [chartItems, setChartItems] = useState(chartItemsFromStore);
  const [barChartHeight, setBarChartHeight] = useState<string>();

  const { start: trendStart, end: trendEnd } = displayRange;
  const isPresentationMode = isPresentationWorkbookMode();
  const rangeEditingEnabled = !isPresentationMode;
  const isCapsuleTime = view === TREND_VIEWS.CAPSULE;
  const isChainView = view === TREND_VIEWS.CHAIN;
  const summaryLabel = isPresentationMode ? sqTrendStore.getSummaryAsString() : undefined;
  const canShowChart = checkCanShowChart({
    initialLoadFinished,
    view,
    chartItems,
    isCompareMode,
  });

  // asynchronous mode for Boabab is disabled when isProtractor returns true (i.e. when running system tests). This
  // timeout makes sure that the chartItems are not frantically updated when a new event is triggered
  const updateChartTimoutRef = useRef<number>();

  const updateChartItems = (): void => {
    clearTimeout(updateChartTimoutRef.current);
    updateChartTimoutRef.current = window.setTimeout(
      () => {
        const [displayTables, otherItems] = _.partition(getChartItems(), ['itemType', ITEM_TYPES.TABLE]);
        setDisplayTables(displayTables);
        setChartItems(otherItems);
      },
      isProtractor() ? 500 : 0,
    );
  };

  useResizeWatcher(
    {
      elementRef: toolbarRef,
      callback: ({ newWidth }) => {
        const isSmall = newWidth < 815;
        if (isSmall !== isSmallToolbar) {
          setSmallToolbar(isSmall);
        }
      },
      callOnLoad: true,
    },
    [isSmallToolbar],
  );

  useEffect(() => {
    updateChartItems();
    if (view === TREND_VIEWS.CHAIN) {
      sqTrendActions.createStitchDetails();
    }
    // This timeout avoids the chain view 'no capsules' help screen from showing while the trend is first loading.
    // Since screenshots wait for HTTP requests to finish, it isn't necessary to wait in that case.
    if (headlessRenderMode()) {
      setInitialLoadFinished(true);
    } else {
      setTimeout(
        () => {
          setInitialLoadFinished(true);
        },
        isChainView ? 3000 : 0,
      );
    }
  }, []);

  useDidUpdate(() => {
    updateChartItems();
  }, [chartItemsFromStore, view, hideUnselectedItems, separateByProperty, colorByProperty, conditionToSeriesGrouping]);

  useEffect(() => {
    if (canShowChart || displayTables.length > 0) {
      setBarChartHeight(
        `${determineBarChartHeight({
          chartItems,
          displayTables,
          trendDisplayAreaRef,
        })}px`,
      );
    }
  }, [canShowChart, displayTables.length, chartItems]);

  function syncTrendStores(e: BaobabEvent) {
    if (collectionPropertyChanged(e, 'items', LISTEN_ITEM_PROPERTIES)) {
      updateChartItems();
    }

    if (propertyChanged(e, ['previewChartItem'])) {
      if (!sqTrendSeriesStore.previewChartItem || _.isNil(sqTrendSeriesStore.previewChartItem.yAxisMin)) {
        updateLaneDisplay(sqTrendActions);
      }

      updateChartItems();
    }
  }

  return (
    <div
      className="trendViewer flexFill flexRowContainer resizablePanelContainer"
      ref={trendViewerRef}
      data-testid="trendViewer">
      {isPresentationMode && <div data-testid="mb10" className="mb10" />}

      {!isPresentationMode && (
        <div
          id="trendViewerToolbar"
          data-testid="trendViewerToolbar"
          className={classNames('flexColumnContainer flexSpaceBetween toolbar mb10 pl10', {
            smallToolbar: isSmallToolbar,
          })}
          ref={toolbarRef}>
          <TrendToolbar isSmall={isSmallToolbar} />
        </div>
      )}

      {!canShowChart && displayTables.length === 0 && (
        <div className="flexRowContainer flexCenter flexFill">
          <div
            data-testid="trendHelpContainer"
            className={classNames('flexSelfCenter', {
              'alert alert-info fs16 p25 width-400': !isCompareMode,
            })}>
            <TrendHelp />
          </div>
        </div>
      )}

      {canShowChart && isPickingMode && (
        <div data-testid="capsulePickerPromptContainer" className="flexColumnContainer flexCenter">
          <CapsulePickerPrompt
            toolName={toolName}
            isToolPanelShown={isToolPanelsShown()}
            showToolPanels={() => showToolPanels({ sqWorksheetActions })}
          />
        </div>
      )}

      {(canShowChart || displayTables.length > 0) && (
        <div
          ref={trendDisplayAreaRef}
          className="flexFill flexRowContainer"
          id="trendDisplayArea"
          data-testid="trendDisplayArea">
          {isCapsuleTime && isCapsuleTimeLimited && (
            <AlertWarning>
              <TrendHelp />
            </AlertWarning>
          )}

          {isChainView && capsulePanelHasNext && <ChainViewCapsulesExceedLimit />}

          {_.map(displayTables, (table) => (
            <div
              key={table.id}
              style={{
                minHeight: barChartHeight,
                height: barChartHeight,
              }}
              data-testid="tableVisualizationContainer">
              <TableVisualization
                table={table}
                showLaneLabels={showLaneLabels}
                labelDisplayConfiguration={labelDisplayConfiguration}
              />
            </div>
          ))}

          {canShowChart && !isCompareMode && (
            <div data-testid="chartContainer" className="flexColumnContainer flexFill trendChart sq-chart">
              <Chart
                breaks={breaks}
                capsuleTimeOffsets={capsuleTimeOffsets}
                clearPointerValues={sqTrendActions.clearPointerValues}
                isCapsuleTime={isCapsuleTime}
                isDimmed={isDimmed}
                isPickingMode={isPickingMode}
                items={chartItems}
                pickSelectedRegion={() => pickSelectedRegion({ sqTrendActions })}
                removeSelectedRegion={sqTrendActions.removeSelectedRegion}
                selectItems={(item, items, e) => selectItems({ item, items, e, sqTrendActions })}
                selectedRegion={selectedRegion}
                selectedTimezone={selectedTimezone}
                setPointerValues={sqTrendActions.setPointerValues}
                setSelectedRegion={sqTrendActions.setSelectedRegion}
                setYExtremes={(extremes) => setYExtremes(extremes)}
                summaryLabel={summaryLabel}
                trendStart={trendStart.valueOf()}
                trendEnd={trendEnd.valueOf()}
                view={view}
              />
            </div>
          )}

          {isCompareMode && canShowChart && (
            <div data-testid="compareViewContainer" className="flexFill flexRowContainer trendChart">
              <CompareViewWrapper items={chartItems} labelDisplayConfiguration={labelDisplayConfiguration} />
            </div>
          )}
        </div>
      )}

      <DisplayRangeSelector
        displayRange={displayRange}
        timezone={selectedTimezone}
        rangeEditingEnabled={rangeEditingEnabled}
        label={summaryLabel}
      />

      {!isPresentationMode && (
        <InvestigateRangeSelector
          displayRangeEditingEnabled={rangeEditingEnabled}
          investigateRangeEditingEnabled={rangeEditingEnabled}
        />
      )}

      {!isPresentationMode && <BottomPanels parentHeight={trendViewerRef.current?.clientHeight ?? 0} />}
    </div>
  );
};

export const sqTrendViewer = angularComponent(trendViewerBindings, TrendViewer);
