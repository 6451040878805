// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';

import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { ReportActions } from '@/reportEditor/report.actions';
import { Icon } from '@/hybrid/core/Icon.atom';
import _ from 'lodash';
import { formatTime } from '@/hybrid/datetime/dateTime.utilities';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { EditableText } from '@/hybrid/core/EditableText.atom';
import { FormulaService } from '@/services/formula.service';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { ASSET_PATH_SEPARATOR } from '@/main/app.constants';
import { CopyButtonGivenText } from '@/hybrid/core/CopyButtonGivenText.atom';
import { DateRange, ReportClickActions } from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';
import { sqReportStore } from '@/core/core.stores';

const reportConfigDateRangeBindings = bindingsDefinition({
  range: prop<DateRange>(),
  timezone: prop<string>(),
  openDateSelector: prop<(dateRange: DateRange, isCapsuleSelect: boolean, checkFixed: boolean) => void>(),
  isAutoUpdate: prop<boolean>(),
  viewOnly: prop.optional<boolean>(),
  areDateRangesReadOnly: prop<boolean>(),
  stepDateRangeToEnd: prop.optional<(dateRange: DateRange) => void>(),
  actionWrapper: prop<(action: Function, actionType: ReportClickActions) => (...actionArgs) => any>(),
  reClickAction: prop<ReportClickActions>(),
  openBulkEditWithGivenRange: prop<(dateRange: DateRange) => void>(),
  reClickArg: prop.optional<any>(),
  sqReportActions: injected<ReportActions>(),
  sqReportContent: injected<ReportContentService>(),
  sqFormula: injected<FormulaService>(),
});

export const ReportConfigDateRange: SeeqComponent<typeof reportConfigDateRangeBindings> = (props) => {
  const { sqReportActions, sqReportContent, sqFormula } = useInjectedBindings(reportConfigDateRangeBindings);

  const {
    range,
    timezone,
    openDateSelector,
    isAutoUpdate,
    stepDateRangeToEnd,
    areDateRangesReadOnly,
    reClickAction,
    actionWrapper,
    viewOnly = false,
    reClickArg,
    openBulkEditWithGivenRange,
  } = props;

  const [showCopyId, setShowCopyId] = useState(false);
  const showStepToEnd = !isAutoUpdate && !_.isUndefined(stepDateRangeToEnd);
  const dateRangeToContentMap = useFluxPath(sqReportStore, () => sqReportStore.dateRangeToContentMap);

  const { t } = useTranslation();

  const formatDateRange = (dateRange: DateRange) => {
    return `${formatTime(dateRange.range.start, {
      name: timezone,
    })} - ${formatTime(dateRange.range.end, { name: timezone })}`;
  };

  const isDateRangeUsed = (dateRangeId: string) => _.get(dateRangeToContentMap, dateRangeId, []).length > 0;

  const refreshContentUsingDate = (dateRange: DateRange) => {
    doTrack('Topic', 'Date Range Tool', 'refresh content');
    if (!areDateRangesReadOnly) {
      sqReportContent.forceRefreshContentUsingDate(dateRange.id);
    }
  };

  const onClickRefresh = () => {
    actionWrapper(refreshContentUsingDate, ReportClickActions.Refresh)(range);
  };

  const onClickStep = () => actionWrapper(stepDateRangeToEnd, ReportClickActions.Step)(range);

  const onClickWarning = () => {
    actionWrapper(openBulkEdit, ReportClickActions.Warning)(range);
  };

  const openRangeSelector = (isCapsuleSelectMode) =>
    isCapsuleSelectMode ? openSelectorInCapsuleMode() : openSelectorFullEditMode();
  const openSelectorFullEditMode = () =>
    actionWrapper(openDateSelector, ReportClickActions.Edit)(range, false, !isAutoUpdate);
  const openSelectorInCapsuleMode = () =>
    actionWrapper(openDateSelector, ReportClickActions.Edit)(range, true, !isAutoUpdate);

  const openBulkEdit = (dateRange: DateRange) => {
    doTrack('Topic', 'Date Range Tool', 'edit - warning icon');
    if (!areDateRangesReadOnly) {
      openBulkEditWithGivenRange(dateRange);
    }
  };

  const onReClick = (action: ReportClickActions) =>
    ({
      [ReportClickActions.None]: _.noop,
      [ReportClickActions.Step]: onClickStep,
      [ReportClickActions.Edit]: openRangeSelector,
      [ReportClickActions.Refresh]: onClickRefresh,
      [ReportClickActions.Warning]: onClickWarning,
      [ReportClickActions.ChangeName]: onEditName,
    }[action](getReClickArg(action)));

  const getReClickArg = (action) => {
    if (action === ReportClickActions.ChangeName) {
      return range.name;
    } else if (action === ReportClickActions.Edit) {
      return reClickArg;
    } else {
      return null;
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      onReClick(reClickAction);
    }
    // Returning a cleanup function here that just disables the effect helps address "React state update on an
    // unmounted component" error. See https://dev.to/otamnitram/react-useeffect-cleanup-how-and-when-to-use-it-2hbm
    // for more details.
    return () => {
      mounted = false;
    };
  }, [reClickAction]);

  const [name, setName] = useState(range.name);
  const editName = (dateRange, name) => {
    // Change rendered name early to prevent visual delay
    setName(name);
    sqReportActions.saveDateRange({ ...dateRange, name });
  };

  useEffect(() => {
    setName(range.name);
  }, [range.name]);

  const onEditName = (name) => {
    actionWrapper(editName, ReportClickActions.ChangeName)(range, name);
  };

  const [conditionAsset, setConditionAsset] = useState(null);
  useEffect(() => {
    if (range.condition?.id) {
      sqFormula
        .getDependencies({ id: range.condition.id })
        .then(({ assets }) => setConditionAsset(assets.length === 1 ? assets[0] : null));
    } else {
      setConditionAsset(null);
    }
  }, [range.condition?.id]);

  const assetPathElement = conditionAsset ? (
    <div>
      <HoverTooltip text={`${conditionAsset.formattedName}${ASSET_PATH_SEPARATOR}${range.condition.name}`}>
        <span>
          <Icon icon="fa-cube" extraClassNames="mr3" />
          {conditionAsset.name}
          {ASSET_PATH_SEPARATOR}
          {range.condition.name}
        </span>
      </HoverTooltip>
    </div>
  ) : null;

  const displayThisRange = viewOnly ? isDateRangeUsed(range.id) : true;
  return displayThisRange ? (
    <div className="flexRowContainer mb5" data-testid="dateRange" data-idtocopy={range.id}>
      <div className="flexColumnContainer mr5 pt5">
        <span
          className="flexFill flexColumnContainer"
          onMouseMove={() => setShowCopyId(true)}
          onMouseLeave={() => setShowCopyId(false)}>
          <EditableText
            testId={`${range.id}_name_test_id`}
            value={name}
            onUpdate={onEditName}
            inputClasses="semi-bold flexFill"
            textClasses="semi-bold flexFill nowrap"
          />
          {!viewOnly && showCopyId && (
            <CopyButtonGivenText
              testId={`dateRangeLinkIcon_${range.id}`}
              id={`dateRangeLinkIcon_${range.id}`}
              tooltip="COPY_ID.TO_CLIPBOARD"
              textToCopy={range.id}
              asType="icon"
              iconClass="fc-copy"
              extraClassNames="ml2 fa-sm"
              notifyMessage="COPY_ID.SUCCESS"
              onCopyFinish={() => doTrack('Topic', 'Date Range Tool', 'copy id')}
            />
          )}
        </span>

        {range.condition?.isRedacted && <span>{t('ACCESS_CONTROL.REDACTED')}</span>}
        {!range.condition.isRedacted && (
          <a className="ml15 link-no-focus link-no-underline flexColumnContainer flexCenter cursorPointer">
            <div
              className="flexFill small text-italic text-right text-underline"
              onClick={() => {
                doTrack('Topic', 'Date Range Tool', 'edit - condition link');
                openSelectorInCapsuleMode();
              }}
              data-testid={`dateRangeConditionName_${range.id}`}>
              {range.condition.name}
            </div>
            {range.condition.id && (
              <Icon
                icon="fc-capsule"
                extraClassNames="ml5"
                testId={`dateRangeCapsuleIcon_${range.id}`}
                onClick={() => {
                  doTrack('Topic', 'Date Range Tool', 'edit - capsule icon');
                  openSelectorInCapsuleMode();
                }}
              />
            )}
            {isDateRangeUsed(range.id) && (
              <div data-testid={`dateRangeRefreshIcon_${range.id}`} onClick={onClickRefresh}>
                <Icon
                  icon="fc-redo fa-fw fa-sm"
                  tooltip="REPORT.CONFIG.REFRESH_CONTENT_FOR_DATE_RANGE"
                  extraClassNames="cursorPointer ml5 mt2"
                />
              </div>
            )}
            {!isDateRangeUsed(range.id) && (
              <div data-testid={`dateRangeWarningIcon_${range.id}`} onClick={onClickWarning}>
                <Icon icon="fa-warning" tooltip="REPORT.CONFIG.DATE_UNUSED" extraClassNames="ml5 mt1" type="warning" />
              </div>
            )}
            {showStepToEnd && !range.condition.isCapsuleFromTable && (
              <div data-testid={`stepFixedToNowIcon_${range.id}`} onClick={onClickStep}>
                <Icon icon="fc-arrow-right-right" tooltip="REPORT.CONFIG.STEP_TO_END" extraClassNames="ml5" />
              </div>
            )}
            {showStepToEnd && range.condition.isCapsuleFromTable && (
              <div data-testid={`stepFixedToNowIconDisabled_${range.id}`}>
                <Icon
                  icon="fc-arrow-right-right disabledLook"
                  tooltip="REPORT.CONFIG.STEP_TO_END_DISABLED"
                  extraClassNames="ml5"
                />
              </div>
            )}
            {!showStepToEnd && <Icon icon="fa-fw" extraClassNames="ml2" />}
            <div>
              <Icon
                icon="fa-fw fa-edit"
                extraClassNames="ml6 mr2 mt2"
                testId={`dateRangeEditIcon_${range.id}`}
                onClick={() => {
                  doTrack('Topic', 'Date Range Tool', 'edit - edit icon');
                  openSelectorFullEditMode();
                }}
              />
            </div>
          </a>
        )}
      </div>
      <div data-testid="dateRangeSubtext">
        {(!isAutoUpdate || !range.auto.noCapsuleFound) && <span>{formatDateRange(range)}</span>}
        {isAutoUpdate && range.auto.noCapsuleFound && <span>{t('REPORT.CONTENT.NO_CAPSULE_FOUND')}</span>}
      </div>
      {assetPathElement}
    </div>
  ) : null;
};
