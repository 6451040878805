// @ts-strict-ignore
import { useInjector } from '@/hybrid/core/hooks/useInjector.hook';

/**
 * This hook enables getting a handle on an angular service without needing to pass that
 * service in as a parameter to a component.
 *
 * This should be used in moderation, declaring injectables as bindings clearly shows the
 * services used by components where as using this hook can obscure the services used by
 * components. As a general rule, this hook is best used for built-in angular services or
 * very common services that including in props would seem verbose.
 */
export function useInjectorGet<T = unknown>(name: string): T {
  const $injector = useInjector();
  return $injector.get<T>(name);
}
