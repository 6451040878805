import angular from 'angular';

const dependencies = ['Sq.Core', 'Sq.Worksheets', 'Sq.Worksteps'];

/**
 * @module The workbook module manages available worksheets as
 * well as the currently active worksheet for the currently active
 * workbook.
 */
angular.module('Sq.Workbook', dependencies);
