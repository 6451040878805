import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { IconWithPopoverTooltip } from '@/hybrid/core/IconWithPopoverTooltip.molecule';

const reportContentVisualizationToggleBindings = bindingsDefinition({
  isReact: prop<boolean>(),
  setIsReact: prop<(react: boolean) => void>(),
});

/**
 * Provides checkboxes to switch content between different visualization types
 */
export const ReportContentVisualizationToggle: SeeqComponent<typeof reportContentVisualizationToggleBindings> = (
  props,
) => {
  const { isReact, setIsReact } = props;
  const { t } = useTranslation();

  const tooltip = (
    <div>
      {t('REPORT.CONTENT.INTERACTIVE_TOOLTIP')}
      <ul>
        <li>{t('REPORT.CONTENT.INTERACTIVE_TOOLTIP_BULLET_1')}</li>
        <li>{t('REPORT.CONTENT.INTERACTIVE_TOOLTIP_BULLET_2')}</li>
        <li>{t('REPORT.CONTENT.INTERACTIVE_TOOLTIP_BULLET_3')}</li>
      </ul>
    </div>
  );

  return (
    <div className="flexColumnContainer pl10 pr10 pt10">
      <div className="flexColumnContainer">
        <label>{t('REPORT.CONTENT.INTERACTIVE')}</label>
        <IconWithPopoverTooltip content={tooltip} />
      </div>
      <div className="tn-group flexColumnContainer flexSpaceBetween ml10">
        <Checkbox id="reactOff" isChecked={!isReact} label="NO" onChange={() => setIsReact(false)} type="radio" />
        <Checkbox
          classes="ml15"
          id="reactOn"
          isChecked={isReact}
          label="YES"
          onChange={() => setIsReact(true)}
          type="radio"
        />
      </div>
    </div>
  );
};
