// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { CELL_TYPES, Table, TableColumn } from '@/hybrid/core/Table.atom';
import { CapsuleV1, sqConditionsApi } from '@/sdk';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Icon } from '@/hybrid/core/Icon.atom';
import { formatDuration, formatTime, getMoment } from '@/hybrid/datetime/dateTime.utilities';
import { ItemPropertiesSelectorButton } from '@/hybrid/utilities/ItemPropertiesSelectorButton.molecule';
import { SuggestedPropertiesMode } from '@/hybrid/utilities/CustomPropertySelector.atom';
import { Capsule } from '../../../../../../../../plugin/sdk/seeq';
import { useTranslation } from 'react-i18next';
import { cancelGroup } from '@/hybrid/requests/pendingRequests.utilities';
import { sqWorksheetStore } from '@/core/core.stores';

interface CapsuleTableProps {
  condition: Capsule;
  start: string;
  end: string;
  savedColumns?: string[];
  savedSortAsc?: boolean;
  savedSortBy?: string;
  savedStart: number;
  savedEnd: number;
  updateDateRange: (capsule, columns, sortBy, sortAsc) => void;
}

export enum CapsuleSort {
  Start = 'start',
  End = 'end',
  Duration = 'duration',
}

export const CapsuleTable: React.FunctionComponent<CapsuleTableProps> = (props) => {
  const {
    condition,
    start,
    end,
    updateDateRange,
    savedColumns = [],
    savedSortBy = CapsuleSort.Start,
    savedSortAsc = true,
    savedStart,
    savedEnd,
  } = props;

  const [selectedId, setSelectedId] = useState('');
  const [capsules, setCapsules] = useState([]);
  const [columnKeys, setColumnKeys] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [sort, setSort] = useState({
    sortBy: savedSortBy,
    sortAsc: savedSortAsc,
  });
  const hasSavedColumns = !_.isEmpty(savedColumns);

  const cancellationGroup = 'capsuleTable';
  const { t } = useTranslation();

  const renderInitialColumn = (column, columnName) => {
    const renderingColumn = columnName.toLowerCase();
    let formattedColumn;
    if (renderingColumn === 'duration') {
      formattedColumn = formatDuration(column[columnName.toLowerCase()]);
    } else {
      formattedColumn = formatTime(column[columnName.toLowerCase()], sqWorksheetStore.timezone);
    }
    return renderColumn(formattedColumn, columnName);
  };

  const initialColumns: TableColumn[] = [
    {
      header: '',
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 30, maxWidth: 30 },
    },
    {
      accessor: 'start',
      header: t('START'),
      filterable: false,
      sortable: true,
      cellStyle: { minWidth: 130, maxWidth: 150 },
      cellRenderFunction: renderInitialColumn,
    },
    {
      accessor: 'end',
      header: t('END'),
      sortable: true,
      filterable: false,
      cellStyle: { minWidth: 130, maxWidth: 150 },
      cellRenderFunction: renderInitialColumn,
    },
    {
      accessor: 'duration',
      header: t('DURATION'),
      sortable: true,
      filterable: false,
      cellStyle: { minWidth: 80, maxWidth: 80 },
      cellRenderFunction: renderInitialColumn,
    },
  ];

  const getPropertyValue = (column, propertyName) => {
    const propertyValue = _.chain(column.properties).filter({ name: propertyName }).map('value').value();
    return renderColumn(propertyValue, propertyName);
  };

  const propertyColumns = _.chain(columnKeys)
    .reject((key) => _.some(initialColumns, { accessor: key }))
    .map((key) => ({
      accessor: key,
      header: t(key),
      key,
      title: t(key),
      shortTitle: t(key),
      sortable: true,
      filterable: false,
      cellStyle: { minWidth: 80, maxWidth: 130 },
      cellRenderFunction: getPropertyValue,
    }))
    .value();

  const extraColumns = _.chain(initialColumns)
    .reject({ accessor: 'id' })
    .map((column) => ({ key: column.accessor, title: column.header }))
    .value();

  useEffect(() => {
    initializeColumns();
  }, []);

  useEffect(() => {
    setCapsules(sortCapsules(capsules, sort.sortBy, sort.sortAsc));
  }, [sort]);

  useEffect(() => {
    fetchCapsules();
  }, [start, end, condition?.id]);

  const renderColumn = (formattedColumn, columnName) => {
    return <div data-testid={`column-${columnName}`}>{formattedColumn}</div>;
  };

  // If creating new date range sets default initial columns
  // If editing an existing date range, sets initial columns and property columns as saved
  const initializeColumns = () => {
    if (hasSavedColumns) {
      setColumnKeys(savedColumns);
    } else {
      setColumnKeys(_.map(initialColumns, 'accessor'));
    }
  };

  // fetches capsules and filters out capsules with missing or invalid start/end
  const fetchCapsules = () => {
    setTableLoading(true);
    cancelGroup(cancellationGroup)
      .then(() =>
        sqConditionsApi
          .getCapsules(
            {
              id: condition.id,
              start: moment.utc(start).toISOString(),
              end: moment.utc(end).toISOString(),
            },
            { cancellationGroup },
          )
          .then(({ data: { capsules } }) => {
            setValidCapsules(capsules);
          }),
      )
      .finally(() => {
        setTableLoading(false);
      });
  };

  // Rejects invalid capsules, adds duration attribute, remembers saved capsule when editing
  const setValidCapsules = (capsules) => {
    const isInvalid = (property) => _.isNil(property) || _.endsWith(property, '?');
    const filteredCapsules = _.chain(capsules)
      .reject((capsule) => isInvalid(capsule.start) || isInvalid(capsule.end))
      .map((capsule) => ({
        ...capsule,
        duration: getMoment(capsule.end).valueOf() - getMoment(capsule.start).valueOf(),
      }))
      .value();
    if (hasSavedColumns) {
      setSelectedId(
        _.find(
          filteredCapsules,
          (capsule) =>
            getMoment(capsule.start).valueOf() === savedStart && getMoment(capsule.end).valueOf() === savedEnd,
        )?.id,
      );
    }
    setCapsules(sortCapsules(filteredCapsules, sort.sortBy, sort.sortAsc));
  };

  const selectCallback = (item: CapsuleV1) => {
    updateDateRange(item, columnKeys, sort.sortBy, sort.sortAsc);
    setSelectedId(item.id);
  };

  const toggleColumn = (column) => {
    if (isColumnEnabled(column)) {
      setColumnKeys(_.without(columnKeys, column.key));
    } else {
      addColumn(column.key);
    }
  };

  const addColumn = (key) => {
    setColumnKeys(columnKeys.concat(key));
  };

  const sortClick = (sortBy, sortAscArg) => {
    setSort({ sortBy, sortAsc: !sortAscArg });
  };

  const sortCapsules = (capsules, sortBy, sortAsc) => {
    let sortArray;
    if (_.includes(['start', 'end', 'duration'], sort.sortBy)) {
      sortArray = [sort.sortBy, CapsuleSort];
    } else {
      sortArray = [(capsule: CapsuleV1) => _.find(capsule.properties, { name: sort.sortBy })?.value];
    }
    return _.orderBy(capsules, sortArray, sortAsc ? 'asc' : 'desc');
  };

  const isColumnEnabled = (column) => _.includes(columnKeys, column.key);

  return (
    <div className="flexRowContainer max-height-400 mb5">
      <div className="flexColumnContainer flexSpaceBetween flexAlignStart mt10">
        <label>{t('REPORT.CONFIG.SELECT_THE_CAPSULE')}</label>
        <div data-testid="addColumns">
          <ItemPropertiesSelectorButton
            itemIds={[condition.id]}
            suggestedPropertiesMode={SuggestedPropertiesMode.Capsules}
            extraColumns={extraColumns}
            propertyColumns={propertyColumns}
            isColumnEnabled={isColumnEnabled}
            toggleColumn={toggleColumn}
            addPropertyColumn={({ propertyName }) => addColumn(propertyName)}
            iconClasses="cursorPointer fa-lg sq-text-primary"
          />
        </div>
      </div>
      <div className="tableWrapper">
        <Table
          onRowClickCallback={selectCallback}
          onRowSelectCallback={selectCallback}
          isRowSelectRadio={true}
          selectedIds={[selectedId]}
          sortProperty={sort.sortBy}
          sortAscending={sort.sortAsc}
          sortTableCallback={sortClick}
          columns={_.chain(initialColumns)
            .reject((column) => !_.includes(columnKeys, column.accessor))
            .concat(propertyColumns)
            .value()}
          items={capsules}
        />
        {_.isEmpty(capsules) && !tableLoading && (
          <div className="flexColumnContainer flexCenter">
            <div className="help-block sq-text-danger small">{t('REPORT.CONFIG.CAPSULES_VALIDATE')}</div>
          </div>
        )}
        {tableLoading && (
          <div className="flexColumnContainer flexCenter pt50 pb50">
            <Icon icon="fa-spinner fa-pulse fa-5x" extraClassNames="sq-text-primary" />
          </div>
        )}
      </div>
    </div>
  );
};
