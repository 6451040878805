// @ts-strict-ignore
import angular from 'angular';
import _ from 'lodash';
import { sqAdministration } from '@/hybrid/administration/Administration.page';
import { sqHomeScreen } from '@/hybrid/homescreen/HomeScreen.page';
import { sqAddToDisplayPane } from '@/hybrid/workbooks/AddToDisplayPane.molecule';
import { sqColorPicker } from '@/hybrid/workbooks/ColorPicker.organism';
import { sqSelectItem } from '@/hybrid/core/SelectItem.organism';
import { sqSearchTitle } from '@/hybrid/workbooks/SearchTitle.organism';
import { sqReportContentSize } from '@/hybrid/reportEditor/components/reportContentModal/shared/ReportContentSize.molecule';
import { sqReportComments } from '@/hybrid/reportComments/ReportComments.molecule';
import { sqSignalSmoothing } from '@/hybrid/tools/signalSmoothing/SignalSmoothing.organism';
import { sqReportContentBulkEditModal } from '@/hybrid/reportEditor/components/reportContentModal/bulkEdit/ReportContentBulkEditModal.molecule';
import { sqConfigureAutoUpdateModal } from '@/hybrid/reportConfig/components/dateRanges/autoUpdateModal/ConfigureAutoUpdateModal.organism';
import { sqValueSearch } from '@/hybrid/tools/ValueSearch.organism';
import { sqPathComponents } from '@/hybrid/utilities/PathComponents.atom';
import { sqDigitalFilter } from '@/hybrid/tools/digitalFilter/DigitalFilter.organism';
import { sqItemAclModal } from '@/hybrid/accessControl/ItemAclModal.molecule';
import { sqWorksheetsPane } from '@/hybrid/worksheets/WorksheetsPane.organism';
import { sqEditUserProfile } from '@/hybrid/header/EditUserProfile.atom';
import { sqPeriodicCondition } from '@/hybrid/tools/periodicCondition/PeriodicCondition.organism';
import { sqOrganizerTourModal } from '@/hybrid/tour/OrganizerTourModal.organism';
import { sqWorkbenchTourModal } from '@/hybrid/tour/WorkbenchTourModal.organism';
import { sqCompositeSearch } from '@/hybrid/tools/compositeSearch/CompositeSearch.organism';
import { sqProfileSearch } from '@/hybrid/tools/profileSearch/ProfileSearch.organism';
import { sqFroalaEditor } from '@/hybrid/annotation/FroalaEditor.organism';
import { sqReportConfig } from '@/hybrid/reportConfig/ReportConfig.organism';
import { sqEditor } from '@/hybrid/annotation/Editor.organism';
import { sqInsertContentDropdown } from '@/hybrid/annotation/InsertContentDropdown.molecule';
import { sqManualSignal } from '@/hybrid/tools/manualSignal/ManualSignal.organism';
import { sqImportDatafile } from '@/hybrid/tools/importDatafile/ImportDatafile.organism';
import { sqJournalComment } from '@/hybrid/annotation/JournalComment.molecule';
import { sqAuditTrail } from '@/hybrid/auditTrail/AuditTrail.page';
import { sqReportContentModal } from '@/hybrid/reportEditor/components/reportContentModal/ReportContentModal.molecule';
import { sqReferencePanel } from '@/hybrid/tools/referencePanel/ReferencePanel.organism';
import { sqCapsuleInput } from '@/hybrid/investigate/customCondition/CapsuleInput.molecule';
import { sqDatasourcesTab } from '@/hybrid/administration/datasources/DatasourcesTab.page';
import { sqDatasourcesStatus } from '@/hybrid/footer/DatasourcesStatus.organism';
import { sqAnnotationIcon } from '@/hybrid/annotation/AnnotationIcon.atom';
import { sqLogTracker } from '@/hybrid/logTracker/LogTracker.page';
import { sqThresholdMetric } from '@/hybrid/tools/thresholdMetric/ThresholdMetric.organism';
import { sqAutoUpdate } from '@/hybrid/trend/AutoUpdate.molecule';
import { sqDataStatusIcon } from '@/hybrid/trend/panels/detailsPanel/DataStatusIcon.atom';
import { sqSignalFromCondition } from '@/hybrid/tools/signalFromCondition/SignalFromCondition.organism';
import { sqFrequencyAnalysis } from '@/hybrid/tools/frequencyAnalysis/FrequencyAnalysis.organism';
import { sqHeader } from '@/hybrid/header/Header.atom';
import { sqPrediction } from '@/hybrid/tools/prediction/Prediction.organism';
import { sqTreemapChart } from '@/hybrid/treemap/TreemapChart.atom';
import { sqFormulaTool } from '@/hybrid/tools/formula/FormulaTool.organism';
import { sqManualCondition } from '@/hybrid/tools/manualCondition/ManualCondition.organism';
import { sqExportExcel } from '@/hybrid/tools/exportExcel/ExportExcel.organism';
import { sqExportPiVision } from '@/hybrid/tools/exportPivision/ExportPIVision.organism';
import { sqDerivedDataTree } from '@/hybrid/trend/panels/derivedDataTree/DerivedDataTree.organism';
import { sqJournalEntry } from '@/hybrid/annotation/JournalEntry.organism';
import { sqGetFeedback } from '@/hybrid/getFeedback/GetFeedback.atom';
import { sqExportOData } from '@/hybrid/tools/exportOData/ExportOData.organism';
import { sqTimebar } from '@/hybrid/trend/Timebar.organism';
import { sqFooter } from '@/hybrid/footer/Footer.molecule';
import { sqWorkbookAccessWarningModal } from '@/hybrid/workbooks/WorkbookAccessWarningModal.molecule';
import { sqSystemWarning } from '@/hybrid/systemWarning/SystemWarning.organism';
import { sqHistogram } from '@/hybrid/tools/histogram/Histogram.organism';
import { sqExportPowerpoint } from '@/hybrid/tools/exportPowerpoint/ExportPowerpoint.organism';
import { sqXyPlot } from '@/hybrid/scatterPlot/XYPlot.page';
import { sqToolSelectionView } from '@/hybrid/toolSelection/ToolSelectionView.organism';
import { sqLicenseManagement } from '@/hybrid/licenseManagement/License.page';
import { sqPanelTabs } from '@/hybrid/worksheets/PanelTabs.molecule';
import { sqLicenseExpirationWarning } from '@/hybrid/licenseManagement/LicenseExpirationWarning';
import { sqReportEditor } from '@/hybrid/reportEditor/ReportEditor.organism';
import { sqBottomPanels } from '@/hybrid/trend/panels/bottomPanels/BottomPanels.organism';
import { sqLogin } from '@/hybrid/main/auth/Login.page';
import { sqUnauthorized } from '@/hybrid/main/Unauthorized.page';
import { sqReportTemplateThumbnail } from '@/templates/ReportTemplateThumbnail.page';
import { sqJournalPanel } from '@/hybrid/annotation/JournalPanel.organism';
import { sqTableBuilderView } from '@/hybrid/tableBuilder/TableBuilderView.page';
import { sqTrendViewer } from '@/hybrid/trend/trendViewer/TrendViewer.organism';
import { sqLoadingBar } from '@/hybrid/main/LoadingBar.molecule';
import { sqDisplayPanePluginHost } from '@/hybrid/plugin/DisplayPanePluginHost.molecule';
import { sqLoadError } from '@/hybrid/main/LoadError.page';
import { sqRedactionBanner } from '@/hybrid/worksheet/RedactionBanner.atom';
import { sqTreemap } from '@/hybrid/treemap/Treemap.molecule';
import { sqWorksheetPage } from '@/hybrid/worksheet/WorksheetPage.organism';
import { sqBuilding } from '@/hybrid/builder/Builder.molecule';
import { sqHomeScreenAddOnHost } from './homescreen/HomeScreenAddOnHost.molecule';
import { sqJournalLink } from '@/hybrid/annotation/JournalLink.molecule';

// Provide all react components that should be accessible from angular templates here
// Use angularComponent to create an angular definition from a React definition
const exposedComponents: { [angularName: string]: ng.IComponentOptions } = {
  sqAdministration,
  sqHomeScreen,
  sqAddToDisplayPane,
  sqColorPicker,
  sqSelectItem,
  sqSearchTitle,
  sqSignalSmoothing,
  sqReportContentSize,
  sqReportContentBulkEditModal,
  sqReportComments,
  sqXyPlot,
  sqConfigureAutoUpdateModal,
  sqValueSearch,
  sqOrganizerTourModal,
  sqWorkbenchTourModal,
  sqPathComponents,
  sqManualSignal,
  sqDigitalFilter,
  sqItemAclModal,
  sqFormulaTool,
  sqCompositeSearch,
  sqInsertContentDropdown,
  sqWorksheetsPane,
  sqPeriodicCondition,
  sqEditUserProfile,
  sqFroalaEditor,
  sqReportConfig,
  sqJournalComment,
  sqImportDatafile,
  sqPanelTabs,
  sqReportContentModal,
  sqEditor,
  sqAuditTrail,
  sqReferencePanel,
  sqCapsuleInput,
  sqAnnotationIcon,
  sqDatasourcesTab,
  sqDatasourcesStatus,
  sqThresholdMetric,
  sqProfileSearch,
  sqLogTracker,
  sqAutoUpdate,
  sqSignalFromCondition,
  sqFrequencyAnalysis,
  sqPrediction,
  sqJournalEntry,
  sqTreemapChart,
  sqDataStatusIcon,
  sqManualCondition,
  sqExportExcel,
  sqExportPiVision,
  sqHeader,
  sqDerivedDataTree,
  sqTimebar,
  sqExportOData,
  sqGetFeedback,
  sqFooter,
  sqSystemWarning,
  sqHistogram,
  sqExportPowerpoint,
  sqToolSelectionView,
  sqLicenseManagement,
  sqLicenseExpirationWarning,
  sqReportEditor,
  sqBottomPanels,
  sqLogin,
  sqUnauthorized,
  sqWorkbookAccessWarningModal,
  sqJournalPanel,
  sqTableBuilderView,
  sqTrendViewer,
  sqLoadingBar,
  sqDisplayPanePluginHost,
  sqLoadError,
  sqReportTemplateThumbnail,
  sqRedactionBanner,
  sqTreemap,
  sqWorksheetPage,
  sqBuilding,
  sqHomeScreenAddOnHost,
  sqJournalLink,
};

const angularDependencies = [];

export default _.reduce(
  exposedComponents,
  (module, component, angularName) => module.component(angularName, component),
  angular.module('Sq.ReactComponents', angularDependencies),
);
