// @ts-strict-ignore
import { DEFAULT_AXIS_LABEL_COLOR, DEFAULT_AXIS_LINE_COLOR } from '@/hybrid/trend/trendViewer/trendViewer.constants';
import { getLaneDisplayText } from '@/hybrid/utilities/label.utilities';
import { WHITE_LANE_COLOR } from '@/chart/chart.module';
import _ from 'lodash';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import tinycolor from 'tinycolor2';
import { formatNumber } from '@/hybrid/utilities/numberHelper.utilities';
import { sqTrendStore } from '@/core/core.stores';

/**
 * This function renders the "Lane Label".
 */
export const updateLabels = (chart: Highcharts.Chart, table): void => {
  if (chart) {
    chart.yAxis[0].removePlotBand('labelPlotBand');
    const extremes = chart.yAxis[0].getExtremes();
    const plotBand: Highcharts.AxisPlotBandsOptions = {
      id: 'labelPlotBand',
      from: extremes.min,
      to: extremes.max,
      color: WHITE_LANE_COLOR,
      label: {
        useHTML: true,
        text: getLaneDisplayText([table], null, chart.plotWidth, sqTrendStore),
        align: 'right',
        verticalAlign: 'top',
        x: -15,
        y: 12,
        style: {
          color: DEFAULT_AXIS_LABEL_COLOR,
        },
      },
    };

    chart.yAxis[0].addPlotBand(plotBand);
  }
};

type ToggleSeriesParams = {
  chart: Highcharts.Chart;
  table;
  /** the identifier of the column series (aka - the label) */
  seriesName: string;
  sqTrendTableActions;
};

/**
 * Toggles the visibility of the series. Called when item is clicked in the legend.
 */
export const toggleSeries = ({ chart, table, seriesName, sqTrendTableActions }: ToggleSeriesParams): void => {
  const series = _.find(chart.series, {
    name: seriesName,
  }) as Highcharts.Series;
  const hidden = series.visible; // toggle the current value
  sqTrendTableActions.updateBinVisibility(table.id, seriesName, hidden);
};

/**
 * Returns the factor to use to lighten the color of the "column". The factor is "capped" so that the color does
 * not end up too close to white. This will result in bars using the same color but users have the option to
 * assign a color so this is ok (at least or now).
 *
 * @param {Number} idx - The index of the series (column)
 *
 * @returns {Number} used as the factor to lighten the color.
 */
export const getLightnessFactor = (idx: number): number => {
  return Math.min(5 * idx, 50);
};

/**
 * Returns the color for a given series based on the provided index.
 *
 * @param {String} seriesName - the identifier of the column series (aka - the label)
 * @param {Number} idx - the index
 *
 * @returns {String} representing the color for the series.
 */
export const getSeriesColor = (table, seriesName: string, idx: number): string => {
  const defaultColor = tinycolor(table.color).lighten(getLightnessFactor(idx)).toString();

  return _.get(table, `binConfig.${seriesName}.color`, defaultColor);
};

/**
 * Extracts an Array of Objects from the Table data so it can be rendered.
 *
 * @returns {Object[]} that can be displayed by Highcharts.
 */
export const getSeries = (table) => {
  return _.map(table.data, (entry, idx) => ({
    id: entry.name,
    data: entry.data,
    color: getSeriesColor(table, entry.name, +idx),
    visible: !_.get(table.binConfig, `${entry.name}.hidden`),
    name: entry.name,
    stacking: table.stack ? 'normal' : null,
  }));
};

/**
 * Helper function that returns the display name for the yValue Signal.
 *
 * @returns {String} display name for the y-axis label.
 */
export const getYValueSignalName = (table): string => table.signalName;

/**
 * Formats the tooltip for single series Histograms.
 *
 * @returns {String} that shows the value.
 */
function formatTooltip(): string {
  const value = _.isFinite(this.y) ? formatNumber(this.y) : this.y;

  return `<span class="text-white">${value}</span>`;
}

/**
 * Formats the tooltip for multi-series Histograms.
 *
 * @returns {String} that shows the value as well as the bin they belong to.
 */
function formatAggregationTooltip(): string {
  const value = _.isFinite(this.y) ? formatNumber(this.y) : this.y;

  return `<span class="text-white">Bin ${this.series.name}</br></br>${value}</span>`;
}

export const getChartConfig = (table) => {
  const series = getSeries(table);
  const displayingSubgroups = series.length > 1;
  const chartConfig: any = {
    chart: { type: 'column', animation: false },
    title: { text: null },
    legend: {
      enabled: false,
    },
    yAxis: {
      gridLineWidth: 0,
      lineWidth: 1,
      title: {
        enabled: true,
        text: getYValueSignalName(table),
        style: {
          fontFamily: 'Source Sans Pro',
          fontWeight: 'normal',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          width: '100%',
        },
      },
      color: DEFAULT_AXIS_LINE_COLOR,
      visible: table.calculationType !== TREND_TOOLS.FFT_TABLE,
      plotBands: [
        {
          id: 'labelPlotBand',
          color: WHITE_LANE_COLOR,
        },
      ],
    },
    xAxis: {
      categories: table.categories,
      color: DEFAULT_AXIS_LINE_COLOR,
      labels: {
        style: {
          color: DEFAULT_AXIS_LABEL_COLOR,
        },
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: 'series',
      formatter: displayingSubgroups ? formatAggregationTooltip : formatTooltip,
      useHTML: true,
      padding: 0,
      shadow: false,
    },
    plotOptions: {
      series: {
        animation: false,
      },
      column: {
        stacking: table.stack ? 'normal' : null,
      },
    },
    series,
  };

  return chartConfig;
};

type CreateChartParams = {
  chart: Highcharts.Chart;
  table;
};

/**
 * Creates the chart that displays the table.
 */
export const createChart = ({ chart, table }: CreateChartParams): void => {
  const chartConfig = getChartConfig(table);

  if (chart) {
    chart.update(chartConfig, true);
    updateLabels(chart, table);
  }
};

/**
 * Removes all series from the chart.
 * Does not redraw.
 */
export const removeAllSeries = (chart: Highcharts.Chart): void => {
  while (chart.series.length > 0) {
    chart.series[0].remove(false, false);
  }
};

/**
 * Returns the series object for the given name.
 *
 * @param {String} name - the name of the series to find.
 *
 * @returns {Highcharts.Series} the Series object
 */
export const findChartSeries = (chart: Highcharts.Chart, name: string): Highcharts.Series => {
  if (chart) {
    return _.find(chart.series, { options: { name } });
  }
};

/**
 * Adjust the color and visibility of the Histogram Series.
 * Does not redraw.
 */
const adjustSeries = (chart: Highcharts.Chart, table) => {
  _.forEach(table.data, function (entry, idx) {
    const series = findChartSeries(chart, entry.name);
    if (!series) {
      return;
    }

    series.update({ type: undefined, color: getSeriesColor(table, entry.name, +idx) }, false);
    if (_.get(table.binConfig, `${entry.name}.hidden`)) {
      series.hide();
    } else {
      series.show();
    }
  });
};

/**
 * Updates the stacking properties of a series.
 * Does not redraw.
 *
 * @param {Boolean} doStack - whether to stack the columns or not.
 */
function updateStacking(chart: Highcharts.Chart, doStack: boolean): void {
  _.forEach(chart.series, (series) => {
    series.update({ stacking: doStack ? 'normal' : null } as any, false);
  });
}

type ProcessChangesParams = {
  chart: Highcharts.Chart;
  /** the "old" table */
  oldTable;
  /** the "new" updated table */
  newTable;
  sqTrendTableActions;
};

/**
 * Processes changes in the table data.
 * If the table data changes all the existing "series" is removed from the chart. Given the few data points that
 * make up a series for histograms this is more efficient than diffing & updating/deleting/adding every entry.
 */
export const processChanges = ({ chart, oldTable, newTable, sqTrendTableActions }: ProcessChangesParams): void => {
  let series;
  let redraw = false;

  if (!chart && !_.isEmpty(newTable.data)) {
    createChart({ chart, table: newTable });
  } else {
    if (chart) {
      if (oldTable?.data !== newTable.data) {
        removeAllSeries(chart);
        series = getSeries(newTable);

        chart.xAxis[0].setCategories(newTable.categories, false);

        _.forEach(series, function (singleSeries) {
          chart.addSeries(singleSeries, false, false);
        });

        redraw = true;
      }

      if (newTable.signalName && newTable.signalName !== oldTable?.signalName) {
        chart.yAxis[0].update({
          title: { text: newTable.signalName },
        });
      }

      if (oldTable?.color !== newTable.color || oldTable?.binConfig !== newTable.binConfig) {
        if (oldTable?.color !== newTable.color && !_.isEmpty(newTable.binConfig)) {
          return sqTrendTableActions.resetBins(newTable.id);
        }

        adjustSeries(chart, newTable);
        redraw = true;
      }

      if (oldTable?.stack !== newTable.stack) {
        updateStacking(chart, newTable.stack);
        redraw = true;
      }

      if (oldTable?.name !== newTable.name || oldTable?.outputUnits !== newTable.outputUnits) {
        updateLabels(chart, newTable);
        redraw = true;
      }

      if (redraw) {
        chart.redraw();
      }
    }
  }
};

type UpdateColorParams = {
  /** the identifier of the column series (aka - the label) */
  seriesName: string;
  /** the new color */
  color: string;
  table;
  sqTrendTableActions;
};

/**
 * Updates the color of the specified series (column)
 */
const updateColor = ({ seriesName, color, table, sqTrendTableActions }: UpdateColorParams): void => {
  sqTrendTableActions.updateBinColor(table.id, seriesName, color);
};

/**
 * Calls updateColor with an artificial delay. This is necessary to ensure that the color is properly reflecting
 * the newly selected color.
 */
export const updateColorDelay = (params: UpdateColorParams): void => {
  setTimeout(() => updateColor(params), 10);
};

/**
 * Restores the default coloration scheme.
 */
export const restoreDefaultColors = ({ table, sqTrendTableActions }): void => {
  sqTrendTableActions.resetBins(table.id);
};
