import React, { useCallback, useState } from 'react';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { BottomPanels } from '@/hybrid/trend/panels/bottomPanels/BottomPanels.organism';
import { TableBuilder } from '@/hybrid/tableBuilder/TableBuilder.molecule';
import { DisplayRangeSelector } from '@/hybrid/trend/DisplayRangeSelector.molecule';
import { sqDurationStore, sqWorksheetStore } from '@/core/core.stores';
import { isPresentationWorkbookMode } from '@/hybrid/utilities/utilities';

const tableBuilderViewBindings = bindingsDefinition({});

export const TableBuilderView: SeeqComponent<typeof tableBuilderViewBindings> = () => {
  const isPresentationMode = isPresentationWorkbookMode();
  const { displayRange } = useFlux(sqDurationStore);
  const { timezone } = useFlux(sqWorksheetStore);

  const [tableBuilderViewHeight, setTableBuilderViewHeight] = useState(0);

  const getParentHeight = useCallback((container: HTMLDivElement) => {
    if (container) {
      const { height } = container.getBoundingClientRect();
      setTableBuilderViewHeight(height);
    }
  }, []);

  return (
    <div
      ref={getParentHeight}
      className="flexFill flexRowContainer resizablePanelContainer tableBuilderView"
      data-testid="tableBuilderView">
      <TableBuilder />
      {!isPresentationMode && (
        <DisplayRangeSelector
          timezone={timezone}
          displayRange={displayRange}
          rangeEditingEnabled={true}
          investigateRangeCopyDisabled={true}
        />
      )}

      {!isPresentationMode && <BottomPanels parentHeight={tableBuilderViewHeight} hideCapsulesPanel={true} />}
    </div>
  );
};

export const sqTableBuilderView = angularComponent(tableBuilderViewBindings, TableBuilderView);
