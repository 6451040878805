// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { sqWorkbenchStore } from '@/core/core.stores';
import { WorkbenchActions } from '@/workbench/workbench.actions';
import { sqUsersApi, UserInputV1 } from '@/sdk';
import { updateUser as updateUserSqAdministrationAction } from '@/administration/administration.actions';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';

const editUserModalBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  user: prop<{
    id: string;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    password: string;
    isEnabled: string;
    isAdmin: boolean;
    isPasswordSettable: boolean;
  }>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
});

export const EditUserModal: SeeqComponent<typeof editUserModalBindings> = ({ onClose, user }) => {
  const { sqWorkbenchActions } = useInjectedBindings(editUserModalBindings);

  const { t } = useTranslation();

  const [editingPassword, setEditingPassword] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);

  const userStatusOptions = [
    { value: true, text: 'ADMIN.USER.ENABLED' },
    { value: false, text: 'ADMIN.USER.DISABLED' },
  ];

  const isUserSSO = !user.isPasswordSettable;

  const updateUser = (values) => {
    const { firstName, lastName, email, isAdmin, isEnabled, newPassword } = values;
    const userProps = {
      firstName,
      lastName,
      email,
      isAdmin,
      isEnabled: isEnabled.value,
      password: newPassword,
    };

    const name = `${_.trim(firstName)} ${_.trim(lastName)}`;

    setFormProcessing(true);
    _.assign(userProps, { name });

    /**
     * For SEEQ users we allow them to update their email, but their email is also their username. So, when they
     * update their email, then we have to also update their username (as email and username seem to be one to the
     * end user, at least right now). If a user is however an Active Directory user then they really just edit an email,
     * not their username.
     */
    if (!isUserSSO) {
      _.assign(userProps, { username: email });
    }

    if (editingPassword) {
      setFormProcessing(true); // We assume that authProvider is Seeq because only those users can change their
      // password.
      return sqUsersApi
        .updatePassword({ newPassword }, { id: user.id })
        .then(() => successToast({ messageKey: 'USER.PASSWORD_CHANGED' }))
        .then(onClose)
        .catch((error) => errorToast({ httpResponseOrError: error }))
        .finally(() => {
          setEditingPassword(false);
          setFormProcessing(false);
        });
    }

    return sqUsersApi
      .updateUser(userProps as UserInputV1, { id: user.id })
      .then(({ data }) => {
        updateUserSqAdministrationAction(data);

        if (data.id === sqWorkbenchStore.currentUser.id) {
          sqWorkbenchActions.setCurrentUser();
        }
      })
      .then(() => successToast({ messageKey: 'USER.UPDATED' }))
      .then(onClose)
      .catch((error) => errorToast({ httpResponseOrError: error, displayForbidden: true }))
      .finally(() => setFormProcessing(false));
  };

  const displayEditUser = () => setEditingPassword(false);

  const formDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'userProfile',
      includeIf: !editingPassword,
      components: [
        {
          component: 'LabelFormComponent',
          name: 'userNameLabel',
          value: 'USER.USERNAME',
          extraClassNames: 'mt8 text-bolder',
        },
        {
          component: 'LabelFormComponent',
          name: 'username',
          value: user.username,
          testId: 'username',
          extraClassNames: 'text-italic',
        },
        {
          component: 'FormControlFormComponent',
          name: 'firstName',
          label: 'USER.FIRST_NAME',
          value: user.firstName,
          onChange: _.noop,
          placeholder: 'USER.FIRST_NAME_PROMPT',
          size: 'md',
          testId: 'firstName',
        },
        {
          component: 'FormControlFormComponent',
          name: 'lastName',
          label: 'USER.LAST_NAME',
          value: user.lastName,
          onChange: _.noop,
          placeholder: 'USER.LAST_NAME_PROMPT',
          size: 'md',
          testId: 'lastName',
          required: true,
        },
        {
          component: 'FormControlFormComponent',
          name: 'email',
          // For non-SSO users make it clear that the email field is also the username
          label: isUserSSO ? 'USER.EMAIL' : 'USER.USERNAME_AND_EMAIL',
          // For non-SSO users with no email set (such as 'agent_api_key') show the username to make it clear
          // that editing the field would change the username
          value: isUserSSO ? user.email : user.email || user.username,
          onChange: _.noop,
          placeholder: 'USER.EMAIL_PROMPT',
          size: 'md',
          testId: 'email',
        },
        {
          component: 'IconSelectFormComponent',
          validation: _.isUndefined,
          name: 'isEnabled',
          label: 'USER.ENABLED',
          value: user ? user.isEnabled : true,
          onChange: _.noop,
          selectOptions: userStatusOptions,
          insideModal: true,
          skipStore: true,
          testId: 'isEnabled',
        },
        {
          component: 'CheckboxFormComponent',
          id: 'isAdmin',
          name: 'isAdmin',
          onChange: _.noop,
          skipStore: true,
          checkboxLabel: 'ADMIN.USER.ADMINISTRATOR',
          value: user.isAdmin,
        },
        {
          component: 'ClickableLinkFormComponent',
          name: 'resetPassword',
          includeIf: user.isPasswordSettable,
          onChange: _.noop,
          skipStore: true,
          value: 'ADMIN.USER.RESET_PASSWORD',
          icon: 'fa-lock',
          linkAction: () => setEditingPassword(true),
          extraClassNames: 'ml5',
        },
      ],
    },
    {
      component: 'PasswordGroupFormComponent',
      name: 'passwordFormGroup',
      includeIf: editingPassword,
      value: 'passwordFormGroup',
    },
  ];

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="editUserModal">
      <Modal.Header closeButton={true}>
        <h3>{t('ADMIN.USER.MODAL.TITLE.UPDATE')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="editUserModalBody">
          <SimpleSaveFormBuilder
            formDefinition={formDefinition}
            formSaveProcessing={formProcessing}
            submitFn={updateUser}
            closeFn={editingPassword ? displayEditUser : onClose}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
