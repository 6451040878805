import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { JournalOverview } from '@/hybrid/annotation/JournalOverview.molecule';
import { JournalEntry } from '@/hybrid/annotation/JournalEntry.organism';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { sqAnnotationStore } from '@/core/core.stores';

const journalPanelBindings = bindingsDefinition({
  extraClassNames: prop.optional<string>(),
});

export const JournalPanel: SeeqComponent<typeof journalPanelBindings> = (props) => {
  const { id } = useFlux(sqAnnotationStore);
  const { extraClassNames } = props;

  return (
    <div
      data-testid="journalPanel"
      className={classNames('journalPanel flexFillOverflow flexRowContainer', extraClassNames)}>
      {!id && <JournalOverview />}
      {id && <JournalEntry />}
    </div>
  );
};

export const sqJournalPanel = angularComponent(journalPanelBindings, JournalPanel);
