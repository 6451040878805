// @ts-strict-ignore
import { flux } from '@/core/flux.module';

/**
 * Set the duration to base this condition on.
 *
 * @param {String} duration - One of the keys from STANDARD_DURATIONS
 */
export function setDuration(duration) {
  flux.dispatch('PERIODIC_CONDITION_DURATION', { duration });
}

/**
 * Set the timezone for the condition.
 *
 * @param {Object} [timezone] - The selected timezone object. If not provided then timezone is unset.
 * @param {String} [timezone.name] - Name of the selected timezone.
 */
export function setTimezone({ name }) {
  flux.dispatch('PERIODIC_CONDITION_TIMEZONE', { timezone: name });
}

/**
 * Set the day the week duration duration starts on.
 *
 * @param {String} weekStart - The selected start data of the week
 */
export function setWeekStart(weekStart) {
  flux.dispatch('PERIODIC_CONDITION_WEEK_START', { weekStart });
}

/**
 * Set the day the week duration duration starts on.
 *
 * @param {String} month - The selected month enum of the quarter start
 * @param {Number} day - The selected start day of the quarter start
 */
export function setQuarterStart(month, day) {
  flux.dispatch('PERIODIC_CONDITION_QUARTER_START', { month, day });
}

/**
 * Set the duration of the shifts period
 *
 * @param {String} month - The selected month enum of the quarter start
 * @param {Number} shiftOffset - The number of hours after midnight to start the capsule
 * @param {Number} shiftDuration - The duration of the shift. Must be integer hours
 */
export function setShift(shiftOffset, shiftDuration) {
  flux.dispatch('PERIODIC_CONDITION_SHIFT', {
    offset: shiftOffset,
    duration: shiftDuration,
  });
}

/**
 * Adds or removes the specified frequency from the frequencies list.
 *
 * @param {String} [frequency] - The frequency to toggle. If not provided the list will be cleared.
 */
export function toggleFrequency(frequency) {
  flux.dispatch('PERIODIC_CONDITION_TOGGLE_FREQUENCY', { frequency });
}

/**
 * Set an offset that can be used to shift the start of the of the capsules.
 *
 * @param {Object} offset - Object container
 * @param {Number} offset.value - The number that indicates how long the offset is
 * @param {String} offset.units - The units that the value represents
 */
export function setOffset(offset) {
  flux.dispatch('PERIODIC_CONDITION_OFFSET', {
    value: offset.value,
    units: offset.units,
  });
}
