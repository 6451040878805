// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { load, removeGroups } from '@/administration/administration.actions';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { CELL_TYPES, TableColumn } from '@/hybrid/core/Table.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import { formatGroups, isGroupReadOnly } from '@/hybrid/administration/groupAdmin.utilities';
import { FormWarning } from '@/hybrid/core/FormWarning.atom';
import { AdminTableWrapper } from '@/hybrid/core/AdminTableWrapper.molecule';
import { EditAddGroupModal } from '@/hybrid/administration/groups/EditAddGroupModal.molecule';
import { sqAdministrationStore } from '@/core/core.stores';

const groupsTabBindings = bindingsDefinition({});

export const GroupsTab: SeeqComponent<typeof groupsTabBindings> = () => {
  const [viewOnlyGroupSelected, setViewOnlyGroupSelected] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  type ModalType = 'addGroup' | 'editGroup' | 'viewGroup' | 'none';
  const [modalShown, setModalShown] = useState<ModalType>('none');
  const [getGroup, setGetGroup] = useState();

  const closeModal = () => {
    setModalShown('none');
    setGetGroup(undefined);
  };

  const { t } = useTranslation();
  const unformattedGroups = useFluxPath(sqAdministrationStore, () => sqAdministrationStore.groups);

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setViewOnlyGroupSelected(_.some(selectedItems, (group) => isGroupReadOnly(group)));
  }, [groups, selectedItems]);

  useEffect(() => {
    setGroups(formatGroups(unformattedGroups));
  }, [unformattedGroups]);

  let modalTitle = 'ADMIN.GROUP.MODAL.TITLE.ADD';
  let readOnly = false;
  switch (modalShown) {
    case 'editGroup':
      modalTitle = 'ADMIN.GROUP.MODAL.TITLE.UPDATE';
      readOnly = false;
      break;
    case 'viewGroup':
      modalTitle = 'ADMIN.GROUP.MODAL.TITLE.VIEW';
      readOnly = true;
      break;
    default:
      break;
  }

  const accessGroup = (group, readOnly) => {
    setModalShown(readOnly ? 'viewGroup' : 'editGroup');
    setGetGroup(group);
  };

  const renderViewOrEdit = (group) => {
    const isReadOnly = isGroupReadOnly(group);
    return (
      <div>
        <div
          className="flexColumnContainer specAdminEditGroup flexAlignCenter cursorPointer"
          onClick={() => accessGroup(group, isReadOnly)}>
          <Icon icon={isReadOnly ? 'fa-list-alt' : 'fa-pencil'} testId={`editGroup_${group.id}`} />
          <span className="pl5 sq-text-primary">
            {t(isReadOnly ? 'ADMIN.GROUP.VIEW_GROUP_BUTTON' : 'ADMIN.GROUP.EDIT_GROUP_BUTTON')}
          </span>
        </div>
      </div>
    );
  };

  const columns: TableColumn[] = [
    {
      accessor: 'id',
      sortable: false,
      filterable: false,
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40 },
    },
    {
      accessor: 'name',
      searchProperty: 'name',
      header: 'ADMIN.GROUP.NAME',
      cellStyle: {
        minWidth: 150,
        maxWidth: 250,
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        wordBreak: 'break-all',
      },
      sortable: true,
      filterable: true,
    },
    {
      accessor: 'datasourceName',
      searchProperty: 'datasourceName',
      header: 'ADMIN.GROUP.DIRECTORY',
      cellStyle: { minWidth: 75 },
      sortable: true,
      filterable: true,
    },
    {
      accessor: 'isEnabled',
      searchProperty: 'isEnabled',
      header: 'ADMIN.GROUP.STATUS',
      cellType: CELL_TYPES.ENABLED_DISABLED,
      cellStyle: { width: 150, maxWidth: 150 },
      sortable: true,
      filterable: true,
    },
    {
      accessor: 'edit',
      filterable: false,
      sortable: false,
      cellStyle: { width: 85, maxWidth: 90 },
      cellRenderFunction: renderViewOrEdit,
    },
  ];

  return (
    <div className="height-maximum">
      <div className="flexColumnContainer flexSpaceBetween mb5">
        <ButtonWithManagedSpinner
          buttonProps={{
            id: 'removeSelectedGroup',
            disabled: viewOnlyGroupSelected || _.isEmpty(selectedItems),
          }}
          spinnerIconProps={{ white: true }}
          action={() => removeGroups(_.map(selectedItems, 'id')).finally(() => setSelectedItems([]))}
          label="ADMIN.GROUP.REMOVE_SELECTED"
        />

        {viewOnlyGroupSelected && <FormWarning warningText="ADMIN.GROUP.REMOVE_WARNING" extraClassNames="mb5" />}

        <div className="flexColumnContainer">
          <ButtonWithManagedSpinner
            buttonProps={{
              id: 'refreshGroups',
              extraClassNames: 'addGroup action mr10',
            }}
            spinnerIconProps={{ type: 'text' }}
            action={() => load().finally(() => setSelectedItems([]))}
            icon="fa-repeat"
            label="ADMIN.GROUP.REFRESH_LIST"
          />

          <TextButton
            id="addGroupModal"
            variant="theme"
            onClick={() => setModalShown('addGroup')}
            icon="fa-plus"
            iconStyle="white"
            extraClassNames="mr15"
            label="ADMIN.GROUP.ADD_GROUP_BUTTON"
          />
        </div>
      </div>

      <div className="overflowAuto width-maximum height-maximum pb70">
        <AdminTableWrapper
          testId="groupsTable"
          items={groups}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          defaultSort={{ property: 'name', asc: true }}
          columns={columns}
        />
      </div>

      {modalShown !== 'none' && (
        <EditAddGroupModal
          onClose={closeModal}
          modalTitle={<h3>{t(modalTitle)}</h3>}
          group={getGroup}
          isReadOnly={readOnly}
        />
      )}
    </div>
  );
};
