// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const reportConfigBackupsEntryBindings = bindingsDefinition({
  date: prop<string>(),
  displayName: prop<string>(),
  onClickHandler: prop<(e) => void>(),
  selected: prop<boolean>(),
});

export const ReportConfigBackupsEntry: SeeqComponent<typeof reportConfigBackupsEntryBindings> = (props) => {
  const { date, displayName, onClickHandler, selected } = props;
  const { t } = useTranslation();

  const [showClickPreview, setShowClickPreview] = useState(false);

  return (
    <div
      className={classNames('versionEntry', 'flexRowContainer', 'p0', 'pl5', {
        selected,
      })}
      onClick={onClickHandler}
      onMouseEnter={() => setShowClickPreview(true)}
      onMouseLeave={() => setShowClickPreview(false)}>
      <div>{date}</div>
      <div className="versionEntryUser">{displayName}</div>
      {showClickPreview && <div className="clickPreviewPrompt">{t('REPORT.CONTENT.CLICK_PREVIEW')}</div>}
    </div>
  );
};
