// @ts-strict-ignore
import _ from 'lodash';
import { sqPluginsApi } from '@/sdk';
import { errorToast, successToast, warnToast } from '@/hybrid/utilities/toast.utilities';
import { flux } from '@/core/flux.module';
import { sqPluginsAdminStore } from '@/core/core.stores';

/**
 * Loads the plugins table using the pageSize and currentPageNumber to fetch the proper page content.
 */
export function loadPlugins() {
  const limit = sqPluginsAdminStore.getPageSizeByTable();
  const currentPageNumber = sqPluginsAdminStore.getPageNumberForTable();
  const offset = (currentPageNumber - 1) * limit;
  const sortOrder = `${sqPluginsAdminStore.sortProperty} ${sqPluginsAdminStore.sortAscending ? 'asc' : 'desc'}`;
  const searchParams = sqPluginsAdminStore.searchParams;
  flux.dispatch('PLUGINSADMIN_SET_TABLE_LOADING', { isLoading: true });

  return sqPluginsApi
    .getPlugins(_.assign({}, searchParams, { limit, offset, sortOrder }))
    .then((result) => {
      flux.dispatch('PLUGINSADMIN_SET_PLUGINS', {
        plugins: result.data.plugins,
      });
      flux.dispatch('PLUGINSADMIN_SET_PLUGINS_TOTAL', {
        count: _.toNumber(result.data.totalResults),
      });
    })
    .finally(() => flux.dispatch('PLUGINSADMIN_SET_TABLE_LOADING', { isLoading: false }));
}

export function setFilter(field: string, value: string) {
  flux.dispatch('PLUGINSADMIN_SET_SEARCH_PARAMS', { field, value });
  flux.dispatch('PLUGINSADMIN_SET_PAGE_NUMBER', { pageNumber: 1 });
}

export function setSort(field: string, sortAsc: boolean) {
  flux.dispatch('PLUGINSADMIN_SET_SORT', { field, sortAscending: sortAsc });
  flux.dispatch('PLUGINSADMIN_SET_PAGE_NUMBER', { pageNumber: 1 });
}

export function resetPageNumber() {
  flux.dispatch('PLUGINSADMIN_SET_PAGE_NUMBER', { pageNumber: 1 });
}

/**
 * Remove plugins
 * If no plugins are selected a warning to select plugins is displayed.
 */
export function removePlugins(ids: string[]) {
  if (_.isEmpty(ids)) {
    warnToast({ messageKey: 'ADMIN.PLUGIN.SELECTION_REQUIRED' });
    return Promise.resolve();
  }
  const removed = _.map(ids, (id) => sqPluginsApi.deletePlugin({ id }));
  return Promise.all(removed)
    .then(() => {
      flux.dispatch('PLUGINSADMIN_REMOVE_PLUGINS', ids);
      successToast({ messageKey: 'ADMIN.PLUGIN.REMOVED' });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
      loadPlugins();
    });
}

export function setPageSizeForTable({ size }) {
  flux.dispatch('PLUGINSADMIN_SET_PAGE_SIZE', { size });
}

export function setPageNumberAndGo(pageNumber: number) {
  flux.dispatch('PLUGINSADMIN_SET_PAGE_NUMBER', { pageNumber });
  loadPlugins();
}
