// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { sqWorkbenchStore } from '@/core/core.stores';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { LOCALES } from '@/main/app.constants';

interface EnglishOnlyWarningProps {}

/**
 * Displays message that content is only available in English
 */
export const EnglishOnlyWarning: React.FunctionComponent<EnglishOnlyWarningProps> = () => {
  const { t } = useTranslation();

  const isEnglish = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userLanguage === LOCALES.EN);

  return !isEnglish && <div className="xsmall text-italic">{t('ENGLISH_ONLY_WARNING')}</div>;
};
