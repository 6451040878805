// @ts-strict-ignore
import { createContext, useContext } from 'react';

export const InjectorContext = createContext<ng.auto.IInjectorService>(null);

/**
 * This hook enables getting a handle on the angular $injector
 */
export function useInjector() {
  const $injector = useContext(InjectorContext);
  if (!$injector) {
    throw new Error(
      `AngularJS $injector is unavailable. ${
        process.env.NODE_ENV === 'test'
          ? 'Ensure that "withRootComponent" is used with @testing-library/react\'s render method'
          : ''
      }`,
    );
  }

  return $injector;
}
