import _ from 'lodash';

const isHeadlessRenderMore = _.isFunction(window.seeqHeadlessCapture);

export const NUMBERS_ONLY_REGEX = /^((?!\s*$)[-+]?[0-9]*\.?[0-9]*([eE][-+]?[0-9]+)?)$/;
export const DEBOUNCE = {
  // Suitable for deduping multiple calls that are expected to happen in quick succession, but too short for use
  // debouncing user input
  SHORT: isHeadlessRenderMore ? 1 : 100,
  // Suitable for user input/dragging/scrolling/resizing events. When in doubt use this one
  MEDIUM: isHeadlessRenderMore ? 1 : 300,
  // Much the same as MEDIUM but for especially expensive api calls (pdf preview, investigate range capsules)
  LONG: isHeadlessRenderMore ? 1 : 1000,
  // The amount of debounce to use for workstep creation
  WORKSTEP: isHeadlessRenderMore ? 1 : 300,
  // The amount of debounce to use for live tool previews
  PREVIEW: isHeadlessRenderMore ? 1 : 500,
};

export const THROTTLE = {
  // The timebar stutters without this minimal throttle
  DRAG_TIMEBAR: 1,
  SHORT: 50,
};
