import angular from 'angular';

const dependencies = [
  'Sq.AppConstants',
  'Sq.Workbench',
  'Sq.Core',
  'Sq.Worksheets',
  'Sq.Worksteps',
  'Sq.Vendor',
  'Sq.Worksheet',
  'Sq.LicenseManagement',
  'Sq.SystemWarning',
  'Sq.Administration.Datasources',
  'Sq.Administration.Agents',
];

/**
 * The Sq.Header module contains all functionality related to the workbench header panel
 */
angular.module('Sq.Header', dependencies);
