// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import {
  addAggregation,
  createFormula,
  removeAggregation,
  setAggregationItem,
  setAggregationMode,
  setBinMode,
  setBinNumber,
  setBinSize,
  setCapsuleMode,
  setConditionProperty,
  setEmptyBuckets,
  setStat,
  setTimeBucket,
  setYValueBinMax as setYValueBinMaxSqAggregationBin,
  setYValueBinMin as setYValueBinMinSqAggregationBin,
} from '@/hybrid/tools/histogram/aggregationBin.actions';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import {
  AGGREGATION_MODES,
  CAPSULE_MODES,
  TIME_BUCKETS,
  Y_VALUE_BIN_MODES,
} from '@/hybrid/tools/histogram/histogram.constants';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { API_TYPES, DISPLAY_MODE } from '@/main/app.constants';
import { useConditionFormGroup } from '@/hybrid/tools/histogram/hooks/useConditionFormGroup';
import { CapsuleModesContent } from '@/hybrid/tools/histogram/CapsuleModesContent';
import { CapsuleModesLabel } from '@/hybrid/tools/histogram/CapsuleModesLabel';
import { AddGroupingButton } from '@/hybrid/tools/histogram/AddGroupingButton';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { getViewCapsuleParameter } from '@/hybrid/utilities/tableHelper.utilities';
import { fetchCapsuleProperties } from '@/hybrid/utilities/investigateHelper.utilities';
import { CalculationRunnerService } from '@/services/calculationRunner.service';
import { sqAggregationBinStore, sqInvestigateStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';

const histogramBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqToolRunner: injected<ToolRunnerService>(),
  sqCalculationRunner: injected<CalculationRunnerService>(),
});

export const Histogram: SeeqComponent<typeof histogramBindings> = () => {
  const { sqInvestigateActions, sqToolRunner, sqCalculationRunner } = useInjectedBindings(histogramBindings);

  const { t } = useTranslation();
  const [color, setColor] = useState('');

  const { name, id, signalToAggregate, originalParameters, stat, aggregationConfigs, includeEmptyBuckets } =
    useFlux(sqAggregationBinStore);
  const displayMode = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.displayMode);

  const aggregationSelections = _.cloneDeep(aggregationConfigs);

  /**
   * Determines if a component should be visible. This is used to switch out the value, condition and time components
   * based on the radio selection.
   *
   * @param {String} mode - one of AGGREGATION_MODES
   * @param {String} id - the id identifying the component
   * @returns {Boolean} true if the component is visible, false otherwise.
   */
  const show = (mode: string, id: string) => _.get(_.find(aggregationSelections, { id }), 'mode') === mode;

  const yValueFormGroup = ({ id }): FormElement => {
    const {
      item,
      yValueBinMode = Y_VALUE_BIN_MODES.SIZE,
      numberOfBins,
      binSize,
      yValueBinMin,
      yValueBinMax,
    } = _.find<any>(aggregationConfigs, { id });

    /**
     * Persists the maximum range used for the y-value bin definition to the store.
     */
    const setYValueBinMin = (yValueBinMin) => setYValueBinMinSqAggregationBin(yValueBinMin, id);

    /**
     * Persists the minimum range used for the y-value bin definition to the store.
     */
    const setYValueBinMax = (yValueBinMax) => setYValueBinMaxSqAggregationBin(yValueBinMax, id);

    /**
     * Whenever a new series to define bins is selected the min and max input fields are automatically
     * pre-populated with the rounded min/max values in the given display range.
     * The rounding ensures "nice" labels.
     *
     * @param {Object} item - the selected Signal
     */
    const setSignal = (item) => {
      setYValueBinMin(Math.floor(_.get(item, 'yAxisMin', 0)));
      setYValueBinMax(Math.ceil(_.get(item, 'yAxisMax')));
      setAggregationItem(item, id);
    };

    /**
     * Sets the yValueBinMode.
     * The yValueBinMode is used to determine if bins should be created by size or if a specific number of bins should
     * be created. Also resets the value for the not selected option.
     *
     * @param {String} mode - one of Y_VALUE_BIN_MODES
     */
    const setBinCreationMode = (mode: string) => {
      setBinMode(mode, id);
      if (mode === Y_VALUE_BIN_MODES.NUMBER) {
        setBinSize(undefined, id);
      } else {
        setBinNumber(undefined, id);
      }
    };

    return {
      component: 'FormGroup',
      name: `yValueFormGroup${id}`,
      testId: `yValueFormGroup${id}`,
      includeIf: show(AGGREGATION_MODES.Y_VALUE, id),
      components: [
        {
          component: 'ItemSelectFormComponent',
          name: `specAggregateByYValue${id}`,
          testId: `specAggregateByYValue${id}`,
          displayNumber: true,
          value: item?.id,
          onChange: setSignal,
          label: 'AGGREGATIONS.SELECT_BIN_SERIES',
          itemTypes: [ITEM_TYPES.SERIES],
          excludeStringSignals: true,
          additionalItems: originalParameters,
        },
        {
          component: 'ValueBinsFormComponent',
          name: `valueBins${id}`,
          testId: `valueBins${id}`,
          label: 'AGGREGATIONS.HOW_TO_BIN',
          value: {
            binSize,
            numberOfBins,
            min: yValueBinMin,
            max: yValueBinMax,
          },
          onChange: _.noop,
          onBinSizeChange: (binSize) => setBinSize(binSize, id),
          onBinNumberChange: (binNumber) => setBinNumber(binNumber, id),
          onMinChange: setYValueBinMin,
          onMaxChange: setYValueBinMax,
          yValueBinMode,
          setBinCreationMode,
          rowClasses: item?.rowClasses,
          displayNumber: true,
        },
      ],
    };
  };

  const { detectedPropertiesLoading, detectedProperties, updateDetectedProperties, updateDetectedPropertiesLoading } =
    useConditionFormGroup();

  const isUnboundedInValid = (value: string) =>
    !_.find(_.filter(CAPSULE_MODES, { boundedOnly: false }), (mode) => mode.key === value);

  const getProperties = (item, id: string) => {
    updateDetectedPropertiesLoading(true, id);
    fetchCapsuleProperties(item.id)
      .then((detectedProperties) => updateDetectedProperties(_.map(detectedProperties, 'name'), id))
      .finally(() => updateDetectedPropertiesLoading(false, id));
  };

  const conditionFormGroup = ({ id }): FormElement => {
    const { item, capsuleMode, conditionProperty } = _.find<any>(aggregationConfigs, { id });
    const isUnbounded = _.isUndefined(_.get(item, ['conditionMetadata', 'maximumDuration']));

    if (
      show(AGGREGATION_MODES.CONDITION, id) &&
      !detectedPropertiesLoading[id] &&
      !_.isEmpty(item) &&
      _.isUndefined(detectedProperties[id])
    ) {
      getProperties(item, id);
    }

    return {
      component: 'FormGroup',
      name: `conditionFormGroup${id}`,
      testId: `conditionFormGroup${id}`,
      includeIf: show(AGGREGATION_MODES.CONDITION, id),
      components: [
        {
          component: 'ItemSelectFormComponent',
          name: `specHistogramConditionSelect${id}`,
          testId: `specHistogramConditionSelect${id}`,
          displayNumber: true,
          value: item?.id,
          onChange: (item) => {
            setAggregationItem(item, id);
            getProperties(item, id);
          },
          label: 'AGGREGATIONS.SELECT_CONDITION',
          itemTypes: [ITEM_TYPES.CAPSULE_SET],
          includeMetadata: true,
          additionalItems: originalParameters,
        },
        {
          component: 'ButtonWithPopoverFormComponent',
          name: `conditionCapsuleMode${id}`,
          testId: `conditionCapsuleMode${id}`,
          displayNumber: true,
          extraClassNames: 'flexFillOverflow',
          label: 'AGGREGATIONS.CAPSULE_MODE.DESCRIPTION',
          popoverLabel: ({ className }) => <CapsuleModesLabel capsuleMode={capsuleMode} extraClassNames={className} />,
          popoverContent: ({ onChange, value }) => <CapsuleModesContent id={id} value={value} onChange={onChange} />,
          popoverConfig: {
            id: `conditionCapsuleModePopover${id}`,
            placement: 'top',
          },
          closeOnClick: false,
          value: [capsuleMode, id],
          onChange: ([capsuleMode, id]) => setCapsuleMode(capsuleMode, id),
          validation: (value) => _.isUndefined(value?.[0]) || (isUnbounded && isUnboundedInValid(value?.[0])),
          extendValidation: true,
          customErrorText: isUnbounded && isUnboundedInValid(capsuleMode) ? 'AGGREGATIONS.UNBOUNDED_ERROR' : undefined,
        },
        {
          component: 'SelectFormComponent',
          name: `specHistogramPropertySelect${id}`,
          testId: `specHistogramPropertySelect${id}`,
          value: conditionProperty,
          label: (
            <div className="flexColumnContainer flexAlignCenter mb5">
              <span className="pr5">{t('AGGREGATIONS.SELECT_PROPERTY')}</span>
              {detectedPropertiesLoading[id] && <IconWithSpinner spinning={true} />}
            </div>
          ),
          placeholder: 'AGGREGATIONS.SELECT_PROPERTY_PLACEHOLDER',
          onChange: (value) => setConditionProperty(value, id),
          options: _.map(detectedProperties[id], (property) => ({
            label: property,
            value: property,
          })),
          displayNumber: true,
          required: true,
        },
      ],
    };
  };

  const timeFormGroup = ({ id }): FormElement => {
    const { timeBucket, capsuleMode } = _.find<any>(aggregationConfigs, { id });
    const selectedTimeBucket = timeBucket?.key;

    return {
      component: 'FormGroup',
      name: `timeFormGroup${id}`,
      testId: `timeFormGroup${id}`,
      includeIf: show(AGGREGATION_MODES.TIME, id),
      components: [
        {
          component: 'CheckboxTableFormComponent',
          name: `timeBucket${id}`,
          displayNumber: true,
          options: _.map(TIME_BUCKETS, ({ key, display }) => ({
            key,
            display: `AGGREGATIONS.${display}`,
          })),
          value: selectedTimeBucket,
          type: 'radio',
          onChange: (bucketKey) => setTimeBucket(_.find(TIME_BUCKETS, { key: bucketKey }), id),
        },
        {
          component: 'ButtonWithPopoverFormComponent',
          name: `timeCapsuleMode${id}`,
          testId: `timeCapsuleMode${id}`,
          displayNumber: true,
          extraClassNames: 'flexFillOverflow',
          label: 'AGGREGATIONS.CAPSULE_MODE.DESCRIPTION',
          popoverLabel: ({ className }) => <CapsuleModesLabel capsuleMode={capsuleMode} extraClassNames={className} />,
          popoverConfig: {
            id: `timeCapsuleModePopover${id}`,
            placement: 'top',
          },
          closeOnClick: false,
          value: [capsuleMode, id],
          onChange: ([capsuleMode, id]) => setCapsuleMode(capsuleMode, id),
          validation: (value) => _.isUndefined(value?.[0]),
          popoverContent: ({ onChange, value }) => <CapsuleModesContent id={id} value={value} onChange={onChange} />,
        },
      ],
    };
  };

  const aggregationOptFields = ({ id }): FormElement[] => [
    {
      component: 'RadioButtonGroupFormComponent',
      name: `aggregationType${id}`,
      id: `aggregationType${id}`,
      testId: `aggregationType${id}`,
      label: (
        <div className="flexColumnContainer flexSpaceBetween flexAlignCenter">
          <span>{t('AGGREGATIONS.SELECT_AGGREGATION_TYPE')}</span>
          {aggregationSelections.length > 1 && <Icon icon="fa-close" onClick={() => removeAggregation(id)} />}
        </div>
      ),
      onChange: _.noop,
      value: null,
      displayNumber: true,
      extraClassNames: 'flexFillOverflow',
      options: [
        {
          id: `${id}YValue`,
          label: 'AGGREGATIONS.BY_Y_VALUE',
          checked: show(AGGREGATION_MODES.Y_VALUE, id),
          onToggle: () => setAggregationMode(AGGREGATION_MODES.Y_VALUE, id),
        },
        {
          id: `${id}Condition`,
          label: 'AGGREGATIONS.BY_CONDITION',
          checked: show(AGGREGATION_MODES.CONDITION, id),
          onToggle: () => setAggregationMode(AGGREGATION_MODES.CONDITION, id),
        },
        {
          id: `${id}Time`,
          label: 'AGGREGATIONS.BY_TIME',
          checked: show(AGGREGATION_MODES.TIME, id),
          onToggle: () => setAggregationMode(AGGREGATION_MODES.TIME, id),
        },
      ],
    },
    yValueFormGroup({ id }),
    conditionFormGroup({ id }),
    timeFormGroup({ id }),
  ];

  const formDataSetup: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'histogramSearchTitle',
      testId: 'histogramSearchTitle',
      value: name,
      onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.AGGREGATION_BINS_TABLE, name),
      id,
      onColorChange: setColor,
      searchIconClass: 'fc-bar-chart',
      defaultName: 'AGGREGATIONS.HEADER',
    },
    {
      component: 'ItemSelectFormComponent',
      name: 'signalToAggregate',
      testId: 'signalToAggregate',
      displayNumber: true,
      value: signalToAggregate?.id,
      onChange: (item) =>
        sqInvestigateActions.setParameterItem(TREND_TOOLS.AGGREGATION_BINS_TABLE, 'signalToAggregate', item),
      label: 'AGGREGATIONS.SIGNAL_TO_AGGREGATE',
      itemTypes: [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET],
      additionalItems: originalParameters,
    },
    {
      component: 'StatisticSelectorFormComponent',
      name: 'statistic',
      testId: 'statistic',
      extraClassNames: 'flexFillOverflow',
      isRequired: true,
      outputType: ['histogram'],
      item: signalToAggregate,
      onChange: setStat,
      value: stat,
      validation: (value) => !signalToAggregate || !value?.key,
      extendValidation: true,
      displayNumber: true,
      label: 'AGGREGATIONS.STATS',
      onValidate: _.noop,
    },
    {
      component: 'FormGroup',
      name: 'aggregationSelectionsFormGroup',
      testId: 'aggregationSelectionsFormGroup',
      components: _.flatten(_.map(aggregationSelections, aggregationOptFields)),
    },
    {
      component: 'DisplayOnlyFormElementWrapper',
      name: 'addSubGroupingBtn',
      includeIf: aggregationSelections.length < 2,
      children: <AddGroupingButton onClick={addAggregation} />,
    },
    {
      component: 'CheckboxFormComponent',
      name: 'emptyBuckets',
      id: 'emptyBuckets',
      displayNumber: true,
      value: includeEmptyBuckets,
      onChange: () => setEmptyBuckets(!includeEmptyBuckets),
      checkboxLabel: 'AGGREGATIONS.INCLUDE_EMPTY_BUCKETS',
    },
  ];

  /**
   * Generates and runs the formula function that creates the appropriate aggregation formula.
   * This function also calls saveUIConfig and ensures that the result is added to the details panel.
   *
   * The formulas that are created are "special" as they support "unbound" parameters, aka parameters that vary based
   * on the view range. This enables a re-run of the same formula for a new display range (instead of re-creation of
   * a new formula). To validate the formula unbound parameters must be also supplied when creating the formula, not
   * just when running it.
   */
  const run = () => {
    const { formula, parameters } = createFormula({
      signalToAggregate,
      selectedStatistic: stat,
      sqCalculationRunner,
    });

    return sqToolRunner
      .panelExecuteFormulaFunction(
        API_TYPES.TABLE,
        {
          formula,
          // add the "unbound" parameters
          parameters: _.concat(parameters, getViewCapsuleParameter()),
          name,
        },
        sqAggregationBinStore.configParams,
        id,
        color,
      )
      .then(() => {
        doTrack('Workbench_Tool', 'Histogram', 'completed');
      })
      .catch(() => {
        doTrack('Workbench_Tool', 'Histogram', 'error');
      });
  };

  const histogramBuilder = (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={run}
      closeFn={sqInvestigateActions.close}
      toolId={TREND_TOOLS.AGGREGATION_BINS_TABLE}
      submitBtnId="runHistogram"
    />
  );

  return displayMode === DISPLAY_MODE.NEW || displayMode === DISPLAY_MODE.EDIT ? histogramBuilder : null;
};

export const sqHistogram = angularComponent(histogramBindings, Histogram);
