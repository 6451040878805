// @ts-strict-ignore
import _ from 'lodash';
import { FormulaToolStore } from '@/hybrid/tools/formula/formulaTool.store';

import { ToolRunnerService } from '@/services/toolRunner.service';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { DISPLAY_MODE } from '@/main/app.constants';
import { FormulaParameter } from '@/hybrid/tools/formula/formulaTool.module';
import { warnToast } from '@/hybrid/utilities/toast.utilities';
import { flux } from '@/core/flux.module';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { trackPowerSearchCompletedInfo } from '@/track/track.service';

export type FormulaToolActions = ReturnType<typeof sqFormulaToolActions>;

export function sqFormulaToolActions(
  $injector: ng.auto.IInjectorService,
  sqToolRunner: ToolRunnerService,
  sqFormulaToolStore: FormulaToolStore,
) {
  const service = {
    setFormula,
    addParameter,
    updateParameter,
    removeParameter,
    selectParameter,
    removeAllParameters,
    search,
    addDetailsPaneParameters,
    setNavigationStack,
    editNewFormula,
    formulaTypeClass,
    toggleHelp,
    setFormulaFilter,
  };
  return service;

  /**
   * Runs the formula and creates a calculated series, capsule set, or scalar.
   * @param {String} color - Color hex code (e.g. #CCCCCC)
   * @return {Promise} - a promise that resolves when the power search completes.
   */
  function search(color) {
    const parameters = _.transform(
      sqFormulaToolStore.parameters,
      (accum, parameter: any) => {
        accum[parameter.identifier] = parameter.item.id;
      },
      {},
    );

    const searchCompletePayload = {
      name: sqFormulaToolStore.name,
      formula: sqFormulaToolStore.formula,
      parameters: sqFormulaToolStore.parameters,
      // isNew in particular must be determined before calling generate because it will create the id
      isNew: !sqFormulaToolStore.id,
    };

    return Promise.resolve()
      .then(() =>
        sqToolRunner.panelExecuteCalculatedItem(
          sqFormulaToolStore.name,
          sqFormulaToolStore.formula,
          parameters,
          sqFormulaToolStore.configParams,
          sqFormulaToolStore.id,
          color,
          { notifyOnError: false },
        ),
      )
      .catch((errorMessage) => {
        trackPowerSearchCompletedInfo(
          _.assign(searchCompletePayload, {
            success: false,
            errorMessage,
            id: sqFormulaToolStore.id,
          }),
        );

        return Promise.reject(errorMessage);
      })
      .then(() => {
        trackPowerSearchCompletedInfo(
          _.assign(searchCompletePayload, {
            success: true,
            id: sqFormulaToolStore.id,
          }),
        );
      });
  }

  /**
   * Sets the search formula
   *
   * @param {String} formula - the formula for the search.
   */
  function setFormula(formula) {
    flux.dispatch('FORMULA_SET_FORMULA', { formula });
  }

  /**
   * Adds a parameter.
   *
   * @param {Object} parameter - The parameter to add
   * @param {String} parameter.identifier - The symbolic identifier for the parameter
   * @param {Object} parameter.item - Object containing item properties
   * @param {String} parameter.item.id - The id of the item referenced by the parameter
   * @param {String} parameter.item.name - The name of the item referenced by the parameter
   */
  function addParameter(parameter) {
    flux.dispatch('FORMULA_ADD_PARAMETER', { parameter });
  }

  /**
   * Adds items from details pane to the formula as parameters if they don't already exist there.
   */
  function addDetailsPaneParameters() {
    flux.dispatch('FORMULA_ADD_DETAILS_PANE_PARAMETERS');
  }

  /**
   * Updates a parameter.
   *
   * @param {Number} index - The index of the parameter to update
   * @param {Object} parameter - The parameter to add
   * @param {String} parameter.identifier - The symbolic identifier for the parameter
   * @param {Object} parameter.item - Object containing item properties
   * @param {String} parameter.item.id - The id of the item referenced by the parameter
   * @param {String} parameter.item.name - The name of the item referenced by the parameter
   */
  function updateParameter(parameter, originalParameter) {
    if (!parameter.identifier.trim()) {
      warnToast({ messageKey: 'INVALID_NAME' });
      return Promise.reject();
    }
    const index: number = _.findIndex(sqFormulaToolStore.parameters, {
      identifier: originalParameter.identifier,
    });
    const isUnique = _.every(
      sqFormulaToolStore.parameters,
      (v: any, i: number) => v.identifier !== parameter.identifier || i === index,
    );

    if (!isUnique) {
      warnToast({ messageKey: 'FORMULA.VARIABLE_UNIQUE' });
      return Promise.reject();
    }

    flux.dispatch('FORMULA_UPDATE_PARAMETER', { index, parameter });
  }

  function selectParameter(parameter) {
    // The index may have changed due to user removing or reordering parameters
    const index = _.findIndex(sqFormulaToolStore.parameters, { identifier: parameter.identifier });
    flux.dispatch('FORMULA_UPDATE_PARAMETER', { index, parameter });
  }

  /**
   * Removes a parameter.
   *
   * @param {string} identifier - The name (identifier) of the parameter to remove
   */
  function removeParameter(identifier) {
    flux.dispatch('FORMULA_REMOVE_PARAMETER', { identifier });
  }

  /**
   * Removes all parameters.
   */
  function removeAllParameters() {
    flux.dispatch('FORMULA_REMOVE_ALL_PARAMETERS');
  }

  /**
   * Sets the formula editor navigation stack
   *
   * @param {String} navigationStack - the navigation stack
   */
  function setNavigationStack(navigationStack) {
    flux.dispatch('FORMULA_SET_NAVIGATION_STACK', { navigationStack });
  }

  /**
   * Sets the formula documentation search filter
   *
   * @param {String} filterTerm - the search term
   */
  function setFormulaFilter(filterTerm) {
    flux.dispatch('FORMULA_SET_FILTER', { filter: filterTerm });
  }

  /**
   * Open the formula tool for edit with the supplied formula and parameters
   *
   * @param formula - the formula
   * @param parameters - the formula parameters
   */
  function editNewFormula(formula: string, parameters: FormulaParameter[]) {
    const sqInvestigateActions = $injector.get<InvestigateActions>('sqInvestigateActions');
    sqInvestigateActions.setActiveTool(TREND_TOOLS.FORMULA, DISPLAY_MODE.NEW, true);
    service.removeAllParameters();
    _.forEach(parameters, (parameter) => service.addParameter(parameter));
    service.setFormula(formula);
  }

  function formulaTypeClass(type) {
    return `formula-type-${type.toLowerCase()}`;
  }

  function toggleHelp() {
    flux.dispatch('FORMULA_TOGGLE_HELP_SHOWN');
  }
}
