import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Card } from 'react-bootstrap';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { DateTimeEntry } from '@/hybrid/core/DateTimeEntry.atom';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { FormInput } from '@/hybrid/core/FormInput.atom';
import { SearchParametersIF, SelectOptionIF } from './UsageTab.page';
import SelectIdentity from '@/hybrid/core/SelectIdentity.molecule';
import { IdentityPreviewV1 } from '@/sdk';
import { AggregateByEnum } from '@/sdk/api/UsageApi';
import { USAGE_TIMEZONE } from '@/hybrid/administration/usage/usage.utilities';
import { Icon } from '@/hybrid/core/Icon.atom';

export interface UsageSearchPanelIF {
  searchParameters: SearchParametersIF;
  resetCallback: () => void;
  searchCallback: (searchParameters: SearchParametersIF) => void;
  itemTypeOptions: SelectOptionIF<string>[];
  aggregateByOptions: SelectOptionIF<AggregateByEnum>[];
  isDrilledIn: boolean;
  hasMore: boolean;
}

export const UsageSearchPanel: React.FunctionComponent<UsageSearchPanelIF> = ({
  searchParameters,
  searchCallback,
  resetCallback,
  itemTypeOptions,
  aggregateByOptions,
  isDrilledIn,
  hasMore,
}) => {
  const { t } = useTranslation();

  const [startTime, setStartTime] = useState(searchParameters.startTime);
  const [endTime, setEndTime] = useState(searchParameters.endTime);
  const [type, setType] = useState<SelectOptionIF<string>[]>([]);
  const [source, setSource] = useState('');
  const [identityId, setIdentityId] = useState<Partial<IdentityPreviewV1>[]>([]);
  const [aggregateBy, setAggregateBy] = useState<SelectOptionIF<AggregateByEnum>[]>(searchParameters.aggregateBy);

  useEffect(() => {
    setStartTime(searchParameters.startTime);
    setEndTime(searchParameters.endTime);
    setType(searchParameters.type);
    setSource(searchParameters.source);
    setAggregateBy(searchParameters.aggregateBy);
    if (searchParameters.identityId.length === 0) {
      setIdentityId([]);
    }
  }, [searchParameters]);

  const DatePickerStart = (
    <HoverTooltip text={t('USAGE.TIMEZONE', { TIMEZONE: USAGE_TIMEZONE.name })} placement="top">
      <div className="flexRowContainer pr10 mt1 width-auto">
        <label className="col-form-label">{t('START')}</label>
        <DateTimeEntry
          testId="usageStartTime"
          date={moment.utc(startTime)}
          otherDate={moment.utc(endTime)}
          updateDate={(d) => setStartTime(d.valueOf())}
          updateBothDates={_.noop}
          timezone={USAGE_TIMEZONE}
          fieldIdentifier="DISPLAY_RANGE_START"
          readOnly={false}
          timeZoneReadOnly={true}
          hideTimezone={true}
          trackCategory=""
          trackAction=""
          trackInformation=""
          inputExtraClassNames="overflowHidden dateTimeEntryField"
          textExtraClassNames="overflowHidden width-150 dateTimeEntryField"
        />
      </div>
    </HoverTooltip>
  );

  const DatePickerEnd = (
    <HoverTooltip text={t('USAGE.TIMEZONE', { TIMEZONE: USAGE_TIMEZONE.name })} placement="top">
      <div className="flexRowContainer pr10 mt2 width-auto">
        <label className="col-form-label">{t('END')}</label>
        <DateTimeEntry
          testId="usageEndTime"
          date={moment.utc(endTime)}
          otherDate={moment.utc(startTime)}
          updateDate={(d) => setEndTime(d.valueOf())}
          updateBothDates={_.noop}
          timezone={USAGE_TIMEZONE}
          fieldIdentifier="DISPLAY_RANGE_END"
          readOnly={false}
          timeZoneReadOnly={true}
          hideTimezone={true}
          trackCategory=""
          trackAction=""
          trackInformation=""
          inputExtraClassNames="overflowHidden dateTimeEntryField"
          textExtraClassNames="overflowHidden width-150 dateTimeEntryField"
        />
      </div>
    </HoverTooltip>
  );

  const UserField = (
    <div className="mr10 flexRowContainer">
      <label className="col-form-label">{t('USAGE.USER')}</label>
      <div className="multiSelectTwoLine">
        <SelectIdentity
          idForLabel="identityName"
          setIdentity={setIdentityId}
          allowGroups={true}
          identity={identityId as Partial<IdentityPreviewV1>}
          includeAllProperties={true}
          startEditable={true}
          placeholder="USAGE.USER_PLACEHOLDER"
          multiple={true}
          clearIdentityWhenEmpty={true}
        />
      </div>
    </div>
  );

  const SourceField = (
    <div className="flexRowContainer pr10 mt3">
      <label className="col-form-label">{t('USAGE.SOURCE')}</label>
      <FormInput
        value={source}
        onChange={(event) => {
          const sourceValue = event.currentTarget.value;
          setSource(sourceValue);
        }}
        placeholder={t('USAGE.SOURCE_PLACEHOLDER')}
        data-testid="sourceFilter"
      />
    </div>
  );

  const TypeSelect = (
    <div className="flexRowContainer pr10">
      <label className="col-form-label">{t('USAGE.TYPE')}</label>
      <div className="multiSelectTwoLine" data-testid="changeTypeSelect">
        <Select
          classNamePrefix="react-select"
          options={itemTypeOptions}
          isMulti={true}
          placeholder={t('USAGE.TYPE_PLACEHOLDER')}
          isSearchable={false}
          onChange={(newSelectedType) => {
            setType(_.isNil(newSelectedType) ? [] : (newSelectedType as SelectOptionIF<string>[]));
          }}
          value={type}
          // So that it appears above other dropdowns or flexFill containers
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 1000 }) }}
        />
      </div>
    </div>
  );

  const AggregateBy = (
    <div className="flexRowContainer pr10">
      <label className="col-form-label">{t('USAGE.AGGREGATE_BY')}</label>
      <div className="multiSelectTwoLine" data-testid="changeAggregateBy">
        <Select
          classNamePrefix="react-select"
          options={aggregateByOptions}
          defaultValue={_.find(aggregateByOptions, { value: AggregateByEnum.Day })}
          isMulti={true}
          placeholder={t('USAGE.AGGREGATE_BY_PLACEHOLDER')}
          isSearchable={false}
          onChange={(newAggregateBy) => {
            setAggregateBy(_.isNil(newAggregateBy) ? [] : (newAggregateBy as SelectOptionIF<AggregateByEnum>[]));
          }}
          value={aggregateBy}
          // So that it appears above other dropdowns or flexFill containers
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 1000 }) }}
        />
      </div>
    </div>
  );

  const SearchButton = (
    <TextButton
      label={t('SEARCH')}
      variant="theme"
      icon="fa-search"
      disabled={false}
      iconStyle="white"
      type="submit"
      extraClassNames="mr5 mt5 ml10"
      size="lg"
      testId="usageSearch"
      onClick={() =>
        searchCallback({
          startTime,
          endTime,
          type,
          source,
          identityId: identityId.map((identity) => identity.id!),
          aggregateBy,
        })
      }
    />
  );

  const ResetButton = (
    <TextButton
      label={isDrilledIn ? t('USAGE.GO_BACK') : t('USAGE.RESET')}
      variant="outline"
      disabled={false}
      type="submit"
      extraClassNames="mr5 mt5 ml10"
      size="sm"
      testId="usageReset"
      onClick={resetCallback}
    />
  );

  return (
    <Card data-testid="usageSearchPanel">
      <Card.Header className="flexColumnContainer flexWrap">
        <div className="flexRowContainer">
          {DatePickerStart}
          {DatePickerEnd}
        </div>
        <div className="flexRowContainer flexFillOverflow">
          {UserField}
          {SourceField}
        </div>
        <div className="flexRowContainer flexFillOverflow">
          {TypeSelect}
          {AggregateBy}
        </div>
        <div className="flexRowContainer flexJustifyCenter">
          <div className="flexColumnContainer flexCenter">
            {SearchButton}
            {hasMore && (
              <Icon
                testId="hasMoreWarning"
                icon="fa-exclamation-triangle"
                type="warning"
                extraClassNames="ml5"
                tooltip={'USAGE.HAS_MORE_ENTRIES'}
              />
            )}
          </div>
          {ResetButton}
        </div>
      </Card.Header>
    </Card>
  );
};
