import React, { useState } from 'react';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { useTranslation } from 'react-i18next';
import { FolderExplorerModal } from '@/hybrid/explorer/FolderExplorerModal.organism';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { HOME_SCREEN_TABS } from '@/main/app.constants';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { sqHomeScreenStore } from '@/core/core.stores';

interface CorporateDriveMessageProps {
  item?: any;
  itemId: string;
  itemName: string;
  onClose?: any;
  fetchInherited?: any;
  folderId: string;
  isAclModalLocationInHeader: boolean;
  aclModified?: any;
}

export const CorporateDriveMessage: React.FunctionComponent<CorporateDriveMessageProps> = ({
  item,
  onClose,
  aclModified,
  itemId,
  itemName,
  fetchInherited,
  folderId,
  isAclModalLocationInHeader,
}) => {
  const { t } = useTranslation();
  const currentTab = useFluxPath(sqHomeScreenStore, () => sqHomeScreenStore.currentTab);
  const handleFolderExplorerClose = () => {
    setShowFolderExplorerModal(false);
    if (!aclModified) {
      onClose();
    }
  };

  const [showFolderExplorerModal, setShowFolderExplorerModal] = useState(false);

  const moveCorporate = () => {
    if (sqHomeScreenStore.currentTab !== HOME_SCREEN_TABS.CORPORATE) {
      return setShowFolderExplorerModal(true);
    } else {
      return errorToast({
        messageKey: 'ITEM_ACL.ITEM_IN_CORPORATE_ERROR',
        messageParams: { item: itemName },
      });
    }
  };

  return (
    <>
      <div data-testid="corporateMessage" className="corporateMessage fakeTableRowDivider">
        {t('ITEM_ACL.CORPORATE_MESSAGE')}
        <TextButton
          testId="corporateMessageButton"
          variant="outline"
          onClick={moveCorporate}
          type="button"
          extraClassNames="ml15"
          size="sm"
          label="ITEM_ACL.MOVE_CORPORATE"
        />
        {showFolderExplorerModal && (
          <FolderExplorerModal
            onClose={handleFolderExplorerClose}
            item={item}
            currentFolderId={folderId}
            currentTab={currentTab}
            corporateDriveMessage={true}
            itemId={itemId}
            itemName={itemName}
            isAclModalLocationInHeader={isAclModalLocationInHeader}
            aclModified={aclModified}
            fetchInherited={fetchInherited}
          />
        )}
      </div>
    </>
  );
};
