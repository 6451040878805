// @ts-strict-ignore
import { AgentStatusOutputV1, ConnectionStatusOutputV1, DatasourceSummaryStatusOutputV1 } from '@/sdk';
import { Card } from 'react-bootstrap';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { DatasourcesService, DatasourceStatus } from '@/hybrid/administration/datasources/datasources.service';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleSwitch } from '@/hybrid/core/ToggleSwitch.atom';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { sqWorkbenchStore } from '@/core/core.stores';
import { formatTime as formatDateTime, parseISODate } from '@/hybrid/datetime/dateTime.utilities';
import { TrendingCount } from '@/hybrid/core/TrendingCount.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { ConnectionCard } from '@/hybrid/administration/datasources/ConnectionCard.molecule';
import _ from 'lodash';
import classNames from 'classnames';
import { ButtonWithManagedSpinner } from '@/hybrid/core/ButtonWithManagedSpinner.atom';

const datasourceCardBindings = bindingsDefinition({
  datasource: prop<DatasourceSummaryStatusOutputV1>(),
  onAclManage: prop<() => void>(),
  onDatasourceManage: prop<() => any>(),
  onConnectionManage: prop<(connection: ConnectionStatusOutputV1) => Promise<any>>(),
  onExpand: prop<() => void>(),
  expanded: prop<boolean>(),
  agents: prop<AgentStatusOutputV1[]>(),
  sqDatasourcesService: injected<DatasourcesService>(),
});

export const DatasourceCard: SeeqComponent<typeof datasourceCardBindings> = (props) => {
  const { datasource, onAclManage, onDatasourceManage, onConnectionManage, onExpand, expanded, agents } = props;

  const { sqDatasourcesService } = useInjectedBindings(datasourceCardBindings);
  const { t } = useTranslation();
  const timezone = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.timezone);

  const formatTime = (input: string): string => {
    const timeFormat = 'LL LTS'; // Example: June 1, 2020 8:00:00 AM
    return input != null
      ? formatDateTime(parseISODate(input), { name: timezone.name }, timeFormat)
      : t('ADMIN.DATASOURCES.NO_INDEXING_DATE');
  };

  const datasourceStatusIconMap = new Map([
    [DatasourceStatus.Unknown, 'fa-exclamation-triangle sq-status-error width-17'],
    [DatasourceStatus.Error, 'fa-exclamation-triangle sq-status-error width-17'],
    [DatasourceStatus.Indexing, 'fa-refresh fa-spin sq-status-progress width-17'],
    [DatasourceStatus.Warning, 'fa-exclamation-circle sq-status-warning width-17'],
    [DatasourceStatus.Happy, 'fa-check-circle sq-status-good width-17'],
    [DatasourceStatus.NotConnectable, 'fa-minus-circle disabledLook width-17'],
  ]);

  const renderCancelAllRequests = () => (
    <ButtonWithManagedSpinner
      action={() => sqDatasourcesService.cancelAllRequests(datasource)}
      buttonProps={{
        extraClassNames: 'sq-text-danger pt0 pr0 pb0 link-no-underline',
        type: 'link',
        variant: 'no-border',
        testId: `cancelAllRequests_${datasource.id}`,
      }}
      spinnerIconProps={{
        type: 'danger',
      }}
      icon="fa-times-circle mr2"
      label="ADMIN.DATASOURCES.CANCEL_ALL_REQUESTS"
    />
  );

  const renderAllowRequestsToDatasource = () => (
    <ToggleSwitch
      testId={`allowRequests_${datasource.id}`}
      isOn={datasource.allowRequests}
      onChange={() => sqDatasourcesService.setDatasourceAllowRequests(datasource, !datasource.allowRequests)}
      label="ADMIN.DATASOURCES.ALLOW_REQUESTS_TO_DATASOURCE"
    />
  );

  const renderCacheEnableToggle = () => (
    <ToggleSwitch
      testId={`cacheEnable_${datasource.id}`}
      isOn={datasource.cacheEnabled}
      onChange={() => sqDatasourcesService.setCacheEnabled(datasource, !datasource.cacheEnabled)}
      label="ADMIN.DATASOURCES.CACHE_ENABLED_TOGGLE_LABEL"
    />
  );

  const syncProgress = datasource.syncProgress;

  const signalProgress = syncProgress.signalProgress || 0;
  const signalCount = syncProgress.signalCount || 0;
  const previousSignalCount = syncProgress.previousSignalCount || 0;
  const conditionProgress = syncProgress.conditionProgress || 0;
  const conditionCount = syncProgress.conditionCount || 0;
  const previousConditionCount = syncProgress.previousConditionCount || 0;
  const scalarProgress = syncProgress.scalarProgress || 0;
  const scalarCount = syncProgress.scalarCount || 0;
  const previousScalarCount = syncProgress.previousScalarCount || 0;
  const assetProgress = syncProgress.assetProgress || 0;
  const assetCount = syncProgress.assetCount || 0;
  const previousAssetCount = syncProgress.previousAssetCount || 0;
  const userGroupProgress = syncProgress.userGroupProgress || 0;
  const userGroupCount = syncProgress.userGroupCount || 0;
  const previousUserGroupCount = syncProgress.previousUserGroupCount || 0;
  const relationshipProgress = syncProgress.relationshipProgress || 0;
  const relationshipCount = syncProgress.relationshipCount || 0;

  const anyCurrentIndexCountHasChanged =
    signalCount !== previousSignalCount ||
    conditionCount !== previousConditionCount ||
    scalarCount !== previousScalarCount ||
    assetCount !== previousAssetCount ||
    userGroupCount !== previousUserGroupCount;

  const renderDatasourceStatusIcon = () => (
    <div className="pt4 mr5">
      <Icon
        testId={`dsStatusIcon_${datasource.id}`}
        icon={datasourceStatusIconMap.get(sqDatasourcesService.getDatasourceStatus(datasource))}
        large={true}
      />
    </div>
  );

  const renderDatasourceTitleRow = () => (
    <div className="flexColumnContainer">
      <div className="font-size-large font-weight-lighter">
        <span>{datasource.datasourceClass}</span>
        <span>: </span>
        <span data-testid={`datasourceCardName_${datasource.id}`}>{datasource.name}</span>
      </div>
      <div
        data-testid={`dsTitleFiller_${datasource.id}`}
        className="flexFill cursorPointer"
        onClick={() => onExpand()}
      />
    </div>
  );

  const renderCollapsedSummaryRow = (itemsIndexed) => (
    <div className="flexColumnContainer">
      <div className="flexRowContainer">
        <div>
          {t('ADMIN.DATASOURCES.ITEMS_INDEXED_NUM', {
            count: _.toNumber(itemsIndexed),
          })}
        </div>
        <div>
          {t('ADMIN.DATASOURCES.INDEXED_AT', {
            date: formatTime(datasource.currentIndexAt),
          })}
        </div>
      </div>
      <div className="flexFill" />
    </div>
  );

  const renderExpandedDetailedInfoRow = () => (
    <>
      <div className="flexColumnContainer">
        {datasource.datasourceIndexing && (
          <>
            <div className="flexRowContainer mr10">
              <div className="semi-bold">{t('ADMIN.DATASOURCES.CURRENTLY_INDEXING')}</div>
              <div>{t('ADMIN.DATASOURCES.NOW')}</div>
              <TrendingCount
                testId={`trendIndicatorSignalProgress_${datasource.id}`}
                currentCount={signalProgress}
                otherCount={signalCount}
                showIndicator={false}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_SIGNALS"
              />
              <TrendingCount
                testId={`trendIndicatorConditionProgress_${datasource.id}`}
                currentCount={conditionProgress}
                otherCount={conditionCount}
                showIndicator={false}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_CONDITIONS"
              />
              <TrendingCount
                testId={`trendIndicatorScalarProgress_${datasource.id}`}
                currentCount={scalarProgress}
                otherCount={scalarCount}
                showIndicator={false}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_SCALARS"
              />
              <TrendingCount
                testId={`trendIndicatorAssetProgress_${datasource.id}`}
                currentCount={assetProgress}
                otherCount={assetCount}
                showIndicator={false}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_ASSETS"
              />
              <TrendingCount
                testId={`trendIndicatorUserGroupProgress_${datasource.id}`}
                currentCount={userGroupProgress}
                otherCount={userGroupCount}
                showIndicator={false}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_USERGROUPS"
              />
              {relationshipCount !== 0 && (
                <span>
                  {t('ADMIN.DATASOURCES.PROGRESS.PERCENT_RELATIONSHIPS', {
                    percent: Math.round((relationshipProgress * 100) / relationshipCount),
                  })}
                </span>
              )}
              {relationshipCount === 0 && relationshipProgress !== 0 && (
                <span>
                  {t('ADMIN.DATASOURCES.PROGRESS.NUM_RELATIONSHIPS', {
                    count: _.toNumber(relationshipProgress),
                  })}
                </span>
              )}
            </div>

            <div className="flexRowContainer mr10">
              <div className="semi-bold">{t('ADMIN.DATASOURCES.PREVIOUS_INDEX')}</div>
              <div>{formatTime(datasource.currentIndexAt)}</div>
              <TrendingCount
                testId={`trendIndicatorSignalCount_${datasource.id}`}
                currentCount={signalCount}
                otherCount={signalProgress}
                showIndicator={false}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_SIGNALS"
              />
              <TrendingCount
                testId={`trendIndicatorConditionCount_${datasource.id}`}
                currentCount={conditionCount}
                otherCount={conditionProgress}
                showIndicator={false}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_CONDITIONS"
              />
              <TrendingCount
                testId={`trendIndicatorScalarCount_${datasource.id}`}
                currentCount={scalarCount}
                otherCount={scalarProgress}
                showIndicator={false}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_SCALARS"
              />
              <TrendingCount
                testId={`trendIndicatorAssetCount_${datasource.id}`}
                currentCount={assetCount}
                otherCount={assetProgress}
                showIndicator={false}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_ASSETS"
              />
              <TrendingCount
                testId={`trendIndicatorUserGroupCount_${datasource.id}`}
                currentCount={userGroupCount}
                otherCount={userGroupProgress}
                showIndicator={false}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_USERGROUPS"
              />
            </div>
          </>
        )}

        {!datasource.datasourceIndexing && (
          <>
            <div className="flexRowContainer mr10">
              <div className="semi-bold">{t('ADMIN.DATASOURCES.CURRENTLY_INDEXED')}</div>
              <div>{formatTime(datasource.currentIndexAt)}</div>
              <TrendingCount
                testId={`trendIndicatorSignalCount_${datasource.id}`}
                currentCount={signalCount}
                otherCount={previousSignalCount}
                showIndicator={anyCurrentIndexCountHasChanged}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_SIGNALS"
              />
              <TrendingCount
                testId={`trendIndicatorConditionCount_${datasource.id}`}
                currentCount={conditionCount}
                otherCount={previousConditionCount}
                showIndicator={anyCurrentIndexCountHasChanged}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_CONDITIONS"
              />
              <TrendingCount
                testId={`trendIndicatorScalarCount_${datasource.id}`}
                currentCount={scalarCount}
                otherCount={previousScalarCount}
                showIndicator={anyCurrentIndexCountHasChanged}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_SCALARS"
              />
              <TrendingCount
                testId={`trendIndicatorAssetCount_${datasource.id}`}
                currentCount={assetCount}
                otherCount={previousAssetCount}
                showIndicator={anyCurrentIndexCountHasChanged}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_ASSETS"
              />
              <TrendingCount
                testId={`trendIndicatorUserGroupCount_${datasource.id}`}
                currentCount={userGroupCount}
                otherCount={previousUserGroupCount}
                showIndicator={anyCurrentIndexCountHasChanged}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_USERGROUPS"
              />
            </div>

            <div className="flexRowContainer mr10">
              <div className="semi-bold">{t('ADMIN.DATASOURCES.PREVIOUS_INDEX')}</div>
              <div>{formatTime(datasource.previousIndexAt)}</div>
              <TrendingCount
                testId={`trendIndicatorPrevSignalCount_${datasource.id}`}
                currentCount={previousSignalCount}
                otherCount={signalCount}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_SIGNALS"
                showIndicator={false}
              />
              <TrendingCount
                testId={`trendIndicatorPrevConditionCount_${datasource.id}`}
                currentCount={previousConditionCount}
                otherCount={conditionCount}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_CONDITIONS"
                showIndicator={false}
              />
              <TrendingCount
                testId={`trendIndicatorPrevScalarCount_${datasource.id}`}
                currentCount={previousScalarCount}
                otherCount={scalarCount}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_SCALARS"
                showIndicator={false}
              />
              <TrendingCount
                testId={`trendIndicatorPrevAssetCount_${datasource.id}`}
                currentCount={previousAssetCount}
                otherCount={assetCount}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_ASSETS"
                showIndicator={false}
              />
              <TrendingCount
                testId={`trendIndicatorPrevUserGroupCount_${datasource.id}`}
                currentCount={previousUserGroupCount}
                otherCount={userGroupCount}
                label="ADMIN.DATASOURCES.PROGRESS.NUM_USERGROUPS"
                showIndicator={false}
              />
            </div>
          </>
        )}

        <div className="flexFill" />

        <div className="flexRowContainer flexAlignEnd">
          {renderAllowRequestsToDatasource()}
          {renderCacheEnableToggle()}
          {renderCancelAllRequests()}
        </div>
      </div>

      {!datasource.datasourceIndexing && (
        <div className="flexColumnContainer mt10">
          <span className="semi-bold">{t('ADMIN.DATASOURCES.NEXT_SCHEDULED_INDEX')}</span>
          <span className="mr2">:</span>
          <span data-testid={`nextScheduledIndex_${datasource.id}`}>{formatTime(datasource.nextScheduledIndexAt)}</span>
        </div>
      )}

      <div className="flexColumnContainer mt10 mb3">
        <ButtonWithManagedSpinner
          buttonProps={{
            testId: `requestIndexButton_${datasource.id}`,
            size: 'sm',
            type: 'button',
            variant: 'outline',
            iconStyle: 'theme',
            extraClassNames: 'mr5 specRequestIndexBtn',
            disabled: !datasource.indexingScheduleSupported,
          }}
          label="ADMIN.DATASOURCES.REQUEST_INDEX"
          action={() =>
            sqDatasourcesService.requestIndex(
              datasource,
              t('ADMIN.DATASOURCES.NO_CONNECTIONS_FOUND_TO_SEND_INDEX_REQUEST'),
            )
          }
          icon="fa-plus"
        />

        <TextButton
          testId={`accessControl_${datasource.id}`}
          size="sm"
          label="ADMIN.DATASOURCES.ACCESS_CONTROL"
          type="button"
          variant="outline"
          onClick={onAclManage}
          icon="fc fc-share"
          iconStyle="theme"
          extraClassNames="btn btn-secondary mr5"
        />

        <div className="flexFill" />

        <ButtonWithManagedSpinner
          buttonProps={{
            testId: `datasourceManage_${datasource.id}`,
            size: 'sm',
            type: 'button',
            variant: 'theme',
            iconStyle: 'white',
            extraClassNames: 'ml5',
          }}
          label="ADMIN.DATASOURCES.MANAGE"
          action={onDatasourceManage}
          icon="fa-cog"
          spinnerIconProps={{ white: true }}
        />
      </div>

      {_.map(sqDatasourcesService.sortConnections(datasource.connections), (conn) => (
        <ConnectionCard
          key={conn.connectionId}
          onConnectionManage={onConnectionManage}
          connection={conn}
          agents={agents}
        />
      ))}
    </>
  );

  const renderExpandCollapseControl = () => (
    <div onClick={() => onExpand()}>
      <Icon
        icon={classNames({
          'fa-chevron-up': expanded,
          'fa-chevron-down': !expanded,
        })}
        extraClassNames="ml5 font-size-larger cursorPointer"
        testId={`expandControl_${datasource.id}`}
        type="text"
      />
    </div>
  );

  return (
    <Card className="mb10 datasourceCard">
      <Card.Header>
        <div className="flexColumnContainer">
          {renderDatasourceStatusIcon()}
          <div className="flexFill flexRowContainer">
            {renderDatasourceTitleRow()}
            {!expanded && renderCollapsedSummaryRow(sqDatasourcesService.getDatasourceItemsCount(datasource))}
            {expanded && renderExpandedDetailedInfoRow()}
          </div>
          {renderExpandCollapseControl()}
        </div>
      </Card.Header>
    </Card>
  );
};
