// @ts-strict-ignore
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { CapsulePanel } from '@/hybrid/trend/panels/capsulesPanel/CapsulesPanel.organism';
import { DetailsPanel } from '@/hybrid/trend/panels/detailsPanel/DetailsPanel.organism';
import { DetailsPanelHeader } from '@/hybrid/trend/panels/detailsPanel/DetailsPanelHeader.atom';
import { TrendActions } from '@/trendData/trend.actions';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  adjustDropdownMenus,
  constrainLeftPanelWidth,
  panelHeight,
  resizeDividerStart,
  resizePanelStart,
} from '@/hybrid/trend/panels/bottomPanels/bottomPanels.utilities';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { DEBOUNCE } from '@/core/core.constants';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useDebounce } from '@/hybrid/core/hooks/useDebounce.hook';
import { useResizeWatcher } from '@/hybrid/core/hooks/useResizeWatcher.hook';
import { useDidUpdate } from 'rooks';
import { TREND_PANELS } from '@/trendData/trendData.constants';
import { sqTrendCapsuleSetStore, sqTrendStore } from '@/core/core.stores';

const bottomPanelsBindings = bindingsDefinition({
  sqTrendActions: injected<TrendActions>(),
  parentHeight: prop<number>(),
  hideCapsulesPanel: prop.optional<boolean>(),
});

export const BottomPanels: SeeqComponent<typeof bottomPanelsBindings> = ({ parentHeight, hideCapsulesPanel }) => {
  const { sqTrendActions } = useInjectedBindings(bottomPanelsBindings);

  // force component re-render when trend-store updates
  useFlux(sqTrendStore);
  const items = useFluxPath(sqTrendCapsuleSetStore, () => sqTrendCapsuleSetStore.items);
  const bottomPanelPropsFromStore = _.cloneDeep(sqTrendStore.getPanelProps(TREND_PANELS.BOTTOM));

  const bottomPanelsRef = useRef<HTMLDivElement>();
  const canUpdateStore = useRef(false);

  const [bottomPanelProps, setBottomPanelProps] = useState(bottomPanelPropsFromStore);
  const capsulesExist = items.length > 0;
  const showCapsulesPanel = capsulesExist && !hideCapsulesPanel;
  const targetHeight = bottomPanelProps.height ? Number(_.replace(bottomPanelProps.height, '%', '')) : 30;

  const debouncedSetBottomPanelProps = useDebounce(
    () => sqTrendActions.setPanelProps(TREND_PANELS.BOTTOM, _.cloneDeep(bottomPanelProps)),
    DEBOUNCE.LONG,
    { isAllowedDuringTest: true },
  );

  useEffect(adjustDropdownMenus, []);

  useEffect(
    () => setBottomPanelProps(bottomPanelPropsFromStore),
    [bottomPanelPropsFromStore?.height, bottomPanelPropsFromStore?.leftPanelWidth],
  );

  const updateBottomPanelProps = (width?: number, updateStore = true) => {
    setBottomPanelProps((bottomPanelProps) => ({
      ...bottomPanelProps,
      leftPanelWidth: constrainLeftPanelWidth({
        leftPanelWidth: width || bottomPanelProps.leftPanelWidth,
        showCapsulesPanel,
        bottomPanelsElm: bottomPanelsRef.current,
      }),
    }));
    updateStore && debouncedSetBottomPanelProps();
  };

  useResizeWatcher(
    {
      elementRef: bottomPanelsRef,
      callback: () => {
        updateBottomPanelProps(null, canUpdateStore.current);
        canUpdateStore.current = true;
      },
      callOnLoad: false,
    },
    [showCapsulesPanel],
  );

  useDidUpdate(() => updateBottomPanelProps(), [showCapsulesPanel]);

  const updatePanelProps = (newPercent: number) => {
    setBottomPanelProps((bottomPanelProps) => ({
      ...bottomPanelProps,
      height: `${newPercent}%`,
    }));
    debouncedSetBottomPanelProps();
  };

  const constrainAndUpdatePanelProps = (width: number) => {
    updateBottomPanelProps(width);
    debouncedSetBottomPanelProps();
  };

  return (
    <div
      id="bottomPanels"
      ref={bottomPanelsRef}
      data-testid="bottomPanels"
      style={{ height: `${panelHeight(parentHeight, targetHeight)}px` }}
      className="flexColumnContainer bottomPanel flexNoShrink">
      <div
        id="detailsPanel"
        data-testid="detailsPanel"
        className="flexColumnContainer width-maximum detailsPanel"
        style={{
          minWidth: bottomPanelProps.leftPanelWidth,
          maxWidth: bottomPanelProps.leftPanelWidth,
        }}>
        <div className="flexRowContainer flexFill panelBorderRight" id="detailsPanelContent">
          <div
            className="header flexColumnContainer flexCenter flexNoGrowNoShrink cursorResize"
            data-testid="detailsPanelHeader"
            onMouseDown={(e) =>
              resizePanelStart({
                e,
                parentHeight,
                updatePanelProps,
                bottomPanelsElm: bottomPanelsRef.current,
              })
            }>
            <DetailsPanelHeader />
          </div>
          <div className="height-maximum overflowAuto">
            <DetailsPanel />
          </div>
        </div>
      </div>
      {showCapsulesPanel && (
        <div
          data-testid="divider"
          className="divider flexRowContainer flexCenter"
          onMouseDown={(e) =>
            resizeDividerStart({
              e,
              startPanelWidth: bottomPanelProps.leftPanelWidth,
              constrainAndUpdatePanelProps,
            })
          }>
          <Icon icon="fc-grab-window" />
        </div>
      )}
      {showCapsulesPanel && (
        <div className="flexColumnContainer width-maximum capsulesPanel">
          <CapsulePanel
            resizeEnabled={true}
            resizePanelStart={(e) =>
              resizePanelStart({
                e,
                parentHeight,
                updatePanelProps,
                bottomPanelsElm: bottomPanelsRef.current,
              })
            }
          />
        </div>
      )}
    </div>
  );
};

export const sqBottomPanels = angularComponent(bottomPanelsBindings, BottomPanels);
