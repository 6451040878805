// @ts-strict-ignore
import _ from 'lodash';
import angular from 'angular';
import HttpCodes from 'http-status-codes';
import moment from 'moment-timezone';
import tinycolor from 'tinycolor2';
import tinygradient from 'tinygradient';
import { emitPermissions, onAllServerRequestsCanceled } from '@/services/notifier.service';
import { DurationActions } from '@/trendData/duration.actions';
import { TrendTableActions } from '@/trendData/trendTable.actions';
import { getCapsuleFormula, inflateTimes } from '@/hybrid/datetime/dateTime.utilities';
import { FormulaToolStore } from '@/hybrid/tools/formula/formulaTool.store';
import { AnnotationActions } from '@/annotation/annotation.actions';
import { removeGaps, updateLaneDisplay } from '@/trendData/yAxis.actions';
import { addRecentlyAccessed } from '@/workbook/workbook.actions';
import { findChildrenIn, findItemIn, getAllChildItems, getAllItems } from '@/hybrid/trend/trendDataHelper.utilities';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { ItemOutputV1, ThresholdMetricOutputV1 } from '@/sdk/model/models';
import { ProcessTypeEnum } from '@/sdk/model/ThresholdMetricOutputV1';
import { ErrorTypeEnum } from '@/sdk/model/FormulaErrorOutputV1';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import {
  FormulaService,
  FormulaTable,
  SPIKECATCHER_PER_PIXEL,
  TableSortParams,
  XY_TABLE_PER_PIXEL,
} from '@/services/formula.service';
import { API_TYPES, NUMBER_CONVERSIONS, STRING_UOM } from '@/main/app.constants';
import { DEBOUNCE } from '@/core/core.constants';
import { ThresholdOutputV1 } from '@/sdk/model/ThresholdOutputV1';
import { SummaryTypeEnum } from '@/sdk/model/ContentInputV1';
import { TableBuilderActions } from '@/hybrid/tableBuilder/tableBuilder.actions';
import { sqAnnotationsApi, sqMetricsApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { logError, logInfo, logWarn } from '@/hybrid/utilities/logger';
import { formatMessage } from '@/hybrid/utilities/logger.utilities';
import { HeadlessCategory, headlessRenderCategory } from '@/services/headlessCapture.utilities';
import { formatNumber, FormatOptions } from '@/hybrid/utilities/numberHelper.utilities';
import {
  cancelAll,
  cancelGroup,
  CANCELLATION_GROUP_GUID_SEPARATOR,
  count,
} from '@/hybrid/requests/pendingRequests.utilities';
import { isCanceled } from '@/hybrid/utilities/http.utilities';
import {
  getDefaultBarWidth,
  getMSPerPixelWidth,
  getToolType,
  headlessRenderMode,
  isApplePlatform,
  isCapsuleFullyVisible,
  isPresentationWorkbookMode,
  isStringSeries,
  isTimestampVisible,
  isTrendable,
  workbookLoaded,
} from '@/hybrid/utilities/utilities';
import {
  API_TYPES_TO_ITEM_TYPES,
  CAPSULE_PANEL_LOOKUP_COLUMNS,
  CapsuleTimeColorMode,
  CHART_CAPSULES_LIMIT,
  CHILD_CLONED_PROPERTIES,
  CompareViewColorMode,
  CUSTOMIZATION_MODES,
  DASH_STYLES,
  FORMULA_FRAGMENT_TYPE,
  ITEM_CHILDREN_TYPES,
  ITEM_DATA_STATUS,
  ITEM_TYPES,
  MAX_SERIES_PIXELS,
  PREVIEW_ID,
  PROPERTIES_COLUMN_PREFIX,
  PROPERTIES_UOM_COLUMN_PREFIX,
  PropertyColumn,
  SAMPLE_OPTIONS,
  SHADED_AREA_CURSORS,
  SHADED_AREA_DIRECTION,
  SHADED_AREA_TYPES,
  SwapPairs,
  TREND_CAPSULE_INFLATION,
  TREND_COLORS,
  TREND_CONDITION_STATS,
  TREND_PANELS,
  TREND_SIGNAL_STATS,
  TREND_STORES,
  TREND_VIEWS,
} from '@/trendData/trendData.constants';
import { errorToast, infoToast, successToast, warnToast } from '@/hybrid/utilities/toast.utilities';
import { TableColumnFilter } from '@/hybrid/core/tableUtilities/tables';
import { addCondition, addSignal } from '@/hybrid/utilities/autoGroup.utilities';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { flux } from '@/core/flux.module';
import { groupedNonCapsuleSeries, isHidden } from '@/hybrid/utilities/trendChartItemsHelper.utilities';
import { PUSH_IGNORE } from '@/core/flux.service';
import { notifyCancellation } from '@/hybrid/utilities/screenshot.utilities';
import {
  sqDurationStore,
  sqInvestigateStore,
  sqTrendCapsuleSetStore,
  sqTrendCapsuleStore,
  sqTrendMetricStore,
  sqTrendScalarStore,
  sqTrendSeriesStore,
  sqTrendStore,
  sqWorkbenchStore,
  sqWorkbookStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { ReactSelectOption } from '@/hybrid/core/IconSelect.molecule';
import { CHART_THRESHOLDS } from '@/hybrid/trend/trendViewer/trendViewer.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { doTrack } from '@/track/track.service';
import { FrontendDuration } from '@/services/systemConfiguration.constants';
import { computeLightestColor } from '@/hybrid/core/html.utilities';
import { calculate } from '@/hybrid/trend/trendViewer/capsuleBuckets.utilities';
import { conditionFormula } from '@/hybrid/tools/manualCondition/conditionFormula.service';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { buildSignalSegmentsFormula } from '@/trendData/trendSeries.utilities';
import { fetchTreemap } from '@/treemap/treemap.actions';
import { handleForbidden, isForbidden, isItemRedacted } from '@/hybrid/utilities/redaction.utilities';
import { fetchItemUsages } from '@/search/search.actions';

export type TrendActions = ReturnType<typeof sqTrendActions>;

export function sqTrendActions(
  $window: ng.IWindowService,
  $document: ng.IDocumentService,
  $injector: ng.auto.IInjectorService,
  $state: ng.ui.IStateService,
  sqDurationActions: DurationActions,
  sqTrendTableActions: TrendTableActions,
  sqFormulaToolStore: FormulaToolStore,
  sqAnnotationActions: AnnotationActions,
  sqHomeScreenUtilities: HomeScreenUtilitiesService,
  sqTableBuilderActions: TableBuilderActions,
  sqFormula: FormulaService,
  sqScatterPlotActions: ScatterPlotActions,
  sqWorksheetActions: WorksheetActions,
) {
  let chartWidth = computeChartWidth();
  let debouncedSetCapsuleTimeOffsets;
  let debouncedFetchAllTimeseries;
  const DESCRIPTION = 'description';
  const DATASOURCE_NAME = 'datasourceName';
  const PIXELS_PER_BREAK = 0;
  const previewSeriesCancellationGroup = 'previewChartSeries';
  const previewCapsulesCancellationGroup = 'previewChartCapsules';

  const service = {
    addCapsuleTimeSegments,
    addItem,
    batchAddItems,
    addPropertiesColumn,
    addMultiplePropertiesColumn,
    setColumnFilter,
    removeColumnFilter,
    cancelPreviewCapsules,
    cancelPreviewSeries,
    catchItemDataFailure,
    clear,
    clearPointerValues,
    createStitchDetails,
    displayEmptyPreviewCapsuleLane,
    fetchAllItems,
    fetchAllScalars,
    fetchAllStatistics,
    fetchAllTimebarCapsules,
    fetchAllTimeseries,
    fetchHiddenTrendData,
    fetchChartCapsules,
    fetchItemAndDependents,
    fetchItemProps,
    fetchItems,
    fetchMetric,
    fetchPreviewSeries,
    fetchPropsForAllItems,
    fetchScalar,
    fetchSignalSegments,
    fetchStatistics,
    fetchTableAndChartCapsules,
    fetchTimebarCapsules,
    fetchTimeseries,
    generatePreviewCapsules,
    generatePreviewSeries,
    getChartWidth,
    getSwaps,
    isPropertyColumn,
    removeAllItems,
    removeChildren,
    removeItem,
    removeItems,
    removePreviewCapsules,
    removePreviewSeries,
    removePropertiesColumn,
    removeSelectedItems,
    removeSelectedRegion,
    removeUnselectedSeriesFromCapsules,
    replaceCapsuleSelection,
    selectItems,
    setCapsuleProperties,
    setColorByProperty,
    setSeparateByProperty,
    setFirstColumn,
    setCapsuleLaneLabels,
    setCapsulePanelOffset,
    setCapsulePreview,
    setCapsuleTimeOffsets,
    resetCapsuleTimeOffsets,
    setCapsulesPerPage,
    setChartWidth,
    setColumnEnabled,
    setCustomLabel,
    setEditModeForCapsuleSet,
    setEditModeForSeries,
    setGlobalScope,
    setItemColor,
    setItemSelected,
    setLabelDisplayConfiguration,
    setPanelProps,
    setPanelSort,
    setPointerValues,
    setCustomizationProps,
    setIsCompareMode,
    setSelectedRegion,
    setTrendItemProps,
    setSummary,
    setView,
    swapAssets,
    setCapsuleTimeColorMode,
    setCompareViewColorMode,
    setCustomizationMode,
    toggleColumn,
    toggleDimDataOutsideCapsules,
    setGridlines,
    toggleHideUnselectedItems,
    toggleItemSelected,
    togglePanelSort,
    toggleStatisticsColumn,
    selectCapsules,
    unselectAllCapsules,
    zoomOutToCapsules,
    zoomToSelectedRegion,
    alignMeasuredItemWithMetric,
    updateCapsuleGrouping,
    // Exposed for test
    addMetricChildren,
    createFormulaCondition,
    assignFragmentFormulas,
    setItemStatusNotRequired,
    updateStatistics,
    updateConditionLanes,
    removeChartCapsulesProperty,
  };

  // Notify the user if an admin user cancels all server requests
  onAllServerRequestsCanceled(() => {
    warnToast({ messageKey: 'REQUEST_CANCELLATION.ALL_BY_ADMIN' });
  });
  const fetchTableDebounced = _.debounce(() => sqTableBuilderActions.fetchTable(), DEBOUNCE.SHORT);
  const fetchConditionsDebounced = _.debounce(() => {
    service.fetchAllTimebarCapsules();
    service.fetchTableAndChartCapsules();
    $injector.get<ScatterPlotActions>('sqScatterPlotActions').fetchPlot();
  }, DEBOUNCE.SHORT);
  const fetchHiddenDebounced = _.debounce(() => service.fetchHiddenTrendData(), DEBOUNCE.SHORT);
  const fetchCapsuleTimeDebounced = _.debounce(() => {
    service.addCapsuleTimeSegments(true);
    service.createStitchDetails();
  }, DEBOUNCE.SHORT);

  return service;

  /**
   * Enables an "editing mode" for capsule sets. The id specified is the id of the capsule set the
   * user is editing. To enable preview the existing results belonging to that id will be removed from
   * the display and replaced with the new, temporary preview result.
   *
   * @param {String} capsuleSetId - the id of the capsule set that is being edited.
   */
  function setEditModeForCapsuleSet(capsuleSetId: string | number) {
    const oldEditingId = sqTrendSeriesStore.editingId;
    flux.dispatch('TREND_SET_EDITING_CAPSULE_SET_ID', { id: capsuleSetId });
    if (capsuleSetId !== oldEditingId) {
      service.fetchTableAndChartCapsules();
    }
  }

  /**
   * Enables an "editing mode" for calculated series. The id specified is the id of the series the
   * user is editing. To enable preview the existing results belonging to that id will be removed from
   * the display and replaced with the new, temporary preview result.
   *
   * @param {String} seriesId - the id of the calculated series that is being edited.
   */
  function setEditModeForSeries(seriesId: string | number) {
    flux.dispatch('TREND_SET_EDITING_SERIES_ID', { id: seriesId });
  }

  /**
   * Generates capsules for preview purposes.
   *
   * @param {String} formula - the formula to use to generate the capsules
   * @param {Object} parameters - the parameters to use to resolve the formula.
   * @param {String} capsuleSetId - the id of the capsule set or undefined if creating a new search
   * @param {String} color - the color to display the resulting capsules in formatted in hex code (e.g. #CCCCCC)
   * @param {Boolean} [usePost=false] - if true make the request with POST /formula/run instead of GET /formula/run
   *
   * @returns {Promise} that resolves once the results have been added to the chart
   */
  function generatePreviewCapsules(
    formula: string,
    parameters: any,
    capsuleSetId: string,
    color: string,
    usePost = false,
  ) {
    const capsuleSet = sqTrendCapsuleSetStore.findItem(capsuleSetId);

    return cancelGroup(previewCapsulesCancellationGroup)
      .then(() =>
        sqFormula.computeCapsules({
          formula,
          parameters,
          range: sqDurationStore.displayRange,
          cancellationGroup: previewCapsulesCancellationGroup,
          usePost,
        }),
      )
      .then((result) => {
        flux.dispatch('TREND_SET_CHART_CAPSULES_PREVIEW', {
          capsules: result,
          existingCapsuleSet: capsuleSet,
          id: capsuleSetId,
          color,
          formula,
          parameters,
        });
      })
      .catch(() => {
        service.displayEmptyPreviewCapsuleLane();
      });
  }

  /**
   * Cancels the preview capsules
   */
  function cancelPreviewCapsules() {
    return cancelGroup(previewCapsulesCancellationGroup);
  }

  /**
   * Generates series for preview purposes.
   *
   * @param {String} formula - the formula to use to generate the series
   * @param {Object} parameters - the parameters to use to resolve the formula.
   * @param {String} seriesId - the id of the series or undefined if creating a new search
   * @param {String} color - the color to display the resulting capsules in formatted in hex code (e.g. #CCCCCC)
   * @returns {Promise} that resolves once the results have been added to the chart
   */
  function generatePreviewSeries(formula, parameters, seriesId, color): Promise<any> {
    let series = sqTrendSeriesStore.findItem(seriesId);
    const numPixels = Math.min(chartWidth, MAX_SERIES_PIXELS);

    // If loading for the first time we won't have a chartWidth, so no need to fetch data that will be overwritten as
    // soon as the chart is instantiated.
    if (!_.isNumber(chartWidth)) {
      return Promise.resolve();
    }

    if (_.isUndefined(series) && _.startsWith(seriesId, PREVIEW_ID)) {
      series = sqTrendSeriesStore.previewChartItem;
    }

    const laneWidth = `${getMSPerPixelWidth(sqDurationStore.displayRange.duration.asMilliseconds(), numPixels)}ms`;
    const downSampleFormula = sqTrendStore.buildDownsampleFormula(laneWidth, seriesId);
    return cancelGroup(previewSeriesCancellationGroup)
      .then(() =>
        sqFormula.computeSamples({
          formula: `${formula}${sqTrendStore.buildSummarizeFormula(seriesId)}${downSampleFormula}.parallelize()`,
          parameters,
          range: sqDurationStore.displayRange,
          limit: numPixels * SPIKECATCHER_PER_PIXEL,
          cancellationGroup: previewSeriesCancellationGroup,
        }),
      )
      .then(function (result) {
        flux.dispatch('TREND_SET_CHART_SERIES_PREVIEW', {
          formula,
          parameters,
          id: seriesId,
          color,
          samples: result.samples,
          valueUnitOfMeasure: result.valueUnitOfMeasure,
          interpolationMethod: result.interpolationMethod,
          lane: series && series.lane ? series.lane : sqTrendStore.nextLane,
          alignment: series && series.axisAlign ? series.axisAlign : sqTrendStore.nextAlignment,
        });
      })
      .catch(function (e) {
        const id = _.get(series, 'id', PREVIEW_ID);
        flux.dispatch('TREND_SET_CHART_SERIES_PREVIEW', {});
        service.catchItemDataFailure(id, previewSeriesCancellationGroup, e);
      });
  }

  /**
   * Dispatches the TREND_SET_SUMMARY call to the store, updating the dataSummary in the store with the
   * given payload
   * @param summary - The new summary values
   * @param refetch - Trigger refetch time series or not
   */
  function setSummary(
    summary: {
      type: SummaryTypeEnum;
      value: number;
      isSlider: boolean;
      discreteUnits: FrontendDuration;
    },
    refetch = true,
  ) {
    flux.dispatch('TREND_SET_SUMMARY', summary);

    if (refetch) {
      if (sqTrendStore.view === TREND_VIEWS.CAPSULE) {
        addCapsuleTimeSegments(true);
      } else {
        debouncedFetchAllTimeseries = lazyDebounceOf(fetchAllTimeseries, debouncedFetchAllTimeseries);
        debouncedFetchAllTimeseries();
      }
    }
  }

  /**
   * Sets the status for all non-redacted items displayed in the detailsPanel to "not required". This is useful when
   * requests are forcefully cancelled (without the user requesting the cancellation explicitly) and avoiding issues
   * with the loading indicator.
   */
  function setItemStatusNotRequired() {
    _.forEach(
      getAllItems({
        excludeDataStatus: [ITEM_DATA_STATUS.REDACTED],
      }),
      (item) => {
        flux.dispatch('TREND_SET_DATA_STATUS_NOT_REQUIRED', { id: item.id }, PUSH_IGNORE);
      },
    );
  }

  /**
   * Cancels the preview series
   */
  function cancelPreviewSeries() {
    cancelGroup(previewSeriesCancellationGroup);
  }

  /**
   * Removes the preview Capsules from the trend.
   */
  function removePreviewCapsules() {
    flux.dispatch('TREND_REMOVE_CHART_CAPSULES_PREVIEW');
  }

  /**
   * Removes the preview Series from the trend.
   */
  function removePreviewSeries() {
    flux.dispatch('TREND_REMOVE_CHART_SERIES_PREVIEW');
  }

  /**
   * Displays an empty preview lane. Opposed to removePreviewCapsules it does not reset
   * the previous stored capsules if available.
   */
  function displayEmptyPreviewCapsuleLane() {
    flux.dispatch('TREND_DISPLAY_EMPTY_CAPSULE_PREVIEW');
  }

  /**
   * Sets the chart configuration mode
   */
  function setCustomizationMode(customizationMode) {
    flux.dispatch('TREND_SET_CUSTOMIZATION_MODE', { customizationMode });
  }

  /**
   * Sets the visibility of the capsule set names displayed in the capsule lane.
   *
   * @param {Boolean} show - Whether or not to display capsule names
   */
  function setCapsuleLaneLabels(show) {
    flux.dispatch('TREND_SET_SHOW_CAPSULE_LABELS', { showCapsuleLabels: show });
  }

  /**
   * Toggles the visibility of the signal names and units of measure displayed in the signal lane.
   *
   * @param {String} property - The property to be set, must be one of LABEL_PROPERTIES
   * @param {String} value - The value to be set, must be one of LABEL_LOCATIONS
   */
  function setLabelDisplayConfiguration(property, value) {
    const payload = {
      property,
      value,
    };
    flux.dispatch('TREND_SET_LABEL_DISPLAY_CONFIG', payload);
  }

  /**
   * Sets the visibility of the gridlines on the chart.
   * @param showGridlines - true if we want to turn on gridlines, false if we want to turn off gridlines
   * @param skipWarning - (optional, defaults to false) true if we want to skip the "Gridlines can not be shown..."
   *  warning (i.e. if the caller has already shown the warning)
   */
  function setGridlines(showGridlines, skipWarning = false) {
    const payload = { showGridlines, skipWarning };
    flux.dispatch('TREND_SET_GRIDLINES', payload);
  }

  function setIsCompareMode(isCompareMode) {
    flux.dispatch('TREND_SET_IS_COMPARE_MODE', { isCompareMode });
  }

  /**
   * Sets the sort order of a column in a panel.
   *
   * @param {String} panel - The name of the panel. Must be one of TREND_PANELS
   * @param {String} sortBy - The name of the column by which to sort
   * @param {Boolean} sortAsc - True to sort ascending, false otherwise
   * @param {String} [option] - One of the WORKSTEP_PUSH constants
   * @throws TypeError if the panel is not recognized.
   */
  function setPanelSort(panel, sortBy, sortAsc, option?) {
    if (!_.includes(_.values(TREND_PANELS), panel)) {
      throw new TypeError(`${panel} is not a valid panel`);
    }

    flux.dispatch('TREND_SET_PANEL_SORT', { panel, sortBy, sortAsc }, option);

    if (panel === TREND_PANELS.CAPSULES) {
      service.setCapsulePanelOffset(0);
    }
  }

  /**
   * Toggles the sort order of a column in a panel. If switching sort to a new column it defaults to sorting in
   * ascending order.
   *
   * @param {String} panel - The name of the panel. Must be one of TREND_PANELS
   * @param {String} sortBy - The name of the column by which to sort
   */
  function togglePanelSort(panel, sortBy) {
    let currentSort, sortAsc;
    if (!_.includes(_.values(TREND_PANELS), panel)) {
      throw new TypeError(`${panel} is not a valid panel`);
    }

    currentSort = sqTrendStore.getPanelSort(panel);
    sortAsc = currentSort.sortBy === sortBy ? !currentSort.sortAsc : true;
    service.setPanelSort(panel, sortBy, sortAsc);
  }

  /**
   * Sets one or more properties to be applied to a panel
   *
   * @param {String} panel - The name of the panel. Must be one of TREND_PANELS
   * @param {Object} props - Keys and values to apply to the panel
   */
  function setPanelProps(panel, props) {
    if (!_.includes(_.values(TREND_PANELS), panel)) {
      throw new TypeError(`${panel} is not a valid panel`);
    }

    flux.dispatch('TREND_SET_PANEL_PROPS', { panel, props });
    if (panel === TREND_PANELS.BOTTOM) {
      setChartWidth();
    }
  }

  /**
   * Sets the offsets for capsule time
   *
   * @param {Number} lower - The duration of the lower offset
   * @param {Number} upper - The duration of the upper offset
   * @param {String} [option] - One of the WORKSTEP_PUSH constants
   */
  function setCapsuleTimeOffsets(lower, upper, option?) {
    flux.dispatch('TREND_SET_CAPSULE_TIME_OFFSETS', { lower, upper }, option);

    debouncedSetCapsuleTimeOffsets = lazyDebounceOf(onSetCapsuleTimeOffsets, debouncedSetCapsuleTimeOffsets);
    debouncedSetCapsuleTimeOffsets();

    function onSetCapsuleTimeOffsets() {
      service.addCapsuleTimeSegments(true);
    }
  }

  /**
   * Resets the capsule time offsets to their defaults, as though the user has not changed the x-axis.
   */
  function resetCapsuleTimeOffsets() {
    service.setCapsuleTimeOffsets(0, 0);
  }

  /**
   * Sets the selected region on the chart and updates statistics since they are tied to the selected region.
   *
   * @param {Number} min - A timestamp for the minimum side of the range.
   * @param {Number} max - A timestamp for the maximum side of the range.
   * @param {String} [option] - One of the WORKSTEP_PUSH constants
   */
  function setSelectedRegion(min, max, option?) {
    flux.dispatch('TREND_SET_SELECTED_REGION', { min, max }, option);
    service.fetchAllStatistics(true);
  }

  /**
   * Removes the selected region.
   *
   * @param {String} [option] - One of the WORKSTEP_PUSH constants
   */
  function removeSelectedRegion(option?) {
    service.setSelectedRegion(0, 0, option);
  }

  /**
   * Zooms to the selected region.
   *
   * @param {Boolean} [keepRegion=false] - If true, does not remove the selected region
   */
  function zoomToSelectedRegion(keepRegion = false) {
    if (!sqTrendStore.isRegionSelected()) {
      return;
    }

    const min = sqTrendStore.selectedRegion.min;
    const max = sqTrendStore.selectedRegion.max;
    if (sqTrendStore.view === TREND_VIEWS.CAPSULE) {
      service.setCapsuleTimeOffsets(0 + min, max - sqTrendSeriesStore.longestCapsuleSeriesDuration);
    } else {
      sqDurationActions.displayRange.updateTimes(min, max);
    }

    if (!keepRegion) {
      service.removeSelectedRegion();
    }
  }

  /**
   * Zooms out to the selected capsules, or to include all capsules if none are selected.
   */
  function zoomOutToCapsules() {
    const selectedCapsules = _.filter(sqTrendCapsuleStore.items, 'selected');
    const capsules = selectedCapsules.length ? selectedCapsules : sqTrendCapsuleStore.items;
    if (capsules.length) {
      const minTime = (_.chain(capsules).map('startTime') as any).min().value();
      const maxTime = (_.chain(capsules).map('endTime') as any).max().value();
      const inflation = inflateTimes(minTime, maxTime, TREND_CAPSULE_INFLATION);
      sqDurationActions.displayRange.updateTimes(inflation.start, inflation.end);
    }
  }

  function updateCapsuleGrouping() {
    if (sqTrendStore.isTrendViewCapsuleTime()) {
      service.addCapsuleTimeSegments();
    } else if (sqTrendStore.isTrendViewChainView()) {
      service.createStitchDetails();
    }
  }

  /**
   * Changes the view mode. If moving to a new view it prepares for the new chart.  In capsule time it then converts
   * all capsules into timeseries items, because capsule time is a chart displaying the timeseries for a capsule's
   * start and end times.
   *
   * @param {String} view - Set the new view: TREND_VIEWS.CALENDAR, TREND_VIEWS.CHAIN, or TREND_VIEWS.CAPSULE
   */
  function setView(view) {
    const previousView = sqTrendStore.view;
    const requestsToCancel =
      previousView === TREND_VIEWS.CAPSULE ? sqTrendSeriesStore.capsuleSeries : sqTrendSeriesStore.nonCapsuleSeries;

    _.forEach(requestsToCancel, (series: any) => {
      flux.dispatch('TREND_SET_DATA_STATUS_NOT_REQUIRED', { id: series.id }, PUSH_IGNORE);
      return cancelGroup(`fetchTimeseries${series.id}`, true);
    });

    flux.dispatch('TREND_SET_VIEW', { view });

    if (view === TREND_VIEWS.CAPSULE) {
      service.addCapsuleTimeSegments(true);
    } else if (view === TREND_VIEWS.CHAIN) {
      let capsulePromise;
      // re-fetch the chart capsules if we're coming from capsule time as those could have changed (CRAB-20414)
      if (previousView === TREND_VIEWS.CAPSULE) {
        capsulePromise = service.fetchChartCapsules();
      } else {
        capsulePromise = Promise.resolve();
      }

      capsulePromise.then(service.createStitchDetails);
    } else {
      service.fetchAllTimeseries();
      service.fetchChartCapsules();
    }

    // Remove the selected region when navigating to or away from capsule view
    if (
      (previousView !== TREND_VIEWS.CAPSULE && view === TREND_VIEWS.CAPSULE) ||
      (previousView === TREND_VIEWS.CAPSULE && view !== TREND_VIEWS.CAPSULE)
    ) {
      service.removeSelectedRegion();
    }

    // The behavior of fetchTableAndChartCapsules changes slightly in dimming mode
    if (
      (previousView !== TREND_VIEWS.CALENDAR && view === TREND_VIEWS.CALENDAR) ||
      (previousView === TREND_VIEWS.CALENDAR && view !== TREND_VIEWS.CALENDAR)
    ) {
      // This mirrors the logic in `fetchTableAndChartCapsules` for getting the conditions to fetch
      if (
        _.chain(
          getAllItems({
            excludeDataStatus: [ITEM_DATA_STATUS.REDACTED, ITEM_DATA_STATUS.FAILURE, ITEM_DATA_STATUS.CANCELED],
            workingSelection: true,
            excludeEditingCondition: true,
            itemTypes: [ITEM_TYPES.CAPSULE_SET],
          }),
        )
          .some((item) => isHidden({ item }))
          .value()
      ) {
        // If some items in the working selection are hidden we need to re-fetch the capsule table
        // because the conditionIds that we would use to build the table have changed with the view
        service.fetchTableAndChartCapsules();
      }
    }

    if (view !== TREND_VIEWS.CHAIN) {
      flux.dispatch('TREND_SET_STITCH_BREAKS', { stitchBreaks: [] }, PUSH_IGNORE);
      flux.dispatch('TREND_SET_STITCH_TIMES', { stitchTimes: [] }, PUSH_IGNORE);
    }

    // This is more of a sanity check that all the displayed data is shown; if all the data is already shown this
    // will be a no-op.
    service.fetchHiddenTrendData();
  }

  /**
   * Adds all capsule time segments for the given time series and makes each series an interest of its respective
   * capsule. Defaults to adding all capsules for all time series. Existing capsule series are simply updated with
   * new data, new ones are added, and no longer displayed ones are removed. Simply removing all the capsule series
   * would cause an undesirable flicker when auto-updated is enabled.
   *
   * @param {boolean} [isFetchRequired] - True if fetching the data is required for existing capsule series
   * @param {Array<Item>} [seriesToUpdate] - The array of time series for which capsule time segments will be added.
   * If not provided, defaults to all capsules in the capsule store
   * @returns {Promise} Resolves when all timeseries have been fetched
   */
  function addCapsuleTimeSegments(isFetchRequired = false, seriesToUpdate = []) {
    const MAX_CAPSULE_TIME_ITEMS = sqTrendStore.getMaxCapsuleTimeItems();

    if (!sqTrendStore.isTrendViewCapsuleTime() || sqTrendStore.capsulePanelIsLoading) {
      return;
    }
    let itemsOfInterest;
    let existingCapsuleSeries;
    if (_.isEmpty(seriesToUpdate)) {
      itemsOfInterest =
        sqTrendStore.hideUnselectedItems && _.some(sqTrendSeriesStore.nonCapsuleSeries, ['selected', true])
          ? _.filter(sqTrendSeriesStore.nonCapsuleSeries, ['selected', true])
          : sqTrendSeriesStore.nonCapsuleSeries;
      existingCapsuleSeries = sqTrendSeriesStore.capsuleSeries;
    } else {
      itemsOfInterest =
        sqTrendStore.hideUnselectedItems && _.some(seriesToUpdate, ['selected', true])
          ? _.filter(seriesToUpdate, ['selected', true])
          : seriesToUpdate;
      existingCapsuleSeries = _.filter(sqTrendSeriesStore.capsuleSeries, (capsuleSeries) =>
        _.find(itemsOfInterest, { id: capsuleSeries.interestId }),
      );
    }

    _.forEach(existingCapsuleSeries, (capsuleSeries) => {
      flux.dispatch('TREND_SET_PROPERTIES', { id: capsuleSeries.id, dirty: true }, PUSH_IGNORE);
    });

    let allCapsuleSeriesLoads;
    let isCapsuleTimeLimited = false;
    let visibleItemsCount = 0;
    let visibleCapsulesCount = 0;

    const manageSignalSegmentsLoad = (interest, capsules) => {
      // We only want to run the code below if the capsule will appear on the trend
      if (isHidden({ item: interest })) {
        return;
      }

      const capsulesToLoad = [];
      _.forEach(capsules, (capsule) => {
        const existingCapsuleSeries: any = _.find(sqTrendSeriesStore.capsuleSeries, {
          capsuleId: capsule.id,
          interestId: interest.id,
        });
        if (existingCapsuleSeries) {
          const { properties } = capsule;
          let compareProperties = {};
          if (!_.isEmpty(properties)) {
            const { column, compareBy } = existingCapsuleSeries;
            compareProperties = {
              column: properties[sqTrendStore.separateByProperty] || column,
              colorBy: properties[sqTrendStore.colorByProperty] || compareBy,
            };
          }
          flux.dispatch(
            'TREND_SET_PROPERTIES',
            {
              id: existingCapsuleSeries.id,
              dirty: false,
              ...compareProperties,
            },
            PUSH_IGNORE,
          );
        } else {
          flux.dispatch('TREND_ADD_SERIES_FROM_CAPSULE', { capsule, interest }, PUSH_IGNORE);
          capsulesToLoad.push(capsule);
        }
      });
      if (isFetchRequired) {
        return service.fetchSignalSegments(interest.id, capsules);
      }
      if (capsulesToLoad.length > 0) {
        return service.fetchSignalSegments(interest.id, capsulesToLoad);
      }
    };

    // If capsule group mode is enabled then only signals that are paired to a condition are shown during the
    // conditions capsules. This requires filtering of the capsules by condition and then explicit loading of only
    // the grouped signals.
    if (sqWorksheetStore.capsuleGroupMode) {
      let seriesCapsulePairList = _.chain(_.keys(sqWorksheetStore.conditionToSeriesGrouping))
        .flatMap((conditionId) => {
          // find the capsules that belong to a given condition
          const condition = { isChildOf: conditionId, notFullyVisible: false };
          _.assign(
            condition,
            sqTrendStore.hideUnselectedItems && _.some(sqTrendCapsuleStore.items, { selected: true })
              ? { selected: true }
              : {},
          );
          return _.filter(sqTrendCapsuleStore.items, condition);
        })
        .flatMap((capsule: any) =>
          _.chain(itemsOfInterest)
            .filter((series) =>
              // ensure only linked signal's segments are loaded.
              _.includes(sqWorksheetStore.conditionToSeriesGrouping[capsule.isChildOf], series.id),
            )
            .compact()
            .reject('isChildOf')
            .map((interest) => {
              return { interest, capsule };
            })
            .value(),
        )
        .value();

      if (seriesCapsulePairList.length > MAX_CAPSULE_TIME_ITEMS) {
        seriesCapsulePairList = seriesCapsulePairList.slice(0, MAX_CAPSULE_TIME_ITEMS);
        isCapsuleTimeLimited = true;
        visibleItemsCount = _.uniqBy(seriesCapsulePairList, 'interest').length;
        visibleCapsulesCount = _.uniqBy(seriesCapsulePairList, 'capsule').length;
      }
      const capsulesBySeriesId: Record<string, object[]> = _.reduce(
        seriesCapsulePairList,
        (result, value) => {
          const signalId = value.interest.id;
          (result[signalId] || (result[signalId] = [])).push(value.capsule);
          return result;
        },
        {},
      );

      allCapsuleSeriesLoads = _.chain(capsulesBySeriesId)
        .flatMap((capsules, interestId) => {
          const interest = sqTrendSeriesStore.findItem(interestId);
          return manageSignalSegmentsLoad(interest, capsules);
        })
        .thru((promises) => Promise.all(promises))
        .value();
    } else {
      const capsules =
        sqTrendStore.hideUnselectedItems && _.some(sqTrendCapsuleStore.items, ['selected', true])
          ? _.filter(sqTrendCapsuleStore.items, ['selected', true])
          : sqTrendCapsuleStore.items;
      const visibleCapsules = _.reject(capsules, (capsule) => !isTimestampVisible(capsule.startTime));
      const allVisibleItems = _.chain(itemsOfInterest)
        .reject((item) => isHidden({ item }))
        .reject('isChildOf')
        .value();
      // low probability to trend more than MAX_CAPSULE_TIME_ITEMS items, but if this happens, take
      // MAX_CAPSULE_TIME_ITEMS items and show one capsule for each of them
      const displayableItems = allVisibleItems.slice(0, Math.min(MAX_CAPSULE_TIME_ITEMS, allVisibleItems.length));
      const maxSegmentsPerItem = MAX_CAPSULE_TIME_ITEMS / displayableItems.length;

      const segmentsToLoad = visibleCapsules.slice(0, Math.min(maxSegmentsPerItem, visibleCapsules.length));
      allCapsuleSeriesLoads = _.chain(displayableItems)
        .flatMap((interest) => manageSignalSegmentsLoad(interest, segmentsToLoad))
        .thru((promises) => Promise.all(promises))
        .value();

      visibleItemsCount = allVisibleItems.length;
      visibleCapsulesCount = visibleCapsules.length;
      isCapsuleTimeLimited = visibleItemsCount * visibleCapsulesCount > MAX_CAPSULE_TIME_ITEMS;
    }

    flux.dispatch(
      'TREND_SET_CAPSULE_TIME_LIMITED',
      {
        enabled: isCapsuleTimeLimited,
        itemsCount: visibleItemsCount,
        capsulesCount: visibleCapsulesCount,
      },
      PUSH_IGNORE,
    );

    return allCapsuleSeriesLoads.finally(() => {
      updateLaneDisplay(this);
      const seriesFromCapsules = _.chain(sqTrendSeriesStore.capsuleSeries)
        .filter('dirty')
        .map((capsuleSeries) => {
          return {
            capsuleId: capsuleSeries.capsuleId,
            interestId: capsuleSeries.interestId,
          };
        })
        .value();
      if (!_.isEmpty(seriesFromCapsules)) {
        flux.dispatch('TREND_REMOVE_SERIES_FROM_CAPSULE', { seriesFromCapsules }, PUSH_IGNORE);
      }
      service.setCapsuleTimeColorMode();
    });
  }

  /**
   * Removes all unselected capsule for each series (also removes each capsule as an interest of the capsule).
   */
  function removeUnselectedSeriesFromCapsules() {
    const removeUnselectedConditions =
      !sqTrendStore.isTrendViewCapsuleTime() || _.some(sqTrendCapsuleStore.items, ['selected', true]);
    const removeUnselectedSeries =
      !sqTrendStore.isTrendViewCapsuleTime() || _.some(sqTrendSeriesStore.nonCapsuleSeries, ['selected', true]);
    const remainingSeriesFromCapsules = [];
    const seriesFromCapsules = [];
    _.forEach(sqTrendCapsuleStore.items, function (capsule) {
      _.forEach(sqTrendSeriesStore.nonCapsuleSeries, function (interest) {
        if ((!capsule.selected && removeUnselectedConditions) || (!interest.selected && removeUnselectedSeries)) {
          seriesFromCapsules.push({
            capsuleId: capsule.id,
            interestId: interest.id,
          });
        } else {
          remainingSeriesFromCapsules.push({
            capsuleId: capsule.id,
            interestId: interest.id,
          });
        }
      });
    });
    if (!_.isEmpty(seriesFromCapsules)) {
      flux.dispatch('TREND_REMOVE_SERIES_FROM_CAPSULE', { seriesFromCapsules });
    }

    const visibleItemsCount = _.uniqBy(remainingSeriesFromCapsules, 'interestId').length;
    const visibleCapsulesCount = _.uniqBy(remainingSeriesFromCapsules, 'capsuleId').length;
    const isCapsuleTimeLimited = visibleItemsCount * visibleCapsulesCount > sqTrendStore.getMaxCapsuleTimeItems();

    flux.dispatch(
      'TREND_SET_CAPSULE_TIME_LIMITED',
      {
        enabled: isCapsuleTimeLimited,
        itemsCount: visibleItemsCount,
        capsulesCount: visibleCapsulesCount,
      },
      PUSH_IGNORE,
    );
  }

  /**
   * Removes a property from TREND_PANELS.CHART_CAPSULES
   *
   * @param property
   */
  function removeChartCapsulesProperty(property: PropertyColumn) {
    service.setColumnEnabled(TREND_PANELS.CHART_CAPSULES, property.key, false, PUSH_IGNORE);
    flux.dispatch('TREND_REMOVE_PROPERTY_COLUMN', {
      panel: TREND_PANELS.CHART_CAPSULES,
      property,
    });
  }

  /**
   * Sets a column to be enabled or disabled in the specified panel
   *
   * @param {TREND_PANELS} panel - The name of the panel. Must be one of TREND_PANELS
   * @param {String|Object} column - The column being toggled. Must be one of TREND_COLUMNS or TREND_SIGNAL_STATS unless
   * it is a custom column definition.
   * @param {Boolean} enabled - True if the column is enabled, false otherwise
   * @param {String} [option] - One of the WORKSTEP_PUSH constants
   * @throws TypeError if the column or panel is not recognized.
   */
  function setColumnEnabled(panel: TREND_PANELS, column, enabled, option?) {
    flux.dispatch(
      'TREND_SET_COLUMN_ENABLED',
      {
        panel,
        column: _.isString(column) ? column : column.key,
        columnDefinition: _.isString(column) ? undefined : column,
        enabled,
      },
      option,
    );

    if (enabled && _.some(TREND_SIGNAL_STATS.concat(TREND_CONDITION_STATS), ['key', column])) {
      service.fetchAllStatistics(true);
    }

    if (
      enabled &&
      (column === DESCRIPTION ||
        column === DATASOURCE_NAME ||
        _.some(sqTrendStore.propertyColumns(TREND_PANELS.SERIES), _.flow(_.property('key'), _.partial(_.eq, column))))
    ) {
      service.fetchPropsForAllItems();
    }

    if (enabled && sqTrendStore.customizationMode !== CUSTOMIZATION_MODES.OFF) {
      service.setCustomizationMode(CUSTOMIZATION_MODES.OFF);
    }

    if (enabled && panel === TREND_PANELS.CAPSULES) {
      service.fetchTableAndChartCapsules();
    }

    if (panel === TREND_PANELS.CHART_CAPSULES) {
      flux.dispatch('TREND_RECOMPUTE_CHART_CAPSULES', {}, PUSH_IGNORE);
    }
  }

  /**
   * Toggles a particular column on or off and then refreshes the statistics.
   * Hides the customize panel if it is showing and the column is being toggled on so the statistics are visible.
   *
   * @param panel - The name of the panel. Must be one of TREND_PANELS
   * @param columnKey - The column being toggled. Must be one of TREND_COLUMNS.
   */
  function toggleColumn(panel: TREND_PANELS, columnKey: string) {
    const shouldEnable = !sqTrendStore.isColumnEnabled(panel, columnKey);
    service.setColumnEnabled(panel, columnKey, shouldEnable);
    if (
      shouldEnable &&
      panel === TREND_PANELS.CHART_CAPSULES &&
      !sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, columnKey)
    ) {
      service.setColumnEnabled(TREND_PANELS.CAPSULES, columnKey, true);
    }
    service.removeColumnFilter(columnKey);

    return shouldEnable;
  }

  /**
   * Toggles a custom statistics column for the capsule panel.
   *
   * @param {Object} statistic - One of the TREND_SIGNAL_STATS
   * @param {String} itemId - id of a series
   */
  function toggleStatisticsColumn(statistic, itemId) {
    const column = {
      key: `${statistic.key}.${itemId}`,
      referenceSeries: itemId,
      statisticKey: statistic.key,
    };
    const panel = TREND_PANELS.CAPSULES;
    const wasEnabled = sqTrendStore.isColumnEnabled(panel, column.key);
    service.setColumnEnabled(panel, column, !wasEnabled);
  }

  /**
   * Adds a property column that retrieves propertyName from the item. Column will be enabled.
   *
   * @param {String} panel - The name of the panel. Must be one of TREND_PANELS
   * @param {Object} propertyInput - object to be used as a column template definition.
   * @param {String} propertyInput.propertyName - the name of the property that will be requested with `getProperty`
   * @param {boolean} forceEnabled - True to force the column enabled, regardless of its current status
   */
  function addPropertiesColumn(panel, propertyInput, forceEnabled = false) {
    const property = _.assign(
      {
        key: PROPERTIES_COLUMN_PREFIX + propertyInput.propertyName,
        uomKey: PROPERTIES_UOM_COLUMN_PREFIX + propertyInput.propertyName,
      },
      propertyInput,
    );
    flux.dispatch('TREND_ADD_PROPERTY_COLUMN', { panel, property }, PUSH_IGNORE);
    service.setColumnEnabled(panel, property.key, forceEnabled || !sqTrendStore.isColumnEnabled(panel, property.key));
    if (panel === TREND_PANELS.CHART_CAPSULES && !sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, property.key)) {
      flux.dispatch('TREND_ADD_PROPERTY_COLUMN', { panel: TREND_PANELS.CAPSULES, property }, PUSH_IGNORE);
      service.setColumnEnabled(
        TREND_PANELS.CAPSULES,
        property.key,
        forceEnabled || !sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, property.key),
      );
    }
  }

  function addMultiplePropertiesColumn(panel: TREND_PANELS, propertyInputs: any[], forceEnabled = false) {
    const properties = _.map(propertyInputs, (propertyInput) =>
      _.assign(
        {
          key: PROPERTIES_COLUMN_PREFIX + propertyInput.propertyName,
          uomKey: PROPERTIES_UOM_COLUMN_PREFIX + propertyInput.propertyName,
        },
        propertyInput,
      ),
    );
    flux.dispatch('TREND_ADD_MULTIPLE_PROPERTY_COLUMNS', { panel, properties }, PUSH_IGNORE);
    _.forEach(properties, (property) =>
      service.setColumnEnabled(panel, property.key, forceEnabled || !sqTrendStore.isColumnEnabled(panel, property.key)),
    );
  }

  /**
   * Checks if the column is a property column
   * @param column - the column to be checked
   * @returns true for a property column, false otherwise.
   */
  function isPropertyColumn(column): boolean {
    return !!(column.propertyName && column.key === PROPERTIES_COLUMN_PREFIX + column.propertyName);
  }

  /**
   * Removes a property column
   *
   * @param {String} panel - The name of the panel. Must be one of TREND_PANELS
   * @param {Object} property - column definition (created by `addPropertiesColumn`).
   */
  function removePropertiesColumn(panel, property) {
    if (sqTrendStore.isColumnEnabled(panel, property.key)) {
      service.setColumnEnabled(panel, property.key, false, PUSH_IGNORE);
    }

    flux.dispatch('TREND_REMOVE_PROPERTY_COLUMN', { panel, property });
    if (panel === TREND_PANELS.CAPSULES && sqTrendStore.isColumnEnabled(TREND_PANELS.CHART_CAPSULES, property.key)) {
      service.removeChartCapsulesProperty(property);
    }
    service.removeColumnFilter(property.propertyName ?? property.key);
  }

  /**
   * Sets the color mode for the lines in each lane of capsule time. There are four different modes:
   * - Signal: every line gets its color from its signal, regardless of its condition
   * - Rainbow: every line gets a unique color, except that sort-order acts as a proxy for grouping colors
   * - SignalGradient: the base color is that of the signal and then a gradient is computed from a lighter version
   * of the color. The same grouping rules apply as in Rainbow.
   * - ConditionGradient: the base color is that of the condition and then a gradient is computed from a lighter version
   * of the color. The same grouping rules apply as in Rainbow.
   *
   * @param {CapsuleTimeColorMode} mode - The color mode
   */
  function setCapsuleTimeColorMode(mode: CapsuleTimeColorMode = sqTrendStore.capsuleTimeColorMode) {
    const pushMode = mode === sqTrendStore.capsuleTimeColorMode ? PUSH_IGNORE : undefined;
    flux.dispatch('TREND_SET_CAPSULE_TIME_COLOR_MODE', { mode }, pushMode);
    const sortBy = sqTrendStore.getPanelSort(TREND_PANELS.CAPSULES).sortBy;
    const capsules = _.reject(sqTrendCapsuleStore.items, 'notFullyVisible') as any[];
    const requiredColorsCount = _.uniqBy(capsules, (capsule) => _.get(capsule, sortBy)).length;
    const itemsForGradients =
      {
        [CapsuleTimeColorMode.ConditionGradient]: sqTrendCapsuleSetStore.items,
        [CapsuleTimeColorMode.SignalGradient]: sqTrendSeriesStore.nonCapsuleSeries,
      }[mode] || [];
    const colorGradients = _.transform(
      itemsForGradients as any[],
      (memo, item) => {
        memo[item.id] =
          requiredColorsCount < 2
            ? [item.color]
            : _.chain(computeLightestColor(item.color, 0.2))
                .thru((lightestColor) => tinygradient([lightestColor, item.color]).rgb(requiredColorsCount))
                .map((color) => color.toString('rgb'))
                .value();
      },
      {} as { string: string[] },
    );

    let previousGroupByValue;
    const previousColors = {};
    let index = 0;
    _.forEach(capsules, (capsule) => {
      let capsuleValue = _.get(capsule, sortBy);
      if (_.startsWith(sortBy, 'statistics')) {
        capsuleValue = formatNumber(capsuleValue, capsule.formatOptions);
      }

      const isGrouped =
        !_.isUndefined(previousGroupByValue) &&
        previousGroupByValue === capsuleValue &&
        mode !== CapsuleTimeColorMode.Signal;

      _.chain(sqTrendSeriesStore.findChildren(capsule.id))
        .filter({ childType: ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE })
        .forEach((signal) => {
          const [color, childColor] = isGrouped ? previousColors[signal.isChildOf] : computeColors(signal);
          previousColors[signal.isChildOf] = [color, childColor];

          service.setItemColor(signal.id, color, pushMode);
          service.setTrendItemProps(capsule.id, { childColor: childColor || color }, pushMode);
        })
        .value();

      if (!isGrouped) {
        index++;
      }
      previousGroupByValue = capsuleValue;

      function computeColors(signal: any): [string, string?] {
        switch (mode) {
          case CapsuleTimeColorMode.Rainbow:
            return [TREND_COLORS[index % TREND_COLORS.length]];
          case CapsuleTimeColorMode.ConditionGradient:
            const conditionGradient = colorGradients[capsule.isChildOf];
            return [conditionGradient[index % conditionGradient.length]];
          case CapsuleTimeColorMode.SignalGradient:
            const signalGradient = colorGradients[signal.isChildOf];
            const color = signalGradient[index % signalGradient.length];
            return [color, tinycolor(color).greyscale().toString('rgb')];
          case CapsuleTimeColorMode.Signal:
            return [sqTrendSeriesStore.findItem(signal.isChildOf).color];
          default:
            throw new TypeError(`Unknown capsule time color mode: ${mode}`);
        }
      }
    });
  }

  function setCompareViewColorMode(mode: CompareViewColorMode) {
    flux.dispatch('TREND_SET_COMPARE_VIEW_COLOR_MODE', { mode });
  }

  /**
   * Dispatches to the store to create, and set, the stitch details
   *
   * @returns {Promise} Resolves when data for stitches is fetched.
   */
  function createStitchDetails() {
    if (sqTrendStore.view !== TREND_VIEWS.CHAIN) {
      return Promise.resolve();
    }

    flux.dispatch('TREND_SET_STITCH_DETAILS', { numPixels: Math.min(chartWidth, MAX_SERIES_PIXELS) }, PUSH_IGNORE);

    if (sqTrendCapsuleStore.stitchDetailsSet) {
      return service.fetchAllTimeseries();
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Fetches statistics for all signals and conditions in the details pane.
   *
   * @param {boolean} fetchSeries - True if the stats for series should be fetched in addition to capsule sets stats
   */
  function fetchAllStatistics(fetchSeries = false) {
    const itemTypes = fetchSeries ? [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET] : [ITEM_TYPES.CAPSULE_SET];
    return _.chain(
      getAllItems({
        itemTypes,
        itemChildrenTypes: [ITEM_CHILDREN_TYPES.METRIC_DISPLAY],
      }),
    )
      .map(_.unary(service.fetchStatistics))
      .thru((p) => Promise.all(p))
      .value();
  }

  /**
   * Updates the statistics for conditions or signals. This entails figuring out the correct time range for which to
   * request stats. It is either the selected region, if present, or the display range. A formula is built that
   * returns a statistic that corresponds to each enabled stat column in the details pane.
   *
   * @param {Object} item - The condition or signal
   * @return {Promise} Resolves when all the statistics finish computing
   */
  function fetchStatistics(item) {
    const stats = _.chain(item.itemType === ITEM_TYPES.SERIES ? TREND_SIGNAL_STATS : TREND_CONDITION_STATS)
      .filter((stat: any) => sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, stat.key))
      .filter((stat: any) => (isStringSeries(item) ? stat.isStringCompatible : true))
      .value();

    if (
      (item.childType && item.childType !== ITEM_CHILDREN_TYPES.METRIC_DISPLAY) ||
      _.isEmpty(stats) ||
      sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREND ||
      isPresentationWorkbookMode()
    ) {
      return Promise.resolve();
    }

    const viewCapsule = getCapsuleFormula(
      sqTrendStore.isRegionSelected()
        ? {
            start: sqTrendStore.selectedRegion.min,
            end: sqTrendStore.selectedRegion.max,
          }
        : sqDurationStore.displayRange,
    );
    const enums = _.map(stats, 'stat').join(', ');

    let dispatchItemId = item.id;

    // Compute statistics on the metric's display signal but dispatch the results to the metric item.
    if (item.childType === ITEM_CHILDREN_TYPES.METRIC_DISPLAY) {
      const parentMetric = sqTrendMetricStore.findItem(item.isChildOf);
      const metricProcessType = _.get(parentMetric, 'definition.processType');

      if (metricProcessType && metricProcessType !== ProcessTypeEnum.Simple) {
        dispatchItemId = parentMetric.id;
      } else {
        // We can't compute statistics for simple metrics because display signal is a formula function
        // Reset in case the type has changed
        flux.dispatch('TREND_SET_STATISTICS', { id: parentMetric.id, statistics: {} }, PUSH_IGNORE);
        return Promise.resolve();
      }
    }

    const cancellationGroup = `stats${CANCELLATION_GROUP_GUID_SEPARATOR}${item.id}`;
    const formula = `group(${viewCapsule}).toTable('stats').addStatColumn('series', $series, ${enums})`;
    const dispatchParams = { id: dispatchItemId, statistics: {} };
    // Reset all enabled statistics for this item before requesting new values
    flux.dispatch('TREND_SET_STATISTICS', dispatchParams, PUSH_IGNORE);
    return cancelGroup(cancellationGroup, true)
      .then(() =>
        sqFormula
          .computeTable({
            formula,
            parameters: { series: getItemId(item) },
            cancellationGroup,
          })
          .then((table: { data: any[] }) => {
            _.chain(table.data[0]) // Only ever one row of data
              .drop(2) // First two columns are always start and end time
              .forEach((val, i) => _.set(dispatchParams, stats[i].key, val))
              .value();
            flux.dispatch('TREND_SET_STATISTICS', dispatchParams, PUSH_IGNORE);
          }),
      )
      .catch((e) => service.catchItemDataFailure(item.id, cancellationGroup, e));
  }

  /**
   * Adds an item that comes from the REST API to one of the item stores. Since this is meant to be invoked when the
   * user decides to graph a new item it changes to chain, if the user is in capsule time.
   *
   * This function also fetches statistics (if enabled).
   *
   * @param {Object} item - The item to add with a type property that identifies what type of item it is.
   * @param [props] - additional properties to be set on the item before fetching
   * @param [option] - One of the WORKSTEP_PUSH constants
   * @param skipDependencies - flags whether to skip property dependencies
   * @throws TypeError if the item type is not recognized.
   * @return {Object} A promise that resolves with the item when it is finished fetching.
   */
  function addItem(item, props?: object, option?: string, skipDependencies = false): Promise<any> {
    let promise: Promise<any>;
    let doUpdateLaneDisplay = true;
    const type = getItemType(item) || item.type; // Journals don't have an ITEM_TYPE so we compare with the API_TYPES
    const existingItem = findItemIn(TREND_STORES, item.id);

    // If adding an item that was a child remove it first so it will get a new color and lane
    if (existingItem && existingItem.isChildOf) {
      service.removeItems([existingItem]);
    }

    if (ITEM_TYPES.SERIES === type) {
      promise = addSeries(item, props, option);
    } else if (ITEM_TYPES.SCALAR === type) {
      promise = addScalar(item, props, option);
    } else if (ITEM_TYPES.CAPSULE_SET === type) {
      promise = addCapsuleSet(item, props, option);
    } else if (ITEM_TYPES.TABLE === type) {
      doUpdateLaneDisplay = false;
      promise = sqTrendTableActions.addTable(item, props, option);
    } else if (ITEM_TYPES.METRIC === type) {
      promise = addMetric(item, props, option);
    } else if (API_TYPES.JOURNAL === type) {
      return addAnnotation(item);
    } else {
      throw new TypeError(`item type "${type}" is not trendable`);
    }

    if (doUpdateLaneDisplay) {
      // Called before data is fetched so that the existing items 'move' out of the way (CRAB-8386).
      updateLaneDisplay(this);
    }

    // Wait for promise to resolve because we need calculationType to be set on the item
    return promise.then(() => {
      if (!skipDependencies) {
        service.fetchTableAndChartCapsules();
        $injector.get<InvestigateActions>('sqInvestigateActions').updateDerivedDataTree();
        sqTableBuilderActions.fetchTable();
        sqAnnotationActions.fetchAnnotations();
      }

      const addedItem = findItemIn(TREND_STORES, item.id);
      if (addedItem && !addedItem.isChildOf) {
        if (_.some(getAllItems({}), 'selected')) {
          service.setItemSelected(item, true);
        }

        if ($state.params.workbookId) {
          addRecentlyAccessed($state.params.workbookId, item.id, sqHomeScreenUtilities);
        }

        if (
          addedItem.lane &&
          getAllItems({}).length > 1 &&
          !sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, 'lane')
        ) {
          service.setColumnEnabled(TREND_PANELS.SERIES, 'lane', true, PUSH_IGNORE);
        }

        if (!_.isEmpty(addedItem.assets)) {
          service.setColumnEnabled(TREND_PANELS.SERIES, 'asset', true, PUSH_IGNORE);
        }
      }

      return addedItem;
    });
  }

  function batchAddItems(items: any[], isSeparate = true) {
    const extraProps = isSeparate
      ? undefined
      : {
          lane: sqTrendStore.nextLane,
          alignment: sqTrendStore.nextAlignment,
          yAxisType: _.reject(items, isStringSeries)[0]?.yAxisType,
        };
    items.forEach((item) => {
      let newItem = item;
      if (extraProps) {
        newItem = {
          ...item,
          ..._.omitBy(
            {
              lane: extraProps.lane,
              axisAlign: isStringSeries(item) ? undefined : extraProps.alignment,
              yAxisType: isStringSeries(item) ? undefined : extraProps.yAxisType,
            },
            _.isUndefined,
          ),
        };
      }
      service.addItem(newItem);
    });
    if (!isSeparate && _.some(items, isStringSeries) && items.length > 1) {
      infoToast({ messageKey: 'NO_STRING_DATA_Y_AXIS_SHARING' });
    }

    flux.dispatch('SEARCH_CLEAR_SELECTED_ITEMS');
  }

  /**
   * Adds an Annotation which just involves adding its interests and switching the tab, because the annotation tab will
   * update to include annotations matching the interest.
   *
   * @param {Object} item - An annotation from the REST API.
   * @return {Object} A promise that will be fulfilled when it is finished adding the annotation.
   */
  function addAnnotation(item) {
    return sqAnnotationsApi.getAnnotation({ id: item.id }).then(({ data: annotation }) => {
      sqAnnotationActions.showEntry(annotation.id);
      return _.chain(annotation.interests)
        .map('item')
        .filter(isTrendable)
        .map((item) => service.addItem(item))
        .value();
    });
  }

  /**
   * Adds a CapsuleSet.
   *
   * @param {Object} item - A capsule set item from the REST API.
   * @param {Object} [props] - additional properties to be set on the item before fetching
   * @param {String} [option] - One of the WORKSTEP_PUSH constants
   * @return {Object} A promise that will be fulfilled when it is finished fetching.
   */
  function addCapsuleSet(item, props?, option?) {
    if (!sqTrendCapsuleSetStore.findItem(item.id)) {
      const payload = {
        id: item.id,
        name: item.name,
        isArchived: !!item.isArchived,
        color: item.color,
        effectivePermissions: item.effectivePermissions,
        conditionLane: sqTrendStore.conditionLanes.length + 1,
      };

      flux.dispatch('TREND_ADD_CAPSULE_SET', payload, option);
    }

    service.setTrendItemProps(item.id, props, option);

    // Fetch chart and details first to get it on screen quickly and to ensure formula warnings show (CRAB-11702)
    return Promise.all([
      service.fetchItemProps(item.id).then(() => {
        addCondition({
          id: item.id,
          sqWorksheetActions,
        });
      }),
      service.fetchChartCapsules([item]),
    ]).then(() =>
      Promise.all([
        fetchTreemap(),
        service.fetchTimebarCapsules(item.id),
        service.fetchTableAndChartCapsules(),
        service.fetchStatistics(sqTrendCapsuleSetStore.findItem(item.id)),
      ]),
    );
  }

  /**
   * Adds a series item and fetches the data for it.
   *
   * @param {Object} item - A timeseries item from the REST API.
   * @param {Object} [props] - additional properties to be set on the item before fetching
   * @param {String} [option] - One of the WORKSTEP_PUSH constants
   * @return {Object} A promise that will be fulfilled when it is finished fetching.
   */
  function addSeries(item, props?, option?) {
    if (!sqTrendSeriesStore.findItem(item.id)) {
      const payload = {
        id: item.id,
        interpolationMethod: _.find(item?.properties, {
          name: SeeqNames.Properties.InterpolationMethod,
        }),
        name: item.name,
        isArchived: !!item.isArchived,
        lane: item.lane ? item.lane : sqTrendStore.nextLane,
        alignment: item.axisAlign ? item.axisAlign : sqTrendStore.nextAlignment,
        color: item.color,
        effectivePermissions: item.effectivePermissions,
      };

      flux.dispatch('TREND_ADD_SERIES', payload, option);
    }

    service.setTrendItemProps(item.id, props, option);

    return Promise.all([
      service.fetchItemProps(item.id).then(() => {
        addSignal({
          id: item.id,
          sqWorksheetActions,
          trendStores: TREND_STORES,
        });
      }),
      service.fetchTimeseries(item.id),
    ]);
  }

  /**
   * Adds a scalar item and fetches the data for it.
   *
   * @param {Object} item - A scalar item from the REST API.
   * @param {Object} [props] - additional properties to be set on the item before fetching
   * @param {String} [option] - One of the WORKSTEP_PUSH constants
   * @return {Object} A promise that will be fulfilled when it is finished fetching.
   */
  function addScalar(item, props?, option?) {
    if (!sqTrendScalarStore.findItem(item.id)) {
      const payload = {
        id: item.id,
        name: item.name,
        isArchived: !!item.isArchived,
        lane: item.lane ? item.lane : sqTrendStore.nextLane,
        alignment: item.axisAlign ? item.axisAlign : sqTrendStore.nextAlignment,
        color: item.color,
        effectivePermissions: item.effectivePermissions,
      };

      // If we are adding a scalar to a chart with only one lane and alignment displaying, add it to the displayed
      // ones
      const uniqueLanes = sqTrendStore.uniqueLanes;
      const uniqueAlignments = sqTrendStore.uniqueAlignments;
      if (uniqueLanes.length === 1 && uniqueAlignments.length === 1) {
        payload.lane = uniqueLanes[0];
        payload.alignment = uniqueAlignments[0];
      }

      flux.dispatch('TREND_ADD_SCALAR', payload, option);
    }

    service.setTrendItemProps(item.id, props, option);

    return Promise.all([service.fetchItemProps(item.id), service.fetchScalar(item.id)]);
  }

  /**
   * Adds a metric item and fetches the data for it. Also adds the metric bounding condition if the process type is
   * Batch.
   *
   * @param {Object} item - A scalar item from the REST API.
   * @param {Object} [props] - additional properties to be set on the item before fetching
   * @param {String} [option] - One of the WORKSTEP_PUSH constants
   * @return {Object} A promise that will be fulfilled when it is finished fetching.
   */
  function addMetric(item, props?, option?) {
    if (!sqTrendMetricStore.findItem(item.id)) {
      const payload = {
        id: item.id,
        lane: item.lane ? item.lane : sqTrendStore.nextLane,
        alignment: item.axisAlign ? item.axisAlign : sqTrendStore.nextAlignment,
        name: item.name,
        isArchived: !!item.isArchived,
        color: item.color,
        effectivePermissions: item.effectivePermissions,
      };

      flux.dispatch('TREND_ADD_METRIC', payload, option);
    }

    service.setTrendItemProps(item.id, props, option);

    return Promise.all([service.fetchItemProps(item.id)]).then(() => {
      // If metric is Condition then automatically add the bounding condition to the trend when the metric is added
      const addedItem = findItemIn(TREND_STORES, item.id);
      const metricProcessType = _.get(addedItem, 'definition.processType');
      const metricBoundingCondition = _.get(addedItem, 'definition.boundingCondition');
      if (metricProcessType === ProcessTypeEnum.Condition && metricBoundingCondition) {
        service.addItem(metricBoundingCondition, props, PUSH_IGNORE);
      }
    });
  }

  /**
   * Given a specification for an asset swap, calculates the new ID for each item to be swapped
   * using the findSwap API endpoint.
   *
   * @param {SwapPairs} swapPairsForItems - each key is an item ID; each value is the list of swap pairs to be used
   *    for the swap
   * @param {string[]} allItems - an array containing the IDs of all current trend items. Usually the output from
   *    getAllItems from trendDataHelper
   * @param {string[]} itemIdsToSwap - an array containing the IDs out of allItems that should be swapped
   * @param {boolean} suppressWarnings - if true, this function will not raise toast messages to the user for swaps
   *    that don't exist and swaps that are already in the details pane
   * @returns {Object} - dictionary mapping old items IDs to their swapped item IDs
   */
  function getSwaps(swapPairsForItems: SwapPairs, allItems, itemIdsToSwap, suppressWarnings = false) {
    return _.chain(itemIdsToSwap)
      .filter((id: any) => _.has(swapPairsForItems, id))
      .map(function (id: string) {
        return sqItemsApi
          .findSwap(swapPairsForItems[id], { id })
          .then(({ data }) => ({ id, swappedId: data.id }))
          .catch(function (e) {
            if (e.status === HttpCodes.BAD_REQUEST && !suppressWarnings) {
              warnToast({ httpResponseOrError: e });
            }
          });
      })
      .thru((promises) => Promise.all(promises as Promise<any>[]))
      .value()
      .then(_.compact)
      .then(function (swaps) {
        // A swap is invalid if it would replace the current item with one that is already in the store;
        // therefore, adding it would cause a duplicate in the Details panel (CRAB-11603).
        const invalidSwaps = _.filter(swaps, (swap) => _.includes(_.map(allItems, 'id'), swap.swappedId));
        if (!_.isEmpty(invalidSwaps)) {
          const invalidSwapOutItemNames = _.map(invalidSwaps, (swap) => _.find(allItems, ['id', swap.id]).name).join(
            ', ',
          );
          if (!suppressWarnings) {
            warnToast({
              messageKey: 'SWAPS.INVALID_SWAPS',
              messageParams: {
                INVALID_SWAP_OUT_ITEM_NAMES: invalidSwapOutItemNames,
              },
            });
          }

          // Although we don't want to swap items that are already in the details pane, we do need to update the
          // worksheet store's conditionToSeriesGrouping mapping to ensure the groupings point to the correct signal.
          flux.dispatch('WORKSHEET_SWAP_GROUPINGS', {
            swaps: _.zipObject(_.map(invalidSwaps, 'id'), _.map(invalidSwaps, 'swappedId')),
          });
        }
        return _.difference(swaps, invalidSwaps);
      })
      .then((swaps) => _.zipObject(_.map(swaps, 'id'), _.map(swaps, 'swappedId')));
  }

  /**
   * Swap out one asset for another for all items on the trend. Each item that relies upon signals in the out asset
   * will be swapped for a corresponding version that uses the in asset.
   *
   * @param {SwapPairs} swapPairsForItems - each key is an item ID; each value is the list of
   * swap pairs to be used for the swap
   *
   * @return {Promise} Resolves when all assets have been swapped and data refreshed
   */
  function swapAssets(swapPairsForItems: SwapPairs) {
    const allItems = getAllItems({});
    const itemIdsToSwap = _.chain(allItems).uniqBy('id').map('id').value();

    return getSwaps(swapPairsForItems, allItems, itemIdsToSwap)
      .then((swaps) => {
        if (_.isEmpty(swaps)) {
          return null;
        }
        _.forEach(swaps, (swappedId, id) => {
          service.removeChildren(id);
          // Remove other signals from the chart so that old data and new data is not mixed while loading
          flux.dispatch('TREND_SERIES_CLEAR_DATA', { id });
        });
        $injector.get<InvestigateActions>('sqInvestigateActions').close();

        flux.dispatch('TREND_SWAP_ITEMS', { swaps });
        successToast({
          messageKey: 'SWAPS.SUCCESS',
          messageParams: {
            SUCCESSFUL_SWAPS_COUNT: _.size(swaps),
            ALL_ITEMS_COUNT: allItems.length,
          },
        });
      })
      .then(() => service.fetchAllItems());
  }

  /**
   * Updates the conditionLane property for conditions when one or more conditions have been removed from the trend
   */
  function updateConditionLanes() {
    const conditions = _.chain(getAllItems({ itemTypes: [ITEM_TYPES.CAPSULE_SET] }))
      .sortBy('conditionLane')
      .groupBy('conditionLane')
      .values()
      .value();
    const itemsToUpdate = [];
    _.forEach(conditions, (conditionGroup, index) => {
      _.forEach(conditionGroup, (condition) => {
        if (condition.conditionLane !== index + 1) {
          itemsToUpdate.push({ id: condition.id, conditionLane: index + 1 });
        }
      });
    });
    service.setCustomizationProps(itemsToUpdate);
  }

  /**
   * Removes an item and, optionally, its children from the chart. When the removed item is a series or capsule, if
   * there are children for the item, remove them too.
   *
   * @param {Object} item - The item to remove.
   * @param {Boolean} [keepChildren] - True to prevent children of the item from being removed when the item is
   *   removed. (e.g. When you delete a series, don't delete the capsules that are interested in that series)
   */
  function removeItem(item, keepChildren = false) {
    service.removeItems([item], keepChildren);
  }

  /**
   * Removes an array of items and, optionally, their children from the chart. When the removed item is a series or
   * capsule, if there are children for the item, remove them too.
   *
   * @param {Object[]} items - The  array of items to remove.
   * @param {Boolean} [keepChildren] - True to prevent children of the item from being removed when the item is
   *   removed. (e.g. When you delete a series, don't delete the capsules that are interested in that series)
   * @param {String} [option] - One of the WORKSTEP_PUSH constants
   */
  function removeItems(items: any[], keepChildren = false, option?) {
    // Short-circuit and avoid the work if not removing anything
    if (!items.length) {
      return;
    }

    items = _.flatMap(items, (item) =>
      !keepChildren || item.itemType === ITEM_TYPES.METRIC ? [item].concat(findAncestors(item)) : [item],
    );

    _.forEach(items, _.flow(_.property('id'), cancelGroup));
    flux.dispatch('TREND_REMOVE_ITEMS', { items, numPixels: Math.min(chartWidth, MAX_SERIES_PIXELS) }, option);

    $injector.get<InvestigateActions>('sqInvestigateActions').updateDerivedDataTree();

    _.chain(items)
      .reject('isChildOf')
      .map('id')
      .forEach((id) => addRecentlyAccessed($state.params.workbookId, id, sqHomeScreenUtilities))
      .value();

    if (_.some(items, (item) => item.itemType === ITEM_TYPES.SERIES)) {
      $injector
        .get<ScatterPlotActions>('sqScatterPlotActions')
        .fetchPlot()
        .catch((e) => logWarn(formatMessage`Error reloading xy ploy after removing item: ${e}`));
    }

    if (_.some(items, (item) => item.itemType === ITEM_TYPES.CAPSULE_SET)) {
      Promise.all([
        service.fetchTableAndChartCapsules(),
        service.fetchChartCapsules(),
        service.fetchAllTimebarCapsules(),
        fetchTreemap(),
      ]).catch((e) => logWarn(formatMessage`Error reloading condition data after removing item: ${e}`));
    }

    if (
      _.some(items, (item) =>
        _.includes([ITEM_TYPES.SCALAR, ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET, ITEM_TYPES.METRIC], item.itemType),
      )
    ) {
      sqTableBuilderActions.fetchTable();
      sqAnnotationActions
        .fetchAnnotations()
        .catch((e) => logWarn(formatMessage`Error fetching annotations after removing item: ${e}`));
    }

    removeGaps(this);
    updateLaneDisplay(this);

    if (_.some(items, ['itemType', ITEM_TYPES.CAPSULE_SET])) {
      service.updateConditionLanes();
    }

    if (sqTrendStore.capsuleTimeLimited.enabled) {
      service.addCapsuleTimeSegments();
    }

    if (_.some(items, ['id', sqInvestigateStore.item.id])) {
      $injector.get<InvestigateActions>('sqInvestigateActions').clearItem();
    }

    /**
     * Recursively find all ancestors of the item by searching all stores for children of the item.
     * @param {Object} item - The parent item
     * @returns {Object[]} Array of ancestors
     */
    function findAncestors(item) {
      const ancestors = findChildrenIn(TREND_STORES, item.id);

      if (ancestors.length) {
        return ancestors.concat(_.flatMap(ancestors, findAncestors));
      } else {
        return _.compact(ancestors);
      }
    }
  }

  /**
   * Removes all items from the chart.
   */
  function removeAllItems() {
    service.removeItems(getAllItems({}));
  }

  /**
   * Clears the chart by removing all items, canceling any ongoing operations, and resetting the chart defaults,
   * without resetting the duration.
   */
  function clear() {
    service.removeAllItems();
    service.removeSelectedRegion();
    cancelAll();
  }

  /**
   * Removes all the selected items.
   *
   */
  function removeSelectedItems() {
    _.chain(getAllItems({})).filter(['selected', true]).tap(service.removeItems).value();
  }

  /**
   * Toggles the selection of an item.
   *
   * @param {Object} item - The item to toggle.
   */
  function toggleItemSelected(item, forceTableRefresh = false) {
    service.setItemSelected(item, !item.selected, forceTableRefresh);
  }

  /**
   * Sets the selection of an item. Because the user or the select all button can trigger this function in rapid
   * succession all async calls are debounced.
   *
   * @param {Object} item - The item to toggle.
   * @param selected - True to select the item; false to unselect.
   * @param [forceTableRefresh] - True to refresh table/chart capsules; defaults to false.
   * @param [fetchTableData] - True to fetch data for the Simple or Condition Table, false to skip this. Defaults true.
   */
  function setItemSelected(item, selected: boolean, forceTableRefresh = false, fetchTableData = true) {
    // Get item from the store to ensure item props are present (e.g. childType) because they may not be present
    // in the supplied item (e.g. when the item is initially added to the trend), fallback to use the provided item
    // if no store item can be found to ensure capsule selection of capsules not in the capsule table still works.
    item = findItemIn(TREND_STORES, item.id) || item;
    // If item is a composite child then replace it with the parent
    item = substituteParentforChild(item);

    flux.dispatch('TREND_SET_SELECTED', { item, selected, numPixels: chartWidth });

    if (fetchTableData) {
      fetchTableDebounced();
    }
    if (item.itemType === ITEM_TYPES.CAPSULE_SET || item.itemType === ITEM_TYPES.CAPSULE || forceTableRefresh) {
      fetchConditionsDebounced();
    }

    if (sqTrendStore.hideUnselectedItems) {
      fetchHiddenDebounced();
      if (item.itemType !== ITEM_TYPES.CAPSULE) {
        updateLaneDisplay(this);
      }
    }

    fetchCapsuleTimeDebounced();
  }

  /**
   * Handles selecting one or more items. MultiSelect mode is toggled by using the ctrl/meta key and causes the item
   * to be toggled without affecting the state of other items.  Without the modifier key there are two behaviors:
   * - If multiple items are selected then the item will now be the selected one, even if it was already selected.
   * - If only a single item is selected then the item's selection will be toggled.
   *
   * @param {Object} item - The item to be selected
   * @param {Object[]} items - The collection that this item belongs to
   * @param {Object} event - The click event
   */
  function selectItems(item, items, event) {
    let singleItemSelected;
    const multiSelect = isApplePlatform() ? event.metaKey : event.ctrlKey;
    const isCapsulePickingMode = item.itemType === ITEM_TYPES.CAPSULE && sqInvestigateStore.isCapsulePickingMode;

    if (multiSelect || isCapsulePickingMode) {
      service.toggleItemSelected(item);
    } else {
      // Capsules behave differently since selection can include elements not in items
      if (item.itemType === ITEM_TYPES.CAPSULE) {
        singleItemSelected = sqTrendCapsuleStore.selectedCapsules.length === 1;
        service.unselectAllCapsules();
      } else {
        ({ item, items } = processItems(item, items));
        singleItemSelected = _.filter(items, 'selected').length === 1;
        _.forEach(items, (item) => flux.dispatch('TREND_SET_SELECTED', { item, selected: false }));
      }

      // If multiple items were previously selected, we want to select only the new item and clear other selections
      // (regardless of whether the passed in item was selected or not). If only one item was previously selected, we
      // want to toggle the item's selection status instead.
      if (singleItemSelected) {
        service.toggleItemSelected(item, _.some(items, { selected: false, itemType: ITEM_TYPES.CAPSULE_SET }));
      } else {
        service.setItemSelected(item, true, true);
      }
    }
  }

  /**
   * Substitutes the parent for any composite child in the supplied items. A composite child is one that
   * must be treated as part of the whole parent item from the perspective of trend selection. Returns the item and
   * items arguments unchanged if they do not contain a composite child.
   *
   * @param {Object} item - an item
   * @param {Object[]} items - an array of items
   * @returns {{item: any; items: any}} an object containing the possibly-updated item and items
   */
  function processItems(item, items) {
    items = _.chain(items)
      .map((item) => substituteParentforChild(item))
      .uniq()
      .value();

    if (isCompositeChild(item)) {
      const parent = _.cloneDeep(substituteParentforChild(item));
      parent.selected = item.selected;
      item = parent;
    }
    return { item, items };
  }

  /**
   * If the supplied item is a composite child then the parent is returned instead. Otherwise the item is returned
   * unchanged.
   *
   * @param {Object} item - an item
   * @returns {Object} an item; possibly a parent in place of the supplied composite child.
   */
  function substituteParentforChild(item) {
    if (isCompositeChild(item)) {
      return findItemIn(TREND_STORES, item.isChildOf);
    } else {
      return item;
    }
  }

  /**
   * Determines if an item is a child that must be treated as part of the whole parent item from the perspective of
   * trend selection.
   *
   * @param {Object} item - an item
   * @returns {Boolean} true if the item is a composite child, false otherwise
   */
  function isCompositeChild(item) {
    const childrenTypes = [ITEM_CHILDREN_TYPES.METRIC_DISPLAY, ITEM_CHILDREN_TYPES.METRIC_THRESHOLD];
    return _.includes(childrenTypes, item.childType);
  }

  /**
   * Overwrite the selected capsules with the provided capsules.
   *
   * @param {Object[]} capsules - Selected capsules
   * @param {String} capsules[].id - capsule's unique id created from `getUniqueId`
   * @param {Number} capsules[].startTime - end of the capsule in ms
   * @param {Number} capsules[].endTime - start of capsule in ms
   * @param {Boolean} capsules[].isUncertain - indicates that the capsule is uncertain
   */
  function replaceCapsuleSelection(capsules) {
    flux.dispatch('TREND_REPLACE_CAPSULE_SELECTION', { capsules });
  }

  /**
   * Unselects all capsules.
   */
  function unselectAllCapsules() {
    flux.dispatch('TREND_UNSELECT_ALL_CAPSULES');
    if (sqTrendStore.hideUnselectedItems) {
      flux.dispatch('TREND_REMOVE_ALL_CAPSULE_SERIES');
      // Reload capsules and data when we are in capsule time view and "Dimming" -> "Show only selected" is on.
      // Otherwise, we have no data for the unselected capsules.
      service.addCapsuleTimeSegments(true);
    }
    // trigger fetch data when we are in chain view
    createStitchDetails();
    // trigger fetch data when in xyplot
    if (sqWorksheetStore.view.key === WORKSHEET_VIEW.SCATTER_PLOT) {
      $injector.get<ScatterPlotActions>('sqScatterPlotActions').fetchPlot();
    }
  }

  /**
   * Selects a set of capsules by dispatching a single event for all of them.
   * @param capsules - The set of capsules.
   */
  function selectCapsules(capsules: any[]) {
    flux.dispatch('TREND_SET_SELECTED_CAPSULES', { capsules });
  }

  /**
   * Sets the pointer x and y values that correspond to where the mouse pointer is on the chart.
   *
   * @param {Number} xValue - The x-value timestamp.
   * @param {Array} yValues - An array of y-values for the items on the chart.
   */
  function setPointerValues(xValue, yValues) {
    flux.dispatch('TREND_SET_X_VALUE', { xValue }, PUSH_IGNORE);
    flux.dispatch('TREND_SET_POINT_VALUE', { yValues }, PUSH_IGNORE);
  }

  /**
   * Clears the pointer values.
   */
  function clearPointerValues() {
    flux.dispatch(
      'TREND_SET_X_VALUE',
      {
        xValue: null,
      },
      PUSH_IGNORE,
    );
    flux.dispatch('TREND_CLEAR_POINT_VALUES', undefined, PUSH_IGNORE);
  }

  /**
   * Compute the number of lanes to use for the chart. We don't want to sacrifice chart fidelity by choosing a
   * number that's too low, but choosing a huge number will result in more data than necessary being fetched
   * (CRAB-13743). Use the next common screen resolution width greater than or equal to the browser window so we don't
   * lose chart fidelity. If we go beyond resolutions listed (currently at 8k), double the value until
   * it exceeds the width of the browser.
   */
  function computeChartWidth() {
    if (headlessRenderCategory() === HeadlessCategory.Thumbnail) {
      // Thumbnails should always pretend to have a really wide chart width. This seems counter-intuitive because it
      // seems like we are asking appserver for extra data, however it reduces the laneWidth that is passed to
      // spikeCatcher which means that less data will need to be requested to to satisfy the lanes at the edges of
      // the display window. Which can save round trips to datasources because the data might be cached. See CRAB-15579
      return _.last(CHART_THRESHOLDS);
    }

    const width = $window.innerWidth;
    let threshold = _.reduceRight(
      CHART_THRESHOLDS,
      (currentThreshold, threshold) => (threshold >= width ? threshold : currentThreshold),
      _.last(CHART_THRESHOLDS),
    );

    while (width > threshold) {
      // The window is using more pixels than the largest resolution listed in chartThresholds.
      threshold *= 2;
    }

    return threshold;
  }

  /**
   * Sets the width of the chart and then re-fetches timeseries data when the browser size increases
   * beyond a CHART_THRESHOLD. Always resize chartWidth to a value greater than or equal to the number of pixels
   * of the chart so wo don't lose chart fidelity (CRAB-13743).
   */
  function setChartWidth() {
    if (headlessRenderMode()) {
      // CRAB-26006 - in headless mode, page.setViewport may be called to adjust the width, so that the screenshot
      // contains the whole content (e.g. very wide table). In this case we do not want to trigger any data fetch.
      return;
    }

    const newChartWidth = computeChartWidth();
    flux.dispatch('AUTO_UPDATE_SET_DISPLAY_PIXELS', { displayPixels: newChartWidth }, PUSH_IGNORE);

    if (newChartWidth <= chartWidth) {
      // Only need to fetch (downsampled) data if chart is getting larger than threshold.
      return;
    }

    chartWidth = newChartWidth;

    const fetchRequests = [
      sqTrendTableActions.fetchAllFftTables(),
      $injector.get<ScatterPlotActions>('sqScatterPlotActions').fetchMinimapSignals(),
    ];
    switch (sqTrendStore.view) {
      case TREND_VIEWS.CALENDAR:
        fetchRequests.push(service.fetchAllTimeseries());
        break;
      case TREND_VIEWS.CHAIN:
        fetchRequests.push(service.createStitchDetails());
        break;
      case TREND_VIEWS.CAPSULE:
        fetchRequests.push(service.addCapsuleTimeSegments(true));
        break;
    }
    return Promise.all(fetchRequests);
  }

  /**
   * Returns the chart width in pixels.
   *
   * @returns {Number} the chart width in pixels.
   */
  function getChartWidth() {
    return chartWidth;
  }

  /**
   * Fetch the data for all items used by the different charts and views. Any updates to this method should also
   * considered in the context fetchItems. Note: if skipProps is not set to true, all properties will be fetched
   * before fetching any other items. This is because fetchPropsForAllItems is the only place that all items get
   * checked for being redacted. The downside to this is that all item props have to load before data is fetched,
   * which could be noticeable on large worksheets, but having the guarantee of having the dataStatus for each item
   * readily available is ultimately more beneficial.
   *
   * @param {Object} [options] - Options to configure what is fetched
   * @param {Boolean} [options.skipProps] - Skip the fetching of properties
   * @param {Boolean} [options.skipTimebar] - Skip fetching timebar capsules
   *
   * @returns {Promise} A promise that resolves when all the data is fetched.
   */
  function fetchAllItems({ skipProps = false, skipTimebar = false } = {}) {
    // None of this async data is needed in an organizer topic
    if (sqWorkbookStore.isReportBinder) {
      return Promise.resolve([]);
    }

    if (skipProps) {
      return Promise.all(additionalFetches());
    } else {
      return service
        .fetchPropsForAllItems(true)
        .catch((e) => logError(e))
        .then(() => Promise.all(additionalFetches()));
    }

    function additionalFetches() {
      const promises = [];
      promises.push(fetchTreemap());
      promises.push(sqTableBuilderActions.fetchTable());
      promises.push($injector.get<ScatterPlotActions>('sqScatterPlotActions').fetchPlot());
      promises.push(service.fetchAllStatistics());
      promises.push(
        skipProps ? undefined : $injector.get<InvestigateActions>('sqInvestigateActions').updateDerivedDataTree(),
      );

      const isChainOrCapsuleView = sqTrendStore.view === TREND_VIEWS.CHAIN || sqTrendStore.view === TREND_VIEWS.CAPSULE;
      promises.push(isChainOrCapsuleView ? undefined : service.fetchAllTimeseries(!skipProps));
      promises.push(isChainOrCapsuleView ? undefined : service.fetchPreviewSeries());
      promises.push(sqTrendTableActions.fetchAllTables());
      promises.push(service.fetchAllScalars(!skipProps));
      // The request for capsules must be done before the request for capsules table. Otherwise the warnings are
      // swallowed by the last one. This specific order is needed until CRAB-8020 will be solved.
      flux.dispatch('TREND_SET_CAPSULE_PANEL_IS_LOADING', { isLoading: true }, PUSH_IGNORE);
      promises.push(
        service.fetchChartCapsules().then(() => {
          service.fetchTableAndChartCapsules();
        }),
      );
      promises.push(skipTimebar ? undefined : service.fetchAllTimebarCapsules());
      return promises;
    }
  }

  /**
   * Fetch the data for items used by the different charts and views. Any updates to this method should also
   * considered in the context of fetchAllItems.
   *
   * @param {Object[]} items - Array of items currently in the chart
   * @param {Object} options - Options for fetching
   * @param {boolean} options.fetchFailed - True to force it to also fetch FAILED conditions
   * @param {boolean} options.fetchCapsulesLater - True to skip fetching capsules before fetching everything else
   * @returns {Promise} A promise that resolves when all the data is fetched.
   */
  function fetchItems(items: any[], options = { fetchFailed: false, fetchCapsulesLater: false }) {
    const isConditionPresent = _.some(items, ['itemType', ITEM_TYPES.CAPSULE_SET]);

    return (
      isConditionPresent && !options.fetchCapsulesLater
        ? // A workaround for the fact that warnings do not show for cached data and conditions fire off three requests
          // (chart, timebar, and table) which means two of those will use cached data. So, to allow the user to see
          // formula warnings when updating a formula the chart capsules complete first (CRAB-11702).
          service.fetchChartCapsules(_.filter(items, ['itemType', ITEM_TYPES.CAPSULE_SET]))
        : // In the case where a users items are completely errored out, it is very confusing to have the capsules load
          // first, so in that case we postpone retrieving chart capsules until later on (CRAB-16686)
          Promise.resolve()
    ).then(() => {
      const propertyPromises = [];
      const promises = [];

      _.forEach(items, (item: any) => {
        propertyPromises.push(service.fetchItemProps(item.id));
        switch (item.itemType) {
          case ITEM_TYPES.CAPSULE_SET:
            if (options.fetchCapsulesLater) {
              promises.push(service.fetchChartCapsules([item]));
            }
            promises.push(service.fetchTimebarCapsules(item.id));
            promises.push(service.fetchStatistics(item));
            break;
          case ITEM_TYPES.SERIES:
            promises.push(service.fetchTimeseries(item.id));
            break;
          case ITEM_TYPES.SCALAR:
            promises.push(service.fetchScalar(item.id));
            break;
          case ITEM_TYPES.TABLE:
            promises.push(sqTrendTableActions.fetchTableData(item.id));
            break;
        }
      });

      promises.push(
        Promise.all(propertyPromises).then(function () {
          return [
            // Properties must be set before we can update the derived data tree
            $injector.get<InvestigateActions>('sqInvestigateActions').updateDerivedDataTree(),
            // Assets must be fetched before treemap can be updated
            fetchTreemap(),
          ];
        }),
      );

      const statisticsDisplayed = _.chain(items)
        .map('id')
        .intersection(_.map(sqTrendStore.customColumns(TREND_PANELS.CAPSULES), 'referenceSeries'))
        .some()
        .value();

      if (isConditionPresent || statisticsDisplayed) {
        promises.push(service.fetchTableAndChartCapsules(options));
      }

      promises.push($injector.get<ScatterPlotActions>('sqScatterPlotActions').fetchPlot());
      promises.push(sqTableBuilderActions.fetchTable());

      return Promise.all(promises);
    });
  }

  /**
   * HIDDEN_FROM_TREND is set when an item is hidden from the trend rather than fetching data that won't be seen. If
   * the item becomes visible again, this method handles loading the data that we deferred loading. This is
   * essentially a slimmed down version of fetchItems
   */
  function fetchHiddenTrendData() {
    const [conditions, otherItems] = _.chain(
      getAllItems({
        itemChildrenTypes:
          sqTrendStore.view === TREND_VIEWS.CAPSULE
            ? [ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE]
            : [ITEM_CHILDREN_TYPES.METRIC_DISPLAY, ITEM_CHILDREN_TYPES.METRIC_THRESHOLD],
      }),
    )
      .filter(['dataStatus', ITEM_DATA_STATUS.HIDDEN_FROM_TREND])
      .partition(['itemType', ITEM_TYPES.CAPSULE_SET])
      .value();
    const promises = [];

    if (_.some(conditions)) {
      promises.push(service.fetchChartCapsules(conditions));
    }
    _.forEach(otherItems, (item: any) => {
      switch (item.itemType) {
        case ITEM_TYPES.SERIES:
          promises.push(service.fetchTimeseries(item.id));
          break;
        case ITEM_TYPES.SCALAR:
          promises.push(service.fetchScalar(item.id));
          break;
        case ITEM_TYPES.TABLE:
          promises.push(sqTrendTableActions.fetchTableData(item.id));
          break;
      }
    });

    return Promise.all(promises);
  }

  /**
   * Fetches the specified item and all of its calculation dependencies
   *
   * @param {String} id - ID of item
   *
   * @returns {Promise} that resolves when the item and all of its dependencies have been fetched
   */
  function fetchItemAndDependents(id: string) {
    return sqItemsApi.getFormulaDependents({ id }).then(({ data }) =>
      _.chain(data.items)
        .map('id')
        .concat(id)
        .thru((ids) => {
          const children = _.chain(getAllChildItems({})).filter((item) =>
            _.some(['interestId', 'shadedAreaLower.id', 'shadedAreaUpper.id'], (path) =>
              _.includes(ids, _.get(item, path)),
            ),
          );

          const parentIds = children.map('isChildOf').value();
          return _.concat(ids, parentIds);
        })
        .uniq()
        .map((itemId) => findItemIn(TREND_STORES, itemId))
        .compact()
        .thru((items) => service.fetchItems(items))
        .value(),
    );
  }

  /**
   * Fetches the sample data for the specified sample series. Computes a unique cancellation group name based on the
   * items so that if the another set of requests is made for the same items the first are canceled before fetching
   * the next batch. Calls to this function should still be debounced to ensure that there are not repeated calls and
   * cancels.
   *
   * @param {boolean} skipChildren - if true, no data is fetched for child items
   *
   * @return {Promise} A promise that resolves when all series have been fetched
   */
  function fetchAllTimeseries(skipChildren?: boolean) {
    const items =
      sqTrendStore.view === TREND_VIEWS.CAPSULE
        ? sqTrendSeriesStore.capsuleSeries
        : groupedNonCapsuleSeries({
            nonCapsuleSeries: sqTrendSeriesStore.nonCapsuleSeries,
          });

    return _.chain(items)
      .reject((item) => isItemRedacted(item))
      .reject((item: any) => skipChildren && item.isChildOf)
      .map((item: any) => service.fetchTimeseries(item.id))
      .thru((promises) => Promise.all(promises))
      .value();
  }

  /**
   * Fetches the timeseries data and then updates statistics. Handles both regular series and series converted from
   * capsules since the start/end times and fetched series are dependent on that property. This method is
   * dependent on the `chartWidth` variable because that determines how much data to fetch.
   * This method also loads any custom properties specified by the user. Properties are only loaded once as they are
   * assigned to the asset and don't change as the time range changes.
   *
   * @param {String} itemId - The id of the series item to fetch.
   *
   * @return {Promise} Promise that is resolved with the plot values for the series
   */
  function fetchTimeseries(itemId: string): Promise<any> {
    let item = sqTrendSeriesStore.findItem(itemId);
    const cancellationGroup = `fetchTimeseries${CANCELLATION_GROUP_GUID_SEPARATOR}${itemId}`;

    // If loading for the first time we won't have a chartWidth, so no need to fetch data that will be overwritten as
    // soon as the chart is instantiated.
    if (!_.isNumber(chartWidth)) {
      return Promise.resolve();
    }

    if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREND) {
      return Promise.resolve();
    }

    if (
      isHidden({
        item,
      })
    ) {
      flux.dispatch('TREND_SET_DATA_STATUS_HIDDEN_FROM_TREND', { id: item.id }, PUSH_IGNORE);
      return Promise.resolve();
    }

    const numPixels = Math.min(chartWidth, MAX_SERIES_PIXELS);

    if (!_.includes([WORKSHEET_VIEW.TREND, WORKSHEET_VIEW.TABLE], sqWorksheetStore.view.key)) {
      // Scatterplot and Treemap views fetch their own data
      return Promise.resolve();
    }

    if (sqTrendStore.view === TREND_VIEWS.CAPSULE && item.childType !== ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE) {
      // In capsule view, Series from Capsule segments are be passed instead of the parent signal
      return Promise.resolve();
    }

    if (sqTrendStore.view !== TREND_VIEWS.CAPSULE && item.childType === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE) {
      // Series from Capsule segments should never be fetched outside of chain view
      return Promise.resolve();
    }

    // Reset all enabled statistics for this item before requesting new values
    flux.dispatch('TREND_SET_STATISTICS', { id: item.id, statistics: {} }, PUSH_IGNORE);

    // Items that specify fragments are formula functions that will be evaluated by fetchOneSignal
    if (sqTrendStore.view !== TREND_VIEWS.CHAIN || item.fragments) {
      let startTime = sqDurationStore.displayRange.start;
      let endTime = sqDurationStore.displayRange.end;

      flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: item.id }, PUSH_IGNORE);
      cancelGroup(cancellationGroup, true);

      const range = {
        start: startTime,
        end: endTime,
        duration: endTime - startTime,
      };

      let seriesRequest;
      if (item.shadedAreaUpper && item.shadedAreaLower) {
        // There are two paired signals (series boundaries).
        // Request both signals jointly so the corresponding samples line up.
        seriesRequest = fetchPairedSignal(
          item.shadedAreaLower,
          item.shadedAreaUpper,
          range,
          numPixels,
          cancellationGroup,
        );
      } else {
        // This is a "normal" signal in the Trend view
        seriesRequest = fetchOneSignal(getItemId(item), range, numPixels, item.fragments, cancellationGroup);
      }

      return seriesRequest
        .then((results) => {
          if (!sqTrendStore.isRegionSelected()) {
            service.updateStatistics(item, results);
          } else {
            // this could be a result of a reload, so we need to fetch the statistics for the selected region
            service.fetchStatistics(item);
          }

          const payload = _.assign(
            { id: item.id },
            _.pick(results, [
              'samples',
              'valueUnitOfMeasure',
              'timingInformation',
              'meterInformation',
              'warningLogs',
              'warningCount',
              'interpolationMethod',
            ]),
          );
          flux.dispatch('TREND_SERIES_RESULTS_SUCCESS', payload, PUSH_IGNORE);
          updateLaneDisplay(this);

          return payload;
        })
        .catch(_.partial(service.catchItemDataFailure, item.id, cancellationGroup));
    } else {
      // sqTrendStore.view === TREND_VIEWS.CHAIN: this is "Chain View"
      if (_.isEmpty(sqTrendCapsuleStore.stitchTimes)) {
        return Promise.resolve();
      }

      return (
        item.shadedAreaUpper && item.shadedAreaLower
          ? fetchPairedSignalWithinCondition(
              item.isChildOf,
              item.shadedAreaLower,
              item.shadedAreaUpper,
              numPixels,
              cancellationGroup,
            )
          : fetchOneSignalWithinCondition(getItemId(item), numPixels, cancellationGroup)
      )
        .then(function (data) {
          if (!sqTrendStore.isRegionSelected()) {
            service.updateStatistics(item, data);
          } else {
            // this could be a result of a reload, so we need to fetch the statistics for the selected region
            service.fetchStatistics(item);
          }
          const payload = _.assign(
            {
              id: itemId,
              valueUnitOfMeasure: data.valueUnitOfMeasure,
              samples: data.samples,
            },
            _.pick(data, [
              'timingInformation',
              'meterInformation',
              'warningLogs',
              'warningCount',
              'interpolationMethod',
            ]),
          );

          flux.dispatch('TREND_SERIES_RESULTS_SUCCESS', payload, PUSH_IGNORE);
          updateLaneDisplay(this);
          return payload;
        })
        .catch(_.partial(service.catchItemDataFailure, item.id, cancellationGroup));
    }
  }

  /**
   * Fetches a set of signal segments in one request by creating a manual condition with all the capsules. This
   * method is dependent on the `chartWidth` variable because that determines how much data to fetch.
   *
   * @param itemId - The id of the series item to fetch.
   * @param capsules - A set of capsules used to determine the signal segments which will be loaded.
   * @return {Promise} Promise that is resolved with the plot values for the series
   */
  function fetchSignalSegments(itemId: string, capsules) {
    if (_.isEmpty(capsules) || !_.isNumber(chartWidth)) {
      return Promise.resolve();
    }

    flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: itemId }, PUSH_IGNORE);

    const cancellationGroup = `loadSignalSegments${CANCELLATION_GROUP_GUID_SEPARATOR}${itemId}`;

    cancelGroup(cancellationGroup, true);
    const { formula, limit, capsulesQueryIntervals, range } = buildSignalSegmentsFormula(
      itemId,
      capsules,
      chartWidth,
      sqTrendSeriesStore.longestCapsuleSeriesDuration,
    );

    return sqFormula
      .computeSamples({
        usePost: true, // Needed in case the condition formula is long
        id: itemId,
        range,
        formula,
        limit,
        cancellationGroup,
      })
      .then(
        ({
          samples,
          valueUnitOfMeasure,
          meterInformation,
          timingInformation,
          warningCount,
          warningLogs,
          interpolationMethod,
        }) => {
          flux.dispatch(
            'TREND_MULTIPLE_SERIES_DATA_RESULTS_SUCCESS',
            {
              requestCapsules: capsulesQueryIntervals,
              samples,
              valueUnitOfMeasure,
              timingInformation,
              meterInformation,
              interpolationMethod,
            },
            PUSH_IGNORE,
          );
          flux.dispatch(
            'TREND_SET_DATA_STATUS_PRESENT',
            {
              id: itemId,
              valueUnitOfMeasure,
              timingInformation,
              meterInformation,
              warningCount,
              warningLogs,
              interpolationMethod,
            },
            PUSH_IGNORE,
          );
        },
      )
      .catch(_.partial(service.catchItemDataFailure, itemId, cancellationGroup));
  }

  /**
   * Updates the series statistics with those returned by spikecatcher
   *
   * @param {Object} item - The item to be updated
   * @param {Object} results - the results returned by spikecatcher
   * @param {Object} results.table - the statistics table
   **/
  function updateStatistics(item, results) {
    if (results.table) {
      const stats = _.chain(TREND_SIGNAL_STATS)
        .filter((stat: any) => sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, stat.key))
        .filter((stat: any) => (results.valueUnitOfMeasure === STRING_UOM ? stat.isStringCompatible : true))
        .value();
      const table = results.table;
      const dispatchParams = { id: item.id, statistics: {} };

      _.forEach(stats, (value) => {
        const index = _.findIndex(table.headers, (header) => {
          return _.endsWith(value.key, `.${_.get(header, 'name')}`);
        });
        if (index >= 0) {
          _.set(dispatchParams, value.key, table.data[0][index]);
        }
      });

      flux.dispatch('TREND_SET_STATISTICS', dispatchParams, PUSH_IGNORE);
    }
  }

  /**
   * Fetch a "normal" (unpaired) signal and return a promise with the results.
   *
   * @param {String} seriesId - the series ID
   * @param {Object} range - Object container for range arguments
   * @param {Moment|Number} range.start - start of the range
   * @param {Moment|Number} range.end - end of the range
   * @param {Number} range.duration - duration of the time range in milliseconds
   * @param {Number} numPixels - the number or horizontal pixels avaliable for signal display
   * @param {Object} fragments - A formula fragment object where the keys are the names of unbound formula function
   * variables and the values are constants identifying the formula fragments that should be used.
   * @param {String} cancellationGroup - the group used to cancel the requests
   * @returns {Promise} a promise that resolves with the fetched signal
   */
  function fetchOneSignal(seriesId, range, numPixels, fragments, cancellationGroup) {
    const laneWidth = `${getMSPerPixelWidth(range.duration, numPixels)}ms`;

    const downSampleFormula = sqTrendStore.buildDownsampleFormula(laneWidth, seriesId, null, getEnabledColumns());
    return sqFormula
      .computeSamples(
        _.omitBy(
          {
            id: seriesId,
            range,
            fragments: assignFragmentFormulas(fragments, laneWidth),
            formula: `$series${sqTrendStore.buildSummarizeFormula(seriesId)}${downSampleFormula}.parallelize()`,
            limit: numPixels * SPIKECATCHER_PER_PIXEL,
            cancellationGroup,
          },
          _.isUndefined,
        ),
      )
      .then((results) => ({
        valueUnitOfMeasure: results.valueUnitOfMeasure,
        samples: results.samples,
        table: results.table,
        timingInformation: results.timingInformation,
        meterInformation: results.meterInformation,
        warningLogs: results.warningLogs,
        warningCount: results.warningCount,
        interpolationMethod: results.interpolationMethod,
      }));
  }

  /**
   * Replaces a FORMULA_FRAGMENT_TYPE constant with the actual formula fragment that should be used by the backend
   * to evaluate the formula function.
   *
   * @param {Object} fragments - A formula fragment object where the keys are the names of unbound formula function
   * variables and the values are constants identifying the formula fragments that should be used.
   * @param {string} laneWidth - a string representation of the lane width in milliseconds
   * @returns {Object} A formula fragment object where the keys are the names of unbound formula function variables
   *   and the values are the corresponding formula fragments that are used to compute the value of the variable.
   */
  function assignFragmentFormulas(fragments, laneWidth) {
    const result = _.transform(
      fragments,
      (result, value, key) => {
        if (value === FORMULA_FRAGMENT_TYPE.DISPLAY_RANGE) {
          result[key] = getCapsuleFormula(sqDurationStore.displayRange);
          // Also needs the laneWidth to be assigned so the backend can run spikeCatcher
          result.laneWidth = laneWidth; // expecting milliseconds
        }
      },
      {},
    );
    return !_.isEmpty(result) ? result : undefined;
  }

  /**
   * Fetch a paired signal and return a promise with the results, in the same format as fetchOneSignal.
   *
   * @param {Object} shadedAreaLower - Object container for lower bound
   * @param {String} shadedAreaLower.id - id of the lower series
   * @param {Boolean} shadedAreaLower.isSignal - if false, the `toSignal` operator will be used
   * @param {Object} shadedAreaUpper - Object container for upper bound
   * @param {String} shadedAreaUpper.id - id of the upper series
   * @param {Boolean} shadedAreaUpper.isSignal - if false, the `toSignal` operator will be used
   * @param {Object} range - Object container for range arguments
   * @param {Moment|Number} range.start - start of the range
   * @param {Moment|Number} range.end - end of the range
   * @param {Number} range.duration - duration of the time range in milliseconds
   * @param {Number} numPixels - the number or horizontal pixels available for signal display
   * @param {String} cancellationGroup - the group used to cancel the requests
   * @returns {Promise} a promise that resolves with the fetched signal
   */
  function fetchPairedSignal(shadedAreaLower, shadedAreaUpper, range, numPixels, cancellationGroup) {
    return sqFormula
      .computeTable({
        formula: `xyTable(${[
          getCapsuleFormula({ start: range.start, end: range.end }),
          `$lower${!shadedAreaLower.isSignal ? '.toSignal()' : ''}`,
          `$upper${!shadedAreaUpper.isSignal ? '.toSignal()' : ''}`,
          numPixels * XY_TABLE_PER_PIXEL,
        ].join(', ')})`,
        parameters: {
          lower: shadedAreaLower.id,
          upper: shadedAreaUpper.id,
        },
        cancellationGroup,
      })
      .then((results) => ({
        valueUnitOfMeasure: results.headers[1].units,
        samples: _.map(results.data, ([key, lower, upper]) => ({
          key,
          lower,
          upper,
        })),
        timingInformation: results.timingInformation,
        meterInformation: results.meterInformation,
        warningLogs: results.warningLogs,
        warningCount: results.warningCount,
      }));
  }

  /**
   * Create a condition formula based on the stitchTimes. The resulting condition contains
   * only the capsules that are being displayed in chain view
   *
   * @param {String} [seriesId] - the series ID that should be used for filtering stitchTimes in grouping mode
   */
  function createFormulaCondition(seriesId?) {
    // If capsule group mode is enabled then only signals that are paired to a condition are shown during the
    // conditions capsules. This requires filtering of the capsules by condition and then explicit loading of only
    // the grouped signals.
    let capsules;
    if (sqWorksheetStore.capsuleGroupMode && seriesId) {
      const conditionIds = _.keys(sqWorksheetStore.conditionToSeriesGrouping).filter((k) =>
        _.includes(sqWorksheetStore.conditionToSeriesGrouping[k], seriesId),
      );
      capsules = _.filter(sqTrendCapsuleStore.capsulesTimings, (c) => _.includes(conditionIds, c.isChildOf));
    }

    return conditionFormula(
      _.map(_.isEmpty(capsules) ? sqTrendCapsuleStore.stitchTimes : capsules, ({ start, end }) => ({
        startTime: start,
        endTime: moment.isMoment(end) ? end.valueOf() : end,
        properties: [],
      })),
    );
  }

  /**
   * Fetch a "normal" (unpaired) signal within a given condition and return a promise with the results.
   *
   * @param {String} seriesId - the series ID
   * @param {Number} numPixels - the number or horizontal pixels available for signal display
   * @param {String} cancellationGroup - the group used to cancel the requests
   * @returns {Promise} a promise that resolves with the fetched signal
   */
  function fetchOneSignalWithinCondition(seriesId, numPixels, cancellationGroup) {
    const range = sqDurationStore.displayRange;

    // Total all durations so we can figure out percentage of total chartWidth to be used for each period
    const durationTotal = _.sumBy(sqTrendCapsuleStore.stitchTimes, 'duration');
    const useablePixels = numPixels - PIXELS_PER_BREAK * sqTrendCapsuleStore.stitchBreaks.length;

    if (useablePixels === 0) {
      throw new Error('Not enough usable pixels - would have have divided by zero');
    }

    // We're in chain view, so reduce the number of pixels by the number used for the breaks. Note:
    // Nanoseconds are used to support very small capsules
    const laneWidth = `${
      NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND * getMSPerPixelWidth(durationTotal, useablePixels)
    }ns`;
    const downSampleFormula = sqTrendStore.buildDownsampleFormula(
      laneWidth,
      seriesId,
      createFormulaCondition(seriesId),
      getEnabledColumns(),
    );
    return sqFormula.computeSamples({
      usePost: true, // Needed in case the condition formula is long
      id: seriesId,
      range,
      formula: `$series${sqTrendStore.buildSummarizeFormula(seriesId)}${downSampleFormula}.parallelize()`,
      limit: useablePixels * SPIKECATCHER_PER_PIXEL,
      cancellationGroup,
    });
  }

  /**
   * Fetch a paired signal and return a promise with the results, in the same format as fetchOneSignal.
   *
   * @param {String} parentItemId - id of the initial series
   * @param {Object} shadedAreaLower - Object container for lower bound
   * @param {String} shadedAreaLower.id - id of the lower series
   * @param {Boolean} shadedAreaLower.isSignal - if false, the `toSignal` operator will be used
   * @param {Object} shadedAreaUpper - Object container for upper bound
   * @param {String} shadedAreaUpper.id - id of the upper series
   * @param {Boolean} shadedAreaUpper.isSignal - if false, the `toSignal` operator will be used
   * @param {Number} numPixels - the number or horizontal pixels available for signal display
   * @param {String} cancellationGroup - the group used to cancel the requests
   * @returns {Promise} a promise that resolves with the fetched signal
   */
  function fetchPairedSignalWithinCondition(
    parentItemId,
    shadedAreaLower,
    shadedAreaUpper,
    numPixels,
    cancellationGroup,
  ) {
    // Increase the the maxSamples in this view so that the boundaries are drawn with sufficient resolution
    // TODO: Cody Ray Hoeft - this seems to be very expensive in the worst case (where display range is large
    // and capsule is small). Maybe there are optimizations that can be made for signals with no content?
    const durationTotal = _.sumBy(sqTrendCapsuleStore.stitchTimes, 'duration');
    const useablePixels = numPixels - PIXELS_PER_BREAK * sqTrendCapsuleStore.stitchBreaks.length;

    if (durationTotal * useablePixels === 0) {
      throw new Error('Not enough usable pixels or capsule duration - would have have divided by zero');
    }

    return sqFormula
      .computeTable({
        usePost: true, // Needed in case the condition formula is long
        formula: [
          `$condition = ${createFormulaCondition(parentItemId)}`,
          `xyTable(${[
            getCapsuleFormula(sqDurationStore.displayRange),
            `$lower${!shadedAreaLower.isSignal ? '.toSignal()' : ''}.within($condition)`,
            `$upper${!shadedAreaUpper.isSignal ? '.toSignal()' : ''}.within($condition)`,
            (sqDurationStore.displayRange.duration / durationTotal) * useablePixels * XY_TABLE_PER_PIXEL,
          ].join(', ')})`,
        ].join('\n'),
        parameters: {
          lower: shadedAreaLower.id,
          upper: shadedAreaUpper.id,
        },
        limit: useablePixels * XY_TABLE_PER_PIXEL,
        cancellationGroup,
      })
      .then((results) => ({
        valueUnitOfMeasure: results.headers[1].units,
        samples: _.map(results.data, ([key, lower, upper]) => ({
          key,
          lower,
          upper,
        })),
        timingInformation: results.timingInformation,
        meterInformation: results.meterInformation,
        warningLogs: results.warningLogs,
        warningCount: results.warningCount,
      }));
  }

  /**
   * Fetches the data for the specified scalar. Computes a unique cancellation group name based on the
   * items so that if the another set of requests is made for the same items the first are canceled before fetching
   * the next batch. Calls to this function should still be debounced to ensure that there are not repeated calls and
   * cancels.
   *
   * @param {boolean} skipChildren - if true, no data is fetched for child items
   *
   * @return {Promise} A promise that resolves when all scalars have been fetched
   */
  function fetchAllScalars(skipChildren?) {
    return _.chain(sqTrendScalarStore.items)
      .reject((item) => isItemRedacted(item))
      .reject((item: any) => skipChildren && item.isChildOf)
      .map((item: any) => service.fetchScalar(item.id))
      .thru((promises) => Promise.all(promises))
      .value();
  }

  /**
   * Fetches the scalar data and then updates statistics.
   *
   * @param {String} itemId - The id of the scalar item to fetch.
   * @return {Promise} Promise that is resolved with the plot values for the scalar
   */
  function fetchScalar(itemId) {
    const item = sqTrendScalarStore.findItem(itemId);
    const cancellationGroup = `fetchScalar${CANCELLATION_GROUP_GUID_SEPARATOR}${itemId}`;

    if (
      isHidden({
        item,
      })
    ) {
      flux.dispatch('TREND_SET_DATA_STATUS_HIDDEN_FROM_TREND', { id: item.id }, PUSH_IGNORE);
      return Promise.resolve();
    }

    flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: item.id }, PUSH_IGNORE);
    cancelGroup(cancellationGroup, true);

    let scalarRequest;
    if (item.shadedAreaUpper && item.shadedAreaLower) {
      // There are two paired scalars (scalar boundaries). Fetch them both
      scalarRequest = Promise.all([
        sqFormula.computeScalar({
          id: item.shadedAreaLower.id,
          cancellationGroup,
        }),
        sqFormula.computeScalar({
          id: item.shadedAreaUpper.id,
          cancellationGroup,
        }),
      ]).then(([lowerResult, upperResult]) => {
        return _.assign({}, _.omit(lowerResult, ['value']), {
          lower: lowerResult.value,
          upper: upperResult.value,
        });
      });
    } else {
      scalarRequest = sqFormula.computeScalar({
        id: getItemId(item),
        cancellationGroup,
      });
    }
    return scalarRequest
      .then(function (results) {
        const payload = _.assign(
          { id: item.id },
          _.pick(results, [
            'value',
            'lower',
            'upper',
            'warningCount',
            'warningLogs',
            'timingInformation',
            'meterInformation',
          ]),
        );
        flux.dispatch('TREND_SCALAR_RESULTS_SUCCESS', payload, PUSH_IGNORE);
        updateLaneDisplay(this);
        return payload;
      })
      .catch(_.partial(service.catchItemDataFailure, item.id, cancellationGroup));
  }

  /**
   * Fetches the capsules for all timebar conditions.
   */
  function fetchAllTimebarCapsules() {
    // Clear all to ensure regions for inactive conditions are removed
    flux.dispatch('TIMEBAR_CLEAR_REGIONS', {}, PUSH_IGNORE);
    return Promise.all(
      _.map(
        getAllItems({
          excludeEditingCondition: true,
          workingSelection: true,
          excludeDataStatus: [ITEM_DATA_STATUS.REDACTED],
          itemTypes: [ITEM_TYPES.CAPSULE_SET],
        }),
        (item) => service.fetchTimebarCapsules(getItemId(item)),
      ),
    );
  }

  /**
   * Fetches the capsules, in an aggregated form, for the timebar and dispatches the results.
   *
   * @param {String} id - The id of the condition for which to fetch capsules
   * @returns {Promise} A promise that resolves when the results have been fetched.
   */
  function fetchTimebarCapsules(id) {
    if (!sqTrendStore.capsulePreview) {
      flux.dispatch('TIMEBAR_SET_REGIONS_FOR_CONDITION', { id, regions: [] }, PUSH_IGNORE);
      return Promise.resolve();
    }
    const cancellationGroup = `timebarCapsules${CANCELLATION_GROUP_GUID_SEPARATOR}${id}`;
    const item = sqTrendCapsuleSetStore.findItem(id);
    const range = sqDurationStore.investigateRange;
    const width = angular.element('#timebar').width();

    if (width <= 0) {
      return Promise.resolve();
    }

    const buckets = Math.floor(width / 2);
    const bucketWidthArg = `${range.duration.asMilliseconds() / buckets}ms`;
    return cancelGroup(cancellationGroup)
      .then(() =>
        calculate({
          item,
          range,
          bucketWidthArg,
          cancellationGroup,
          sqFormula,
        }),
      )
      .then((regions) => flux.dispatch('TIMEBAR_SET_REGIONS_FOR_CONDITION', { id, regions }, PUSH_IGNORE))
      .catch((e) => {
        if (!isCanceled(e)) {
          logError(formatMessage`Error with fetchTimebarCapsules: ${e}`);
        }
        flux.dispatch('TIMEBAR_SET_REGIONS_FOR_CONDITION', { id, regions: [] }, PUSH_IGNORE);
      });
  }

  /**
   * Fetches the capsules for a list of conditions and then adds the capsules to the store. Data for each capsule
   * is provided either through one of the predefined TREND_COLUMNS or a custom column definition.
   *
   * Adds table capsules to chart if view is capsule time
   *
   * @param {Object} options - Options for fetching
   * @param {boolean} options.fetchFailed - True to force it to also fetch FAILED conditions
   * @returns {Promise} Resolves with the capsule data
   */
  function fetchTableAndChartCapsules(options = { fetchFailed: false }) {
    if (
      _.includes([WORKSHEET_VIEW.TABLE, WORKSHEET_VIEW.TREEMAP], sqWorksheetStore.view.key) ||
      sqWorkbookStore.isReportBinder
    ) {
      return Promise.resolve();
    }
    const cancellationGroup = 'tableCapsules';
    const conditionIds = _.chain(
      getAllItems({
        // Ensure that failing conditions do not fail the whole request
        excludeDataStatus: options.fetchFailed
          ? [ITEM_DATA_STATUS.REDACTED] // Only retry REDACTED items by refreshing the page
          : [ITEM_DATA_STATUS.REDACTED, ITEM_DATA_STATUS.FAILURE, ITEM_DATA_STATUS.CANCELED],
        workingSelection: true,
        excludeEditingCondition: true,
        itemTypes: [ITEM_TYPES.CAPSULE_SET],
      }),
    )
      // This handles the case that, in dimming mode, no conditions are selected, but
      // another item is selected, preventing the condition from being shown. However,
      // chain view and capsule time don't make sense without conditions so in those views
      // populate the table with all the conditions even though none are selected.
      .reject(
        (item) =>
          sqTrendStore.view === TREND_VIEWS.CALENDAR &&
          isHidden({
            item,
          }),
      )
      .map(getItemId)
      .value();

    const range = sqDurationStore.displayRange;
    const initialSort = sqTrendStore.getPanelSort(TREND_PANELS.CAPSULES);
    const sort: TableSortParams = {
      sortAsc: initialSort.sortAsc,
      sortBy: initialSort.sortBy,
      orderedAdditionalSortPairs: [
        {
          sortBy: 'conditionId',
          sortAsc: true,
        },
      ],
    };
    const offset = sqTrendStore.capsulePanelOffset;

    if (_.isEmpty(conditionIds)) {
      // No conditions would have been fetched, clear the table
      flux.dispatch(
        'TREND_ADD_CAPSULES',
        {
          capsules: [],
          numPixels: Math.min(chartWidth, MAX_SERIES_PIXELS),
        },
        PUSH_IGNORE,
      );

      return Promise.resolve();
    }
    const { allDecoratedPropertyColumns, allDecoratedStatColumns, customColumnKeys } =
      sqFormula.getPropertyAndStatisticsColumns();

    flux.dispatch('TREND_SET_CAPSULE_PANEL_IS_LOADING', { isLoading: true }, PUSH_IGNORE);
    return cancelGroup(cancellationGroup)
      .then(() =>
        sqFormula.computeCapsuleTable({
          columns: {
            propertyColumns: allDecoratedPropertyColumns,
            statColumns: allDecoratedStatColumns,
          },
          range,
          buildAdditionalFormula: sqFormula.getBuildAdditionalFormula(
            allDecoratedPropertyColumns,
            allDecoratedStatColumns,
            true,
          ),
          itemIds: conditionIds,
          sortParams: sort,
          offset,
          limit: sqTrendCapsuleStore.capsulesPerPage,
          cancellationGroup,
        }),
      )
      .then((results: FormulaTable) => {
        const capsulesToAdd = [];
        flux.dispatch('TREND_SET_CAPSULE_PANEL_HAS_NEXT', { hasNext: results.data?.hasNextPage }, PUSH_IGNORE);
        _.forEach(results.data?.table, (capsule: any) => {
          const condition = sqTrendCapsuleSetStore.findItem(capsule.conditionId);
          if (condition) {
            const capsuleToPush = {
              id: capsule.capsuleId,
              isChildOf: condition.id,
              childType: ITEM_CHILDREN_TYPES.CAPSULE,
              capsuleSetName: condition.name,
              color: condition.color,
              isUncertain: capsule.isUncertain,
              isReferenceCapsule: capsule.isReferenceCapsule,
              startTime: _.get(capsule, 'startTime', range.start),
              endTime: _.get(capsule, 'endTime', range.end),
              notFullyVisible: !isCapsuleFullyVisible(capsule.startTime, capsule.endTime),
              similarity: capsule.similarity,
            };

            _.forEach(_.pick(capsule, customColumnKeys), (value, key) => {
              const [_ignored, topKey, subKey] = key.match(/^([^.]+)\.(.+)$/);
              _.set(capsuleToPush, topKey === 'properties' ? [topKey, subKey] : key, value);
            });

            _.forEach(CAPSULE_PANEL_LOOKUP_COLUMNS, (column) => {
              if (sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, column.key)) {
                capsuleToPush[column.accessor] = condition[column.accessor];
              }
            });

            // If the display range has not changed (as a result of a growing capsule) - we need to fetch the chart
            // capsules
            capsulesToAdd.push(capsuleToPush);
          }
        });

        flux.dispatch(
          'TREND_ADD_CAPSULES',
          {
            capsules: capsulesToAdd,
            numPixels: Math.min(chartWidth, MAX_SERIES_PIXELS),
          },
          PUSH_IGNORE,
        );

        const headers = results.data.headers;
        _.forEach(CAPSULE_PANEL_LOOKUP_COLUMNS, (column: PropertyColumn) => {
          if (sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, column.key)) {
            headers.push({ name: column.title, type: column.style });
          }
        });

        flux.dispatch('TREND_SET_CAPSULE_PANEL_HEADERS', { headers }, PUSH_IGNORE);

        service.removeItems(
          _.reject(sqTrendSeriesStore.capsuleSeries, (series: any) => sqTrendCapsuleStore.findItem(series.capsuleId)),
          false,
          PUSH_IGNORE,
        );
        flux.dispatch('TREND_SET_CAPSULE_PANEL_IS_LOADING', { isLoading: false }, PUSH_IGNORE);
      })
      .catch((e) => {
        if (!isCanceled(e)) {
          flux.dispatch('TREND_SET_CAPSULE_PANEL_IS_LOADING', { isLoading: false }, PUSH_IGNORE);
          flux.dispatch(
            'TREND_ADD_CAPSULES',
            {
              capsules: [],
              numPixels: Math.min(chartWidth, MAX_SERIES_PIXELS),
            },
            PUSH_IGNORE,
          );
          errorToast({
            httpResponseOrError: e,
          });
        }
      })
      .finally(() => {
        service.addCapsuleTimeSegments(true);
        service.createStitchDetails().catch((err) => {});
      });
  }

  /**
   * Fetches the series that are the results of a live preview (if one is in progress).
   * This is called when scrolling.
   *
   * @returns {Promise} that resolves once the results have been added to the chart
   */
  function fetchPreviewSeries() {
    const previewSeriesDef = sqTrendSeriesStore.previewSeriesDefinition;
    if (!_.isEmpty(previewSeriesDef)) {
      return service.generatePreviewSeries(
        previewSeriesDef.formula,
        previewSeriesDef.parameters,
        previewSeriesDef.id,
        previewSeriesDef.color,
      );
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Fetches capsules for specified capsule sets or all capsule sets in the display range and dispatches them to be
   * displayed on the chart.
   *
   * @param {Object[]} items - Optional array of items currently in the chart that need to be fetched
   * @return {Promise} Resolves when all the capsules have been dispatched.
   */
  function fetchChartCapsules(items?) {
    const cancellationGroupPrefix = 'chartCapsules';
    const fetchItems = items
      ? _.map(items, (item) => {
          return sqTrendCapsuleSetStore.findItem(item.id);
        })
      : sqTrendCapsuleSetStore.items;

    if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREND) {
      return Promise.resolve();
    }

    return _.chain(fetchItems)
      .reject((item) => isItemRedacted(item))
      .map((capsuleSet: any) => {
        const conditionId = getItemId(capsuleSet);
        const cancellationGroup = cancellationGroupPrefix + CANCELLATION_GROUP_GUID_SEPARATOR + conditionId;
        // Chain view and capsule time requires chart capsules to be fetched even though the conditions
        // themselves might not be shown
        if (
          sqTrendStore.view === TREND_VIEWS.CALENDAR &&
          isHidden({
            item: capsuleSet,
          })
        ) {
          flux.dispatch('TREND_SET_DATA_STATUS_HIDDEN_FROM_TREND', { id: capsuleSet.id }, PUSH_IGNORE);
          // Clears the data from the hidden capsule chartItems
          return Promise.resolve({ id: capsuleSet.id, capsules: [] });
        }
        flux.dispatch('TREND_SET_DATA_STATUS_LOADING', _.pick(capsuleSet, ['id']), PUSH_IGNORE);
        const { allDecoratedStatColumns, allDecoratedPropertyColumns } = sqFormula.getPropertyAndStatisticsColumns();
        return cancelGroup(cancellationGroup, true)
          .then(() =>
            sqFormula.computeCapsulesWithLimit(
              {
                id: conditionId,
                isUnbounded: capsuleSet.isUnbounded,
                range: sqDurationStore.displayRange,
                limit: CHART_CAPSULES_LIMIT,
                cancellationGroup,
              },
              allDecoratedPropertyColumns,
              allDecoratedStatColumns,
            ),
          )
          .then((result) => {
            flux.dispatch(
              'TREND_SET_DATA_STATUS_PRESENT',
              _.assign(
                { id: capsuleSet.id },
                _.pick(result, ['warningCount', 'warningLogs', 'timingInformation', 'meterInformation']),
              ),
              PUSH_IGNORE,
            );
            return _.assign(
              { capsules: result.capsules },
              _.pick(capsuleSet, ['id', 'name', 'color', 'selected', 'conditionLane']),
            );
          })
          .catch((error) => {
            service.catchItemDataFailure(capsuleSet.id, cancellationGroup, error);
            // Prevents all the chart capsules from disappearing when just one is having problems
            return Promise.resolve({ id: capsuleSet.id, capsules: [] });
          });
      })
      .thru((promises) => Promise.all(promises))
      .value()
      .then((capsuleSets) => {
        flux.dispatch(
          items ? 'TREND_ADD_CHART_CAPSULES' : 'TREND_SET_CHART_CAPSULES',
          {
            capsuleSets,
            numPixels: Math.min(chartWidth, MAX_SERIES_PIXELS),
          },
          PUSH_IGNORE,
        );
      })
      .catch((err) => {});
  }

  /**
   * Dispatches a command to set properties on a trend item
   *
   * @param {String} id - The id of the item
   * @param {Object} props - The properties to set
   * @param {String} [pushMode] - One of the PUSH constants
   */
  function setTrendItemProps(id, props, pushMode?) {
    flux.dispatch('TREND_SET_PROPERTIES', _.assign({ id }, props), pushMode);
  }

  /**
   * Dispatches a command to set configuration properties on a trend item
   *
   * @param {Object[]} itemsProperties - items to update
   * @param {String} itemsProperties.id - The id of the item
   * @param {Object} itemsProperties.* - The properties to set
   * @param {String} [pushMode] - One of the PUSH constants
   */
  function setCustomizationProps(itemsProperties, pushMode?) {
    const items = _.map(itemsProperties, (payload) => {
      // Reset the lineWidth when setting sampleDisplayOption is set since it changes if we are showing as a BAR
      if (payload.sampleDisplayOption) {
        let item = findItemIn(TREND_STORES, payload.id);
        if (_.get(item, 'itemType') === ITEM_TYPES.METRIC) {
          item = _.find(findChildrenIn(TREND_STORES, payload.id), ['childType', ITEM_CHILDREN_TYPES.METRIC_DISPLAY]);
        }
        const lineWidth =
          payload.sampleDisplayOption === SAMPLE_OPTIONS.BAR
            ? getDefaultBarWidth(_.get(item, 'data'), service.getChartWidth())
            : 1;

        payload = {
          ...payload,
          lineWidth,
        };
      }

      if (payload.conditionLane) {
        // make sure the chart capsules are updated to reflect the new condition lane
        flux.dispatch('TREND_UPDATE_CHART_CONDITION_LANES', payload, PUSH_IGNORE);
      }

      return payload;
    });

    flux.dispatch('TREND_SET_CUSTOMIZATIONS', { items }, pushMode);
  }

  /**
   * Sets the scopedTo property of an item so it is available globally
   *
   * @param id - the item ID
   * @returns {Promise} a promise that resolves when the scope has been set and item properties have been fetched
   */
  function setGlobalScope(id) {
    return sqItemsApi
      .setScope({ id })
      .then(() => {
        service.fetchItemProps(id);
        emitPermissions($state.params.workbookId, $state.params.worksheetId, id);
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  }

  /**
   * Sets the offset for the capsule panel's list of capsules.
   *
   * @param {Number} offset - The offset in the list of capsules
   * @returns {Promise} a promise that resolves when table capsules have been fetched using the new offset
   */
  function setCapsulePanelOffset(offset) {
    flux.dispatch('TREND_SET_CAPSULE_PANEL_OFFSET', { offset });
    return service.fetchTableAndChartCapsules();
  }

  function setCapsulesPerPage(count) {
    flux.dispatch('TREND_RESET_CAPSULE_PANEL_OFFSET');
    flux.dispatch('TREND_SET_CAPSULES_PER_PAGE', { count });
    return service.fetchTableAndChartCapsules();
  }

  /**
   * Sets the color of an item.
   *
   * @param {String} id - The id of the item to update
   * @param {String} color - The color for the item
   * @param {String} [pushMode] - One of the PUSH constants
   */
  function setItemColor(id, color, pushMode?) {
    const item = findItemIn(TREND_STORES, id);
    flux.dispatch('TREND_SET_COLOR', { id, color }, pushMode);

    updateLaneDisplay(this);
    fetchTreemap();
    $injector.get<ScatterPlotActions>('sqScatterPlotActions').refreshView();

    if (item?.childType !== ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE) {
      service.setCapsuleTimeColorMode();
    }
  }

  /**
   * Toggles the dimming of data outside of capsules in capsule time, hidden if false
   */
  function toggleDimDataOutsideCapsules() {
    flux.dispatch('TREND_TOGGLE_DIM_DATA_OUTSIDE_CAPSULES');
    service.addCapsuleTimeSegments(true);
  }

  /**
   * Toggles the visibility of unselected items
   */
  function toggleHideUnselectedItems() {
    flux.dispatch('TREND_TOGGLE_HIDE_UNSELECTED_ITEMS');
    if (sqTrendStore.hideUnselectedItems) {
      service.removeUnselectedSeriesFromCapsules();
    } else {
      service.fetchHiddenTrendData();
      service.fetchTableAndChartCapsules();
    }
    updateLaneDisplay(this);
  }

  /**
   * Fetches additional static properties for an item. This includes properties that are always fetched, such as
   * name, and others that are enabled by the user and stored in the sqTrendStore. Additionally, the assets
   * associated with an item. For a stored item, it will be the asset that is the parent of the item. For a
   * calculated item, it will be the parent asset of the item and the parent assets of all formula arguments used to
   * compute the calculated item.
   *
   * @param itemId - the ID of the item to fetch properties for
   * @param skipDependencies - skip property dependencies. Useful if these have already been loaded
   * @return {Promise} that will resolve when all the information has been fetched
   */
  function fetchItemProps(itemId: string, skipDependencies = false) {
    const item = findItemIn(TREND_STORES, itemId);
    if (!workbookLoaded() || !item || item.isChildOf) {
      return Promise.resolve([]);
    }
    const cancellationGroup = `itemProps-${item.id}`;

    // Command the last fetch request to update so plugins can recognize when a fetch request was made for an item
    flux.dispatch('TREND_UPDATE_LAST_FETCH_REQUEST', { id: item.id });

    return Promise.all([
      // Always retrieve the item. Calendar and Chain views need the Scorecard and Treemap store
      // rendering information as properties on the item.
      sqItemsApi
        .getItemAndAllProperties({ id: item.id }, { cancellationGroup })
        .then(({ data }) => data)
        .catch((response) =>
          service.catchItemDataFailure(item.id, cancellationGroup, response, {}),
        ) as Promise<ItemOutputV1>,
      sqFormula
        .getDependencies({ id: item.id })
        .catch((response) => service.catchItemDataFailure(item.id, cancellationGroup, response, [])),
      (item.itemType === ITEM_TYPES.METRIC
        ? fetchMetric(item.id).then((result) => _.get(result, 'data'))
        : Promise.resolve()) as Promise<ThresholdMetricOutputV1>,
    ]).then(([fetchedItem, dependenciesResult, fetchedMetric]) => {
      const dispatchParams = {} as any;
      dispatchParams.name = fetchedItem.name;

      if (sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, DESCRIPTION)) {
        dispatchParams.description = fetchedItem.description;
      }

      if (sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, DATASOURCE_NAME)) {
        dispatchParams.datasource = { name: fetchedItem.datasource?.name };
      }

      // set the formatOptions
      const numberFormat =
        _.find(fetchedItem.properties, ['name', SeeqNames.Properties.NumberFormat]) ||
        _.find(fetchedItem.properties, ['name', SeeqNames.Properties.SourceNumberFormat]);
      const stringFormat =
        _.find(fetchedItem.properties, ['name', SeeqNames.Properties.StringFormat]) ||
        _.find(fetchedItem.properties, ['name', SeeqNames.Properties.SourceStringFormat]);
      dispatchParams.formatOptions = {
        format: _.get(numberFormat, 'value'),
        stringFormat: _.get(stringFormat, 'value'),
      } as FormatOptions;

      // set calculated properties
      dispatchParams.calculationType = getToolType(fetchedItem);

      // Need to know the source id of a swap in a few places such as sq-select-item
      const swapSourceProp = _.find(fetchedItem.properties, ['name', SeeqNames.Properties.SwapSourceId]) as any;
      dispatchParams.swapSourceId = swapSourceProp ? _.toUpper(swapSourceProp.value) : null; // uppercase for
      // consistency with id

      dispatchParams.isArchived = !!fetchedItem.isArchived;

      const uomProp = _.find(
        fetchedItem.properties,
        (prop) => prop.name === SeeqNames.Properties.ValueUom || prop.name === SeeqNames.Properties.Uom,
      ) as any;
      if (uomProp) {
        dispatchParams.valueUnitOfMeasure = uomProp.value;
      }
      const sourceUomProp = _.find(fetchedItem.properties, ['name', SeeqNames.Properties.SourceValueUom]) as any;
      if (sourceUomProp) {
        dispatchParams.sourceValueUnitOfMeasure = sourceUomProp.value;
      }

      _.chain(sqTrendStore.propertyColumns(TREND_PANELS.SERIES))
        .filter(_.flow(_.property('key'), _.partial(sqTrendStore.isColumnEnabled, TREND_PANELS.SERIES)))
        .forEach(function (prop: any) {
          const property = _.find(fetchedItem.properties, ['name', prop.propertyName]) as any;
          dispatchParams[prop.key] = property ? property.value : null;
          dispatchParams[prop.uomKey] = property ? property.unitOfMeasure : null;
        })
        .value();

      dispatchParams.assets = _.get(dependenciesResult, 'assets');

      if (fetchedItem.scopedTo) {
        dispatchParams.scopedTo = fetchedItem.scopedTo;
      }

      if (fetchedItem.effectivePermissions) {
        dispatchParams.effectivePermissions = fetchedItem.effectivePermissions;
      }

      if (item.itemType === ITEM_TYPES.CAPSULE_SET) {
        dispatchParams.isUnbounded = !_.some(fetchedItem.properties, ['name', SeeqNames.Properties.MaximumDuration]);
      }

      service.setTrendItemProps(item.id, _.omitBy(dispatchParams, _.isUndefined), PUSH_IGNORE);
      if (!dispatchParams.calculationType) {
        service.setTrendItemProps(item.id, { calculationType: undefined }, PUSH_IGNORE);
      }

      addLegacyBoundaries(item, _.get(item, 'displayedAncillaryItemIds', []));

      if (fetchedMetric) {
        service.addMetricChildren(item.id, fetchedMetric, skipDependencies);
      }

      if (item.itemType === ITEM_TYPES.SERIES) {
        service.addCapsuleTimeSegments(true, [item]);
      }
    }) as Promise<any>;
  }

  /**
   * Fetches the metric item with data status updates. Dispatches data status updates if it is not in table builder
   * view because that view takes care of setting item data status.
   *
   * @param id - the metric ID
   * @returns the metric result
   */
  function fetchMetric(id: string): ng.IHttpPromise<ThresholdMetricOutputV1> {
    const cancellationGroup = `trendFetchMetric${CANCELLATION_GROUP_GUID_SEPARATOR}${id}`;
    ifNotTableBuilderView(() => flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE));
    return cancelGroup(cancellationGroup)
      .then(() => sqMetricsApi.getMetric({ id }, { cancellationGroup }))
      .then((result) => {
        ifNotTableBuilderView(() => flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', { id }));
        return result;
      })
      .catch((e) => service.catchItemDataFailure(id, cancellationGroup, e));

    function ifNotTableBuilderView(action) {
      if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TABLE) {
        action();
      }
    }
  }

  /**
   * Legacy code to support old ancillary boundaries that have been converted to threshold metrics. Looks through all
   * metrics that have the parent as a measured item and which have at least one of the boundaries as a threshold.
   * If so, then adds those thresholds with the old boundary look.
   *
   * @param parent - The parent item, from the sqTrendSeriesStore
   * @param boundaryIds - The ids of items that were used as the boundary thresholds
   */
  function addLegacyBoundaries(parent: any, boundaryIds: string[]) {
    if (!_.isEmpty(boundaryIds)) {
      sqItemsApi
        .getItemUsages({ id: parent.id, scope: [sqWorkbenchStore.stateParams.workbookId] })
        .then(({ data: { items } }) => {
          _.chain(items)
            .filter({ type: SeeqNames.Types.ThresholdMetric })
            .forEach(({ id }) =>
              sqMetricsApi.getMetric({ id }).then(({ data: metric }) => {
                if (
                  metric.measuredItem.id === parent.id &&
                  _.some(metric.thresholds, (threshold) => _.includes(boundaryIds, threshold.item?.id))
                ) {
                  const lower = _.find(metric.thresholds, (threshold) => threshold.priority.level < 0)?.item;
                  const upper = _.find(metric.thresholds, (threshold) => threshold.priority.level > 0)?.item;
                  const showMigrationToast = () => {
                    warnToast({
                      messageKey: 'BOUNDARIES_MIGRATED',
                      messageParams: { name: parent.name },
                      buttonLabelKey: 'BOUNDARIES_VIEW',
                      buttonIcon: 'fc fc-metric',
                      buttonIconStyle: 'dark-gray',
                      buttonVariant: 'outline',
                      buttonAction: () =>
                        fetchItemUsages(parent, 'main', [sqWorkbenchStore.stateParams.workbookId], true).then(() => {
                          sqWorksheetActions.tabsetChangeTab('sidebar', 'search');
                        }),
                    });
                  };

                  if (lower && upper) {
                    showMigrationToast();
                    addShadedArea(
                      {
                        parentId: parent.id,
                        childType: ITEM_CHILDREN_TYPES.ANCILLARY,
                        baseItem: metric,
                        lower,
                        upper,
                        props: {
                          axisVisibility: false,
                          fillOpacity: 0.2,
                          lineWidth: 0,
                        },
                      },
                      PUSH_IGNORE,
                    );
                  } else if (lower || upper) {
                    showMigrationToast();
                    addChildItem(
                      parent.id,
                      ITEM_CHILDREN_TYPES.ANCILLARY,
                      lower || upper,
                      {
                        id: `${parent.id}_${metric.id}`,
                        dashStyle: DASH_STYLES.DASH,
                        axisVisibility: false,
                      },
                      PUSH_IGNORE,
                    );
                  }
                }
              }),
            )
            .value();
        })
        .catch(_.noop);
    }
  }

  /**
   * @see addShadedArea
   */
  type AddShadedAreaParams = {
    parentId: string;
    childType: string;
    baseItem: any;
    lower?: any;
    upper?: any;
    props?: any;
  };

  /**
   * Adds a shaded area to the trend stores. A shaded areas are special items in the trendSeries and trendScalar
   * stores that highcharts will draw solid filled areas for on the chart. A shaded area has `shadedArea*`
   * properties that determine how it looks and behaves on the trend and how data should be requested.
   *
   * There are a few classes of shaded areas:
   *  - shading from a signal or scalar to the edge of the lane
   *    - the item is requested as normal, but the chart will draw up or down to Infinity or -Infinity
   *    - `shadedAreaDirection` property determines the direction
   *  - shading between two signals or a signal and a scalar
   *    - the item is requested by pairing the upper and lower ids and the data array will contain an extra y value
   *    - stored in the trendSeries store and is requested as the display range changes
   *    - `shadedAreaLower` and `shadedAreaUpper` contain the ids and whether `toScalar` needs to be used on the id
   *  - shading between two scalars
   *    - the item is requested by requesting both the values and constructing a matching data array
   *    - stored in the trendScalar store and not requested as the display range changes
   *    - `shadedAreaLower` and `shadedAreaUpper` contain the ids of the scalars
   *
   * For shading between two items `shadedAreaCursors` determines if both the top and the bottom of the shaded
   * region should produce cursors that show the value on the trend. For example, if you are trying to stack shaded
   * areas for an effect you want to avoid having the same value show up twice.
   *
   * @param {string} parentId - this id will be used as the parent for the new child item
   * @param {string} childType - one of ITEM_CHILDREN_TYPES, shaded areas can only be child items
   * @param {Object} baseItem - the item that is the "focus" of the shaded area; the id is used as the id of the item
   * @param {Object} [lower] - the item for the lower edge of the shaded area or absent to indicate shading to bottom
   * @param {Object} [upper] - the item for the upper edge of the shaded area or absent to indicate shading to top
   * @param {Object} [props] - extra properties to set on the item
   * @param {string} [option] - argument to pass to relevant flux.dispatch calls
   * @param skipDependencies - Will signal whether to skip the child's dependencies
   */
  function addShadedArea(
    { parentId, childType, baseItem, lower, upper, props }: AddShadedAreaParams,
    option?,
    skipDependencies = false,
  ) {
    const lowerType = lower && getItemType(lower);
    const upperType = upper && getItemType(upper);
    const { id, name, type } = baseItem;

    if ((lower && !_.includes(SHADED_AREA_TYPES, lowerType)) || (upper && !_.includes(SHADED_AREA_TYPES, upperType))) {
      throw new Error('Only signals and scalars can be used as bounds for shaded areas');
    }

    // If both items are scalars we don't need to fetch the bounds as the display range changes, so they are
    // stored in the scalars store and they are fetched only once.
    const itemType =
      lowerType === ITEM_TYPES.SCALAR && upperType === ITEM_TYPES.SCALAR ? ITEM_TYPES.SCALAR : ITEM_TYPES.SERIES;

    if (lower && upper) {
      // If the base item is one of the thresholds, assume that it is the focus of the shading and only show that
      // cursor
      let shadedAreaCursors = SHADED_AREA_CURSORS.BOTH;
      if (baseItem.id === lower.id) {
        shadedAreaCursors = SHADED_AREA_CURSORS.LOWER;
      }
      if (baseItem.id === upper.id) {
        shadedAreaCursors = SHADED_AREA_CURSORS.UPPER;
      }
      if (baseItem.isNeutral) {
        shadedAreaCursors = SHADED_AREA_CURSORS.NONE;
      }

      addChildItem(
        parentId,
        childType,
        { id, name, type, itemType },
        {
          shadedAreaLower: {
            id: lower.id,
            isSignal: lowerType === ITEM_TYPES.SERIES,
          },
          shadedAreaUpper: {
            id: upper.id,
            isSignal: upperType === ITEM_TYPES.SERIES,
          },
          shadedAreaCursors,
          ...props,
        },
        option,
        skipDependencies,
      );
    } else if (lower || upper) {
      const { id: interestId, type, itemType } = !upper ? lower : upper;
      addChildItem(
        parentId,
        childType,
        { id, name, type, itemType },
        {
          interestId,
          shadedAreaDirection: !upper ? SHADED_AREA_DIRECTION.UP : SHADED_AREA_DIRECTION.DOWN,
          ...props,
        },
        option,
        skipDependencies,
      );
    } else {
      throw new Error('Only one bound can be omitted');
    }
  }

  /**
   * Adds a child item to the trend stores
   *
   * @param parentId - id of the parent which is used for `isChildOf`
   * @param childType - one of ITEM_CHILDREN_TYPES
   * @param {Object} item - item to add to the trend
   * @param [props] - extra properties to set on the item
   * @param [option] - argument to pass to relevant flux.dispatch calls
   * @param skipDependencies - Will signal whether to skip the child's dependencies
   * @returns {Promise} that resolves when the item has been added
   */
  function addChildItem(
    parentId: string,
    childType: string,
    item,
    props?: object,
    option?: string,
    skipDependencies = false,
  ): Promise<any> {
    const parent = findItemIn(TREND_STORES, parentId);
    const { id: interestId, name, type, itemType } = item;
    // The concatenation of the ids is used only so that the child item's id won't conflict with the ids of other
    // items. Any unique id would work, but the concatenation is useful for debugging and predictability.
    const id = _.get(props, 'id', `${parentId}_${interestId}`);
    return service.addItem(
      { id, name: parent.name, type, itemType },
      {
        childType,
        isChildOf: parentId,
        interestId, // Placed before ...props so that the caller can override the interestId
        ..._.omit(props, ['id']),
        ..._.pick(parent, CHILD_CLONED_PROPERTIES[childType]),
      },
      option,
      skipDependencies,
    );
  }

  /**
   * Attempt to translate an item that may be from the backend (has a type parameter) or from the frontend (has an
   * itemType parameter) into the item types that the frontend uses to identify items. Otherwise falls back to the
   * item.type.
   *
   * @param {Object} item - item to get the type of
   * @returns {string} the type of the item
   */
  function getItemType(item) {
    return item.itemType || API_TYPES_TO_ITEM_TYPES[item.type];
  }

  /**
   * Adds child metric items for a metric. These are the series and thresholds that are displayed on the trend
   *
   * @param itemId
   * @param fetchedMetric
   * @param skipDependencies will skip unneeded dependencies. Useful if these have already been fetched
   */
  function addMetricChildren(itemId: string, fetchedMetric: ThresholdMetricOutputV1, skipDependencies = false) {
    // Set metric first so definition is available when children are added
    const props = _.omitBy(
      {
        definition: fetchedMetric,
        valueUnitOfMeasure: fetchedMetric.valueUnitOfMeasure,
      },
      _.isUndefined,
    );
    service.setTrendItemProps(itemId, props, PUSH_IGNORE);

    // Remove existing children of this item before updated ones are re-added
    service.removeChildren(itemId);

    // Make the item type be that which will be returned when the formula function is executed and set the fragments
    // property to be an object where the key is the parameter name and the value is the formula fragment type that
    // should be used when the signal is fetched (in this case a capsule formula for the current display range).
    const displayItemProps = {} as any;
    const displayItem = _.cloneDeep(fetchedMetric.displayItem) as any;
    if (displayItem.type === API_TYPES.FORMULA_FUNCTION) {
      displayItem.itemType = ITEM_TYPES.SERIES;
      displayItemProps.fragments = {
        capsule: FORMULA_FRAGMENT_TYPE.DISPLAY_RANGE,
      };
    }

    addChildItem(
      itemId,
      ITEM_CHILDREN_TYPES.METRIC_DISPLAY,
      displayItem,
      displayItemProps,
      PUSH_IGNORE,
      skipDependencies,
    );

    // Ordering by priority makes things easier to reason about here
    const thresholds = _.chain(fetchedMetric.thresholds)
      .cloneDeep()
      .concat({
        item: displayItem,
        priority: { level: 0, color: fetchedMetric.neutralColor },
      } as ThresholdOutputV1)
      .sortBy('priority.level')
      .value();
    if (
      fetchedMetric.valueUnitOfMeasure !== 'string' &&
      _.every(thresholds, (threshold) => _.includes(SHADED_AREA_TYPES, getItemType(threshold.item)))
    ) {
      // Display thresholds as shaded areas on the trend
      _.forEach(thresholds, (threshold, i) => {
        const baseParams = {
          parentId: itemId,
          childType: ITEM_CHILDREN_TYPES.METRIC_THRESHOLD,
          baseItem: threshold.item,
          props: {
            // Include the priority level so the same signal used for multiple thresholds doesn't cause unexpected
            // results
            id: `${itemId}_${threshold.item.id}_${threshold.priority.level}`,
            dashStyle: DASH_STYLES.SOLID,
            color: threshold.priority.color,
            axisVisibility: false,
            fillOpacity: 0.09,
          },
        };
        const maybeUpperItem = _.get(thresholds, [i + 1, 'item']);
        const maybeLowerItem = _.get(thresholds, [i - 1, 'item']);
        if (threshold.priority.level === 0) {
          if ((maybeLowerItem || maybeUpperItem) && threshold.priority.color !== '#ffffff') {
            addShadedArea(
              {
                ...baseParams,
                baseItem: { ...threshold.item, isNeutral: true },
                lower: maybeLowerItem,
                upper: maybeUpperItem,
              },
              PUSH_IGNORE,
              skipDependencies,
            );
          }
        } else if (threshold.priority.level > 0) {
          addShadedArea(
            {
              ...baseParams,
              lower: threshold.item,
              upper: maybeUpperItem,
            },
            PUSH_IGNORE,
            skipDependencies,
          );
        } else {
          addShadedArea(
            {
              ...baseParams,
              lower: maybeLowerItem,
              upper: threshold.item,
            },
            PUSH_IGNORE,
            skipDependencies,
          );
        }
      });
    } else {
      // Display thresholds as dotted lines on the trend
      _.chain(thresholds)
        .reject((threshold) => threshold.priority.level === 0)
        .forEach((threshold, i) => {
          const props = {
            // Include the priority level so the same signal used for multiple thresholds doesn't cause unexpected
            // results
            id: `${itemId}_${threshold.item.id}_${threshold.priority.level}`,
            dashStyle: DASH_STYLES.DASH,
            color: threshold.priority.color,
            axisVisibility: false,
          };
          addChildItem(
            itemId,
            ITEM_CHILDREN_TYPES.METRIC_THRESHOLD,
            threshold.item,
            props,
            PUSH_IGNORE,
            skipDependencies,
          );
        })
        .value();
    }
  }

  /**
   * Aligns a metric's measured item to be on the same lane, the same axis if it shares the same UOM, and selection
   * status if metric is selected.
   *
   * @param {Object} metric - The metric with the measured item
   */
  function alignMeasuredItemWithMetric(metric) {
    const measuredItem = findItemIn(TREND_STORES, _.get(metric, 'definition.measuredItem.id'));
    if (!measuredItem) {
      return;
    }

    service.setCustomizationProps([
      _.omitBy(
        {
          id: measuredItem.id,
          lane: measuredItem.itemType !== ITEM_TYPES.CAPSULE_SET ? metric.lane : undefined,
          axisAlign: metric.valueUnitOfMeasure === measuredItem.valueUnitOfMeasure ? metric.axisAlign : undefined,
        },
        _.isNil,
      ),
    ]);
    updateLaneDisplay(this);

    if (metric.selected) {
      this.setItemSelected(measuredItem, true);
    }
  }

  /**
   * Fetches properties for all items.
   *
   * @param skipDependencies allows property dependencies to be skipped. Useful if these items
   * already have been fetched or are not needed.
   * @returns {Promise} Resolves when all properties are fetched.
   */
  function fetchPropsForAllItems(skipDependencies = false) {
    return Promise.all(_.map(getAllItems({}), (item) => service.fetchItemProps(item.id, skipDependencies)));
  }

  /**
   * Helper function to be used in conjunction with fetching data for an item. It sets the item status to "Canceled"
   * or "Failure" based on the response. Because the item status should already be set (e.g. to "Loading"), we only
   * set the cancelled item status if the request was canceled and there is a not new request already in flight and
   * the cancellation call was not flagged as "refetching" which indicates that a data request will follow the
   * cancellation request. The refetching check is needed to prevent unwanted red triangles in the details pane that
   * could occur due to a race condition when the user moved the trend around quickly (causing many cancellations) on
   * a slow network. The race condition was that a prior cancellation promise could resolve after a call to cancel a
   * cancellation group but before the pending requests cancellation interceptor had added the next request (which
   * would result in a no pending requests existing for the cancellation group at that particular moment).
   *
   * @param {String} id - The id of the item
   * @param {String} cancellationGroup - the group name used to count the number of requests
   * @param {Object} error - The error object from the API request
   * @param {any} defaultValue? - An optional return value to be used if specified
   * @returns {Promise|any} returns defaultValue if provided, or otherwise, a rejected promise if a failure status
   * was set on the item
   */
  function catchItemDataFailure(id, cancellationGroup, error, defaultValue?) {
    const nameWithId = `"${_.get(findItemIn(TREND_STORES, id), 'name', '')}" (${id})`;
    const canceled = isCanceled(error);

    // Show a cancellation "red triangle" in the details pane since this cancellation wasn't expected
    if (canceled && !count(cancellationGroup) && !_.get(error, 'config.refetching', false)) {
      flux.dispatch('TREND_SET_DATA_STATUS_CANCELED', { id }, PUSH_IGNORE);
      notifyCancellation(`Item data request canceled for ${nameWithId}`);
      logInfo(`Item data request canceled for ${nameWithId}`);
    } else if (canceled && error?.xhrStatus === 'abort' && error?.config?.refetching) {
      // CRAB-22078: if an item is refetching, sometimes we get stuck in state with an infinite spinner,
      // so tell the user there was a failure
      flux.dispatch('TREND_SET_DATA_STATUS_FAILURE', { id }, PUSH_IGNORE);
      logWarn(`Item data failure for ${nameWithId}`);
    }

    // Show a "red triangle" in the details pane with more information
    if (!canceled) {
      const message = _.get(error, 'data.statusMessage');
      const errorType = _.get(error, 'data.errorType');
      const errorCategory = _.get(error, 'data.errorCategory');
      const inaccessible = _.get(error, 'data.inaccessible');
      if (errorType === ErrorTypeEnum.MAXDURATIONREQUIRED || errorType === ErrorTypeEnum.MAXDURATIONPROHIBITED) {
        doTrack('ERROR', errorType, message);
      }
      if (isForbidden(error)) {
        handleForbidden(error);
        if (_.isNil(inaccessible) || _.includes(inaccessible, id)) {
          flux.dispatch('TREND_SET_DATA_STATUS_REDACTED', { id, message, errorType, errorCategory }, PUSH_IGNORE);
          logWarn(`Item redacted ${nameWithId}: ${message}`);
        } else {
          flux.dispatch('TREND_SET_DATA_STATUS_ABORTED', { id, message, errorType, errorCategory }, PUSH_IGNORE);
          logWarn(`Item data aborted for ${nameWithId}: ${message}`);
        }
      } else {
        flux.dispatch('TREND_SET_DATA_STATUS_FAILURE', { id, message, errorType, errorCategory }, PUSH_IGNORE);
        logWarn(`Item data failure for ${nameWithId}: ${message}`);
      }

      if (_.isNil(defaultValue)) {
        return Promise.reject(error);
      }
    }

    if (!_.isNil(defaultValue)) {
      return defaultValue;
    }
  }

  /**
   * Adds, updates, or removes a custom label. Undefined or empty text will cause the label to be removed
   *
   * @param {String} location - the location of the target (lane or axis) one of LABEL_LOCATIONS
   * @param {String|Number} target - the name of the axis or lane
   * @param {String} [text] - the text to set the custom label or undefined or empty text to remove label
   */
  function setCustomLabel(location, target, text) {
    if (_.isEmpty(_.trim(text))) {
      flux.dispatch('TREND_REMOVE_CUSTOM_LABEL', {
        location,
        target,
      });
    } else {
      flux.dispatch('TREND_SET_CUSTOM_LABEL', {
        location,
        target,
        text,
      });
    }
  }

  /**
   * Toggles whether or not capsule previews are loaded and displayed in the timebar
   * Fetches or clears the timebar capsules depending on the current toggle position
   *
   * @param {boolean} capsulePreview - whether or not the capsules should be previewed on investigate range
   */
  function setCapsulePreview(capsulePreview) {
    flux.dispatch('TREND_SET_CAPSULE_PREVIEW', { capsulePreview });

    _.forEach(sqTrendCapsuleSetStore.items, (item) => service.fetchTimebarCapsules(item.id));
  }

  /**
   * Sets column filter
   *
   * @param key - column key of the column being filtered
   * @param filter - filter to be applied to the column
   * */
  function setColumnFilter(key: string, filter: TableColumnFilter) {
    flux.dispatch('TREND_SET_COLUMN_FILTER', { key, filter });
    flux.dispatch('TREND_RESET_CAPSULE_PANEL_OFFSET');
    service.fetchTableAndChartCapsules();
    service.fetchChartCapsules();
    sqScatterPlotActions.fetchXYData();
  }

  /**
   * Removes filter from columnFilters by setting it to undefined.
   *
   * @param columnKey - column key for which column to remove the filter
   * */
  function removeColumnFilter(columnKey: string) {
    service.setColumnFilter(columnKey, undefined);
  }

  /**
   * Removes the children of an item
   *
   * @param {String} itemId - the item ID
   */
  function removeChildren(itemId) {
    service.removeItems(findChildrenIn(TREND_STORES, itemId));
  }

  /**
   * Helper that gets the Item ID for an item. Accounts for the fact that frontend items can have an "id" property
   * that is not the same as the GUID used by the backend.
   *
   * @param {Object} item - An item from one of the trend stores
   * @return {string} The GUID to use for API calls
   */
  function getItemId(item) {
    return item.childType && item.interestId ? item.interestId : item.id;
  }

  /**
   * Returns a comma separated list of the currently selected stats - ready for spikeCatcher formula input
   */
  function getEnabledColumns() {
    const stats = _.chain(TREND_SIGNAL_STATS)
      .filter((stat: any) => sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, stat.key))
      .map((stat: any) => stat.stat)
      .value();

    return _.get(stats, 'length') ? `, ${_.join(stats, ', ')}` : '';
  }

  /**
   * @example
   *          ---If you need debounced version of a function, put handle up top like:
   *          let debouncedHandle;
   *
   *          ---then, when you need to use it somewhere:
   *          function foo() {
   *            ...
   *            debouncedHandle =  lazyDebounceOf(debouncedFunction, debouncedHandle);
   *            debouncedHandle();
   *          }
   * @param debounceHandle
   * @param functionToDebounce
   */
  function lazyDebounceOf(functionToDebounce, debounceHandle) {
    // We defer calling _.debounce until the first time it is used so that protractor running the system
    // tests has an opportunity to replace _.debounce in protractor.conf. See CRAB-7098
    if (!debounceHandle) {
      debounceHandle = _.debounce(functionToDebounce, DEBOUNCE.MEDIUM);
    }
    return debounceHandle;
  }

  /**
   * Sets the list of property options to separate and/or color by in compare mode
   *
   * @param {ReactSelectOption<string>[]} capsuleProperties - list of property options
   */
  function setCapsuleProperties(capsuleProperties: ReactSelectOption<string>[]) {
    flux.dispatch('TREND_SET_CAPSULE_PROPERTIES', { capsuleProperties });
  }

  /**
   * Sets the property to color by in compare mode
   *
   * @param {String} colorByProperty - property to use for coloring in compare mode
   */
  function setColorByProperty(colorByProperty: string) {
    flux.dispatch('TREND_SET_COLOR_BY_PROPERTY', { colorByProperty });
  }

  /**
   * Sets the property to separate by in compare mode
   *
   * @param {String} separateByProperty - property to use for separation in compare mode
   */
  function setSeparateByProperty(separateByProperty: string) {
    flux.dispatch('TREND_SET_SEPARATE_BY_PROPERTY', { separateByProperty });
  }

  /**
   * Sets the first column in compare mode
   *
   * @param {String} payload.firstColumn - first column
   */
  function setFirstColumn(firstColumn: string) {
    flux.dispatch('TREND_SET_FIRST_COLUMN', { firstColumn });
  }
}
