import angular from 'angular';

const dependencies = ['Sq.Core', 'Sq.Search', 'Sq.TrendData', 'Sq.Worksteps'];

/**
 * The worksheet module manages the currently selected tab (i.e. search or assets)
 * as well as the currently visible view. The available worksheet views that are
 * available for display on the worksheet are defined here.
 */
angular.module('Sq.Worksheet', dependencies);
