// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import Select, { components } from 'react-select';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { PeriodicCondition } from '@/hybrid/tools/periodicCondition/PeriodicCondition.organism';
import { SearchWidget } from '@/hybrid/search/SearchWidget.organism';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { sqReportStore } from '@/core/core.stores';

const SEARCH_TYPES = ['Condition'];
const SEARCH_PANE = 'modal';

const reportDateModalCreateFindConditionBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  newDateRange: prop<any>(),
  isSelectingRelative: prop<any>(),
  selectedCondition: prop<any>(),
  isConditionSelected: prop<any>(),
  onConditionChange: prop<any>(),
  setIsSelectingRelative: prop<any>(),
  onConditionSelected: prop<any>(),
});

export const ReportDateModalCreateFindCondition: SeeqComponent<typeof reportDateModalCreateFindConditionBindings> = ({
  newDateRange,
  isSelectingRelative,
  selectedCondition,
  isConditionSelected,
  onConditionChange,
  setIsSelectingRelative,
  onConditionSelected,
}) => {
  const { sqInvestigateActions } = useInjectedBindings(reportDateModalCreateFindConditionBindings);

  const { t } = useTranslation();

  const contentWorkbookIds = useFluxPath(sqReportStore, () => sqReportStore.contentWorkbookIds);
  const [showPeriodicTool, setShowPeriodicTool] = useState(false);

  const onDropdownChange = (option) => {
    if (option?.id === PERIODIC_ID) {
      setShowPeriodicTool(true);
      sqInvestigateActions.setActiveTool(TREND_TOOLS.PERIODIC_CONDITION);
    }

    onConditionChange(option);
  };

  const onPeriodicConditionSaved = (item) => {
    setShowPeriodicTool(false);
    onConditionSelected(item);
  };

  const PERIODIC_ID = 'periodic';
  const EXISTING_ID = 'existing';
  const dropdownOptions = [
    {
      id: PERIODIC_ID,
      name: 'PERIODIC_CONDITION.HEADER',
      description: 'REPORT.CONFIG.PERIODIC_CONDITION_DESCRIPTION',
      value: 0,
    },
    {
      id: EXISTING_ID,
      name: 'REPORT.CONFIG.EXISTING_CONDITION',
      description: 'REPORT.CONFIG.EXISTING_CONDITION_DESCRIPTION',
      value: 1,
    },
    {
      id: selectedCondition?.id,
      name: selectedCondition?.name,
      value: 2,
    },
  ];

  const selectedOption = selectedCondition?.id ? _.find(dropdownOptions, { id: selectedCondition.id }) : undefined;
  const SingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        {!props.data.id && <Icon icon="fc-capsule-set" type="gray" />}
        {t(props.data.name)}
      </components.SingleValue>
    );
  };

  const CustomOption = (props) => {
    return (
      <>
        {props.data.id && (
          <components.Option {...props}>
            <div data-testid={props.data.id}>
              <div className="text-bold">{t(props.data.name)}</div>
              <div className="small text-italic">{t(props.data.description)}</div>
            </div>
          </components.Option>
        )}
      </>
    );
  };

  return (
    <div className="flexRowContainer" data-testid="specCreateFindCondition">
      <div className="flexColumnContainer flexAlignStart mb0">
        <label className="mb0">{t('ITEM_TYPES.CONDITION')}</label>
        <div className="small text-italic sq-darkish-gray ml5 mb5">{t('REPORT.CONFIG.CONDITION_DESCRIPTION')}</div>
      </div>
      <div className="flexColumnContainer flexAlignCenter">
        <div className="flexGrowShrink flexColumnContainer flexAlignCenter mb10">
          <Select
            id="conditionSelection"
            classNamePrefix="spec_reportDateModal_condition react-select"
            options={dropdownOptions}
            value={selectedOption}
            isSearchable={false}
            components={{ Option: CustomOption, SingleValue }}
            placeholder={t('REPORT.CONFIG.CONDITION_PLACEHOLDER')}
            className="flexGrowShrink"
            isClearable={true}
            onChange={onDropdownChange}
          />
        </div>
      </div>
      {isConditionSelected && !newDateRange?.auto?.enabled && (
        <div>
          <div className="ml5 mb10">
            <div className="form-check form-check-inline flexColumnContainer">
              <Checkbox
                id="showRelativeSelection"
                type="radio"
                onChange={() => setIsSelectingRelative(true)}
                isChecked={isSelectingRelative || false}
                label={t('REPORT.CONFIG.SELECT_THE_CAPSULE_RELATIVE')}
                classes="form-check-input"
                extraLabel={
                  <span className="ml5 small text-italic sq-darkish-gray">
                    {t('REPORT.CONFIG.SELECT_THE_CAPSULE_RELATIVE_DESCRIPTION')}
                  </span>
                }
              />
            </div>
            <div className="form-check form-check-inline flexColumnContainer">
              <Checkbox
                id="showCapsuleTable"
                type="radio"
                onChange={() => setIsSelectingRelative(false)}
                isChecked={!isSelectingRelative || false}
                label={t('REPORT.CONFIG.SELECT_THE_CAPSULE_TABLE')}
                classes="form-check-input"
                extraLabel={
                  <span className="ml5 small text-italic sq-darkish-gray">
                    {t('REPORT.CONFIG.SELECT_THE_CAPSULE_TABLE_DESCRIPTION')}
                  </span>
                }
              />
            </div>
            <div className="flexFillOverflow" />
          </div>
        </div>
      )}

      {showPeriodicTool && (
        <div className="flexRowContainer" data-testid="specPeriodicCondition">
          <PeriodicCondition isReport={true} onSave={onPeriodicConditionSaved} />
        </div>
      )}

      {selectedCondition?.id === EXISTING_ID && (
        <div className="flexRowContainer mb10" data-testid="specExistingCondition">
          <SearchWidget
            modalId="reportDateFindConditionModal"
            pane={SEARCH_PANE}
            onItemClick={onConditionSelected}
            allowAssetReplacement={false}
            searchTypes={SEARCH_TYPES}
            scopeIds={contentWorkbookIds}
          />
        </div>
      )}
    </div>
  );
};
