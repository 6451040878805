// @ts-strict-ignore
import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import _ from 'lodash';
import { ReportConfigFixedDateRangeTable } from '@/hybrid/reportConfig/components/dateRanges/dateRangeTable/components/ReportConfigFixedDateRangeTable.molecule';
import { ReportConfigAutoUpdateDateRangeTable } from '@/hybrid/reportConfig/components/dateRanges/dateRangeTable/components/ReportConfigAutoUpdateDateRangeTable.molecule';

import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { DateRange } from '@/reportEditor/report.constants';
import { doTrack } from '@/track/track.service';
import { sqReportStore } from '@/core/core.stores';

interface ReportConfigDateRangeTableProps {
  backupPreview: any;
  viewOnly?: boolean;
  dateRanges: DateRange[];
  dateRangeUpdating: boolean;
  hasAutoDateRanges: boolean;
  hasFixedDateRanges: boolean;
  openDateSelector: (dateRange: DateRange, isCapsuleSelect: boolean) => void;
  timezone: string;
  withDateRangeUpdating: (action: Function) => void;
  openBulkEditWithGivenRange: (dateRange: DateRange) => void;
  isSandboxMode?: boolean;
}

export const ReportConfigDateRangeTable: React.FunctionComponent<ReportConfigDateRangeTableProps> = (props) => {
  const {
    timezone,
    hasFixedDateRanges,
    hasAutoDateRanges,
    dateRanges,
    dateRangeUpdating,
    backupPreview,
    withDateRangeUpdating,
    openDateSelector,
    viewOnly = false,
    openBulkEditWithGivenRange,
  } = props;

  const { t } = useTranslation();

  const areDateRangesReadOnly = dateRangeUpdating || backupPreview;

  /**
   * Opens the date selector for a date range. If no date range is provided, the checkFixed parameter
   * is used to determine which option (Fixed or Auto-Update) should initially be checked.
   *
   * @param {DateRange} dateRange - date range to open in date selector
   * @param {boolean} checkFixed - if no date range is provided, should we check Fixed (true) or Auto-Update (false)?
   */
  const openDateSelectorPreChecked = function (dateRange?, isCapsuleSelect?, checkFixed?) {
    if (!areDateRangesReadOnly) {
      let initialDateRange;
      if (dateRange) {
        initialDateRange = _.cloneDeep(dateRange);
      } else if (checkFixed || _.isNil(checkFixed)) {
        initialDateRange = {};
      } else {
        initialDateRange = { auto: { enabled: true } };
      }

      openDateSelector(initialDateRange, isCapsuleSelect);
    }
  };

  return (
    <div className={`flexRowContainer mb15 toolOptions ${areDateRangesReadOnly ? 'disabled' : ''}`}>
      <div className="flexColumnContainer flexSpaceBetween pt10 pb10 pl3">
        <div>
          <Icon icon="fa-calendar" extraClassNames="" type="text" large={true} />
          <span className="mb5 pl5">{t('REPORT.CONFIG.REPORTING_RANGES')}</span>
        </div>
        <div className="mr5 pr3 pl5 flexColumnContainer">
          {!viewOnly && (
            <div
              data-testid="addDateRangeIcon"
              onClick={() => {
                doTrack('Topic', 'Date Range Tool', 'new - main plus icon');
                openDateSelectorPreChecked(undefined, false, true);
              }}>
              <Icon
                icon="fa-plus"
                tooltip="REPORT.CONFIG.CONFIGURE"
                tooltipPlacement="top"
                extraClassNames="fa-fw cursorPointer ml5 mt2"
                large={true}
                type={areDateRangesReadOnly ? 'inherit' : 'theme'}
              />
            </div>
          )}
        </div>
      </div>
      <div className="divider" />
      {!dateRanges.length && !viewOnly && (
        <div className="pt10 pb10 pl3">
          <a
            className="specConfigureCustomDateRangeLink"
            href="#"
            onClick={() => {
              doTrack('Topic', 'Date Range Tool', 'new - text link');
              openDateSelector(undefined, false);
            }}>
            {t('REPORT.CONFIG.CONFIGURE')}
          </a>
        </div>
      )}
      {viewOnly && !sqReportStore.hasInUseDateRanges() && (
        <span className="text-center">{t('REPORT.CONFIG.DATES_NOT_USED')}</span>
      )}
      {hasFixedDateRanges && (
        <div>
          <div className="divider" />
          <ReportConfigFixedDateRangeTable
            areDateRangesReadOnly={areDateRangesReadOnly}
            dateRanges={dateRanges}
            viewOnly={viewOnly}
            timezone={timezone}
            openDateSelector={openDateSelectorPreChecked}
            withDateRangeUpdating={withDateRangeUpdating}
            openBulkEditWithGivenRange={openBulkEditWithGivenRange}
          />
        </div>
      )}
      {hasAutoDateRanges && (
        <div>
          <div className="divider" />
          <ReportConfigAutoUpdateDateRangeTable
            areDateRangesReadOnly={areDateRangesReadOnly}
            dateRanges={dateRanges}
            viewOnly={viewOnly}
            timezone={timezone}
            openDateSelector={openDateSelectorPreChecked}
            withDateRangeUpdating={withDateRangeUpdating}
            openBulkEditWithGivenRange={openBulkEditWithGivenRange}
          />
        </div>
      )}
    </div>
  );
};
