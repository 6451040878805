// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon } from '../core/Icon.atom';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { AssetReplaceModal } from '@/hybrid/search/AssetReplaceModal.molecule';
import { useModalManager } from '@/hybrid/core/hooks/useModalManager.hook';

const panelTabsBindings = bindingsDefinition({
  activeTabIndex: prop<number>(),
  isReportBinder: prop<boolean>(),
  isPresentationMode: prop<boolean>(),
  changeSidebarTab: prop<(tabIndex: string) => void>(),
  isAssetGroupEditorView: prop<boolean>(),
  isViewMode: prop<boolean>(),
  searchTabIndex: prop<string>(),
  investigateTabIndex: prop<string>(),
  annotateTabIndex: prop<string>(),
  reportConfigTabIndex: prop<string>(),
  reportCommentsTabIndex: prop<string>(),
});

export const PanelTabs: SeeqComponent<typeof panelTabsBindings> = ({
  activeTabIndex,
  isReportBinder,
  isPresentationMode,
  changeSidebarTab,
  isAssetGroupEditorView,
  isViewMode,
  searchTabIndex,
  investigateTabIndex,
  annotateTabIndex,
  reportCommentsTabIndex,
  reportConfigTabIndex,
}) => {
  const { t } = useTranslation();

  const [showModal, , modalProps] = useModalManager(AssetReplaceModal);

  const wrappedChangeSidebarTab = (tabIndexAsString) => {
    const tabIndex = _.toNumber(tabIndexAsString);
    if (!isReportBinder) {
      if (tabIndex === _.toNumber(searchTabIndex)) {
        changeSidebarTab('search');
      } else if (!isAssetGroupEditorView) {
        if (tabIndex === _.toNumber(investigateTabIndex)) {
          changeSidebarTab('investigate');
        } else if (tabIndex === _.toNumber(annotateTabIndex)) {
          changeSidebarTab('annotate');
        }
      }
    } else {
      if (tabIndex === _.toNumber(reportConfigTabIndex)) {
        changeSidebarTab('reportConfig');
      } else if (tabIndex === _.toNumber(reportCommentsTabIndex)) {
        changeSidebarTab('reportComments');
      }
    }
  };

  const generateTitle = (id: string, text: string, icon: string) => (
    <div id={id} data-testid={id}>
      <Icon extraClassNames="pr3 sq-text-primary tab-icon" icon={icon} />
      <span>{t(text)}</span>
    </div>
  );

  return (
    <div className="panelTabs" data-testid="panelTabs">
      <Tabs justify={true} transition={false} activeKey={activeTabIndex} onSelect={wrappedChangeSidebarTab}>
        {/* Data Tab */}
        {/* The conditional rendering has to be per tab here because <Tabs> complains if you use <></> */}
        {!isReportBinder && !isPresentationMode && (
          <Tab
            eventKey={searchTabIndex}
            tabClassName="worksheetTabs"
            title={generateTitle('searchTab', 'MY_DATA_HEADER', 'fa-database')}
          />
        )}
        {/* Tools Tab */}
        {!isReportBinder && !isPresentationMode && (
          <Tab
            eventKey={investigateTabIndex}
            disabled={isAssetGroupEditorView}
            tabClassName="worksheetTabs"
            title={generateTitle('investigateTab', 'INVESTIGATE', 'fa-wrench')}
          />
        )}
        {/* Journal Tab */}
        {!isReportBinder && !isPresentationMode && (
          <Tab
            eventKey={annotateTabIndex}
            disabled={isAssetGroupEditorView}
            tabClassName="worksheetTabs"
            title={
              <div id="annotateTab">
                {!isViewMode && <Icon extraClassNames="pr3 sq-text-primary tab-icon" icon="fc-journal" />}
                <span>{t('JOURNAL.HEADER')}</span>
              </div>
            }
          />
        )}
        {/* Report Configuration tab */}
        {isReportBinder && !isPresentationMode && (
          <Tab
            eventKey={reportConfigTabIndex}
            title={
              <div id="reportConfigTab">
                <Icon extraClassNames="pr3 sq-text-primary" icon="fa-cog" />
                <span>{t(isViewMode ? 'REPORT.CONFIG.HEADER_VIEW_MODE' : 'REPORT.CONFIG.HEADER')}</span>
              </div>
            }
          />
        )}
        {/* Report Comments tab */}
        {isReportBinder && !isPresentationMode && (
          <Tab
            eventKey={reportCommentsTabIndex}
            title={generateTitle('reportCommentsTab', 'REPORT.COMMENTS.HEADER', 'fc-comment')}
          />
        )}
      </Tabs>
      {showModal && <AssetReplaceModal {...modalProps} />}
    </div>
  );
};

export const sqPanelTabs = angularComponent(panelTabsBindings, PanelTabs);
