// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { ReportContentStore } from '@/reportEditor/reportContent.store';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { getWorksheet } from '@/hybrid/worksheets/worksheets.utilities';
import { formatDateWorkbook } from '@/hybrid/datetime/dateTime.utilities';

import { getCurrentWorkstepId } from '@/hybrid/worksteps/worksteps.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { ReportContentWorkstep } from '@/hybrid/reportEditor/components/reportContentModal/shared/ReportContentWorkstep.molecule';
import { ReportContentSize } from '@/hybrid/reportEditor/components/reportContentModal/shared/ReportContentSize.molecule';
import { ReportContentDateRange } from '@/hybrid/reportEditor/components/reportContentModal/shared/ReportContentDateRange.molecule';
import { ReportContentSummary } from '@/hybrid/reportEditor/components/reportContentModal/shared/ReportContentSummary.organism';
import { sqRequestsApi } from '@/sdk';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ReportContentAssetSelection } from '@/hybrid/reportEditor/components/reportContentModal/shared/ReportContentAssetSelection.molecule';
import { ReportContentVisualizationToggle } from '@/hybrid/reportEditor/components/reportContentModal/edit/ReportContentVisualizationToggle.molecule';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { useTimeoutWhen } from 'rooks';
import { subscribe } from '@/hybrid/utilities/socket.utilities';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { REPORT_CONTENT } from '@/reportEditor/report.constants';
import { generateRequestId } from '@/hybrid/utilities/http.utilities';
import { sqReportStore } from '@/core/core.stores';
import { canModifyWorkbook as authServiceCanModifyWorkbook } from '@/services/authorization.service';

const reportContentPropertiesBindings = bindingsDefinition({
  contentItems: prop<ReportContentStore[]>(),
  setContentItems: prop<(contentItems: ReportContentStore[]) => void>(),
  sqReportContentActions: injected<ReportContentActions>(),
  sqReportContentStore: injected<ReportContentStore>(),
  sqReportContent: injected<ReportContentService>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
});

export const ReportContentProperties: SeeqComponent<typeof reportContentPropertiesBindings> = ({
  contentItems,
  setContentItems,
}) => {
  const { t } = useTranslation();
  const { sqReportContentActions, sqReportContentStore, sqReportContent, sqHomeScreenUtilities } = useInjectedBindings(
    reportContentPropertiesBindings,
  );

  const {
    workbookId,
    workstepId,
    worksheetId,
    width,
    height,
    useSizeFromRender,
    summary,
    sizeConstant,
    shapeConstant,
    scale,
    sizeKey,
    shapeKey,
    canUseReact,
    isReact,
  } = useFlux(sqReportContentStore);

  const dateRange = sqReportStore.getDateRangeById(sqReportContentStore.dateRangeId);
  const assetSelection = sqReportStore.getAssetSelectionById(sqReportContentStore.assetSelectionId);
  const [owner, setOwner] = useState('');
  const [workbookName, setWorkbookName] = useState('');
  const [zoomPercentage, setZoomPercentage] = useState(0);
  const [canModifyWorkbook, setCanModifyWorkbook] = useState(false);
  const [worksheet, setWorksheet] = useState(null);
  const [sourceWorksheetWorkStepId, setSourceWorksheetWorkStepId] = useState(workstepId);
  const [contentDiffers, setContentDiffers] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [contentIndex, setContentIndex] = useState(0);

  const updateContent = (switchingContent: boolean, newContentIndex?: number) => {
    const newContentItems = [...contentItems];
    newContentItems[contentIndex] = { ...sqReportContentStore };
    setContentItems(newContentItems);
    if (switchingContent) {
      setContentIndex((contentIndex + newContentIndex + newContentItems.length) % newContentItems.length);
    }
  };

  useEffect(() => {
    const currentContent = contentItems[contentIndex];
    sqReportContentActions.setWorksheetId(currentContent.worksheetId);
    sqReportContentActions.setWorkstepId(currentContent.workstepId);
    sqReportContentActions.setScale(currentContent.scale);
    sqReportContentActions.setSummary(currentContent.summary);
    sqReportContentActions.setWidth(currentContent.width);
    sqReportContentActions.setHeight(currentContent.height);
    sqReportContentActions.setSize(currentContent.sizeKey);
    sqReportContentActions.setShape(currentContent.shapeKey);
    sqReportContentActions.setDateRangeId(currentContent.dateRangeId);
    sqReportContentActions.setAssetSelectionId(currentContent.assetSelectionId);
    sqReportContentActions.setIsReact(currentContent.isReact);
    sqReportContentActions.setCanUseReact(currentContent.canUseReact);
    sqReportContentActions.setUseSizeFromRender(currentContent.useSizeFromRender);
    updatePreviewImage();
  }, [contentIndex]);

  useEffect(() => {
    updatePreviewImage();
    updateContent(false);
  }, [workstepId, width, height, summary, scale, sizeKey, shapeKey, dateRange, assetSelection, isReact]);

  const [startImageUrlTimeout, setStartImageUrlTimeout] = useState(false);
  useTimeoutWhen(
    () => {
      setImageUrl(appendRequestIdToUrl(getImageUrl()));
      setStartImageUrlTimeout(false);
    },
    1,
    startImageUrlTimeout,
  );

  const sizes = REPORT_CONTENT.SIZE;
  const shapes = REPORT_CONTENT.SHAPE;
  const scales = REPORT_CONTENT.SCALE;
  const dateRanges = sqReportStore.dateRangesNotArchived;
  const assetSelections = sqReportStore.assetSelectionsNotArchived;
  const summaries = REPORT_CONTENT.SUMMARY;
  const scaleConstant = _.find(scales, ['key', sqReportContentStore.scale]);
  const isImageNoCapsuleFound = dateRange?.auto.noCapsuleFound;
  const isSizeCustom = sizeKey === sizes.CUSTOM.key;
  const setSize = (size) => sqReportContentActions.setSize(size.key);
  const setShape = (shape) => sqReportContentActions.setShape(shape.key);
  const setWidth = (width) => sqReportContentActions.setWidth(width);
  const setHeight = (height) => sqReportContentActions.setHeight(height);
  const setScale = (scale) => sqReportContentActions.setScale(scale.key);
  const setSummary = (summary) => sqReportContentActions.setSummary(summary);
  const setAssetSelectionId = (assetSelection) => sqReportContentActions.setAssetSelectionId(assetSelection?.id);
  const setIsReact = (isReact) => sqReportContentActions.setIsReact(isReact);

  const syncWorkstepToReportModal = ({ workstepId: id }) => {
    setSourceWorksheetWorkStepId(id);
    setContentDiffers(workstepId !== id);
  };

  const getImageUrl = () => {
    return sqReportContentStore.paramsModified || sqReportContentStore.isReact
      ? sqReportContentStore.previewUrl
      : sqReportStore.getContentImageUrl(sqReportContentStore.id, false);
  };

  /*
   *Since the browser makes the img requests and therefore won't have a request ID in the header, we have to append
   * a request ID to the image Url as a query parameter so that appserver can then parse it and use it to cancel
   * the preview image request if it's not necessary anymore
   * */
  const appendRequestIdToUrl = (url) => {
    const reqId = generateRequestId();
    setRequestId(reqId);
    return `${url + (url.includes('?') ? '&' : '?') + SeeqNames.API.QueryParams.requestId}=${reqId}`;
  };

  const updatePreviewImage = () => {
    if (imageUrl !== undefined && stateAndStoreImageUrlsAreEqual()) {
      return;
    }

    if (requestId) {
      sqRequestsApi.cancelRequest({ requestId });
      setRequestId(undefined);
    }

    setIsError(false);
    setIsLoaded(false);

    // Remove the source before we show the preview so that there's no temporary broken image
    // and to allow the spinner to be visible
    setImageUrl(undefined);
    // Must wait a tick so the browser first renders the img tag with undefined src
    setStartImageUrlTimeout(true);
  };

  const stateAndStoreImageUrlsAreEqual = (): boolean => {
    const imageURL = new URL(imageUrl, window.location.href);
    const imageParams = imageURL.searchParams;
    imageParams.delete('requestId');
    imageParams.sort();
    const storeURL = new URL(getImageUrl(), window.location.href);
    const storeParams = storeURL.searchParams;
    storeParams.sort();
    return imageURL.pathname === storeURL.pathname && imageParams.toString() === storeParams.toString();
  };

  const updateFromSourceWorksheet = () => {
    sqReportContent
      .evaluateVisualizationOptions(workbookId, worksheetId, sourceWorksheetWorkStepId, { isReact })
      .then(() => {
        sqReportContentActions.setWorkstepId(sourceWorksheetWorkStepId);
        setContentDiffers(false);
        updatePreviewImage();
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error.message });
      });
  };

  const editSourceWorksheet = () => {
    const url = `/workbook/${workbookId}/worksheet/${worksheetId}`;
    window.open(url, '_blank');
  };

  const calculateImageZoom = (img: any) => {
    setZoomPercentage(_.round((img.width / img.naturalWidth) * 100, 2));
  };

  const setDateRange = (dateRange) => {
    sqReportContentActions.setDateRangeId(dateRange?.id);
  };

  const onImageLoaded = ({ target: img }) => {
    setIsLoaded(true);
    setIsError(false);
    calculateImageZoom(img);
    setRequestId(undefined);
  };

  const onImageError = () => {
    setImageUrl(undefined);
    setIsError(true);
    setIsLoaded(false);
    return true;
  };

  /**
   * Fetches workbook, worksheet, and workstep information to be used by the component. Also sets up a notifier for
   * new worksteps so that the user can be notified if the worksheet is out of date.
   */
  useEffect(() => {
    if (workbookId) {
      sqHomeScreenUtilities.getWorkbookOnly(workbookId).then((workbook: any) => {
        setOwner(workbook?.owner?.name);
        setWorkbookName(workbook?.name);
        setCanModifyWorkbook(authServiceCanModifyWorkbook(workbook, false));
      });

      getWorksheet(workbookId, worksheetId).then((worksheetArg) => {
        setWorksheet(_.pick(worksheetArg, ['name', 'createdAt', 'updatedAt']));
      });

      getCurrentWorkstepId(workbookId, worksheetId).then((workstepIdArg) => {
        setSourceWorksheetWorkStepId(workstepIdArg);
        setContentDiffers(workstepId !== workstepIdArg);
      });
    }

    const offWorkstep = subscribe({
      channelId: [SeeqNames.Channels.WorkstepChannel, worksheetId],
      onMessage: syncWorkstepToReportModal,
    });

    return () => {
      if (requestId) {
        sqRequestsApi.cancelRequest({ requestId });
      }
      offWorkstep();
    };
  }, []);

  return (
    <div
      className="flexFillOverflow flexColumnContainer reportContentProperties"
      data-testid="report-content-properties">
      <div className="flexDoubleFill flexRowContainer">
        <div className="flexRowContainer flexFill">
          {!isError && (
            <div className="flexRowContainer flexFill image p5">
              {contentItems.length > 1 && (
                <div className="flexColumnContainer max-height-25 flexSpaceBetween fs16">
                  <Icon
                    icon="fa-chevron-left"
                    extraClassNames="fa-lg mt3 text-opaque-onhover"
                    onClick={() => updateContent(true, -1)}
                  />
                  <div className="sq-fairly-dark-gray flexAlignCenter fs12">
                    {t('REPORT.CONTENT.PROPERTIES_PAGE_COUNT', {
                      CURRENT_CONTENT: contentIndex + 1,
                      TOTAL_CONTENT: contentItems.length,
                    })}
                  </div>
                  <Icon
                    icon="fa-chevron-right"
                    extraClassNames="fa-lg mt3 text-opaque-onhover"
                    onClick={() => updateContent(true, 1)}
                  />
                </div>
              )}
              <div className="flexRowContainer flexFillOverflow flexCenter">
                {imageUrl && (
                  <img
                    id="contentPreview"
                    alt=""
                    data-testid="previewImageId"
                    className={classNames({
                      loadingSpinnerNoBorder: !isLoaded,
                      contentLoaded: isLoaded,
                    })}
                    src={imageUrl}
                    onLoad={onImageLoaded}
                    onError={onImageError}
                  />
                )}
              </div>
              {isLoaded && zoomPercentage && zoomPercentage < 100 && (
                <div className="flexCenter colorGray pt5">{t('REPORT.CONTENT.SHOWN_AT', { ZOOM: zoomPercentage })}</div>
              )}
            </div>
          )}
          {isError && (
            <div
              className={classNames(
                'placeholder flexRowContainer flexCenter flexFill',
                'text-center pt70 pr10 pl10 sq-text-danger',
                {
                  contentNoCapsuleError: isImageNoCapsuleFound,
                  contentLoadError: !isImageNoCapsuleFound,
                },
              )}>
              {isImageNoCapsuleFound ? t('REPORT.CONTENT.NO_CAPSULE_FOUND') : t('REPORT.CONTENT.FAILED')}
            </div>
          )}
          {worksheet && (
            <div className="properties flexRowContainer flexJustifyCenter backgroundColorOffWhite p5">
              <div className="flexColumnContainer">
                <label className="width-80 text-right flexAlignStart">{t('REPORT.CONFIG.WORKSHEET')}</label>
                <label className="sq-fairly-dark-gray pl15">{worksheet.name}</label>
              </div>
              <div className="flexColumnContainer">
                <label className="width-80 text-right">{t('REPORT.CONFIG.WORKBOOK')}</label>
                <label className="sq-fairly-dark-gray pl15" data-testid="workbookNameId">
                  {workbookName}
                </label>
              </div>
              <div className="flexColumnContainer">
                <label className="width-80 text-right">{t('REPORT.CONFIG.CREATED')}</label>
                <label className="sq-fairly-dark-gray pl15">{formatDateWorkbook(worksheet.createdAt)}</label>
              </div>
              <div className="flexColumnContainer">
                <label className="width-80 text-right">{t('REPORT.CONFIG.UPDATED')}</label>
                <label className="sq-fairly-dark-gray pl15">{formatDateWorkbook(worksheet.updatedAt)}</label>
              </div>
              <div className="flexColumnContainer">
                <label className="width-80 text-right">{t('REPORT.CONFIG.OWNER')}</label>
                <label className="sq-fairly-dark-gray pl15">{owner}</label>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="settings flexFillOverflow flexRowContainer">
        <ReportContentWorkstep
          contentDiffers={contentDiffers}
          canModifyWorkbook={canModifyWorkbook}
          updateFromSourceWorksheet={updateFromSourceWorksheet}
          editSourceWorksheet={editSourceWorksheet}
        />
        {canUseReact && sqReportStore.isCkEnabled && (
          <ReportContentVisualizationToggle isReact={isReact} setIsReact={setIsReact} />
        )}
        <ReportContentSize
          useSizeFromRender={useSizeFromRender}
          isReact={isReact}
          isSizeCustom={isSizeCustom}
          sizes={sizes}
          shapes={shapes}
          scales={scales}
          size={sizeConstant}
          shape={shapeConstant}
          scale={scaleConstant}
          setShape={setShape}
          setSize={setSize}
          setScale={setScale}
          width={width}
          height={height}
          setHeight={setHeight}
          setWidth={setWidth}
        />
        <ReportContentDateRange dateRange={dateRange} dateRanges={dateRanges} setDateRange={setDateRange} />
        <ReportContentAssetSelection
          selection={assetSelection}
          assetSelections={assetSelections}
          setAssetSelection={setAssetSelectionId}
        />
        <ReportContentSummary summary={summary} summaries={summaries} setSummary={setSummary} />
      </div>
    </div>
  );
};
