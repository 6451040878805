// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { ScatterConditionActions } from '@/hybrid/tools/scatterPlotSelection/scatterCondition.actions';
import { ScatterConditionStore } from '@/hybrid/tools/scatterPlotSelection/scatterCondition.store';
import { ToolRunnerService } from '@/services/toolRunner.service';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { VALUE_SEARCH_DURATIONS } from '@/hybrid/toolSelection/investigate.constants';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { Icon } from '@/hybrid/core/Icon.atom';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { formatNumber } from '@/hybrid/utilities/numberHelper.utilities';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { ChartRegion } from '@/chart/chart.module';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { sqDurationStore, sqScatterPlotStore, sqWorksheetStore } from '@/core/core.stores';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { doTrack } from '@/track/track.service';

const scatterPlotSelectionBindings = bindingsDefinition({
  sqInvestigateActions: injected<InvestigateActions>(),
  sqScatterConditionActions: injected<ScatterConditionActions>(),
  sqScatterConditionStore: injected<ScatterConditionStore>(),
  sqToolRunner: injected<ToolRunnerService>(),
  sqScatterPlotActions: injected<ScatterPlotActions>(),
  sqWorksheetActions: injected<WorksheetActions>(),
});

/**
 * This is the tool card for the tool which creates a condition from a region selected on the
 * scatter plot, or values input by the user. The scatter plot selected region will change based on values
 * input to this tool, and the values in the input boxes of the tool will change based on updates to the selected
 * region.
 */
export const ScatterPlotSelection: SeeqComponent<typeof scatterPlotSelectionBindings> = () => {
  const {
    sqInvestigateActions,
    sqScatterConditionActions,
    sqScatterConditionStore,
    sqScatterPlotActions,
    sqWorksheetActions,
  } = useInjectedBindings(scatterPlotSelectionBindings);
  const { t } = useTranslation();

  const worksheetView = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.view.key);
  const displayRange = useFluxPath(sqDurationStore, () => sqDurationStore.displayRange);

  const { id, name, isBounding, isCleansing, minDuration, mergeDuration } = useFlux(sqScatterConditionStore);
  const { xSignal, ySignals, selectedRegion } = useFlux(sqScatterPlotStore);
  // TODO CRAB-27653: Should this generate 1 condition or multiple? Or let the user pick which y signal to use?
  const ySignal = ySignals?.[0];
  const [color, setColor] = useState('');

  const toolName = TREND_TOOLS.SCATTER_CONDITION;
  const isNew = !id;

  const setSearchName = (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.SCATTER_CONDITION, name);

  const setScatterPlotView = () => sqWorksheetActions.setView(WORKSHEET_VIEW.SCATTER_PLOT);

  /**
   * Generates the condition with properties and color by it on the scatter plot
   */
  const execute = () => {
    const { formula, formulaParameters } = sqScatterConditionStore.getFormulaAndParameters(
      selectedRegion,
      xSignal,
      ySignal,
      isBounding,
      displayRange,
      isCleansing,
      minDuration,
      mergeDuration,
    );

    return sqScatterConditionActions
      .generate(name, formula, formulaParameters, color)
      .then((realId) => {
        doTrack('Workbench_Tool', toolName, 'completed');
        sqScatterPlotActions.autoAddNewConditionForColoring(realId, isNew);
        sqInvestigateActions.close();
      })
      .catch((e) => {
        doTrack('Workbench_Tool', toolName, 'error');
        errorToast({ doNotTrack: true, httpResponseOrError: e });
      });
  };

  // TODO CRAB-27993: Scatter Plot selection will be reworked in the future so just use the first y signals range
  //  for now
  const y = selectedRegion.ys[ySignal?.id];
  const selectedRegionAsChartRegion: ChartRegion = {
    xMin: selectedRegion.x.min,
    xMax: selectedRegion.x.max,
    yMin: y?.min,
    yMax: y?.max,
  };

  const formDataSetup: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'scatterPlotSelectionSearchTitle',
      value: name,
      onChange: setSearchName,
      id,
      onColorChange: setColor,
      searchIconClass: 'fc-scatterplot-select',
      defaultName: 'INVESTIGATE_TOOLS.SCATTER_CONDITION.DEFAULT_NAME',
    },
    {
      component: 'ScatterPlotRegionFormComponent',
      name: 'scatterPlotRegion',
      formatter: formatNumber,
      value: selectedRegionAsChartRegion,
      onChange: (region: ChartRegion) => {
        const newRegion = _.cloneDeep(selectedRegion);
        newRegion.x.min = region.xMin;
        newRegion.x.max = region.xMax;
        newRegion.ys[ySignal?.id] = { min: region.yMin, max: region.yMax };
        sqScatterPlotActions.setSelectedRegion(newRegion);
      },
      displayNumber: true,
    },
    {
      component: 'CheckboxFormComponent',
      name: 'isBoundingCheckbox',
      checkboxLabel: 'INVESTIGATE_TOOLS.SCATTER_CONDITION.BOUNDING_CHECKBOX',
      id: 'isBoundingCheckbox',
      onChange: () => sqScatterConditionActions.setBounding(!isBounding),
      value: isBounding,
      displayNumber: true,
    },
    {
      component: 'FormGroup',
      name: 'isCleansingFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'CheckboxFormComponent',
          name: 'isCleansingCheckbox',
          checkboxLabel: 'INVESTIGATE_TOOLS.SCATTER_CONDITION.CLEANSING_CHECKBOX',
          id: 'isCleansingCheckbox',
          onChange: () => sqScatterConditionActions.setCleansing(!isCleansing),
          value: isCleansing,
        },
        {
          component: 'ValueWithUnitsFormComponent',
          name: 'capsulesShorter',
          testId: 'capsulesShorter',
          label: 'INVESTIGATE_TOOLS.SCATTER_CONDITION.IGNORE_SHORT_CAPSULE_DURATION',
          min: 0,
          propName: VALUE_SEARCH_DURATIONS.MIN,
          value: minDuration,
          required: isCleansing,
          onChange: sqScatterConditionActions.setMinDuration,
          includeIf: isCleansing,
          customErrorText: 'VALUE_WITH_UNITS.INVALID',
          customErrorParams: { min: 0 },
          tooltip: 'INVESTIGATE_TOOLS.SCATTER_CONDITION.IGNORE_SHORT_CAPSULE_DURATION_TOOLTIP',
          wrapperClassNames: 'ml3',
        },
        {
          component: 'ValueWithUnitsFormComponent',
          name: 'gapsShorter',
          testId: 'gapsShorter',
          label: 'INVESTIGATE_TOOLS.SCATTER_CONDITION.IGNORE_SHORT_CAPSULE_GAPS',
          min: 0,
          propName: VALUE_SEARCH_DURATIONS.MERGE,
          value: mergeDuration,
          required: isCleansing,
          onChange: sqScatterConditionActions.setMergeDuration,
          includeIf: isCleansing,
          customErrorText: 'VALUE_WITH_UNITS.INVALID',
          customErrorParams: { min: 0 },
          tooltip: 'INVESTIGATE_TOOLS.SCATTER_CONDITION.IGNORE_SHORT_CAPSULE_GAPS_TOOLTIP',
          wrapperClassNames: 'ml3',
        },
      ],
    },
    {
      component: 'DisplayOnlyFormElementWrapper',
      name: 'showScatterPlotView',
      children: (
        <div className="flexColumnContainer pb15 flexCenter mt20 mb10" onClick={setScatterPlotView}>
          <Icon icon="fc-scatterplot" extraClassNames="sq-text-primary p5" />
          <a href="#">{t('INVESTIGATE_TOOLS.SCATTER_CONDITION.SWITCH_VIEW')}</a>
        </div>
      ),
      includeIf: worksheetView !== WORKSHEET_VIEW.SCATTER_PLOT,
    },
  ];

  return (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={execute}
      closeFn={sqInvestigateActions.close}
      toolId={toolName}
      submitBtnId="scatterPlotSelectionButton"
    />
  );
};
