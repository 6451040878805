// @ts-strict-ignore
import _ from 'lodash';
import { sqWorkbenchStore } from '@/core/core.stores';
import { AceOutputV1 } from '@/sdk/model/AceOutputV1';
import { auditingAllUsersCanRead, restrictLogs } from '@/services/systemConfiguration.utilities';

/**
 * Determines if the logs can be viewed by the current user
 *
 * @return {boolean} true if the current user can view logs, otherwise false
 */
export function canViewLogs() {
  // Err on the side of caution and restrict log viewing
  // if the setting is null or undefined as well as true.
  return isAdmin() || restrictLogs() === false;
}

/**
 * Determines if the Audit Trail logs can be viewed by the current user
 *
 * @return {boolean} true if the current user can view Audit Trail Logs,
 * otherwise false even if the setting is null or undefined
 */
export function canReadAuditTrail() {
  return isAdmin() || auditingAllUsersCanRead() === true;
}

/**
 * Determines if the current user can read the item
 *
 * @param item - an item
 * @returns true if the user can read the item, false otherwise
 */
export function canReadItem(item: any): boolean {
  return isAdmin() || !!_.get(item, 'effectivePermissions.read');
}

/**
 * Determines if the current user can write the item
 *
 * @param item - an item
 * @returns true if the user can write the item, false otherwise
 */
export function canWriteItem(item: any): boolean {
  return isAdmin() || !!_.get(item, 'effectivePermissions.write');
}

/**
 * Determines if the current user can annotate the item
 *
 * @param item - an item
 * @returns true if the user can annotate the item, false otherwise
 */
export function canAnnotateItem(item: any): boolean {
  // Users in Seeq can annotate an item if they have permission to read it
  return isAdmin() || !!_.get(item, 'effectivePermissions.read');
}

/**
 * Determines if the current user can manage the item (i.e. view and change ACL permissions)
 *
 * @param item - an item
 * @returns true if the user can manage the item, false otherwise
 */
export function canManageItem(item: any): boolean {
  return isAdmin() || !!_.get(item, 'effectivePermissions.manage');
}

/**
 * Determines if a user has exclusive access
 *
 * @param acl - an access control list
 * @param userId - a user ID
 * @returns true if exclusive access; false otherwise
 */
export function userHasExclusiveAccess(acl: AceOutputV1[], userId = sqWorkbenchStore.currentUser.id): boolean {
  return !_.chain(acl)
    .reject((ace) => _.get(ace, 'identity.id') === userId || !_.some(ace.permissions))
    .some()
    .value() as boolean;
}

/**
 * Determines if the specified workbook can be modified by the current user.
 *
 * @param  {Object} workbook - The workbook to test
 * @param  {Boolean} [disallowArchived=true] - if true prevent modification of archived workbooks
 * @return {Boolean} True if it can be modified, otherwise false
 */
export function canModifyWorkbook(workbook, disallowArchived = true) {
  if (disallowArchived && _.get(workbook, 'isArchived', false)) {
    return false;
  }

  return canWriteItem(workbook);
}

/**
 * Allows the user to do something if they are an administrator.
 *
 * @return {Boolean} True if user is an administrator, false otherwise
 */
export function isAdmin() {
  return !!sqWorkbenchStore.currentUser.isAdmin;
}

/**
 * Allows the user to do something if the value at the supplied property path matches the current user ID.
 *
 * @param {String} userIdPropertyPath - property path to a user ID
 * @return {Boolean} True if the value at the supplied property path matches the current user ID, false otherwise
 */
export function hasCurrentUserId(item, userIdPropertyPath) {
  return _.get(item, userIdPropertyPath) === sqWorkbenchStore.currentUser.id;
}
