import React from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { DateTimeEntry } from '@/hybrid/core/DateTimeEntry.atom';
import { DurationEntry } from '@/hybrid/core/DurationEntry.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { DurationActions } from '@/trendData/duration.actions';

import { Timebar } from '@/hybrid/trend/Timebar.organism';
import { sqDurationStore, sqWorksheetStore } from '@/core/core.stores';
import { isPresentationWorkbookMode } from '@/hybrid/utilities/utilities';

const investigateRangeSelectorBindings = bindingsDefinition({
  sqDurationActions: injected<DurationActions>(),
  displayRangeEditingEnabled: prop<boolean>(),
  investigateRangeEditingEnabled: prop<boolean>(),
});

/**
 * This component controls the date range that is being investigated in a chart/trend. The range can be copied
 * directly to the display range. The display range is always a subset of the investigate range, or equal to the
 * investigate range.
 */
export const InvestigateRangeSelector: SeeqComponent<typeof investigateRangeSelectorBindings> = ({
  displayRangeEditingEnabled,
  investigateRangeEditingEnabled,
}) => {
  const { sqDurationActions } = useInjectedBindings(investigateRangeSelectorBindings);

  const investigateRange = useFluxPath(sqDurationStore, () => sqDurationStore.investigateRange);
  const timezone = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone);
  const isPresentationMode = isPresentationWorkbookMode();
  const copyInvestigateRangeToDisplayRange = sqDurationActions.displayRange.copyFromInvestigateRange;
  const updateInvestigateRangeStart = sqDurationActions.investigateRange.updateStart;
  const updateInvestigateRangeEnd = sqDurationActions.investigateRange.updateEnd;
  const updateInvestigateRangeTimes = sqDurationActions.investigateRange.updateTimes;
  const updateInvestigateRangeDuration = sqDurationActions.investigateRange.updateDuration;

  return (
    <div className="investigateRangeSelector" data-testid="investigateRangeSelector">
      <div className="timebarContainer">
        <Timebar rangeEditingEnabled={displayRangeEditingEnabled} />
      </div>

      <div id="investigateRangeSelector" className="flexColumnContainer flexAlignCenter flexSpaceBetween">
        {!isPresentationMode && (
          <span
            data-testid="specCopyRangeUpBtn"
            id="specCopyRangeUpBtn"
            className="displayRangeIcon btn btn-link sq-btn-xs cursorpointer link-no-underline">
            <Icon
              testId="copyRangeUpIconTestId"
              icon="fc-arrow-up"
              tooltip="RANGE_SELECTOR.COPY_TO_DISPLAY"
              tooltipDelay={1500}
              tooltipPlacement="bottom"
              onClick={copyInvestigateRangeToDisplayRange}
            />
          </span>
        )}

        {isPresentationMode && <div className="ml25" />}

        <div className="flexFillEqually flexAlignStart" id="investigateRangeStart">
          <DateTimeEntry
            testId="specInvestigateRangeStart"
            extraClassName="small"
            date={investigateRange.start}
            updateDate={updateInvestigateRangeStart}
            updateBothDates={updateInvestigateRangeTimes}
            otherDate={investigateRange.end}
            timezone={timezone}
            hideTimezone={true}
            dateFormat="l"
            fieldIdentifier="INVESTIGATE_RANGE_START"
            trackCategory="InvestigateRangeSelector"
            trackInformation="DateTimeEntry"
            trackAction="Start Date Set"
            readOnly={!investigateRangeEditingEnabled}
          />
        </div>

        <div className="flexFillEqually flexCenter flexColumnContainer">
          <DurationEntry
            startDate={investigateRange.start}
            endDate={investigateRange.end}
            updateDuration={updateInvestigateRangeDuration}
            updateDates={updateInvestigateRangeTimes}
            timezone={timezone}
            readOnly={!investigateRangeEditingEnabled}
          />
        </div>

        <div className="flexFillEqually flexFill mr15" id="investigateRangeEnd">
          <DateTimeEntry
            testId="specInvestigateRangeEnd"
            extraClassName="small text-right"
            date={investigateRange.end}
            updateDate={updateInvestigateRangeEnd}
            updateBothDates={updateInvestigateRangeTimes}
            otherDate={investigateRange.start}
            timezone={timezone}
            hideTimezone={true}
            dateFormat="l"
            fieldIdentifier="INVESTIGATE_RANGE_END"
            readOnly={!investigateRangeEditingEnabled}
            trackCategory="InvestigateRangeSelector"
            trackInformation="DateTimeEntry"
            trackAction="End Date Set"
          />
        </div>
      </div>
    </div>
  );
};
