// @ts-strict-ignore
import _ from 'lodash';
import angular from 'angular';
import { API_TYPES } from '@/main/app.constants';
import { ThresholdMetricStore } from '@/hybrid/tools/thresholdMetric/thresholdMetric.store';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { TrendActions } from '@/trendData/trend.actions';
import { WorksheetActions } from '@/worksheet/worksheet.actions';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { formatNumber } from '@/hybrid/utilities/numberHelper.utilities';
import { sqItemsApi, sqMetricsApi } from '@/sdk';
import { formatString } from '@/hybrid/utilities/stringHelper.utilities';
import { isStringSeries } from '@/hybrid/utilities/utilities';
import i18next from 'i18next';
import { doTrack } from '@/track/track.service';
import { defaultNumberFormat, defaultStringFormat } from '@/services/systemConfiguration.utilities';
import { fetchItemUsages } from '@/search/search.actions';

angular.module('Sq.Investigate').service('sqItemProperties', sqItemProperties);

export type ItemPropertiesService = ReturnType<typeof sqItemProperties>;

function sqItemProperties(
  $state: ng.ui.IStateService,
  sqInvestigateActions: InvestigateActions,
  sqWorksheetActions: WorksheetActions,
  sqThresholdMetricStore: ThresholdMetricStore,
  sqTrendActions: TrendActions,
) {
  const service = {
    preparePropertiesListForDisplay,
    setPropertyInternal,
    loadPropertiesHelper,
    showUsagesAndTrack,
    getValidatedNumber,
    getValidatedString,
    setItem,
    getToolType,
  };

  return service;

  /**
   * Determines the tool type from the UIConfig property
   *
   * @returns {string|undefined} the tool type
   */
  function getToolType(propertiesAdvanced) {
    return _.chain(propertiesAdvanced)
      .find(['name', SeeqNames.Properties.UIConfig])
      .get('value')
      .thru((value) => (value ? JSON.parse(value) : undefined))
      .get('type')
      .value();
  }

  /**
   * Sets the current item in the panel
   *
   * @param {Object} item - The selected item
   */
  function setItem(item) {
    sqInvestigateActions.setItem(item.id);
  }

  /**
   * Applies the format string to a representative number and returns a formatted representation of that number or an
   * error string detailing a formatting mistake.
   *
   * @param {String} format - A string detailing the format to validate
   */
  function getValidatedNumber(format: string) {
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    const validNumber = 123456789.987654321;
    let error;

    const formattedNumber = formatNumber(validNumber, { format }, (err) => {
      error = err;
    });
    if (error) {
      return error;
    }

    return formattedNumber;
  }

  /**
   * Applies the format string to a representative string and returns a formatted representation of that string or an
   * error string detailing a formatting mistake.
   *
   * @param {String} format - A string detailing the format to validate
   * @param {String} sample - A sample string on which to demonstrate the formatting
   */
  function getValidatedString(format: string, sample: string) {
    let error;
    const formattedString = formatString(sample, { format }, (err) => {
      error = err;
    });
    if (error) {
      return error;
    }

    return formattedString;
  }

  /**
   * Opens data tab and searches for item usages.
   */
  function showUsagesAndTrack(item) {
    doTrack('Analysis', 'Usages', 'from item');
    fetchItemUsages(item, 'main', $state.params.workbookId ? [$state.params.workbookId] : [], true).then(() => {
      sqWorksheetActions.tabsetChangeTab('sidebar', 'search');
    });
  }

  /**
   * Updates a property of a specified item.
   *
   * @access protected
   * @param {Object} item - Item on which to set the property
   * @param {Object} property - Property to update
   * @param {boolean} isOverridable - whether the property can be overridden by the user
   * @param {boolean} isOverridden - whether the property is overridden by the user
   * @param {Object} property.originalValue - Previous value of the property, for change detection
   * @param {Object} property.value - Value requesting to set
   * @param {Object} [options] - options object
   * @param {boolean} [options.updatePropsOnly] - True to refresh only the properties of the item
   * @param {boolean} [options.skipFetch] - True to skip the item fetch after the property is set; used when the item is
   *   being Archived, since it will immediately be removed and we don't want the side-effect actions to occur
   *
   * @returns {Promise} that resolves when the property value has been set
   */
  function setPropertyInternal(
    item,
    property,
    isOverridable = false,
    isOverridden = false,
    { updatePropsOnly = false, skipFetch = false }: { updatePropsOnly?: boolean; skipFetch?: boolean } = {},
  ) {
    if (property.value === property.originalValue && (!isOverridable || isOverridden)) {
      return Promise.resolve();
    }
    property.originalValue = property.value;

    return sqItemsApi.setProperty({ value: property.value }, { id: item.id, propertyName: property.name }).then(() => {
      if (skipFetch) {
        return;
      }

      return updatePropsOnly ? sqTrendActions.fetchItemProps(item.id) : sqTrendActions.fetchItemAndDependents(item.id);
    });
  }

  /**
   * Appends an originalValue to the properties and prepares the display order like this:
   * 1) the property
   * 2) the property override if it exists
   * 3) the property source if it exists.
   * The code works reliably because the properties returned from the backend are sorted by href which
   * includes the property name
   *
   * @param properties - list of item properties
   * @return a sorted list of properties with the original value appended
   */
  function preparePropertiesListForDisplay(properties: any[]): any[] {
    return (
      _.chain(_.cloneDeep(properties))
        .forEach((property) => {
          property.originalValue = property.value;
        })
        // Property sort order shall be:
        .sortBy((property) =>
          _.startsWith(property.name, 'Source') || _.startsWith(property.name, 'Override')
            ? `${_.replace(property.name, /Source |Override /, '')}z`
            : property.name,
        )
        .value()
    );
  }

  /**
   * Adds the default number format property if no number format is present.
   *
   * @param {any} response - the response
   * @param response {Promise} that resolves with the response number formats added
   */
  function buildNumberFormatProperties(response: any) {
    const formattableItems = [
      API_TYPES.THRESHOLD_METRIC,
      API_TYPES.CALCULATED_SCALAR,
      API_TYPES.CALCULATED_SIGNAL,
      API_TYPES.STORED_SIGNAL,
    ];
    const valueUom = _.find(response.properties, ['name', SeeqNames.Properties.ValueUom]);
    const sourceValueUom = _.find(response.properties, ['name', SeeqNames.Properties.SourceValueUom]);

    if (_.get(valueUom, 'value') === 'string' || _.get(sourceValueUom, 'value') === 'string') {
      _.remove(response.properties, {
        name: SeeqNames.Properties.NumberFormat,
      });

      return Promise.resolve(response);
    }

    if (_.includes(formattableItems, response.type)) {
      const hasNumberFormat = _.some(response.properties, {
        name: SeeqNames.Properties.NumberFormat,
      });

      if (!hasNumberFormat) {
        const sourceNumberFormat = _.find(response.properties, {
          name: SeeqNames.Properties.SourceNumberFormat,
        });
        response.properties.push({
          name: SeeqNames.Properties.NumberFormat,
          value: _.get(sourceNumberFormat, 'value') || defaultNumberFormat(),
        });
        _.set(response, 'numberFormatOverridden', false);
      } else {
        _.set(response, 'numberFormatOverridden', true);
      }
    } else {
      _.set(response, 'numberFormatOverridden', false);
    }

    return Promise.resolve(response);
  }

  /**
   * Adds the default string format property if no string format is present.
   *
   * @param {any} response - the response
   * @param response {Promise} that resolves with the response string formats added
   */
  function buildStringFormatProperties(response: any) {
    const formattableItems = [API_TYPES.CALCULATED_SCALAR, API_TYPES.CALCULATED_SIGNAL, API_TYPES.STORED_SIGNAL];
    const valueUom = _.find(response.properties, ['name', SeeqNames.Properties.ValueUom]);
    const sourceValueUom = _.find(response.properties, ['name', SeeqNames.Properties.SourceValueUom]);
    const signal = {
      valueUnitOfMeasure: valueUom?.value,
      sourceValueUnitOfMeasure: sourceValueUom?.value,
    };

    if (!isStringSeries(signal)) {
      _.remove(response.properties, {
        name: SeeqNames.Properties.StringFormat,
      });

      return Promise.resolve(response);
    }

    if (_.includes(formattableItems, response.type)) {
      const hasStringFormat = _.some(response.properties, {
        name: SeeqNames.Properties.StringFormat,
      });

      if (!hasStringFormat) {
        const sourceStringFormat = _.find(response.properties, {
          name: SeeqNames.Properties.SourceStringFormat,
        });
        response.properties.push({
          name: SeeqNames.Properties.StringFormat,
          value: _.get(sourceStringFormat, 'value') || defaultStringFormat(),
        });
        _.set(response, 'stringFormatOverridden', false);
      } else {
        _.set(response, 'stringFormatOverridden', true);
      }
    } else {
      _.set(response, 'stringFormatOverridden', false);
    }

    return Promise.resolve(response);
  }

  /**
   * Adds an additionalAdvancedProperties array of tool-specific properties for the advanced section
   *
   * @param {any} response - the response
   * @returns {Promise} that resolves with the response with additional advanced properties added
   */
  function addAdditionalAdvancedProperties(response: any) {
    if (response.type !== API_TYPES.THRESHOLD_METRIC) {
      return Promise.resolve(response);
    }

    return sqMetricsApi.getMetric({ id: response.id }).then(({ data: definition }) => {
      response.additionalAdvancedProperties = [];
      _.forEach(['processType', 'measuredItem', 'boundingCondition'], (propName) => {
        const propValue = _.get(definition, propName);
        if (propValue) {
          response.additionalAdvancedProperties.push({
            name: _.startCase(propName),
            value: _.get(propValue, 'id', propValue),
          });
        }
      });

      const priority = i18next.t('PRIORITY');
      _.chain(definition)
        .get('thresholds')
        .forEach((threshold) =>
          response.additionalAdvancedProperties.push({
            name: `${priority} ${_.get(threshold, 'priority.name')}`,
            value: sqThresholdMetricStore.getThresholdString(threshold),
          }),
        )
        .value();

      return response;
    });
  }

  function loadPropertiesHelper(itemId: string) {
    return sqItemsApi
      .getItemAndAllProperties({ id: itemId })
      .then(({ data }) => data)
      .then(addAdditionalAdvancedProperties)
      .then(buildNumberFormatProperties)
      .then(buildStringFormatProperties) as Promise<any>;
  }
}
