// @ts-strict-ignore
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import {
  setBoundingOverride,
  setInputOverride,
  setInterpolation,
  setKeyMethod,
  setStat,
  SignalFromConditionActions,
} from '@/hybrid/tools/signalFromCondition/signalFromCondition.actions';
import _ from 'lodash';
import { ToolPanelFormBuilder } from '@/hybrid/formbuilder/ToolPanelFormBuilder.page';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { DURATION_TIME_UNITS_ALL } from '@/main/app.constants';
import { DEBOUNCE } from '@/core/core.constants';
import { ErrorTypeEnum } from '@/sdk/model/FormulaErrorOutputV1';
import { useDebounce } from '@/hybrid/core/hooks/useDebounce.hook';
import { TrendActions } from '@/trendData/trend.actions';
import { convertDuration } from '@/hybrid/datetime/dateTime.utilities';
import { isStringSeries } from '@/hybrid/utilities/utilities';
import { ITEM_TYPES, PREVIEW_ID, PREVIEW_PREFIX, SAMPLE_OPTIONS } from '@/trendData/trendData.constants';
import { useDidUpdate } from 'rooks';
import { sqInvestigateStore, sqSignalFromConditionStore } from '@/core/core.stores';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { INTERPOLATION_METHODS, SAMPLE_FROM_SCALARS } from '@/services/calculationRunner.constants';
import { doTrack } from '@/track/track.service';
import { FrontendDuration } from '@/services/systemConfiguration.constants';
import { getDefaultMaxInterpolation } from '@/services/systemConfiguration.utilities';

const DURATION_KEY_METHOD = 'duration';

const signalFromConditionBindings = bindingsDefinition({
  sqSignalFromConditionActions: injected<SignalFromConditionActions>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqTrendActions: injected<TrendActions>(),
});

export const SignalFromCondition: SeeqComponent<typeof signalFromConditionBindings> = () => {
  const { sqSignalFromConditionActions, sqInvestigateActions, sqTrendActions } =
    useInjectedBindings(signalFromConditionBindings);

  const {
    id,
    name,
    originalParameters,
    inputItem,
    condition,
    interpolation,
    stat,
    maximumDurationInputOverride,
    maximumDurationBoundingOverride,
    keyMethod,
  } = useFlux(sqSignalFromConditionStore);
  const { item, activeTool } = useFlux(sqInvestigateStore);
  const inProgress = useRef(false);

  const showInputMaximumDurationOverride =
    !!inputItem &&
    inputItem.itemType === ITEM_TYPES.CAPSULE_SET &&
    !_.get(inputItem, 'conditionMetadata.maximumDuration');
  const showBoundingMaximumDurationOverride = condition && !condition.conditionMetadata?.maximumDuration;

  const [color, setColor] = useState('');
  const [maxInterpolation, setMaxInterpolation] = useState<FrontendDuration>();

  const timestampLocationOptions = useMemo(() => {
    if (!(inputItem?.id && inputItem?.itemType)) {
      return [];
    }

    const items =
      inputItem.itemType === ITEM_TYPES.CAPSULE_SET ||
      (inputItem.itemType === ITEM_TYPES.SERIES && isStringSeries(inputItem))
        ? _.reject(SAMPLE_FROM_SCALARS.KEY_METHODS, 'requiresSignal')
        : SAMPLE_FROM_SCALARS.KEY_METHODS;

    return _.map(items, (value) => ({
      value: value.key,
      label: value.title,
    }));
  }, [SAMPLE_FROM_SCALARS.KEY_METHODS, inputItem, inputItem?.itemType]);

  const isComputedMaximumInterpolation = () =>
    !!condition?.conditionMetadata?.maxInterpolation ||
    interpolation === INTERPOLATION_METHODS.DISCRETE ||
    keyMethod === DURATION_KEY_METHOD;

  const getFormulaMaxInterpolation = () => {
    const boundingConditionMaxInterpolation = convertDuration(condition?.conditionMetadata?.maxInterpolation);

    if (keyMethod === DURATION_KEY_METHOD) {
      return undefined;
    } else if (boundingConditionMaxInterpolation) {
      return boundingConditionMaxInterpolation;
    } else {
      return maxInterpolation; // previous, default, or user-set value;
    }
  };

  const generate = (preview: boolean) => {
    const maxInterpolation = getFormulaMaxInterpolation();
    const sampleDisplayOption =
      interpolation === INTERPOLATION_METHODS.DISCRETE && keyMethod !== DURATION_KEY_METHOD
        ? SAMPLE_OPTIONS.BAR
        : SAMPLE_OPTIONS.LINE;

    const params = {
      interpolation,
      stat,
      maximumDuration: {
        inputOverride: maximumDurationInputOverride,
        boundingOverride: maximumDurationBoundingOverride,
      },
      keyMethod,
    };

    if (!stat?.key) {
      return;
    }

    if (preview) {
      sqTrendActions
        .generatePreviewSeries(
          sqSignalFromConditionActions.getFormula(params, maxInterpolation),
          sqSignalFromConditionActions.getParameters(condition.id, inputItem.id),
          id || PREVIEW_ID,
          color,
        )
        .then(() => {
          const newId = id === PREVIEW_ID || _.startsWith(id, PREVIEW_PREFIX) ? id : PREVIEW_PREFIX + id;
          sqTrendActions.setCustomizationProps([{ id: newId, sampleDisplayOption }]);
        });
    } else {
      inProgress.current = true;
      runPreview.cancel();
      sqTrendActions.removePreviewSeries();

      return sqSignalFromConditionActions
        .generate(condition.id, inputItem.id, color, maxInterpolation, params)
        .then((id) => {
          sqTrendActions.setCustomizationProps([{ id, sampleDisplayOption }]);
          doTrack('Workbench_Tool', 'Signal from Condition', 'completed');
        })
        .catch(() => {
          doTrack('Workbench_Tool', 'Signal from Condition', 'error');
        })
        .finally(() => {
          inProgress.current = false;
        });
    }
  };

  const runPreview = useDebounce(() => {
    if (!inProgress.current && activeTool === TREND_TOOLS.SIGNAL_FROM_CONDITION) {
      generate(true);
    }
  }, DEBOUNCE.PREVIEW);

  const previousMaxInterpolation = _.get(sqSignalFromConditionStore, 'signalMetadata.maxInterpolation');

  useEffect(() => {
    if (previousMaxInterpolation && !isComputedMaximumInterpolation()) {
      setMaxInterpolation(previousMaxInterpolation);
    } else {
      setMaxInterpolation(getDefaultMaxInterpolation());
    }

    return () => {
      sqTrendActions.removePreviewSeries();
      runPreview.cancel && runPreview.cancel();
      sqTrendActions.cancelPreviewSeries();
    };
  }, []);

  useDidUpdate(() => {
    if (previousMaxInterpolation) {
      setMaxInterpolation(previousMaxInterpolation);
    }
  }, [previousMaxInterpolation]);

  useEffect(() => {
    const timer = setTimeout(runPreview, DEBOUNCE.PREVIEW);

    return () => clearTimeout(timer);
  }, [
    inputItem,
    condition,
    interpolation,
    stat,
    maximumDurationBoundingOverride,
    maximumDurationInputOverride,
    keyMethod,
  ]);

  const formDataSetup: FormElement[] = [
    {
      component: 'SearchTitleFormComponent',
      name: 'signalFromConditionTitle',
      value: name,
      onChange: (name) => sqInvestigateActions.setSearchName(TREND_TOOLS.SIGNAL_FROM_CONDITION, name),
      id,
      onColorChange: setColor,
      searchIconClass: 'fc-series-gen',
      defaultName: 'SIGNAL_FROM_CONDITION.HEADER',
    },
    {
      component: 'FormGroup',
      name: 'signalConditionFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'LabelFormComponent',
          name: 'signalConditionLabel',
          value: 'SIGNAL_FROM_CONDITION.SELECT_SIGNAL_OR_CONDITION',
        },
        {
          component: 'FormGroup',
          name: 'signalConditionGroup',
          showBracket: true,
          components: [
            {
              component: 'ItemSelectFormComponent',
              name: 'inputItem',
              testId: 'signalConditionInput',
              value: inputItem?.id,
              onChange: (item) =>
                sqInvestigateActions.setParameterItem(TREND_TOOLS.SIGNAL_FROM_CONDITION, 'inputItem', item),
              itemTypes: [ITEM_TYPES.SERIES, ITEM_TYPES.CAPSULE_SET],
              additionalItems: originalParameters,
              excludedIds: id,
              includeMetadata: true,
              extraClassNames: showInputMaximumDurationOverride ? undefined : 'mb-0',
            },
            {
              component: 'MaxCapsuleDurationFormComponent',
              name: 'inputMaximumDurationOverride',
              testId: 'inputMaximumDurationOverride',
              includeIf: showInputMaximumDurationOverride,
              maxDurationRequired: true,
              value: maximumDurationInputOverride,
              onChange: setInputOverride,
              label: 'MAXIMUM_CAPSULE_DURATION',
              tooltip: 'MAXIMUM_CAPSULE_DURATION_TOOLTIP',
              extraClassNames: 'mb-0',
            },
          ],
        },
      ],
    },
    {
      component: 'StatisticSelectorFormComponent',
      name: 'statistic',
      testId: 'statistic',
      label: 'SIGNAL_FROM_CONDITION.SELECT_STATISTIC',
      extraClassNames: 'flexFillOverflow',
      displayNumber: true,
      isRequired: true,
      outputType: ['sample'],
      item: inputItem,
      onChange: setStat,
      value: stat,
      onValidate: () => {},
      validation: (value) => !inputItem || !value?.key,
    },
    {
      component: 'FormGroup',
      name: 'boundingConditionFormGroup',
      displayNumber: true,
      components: [
        {
          component: 'LabelFormComponent',
          name: 'signalConditionLabel',
          value: 'SIGNAL_FROM_CONDITION.SELECT_BOUNDS_CONDITION',
        },
        {
          component: 'FormGroup',
          name: 'boundingConditionGroup',
          showBracket: true,
          components: [
            {
              component: 'ItemSelectFormComponent',
              name: 'condition',
              testId: 'boundingConditionInput',
              value: condition?.id,
              onChange: (item) =>
                sqInvestigateActions.setParameterItem(TREND_TOOLS.SIGNAL_FROM_CONDITION, 'condition', item),
              itemTypes: [ITEM_TYPES.CAPSULE_SET],
              additionalItems: originalParameters,
              includeMetadata: true,
              extraClassNames: showBoundingMaximumDurationOverride ? undefined : 'mb-0',
            },
            {
              component: 'MaxCapsuleDurationFormComponent',
              name: 'boundingMaximumDurationOverride',
              testId: 'boundingMaximumDurationOverride',
              includeIf: showBoundingMaximumDurationOverride,
              maxDurationRequired: true,
              value: maximumDurationBoundingOverride,
              onChange: setBoundingOverride,
              label: 'MAXIMUM_CAPSULE_DURATION',
              tooltip: 'MAXIMUM_CAPSULE_DURATION_TOOLTIP',
              extraClassNames: 'mb-0',
            },
          ],
        },
      ],
    },
    {
      component: 'SelectFormComponent',
      displayNumber: true,
      name: 'keyMethod',
      testId: 'keyMethod',
      value: keyMethod,
      label: 'SIGNAL_FROM_CONDITION.SELECT_TIMESTAMP_LOCATION',
      placeholder: 'SIGNAL_FROM_CONDITION.SELECT_TIMESTAMP_LOCATION_PLACEHOLDER',
      onChange: (value) => {
        if (keyMethod === DURATION_KEY_METHOD) {
          setInterpolation(INTERPOLATION_METHODS.LINEAR);
        }
        setKeyMethod(value);
      },
      options: timestampLocationOptions,
      required: true,
      popoverContent: <img src="/img/timeValueHelp.png" alt="Time Value Help" className="width-175 height-175" />,
    },
    {
      component: 'ButtonGroupFormComponent',
      displayNumber: true,
      name: 'interpolation',
      testId: 'interpolation',
      value: interpolation,
      label: 'SIGNAL_FROM_CONDITION.INTERPOLATION_METHOD',
      onChange: setInterpolation,
      includeIf: keyMethod !== DURATION_KEY_METHOD,
      buttonItems: [
        {
          label: 'SIGNAL_FROM_CONDITION.DISCRETE',
          id: 'signalFromConditionInterpolateDiscrete',
          value: INTERPOLATION_METHODS.DISCRETE,
        },
        {
          label: 'SIGNAL_FROM_CONDITION.LINEAR',
          id: 'signalFromConditionInterpolateLinear',
          value: INTERPOLATION_METHODS.LINEAR,
        },
        {
          label: 'SIGNAL_FROM_CONDITION.STEP',
          id: 'signalFromConditionInterpolateStep',
          value: INTERPOLATION_METHODS.STEP,
        },
      ],
    },
    {
      component: 'ValueWithUnitsFormComponent',
      includeIf: !isComputedMaximumInterpolation(),
      displayNumber: true,
      testId: 'maxInterpolation',
      name: 'maxInterpolation',
      min: 0,
      minIsExclusive: true,
      value: maxInterpolation,
      availableUnits: DURATION_TIME_UNITS_ALL,
      label: 'IMPORTS.INTERPOLATION.MAXGAP',
      tooltip: 'SIGNAL_FROM_CONDITION.MAX_INTERPOLATION_HELP',
      onChange: (value) => {
        if (value.value > 0) {
          setMaxInterpolation(value);
          runPreview();
        }
      },
    },
    {
      component: 'LabelFormComponent',
      value: 'CAPSULE_DURATION.NO_LONGER_REQUIRED',
      includeIf: item.errorType === ErrorTypeEnum.MAXDURATIONPROHIBITED,
      name: 'durationNotRequired',
      testId: 'durationNotRequired',
      extraClassNames: 'sq-text-danger fs12 ml20 mb20',
    },
  ];

  const handleSubmit = () => generate(false);

  return (
    <ToolPanelFormBuilder
      formDefinition={formDataSetup}
      submitFn={handleSubmit}
      closeFn={() => {
        sqInvestigateActions.close();
      }}
      toolId={TREND_TOOLS.SIGNAL_FROM_CONDITION}
      submitBtnId="executeSignalFromConditionTool"
    />
  );
};

export const sqSignalFromCondition = angularComponent(signalFromConditionBindings, SignalFromCondition);
