// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useTranslation } from 'react-i18next';
import { FormulaToolActions } from '@/hybrid/tools/formula/formulaTool.actions';
import { FormulaTextWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';

export type FormulaParameter = {
  name: string;
  type: string;
  optional: boolean;
  description: string;
  defaultValue: string;
};

const formulaParametersBindings = bindingsDefinition({
  parameters: prop<FormulaParameter[]>(),
  sqFormulaToolActions: injected<FormulaToolActions>(),
  requestDocumentation: prop<(href: string) => any>(),
});

export const FormulaParameters: SeeqComponent<typeof formulaParametersBindings> = ({
  parameters,
  requestDocumentation,
}) => {
  const { sqFormulaToolActions } = useInjectedBindings(formulaParametersBindings);
  const { t } = useTranslation();

  const isLast = (index) => _.toNumber(index) === parameters.length - 1;

  return (
    parameters?.length > 0 && (
      <>
        <h2 className="mb5">{t('FORMULA.DOCUMENTATION.PARAMETERS')}</h2>
        {_.map(parameters, (param, index) => (
          <div className={classNames(isLast(index) ? 'mb15' : 'mb10')} key={index} data-testid="parameter">
            <span className="flexColumnContainer flexBaseline">
              <h3 className="mb0 mt3">{param.name}: </h3>
              <span className={`pl5 ${sqFormulaToolActions.formulaTypeClass(param.type)}`}>{param.type}</span>
              {param.optional && (
                <span className="pl2">
                  (
                  {t('FORMULA.DOCUMENTATION.OPTIONAL', {
                    DEFAULT: param.defaultValue,
                  })}
                  )
                </span>
              )}
            </span>
            <div className="pl10">
              <FormulaTextWithHTML requestDocumentation={requestDocumentation} content={param.description} />
            </div>
          </div>
        ))}
      </>
    )
  );
};
