// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import Select, { components } from 'react-select';
import Img from 'react-image';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';

export interface ImageSelectOption {
  text: string;
  value: any;
  imagePath: string;
}

interface ImageSelectProps {
  selectOptions: ImageSelectOption[];
  onChange: (option: { value: any }, property?: string) => void;
  name?: string;
  extraClassNames?: string;
  placeholder?: string;
  value?: any;
  testId?: string;
  insideModal?: boolean;
}

/** Select dropdown element that supports text+image as the individual items */
export const ImageSelect: React.FunctionComponent<ImageSelectProps> = (props) => {
  const { name, value, selectOptions, extraClassNames, placeholder, onChange, insideModal } = props;

  const { t } = useTranslation();

  const menuPosition = insideModal ? 'absolute' : 'fixed';
  const portalStyle = { menuPortal: (base) => ({ ...base, zIndex: 9999 }) };

  const makeLabel = (option) => {
    const isSelectedValue = value === option.value;
    return (
      <span className="flexColumnContainer flexAlignCenter">
        <span className="col-sm-6 flexColumnContainer flexAlignCenter">
          <Icon large={true} icon={isSelectedValue ? 'fa-check-circle' : 'fa-circle-thin'} type="text" />
          <span className="ml10">{t(option.text)}</span>
        </span>
        <Img src={option.imagePath} height={25} alt={`${option.text}_image`} className="col-sm-6" />
      </span>
    );
  };

  const options = _.map(selectOptions, (option) => ({
    ...option,
    label: makeLabel(option),
  }));

  // This enables a different label to display for the selected item once the menu is closed
  const SingleValue = (props) => (
    <components.SingleValue {...props}>
      <span className="flexColumnContainer">
        <span className="mt2 pr10">{t(props.data.text)}</span>
        <Img src={props.data.imagePath} height={20} alt={`${props.data.text}_image`} />
      </span>
    </components.SingleValue>
  );

  return (
    <div data-testid={`${name}_image-select`}>
      <Select
        className={extraClassNames}
        classNamePrefix={`${extraClassNames} image-select react-select`}
        placeholder={_.isEmpty(placeholder) ? '' : t(placeholder)}
        value={_.find(options, { value })}
        menuPosition={menuPosition}
        styles={insideModal && portalStyle}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        options={options}
        isSearchable={false}
        components={{ SingleValue }}
        onChange={(selectedOption) => onChange(selectedOption, name)}
      />
    </div>
  );
};
