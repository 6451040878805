// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { DISPLAY_MODE } from '@/main/app.constants';
import { getAllItems } from '@/hybrid/trend/trendDataHelper.utilities';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { sqDurationStore, sqWorkbookStore, sqWorksheetStore } from '@/core/core.stores';
import { BaseToolStore, ParameterDefinitions } from '@/hybrid/toolSelection/baseTool.store';
import { isItemRedacted } from '@/hybrid/utilities/redaction.utilities';
import { BASE_TOOL_COMMON_PROPS } from '@/hybrid/toolSelection/baseTool.constants';

export const GRID_OPTION = {
  AUTOMATIC: 'automatic',
  CUSTOM: 'custom',
  ORIGINAL: 'original',
};

export class ExportExcelPanelStore extends BaseToolStore {
  static readonly storeName = 'sqExportExcelPanelStore';
  parameterDefinitions: ParameterDefinitions = {
    exportSignals: {
      predicate: ['name', 'a'],
      multiple: true,
    },
    exportConditions: {
      predicate: ['name', 'a'],
      multiple: true,
    },
  };
  type = TREND_TOOLS.EXPORT_EXCEL;

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, {
        name: sqWorkbookStore.name,
        exportStatistics: true,
        exportData: true,
        timeRange: {
          start: sqDurationStore.displayRange.start.valueOf(),
          end: sqDurationStore.displayRange.end.valueOf(),
        },
        exportTimeZone: sqWorksheetStore.timezone,
        gridOption: GRID_OPTION.AUTOMATIC,
        gridSize: { value: undefined, units: 'min' },
        gridOrigin: moment().tz(sqWorksheetStore.timezone.name).startOf('year').valueOf(),
        gridOriginEnabled: false,
      }),
    );
  }

  get exportStatistics() {
    return this.state.get('exportStatistics');
  }

  get exportData() {
    return this.state.get('exportData');
  }

  get exportSignals() {
    return this.state.get('exportSignals');
  }

  get exportConditions() {
    return this.state.get('exportConditions');
  }

  get timeRange() {
    const state = this.state;
    const start = state.get('timeRange', 'start');
    const end = state.get('timeRange', 'end');

    return {
      duration: moment.duration(end - start) as moment.duration,
      end: moment.utc(end) as moment,
      start: moment.utc(start) as moment,
    };
  }

  get exportTimeZone() {
    return this.state.get('exportTimeZone');
  }

  get gridOption() {
    return this.state.get('gridOption');
  }

  get gridSize() {
    return this.state.get('gridSize');
  }

  get gridOrigin() {
    return moment(this.state.get('gridOrigin')).tz(sqWorksheetStore.timezone.name);
  }

  get gridOriginEnabled() {
    return this.state.get('gridOriginEnabled');
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Sets the prediction panel state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    /**
     * Initializes the items to their default values. Done here so that this store doesn't dehydrate a bunch of
     * items when it is not in use.
     *
     * @param {Object} payload - Object containing state information
     * @param {String} payload.mode - The display mode being set, one of DISPLAY_MODE
     * @param {String} payload.type - The name of the tool, one of TREND_TOOLS
     */
    INVESTIGATE_SET_DISPLAY_MODE: (payload: { mode: string; type: string }) => {
      this.reset(payload);

      const getExportItems = (itemType) => {
        return _.chain(
          getAllItems({
            workingSelection: true,
            itemTypes: [itemType],
          }),
        )
          .reject(isItemRedacted)
          .map((item) => _.pick(item, this.TOOL_ITEM_PROPS))
          .value();
      };

      if (payload.mode === DISPLAY_MODE.NEW && payload.type === TREND_TOOLS.EXPORT_EXCEL) {
        this.state.set('exportSignals', getExportItems(ITEM_TYPES.SERIES));
        this.state.set('exportConditions', getExportItems(ITEM_TYPES.CAPSULE_SET));
      }
    },

    /**
     * Sets whether statistics should be exported
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.exportStatistics - True to export statistics
     */
    EXPORT_EXCEL_STATISTICS: (payload: { exportStatistics: boolean }) => {
      this.state.set('exportStatistics', payload.exportStatistics);
    },

    /**
     * Sets whether signal samples should be exported
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.exportData - True to export signal samples
     */
    EXPORT_EXCEL_DATA: (payload: { exportData: boolean }) => {
      this.state.set('exportData', payload.exportData);
    },

    /**
     * Set the time range end value that is used in the form. Ensures that start is not before end.
     *
     * @param {Object} payload - Object container
     * @param {moment} payload.start - The start time
     */
    EXPORT_EXCEL_TIME_RANGE_START: (payload: { start: Date }) => {
      const newStart = payload.start.valueOf();
      const end = this.state.get(['timeRange', 'end']);
      this.state.set(['timeRange', 'start'], newStart);

      if (newStart > end) {
        this.state.set(['timeRange', 'end'], newStart);
      }
    },

    /**
     * Set the time range end value that is used in the form. Ensures that end is not after start.
     *
     * @param {Object} payload - Object container
     * @param {moment} payload.end - The start time
     */
    EXPORT_EXCEL_TIME_RANGE_END: (payload: { end: Date }) => {
      const newEnd = payload.end.valueOf();
      const start = this.state.get(['timeRange', 'start']);
      this.state.set(['timeRange', 'end'], newEnd);

      if (newEnd < start) {
        this.state.set(['timeRange', 'start'], newEnd);
      }
    },

    /**
     * Set the time zone that is used in the form.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.exportTimeZone - The time zone object from Moment.JS
     * @param {String} payload.exportTimeZone.name - The time zone name to be used by the backend
     */
    EXPORT_EXCEL_EXPORT_TIME_ZONE: (payload: { exportTimeZone: { name: string } }) => {
      this.state.set(['exportTimeZone'], payload.exportTimeZone);
    },

    /**
     * Updates the duration of the time range by shifting the start date.
     *
     * @param {Object} payload - Object container
     * @param {moment.duration} payload.duration - The duration
     */
    EXPORT_EXCEL_DURATION: (payload: { duration: moment.duration }) => {
      this.state.set(['timeRange', 'start'], this.state.get('timeRange', 'end') - payload.duration.valueOf());
    },

    /**
     * Set the selected option for gridding.
     *
     * @param {Object} payload - Object container
     * @param {String} payload.gridOption - The grid option
     */
    EXPORT_EXCEL_GRID_OPTION: (payload: { gridOption: string }) => {
      this.state.set('gridOption', payload.gridOption);
    },

    /**
     * If GRID_OPTION.CUSTOM is chosen, the duration between samples to be used for gridding.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.gridSize - The grid size, value and unit
     */
    EXPORT_EXCEL_GRID_SIZE: (payload: { gridSize: string }) => {
      this.state.set('gridSize', payload.gridSize);
    },

    /**
     * If GRID_OPTION.CUSTOM is chosen, the timestamp to originate the gridding through.
     *
     * @param {Object} payload - Object container
     * @param {Object} payload.gridOrigin - The origin of the grid, in the form of a Moment
     */
    EXPORT_EXCEL_GRID_ORIGIN: (payload: { gridOrigin: { value: string } }) => {
      this.state.set('gridOrigin', payload.gridOrigin.valueOf());
    },

    /**
     * The boolean to indicate whether the gridOrigin will be used.
     *
     * @param {Object} payload - Object container
     * @param {boolean} payload.gridOriginEnabled - True if the gridOrigin should be used
     */
    EXPORT_EXCEL_GRID_ORIGIN_ENABLED: (payload: { gridOriginEnabled: boolean }) => {
      this.state.set('gridOriginEnabled', payload.gridOriginEnabled);
    },
  };
}
