// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import _ from 'lodash';
import { Carousel, Modal } from 'react-bootstrap';
import { IS_PROTRACTOR } from '@/main/app.constants';
import Img from 'react-image';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import { TextButton } from '@/hybrid/core/TextButton.atom';
import { Checkbox } from '@/hybrid/core/Checkbox.atom';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { useKey } from '@/hybrid/core/hooks/useKey.hook';
import { headlessRenderMode } from '@/hybrid/utilities/utilities';
import { doTrack } from '@/track/track.service';

const tourModalBindings = bindingsDefinition({
  displayTour: prop<boolean>(),
  tourTitle: prop<string>(),
  tourSteps: prop<{ gifSrc: string; heading: string }[]>(),
  onClose: prop<() => void>(), // called only if the "Don't Show Again" checkbox is checked when closing
  testId: prop<string>(),
  trackName: prop<string>(),
});

export const TourModal: SeeqComponent<typeof tourModalBindings> = ({
  displayTour,
  tourTitle,
  tourSteps,
  onClose,
  trackName,
  testId,
}) => {
  const { t } = useTranslation();

  const [showTour, setShowTour] = useState(displayTour);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const rightArrowKey = useKey(39);
  const leftArrowKey = useKey(37);

  useEffect(() => {
    if (rightArrowKey && activeIndex !== tourSteps.length - 1) {
      setActiveIndex(activeIndex + 1);
    }
  }, [rightArrowKey]);

  useEffect(() => {
    if (leftArrowKey && activeIndex !== 0) {
      setActiveIndex(activeIndex - 1);
    }
  }, [leftArrowKey]);

  const closeTour = () => {
    setShowTour(false);

    const trackInfo = JSON.stringify({
      gifNumber: activeIndex + 1,
      totalGifs: tourSteps.length,
      dontShowChecked: dontShowAgain,
    });

    doTrack('Training', `close ${trackName} tour modal`, trackInfo);

    if (dontShowAgain) {
      onClose();
    }
  };

  const loadingPlaceholder = (showSpinner) => (
    <div className="flexFill height-425 text-center lightGreyBorder flexRowContainer">
      {showSpinner && (
        <div className="flexAlignCenter flexFill flexRowContainer flexJustifyCenter">
          <IconWithSpinner spinning={true} extraClassNames="fa-4x" />
        </div>
      )}
    </div>
  );

  const trackSlideNavigation = (eventKey, direction) =>
    doTrack('Training', `${direction} Arrow clicked`, `Slide ${eventKey + 1}`);

  const carouselIcon = (direction: 'right' | 'left') => (
    <Icon
      icon={`fa-chevron-${direction}`}
      type="theme"
      testId={`arrow-${direction}`}
      extraClassNames="fs30 link-no-underline"
    />
  );

  return (
    showTour &&
    !headlessRenderMode() &&
    !IS_PROTRACTOR && (
      <Modal animation={false} show={true} onHide={closeTour} dialogClassName="min-width-900" data-testid={testId}>
        <Modal.Header closeButton={true}>
          <h3>{t(tourTitle)}</h3>
        </Modal.Header>
        <Modal.Body>
          <Carousel
            className="tourCarousel ml50 mr50 mb25"
            interval={null}
            wrap={false}
            activeIndex={activeIndex}
            onSelect={setActiveIndex}
            onSlide={trackSlideNavigation}
            nextIcon={carouselIcon('right')}
            prevIcon={carouselIcon('left')}>
            {_.map(tourSteps, (step, index) => (
              <Carousel.Item key={index}>
                <div className="flexRowContainer flexFill">
                  <h4>{t(step.heading)}</h4>
                  <Img
                    src={step.gifSrc}
                    height={425}
                    loader={loadingPlaceholder(true)}
                    unloader={loadingPlaceholder(false)}
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>

          <div className="flexColumnContainer flexJustifyEnd">
            <Checkbox
              id="dontShowAgain"
              classes="mr10"
              label="TOUR.DONT_SHOW_AGAIN"
              isChecked={dontShowAgain}
              onChange={() => setDontShowAgain(!dontShowAgain)}
            />
            <TextButton label="TOUR.GOT_IT" testId="gotItButton" variant="theme" onClick={closeTour} />
          </div>
        </Modal.Body>
      </Modal>
    )
  );
};
