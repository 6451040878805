// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import { ButtonWithPopover } from './ButtonWithPopover.molecule';
import _ from 'lodash';
import { TextButton } from '@/hybrid/core/TextButton.atom';

export type ButtonWithPopoverAttachedProps = {
  id: string;
  onClick: () => void;
  label: string;
  popoverContent: React.ReactNode;
  showPopover?: boolean;
  popoverIndicator?: React.ReactNode;
  testId?: string;
};

/**
 * A normal button with a popover button (`<ButtonWithPopover />`) attached
 */
export const ButtonWithPopoverAttached: React.FunctionComponent<ButtonWithPopoverAttachedProps> = ({
  id,
  label,
  onClick,
  showPopover = true,
  popoverIndicator = undefined,
  popoverContent,
  testId = 'buttonWithPopoverAttached',
}) => (
  <div className="ml5 inlineFlex" data-testid={testId}>
    <TextButton
      variant="theme"
      label={label}
      extraClassNames={classNames('height-30', {
        noRightRadius: showPopover && popoverContent,
      })}
      onClick={onClick}
      testId={`${testId}Button`}
    />
    {showPopover && popoverContent && (
      <ButtonWithPopover
        popoverConfig={{ id: `${id}Popover`, placement: 'bottom' }}
        label={
          <TextButton
            variant="theme"
            stopPropagation={false}
            extraClassNames="height-30 mln1 noLeftRadius"
            icon={_.isUndefined(popoverIndicator) && 'fa-caret-down'}
            iconStyle="white"
            formattedLabel={popoverIndicator}
            testId={`${testId}PopoverLabel`}
          />
        }>
        {popoverContent}
      </ButtonWithPopover>
    )}
  </div>
);
