import React from 'react';
import { useTranslation } from 'react-i18next';
import { doTrack } from '@/track/track.service';
import { TextButton } from '@/hybrid/core/TextButton.atom';

interface ReportContentWorkstepProps {
  contentDiffers: boolean;
  canModifyWorkbook: boolean;
  updateFromSourceWorksheet: () => void;
  editSourceWorksheet: any;
}

export const ReportContentWorkstep: React.FunctionComponent<ReportContentWorkstepProps> = ({
  contentDiffers,
  canModifyWorkbook,
  updateFromSourceWorksheet,
  editSourceWorksheet,
}) => {
  const { t } = useTranslation();

  const updateFromSourceWorksheetAndTrack = () => {
    doTrack('Topic', 'Seeq Link - Worksheet', 'update from worksheet');
    updateFromSourceWorksheet();
  };

  const editSourceWorksheetAndTrack = () => {
    doTrack('Topic', 'Seeq Link - Worksheet', 'edit source');
    editSourceWorksheet();
  };

  const contentSameDiv = !contentDiffers && (
    <div className="sq-fairly-dark-gray text-center">{t('REPORT.CONTENT.SAME')}</div>
  );

  const contentDiffersDiv = contentDiffers && (
    <div className="sq-text-warning text-center">{t('REPORT.CONTENT.DIFFERS')}</div>
  );

  const editDisabledDiv = !canModifyWorkbook && (
    <div className="sq-fairly-dark-gray text-center sq-text-warning mt5">
      {t('REPORT.CONTENT.EDIT_DISABLED_TOOLTIP')}
    </div>
  );

  return (
    <div className="flexRowContainer p10">
      {contentSameDiv}
      {contentDiffersDiv}
      <TextButton
        testId="updateContent"
        disabled={!contentDiffers}
        extraClassNames="btn btn-default width-maximum mt5"
        onClick={updateFromSourceWorksheetAndTrack}
        label={t('REPORT.CONTENT.UPDATE')}
      />
      {editDisabledDiv}
      <TextButton
        testId="editWorksheet"
        disabled={!canModifyWorkbook}
        extraClassNames="btn btn-default width-maximum mt5"
        onClick={editSourceWorksheetAndTrack}
        label={t('REPORT.CONTENT.EDIT_WORKSHEET')}
      />
    </div>
  );
};
