// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { Capsule } from '@/hybrid/utilities/datetime.constants';
import { sqConditionsApi } from '@/sdk';
import { setCapsules } from '@/hybrid/tools/manualCondition/capsuleGroup.actions';
import { CapsuleGroupCapsule } from '@/hybrid/tools/manualCondition/manualCondition.constants';
import { formatApiError } from '@/hybrid/utilities/utilities';
import {
  conditionFormula,
  makeCapsulesUnique,
  removeInvalidCapsules,
} from '@/hybrid/tools/manualCondition/conditionFormula.service';
import { sqCapsuleGroupStore } from '@/core/core.stores';

/**
 * Requests capsule properties for the capsules in the manual condition store that have a propertiesSource
 * property. A call is made for each capsule set that encompases the range of all the capsules. The returned
 * capsules are then matched and the properties are copied
 *
 * @return {Promise} a promise that resolves with the formula
 */
export function fetchPropertiesAndBuildFormula() {
  const groupCapsules: CapsuleGroupCapsule[] = sqCapsuleGroupStore.capsules;

  return Promise.resolve()
    .then(() =>
      _.chain(groupCapsules)
        .filter((c) => !_.isNil(c.propertiesSource))
        .groupBy((c) => c.propertiesSource.capsuleSetId)
        .mapValues((capsules) => ({
          capsules,
          startTime: _.minBy(capsules, (c) => c.propertiesSource.startTime).propertiesSource.startTime,
          endTime: _.maxBy(capsules, (c) => c.propertiesSource.endTime).propertiesSource.endTime,
        }))
        .map(({ capsules, startTime, endTime }, capsuleSetId) =>
          sqConditionsApi
            .getCapsules({
              id: capsuleSetId,
              start: moment(startTime).toISOString(),
              end: moment(endTime).toISOString(),
            })
            .catch((error) => Promise.reject(formatApiError(error)))
            .then(({ data }) =>
              _.map(capsules, ({ id, startTime, endTime, propertiesSource }) => {
                const properties = _.get(
                  _.find(
                    data.capsules as any[],
                    (responseCapsule) => responseCapsule.id === propertiesSource.capsuleId,
                  ),
                  'properties',
                  [],
                );

                return { id, startTime, endTime, properties } as Capsule;
              }),
            ),
        )
        .thru((p) => Promise.all(p))
        .value(),
    )
    .then((r) =>
      _.chain(r)
        .flatten()
        .concat(_.filter(groupCapsules, (c) => _.isNil(c.propertiesSource)) as Capsule[])
        .value(),
    )
    .then((c) => _.tap(c, setCapsules))
    .then(removeInvalidCapsules)
    .then(makeCapsulesUnique)
    .then(conditionFormula);
}
