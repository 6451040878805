// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import _ from 'lodash';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { ScatterPlotToolbar } from '@/hybrid/scatterPlot/ScatterPlotToolbar.organism';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { XyPlotChartWrapper } from '@/hybrid/scatterPlot/XyPlotChartWrapper.organism';
import { getScatterPlotChartConfig } from '@/scatterPlot/scatterPlot.selectors';
import { TrendActions } from '@/trendData/trend.actions';
import { ScatterPlotActions } from '@/scatterPlot/scatterPlot.actions';
import { InvestigateRangeSelector } from '@/hybrid/trend/InvestigateRangeSelector.molecule';
import { useFluxEffect } from '@/hybrid/core/hooks/useFluxEffect.hook';
import { getDensityPlotChartConfig } from '@/scatterPlot/densityPlot.selectors';
import { overlaps, SerializedRange, serializeRange } from '@/hybrid/datetime/dateTime.utilities';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { isPresentationWorkbookMode, isStringSeries } from '@/hybrid/utilities/utilities';
import { BottomPanels } from '@/hybrid/trend/panels/bottomPanels/BottomPanels.organism';
import { cancelGroup } from '@/hybrid/requests/pendingRequests.utilities';
import {
  sqDurationStore,
  sqScatterPlotStore,
  sqTrendCapsuleSetStore,
  sqTrendSeriesStore,
  sqTrendStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import {
  SCATTER_PLOT_COLORS,
  SCATTER_PLOT_MODES,
  SCATTER_PLOT_VIEWS,
  ScatterPlotSignal,
} from '@/scatterPlot/scatterPlot.constants';
import { calculate } from '@/hybrid/trend/trendViewer/capsuleBuckets.utilities';
import { FormulaService } from '@/services/formula.service';
import { isItemRedacted } from '@/hybrid/utilities/redaction.utilities';

const xyPlotBindings = bindingsDefinition({
  sqTrendActions: injected<TrendActions>(),
  sqScatterPlotActions: injected<ScatterPlotActions>(),
  sqFormula: injected<FormulaService>(),
});

export const XYPlot: SeeqComponent<typeof xyPlotBindings> = ({}) => {
  const { sqTrendActions, sqScatterPlotActions, sqFormula } = useInjectedBindings(xyPlotBindings);

  const xyPlotRef = useRef(null);
  const [signals, setSignals] = useState<ScatterPlotSignal[] | undefined>([]);
  const [fxLines, setFxLines] = useState([]);
  const [xSignal, setXSignal] = useState<ScatterPlotSignal | undefined>(undefined);
  const [ySignals, setYSignals] = useState<ScatterPlotSignal[] | undefined>([]);
  const [colorSignal, setColorSignal] = useState(undefined);
  const [colorConditions, setColorConditions] = useState([]);
  const [colorRanges, setColorRanges] = useState([]);
  const [regions, setRegions] = useState([]);
  const [minimapRegions, setMinimapRegions] = useState([]);
  const [chartConfig, setChartConfig] = useState(undefined);

  const conditions = useFluxPath(sqTrendCapsuleSetStore, () => sqTrendCapsuleSetStore.items);
  const xValue = useFluxPath(sqTrendStore, () => sqTrendStore.xValue);
  const timezone = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone);
  const { primarySeries } = useFlux(sqTrendSeriesStore);

  const displayRange = useFluxPath(sqDurationStore, () => sqDurationStore.displayRange);
  const [serializedDisplayRange, setSerializedDisplayRange] = useState<SerializedRange>(serializeRange(displayRange));
  useEffect(() => setSerializedDisplayRange(serializeRange(displayRange)), [displayRange]);

  useEffect(() => {
    setSignals(
      _.chain(primarySeries)
        .reject(isStringSeries)
        .reject(isItemRedacted)
        .map((signal) => ({
          ...signal,
          label: signal.name,
          value: signal.id,
        }))
        .value(),
    );
  }, [primarySeries]);

  const [
    {
      minimapSignals,
      xSignal: unformattedXSignal,
      ySignals: unformattedYSignals,
      scatterData,
      densityPlotData,
      storeState,
      plotView,
      plotMode,
      gradientConfig,
      colorCapsuleProperty,
      capsulePropertyColorsConfig,
      selector,
      selectedRegion,
      viewRegion,
      colorSignalId,
      colorConditionIds,
      colorRanges: unformattedColorRanges,
      fxLines: unformattedFxLines,
      colorByItemColor,
    },
    scatterPlotStoreChanged,
  ] = useFluxEffect(sqScatterPlotStore);

  useEffect(() => {
    updateSeriesAndChartConfig();
  }, [scatterPlotStoreChanged, signals, timezone, conditions]);

  useEffect(() => {
    setColorConditions(_.chain(colorConditionIds).map(sqTrendCapsuleSetStore.findItem).compact().value());
  }, [colorConditionIds]);

  useEffect(() => {
    setColorRanges(
      _.chain(unformattedColorRanges)
        .filter(({ range }) =>
          overlaps(range, {
            startTime: displayRange.start,
            endTime: displayRange.end,
          }),
        )
        .map((colorRange) => ({
          id: colorRange.id,
          color: colorRange.color,
          start: colorRange.range.startTime,
          end: colorRange.range.endTime,
        }))
        .sortBy('start')
        .value(),
    );
  }, [displayRange, unformattedColorRanges]);

  useEffect(() => {
    setMinimapRegions(_.chain(colorRanges).concat(regions).reject(_.isUndefined).value());
  }, [colorRanges, regions]);

  useEffect(() => {
    setFxLines(
      _.map(unformattedFxLines, (line) => ({
        ...line,
        name: sqTrendSeriesStore.findItem(line.id)?.name,
      })),
    );
  }, [unformattedFxLines]);

  useEffect(() => {
    if (conditions.length === 0 && plotMode === SCATTER_PLOT_MODES.CAPSULES) {
      sqScatterPlotActions.setPlotMode(SCATTER_PLOT_MODES.DISPLAY_RANGE);
    }
    updateCapsuleRegions();
  }, [displayRange, plotMode, conditions]);

  const updateSeriesAndChartConfig = () => {
    const tempX = sqTrendSeriesStore.findItem(unformattedXSignal?.id);
    const tempY = _.map(unformattedYSignals, (ySignal) => sqTrendSeriesStore.findItem(ySignal?.id));
    setXSignal(tempX);
    setYSignals(tempY);

    // This function is called immediately after fetching a ySignal is added or removed, but highcharts will error
    // if it receives a mismatch more data sets than y axes.
    if (scatterData.length > tempY.length) {
      return;
    }
    setColorSignal(sqTrendSeriesStore.findItem(colorSignalId));
    setChartConfig(
      plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT
        ? getScatterPlotChartConfig({
            state: storeState,
            scatterData,
            xSignal: tempX,
            ySignals: tempY,
          })
        : getDensityPlotChartConfig({
            state: storeState,
            densityPlotData,
            xSignal: tempX,
            ySignal: tempY?.[0],
            colorAxisRange: sqScatterPlotStore.getColorAxisRange(),
          }),
    );
  };

  const updateCapsuleRegions = () => {
    if (plotMode === SCATTER_PLOT_MODES.CAPSULES) {
      const bucketWidth = `${displayRange.duration.asMilliseconds() / sqTrendActions.getChartWidth()}ms`;
      const selectedCapsuleSets = _.some(conditions, 'selected');

      // Remove regions for capsule sets that are no longer in the details pane
      let updatedRegions = _.filter(regions, (region) => _.some(conditions, (item) => item.id === region.capsuleSetId));

      _.forEach(conditions, (item: any) => {
        const cancellationGroup = `scatterPlotRegions-${item.id}`;
        cancelGroup(cancellationGroup, true);
        updatedRegions = _.reject(updatedRegions, { capsuleSetId: item.id });

        if (!selectedCapsuleSets || (selectedCapsuleSets && item.selected)) {
          calculate({
            item,
            range: displayRange,
            bucketWidthArg: bucketWidth,
            cancellationGroup,
            sqFormula,
          })
            .then((regions) => {
              updatedRegions = updatedRegions.concat(regions);
            })
            .catch(() => {
              updatedRegions = _.reject(updatedRegions, {
                capsuleSetId: item.id,
              });
            })
            .finally(() => {
              setRegions(updatedRegions);
            });
        }
      });
    } else {
      setRegions([]);
    }
  };

  const isPresentationMode = isPresentationWorkbookMode();
  const canShowPlot = () => signals.length >= 2;

  return (
    <div className="flexFill flexRowContainer resizablePanelContainer" ref={xyPlotRef} data-testid="xyPlot">
      {isPresentationMode && <div className="mb10" />}

      {!isPresentationMode && <ScatterPlotToolbar fxLines={fxLines} colorRanges={colorRanges} signals={signals} />}

      <XyPlotChartWrapper
        isScatterPlotView={plotView === SCATTER_PLOT_VIEWS.SCATTER_PLOT}
        isDensityPlotView={plotView === SCATTER_PLOT_VIEWS.DENSITY_PLOT}
        canShowPlot={canShowPlot}
        chartConfig={chartConfig}
        seriesX={xSignal}
        seriesY={ySignals?.[0]}
        viewRegion={viewRegion}
        selectedRegion={selectedRegion}
        setPointerValues={sqTrendActions.setPointerValues}
        clearPointerValues={sqTrendActions.clearPointerValues}
        zoomToRegion={sqScatterPlotActions.zoomToRegion}
        setSelectedRegion={sqScatterPlotActions.setSelectedRegion}
        clearSelectedRegion={sqScatterPlotActions.clearSelectedRegion}
        fetchScatterPlot={sqScatterPlotActions.fetchScatterPlot}
        timezone={timezone}
        colorConditions={colorConditions}
        colorSignalName={colorSignal?.name}
        gradientConfig={gradientConfig}
        fxLines={fxLines}
        colorRanges={colorRanges}
        colorCapsuleProperty={colorCapsuleProperty}
        capsulePropertyColorsConfig={capsulePropertyColorsConfig}
        rangeEditingEnabled={!isPresentationMode}
        displayRange={serializedDisplayRange}
        xValue={xValue}
        plotSeries={minimapSignals}
        regions={minimapRegions}
        selectorLow={selector.low}
        selectorHigh={selector.high}
        setSelectors={sqScatterPlotActions.setSelectors}
        useSelectors={!(colorSignal?.name || colorRanges.length || colorByItemColor)}
        backgroundColor={colorRanges.length ? SCATTER_PLOT_COLORS.LOW : undefined}
      />

      {!isPresentationMode && (
        <InvestigateRangeSelector
          displayRangeEditingEnabled={!isPresentationMode}
          investigateRangeEditingEnabled={!isPresentationMode}
        />
      )}

      {!isPresentationMode && (
        <BottomPanels parentHeight={xyPlotRef?.current?.clientHeight} hideCapsulesPanel={false} />
      )}
    </div>
  );
};

export const sqXyPlot = angularComponent(xyPlotBindings, XYPlot);
