import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { useTogglingSelectAll } from '@/hybrid/search/hooks/useTogglingSelectAll.hook';
import { TrendActions } from '@/trendData/trend.actions';
import { ToggleSelectAll } from '@/hybrid/search/multiSelect/ToggleSelectAll.molecule';
import { SelectAndAdd } from '@/hybrid/search/multiSelect/SelectAndAdd.molecule';
import { useTranslation } from 'react-i18next';
import { isAsset, isDisplay } from '@/hybrid/utilities/utilities';
import { doTrack } from '@/track/track.service';
import { SearchPanes } from '@/search/search.constants';
import { getStoreForPane, toggleSelectAllItems } from '@/search/search.actions';

const trendMultiSelectBindings = bindingsDefinition({
  pane: prop<SearchPanes>(),
  items: prop<any[]>(),
  sqTrendActions: injected<TrendActions>(),
});

/**
 * Manages trend multi-select
 */
export const TrendMultiSelect: SeeqComponent<typeof trendMultiSelectBindings> = ({ pane, items }) => {
  const { sqTrendActions } = useInjectedBindings(trendMultiSelectBindings);

  const { t } = useTranslation();

  const store = getStoreForPane(pane);
  const selectAllChecked = useFluxPath(store, () => store.selectAllChecked);
  const selectedItemIds = useFluxPath(store, () => store.selectedItemIds);

  const options = [
    { value: 'separate', label: t('MULTI_SELECT.TREND.SEPARATE') },
    { value: 'same', label: t('MULTI_SELECT.TREND.SAME') },
  ];

  const selectableItems = useMemo(
    () => items?.filter((item) => !isAsset(item) && !isDisplay(item) && !item.hasChildren),
    [items],
  );
  const { togglingSelectAll, setTogglingSelectAll } = useTogglingSelectAll({
    items: selectableItems,
  });
  const [selectedValue, setSelectedValue] = useState(options[0]);

  useEffect(() => {
    setSelectedValue(selectedValue.value === 'separate' ? options[0] : options[1]);
  }, [options[0].label]);

  const isSelectAllDisabled = selectableItems.length === 0;

  return (
    <div className="flexColumnContainer flexAlignCenter" data-testid="multiSelectActions">
      <ToggleSelectAll
        togglingSelectAll={togglingSelectAll}
        isChecked={selectAllChecked}
        onClick={() => {
          setTogglingSelectAll(true);
          toggleSelectAllItems(selectableItems);
        }}
        isDisabled={isSelectAllDisabled}
      />
      <SelectAndAdd
        selectedItemIds={selectedItemIds}
        options={options}
        onChange={setSelectedValue}
        selectedValue={selectedValue}
        onAddClick={() => {
          const selectedItems = selectedItemIds
            .map((id: string) => _.find(selectableItems, { id }))
            .filter((item: string) => !!item);
          if (_.get(selectedValue, 'value', '') === 'separate') {
            sqTrendActions.batchAddItems(selectedItems);
            doTrack('Multi-Select', 'Add items to separate lanes');
          } else {
            sqTrendActions.batchAddItems(selectedItems, false);
            doTrack('Multi-Select', 'Add items to same lane and axis');
          }
        }}
        isSelectAllDisabled={isSelectAllDisabled}
      />
    </div>
  );
};
