// @ts-strict-ignore
import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { CONTENT_TYPES } from './EditDateRangeLabel.molecule';
import _ from 'lodash';
import { Capsule } from '@/hybrid/utilities/datetime.constants';
import { DateRange } from '@/reportEditor/report.constants';

const dateRangeLabelBindings = bindingsDefinition({
  onClick: prop.optional<(e) => void>(),
  targetRef: prop.optional<any>(),
  dateRange: prop<DateRange>(),
  contentToDisplay: prop<string>(),
  format: prop<string>(),
  timezone: prop<string>(),
  capsuleData: prop.optional<Capsule>(),
});

export const DateRangeLabel: SeeqComponent<typeof dateRangeLabelBindings> = (props) => {
  const { dateRange, onClick = null, targetRef = null, contentToDisplay, format, timezone, capsuleData } = props;

  const { t } = useTranslation();
  let content = t('REPORT.DATE_RANGE_LABEL.INVALID_DATE_RANGE');

  if (dateRange) {
    switch (contentToDisplay) {
      case CONTENT_TYPES.START:
        content = moment(dateRange.range.start).tz(timezone).format(format);
        break;
      case CONTENT_TYPES.END:
        content = moment(dateRange.range.end).tz(timezone).format(format);
        break;
      case CONTENT_TYPES.START_END:
        content = `${moment(dateRange.range.start).tz(timezone).format(format)} - ${moment(dateRange.range.end)
          .tz(timezone)
          .format(format)}`;
        break;
      default:
        // check the capsuleData for the property
        if (capsuleData && capsuleData.properties) {
          content = `${_.get(
            _.find(capsuleData.properties, ['name', contentToDisplay]),
            'value',
            t('REPORT.DATE_RANGE_LABEL.INVALID_PROPERTY'),
          )}`;
        }
    }
  }

  return (
    <span ref={targetRef} onClick={onClick} data-testid="dateRangeLabelText">
      {content}
    </span>
  );
};
