// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { formatDuration } from '@/hybrid/datetime/dateTime.utilities';
import { Icon } from '@/hybrid/core/Icon.atom';
import { useTranslation } from 'react-i18next';
import { TrendActions } from '@/trendData/trend.actions';
import { formatXLabel } from '@/hybrid/trend/trendViewer/cursors.service';
import { sqTrendStore, sqWorksheetStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';

const selectedRegionRowBindings = bindingsDefinition({
  numberOfEnabledColumns: prop<number>(),
  sqTrendActions: injected<TrendActions>(),
});

export const SelectedRegionRow: SeeqComponent<typeof selectedRegionRowBindings> = ({ numberOfEnabledColumns }) => {
  const { sqTrendActions } = useInjectedBindings(selectedRegionRowBindings);
  const { t } = useTranslation();

  const [selectedRegionDetails, setSelectedRegionDetails] = useState('');

  const region = useFluxPath(sqTrendStore, () => sqTrendStore.selectedRegion);
  const isRegionSelected = useFluxPath(sqTrendStore, () => sqTrendStore.isRegionSelected());

  useEffect(() => {
    if (isRegionSelected) {
      const minLabel = formatXLabel(region.min, sqWorksheetStore.timezone.name);
      const maxLabel = formatXLabel(region.max, sqWorksheetStore.timezone.name);
      const durationLabel = formatDuration(region.max - region.min, true);
      setSelectedRegionDetails(`${minLabel} - ${maxLabel} (${durationLabel})`);
    } else {
      setSelectedRegionDetails('');
    }
  }, [region, isRegionSelected]);

  return (
    <tr id="selectedRegionLegendItem">
      <td colSpan={3} />
      <td className="icon">
        <Icon
          icon="fa-close"
          extraClassNames="btn-transparent removeButton"
          testId="removeSelectedRegionIcon"
          onClick={(e) => {
            e.stopPropagation();
            sqTrendActions.removeSelectedRegion();
            doTrack('Worksheets', 'Signal removed');
          }}
          tooltip="REMOVE"
          tooltipPlacement="bottom"
        />
      </td>

      <td>
        <Icon
          icon="fa-search-plus"
          testId="zoomToSelectedRegionIcon"
          extraClassNames="btn-transparent mr1 zoomButton"
          onClick={(e) => {
            e.stopPropagation();
            sqTrendActions.zoomToSelectedRegion();
          }}
          tooltip="TOOLBAR.ZOOM"
          tooltipPlacement="bottom"
        />
      </td>

      <td>
        <Icon icon="fa-crop" testId="selectedRegionCropIcon" extraClassNames="pl3 pr3" />
      </td>

      <td />

      <td colSpan={numberOfEnabledColumns}>
        <em>{t('SELECTED_REGION')}</em>
        <em className="pl10">{selectedRegionDetails}</em>
      </td>
    </tr>
  );
};
