import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import React from 'react';
import { Icon } from '@/hybrid/core/Icon.atom';

interface TrendingCountProps {
  /** Number representing current value */
  currentCount: number;
  /** Number representing previous value, to determine direction of trend */
  otherCount: number;
  /** Translation key for label */
  label: string;
  /** True to show even if both values are 0 */
  showWhenZeros?: boolean;
  /** False to hide visual trend triangle */
  showIndicator?: boolean;
  testId?: string;
}

/**
 * Displays a number with label and (optional) visual up/down/blank triangle when greater/less/equal than a second
 * number, indicating the direction of trend (up/down/equal)
 */
export const TrendingCount: React.FunctionComponent<TrendingCountProps> = (props) => {
  const { currentCount, otherCount, label, showWhenZeros = false, showIndicator = true, testId } = props;

  const { t } = useTranslation();

  const renderCount = showIndicator ? (
    <div className="flexColumnContainer" data-testid="trendingCountIndicator">
      <Icon
        testId={testId}
        icon={classNames({
          'fa-caret-up': currentCount > otherCount,
          'fa-caret-down': currentCount < otherCount,
        })}
        type={currentCount > otherCount ? 'success' : 'danger'}
        extraClassNames={classNames('ml5', 'mr5', {
          pr7: currentCount === otherCount,
        })}
      />
      <div>{t(label, { count: currentCount })}</div>
    </div>
  ) : (
    <div>{t(label, { count: currentCount })}</div>
  );

  return currentCount !== 0 || otherCount !== 0 || showWhenZeros ? renderCount : null;
};
