// @ts-strict-ignore
import React, { useState } from 'react';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { TrendActions } from '@/trendData/trend.actions';
import DataSummarySlider from '@/hybrid/trend/DataSummarySlider.molecule';
import { useTranslation } from 'react-i18next';
import { ToolbarPopoverButton } from '@/hybrid/core/ToolbarPopoverButton.molecule';
import { Form, Popover } from 'react-bootstrap';
import _ from 'lodash';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { SummaryTypeEnum } from '@/sdk/model/ContentInputV1';
import { getAllItems } from '@/hybrid/trend/trendDataHelper.utilities';
import { ITEM_TYPES, SummarySliderValues, SummaryTypeSettings } from '@/trendData/trendData.constants';
import { doTrack } from '@/track/track.service';
import { FrontendDuration } from '@/services/systemConfiguration.constants';
import { sqTrendStore } from '@/core/core.stores';

const summarizeDataBindings = bindingsDefinition({
  isSmall: prop<boolean>(),
  sqTrendActions: injected<TrendActions>(),
});

export const SummarizeData: SeeqComponent<typeof summarizeDataBindings> = (props) => {
  const { isSmall } = props;
  const { sqTrendActions } = useInjectedBindings(summarizeDataBindings);
  const { t } = useTranslation();

  const HEADER_CONFIGURATIONS = [
    {
      type: SummaryTypeEnum.AUTO,
      key: 'TOOLBAR.SUMMARY.OPTIONS.AUTO',
      tooltip: 'TOOLBAR.SUMMARY.AUTO_OPTION_TOOLTIP',
    },
    {
      type: SummaryTypeEnum.DISCRETE,
      key: 'TOOLBAR.SUMMARY.OPTIONS.DISCRETE',
      tooltip: 'TOOLBAR.SUMMARY.DISCRETE_OPTION_TOOLTIP',
    },
  ];
  const { dataSummary } = useFlux(sqTrendStore);
  const getLabelText = (type: SummaryTypeEnum, discreteUnits: FrontendDuration, value: number) => {
    const isActive = sqTrendStore.isSummarizationActive;
    if (type === SummaryTypeEnum.DISCRETE && isActive) {
      return `${discreteUnits.value} ${discreteUnits.units}`;
    } else if (type === SummaryTypeEnum.AUTO && isActive) {
      return `Auto: ${value}`;
    } else {
      return t('TOOLBAR.SUMMARY.BUTTON_LABEL');
    }
  };
  const [label, setLabel] = useState(getLabelText(dataSummary.type, dataSummary.discreteUnits, dataSummary.value));
  const [currentSliderSettings, setSliderSettings] = useState(SummaryTypeSettings[dataSummary.type]);
  const isActive = sqTrendStore.isSummarizationActive;

  const changeType = (type: SummaryTypeEnum) => {
    const newValue = SummaryTypeSettings[type].min;
    const newUnits: FrontendDuration = { value: 0, units: 'min' };
    setSliderSettings(SummaryTypeSettings[type]);
    sqTrendActions.setSummary({
      type,
      value: newValue,
      isSlider: true,
      discreteUnits: newUnits,
    });
    setLabel(getLabelText(type, newUnits, newValue));
  };

  const updateSliderValue = (value) => {
    const discreteUnits =
      dataSummary.type === SummaryTypeEnum.DISCRETE
        ? SummarySliderValues[SummaryTypeEnum.DISCRETE].value[value]
        : { value: 0, units: 'min' };
    sqTrendActions.setSummary({
      type: dataSummary.type,
      value,
      isSlider: true,
      discreteUnits,
    });
    setLabel(getLabelText(dataSummary.type, discreteUnits, value));
  };
  const headerOptions = _.map(HEADER_CONFIGURATIONS, ({ type, key, tooltip }) => (
    <Form.Check
      type="radio"
      label={
        <HoverTooltip text={tooltip} placement="right">
          <span className="text-not-bold">{t(key)}</span>
        </HoverTooltip>
      }
      id={`dataSummaryHeaders${type}`}
      value={type}
      key={key}
      checked={dataSummary.type === type}
      onChange={() => changeType(type)}
    />
  ));

  const updateValueWithUnits = (update: FrontendDuration) => {
    sqTrendActions.setSummary({
      type: dataSummary.type,
      value: dataSummary.value,
      discreteUnits: update,
      isSlider: false,
    });
    setLabel(getLabelText(dataSummary.type, update, dataSummary.value));
  };

  const slider = dataSummary.type !== SummaryTypeEnum.NONE && (
    <DataSummarySlider
      label="TOOLBAR.SUMMARY.SLIDER_LABEL"
      min={currentSliderSettings.min}
      max={currentSliderSettings.max}
      step={currentSliderSettings.step}
      onChange={updateSliderValue}
      value={dataSummary.value}
      summary={dataSummary}
      testId="summary-input"
      updateValueWithUnits={updateValueWithUnits}
    />
  );

  function trackSelectionInformation() {
    const { type, value, discreteUnits, isSlider } = dataSummary;
    const typeIsNone = type === SummaryTypeEnum.NONE || (value <= 0 && discreteUnits.value === 0);
    const modeToReport = typeIsNone ? SummaryTypeEnum.NONE : type;
    const action = `Summarize : ${modeToReport}`;
    if (typeIsNone) {
      doTrack('Trend', action);
      return;
    }
    let summaryLevel = '';
    if (type === SummaryTypeEnum.DISCRETE) {
      const discreteValue = isSlider ? value : 'Custom';
      summaryLevel = `Discrete Summary Level: ${discreteValue} - (${discreteUnits.value} ${discreteUnits.units})`;
    } else {
      summaryLevel = `Auto-Summarize Level: ${value}`;
    }

    const displayTypeStats = _.chain(getAllItems({ itemTypes: [ITEM_TYPES.SERIES] }))
      .groupBy('sampleDisplayOption')
      .reduce((result, values, itemType) => {
        result.push(`(${itemType}, ${values.length})`);
        return result;
      }, [])
      .join(', ')
      .thru((displayTypeStats) => `Sample Display Options in form (type, # of this type): ${displayTypeStats}`)
      .value();
    const information = `[${summaryLevel}], [${displayTypeStats}]`;
    doTrack('Trend', action, information);
  }

  return (
    <ToolbarPopoverButton
      label={label}
      tooltipText="TOOLBAR.SUMMARY.BUTTON_TOOLTIP_TEXT"
      tooltipPlacement="top"
      active={isActive}
      icon="fc-binoculars"
      isSmall={isSmall}
      testId="summarize-button"
      onHide={trackSelectionInformation}>
      <Popover id="data-summary-popover">
        <Popover.Content>
          <Popover.Title>{t('TOOLBAR.SUMMARY.OPTIONS_LABEL')}</Popover.Title>
          <div className="flexRowContainer flexNoGrowNoShrink">
            {headerOptions}
            {dataSummary.type !== SummaryTypeEnum.NONE && (
              <Popover.Title>{t('TOOLBAR.SUMMARY.SLIDER_LABEL')}</Popover.Title>
            )}
            {slider}
          </div>
        </Popover.Content>
      </Popover>
    </ToolbarPopoverButton>
  );
};
