import React from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { InjectorContext } from '@/hybrid/core/hooks/useInjector.hook';
import { ErrorBoundaryWithLogging } from '@/hybrid/core/ErrorBoundary.atom';
import { ErrorFallbackIcon } from '@/hybrid/core/ErrorFallback.organism';
import { FluxContext } from './flux.context';
import { flux } from '@/core/flux.module';

export const rootBindings = bindingsDefinition({
  $injector: injected<ng.auto.IInjectorService>(),
  $rootScope: injected<ng.IRootScopeService>(),
});

// Unlike non-root components the injected values of the root component are used as props for the component as well
export type RootProps = PropBindings<typeof rootBindings> & InjectedBindings<typeof rootBindings>;

/**
 * This component is the highest component of a branch of React components and is an ideal location to provide
 * contexts that are in use throughout the whole application.
 */
export const Root: React.FunctionComponent<RootProps> = ({ $injector, children }) => {
  return (
    <FluxContext.Provider value={flux}>
      <InjectorContext.Provider value={$injector}>
        <ErrorBoundaryWithLogging fallback={(error, reset) => <ErrorFallbackIcon error={error} retry={reset} />}>
          {children}
        </ErrorBoundaryWithLogging>
      </InjectorContext.Provider>
    </FluxContext.Provider>
  );
};

/**
 * This version is used in unit tests where the ErrorBoundary could undesirably suppress errors
 */
export const RootForTests: React.FunctionComponent<RootProps> = ({ $injector, $rootScope, children }) => {
  return (
    <FluxContext.Provider value={flux}>
      <InjectorContext.Provider value={$injector}>{children}</InjectorContext.Provider>
    </FluxContext.Provider>
  );
};
