// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { TrendActions } from '@/trendData/trend.actions';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { FormulaParameterOutputV1, sqItemsApi } from '@/sdk';
import { AddToDisplayPane } from '@/hybrid/workbooks/AddToDisplayPane.molecule';
import { findItemIn } from '@/hybrid/trend/trendDataHelper.utilities';
import { TREND_STORES } from '@/trendData/trendData.constants';
import HttpCodes from 'http-status-codes';
import { FormulaService } from '@/services/formula.service';
import { Icon } from '@/hybrid/core/Icon.atom';
import { getHref } from '@/hybrid/utilities/utilities';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { getAxiosInstance } from '@/hybrid/requests/axios.utilities';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';

interface AssetFormulaParameterOutputV1 extends FormulaParameterOutputV1 {
  redacted: boolean;
  assets: string;
}

const formulaParametersBindings = bindingsDefinition({
  selectedItem: prop<{ id: string }>(),
  sqFormula: injected<FormulaService>(),

  sqInvestigateActions: injected<InvestigateActions>(),
  sqTrendActions: injected<TrendActions>(),
});

export const FormulaParameters: SeeqComponent<typeof formulaParametersBindings> = (props) => {
  const { t } = useTranslation();
  const { selectedItem } = props;

  const { sqFormula, sqInvestigateActions, sqTrendActions } = useInjectedBindings(formulaParametersBindings);

  const [isLoading, setIsLoading] = useState(false);
  const [formulaParametersList, setFormulaParametersList] = useState<AssetFormulaParameterOutputV1[]>([]);

  useEffect(() => {
    if (!selectedItem) {
      return;
    }
    setIsLoading(true);
    setFormulaParametersList([]);
    sqItemsApi
      .getItemAndAllProperties({ id: selectedItem.id })
      .then(({ data }) => {
        getAxiosInstance()
          .get(APPSERVER_API_PREFIX + getHref(data))
          .then((response) => response.data as any)
          .then((toolItem) => _.reject(toolItem.parameters, 'unbound'))
          .then((parameters: any) => {
            _.chain(parameters)
              .map((parameter) => {
                const loadedItem = findItemIn(TREND_STORES, parameter.item.id);
                if (loadedItem?.dataStatus === 'ItemDataRedacted') {
                  return Promise.resolve({
                    id: parameter.item.id,
                    redacted: true,
                  });
                }
                const formattedName = loadedItem?.assets?.[0]?.formattedName;
                if (formattedName) {
                  return Promise.resolve({
                    id: parameter.item.id,
                    assets: [{ formattedName }],
                  });
                }
                return sqFormula.getDependencies({ id: parameter.item.id }).catch((error) => {
                  if (error.status === HttpCodes.FORBIDDEN) {
                    return Promise.resolve({
                      id: parameter.item.id,
                      redacted: true,
                    });
                  }
                  throw error;
                });
              })
              .thru((promises) => Promise.all(promises))
              .value()
              .then((results) => {
                _.chain(results)
                  .map((result: any) => {
                    const param = _.find(parameters, (parameter) => parameter.item.id === result.id);
                    param.redacted = result.redacted;
                    param.assets = result?.assets?.[0]?.formattedName || result?.assets?.[0]?.datasource?.name;
                    return param;
                  })
                  .thru((params) => setFormulaParametersList(params))
                  .value();
              })
              .catch((error) => errorToast({ httpResponseOrError: error }));
          });
      })
      .catch((error) => errorToast({ httpResponseOrError: error }))
      .finally(() => setIsLoading(false));
  }, [selectedItem]);

  if (!_.get(selectedItem, 'id')) {
    return null;
  }

  const addItemToTrend = (item) => {
    sqTrendActions.addItem(item);
  };

  const openItemProperties = (item) => {
    addItemToTrend(item);
    sqInvestigateActions.setActiveTool(TREND_TOOLS.PROPERTIES);
    sqInvestigateActions.setItem(item.id);
  };

  return (
    <div data-testid="formulaParameters">
      {!isLoading && formulaParametersList.length > 0 && (
        <div data-testid="formulaParametersTable" className="tableWrapper">
          <table className="table table-striped table-condensed mb10">
            <thead>
              <tr>
                <th className="nowrap">{t('PARAMETERS_LIST.PARAMETER')}</th>
                <th>{t('PARAMETERS_LIST.ITEM')}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {_.map(formulaParametersList, (parameter) => (
                <tr key={parameter.item.id}>
                  <td className="text-valign-middle nowrap semi-bold">${parameter.name}</td>
                  {!parameter.redacted && (
                    <td className="text-valign-middle aggressiveWordBreak">
                      <div
                        data-testid={`parameterLink_${parameter.item.id}`}
                        className="a force-link-color link-underline-hover"
                        onClick={() => openItemProperties(parameter.item)}>
                        {parameter.item.name}
                      </div>
                      <div className="xsmall text-italic breakWord">{parameter.assets}</div>
                    </td>
                  )}
                  {parameter.redacted && (
                    <td className="text-valign-middle">
                      <div
                        data-testid={`parameterLink_${parameter.item.id}`}
                        className="flexColumnContainer flexCenter flexFill">
                        <Icon icon="fa-warning" type="danger" extraClassNames="pr5" />
                        <div className="flexColumnContainer font-size-smaller">{t('NO_ITEM_ACCESS')}</div>
                      </div>
                    </td>
                  )}
                  <td className="text-valign-middle">
                    {!parameter.redacted && (
                      <div data-testid={`parameterTrendLink_${parameter.item.id}`}>
                        <AddToDisplayPane itemId={parameter.item.id} tooltipPlacement="left" />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {isLoading && <i data-testid="spinner" className="fa fa-spinner fa-spin flexSelfCenter" />}
    </div>
  );
};
