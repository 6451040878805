import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { TranslationWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';
import { HelpButton } from '@/hybrid/core/HelpButton.atom';

const reportEditorRendererDisabledError = bindingsDefinition({
  forbiddenContentErrorsCount: prop<number>(),
  isEditMode: prop<boolean>(),
  isReportScheduleError: prop<boolean>(),
});

export const ReportEditorRendererDisabledError: SeeqComponent<typeof reportEditorRendererDisabledError> = (props) => {
  const { forbiddenContentErrorsCount, isEditMode, isReportScheduleError } = props;

  return (
    <>
      {(forbiddenContentErrorsCount > 0 || isReportScheduleError) && (
        <div className="flexColumnContainer verticalAlignMiddle alert-warning p10 lightGreyBorderTop">
          {forbiddenContentErrorsCount > 0 && isReportScheduleError && (
            <TranslationWithHTML
              extraClassName="flexSelfCenter pl10"
              translationKey="REPORT.EDITOR.FORBIDDEN_CONTENT_AND_REPORT_SCHEDULE_ERROR"
              translationParams={{ COUNT: forbiddenContentErrorsCount }}
            />
          )}
          {!(forbiddenContentErrorsCount > 0 && isReportScheduleError) && (
            <>
              {forbiddenContentErrorsCount > 0 && (
                <TranslationWithHTML
                  extraClassName="flexSelfCenter pl10"
                  translationKey="REPORT.EDITOR.FORBIDDEN_CONTENT_ERROR"
                  translationParams={{ COUNT: forbiddenContentErrorsCount }}
                />
              )}
              {isReportScheduleError && (
                <TranslationWithHTML
                  extraClassName="flexSelfCenter pl10"
                  translationKey="REPORT.EDITOR.FORBIDDEN_REPORT_SCHEDULE_ERROR"
                />
              )}
            </>
          )}
          {isEditMode && (
            <HelpButton
              iconClassNames="fa-xlg pl8 text-interactive"
              customChildren={
                <div className="flexRowContainer">
                  <div className="ml5">
                    <TranslationWithHTML translationKey="REPORT.EDITOR.FORBIDDEN_CONTENT_SOLVE_TITLE" />
                  </div>
                  <ul>
                    <li>
                      <TranslationWithHTML translationKey="REPORT.EDITOR.FORBIDDEN_CONTENT_SOLVE_OPTION_1" />
                    </li>
                    <li>
                      <TranslationWithHTML translationKey="REPORT.EDITOR.FORBIDDEN_CONTENT_SOLVE_OPTION_2" />
                    </li>
                  </ul>
                </div>
              }
            />
          )}
        </div>
      )}
    </>
  );
};
