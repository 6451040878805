// @ts-strict-ignore
import { flux } from '@/core/flux.module';

export function setExportStatistics(exportStatistics) {
  flux.dispatch('EXPORT_EXCEL_STATISTICS', { exportStatistics });
}

export function setExportData(exportData) {
  flux.dispatch('EXPORT_EXCEL_DATA', { exportData });
}

export function setTimeRangeStart(start) {
  flux.dispatch('EXPORT_EXCEL_TIME_RANGE_START', { start });
}

export function setTimeRangeEnd(end) {
  flux.dispatch('EXPORT_EXCEL_TIME_RANGE_END', { end });
}

export function setDuration(duration) {
  flux.dispatch('EXPORT_EXCEL_DURATION', { duration });
}

export function setExportTimeZone(exportTimeZone) {
  flux.dispatch('EXPORT_EXCEL_EXPORT_TIME_ZONE', { exportTimeZone });
}

export function setGridOption(gridOption) {
  flux.dispatch('EXPORT_EXCEL_GRID_OPTION', { gridOption });
}

export function setGridSize(gridSize) {
  flux.dispatch('EXPORT_EXCEL_GRID_SIZE', { gridSize });
}

export function setGridOrigin(gridOrigin) {
  flux.dispatch('EXPORT_EXCEL_GRID_ORIGIN', { gridOrigin });
}

export function setGridOriginEnabled(gridOriginEnabled) {
  flux.dispatch('EXPORT_EXCEL_GRID_ORIGIN_ENABLED', { gridOriginEnabled });
}
