import _ from 'lodash';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { BaseToolStore } from '@/hybrid/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/hybrid/toolSelection/baseTool.constants';

export class ItemPropertiesStore extends BaseToolStore {
  type = TREND_TOOLS.PROPERTIES;
  static readonly storeName = 'sqItemPropertiesStore';

  initialize() {
    this.state = this.immutable(
      _.defaults(
        {},
        {
          advancedParametersCollapsed: this.state ? this.state.get('advancedParametersCollapsed') : undefined,
        },
        _.pick(BASE_TOOL_COMMON_PROPS, 'advancedParametersCollapsed'),
      ),
    );
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} State for the store
   */
  dehydrate() {
    return this.state.serialize();
  }

  /**
   * Sets the references panel state
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState: object) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    ...this.baseHandlers,
  };
}
