// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ReportContentService } from '@/hybrid/annotation/reportContent.service';
import { ReportContentActions } from '@/reportEditor/reportContent.actions';
import { ReportEditorService } from '@/reportEditor/reportEditor.service';
import { ContentCallbacks, CustomPlugin } from '@/hybrid/annotation/ckEditorPlugins/CkEditorPlugins.module';
import { setContentPropertyOverrides } from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { CkReportContentService } from '@/hybrid/annotation/ckReportContent.service';
import { logError } from '@/hybrid/utilities/logger';
import { errorToast } from '@/hybrid/utilities/toast.utilities';
import { useTranslation } from 'react-i18next';
import { Visualization } from '@/hybrid/annotation/ckEditorPlugins/components/content.utilities.constants';
import { CONTENT_MODEL_ATTRIBUTES } from '@/hybrid/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { isAxiosError } from '@/hybrid/requests/axios.utilities';
import { copyToClipboard } from '@/hybrid/utilities/tableBuilderHelper.utilities';
import { CONTENT_STATE } from '@/reportEditor/report.constants';

const contentMenuBindings = bindingsDefinition({
  contentId: prop<string>(),
  closeMenu: prop<() => void>(),
  isReact: prop<boolean>(),
  /** The current react properties for [contentId], if the content is interactive **/
  properties: prop.optional<any>(),
  sqReportContent: injected<ReportContentService>(),
  sqCkReportContent: injected<CkReportContentService>(),
  sqReportContentActions: injected<ReportContentActions>(),
  sqReportEditor: injected<ReportEditorService>(),
});

/**
 * A menu containing extra functionality for Content related to updating, refreshing, and formatting content.
 */
export const ContentMenu: SeeqComponent<typeof contentMenuBindings> = (props) => {
  const { contentId, closeMenu, isReact, properties } = props;
  const { sqReportContent, sqCkReportContent, sqReportContentActions, sqReportEditor } =
    useInjectedBindings(contentMenuBindings);

  const { t } = useTranslation();
  const url = `/api/content/${contentId}/sourceUrl`;
  const iconClasses = 'editorBtn fa-2x';

  const editor: any = sqReportEditor.getGlobalInstance();
  const contentCallbacks: ContentCallbacks = editor.config.get(CustomPlugin.Content).contentCallbacks;
  const model = contentCallbacks.getCurrentModel(contentId);

  return (
    <div className="backgroundColorWhite flexColumnContainer flexSpaceBetween min-width-120">
      <Icon
        icon="fc-seeq-content fc-inverse"
        extraClassNames={classNames(iconClasses, 'contentBtn')}
        tooltip="REPORT.EDITOR.UPDATE_BUTTON_TITLE"
        tooltipPlacement="top"
        testId="contentEdit"
        onClick={() => {
          sqReportContent
            .setStoreFromContent(contentId)
            .then(() => sqReportContentActions.setModalName(CONTENT_STATE.LOAD_PROPERTIES))
            .catch((error) => {
              const messageKey =
                isAxiosError(error) && error.response.status === HttpCodes.FORBIDDEN
                  ? 'REPORT.CONTENT.ACCESS_DENIED'
                  : 'REPORT.CONTENT.FAILED';
              errorToast({ messageKey });
              logError(error);
            })
            .finally(closeMenu);
        }}
      />
      <Icon
        extraClassNames={iconClasses}
        icon="fa-external-link"
        tooltip="OPEN_LINK"
        tooltipPlacement="top"
        onClick={() => window.open(url)}
        testId="contentLink"
      />
      <Icon
        extraClassNames={iconClasses}
        icon="fc-redo"
        tooltip="REPORT.EDITOR.REFRESH_CONTENT"
        tooltipPlacement="top"
        onClick={() => sqReportContent.forceRefreshContent(contentId)}
        testId="contentRefresh"
      />
      {isReact && properties?.visualization === Visualization.TABLE && properties.isSimpleMode && (
        <Icon
          extraClassNames={iconClasses}
          icon="fc-bar-chart"
          testId="contentChartView"
          tooltip="TABLE_BUILDER.TOGGLE_CHART_VIEW"
          tooltipPlacement="top"
          onClick={() => {
            const showChartViewOverride = model.getAttribute(
              CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES,
            )?.showChartView;
            const showChartView = _.isUndefined(showChartViewOverride)
              ? !properties?.showChartView
              : !showChartViewOverride;

            setContentPropertyOverrides(editor, contentId, { showChartView });
          }}
        />
      )}
      {isReact &&
        properties?.visualization === Visualization.TABLE &&
        !properties.showChartView &&
        !model.getAttribute(CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES)?.showChartView && (
          <Icon
            extraClassNames={iconClasses}
            icon="fa-copy"
            tooltip="TABLE_BUILDER.COPY_TO_CLIPBOARD_TOOLTIP"
            tooltipPlacement="top"
            testId="contentTableCopy"
            onClick={() => {
              // We can share most of the copy logic by using sqTableBuilderHelper, but we have to disable some
              // CK/organizer specific state to be able to do it. Namely, taking off the noCopy class which prevents
              // copying of elements (which in CK can make copying a single element result in actually copying more
              // than 1) and turning off the content specific copy logic, which would otherwise result in the content
              // being pasted as an image.
              const contentSelector = `[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}="${contentId}"]`;
              const tableWrapper = document.querySelector(`${contentSelector} table`).parentElement;

              contentCallbacks.setUseCkCopyLogic(false);
              const contentWrapper = document.querySelector(contentSelector).parentElement;
              contentWrapper.classList.remove('noCopy');
              copyToClipboard(tableWrapper);
              contentCallbacks.setUseCkCopyLogic(true);
              contentWrapper.classList.add('noCopy');
            }}
          />
        )}
      <>
        {!isReact && (
          <Icon
            extraClassNames={iconClasses}
            icon="fa-window-close-o"
            tooltip="REPORT.EDITOR.CLEAR_RESIZE"
            tooltipPlacement="top"
            testId="contentResizeClear"
            // We can call ckReportContent directly because this will never be used by Froala
            onClick={() => sqCkReportContent.clearContentResize(contentId)}
          />
        )}
        <Icon
          extraClassNames={iconClasses}
          icon="fc-border-style"
          tooltip="REPORT.EDITOR.TOGGLE_BORDER"
          tooltipPlacement="top"
          testId="contentBorderToggle"
          onClick={() => {
            const model = contentCallbacks.getCurrentModel(contentId);
            editor.model.change((writer) =>
              writer.setAttribute(
                CONTENT_MODEL_ATTRIBUTES.BORDER,
                !model.getAttribute(CONTENT_MODEL_ATTRIBUTES.BORDER),
                model,
              ),
            );
          }}
        />
        {!isReact && (
          <Icon
            extraClassNames={iconClasses}
            icon="fc-capsule-bar"
            tooltip="REPORT.EDITOR.NO_MARGIN"
            tooltipPlacement="top"
            testId="contentMarginToggle"
            onClick={() => {
              const model = contentCallbacks.getCurrentModel(contentId);
              editor.model.change((writer) =>
                writer.setAttribute(
                  CONTENT_MODEL_ATTRIBUTES.NO_MARGIN,
                  !model.getAttribute(CONTENT_MODEL_ATTRIBUTES.NO_MARGIN),
                  model,
                ),
              );
            }}
          />
        )}
      </>
    </div>
  );
};
