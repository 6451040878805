// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/hybrid/core/Icon.atom';
import { ButtonWithPopover } from '@/hybrid/core/ButtonWithPopover.molecule';
import { truncate } from '@/hybrid/utilities/utilities';

interface BasicInfoDropdownProps {
  item: any;
  setIsOpen: (isOpen: boolean) => void;
}

export const BasicInfoDropdown: React.FunctionComponent<BasicInfoDropdownProps> = (props) => {
  const { item, setIsOpen } = props;
  const { t } = useTranslation();
  const dropdownId = `itemInfo-${item.workbookId}`;

  return (
    <div className="mr10 pt10">
      <ButtonWithPopover
        label={
          <div className="sq-icon-hover">
            <Icon
              icon="fa-info-circle"
              extraClassNames="fa-fw fa-xlg folderAction"
              tooltip="HOME_SCREEN.INFO_POPOVER.TOOLTIP"
              tooltipDelay={500}
              testId="basicInfoDropdownButton"
              id={dropdownId}
            />
          </div>
        }
        popoverConfig={{
          id: `${dropdownId}Menu`,
          placement: 'bottom-end',
        }}
        onToggle={setIsOpen}
        extraClassNames="width-fit-content">
        <div
          className="forceFont p10 min-width-200 max-height-250 overflowYAuto overflowXHidden"
          data-testid="basicInfoDropdown">
          <div className="text-bolder sq-text-primary pb5 aggressiveWordBreak">{truncate(item?.name, 80, 35)}</div>
          <div>
            {item?.description ? item.description : <span className="text-italic">{t('WORKBOOK_NO_DESCRIPTION')}</span>}
          </div>
        </div>
      </ButtonWithPopover>
    </div>
  );
};
