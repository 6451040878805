/**
 * Check mark or number used in the tool panels
 *
 * @param number (number): which number to show
 * @param showNumber (boolean): true to show the number, false to show the check mark
 */
import React from 'react';
import { Icon } from '@/hybrid/core/Icon.atom';

interface CheckMarkOrNumberProps {
  number: number;
  showNumber: boolean;
}

export const CheckMarkOrNumber: React.FunctionComponent<CheckMarkOrNumberProps> = (props) => {
  const { number, showNumber } = props;

  return (
    <div className="flexRowContainer">
      <div className="min-width-30">
        {showNumber ? (
          <h4 className="mt0 mb0 sq-force-text-gray">{number}.</h4>
        ) : (
          <Icon icon="fa-check" number={number} />
        )}
      </div>
    </div>
  );
};
