// @ts-strict-ignore
import bind from 'class-autobind-decorator';
import _ from 'lodash';
import {
  convertFrequencyToPeriod,
  determineIdealUnits,
  getCapsuleFormula,
  isFrequency,
  updateUnits,
} from '@/hybrid/datetime/dateTime.utilities';
import { ToolRunnerService } from '@/services/toolRunner.service';

import { DURATION_TIME_UNITS } from '@/main/app.constants';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { FormulaService } from '@/services/formula.service';
import { flux } from '@/core/flux.module';
import { checkWindowSizeRatio } from '@/hybrid/utilities/investigateHelper.utilities';
import i18next from 'i18next';
import { sqDurationStore, sqSignalSmoothingStore } from '@/core/core.stores';
import {
  SMOOTHING_ALGORITHMS,
  SMOOTHING_DEFAULTS,
  SMOOTHING_LIMITS,
} from '@/hybrid/tools/signalSmoothing/signalSmoothing.constants';

@bind
export class SignalSmoothingActions {
  constructor(
    public sqToolRunner: ToolRunnerService,
    public sqFormula: FormulaService,
    public sqInvestigateActions: InvestigateActions,
  ) {}

  getFormulaAndTrackAction(algorithm) {
    let formula = '$inputSignal';
    let trackAction = '';
    const format = (valueWithUnits) => `${valueWithUnits.value}${valueWithUnits.units}`;

    const samplingRate = format(sqSignalSmoothingStore.samplingRate);
    const cutoff = format(sqSignalSmoothingStore.cutoff);
    const smoothingWindow = format(sqSignalSmoothingStore.smoothingWindow);
    const tauOrAlpha = sqSignalSmoothingStore.isTau ? format(sqSignalSmoothingStore.tau) : sqSignalSmoothingStore.alpha;

    switch (algorithm) {
      case SMOOTHING_ALGORITHMS.SEEQ_AGILE.VALUE:
        formula += `.agileFilter(${samplingRate},${smoothingWindow})`;
        trackAction = 'Seeq Agile Filter';
        break;
      case SMOOTHING_ALGORITHMS.SAVITZKY.VALUE:
        formula += `.sgFilter(${samplingRate},${smoothingWindow},${sqSignalSmoothingStore.polynomialFactor})`;
        trackAction = 'Savitzky-Golay Filter';
        break;
      case SMOOTHING_ALGORITHMS.LOW_PASS.VALUE:
        formula += `.lowPassFilter(${cutoff},${samplingRate},${this.calculateTaps()})`;
        trackAction = 'Low Pass Filter';
        break;
      case SMOOTHING_ALGORITHMS.MOVING_AVERAGE.VALUE:
        formula += `.movingAverageFilter(${samplingRate},${smoothingWindow})`;
        trackAction = 'Moving Average Filter';
        break;
      case SMOOTHING_ALGORITHMS.EXPONENTIAL.VALUE:
        formula += `.exponentialFilter(${tauOrAlpha},${samplingRate})`;
        trackAction = 'Exponential Filter';
        break;
    }

    return { formula, trackAction };
  }

  setAlgorithmSelectedValue(algorithmSelectedValue) {
    if (algorithmSelectedValue === SMOOTHING_ALGORITHMS.EXPONENTIAL.VALUE) {
      this.getEstimatedSamplePeriod();
    }
    flux.dispatch('SIGNAL_SMOOTHING_SET_ALGORITHM_SELECTED_VALUE', {
      algorithmSelectedValue,
    });
  }

  setSamplingRate(samplingRate) {
    flux.dispatch('SIGNAL_SMOOTHING_SET_SAMPLING_RATE', { samplingRate });
  }

  setSmoothingWindow(smoothingWindow) {
    flux.dispatch('SIGNAL_SMOOTHING_SET_SMOOTHING_WINDOW', { smoothingWindow });
    // if isSamplingRateAuto is true then update the sampling rate whenever the smoothing window changes
    if (sqSignalSmoothingStore.isSamplingRateAuto) {
      this.recalculateSamplingRate(smoothingWindow);
    }
  }

  setCutoff(cutoff) {
    flux.dispatch('SIGNAL_SMOOTHING_SET_CUTOFF', { cutoff });
  }

  setPolynomialFactor(polynomialFactor) {
    flux.dispatch('SIGNAL_SMOOTHING_SET_POLYNOMIAL_FACTOR', {
      polynomialFactor,
    });
  }

  setIsSamplingRateAuto(isSamplingRateAuto) {
    flux.dispatch('SIGNAL_SMOOTHING_SET_IS_SAMPLE_RATE_AUTO', {
      isSamplingRateAuto,
    });

    if (isSamplingRateAuto) {
      this.recalculateSamplingRate(sqSignalSmoothingStore.smoothingWindow);
    }
  }

  setIsCutoffAuto(isCutoffAuto) {
    flux.dispatch('SIGNAL_SMOOTHING_SET_IS_CUTOFF_AUTO', { isCutoffAuto });

    if (isCutoffAuto) {
      this.recalculateCutoff(sqSignalSmoothingStore.samplingRate);
    }
  }

  setIsTau(isTau) {
    flux.dispatch('SIGNAL_SMOOTHING_SET_IS_TAU', { isTau });
  }

  setTau(tau) {
    flux.dispatch('SIGNAL_SMOOTHING_SET_TAU', { tau });
  }

  setAlpha(alpha) {
    flux.dispatch('SIGNAL_SMOOTHING_SET_ALPHA', { alpha });
  }

  setIsMigratedLowPassFilter(isMigratedLowPassFilter) {
    flux.dispatch('SIGNAL_SMOOTHING_SET_IS_MIGRATED_LOW_PASS_FILTER', {
      isMigratedLowPassFilter,
    });
  }

  calculateTaps() {
    const windowDuration = updateUnits(
      sqSignalSmoothingStore.smoothingWindow.value,
      'ms',
      sqSignalSmoothingStore.smoothingWindow.units,
    ).value;
    const rateDuration = updateUnits(
      sqSignalSmoothingStore.samplingRate.value,
      'ms',
      sqSignalSmoothingStore.samplingRate.units,
    ).value;
    const taps = 2 * Math.floor(windowDuration / rateDuration / 2) + 1;
    return taps > 3 ? taps : SMOOTHING_LIMITS.TAPS_MIN;
  }

  recalculateSamplingRate(smoothingWindow) {
    if (smoothingWindow.valid) {
      const windowMS = updateUnits(smoothingWindow.value, 'ms', smoothingWindow.units).value;
      const newSamplingRate = windowMS / SMOOTHING_DEFAULTS.TAPS;
      const samplingRate = determineIdealUnits({
        value: newSamplingRate,
        units: 'ms',
      });
      this.setSamplingRate({
        value: Math.round(samplingRate.value),
        units: samplingRate.units,
        valid: true,
      });
    }
  }

  recalculateCutoff(samplingRate) {
    if (samplingRate.valid) {
      this.setCutoff({
        value: SMOOTHING_DEFAULTS.CUTOFF_RATIO * samplingRate.value,
        units: samplingRate.units,
        valid: true,
      });
    }
  }

  executeSignalSmoothing(formula, parameters, color?) {
    return this.sqToolRunner.panelExecuteSignal(
      sqSignalSmoothingStore.name,
      formula,
      parameters,
      sqSignalSmoothingStore.configParams,
      sqSignalSmoothingStore.id,
      color,
      { notifyOnError: false },
    );
  }

  setDefaultWindowSize() {
    // We don't want to override the window size when migrating existing low pass filters to the smoothing tool
    if (sqSignalSmoothingStore.isMigratedLowPassFilter) {
      return Promise.resolve();
    }

    return this.fetchEstimatedSamplePeriod().then((result) => {
      const defaultSmoothingWindow = determineIdealUnits({
        value: result.value * SMOOTHING_DEFAULTS.TAPS,
        units: result.uom,
      });
      this.setSmoothingWindow({
        units: defaultSmoothingWindow.units,
        value: Math.round(defaultSmoothingWindow.value),
        valid: true,
      });
    });
  }

  fetchEstimatedSamplePeriod() {
    const parameters = {
      inputSignal: sqSignalSmoothingStore.inputSignal?.id,
    };
    const formula = `estimateSamplePeriod($inputSignal, ${getCapsuleFormula({
      start: sqDurationStore.displayRange.start,
      end: sqDurationStore.displayRange.end,
    })})`;
    return this.sqFormula
      .computeScalar({
        formula,
        parameters,
        cancellationGroup: 'estimateSamplePeriod',
      })
      .then((result) => result);
  }

  getEstimatedSamplePeriod() {
    return this.fetchEstimatedSamplePeriod().then((result) => {
      const samplingRate = determineIdealUnits({
        value: result.value,
        units: result.uom,
      });
      this.setSamplingRate(samplingRate);
    });
  }

  // Polynomial Factor must be greater than or equal to 1 and less than or equal to 7
  invalidPolynomialFactorRange(factor) {
    return factor < SMOOTHING_LIMITS.POLYNOMIAL_FACTOR_MIN || factor > SMOOTHING_LIMITS.POLYNOMIAL_FACTOR_MAX;
  }

  // Polynomial Factor must be less than the number of periods in a window
  invalidPolynomialFactorPeriods(factor) {
    const smoothingWindow = sqSignalSmoothingStore.smoothingWindow;
    const samplingRate = sqSignalSmoothingStore.samplingRate;
    if (samplingRate.valid && smoothingWindow.valid && !this.invalidPolynomialFactorRange(factor)) {
      const samplingRateMS = updateUnits(samplingRate.value, 'ms', samplingRate.units).value;
      const smoothingWindowMS = updateUnits(smoothingWindow.value, 'ms', smoothingWindow.units).value;
      return factor >= smoothingWindowMS / samplingRateMS;
    }
    return false;
  }

  getPolynomialFactorUpperBound() {
    const smoothingWindow = sqSignalSmoothingStore.smoothingWindow;
    const samplingRate = sqSignalSmoothingStore.samplingRate;

    if (!smoothingWindow.valid || !samplingRate.valid) {
      return { limit: SMOOTHING_LIMITS.POLYNOMIAL_FACTOR_MAX };
    }

    const samplingRateMS = updateUnits(samplingRate.value, 'ms', samplingRate.units).value;
    const smoothingWindowMS = updateUnits(smoothingWindow.value, 'ms', smoothingWindow.units).value;

    const ratioLimit = smoothingWindowMS / samplingRateMS;
    // Make sure the ratio is in between the limits before returning
    return {
      limit: this.invalidPolynomialFactorRange(ratioLimit) ? SMOOTHING_LIMITS.POLYNOMIAL_FACTOR_MAX : ratioLimit,
    };
  }

  // Smoothing Window must be greater than or equal to 3 times the Sampling Rate and less than or equal to 101 times
  // the Sampling Rate
  isWindowRatioInvalid({ samplingRate, smoothingWindow }) {
    const lowerBoundRatioValid = checkWindowSizeRatio(
      samplingRate,
      smoothingWindow,
      SMOOTHING_LIMITS.WINDOW_SIZE_MIN_RATIO,
    );
    const upperBoundRatioValid = checkWindowSizeRatio(
      samplingRate,
      smoothingWindow,
      SMOOTHING_LIMITS.WINDOW_SIZE_MAX_RATIO,
      false,
    );
    return (smoothingWindow.valid && !lowerBoundRatioValid) || !upperBoundRatioValid;
  }

  getWindowSizeErrorBounds(samplingRate) {
    const rate = isFrequency(samplingRate.units) ? convertFrequencyToPeriod(samplingRate) : samplingRate;
    const unit = _.find(DURATION_TIME_UNITS, (unit) => unit?.unit[0] === rate.units)?.unit[0] ?? rate?.units;

    const lowerBound = determineIdealUnits({
      value: rate.value * SMOOTHING_LIMITS.WINDOW_SIZE_MIN_RATIO,
      units: unit,
    });
    const upperBound = determineIdealUnits({
      value: rate.value * SMOOTHING_LIMITS.WINDOW_SIZE_MAX_RATIO,
      units: unit,
    });

    const getDisplayUnits = (timeUnit) =>
      i18next.t(_.find(DURATION_TIME_UNITS, (unit) => unit.unit[0] === timeUnit.units)?.translationKey) ?? rate?.units;

    return {
      lowerBound: `${lowerBound.value} ${getDisplayUnits(lowerBound)}`,
      upperBound: `${upperBound.value} ${getDisplayUnits(upperBound)}`,
    };
  }
}
