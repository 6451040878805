import _ from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationWithHTML } from '@/hybrid/core/ContainerWithHTML.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { HelpButton } from '@/hybrid/core/HelpButton.atom';
import { TREND_VIEWS } from '@/trendData/trendData.constants';
import { sqTrendCapsuleSetStore, sqTrendSeriesStore, sqTrendStore, sqWorksheetStore } from '@/core/core.stores';
import { DIMMING_ICON } from '@/hybrid/trend/trendHelp/trendHelp.constants';

export const TrendHelp = () => {
  const { t } = useTranslation();

  const assetTreeIcon = "<i class='sq-fairly-dark-gray pl5 pr10 fa fa-lg fa-cube'></i>";
  const chartIcon = "<i class='fa fa-area-chart pr3'></i>";
  const dataIcon = "<i class='fa fa-database pr3'></i>";
  const groupIcon = "<i class='fc fc-group pr3'></i>";

  const view = useFluxPath(sqTrendStore, () => sqTrendStore.view);
  const isCompareMode = useFluxPath(sqTrendStore, () => sqTrendStore.isCompareMode);
  const separateByProperty = useFluxPath(sqTrendStore, () => sqTrendStore.separateByProperty);
  const colorByProperty = useFluxPath(sqTrendStore, () => sqTrendStore.colorByProperty);
  const hideUnselectedItems = useFluxPath(sqTrendStore, () => sqTrendStore.hideUnselectedItems);
  const capsuleTimeLimited = useFluxPath(sqTrendStore, () => sqTrendStore.capsuleTimeLimited);
  const capsuleGroupMode = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.capsuleGroupMode);
  const noSignalsInAnyGroup = useFluxPath(sqWorksheetStore, () =>
    _.isEmpty(_.chain(sqWorksheetStore.conditionToSeriesGrouping).values().flatten().value()),
  );
  const capsuleSeries = useFluxPath(sqTrendSeriesStore, () => sqTrendSeriesStore.capsuleSeries);
  const nonCapsuleSeriesAndPreview = useFluxPath(
    sqTrendSeriesStore,
    () => sqTrendSeriesStore.nonCapsuleSeriesAndPreview,
  );
  const capsuleSetItems = useFluxPath(sqTrendCapsuleSetStore, () => sqTrendCapsuleSetStore.items);

  const MAX_CAPSULE_TIME_ITEMS = sqTrendStore.getMaxCapsuleTimeItems();

  useEffect(() => {
    displayText();
  }, [
    view,
    hideUnselectedItems,
    capsuleTimeLimited,
    capsuleGroupMode,
    capsuleSeries,
    nonCapsuleSeriesAndPreview,
    capsuleSetItems,
  ]);

  const renderAddCapsule = (
    <>
      <p>{t('TREND_HELP.CAPSULE_TIME_ENABLES')}</p>
      <p>
        <strong>{t('TREND_HELP.ADD_CAPSULE')}</strong>
      </p>
    </>
  );

  const renderAddCapsuleCompareMode = (
    <div className="alert alert-info fs16 p25 flexSelfCenter width-400">
      <p>{t('TREND_HELP.COMPARE_VIEW_HELP_TEXT')}</p>
      <p>
        <strong>{t('TREND_HELP.ADD_CAPSULE')}</strong>
      </p>
    </div>
  );

  const renderAddCapsuleSeries = <p>{t('TREND_HELP.CAPSULE_TIME_ENABLES')}</p>;

  const renderAddCondition = (
    <>
      <p>{t('TREND_HELP.CAPSULE_TIME_OVERLAYS')}</p>
      <p>
        <strong>{t('TREND_HELP.CAPSULE_ADD_CONDITION')}</strong>
      </p>
    </>
  );

  const renderAddData = (
    <p>
      <TranslationWithHTML
        translationKey="TREND_HELP.ADD_DATA"
        translationParams={{
          DATA_ICON: dataIcon,
          ASSET_TREE_ICON: assetTreeIcon,
        }}
      />
    </p>
  );

  const renderAddSignal = (
    <>
      <p>{t('TREND_HELP.CAPSULE_TIME_ENABLES')}</p>
      <p>
        <strong>
          <TranslationWithHTML translationKey="TREND_HELP.ADD_SIGNAL" translationParams={{ CHART_ICON: chartIcon }} />
        </strong>
      </p>
    </>
  );

  const renderCapsuleGroupMode = (
    <>
      <p>{t('TREND_HELP.CAPSULE_GROUP_MODE')}</p>
      <p>
        <strong>
          <TranslationWithHTML
            translationKey="TREND_HELP.CLICK_GROUP"
            translationParams={{ GROUP_ICON: groupIcon, CHART_ICON: chartIcon }}
          />
        </strong>
      </p>
    </>
  );

  const renderCapsuleTimePerformance = (
    <>
      <div className="flexColumnContainer verticalAlignMiddle">
        <TranslationWithHTML
          extraClassName="flexSelfCenter"
          translationKey="TREND_HELP.CAPSULE_TIME_LIMITED"
          translationParams={{ COUNT: MAX_CAPSULE_TIME_ITEMS }}
        />
        <HelpButton
          iconClassNames="fa-xlg pl8 text-interactive"
          customChildren={
            <div className="flexRowContainer">
              <div className="ml5">
                <TranslationWithHTML
                  translationKey="TREND_HELP.CAPSULE_TIME_TAKE_ACTION"
                  translationParams={{
                    SIGNALS: capsuleTimeLimited.itemsCount,
                    CAPSULES: capsuleTimeLimited.capsulesCount,
                    SEGMENTS: capsuleTimeLimited.itemsCount * capsuleTimeLimited.capsulesCount,
                  }}
                />
              </div>
              <ul>
                <li>
                  <TranslationWithHTML
                    translationKey="TREND_HELP.REDUCE_SIGNALS_CONDITIONS"
                    translationParams={{ CHART_ICON: chartIcon }}
                  />
                </li>
                <li>{t('TREND_HELP.REDUCE_DISPLAY_RANGE_DURATION')}</li>
                <li>{t('TREND_HELP.REDUCE_CAPSULES')}</li>
                <li>
                  <TranslationWithHTML
                    translationKey="TREND_HELP.SHOW_ONLY_SELECTED"
                    translationParams={{ DIMMING_ICON }}
                  />
                </li>
                <li>{t('TREND_HELP.FILTER_CAPSULES_PANE')}</li>
              </ul>
            </div>
          }
        />
      </div>
    </>
  );

  const renderChainNoConditionSelected = (
    <>
      <p>{t('TREND_HELP.CHAIN_VIEW_SHOWS')}</p>
      <p>
        <strong>
          <TranslationWithHTML
            translationKey="TREND_HELP.CHAIN_NO_CONDITION_SELECTED"
            translationParams={{ DIMMING_ICON }}
          />
        </strong>
      </p>
    </>
  );

  const renderChainRequiresCapsules = (
    <>
      <p>{t('TREND_HELP.CHAIN_VIEW_SHOWS')}</p>
      <p>
        <strong>{t('TREND_HELP.CHAIN_REQUIRES_CAPSULES')}</strong>
      </p>
    </>
  );

  const renderChainRequiresCondition = (
    <>
      <p>{t('TREND_HELP.CHAIN_REQUIRES_CONDITION')}</p>
      <p>
        <b>{t('TREND_HELP.CHAIN_ADD_CONDITION')}</b>
      </p>
    </>
  );

  const renderCompareViewPerformance = (
    <>
      <p>
        {t('TREND_HELP.COMPARE_VIEW_LIMITED', {
          COUNT: MAX_CAPSULE_TIME_ITEMS,
        })}
      </p>
      <p>
        {t('TREND_HELP.CAPSULE_TIME_TAKE_ACTION', {
          SIGNALS: capsuleTimeLimited.itemsCount,
          CAPSULES: capsuleTimeLimited.capsulesCount,
          SEGMENTS: capsuleTimeLimited.itemsCount * capsuleTimeLimited.capsulesCount,
        })}
      </p>
      <ul>
        <li>
          <TranslationWithHTML
            translationKey="TREND_HELP.REDUCE_SIGNALS_CONDITIONS"
            translationParams={{ CHART_ICON: chartIcon }}
          />
        </li>
        <li>{t('TREND_HELP.REDUCE_CAPSULES')}</li>
      </ul>
    </>
  );

  const renderCompareViewDefault = (
    <div className="alert alert-info fs16 p25 flexSelfCenter width-400">
      <p>{t('TREND_HELP.COMPARE_VIEW_HELP_TEXT')}</p>
    </div>
  );

  const renderCompareViewImage = (
    <div className="flexRowContainer flexAlignCenter">
      <p className="width-400 text-center">{t('CONDITION_WITH_PROPERTIES.HELP.SPLASH_TEXT')}</p>
      <img src="/img/batch-instructional-screen-3-steps.gif" width="650px" data-testid="compareViewConditionHelp" />
    </div>
  );

  const displayText = () => {
    if (isCompareMode) {
      // The order of evaluation is important
      if (!capsuleSetItems.length || !nonCapsuleSeriesAndPreview.length || !colorByProperty || !separateByProperty) {
        return renderCompareViewImage;
      } else if (capsuleTimeLimited.enabled) {
        return renderCompareViewPerformance;
      } else if (!capsuleSeries.length || !_.some(capsuleSeries, (capsule) => capsule.duration > 0)) {
        return renderAddCapsuleCompareMode;
      } else {
        return renderCompareViewDefault;
      }
    } else if (view === TREND_VIEWS.CAPSULE) {
      // The order of evaluation is important
      if (!capsuleSetItems.length) {
        return renderAddCondition;
      } else if (!nonCapsuleSeriesAndPreview.length) {
        return renderAddSignal;
      } else if (capsuleTimeLimited.enabled) {
        return renderCapsuleTimePerformance;
      } else if (capsuleGroupMode && noSignalsInAnyGroup) {
        return renderCapsuleGroupMode;
      } else if (!capsuleSeries.length || !_.some(capsuleSeries, (capsule) => capsule.duration > 0)) {
        return renderAddCapsule;
      } else {
        return renderAddCapsuleSeries;
      }
    } else if (view === TREND_VIEWS.CHAIN) {
      if (hideUnselectedItems) {
        return renderChainNoConditionSelected;
      } else if (!capsuleSetItems.length) {
        return renderChainRequiresCondition;
      } else {
        return renderChainRequiresCapsules;
      }
    } else {
      return renderAddData;
    }
  };

  return <span> {displayText()} </span>;
};

export default TrendHelp;
