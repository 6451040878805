// @ts-strict-ignore
import React, { useCallback, useState } from 'react';
import { FormControl, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { setItemOwner, setItemRenderer, setWorkbookName, setWorkbookDescription } from '@/workbook/workbook.actions';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { APP_STATE, MAX_NAME_LENGTH } from '@/main/app.constants';
import { DEBOUNCE } from '@/core/core.constants';
import { SelectIdentity } from '@/hybrid/core/SelectIdentity.molecule';
import { HomeScreenActions } from '@/hybrid/homescreen/homescreen.actions';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';
import { CancelAndSave } from '@/hybrid/core/CancelAndSave.molecule';
import { ITEM_TYPES } from '@/hybrid/homescreen/homescreen.constants';
import { sqHomeScreenStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';
import { HomeScreenUtilitiesService } from './homeScreen.utilities.service';

const editWorkbookModalBindings = bindingsDefinition({
  onClose: prop<() => void>(),
  id: prop<string>(),
  type: prop<string>(),
  name: prop<string>(),
  description: prop<string>(),
  parentFolderId: prop.optional<string>(),
  owner: prop.optional<{ name: string; id: string; isAdmin: boolean }>(),
  renderer: prop.optional<{ name: string; id: string }>(),
  isCorporate: prop.optional<boolean>(),
  sqHomeScreenActions: injected<HomeScreenActions>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
  $state: injected<ng.ui.IStateService>(),
});

export const EditWorkbookModal: SeeqComponent<typeof editWorkbookModalBindings> = (props) => {
  const { $state, sqHomeScreenActions, sqHomeScreenUtilities } = useInjectedBindings(editWorkbookModalBindings);
  const { onClose, id, type, parentFolderId } = props;
  const { t } = useTranslation();

  const [name, setName] = useState(props.name);
  const [owner, setOwner] = useState(props.owner);
  const [renderer, setRenderer] = useState(props.renderer);
  const [description, setDescription] = useState(props.description);

  if (type === ITEM_TYPES.TOPIC && !renderer) {
    setRenderer(owner);
  }
  const btnEnabled =
    !_.isEmpty(name) &&
    !_.isEmpty(owner?.id || null) &&
    (!_.isEmpty(renderer?.id || null) || type !== ITEM_TYPES.TOPIC);
  const callbackRef = useCallback((titleElement) => {
    const delay = setTimeout(() => {
      if (titleElement) {
        titleElement.focus();
      }
    }, DEBOUNCE.SHORT);
    return () => clearTimeout(delay);
  }, []);

  const trackAndCallbackIfChanged = (
    newValue,
    previousValue,
    callback,
    trackCategory,
    trackAction,
    trackInformation,
  ) => {
    if (previousValue !== newValue) {
      return callback().then(() => doTrack(trackCategory, trackAction, trackInformation));
    } else {
      return Promise.resolve();
    }
  };

  const save = () => {
    let folderId = id;
    return Promise.resolve()
      .then(() => {
        if (!id) {
          const ownerId = props.owner.isAdmin ? owner.id : undefined;
          return sqHomeScreenActions.addFolder({ name, parentFolderId, ownerId }).then((folder) => {
            folderId = folder.id;
          });
        } else {
          return trackAndCallbackIfChanged(
            name,
            props.name,
            () => setWorkbookName(folderId, name, props.name, sqHomeScreenUtilities),
            'Workbench_2.0',
            type,
            'renamed',
          );
        }
      })
      .then(() =>
        trackAndCallbackIfChanged(
          description,
          props.description,
          () => setWorkbookDescription(folderId, description, sqHomeScreenUtilities),
          'Workbench_2.0',
          type,
          'description changed',
        ),
      )
      .then(() => {
        if (id) {
          trackAndCallbackIfChanged(
            owner.id,
            props.owner.id,
            () => setItemOwner(id, owner),
            'Workbench_2.0',
            type,
            'owner changed',
          );
        }
      })
      .then(() => {
        if (renderer) {
          trackAndCallbackIfChanged(
            renderer.id,
            props.renderer?.id || null,
            () => setItemRenderer(folderId, renderer),
            'Workbench_2.0',
            type,
            'renderer changed',
          );
        }
      })
      .then(() => {
        onClose();
        $state
          .go(APP_STATE.FOLDER_EXPANDED, { currentFolderId: sqHomeScreenStore.currentFolderId }, { reload: true })
          .then(() => requestAnimationFrame(() => successToast({ messageKey: 'CHANGES_SAVED' })));
      })
      .catch((error) => {
        doTrack('Workbench_2.0', type, 'side panel save failed');
        errorToast({ doNotTrack: true, httpResponseOrError: error });
      });
  };

  return (
    <Modal show={true} onHide={onClose} animation={false} data-testid="editWorkbookModal">
      <Modal.Header closeButton={true}>
        <h3>{t('WORKBOOK_EDIT')}</h3>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            <label>{t('NAME')}</label>
            <FormControl
              autoComplete="off"
              onFocus={(e) => e.target.select()}
              id="name"
              name="name"
              value={name}
              data-testid="name"
              onChange={(e) => setName(e.target.value)}
              maxLength={MAX_NAME_LENGTH.WORKBOOK}
              ref={callbackRef}
            />
          </div>
          {!name && <p className="help-block">{t('FORM.REQUIRED_FIELD')}</p>}
          <div className="form-group">
            <label htmlFor="description">{t('DESCRIPTION')}</label>
            <textarea
              className="form-control"
              id="description"
              name="description"
              onChange={(e) => setDescription(e.target.value)}
              rows={5}
              value={description}
            />
          </div>
          {props.isCorporate && ( // corporate items' owners can change and should always be viewable
            <div className="form-group">
              <label htmlFor="owner">{t('OWNER')}</label>
              <SelectIdentity
                idForLabel="owner"
                identity={owner}
                setIdentity={setOwner}
                allowGroups={false}
                placeholder="CHANGE_OWNER.PLACEHOLDER"
                tooltip="CHANGE_OWNER.TOOLTIP"
                unauthorizedTooltip="CHANGE_OWNER.NOT_PERMITTED_TO_CHANGE"
              />
            </div>
          )}
          {type === ITEM_TYPES.TOPIC && (
            <div className="form-group">
              <label htmlFor="renderer">{t('RENDERER')}</label>
              <SelectIdentity
                idForLabel="renderer"
                identity={renderer}
                setIdentity={setRenderer}
                allowGroups={true}
                placeholder="CHANGE_RENDERER.PLACEHOLDER"
                tooltip="CHANGE_RENDERER.TOOLTIP"
                unauthorizedTooltip="CHANGE_RENDERER.NOT_PERMITTED_TO_CHANGE"
              />
            </div>
          )}

          <div className="text-center">
            <CancelAndSave cancelFn={onClose} submitFn={save} btnDisabled={!btnEnabled} values={[]} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};
