// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { HomeScreenUtilitiesService } from '@/hybrid/homescreen/homeScreen.utilities.service';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import _ from 'lodash';
import { formatDateWorkbook } from '@/hybrid/datetime/dateTime.utilities';
import { IconWithSpinner } from '@/hybrid/core/IconWithSpinner.atom';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Icon, IconProps } from '@/hybrid/core/Icon.atom';

const NameCellBindings = bindingsDefinition({
  item: prop<any>(),
  showBreadcrumbs: prop<boolean>(),
  extraClassNames: prop.optional<string>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
});

export const NameCell: SeeqComponent<typeof NameCellBindings> = (props) => {
  const { item, showBreadcrumbs, extraClassNames } = props;
  const { sqHomeScreenUtilities } = useInjectedBindings(NameCellBindings);

  const renderBreadcrumbs = (crumbs) => (
    <span data-testid="itemBreadcrumb" className="xsmall">
      {_.join(
        _.map(crumbs, (crumb) => sqHomeScreenUtilities.translateBreadcrumb(crumb)),
        ' » ',
      )}
    </span>
  );

  return (
    <div className={classNames('flexRowContainer', extraClassNames)}>
      {showBreadcrumbs && renderBreadcrumbs(item.ancestors)}
      <span data-testid="itemName">{item.name}</span>
    </div>
  );
};

const homeScreenIconCellBindings = bindingsDefinition({
  item: prop<any>(),
  spinning: prop.optional<boolean>(),
  sqHomeScreenUtilities: injected<HomeScreenUtilitiesService>(),
});

export const HomeScreenItemIconCell: SeeqComponent<typeof homeScreenIconCellBindings> = (props) => {
  const { item, spinning = false } = props;
  const { sqHomeScreenUtilities } = useInjectedBindings(homeScreenIconCellBindings);
  const itemIcon = sqHomeScreenUtilities.getItemIcon(props.item);

  return (
    <div data-testid={`icon_${item.id}`}>
      <IconWithSpinner spinning={spinning} icon={classNames('workbookIcon', 'workbenchDisplay', itemIcon)} />
    </div>
  );
};

const dateCellBindings = bindingsDefinition({
  item: prop<any>(),
  accessor: prop<string>(),
});

export const DateCell: SeeqComponent<typeof dateCellBindings> = (props) => {
  const { item, accessor } = props;

  if (!item[accessor]) {
    return null;
  }

  return <span>{formatDateWorkbook(item[accessor])}</span>;
};

export const ProgressCell: React.FunctionComponent<{ percentage: number }> = ({ percentage }) => (
  <div className="flexColumnContainer flexCenter">
    <span className="dataStatusIcon verticalAlignMiddle">
      <CircularProgressbar value={percentage} text={_.toString(percentage)} />
    </span>
  </div>
);

export const IconCell: React.FunctionComponent<IconProps> = (props) => (
  <div className="flexColumnContainer flexCenter">
    <Icon {...props} />
  </div>
);
