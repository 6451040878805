// @ts-strict-ignore
import _ from 'lodash';
import React from 'react';
import { TableBuilderTextHeader } from '@/hybrid/tableBuilder/tableComponents/TableBuilderTextHeader.atom';
import { TableBuilderDataHeader } from '@/hybrid/tableBuilder/tableComponents/TableBuilderDataHeader.atom';
import { TableBuilderDataCell } from '@/hybrid/tableBuilder/tableComponents/TableBuilderDataCell.atom';
import { TableBuilderTextCell } from '@/hybrid/tableBuilder/tableComponents/TableBuilderTextCell.atom';

const ALLOWED_COMPONENTS: React.FunctionComponent[] = [
  TableBuilderTextHeader,
  TableBuilderTextCell,
  TableBuilderDataHeader,
  TableBuilderDataCell,
];

export const TableBuilderHeaderRow: React.FunctionComponent = (props) => {
  const { children } = props;

  return <tr data-testid="tableBuilderHeaderRow">{children}</tr>;
};

TableBuilderHeaderRow.propTypes = {
  children: (props, propName) => {
    const prop = props[propName];
    if (_.isEmpty(prop)) {
      return new Error('TableBuilderHeaderRow must contain at least one cell');
    }
    return (
      _.find(React.Children.toArray(prop), (child) => !_.includes(ALLOWED_COMPONENTS, (child as JSX.Element).type)) &&
      new TypeError('TableBuilderHeaderRow cells must be either TextHeader, TextCell, DataHeader or DataCell')
    );
  },
};
