// @ts-strict-ignore
import React, { useState } from 'react';
import _ from 'lodash';
import { bindingsDefinition, injected, prop } from '@/hybrid/core/bindings.util';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SimpleSaveFormBuilder } from '@/hybrid/formbuilder/SimpleSaveFormBuilder.page';
import { FormElement } from '@/hybrid/formbuilder/formBuilder.module';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import { sqWorkbenchStore } from '@/core/core.stores';
import { WorkbenchActions } from '@/workbench/workbench.actions';

import { LOCALES, SUPPORTED_LANGUAGES } from '@/main/app.constants';
import { useFlux } from '@/hybrid/core/hooks/useFlux.hook';
import { sqUsersApi, UserInputV1 } from '@/sdk';
import { errorToast, successToast } from '@/hybrid/utilities/toast.utilities';
import { sqTimezones } from '@/hybrid/utilities/datetime.constants';
import { switchLanguage } from '@/hybrid/utilities/utilities';
import { LoadingFallback } from '@/hybrid/main/LoadingFallback.atom';

const editUserProfileBindings = bindingsDefinition({
  closeFn: prop<() => void>(),
  sqWorkbenchActions: injected<WorkbenchActions>(),
});

const WrappedEditUserProfile: SeeqComponent<typeof editUserProfileBindings> = (props) => {
  const { sqWorkbenchActions } = useInjectedBindings(editUserProfileBindings);
  const { closeFn } = props;
  const { t } = useTranslation();

  const { currentUser: user, userTimeZone, userLanguage, preferNewTab } = useFlux(sqWorkbenchStore);

  const [editingPassword, setEditingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [formSaveInProcess, setFormSaveInProcess] = useState(false);

  const isUserSSO = !user.isPasswordSettable;

  const formattedLanguageOptions = _.map(SUPPORTED_LANGUAGES, (language) => ({
    text: language.text,
    value: language.key,
  }));

  const selectedLanguage = _.find(formattedLanguageOptions, {
    value: userLanguage,
  });

  const updateUser = (values) => {
    const { firstName, lastName, email, timezone, language, preferNewTab, newPassword } = values;
    const userProps = _.pick(values, ['email', 'firstName', 'lastName']);
    const name = `${_.trim(firstName)} ${_.trim(lastName)}`;

    setFormSaveInProcess(true);
    _.assign(userProps, { name });

    /**
     * For SEEQ users we allow them to update their email, but their email is also their username. So, when they
     * update their email, then we have to also update their username (as email and username seem to be one to the end
     * user, at least right now). If a user is however an Active Directory user then they really just edit an email,
     * not their username.
     */
    if (!isUserSSO) {
      _.assign(userProps, { username: email });
    }

    if (editingPassword) {
      setFormSaveInProcess(true);
      return (
        sqUsersApi
          .updatePassword({ currentPassword, newPassword }, { id: user.id })
          // We assume that authProvider is Seeq because only those users can change their password.
          .then(() => successToast({ messageKey: 'USER.PASSWORD_CHANGED' }))
          .then(closeFn)
          .catch((error) => errorToast({ httpResponseOrError: error }))
          .finally(() => {
            setEditingPassword(false);
            setFormSaveInProcess(false);
          })
      );
    }

    return sqUsersApi
      .updateUser(userProps as UserInputV1, { id: user.id })
      .then(() => sqWorkbenchActions.setCurrentUser())
      .then(() => sqWorkbenchActions.setUserTimeZone(timezone))
      .then(() => sqWorkbenchActions.setUserLanguage(language?.value ?? LOCALES.EN))
      .then(() => sqWorkbenchActions.setPreferNewTab(preferNewTab))
      .then(() => switchLanguage(language?.value ?? LOCALES.EN))
      .then(() => successToast({ messageKey: 'USER.PROFILE_UPDATED' }))
      .then(closeFn)
      .catch((error) => errorToast({ httpResponseOrError: error }))
      .finally(() => {
        setFormSaveInProcess(false);
      });
  };

  const displayEditUser = () => setEditingPassword(false);

  const passwordChangeForm: FormElement = {
    component: 'FormGroup',
    name: 'editPassword',
    includeIf: editingPassword,
    components: [
      {
        component: 'FormControlFormComponent',
        name: 'currentPassword',
        label: 'USER.CURRENT_PASSWORD',
        value: currentPassword,
        onChange: (value: string) => setCurrentPassword(value),
        size: 'md',
        includeIf: !user.isAdmin,
        type: 'password',
        testId: 'currentPassword',
      },
      {
        component: 'PasswordGroupFormComponent',
        name: 'passwordFormGroup',
        value: 'passwordFormGroup',
      },
    ],
  };

  const formDefinition: FormElement[] = [
    {
      component: 'FormGroup',
      name: 'userProfile',
      includeIf: !editingPassword,
      components: [
        {
          component: 'FormControlFormComponent',
          name: 'firstName',
          label: 'USER.FIRST_NAME',
          value: user.firstName,
          onChange: _.noop,
          size: 'md',
          testId: 'firstName',
        },
        {
          component: 'FormControlFormComponent',
          name: 'lastName',
          label: 'USER.LAST_NAME',
          value: user.lastName,
          onChange: _.noop,
          size: 'md',
          testId: 'lastName',
        },
        {
          component: 'FormControlFormComponent',
          name: 'email',
          // For non-SSO users make it clear that the email field is also the username
          label: isUserSSO ? 'USER.EMAIL' : 'USER.USERNAME_AND_EMAIL',
          // For non-SSO users with no email set (such as 'agent_api_key') show the username to make it clear
          // that editing the field would change the username
          value: isUserSSO ? user.email : user.email || user.username,
          onChange: _.noop,
          size: 'md',
          testId: 'email',
        },
        {
          component: 'TimeZoneSelectorFormComponent',
          name: 'timezone',
          label: 'USER.TIME_ZONE',
          value: userTimeZone || sqTimezones.defaultTimezone,
          onChange: _.noop,
          defaultTimeZone: sqTimezones.defaultTimezone,
          skipStore: true,
          testId: 'timezone',
        },
        {
          component: 'IconSelectFormComponent',
          name: 'language',
          label: 'USER.LANGUAGE.SELECTION',
          value: selectedLanguage,
          onChange: _.noop,
          selectOptions: formattedLanguageOptions,
          insideModal: true,
          skipStore: true,
          testId: 'language',
        },
        {
          component: 'CheckboxFormComponent',
          id: 'userPreferNewTab',
          name: 'preferNewTab',
          label: '',
          onChange: _.noop,
          skipStore: true,
          checkboxLabel: 'USER.PREFER_NEW_TAB',
          value: preferNewTab,
        },
        {
          component: 'LabelFormComponent',
          name: 'passwordReset',
          value: '',
          extraClassNames: 'text-bolder',
        },
        {
          component: 'ClickableLinkFormComponent',
          name: 'setPassword',
          includeIf: user.isPasswordSettable,
          onChange: _.noop,
          value: 'USER.CHANGE_PASSWORD',
          icon: 'fa-lock',
          linkAction: () => setEditingPassword(true),
        },
      ],
    },
    passwordChangeForm,
  ];

  return (
    // The close and cancel buttons get hidden when the user has not set their timezone to require the user
    // to save their profile, which will set their timezone
    <Modal show={true} onHide={closeFn} animation={false} data-testid="editUserProfile">
      <Modal.Header closeButton={!!userTimeZone}>
        <h3 className="mr-auto">{editingPassword ? t('USER.CHANGE_PASSWORD') : t('EDIT_PROFILE')}</h3>
      </Modal.Header>
      <Modal.Body>
        <div data-testid="editUserProfile">
          <SimpleSaveFormBuilder
            formDefinition={formDefinition}
            formSaveProcessing={formSaveInProcess}
            submitFn={updateUser}
            closeFn={editingPassword ? displayEditUser : closeFn}
            hideCancel={!userTimeZone}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

// Wrap in Suspense to ensure translations are loaded before displaying the component
export const EditUserProfile: SeeqComponent<typeof editUserProfileBindings> = (props) => {
  return (
    <React.Suspense fallback={<LoadingFallback fallbackLocation="Edit user profile modal" />}>
      <WrappedEditUserProfile {...props} />
    </React.Suspense>
  );
};

export const sqEditUserProfile = angularComponent(editUserProfileBindings, EditUserProfile);
