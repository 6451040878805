// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { angularComponent } from '@/hybrid/core/react2angular.util';
import { bindingsDefinition } from '@/hybrid/core/bindings.util';
import { sqWorkbenchStore } from '@/core/core.stores';
import { SEEQ_VERSION } from '@/services/buildConstants.service';
import { DatasourcesStatus } from '@/hybrid/footer/DatasourcesStatus.organism';
import { useTranslation } from 'react-i18next';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { HoverTooltip } from '@/hybrid/core/HoverTooltip.atom';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { Overlay, Popover } from 'react-bootstrap';
import { subscribe } from '@/hybrid/utilities/socket.utilities';
import { getFeedbackEnabled } from '@/services/systemConfiguration.utilities';
import { LoadingFallback } from '@/hybrid/main/LoadingFallback.atom';

const footerBindings = bindingsDefinition({});

const WrappedFooter: SeeqComponent<typeof footerBindings> = () => {
  const { t } = useTranslation();
  const [serverLoad, setServerLoad] = useState({
    labelClass: '',
    loadPercentage: 0,
    description: '',
  });
  const [showServerLoadDescription, setShowServerLoadDescription] = useState(false);
  const interactiveSessionId = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.interactiveSessionId);
  const isDev = process.env.NODE_ENV === 'development';
  const target = useRef();

  const provideServerLoad = (message) => {
    setServerLoad({
      ...message?.serverLoad,
      description: `${message?.serverLoad.description} ${message?.consumption}`,
      loadPercentage: Math.round(message?.serverLoad.loadPercentage * 100),
      labelClass: getLabelClass(message?.serverLoad),
    });
  };

  const getLabelClass = _.cond([
    [_.conforms({ loadPercentage: (n: number) => n < 1 }), _.constant('label-success')],
    [_.conforms({ loadPercentage: (n: number) => n >= 1 && n < 2 }), _.constant('label-warning')],
    [_.stubTrue, _.constant('label-danger')],
  ]);

  useEffect(() => {
    const unsubscribe = subscribe({
      channelId: [SeeqNames.Channels.RequestsProgress, sqWorkbenchStore.interactiveSessionId],
      onMessage: provideServerLoad,
      useSubscriptionsApi: false,
    });
    return unsubscribe;
  }, [interactiveSessionId]);

  // Because we support sending feedback only to a percentage of users just enabling feedback doesn't guarantee
  // there is a feedback button - in those cases we don't want the server health to be displayed strangely indented.
  // Unfortunately mopininon does not have a way for us to know when the feedback button is done loading, so we have
  // to check on every re-render to make sure things look right :)
  const isFeedbackDisplayed = getFeedbackEnabled() && !_.isEmpty(document.querySelector('.btn-open-survey'));

  return (
    <footer
      data-testid="footer"
      className={classNames('mainFooter', 'flexFill', 'flexColumnContainer', 'flexSpaceBetween', {
        footerWithFeedback: isFeedbackDisplayed,
      })}>
      <div id="seeqVersion" className="inlineBlock">
        {SEEQ_VERSION} {isDev && '[DEV]'}
      </div>
      <div className="flexColumnContainer">
        <DatasourcesStatus />

        {!_.isEmpty(serverLoad) && (
          <HoverTooltip text="SERVER_LOAD.TOOLTIP" placement="top">
            <div
              ref={target}
              onClick={() => setShowServerLoadDescription(true)}
              className="text-nowrap flexColumnContainer flexAlignCenter cursorPointer">
              <span className="sq-fairly-dark-gray cursorDefault ml5 mr5">|</span>
              <span className="sq-fairly-dark-gray pr5">{t('SERVER_LOAD.TITLE')}</span>
              <span className={classNames('label', serverLoad.labelClass)}>
                {t('PERCENT', { PERCENT: serverLoad.loadPercentage })}
              </span>
            </div>
          </HoverTooltip>
        )}

        {showServerLoadDescription && (
          <Overlay
            target={target?.current}
            show={true}
            placement="top"
            rootClose={true}
            onHide={() => setShowServerLoadDescription(false)}
            transition={false}>
            <Popover id="serverLoadPopOver" className="popover datasourcesPopup min-width-500">
              <Popover.Title className="popover-title">
                <span>{t('SERVER_LOAD.POPOVER_TITLE')}</span>
              </Popover.Title>
              <Popover.Content>
                <div className="flexColumnContainer flexCenter">
                  <div className="text-pre-wrap text-monospace">{serverLoad.description}</div>
                </div>
              </Popover.Content>
            </Popover>
          </Overlay>
        )}
      </div>
    </footer>
  );
};

// Wrap in Suspense to ensure translations are loaded before displaying the component
export const Footer: SeeqComponent<typeof footerBindings> = () => {
  return (
    <React.Suspense fallback={<LoadingFallback fallbackLocation="Footer" />}>
      <WrappedFooter />
    </React.Suspense>
  );
};

export const sqFooter = angularComponent(footerBindings, Footer);
