// @ts-strict-ignore
import { useEffect, useState } from 'react';
import { bindingsDefinition, injected } from '@/hybrid/core/bindings.util';
import { useInjectedBindings } from '@/hybrid/core/hooks/useInjectedBindings.hook';
import _ from 'lodash';
import { SeeqNames } from '@/main/app.constants.seeqnames';

import { TrendActions } from '@/trendData/trend.actions';
import { InvestigateActions } from '@/hybrid/toolSelection/investigate.actions';
import { useFluxPath } from '@/hybrid/core/hooks/useFluxPath.hook';
import { ItemPropertiesService } from '@/hybrid/tools/itemProperties/propertiesPanel.service';
import { getScopeMessage } from '@/hybrid/tools/itemProperties/propertiesPanel.utilities';
import { sqItemsApi } from '@/sdk';
import { isUserCreatedType as UserCreatedTypeUtil } from '@/hybrid/utilities/utilities';
import { errorToast, infoToast, successToast } from '@/hybrid/utilities/toast.utilities';
import { sqInvestigateStore } from '@/core/core.stores';
import { TREND_TOOLS } from '@/hybrid/toolSelection/investigate.module';
import { onPermissions } from '@/services/notifier.service';
import { ADVANCED_PROPERTIES } from '@/trendData/trendData.constants';
import { canManageItem, canWriteItem } from '@/services/authorization.service';

type TProperties = {
  item;
  isItemPresent: boolean;
  properties;
  propertiesAdvanced: any[];
  numberFormatOverridden;
  stringFormatOverridden;
  cached;
  isCacheSupported: boolean;
  cacheChangeInProgress;
  isMaxInterpolationEditable: boolean;
  isNumberFormatEditable: boolean;
  isStringFormatEditable: boolean;
  canShowUsages: boolean;
  isUserCreatedType: boolean;
  originalDesc: string;
  scopeMessage: string;
  availableOutsideAnalysis: boolean;
  clearCacheTrackingInfo: string;
  canManage: boolean;
};

const propertiesPanelBindings = bindingsDefinition({
  $state: injected<ng.ui.IStateService>(),
  sqInvestigateActions: injected<InvestigateActions>(),
  sqTrendActions: injected<TrendActions>(),
  sqItemProperties: injected<ItemPropertiesService>(),
});

export const useProperties = () => {
  const { $state, sqTrendActions, sqInvestigateActions, sqItemProperties } =
    useInjectedBindings(propertiesPanelBindings);

  const itemFromStore = useFluxPath(sqInvestigateStore, () => sqInvestigateStore.item);

  const [tProperties, setTProperties] = useState({
    originalDesc: '',
    item: itemFromStore,
    propertiesAdvanced: [],
  } as TProperties);

  const { item, numberFormatOverridden, stringFormatOverridden, originalDesc } = tProperties;

  useEffect(() => {
    return onPermissions((workbookId, worksheetId) => {
      if (workbookId === $state.params.workbookId && worksheetId === $state.params.worksheetId) {
        loadProperties();
      }
    });
  }, []);

  /**
   * Syncs sqInvestigateStore and view-model properties
   */
  useEffect(() => {
    let cacheChangeInProgress = item?.id !== itemFromStore?.id ? false : tProperties.cacheChangeInProgress;
    const isItemPresent = !!itemFromStore?.id;

    updateTProperties({
      cacheChangeInProgress,
      isItemPresent,
      item: itemFromStore,
    });

    if (!isItemPresent) {
      clearProperties();
    } else {
      loadProperties(itemFromStore?.id);
    }
  }, [itemFromStore]);

  useEffect(
    () =>
      updateTProperties({
        isMaxInterpolationEditable: shouldMaxInterpolationBeEditable(),
        isNumberFormatEditable: shouldNumberFormatBeEditable(),
        isStringFormatEditable: shouldStringFormatBeEditable(),
        canManage: canManageItem(item),
      }),
    [item],
  );

  const updateTProperties = (props: Partial<TProperties>): void =>
    setTProperties((tProperties) => ({ ...tProperties, ...props }));

  const canWrite = (): boolean => canWriteItem(item);

  /**
   * Finds whether the user should be able to change the maximum interpolation
   *
   * @return {Boolean} True if the item is not a user-created type
   */
  const shouldMaxInterpolationBeEditable = (): boolean => !UserCreatedTypeUtil(item.type) && canWrite();

  /**
   * Returns true if the number format is editable (overridable) by the current user, false otherwise.
   *
   * @return {Boolean} True if the current user can change the number format, false otherwise.
   */
  const shouldNumberFormatBeEditable = (): boolean => canWrite();

  /**
   * Returns true if the string format is editable (overridable) by the current user, false otherwise.
   *
   * @return {Boolean} True if the current user can change the string format, false otherwise.
   */
  const shouldStringFormatBeEditable = (): boolean => canWrite();

  /**
   * Clears properties to reset panel state
   */
  const clearProperties = () =>
    updateTProperties({
      properties: undefined,
      propertiesAdvanced: undefined,
      cached: undefined,
      clearCacheTrackingInfo: undefined,
      isUserCreatedType: false,
    });

  /**
   * Fetches and shows the properties for an item
   *
   * @returns {Promise} that resolves when the properties have been fetched
   */
  const loadProperties = (itemId = ''): Promise<void> =>
    sqItemProperties
      .loadPropertiesHelper(itemId || item.id)
      .then(
        ({
          properties,
          cached,
          type,
          additionalAdvancedProperties,
          effectivePermissions,
          scopedTo,
          numberFormatOverridden,
          stringFormatOverridden,
        }) => {
          // Unfortunately, we can't rely on the 'Cache Enabled' property to determine the cache status of this item,
          // since that property is only present when the property is explicitly set; it doesn't capture when the
          // cache status is at a default value based on the item type. So the backend now returns a .cached attribute
          // of the item that serves that purpose.
          const isCached = cached;
          const isCacheSupported = !_.isUndefined(cached);

          const allProperties = sqItemProperties.preparePropertiesListForDisplay(properties);

          // If a value was supplied for the focusPropName argument, then set the .triggerFocus value of the property
          // with that name. We use Date.now() as a convenient way to ensure there will always be a change each time
          // .triggerFocus is updated. This is required so that anyone watching .triggerFocus will see a change.
          if (item.propertiesFocus) {
            _.set(_.find(properties, ['name', item.propertiesFocus]), 'triggerFocus', Date.now());
          }

          const canShowUsages = type !== SeeqNames.Types.Asset;
          const isUserCreatedType = UserCreatedTypeUtil(type);
          const itemType = type;
          const itemEffectivePermissions = effectivePermissions;
          const itemScopedTo = scopedTo;

          const availableOutsideAnalysis = !scopedTo;
          const scopeMessage = getScopeMessage(availableOutsideAnalysis, scopedTo, $state.params.workbookId);

          const CUSTOM_UI_PROPERTIES = [SeeqNames.Properties.CacheEnabled, SeeqNames.Properties.Description];
          const [advancedProperties, commonProperties] = _.chain(allProperties)
            .reject((property) => _.includes(CUSTOM_UI_PROPERTIES, property.name))
            .partition((property) => _.includes(ADVANCED_PROPERTIES, property.name))
            .value();

          const originalDesc = _.find(allProperties, { name: SeeqNames.Properties.Description })?.value;

          const trackingProperties = ['ID', SeeqNames.Properties.DatasourceClass, SeeqNames.Properties.DatasourceId];
          const clearCacheTrackingInfo = _.chain(allProperties)
            .filter((prop: any) => _.includes(trackingProperties, prop.name))
            .map((property) => `${property.name}=${property.value}`)
            .value()
            .join(',');

          updateTProperties({
            item: {
              ...itemFromStore,
              type: itemType,
              scopedTo: itemScopedTo,
              effectivePermissions: itemEffectivePermissions,
            },
            numberFormatOverridden,
            stringFormatOverridden,
            properties: commonProperties,
            propertiesAdvanced: advancedProperties.concat(_.compact(additionalAdvancedProperties)),
            canShowUsages,
            isUserCreatedType,
            originalDesc,
            clearCacheTrackingInfo,
            isCacheSupported,
            cached: isCached,
            availableOutsideAnalysis,
            scopeMessage,
          });
        },
      )
      .catch((response) => {
        clearProperties();
        errorToast({ httpResponseOrError: response });
      })
      .finally(sqInvestigateActions.updateDerivedDataTree);

  /**
   * Update the property of an item if it has changed. The change check is done because this is called onBlur.
   *
   * @param {Object} property - The property to update
   * @param {Boolean} [updatePropsOnly] - True to update only the properties
   *
   * @return {Promise} that resolves when the property value has been set
   */
  const setProperty = (property, updatePropsOnly = false) => {
    const overridableProperties = [SeeqNames.Properties.NumberFormat, SeeqNames.Properties.StringFormat];
    const isOverridable = _.includes(overridableProperties, property.name);
    const isOverridden =
      (property.name === SeeqNames.Properties.NumberFormat && numberFormatOverridden) ||
      (property.name === SeeqNames.Properties.StringFormat && stringFormatOverridden);

    return sqItemProperties
      .setPropertyInternal(item, property, isOverridable, isOverridden, {
        updatePropsOnly,
      })
      .then(() => loadProperties())
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  };

  /**
   * Deletes a property from the item
   *
   * @param {Object} property - Property to delete
   * @param {String} property.name - Name of property
   * @returns {Promise} that resolves when property has been deleted
   */
  const deleteProperty = (property) =>
    sqItemsApi
      .deleteProperty({ id: item.id, propertyName: property.name })
      .then(() => sqTrendActions.fetchItemAndDependents(item.id))
      .then(() => loadProperties())
      .catch((error) => {
        // When a delete happens twice, the second one won't find the property. That's okay, sometimes users
        // double-click.
        if (!error.data?.statusMessage.match(/The property .* could not be found/)) {
          errorToast({ httpResponseOrError: error });
        }
      });

  /**
   * Set the archived property on the item to true or false. When set to True, item is also removed from the worksheet.
   *
   * @param {Boolean} val - Value for Archived property
   * @returns {Promise} that resolves when property has been set
   */
  const setArchived = (val: boolean) => {
    // Skip fetching updated properties when setting Archived to true
    return sqItemProperties
      .setPropertyInternal(item, { name: SeeqNames.Properties.Archived, value: val }, false, false, {
        updatePropsOnly: true,
        skipFetch: val,
      })
      .then(() => {
        if (val) {
          const id = item.id;
          const restoreItem = () =>
            sqItemsApi
              .getItemAndAllProperties({ id })
              .then(({ data }) => sqTrendActions.addItem(data))
              .then((item) =>
                sqItemProperties.setPropertyInternal(item, {
                  name: SeeqNames.Properties.Archived,
                  value: false,
                }),
              )
              .then(() => {
                sqInvestigateActions.setActiveTool(TREND_TOOLS.PROPERTIES);
                sqInvestigateActions.setItem(id);
                sqInvestigateActions.updateDerivedDataTree();
              })
              .catch((error) => {
                errorToast({ httpResponseOrError: error });
              });

          sqTrendActions.removeItem(item, true);
          successToast({
            messageKey: 'TRASH.ITEM_TRASHED_NOTIFICATION',
            messageParams: { ITEM_NAME: item.name },
            buttonLabelKey: 'RESTORE',
            buttonAction: restoreItem,
          });
        } else {
          sqInvestigateActions.setItem(item.id);
          sqInvestigateActions.updateDerivedDataTree();
        }
      })
      .catch((error) => {
        errorToast({ httpResponseOrError: error });
      });
  };

  /**
   * Set the Cache Enabled property to the specified value
   *
   * @param {boolean} isCacheEnabled - True to enable cache; otherwise false
   */
  const setCacheEnabled = (isCacheEnabled: boolean) => {
    updateTProperties({ cacheChangeInProgress: true });
    setProperty({
      name: SeeqNames.Properties.CacheEnabled,
      value: isCacheEnabled,
    }).finally(() => updateTProperties({ cacheChangeInProgress: false }));
  };

  /**
   * Clear the cache for the specified item
   */
  const clearCache = () => {
    updateTProperties({ cacheChangeInProgress: true });

    return sqItemsApi
      .clearCache(item)
      .then((response) => infoToast({ httpResponseOrError: response }))
      .then(() => sqTrendActions.fetchItemAndDependents(item.id))
      .catch((error) => errorToast({ httpResponseOrError: error }))
      .finally(() => updateTProperties({ cacheChangeInProgress: false }));
  };

  const updatePropertyValue = (value: string, propertyIndex: number) => {
    const newProperties = [...tProperties.properties];
    newProperties[propertyIndex].value = value;
    updateTProperties({ properties: newProperties });
  };

  /**
   * Updates the description property of the item
   *
   * @return {Promise} Resolves when description has been updated.
   */
  const updateDescription = (description: string): any => {
    if (originalDesc !== description) {
      if (!_.isEmpty(description)) {
        return sqItemsApi
          .setProperty({ value: description }, { id: item.id, propertyName: SeeqNames.Properties.Description })
          .then(() => successToast({ messageKey: 'CHANGES_SAVED' }))
          .then(() => sqTrendActions.fetchItemAndDependents(item.id))
          .then(() => loadProperties())
          .catch((error) => {
            errorToast({ httpResponseOrError: error });
          });
      } else {
        return sqItemsApi
          .deleteProperty({
            id: item.id,
            propertyName: SeeqNames.Properties.Description,
          })
          .then(() => successToast({ messageKey: 'CHANGES_SAVED' }))
          .then(() => sqTrendActions.fetchItemAndDependents(item.id))
          .then(() => loadProperties())
          .catch((error) => {
            errorToast({ httpResponseOrError: error });
          });
      }
    }

    return Promise.resolve();
  };

  return {
    setArchived,
    canWrite,
    setProperty,
    deleteProperty,
    setCacheEnabled,
    clearCache,
    updatePropertyValue,
    updateDescription,
    loadProperties,
    tProperties,
  };
};
