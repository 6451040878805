import angular from 'angular';
import formulaToolModule from '@/hybrid/tools/formula/formulaTool.module';

const dependencies = ['Sq.AppConstants', 'Sq.Services.Formula', 'Sq.TrendData', formulaToolModule.name];

/**
 * @file Module for service that facilitates creating a calculation and then using that
 * calculation to create results, such as generated signals and conditions.
 */
angular.module('Sq.Services.CalculationRunner', dependencies);
