import React from 'react';
import { bindingsDefinition, prop } from '@/hybrid/core/bindings.util';
import { useTranslation } from 'react-i18next';

const capsulePickerPromptBindings = bindingsDefinition({
  toolName: prop<string>(),
  isToolPanelShown: prop<boolean>(),
  showToolPanels: prop<() => void>(),
});

export const CapsulePickerPrompt: SeeqComponent<typeof capsulePickerPromptBindings> = ({
  toolName,
  isToolPanelShown,
  showToolPanels,
}) => {
  const { t } = useTranslation();
  return isToolPanelShown ? (
    <div id="specTrendAlert" className="alert alert-info mt0 mb8 ml5 mr5 pt7 pb7 text-center">
      {t('CAPSULE_GROUP_PICKING.CLICK_TO_COPY', { toolName })}
    </div>
  ) : (
    <div className="alert alert-info mt0 mb7 ml5 mr5 pt2 pb2 text-center">
      <p className="mt6 mb0 inlineBlock">{t('CAPSULE_GROUP_PICKING.EDITING_A', { toolName })}</p>
      <button type="button" className="sq-btn sq-btn-outline sq-btn-sm ml10" onClick={showToolPanels}>
        {t('CAPSULE_GROUP_PICKING.RETURN_TO', { toolName })}
      </button>
    </div>
  );
};
