// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Form } from 'react-bootstrap';

export interface HeaderOptionsSelectorProps {
  options: { label: string; id: string; value: string }[];
  onChange: (value) => void;
  name: string;
  title?: string;
  defaultValue?: string;
}

export const HeaderOptionsSelector: React.FunctionComponent<HeaderOptionsSelectorProps> = ({
  options,
  onChange,
  name,
  title = '',
  defaultValue,
}) => (
  <Form.Group>
    {title !== '' && <Form.Label className="mt15">{title}</Form.Label>}
    {_.map(options, (singleOption) => (
      <Form.Check
        key={singleOption.id}
        type="radio"
        id={singleOption.id}
        name={name}
        data-testid={singleOption.value}
        label={<span className="text-not-bold">{singleOption.label}</span>}
        value={singleOption.value}
        defaultChecked={defaultValue === singleOption.value}
        onChange={() => onChange(singleOption.value)}
      />
    ))}
  </Form.Group>
);
